import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const SearchIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SearchIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M19.8145 18.9453L14.2676 13.4375C15.5176 11.9922 16.2207 10.1562 16.2207 8.125C16.2207 3.67188 12.5488 0 8.0957 0C3.60352 0 0.00976562 3.67188 0.00976562 8.125C0.00976562 12.6172 3.64258 16.25 8.0957 16.25C10.0879 16.25 11.9238 15.5469 13.3691 14.2969L18.877 19.8438C19.0332 19.9609 19.1895 20 19.3848 20C19.541 20 19.6973 19.9609 19.8145 19.8438C20.0488 19.6094 20.0488 19.1797 19.8145 18.9453ZM8.13477 15C4.30664 15 1.25977 11.9141 1.25977 8.125C1.25977 4.33594 4.30664 1.25 8.13477 1.25C11.9238 1.25 15.0098 4.33594 15.0098 8.125C15.0098 11.9531 11.9238 15 8.13477 15Z"
            fill="currentColor"
        />
    )
})

export const SearchIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SearchIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M19.6973 18.3984L14.4629 13.1641C15.5957 11.7969 16.2207 10.0391 16.2207 8.125C16.2207 3.67188 12.5488 0 8.0957 0C3.60352 0 0.00976562 3.67188 0.00976562 8.125C0.00976562 12.6172 3.64258 16.25 8.0957 16.25C9.9707 16.25 11.7285 15.625 13.1348 14.4922L18.3691 19.7266C18.5645 19.9219 18.7988 20 19.0723 20C19.3066 20 19.541 19.9219 19.6973 19.7266C20.0879 19.375 20.0879 18.7891 19.6973 18.3984ZM1.88477 8.125C1.88477 4.6875 4.6582 1.875 8.13477 1.875C11.5723 1.875 14.3848 4.6875 14.3848 8.125C14.3848 11.6016 11.5723 14.375 8.13477 14.375C4.6582 14.375 1.88477 11.6016 1.88477 8.125Z"
            fill="currentColor"
        />
    )
})

export const SearchIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SearchIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M19.559 17.3324L14.8715 12.6449C15.9262 11.0824 16.4731 9.12931 16.1996 7.01994C15.6918 3.42619 12.7621 0.535565 9.20744 0.0668147C3.89494 -0.597248 -0.597248 3.89494 0.0668147 9.20744C0.535565 12.7621 3.42619 15.6918 7.01994 16.1996C9.12931 16.4731 11.0824 15.9262 12.684 14.8715L17.3324 19.559C17.9574 20.1449 18.934 20.1449 19.559 19.559C20.1449 18.934 20.1449 17.9574 19.559 17.3324ZM3.11369 8.11369C3.11369 5.37931 5.34025 3.11369 8.11369 3.11369C10.8481 3.11369 13.1137 5.37931 13.1137 8.11369C13.1137 10.8871 10.8481 13.1137 8.11369 13.1137C5.34025 13.1137 3.11369 10.8871 3.11369 8.11369Z"
            fill="currentColor"
        />
    )
})
