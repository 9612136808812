import { Box } from '@chakra-ui/react'
import { Breadcrumb } from '@missionlabs/react'
import { AnalyticsActivities } from 'features/analytics/components/AnalyticsActivites/AnalyticsActivities'
import { AnalyticsGraphView } from 'features/analytics/components/AnalyticsGraph/AnalyticsGraphView'
import { AnalyticsPieChartTotals } from 'features/analytics/components/AnalyticsPieChartTotals/AnalyticsPieChartTotals'
import AnalyticsTabsView from 'features/analytics/components/AnalyticsTabView'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import {
    useGetSingleNumberRecentCallHistoryQuery,
    useGetSingleNumberTotalsAnalyticsQuery
} from 'shared/store'

interface Props {
    number: string
    clearNumber(): void
}

const SearchNumberView: React.FC<Props> = ({ number, clearNumber }) => {
    const { t } = useTranslation()
    const { dateScroller, group } = useAnalytics()

    const { formatToLocalNumber } = useFormatToNumberE164()

    const apiArgs = {
        from: dateScroller.start?.getTime().toString(),
        to: dateScroller.end?.getTime().toString(),
        timezone: dateScroller.timezone,
        numberE164: formatToLocalNumber(number),
        group
    }

    const {
        data: numbersAnalyticsData,
        isLoading: isLoadingAnalytics,
        isFetching: isFetchingAnalytics
    } = useGetSingleNumberTotalsAnalyticsQuery(apiArgs, {
        skip: !dateScroller.start || !dateScroller.end
    })

    const {
        data: activities,
        isFetching: isFetchingActivities,
        isLoading: isLoadingAcivities
    } = useGetSingleNumberRecentCallHistoryQuery(apiArgs)

    const isLoading =
        isFetchingActivities || isLoadingAcivities || isFetchingAnalytics || isLoadingAnalytics

    const isNoResults = !activities?.data.length && !numbersAnalyticsData?.data.length

    return (
        <SettingsPage
            isLoading={isLoading}
            title={
                <Breadcrumb
                    variant="title"
                    items={[
                        {
                            title: t('analytics.numbers'),
                            onClick: () => clearNumber(),
                            isCurrentPage: false
                        },
                        { title: number, isCurrentPage: true }
                    ]}
                >
                    Users
                </Breadcrumb>
            }
            subtitle={t('analytics.search-number-subtitle')}
            contentProps={{ padding: 0, maxW: 'unset' }}
        >
            <AnalyticsTabsView header={null} noResults={isNoResults} isLoading={isLoading}>
                <Box>
                    {numbersAnalyticsData?.total && (
                        <AnalyticsPieChartTotals
                            durations={numbersAnalyticsData.durations}
                            totals={numbersAnalyticsData.total}
                        />
                    )}
                    <AnalyticsGraphView data={numbersAnalyticsData?.data} />
                    <AnalyticsActivities activities={activities?.data ?? []} />
                </Box>
            </AnalyticsTabsView>
        </SettingsPage>
    )
}

export default SearchNumberView
