import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerOverlay,
    HStack,
    useColorMode,
    useDisclosure
} from '@chakra-ui/react'
import {
    AddMeetingParticipants,
    addMeetingParticipants,
    ParticipantType,
    ParticipantVideoStatus,
    selectActiveMeeting,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { useExternalMeetingLink } from '@missionlabs/meetings'
import {
    Body,
    Button,
    DrawerHeader,
    FormProvider,
    useMeasure,
    useYupForm,
    yup
} from '@missionlabs/react'
import { useContactsFilterParams } from 'features/contacts/hooks/useContactsFilterParams'
import { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { MeetingsCallAddDirectory } from './MeetingsCallAddDirectory'

export interface MeetingsCallAddDrawerFields {
    users: { userID: string }[]
}

const meetingsCallAddDrawerFields = yup.object({ users: yup.array() })

type MeetingsCallAddDrawerProps = {
    siblingPath?: string
}

// todo: handle guest invites
export const MeetingsCallAddDrawer: FC<MeetingsCallAddDrawerProps> = ({ siblingPath }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    const meeting = useSelector(selectActiveMeeting)
    const meetingURL = useExternalMeetingLink(meeting?.meetingID ?? '')

    const formRef = useRef<HTMLFormElement>(null)
    const [linkRef, { blockSize: linkHeight }] = useMeasure<HTMLDivElement>()

    const methods = useYupForm<MeetingsCallAddDrawerFields>(meetingsCallAddDrawerFields, {
        defaultValues: { users: [] }
    })

    const contactFiltersStorageKey = `__${meeting?.meetingID}_contact_filters`

    const { toggleType, reset, remove } = useContactsFilterParams(contactFiltersStorageKey)

    useEffect(
        function handleAddContactFilters() {
            if (isOpen) {
                reset()
                toggleType('users', true)
            }

            return () => {
                if (!isOpen) {
                    remove()
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isOpen]
    )

    function handleSubmit(data: MeetingsCallAddDrawerFields) {
        if (!meeting?.meetingID || !meeting.otherParticipants) return

        const newParticipants = data.users
            .filter(item => {
                return !meeting.otherParticipants!.some(
                    p => p.userID === item.userID && p.status !== ParticipantVideoStatus.GONE
                )
            })
            .map(item => {
                return {
                    ...item,
                    // todo
                    type: ParticipantType.INTERNAL,
                    status: ParticipantVideoStatus.PENDING,
                    accepted: false
                }
            })
        if (!newParticipants.length) return

        const payload: AddMeetingParticipants = {
            meetingID: meeting.meetingID,
            otherParticipants: newParticipants
        }
        dispatch(addMeetingParticipants(payload))
        onClose()
    }

    function handleTransition() {
        navigate(siblingPath ? `../${siblingPath}` : '..')
    }

    return (
        <FormProvider {...methods}>
            <Drawer size="sm" isOpen={isOpen} onClose={onClose} onCloseComplete={handleTransition}>
                <DrawerOverlay />
                <DrawerContent mt="64px" bg={`${colorMode}.tones.ghostWhite`}>
                    <DrawerHeader>{t('meeting.add.title')}</DrawerHeader>
                    <DrawerBody
                        ref={formRef}
                        as="form"
                        onSubmit={methods.handleSubmit(handleSubmit)}
                    >
                        <HStack
                            ref={linkRef}
                            as="section"
                            spacing="8px"
                            px="24px"
                            py="16px"
                            borderBottom="1px solid"
                            borderColor={`${colorMode}.tones.periwinkle`}
                        >
                            <Body size="sm" whiteSpace="nowrap">
                                {t('meeting.add.link', { link: meetingURL })}
                            </Body>
                            <Button
                                variant="link-underline"
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                onClick={() => navigator.clipboard.writeText(meetingURL)}
                            >
                                {meetingURL}
                            </Button>
                        </HStack>
                        <MeetingsCallAddDirectory
                            siblingHeight={linkHeight}
                            contactFiltersStorageKey={contactFiltersStorageKey}
                        />
                    </DrawerBody>
                    <DrawerFooter pb="24px" gap="24px" justifyContent="space-between">
                        <Button size="lg" variant="secondary" w="full" onClick={onClose}>
                            {t('Cancel')}
                        </Button>
                        <Button
                            size="lg"
                            variant="creationary"
                            w="full"
                            isDisabled={!methods.formState.isDirty}
                            onClick={() => formRef.current?.requestSubmit()}
                        >
                            {t('Invite')}
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </FormProvider>
    )
}
