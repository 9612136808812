import { HStack, VStack } from '@chakra-ui/react'
import { Button, Input, useFormContext, ValidatedInput } from '@missionlabs/react'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { MyDetailsFormFields } from './MyDetails'

export const ChangePassword: FC = () => {
    const { t } = useTranslation()

    const { setValue } = useFormContext<MyDetailsFormFields>()

    function cancelForm() {
        /** Calling reset causes the address component to play up */
        setValue('currentPassword', '')
        setValue('newPassword', '')
        setValue('confirmPassword', '')
    }

    return (
        <SettingsRow label={t('changePassword')}>
            <VStack w="full" align="start" spacing={4}>
                <ValidatedInput
                    name="currentPassword"
                    label={t('myDetails.currentPassword')}
                    w="300px"
                >
                    <Input
                        w="300px"
                        type="password"
                        placeholder={t('myDetails.yourCurrentPassword')}
                        data-testid="current-password"
                    />
                </ValidatedInput>
                <HStack>
                    <ValidatedInput name="newPassword" label={t('myDetails.newPassword')}>
                        <Input
                            w="300px"
                            type="password"
                            placeholder={t('myDetails.yourNewPassword')}
                            data-testid="new-password"
                        />
                    </ValidatedInput>
                    <ValidatedInput name="confirmPassword" label={t('myDetails.confirmPassword')}>
                        <Input
                            w="300px"
                            type="password"
                            placeholder={t('myDetails.confirmPassword')}
                            data-testid="confirm-password"
                        />
                    </ValidatedInput>
                </HStack>
                <Button onClick={cancelForm}>{t('Cancel')}</Button>
            </VStack>
        </SettingsRow>
    )
}
