import { AlertDialogHeader, ModalHeaderProps, useModalStyles } from '@chakra-ui/react'
import { Heading } from 'atoms'
import { FC, PropsWithChildren } from 'react'

export type DialogHeaderProps = ModalHeaderProps

export const DialogHeader: FC<PropsWithChildren<DialogHeaderProps>> = ({ children, ...props }) => {
    const { header } = useModalStyles()
    return (
        <AlertDialogHeader {...props}>
            <Heading
                size="h3"
                sx={{
                    color: header.color,
                    fontSize: header.fontSize,
                    lineHeight: header.lineHeight
                }}
            >
                {children}
            </Heading>
        </AlertDialogHeader>
    )
}
