import { Divider, useColorMode, VStack } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { FC } from 'react'
import { useSectionTitle } from 'shared/hooks/useSectionTitle'
import { Section } from 'shared/types/feed'

export interface ChatHistorySectionProps {
    section: Section
}

export const ChatHistorySection: FC<ChatHistorySectionProps> = ({ section }) => {
    const { colorMode } = useColorMode()
    const title = useSectionTitle(section)

    return (
        <VStack align="left" spacing="12px" px="24px" py="24px">
            <Body align="left" color={`${colorMode}.tones.midnightexpress`}>
                {title}
            </Body>
            <Divider w="full" borderColor={`${colorMode}.tones.periwinkle`} />
        </VStack>
    )
}
