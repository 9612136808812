import { setActiveViewChannel, useDispatch } from '@missionlabs/api'
import { useCallback } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

export type LocationState = {}

export enum UseSelectMode {
    chat = 'chat',
    message = 'chat',
    chatGroup = 'chat/group',
    contacts = 'contacts',
    contactGroups = 'contacts/group'
}

interface UseSelectReturn {
    toggle: (id: string, preserveSearch?: boolean) => void
    toggleMessage: (channelID: string, messageID: string, state?: LocationState) => void
    isSelected: (id: string, messageID?: string) => boolean
}

export const useSelect = (mode: UseSelectMode): UseSelectReturn => {
    const navigate = useNavigate()
    const match = useMatch(`/${mode}/:id/*`)
    const messageMatch = useMatch(`/${mode}/:id/:messageID`)
    const dispatch = useDispatch()

    const isSelected = useCallback(
        (id: string, messageID?: string) => {
            /** gets the :id parameter from the current path if it exits */
            if (messageID) {
                const pathMessageID = messageMatch?.params?.messageID ?? null
                const pathchannelID = messageMatch?.params?.id ?? null

                return pathMessageID === messageID && pathchannelID === id
            }
            const selectedInCurrentPath = match?.params?.id ?? null
            return selectedInCurrentPath === id
        },
        [match?.params?.id, messageMatch?.params?.id, messageMatch?.params?.messageID]
    )

    const handleNavigate = useCallback(
        (id: string | null, preserveSearch?: boolean) => {
            if (!id)
                return navigate({
                    pathname: `/${mode}`,
                    search: preserveSearch ? window.location.search : ''
                })
            return navigate({
                pathname: `/${mode}/${id}`,
                search: preserveSearch ? window.location.search : ''
            })
        },
        [mode, navigate]
    )
    const handleNavigateForMessage = useCallback(
        (id: string | null, messageID?: string) => {
            if (!id) return navigate(`/${mode}`)
            return navigate(`/${mode}/${id}/${messageID}`)
        },
        [mode, navigate]
    )

    const toggleSelect = useCallback(
        (id: string, preserveSearch?: boolean) => {
            // deselect current ID and return to mode
            if (isSelected(id)) {
                dispatch(setActiveViewChannel(null))
                return handleNavigate(null, preserveSearch)
            }
            //Set active channel in state
            dispatch(setActiveViewChannel(id))
            // redirect to incoming ID
            return handleNavigate(id, preserveSearch)
        },
        [isSelected, dispatch, handleNavigate]
    )
    const toggleSelectMessage = useCallback(
        (channelID: string, messageID: string) => {
            // deselect current ID and return to mode
            if (isSelected(channelID, messageID)) return handleNavigateForMessage(null)
            return handleNavigateForMessage(channelID, messageID)
        },
        [isSelected, handleNavigateForMessage]
    )
    return {
        toggle: toggleSelect,
        toggleMessage: toggleSelectMessage,
        isSelected
    }
}
