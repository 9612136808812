import { useSelector } from '@missionlabs/api'
import { SMSIcon, WhatsappIcon } from '@missionlabs/react/circleloop'
import { OutboundMessage, OutboundMessageType } from '@missionlabs/types'
import { FC } from 'react'
import { selectFocusedMessage } from 'shared/slices/messagingSlice'

import { DrawerMode } from '../ContactDrawer'
import { NavButton } from './NavButton'

interface MessageButtonProps {
    message: OutboundMessage
    onOpen: (mode?: DrawerMode, id?: string) => void
}

export const MessageButton: FC<MessageButtonProps> = ({ message, onOpen }) => {
    const focusedMessage = useSelector(selectFocusedMessage)

    switch (message.type) {
        case OutboundMessageType.WhatsApp:
            return (
                <NavButton
                    aria-label="whatsapp button"
                    icon={<WhatsappIcon color="inherit" boxSize="28px" />}
                    isActive={focusedMessage?.ID === message.ID}
                    onClick={() => onOpen(DrawerMode.WHATSAPP, message.ID)}
                />
            )

        case OutboundMessageType.SMS:
            return (
                <NavButton
                    aria-label="sms button"
                    icon={<SMSIcon color="inherit" boxSize="20px" />}
                    isActive={focusedMessage?.ID === message.ID}
                    onClick={() => onOpen(DrawerMode.SMS, message.ID)}
                />
            )
        default:
            return null
    }
}
