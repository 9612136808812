import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const CheckCircleIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CheckCircleIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M9.17969 12.9688C8.94531 13.2031 8.51562 13.2031 8.28125 12.9688L5.78125 10.4688C5.54688 10.2344 5.54688 9.80469 5.78125 9.57031C6.01562 9.33594 6.44531 9.33594 6.67969 9.57031L8.75 11.6406L13.2812 7.07031C13.5156 6.83594 13.9453 6.83594 14.1797 7.07031C14.4141 7.30469 14.4141 7.73438 14.1797 7.96875L9.17969 12.9688ZM20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10ZM10 1.25C5.15625 1.25 1.25 5.19531 1.25 10C1.25 14.8438 5.15625 18.75 10 18.75C14.8047 18.75 18.75 14.8438 18.75 10C18.75 5.19531 14.8047 1.25 10 1.25Z"
            fill="currentColor"
        />
    )
})

export const CheckCircleIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CheckCircleIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M9.49219 13.2812C9.0625 13.7109 8.39844 13.7109 7.96875 13.2812L5.46875 10.7812C5.03906 10.3516 5.03906 9.6875 5.46875 9.25781C5.89844 8.82812 6.5625 8.82812 6.99219 9.25781L8.75 10.9766L12.9688 6.75781C13.3984 6.32812 14.0625 6.32812 14.4922 6.75781C14.9219 7.1875 14.9219 7.85156 14.4922 8.28125L9.49219 13.2812ZM20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10ZM10 1.875C5.50781 1.875 1.875 5.54688 1.875 10C1.875 14.4922 5.50781 18.125 10 18.125C14.4531 18.125 18.125 14.4922 18.125 10C18.125 5.54688 14.4531 1.875 10 1.875Z"
            fill="currentColor"
        />
    )
})

export const CheckCircleIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CheckCircleIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10ZM14.4922 8.28125C14.9219 7.85156 14.9219 7.1875 14.4922 6.75781C14.0625 6.32812 13.3984 6.32812 12.9688 6.75781L8.75 10.9766L6.99219 9.25781C6.5625 8.82812 5.89844 8.82812 5.46875 9.25781C5.03906 9.6875 5.03906 10.3516 5.46875 10.7812L7.96875 13.2812C8.39844 13.7109 9.0625 13.7109 9.49219 13.2812L14.4922 8.28125Z"
            fill="currentColor"
        />
    )
})
