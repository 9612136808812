import { VStack } from '@chakra-ui/react'
import { Scrollable, useFieldArray } from '@missionlabs/react'
import { AttendeeDetails, DirectoryEntry } from '@missionlabs/types'
import { isValidEmail } from '@missionlabs/utils'
import { ContactsRow } from 'features/contacts/ContactsList/ContactsRow'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ShareBasicList } from './ShareBasicList'

type MeetingCreateShareFormProps = {}

export const SharedForm: FC<MeetingCreateShareFormProps> = () => {
    const { t } = useTranslation()

    const { fields: alreadyInvitedUsers } = useFieldArray<{
        alreadyInvitedUsers: AttendeeDetails[]
    }>({
        name: 'alreadyInvitedUsers'
    })

    const listItems = useMemo(() => {
        return alreadyInvitedUsers.map((item: AttendeeDetails & { fullName?: string }) => {
            const isExternal = isValidEmail(item.userID)

            item.fullName = item.name
            item.companyName = isExternal ? t('Unknown') : item.companyName

            return (
                <div role="listitem" key={item.userID}>
                    <ContactsRow
                        key={item.userID}
                        data={item as unknown as DirectoryEntry}
                        onClick={() => {}}
                    />
                </div>
            )
        })
    }, [alreadyInvitedUsers, t])

    return (
        <VStack mx="24px" height="100%" p="24px">
            <ShareBasicList>
                <Scrollable height="100%">{listItems}</Scrollable>
            </ShareBasicList>
        </VStack>
    )
}
