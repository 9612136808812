import { UserRole } from '@missionlabs/types'

export function getRole(apiRole: string) {
    switch (apiRole) {
        case 'superuser':
        case 'owner':
        case 'admin':
        case 'partner':
            return UserRole.ADMIN
        case 'standard':
        case 'portal':
            return UserRole.END_USER
        default:
            return UserRole.NONE
    }
}
