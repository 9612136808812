import { switchAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    switchAnatomy.keys
)

const baseStyle = definePartsStyle(({ colorMode }) => ({
    thumb: defineStyle({
        bg: `${colorMode}.tones.white`,
        boxSize: '12px',
        boxSizing: 'border-box',
        transform: 'translate(3px, 3px)', // (4px, 4px) - (1px, 1px) for border.
        _checked: {
            transform: 'translate(15px, 3px)' // (16px, 4px) - (1px, 1px) for border.
        },
        _disabled: {
            bg: `${colorMode}.tones.periwinkle`
        }
    }),
    track: defineStyle({
        bg: `${colorMode}.tones.periwinkle`,
        w: '30px', // 32px w/ border
        h: '18px', // 20px w/ border
        borderRadius: '12px',
        border: '1px solid',
        borderColor: `${colorMode}.tones.periwinkle`,
        padding: 0,
        _hover: {
            bg: `${colorMode}.tones.navy`,
            borderColor: `${colorMode}.tones.navy`
        },
        _focus: {
            bg: `${colorMode}.tones.navy`,
            borderColor: `${colorMode}.tones.midnightExpress`
        },
        _checked: {
            bg: `${colorMode}.alerts.aqua`,
            borderColor: `${colorMode}.alerts.aqua`,
            _hover: {
                bg: `${colorMode}.alerts.algae`,
                borderColor: `${colorMode}.alerts.algae`
            },
            _focus: {
                bg: `${colorMode}.alerts.aqua`,
                borderColor: `${colorMode}.alerts.aqua`
            }
        },
        _disabled: {
            bg: `${colorMode}.tones.white`,
            borderColor: `${colorMode}.tones.periwinkle`,
            _hover: {
                bg: `${colorMode}.tones.white`,
                borderColor: `${colorMode}.tones.periwinkle`
            },
            _checked: {
                bg: `${colorMode}.tones.white`,
                borderColor: `${colorMode}.tones.periwinkle`,
                _hover: {
                    bg: `${colorMode}.tones.white`,
                    borderColor: `${colorMode}.tones.periwinkle`
                }
            }
        }
    })
}))

const sizes = {
    sm: definePartsStyle({
        thumb: defineStyle({
            boxSize: '8px',
            transform: 'translate(3px, 3px)', // (4px, 4px) - (1px, 1px) for border.
            _checked: {
                transform: 'translate(11px, 3px)' // (12px, 4px) - (1px, 1px) for border.
            }
        }),
        track: defineStyle({
            w: '22px', // 24px w/ border
            h: '14px' // 16px w/ border
        })
    }),
    lg: defineStyle({
        thumb: defineStyle({
            boxSize: '12px',
            transform: 'translate(3px, 3px)', // (4px, 4px) - (1px, 1px) for border.
            _checked: {
                transform: 'translate(15px, 3px)' // (16px, 4px) - (1px, 1px) for border.
            }
        }),
        track: defineStyle({
            w: '30px', // 32px w/ border
            h: '18px' // 20px w/ border
        })
    })
}

export const ToggleStyles: ComponentStyleConfig = defineMultiStyleConfig({ baseStyle, sizes })
