import React, { useMemo } from 'react'
import { useCanMakeCalls } from 'shared/hooks/useCanMakeCalls'
import { useIntegrationAppInvalid } from 'shared/hooks/useIntegrationAppInvalid'

import AccountSuspendedBanner from './AccountSuspendedBanner'
import IntegrationErrorBanner from './IntegrationErrorBanner'

interface Props {}

const AppBanner = React.forwardRef<HTMLDivElement, Props>((_, ref) => {
    const hasCalling = useCanMakeCalls()
    const hasInvalidIntegration = useIntegrationAppInvalid()

    const Banner = useMemo(() => {
        if (!hasCalling) return AccountSuspendedBanner
        if (hasInvalidIntegration) return IntegrationErrorBanner
    }, [hasCalling, hasInvalidIntegration])

    if (!Banner) return null

    return (
        <div ref={ref}>
            <Banner />
        </div>
    )
})

export default AppBanner
