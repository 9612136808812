import { usePromisedDebouncedFunction } from '@missionlabs/react'
import { Channel } from '@missionlabs/types'
import { useCallback, useState } from 'react'
import { useGetSearchChannelsMutation } from 'shared/store'

type useMessagesSearchReturn = [
    UseMessagesSearchType,
    { data: Channel[]; isLoading: boolean; isError: boolean }
]

type UseMessagesSearchType = (searchTerm: string) => Promise<void>

export const useChatChannelsSearch = (): useMessagesSearchReturn => {
    const [search, { isLoading, isError }] = useGetSearchChannelsMutation()

    const [channels, setChannels] = useState<Channel[]>([])

    const _searchChannels: UseMessagesSearchType = useCallback(
        async searchTerm => {
            if (!searchTerm) return setChannels([])
            try {
                setChannels(await search(searchTerm).unwrap())
            } catch {
                setChannels([])
            }
        },
        [setChannels, search]
    )

    const searchChannels = usePromisedDebouncedFunction(_searchChannels, 200)

    return [searchChannels, { data: channels, isLoading, isError }]
}
