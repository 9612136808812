import { Country, Option, Timezone } from '@missionlabs/types'

export function getSelectedOptionLabel(options: Option[], selectedOption?: string) {
    return options.find(o => o.value === selectedOption)?.label
}

export function localeToOption(locale: Country): Option {
    return { label: locale.name, value: locale.country ?? '' }
}

function sanitizeTimezoneText(value: string): string {
    return value.replace('_', ' ')
}

export function timezoneToOption(timezone: Timezone): Option {
    return {
        label: `${timezone.utcOffset} ${sanitizeTimezoneText(timezone.timezone)}`,
        value: timezone.timezone
    }
}

export function stringToOption(str: string): Option {
    return {
        label: str,
        value: str
    }
}
