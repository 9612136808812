import { VStack } from '@chakra-ui/react'
import { NumberPortability } from '@missionlabs/types'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import InputPortingNumber from './InputPortingNumber'
import ShowPortingForm from './ShowPortingForm'

interface PortNumberFormProps {
    onClose: () => void
}

type PortingState = {
    number: string
} & NumberPortability

const PortNumberForm: FC<PortNumberFormProps> = ({ onClose }) => {
    const [portabilityState, setPortabilityState] = useState<Partial<PortingState>>({})

    const navigate = useNavigate()

    const onReset = () => {
        setPortabilityState({})
    }
    const toMirror = () => {
        navigate('/admin/numbers/existing/mirror')
    }

    return (
        <VStack h="full" align="stretch" justify="space-between" display="flex">
            {portabilityState?.number && portabilityState?.portable ? (
                <ShowPortingForm
                    onReset={onReset}
                    portability={portabilityState}
                    toMirror={toMirror}
                />
            ) : (
                <InputPortingNumber onClose={onClose} setPortabilityState={setPortabilityState} />
            )}
        </VStack>
    )
}

export default PortNumberForm
