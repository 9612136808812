import { ListTitle } from '@missionlabs/react'
import { DirectoryEntry, HuntGroup, IVRMenu } from '@missionlabs/types'
import { ContactsRow } from 'features/contacts/ContactsList/ContactsRow'
import { CSSProperties } from 'react'

import { useAddNumberToContactContext } from './context'

interface AddNumberToContactRendererProps {
    data: (string | DirectoryEntry | HuntGroup | IVRMenu)[]
    index: number
    style: CSSProperties
}

function isTitle(item: string | DirectoryEntry | HuntGroup | IVRMenu): item is string {
    return typeof item === 'string'
}

export const AddNumberToContactRenderer = ({
    data,
    index,
    style
}: AddNumberToContactRendererProps) => {
    const { selectedContact, setSelectedContact } = useAddNumberToContactContext()
    const item = data[index]

    const handleClick = () => {
        if (typeof item === 'string' || typeof item === 'undefined') return
        if (selectedContact?.ID === item.ID) {
            setSelectedContact(undefined)
        } else {
            setSelectedContact(item as DirectoryEntry)
        }
    }

    if (isTitle(item)) {
        return (
            <div style={style} role="listitem">
                <ListTitle sx={{ paddingLeft: 0, paddingRight: 0 }}>{item}</ListTitle>
            </div>
        )
    }

    return (
        <div style={{ ...style }} role="listitem">
            <ContactsRow
                key={item.ID}
                data={item}
                isActive={selectedContact?.ID === item.ID}
                onClick={handleClick}
                renderTick
            />
        </div>
    )
}
