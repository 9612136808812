import * as Jabra from '@gnaudio/jabra-js'
import {
    addCall,
    answer,
    CallState,
    hangup,
    liveServicesConnect,
    LiveServicesState,
    MeetingState,
    removeCall,
    selectConnectedCall,
    selectIncomingCalls,
    sendDTMF,
    SubscriptionState,
    toggleMute,
    updateCall,
    UserState
} from '@missionlabs/api'
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'

import { devicePaired, init as jabraInit, webHIDConsent } from '../slices/jabraSlice'

type RootState = {
    meetingSlice: MeetingState
    liveServicesSlice: LiveServicesState
    userSlice: UserState
    subscriptionSlice: SubscriptionState
    callSlice: CallState
}

export const jabraMiddleware = createListenerMiddleware<RootState>()

let jabraSdk: Jabra.IApi
let deviceCallControl: Jabra.ICallControl | undefined
let signalSubscription: any
let muteState = false
let gotLock = false

/**
 * Load any previously connected devices when
 * the app loads or Jabra has initialised
 */
jabraMiddleware.startListening({
    matcher: isAnyOf(liveServicesConnect, jabraInit),
    effect: async (_action, { dispatch }) => {
        try {
            jabraSdk = await Jabra.init({
                transport: Jabra.RequestedBrowserTransport.WEB_HID,
                partnerKey: '6f3c-aacb0337-a54f-4ee9-ad84-f7fcbdeeff28',
                appId: 'circleloop',
                appName: 'CircleLoop'
            })

            const callControlFactory = new Jabra.CallControlFactory(jabraSdk)

            jabraSdk.deviceAdded.subscribe(async device => {
                if (!callControlFactory.supportsCallControl(device)) return
                deviceCallControl = await callControlFactory.createCallControl(device)
                dispatch(devicePaired(device.name))
            })

            jabraSdk.deviceRemoved.subscribe(() => {
                dispatch(devicePaired())
            })
        } catch (error) {
            console.log(error)
        }
    }
})

jabraMiddleware.startListening({
    actionCreator: webHIDConsent,
    effect: async (_action, { dispatch }) => {
        await Jabra.webHidPairing()
        dispatch(jabraInit)
    }
})

/**
 * Listen for events coming from the Jabra device
 * and call the relevant LiveServices actions
 */
jabraMiddleware.startListening({
    actionCreator: addCall,
    effect: async (_action, { dispatch, getState }) => {
        try {
            if (!deviceCallControl) return

            gotLock = await deviceCallControl.takeCallLock()
            if (!gotLock) return

            deviceCallControl.ring(true)

            signalSubscription = deviceCallControl.deviceSignals.subscribe(({ type, value }) => {
                const incomingCalls = selectIncomingCalls(getState())
                const connectedCall = selectConnectedCall(getState())

                const offHook = type === 32 && value
                const onHook = type === 32 && !value
                const muteSignal = type === 47

                const dtmfSignals = {
                    176: '0',
                    177: '1',
                    178: '2',
                    179: '3',
                    180: '4',
                    181: '5',
                    182: '6',
                    183: '7',
                    184: '8',
                    185: '9',
                    186: '*',
                    187: '#'
                }

                if (offHook && incomingCalls.length > 0) {
                    const callToAnswer = incomingCalls[incomingCalls.length - 1]
                    dispatch(answer({ callTraceID: callToAnswer.callTraceID }))
                } else if (connectedCall) {
                    const { callTraceID } = connectedCall
                    const isDtmfSignal = Object.keys(dtmfSignals).includes(type.toString())
                    if (isDtmfSignal) {
                        dispatch(sendDTMF({ callTraceID, dtmfKey: dtmfSignals[type] }))
                    } else if (onHook) {
                        dispatch(hangup({ callTraceID }))
                    } else if (muteSignal) {
                        dispatch(toggleMute({ callTraceID, enabled: muteState }))
                    }
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
})

jabraMiddleware.startListening({
    actionCreator: toggleMute,
    effect: action => {
        try {
            if (deviceCallControl) {
                const muteAudio = !action.payload.enabled
                muteState = muteAudio
                deviceCallControl.mute(muteAudio)
            }
        } catch (error) {
            console.log(error)
        }
    }
})

/**
 * Check the startTimestamp has been created
 * as this tells us the call has been answered
 */
jabraMiddleware.startListening({
    predicate: (action, newState, oldState) => {
        const isUpdateCallAction = isAnyOf(updateCall)(action)
        if (!isUpdateCallAction) return false
        const oldConnectedCall = selectConnectedCall(oldState)
        const newConnectedCall = selectConnectedCall(newState)
        return (
            typeof oldConnectedCall?.startTimestamp === 'undefined' &&
            newConnectedCall?.startTimestamp !== oldConnectedCall?.startTimestamp
        )
    },
    effect: () => {
        try {
            if (gotLock && deviceCallControl) {
                deviceCallControl.ring(false)
                deviceCallControl.mute(muteState)
                deviceCallControl.offHook(true)
            }
        } catch (error) {
            console.log(error)
        }
    }
})

jabraMiddleware.startListening({
    actionCreator: removeCall,
    effect: () => {
        try {
            if (gotLock && deviceCallControl) {
                deviceCallControl.offHook(false)
                deviceCallControl.ring(false)
                deviceCallControl.releaseCallLock()
                signalSubscription.unsubscribe()
            }
        } catch (error) {
            console.log(error)
        }
    }
})
