import { useGetClientQuery } from 'shared/store'

export enum Features {
    advanced_routing = 'advanced_routing',
    backup_number = 'backup_number',
    band10_calling = 'band10_calling',
    band2_calling = 'band2_calling',
    band5_calling = 'band5_calling',
    band7_calling = 'band7_calling',
    call_pull = 'call_pull',
    chat = 'chat',
    direct_debit = 'direct_debit',
    emergency_calls = 'emergency_calls',
    free_calls_band0 = 'free_calls_band0',
    free_calls_band1 = 'free_calls_band1',
    free_calls_band7 = 'free_calls_band7',
    free_calls_standard = 'free_calls_standard',
    inbound_calling = 'inbound_calling',
    integrations = 'integrations',
    international_calling = 'international_calling',
    international_premium_calling = 'international_premium_calling',
    meetings = 'meetings',
    menus = 'menus',
    minor_networks = 'minor_networks',
    number_addons = 'number_addons',
    number_alpha_tagging = 'number_alpha_tagging',
    nway_calling = 'nway_calling',
    outbound_calling = 'outbound_calling',
    sms = 'sms',
    teams = 'teams',
    user_extensions = 'user_extensions',
    users = 'users',
    vm_transcription = 'vm_transcription',
    whatsapp = 'whatsapp',
    withhold_number = 'withhold_number'
}

export const useHasClientFeature = (featureList: Features | Features[]) => {
    const features = [featureList].flat()

    const { data: client } = useGetClientQuery()

    if (!client) return false

    if (features.some(feature => client.blockedFeatures?.includes(feature))) {
        return false
    }

    return features.every(feature => client.currentPlan?.features.includes(feature) ?? false)
}
