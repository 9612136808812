import { Call } from '@missionlabs/api'
import { Body } from '@missionlabs/react'
import { DecoratedCall } from '@missionlabs/types'
import { format } from 'date-fns'
import { TFunction } from 'i18next'
import { ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFormatToNumberE164 } from './useFormatToNumberE164'

export type CallSource = Call | DecoratedCall | undefined

export const isDecoratedCall = (call: CallSource): call is DecoratedCall => {
    return !!call && !('status' in call)
}

export const getNameFromCall = (call: CallSource, format: Function, t: TFunction) => {
    if (!call) return t('details.unknown')
    if (isDecoratedCall(call)) return call.displayName ?? format(call.originatingNumber)

    if (call.isTransfer && call.direction === 'inbound') {
        if (call.transfereeNumber === 'anonymous') return t('details.anonymous')
        if (call.transfereeNumber === 'unavailable') return t('details.unavailable')
        return call.transfereeContact?.fullName ?? format(call.transfereeNumber)
    }

    if (call.remoteNumber === 'anonymous') return t('details.anonymous')
    if (call.remoteNumber === 'unavailable') return t('details.unavailable')

    return call.displayName ?? call.contactName ?? format(call.remoteNumber)
}

export interface UseCallDetailsReturn {
    contactName: string
    number: string
    company?: string
    team: string | undefined
    transferer: ReactNode | undefined
    startTime: string | undefined
    isWithheld: boolean
    isUnknown: boolean
    localNumber?: string
}

export const useCallDetails = (call: CallSource): UseCallDetailsReturn => {
    const { t } = useTranslation()
    const { formatToLocalNumber } = useFormatToNumberE164()
    const isWithheld = !isDecoratedCall(call) && call?.remoteNumber === 'anonymous'
    const isUnknown = !isDecoratedCall(call) && call?.remoteNumber === 'unavailable'

    function getNumber() {
        if (!call) return t('details.unknown')
        if (isUnknown) return t('details.unavailable')
        if (isWithheld) return t('details.anonymous')
        if (isDecoratedCall(call)) {
            return call.displayName ?? formatToLocalNumber(call.originatingNumber)
        }

        return formatToLocalNumber(call.remoteNumber)
    }

    function getCompany() {
        if (call && !isDecoratedCall(call) && call.isTransfer && call.direction === 'inbound') {
            return call.transfereeContact?.companyName
        }
        return call?.companyName
    }

    function getTeam() {
        if (!call) return
        if (!isDecoratedCall(call) && call.pageGroupName) return call.pageGroupName
        if (!isDecoratedCall(call) && call.userGroupName) return call.userGroupName
        if (call.teamName) return call.teamName
    }

    function getTransferer() {
        if (!call || isDecoratedCall(call) || !call.isTransfer) return
        return (
            <Trans
                i18nKey={`call.transfer.${call.transferType}`}
                components={{
                    bold: <Body as="span" size="sm" variant="bold" sx={{ color: 'inherit' }} />
                }}
                values={{ name: call.transfererContactName }}
            />
        )
    }

    function getStartTime() {
        if (!call) return
        if (!('startTimestamp' in call)) return
        if (!call.startTimestamp) return

        return format(call.startTimestamp, 'HH:mm')
    }

    return {
        contactName: getNameFromCall(call, formatToLocalNumber, t),
        number: getNumber(),
        localNumber: formatToLocalNumber(
            isDecoratedCall(call) ? call.dialledNumber : call?.localNumber
        ),
        company: getCompany(),
        team: getTeam(),
        transferer: getTransferer(),
        startTime: getStartTime(),
        isWithheld,
        isUnknown
    }
}
