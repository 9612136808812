import { useParticipantsData } from '@missionlabs/meetings'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MAX_TILES } from './useVideoCall'

export const useFeedLayoutOptions = () => {
    const { t } = useTranslation()

    const { joinedParticipants: participants } = useParticipantsData()

    const dynamicOptions = useMemo(() => {
        return Array.from({ length: Math.min(MAX_TILES, participants.length) }, (_, index) => ({
            value: index + 1,
            label: t('tileCount', { count: index + 1 })
        }))
    }, [participants.length, t])

    return [{ value: 0, label: t('Automatic') }, ...dynamicOptions]
}
