import {
    useFormatToNumberE164 as _useFormatToNumberE164,
    UseFormatToNumberE164Values
} from '@missionlabs/react'
import { useAuthenticatedUser } from 'shared/hooks/useAuthenticatedUser'
import { useGetClientQuery } from 'shared/store'

export const useFormatToNumberE164 = (): UseFormatToNumberE164Values => {
    const { data: client } = useGetClientQuery()
    const { data: user } = useAuthenticatedUser()

    const locale = user?.locale ?? client?.locale ?? 'GB'

    return _useFormatToNumberE164(locale.toUpperCase())
}
