import { useColorMode } from '@chakra-ui/react'
import { CallStatus } from '@missionlabs/browser-calling'
import { IconButton, Tooltip } from '@missionlabs/react'
import { HoldCallIcon, HoldOffOutlineIcon } from '@missionlabs/react/circleloop'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface HoldButtonProps {
    status: CallStatus
    onToggle: () => void
    isDisabled: boolean
}

export const HoldButton: FC<HoldButtonProps> = ({ status, onToggle, isDisabled }) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    const isOnHold = status === 'ON_HOLD'

    return (
        <Tooltip label={isOnHold ? t('Unhold') : t('Hold')}>
            <IconButton
                icon={
                    isOnHold ? (
                        <HoldOffOutlineIcon
                            boxSize="16px"
                            color={`${colorMode}.tones.periwinkle`}
                        />
                    ) : (
                        <HoldCallIcon boxSize="16px" color={`${colorMode}.tones.periwinkle`} />
                    )
                }
                variant="dialler"
                boxSize="48px"
                isActive={isOnHold}
                flex={1}
                isDisabled={isDisabled}
                aria-label="hold"
                onClick={onToggle}
            />
        </Tooltip>
    )
}
