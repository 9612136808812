import { Button, Spinner, Table } from '@missionlabs/react'
import { CreditNote } from '@missionlabs/types'
import { downloadURL, formatCurrency, titleCase } from '@missionlabs/utils'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGenerateCreditNotePDFMutation, useGetCreditNotesQuery } from 'shared/store'

const columnHelper = createColumnHelper<CreditNote>()

export const BillingCreditNotesTable: FC = () => {
    const { t } = useTranslation()

    const { data = [], isLoading } = useGetCreditNotesQuery()
    const [generatePDF, { isLoading: downloadLoading, originalArgs }] =
        useGenerateCreditNotePDFMutation()

    async function handleDownload(id: string) {
        const pdf = await generatePDF(id).unwrap()
        if (pdf) downloadURL(pdf)
    }

    const columns = [
        columnHelper.accessor('date', {
            cell: info => format(info.getValue() * 1000, 'dd MMM yyyy'),
            header: t('Date')
        }),
        columnHelper.accessor('creditID', {
            cell: info => info.getValue(),
            header: t('Reference')
        }),
        columnHelper.accessor('total', {
            cell: info => {
                const amount = info.getValue() / 100
                const currency = info.row.original.currencyCode
                return formatCurrency({ currency, amount })
            },
            header: t('Cost')
        }),
        columnHelper.accessor('status', {
            cell: info => titleCase(info.getValue()).replaceAll('_', ' '),
            header: t('Status')
        }),
        columnHelper.accessor('creditID', {
            cell: info => (
                <Button
                    variant="secondary"
                    size="sm"
                    fontWeight="500"
                    isLoading={downloadLoading && originalArgs === info.getValue()}
                    onClick={() => handleDownload(info.getValue())}
                    data-testid={`download-credit-note-${info.getValue()}`}
                >
                    {t('Download')}
                </Button>
            ),
            id: 'download',
            header: '',
            meta: { align: 'end' }
        })
    ]

    if (isLoading) return <Spinner size="sm" />

    return <Table data={data} columns={columns} paginate />
}
