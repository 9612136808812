import { ComponentStyleConfig, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
    'name',
    'role',
    'wrapper',
    'container'
])

const baseStyle = definePartsStyle(({ colorMode }) => ({
    name: defineStyle({ color: `${colorMode}.tones.midnightExpress`, textAlign: 'left' }),
    role: defineStyle({ color: `${colorMode}.tones.stormGrey`, textAlign: 'left' }),
    wrapper: defineStyle({ width: 'fit-content', flexDirection: 'row', alignItems: 'flex-start' }),
    container: defineStyle({ marginLeft: '16px' })
}))

const sizes = {
    sm: definePartsStyle({
        container: defineStyle({ marginLeft: '8px' })
    })
}

const variants = {
    horizontal: definePartsStyle({
        name: defineStyle({ textAlign: 'left' }),
        role: defineStyle({ textAlign: 'left' }),
        wrapper: defineStyle({ flexDirection: 'row', alignItems: 'flex-start' })
    }),
    vertical: definePartsStyle({
        name: defineStyle({ textAlign: 'center' }),
        role: defineStyle({ textAlign: 'center' }),
        container: defineStyle({ marginLeft: '0', marginTop: '8px' }),
        wrapper: defineStyle({ flexDirection: 'column', alignItems: 'center' })
    }),
    'horizontal-dark': definePartsStyle({
        name: defineStyle({ textAlign: 'left', color: 'white' }),
        role: defineStyle({ textAlign: 'left', color: 'white' }),
        wrapper: defineStyle({ flexDirection: 'row', alignItems: 'flex-start' })
    })
}

export const ContactDetailStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle,
    sizes,
    variants
})
