import { call, selectPresentationNumber, useDispatch, useSelector } from '@missionlabs/api'
import { useFormatToNumberE164 } from '@missionlabs/react'
import { AddIcon } from '@missionlabs/react/circleloop'
import { ContactBookIconRegular } from '@missionlabs/react/zeta'
import { ContactPhoneNumber, DirectoryEntry } from '@missionlabs/types'
import { Action, useKBar, useRegisterActions } from 'kbar'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useContactsSearch } from 'shared/hooks/useContactsSearch'
import { useGetUserData } from 'shared/hooks/useGetUserData'

import { commandMenuShortcuts } from './shortcuts'

export function useContactsActions() {
    const navigate = useNavigate()

    const contactsActions = useMemo(() => {
        return [
            {
                id: 'contacts',
                name: 'Search Contacts...',
                shortcut: commandMenuShortcuts.searchContacts,
                keywords: 'search contacts',
                icon: <ContactBookIconRegular />,
                section: 'Contacts'
            },
            {
                id: 'contacts-search-placeholder',
                name: 'Start typing to search for contacts',
                parent: 'contacts'
                // priority: -1
            },
            {
                id: 'add-contact',
                name: 'Add New Contact...',
                keywords: 'add contact',
                icon: <AddIcon />,
                section: 'Contacts',
                perform: () => navigate('/contacts/add')
            }
        ]
    }, [navigate])

    useRegisterActions(contactsActions)
}

/**
 * Registers command menu actions for contact search results
 * so that they can be viewed and called.
 */
export function useContactsSearchActions() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { currentRootActionId, searchQuery } = useKBar(state => state)
    const [searchContacts, { data: contacts }] = useContactsSearch()
    const { formatToNumberE164 } = useFormatToNumberE164()

    const { callRoute } = useGetUserData()

    const presentationNumber = useSelector(selectPresentationNumber)
    const fromNumber = useMemo(
        () => presentationNumber ?? callRoute?.defaultPhoneNumber ?? '',
        [presentationNumber, callRoute?.defaultPhoneNumber]
    )

    const searchParams = useMemo(() => ({ searchTerm: searchQuery.trim() }), [searchQuery])

    const onViewContact = useCallback(
        (contact: DirectoryEntry) => navigate(`/contacts/${contact.ID}`),
        [navigate]
    )

    const onEditContact = useCallback(
        (contact: DirectoryEntry) => navigate(`/contacts/${contact.ID}/edit`),
        [navigate]
    )

    const onCallContact = useCallback(
        (contact: DirectoryEntry, numberE164: string) => {
            dispatch(call({ to: formatToNumberE164(numberE164), from: fromNumber, contact }))
        },
        [dispatch, fromNumber, formatToNumberE164]
    )

    useEffect(
        /** When we're on the contacts page and the search value changes, search the contacts again */
        function loadSearchResultsOnContactsPage() {
            if (currentRootActionId !== 'contacts') return
            if (!searchParams.searchTerm) return
            searchContacts(searchParams)
        },
        [currentRootActionId, searchParams, searchContacts]
    )

    /**
     *  For each contact, create an action for them,
     *  then sub-actions to view them, edit them and call each of their numbers
     */
    const searchContactActions: Action[] = useMemo(() => {
        if (!currentRootActionId?.startsWith('contacts')) return []
        const actions: Action[] = []

        for (const contact of contacts) {
            actions.push(
                mainContactAction(contact),
                viewContactAction(contact, () => onViewContact(contact)),
                editContactAction(contact, () => onEditContact(contact))
            )

            const { phoneNumbers = [] } = contact
            for (const num of phoneNumbers) {
                actions.push(
                    callContactAction(contact, num, () => onCallContact(contact, num.numberE164))
                )
            }
        }

        return actions
    }, [currentRootActionId, contacts, onViewContact, onEditContact, onCallContact])

    useRegisterActions(searchContactActions, [currentRootActionId, contacts])
}

function mainContactAction(contact: DirectoryEntry): Action {
    const { ID, fullName = 'Unknown', companyName } = contact
    return {
        id: `contacts-${ID}`,
        name: fullName,
        subtitle: companyName,
        parent: 'contacts'
    }
}

function viewContactAction(contact: DirectoryEntry, callback: () => void): Action {
    const { ID } = contact
    return {
        id: `contacts-${ID}-view`,
        name: 'View contact',
        parent: `contacts-${ID}`,
        perform: callback
    }
}

function editContactAction(contact: DirectoryEntry, callback: () => void): Action {
    const { ID } = contact
    return {
        id: `contacts-${ID}-edit`,
        name: 'Edit contact',
        parent: `contacts-${ID}`,
        perform: callback
    }
}

function callContactAction(
    contact: DirectoryEntry,
    number: ContactPhoneNumber,
    callback: () => void
): Action {
    const { ID } = contact
    const { numberE164, label } = number

    let name: string = `Call ${numberE164}`
    if (label?.trim()) {
        name += ` (${label})`
    }

    return {
        id: `contacts-${ID}-call-${numberE164}`,
        name,
        parent: `contacts-${ID}`,
        perform: callback
    }
}
