import { Buffer } from 'buffer'

export const encryptData = async (toEncrypt: Object) => {
    const encoder = new TextEncoder()
    const text = JSON.stringify(toEncrypt)
    const importKey = process.env.IMPORT_ENC_KEY?.replaceAll('-', '')
    const encodedData = encoder.encode(text)
    const encodedKey = encoder.encode(importKey)

    const algorithm = {
        name: 'AES-GCM',
        length: 256,
        // 96 bit array of random numbers:
        // https://developer.mozilla.org/en-US/docs/Web/API/AesGcmParams#iv
        iv: window.crypto.getRandomValues(new Uint8Array(12))
    }

    const key = await window.crypto.subtle.importKey('raw', encodedKey, algorithm, true, [
        'encrypt',
        'decrypt'
    ])

    const encrypted = await window.crypto.subtle.encrypt(algorithm, key, encodedData)

    const data = Buffer.from(encrypted).toString('base64')
    const iv = Buffer.from(algorithm.iv).toString('base64')

    return { data, iv }
}
