import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const LinkIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'LinkIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M9.52099 11.692L4.24252 16.9704C3.46148 17.7515 3.46148 19.0178 4.24252 19.7989C5.02357 20.5799 6.2899 20.5799 7.07095 19.7989L12.3494 14.5204C12.7058 15.8549 12.3605 17.3377 11.3136 18.3847L8.48517 21.2131C6.92307 22.7752 4.39041 22.7752 2.82831 21.2131C1.26621 19.651 1.26621 17.1183 2.82831 15.5562L5.65674 12.7278C6.70366 11.6809 8.18652 11.3356 9.52099 11.692Z"
                fill="currentColor"
            />
            <path
                d="M18.3847 11.3136C17.3377 12.3605 15.8549 12.7058 14.5204 12.3494L19.7989 7.07095C20.5799 6.2899 20.5799 5.02357 19.7989 4.24252C19.0178 3.46148 17.7515 3.46148 16.9704 4.24252L11.692 9.52099C11.3356 8.18652 11.6809 6.70366 12.7278 5.65674L15.5562 2.82831C17.1183 1.26621 19.651 1.26621 21.2131 2.82831C22.7752 4.39041 22.7752 6.92307 21.2131 8.48517L18.3847 11.3136Z"
                fill="currentColor"
            />
            <path
                d="M8.48517 14.142C8.09464 14.5325 8.09464 15.1657 8.48517 15.5562C8.87569 15.9468 9.50885 15.9468 9.89938 15.5562L15.5562 9.89938C15.9468 9.50885 15.9468 8.87569 15.5562 8.48517C15.1657 8.09464 14.5325 8.09464 14.142 8.48517L8.48517 14.142Z"
                fill="currentColor"
            />
        </g>
    )
})
