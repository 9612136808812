import { Call } from '@missionlabs/api'
import { useCallback, useState } from 'react'

/**
 * When there's a call happening on another device,
 * we will get metadata from the presence subscription which will contain the call details.
 */
export type OtherDeviceCallDetails = Omit<Call, 'startTimestamp'> & {
    displayDestinationID?: string
    displayDestinationName?: string
    displayDestinationType?: string
    answeredTime?: number
    callID?: string
}

type DialState =
    | { view: 'dialpad' }
    | { view: 'calls' }
    | { view: 'cancelledCall'; call: Call }
    | { view: 'inCallOnOtherDevice'; callDetails?: OtherDeviceCallDetails }

export type UseDialStateReturn = DialState & {
    setDialState: (state: DialState) => void
    showDialpad: () => void
    showCalls: () => void
    showCancelledCall: (call: Call) => void
    showInCallOnOtherDevice: () => void
}

/** Manages the state of the dialler, switching between dialpad, calls and cancelled call views */
export const useDialState = (): UseDialStateReturn => {
    const [state, setState] = useState<DialState>({ view: 'dialpad' })

    const showDialpad = useCallback(() => setState({ view: 'dialpad' }), [])
    const showCalls = useCallback(() => setState({ view: 'calls' }), [])
    const showCancelledCall = useCallback(
        (call: Call) => setState({ view: 'cancelledCall', call }),
        []
    )
    const showInCallOnOtherDevice = useCallback((callDetails?: OtherDeviceCallDetails) => {
        setState({ view: 'inCallOnOtherDevice', callDetails })
    }, [])

    return {
        ...state,
        setDialState: setState,
        showDialpad,
        showCalls,
        showCancelledCall,
        showInCallOnOtherDevice
    }
}
