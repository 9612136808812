import { InfoOutlineIcon } from '@chakra-ui/icons'
import { HStack, RadioGroup, useColorMode, useMultiStyleConfig, VStack } from '@chakra-ui/react'
import { Body, Label, Radio, Tooltip, useFormContext } from '@missionlabs/react'
import { OutOfHoursForwardType } from '@missionlabs/types'
import { useTranslation } from 'react-i18next'

import { ReorderRadio } from './routingOptions/ReorderRadio'

export function TeamRoutingSettings() {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const styles = useMultiStyleConfig('Radio', { variant: colorMode })

    const { setValue, watch } = useFormContext()

    const callSequence = watch('callRoute.callSequence')

    return (
        <>
            <VStack spacing="10px" align="inherit">
                <Body variant="bold">{t('advancedRouting.routingSettings')}</Body>
                <RadioGroup
                    value={callSequence}
                    onChange={(value: OutOfHoursForwardType) => {
                        setValue('callRoute.callSequence', value, {
                            shouldDirty: true
                        })
                    }}
                >
                    <Radio value="group" py="4px" display="flex" alignItems="center">
                        <HStack>
                            <Label
                                textProps={{ color: styles.label.color as string }}
                                text={t('advancedRouting.ringEveryone')}
                            />
                            <Tooltip label={t('advancedRouting.ringEveryone_helper')}>
                                <InfoOutlineIcon />
                            </Tooltip>
                        </HStack>
                    </Radio>
                    <ReorderRadio
                        value="linear"
                        label={t('advancedRouting.linear')}
                        tooltipLabel={t('advancedRouting.linear_helper')}
                    />
                    <ReorderRadio
                        value="round_robin"
                        label={t('advancedRouting.roundRobin')}
                        tooltipLabel={t('advancedRouting.roundRobin_helper')}
                    />
                </RadioGroup>
            </VStack>
        </>
    )
}
