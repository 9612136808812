import { ComponentStyleConfig } from '@chakra-ui/react'

export const NotificationStyles: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        container: {
            width: '375px',
            background: 'white',
            borderRadius: '4px',
            boxShadow: 'sm'
        },
        header: {
            padding: '0px 16px 0px 0px',
            background: `${colorMode}.tones.whiteLilac`,
            borderBottom: '1px solid',
            borderTopRadius: '4px',
            color: `${colorMode}.tones.midnightExpress`,
            justifyContent: 'space-between',
            icon: { color: `${colorMode}.tones.stormGrey` }
        },
        main: {
            padding: '16px',
            gap: '8px',
            borderBottomRadius: '4px',
            alignItems: 'start',
            justifyContent: 'space-between'
        },
        actions: {
            maxWidth: '72px',
            flexWrap: 'wrap',
            gap: '8px',
            justifyContent: 'end'
        }
    }),
    variants: {
        call: ({ colorMode }) => ({
            header: { borderColor: `${colorMode}.alerts.aqua` }
        }),
        chat: ({ colorMode }) => ({
            header: { borderColor: `${colorMode}.tones.periwinkle` }
        }),
        meetings: ({ colorMode }) => ({
            header: { borderColor: `${colorMode}.primary.gamma` }
        }),
        reminder: ({ colorMode }) => ({
            header: { borderColor: `${colorMode}.alerts.rose` }
        }),
        voicemail: ({ colorMode }) => ({
            header: { borderColor: `${colorMode}.alerts.selectiveYellow` }
        })
    }
}
