import { MenuDivider } from '@chakra-ui/react'
import { selectActiveMeeting, selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@missionlabs/react'
import { DotsVerticalIconRegular, UserCircleIconRegular } from '@missionlabs/react/zeta'
import { openURL } from '@missionlabs/utils'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

export interface MeetingsCallParticipantsMenuProps {
    chimeAttendeeID: string
    externalUserId?: string
}

export const MeetingsCallParticipantsMenu: FC<MeetingsCallParticipantsMenuProps> = ({
    chimeAttendeeID,
    externalUserId
}) => {
    const navigate = useNavigate()
    const { id = '' } = useParams()
    const { t } = useTranslation()

    const meeting = useSelector(selectActiveMeeting)
    const user = useSelector(selectAuthenticatedUser)

    const participant = useMemo(() => {
        if (!meeting || !externalUserId) return
        // If the chimeAttendeeID matches, we have the participant
        return meeting?.otherParticipants?.find(p => p.userID === externalUserId)
    }, [externalUserId, meeting])

    const userCanKickParticipant = useMemo(() => {
        // Guests can't kick anyone
        // Internal users can kick guests, but not other internal users
        return !user?.isGuest && participant?.type === 'GUEST'
    }, [user, participant])

    function handleKick() {
        if (!userCanKickParticipant) return
        navigate(`/meetings/${id}/remove/${chimeAttendeeID}`)
    }

    function viewContact() {
        if (!participant?.contactID) return
        openURL(`/contacts/${participant.contactID}`)
    }

    // There's no options that guests can perform
    if (user?.isGuest) return null

    // If there's going to be no options, no point rendering a menu
    if (!participant?.contactID && !userCanKickParticipant) return null

    return (
        <Menu>
            <MenuButton as={IconButton} size="sm" variant="transparent" aria-label="More">
                <DotsVerticalIconRegular />
            </MenuButton>
            <MenuList>
                {participant?.contactID && (
                    <MenuItem icon={<UserCircleIconRegular />} onClick={viewContact}>
                        {t('View contact')}
                    </MenuItem>
                )}
                <MenuDivider />
                <MenuItem
                    variant="destructive"
                    icon={<UserCircleIconRegular />}
                    isDisabled={!userCanKickParticipant}
                    onClick={handleKick}
                >
                    {t('Kick from meeting')}
                </MenuItem>
            </MenuList>
        </Menu>
    )
}
