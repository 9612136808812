import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const CheckSquareIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CheckSquareIconLight',
    viewBox: '0 0 16 16',
    path: (
        <g>
            <path
                d="M12.4821 5.4362C12.7229 5.17 12.7024 4.75895 12.4362 4.5181C12.17 4.27726 11.759 4.29781 11.5181 4.56401L6.57153 10.0313L4.4821 7.7219C4.24125 7.4557 3.83021 7.43515 3.56401 7.676C3.29781 7.91685 3.27726 8.32789 3.5181 8.59409L6.08953 11.4362C6.21276 11.5724 6.38786 11.6501 6.57153 11.6501C6.7552 11.6501 6.9303 11.5724 7.05353 11.4362L12.4821 5.4362Z"
                fill="currentColor"
            />
            <path
                d="M3 0.5H13C14.3672 0.5 15.5 1.63281 15.5 3V13C15.5 14.3672 14.3672 15.5 13 15.5H3C1.59375 15.5 0.5 14.3672 0.5 13V3C0.5 1.63281 1.59375 0.5 3 0.5ZM13 14.25C13.6641 14.25 14.25 13.7031 14.25 13V3C14.25 2.33594 13.6641 1.75 13 1.75H3C2.29688 1.75 1.75 2.33594 1.75 3V13C1.75 13.7031 2.29688 14.25 3 14.25H13Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </g>
    )
})

export const CheckSquareIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CheckSquareIconRegular',
    viewBox: '0 0 16 16',
    path: (
        <g>
            <path
                d="M11.7652 6.39506C12.09 6.06428 12.0761 5.54141 11.7342 5.22718C11.3923 4.91294 10.8518 4.92635 10.527 5.25712L6.87641 8.97451L5.47303 7.54545C5.14821 7.21468 4.60771 7.20127 4.26579 7.5155C3.92387 7.82974 3.91001 8.35261 4.23483 8.68338L6.2573 10.7429C6.41852 10.907 6.64233 11 6.87641 11C7.11048 11 7.33429 10.907 7.49551 10.7429L11.7652 6.39506Z"
                fill="currentColor"
            />
            <path
                d="M3 0.5H13C14.3672 0.5 15.5 1.63281 15.5 3V13C15.5 14.3672 14.3672 15.5 13 15.5H3C1.59375 15.5 0.5 14.3672 0.5 13V3C0.5 1.63281 1.59375 0.5 3 0.5ZM13 13.625C13.3125 13.625 13.625 13.3516 13.625 13V3C13.625 2.6875 13.3125 2.375 13 2.375H3C2.64844 2.375 2.375 2.6875 2.375 3V13C2.375 13.3516 2.64844 13.625 3 13.625H13Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </g>
    )
})

export const CheckSquareIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CheckSquareIconSolid',
    viewBox: '0 0 16 16',
    path: (
        <g>
            <path
                d="M15.5 13V3C15.5 1.63281 14.3672 0.5 13 0.5H3C1.59375 0.5 0.5 1.63281 0.5 3V13C0.5 14.3672 1.59375 15.5 3 15.5H13C14.3672 15.5 15.5 14.3672 15.5 13ZM11.7342 5.22718C12.0761 5.54141 12.09 6.06428 11.7652 6.39506L7.49551 10.7429C7.33429 10.907 7.11048 11 6.87641 11C6.64233 11 6.41852 10.907 6.2573 10.7429L4.23483 8.68338C3.91001 8.35261 3.92387 7.82974 4.26579 7.5155C4.60771 7.20127 5.14821 7.21468 5.47303 7.54545L6.87641 8.97451L10.527 5.25712C10.8518 4.92635 11.3923 4.91294 11.7342 5.22718Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </g>
    )
})
