/* eslint-disable import/first */

/**
 * DEFINE GLOBAL
 *
 * Needed to enable aspects of the Chime SDK to function properly, e.g. CSPMonitor.
 *
 * Declared here instead of Vite config due to conflicts with 'global' definitions
 * within other files e.g. Chakra UI styles
 *
 */
window.global ||= window

import { SharedContext } from '@missionlabs/api'
import { ErrorBoundary, ThemeProvider, Toaster } from '@missionlabs/react'
import { getFlagsmithEnvironment } from '@missionlabs/utils'
import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import isElectron from 'is-electron'
import { KBarProvider } from 'kbar'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { HeadProvider } from 'react-head'
import { I18nextProvider } from 'react-i18next'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { AppRouter } from 'routes'
import { ErrorFallback } from 'shared/components/ErrorFallback'
import { persistor, store } from 'shared/store'
import { AppConfig } from 'shared/utils/env'

import { CommandMenu } from './shared/components/CommandMenu/CommandMenu'
import i18n from './translations'

if (
    import.meta.env.NODE_ENV === 'development' &&
    import.meta.env.REACT_APP_MOCKING_ENABLED === 'true'
) {
    import('@missionlabs/api').then(({ createWorker }) => {
        createWorker().then(worker => worker!.start({ onUnhandledRequest: 'bypass' }))
    })
}

const Router = isElectron() ? HashRouter : BrowserRouter

function openDebugOnQueryChange(query: string) {
    if (!isElectron()) return
    if (query.toLowerCase() === 'debug') {
        window.openDebug()
    }
}

createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
        <ReduxProvider store={store} context={SharedContext}>
            <PersistGate loading={null} persistor={persistor}>
                <FlagsmithProvider
                    flagsmith={flagsmith}
                    options={{
                        api: import.meta.env.FLAGSMITH_API,
                        environmentID: getFlagsmithEnvironment(),
                        cacheFlags: true,
                        traits: { brand: AppConfig.brand }
                    }}
                >
                    <I18nextProvider i18n={i18n}>
                        <HeadProvider>
                            <ThemeProvider variant={AppConfig.theme}>
                                <ErrorBoundary fallback={ErrorFallback}>
                                    <Toaster />
                                    <Router>
                                        <KBarProvider
                                            options={{
                                                callbacks: {
                                                    onQueryChange: openDebugOnQueryChange
                                                }
                                            }}
                                        >
                                            <CommandMenu />
                                            <AppRouter />
                                        </KBarProvider>
                                    </Router>
                                </ErrorBoundary>
                            </ThemeProvider>
                        </HeadProvider>
                    </I18nextProvider>
                </FlagsmithProvider>
            </PersistGate>
        </ReduxProvider>
    </StrictMode>
)
