/* eslint-disable import/no-anonymous-default-export */
export default {
    log: function (type?: 'error' | 'info' | 'trace' | 'debug', ...args) {
        switch (type) {
            case 'error':
                console.error(...args)
                break
            case 'info':
                console.info(...args)
                break
            case 'trace':
                console.trace(...args)
                break
            case 'debug':
                console.debug(...args)
                break
            default:
                console.log(...args)
        }
    },
    putEvent: function (..._args) {
        // console.log(...args)
        //On actual implementations this logs to    .
    },
    putMetric: function (..._args) {
        // On actual implementations this logs to Kinesis.
    }
}
