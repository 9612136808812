import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const Logo: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'Logo',
    viewBox: '0 0 74 53',
    path: [
        <path
            key={0}
            d="M40.9866 5.31154C40.5251 7.18003 38.8353 8.56566 36.8213 8.56566C34.452 8.56566 32.5314 6.64817 32.5314 4.28283C32.5314 1.91749 34.452 0 36.8213 0C38.8803 0 40.6005 1.44824 41.0156 3.38007H50.5211C50.9216 1.84606 52.3187 0.713805 53.9807 0.713805C55.6426 0.713805 57.0397 1.84606 57.4402 3.38007H68.4261C68.8045 2.2456 69.8766 1.42761 71.1401 1.42761C72.7196 1.42761 74 2.70594 74 4.28283C74 5.85972 72.7196 7.13805 71.1401 7.13805C69.9239 7.13805 68.885 6.38011 68.4714 5.31154H57.4048C56.9625 6.78118 55.5968 7.85185 53.9807 7.85185C52.3645 7.85185 50.9989 6.78118 50.5565 5.31154H40.9866Z"
            fill="url(#paint0_linear_1445_67412)"
        />,
        <path
            key={1}
            d="M32.2873 17.6667H42.1235C42.6752 16.4061 43.935 15.5252 45.401 15.5252C46.8669 15.5252 48.1267 16.4061 48.6784 17.6667H60.0831C60.5776 16.8132 61.5018 16.239 62.5604 16.239C64.1399 16.239 65.4203 17.5174 65.4203 19.0943C65.4203 20.6712 64.1399 21.9495 62.5604 21.9495C61.5018 21.9495 60.5776 21.3753 60.0831 20.5219H48.6784C48.1267 21.7824 46.8669 22.6633 45.401 22.6633C43.935 22.6633 42.6752 21.7824 42.1235 20.5219H32.2873C31.6984 22.1853 30.1094 23.3771 28.2415 23.3771C26.3737 23.3771 24.7847 22.1853 24.1958 20.5219H13.5594C13.0649 21.3753 12.1407 21.9495 11.0821 21.9495C9.50264 21.9495 8.22222 20.6712 8.22222 19.0943C8.22222 17.5174 9.50264 16.239 11.0821 16.239C12.1407 16.239 13.0649 16.8132 13.5594 17.6667H24.1958C24.7847 16.0032 26.3737 14.8114 28.2415 14.8114C30.1094 14.8114 31.6984 16.0032 32.2873 17.6667Z"
            fill="url(#paint1_linear_1445_67412)"
        />,
        <path
            key={2}
            d="M32.4337 47.8039L41.9442 47.8039C42.3483 46.2752 43.7428 45.1481 45.401 45.1481C47.3753 45.1481 48.9758 46.7461 48.9758 48.7172C48.9758 50.6883 47.3753 52.2862 45.401 52.2862C43.781 52.2862 42.4127 51.2104 41.9737 49.7354L32.4094 49.7354C31.9517 51.6092 30.2593 53 28.2415 53C26.2238 53 24.5313 51.6092 24.0737 49.7354L13.7548 49.7354C13.344 50.8095 12.3023 51.5724 11.0821 51.5724C9.50265 51.5724 8.22223 50.2941 8.22223 48.7172C8.22223 47.1403 9.50265 45.8619 11.0821 45.8619C12.3417 45.8619 13.4111 46.6749 13.7926 47.8039L24.0494 47.8039C24.4685 45.8773 26.1863 44.4343 28.2415 44.4343C30.2968 44.4343 32.0145 45.8773 32.4337 47.8039Z"
            fill="url(#paint2_linear_1445_67412)"
        />,
        <path
            key={3}
            d="M32.8224 32.3522H22.0617C21.5518 31.5687 20.6674 31.0505 19.6618 31.0505C18.6562 31.0505 17.7718 31.5687 17.2619 32.3522L5.25979 32.3521C4.74989 31.5687 3.86551 31.0505 2.8599 31.0505C1.28042 31.0505 0 32.3288 0 33.9057C0 35.4826 1.28042 36.7609 2.8599 36.7609C3.95283 36.7609 4.90257 36.1489 5.38399 35.2493L17.1378 35.2494C17.6192 36.1489 18.5689 36.7609 19.6618 36.7609C20.7548 36.7609 21.7045 36.1489 22.1859 35.2494H32.7468C33.3112 36.9565 34.9221 38.1886 36.8213 38.1886C38.7204 38.1886 40.3313 36.9565 40.8958 35.2494H50.6678C51.1994 36.5546 52.4824 37.4748 53.9807 37.4748C55.479 37.4748 56.7619 36.5546 57.2936 35.2494L68.616 35.2494C69.0974 36.1489 70.0472 36.761 71.1401 36.761C72.7196 36.761 74 35.4826 74 33.9057C74 32.3288 72.7196 31.0505 71.1401 31.0505C70.1345 31.0505 69.2501 31.5687 68.7402 32.3522L57.2 32.3522C56.6214 31.1593 55.3973 30.3367 53.9807 30.3367C52.5641 30.3367 51.3399 31.1593 50.7613 32.3522H40.8202C40.1971 30.7548 38.6416 29.6229 36.8213 29.6229C35.0009 29.6229 33.4454 30.7548 32.8224 32.3522Z"
            fill="url(#paint3_linear_1445_67412)"
        />,
        <defs key={4}>
            <linearGradient
                id="paint0_linear_1445_67412"
                x1="70.1307"
                y1="4.82868"
                x2="9.9877"
                y2="50.7962"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#7B61FF" />
                <stop offset="1" stopColor="#33E8C8" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1445_67412"
                x1="70.1307"
                y1="4.82868"
                x2="9.9877"
                y2="50.7962"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#7B61FF" />
                <stop offset="1" stopColor="#33E8C8" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_1445_67412"
                x1="70.1307"
                y1="4.82868"
                x2="9.9877"
                y2="50.7962"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#7B61FF" />
                <stop offset="1" stopColor="#33E8C8" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_1445_67412"
                x1="70.1307"
                y1="4.82868"
                x2="9.9877"
                y2="50.7962"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#7B61FF" />
                <stop offset="1" stopColor="#33E8C8" />
            </linearGradient>
        </defs>
    ]
})
