import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const SubItemIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SubItemIconLight',
    viewBox: '0 0 20 16',
    path: (
        <path
            d="M1.14582e-06 2.38451L1.2551e-06 1.13451C1.28584e-06 0.782952 0.312501 0.509514 0.625001 0.509514C0.976564 0.509514 1.25 0.782952 1.25 1.13451L1.25 2.34545C1.25 5.43139 3.78906 7.93139 6.875 7.93139L17.6563 7.93139L11.4844 2.77514C11.2109 2.54076 11.2109 2.15014 11.4063 1.8767C11.5234 1.83764 11.7188 1.75952 11.875 1.75952C12.0313 1.75952 12.1875 1.79858 12.3047 1.8767L19.8047 8.08764C19.9219 8.24389 20 8.4392 20 8.63452C20 8.82983 19.9219 9.02514 19.8047 9.14233L12.3047 15.3533C12.0312 15.5486 11.6406 15.5486 11.4063 15.2751C11.2109 15.0017 11.2109 14.6111 11.4844 14.3767L17.6563 9.25952L6.875 9.25951C3.08594 9.25951 8.14573e-07 6.17358 1.14582e-06 2.38451Z"
            fill="currentColor"
        />
    )
})

export const SubItemIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SubItemIconRegular',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M1.03245e-06 5.90788L1.44225e-06 1.22038C1.49006e-06 0.6735 0.429689 0.282875 0.937502 0.282875C1.48438 0.282875 1.875 0.6735 1.875 1.18131L1.875 5.82975C1.875 8.05631 3.71094 9.85319 5.9375 9.85319L16.5625 9.85319L11.6016 5.55631C11.2109 5.20475 11.1719 4.61881 11.4844 4.22819C11.6797 4.111 11.9531 4.03288 12.1875 4.03288C12.4219 4.03288 12.6563 4.07194 12.8125 4.22819L19.6875 10.1266C19.8828 10.361 20 10.6344 20 10.9079C20 11.1813 19.8828 11.4157 19.6875 11.611L12.8125 17.5094C12.4609 17.8219 11.8359 17.7829 11.4844 17.3923C11.1719 17.0016 11.2109 16.4157 11.6016 16.0641L16.5625 11.8454L5.9375 11.8454C2.69531 11.8454 7.49013e-07 9.15006 1.03245e-06 5.90788Z"
            fill="currentColor"
        />
    )
})

export const SubItemIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SubItemIconSolid',
    viewBox: '0 0 22 18',
    path: (
        <path
            d="M20.6826 11.6147L13.8076 17.5132C13.2217 18.021 12.2451 17.5913 12.2451 16.8101L12.2451 13.646C5.99512 13.646 0.995118 12.3569 0.995118 6.41943C0.995118 4.03662 2.55762 1.6538 4.27637 0.403803C4.78418 0.0131784 5.56543 0.520991 5.37012 1.14599C3.61231 6.81005 6.19043 8.29443 12.2451 8.41162L12.2451 4.97412C12.2451 4.1538 13.2217 3.72412 13.8076 4.23193L20.6826 10.1694C21.1123 10.5601 21.1123 11.2241 20.6826 11.6147Z"
            fill="currentColor"
        />
    )
})
