import React from 'react'

interface Props {}

const DateIcon: React.FC<Props> = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.375 0C14.7202 0 15 0.279822 15 0.625V2.5H18.125C19.1605 2.5 20 3.33947 20 4.375V18.125C20 19.1605 19.1605 20 18.125 20H1.875C0.839472 20 0 19.1605 0 18.125V4.375C0 3.33947 0.839472 2.5 1.875 2.5H5V0.625C5 0.279822 5.27982 0 5.625 0C5.97018 0 6.25 0.279822 6.25 0.625V2.5H13.75V0.625C13.75 0.279822 14.0298 0 14.375 0ZM18.75 8.75H1.25V18.125C1.25 18.4702 1.52983 18.75 1.875 18.75H18.125C18.4702 18.75 18.75 18.4702 18.75 18.125V8.75ZM4.68713 15C5.20527 15 5.625 15.4197 5.625 15.9375C5.625 16.4553 5.20527 16.875 4.6875 16.875C4.16973 16.875 3.75 16.4553 3.75 15.9375C3.75 15.4199 4.16953 15.0002 4.68713 15ZM9.99955 15C10.5178 15 10.9375 15.4197 10.9375 15.9375C10.9375 16.4553 10.5178 16.875 10 16.875C9.48223 16.875 9.0625 16.4553 9.0625 15.9375C9.0625 15.4199 9.48199 15.0002 9.99955 15ZM15.3121 15C15.8303 15 16.25 15.4197 16.25 15.9375C16.25 16.4553 15.8303 16.875 15.3125 16.875C14.7947 16.875 14.375 16.4553 14.375 15.9375C14.375 15.4199 14.7945 15.0002 15.3121 15ZM4.68713 10.625C5.20527 10.625 5.625 11.0447 5.625 11.5625C5.625 12.0803 5.20527 12.5 4.6875 12.5C4.16973 12.5 3.75 12.0803 3.75 11.5625C3.75 11.0449 4.16953 10.6252 4.68713 10.625ZM9.99955 10.625C10.5178 10.625 10.9375 11.0447 10.9375 11.5625C10.9375 12.0803 10.5178 12.5 10 12.5C9.48223 12.5 9.0625 12.0803 9.0625 11.5625C9.0625 11.0449 9.48199 10.6252 9.99955 10.625ZM15.3121 10.625C15.8303 10.625 16.25 11.0447 16.25 11.5625C16.25 12.0803 15.8303 12.5 15.3125 12.5C14.7947 12.5 14.375 12.0803 14.375 11.5625C14.375 11.0449 14.7945 10.6252 15.3121 10.625ZM5 3.75H1.875C1.52983 3.75 1.25 4.02983 1.25 4.375V7.5H18.75V4.375C18.75 4.02983 18.4702 3.75 18.125 3.75H15V5C15 5.34518 14.7202 5.625 14.375 5.625C14.0298 5.625 13.75 5.34518 13.75 5V3.75H6.25V5C6.25 5.34518 5.97018 5.625 5.625 5.625C5.27982 5.625 5 5.34518 5 5V3.75Z"
                fill="#616E7C"
            />
        </svg>
    )
}

export default DateIcon
