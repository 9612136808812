import { useEffect, useRef } from 'react'

export const useOnTabClose = (cb: Function) => {
    const handleTabClose = useRef<(event: BeforeUnloadEvent) => void>()

    useEffect(() => {
        handleTabClose.current = (event: BeforeUnloadEvent) => {
            event.preventDefault()
            cb()
        }

        window.addEventListener('beforeunload', handleTabClose.current)

        return () => {
            if (!handleTabClose.current) return
            window.removeEventListener('beforeunload', handleTabClose.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return () => {
        if (!handleTabClose.current) return
        window.removeEventListener('beforeunload', handleTabClose.current)
    }
}
