import { Table, useFuzzySearch } from '@missionlabs/react'
import { NumberE164 } from '@missionlabs/types'
import { createColumnHelper } from '@tanstack/react-table'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'

const columnHelper = createColumnHelper<Partial<NumberE164>>()

export interface NumbersTableProps {
    numbers: NumberE164[]
    searchTerm: string
}

export const NumbersTable: FC<NumbersTableProps> = ({ numbers, searchTerm }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { formatToLocalNumber } = useFormatToNumberE164()

    const formattedNumbers = useMemo(() => {
        return numbers.map(number => {
            return { ...number, displayNumber: formatToLocalNumber(number.numberE164) }
        })
    }, [formatToLocalNumber, numbers])

    const { results } = useFuzzySearch<NumberE164>({
        items: formattedNumbers,
        searchValue: searchTerm,
        keys: ['displayNumber', 'displayNumberType'],
        options: {
            minMatchCharLength: 1
        }
    })

    const getAssigneeType = (row: Partial<NumberE164>) => {
        if ('userID' in row) return t('User')
        if (row?.team?.object === 'team') return t('Team')
        if (row?.team?.object === 'menu') return t('Menu')
        return ''
    }

    const getUserOrTeam = (row: Partial<NumberE164>) =>
        row.user?.fullName ?? row.team?.name ?? t('numbers.unassigned')

    const columns = [
        columnHelper.accessor('displayNumber', {
            id: 'displayNumber',
            cell: info => info.getValue(),
            header: t('Number')
        }),
        columnHelper.accessor('displayNumberType', {
            id: 'displayNumberType',
            cell: info => info.getValue(),
            header: 'Locale'
        }),
        columnHelper.accessor(row => `${getAssigneeType(row)}`, {
            id: 'assigneeType',
            cell: info => info.getValue(),
            header: t('Type')
        }),
        columnHelper.accessor(row => `${getUserOrTeam(row)}`, {
            id: 'assignee',
            cell: info => info.getValue(),
            header: t('Assigned to')
        }),
        columnHelper.accessor('alphaTag', {
            id: 'tag',
            cell: info => info.getValue(),
            header: t('Tags')
        })
    ]

    const handleRowClicked = (data: Partial<NumberE164>) => {
        navigate(`/admin/numbers/${data.ID}/edit`)
    }

    return <Table data={results} columns={columns} paginate onRowClicked={handleRowClicked} />
}
