import { buildRootAPI, useDispatch } from '../apis'
import { AuthenticatedUser, setUser, updateAutoLogin } from '../slices'

export interface KumodiLoginArgs {
    useLazyTempQuery: ReturnType<typeof buildRootAPI>['useLazyTempQuery']
    useLoginMutation: ReturnType<typeof buildRootAPI>['useLoginMutation']
}

export const useKumodiLogin = ({ useLazyTempQuery, useLoginMutation }: KumodiLoginArgs) => {
    const dispatch = useDispatch()
    const [getToken, { isLoading: isLoadingToken }] = useLazyTempQuery()
    const [login, { isLoading: isLoadingLogin }] = useLoginMutation()

    const loginWithKumodi = async (
        email: string,
        password: string,
        rememberDetails?: boolean
    ): Promise<AuthenticatedUser | undefined> => {
        const token = await getToken().unwrap()
        if (!token) return
        const authResponse = await login({ login: { email, password }, token }).unwrap()
        if (!authResponse) return
        const { ID, clientID, userID } = authResponse
        const user: AuthenticatedUser = {
            token: ID,
            userID,
            clientID
        }

        dispatch(setUser(user))
        if (rememberDetails) dispatch(updateAutoLogin(true))
        return user
    }

    const isLoading = isLoadingToken || isLoadingLogin

    return { loginWithKumodi, isLoading }
}
