import {
    AuthenticatedUser,
    loginSuccess,
    setUser,
    updateAutoLogin,
    useDispatch
} from '@missionlabs/api'
import { useNavigate } from 'react-router-dom'
import { useSetUpDestination } from 'shared/hooks/useSetUpDestination'

function getStorageItem(name: string): AuthenticatedUser | null {
    try {
        return JSON.parse((sessionStorage.getItem(name) || localStorage.getItem(name)) ?? '')
    } catch {
        return null
    }
}

type UseAutoLoginOptions = {
    redirectAfterLogin?: string | null
    updateAutoLoginPreference?: boolean
    setErrorMessage?: (value: string | null) => void
}

type UseAutoLoginReturn = [() => Promise<void>, { session: AuthenticatedUser | null }]

export const useAutoLogin = ({
    redirectAfterLogin = '/home',
    updateAutoLoginPreference = true,
    setErrorMessage
}: UseAutoLoginOptions): UseAutoLoginReturn => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const setUpDestination = useSetUpDestination()

    const session = getStorageItem('auth_session')

    async function handler() {
        if (!session) return

        try {
            setErrorMessage?.(null)

            const user: AuthenticatedUser = {
                token: session.token,
                userID: session.userID,
                clientID: session.clientID,
                username: session.username
            }

            dispatch(setUser(user))
            await setUpDestination(user)
            dispatch(loginSuccess())
            if (redirectAfterLogin !== null) navigate(redirectAfterLogin)
        } catch (e) {
            console.error(e)
            if (updateAutoLoginPreference) dispatch(updateAutoLogin(false))
            setErrorMessage?.('data' in e ? e.data.message : e.message)
        }
    }

    return [handler, { session }]
}

export function clearAutoLogin() {
    try {
        sessionStorage.removeItem('auth_session')
        localStorage.removeItem('auth_session')
    } catch (error) {
        console.error('Error clearing auto login:', error)
    }
}
