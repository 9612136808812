import { useDisclosure } from '@chakra-ui/react'
import { IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@missionlabs/react'
import { BinIconRegular, DotsVerticalIconRegular, EditIconRegular } from '@missionlabs/react/zeta'
import { ScheduledMeeting } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { CancelMeetingDialog } from './CancelMeetingDialog'
import { RemoveMeetingDialog } from './RemoveMeetingDialog'

type MeetingOverflowMenuProps = {
    meeting: ScheduledMeeting
    isOrganiser: boolean
    isCancelled: boolean
    hasExternalParticipants: boolean
    hasStarted: boolean
}

export const MeetingOverflowMenu: FC<MeetingOverflowMenuProps> = ({
    meeting,
    isOrganiser,
    isCancelled,
    hasExternalParticipants,
    hasStarted
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const cancelMeetingDisclosure = useDisclosure()
    const removeMeetingDisclosure = useDisclosure()

    return (
        <>
            <Menu placement="bottom-end" offset={[0, 0]}>
                <MenuButton
                    as={IconButton}
                    icon={<DotsVerticalIconRegular />}
                    aria-label="Meeting menu"
                    variant="transparent"
                />
                <MenuList>
                    {isOrganiser && !isCancelled && !hasStarted && (
                        <>
                            <MenuItem
                                onClick={() =>
                                    navigate(
                                        `${location.pathname}/${meeting.ID}/edit/${location.search}`
                                    )
                                }
                                icon={<EditIconRegular />}
                                isDisabled={hasExternalParticipants}
                            >
                                {t('meetings.editMeeting')}
                            </MenuItem>
                            <MenuDivider />

                            <MenuItem
                                icon={<BinIconRegular />}
                                variant="destructive"
                                onClick={cancelMeetingDisclosure.onOpen}
                                isDisabled={hasExternalParticipants}
                            >
                                {t('meetings.cancelMeeting')}
                            </MenuItem>
                        </>
                    )}
                </MenuList>
            </Menu>
            <CancelMeetingDialog
                meeting={meeting}
                isOpen={cancelMeetingDisclosure.isOpen}
                onClose={cancelMeetingDisclosure.onClose}
            />
            <RemoveMeetingDialog
                meeting={meeting}
                isOpen={removeMeetingDisclosure.isOpen}
                onClose={removeMeetingDisclosure.onClose}
            />
        </>
    )
}
