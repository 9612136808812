import { CSSProperties } from 'react'

import { ContactRow, VirtualContactRow } from './ContactRow'

interface ContactListRendererProps {
    data: VirtualContactRow[]
    index: number
    style: CSSProperties
}

export const ContactListRenderer = ({ data, index, style }: ContactListRendererProps) => {
    const { contact, onSelect, selectedContact } = data[index]

    return (
        <div style={style}>
            <ContactRow contact={contact} onSelect={onSelect} selectedContact={selectedContact} />
        </div>
    )
}
