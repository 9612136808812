import {
    authMiddleware,
    authSlice,
    buildAddressesAPI,
    buildAnalyticsAPI,
    buildBillingApi,
    buildBlockedNumbersAPI,
    buildCallNoteAPI,
    buildChatAPI,
    buildClientAPI,
    buildContactsAPI,
    buildDestinationAPI,
    buildEmojiAPI,
    buildExtensionsApi,
    buildHuntGroupsApi,
    buildIntegrationsAPI,
    buildIVRMenusApi,
    buildKvpgroupsApi,
    buildLogsAPI,
    buildMiscAPI,
    buildNumbersAPI,
    buildPageGroupsApi,
    buildResetTokenAPI,
    buildRootAPI,
    buildSavedNumbersListAPI,
    buildScheduledMeetingsApi as buildScheduledMeetingsAPI,
    buildSequentialRingingListsAPI,
    buildServicePacksAPI,
    buildSMSAPI,
    buildSpeedDialListApi,
    buildUploadAPI,
    buildUserGroupsApi,
    buildUsersAPI,
    buildWhatsAppAPI,
    buildZetaIDAPI,
    callSlice,
    chatSlice,
    guestLinkSlice,
    liveServicesMiddleware,
    liveServicesSlice,
    loggerMiddleware,
    logoutSuccess,
    meetingSlice,
    preferencesSlice,
    subscriptionSlice,
    systemSlice,
    userSlice
} from '@missionlabs/api'
import { configureStore, Reducer } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import { callNoteSlice } from 'shared/slices/callNoteSlice'
import { messagingSlice } from 'shared/slices/messagingSlice'
import { whatsappSlice } from 'shared/slices/whatsappSlice'

import {
    authenticatedBaseQuery,
    emojiBaseQuery,
    logsBaseQuery,
    lsBaseQuery,
    pbxBaseQuery,
    pbxTempTokenBaseQuery,
    zetaIDBaseQuery
} from './baseQuery'
import { jabraMiddleware } from './middleware/jabraMiddleware'
import { audioPreferencesSlice } from './slices/audioDeviceSlice'
import { jabraSlice } from './slices/jabraSlice'
import { paginationSlice } from './slices/paginationSlice'
import { presenceSlice } from './slices/presenceSlice'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['userSlice', 'authSlice', 'liveServicesSlice', 'preferencesSlice'] // only these reducers will be persistent
}

export const { api: kvpGroupsAPI } = buildKvpgroupsApi()

export const { useGetReportTypesQuery } = kvpGroupsAPI

export const { api: addressesAPI, useGetAddressesByPostcodeQuery } =
    buildAddressesAPI(pbxTempTokenBaseQuery)

export const { api: analyticsAPI } = buildAnalyticsAPI(authenticatedBaseQuery)

export const {
    useDeleteAdminCallRecordingMutation,
    useGetSummaryAnalyticsQuery,
    useGetUsersAnalyticsQuery,
    useGetNumbersAnalyticsQuery,
    useGetCallGroupsAnalyticsQuery,
    useGetReportsQuery,
    useGetReportQuery,
    useGetSingleNumberTotalsAnalyticsQuery,
    useGetSingleNumberRecentCallHistoryQuery,
    useGetSingleUserRecentCallsAnalyticsQuery,
    useGetSingleUserTotalsAnalyticsQuery,
    useGetSingleCallGroupRecentCallHistoryQuery,
    useGetSingleCallGroupTotalsAnalyticsQuery,
    useRequestReportMutation
} = analyticsAPI

export const {
    api: billingAPI,
    useGetCreditNotesQuery,
    useGetEstimatedInvoiceQuery,
    useGetInvoicesQuery,
    useGetInvoiceQuery,
    useGetPaymentMethodsQuery,
    useGetSummaryQuery,
    useGenerateCreditNotePDFMutation,
    useGenerateInvoicePDFMutation,
    useUpdateCardDetailsMutation,
    useAddDirectDebitMutation,
    useGetClientPlansQuery,
    useUpdateClientPlanMutation,
    usePayInvoiceMutation
} = buildBillingApi(authenticatedBaseQuery)

export const {
    api: blockedNumbersAPI,
    useGetDefaultBlockedNumberListQuery,
    useCreateDefaultBlockedNumberMutation,
    useBatchCreateDefaultBlockedNumbersMutation,
    useUpdateDefaultBlockedNumberMutation,
    useDeleteDefaultBlockedNumberListMutation,
    useGetUserBlockedNumberListQuery,
    useUpdateUserBlockedNumberListMutation,
    useDeleteUserBlockedNumberListMutation,
    useDeleteDefaultBlockedNumberMutation
} = buildBlockedNumbersAPI(authenticatedBaseQuery)

export const {
    api: callNoteApi,
    useCreateCallNoteMutation,
    useUpdateCallNoteMutation,
    useDeleteCallNoteMutation
} = buildCallNoteAPI(authenticatedBaseQuery)

export const {
    api: clientApi,
    useGetClientQuery,
    useGetClientActivitiesQuery,
    useUpdateClientMutation,
    useCancelClientSubscriptionMutation,
    useGetClientAudioClipsQuery
} = buildClientAPI(authenticatedBaseQuery)

export const {
    api: destinationAPI,
    useGetDestinationForUserQuery,
    useGetDestinationsForUserQuery,
    usePostDestinationForUserMutation,
    usePutDestinationForUserMutation,
    useDeleteDestinationForUserMutation,
    usePostInstallationIdMutation,
    useGetDestinationNotificationTypesQuery
} = buildDestinationAPI(authenticatedBaseQuery)

export const {
    api: contactsAPI,
    useLazyContactsSearchQuery,
    useGetContactQuery,
    useCreateUserContactMutation,
    useUpdateUserContactMutation,
    useDeleteUserContactMutation,
    useGetUserContactGroupQuery,
    useGetUserContactGroupsQuery,
    useCreateUserContactGroupMutation,
    useUpdateUserContactGroupMutation,
    useDeleteUserContactGroupMutation,
    useGetUserFavouriteContactsQuery,
    useGetContactSourcesQuery,
    useGetContactPresenceQuery,
    useExportContactsMutation,
    useLazyGetContactExportLogQuery,
    useImportContactsMutation,
    useLazyGetContactImportLogQuery
} = buildContactsAPI(authenticatedBaseQuery)

export const {
    api: chatAPI,
    useGetMessagesQuery,
    useGetMessageThreadQuery,
    useGetChannelQuery,
    useGetUnreadThreadsQuery,
    useGetMessageMutation,
    useGetChannelByIDMutation,
    useGetChannelsQuery,
    useGetSearchChannelsMutation,
    usePinChannelMutation,
    useGetChannelMessagesQuery,
    useSearchChannelMessagesQuery,
    useGetSessionQuery,
    useCreateChannelMessageMutation,
    useCreateChannelMutation,
    useRemoveUserFromChannelMutation,
    useGetSearchMessagesMutation,
    useSendEmojiMutation,
    useEditChannelMessageMutation,
    useMarkUnreadMutation,
    useGetChatGroupsQuery,
    useGetChatGroupQuery,
    useCreateChatGroupMutation,
    useEditChatGroupMutation,
    useDeleteChatGroupMutation,
    useLeaveChatGroupMutation
} = buildChatAPI(authenticatedBaseQuery)

export const {
    api: usersAPI,
    useGetUserQuery,
    useLazyGetUserQuery,
    useGetUsersQuery,
    useGetUserActivityQuery,
    useGetUserActivitiesQuery,
    useSearchUserActivitiesQuery,
    useLazySearchUserActivitiesQuery,
    useLazyGetUserActivitiesQuery,
    useGetUserActivityCountsQuery,
    useGetUserAudioClipsQuery,
    useGetUserCallRouteQuery,
    useGetUserVoicemailsQuery,
    useCreateUserAudioClipMutation,
    useCreateAudioClipMutation,
    useCreateUserFileMutation,
    useUpdateUserMutation,
    useUpdateUserActivityMutation,
    useUpdateUserAudioClipMutation,
    useUpdateUserCallRouteMutation,
    useUpdateUserPasswordMutation,
    useUpdateUserVoicemailMutation,
    useGetFlaggedActivitiesQuery,
    useUpdateFlaggedActivitiesMutation,
    useDeleteFlaggedActivitiesMutation,
    useGetSubscriptionGroupsQuery,
    useCreateSubscriptionGroupMutation,
    useUpdateSubscriptionGroupMutation,
    useDeleteSubscriptionGroupMutation,
    useCreateUserMutation,
    useDeleteUserMutation,
    useDeleteUserAudioClipMutation,
    useDeleteUserCallRecordingMutation,
    useGenerateNewPinMutation,
    useMarkActivitiesAsReadMutation,
    useMarkAllActivitiesAsReadMutation,
    useGetUserPresenceQuery,
    useGetCallsOnOtherDevicesQuery
} = buildUsersAPI(authenticatedBaseQuery)

export const { api: extensionsAPI, useGetExtensionsQuery } =
    buildExtensionsApi(authenticatedBaseQuery)

export const {
    api: huntGroupsAPI,
    useGetHuntGroupQuery,
    useGetHuntGroupsQuery,
    useCreateHuntGroupMutation,
    useGetHuntGroupMembersQuery,
    useUpdateHuntGroupMutation,
    useDeleteHuntGroupMutation,
    useSignCallQueueGroupMutation
} = buildHuntGroupsApi(authenticatedBaseQuery)

export const {
    api: ivrMenusAPI,
    useGetIVRMenusQuery,
    useGetIVRMenuQuery,
    useCreateIVRMenuMutation,
    useUpdateIVRMenuMutation,
    useDeleteIVRMenuMutation
} = buildIVRMenusApi(authenticatedBaseQuery)

export const { api: pageGroupsAPI, useGetPageGroupsQuery } =
    buildPageGroupsApi(authenticatedBaseQuery)

export const {
    api: savedNumbersListAPI,
    useGetSavedNumbersListQuery,
    useCreateSavedNumbersListMutation,
    useUpdateSavedNumbersListMutation,
    useDeleteSavedNumbersListMutation
} = buildSavedNumbersListAPI(authenticatedBaseQuery)

export const {
    api: scheduledMeetingsAPI,
    useGetScheduledMeetingsQuery,
    useGetScheduledMeetingQuery,
    useCreateScheduledMeetingMutation,
    useUpdateScheduledMeetingMutation,
    useUpdateScheduledMeetingParticipantsMutation,
    useUpdateScheduledMeetingAttendanceMutation,
    useCancelScheduledMeetingMutation
} = buildScheduledMeetingsAPI(authenticatedBaseQuery)

export const {
    api: sequentialRingingListsAPI,
    useGetSequentialRingingListsQuery,
    useGetSequentialRingingListQuery,
    useCreateSequentialRingingListMutation,
    useUpdateSequentialRingingListMutation,
    useDeleteSequentialRingingListMutation
} = buildSequentialRingingListsAPI(authenticatedBaseQuery)

export const {
    api: servicePacksAPI,
    useGetAssignedServicePacksForClientQuery,
    useGetAssignedServicePacksForUserQuery
} = buildServicePacksAPI(authenticatedBaseQuery)

export const {
    api: speedDialListAPI,
    useGetSpeedDialListsForClientQuery,
    useGetSpeedDialListForClientQuery,
    useCreateSpeedDialListForClientMutation,
    useUpdateSpeedDialListForClientMutation,
    useDeleteSpeedDialListForClientMutation,
    useGetSpeedDialListForUserQuery,
    useCreateSpeedDialListForUserMutation,
    useUpdateSpeedDialListForUserMutation,
    useDeleteSpeedDialListForUserMutation
} = buildSpeedDialListApi(authenticatedBaseQuery)

export const { api: userGroupsAPI, useGetUserGroupsQuery } =
    buildUserGroupsApi(authenticatedBaseQuery)

export const {
    api: numbersAPI,
    useGetAvailableNumbersQuery,
    useGetNumbersQuery,
    useGetNumbersForUserQuery,
    useAddNumberMutation,
    useAssignNumberMutation,
    useUnassignNumberMutation,
    useDeleteNumberMutation,
    useVerifyNumberMutation,
    useVerifyCodeMutation,
    useGetNumberPortabilityQuery,
    useAddAlphaTagMutation
} = buildNumbersAPI(authenticatedBaseQuery)

export const {
    api: zetaIDAPI,
    useAuthenticateMutation,
    useForgotPasswordMutation
} = buildZetaIDAPI(zetaIDBaseQuery)

export const { api: smsAPI, useSendMessageMutation } = buildSMSAPI(lsBaseQuery)

export const {
    api: miscAPI,
    useGetAreaCodesQuery,
    useGetImagesQuery,
    useGetLocalesQuery,
    useGetMonitoringConfigQuery,
    useGetNumberTypesQuery,
    useGetSharedAppDataQuery,
    useGetSipPhonesQuery,
    useGetTimezonesQuery,
    useLazyGetServerDataQuery,
    useGetPortingFormURLsQuery
} = buildMiscAPI(authenticatedBaseQuery)
export const {
    api: rootAPI,
    useGetMetadataQuery,
    useLoginWithJWTMutation,
    useLoginMutation,
    useLazyTempQuery,
    useSuperLoginMutation
} = buildRootAPI(pbxBaseQuery)

export const {
    api: uploadsAPI,
    useUploadChatFileMutation,
    useGetFileUploadQuery,
    useUploadChatGroupFileMutation,
    useGetChatGroupFileQuery
} = buildUploadAPI(authenticatedBaseQuery)

export const {
    api: emojiAPI,
    useGetEmojisQuery,
    useGetEmoticonsQuery
} = buildEmojiAPI(emojiBaseQuery)

export const {
    api: resetTokenAPI,
    useGetResetTokenQuery,
    useUpdateResetTokenMutation
} = buildResetTokenAPI(pbxTempTokenBaseQuery)

export const {
    api: logsAPI,
    useGetLogsConfigQuery,
    useLazyGetLogsURLQuery,
    useUploadLogsMutation
} = buildLogsAPI(logsBaseQuery)

export const {
    api: integrationsAPI,
    useGetIntegrationsQuery,
    usePutIntegrationMutation
} = buildIntegrationsAPI(authenticatedBaseQuery)

export const {
    api: whatsappAPI,
    useGetWhatsAppBusinessQuery,
    useLazyGetWhatsAppBusinessQuery,
    usePostWhatsAppBusinessMutation,
    useDeleteWhatsAppBusinessMutation,
    useGetWhatsAppNumbersQuery,
    usePostWhatsAppNumberMutation,
    useDeleteWhatsAppNumberMutation,
    usePostWhatsAppRequestCodeMutation,
    usePostWhatsAppVerifyCodeMutation,
    usePostWhatsAppRegisterNumberMutation,
    useGetWhatsAppTemplatesQuery,
    usePostWhatsAppMessageMutation,
    usePostNumberCheckMutation
} = buildWhatsAppAPI(authenticatedBaseQuery)

const appReducer = combineReducers({
    userSlice: userSlice.reducer,
    authSlice: authSlice.reducer,
    liveServicesSlice: liveServicesSlice.reducer,
    callSlice: callSlice.reducer,
    callNoteSlice: callNoteSlice.reducer,
    meetingSlice: meetingSlice.reducer,
    paginationSlice: paginationSlice.reducer,
    subscriptionSlice: subscriptionSlice.reducer,
    systemSlice: systemSlice.reducer,
    messagingSlice: messagingSlice.reducer,
    chatSlice: chatSlice.reducer,
    presenceSlice: presenceSlice.reducer,
    jabraSlice: jabraSlice.reducer,
    guestLinkSlice: guestLinkSlice.reducer,
    preferencesSlice: preferencesSlice.reducer,
    audioPreferencesSlice: audioPreferencesSlice.reducer,
    whatsappSlice: whatsappSlice.reducer,
    [addressesAPI.reducerPath]: addressesAPI.reducer,
    [analyticsAPI.reducerPath]: analyticsAPI.reducer,
    [destinationAPI.reducerPath]: destinationAPI.reducer,
    [usersAPI.reducerPath]: usersAPI.reducer,
    [zetaIDAPI.reducerPath]: zetaIDAPI.reducer,
    [rootAPI.reducerPath]: rootAPI.reducer,
    [miscAPI.reducerPath]: miscAPI.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [contactsAPI.reducerPath]: contactsAPI.reducer,
    [resetTokenAPI.reducerPath]: resetTokenAPI.reducer,
    [blockedNumbersAPI.reducerPath]: blockedNumbersAPI.reducer,
    [extensionsAPI.reducerPath]: extensionsAPI.reducer,
    [ivrMenusAPI.reducerPath]: ivrMenusAPI.reducer,
    [kvpGroupsAPI.reducerPath]: kvpGroupsAPI.reducer,
    [huntGroupsAPI.reducerPath]: huntGroupsAPI.reducer,
    [pageGroupsAPI.reducerPath]: pageGroupsAPI.reducer,
    [userGroupsAPI.reducerPath]: userGroupsAPI.reducer,
    [savedNumbersListAPI.reducerPath]: savedNumbersListAPI.reducer,
    [scheduledMeetingsAPI.reducerPath]: scheduledMeetingsAPI.reducer,
    [sequentialRingingListsAPI.reducerPath]: sequentialRingingListsAPI.reducer,
    [speedDialListAPI.reducerPath]: speedDialListAPI.reducer,
    [numbersAPI.reducerPath]: numbersAPI.reducer,
    [smsAPI.reducerPath]: smsAPI.reducer,
    [billingAPI.reducerPath]: billingAPI.reducer,
    [servicePacksAPI.reducerPath]: servicePacksAPI.reducer,
    [logsAPI.reducerPath]: logsAPI.reducer,
    [integrationsAPI.reducerPath]: integrationsAPI.reducer,
    [uploadsAPI.reducerPath]: uploadsAPI.reducer,
    [emojiAPI.reducerPath]: emojiAPI.reducer,
    [chatAPI.reducerPath]: chatAPI.reducer,
    [whatsappAPI.reducerPath]: whatsappAPI.reducer,
    [callNoteApi.reducerPath]: callNoteApi.reducer
})

const rootReducer: Reducer = (state, action) => {
    // on logout reset all reducers to initial state and clear persisted state
    if (logoutSuccess.match(action)) {
        storage.removeItem('persist:root')
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: { ignoredActions: ['persist/PERSIST'] } })
            .prepend(authMiddleware)
            .prepend(loggerMiddleware)
            .prepend(jabraMiddleware.middleware)
            .prepend(liveServicesMiddleware.middleware)
            .concat(addressesAPI.middleware)
            .concat(analyticsAPI.middleware)
            .concat(destinationAPI.middleware)
            .concat(usersAPI.middleware)
            .concat(zetaIDAPI.middleware)
            .concat(rootAPI.middleware)
            .concat(miscAPI.middleware)
            .concat(clientApi.middleware)
            .concat(contactsAPI.middleware)
            .concat(resetTokenAPI.middleware)
            .concat(blockedNumbersAPI.middleware)
            .concat(extensionsAPI.middleware)
            .concat(ivrMenusAPI.middleware)
            .concat(kvpGroupsAPI.middleware)
            .concat(huntGroupsAPI.middleware)
            .concat(pageGroupsAPI.middleware)
            .concat(userGroupsAPI.middleware)
            .concat(savedNumbersListAPI.middleware)
            .concat(sequentialRingingListsAPI.middleware)
            .concat(speedDialListAPI.middleware)
            .concat(numbersAPI.middleware)
            .concat(smsAPI.middleware)
            .concat(billingAPI.middleware)
            .concat(servicePacksAPI.middleware)
            .concat(logsAPI.middleware)
            .concat(integrationsAPI.middleware)
            .concat(uploadsAPI.middleware)
            .concat(emojiAPI.middleware)
            .concat(chatAPI.middleware)
            .concat(scheduledMeetingsAPI.middleware)
            .concat(whatsappAPI.middleware)
            .concat(callNoteApi.middleware)
})

export const persistor = persistStore(store)
// https://redux-toolkit.js.org/rtk-query/api/setupListeners
setupListeners(store.dispatch)

// Infer the `RootState` type from the store itself
export type RootState = ReturnType<typeof store.getState>
