import { createIcon } from '@chakra-ui/icons'

export const SummaryIcon2 = createIcon({
    displayName: 'SummaryIcon2',
    viewBox: '0 0 15 14',
    path: [
        <path
            key="1"
            d="M0.5 4.2H3.5V14H0.5V4.2ZM6.1 0H8.9V14H6.1V0ZM11.7 8H14.5V14H11.7V8Z"
            fill="currentColor"
        />
    ]
})
