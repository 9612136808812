import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { Recordings } from './Recordings'

export const RecordingsDashboard: FC = () => {
    return (
        <>
            <Recordings />
            <Outlet />
        </>
    )
}
