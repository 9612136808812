import { Center, Grid, VStack } from '@chakra-ui/react'
import { Body, Button, Heading } from '@missionlabs/react'
import { getDeploymentEnvironment, isBeta } from '@missionlabs/utils'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AppConfig } from 'shared/utils/env'

export const getPath = (yaml: string, isMac: boolean) => {
    return yaml
        .split(/\r?\n/)
        .find(str => str.includes('path:'))
        ?.split(':')[1]
        .trim()
        .replace('.zip', `.${isMac ? 'dmg' : 'exe'}`)
}

export const DownloadPage: FC = () => {
    const { t } = useTranslation()
    const isMac = navigator.userAgent.includes('Mac')
    const [path, setPath] = useState<string>()
    //Annoyingly the bucket is named prd not prod
    const env = getDeploymentEnvironment()?.replace('prod', 'prd')

    const downloadUrl = AppConfig.downloadBucket.replace('{{env}}', env ?? '')

    useEffect(() => {
        //If on beta app then look for beta builds
        const fileName = isBeta ? 'beta' : 'latest'
        const latestYml = isMac ? `${fileName}-mac.yml` : `${fileName}.yml`
        fetch(`${downloadUrl}/${latestYml}`)
            .then(res => res.text())
            .then(txt => setPath(getPath(txt, isMac)))
    }, [downloadUrl, isMac])

    return (
        <Grid h="100dvh" w="100dvw" as="main">
            <Center>
                <VStack spacing={2}>
                    <Heading size="h1">
                        {/*lets assume __VERSION__ matches the latest download version*/}
                        {AppConfig.name} {__VERSION__}
                    </Heading>
                    <Body>{isMac ? 'Mac' : 'Windows'} Version</Body>
                    <Link to={`${downloadUrl}/${path ?? ''}`} target="_blank">
                        <Button isDisabled={!path} mt={4}>
                            {t('Download')}
                        </Button>
                    </Link>
                </VStack>
            </Center>
        </Grid>
    )
}
