import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PhoneIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PhoneIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M18.9219 12.9141L14.9375 11.1953C14.2344 10.9219 13.375 11.1172 12.9062 11.7422L11.5781 13.3047C9.54688 12.1719 7.82812 10.4531 6.69531 8.42188L8.29688 7.13281C8.88281 6.625 9.07812 5.76563 8.76562 5.0625L7.08594 1.07813C6.73438 0.296877 5.875 -0.132811 5.05469 0.0625019L1.38281 0.921877C0.5625 1.07813 0.015625 1.78125 0.015625 2.64063C0.015625 12.2109 7.78906 19.9844 17.3594 19.9844C18.2188 19.9844 18.9219 19.4375 19.1172 18.6172L19.9375 14.9453C20.1328 14.125 19.7031 13.2656 18.9219 12.9141ZM17.8672 18.3438C17.8281 18.5781 17.6328 18.7734 17.3594 18.7734C8.49219 18.7734 1.26562 11.5078 1.26562 2.64063C1.26562 2.36719 1.42188 2.17188 1.65625 2.13281L5.32812 1.27344C5.36719 1.27344 5.40625 1.23438 5.44531 1.23438C5.64062 1.23438 5.83594 1.39063 5.95312 1.58594L7.63281 5.53125C7.71094 5.76563 7.67188 6 7.47656 6.15625L5.52344 7.75781C5.28906 7.95313 5.21094 8.26563 5.36719 8.53906C6.65625 11.1563 8.84375 13.3438 11.5 14.6328C11.7344 14.7891 12.0469 14.7109 12.2422 14.4766L13.8438 12.5234C14 12.3281 14.2344 12.2891 14.4688 12.3672L18.4141 14.0469C18.6484 14.1641 18.8047 14.3984 18.7266 14.6719L17.8672 18.3438Z"
            fill="currentColor"
        />
    )
})

export const PhoneIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PhoneIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M18.7716 12.5285L14.9435 10.8879C14.0841 10.4973 13.1075 10.7707 12.5216 11.4738L11.4669 12.7629C9.70911 11.7473 8.26379 10.302 7.24817 8.58322L8.57629 7.52854C9.24036 6.9426 9.51379 5.96604 9.16223 5.14572L7.48254 1.23947C7.09192 0.341035 6.11536 -0.166777 5.13879 0.0675979L1.58411 0.88791C0.646606 1.08322 0.0216064 1.90354 0.0216064 2.8801C0.0216064 12.3332 7.67786 19.9895 17.131 19.9895C18.1075 19.9895 18.9279 19.3645 19.0841 18.427L19.9044 14.8723C20.1779 13.8957 19.67 12.9192 18.7716 12.5285ZM18.1075 14.4426L17.2872 17.9973C17.2872 18.0363 17.2091 18.1145 17.131 18.1145C8.73254 18.1145 1.85754 11.2785 1.85754 2.8801C1.85754 2.80197 1.93567 2.72385 2.01379 2.72385L5.56848 1.90354L5.60754 1.86447C5.68567 1.86447 5.72473 1.9426 5.76379 1.98166L7.40442 5.80979C7.44348 5.88791 7.44348 5.96604 7.36536 6.0051L5.45129 7.5676C5.13879 7.84104 5.02161 8.30979 5.21692 8.70041C6.50598 11.3176 8.65442 13.466 11.2716 14.7551C11.6622 14.9504 12.17 14.8723 12.4435 14.5207L14.006 12.6067C14.045 12.5676 14.1232 12.5285 14.2013 12.5676L18.0294 14.2082C18.1075 14.2863 18.1466 14.3645 18.1075 14.4426Z"
            fill="currentColor"
        />
    )
})

export const PhoneIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PhoneIconSolid',
    viewBox: '0 0 20 22',
    path: (
        <path
            d="M19.9681 16.0905L19.0306 20.0358C18.9135 20.6218 18.4447 21.0124 17.8588 21.0124C8.01501 20.9733 0.00720215 12.9655 0.00720215 3.12177C0.00720215 2.53583 0.358765 2.06708 0.944702 1.94989L4.89001 1.01239C5.43689 0.895206 6.02283 1.20771 6.2572 1.71552L8.09314 5.97333C8.28845 6.48114 8.17126 7.06708 7.74158 7.37958L5.6322 9.09833C6.96033 11.7936 9.14783 13.9811 11.8822 15.3093L13.601 13.1999C13.9135 12.8093 14.4994 12.653 15.0072 12.8483L19.265 14.6843C19.7728 14.9577 20.0853 15.5436 19.9681 16.0905Z"
            fill="currentColor"
        />
    )
})
