import { Box, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Heading } from '@missionlabs/react'
import { PlaceholderIcon } from '@missionlabs/react/circleloop'
import { formatDuration } from 'features/analytics/utils/time'
import React from 'react'

export type AnalyticsCardProps = {
    label: string
    total: string | number
    duration: number
    colour: string
}

export const AnalyticsCard: React.FC<React.PropsWithChildren<AnalyticsCardProps>> = ({
    label,
    total,
    duration,
    colour,
    children
}) => {
    const { colorMode } = useColorMode()

    return (
        <Box>
            <VStack spacing={2} justify="space-between" alignItems="flex-start">
                <HStack spacing={2}>
                    <PlaceholderIcon />
                    <Body
                        fontSize="14px"
                        fontWeight={400}
                        color={`${colorMode}.tones.navy`}
                        data-testid="analytics-card-label"
                    >
                        {label}
                    </Body>
                </HStack>
                <HStack width="full" spacing={6} alignItems="center" alignSelf="start">
                    <Box borderRadius="4px" h="56px" w="5px" bg={colour} />
                    <VStack>
                        <Heading
                            fontFamily="body"
                            fontSize="32px"
                            fontWeight={400}
                            data-testid="analytics-card-total"
                            as="p"
                        >
                            {total}
                        </Heading>
                        <Body color={`${colorMode}.tones.stormGrey`} size="sm">
                            {formatDuration(duration)}
                        </Body>
                    </VStack>
                    {children}
                </HStack>
            </VStack>
        </Box>
    )
}
