import {
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay
} from '@missionlabs/react'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'

export interface DeleteSubscriptionGroupProps {
    isOpen: boolean
    onClose: () => void
    handleDelete: () => void
}

export const DeleteSubscriptionGroupDialog: FC<DeleteSubscriptionGroupProps> = ({
    isOpen,
    onClose,
    handleDelete
}) => {
    const { t } = useTranslation()

    const ref = useRef<HTMLButtonElement>(null)

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>{t('notifications.delete.title')}</DialogHeader>
                <DialogBody>{t('notifications.delete.subtitle')}</DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('notifications.delete.confirm'),
                        isDestructive: true,
                        onClick: handleDelete
                    }}
                    isLoading={false}
                />
            </DialogContent>
        </Dialog>
    )
}
