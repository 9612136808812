import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const RefreshIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'RefreshIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7432 7.97721L16.5582 7.28467C15.9361 7.08748 15.3076 7.46056 15.1544 8.11797C15.0012 8.77537 15.3814 9.46816 16.0036 9.66535L19.9656 10.9211C20.415 11.0636 20.8872 10.9102 21.167 10.5308L23.6343 7.18599C24.0217 6.66077 23.9221 5.87682 23.4117 5.435C22.9014 4.99317 22.1736 5.06078 21.7862 5.58601L20.7952 6.92955C19.0108 3.83607 15.6886 1.8501 12.0001 1.8501C6.39441 1.8501 1.8501 6.39441 1.8501 12.0001C1.8501 17.6058 6.39441 22.1501 12.0001 22.1501C16.258 22.1501 20.0184 19.5038 21.4982 15.5858C21.7226 14.9917 21.4229 14.3281 20.8287 14.1037C20.2346 13.8793 19.571 14.179 19.3466 14.7732C18.2022 17.8032 15.2935 19.8501 12.0001 19.8501C7.66466 19.8501 4.1501 16.3355 4.1501 12.0001C4.1501 7.66466 7.66466 4.1501 12.0001 4.1501C14.8126 4.1501 17.3494 5.64264 18.7432 7.97721Z"
                fill="currentColor"
            />
            <mask
                id="mask0_2_2973"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="1"
                y="1"
                width="23"
                height="22"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.7432 7.97721L16.5582 7.28467C15.9361 7.08748 15.3076 7.46056 15.1544 8.11797C15.0012 8.77537 15.3814 9.46816 16.0036 9.66535L19.9656 10.9211C20.415 11.0636 20.8872 10.9102 21.167 10.5308L23.6343 7.18599C24.0217 6.66077 23.9221 5.87682 23.4117 5.435C22.9014 4.99317 22.1736 5.06078 21.7862 5.58601L20.7952 6.92955C19.0108 3.83607 15.6886 1.8501 12.0001 1.8501C6.39441 1.8501 1.8501 6.39441 1.8501 12.0001C1.8501 17.6058 6.39441 22.1501 12.0001 22.1501C16.258 22.1501 20.0184 19.5038 21.4982 15.5858C21.7226 14.9917 21.4229 14.3281 20.8287 14.1037C20.2346 13.8793 19.571 14.179 19.3466 14.7732C18.2022 17.8032 15.2935 19.8501 12.0001 19.8501C7.66466 19.8501 4.1501 16.3355 4.1501 12.0001C4.1501 7.66466 7.66466 4.1501 12.0001 4.1501C14.8126 4.1501 17.3494 5.64264 18.7432 7.97721Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_2_2973)"></g>
        </g>
    )
})
