import { BlockedNumber, NewBlockedNumber, UserBlockedNumberList } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildBlockedNumbersAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'blockedNumbersAPI',
        tagTypes: ['BlockedNumberClientDefaultList', 'BlockedNumberUserList'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getDefaultBlockedNumberList: builder.query<BlockedNumber[], void>({
                query: () => '/blockednumbers',
                transformResponse: (response: { data: BlockedNumber[] }) => response.data,
                providesTags: () => ['BlockedNumberClientDefaultList']
            }),
            createDefaultBlockedNumber: builder.mutation<BlockedNumber[], NewBlockedNumber>({
                query: newNumber => ({
                    url: '/blockednumbers',
                    method: 'POST',
                    body: newNumber
                }),
                transformResponse: (response: { data: BlockedNumber[] }) => response.data,
                invalidatesTags: () => ['BlockedNumberClientDefaultList']
            }),
            batchCreateDefaultBlockedNumbers: builder.mutation<
                BlockedNumber[],
                Omit<NewBlockedNumber, 'numberE164'> & { numberE164: string[] }
            >({
                query: newNumber => ({
                    url: '/blockednumbers',
                    method: 'POST',
                    body: newNumber
                }),
                transformResponse: (response: { data: BlockedNumber[] }) => response.data,
                invalidatesTags: () => ['BlockedNumberClientDefaultList']
            }),
            updateDefaultBlockedNumber: builder.mutation<BlockedNumber[], BlockedNumber>({
                query: blockedNumber => ({
                    url: `/blockednumbers/${blockedNumber.ID}`,
                    method: 'PUT',
                    body: blockedNumber
                }),
                transformResponse: (response: { data: BlockedNumber[] }) => response.data,
                invalidatesTags: () => ['BlockedNumberClientDefaultList']
            }),
            deleteDefaultBlockedNumberList: builder.mutation<
                BlockedNumber[],
                Omit<NewBlockedNumber, 'numberE164'> & { numberE164: string[] }
            >({
                query: list => ({
                    url: '/blockednumbers',
                    method: 'DELETE',
                    body: list
                }),
                transformResponse: (response: { data: BlockedNumber[] }) => response.data,
                invalidatesTags: () => ['BlockedNumberClientDefaultList']
            }),

            deleteDefaultBlockedNumber: builder.mutation<void, string>({
                query: listID => ({
                    url: `/blockednumbers/${listID}`,
                    method: 'DELETE'
                }),
                invalidatesTags: () => ['BlockedNumberClientDefaultList']
            }),
            getUserBlockedNumberList: builder.query<BlockedNumber[], string>({
                query: userID => `/users/${userID}/blockednumbers`,
                transformResponse: (response: { data: BlockedNumber[] }) => response.data,
                providesTags: (result, error, userID) => [
                    { type: 'BlockedNumberUserList', id: userID }
                ]
            }),
            updateUserBlockedNumberList: builder.mutation<BlockedNumber[], UserBlockedNumberList>({
                query: list => ({
                    url: `/users/${list.blockForUserID}/blockednumbers`,
                    method: 'POST',
                    body: list
                }),
                invalidatesTags: (result, error, list) => [
                    { type: 'BlockedNumberUserList', id: list.blockForUserID }
                ]
            }),
            deleteUserBlockedNumberList: builder.mutation<string, UserBlockedNumberList>({
                query: list => ({
                    url: `/users/${list.blockForUserID}/blockednumbers`,
                    method: 'DELETE',
                    body: list
                }),
                invalidatesTags: (result, error, list) => [
                    { type: 'BlockedNumberUserList', id: list.blockForUserID }
                ]
            })
        })
    })

    const {
        useGetDefaultBlockedNumberListQuery,
        useCreateDefaultBlockedNumberMutation,
        useBatchCreateDefaultBlockedNumbersMutation,
        useUpdateDefaultBlockedNumberMutation,
        useDeleteDefaultBlockedNumberListMutation,
        useGetUserBlockedNumberListQuery,
        useUpdateUserBlockedNumberListMutation,
        useDeleteUserBlockedNumberListMutation,
        useDeleteDefaultBlockedNumberMutation
    } = api
    return {
        api,
        useGetDefaultBlockedNumberListQuery,
        useCreateDefaultBlockedNumberMutation,
        useBatchCreateDefaultBlockedNumbersMutation,
        useUpdateDefaultBlockedNumberMutation,
        useDeleteDefaultBlockedNumberListMutation,
        useGetUserBlockedNumberListQuery,
        useUpdateUserBlockedNumberListMutation,
        useDeleteUserBlockedNumberListMutation,
        useDeleteDefaultBlockedNumberMutation
    }
}
