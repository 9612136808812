import { Button, HStack, VStack } from '@chakra-ui/react'
import {
    Dropdown,
    FormProvider,
    useToast,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { PostWhatsAppRequestCodeRequest } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { usePostWhatsAppRequestCodeMutation } from 'shared/store'

export interface RequestWhatsAppCodeFormProps {}

export const RequestWhatsAppCodeForm: FC<RequestWhatsAppCodeFormProps> = () => {
    const { t } = useTranslation()
    const { toast } = useToast()
    const { id: numberID = '' } = useParams()
    const [requestCode, { isLoading }] = usePostWhatsAppRequestCodeMutation()

    const values: PostWhatsAppRequestCodeRequest = {
        ID: numberID,
        codeType: '',
        language: 'en_US'
    }

    const codeTypes = [
        { label: 'Send me an SMS', value: 'SMS' },
        { label: 'Call me', value: 'VOICE' }
    ]

    async function onSubmit(data: PostWhatsAppRequestCodeRequest) {
        try {
            await requestCode(data)
            toast({
                title: t('admin.whatsapp.requestCode.success'),
                status: 'success'
            })
        } catch (error) {
            console.error('Error requesting code', error)
            toast({
                title: t('admin.whatsapp.requestCode.error'),
                status: 'error'
            })
        }
    }

    const schema = yup.object({
        codeType: yup.string().required().oneOf(['SMS', 'VOICE'])
    })

    const methods = useYupForm<PostWhatsAppRequestCodeRequest>(schema, {
        values
    })

    const {
        setValue,
        watch,
        formState: { isDirty }
    } = methods

    return (
        <FormProvider {...methods}>
            <VStack
                as="form"
                h="full"
                align="stretch"
                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <VStack align="start" py={4}>
                    <ValidatedInput name="verifyMethod">
                        <Dropdown
                            width={312}
                            options={codeTypes}
                            value={watch('codeType')}
                            onChange={value =>
                                setValue('codeType', value, {
                                    shouldDirty: true,
                                    shouldTouch: true
                                })
                            }
                        />
                    </ValidatedInput>
                </VStack>

                <HStack pb={8}>
                    <Button
                        type="submit"
                        size="lg"
                        variant="creationary"
                        isDisabled={!isDirty}
                        isLoading={isLoading}
                    >
                        {t('admin.whatsapp.requestCode.fields.submit')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}
