import {
    Box,
    FormControl as ChakraFormControl,
    FormControlProps as ChakraFormControlProps,
    FormHelperText,
    FormLabel,
    SystemStyleObject,
    useMultiStyleConfig
} from '@chakra-ui/react'
import {
    Children,
    cloneElement,
    forwardRef,
    isValidElement,
    PropsWithChildren,
    ReactNode
} from 'react'

import { ErrorMessage } from './ErrorMessage'

export interface FormControlProps extends ChakraFormControlProps {
    variant?: 'light' | 'dark'
    name?: string
    error?: string
    htmlFor?: string
    helperText?: ReactNode
    containerSx?: SystemStyleObject
    errorSx?: SystemStyleObject
    labelSx?: SystemStyleObject
}

export const FormControl = forwardRef<HTMLDivElement, PropsWithChildren<FormControlProps>>(
    (
        {
            variant = 'light',
            name = '',
            label,
            helperText,
            error,
            htmlFor,
            containerSx,
            errorSx,
            labelSx,
            children,
            ...props
        },
        ref
    ) => {
        const styles = useMultiStyleConfig('CustomInput', { variant })
        return (
            <ChakraFormControl
                ref={ref}
                {...props}
                sx={{ ...styles.container, ...(containerSx ?? {}) }}
            >
                {!!label && (
                    <FormLabel
                        id={`${name}-label`}
                        htmlFor={htmlFor || name}
                        sx={{ ...styles.label, ...(labelSx ?? {}) }}
                    >
                        {label}
                    </FormLabel>
                )}

                {Children.map(children, child =>
                    isValidElement(child)
                        ? cloneElement(child, {
                              ...(label ? { 'aria-labelledby': `${name}-label` } : {}),
                              ...child.props,
                              id: name,
                              variant
                          })
                        : null
                )}

                {helperText && <FormHelperText>{helperText}</FormHelperText>}

                {!!error && (
                    <Box sx={errorSx}>
                        <ErrorMessage error={error} />
                    </Box>
                )}
            </ChakraFormControl>
        )
    }
)

FormControl.displayName = 'FormControl'
