import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const TooltipIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TooltipIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M5.995 11C3.23423 10.9972 0.998162 8.75744 1 5.99667C1.00184 3.2359 3.24089 0.999081 6.00167 1C8.76244 1.00092 11 3.23923 11 6C10.9983 8.76269 8.75769 11.0011 5.995 11ZM2 6.086C2.02366 8.28662 3.82055 10.0548 6.02127 10.043C8.22199 10.0311 9.99977 8.24375 9.99977 6.043C9.99977 3.84225 8.22199 2.05488 6.02127 2.043C3.82055 2.03123 2.02366 3.79938 2 6V6.086ZM6.5 8.5H5.5V7.5H6.5V8.5ZM6.5 6.5H5.5V3.5H6.5V6.5Z"
            fill="currentColor"
        />
    )
})
