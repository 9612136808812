import './array'
import './extension'
import './phone'

import * as yup from 'yup'

import { locale } from './locale'

yup.setLocale(locale)

export default yup
