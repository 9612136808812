import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { DocumentTitle } from 'shared/components/DocumentTitle'
import { MainLayout } from 'shared/components/MainLayout'

import { AdminSidebar } from './AdminSidebar'

export interface AdminDashboardProps {}

export const AdminDashboard: FC<AdminDashboardProps> = () => {
    return (
        <>
            <DocumentTitle breadcrumbs={['Admin Settings']} />
            <MainLayout left={<AdminSidebar />} leftWidth={320} right={<Outlet />} />
        </>
    )
}
