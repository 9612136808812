import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const UploadIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'UploadIconLight',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M8.53125 0.195312L2.90625 5.82031C2.67188 6.05469 2.67188 6.48438 2.90625 6.71875C3.14062 6.95312 3.57031 6.95312 3.80469 6.71875L8.375 2.14844V13.125C8.375 13.4766 8.64844 13.75 9 13.75C9.3125 13.75 9.625 13.4766 9.625 13.125V2.14844L14.1562 6.71875C14.3906 6.95312 14.8203 6.95312 15.0547 6.71875C15.2891 6.48438 15.2891 6.05469 15.0547 5.82031L9.42969 0.195312C9.3125 0.078125 9.15625 0 9 0C8.80469 0 8.64844 0.078125 8.53125 0.195312ZM16.5 13.125V16.875C16.5 17.9297 15.6406 18.75 14.625 18.75H3.375C2.32031 18.75 1.5 17.9297 1.5 16.875V13.125C1.5 12.8125 1.1875 12.5 0.875 12.5C0.523438 12.5 0.25 12.8125 0.25 13.125V16.875C0.25 18.6328 1.61719 20 3.375 20H14.625C16.3438 20 17.75 18.6328 17.75 16.875V13.125C17.75 12.8125 17.4375 12.5 17.125 12.5C16.7734 12.5 16.5 12.8125 16.5 13.125Z"
            fill="currentColor"
        />
    )
})

export const UploadIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'UploadIconRegular',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M16.8125 12.4929C17.3203 12.4929 17.75 12.9226 17.75 13.4304V16.2429C17.75 18.3522 16.0703 19.9929 14 19.9929H4C1.89062 19.9929 0.25 18.3522 0.25 16.2429V13.4304C0.25 12.9226 0.640625 12.4929 1.1875 12.4929C1.69531 12.4929 2.125 12.9226 2.125 13.4304V16.2429C2.125 17.2976 2.94531 18.1179 4 18.1179H14C15.0156 18.1179 15.875 17.2976 15.875 16.2429V13.4304C15.875 12.9226 16.2656 12.4929 16.8125 12.4929ZM8.33594 0.266306L3.02344 5.22724C2.63281 5.61787 2.63281 6.20381 2.98438 6.59443C3.33594 6.98506 3.92188 6.98506 4.3125 6.63349L8.0625 3.11787V12.8054C8.0625 13.3522 8.45312 13.7429 9 13.7429C9.50781 13.7429 9.9375 13.3522 9.9375 12.8444V3.11787L13.6484 6.63349C14.0391 6.98506 14.625 6.98506 14.9766 6.59443C15.1328 6.39912 15.25 6.16474 15.25 5.93037C15.25 5.69599 15.1328 5.46162 14.9375 5.26631L9.625 0.305369C9.27344 -0.0852564 8.6875 -0.0852564 8.33594 0.266306Z"
            fill="currentColor"
        />
    )
})

export const UploadIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'UploadIconSolid',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M15.25 13.7451V16.2451C15.25 16.9482 14.6641 17.4951 14 17.4951H4C3.29688 17.4951 2.75 16.9482 2.75 16.2451V13.7451C2.75 13.0811 2.16406 12.4951 1.5 12.4951C0.796875 12.4951 0.25 13.0811 0.25 13.7451V16.2451C0.25 18.3545 1.89062 19.9951 4 19.9951H14C16.0703 19.9951 17.75 18.3545 17.75 16.2451V13.7451C17.75 13.0811 17.1641 12.4951 16.5 12.4951C15.7969 12.4951 15.25 13.0811 15.25 13.7451ZM8.10156 0.385742L3.10156 5.38574C2.59375 5.85449 2.59375 6.6748 3.10156 7.14355C3.57031 7.65137 4.39062 7.65137 4.85938 7.14355L7.75 4.29199V12.4951C7.75 13.1982 8.29688 13.7451 9 13.7451C9.66406 13.7451 10.25 13.1982 10.25 12.4951V4.29199L13.1016 7.14355C13.5703 7.65137 14.3906 7.65137 14.8594 7.14355C15.3672 6.6748 15.3672 5.85449 14.8594 5.38574L9.85938 0.385742C9.39062 -0.12207 8.57031 -0.12207 8.10156 0.385742Z"
            fill="currentColor"
        />
    )
})
