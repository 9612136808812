import { HStack, useColorMode } from '@chakra-ui/react'
import {
    admitGuest,
    rejectGuest,
    selectActiveMeeting,
    selectAuthenticatedUser,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { Participant, useAttendeeStatus } from '@missionlabs/meetings'
import { ContactDetail, IconButton, ListItem, useMeasure } from '@missionlabs/react'
import {
    CheckCircleIconSolid,
    MicrophoneIconSolid,
    MicrophoneSlashIconSolid,
    XCircleIconSolid
} from '@missionlabs/react/zeta'
import { FC } from 'react'

import { MeetingsCallParticipantsMenu } from './MeetingsCallParticipantsMenu'

export interface MeetingsCallParticipantsRowProps {
    data: Participant
}

export const MeetingsCallParticipantsRow: FC<MeetingsCallParticipantsRowProps> = ({ data }) => {
    const dispatch = useDispatch()
    const { colorMode } = useColorMode()
    const { muted } = useAttendeeStatus(data.chimeAttendeeId ?? '')
    const [buttonsRef, { width: buttonsWidth }] = useMeasure<HTMLDivElement>()

    const meeting = useSelector(selectActiveMeeting)
    const user = useSelector(selectAuthenticatedUser)

    function handleAdmit() {
        if (!meeting?.meetingID) return
        dispatch(admitGuest({ meetingID: meeting.meetingID, guestUserID: data.externalUserId }))
    }

    function handleReject() {
        if (!meeting?.meetingID) return
        dispatch(rejectGuest({ meetingID: meeting.meetingID, guestUserID: data.externalUserId }))
    }

    function renderButtons() {
        if (data.inWaitingRoom && !user?.isGuest) {
            return (
                <>
                    <IconButton
                        size="sm"
                        variant="transparent"
                        aria-label={`Admit ${data.name}`}
                        onClick={handleAdmit}
                    >
                        <CheckCircleIconSolid boxSize="20px" color={`${colorMode}.alerts.aqua`} />
                    </IconButton>
                    <IconButton
                        size="sm"
                        variant="transparent"
                        aria-label={`Reject ${data.name}`}
                        onClick={handleReject}
                    >
                        <XCircleIconSolid boxSize="20px" color={`${colorMode}.alerts.red`} />
                    </IconButton>
                </>
            )
        }

        if (data.chimeAttendeeId) {
            return (
                <>
                    <IconButton
                        size="sm"
                        variant="transparent"
                        aria-label={`${data.name} ${muted ? 'muted' : 'unmuted'}`}
                    >
                        {muted ? (
                            <MicrophoneSlashIconSolid />
                        ) : (
                            <MicrophoneIconSolid color={`${colorMode}.alerts.red`} />
                        )}
                    </IconButton>
                    <MeetingsCallParticipantsMenu
                        chimeAttendeeID={data.chimeAttendeeId}
                        externalUserId={data.externalUserId}
                    />
                </>
            )
        }

        return null
    }

    return (
        <ListItem>
            <ContactDetail
                name={data.name}
                subText={data.companyName ?? data.type}
                sx={{ minWidth: `calc(100% - ${buttonsWidth}px)` }}
            />
            <HStack spacing={0} ref={buttonsRef}>
                {renderButtons()}
            </HStack>
        </ListItem>
    )
}
