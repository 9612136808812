import { useUpdateParams } from '@missionlabs/react'
import { createContext, FC, PropsWithChildren, useCallback, useContext } from 'react'

export enum Filter {
    ALL = 'all',
    INTERNAL = 'internal',
    EXTERNAL = 'external',
    TEAMS = 'teams'
}

interface IContactsFilterContext {
    filter: Filter
    setFilter: (filter: Filter) => void
}

export const ContactsFilterContext = createContext<IContactsFilterContext>({
    filter: Filter.ALL,
    setFilter: () => null
})

export const ContactsFilterProvider: FC<PropsWithChildren> = ({ children }) => {
    const [searchParams, updateSearchParams] = useUpdateParams()
    const filter = (searchParams.get('filter') as Filter) ?? Filter.ALL

    const setFilter = useCallback(
        (value: Filter) => updateSearchParams({ filter: value }),
        [updateSearchParams]
    )

    return (
        <ContactsFilterContext.Provider value={{ filter, setFilter }}>
            {children}
        </ContactsFilterContext.Provider>
    )
}

export function useContactsFilters() {
    const ctx = useContext(ContactsFilterContext)
    if (!ctx) {
        throw Error(
            'No ContactsFilterContext exists. Component must be wrapped in <ContactsFilterProvider>'
        )
    }
    return ctx
}
