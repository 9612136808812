import { Box } from '@chakra-ui/react'
import { Input, Scrollable } from '@missionlabs/react'
import { SearchIcon } from '@missionlabs/react/circleloop'
import { DirectoryEntry } from '@missionlabs/types'
import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { useContactsSearch } from 'shared/hooks/useContactsSearch'

import { ContactListRenderer } from './ContactListRenderer'
import { SelectedContact, VirtualContactRow } from './ContactRow'

interface ContactListProps {
    onSelect: (selectedContact: SelectedContact) => void
    selectedContact?: SelectedContact
}

export const ContactList: FC<ContactListProps> = ({ onSelect, selectedContact }) => {
    const { t } = useTranslation()

    const [searchContacts, { data: contacts, isLoading }] = useContactsSearch()

    const [contactSearch, setContactSearch] = useState('')

    const handleSearchChange = useCallback(
        (searchTerm: string) => {
            setContactSearch(searchTerm)
            searchContacts({ searchTerm })
        },
        [searchContacts]
    )

    const virtualContacts: VirtualContactRow[] = useMemo(() => {
        const contactsList = [...contacts]
        if (!isLoading && !contacts.length && contactSearch) {
            const unknownContact = {
                ID: `Unknown-${contactSearch}`,
                fullName: contactSearch,
                jobTitle: 'Unknown',
                phoneNumbers: [{ numberE164: contactSearch }]
            }

            contactsList.push(unknownContact as DirectoryEntry)
        }
        return contactsList.map(contact => ({ contact, onSelect, selectedContact }))
    }, [isLoading, contactSearch, contacts, onSelect, selectedContact])

    return (
        <>
            <Input
                value={contactSearch}
                onChange={e => handleSearchChange(e.target.value)}
                placeholder={t('Search name or number')}
                rightIcon={<SearchIcon />}
                mb="12px"
            />

            <Box h="calc(100dvh - 500px)" w="full">
                <AutoSizer>
                    {({ height, width }) => (
                        <FixedSizeList
                            height={height}
                            width={width}
                            itemSize={70}
                            itemCount={virtualContacts.length}
                            itemData={virtualContacts}
                            outerElementType={Scrollable}
                        >
                            {ContactListRenderer}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </Box>
        </>
    )
}
