import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const GIFIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'GIFIconLight',
    viewBox: '0 0 20 11',
    path: [
        <path
            d="M4.94142 10.0355C3.85875 10.0355 2.94727 9.83656 2.20698 9.43866C1.47595 9.0315 0.925359 8.45315 0.555215 7.70361C0.185072 6.95407 0 6.0611 0 5.0247C0 3.9883 0.194325 3.09532 0.582976 2.34578C0.98088 1.59624 1.54535 1.01789 2.27638 0.610737C3.01667 0.203579 3.90501 0 4.94142 0C5.65394 0 6.27393 0.10179 6.80139 0.305369C7.32884 0.499694 7.80077 0.786555 8.21719 1.16595L7.70361 2.29026C7.4075 2.04042 7.12063 1.84146 6.84303 1.69341C6.56542 1.54535 6.27856 1.43893 5.98244 1.37416C5.68633 1.30013 5.33932 1.26311 4.94142 1.26311C3.82173 1.26311 2.9704 1.59162 2.38743 2.24862C1.80445 2.90563 1.51296 3.83561 1.51296 5.03858C1.51296 6.26005 1.79057 7.19004 2.34578 7.82853C2.91025 8.46703 3.79397 8.78628 4.99694 8.78628C5.41335 8.78628 5.82513 8.74927 6.23229 8.67524C6.63945 8.59196 7.03273 8.47166 7.41212 8.31435L7.13452 8.99449V5.78812H4.71933V4.70545H8.34211V9.32761C7.91644 9.54045 7.39362 9.71164 6.77363 9.84119C6.16289 9.97074 5.55215 10.0355 4.94142 10.0355Z"
            fill="currentColor"
            key="1"
        />,
        <path d="M10.2625 9.91059V0.124923H11.706V9.91059H10.2625Z" fill="currentColor" key="2" />,
        <path
            d="M13.7261 9.91059V0.124923H20V1.277H15.1696V4.41396H19.6807V5.56603H15.1696V9.91059H13.7261Z"
            fill="currentColor"
            key="3"
        />
    ]
})

export const GIFIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'GIFIconRegular',
    viewBox: '0 0 20 10',
    path: [
        <path
            d="M4.81 9.451C3.76133 9.451 2.87733 9.26033 2.158 8.879C1.44733 8.489 0.91 7.943 0.546 7.241C0.182 6.53033 0 5.70267 0 4.758C0 3.78733 0.195 2.94667 0.585 2.236C0.983667 1.52533 1.55567 0.975 2.301 0.585C3.04633 0.195 3.939 0 4.979 0C5.64633 0 6.23133 0.0866666 6.734 0.26C7.24533 0.424666 7.69167 0.663 8.073 0.975L7.423 2.548C7.14567 2.34867 6.88567 2.19267 6.643 2.08C6.40033 1.95867 6.149 1.87633 5.889 1.833C5.629 1.781 5.33 1.755 4.992 1.755C4.03867 1.755 3.31933 2.015 2.834 2.535C2.35733 3.04633 2.119 3.79167 2.119 4.771C2.119 5.74167 2.34433 6.47833 2.795 6.981C3.24567 7.48367 3.952 7.735 4.914 7.735C5.23467 7.735 5.564 7.70467 5.902 7.644C6.24 7.58333 6.57367 7.49233 6.903 7.371L6.539 8.151V5.681H4.524V4.238H8.216V8.736C7.75667 8.96133 7.22367 9.139 6.617 9.269C6.019 9.39033 5.41667 9.451 4.81 9.451Z"
            fill="currentColor"
            key="1"
        />,
        <path d="M9.69952 9.308V0.143H11.7275V9.308H9.69952Z" fill="currentColor" key="2" />,
        <path
            d="M13.3243 9.308V0.143H19.5513V1.716H15.3523V3.952H19.2523V5.512H15.3523V9.308H13.3243Z"
            fill="currentColor"
            key="3"
        />
    ]
})

export const GIFIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'GIFIconSolid',
    viewBox: '0 0 20 10',
    path: [
        <path
            d="M4.9239 9.44506C3.85857 9.44506 2.95781 9.25018 2.2216 8.86042C1.49406 8.47067 0.939742 7.92501 0.558648 7.22345C0.186216 6.5219 0 5.70341 0 4.768C0 3.79794 0.203539 2.95781 0.610616 2.24759C1.02635 1.5287 1.61965 0.974387 2.3905 0.584632C3.16134 0.194878 4.08809 0 5.17075 0C5.83766 0 6.42662 0.0779511 6.93763 0.233853C7.45731 0.389755 7.90336 0.614947 8.27579 0.909428L7.53526 2.74128C7.26676 2.55939 7.01125 2.42081 6.76874 2.32554C6.53489 2.2216 6.29237 2.14798 6.0412 2.10468C5.79868 2.06137 5.51719 2.03972 5.19673 2.03972C4.29596 2.03972 3.62039 2.27357 3.17001 2.74128C2.72828 3.20032 2.50742 3.87589 2.50742 4.768C2.50742 5.65144 2.71096 6.31836 3.11804 6.76874C3.52511 7.21912 4.17038 7.44432 5.05382 7.44432C5.32232 7.44432 5.61247 7.41833 5.92427 7.36636C6.24474 7.3144 6.56953 7.23645 6.89866 7.13251L6.46993 7.98997V5.80734H4.57312V4.14439H8.43169V8.73051C7.938 8.96436 7.38369 9.14191 6.76874 9.26317C6.16246 9.38443 5.54751 9.44506 4.9239 9.44506Z"
            fill="currentColor"
            key="1"
        />,
        <path d="M9.73088 9.30215V0.14291H12.1344V9.30215H9.73088Z" fill="currentColor" key="2" />,
        <path
            d="M13.569 9.30215V0.14291H20V1.97476H15.9725V3.83259H19.7012V5.66444H15.9725V9.30215H13.569Z"
            fill="currentColor"
            key="3"
        />
    ]
})
