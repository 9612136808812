import { DetailedState, MeetingStatus, ScheduledMeeting } from '@missionlabs/types'
import { getMillisecondsUnix } from '@missionlabs/utils'
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useGetPresence } from 'shared/hooks/useGetPresence'

const SHOW_BUTTON_TIME = 300000 // amount of time before the meeting we show the "Join meeting" button, 300000 is 5 mins

export const useGetMeetingData = (
    meeting: ScheduledMeeting | undefined,
    allMeetings?: ScheduledMeeting[],
    userID?: string
) => {
    const { detailedState, subscriptions } = useGetPresence(userID)

    const isInAnotherMeeting = useMemo(
        () => detailedState === DetailedState.INMEETING,
        [detailedState]
    )

    const userNotInThisMeeting = useMemo(() => {
        if (!isInAnotherMeeting) return true
        return subscriptions?.find(subscription =>
            subscription.currentMeetingIDs?.find(ID => !!ID && ID !== meeting?.ID)
        )
    }, [subscriptions, meeting, isInAnotherMeeting])

    const startTime = useMemo(
        () => new Date(getMillisecondsUnix(meeting?.startTime)),
        [meeting?.startTime]
    )
    const endTime = useMemo(
        () => new Date(getMillisecondsUnix(meeting?.endTime)),
        [meeting?.endTime]
    )

    const [currentTime, setCurrentTime] = useState<Date>(new Date())

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date())
        }, 1000)

        return () => clearInterval(intervalId)
    }, [setCurrentTime])

    const hasExpired = useMemo(() => endTime < currentTime, [endTime, currentTime])

    const hasStarted = useMemo(() => startTime < currentTime, [startTime, currentTime])

    const isJoinable = useMemo(
        () =>
            userNotInThisMeeting &&
            startTime.getTime() - SHOW_BUTTON_TIME <= currentTime.getTime() &&
            currentTime < endTime,
        [startTime, currentTime, endTime, userNotInThisMeeting]
    )

    const meetingOverlaps = useMemo(() => {
        return allMeetings?.some(otherMeeting => {
            if (otherMeeting.ID !== meeting?.ID) {
                const otherStartTime = new Date(otherMeeting.startTime)
                const otherEndTime = new Date(otherMeeting.endTime)

                if (otherEndTime < currentTime) return false

                return (
                    (otherStartTime <= startTime && startTime < otherEndTime) ||
                    (otherStartTime < endTime && endTime <= otherEndTime) ||
                    (startTime <= otherStartTime && otherEndTime <= endTime)
                )
            }
            return false
        })
    }, [allMeetings, currentTime, startTime, endTime, meeting?.ID])

    const time = useMemo(() => {
        const formattedStartTime = format(
            new Date(getMillisecondsUnix(meeting?.startTime)),
            'HH:mm'
        )
        const formattedEndTime = format(new Date(getMillisecondsUnix(meeting?.endTime)), 'HH:mm')

        return `${formattedStartTime} - ${formattedEndTime}`
    }, [meeting])

    const isInternalAndExternal = useMemo(() => {
        return meeting?.meetingSettings.allowGuestUsers === 'ALLOW'
    }, [meeting])

    const isCancelled = useMemo(
        () => meeting?.status === MeetingStatus.CANCELLED,
        [meeting?.status]
    )

    const participantsCount = useMemo(() => {
        return meeting?.invitedUsers.length ?? 0
    }, [meeting])

    return {
        hasExpired,
        isJoinable,
        meetingOverlaps,
        time,
        isInternalAndExternal,
        isInAnotherMeeting,
        isCancelled,
        participantsCount,
        hasStarted
    }
}
