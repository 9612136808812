import { MS_ONE_DAY } from '@missionlabs/utils'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Section } from 'shared/types/feed'

export const isSection = (x: any): x is Section =>
    typeof x === 'object' && 'object' in x && x.object === 'section'

export const useSectionTitle = (section: Section) => {
    const { t } = useTranslation()

    const title = useMemo(() => {
        if (typeof section.title === 'string') return section.title

        const startOfToday = new Date(Date.now()).setUTCHours(0, 0, 0, 0).valueOf()
        const diff = Math.floor(startOfToday - section.startOfDay)

        if (diff === 0) return t('Today')
        if (diff === MS_ONE_DAY) return t('Yesterday')
        return format(section.startOfDay, 'dd/MM/yyyy')
    }, [section, t])

    return title
}
