import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps,
    useToast
} from '@missionlabs/react'
import { BlockedNumber } from '@missionlabs/types'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import {
    useCreateDefaultBlockedNumberMutation,
    useDeleteDefaultBlockedNumberMutation,
    useUpdateDefaultBlockedNumberMutation
} from 'shared/store'

import {
    BlockedNumberForm,
    BlockedNumberFormFields,
    defaultValues
} from '../BlockedNumbers/BlockedNumbersForm'

export interface BlockedNumbersDrawerProps extends Omit<DrawerProps, 'children'> {
    blockedNumber?: BlockedNumber
}

export const BlockedNumbersDrawer: FC<BlockedNumbersDrawerProps> = ({
    isOpen,
    blockedNumber,
    onClose,
    ...props
}) => {
    const { t } = useTranslation()
    const { formatToNumberE164 } = useFormatToNumberE164()
    const { toast } = useToast()

    const [createBlockedNumber, { isLoading: isCreating }] = useCreateDefaultBlockedNumberMutation()
    const [updateBlockedNumber, { isLoading: isUpdating }] = useUpdateDefaultBlockedNumberMutation()
    const [deleteBlockedNumber, { isLoading: isDeleting }] = useDeleteDefaultBlockedNumberMutation()

    const isLoading = isCreating || isUpdating || isDeleting

    const initialValues = useMemo(() => {
        if (!blockedNumber) return
        return {
            label: blockedNumber?.label ?? defaultValues.label,
            inbound: blockedNumber.inbound ?? defaultValues.inbound,
            outbound: blockedNumber.outbound ?? defaultValues.outbound,
            numberE164: formatToNumberE164(blockedNumber?.numberE164) ?? defaultValues.numberE164
        }
    }, [blockedNumber, formatToNumberE164])

    async function onSubmit(data: BlockedNumberFormFields) {
        try {
            const { numberE164 } = data
            if (blockedNumber?.ID) {
                await updateBlockedNumber({
                    ...blockedNumber,
                    ...data,
                    numberE164: formatToNumberE164(numberE164)
                }).unwrap()
            } else {
                await createBlockedNumber({ ...data, numberE164: formatToNumberE164(numberE164) })
            }

            onClose()
        } catch {
            console.error('Error updating blocked numbers list')
            toast({
                title: t('blockedNumbers.blockedNumberError'),
                status: 'error'
            })
        }
    }

    async function onDelete() {
        try {
            if (blockedNumber?.ID) {
                await deleteBlockedNumber(blockedNumber.ID)
            }
        } catch {
            console.error('Error updating blocked numbers list')
            toast({
                title: t('blockedNumbers.blockedNumberError'),
                status: 'error'
            })
        } finally {
            onClose()
        }
    }

    return (
        <Drawer {...props} size="md" isOpen={isOpen} onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>{t('blockedNumbers.addBlockedNumber')}</DrawerHeader>
                <DrawerBody>
                    <BlockedNumberForm
                        values={initialValues}
                        onClose={onClose}
                        isLoading={isLoading}
                        onDelete={onDelete}
                        onSubmit={onSubmit}
                    />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
