const currencyFormatters = new Map<string, Intl.NumberFormat>()

/** @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat */
function getCurrencyFormatter(locale: string, currency: string) {
    const hashKey = `${locale}-${currency}`
    let formatter = currencyFormatters.get(hashKey)
    if (!formatter) {
        // Only create the formatter if it doesn't already exist for the given locale and currency
        formatter = new Intl.NumberFormat(locale, { style: 'currency', currency })
        currencyFormatters.set(hashKey, formatter)
    }
    return formatter
}

export function formatCurrency({ locale = 'en-GB', amount = 0, currency = 'GBP' }) {
    return getCurrencyFormatter(locale, currency).format(amount)
}
