import { AlertDialogFooter, ModalFooterProps, useModalContext } from '@chakra-ui/react'
import { Button, ButtonProps } from 'atoms'
import { FC, RefObject } from 'react'

export type DialogFooterProps = Omit<ModalFooterProps, 'children'> & {
    cancel: {
        ref: RefObject<HTMLButtonElement>
        label: string
        onClick?: () => void
    }
    confirm?: {
        label: string
        isDestructive?: boolean
        isDisabled?: boolean
        'data-testid'?: string
        variant?: ButtonProps['variant']
        onClick?: () => void | Promise<void>
    }
    isDisabled?: boolean
    isLoading?: boolean
}

export const DialogFooter: FC<DialogFooterProps> = ({
    cancel,
    confirm,
    isDisabled,
    isLoading,
    ...props
}) => {
    const { onClose } = useModalContext()
    return (
        <AlertDialogFooter {...props}>
            <Button
                ref={cancel.ref}
                size="lg"
                width="full"
                variant="secondary"
                isDisabled={isDisabled ?? isLoading}
                isLoading={!confirm && isLoading}
                onClick={cancel?.onClick ?? onClose}
            >
                {cancel.label}
            </Button>
            {confirm && (
                <Button
                    size="lg"
                    width="full"
                    variant={confirm.isDestructive ? 'negatory' : confirm.variant ?? 'creationary'}
                    isDisabled={isDisabled || confirm.isDisabled}
                    isLoading={isLoading}
                    onClick={() => confirm.onClick?.()}
                    data-testid={confirm['data-testid']}
                >
                    {confirm.label}
                </Button>
            )}
        </AlertDialogFooter>
    )
}
