import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const AddIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddIconLight',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M17.125 8.96094C17.125 9.3125 16.8125 9.58594 16.5 9.58594H9.625V16.4609C9.625 16.8125 9.3125 17.125 9 17.125C8.64844 17.125 8.375 16.8125 8.375 16.4609V9.58594H1.5C1.14844 9.58594 0.875 9.3125 0.875 9C0.875 8.64844 1.14844 8.33594 1.5 8.33594H8.375V1.46094C8.375 1.14844 8.64844 0.875 9 0.875C9.3125 0.875 9.625 1.14844 9.625 1.46094V8.33594H16.5C16.8125 8.33594 17.125 8.64844 17.125 8.96094Z"
            fill="currentColor"
        />
    )
})

export const AddIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddIconRegular',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M17.125 8.96094C17.125 9.50781 16.6953 9.89844 16.1875 9.89844H9.9375V16.1484C9.9375 16.6953 9.50781 17.125 9 17.125C8.45312 17.125 8.0625 16.6953 8.0625 16.1484V9.89844H1.8125C1.26562 9.89844 0.875 9.50781 0.875 9C0.875 8.45312 1.26562 8.02344 1.8125 8.02344H8.0625V1.77344C8.0625 1.26562 8.45312 0.875 9 0.875C9.50781 0.875 9.9375 1.26562 9.9375 1.77344V8.02344H16.1875C16.6953 8.02344 17.125 8.45312 17.125 8.96094Z"
            fill="currentColor"
        />
    )
})

export const AddIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddIconSolid',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M17.125 9C17.125 9.70312 16.5391 10.2891 15.875 10.2891H10.25V15.9141C10.25 16.5781 9.66406 17.125 9 17.125C8.29688 17.125 7.75 16.5781 7.75 15.9141V10.2891H2.125C1.42188 10.2891 0.875 9.70312 0.875 9C0.875 8.33594 1.42188 7.78906 2.125 7.78906H7.75V2.16406C7.75 1.46094 8.29688 0.875 9 0.875C9.66406 0.875 10.25 1.46094 10.25 2.16406V7.78906H15.875C16.5391 7.75 17.125 8.33594 17.125 9Z"
            fill="currentColor"
        />
    )
})
