import { Divider, HStack, VStack } from '@chakra-ui/react'
import { Button, FormProvider, Scrollable, useYupForm, yup } from '@missionlabs/react'
import { DirectoryEntry } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetClientQuery } from 'shared/store'

import { AddEditContactDetails } from './AddEditContactDetails'
import { AddEditContactPhoneNumbers } from './AddEditContactPhoneNumbers'

export type AddEditContactFormFields = Required<
    Pick<
        DirectoryEntry,
        'firstName' | 'lastName' | 'companyName' | 'jobTitle' | 'phoneNumbers' | 'tags'
    > & {
        title: string // TODO: This needs adding to the backend
        email: string
        shared: boolean
    }
>

export const defaultValues: AddEditContactFormFields = {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumbers: [{ _default: true, label: undefined, numberE164: '' }],
    companyName: '',
    jobTitle: '',
    tags: [],
    shared: false
}

export interface AddEditContactFormProps {
    values?: AddEditContactFormFields
    isCreate?: boolean
    canEdit?: boolean
    onClose: () => void
    onSubmit: (data: AddEditContactFormFields) => Promise<void>
}

export const AddEditContactForm: FC<AddEditContactFormProps> = ({
    values,
    isCreate = false,
    canEdit = true,
    onClose,
    onSubmit
}) => {
    const { t } = useTranslation()

    const { data: client } = useGetClientQuery()

    const addEditContactSchema = yup.object({
        title: yup.string(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        email: yup.string().email(),
        phoneNumbers: yup
            .array(
                yup.object({
                    label: yup.string().defined({ key: 'validations.required' }),
                    numberE164: yup
                        .string()
                        // todo: should we just be accepting any "possible" phone number here?
                        .phone(client?.locale ?? 'GB')
                        .required()
                })
            )
            .min(1),
        companyName: yup.string(),
        jobTitle: yup.string(),
        tags: yup
            .array(yup.string())
            .test('unique', 'Only unique tags are allowed', value =>
                value ? value.length === new Set(value)?.size : true
            ),
        shared: yup.boolean()
    })

    const methods = useYupForm<AddEditContactFormFields>(addEditContactSchema, {
        defaultValues: defaultValues,
        values: values
    })
    const {
        formState: { isDirty, isSubmitting }
    } = methods

    function handleSubmit(data: AddEditContactFormFields) {
        // remove useFieldArray IDs from phone number data
        const sanitizedData: AddEditContactFormFields = {
            ...data,
            phoneNumbers: data.phoneNumbers.map(item => ({
                label: item.label,
                numberE164: item.numberE164
            }))
        }
        return onSubmit(sanitizedData)
    }

    return (
        <FormProvider {...methods}>
            <VStack
                as="form"
                h="full"
                spacing="32px"
                align="stretch"
                justify="space-between"
                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                <Scrollable h="calc(100% - 72px)" anchor={!isCreate}>
                    <VStack p="24px" spacing="24px" align="inherit">
                        <AddEditContactDetails />
                        <Divider />
                        <AddEditContactPhoneNumbers />
                    </VStack>
                </Scrollable>

                <HStack px="24px" pb="24px" spacing="16px" align="inherit">
                    <Button
                        w="full"
                        size="lg"
                        variant="secondary"
                        isDisabled={isSubmitting}
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        type="submit"
                        w="full"
                        size="lg"
                        variant="creationary"
                        isDisabled={!canEdit || !isDirty}
                        isLoading={isSubmitting}
                    >
                        {t('Save')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}
