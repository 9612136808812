import { Divider } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { FC } from 'react'

export interface SettingsTitleProps {
    label: string
    divider?: boolean
}

export const SettingsTitle: FC<SettingsTitleProps> = ({ label, divider = true }) => {
    return (
        <>
            <Body variant="bold">{label}</Body>
            {divider && <Divider data-testid="divider" />}
        </>
    )
}
