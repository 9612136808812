import { SearchQueryArgs } from '@missionlabs/api'
import { usePromisedDebouncedFunction } from '@missionlabs/react'
import { DirectoryEntry } from '@missionlabs/types'
import { useCallback, useState } from 'react'
import { useLazyContactsSearchQuery } from 'shared/store'

type UseLazyContactsSearchQuery = (args: SearchQueryArgs) => Promise<void>

type UseContactsSearchReturn = [
    UseLazyContactsSearchQuery,
    { data: DirectoryEntry[]; isLoading: boolean; isError: boolean }
]

export const useContactsSearch = (): UseContactsSearchReturn => {
    const [search, { isLoading, isError }] = useLazyContactsSearchQuery()

    const [contacts, setContacts] = useState<DirectoryEntry[]>([])

    const _searchContacts: UseLazyContactsSearchQuery = useCallback(
        async ({
            searchTerm,
            from,
            sortBy,
            myTeamsOnly,
            maxResults,
            filter,
            favouritesOnly
        }: SearchQueryArgs) => {
            try {
                setContacts(
                    await search({
                        searchTerm,
                        maxResults,
                        from,
                        sortBy,
                        myTeamsOnly,
                        favouritesOnly,
                        filter
                    }).unwrap()
                )
            } catch {
                setContacts([])
            }
        },
        [search, setContacts]
    )
    const searchContacts = usePromisedDebouncedFunction(_searchContacts, 200)

    return [searchContacts, { data: contacts, isLoading, isError }]
}
