import { AnalyticsTable } from 'features/analytics/components/AnalyticsTable/AnalyticsTable'
import AnalyticsTabsView from 'features/analytics/components/AnalyticsTabView'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetCallGroupsAnalyticsQuery } from 'shared/store'

interface Props {}

const TeamsNumbers: React.FC<Props> = () => {
    const { t } = useTranslation()
    const { dateScroller, group } = useAnalytics()

    const {
        data: callGroupData,
        isFetching,
        isLoading
    } = useGetCallGroupsAnalyticsQuery(
        {
            from: dateScroller.start?.getTime().toString(),
            to: dateScroller.end?.getTime().toString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            group
        },
        { skip: !dateScroller.start || !dateScroller.end }
    )

    const isNoResults = !callGroupData?.data.length

    return (
        <div>
            <SettingsPage
                title={t('analytics.teams')}
                subtitle={t('analytics.teamsSubtitle')}
                isLoading={isFetching || isLoading}
                contentProps={{ padding: 0, maxW: 'unset' }}
            >
                <AnalyticsTabsView
                    noResults={isNoResults}
                    isLoading={isFetching || isLoading}
                    header={null}
                >
                    <AnalyticsTable callGroupsData={callGroupData?.data} />
                </AnalyticsTabsView>
            </SettingsPage>
        </div>
    )
}

export default TeamsNumbers
