import { DirectoryEntry } from '@missionlabs/types'
import { ContactsListTitle } from 'features/contacts/ContactsList/ContactsListTitle'
import { ContactsRow } from 'features/contacts/ContactsList/ContactsRow'
import { CSSProperties, useMemo } from 'react'

type AdditionalFunctions = {
    handleToggle?: (contact: DirectoryEntry) => void
    isExistingAttendee?: boolean
    isAdded?: (contact: DirectoryEntry) => boolean
}

export type VirtualisedMeetingAttendeeItem = string | (DirectoryEntry & AdditionalFunctions)

interface MeetingAttendeeRowRendererProps {
    data: VirtualisedMeetingAttendeeItem[]
    index: number
    style: CSSProperties
}

function isTitle(item: string | DirectoryEntry): item is string {
    return typeof item === 'string'
}

export const ShareAttendeeRowRenderer = ({
    data,
    index,
    style
}: MeetingAttendeeRowRendererProps) => {
    const item = data[index]

    const isAdded = useMemo(() => {
        if (typeof item === 'string') return false

        return !!item.isAdded?.(item)
    }, [item])

    if (isTitle(item)) {
        return (
            <div style={style} role="listitem">
                <ContactsListTitle label={item} />
            </div>
        )
    }

    const isExistingAttendeeStyle = {
        opacity: '0.5',
        cursor: 'default !important'
    }

    return (
        <div
            style={item?.isExistingAttendee ? { ...style, ...isExistingAttendeeStyle } : style}
            role="listitem"
        >
            <ContactsRow
                key={item.ID}
                data={item}
                isActive={isAdded}
                renderTick
                onClick={() => item.handleToggle?.(item)}
            />
        </div>
    )
}
