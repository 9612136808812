import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PieChartIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PieChartIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 0.703125C10 0.351562 10.2734 0 10.6641 0C15.8203 0 20 4.17969 20 9.33594C20 9.72656 19.6484 10 19.2969 10H10.625C10.2734 10 10 9.72656 10 9.375V0.703125ZM11.25 8.75H18.7109C18.4375 4.76562 15.2344 1.5625 11.25 1.28906V8.75ZM8.75 11.25H17.3828C18.0859 11.25 18.6719 11.875 18.5156 12.5781C17.6172 16.8359 13.8672 20 9.375 20C4.17969 20 0 15.8203 0 10.625C0 6.13281 3.16406 2.38281 7.42188 1.48438C8.125 1.32812 8.75 1.91406 8.75 2.61719V11.25ZM7.5 2.73438C3.90625 3.59375 1.25 6.79688 1.25 10.625C1.25 15.1172 4.88281 18.75 9.375 18.75C13.2031 18.75 16.4062 16.0938 17.2656 12.5H8.75C8.04688 12.5 7.5 11.9531 7.5 11.25V2.73438Z"
            fill="currentColor"
        />
    )
})

export const PieChartIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PieChartIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M19.3359 9.375H10.625V0.664062C10.625 0.3125 10.8594 0 11.25 0C16.0547 0 20 3.94531 20 8.75C20 9.10156 19.6875 9.375 19.3359 9.375ZM8.75 11.25H17.3828C18.0859 11.25 18.6719 11.875 18.5156 12.5781C17.6172 16.8359 13.8672 20 9.375 20C4.17969 20 0 15.8203 0 10.625C0 6.13281 3.16406 2.38281 7.42188 1.48438C8.125 1.32812 8.75 1.91406 8.75 2.61719V11.25ZM6.875 3.55469C3.94531 4.60938 1.875 7.38281 1.875 10.625C1.875 14.7656 5.19531 18.125 9.375 18.125C12.6172 18.125 15.3906 16.0547 16.4453 13.125H6.875V3.55469Z"
            fill="currentColor"
        />
    )
})

export const PieChartIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PieChartIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M19.3359 9.375H10.625V0.664062C10.625 0.3125 10.8594 0 11.25 0C16.0547 0 20 3.94531 20 8.75C20 9.10156 19.6875 9.375 19.3359 9.375ZM7.42188 1.48438C8.125 1.32812 8.75 1.91406 8.75 2.61719V11.25H17.3828C18.0859 11.25 18.6719 11.875 18.5156 12.5781C17.6172 16.8359 13.8672 20 9.375 20C4.17969 20 0 15.8203 0 10.625C0 6.13281 3.16406 2.38281 7.42188 1.48438Z"
            fill="currentColor"
        />
    )
})
