import { Flex, HStack, useColorMode, useMultiStyleConfig } from '@chakra-ui/react'
import { Body } from 'atoms'
import { InformationIconRegular } from 'atoms/Icons/zeta'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type CallWarningProps = {}

export const CallWarning: FC<CallWarningProps> = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { callWarning } = useMultiStyleConfig('ActiveCall')
    return (
        <Flex sx={callWarning}>
            <HStack>
                <InformationIconRegular boxSize="20px" color={`${colorMode}.alerts.rose`} />
                <Body sx={{ fontWeight: 800 }}>{t('callWarning.tryingToReconnect')}</Body>
            </HStack>
        </Flex>
    )
}
