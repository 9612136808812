import Metric, { MetricData } from './metric'

export interface ErrorMetricData extends MetricData {
    errorName: string
    errorMessage: string
    errorStack?: string
}

class ErrorMetric extends Metric<ErrorMetricData> {
    constructor(error: Error, stack?: string, meta?: ErrorMetricData['meta']) {
        super({ errorName: error.name, errorMessage: error.message, errorStack: stack, meta })
    }
}

export default ErrorMetric
