import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PauseIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PauseIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 4H10.25V20H5V4ZM13.75 4H19V20H13.75V4Z"
            fill="currentColor"
        />
    )
})
