import { Slide, useMediaQuery } from '@chakra-ui/react'
import { focusCall, selectFocusedCall, useDispatch, useSelector } from '@missionlabs/api'
import { OutboundMessageType, OutboundSMS, OutboundWhatsApp } from '@missionlabs/types'
import { FC, useEffect, useMemo } from 'react'
import { usePrevious } from 'react-use'
import { Features, useHasClientFeature } from 'shared/hooks/useHasClientFeature'
import { WhatsAppSendMessageProvider } from 'shared/hooks/useWhatsAppSendMessage'
import { selectFocusedMessage, selectMessages } from 'shared/slices/messagingSlice'

import { DiallerView } from './DiallerView'
import { RetrieveCallPanel } from './DrawerPanels/RetrieveCallPanel'
import { SMSPanel } from './DrawerPanels/SMSPanelV2'
import { VoicePanel } from './DrawerPanels/VoicePanel'
import { WhatsAppPanel } from './DrawerPanels/WhatsAppPanel'
import { useContactDrawerState } from './hooks/useContactDrawerState'
import { VoiceWrapper } from './VoiceWrapper'

export enum DrawerMode {
    DIALLER = 'dialler',
    VOICE = 'voice',
    SMS = 'sms',
    WHATSAPP = 'whatsapp',
    RETRIEVE_CALL = 'retrieve_call'
}

type ContactDrawerProps = {}

export const ContactDrawer: FC<ContactDrawerProps> = () => {
    const [isSmallerThan750] = useMediaQuery('(max-height: 750px)')
    const hasWhatsApp = useHasClientFeature(Features.whatsapp)
    const dispatch = useDispatch()
    const messages = useSelector(selectMessages)
    const focusedMessage = useSelector(selectFocusedMessage)

    const { mode, drawer, dialState, setMode } = useContactDrawerState()
    const { isOpen, onOpen, onClose } = drawer

    const drawerWidth = useMemo(() => (isSmallerThan750 ? '350px' : '384px'), [isSmallerThan750])

    // Open voice drawer when a new call is available, takes precedent over other effects.
    useEffect(() => {
        if (dialState.view === 'calls') onOpen(DrawerMode.VOICE)
    }, [dialState.view, onOpen, setMode])

    const previousFocusesMessage = usePrevious(focusedMessage)

    useEffect(() => {
        if (focusedMessage && !previousFocusesMessage) {
            const mode =
                focusedMessage.type === OutboundMessageType.WhatsApp
                    ? DrawerMode.WHATSAPP
                    : DrawerMode.SMS
            onOpen(mode, focusedMessage.ID)
        }
    }, [previousFocusesMessage, focusedMessage, onOpen])

    // Close SMS drawer after last message is removed.
    useEffect(() => {
        if (
            mode !== DrawerMode.VOICE &&
            mode !== DrawerMode.DIALLER &&
            mode !== DrawerMode.RETRIEVE_CALL &&
            !messages.length
        )
            onClose()
    }, [focusedMessage, messages.length, mode, onClose])

    const { calls } = useContactDrawerState()
    const focusedCall = useSelector(selectFocusedCall)

    useEffect(() => {
        if (mode === DrawerMode.VOICE && !focusedCall && calls.length)
            dispatch(focusCall({ callTraceID: calls[0].callTraceID }))

        if (mode === DrawerMode.VOICE && !calls.length) setMode(DrawerMode.DIALLER)
    }, [calls, dispatch, focusedCall, mode, setMode])

    function renderContent() {
        switch (mode) {
            case DrawerMode.VOICE:
                return <VoicePanel call={focusedCall} isOpen={isOpen} dialState={dialState} />
            case DrawerMode.SMS:
                return <SMSPanel message={focusedMessage as OutboundSMS} />
            case DrawerMode.WHATSAPP:
                return <WhatsAppPanel message={focusedMessage as OutboundWhatsApp} />
            case DrawerMode.DIALLER:
                return <DiallerView />
            case DrawerMode.RETRIEVE_CALL:
                return <RetrieveCallPanel />
            default:
                return <DiallerView />
        }
    }

    return (
        <Slide direction="right" in={isOpen} style={{ width: drawerWidth, height: '100%' }}>
            <VoiceWrapper width={drawerWidth} onClose={onClose}>
                {hasWhatsApp ? (
                    <WhatsAppSendMessageProvider>{renderContent()}</WhatsAppSendMessageProvider>
                ) : (
                    renderContent()
                )}
            </VoiceWrapper>
        </Slide>
    )
}
