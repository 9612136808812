import { HStack, Spacer, useColorMode, VStack } from '@chakra-ui/react'
import { Avatar, Body } from '@missionlabs/react'
import { DirectoryEntry, HuntGroup, IVRMenu } from '@missionlabs/types'
import { isTeamOrMenu } from '@missionlabs/utils'
import { FC, useMemo } from 'react'

import { AddNumberOverflow } from './AddNumberOverflow'

export interface SelectedContact {
    contactID: string
    numberE164: string
}

export type VirtualContactRow = {
    contact: DirectoryEntry | HuntGroup | IVRMenu
    onSelect: (selectedContact: SelectedContact) => void
    selectedContact?: SelectedContact
}

interface ContactRowProps extends VirtualContactRow {}

export const ContactRow: FC<ContactRowProps> = ({ contact, onSelect, selectedContact }) => {
    const { colorMode } = useColorMode()

    const info = useMemo(() => {
        if (isTeamOrMenu(contact)) return { fullName: contact.name, jobTitle: undefined }
        return contact
    }, [contact])

    return (
        <HStack
            h="full"
            w="full"
            spacing="8px"
            p="12px"
            borderTop="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
        >
            <Avatar
                name={info.fullName}
                team={isTeamOrMenu(contact) || contact.source === 'teamprofile'}
                src={isTeamOrMenu(contact) ? undefined : contact.photoURL}
                size="md"
                boxShadow="shallow"
                sx={{ border: 'none' }}
            />

            <VStack w="full" align="flex-start" spacing="0">
                {info.fullName && (
                    <Body size="sm" variant="bold">
                        {info.fullName}
                    </Body>
                )}
                {info.jobTitle && <Body size="xs">{info.jobTitle}</Body>}
            </VStack>

            <Spacer />

            <AddNumberOverflow
                contact={contact}
                onSelect={onSelect}
                selectedContact={selectedContact}
            />
        </HStack>
    )
}
