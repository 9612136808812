import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react'
import type { OnUpdatedEventListenerArgs, OverlayScrollbars } from 'overlayscrollbars'
import {
    OverlayScrollbarsComponent,
    OverlayScrollbarsComponentProps,
    OverlayScrollbarsComponentRef
} from 'overlayscrollbars-react'
import { forwardRef, PropsWithChildren, useCallback } from 'react'

export type ScrollableProps = Omit<OverlayScrollbarsComponentProps, 'onScroll'> &
    Omit<BoxProps, 'onScroll'> & {
        anchor?: boolean
        onScroll?: (event: Event) => void
    }

export const Scrollable = forwardRef<
    OverlayScrollbarsComponentRef,
    PropsWithChildren<ScrollableProps>
>(({ anchor = false, events, options, children, onScroll, ...props }, ref) => {
    const styles = useStyleConfig('Scrollable')

    const handleScroll = useCallback(
        (_: OverlayScrollbars, event: Event) => onScroll?.(event),
        [onScroll]
    )

    function handleUpdate(instance: OverlayScrollbars, args: OnUpdatedEventListenerArgs) {
        if (!anchor) return

        // auto scroll to bottom when layout shifts
        const viewport = instance.elements().viewport
        // .scrollTo can be undefined when testing, so double check
        if (typeof viewport.scrollTo === 'function') {
            viewport.scrollTo(0, viewport.scrollHeight)
        }

        // handle any extra events
        if (!!events && typeof events.updated === 'function') {
            events.updated(instance, args)
        }
    }

    return (
        <Box
            {...props}
            as={OverlayScrollbarsComponent}
            // TS really doesn't like the overlapping ref types below, so ignore with any
            ref={ref as any}
            defer={true}
            events={{
                ...events,
                updated: handleUpdate,
                scroll: handleScroll
            }}
            options={{
                ...options,
                scrollbars: { autoHide: 'leave' }
            }}
            sx={{ ...styles, ...(props.sx ?? {}) }}
        >
            {children}
        </Box>
    )
})

Scrollable.displayName = 'Scrollable'
