import { sort } from '@missionlabs/utils'
import Fuse from 'fuse.js'
import { useMemo, useRef } from 'react'

type FuseOptionsWithoutKeys<T> = Omit<Fuse.IFuseOptions<T>, 'keys'>

type UseFuzzySearchProps<T> = {
    /**
     * The items to be searched
     */
    items: T[]
    /**
     * If items are objects, which keys should be used to match against search value
     */
    keys: (keyof T)[]
    /**
     * The value to search for within each field
     */
    searchValue: string
    /**
     * Whether the results should match the search value exactly
     */
    exact?: boolean
    /**
     * Options passed to the Fuse.js instance
     */
    options?: Partial<FuseOptionsWithoutKeys<T>>
    /**
     * A default sorting function used when search value is empty
     */
    fallbackSort?: (a: T, b: T) => number
}

type UseFuzzySearchReturn<T> = {
    results: T[]
    instance: Fuse<T>
}

/**
 * @example
 * const items = [
 * 	{ id: '', name: 'Bill', age: 25 },
 * 	{ id: '', name: 'Ben', age: 27 },
 * ]
 * const { results } = useFuzzySearch({
 items: items,
 keys: ['name', 'age'],
 searchValue: 'Bi',
 fallbackSort: (a, b) => a.name - b.name
 })
 // results: [{ id: '', name: 'Bill', age: 25 }]
 */
export function useFuzzySearch<T>({
    items,
    keys,
    searchValue,
    exact,
    options,
    fallbackSort
}: UseFuzzySearchProps<T>): UseFuzzySearchReturn<T> {
    // Create the fuse instance
    const FuseInstance = new Fuse<T>([], {
        includeScore: true,
        isCaseSensitive: false,
        minMatchCharLength: 2,
        useExtendedSearch: true,
        threshold: 0.2,
        ...options,
        keys: keys as string[],
        shouldSort: false
    })
    const fuzzySearcher = useRef(FuseInstance)

    // Get the results from the fuzzy searcher when the items or search value change
    const results = useMemo(() => {
        fuzzySearcher.current.setCollection(items)

        let value = searchValue.trim()
        if (!value) {
            if (fallbackSort) return items.sort(fallbackSort)
            return items
        }

        if (exact) value = `=${value}`

        return fuzzySearcher.current
            .search(value)
            .sort((a, b) => sort(a.score!, b.score!))
            .map(result => result.item)
    }, [items, searchValue, exact, fallbackSort])

    return {
        results,
        instance: fuzzySearcher.current
    }
}
