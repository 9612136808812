import {
    MeetingEventType,
    OpenAcceptMeetingWindow,
    OpenCreateMeetingWindow,
    OpenJoinMeetingWindow,
    OpenRequestToPullMeetingWindow
} from '@missionlabs/api'
import { useEffect, useState } from 'react'

export const MEETING_PAYLOAD_KEY = 'meetingPayload'

export type MeetingType =
    | OpenAcceptMeetingWindow
    | OpenCreateMeetingWindow
    | OpenJoinMeetingWindow
    | OpenRequestToPullMeetingWindow

type IsMeetingTypeFn<R extends MeetingType> = (payload: MeetingType | undefined) => payload is R

export interface UseGetMeetingPayloadReturn {
    payload?: MeetingType
    clearPayload: () => void
    isAcceptMeetingPayload: IsMeetingTypeFn<OpenAcceptMeetingWindow>
    isCreateMeetingPayload: IsMeetingTypeFn<OpenCreateMeetingWindow>
    isJoinMeetingPayload: IsMeetingTypeFn<OpenJoinMeetingWindow>
    isRequestToPullMeetingPayload: IsMeetingTypeFn<OpenRequestToPullMeetingWindow>
}

const useGetMeetingPayload = (clear = true): UseGetMeetingPayloadReturn => {
    const [payload, setPayload] = useState()

    const isAcceptMeetingPayload: UseGetMeetingPayloadReturn['isAcceptMeetingPayload'] = (
        payload
    ): payload is OpenAcceptMeetingWindow => {
        return payload?.event === MeetingEventType.ACCEPT_VIDEO_CALL
    }

    const isCreateMeetingPayload: UseGetMeetingPayloadReturn['isCreateMeetingPayload'] = (
        payload
    ): payload is OpenCreateMeetingWindow => {
        return payload?.event === MeetingEventType.CREATE_VIDEO_CALL
    }

    const isJoinMeetingPayload: UseGetMeetingPayloadReturn['isJoinMeetingPayload'] = (
        payload
    ): payload is OpenJoinMeetingWindow => {
        return payload?.event === MeetingEventType.JOIN_VIDEO_CALL
    }

    const isRequestToPullMeetingPayload: UseGetMeetingPayloadReturn['isRequestToPullMeetingPayload'] =
        (payload): payload is OpenRequestToPullMeetingWindow => {
            return payload?.event === MeetingEventType.REQUEST_TO_PULL_VIDEO_CALL
        }

    const clearPayload = () => {
        setPayload(undefined)
    }

    useEffect(() => {
        const payloadStr = localStorage.getItem(MEETING_PAYLOAD_KEY)
        if (payloadStr) setPayload(JSON.parse(payloadStr))

        if (clear) localStorage.removeItem(MEETING_PAYLOAD_KEY)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        payload,
        clearPayload,
        isAcceptMeetingPayload,
        isCreateMeetingPayload,
        isJoinMeetingPayload,
        isRequestToPullMeetingPayload
    }
}

export default useGetMeetingPayload
