import { isWhatsApp, OutboundMessage } from '@missionlabs/types'
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'

export type MessagingState = {
    focusedMessageID: string | undefined
    messages: OutboundMessage[]
}

export type MessagingActions = {
    focusMessage: (state: MessagingState, action: PayloadAction<string | undefined>) => void
    addMessage: (state: MessagingState, action: PayloadAction<OutboundMessage>) => void
    updateMessage: (state: MessagingState, action: PayloadAction<OutboundMessage>) => void
    removeMessage: (state: MessagingState, action: PayloadAction<string>) => void
    sentMessage: (state: MessagingState, action: PayloadAction<string>) => void
    removeEmptyMessages: (state: MessagingState) => void
}

const initialState: MessagingState = {
    focusedMessageID: undefined,
    messages: []
}

export const messagingSlice: Slice<MessagingState, MessagingActions, 'messagingSlice'> =
    createSlice({
        name: 'messagingSlice',
        initialState,
        reducers: {
            focusMessage: (state, action) => {
                state.focusedMessageID = action.payload
            },
            addMessage: (state, action) => {
                const message = action.payload
                const fromID = isWhatsApp(message) ? message.numberID : message.fromNumber
                message.ID = `${message.type}-${fromID}-${message.toNumber}`
                state.focusedMessageID = message.ID // focus on message when added.
                if (state.messages.find(message => message.ID === action.payload.ID)) return
                state.messages = [...state.messages, action.payload]
            },
            updateMessage: (state, action) => {
                state.messages = state.messages.map(message => {
                    if (message.ID === action.payload.ID) return action.payload
                    return message
                })
            },
            sentMessage: (state, action) => {
                state.messages = state.messages.filter(message => message.ID !== action.payload)
                state.focusedMessageID = undefined
            },
            removeMessage: (state, action) => {
                state.messages = state.messages.filter(message => message.ID !== action.payload)

                if (state.focusedMessageID === action.payload && state.messages.length > 0) {
                    // focus on last message in list after removal.
                    state.focusedMessageID = state.messages[state.messages.length - 1].ID
                } else if (!state.messages.length) {
                    state.focusedMessageID = undefined
                }
            },
            removeEmptyMessages: state => {
                state.messages = state.messages.filter(message => !!message.text)
            }
        }
    })

export type SMSSliceRootState = {
    messagingSlice: ReturnType<typeof messagingSlice.getInitialState>
}

export const selectMessages = (state: SMSSliceRootState) => {
    return state.messagingSlice.messages
}

export const selectFocusedMessage = (state: SMSSliceRootState) => {
    return state.messagingSlice.messages.find(
        message => message.ID === state.messagingSlice.focusedMessageID
    )
}

export const {
    focusMessage,
    addMessage,
    updateMessage,
    removeMessage,
    removeEmptyMessages,
    sentMessage
} = messagingSlice.actions
