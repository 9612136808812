import { useGetChimeDevices, UseGetChimeDevicesReturn } from '@missionlabs/meetings'
import { useGetDevices, UseGetDevicesReturn } from '@missionlabs/react'
import { createContext, FC, PropsWithChildren, useContext } from 'react'

export function isChime(x?: any): x is UseGetChimeDevicesReturn {
    return typeof x === 'object' && 'audioInputVolume' in x
}

export type DevicesContextValues = UseGetDevicesReturn | UseGetChimeDevicesReturn

const defaultValues: DevicesContextValues = {
    inputDevices: [],
    outputDevices: [],
    cameraDevices: [],
    inputDeviceID: undefined,
    outputDeviceID: undefined,
    cameraDeviceID: undefined,
    ringingOutputDeviceID: undefined
}

const DevicesContext = createContext<DevicesContextValues>(defaultValues)

interface DevicesContextProviderProps extends PropsWithChildren {
    useChime?: boolean
    isPreview?: boolean
}

export const DevicesContextProvider: FC<DevicesContextProviderProps> = ({
    useChime = false,
    isPreview = false,
    children
}) => {
    const values = useGetDevices()
    const chimeValues = useGetChimeDevices(isPreview)

    return (
        <DevicesContext.Provider value={useChime ? chimeValues : values}>
            {children}
        </DevicesContext.Provider>
    )
}

export const useDevicesContext = () => {
    const context = useContext(DevicesContext)

    if (context === undefined) {
        throw new Error('useDevicesContext must be used within a DevicesContextProvider')
    }

    return context
}
