import { Box, useColorMode } from '@chakra-ui/react'
import { Body, Button } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AuthLayout } from 'shared/components/AuthLayout'

export interface ResetPasswordSentProps {}

export const ResetPasswordSent: FC<ResetPasswordSentProps> = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    return (
        <AuthLayout label={t("We've sent you an email")}>
            <Body size="sm" alignSelf="start" sx={{ color: `${colorMode}.tones.navy` }}>
                {t('auth.resetPasswordSent')}
            </Body>
            <Box w="full" pt="8px">
                <Button size="lg" w="full" onClick={() => navigate('/login')}>
                    {t('Sign in')}
                </Button>
            </Box>
        </AuthLayout>
    )
}
