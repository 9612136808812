import { $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ChatMessage } from '@missionlabs/types'
import {
    $createRangeSelection,
    $getRoot,
    $getSelection,
    $insertNodes,
    $setSelection,
    createCommand,
    LexicalCommand
} from 'lexical'
import { FC, useEffect } from 'react'

import { $createEditMessageNode } from '../nodes/EditMessageNode'

export interface EditMessagePluginProps {}

export const INSERT_EDIT_CHAT_MESSAGE_COMMAND: LexicalCommand<{
    message: ChatMessage
}> = createCommand('INSERT_EDIT_CHAT_MESSAGE_COMMAND')

export const EDIT_CHAT_MESSAGE_EVENT = 'EDIT_CHAT_MESSAGE_EVENT'

export const dispatchEditMessageEvent = (message: ChatMessage) => {
    return document.dispatchEvent(new CustomEvent(EDIT_CHAT_MESSAGE_EVENT, { detail: { message } }))
}

export const EditMessagePlugin: FC<EditMessagePluginProps> = () => {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        const insertEditedMessage = e => {
            const { message } = e.detail

            editor.update(() => {
                const currSelection = $getSelection()
                const rangeSelection = $createRangeSelection()
                const root = $getRoot()
                const nodes = root.getChildren()
                const existingNode = nodes.find(
                    n => n.__type === 'reply' || n.__type === 'edit_message'
                )

                if (existingNode) existingNode.remove()

                // In the browser you can use the native DOMParser API to parse the HTML string.
                const parser = new DOMParser()
                const dom = parser.parseFromString(message.contentHTML, 'text/html')

                // Once you have the DOM instance it's easy to generate LexicalNodes.
                const editNode = $generateNodesFromDOM(editor, dom)

                //clear root
                root.clear()

                // Insert them at a selection.
                $insertNodes(editNode)

                $setSelection(rangeSelection)
                $insertNodes([$createEditMessageNode(message)])
                $setSelection(currSelection)
            })
        }

        document.addEventListener(EDIT_CHAT_MESSAGE_EVENT, insertEditedMessage)
        return () => document.removeEventListener(EDIT_CHAT_MESSAGE_EVENT, insertEditedMessage)
    }, [editor])

    return null
}
