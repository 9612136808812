import { useGetWhatsAppTemplatesQuery } from 'shared/store'

export const useWhatsAppTemplates = () => {
    const { data: templates, isLoading } = useGetWhatsAppTemplatesQuery()
    const templateOptions = templates?.map(template => ({
        label: template.name,
        value: template.name
    }))
    return { templates, templateOptions, isLoading }
}
