import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ResizeIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ResizeIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M7.48899 16.5111C7.10393 16.1261 7.10393 15.5018 7.48899 15.1167L15.1166 7.48875C15.5017 7.10368 16.126 7.10368 16.511 7.48875C16.8961 7.87383 16.8961 8.49815 16.511 8.88322L8.88341 16.5111C8.49835 16.8962 7.87405 16.8962 7.48899 16.5111Z"
                fill="currentColor"
            />
            <path
                d="M13.067 16.5111C12.682 16.1261 12.682 15.5018 13.067 15.1167L15.117 13.0666C15.502 12.6815 16.1263 12.6815 16.5114 13.0666C16.8965 13.4517 16.8965 14.076 16.5114 14.4611L14.4615 16.5111C14.0764 16.8962 13.4521 16.8962 13.067 16.5111Z"
                fill="currentColor"
            />
        </g>
    )
})
