import { Grid, Spacer, useColorMode, VStack } from '@chakra-ui/react'
import { Navbar as MLNavbar, NavbarGroup } from '@missionlabs/react'
import {
    ActivityFeed2Icon,
    AdminSettingsIcon,
    ChartIcon,
    ContactsIcon,
    HardwareSettings2Icon,
    HelpIcon,
    KeypadIcon,
    SettingsIcon
} from '@missionlabs/react/circleloop'
import { NavButton } from 'features/voice/NavButtons/NavButton'
import { FC } from 'react'
import { GlobalNavMenu } from 'shared/components/GlobalNavMenu/GlobalNavMenu'
import { NavbarItem } from 'shared/components/Navbar/NavbarItem'

export interface ErrorLayoutProps {}

export const ErrorLayout: FC<ErrorLayoutProps> = () => {
    const { colorMode } = useColorMode()

    return (
        <Grid h="100dvh" w="100dvw" templateColumns="64px 1fr 64px">
            <MLNavbar>
                <NavbarGroup>
                    <NavbarItem noStyles>
                        <GlobalNavMenu />
                    </NavbarItem>
                    <NavbarItem>
                        <ActivityFeed2Icon aria-label="Home" />
                    </NavbarItem>
                    <NavbarItem>
                        <ContactsIcon aria-label="Contacts" />
                    </NavbarItem>
                    <NavbarItem>
                        <ChartIcon aria-label="Analytics" />
                    </NavbarItem>
                    <NavbarItem>
                        <SettingsIcon aria-label="Settings" />
                    </NavbarItem>
                    <NavbarItem>
                        <AdminSettingsIcon aria-label="Admin Settings" />
                    </NavbarItem>
                </NavbarGroup>
                <NavbarGroup>
                    <NavbarItem>
                        <HelpIcon aria-label="Help" />
                    </NavbarItem>
                </NavbarGroup>
            </MLNavbar>
            <div />
            <VStack h="full" bg={`${colorMode}.coolGrey.70`}>
                <NavButton
                    aria-label="dialler button"
                    icon={<KeypadIcon color="white" boxSize="20px" />}
                />
                <Spacer />
                <NavButton
                    aria-label="settings button"
                    icon={<HardwareSettings2Icon color="white" boxSize="20px" />}
                />
            </VStack>
        </Grid>
    )
}
