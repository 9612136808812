import { StackProps, VStack } from '@chakra-ui/react'
import { FC, PropsWithChildren } from 'react'

export interface NavbarGroupProps extends StackProps {}

export const NavbarGroup: FC<PropsWithChildren<NavbarGroupProps>> = ({ children, ...props }) => {
    return (
        <VStack spacing={0} {...props}>
            {children}
        </VStack>
    )
}
