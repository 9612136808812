import { Box, HStack, useColorMode } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { ChatMessage } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface EditMessageProps {
    message: ChatMessage
}

export const EditMessage: FC<EditMessageProps> = () => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    return (
        <Box mb="12px">
            <HStack spacing={0}>
                <Body size="sm" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                    <Box as="span" sx={{ color: `${colorMode}.primary.gamma` }}>
                        {t('Edit: ')}
                    </Box>
                </Body>
            </HStack>
        </Box>
    )
}
