import { Center, HStack, useColorMode } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { Body, ContactDetail } from '@missionlabs/react'
import { Channel, isContactGroup } from '@missionlabs/types'
import { FC } from 'react'
import { useGetPresence } from 'shared/hooks/useGetPresence'

import { getChannelPresenceID } from '../ChatList/ChatListRow'

interface ChatThreadRowHeaderProps {
    channel: Channel
    isThread?: boolean
    unreadCount: number
}

export const ChatThreadRowHeader: FC<ChatThreadRowHeaderProps> = ({ channel, unreadCount }) => {
    const { colorMode } = useColorMode()
    const user = useSelector(selectAuthenticatedUser)
    const name = isContactGroup(channel) ? channel?.name : channel?.channelName
    const presenceID = getChannelPresenceID(channel, user?.userID)
    const { status } = useGetPresence(presenceID)
    return (
        <HStack w="95%" ml={'24px'} mb={'16px'} justifyContent={'space-between'}>
            <ContactDetail
                size="md"
                name={name}
                subText={channel?.user?.companyName || channel?.team?.companyName}
                containerSx={{ marginLeft: '6px' }}
                status={status}
            />
            <Center px="8px" bg={`${colorMode}.primary.gamma`} borderRadius="100px">
                <Body size="sm" variant="bold" sx={{ color: 'white' }}>
                    {unreadCount}
                </Body>
            </Center>
        </HStack>
    )
}
