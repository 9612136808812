import Metric, { MetricData } from './metric'

export interface UserMetricData extends MetricData {
    // metadata index type
    [key: string]: any
    locale: string
}

class UserMetric extends Metric<UserMetricData> {
    constructor(
        metadata: Record<string, string> | undefined = {},
        locale: string,
        meta?: UserMetricData['meta']
    ) {
        super({ ...metadata, locale, meta })
    }
}

export default UserMetric
