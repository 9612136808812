import { AnalyticsDataPoints, AnalyticsGroup } from '@missionlabs/types'
import { AxisProps } from '@nivo/axes'
import { Box, Theme } from '@nivo/core'

import { DatePeriod } from '../../context/index'

export type AnalyticsGraphProps = {
    data: AnalyticsDataPoints[]
    group?: AnalyticsGroup
    period?: DatePeriod
}

type AnalyticsGraphCommon = {
    axisLeft: AxisProps
    axisBottom: AxisProps
    enableGridY: boolean
    margin: Box
    theme: Theme
}

export const AnalyticsGraphCommonProps: AnalyticsGraphCommon = {
    axisLeft: {
        tickSize: 6,
        tickPadding: 8,

        format: e => (Number.isInteger(e) ? e : '')
    },
    enableGridY: false,

    axisBottom: {
        tickSize: 8
    },
    margin: { top: 30, bottom: 50, left: 40, right: 30 },
    theme: {
        background: '#F7F8FD',
        tooltip: {
            container: {
                textTransform: 'capitalize'
            }
        },
        axis: {
            ticks: {
                text: {
                    fontSize: '12px',
                    color: '1B1F3B'
                },
                line: {
                    stroke: '#CACEE9'
                }
            },
            domain: {
                line: {
                    stroke: '#CACEE9',
                    strokeWidth: 1,
                    strokeDasharray: 3
                }
            }
        },

        grid: {
            line: {
                stroke: '#CACEE9',
                strokeWidth: 1,
                strokeDasharray: 3
            }
        }
    }
}
