import { useFlags } from 'flagsmith/react'
import isElectron from 'is-electron'
import { useEffect, useMemo, useState } from 'react'
import { requestPermission } from 'shared/utils/notifications/browserNotifications'

import { useCallNotificationListener } from './useCallNotificationListener'
import { useChatNotificationListener } from './useChatNotificationListener'
import { useMeetingNotificationListener } from './useMeetingNotificationListener'

// Controls all notifications in the application via a single hook.
// This includes DOM Elements, Browser APIs and Electron APIs.
export const useNotificationManager = () => {
    const [browserPermission, setBrowserPermission] = useState<NotificationPermission | undefined>()
    const [isBrowserInView, setIsBrowserInView] = useState<boolean>(true)
    const flags = useFlags(['chat', 'meetings'])

    // Prevent showing notifications on child tabs
    const showNotifications = isElectron() || window.opener === null
    const showNotificationsForChat = showNotifications && flags.chat.enabled
    const showNotificationsForMeeting = showNotifications && flags.meetings.enabled

    // Allow notifications via Browser API.
    // Should only be shown when user is "tabbed out" and permission has been given.
    const showBrowserNotifications = useMemo(() => {
        // For now, we prevent Browser API notifications in Electron as they have a custom implementation.
        return browserPermission === 'granted' && !isBrowserInView && !isElectron()
    }, [browserPermission, isBrowserInView])

    const showBrowserNotificationsForChat = useMemo(() => {
        return showBrowserNotifications && flags.chat.enabled
    }, [showBrowserNotifications, flags])

    const showBrowserNotificationsForMeeting = useMemo(() => {
        return showBrowserNotifications && flags.meetings.enabled
    }, [showBrowserNotifications, flags])

    // Request permission to Browser API  on mount.
    useEffect(() => {
        requestPermission(setBrowserPermission)

        const onWindowFocus = () => setIsBrowserInView(true)
        const onWindowBlur = () => setIsBrowserInView(false)

        window.addEventListener('focus', onWindowFocus)
        window.addEventListener('blur', onWindowBlur)

        return () => {
            window.removeEventListener('focus', onWindowFocus)
            window.removeEventListener('blur', onWindowBlur)
        }
    }, [])

    // Listen and present call notifications.
    useCallNotificationListener(showNotifications, showBrowserNotifications)
    useChatNotificationListener(showNotificationsForChat, showBrowserNotificationsForChat)
    useMeetingNotificationListener(showNotificationsForMeeting, showBrowserNotificationsForMeeting)
}
