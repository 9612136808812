import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const FlagIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'FlagIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.35533 2.13649C8.15787 2.04647 7.94467 2 7.72914 2H5.56229C4.69946 2 4 2.72884 4 3.62791V21.2178C4 21.6498 4.33612 22 4.75071 22C5.16532 22 5.50142 21.6498 5.50142 21.2178V14.9913C5.50142 14.8917 5.56219 14.8033 5.65259 14.7711L18.9418 10.0505C20.2911 9.57116 20.3699 7.61362 19.0639 7.01822L8.35533 2.13649Z"
            fill="currentColor"
        />
    )
})

export const FlagIconOutline: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'FlagIconOutline',
    viewBox: '0 0 12 16',
    defaultProps: {
        fill: 'none'
    },
    path: (
        <path
            d="M10.6413 4.5435C11.4773 4.90925 11.4268 6.11173 10.5632 6.40617L1.67733 9.43543C1.27229 9.57352 1 9.95402 1 10.3819V14.6057C1 14.6392 0.972865 14.6663 0.939394 14.6663V14.6663C0.905922 14.6663 0.878788 14.6392 0.878788 14.6057V2.33301C0.878788 1.78072 1.3265 1.33301 1.87879 1.33301H3.09385C3.23181 1.33301 3.36828 1.36155 3.49467 1.41685L10.6413 4.5435Z"
            stroke="#1B1F3B"
            strokeWidth="1.5"
        />
    )
})
