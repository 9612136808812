import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([...checkboxAnatomy.keys])

export const CheckboxStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => {
        const disabledStyles = {
            bg: `${colorMode}.tones.periwinkle`,
            borderColor: `${colorMode}.tones.periwinkle`,
            color: `${colorMode}.tones.periwinkle`
        }
        const disabledCheckedStyles = {
            ...disabledStyles,
            color: `${colorMode}.tones.stormGrey`,
            _hover: {
                ...disabledStyles,
                color: `${colorMode}.tones.stormGrey`
            }
        }
        const hoverStyles = {
            bg: `${colorMode}.tones.stormGrey`,
            borderColor: `${colorMode}.tones.stormGrey`
        }
        const checkedStyles = {
            bg: `${colorMode}.alerts.red`,
            borderColor: `${colorMode}.alerts.red`,
            _hover: hoverStyles
        }

        return {
            control: {
                w: '16px',
                h: '16px',
                margin: '2px',
                borderRadius: '4px',
                border: '1px solid',
                borderColor: `${colorMode}.tones.periwinkle`,
                _checked: checkedStyles,
                _indeterminate: checkedStyles,
                _hover: hoverStyles,
                _disabled: {
                    ...disabledStyles,
                    _hover: disabledStyles,
                    _checked: disabledCheckedStyles,
                    _indeterminate: disabledCheckedStyles
                },
                _focus: {
                    borderColor: `${colorMode}.tones.stormGrey`
                }
            }
        }
    }
})
