import { zetaTheme } from '@missionlabs/react'
import { lightTheme } from 'amazon-chime-sdk-component-library-react'

import { setBorderColor } from './utils'

const { colors } = zetaTheme
const { rose } = colors.light.alerts
const { periwinkle, whiteLilac } = colors.light.tones

const staticBtn = {
    light: {
        ...lightTheme.buttons.icon.static,
        text: rose,
        bgd: whiteLilac,
        border: setBorderColor(lightTheme.buttons.icon.active.border, periwinkle)
    }
}

const interactionBtn = {
    light: {
        ...staticBtn,
        bgd: periwinkle
    }
}

export const light = {
    ...lightTheme.buttons,
    icon: {
        ...lightTheme.buttons.icon,
        static: staticBtn.light,
        hover: interactionBtn.light,
        selected: staticBtn.light,
        active: staticBtn.light,
        focus: staticBtn.light
    }
}
