import { Level } from './levels'
import transports, { Log } from './transports'

type Transport = InstanceType<(typeof transports)[keyof typeof transports]>

interface LoggerConfig {
    transports: Transport[]
    global?: {
        level?: Level
    }
}

const defaultConfig: LoggerConfig = {
    transports: [
        new transports.console({ level: 'info' }),
        new transports.indexeddb({ level: 'info' })
    ]
}

const methods = {
    trace: 'trace',
    debug: 'debug',
    info: 'info',
    warn: 'warn',
    error: 'error',
    log: 'info'
}

class Logger {
    declare trace: (...data: any[]) => void
    declare debug: (...data: any[]) => void
    declare info: (...data: any[]) => void
    declare warn: (...data: any[]) => void
    declare error: (...data: any[]) => void
    declare log: (...data: any[]) => void

    protected config: LoggerConfig = defaultConfig

    constructor(config: Partial<LoggerConfig> = {}) {
        this.config = { ...defaultConfig, ...config }
        this.validateConfig()
        // instantiate logger methods
        for (const key in methods) {
            this[key] = this.createLogger(methods[key])
        }
    }

    public get transports() {
        return this.config.transports
    }

    public add(transport: Transport): void {
        if (this.isTransportConfigured(transport)) {
            // replace transport if it already existed before
            const index = this.config.transports.findIndex(t =>
                this.compareTransports(t, transport)
            )
            if (index !== -1) this.config.transports[index] = transport
        } else {
            this.config.transports.push(transport)
        }

        this.validateConfig()
    }

    public remove(transport: Transport | undefined): void {
        if (!transport) return

        const index = this.config.transports.indexOf(transport)
        if (index !== -1) this.config.transports.splice(index, 1)

        this.validateConfig()
    }

    private compareTransports(a: Transport, b: Transport): boolean {
        return Object.getPrototypeOf(a) === Object.getPrototypeOf(b)
    }

    private isTransportConfigured(transport: Transport): boolean {
        return this.config.transports.some(t => this.compareTransports(t, transport))
    }

    private validateConfig(): void {
        if (!this.config.transports || this.config.transports.length <= 0) {
            throw new Error('@missionlabs/logger | "transports" cannot be empty!')
        }

        for (const type of Object.values(transports)) {
            if (this.config.transports.filter(t => t instanceof type).length > 1) {
                throw new Error(`@missionlabs/logger | Cannot have more than 1 "${type.name}"!`)
            }
        }
    }

    private createLogger(level: Level): (...data: any[]) => void {
        return (...data: any[]): void => {
            return this.config.transports?.forEach(transport => {
                if (typeof this.config.global?.level !== 'undefined') {
                    transport.level = this.config.global.level
                }

                if (!transport.isAllowed(level)) {
                    return null
                }

                const content = data.reduce((prev, curr) => {
                    const formatted = transport.format(curr || '')
                    return `${prev} ${formatted}`
                }, '')

                const message = transport.getMessage({
                    level,
                    date: new Date(),
                    message: content
                })

                const log: Log = { level, message }
                const result = transport.log(log)
                transport.postLog(log)

                return result
            })
        }
    }
}

export default Logger
