import {
    DatePickerInput as MantineDateInput,
    DatePickerInputProps as MantineDateInputProps
} from '@mantine/dates'
import React from 'react'

import DateIcon from './DateIcon'

type Props = MantineDateInputProps

export const DateInput: React.FC<Props> = ({ ...props }) => {
    return <MantineDateInput rightSection={<DateIcon />} {...props} />
}
