import { HStack, useColorMode } from '@chakra-ui/react'
import { useExternalMeetingLink } from '@missionlabs/meetings'
import {
    ExternalLinkIconRegular,
    ShareIconRegular,
    UsersIconRegular
} from '@missionlabs/react/zeta'
import { ScheduledMeeting } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { MeetingButton } from './MeetingButton'

type MeetingButtonsProps = {
    meeting: ScheduledMeeting
    hasExpired: boolean
    isCancelled: boolean
    hasStarted: boolean
    isOrganiser: boolean
}
// TODO: The designs have things like "View recording" and what looks to be a dial in number on some
// not 100% sure how we are going to know what to use and when
// Probably best waiting to add these in when we are integrated with the backend
export const MeetingButtons: FC<MeetingButtonsProps> = ({
    meeting,
    hasStarted,
    hasExpired,
    isCancelled,
    isOrganiser
}) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const meetingLink = useExternalMeetingLink(meeting.ID)

    return (
        <HStack spacing="25px" justify="space-between" opacity={isCancelled ? 0.8 : 1}>
            {!hasExpired && !isCancelled && (
                <MeetingButton
                    onClick={() => navigator.clipboard.writeText(meetingLink)}
                    icon={<ExternalLinkIconRegular color={`${colorMode}.alerts.red`} />}
                    label={t('meetings.copyMeetingLink')}
                />
            )}
            <MeetingButton
                icon={
                    hasStarted || !isOrganiser ? (
                        <UsersIconRegular color={`${colorMode}.alerts.red`} />
                    ) : (
                        <ShareIconRegular color={`${colorMode}.alerts.red`} />
                    )
                }
                label={
                    hasStarted || !isOrganiser
                        ? t('meetings.viewAttendees')
                        : t('meetings.shareMeeting')
                }
                onClick={() =>
                    navigate(`${location.pathname}/${meeting.ID}/participants/${location.search}`)
                }
            />
        </HStack>
    )
}
