import { SearchFilter } from '@missionlabs/api'
import { isValidEmail } from '@missionlabs/utils'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useContactsSearch } from 'shared/hooks/useContactsSearch'

export const useAddEditMeetingDirectorySearch = () => {
    const [search, setSearch] = useState<string>('')

    const [searchContacts, { data: contacts, isLoading }] = useContactsSearch()

    const filterParams: SearchFilter[] = useMemo(() => ['individual'], [])

    const handleSearch = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value
            setSearch(value)
            searchContacts({ searchTerm: value, filter: filterParams })
        },
        [setSearch, searchContacts, filterParams]
    )

    const clearSearch = useCallback(() => {
        setSearch('')
        searchContacts({ searchTerm: '', filter: filterParams })
    }, [setSearch, searchContacts, filterParams])

    const noSearchResults = useMemo(() => {
        return !contacts.length && !!search.length
    }, [contacts, search])

    const showEmailWarning = useMemo(() => {
        return contacts.length === 0 && isValidEmail(search)
    }, [contacts, search])

    return {
        contacts,
        isLoading,
        search,
        noSearchResults,
        showEmailWarning,
        handleSearch,
        clearSearch,
        filterParams
    }
}
