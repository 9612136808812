import { HStack, useDisclosure } from '@chakra-ui/react'
import { Button, Dropdown, Input } from '@missionlabs/react'
import { isUser, UserCallRoute, UserOrTeam } from '@missionlabs/types'
import { isMenu, isTeam } from '@missionlabs/utils'
import { InterstitialRadio } from 'features/settings/components/InterstitialRadio'
import { UTMDrawer } from 'features/settings/components/UTMDrawer'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getTimeoutOptions } from './timeoutOptions'

type RadioType = 'inHoursUnanswered' | 'outOfHours' | 'busy'

const propNames = {
    inHoursUnanswered: {
        forward: 'inHoursUnanswered',
        redirect: 'unansweredRedirectInternal'
    },
    busy: {
        forward: 'busy',
        redirect: 'busyRedirectInternal'
    },
    outOfHours: {
        forward: 'outOfHours',
        redirect: 'outOfHoursRedirectInternal'
    }
} as const

type InHoursProps = {
    type: 'inHoursUnanswered'
    callRoute: Pick<
        UserCallRoute,
        'inHoursUnanswered' | 'unansweredRedirectInternal' | 'callTimeout'
    >
}

type OutOfHoursProps = {
    type: 'outOfHours'
    callRoute: Pick<UserCallRoute, 'outOfHours' | 'outOfHoursRedirectInternal'>
}

type BusyProps = {
    type: 'busy'
    callRoute: Pick<UserCallRoute, 'busy' | 'busyRedirectInternal'>
}

type RedirectInternalRadioProps = (InHoursProps | OutOfHoursProps | BusyProps) & {
    type: RadioType
    forwardType: string
    onUpdate: (payload: Partial<UserCallRoute>) => void
    minW?: string
}

export const RedirectInternalRadio: FC<RedirectInternalRadioProps> = ({
    type,
    forwardType,
    callRoute,
    onUpdate,
    minW
}) => {
    const { t } = useTranslation()
    const drawer = useDisclosure()

    const isActive = useMemo(() => forwardType === 'redirect_internal', [forwardType])

    const [callTimeout, setCallTimeout] = useState(() =>
        type === 'inHoursUnanswered' ? callRoute.callTimeout : undefined
    )
    const [redirect, setRedirect] = useState(callRoute[propNames[type].redirect])

    const onChangeRedirect = (data: UserOrTeam) => {
        if (isTeam(data)) setRedirect({ teamID: data.ID, display: data.name })
        if (isMenu(data)) setRedirect({ menuID: data.ID, display: data.name })
        if (isUser(data)) setRedirect({ userID: data.ID, display: data.fullName })

        drawer.onClose()
    }

    useEffect(() => {
        if (!isActive) return

        onUpdate({
            [propNames[type].forward]: 'redirect_internal',
            [propNames[type].redirect]: redirect,
            callTimeout
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callTimeout, redirect])

    return (
        <InterstitialRadio
            value="redirect_internal"
            current={forwardType}
            onClick={() => onUpdate({ [propNames[type].forward]: 'redirect_internal' })}
            label={t('calls.redirectInternal')}
            minW={minW}
            alignItems="center"
        >
            <HStack spacing="16px" w="full">
                {type === 'inHoursUnanswered' && (
                    <Dropdown
                        w="full"
                        maxW="196px"
                        options={getTimeoutOptions(t)}
                        value={callTimeout}
                        onChange={setCallTimeout}
                    />
                )}
                {redirect ? (
                    <Input
                        maxW="196px"
                        cursor="pointer"
                        isReadOnly
                        value={redirect.display}
                        onClick={drawer.onOpen}
                    />
                ) : (
                    <Button variant="primary" onClick={drawer.onOpen}>
                        {t('calls.selectUTM')}
                    </Button>
                )}
            </HStack>
            <UTMDrawer
                isOpen={drawer.isOpen}
                defaultValue={redirect?.teamID ?? redirect?.menuID ?? redirect?.userID}
                onClose={drawer.onClose}
                onSubmit={onChangeRedirect}
            />
        </InterstitialRadio>
    )
}
