import { selectInData } from '@missionlabs/api'
import { type NumberE164 } from '@missionlabs/types'
import { useMemo } from 'react'
import { useGetNumbersQuery } from 'shared/store'

export const useGetNumberByID = (numberID: string): NumberE164 | undefined => {
    const selectNumber = useMemo(() => {
        return selectInData<NumberE164[], NumberE164>(data =>
            data?.find(data => data.ID === numberID)
        )
    }, [numberID])

    const { number } = useGetNumbersQuery(
        { loadAssociations: true },
        { selectFromResult: result => ({ ...result, number: selectNumber(result) }) }
    )

    return number
}
