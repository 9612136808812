import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ContactBookIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ContactBookIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 11.25H7.5C5.74219 11.25 4.375 12.6562 4.375 14.375C4.375 14.7266 4.64844 15 5 15C5.3125 15 5.625 14.7266 5.625 14.375C5.625 13.3594 6.44531 12.5 7.5 12.5H10C11.0156 12.5 11.875 13.3594 11.875 14.375C11.875 14.7266 12.1484 15 12.5 15C12.8125 15 13.125 14.7266 13.125 14.375C13.125 12.6562 11.7188 11.25 10 11.25ZM18.75 7.5H18.125V11.25H18.75C19.0625 11.25 19.375 10.9766 19.375 10.625V8.125C19.375 7.8125 19.0625 7.5 18.75 7.5ZM8.75 10C10.1172 10 11.25 8.90625 11.25 7.5C11.25 6.13281 10.1172 5 8.75 5C7.34375 5 6.25 6.13281 6.25 7.5C6.25 8.90625 7.34375 10 8.75 10ZM8.75 6.25C9.41406 6.25 10 6.83594 10 7.5C10 8.20312 9.41406 8.75 8.75 8.75C8.04688 8.75 7.5 8.20312 7.5 7.5C7.5 6.83594 8.04688 6.25 8.75 6.25ZM18.75 12.5H18.125V16.25H18.75C19.0625 16.25 19.375 15.9766 19.375 15.625V13.125C19.375 12.8125 19.0625 12.5 18.75 12.5ZM18.75 2.5H18.125V6.25H18.75C19.0625 6.25 19.375 5.97656 19.375 5.625V3.125C19.375 2.8125 19.0625 2.5 18.75 2.5ZM14.375 0H3.125C1.71875 0 0.625 1.13281 0.625 2.5V17.5C0.625 18.9062 1.71875 20 3.125 20H14.375C15.7422 20 16.875 18.9062 16.875 17.5V2.5C16.875 1.13281 15.7422 0 14.375 0ZM15.625 17.5C15.625 18.2031 15.0391 18.75 14.375 18.75H3.125C2.42188 18.75 1.875 18.2031 1.875 17.5V2.5C1.875 1.83594 2.42188 1.25 3.125 1.25H14.375C15.0391 1.25 15.625 1.83594 15.625 2.5V17.5Z"
            fill="currentColor"
        />
    )
})

export const ContactBookIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ContactBookIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 11.25H7.5C5.74219 11.25 4.375 12.6562 4.375 14.375C4.375 14.7266 4.64844 15 5 15H12.5C12.8125 15 13.125 14.7266 13.125 14.375C13.125 12.6562 11.7188 11.25 10 11.25ZM8.75 10C10.1172 10 11.25 8.90625 11.25 7.5C11.25 6.13281 10.1172 5 8.75 5C7.34375 5 6.25 6.13281 6.25 7.5C6.25 8.90625 7.34375 10 8.75 10ZM18.75 12.5H18.125V16.25H18.75C19.0625 16.25 19.375 15.9766 19.375 15.625V13.125C19.375 12.8125 19.0625 12.5 18.75 12.5ZM18.75 2.5H18.125V6.25H18.75C19.0625 6.25 19.375 5.97656 19.375 5.625V3.125C19.375 2.8125 19.0625 2.5 18.75 2.5ZM18.75 7.5H18.125V11.25H18.75C19.0625 11.25 19.375 10.9766 19.375 10.625V8.125C19.375 7.8125 19.0625 7.5 18.75 7.5ZM14.375 0H3.125C1.71875 0 0.625 1.13281 0.625 2.5V17.5C0.625 18.9062 1.71875 20 3.125 20H14.375C15.7422 20 16.875 18.9062 16.875 17.5V2.5C16.875 1.13281 15.7422 0 14.375 0ZM15 17.5C15 17.8516 14.6875 18.125 14.375 18.125H3.125C2.77344 18.125 2.5 17.8516 2.5 17.5V2.5C2.5 2.1875 2.77344 1.875 3.125 1.875H14.375C14.6875 1.875 15 2.1875 15 2.5V17.5Z"
            fill="currentColor"
        />
    )
})

export const ContactBookIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ContactBookIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M14.375 0H3.125C1.71875 0 0.625 1.13281 0.625 2.5V17.5C0.625 18.9062 1.71875 20 3.125 20H14.375C15.7422 20 16.875 18.9062 16.875 17.5V2.5C16.875 1.13281 15.7422 0 14.375 0ZM8.75 5C10.1172 5 11.25 6.13281 11.25 7.5C11.25 8.90625 10.1172 10 8.75 10C7.34375 10 6.25 8.90625 6.25 7.5C6.25 6.13281 7.34375 5 8.75 5ZM12.5 15H5C4.64844 15 4.375 14.7266 4.375 14.375C4.375 12.6562 5.74219 11.25 7.5 11.25H10C11.7188 11.25 13.125 12.6562 13.125 14.375C13.125 14.7266 12.8125 15 12.5 15ZM18.75 2.5H18.125V6.25H18.75C19.0625 6.25 19.375 5.97656 19.375 5.625V3.125C19.375 2.8125 19.0625 2.5 18.75 2.5ZM18.75 7.5H18.125V11.25H18.75C19.0625 11.25 19.375 10.9766 19.375 10.625V8.125C19.375 7.8125 19.0625 7.5 18.75 7.5ZM18.75 12.5H18.125V16.25H18.75C19.0625 16.25 19.375 15.9766 19.375 15.625V13.125C19.375 12.8125 19.0625 12.5 18.75 12.5Z"
            fill="currentColor"
        />
    )
})
