import { switchAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    switchAnatomy.keys
)

const baseStyle = definePartsStyle({
    thumb: defineStyle({
        bg: 'light.tones.white',
        boxSize: '12px',
        boxSizing: 'border-box',
        transform: 'translate(3px, 3px)', // (4px, 4px) - (1px, 1px) for border.
        _checked: {
            transform: 'translate(15px, 3px)' // (16px, 4px) - (1px, 1px) for border.
        },
        _disabled: {
            bg: 'light.tones.stormGrey'
        }
    }),
    track: defineStyle({
        bg: 'light.tones.periwinkle',
        w: '30px', // 32px w/ border
        h: '18px', // 20px w/ border
        borderRadius: '12px',
        border: '1px solid',
        borderColor: 'light.tones.periwinkle',
        padding: 0,
        _hover: {
            bg: 'light.tones.stormGrey',
            borderColor: 'light.tones.stormGrey'
        },
        _focus: {
            bg: 'light.tones.stormGrey',
            border: '1px solid',
            borderColor: 'light.tones.navy'
        },
        _checked: {
            bg: 'light.primary.gamma',
            borderColor: 'light.primary.gamma',
            _hover: {
                bg: 'light.primary.gammaHover',
                borderColor: 'light.primary.gammaHover'
            },
            _focus: {
                bg: 'light.primary.gammaHover',
                borderColor: 'light.tones.midnightExpress'
            }
        },
        _disabled: {
            bg: 'light.tones.white',
            borderColor: 'light.tones.storyGrey',
            _hover: {
                bg: 'light.tones.white'
            },
            _checked: {
                bg: 'light.tones.white',
                borderColor: 'light.tones.stormGrey',
                _hover: {
                    bg: 'light.tones.white',
                    borderColor: 'light.tones.stormGrey'
                }
            }
        }
    })
})

const sizes = {
    sm: definePartsStyle({
        thumb: defineStyle({
            boxSize: '8px',
            transform: 'translate(3px, 3px)', // (4px, 4px) - (1px, 1px) for border.
            _checked: {
                transform: 'translate(11px, 3px)' // (12px, 4px) - (1px, 1px) for border.
            }
        }),
        track: defineStyle({
            w: '22px', // 24px w/ border
            h: '14px' // 16px w/ border
        })
    }),
    lg: defineStyle({
        thumb: defineStyle({
            boxSize: '12px',
            transform: 'translate(3px, 3px)', // (4px, 4px) - (1px, 1px) for border.
            _checked: {
                transform: 'translate(15px, 3px)' // (16px, 4px) - (1px, 1px) for border.
            }
        }),
        track: defineStyle({
            w: '30px', // 32px w/ border
            h: '18px' // 20px w/ border
        })
    })
}

export const ToggleStyles: ComponentStyleConfig = defineMultiStyleConfig({ baseStyle, sizes })
