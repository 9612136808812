import { Menu as ChakraMenu, MenuProps as ChakraMenuProps } from '@chakra-ui/react'
import { FC } from 'react'

export type MenuProps = ChakraMenuProps

export const Menu: FC<MenuProps> = ({
    autoSelect = false,
    closeOnSelect = true,
    isLazy = true,
    children,
    ...props
}) => {
    return (
        <ChakraMenu
            {...props}
            autoSelect={autoSelect}
            closeOnSelect={closeOnSelect}
            isLazy={isLazy}
        >
            {children}
        </ChakraMenu>
    )
}
