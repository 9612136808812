import { useMultiStyleConfig } from '@chakra-ui/react'
import { Activity as ActivityType } from '@missionlabs/types'

import {
    CallInboundIcon,
    CallMissedInboundIcon,
    CallMissedOutboundIcon,
    CallOutboundIcon,
    EmailIcon,
    SMSIcon,
    VoicemailIcon,
    WhatsappIcon
} from '../../atoms/Icons/circleloop'

interface ActivityIconProps {
    activityType: ActivityType
    callLength?: number
    color?: string
}

const getVariant = ({ activityType, callLength }: ActivityIconProps) => {
    switch (activityType) {
        case ActivityType.CALL_RECEIVED:
        case ActivityType.INTERNAL_CALL_RECEIVED:
            return 'call_success'
        case ActivityType.CALL_MISSED:
        case ActivityType.INTERNAL_CALL_MISSED:
            return 'call_missed'
        case ActivityType.CALL_MADE:
        case ActivityType.INTERNAL_CALL_MADE:
            return callLength ? 'call_success' : 'call_missed'
        case ActivityType.MESSAGE_RECEIVED:
        case ActivityType.INTERNAL_MESSAGE_RECEIVED:
            return 'voicemail'
        case ActivityType.WHATSAPP_MESSAGE_RECEIVED:
        case ActivityType.WHATSAPP_MESSAGE_SENT:
            return 'whatsapp'
        case ActivityType.CHAT_MESSAGE_RECEIVED:
        case ActivityType.CHAT_MESSAGE_SENT:
            return 'chat'
        case ActivityType.DO_NOT_USE__EMAIL_RECEIVED:
        case ActivityType.DO_NOT_USE__EMAIL_SENT:
            return 'email'
        default:
            return 'call_success'
    }
}

export const ActivityIcon = ({ activityType, callLength, color }: ActivityIconProps) => {
    const variant = getVariant({ activityType, callLength })

    const styles = useMultiStyleConfig('ActivityIcon', { variant })
    if (color) styles.color = color

    switch (activityType) {
        case ActivityType.CALL_RECEIVED:
        case ActivityType.INTERNAL_CALL_RECEIVED:
            return <CallInboundIcon data-testid="inbound_call_icon" sx={{ ...styles }} />
        case ActivityType.CALL_MISSED:
        case ActivityType.INTERNAL_CALL_MISSED:
            return (
                <CallMissedInboundIcon
                    aria-label="Missed Call"
                    data-testid="missed_call_icon"
                    sx={{ ...styles }}
                />
            )
        case ActivityType.CALL_MADE:
        case ActivityType.INTERNAL_CALL_MADE:
            return callLength ? (
                <CallOutboundIcon data-testid="outbound_call_icon" sx={{ ...styles }} />
            ) : (
                <CallMissedOutboundIcon data-testid="missed_call_icon" sx={{ ...styles }} />
            )
        case ActivityType.MESSAGE_RECEIVED:
        case ActivityType.INTERNAL_MESSAGE_RECEIVED:
            return (
                <VoicemailIcon borderRadius="0px" data-testid="voicemail_icon" sx={{ ...styles }} />
            )
        case ActivityType.WHATSAPP_MESSAGE_RECEIVED:
        case ActivityType.WHATSAPP_MESSAGE_SENT:
            return <WhatsappIcon data-testid="whatsapp_icon" sx={{ ...styles }} />
        case ActivityType.CHAT_MESSAGE_RECEIVED:
        case ActivityType.CHAT_MESSAGE_SENT:
            return <SMSIcon data-testid="sms-icon" sx={{ ...styles }} />
        case ActivityType.DO_NOT_USE__EMAIL_RECEIVED:
        case ActivityType.DO_NOT_USE__EMAIL_SENT:
            return <EmailIcon data-testid="email-icon" sx={{ ...styles }} />
        default:
            return null
    }
}
