import { selectDevices, selectPreferences, useSelector } from '@missionlabs/api'
import { SelectOption } from '@missionlabs/react'
import { MediaDevice } from '@missionlabs/types'
import { useEffect, useMemo, useState } from 'react'

function mapDevicesToOptionByKind(devices: MediaDevice[], kind: MediaDeviceKind) {
    return devices.filter(x => x.kind === kind).map(x => ({ value: x.deviceId, label: x.label }))
}

export interface UseGetDevicesReturn {
    inputDevices: SelectOption[]
    inputDeviceID: string | undefined
    outputDevices: SelectOption[]
    outputDeviceID: string | undefined
    cameraDevices: SelectOption[]
    cameraDeviceID: string | undefined
    ringingOutputDeviceID: string | undefined
}

export const useGetDevices = (): UseGetDevicesReturn => {
    const [inputDevices, setInputDevices] = useState<SelectOption[]>([])
    const [outputDevices, setOutputDevices] = useState<SelectOption[]>([])
    const [cameraDevices, setCameraDevices] = useState<SelectOption[]>([])

    const devices = useSelector(selectDevices)
    const preferences = useSelector(selectPreferences)

    const defaultDevices = useMemo(() => {
        return {
            input: inputDevices[0],
            output: outputDevices[0],
            camera: cameraDevices[0]
        }
    }, [inputDevices, outputDevices, cameraDevices])

    const inputDeviceID = preferences?.inputDeviceID || defaultDevices.input?.value
    const outputDeviceID = preferences?.outputDeviceID || defaultDevices.output?.value
    const cameraDeviceID = preferences?.cameraDeviceID || defaultDevices.camera?.value
    const ringingOutputDeviceID = preferences?.ringingOutputDeviceID || defaultDevices.output?.value

    useEffect(() => {
        if (devices.length) {
            setInputDevices(mapDevicesToOptionByKind(devices, 'audioinput'))
            setOutputDevices(mapDevicesToOptionByKind(devices, 'audiooutput'))
            setCameraDevices(mapDevicesToOptionByKind(devices, 'videoinput'))
        }
    }, [devices])

    return {
        inputDevices,
        inputDeviceID,
        outputDevices,
        outputDeviceID,
        cameraDevices,
        cameraDeviceID,
        ringingOutputDeviceID
    }
}
