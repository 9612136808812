import { openURL } from './url'

export function openNewEmail(email: string) {
    const link = `mailto:${email}`
    openURL(link)
}

export function isValidEmail(email: string) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    return regex.test(email)
}
