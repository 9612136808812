import { SearchQueryArgs, useDispatch } from '@missionlabs/api'
import { usePromisedDebouncedFunction, useVirtualPagination } from '@missionlabs/react'
import { DirectoryEntry } from '@missionlabs/types'
import { addListener } from '@reduxjs/toolkit'
import { useEffect, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { refetch, reset } from 'shared/slices/paginationSlice'
import { store, useLazyContactsSearchQuery } from 'shared/store'

function getQueryArgs(pageSize: number, args: SearchQueryArgs) {
    const sort = args.sortBy ?? ''
    const fieldName = sort.includes('surname') ? 'lastName' : 'firstName'
    const value = sort.includes('za') ? 'desc' : 'asc'

    return {
        maxResults: pageSize,
        sortBy: `${fieldName}:${value}`,
        filter: args.filter,
        searchTerm: args.searchTerm,
        searchFields: args.searchFields,
        myTeamsOnly: args.myTeamsOnly,
        favouritesOnly: args.favouritesOnly,
        sources: args.sources
    }
}

export const usePaginatedContacts = (args: Partial<SearchQueryArgs> = {}) => {
    const dispatch = useDispatch()
    const [contacts, setContacts] = useState<DirectoryEntry[]>([])
    const {
        page,
        pageSize,
        reset: resetPages,
        setItemCount,
        setIsLoading,
        setIsEndOfList,
        onItemsRendered
    } = useVirtualPagination()

    //As the search is debounced we need to set loading to true until the first search is complete.
    const [initialSearch, setInitialSearch] = useState(false)

    const [contactsSearch, { isLoading, isFetching }] = useLazyContactsSearchQuery()
    const getContactsDebounced = usePromisedDebouncedFunction(contactsSearch, 300)

    // creates a listener than can be used inside rtkquery functions to invalidate the paginated contacts.
    // this gives us the benefit of manually controlling the contact requests using a lazy query and still invalidate them as necessary.
    const refetchListener = useMemo(() => {
        return addListener({
            actionCreator: refetch,
            effect: async action => {
                if (action.payload !== 'contacts') return

                try {
                    const response = await getContactsDebounced({
                        from: 0,
                        ...getQueryArgs(contacts.length, args)
                    })
                    setContacts(response.data ?? [])
                } catch (e) {
                    console.error('Failed to refetch contacts, resetting pagination.')
                    resetPages()
                }
            }
        })
    }, [args, contacts.length, getContactsDebounced, resetPages])

    const resetListener = useMemo(() => {
        return addListener({
            actionCreator: reset,
            effect: async action => {
                if (action.payload !== 'contacts') return

                try {
                    setInitialSearch(true)

                    const response = await getContactsDebounced({
                        from: 0,
                        ...getQueryArgs(pageSize, args)
                    })
                    setContacts(response.data ?? [])
                } catch (e) {
                    console.error('Failed to refetch contacts, resetting pagination.')
                } finally {
                    resetPages()
                }
            }
        })
    }, [args, getContactsDebounced, pageSize, resetPages])

    useEffect(() => {
        const refetchUnsubscribe = store.dispatch(refetchListener)
        const resetUnsubscribe = store.dispatch(resetListener)
        return () => {
            refetchUnsubscribe()
            resetUnsubscribe()
        }
    }, [refetchListener, resetListener])

    useEffect(() => {
        setIsLoading(isFetching)
    }, [isFetching, setIsLoading])

    useEffect(() => {
        // Skip request after page index has been reset to prevent duplicated data.
        if (page === 0 && contacts.length) return

        getContactsDebounced({
            from: page * pageSize,
            ...getQueryArgs(pageSize, args)
        }).then(response => {
            if (response.data) {
                setContacts(contacts => [...contacts, ...response.data!])

                if (response.data.length !== pageSize) {
                    setIsEndOfList(true)
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    // runs only on updates to prevent duplication during initial render.
    useUpdateEffect(() => {
        dispatch(reset('contacts'))
    }, [
        args.filter,
        args.sortBy,
        args.searchTerm,
        args.myTeamsOnly,
        args.favouritesOnly,
        args.searchFields,
        args.sources,
        dispatch
    ])

    const replaceContact = (newContact: DirectoryEntry) => {
        setContacts(contacts =>
            contacts.map(contact => (contact.ID === newContact.ID ? newContact : contact))
        )
    }

    return {
        contacts,
        isLoading: isLoading || !initialSearch,
        onItemsRendered,
        setItemCount,
        replaceContact
    }
}
