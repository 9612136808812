import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from '@missionlabs/react'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetReportTypesQuery } from 'shared/store'

import { ReportForm } from './ReportForm/ReportForm'

interface CreateReportSidebarProps {}

export const CreateReportSidebar: FC<CreateReportSidebarProps> = () => {
    const { t } = useTranslation()

    const { create, setCreate } = useAnalytics().report

    /** Rather then have the user wait for the response when the create report is in view
     * load the reportTypes ahead of time
     */
    useGetReportTypesQuery()

    return (
        <Drawer size="md" isOpen={create} onClose={() => setCreate(false)}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>{t('analytics.reports.create')}</DrawerHeader>

                <DrawerBody>
                    <ReportForm />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
