import { Destination, NotificationResponse } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildDestinationAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'destinationsAPi',
        baseQuery: baseQuery,
        tagTypes: ['Destinations', 'NotificationTypes'],
        endpoints: builder => ({
            getDestinationForUser: builder.query<
                Destination,
                { userID: string; destinationID: string }
            >({
                query: ({ userID, destinationID }) => {
                    return `/users/${userID}/callroute/destinations/${destinationID}`
                },
                providesTags: result => [{ type: 'Destinations', id: result?.ID }]
            }),
            getDestinationsForUser: builder.query<Destination[], string>({
                query: userID => `/users/${userID}/callroute/destinations`,
                transformResponse: (resp: { data: Destination[] }) => resp.data,
                providesTags: ['Destinations']
            }),
            postDestinationForUser: builder.mutation<
                Destination,
                { userID: string; payload: Partial<Destination> }
            >({
                query: ({ userID, payload }) => ({
                    url: `/users/${userID}/callroute/destinations`,
                    method: 'POST',
                    body: payload
                }),
                invalidatesTags: ['Destinations']
            }),
            putDestinationForUser: builder.mutation<Destination, Partial<Destination>>({
                query: destination => ({
                    url: `/users/${destination.userID}/callroute/destinations/${destination.ID}`,
                    method: 'PUT',
                    body: destination
                }),
                invalidatesTags: ['Destinations']
            }),
            deleteDestinationForUser: builder.mutation<string, { ID: string; userID: string }>({
                query: ({ ID, userID }) => ({
                    url: `/users/${userID}/callroute/destinations/${ID}`,
                    method: 'DELETE'
                }),
                onQueryStarted: async (
                    { ID: destinationID, userID },
                    { dispatch, queryFulfilled }
                ) => {
                    dispatch(
                        api.util.updateQueryData(
                            'getDestinationForUser',
                            { userID, destinationID },
                            _ => {
                                return null as any
                            }
                        )
                    )

                    dispatch(
                        api.util.updateQueryData('getDestinationsForUser', userID, draft =>
                            draft.filter(({ ID }) => ID !== destinationID)
                        )
                    )

                    try {
                        await queryFulfilled
                    } catch {
                        dispatch(
                            api.util.invalidateTags([
                                'Destinations',
                                { type: 'Destinations', id: destinationID }
                            ])
                        )
                    }
                }
            }),
            postInstallationId: builder.mutation<
                Destination,
                { clientID: string; userID: string; payload: {} }
            >({
                query: body => ({
                    url: `/users/${body.userID}/callroute/destinations`,
                    method: 'POST',
                    body: { ...body.payload, includeNotifications: true }
                }),
                invalidatesTags: ['Destinations']
            }),
            getDestinationNotificationTypes: builder.query<NotificationResponse, string>({
                query: userID => `/users/${userID}/destinations/notifications`,
                providesTags: ['NotificationTypes']
            })
        })
    })

    return { api, ...api }
}
