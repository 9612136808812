import isElectron from 'is-electron'

import Logger from './Logger'

const separator = ' '
export class CallStatsController {
    config: any
    peerConnection: RTCPeerConnection | null
    logger: typeof Logger
    intervalSeconds: number
    history: any[]
    lastFrame: any
    timer: any
    loggingWS?: WebSocket
    callTraceID?: string

    constructor(
        logger: typeof Logger,
        config?,
        private userID = '',
        private clientID = '',
        private env = '',
        private brand = '',
        private locale = ''
    ) {
        logger.putMetric()
        this.config = config ?? {}
        this.peerConnection = null
        this.logger = logger
        this.intervalSeconds = config?.intervalSeconds || 1
        this.history = []
        this.lastFrame = null
        this.timer = null
    }

    onInboundMediaFlatline(..._args: any) {}
    onCallQualityReport(..._args: any) {}

    private _initWebSocket() {
        let brandCode = ''
        switch (this.brand) {
            case 'circleloop':
                brandCode = 'cl'
                break
            default:
                brandCode = this.brand
        }

        if (this.env === 'prod' || this.env === 'production') this.env = 'prd'

        const key = (
            this.env === 'prd' ? process.env.WEBRTC_STATS_KEY_PRD : process.env.WEBRTC_STATS_KEY_DEV
        ) as string

        if (!key) {
            console.warn('CallStatsController : No key found for logging, cannot proceed')
            return
        }

        const params = new URLSearchParams({
            brand: brandCode,
            env: this.env,
            country: this.locale?.toLowerCase(),
            userid: this.userID,
            clientid: this.clientID,
            device: isElectron() ? 'desktopApp-rebuild' : 'webApp-rebuild',
            apikey: key
        })

        const envPrefix = this.env === 'prd' ? 'prd' : 'dev'
        const wsURL = `wss://${envPrefix}-ws-logs.viazeta.com?${params.toString()}`
        this.loggingWS = new WebSocket(wsURL)
    }

    startLogging(peerConnection: RTCPeerConnection, callTraceID: string) {
        // this.logger.log('CallStats : starting logging every ' + this.intervalSeconds + ' seconds')
        this._cancelTimer()
        this._initWebSocket()
        this.callTraceID = callTraceID
        this.peerConnection = peerConnection
        this.timer = setInterval(() => this._logStats(), this.intervalSeconds * 1000)
    }

    private _getInboundStats(inboundRTPStats, codecName) {
        return {
            bytesReceived: inboundRTPStats.bytesReceived,
            packetsReceived: inboundRTPStats.packetsReceived,
            packetsLost: inboundRTPStats.packetsLost,
            codecName: codecName,
            jitterBufferDelay: inboundRTPStats.jitterBufferDelay,
            jitterBufferEmittedCount: inboundRTPStats.jitterBufferEmittedCount,
            jitterReceived: Math.round(inboundRTPStats.jitter * 1000),
            audioOutputLevel: inboundRTPStats.audioLevel
        }
    }

    private _getOutboundStats(
        outboundRTPStats,
        codecName,
        audioInputLevel,
        roundTripTimeMs,
        remoteJitter
    ) {
        return {
            bytesSent: outboundRTPStats.bytesSent,
            packetsSent: outboundRTPStats.packetsSent,
            packetsLost: outboundRTPStats.packetsLost,
            codecName: codecName,
            audioInputLevel: audioInputLevel,
            roundTripTimeMs: Math.round(roundTripTimeMs * 1000),
            jitterReceived: Math.round(remoteJitter * 1000)
        }
    }

    getQualityReport(deltaInbound?: InboundStats, deltaOutbound?: OutboundStats) {
        if (!deltaInbound && !deltaOutbound) {
            return {
                quality: 5
            }
        }
        // console.log(
        //     'getQualityReport, inboundPacketsLostPercent, outboundPacketsLostPercent :',
        //     deltaInbound.packetsLostPercent,
        //     deltaOutbound.packetsLostPercent
        // )
        //return a number 0-5 5=good
        //get the max % packet loss between inbound and outbound
        const maxPacketLossPercent = Math.max(
            deltaInbound?.packetsLostPercent || 0,
            deltaOutbound?.packetsLostPercent || 0
        )
        let quality
        if (maxPacketLossPercent === 0) {
            quality = 5 // Perfect
        } else if (maxPacketLossPercent > 0 && maxPacketLossPercent <= 2) {
            quality = 4 // Good
        } else if (maxPacketLossPercent > 2 && maxPacketLossPercent <= 5) {
            quality = 3 // Poor
        } else if (maxPacketLossPercent > 5 && maxPacketLossPercent <= 10) {
            quality = 2 // Very Poor
        } else if (maxPacketLossPercent > 10 && maxPacketLossPercent <= 20) {
            quality = 1 // Very Very Poor
        } else {
            quality = 0 // Amazed that the call is still up
        }
        return {
            quality: quality
        }
    }

    private _handleStats(stats?: RTCStatsReport) {
        if (!stats) return

        const all: any[] = []
        stats.forEach(report => all.push(report))

        const filterByType = type => all.filter(report => report.type === type)

        const inboundRTPStats = filterByType('inbound-rtp')
        const outboundRTPStats = filterByType('outbound-rtp')
        const inboundCodec = filterByType('codec').filter(
            codec => codec.id === inboundRTPStats[0].codecId
        )
        const outboundCodec = filterByType('codec').filter(
            codec => codec.id === outboundRTPStats[0].codecId
        )
        const candidatePair = filterByType('candidate-pair').find(pair => pair.nominated)
        const localCandidate = filterByType('local-candidate').find(
            lc => lc.id === candidatePair?.localCandidateId
        )
        const mediaSource = filterByType('media-source')
        const remoteInboundRTPStats = filterByType('remote-inbound-rtp')

        if (!localCandidate || !candidatePair) {
            console.warn(
                "CallStatsController getStats : couldn't find stats for ActiveICECandidate, can't do logging"
            )
            return
        }

        if (inboundRTPStats.length !== 1 || inboundCodec.length !== 1) {
            console.warn(
                "CallStatsController getStats : couldn't find stats for InboundStats, can't do logging"
            )
            return
        }

        if (
            outboundRTPStats.length !== 1 ||
            outboundCodec.length !== 1 ||
            mediaSource.length !== 1 ||
            remoteInboundRTPStats.length !== 1
        ) {
            console.warn(
                "CallStatsController getStats : couldn't find stats for OutboundStats, can't do logging"
            )
            return
        }

        const inboundStats = this._getInboundStats(inboundRTPStats[0], inboundCodec[0].mimeType)
        const outboundStats = this._getOutboundStats(
            outboundRTPStats[0],
            outboundCodec[0].mimeType,
            mediaSource[0].audioLevel,
            candidatePair.currentRoundTripTime,
            remoteInboundRTPStats[0].jitter
        )

        const inbound = new InboundStats(inboundStats)
        const outbound = new OutboundStats(outboundStats)
        const activeCandidatePair = new ActiveICECandidate(
            localCandidate.protocol,
            candidatePair.currentRoundTripTime,
            localCandidate.candidateType
        )

        const frame = {
            inbound: inbound,
            outbound: outbound
        }
        let deltaInbound: InboundStats | undefined = undefined
        let deltaOutbound: OutboundStats | undefined = undefined
        let logString = 'CallStats\n' + inbound.toPrettyString() + outbound.toPrettyString()
        if (!this.lastFrame) {
            //first time
            logString = logString + activeCandidatePair.toPrettyString()
        } else {
            deltaOutbound = frame.outbound.getDelta(this.lastFrame.outbound)
            deltaInbound = frame.inbound.getDelta(this.lastFrame.inbound)
            logString =
                logString +
                deltaInbound.toPrettyString('DELTA (' + this.intervalSeconds + ' seconds)') +
                deltaOutbound.toPrettyString('DELTA (' + this.intervalSeconds + ' seconds)')
        }
        this.logger.log('info', logString)
        this.logger.log('trace', logString, null, 'WEBRTC_STATS')
        this.history.push(frame)
        let didMediaDrop = false
        if (deltaInbound) {
            didMediaDrop = deltaInbound.packetsReceived === 0
        }
        if (didMediaDrop) {
            console.warn('Looks like the call media dropped.')
            this.logger.log(
                'trace',
                'CallStats detected no increase in inbound packet count for last ' +
                    this.intervalSeconds +
                    ' seconds',
                null,
                'NO_MEDIA_INBOUND'
            )
            this.onInboundMediaFlatline()
        }
        const qualityReport = this.getQualityReport(
            this.lastFrame ? deltaInbound : inbound,
            this.lastFrame ? deltaOutbound : outbound
        )
        this.onCallQualityReport(qualityReport)

        //Use the deltas to put the Kinesis metrics
        this._putMetrics(deltaInbound || inbound, deltaOutbound || outbound)

        this.lastFrame = frame
    }

    private _cancelTimer() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }

    private async _logStats() {
        // @ts-ignore
        if (navigator.mozGetUserMedia) {
            // console.log('Detected firefox, not performing call stats logging.')
            return
        }
        try {
            const stats = await this.peerConnection?.getStats()
            this._handleStats(stats)
        } catch (exception) {
            console.warn('Attempt to log call stats failed with exception:')
            console.log(exception)
        }
    }

    private _putMetrics(inboundDelta: InboundStats, outboundDelta: OutboundStats) {
        //Put metric for prop.
        if (!this.logger.putMetric) {
            return
        }

        const qualityReport = this.getQualityReport()

        const stats = {
            bytesReceived: inboundDelta.bytesReceived,
            packetsReceived: inboundDelta.packetsReceived,
            inboundPacketLostPercentage: inboundDelta.packetsLostPercent,
            inboundPacketsLost: inboundDelta.packetsLost,
            jitterBufferDelay: inboundDelta.jitterBufferDelay,
            inboundJitterReceived: inboundDelta.jitterReceived,
            inboundCodecName: inboundDelta.codecName,
            audioOutputLevel: inboundDelta.audioOutputLevel,
            bytesSent: outboundDelta.bytesSent,
            packetsSent: outboundDelta.packetsSent,
            outboundPacketsLost: outboundDelta.packetsLost,
            outboundPacketLostPercentage: outboundDelta.packetsLostPercent,
            roundTripMs: outboundDelta.roundTripTimeMs,
            outboundCodecName: outboundDelta.codecName,
            audioInputLevel: outboundDelta.audioInputLevel,
            outboundJitterReceived: outboundDelta.jitterReceived,
            callTraceID: this.callTraceID,
            callQuality: qualityReport.quality
        }

        this.loggingWS?.send(
            JSON.stringify({
                action: 'processWebRTCStats',
                ...stats
            })
        )
    }

    private _debugStats(stats) {
        console.log('Debugging webRTC stats output')
        stats.result().forEach(function (report) {
            console.log(report)
            console.log('\n')
            console.log(report.names())
            report.names().forEach(function (name) {
                console.log('    ' + name + ' : ' + report.stat(name))
            })
        })
    }

    stopLogging() {
        this.logger.log('debug', 'CallStats : stopping logging, after sending stats summary')
        if (this.lastFrame != null && this.history.length > 0) {
            this.lastFrame.inbound.divideBy(this.intervalSeconds * this.history.length)
            this.lastFrame.outbound.divideBy(this.intervalSeconds * this.history.length)

            const summaryString =
                '\nCallStats, averages per second' +
                this.lastFrame.inbound.toPrettyString() +
                this.lastFrame.outbound.toPrettyString()
            this.logger.log('debug', summaryString)
            this.logger.log('debug', summaryString, null, 'WEBRTC_STATS_SUMMARY')
        }
        this._cancelTimer()
        this.peerConnection = null
        this.loggingWS?.close()
    }
}

// Stats classes ********************************************************

// Inbound *******************************************

class InboundStats {
    bytesReceived: number
    packetsReceived: number
    packetsLost: number
    packetsLostPercent: number
    codecName: string
    jitterBufferDelay: number
    jitterBufferEmittedCount: number
    jitterReceived: number
    audioOutputLevel: number

    constructor(stats?) {
        if (!stats) {
            this.bytesReceived = 0
            this.packetsReceived = 0
            this.packetsLost = 0
            this.packetsLostPercent = 0
            this.codecName = 'unknown'
            this.jitterBufferDelay = 0
            this.jitterBufferEmittedCount = 0
            this.jitterReceived = 0
            this.audioOutputLevel = 0
        } else {
            this.bytesReceived = stats.bytesReceived || 0
            this.packetsReceived = stats.packetsReceived || 0
            this.packetsLost = stats.packetsLost || 0
            this.packetsLostPercent = getPercent(this.packetsLost, this.packetsReceived)
            this.codecName = stats.codecName || ''
            this.jitterReceived = stats.jitterReceived || 0
            this.jitterBufferDelay = stats.jitterBufferDelay || 0
            this.jitterBufferEmittedCount = stats.jitterBufferEmittedCount || 0
            this.audioOutputLevel = stats.audioOutputLevel || 0
        }
    }

    toPrettyString(headerText?: string) {
        let head = ''
        if (headerText) {
            head = '\n' + headerText
        }
        let packetLossPercent = '0%'
        if (this.packetsReceived > 0) {
            packetLossPercent = this.packetsLostPercent.toFixed(2) //((this.packetsLost / (this.packetsReceived + this.packetsLost)) * 100).toFixed(2) + "%";
        }
        // prettier-ignore
        return ("\nInbound WebRTC stats" + head +
            separator + "bytesReceived :     " + this.bytesReceived +
            separator +        "packetsReceived :   " + this.packetsReceived +
            separator +        "packetsLost :       " + this.packetsLost +
            separator +        "packetLoss% :       " + packetLossPercent +
            separator +        "codecName :         " + this.codecName +
            separator +        "jitterBufferDelay :    " + this.jitterBufferDelay +
            separator +        "jitterReceived :    " + this.jitterReceived +
            separator +        "audioOutputLevel :  " + this.audioOutputLevel +
            "\n"
        )
    }

    divideBy(num: number) {
        this.bytesReceived = this.bytesReceived / num
        this.packetsReceived = this.packetsReceived / num
        this.packetsLost = this.packetsLost / num
        this.jitterReceived = this.jitterReceived / num
    }

    getDelta(other) {
        const delta = new InboundStats()
        delta.bytesReceived = this.bytesReceived - other.bytesReceived
        delta.packetsReceived = this.packetsReceived - other.packetsReceived
        delta.packetsLost = this.packetsLost - other.packetsLost
        delta.packetsLostPercent = getPercent(delta.packetsLost, delta.packetsReceived)
        delta.jitterBufferDelay = Math.round(
            ((this.jitterBufferDelay - other.jitterBufferDelay) * 1000) /
                (this.jitterBufferEmittedCount - other.jitterBufferEmittedCount)
        )
        delta.jitterReceived = this.jitterReceived // - other.jitterReceived; //inbound jitter received is NOT a delta unlike outbound.
        delta.audioOutputLevel = this.audioOutputLevel
        delta.codecName = this.codecName
        return delta
    }
}

// Outbound **************************************

class OutboundStats {
    bytesSent: number
    packetsSent: number
    packetsLost: number
    codecName: string
    packetsLostPercent: number
    audioInputLevel: number
    roundTripTimeMs: number
    jitterReceived: number

    constructor(stats?) {
        if (!stats) {
            this.bytesSent = 0
            this.packetsSent = 0
            this.packetsLost = 0
            this.codecName = 'unknown'
            this.packetsLostPercent = 0
            this.audioInputLevel = 0
            this.roundTripTimeMs = 0
            this.jitterReceived = 0
        } else {
            this.bytesSent = stats.bytesSent || 0
            this.packetsSent = stats.packetsSent || 0
            this.packetsLost = stats.packetsLost || 0
            this.codecName = stats.codecName || ''
            this.packetsLostPercent = getPercent(this.packetsLost, this.packetsSent)
            this.audioInputLevel = stats.audioInputLevel || 0
            this.roundTripTimeMs = stats.roundTripTimeMs || 0
            this.jitterReceived = stats.jitterReceived || 0
        }
    }

    toPrettyString(headerText?: string) {
        let head = ''
        if (headerText) {
            head = '\n' + headerText
        }
        // eslint-disable-next-line
        let packetLossPercent = '0%'
        if (this.packetsSent > 0) {
            // eslint-disable-next-line
            packetLossPercent = ((this.packetsLost / this.packetsSent) * 100).toFixed(2) + '%'
        }
        // prettier-ignore
        return (
            "\nOutbound WebRTC stats" + head +
            separator + "bytesSent :         " + this.bytesSent +
            separator + "packetsSent :       " + this.packetsSent +
            separator + "packetsLost :       " + this.packetsLost +
            separator + "packetLoss% :       " + packetLossPercent +
            separator + "audioInputLevel :   " + this.audioInputLevel +
            separator + "jitterReceived :    " + this.jitterReceived +
            separator + "roundTripTimeMs :   " + this.roundTripTimeMs +
            separator + "codecName :         " + this.codecName +
            "\n"
        )
    }

    divideBy(num: number) {
        this.bytesSent = this.bytesSent / num
        this.packetsSent = this.packetsSent / num
        this.packetsLost = this.packetsLost / num
        this.jitterReceived = this.jitterReceived / num
    }

    getDelta(other) {
        const delta = new OutboundStats()
        delta.bytesSent = this.bytesSent - other.bytesSent
        delta.packetsSent = this.packetsSent - other.packetsSent
        delta.packetsLost = this.packetsLost - other.packetsLost
        delta.packetsLostPercent = getPercent(delta.packetsLost, delta.packetsSent)
        delta.audioInputLevel = this.audioInputLevel
        delta.roundTripTimeMs = this.roundTripTimeMs
        delta.jitterReceived = this.jitterReceived //- other.jitterReceived; //Looks like jitterReceived is already a delta?
        delta.codecName = this.codecName
        return delta
    }
}

// ActiveICECandidate *****************************************

class ActiveICECandidate {
    constructor(
        public transportType: string,
        public roundTripTime: number,
        public localCandidateType: string
    ) {}

    toPrettyString() {
        // prettier-ignore
        return (
            '\nActive ICE candidate pair WebRTC stats\ntransport type :    ' +
            this.transportType + ' localCandidateTyp : ' +
            this.localCandidateType + ' roundTripTime :     ' +
            this.roundTripTime + '\n'
        )
    }
}

function getPercent(countFailedToSend: number, countSent: number) {
    if (countSent === 0) {
        return 0
    }

    return parseFloat(((countFailedToSend / countSent) * 100).toFixed(2))
}
