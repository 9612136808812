import { Skeleton, Stack, useColorMode, VStack } from '@chakra-ui/react'
import { SkeletonActivity } from '@missionlabs/react'
import { FC } from 'react'

interface ActivitySkeletonProps {
    siblingHeight: number
    number?: number
}

export const ActivitySkeleton: FC<ActivitySkeletonProps> = ({ siblingHeight, number = 3 }) => {
    const { colorMode } = useColorMode()

    const skeletons = Array.from({ length: number }, (_, i) => (
        <span style={{ position: 'relative', width: '100%', marginBottom: '10px' }} key={i}>
            <SkeletonActivity />
        </span>
    ))

    return (
        <VStack
            w="full"
            h={`calc(100% - ${siblingHeight}px)`}
            align="center"
            bg={`${colorMode}.tones.whiteLilac`}
            id="activity-sidebar-empty"
        >
            <Stack w="full" pb="16px" pt="16px" px="24px" justify="space-between">
                <Skeleton w={'100px'} height="20px" />
            </Stack>
            {skeletons}
        </VStack>
    )
}
