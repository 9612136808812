import { ListIconSolid } from '@missionlabs/react/zeta'
import { Action, useRegisterActions } from 'kbar'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { commandMenuShortcuts } from './shortcuts'

export function useActivitiesActions() {
    const navigate = useNavigate()

    const actions: Action[] = useMemo(
        () => [
            {
                id: 'activities',
                name: 'Show unread...',
                shortcut: commandMenuShortcuts.showUnreadActivities,
                section: 'Activities',
                icon: <ListIconSolid aria-label="Home" />
            },
            {
                id: 'activities-missed-calls',
                name: 'Missed Calls',
                parent: 'activities',
                perform: () => navigate('/home?type=missed_calls')
            },
            {
                id: 'activities-voicemails',
                name: 'Voicemails',
                parent: 'activities',
                perform: () => navigate('/home?type=voicemail')
            },
            {
                id: 'activities-sms',
                name: 'SMS',
                parent: 'activities',
                perform: () => navigate('/home?type=sms')
            },
            {
                id: 'activities-flagged',
                name: 'Flagged',
                parent: 'activities',
                perform: () => navigate('/home?type=flagged')
            }
        ],
        [navigate]
    )

    useRegisterActions(actions)
}
