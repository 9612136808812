import { Box, RadioGroup } from '@chakra-ui/react'
import { Body, Button, Input, MenuGroup, Radio, RadioProps } from '@missionlabs/react'
import { UserStatus } from '@missionlabs/types'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface PresenceMenuProps {
    onSave: (selected: string) => void
    status: string
}

export const PresenceMenu: FC<PresenceMenuProps> = ({ onSave, status }) => {
    const { t } = useTranslation()

    const customOption = {
        value: 'custom',
        text: t('nav.presence.custom'),
        variant: 'warning'
    }

    const options = [
        {
            value: UserStatus.Active,
            text: t('nav.presence.active'),
            variant: 'creationary'
        },
        {
            value: UserStatus.Away,
            text: t('nav.presence.away'),
            variant: 'warning'
        },
        { value: UserStatus.Busy, text: t('nav.presence.busy'), variant: 'destructive' },
        {
            value: UserStatus.DND,
            text: t('nav.presence.doNotDisturb'),
            variant: 'destructive'
        }
    ]

    const [option, setOption] = useState(
        options.find(({ text }) => text.toLowerCase() === status.toLowerCase())?.value ??
            customOption.value
    )

    const [customStatus, setCustomStatus] = useState(
        options.find(({ text }) => text.toLowerCase() === status.toLowerCase())?.value ? '' : status
    )

    const onChange = option => {
        setOption(option)
    }

    const onDone = () => {
        if (option === 'custom') {
            return onSave(customStatus)
        }

        const status = options.find(({ value }) => value === option)?.value ?? customOption.value
        onSave(status)
    }

    return (
        <MenuGroup>
            <Box px={3} py={1}>
                <RadioGroup onChange={onChange} value={option}>
                    {[...options, customOption].map(({ value, text, variant }) => (
                        <Radio
                            variant={variant as RadioProps['variant']}
                            key={value}
                            value={value}
                            mb={4}
                        >
                            <Body size="sm" variant="none">
                                {text}
                            </Body>
                        </Radio>
                    ))}
                </RadioGroup>

                {option === 'custom' && (
                    <Input
                        placeholder={t('nav.presence.enterStatus')}
                        onChange={e => setCustomStatus(e.target.value)}
                        value={customStatus}
                    />
                )}
            </Box>
            <Button
                isDisabled={option === 'custom' && !customStatus}
                variant="primary"
                w="100%"
                onClick={onDone}
            >
                {t('Done')}
            </Button>
        </MenuGroup>
    )
}
