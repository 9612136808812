import { ComponentStyleConfig } from '@chakra-ui/react'

export const TableStyles: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        sortIcon: {
            color: `${colorMode}.alerts.rose`,
            w: '10px',
            h: '10px',
            verticalAlign: 'initial'
        },
        pageCount: {
            color: `${colorMode}.tones.navy`
        }
    }),
    defaultProps: {
        variant: 'table'
    },
    variants: {
        table: ({ colorMode }) => ({
            table: {
                borderBottom: '1px solid',
                borderBottomColor: `${colorMode}.tones.periwinkle`
            },
            td: {
                p: '12px',
                fontFamily: 'body',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '150%',
                color: `${colorMode}.tones.midnightExpress`,
                borderBottom: '2px solid',
                borderBottomColor: 'white',
                borderRight: '2px solid',
                borderRightColor: 'white'
            },
            tr: {
                _even: {
                    bg: `${colorMode}.tones.ghostWhite`,
                    _hover: { bg: `${colorMode}.primary.gamma16` }
                },
                _odd: {
                    bg: `${colorMode}.tones.white`,
                    _hover: { bg: `${colorMode}.primary.gamma16` }
                },
                _last: { td: { borderBottom: '0px solid' } }
            },
            th: {
                bg: `${colorMode}.tones.whiteLilac`,
                borderTop: '1px solid',
                borderTopColor: `${colorMode}.tones.periwinkle`,
                borderBottom: '1px solid #9C9FBC',
                borderRight: '2px solid',
                borderRightColor: 'white',
                p: '12px',
                textTransform: 'none',
                letterSpacing: 'normal',
                fontFamily: 'body',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '150%',
                color: `${colorMode}.tones.midnightExpress`
            }
        })
    }
}
