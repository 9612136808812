import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { toastAnatomy } from '../anatomy'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(toastAnatomy.keys)

export const ToastStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => ({
        info: {
            backgroundColor: `${colorMode}.tones.whiteLilac`,
            color: `${colorMode}.primary.gamma`
        },
        warning: {
            backgroundColor: `${colorMode}.alerts.rose`,
            color: 'white'
        },
        error: {
            backgroundColor: `${colorMode}.alerts.rose24`,
            color: `${colorMode}.alerts.rose`
        },
        success: {
            backgroundColor: `${colorMode}.alerts.turquoise24`,
            color: `${colorMode}.alerts.aqua`
        }
    })
})
