import { useEffect, useRef } from 'react'

export type LocalCache<K, V> = {
    getItem: (key: K) => V | undefined
    setItem: (key: K, value: V) => void
    hasItem: (key: K) => boolean
    clear: () => void
}

export function useLocalCache<K, V>(shouldClear?: boolean): LocalCache<K, V> {
    const ref = useRef(new Map<K, V>())

    const getItem = (key: K) => ref.current.get(key)

    const setItem = (key: K, value: V) => ref.current.set(key, value)

    const hasItem = (key: K) => ref.current.has(key)

    const clear = () => ref.current.clear()

    // Clear cache if an error is detected.
    useEffect(() => {
        if (shouldClear) {
            ref.current.clear()
        }
    }, [shouldClear])

    return { getItem, setItem, hasItem, clear }
}
