import { isFAC, isValidNumber } from '@missionlabs/utils'
// @ts-ignore
import * as PNJS from 'libphonenumber-js'
import { useCallback } from 'react'

export type UseFormatToNumberE164Values = {
    formatToNumberE164: (no?: string) => string
    formatToLocalNumber: (no?: string, locale?: PNJS.CountryCode) => string
    isValidPhoneNumber: (no?: string) => boolean
    isValidNumberOrFAC: (no?: string) => boolean
    isUKMobileNumber: (no?: string) => boolean
}

export const useFormatToNumberE164 = (locale = 'GB'): UseFormatToNumberE164Values => {
    const isShortNumber = useCallback(
        (no?: string) => {
            if (!no) return false
            return PNJS.validatePhoneNumberLength(no, locale as PNJS.CountryCode) === 'TOO_SHORT'
        },
        [locale]
    )

    const parseNumber = useCallback(
        (no: string, _locale = locale) => {
            // remove all whitespace from number
            const num = no.replace(/\s/g, '')

            // prevent numbers starting with FAC's being formatted
            if (isFAC(num) || isShortNumber(num)) {
                return num.replace('+', '')
            }

            try {
                return PNJS.parsePhoneNumber(num, _locale as PNJS.CountryCode)
            } catch {
                return no
            }
        },
        [locale, isShortNumber]
    )

    const _formatIDD = useCallback(
        (no?: string) => {
            if (!no) return undefined

            const formatter = new PNJS.AsYouType()
            formatter.input(`+${no}`) // Prepend '+' to detect country code in number.

            const country: PNJS.CountryCode | undefined = formatter.getCountry()
            const parsed: PNJS.PhoneNumber | undefined = formatter.getNumber()

            // return IDD number when locale doesn't match client
            if (parsed && country && country !== locale) {
                return `00${parsed.countryCallingCode}${parsed.nationalNumber}`
            }

            return undefined
        },
        [locale]
    )

    const formatIDD = useCallback(
        (no: string) => {
            if (no.startsWith('00')) return _formatIDD(no.slice(2))
            return _formatIDD(no)
        },
        [_formatIDD]
    )

    const formatToNumberE164 = useCallback(
        (no?: string) => {
            if (!no) return ''
            const parsed = parseNumber(no)

            // return un-formatted number, e.g. extension
            if (typeof parsed === 'string') return parsed

            // validate the number
            if (PNJS.isValidNumber(parsed)) {
                return parsed.number.replace(/\D/g, '')
            }

            // some numbers look like international numbers and the 'nationalNumber'
            // field fails the validation, so double check for on GB numbers
            if (parsed.country === 'GB' && PNJS.isValidNumber(parsed.number))
                return parsed.number.replace(/\D/g, '')

            const idd = formatIDD(no)
            if (typeof idd === 'string') return idd

            //returns internationally formatted number without + or spaces
            return parsed.formatInternational().replace(/\D/g, '')
        },
        [formatIDD, parseNumber]
    )

    const formatToLocalNumber = useCallback(
        (no?: string, locale?: PNJS.CountryCode) => {
            if (!no) return ''
            const parsed = parseNumber(no, locale)

            // return un-formatted number, e.g. extension
            if (typeof parsed === 'string') return parsed

            const idd = formatIDD(no)
            if (typeof idd === 'string' && !locale) return idd

            const formatted = parsed.formatNational()
            if (locale) return `+${parsed.countryCallingCode} ${formatted}`
            // returns nationally formatted number without + or spaces
            return formatted.replace(/\D/g, '')
        },
        [formatIDD, parseNumber]
    )

    const isValidPhoneNumber = useCallback(
        (no?: string) => {
            if (!no) return false
            return PNJS.isValidPhoneNumber(no, locale as PNJS.CountryCode)
        },
        [locale]
    )

    const isValidNumberOrFAC = useCallback((no?: string) => {
        if (!no) return false
        return isValidNumber(no.replace(/[+\s]/g, ''))
    }, [])

    const isUKMobileNumber = useCallback(
        (no?: string) => {
            if (!no) return false

            const parsed = parseNumber(no)
            if (typeof parsed === 'string') return false

            // Does not evaluate to true when a non-GB mobile number is provided (i.e, +347577123456 is false).
            return parsed.getType() === 'MOBILE'
        },
        [parseNumber]
    )

    return {
        formatToNumberE164,
        formatToLocalNumber,
        isValidPhoneNumber,
        isValidNumberOrFAC,
        isUKMobileNumber
    }
}
