import { NotAvailable } from '@missionlabs/types'

export function buildNotAvailable(notAvailable: NotAvailable): NotAvailable {
    return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].reduce(
        (prev, curr) => {
            const value = notAvailable?.[`${curr}`]
            if (value === null || value === undefined) {
                return { ...prev }
            }
            return { ...prev, [`${curr}`]: value }
        },
        { enabled: notAvailable?.['enabled'] ?? false }
    )
}
