import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const HistoryIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HistoryIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M9.375 4.375C9.375 4.0625 9.64844 3.75 10 3.75C10.3125 3.75 10.625 4.0625 10.625 4.375V9.6875L14.0625 11.9922C14.375 12.1875 14.4531 12.5781 14.2578 12.8516C14.0625 13.1641 13.6719 13.2422 13.3984 13.0469L9.64844 10.5469C9.45312 10.4297 9.33594 10.2344 9.33594 10L9.375 4.375ZM10 0C15.5078 0 20 4.49219 20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0ZM1.25 10C1.25 14.8438 5.15625 18.75 10 18.75C14.8047 18.75 18.75 14.8438 18.75 10C18.75 5.19531 14.8047 1.25 10 1.25C5.15625 1.25 1.25 5.19531 1.25 10Z"
            fill="currentColor"
        />
    )
})

export const HistoryIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HistoryIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M9.0625 4.6875C9.0625 4.17969 9.45312 3.75 10 3.75C10.5078 3.75 10.9375 4.17969 10.9375 4.6875V9.53125L14.2578 11.7188C14.6875 12.0312 14.8047 12.6172 14.4922 13.0469C14.2188 13.4766 13.6328 13.5938 13.2031 13.2812L9.45312 10.7812C9.21875 10.625 9.0625 10.3125 9.0625 10V4.6875ZM10 0C15.5078 0 20 4.49219 20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0ZM1.875 10C1.875 14.4922 5.50781 18.125 10 18.125C14.4531 18.125 18.125 14.4922 18.125 10C18.125 5.54688 14.4531 1.875 10 1.875C5.50781 1.875 1.875 5.54688 1.875 10Z"
            fill="currentColor"
        />
    )
})

export const HistoryIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HistoryIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10C20 15.5469 15.5078 20 10 20ZM9.0625 10C9.0625 10.3125 9.21875 10.625 9.45312 10.7812L13.2031 13.2812C13.6328 13.5938 14.2188 13.4766 14.4922 13.0469C14.8047 12.6172 14.6875 12.0312 14.2578 11.7188L10.9375 9.53125V4.6875C10.9375 4.17969 10.5078 3.75 9.96094 3.75C9.45312 3.75 9.02344 4.17969 9.02344 4.6875L9.0625 10Z"
            fill="currentColor"
        />
    )
})
