import { Button, VStack } from '@chakra-ui/react'
import { Body, Heading } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const WhatsAppLanding: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <VStack maxW="100%" align="start">
            <Heading size="h3" py="1">
                {t('admin.whatsapp.landing.title')}
            </Heading>
            <Body>{t('admin.whatsapp.landing.description')}</Body>
            <Button onClick={() => navigate('/admin/whatsapp/signup')}>
                {t('admin.whatsapp.landing.getStarted')}
            </Button>
        </VStack>
    )
}
