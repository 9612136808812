import { Box } from '@chakra-ui/react'
import { Spinner } from '@missionlabs/react'
import { FC } from 'react'

export const AnalyticsLoading: FC = () => {
    return (
        <Box
            w="full"
            h="full"
            bg="white"
            mt={'64px'}
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={100}
        >
            <Spinner size="sm" />
        </Box>
    )
}
