import { yup } from '@missionlabs/react'
import { DirectoryEntry } from '@missionlabs/types'
import { isBefore } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useAddEditMeetingSchema = () => {
    const { t } = useTranslation()

    const validateUsers = useCallback((users: DirectoryEntry[]) => {
        return users.length > 0
    }, [])
    function validateTime(startTime: string, endTime: string) {
        return isBefore(new Date(`2000-01-01T${startTime}`), new Date(`2000-01-01T${endTime}`))
    }
    const meetingDetailsSchema = useMemo(
        () =>
            yup.object().shape({
                meetingName: yup.string().trim().required(),
                description: yup.string(),
                date: yup.date().required(),
                startTime: yup
                    .string()
                    .required()
                    .test({
                        message: '',
                        test: (startTime, ctx) => validateTime(startTime ?? '', ctx.parent.endTime)
                    }),
                endTime: yup
                    .string()
                    .required()
                    .test({
                        message: t('meetings.timeValidation'),
                        test: (endTime, ctx) => validateTime(ctx.parent.startTime, endTime ?? '')
                    }),
                allowGuestUsers: yup.string(),
                muteAtStart: yup.string()
            }),
        [t]
    )

    const meetingShareSchema = useMemo(
        () =>
            yup.object().shape({
                invitedUsers: yup
                    .array()
                    .test(users => validateUsers(users ?? []))
                    .min(1)
            }),
        [validateUsers]
    )

    const meetingSchema = useMemo(
        () =>
            yup.object().shape({
                meetingName: yup.string().trim().required(),
                description: yup.string(),
                date: yup.date().required(),
                startTime: yup
                    .string()
                    .required()
                    .test({
                        message: '',
                        test: (startTime, ctx) => validateTime(startTime ?? '', ctx.parent.endTime)
                    }),
                endTime: yup
                    .string()
                    .required()
                    .test({
                        message: t('meetings.timeValidation'),
                        test: (endTime, ctx) => validateTime(ctx.parent.startTime, endTime ?? '')
                    }),
                allowGuestUsers: yup.string(),
                muteAtStart: yup.string(),
                invitedUsers: yup.array().test(users => validateUsers(users ?? []))
            }),
        [t, validateUsers]
    )

    return { fullSchema: meetingSchema, meetingDetailsSchema, meetingShareSchema }
}
