import { Divider, Grid } from '@chakra-ui/react'
import { useMeasure } from '@missionlabs/react'
import { ContactNavbar } from 'features/voice/ContactNavbar'
import { createContext, FC, ReactNode } from 'react'
import { Navbar } from 'shared/components/Navbar'

import AppBanner from './AppBanner/AppBanner'

export interface MainLayoutProps {
    left: ReactNode
    right: ReactNode
    leftWidth?: number
}

export const AppBannerContext = createContext<{ appBannerHeight: number }>({
    appBannerHeight: 0
})

export const MainLayout: FC<MainLayoutProps> = ({ left, right, leftWidth = 416 }) => {
    const [ref, { blockSize }] = useMeasure<HTMLDivElement>()

    return (
        <>
            <AppBanner ref={ref} />
            <AppBannerContext.Provider value={{ appBannerHeight: blockSize }}>
                <Grid
                    height={`calc(100dvh - ${blockSize}px)`}
                    w="100dvw"
                    templateColumns={`64px 1px ${leftWidth}px 1px 1fr 64px`}
                >
                    <Navbar />
                    <Divider orientation="vertical" />
                    {left}
                    <Divider orientation="vertical" />
                    {right}
                    <ContactNavbar />
                </Grid>
            </AppBannerContext.Provider>
        </>
    )
}
