import { Box, useColorMode } from '@chakra-ui/react'
import { Body, Button, ErrorMessage, Input, ValidatedInput } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthLayout } from 'shared/components/AuthLayout'

export interface ResetPasswordFormProps {
    errorMessage: string | null
    isLoading: boolean
}

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ errorMessage, isLoading }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    return (
        <AuthLayout label={t('Create new password')}>
            <Body size="sm" alignSelf="start" sx={{ color: `${colorMode}.tones.navy` }}>
                {t('auth.resetPasswordInfo')}
            </Body>
            <ValidatedInput name="password" label={t('New password')}>
                <Input type="password" autoComplete="new-password" />
            </ValidatedInput>
            <ValidatedInput name="passwordConfirmation" label={t('Confirm new password')}>
                <Input type="password" autoComplete="new-password" />
            </ValidatedInput>
            {errorMessage && <ErrorMessage error={errorMessage} />}
            <Box w="full" pt="8px">
                <Button type="submit" size="lg" w="full" isLoading={isLoading}>
                    {t('Set new password')}
                </Button>
            </Box>
        </AuthLayout>
    )
}
