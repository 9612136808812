import { Box, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { call, selectPresentationNumber, useDispatch, useSelector } from '@missionlabs/api'
import { ContactDetail, IconButton, ListTitle, MenuButton } from '@missionlabs/react'
import { DotsVerticalIconRegular, PhoneIconLight } from '@missionlabs/react/zeta'
import { DirectoryEntry } from '@missionlabs/types'
import { isExternalContact } from '@missionlabs/utils'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ContactNumberOverflow } from '../contacts/ContactsHeader/ContactNumberOverflow'
import { DrawerMode } from './ContactDrawer'
import { useContactDrawerState } from './hooks/useContactDrawerState'

interface DiallerContactsProps {}

export const DiallerContacts = (_props: DiallerContactsProps) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { contacts, setMode } = useContactDrawerState()

    const borderColor = `${colorMode}.tones.stormGrey`

    const dispatch = useDispatch()

    const presentationNumber = useSelector(selectPresentationNumber) ?? ''

    const handleCall = useCallback(
        (contact: DirectoryEntry, numberOrExt: string) => {
            dispatch(call({ to: numberOrExt, from: presentationNumber, contact }))
            setMode(DrawerMode.VOICE)
        },
        [dispatch, presentationNumber, setMode]
    )

    return (
        <VStack spacing={0} w="100%" align="stretch">
            <ListTitle color="white">{t('Contacts')}</ListTitle>
            <Box w="full" px="24px">
                {contacts.map(contact => (
                    <HStack
                        key={contact.ID}
                        p="16px"
                        w="full"
                        border="1px solid"
                        borderColor="transparent"
                        // By default, only show bottom border
                        borderBottomColor={borderColor}
                        // First element in list can have top border
                        _first={{ borderTopColor: borderColor }}
                        // Show side borders when hovered
                        _hover={{
                            borderLeftColor: borderColor,
                            borderRightColor: borderColor,
                            borderRadius: '4px'
                        }}
                        transition="all 200ms ease"
                        role="listitem"
                    >
                        {/* Show name & company */}
                        <ContactDetail
                            name={contact.fullName}
                            subText={contact.companyName}
                            variant="horizontal-dark"
                            sx={{ width: '100%' }}
                            team={contact.source === 'teamprofile'}
                        />
                        {isExternalContact(contact) ? (
                            <ContactNumberOverflow
                                variant="dark"
                                contact={contact}
                                trigger={
                                    <MenuButton
                                        as={IconButton}
                                        variant="ghost"
                                        color={`${colorMode}.tones.stormGrey`}
                                        aria-label={`Select number for ${contact.fullName}`}
                                        data-testid="contact-number-menu-trigger"
                                    >
                                        <DotsVerticalIconRegular />
                                    </MenuButton>
                                }
                            />
                        ) : (
                            <IconButton
                                borderRadius="50px"
                                icon={<PhoneIconLight />}
                                onClick={() => {
                                    if (!contact.extension) {
                                        return console.error('internal contact extension missing')
                                    }
                                    handleCall(contact, contact.extension)
                                }}
                                aria-label="call"
                                isRounded
                            />
                        )}
                    </HStack>
                ))}
            </Box>
        </VStack>
    )
}
