import {
    BaseURL,
    liveServicesAuth,
    loginSuccess,
    selectAuthenticatedUser,
    selectAuthEnvironment,
    setUser,
    TelecomConfig,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { populateUrl } from '@missionlabs/utils'
import { useCallback, useEffect } from 'react'

export const useGuestAuthentication = (isGuest = false) => {
    const dispatch = useDispatch()

    const { country, dataCentre, environment, product } = useSelector(selectAuthEnvironment)
    const user = useSelector(selectAuthenticatedUser)

    const setupDestination = useCallback(() => {
        const config: TelecomConfig = {
            liveServicesConfig: {
                url: `${populateUrl(BaseURL.LS, {
                    country,
                    dataCentre,
                    environment,
                    product
                })}/meeting-guests`,
                connectToHost: true
            }
        }
        dispatch(liveServicesAuth({ token: '', destinationID: '', config }))
    }, [country, dataCentre, dispatch, product, environment])

    useEffect(() => {
        if (!isGuest || user) return

        setupDestination()
        dispatch(setUser({ clientID: '', token: '', userID: '', preferences: {} }))
        dispatch(loginSuccess())
    }, [dispatch, isGuest, setupDestination, user])
}
