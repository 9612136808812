import { DateFilterParam, Option } from '@missionlabs/types'
import { UseGetUserActivitiesOptions } from 'shared/hooks/useGetUserActivities'

export type AppliedFilterLabel = Option & { filterName: string }
export type ActivityFilters = Pick<
    UseGetUserActivitiesOptions,
    | 'type'
    | 'status'
    | 'directories'
    | 'directions'
    | 'searchTerm'
    | 'from'
    | 'to'
    | DateFilterParam.FROM
    | DateFilterParam.TO
>

export type SelectedDateOption = 'today' | 'yesterday' | 'this_week' | 'custom'
export type SelectedDatePayload = {
    selectedOption?: SelectedDateOption
    from?: string
    to?: string
}

export enum ActivityFooterMessageType {
    SMS = 'sms',
    WhatsApp = 'whatsapp',
    Notes = 'notes'
}
