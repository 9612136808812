import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js/min'
import * as yup from 'yup'

/**
 * Adds the .phone method to yup.string
 *
 * To use import this file before initialising a yup schema
 * and call .phone(locale) on a yup.string instance
 */
// @ts-ignore
yup.addMethod(yup.string, 'phone', function (locale = 'GB', extensions: string[] = []) {
    return this.test('phone', { key: 'validations.number' }, value => {
        if (value && !extensions.includes(value)) {
            return isValidPhoneNumber(value, locale as CountryCode)
        }

        // default to true to not cause any errors when the user hasn't attempted an input
        return true
    })
})
