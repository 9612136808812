import { Box, HStack } from '@chakra-ui/react'
import {
    loginSuccess,
    selectAuthEnvironment,
    selectIsAuthenticated,
    setDataCentre,
    setEnvironment,
    setProduct,
    useDispatch,
    useKumodiLogin,
    useSelector,
    useZetaIDLogin
} from '@missionlabs/api'
import {
    Body,
    Button,
    Checkbox,
    Dropdown,
    ErrorMessage,
    FormProvider,
    Input,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import {
    DeploymentDataCenters,
    DeploymentEnvironments,
    DeploymentProducts
} from '@missionlabs/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { AuthLayout } from 'shared/components/AuthLayout'
import { DocumentTitle } from 'shared/components/DocumentTitle'
import { useAutoLogin } from 'shared/hooks/useAutoLogin'
import { useSetUpDestination } from 'shared/hooks/useSetUpDestination'
import {
    useAuthenticateMutation,
    useLazyTempQuery,
    useLoginMutation,
    useLoginWithJWTMutation
} from 'shared/store'
import { AppConfig } from 'shared/utils/env'

interface LoginFields {
    username: string
    password: string
    rememberDetails: boolean
}

const loginSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
    rememberDetails: yup.boolean()
})

export const Login = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const isAuthenticated = useSelector(selectIsAuthenticated)
    const { dataCentre, environment } = useSelector(selectAuthEnvironment)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const [handleAutoLogin, { session }] = useAutoLogin({
        redirectAfterLogin: location.state?.redirect ?? '/home',
        setErrorMessage
    })
    const setUpDestination = useSetUpDestination()

    const { loginWithZetaID, isLoading: isZetaIDLoading } = useZetaIDLogin({
        zetaAuth: useAuthenticateMutation,
        pbxAuth: useLoginWithJWTMutation
    })
    const { loginWithKumodi, isLoading: isKumodiLoading } = useKumodiLogin({
        useLazyTempQuery: useLazyTempQuery,
        useLoginMutation: useLoginMutation
    })

    const isKumodiAuth = AppConfig.product === DeploymentProducts.phonelineplus
    const isLoginLoading = isKumodiAuth ? isKumodiLoading : isZetaIDLoading

    useEffect(() => {
        if (!AppConfig.product) return
        dispatch(setProduct(AppConfig.product))
    }, [dispatch])

    const methods = useYupForm<LoginFields>(loginSchema, {
        defaultValues: { username: '', password: '', rememberDetails: false }
    })
    const {
        formState: { isSubmitting, isValid }
    } = methods

    async function onSubmit(data: LoginFields, isRetry?: boolean) {
        const { username, password, rememberDetails } = data
        if (isLoginLoading) return

        setErrorMessage(null)

        const isKumodiOrRetry = isRetry || isKumodiAuth
        const loginMethod = isKumodiOrRetry ? loginWithKumodi : loginWithZetaID

        try {
            const resp = await loginMethod(username, password, rememberDetails)
            await setUpDestination(resp)
            dispatch(loginSuccess())
            return navigate(location.state?.redirect ?? '/home')
        } catch (e) {
            if (e.status === 401 && !isKumodiOrRetry) {
                return await onSubmit(data, true)
            }
            console.error(e)
            setErrorMessage('data' in e ? e.data.message || e.data : e.message)
        }
    }

    useEffectOnce(() => {
        if (isAuthenticated) return navigate(location.state?.redirect ?? '/home')
        if (!session) return

        const { username } = session
        methods.reset({ username: username, password: username, rememberDetails: true })
        methods.handleSubmit(handleAutoLogin, console.error)()
    })

    return (
        <>
            <DocumentTitle breadcrumbs={['Login']} />
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(data => onSubmit(data))}>
                    <AuthLayout label={t('Sign in')}>
                        <ValidatedInput name="username" label={t('Username')} shouldDisable>
                            <Input autoComplete="username" placeholder={t('Username')} />
                        </ValidatedInput>
                        <ValidatedInput name="password" label={t('Password')} shouldDisable>
                            <Input type="password" autoComplete="current-password" />
                        </ValidatedInput>
                        <ValidatedInput name="rememberDetails" shouldDisable>
                            <Checkbox name="rememberDetails" w="100%">
                                {t('remember-details')}
                            </Checkbox>
                        </ValidatedInput>

                        {errorMessage && <ErrorMessage error={errorMessage} />}

                        {__DEV__ && (
                            <HStack>
                                <ValidatedInput
                                    label="Data Centre"
                                    shouldDisable
                                    shouldRegister={false}
                                >
                                    <Dropdown
                                        options={Object.values(DeploymentDataCenters).map(v => ({
                                            label: v,
                                            value: v
                                        }))}
                                        value={dataCentre}
                                        onChange={value => dispatch(setDataCentre(value))}
                                    />
                                </ValidatedInput>
                                <ValidatedInput
                                    label="Environment"
                                    shouldDisable
                                    shouldRegister={false}
                                >
                                    <Dropdown
                                        options={Object.values(DeploymentEnvironments).map(v => ({
                                            label: v,
                                            value: v
                                        }))}
                                        value={environment}
                                        onChange={value => dispatch(setEnvironment(value))}
                                    />
                                </ValidatedInput>
                            </HStack>
                        )}
                        <Box w="full" pt="8px">
                            <Button
                                type="submit"
                                size="lg"
                                w="full"
                                isDisabled={!isValid}
                                isLoading={isSubmitting}
                            >
                                {t('Sign in')}
                            </Button>
                        </Box>
                        <Link to="/forgot-password">
                            <Body size="sm" variant="link-bold">
                                {t('Forgotten password?')}
                            </Body>
                        </Link>
                    </AuthLayout>
                </form>
            </FormProvider>
        </>
    )
}
