import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const UserIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'UserIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.80865 13.8823C8.23376 13.2438 7.79319 12.4259 7.51191 11.4934C7.14928 11.1832 6.91185 10.7476 6.85012 10.2819C6.78108 9.80187 6.88871 9.31877 7.16475 8.91077V7.0299C7.16475 4.49268 8.81662 3 11.502 3C14.24 3 15.8383 4.445 15.8383 7.0299V8.91016C16.1144 9.31895 16.2228 9.79987 16.1562 10.2692C16.0923 10.746 15.856 11.1805 15.4913 11.4929C15.2091 12.428 14.769 13.2464 14.1954 13.8826V14.5099C14.4387 14.9835 16.0529 15.8847 20.0484 17.4407L20.367 17.5648C20.7511 17.7144 21.0041 18.0843 21.0041 18.4966V21C21.0041 21.5523 20.5563 22 20.0041 22H4C3.44772 22 3 21.5523 3 21V18.4968C3 18.0846 3.25295 17.7146 3.63706 17.565L3.9556 17.4409C7.62778 16.0106 8.59563 15.3769 8.75843 14.7209C8.77801 14.6419 8.79345 14.583 8.80865 14.5303V13.8823Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_188"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="3"
                y="3"
                width="19"
                height="19"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.80865 13.8823C8.23376 13.2438 7.79319 12.4259 7.51191 11.4934C7.14928 11.1832 6.91185 10.7476 6.85012 10.2819C6.78108 9.80187 6.88871 9.31877 7.16475 8.91077V7.0299C7.16475 4.49268 8.81662 3 11.502 3C14.24 3 15.8383 4.445 15.8383 7.0299V8.91016C16.1144 9.31895 16.2228 9.79987 16.1562 10.2692C16.0923 10.746 15.856 11.1805 15.4913 11.4929C15.2091 12.428 14.769 13.2464 14.1954 13.8826V14.5099C14.4387 14.9835 16.0529 15.8847 20.0484 17.4407L20.367 17.5648C20.7511 17.7144 21.0041 18.0843 21.0041 18.4966V21C21.0041 21.5523 20.5563 22 20.0041 22H4C3.44772 22 3 21.5523 3 21V18.4968C3 18.0846 3.25295 17.7146 3.63706 17.565L3.9556 17.4409C7.62778 16.0106 8.59563 15.3769 8.75843 14.7209C8.77801 14.6419 8.79345 14.583 8.80865 14.5303V13.8823Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_188)"></g>
        </g>
    )
})

export const UserCircleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'UserCircleIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <mask
                id="mask0_834_92"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_834_92)">
                <path
                    d="M5.85 17.1C6.7 16.45 7.65 15.9373 8.7 15.562C9.75 15.1873 10.85 15 12 15C13.15 15 14.25 15.1873 15.3 15.562C16.35 15.9373 17.3 16.45 18.15 17.1C18.7333 16.4167 19.1877 15.6417 19.513 14.775C19.8377 13.9083 20 12.9833 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 12.9833 4.16267 13.9083 4.488 14.775C4.81267 15.6417 5.26667 16.4167 5.85 17.1ZM12 13C11.0167 13 10.1873 12.6627 9.512 11.988C8.83733 11.3127 8.5 10.4833 8.5 9.5C8.5 8.51667 8.83733 7.68733 9.512 7.012C10.1873 6.33733 11.0167 6 12 6C12.9833 6 13.8127 6.33733 14.488 7.012C15.1627 7.68733 15.5 8.51667 15.5 9.5C15.5 10.4833 15.1627 11.3127 14.488 11.988C13.8127 12.6627 12.9833 13 12 13ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22Z"
                    fill="currentColor"
                />
            </g>
        </g>
    )
})

export const UserCircleIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'UserCircleIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 4.375C8.08594 4.375 6.5625 5.9375 6.5625 7.8125C6.5625 9.72656 8.08594 11.25 10 11.25C11.875 11.25 13.4375 9.72656 13.4375 7.8125C13.4375 5.9375 11.875 4.375 10 4.375ZM10 9.375C9.10156 9.375 8.4375 8.71094 8.4375 7.8125C8.4375 6.95312 9.10156 6.25 10 6.25C10.8594 6.25 11.5625 6.95312 11.5625 7.8125C11.5625 8.71094 10.8594 9.375 10 9.375ZM10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 18.125C8.16406 18.125 6.48438 17.5391 5.11719 16.5234C5.78125 15.1953 7.10938 14.375 8.59375 14.375H11.3672C12.8516 14.375 14.1797 15.2344 14.8438 16.5234C13.4766 17.5391 11.7969 18.125 10 18.125ZM16.25 15.1953C15.1953 13.5547 13.3984 12.5 11.3672 12.5H8.59375C6.5625 12.5 4.76562 13.5547 3.71094 15.1953C2.53906 13.7891 1.875 11.9922 1.875 10C1.875 5.54688 5.50781 1.875 10 1.875C14.4531 1.875 18.125 5.54688 18.125 10C18.125 11.9922 17.4219 13.7891 16.25 15.1953Z"
            fill="#1B1F3B"
        />
    )
})

export const UsersIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'UsersIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M1.08347 19.9746H15.9121C16.5 19.9746 16.9775 19.4873 16.9776 18.8877V16.8262C16.9776 16.3799 16.7041 15.9727 16.2979 15.8135C11.9044 14.0762 11.0963 13.3409 10.9527 13.1114V12.585C11.4513 12.0137 11.8419 11.2769 12.0919 10.4351C12.4122 10.149 12.6261 9.74997 12.6866 9.31447C12.7481 8.86677 12.6495 8.42387 12.4063 8.04937V6.36427C12.4063 4.00737 10.982 2.65527 8.49857 2.65527C6.05027 2.65527 4.58887 4.04197 4.58887 6.36427V8.04977C4.34667 8.42427 4.24797 8.86667 4.31007 9.31397C4.37007 9.74897 4.58297 10.1484 4.90287 10.4346C5.15377 11.2764 5.54497 12.0137 6.04297 12.585V13.1104C5.89647 13.3428 5.08397 14.0781 0.697266 15.8135C0.284666 15.9776 0.0180664 16.375 0.0180664 16.8262V18.8877C0.0180664 19.4873 0.496066 19.9746 1.08347 19.9746Z"
                fill="currentColor"
            />
            <path
                d="M17.9863 19.4746C17.9863 19.751 18.2099 19.9746 18.4863 19.9746H23.0674C23.5879 19.9746 24.0107 19.5488 24.011 19.0244V17.4453C24.011 17.0556 23.7678 16.6992 23.4065 16.5576C20.1057 15.2832 19.4231 14.7265 19.2883 14.5615V14.2402C19.6691 13.8027 19.9689 13.247 20.1662 12.6152C20.425 12.3789 20.5969 12.0537 20.6457 11.7002C20.6955 11.3404 20.6183 10.9849 20.4269 10.6807V9.42627C20.4269 7.57327 19.2414 6.46727 17.256 6.46727C15.2696 6.46727 14.0831 7.57327 14.0831 9.42627V10.6821C13.8926 10.9853 13.8155 11.3398 13.8653 11.6987C13.9141 12.0527 14.086 12.3769 14.3448 12.6142C14.5665 13.3241 14.9171 13.9374 15.3663 14.3954C15.3916 14.4215 15.8192 14.6479 16.2621 14.8824C16.628 15.0761 17.0043 15.2754 17.1733 15.372C17.6118 15.6228 17.9863 16.1703 17.9863 16.5576V19.4746Z"
                fill="currentColor"
            />
        </g>
    )
})
