import Transport, { Log, TransportConfig, Transports } from './transport'

interface ConsoleTransportConfig extends TransportConfig {}

const defaultConfig: ConsoleTransportConfig = {
    level: 'info'
}

class ConsoleTransport extends Transport<ConsoleTransportConfig> {
    public readonly name = Transports.console

    constructor(config = {} as ConsoleTransportConfig) {
        super({ ...defaultConfig, ...config })
    }

    public static find(x: any): x is InstanceType<typeof ConsoleTransport> {
        return x.name === Transports.console
    }

    public log({ message, level }: Log): string {
        this.getConsoleMethod(level)(`${message}\n`)
        return message
    }

    private getConsoleMethod(level: string): Function {
        const method = console[level]
        if (method) return method.bind(console)
        return console.log.bind(console)
    }
}

export default ConsoleTransport
