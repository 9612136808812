import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PresentToAllIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PresentToAllIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <g clipPath="url(#clip0_622_144)">
                <path
                    d="M21 3H3C1.89 3 1 3.89 1 5V19C1 20.11 1.89 21 3 21H21C22.11 21 23 20.11 23 19V5C23 3.89 22.11 3 21 3ZM20 19.02H4C3.45 19.02 3 18.57 3 18.02V5.98C3 5.43 3.45 4.98 4 4.98H20C20.55 4.98 21 5.43 21 5.98V18.02C21 18.57 20.55 19.02 20 19.02ZM10 12H8L11.65 8.35C11.85 8.15 12.16 8.15 12.36 8.35L16 12H14V16H10V12Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_622_144">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </g>
    )
})
