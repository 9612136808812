import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const VideoChatIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VideoChatIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 4C1 2.89543 1.89543 2 3 2H21C22.1046 2 23 2.89543 23 4V16C23 17.1046 22.1046 18 21 18H9L5 22V18H3C1.89543 18 1 17.1046 1 16V4ZM6 7C6 6.44772 6.44772 6 7 6H14C14.5523 6 15 6.44772 15 7V8.43971L17.0716 7.10114C17.2431 6.99031 17.4781 6.9687 17.6761 7.04556C17.8741 7.12241 18 7.28412 18 7.46154V12.5385C18 12.7159 17.8741 12.8776 17.6761 12.9544C17.4781 13.0313 17.2431 13.0097 17.0716 12.8989L15 11.5603V13C15 13.5523 14.5523 14 14 14H7C6.44772 14 6 13.5523 6 13V7Z"
            fill="currentColor"
        />
    )
})

export const VideoOffIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VideoOffIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.83333 4C1.82081 4 1 4.92341 1 6.0625V18.4375C1 19.5766 1.82081 20.5 2.83333 20.5H15.6667C16.6792 20.5 17.5 19.5766 17.5 18.4375V15.4681L21.2979 18.2289C21.6124 18.4575 22.0432 18.5021 22.4062 18.3435C22.7692 18.185 23 17.8515 23 17.4856V7.01443C23 6.64851 22.7692 6.31497 22.4062 6.15646C22.0432 5.99795 21.6124 6.04251 21.2979 6.27111L17.5 9.03191V6.0625C17.5 4.92341 16.6792 4 15.6667 4H2.83333ZM4.8246 15.9128C4.61402 16.1234 4.61402 16.4648 4.8246 16.6754C5.03518 16.886 5.37659 16.886 5.58717 16.6754L9.25 13.0126L12.9128 16.6754C13.1234 16.886 13.4648 16.886 13.6754 16.6754C13.886 16.4648 13.886 16.1234 13.6754 15.9128L10.0126 12.25L13.6754 8.58717C13.886 8.37659 13.886 8.03518 13.6754 7.8246C13.4648 7.61402 13.1234 7.61402 12.9128 7.8246L9.25 11.4874L5.58717 7.8246C5.37659 7.61402 5.03518 7.61402 4.8246 7.8246C4.61402 8.03518 4.61402 8.37659 4.8246 8.58717L8.48743 12.25L4.8246 15.9128Z"
            fill="currentColor"
        />
    )
})

export const VideoIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VideoIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 6.0625C1 4.92341 1.82081 4 2.83333 4H15.6667C16.6792 4 17.5 4.92341 17.5 6.0625V9.03191L21.2979 6.27111C21.6124 6.04251 22.0432 5.99795 22.4062 6.15646C22.7692 6.31497 23 6.64851 23 7.01443V17.4856C23 17.8515 22.7692 18.185 22.4062 18.3435C22.0432 18.5021 21.6124 18.4575 21.2979 18.2289L17.5 15.4681V18.4375C17.5 19.5766 16.6792 20.5 15.6667 20.5H2.83333C1.82081 20.5 1 19.5766 1 18.4375V6.0625Z"
            fill="currentColor"
        />
    )
})
