import { Box, Center, Divider, useColorMode, VStack } from '@chakra-ui/react'
import { Body, BoldTranslation, ContactDetail, ListItem, useFieldArray } from '@missionlabs/react'
import { CheckCircleIconSolid } from '@missionlabs/react/zeta'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AddEditMeetingFormFields } from './AddEditMeetingDrawer'

type AddGuestAttendeeProps = {
    email: string
}

export const AddGuestAttendee: FC<AddGuestAttendeeProps> = ({ email }) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    const {
        fields: invitedUsers,
        append,
        remove
    } = useFieldArray<AddEditMeetingFormFields>({
        name: 'invitedUsers'
    })

    const isAdded = useMemo(
        () => invitedUsers.some(user => user.userID === email),
        [invitedUsers, email]
    )
    const onAddAttendee = useCallback(() => {
        const index = invitedUsers.findIndex(item => item.userID === email)
        if (index !== -1) remove(index)
        else append({ userID: email } as any)
    }, [invitedUsers, email, remove, append])
    return (
        <VStack spacing={0} p="24px" divider={<Divider />}>
            <Box pb="24px">
                <Body size="sm" sx={{ color: `${colorMode}.tones.navy` }}>
                    <BoldTranslation
                        i18nKey="WARNING!"
                        textColor={`${colorMode}.alerts.red`}
                        display={'unset'}
                    />{' '}
                    <BoldTranslation i18nKey="meetings.emailWarning" display={'unset'} />
                </Body>
            </Box>

            <ListItem sx={{ minHeight: '79px' }} data-active={isAdded} onClick={onAddAttendee}>
                <ContactDetail name={email} subText={t('Unknown')} />
                {isAdded && (
                    <Center boxSize="32px">
                        <CheckCircleIconSolid color={`${colorMode}.alerts.aqua`} />
                    </Center>
                )}
            </ListItem>
        </VStack>
    )
}
