import { Dropdown, useFormContext, ValidatedInput } from '@missionlabs/react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ReportFormFields } from '../form'

const ItemisedBillingForm: React.FC = () => {
    const { watch, setValue } = useFormContext<ReportFormFields>()
    const { t } = useTranslation()

    const numberDisplayOptions = useMemo(() => {
        return [
            { label: t('None'), value: 'none' },
            { label: t('Partial'), value: 'partial' },
            { label: t('Full'), value: 'full' }
        ]
    }, [t])

    return (
        <>
            <ValidatedInput
                mb={2}
                name="params.numberDisplay"
                label={t('analytics.reports.form.numberDisplay')}
            >
                <Dropdown
                    options={numberDisplayOptions}
                    value={watch('params.numberDisplay')}
                    onChange={(value: string) => {
                        setValue('params.numberDisplay', value, { shouldDirty: true })
                    }}
                    data-testid="params-number-display"
                />
            </ValidatedInput>
        </>
    )
}

export default ItemisedBillingForm
