import { useDisclosure } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { AddNumberDrawer } from './AddNumber/AddNumberDrawer'
import { Directories } from './Directories'

export interface DirectoriesDashboardProps {}

export const DirectoriesDashboard: FC<DirectoriesDashboardProps> = () => {
    const location = useLocation()
    const { isOpen: addOpen, onClose: addOnClose, onOpen: addOnOpen } = useDisclosure()

    useEffect(() => {
        const end = location.pathname.split('/').pop()
        if (!end) return

        if (['add'].includes(end)) addOnOpen()

        return () => {
            addOnClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        <>
            <AddNumberDrawer isOpen={addOpen} onClose={addOnClose} />

            <Directories />
        </>
    )
}
