import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PowerIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PowerIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M7 3.33782C4.01099 5.06687 2 8.29859 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 8.29859 19.989 5.06687 17 3.33782V5.1254C19.1213 6.67093 20.5 9.17446 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 9.17446 4.87867 6.67093 7 5.1254V3.33782Z"
                fill="currentColor"
            />
            <path d="M11 2H13V13H11V2Z" fill="currentColor" />
        </g>
    )
})
