import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const XCircleIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'XCircleIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M7.03125 7.07031C7.26562 6.83594 7.69531 6.83594 7.92969 7.07031L10 9.14062L12.0312 7.07031C12.2656 6.83594 12.6953 6.83594 12.9297 7.07031C13.1641 7.30469 13.1641 7.73438 12.9297 7.96875L10.8594 10L12.9297 12.0703C13.1641 12.3047 13.1641 12.7344 12.9297 12.9688C12.6953 13.2031 12.2656 13.2031 12.0312 12.9688L10 10.8984L7.92969 12.9688C7.69531 13.2031 7.26562 13.2031 7.03125 12.9688C6.79688 12.7344 6.79688 12.3047 7.03125 12.0703L9.10156 10L7.03125 7.96875C6.79688 7.73438 6.79688 7.30469 7.03125 7.07031ZM20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10ZM10 1.25C5.15625 1.25 1.25 5.19531 1.25 10C1.25 14.8438 5.15625 18.75 10 18.75C14.8047 18.75 18.75 14.8438 18.75 10C18.75 5.19531 14.8047 1.25 10 1.25Z"
            fill="currentColor"
        />
    )
})

export const XCircleIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'XCircleIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M6.83594 6.83594C7.1875 6.48438 7.77344 6.48438 8.125 6.83594L9.96094 8.71094L11.8359 6.83594C12.1875 6.48438 12.7734 6.48438 13.125 6.83594C13.5156 7.22656 13.5156 7.8125 13.125 8.16406L11.2891 10L13.125 11.8359C13.5156 12.2266 13.5156 12.8125 13.125 13.1641C12.7734 13.5547 12.1875 13.5547 11.8359 13.1641L9.96094 11.3281L8.125 13.1641C7.77344 13.5547 7.1875 13.5547 6.83594 13.1641C6.44531 12.8125 6.44531 12.2266 6.83594 11.8359L8.67188 10L6.83594 8.16406C6.44531 7.8125 6.44531 7.22656 6.83594 6.83594ZM20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10ZM10 1.875C5.50781 1.875 1.875 5.54688 1.875 10C1.875 14.4922 5.50781 18.125 10 18.125C14.4531 18.125 18.125 14.4922 18.125 10C18.125 5.54688 14.4531 1.875 10 1.875Z"
            fill="currentColor"
        />
    )
})

export const XCircleIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'XCircleIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10ZM6.83594 8.16406L8.67188 10L6.83594 11.8359C6.44531 12.2266 6.44531 12.8125 6.83594 13.1641C7.1875 13.5547 7.77344 13.5547 8.125 13.1641L9.96094 11.3281L11.8359 13.1641C12.1875 13.5547 12.7734 13.5547 13.125 13.1641C13.5156 12.8125 13.5156 12.2266 13.125 11.8359L11.2891 10L13.125 8.16406C13.5156 7.8125 13.5156 7.22656 13.125 6.83594C12.7734 6.48438 12.1875 6.48438 11.8359 6.83594L9.96094 8.71094L8.125 6.83594C7.77344 6.48438 7.1875 6.48438 6.83594 6.83594C6.44531 7.22656 6.44531 7.8125 6.83594 8.16406Z"
            fill="currentColor"
        />
    )
})
