import { UseDisclosureReturn } from '@chakra-ui/react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { FC } from 'react'
import { EmojiPicker } from 'shared/components/EmojiPicker'

import { INSERT_EMOJI_COMMAND } from '../plugins/EmojiPlugin'

export interface EditorEmojiPickerProps {
    disclosure: UseDisclosureReturn
}

export const EditorEmojiPicker: FC<EditorEmojiPickerProps> = ({ disclosure }) => {
    const [editor] = useLexicalComposerContext()

    function handleClick(unicode: string) {
        editor.dispatchCommand(INSERT_EMOJI_COMMAND, unicode)
    }

    return <EmojiPicker {...disclosure} onClick={handleClick} />
}
