import { createIcon } from '@chakra-ui/react'

export const LinkIconLight = createIcon({
    displayName: 'LinkIconLight',
    viewBox: '0 0 24 24',
    path: (
        <g id="SVGRepo_iconCarrier">
            <path
                d="M15.197 3.35462C16.8703 1.67483 19.4476 1.53865 20.9536 3.05046C22.4596 4.56228 22.3239 7.14956 20.6506 8.82935L18.2268 11.2626M10.0464 14C8.54044 12.4882 8.67609 9.90087 10.3494 8.22108L12.5 6.06212"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
            ></path>
            <path
                d="M13.9536 10C15.4596 11.5118 15.3239 14.0991 13.6506 15.7789L11.2268 18.2121L8.80299 20.6454C7.12969 22.3252 4.55237 22.4613 3.0464 20.9495C1.54043 19.4377 1.67609 16.8504 3.34939 15.1706L5.77323 12.7373"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
            ></path>
        </g>
    )
})
