import { HStack, useColorMode } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { CountIndicator } from '@missionlabs/react'
import {
    CallMissedInboundIcon,
    FlagIcon,
    SMSIcon,
    VoicemailIcon,
    WhatsappIcon
} from '@missionlabs/react/circleloop'
import { ALL_UNREAD_ACTIVITIES, UnreadActivity } from '@missionlabs/types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Features, useHasClientFeature } from 'shared/hooks/useHasClientFeature'
import { useGetUserActivityCountsQuery } from 'shared/store'

import { useActivitySidebarContext } from '../ActivitySidebarContext'

interface UnreadActivityCountsProps {}

export const UnreadActivityCounts = (_props: UnreadActivityCountsProps) => {
    const { colorMode } = useColorMode()
    const authenticatedUser = useSelector(selectAuthenticatedUser)
    const whatsappClientEnabled = useHasClientFeature(Features.whatsapp)
    const { quickFilter, setQuickFilter, showFilters, resetFilters } = useActivitySidebarContext()

    const { data, isLoading } = useGetUserActivityCountsQuery(authenticatedUser?.userID ?? '', {
        skip: !authenticatedUser?.userID
    })

    const unreadCounts = useMemo(() => {
        if (!data) return []
        const unreadActivities = [...ALL_UNREAD_ACTIVITIES]
        // Hide WhatsApp unread count if client feature is disabled
        if (!whatsappClientEnabled) {
            unreadActivities.splice(unreadActivities.indexOf(UnreadActivity.WHATSAPPS), 1)
        }
        return unreadActivities.map(type => [type, data[type] ?? 0])
    }, [data, whatsappClientEnabled])

    const onClick = (type: string) => {
        if (quickFilter === type) {
            resetFilters()
        } else {
            setQuickFilter(type)
        }
    }

    if (isLoading || !data) return null

    // Hide the quick filters when the main filters are open
    if (showFilters) return null

    return (
        <HStack
            id="unread-activity-counts"
            w="full"
            justifyContent="space-evenly"
            px={4}
            py={3}
            borderBottom="1px solid"
            borderBottomColor={`${colorMode}.tones.periwinkle`}
            spacing={'auto'}
        >
            {unreadCounts.map(([type, count]) => (
                <UnreadIndicator
                    key={type}
                    type={type}
                    count={count}
                    onClick={() => onClick(type)}
                    isSelected={quickFilter === type}
                />
            ))}
        </HStack>
    )
}

export const UnreadIndicator = ({
    type,
    count,
    onClick,
    isSelected
}: {
    type: UnreadActivity | 'flagged'
    count: number
    onClick: () => void
    isSelected: boolean
}) => {
    const { t } = useTranslation()

    const icon = useMemo(() => {
        switch (type) {
            case UnreadActivity.MISSED_CALLS:
                return <CallMissedInboundIcon />
            case UnreadActivity.VOICEMAILS:
                return <VoicemailIcon />
            case UnreadActivity.WHATSAPPS:
                return <WhatsappIcon w="24px" h="24px" />
            case UnreadActivity.SMS:
                return <SMSIcon />
            case UnreadActivity.FLAGGED:
                return <FlagIcon />
            // case UnreadActivity.EMAILS:
            // TODO: Add email icon
            default:
                return null
        }
    }, [type])

    const title = useMemo(() => {
        switch (type) {
            case UnreadActivity.MISSED_CALLS:
                return t('activities.filters.missedCalls')
            case UnreadActivity.VOICEMAILS:
                return t('activities.filters.voicemail')
            case UnreadActivity.WHATSAPPS:
                return t('activities.filters.whatsapp')
            case UnreadActivity.SMS:
                return t('activities.filters.sms')
            case UnreadActivity.FLAGGED:
                return t('activities.filters.flagged')
            // case UnreadActivity.EMAILS:
            // TODO: Add email icon
            default:
                return type
        }
    }, [type, t])

    const variant = useMemo(() => {
        if (isSelected) return 'selected'
        if (count === 0) return 'noCount'
        if (type === 'flagged') return 'flagged'
        return 'hasCount'
    }, [count, type, isSelected])

    return (
        <CountIndicator
            variant={variant}
            onClick={onClick}
            tooltipLabel={title}
            count={count}
            icon={icon}
        />
    )
}
