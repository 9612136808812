import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const AlignLeftIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AlignLeftIconLight',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M10.875 0.875C11.1875 0.875 11.5 1.1875 11.5 1.5C11.5 1.85156 11.1875 2.125 10.875 2.125H0.875C0.523438 2.125 0.25 1.85156 0.25 1.5C0.25 1.1875 0.523438 0.875 0.875 0.875H10.875ZM17.125 5.875C17.4375 5.875 17.75 6.1875 17.75 6.5C17.75 6.85156 17.4375 7.125 17.125 7.125H0.875C0.523438 7.125 0.25 6.85156 0.25 6.5C0.25 6.1875 0.523438 5.875 0.875 5.875H17.125ZM0.25 11.5C0.25 11.1875 0.523438 10.875 0.875 10.875H10.875C11.1875 10.875 11.5 11.1875 11.5 11.5C11.5 11.8516 11.1875 12.125 10.875 12.125H0.875C0.523438 12.125 0.25 11.8516 0.25 11.5ZM17.125 15.875C17.4375 15.875 17.75 16.1875 17.75 16.5C17.75 16.8516 17.4375 17.125 17.125 17.125H0.875C0.523438 17.125 0.25 16.8516 0.25 16.5C0.25 16.1875 0.523438 15.875 0.875 15.875H17.125Z"
            fill="currentColor"
        />
    )
})

export const AlignLeftIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AlignLeftIconRegular',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M10.5625 0.5625C11.0703 0.5625 11.5 0.992188 11.5 1.5C11.5 2.04688 11.0703 2.4375 10.5625 2.4375H1.1875C0.640625 2.4375 0.25 2.04688 0.25 1.5C0.25 0.992188 0.640625 0.5625 1.1875 0.5625H10.5625ZM16.8125 5.5625C17.3203 5.5625 17.75 5.99219 17.75 6.5C17.75 7.04688 17.3203 7.4375 16.8125 7.4375H1.1875C0.640625 7.4375 0.25 7.04688 0.25 6.5C0.25 5.99219 0.640625 5.5625 1.1875 5.5625H16.8125ZM0.25 11.5C0.25 10.9922 0.640625 10.5625 1.1875 10.5625H10.5625C11.0703 10.5625 11.5 10.9922 11.5 11.5C11.5 12.0469 11.0703 12.4375 10.5625 12.4375H1.1875C0.640625 12.4375 0.25 12.0469 0.25 11.5ZM16.8125 15.5625C17.3203 15.5625 17.75 15.9922 17.75 16.5C17.75 17.0469 17.3203 17.4375 16.8125 17.4375H1.1875C0.640625 17.4375 0.25 17.0469 0.25 16.5C0.25 15.9922 0.640625 15.5625 1.1875 15.5625H16.8125Z"
            fill="currentColor"
        />
    )
})

export const AlignLeftIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AlignLeftIconSolid',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M10.25 2.75H1.5C0.796875 2.75 0.25 2.20312 0.25 1.5C0.25 0.835938 0.796875 0.25 1.5 0.25H10.25C10.9141 0.25 11.5 0.835938 11.5 1.5C11.5 2.20312 10.9141 2.75 10.25 2.75ZM10.25 12.75H1.5C0.796875 12.75 0.25 12.2031 0.25 11.5C0.25 10.8359 0.796875 10.25 1.5 10.25H10.25C10.9141 10.25 11.5 10.8359 11.5 11.5C11.5 12.2031 10.9141 12.75 10.25 12.75ZM0.25 6.5C0.25 5.83594 0.796875 5.25 1.5 5.25H16.5C17.1641 5.25 17.75 5.83594 17.75 6.5C17.75 7.20312 17.1641 7.75 16.5 7.75H1.5C0.796875 7.75 0.25 7.20312 0.25 6.5ZM16.5 17.75H1.5C0.796875 17.75 0.25 17.2031 0.25 16.5C0.25 15.8359 0.796875 15.25 1.5 15.25H16.5C17.1641 15.25 17.75 15.8359 17.75 16.5C17.75 17.2031 17.1641 17.75 16.5 17.75Z"
            fill="currentColor"
        />
    )
})

export const AlignRightIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AlignRightIconLight',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M17.125 0.875C17.4375 0.875 17.75 1.1875 17.75 1.5C17.75 1.85156 17.4375 2.125 17.125 2.125H7.125C6.77344 2.125 6.5 1.85156 6.5 1.5C6.5 1.1875 6.77344 0.875 7.125 0.875H17.125ZM17.125 5.875C17.4375 5.875 17.75 6.1875 17.75 6.5C17.75 6.85156 17.4375 7.125 17.125 7.125H0.875C0.523438 7.125 0.25 6.85156 0.25 6.5C0.25 6.1875 0.523438 5.875 0.875 5.875H17.125ZM17.125 12.125H7.125C6.77344 12.125 6.5 11.8516 6.5 11.5C6.5 11.1875 6.77344 10.875 7.125 10.875H17.125C17.4375 10.875 17.75 11.1875 17.75 11.5C17.75 11.8516 17.4375 12.125 17.125 12.125ZM17.125 15.875C17.4375 15.875 17.75 16.1875 17.75 16.5C17.75 16.8516 17.4375 17.125 17.125 17.125H0.875C0.523438 17.125 0.25 16.8516 0.25 16.5C0.25 16.1875 0.523438 15.875 0.875 15.875H17.125Z"
            fill="currentColor"
        />
    )
})

export const AlignRightIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AlignRightIconRegular',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M16.8125 0.5625C17.3203 0.5625 17.75 0.992188 17.75 1.5C17.75 2.04688 17.3203 2.4375 16.8125 2.4375H7.4375C6.89062 2.4375 6.5 2.04688 6.5 1.5C6.5 0.992188 6.89062 0.5625 7.4375 0.5625H16.8125ZM16.8125 5.5625C17.3203 5.5625 17.75 5.99219 17.75 6.5C17.75 7.04688 17.3203 7.4375 16.8125 7.4375H1.1875C0.640625 7.4375 0.25 7.04688 0.25 6.5C0.25 5.99219 0.640625 5.5625 1.1875 5.5625H16.8125ZM16.8125 12.4375H7.4375C6.89062 12.4375 6.5 12.0469 6.5 11.5C6.5 10.9922 6.89062 10.5625 7.4375 10.5625H16.8125C17.3203 10.5625 17.75 10.9922 17.75 11.5C17.75 12.0469 17.3203 12.4375 16.8125 12.4375ZM16.8125 15.5625C17.3203 15.5625 17.75 15.9922 17.75 16.5C17.75 17.0469 17.3203 17.4375 16.8125 17.4375H1.1875C0.640625 17.4375 0.25 17.0469 0.25 16.5C0.25 15.9922 0.640625 15.5625 1.1875 15.5625H16.8125Z"
            fill="currentColor"
        />
    )
})

export const AlignRightIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AlignRightIconSolid',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M16.5 2.75H7.75C7.04688 2.75 6.5 2.20312 6.5 1.5C6.5 0.835938 7.04688 0.25 7.75 0.25H16.5C17.1641 0.25 17.75 0.835938 17.75 1.5C17.75 2.20312 17.1641 2.75 16.5 2.75ZM16.5 12.75H7.75C7.04688 12.75 6.5 12.2031 6.5 11.5C6.5 10.8359 7.04688 10.25 7.75 10.25H16.5C17.1641 10.25 17.75 10.8359 17.75 11.5C17.75 12.2031 17.1641 12.75 16.5 12.75ZM0.25 6.5C0.25 5.83594 0.796875 5.25 1.5 5.25H16.5C17.1641 5.25 17.75 5.83594 17.75 6.5C17.75 7.20312 17.1641 7.75 16.5 7.75H1.5C0.796875 7.75 0.25 7.20312 0.25 6.5ZM16.5 17.75H1.5C0.796875 17.75 0.25 17.2031 0.25 16.5C0.25 15.8359 0.796875 15.25 1.5 15.25H16.5C17.1641 15.25 17.75 15.8359 17.75 16.5C17.75 17.2031 17.1641 17.75 16.5 17.75Z"
            fill="currentColor"
        />
    )
})

export const AlignCentreIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AlignCentreIconLight',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M13.375 0.875C13.6875 0.875 14 1.1875 14 1.5C14 1.85156 13.6875 2.125 13.375 2.125H4.625C4.27344 2.125 4 1.85156 4 1.5C4 1.1875 4.27344 0.875 4.625 0.875H13.375ZM17.125 5.875C17.4375 5.875 17.75 6.1875 17.75 6.5C17.75 6.85156 17.4375 7.125 17.125 7.125H0.875C0.523438 7.125 0.25 6.85156 0.25 6.5C0.25 6.1875 0.523438 5.875 0.875 5.875H17.125ZM4 11.5C4 11.1875 4.27344 10.875 4.625 10.875H13.375C13.6875 10.875 14 11.1875 14 11.5C14 11.8516 13.6875 12.125 13.375 12.125H4.625C4.27344 12.125 4 11.8516 4 11.5ZM17.125 15.875C17.4375 15.875 17.75 16.1875 17.75 16.5C17.75 16.8516 17.4375 17.125 17.125 17.125H0.875C0.523438 17.125 0.25 16.8516 0.25 16.5C0.25 16.1875 0.523438 15.875 0.875 15.875H17.125Z"
            fill="currentColor"
        />
    )
})

export const AlignCentreIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AlignCentreIconRegular',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M13.0625 0.5625C13.5703 0.5625 14 0.992188 14 1.5C14 2.04688 13.5703 2.4375 13.0625 2.4375H4.9375C4.39062 2.4375 4 2.04688 4 1.5C4 0.992188 4.39062 0.5625 4.9375 0.5625H13.0625ZM16.8125 5.5625C17.3203 5.5625 17.75 5.99219 17.75 6.5C17.75 7.04688 17.3203 7.4375 16.8125 7.4375H1.1875C0.640625 7.4375 0.25 7.04688 0.25 6.5C0.25 5.99219 0.640625 5.5625 1.1875 5.5625H16.8125ZM4 11.5C4 10.9922 4.39062 10.5625 4.9375 10.5625H13.0625C13.5703 10.5625 14 10.9922 14 11.5C14 12.0469 13.5703 12.4375 13.0625 12.4375H4.9375C4.39062 12.4375 4 12.0469 4 11.5ZM16.8125 15.5625C17.3203 15.5625 17.75 15.9922 17.75 16.5C17.75 17.0469 17.3203 17.4375 16.8125 17.4375H1.1875C0.640625 17.4375 0.25 17.0469 0.25 16.5C0.25 15.9922 0.640625 15.5625 1.1875 15.5625H16.8125Z"
            fill="currentColor"
        />
    )
})

export const AlignCentreIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AlignCentreIconSolid',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M12.75 2.75H5.25C4.54688 2.75 4 2.20312 4 1.5C4 0.835938 4.54688 0.25 5.25 0.25H12.75C13.4141 0.25 14 0.835938 14 1.5C14 2.20312 13.4141 2.75 12.75 2.75ZM16.5 7.75H1.5C0.796875 7.75 0.25 7.20312 0.25 6.5C0.25 5.83594 0.796875 5.25 1.5 5.25H16.5C17.1641 5.25 17.75 5.83594 17.75 6.5C17.75 7.20312 17.1641 7.75 16.5 7.75ZM0.25 16.5C0.25 15.8359 0.796875 15.25 1.5 15.25H16.5C17.1641 15.25 17.75 15.8359 17.75 16.5C17.75 17.2031 17.1641 17.75 16.5 17.75H1.5C0.796875 17.75 0.25 17.2031 0.25 16.5ZM12.75 12.75H5.25C4.54688 12.75 4 12.2031 4 11.5C4 10.8359 4.54688 10.25 5.25 10.25H12.75C13.4141 10.25 14 10.8359 14 11.5C14 12.2031 13.4141 12.75 12.75 12.75Z"
            fill="currentColor"
        />
    )
})
