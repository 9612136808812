import { DirectoryEntry } from './contacts'

export enum OutboundMessageType {
    SMS = 'SMS',
    WhatsApp = 'WhatsApp'
}

export type BaseOutboundMessage = {
    ID?: string
    contact?: DirectoryEntry
    type: OutboundMessageType
    toNumber: string
}

export type OutboundSMS = BaseOutboundMessage & {
    fromNumber: string
    type: OutboundMessageType.SMS
    text: string
    userID?: string
}

export enum WhatsAppContentType {
    TEXT = 'text',
    TEMPLATE = 'template'
}

export type OutboundWhatsApp = BaseOutboundMessage & {
    type: OutboundMessageType.WhatsApp
    contentType: WhatsAppContentType
    text?: string
    templateName?: string
    templateLanguage?: string
    numberID?: string
}

export type OutboundMessage = OutboundSMS | OutboundWhatsApp

export const isSMS = (message: OutboundMessage): message is OutboundSMS =>
    message.type === OutboundMessageType.SMS
export const isWhatsApp = (message: OutboundMessage): message is OutboundWhatsApp =>
    message.type === OutboundMessageType.WhatsApp
