import { createTemplate, format } from './format'
import Logger from './logger'
import metrics, { Metric, MetricData } from './metric'
import transports, { Log, Transport, TransportConfig } from './transports'

const logger = new Logger()

export {
    type Log,
    type MetricData,
    type TransportConfig,
    createTemplate,
    format,
    Logger,
    Metric,
    metrics,
    Transport,
    transports
}

export default logger
