import { useFlags } from 'flagsmith/react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Features, useHasClientFeature } from 'shared/hooks/useHasClientFeature'

import { FilterCheckbox } from './FilterCheckbox'

export const DateFilterOptions = () => {
    const { t } = useTranslation()
    return (
        <>
            <FilterCheckbox label={t('activities.filters.today')} value="today" />
            <FilterCheckbox label={t('activities.filters.yesterday')} value="yesterday" />
            <FilterCheckbox label={t('activities.filters.thisWeek')} value="this_week" />
            <FilterCheckbox label={t('activities.filters.specificDate')} value="custom" />
        </>
    )
}

export const MessagesFilterOptions = () => {
    const { t } = useTranslation()
    const whatsappClientEnabled = useHasClientFeature(Features.whatsapp)
    return (
        <>
            <FilterCheckbox label={t('activities.filters.sms')} value="sms" />
            {whatsappClientEnabled && (
                <FilterCheckbox label={t('activities.filters.whatsapp')} value="whatsapps" />
            )}
        </>
    )
}

export const CallsFilterOptions = ({ disableVoicemail = false }) => {
    const { t } = useTranslation()
    /** We want to show video calling filter is it's enabled globally in flagsmith rather then if it's enabled globally & in the clientPlan features.
     * This is because the client, in the future, may upgrade to video features and therefore has video/meeting activity but could downgraded but won't
     * be able to use this filter */
    const flags = useFlags(['meetings'])
    const isVideoEnabled = flags?.meetings?.enabled ?? false

    return (
        <>
            <FilterCheckbox
                label={t('activities.filters.voicemail')}
                value="voicemail"
                isDisabled={disableVoicemail}
            />
            <FilterCheckbox label={t('activities.filters.missedCalls')} value="missed_calls" />
            <FilterCheckbox label={t('activities.filters.voiceCalls')} value="voice_calls" />
            {isVideoEnabled && (
                <FilterCheckbox label={t('activities.filters.videoCalls')} value="meetings" />
            )}
        </>
    )
}

export const DirectionFilterOptions = ({ disabledOutbound = false }) => {
    const { t } = useTranslation()
    return (
        <>
            <FilterCheckbox label={t('activities.filters.inbound')} value="incoming" />
            <FilterCheckbox
                label={t('activities.filters.outbound')}
                value="outgoing"
                isDisabled={disabledOutbound}
            />
        </>
    )
}

export const StatusFilterOptions = () => {
    const { t } = useTranslation()
    return (
        <>
            <FilterCheckbox label={t('activities.filters.new')} value="isUnread" />
            <FilterCheckbox label={t('activities.filters.flagged')} value="flagged" />
        </>
    )
}
