import { Divider, VStack } from '@chakra-ui/react'
import { Body, Checkbox, Input, ValidatedInput, yup } from '@missionlabs/react'
import { Destination, DestinationTypes } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetClientQuery } from 'shared/store'

import {
    EditDeviceDefaultFields,
    EditDeviceDefaultForm,
    EditDeviceDefaultFormProps
} from './EditDeviceDefaultForm'

export type EditDeviceNumberFields = EditDeviceDefaultFields<{
    type: DestinationTypes.number
    announce: boolean
    flipCLI: boolean
    numberE164: string
}>

export const defaultValues: EditDeviceNumberFields = {
    type: DestinationTypes.number,
    label: '',
    nonRinging: false,
    announce: false,
    flipCLI: false,
    numberE164: ''
}

export const EditDeviceNumberForm: FC<EditDeviceDefaultFormProps<EditDeviceNumberFields>> = ({
    values,
    onClose,
    onDelete,
    onSubmit
}) => {
    const { t } = useTranslation()
    const { formatToNumberE164, formatToLocalNumber } = useFormatToNumberE164()

    const { data: client } = useGetClientQuery()

    const editDeviceNumberSchema = yup.object({
        announce: yup.boolean(),
        flipCLI: yup.boolean(),
        numberE164: yup
            .string()
            .phone(client?.locale ?? 'GB')
            .required()
    })

    function getValues(data: Destination | undefined): EditDeviceNumberFields {
        if (!data) return defaultValues
        return {
            type: data.type,
            label: (data.label || data.name) ?? defaultValues.label,
            nonRinging: data.nonRinging ?? defaultValues.nonRinging,
            announce: data.announce ?? defaultValues.announce,
            flipCLI: data.flipCLI ?? defaultValues.flipCLI,
            numberE164: data.numberE164
                ? formatToLocalNumber(data.numberE164)
                : defaultValues.numberE164
        } as EditDeviceNumberFields
    }

    function handleSubmit(data: EditDeviceNumberFields) {
        return onSubmit({ ...data, numberE164: formatToNumberE164(data.numberE164) })
    }

    return (
        <EditDeviceDefaultForm<EditDeviceNumberFields>
            values={values}
            schema={editDeviceNumberSchema}
            getValues={getValues}
            onClose={onClose}
            onDelete={onDelete}
            onSubmit={handleSubmit}
            data-testid="edit-device-number-form"
        >
            <Divider />
            <VStack spacing="10px" align="inherit">
                <ValidatedInput
                    name="numberE164"
                    label={t('devices.add.number.numberE164')}
                    labelSx={{ fontWeight: 500 }}
                >
                    <Input placeholder={t('devices.add.number.numberE164_placeholder')} />
                </ValidatedInput>
                <Body>{t('devices.add.number.announce_text')}</Body>
                <div role="group">
                    <ValidatedInput name="announce">
                        <Checkbox>{t('devices.add.number.announce_checkbox')}</Checkbox>
                    </ValidatedInput>
                    <ValidatedInput name="flipCLI">
                        <Checkbox>{t('devices.add.number.flipCLI_checkbox')}</Checkbox>
                    </ValidatedInput>
                </div>
            </VStack>
        </EditDeviceDefaultForm>
    )
}
