import { Button, HStack, VStack } from '@chakra-ui/react'
import { FormProvider, Input, useToast, useYupForm, ValidatedInput, yup } from '@missionlabs/react'
import { PostWhatsAppVerifyCodeRequest } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { usePostWhatsAppVerifyCodeMutation } from 'shared/store'

export interface VerifyWhatsAppNumberFormProps {}

export const VerifyWhatsAppNumberForm: FC<VerifyWhatsAppNumberFormProps> = () => {
    const { t } = useTranslation()
    const { toast } = useToast()
    const { id: numberID = '' } = useParams()

    const [verifyNumber, { isLoading }] = usePostWhatsAppVerifyCodeMutation()

    const values: PostWhatsAppVerifyCodeRequest = {
        ID: numberID,
        code: ''
    }

    async function onSubmit(data: PostWhatsAppVerifyCodeRequest) {
        try {
            await verifyNumber(data).unwrap()
            toast({
                title: t('admin.whatsapp.verify.success'),
                status: 'success'
            })
        } catch (error) {
            console.error('Error creating number', error)
            toast({
                title: t('admin.whatsapp.verify.error'),
                status: 'error'
            })
        }
    }

    const schema = yup.object({
        code: yup
            .string()
            .required()
            .matches(/^\d{6}$/, 'Pin must be exactly 6 digits')
    })

    const methods = useYupForm<PostWhatsAppVerifyCodeRequest>(schema, {
        values
    })
    const {
        formState: { isDirty }
    } = methods

    return (
        <FormProvider {...methods}>
            <VStack
                as="form"
                align="stretch"
                h="full"
                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <VStack align="start" py={4}>
                    <ValidatedInput name="code">
                        <Input width={312} />
                    </ValidatedInput>
                </VStack>
                <HStack pb={8}>
                    <Button
                        type="submit"
                        size="lg"
                        variant="creationary"
                        isDisabled={!isDirty}
                        isLoading={isLoading}
                    >
                        {t('admin.whatsapp.verify.fields.submit')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}
