import { useUpdateParams } from '@missionlabs/react'
import { DateFilterParam, UnreadActivity } from '@missionlabs/types'
import { useCallback, useMemo, useState } from 'react'
import { UseGetUserActivitiesOptions } from 'shared/hooks/useGetUserActivities'

import { SelectedDatePayload } from '../../types'
import { getDateRange, getQuickFilterValues, initialFilterState } from '../../utils/filters'

type FiltersReducerAction =
    | { type: 'APPLY_QUICK_FILTER'; payload: UnreadActivity }
    | { type: 'SET_FILTERS'; payload: Partial<UseGetUserActivitiesOptions> }
    | { type: 'SELECT_DATE_FILTER'; payload: SelectedDatePayload }
    | { type: 'RESET_FILTERS' }

export function filtersReducer(state: UseGetUserActivitiesOptions, action: FiltersReducerAction) {
    switch (action.type) {
        case 'APPLY_QUICK_FILTER':
            return { ...state, ...getQuickFilterValues(action.payload) }
        case 'SET_FILTERS':
            return { ...state, ...action.payload }
        case 'SELECT_DATE_FILTER':
            return { ...state, ...getDateRange(state, action.payload) }
        case 'RESET_FILTERS':
            return initialFilterState
        default:
            return state
    }
}

/** Convert filter values into query string parameters */
function sanitizeFilterValues(obj: UseGetUserActivitiesOptions) {
    const result = {}
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'undefined') {
            // Leave undefined keys as undefined - they'll get removed by `updateParams()`
            result[key] = value
        } else if (Array.isArray(value)) {
            result[key] = value.join(',')
        } else {
            result[key] = `${value}`
        }
    }
    return result
}

export function useActivitySidebarFilters() {
    const [params, updateParams] = useUpdateParams()

    const [showFilters, setShowFilters] = useState(false)
    const [dateFilterOption, setDateFilterOption] = useState<string | undefined>()
    const [quickFilter, setQuickFilter] = useState<string | undefined>()

    const filters: UseGetUserActivitiesOptions = useMemo(() => {
        return {
            directions: params.get('directions')?.split(',') ?? [],
            directories: params.get('directories')?.split(',') ?? [],
            type: params.get('type')?.split(',') ?? [],
            status: params.get('status')?.split(',') ?? [],
            [DateFilterParam.FROM]: params.get(DateFilterParam.FROM) ?? undefined,
            [DateFilterParam.TO]: params.get(DateFilterParam.TO) ?? undefined,
            searchTerm: params.get('searchTerm') ?? ''
        }
    }, [params])

    const setFilters = useCallback(
        (newFilters: Partial<UseGetUserActivitiesOptions>) => {
            const nextState = filtersReducer(filters, { type: 'SET_FILTERS', payload: newFilters })
            updateParams(sanitizeFilterValues(nextState))
            // If a quick filter has been applied and a different filter manually applied
            // then remove the quick filter
            if (quickFilter) {
                setQuickFilter(undefined)
            }
        },
        [filters, updateParams, quickFilter, setQuickFilter]
    )

    const selectDateFilter = useCallback(
        (payload: SelectedDatePayload) => {
            const nextState = filtersReducer(filters, { type: 'SELECT_DATE_FILTER', payload })
            updateParams(sanitizeFilterValues(nextState))
            setDateFilterOption(payload.selectedOption)
        },
        [updateParams, filters]
    )

    const resetFilters = useCallback(() => {
        updateParams({
            directions: undefined,
            directories: undefined,
            type: undefined,
            status: undefined,
            searchTerm: undefined,
            [DateFilterParam.FROM]: undefined,
            [DateFilterParam.TO]: undefined
        })
        setDateFilterOption(undefined)
        setQuickFilter(undefined)
    }, [updateParams])

    const applyQuickFilter = useCallback(
        (quickFilter: string) => {
            const nextState = filtersReducer(filters, {
                type: 'APPLY_QUICK_FILTER',
                payload: quickFilter as UnreadActivity
            })
            updateParams(sanitizeFilterValues(nextState))
            setQuickFilter(quickFilter)
        },
        [updateParams, filters]
    )

    const hasAppliedFilters = useMemo(() => {
        return Object.entries(filters).some(([key, value]) => {
            if (key === 'searchTerm') return false
            if (Array.isArray(value)) return value.length > 0
            return !!value
        })
    }, [filters])

    return {
        showFilters,
        setShowFilters,
        filters,
        dateFilterOption,
        setFilters,
        selectDateFilter,
        resetFilters,
        quickFilter,
        setQuickFilter: applyQuickFilter,
        hasAppliedFilters
    }
}
