import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { bannerAnatomy } from 'theme/anatomy'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(bannerAnatomy.keys)

export const BannerStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: () => ({
        container: {
            w: 'full',
            px: '24px',
            py: '16px'
        },
        label: {
            fontSize: '16px',
            lineHeight: '28.16px'
        },
        icon: {
            boxSize: '32px',
            color: 'white'
        }
    }),

    variants: {
        error: ({ colorMode }) => ({
            container: {
                bg: `${colorMode}.alerts.jazberryJam`
            },
            label: {
                color: `${colorMode}.alerts.rose24`
            }
        })
    }
})
