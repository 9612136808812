import { Box, ButtonGroup } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    Button,
    Input,
    ListTitle,
    Scrollable,
    VirtualPaginationContextProvider
} from '@missionlabs/react'
import { DirectoryEntry } from '@missionlabs/types'
import { alphabetizeContacts } from '@missionlabs/utils'
import { ContactsRow } from 'features/contacts/ContactsList/ContactsRow'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList } from 'react-window'
import { usePaginatedContacts } from 'shared/hooks/usePaginatedContacts'

import { Section } from './ChatList/ChatList'

interface ChatContactsListProps {
    selectedUserIDs?: Set<string>
    onClickContact: (userID: string) => void
}
export type VirtualisedContactListItem = DirectoryEntry | Section

export const ChatContactsList: FC<ChatContactsListProps> = ({
    selectedUserIDs,
    onClickContact
}) => {
    const { t } = useTranslation()
    const [showSelected, setShowSelected] = useState(false)
    const user = useSelector(selectAuthenticatedUser)

    const [searchTerm, setSearchTerm] = useState('')
    const paginationArgs = useMemo(
        () => ({ searchTerm, filter: ['internal'] as any }),
        [searchTerm]
    )
    const {
        contacts: allContacts,
        setItemCount,
        onItemsRendered
    } = usePaginatedContacts(paginationArgs)
    const listRef = useRef<VariableSizeList>(null)

    const groupedContacts = useMemo(() => {
        if (!user?.userID) return []

        // Only show personal contacts, including ones that have been shared
        const filtered = allContacts.filter(contact => {
            const isInternal =
                contact.source === 'userprofile' && contact.externalID !== user.userID

            if (showSelected && selectedUserIDs) {
                return selectedUserIDs.has(contact.externalID!) && isInternal
            } else {
                return isInternal
            }
        })

        // Group the contacts by the first letter of their last name
        const alphabetized = alphabetizeContacts(filtered, { sortField: 'lastName' })

        // Sort the groups by letter
        return Object.entries(alphabetized).sort(([letterA], [letterB]) => {
            if (letterA === letterB) return 0
            return letterA < letterB ? -1 : 1
        })
    }, [allContacts, user, showSelected, selectedUserIDs])

    const flatGroupedContacts = useMemo(() => {
        return groupedContacts.reduce((prev, [letter, contacts]) => {
            if (contacts.length < 1) return prev
            return [...prev, letter, ...contacts]
        }, [] as (string | DirectoryEntry)[])
    }, [groupedContacts])

    const getRowSize = useCallback(
        (index: number) => {
            const rowHeights = flatGroupedContacts.map(item => (typeof item === 'string' ? 72 : 78))
            return rowHeights[index]
        },
        [flatGroupedContacts]
    )

    useEffect(() => {
        setItemCount(flatGroupedContacts.length)
    }, [flatGroupedContacts, setItemCount])

    return (
        <VirtualPaginationContextProvider pageSize={25}>
            {selectedUserIDs && (
                <ButtonGroup isAttached w="100%" mb={4}>
                    <Button
                        variant="tertiary"
                        w="50%"
                        opacity={showSelected ? 0.6 : 1}
                        onClick={() => setShowSelected(false)}
                    >
                        {t('chatGroups.all')}
                    </Button>
                    <Button
                        variant="tertiary"
                        w="50%"
                        opacity={showSelected ? 1 : 0.6}
                        onClick={() => setShowSelected(true)}
                    >
                        {t('chatGroups.selected')}
                    </Button>
                </ButtonGroup>
            )}
            <Input
                value={searchTerm}
                onChange={e => setSearchTerm(e.currentTarget.value)}
                placeholder="Enter name or number"
                mb={6}
            />
            <Box w="full" flexGrow="1" role="list" height={`calc(100% - 120px)`}>
                <AutoSizer>
                    {({ height, width }) => (
                        <VariableSizeList
                            ref={listRef}
                            height={height}
                            width={width}
                            itemSize={getRowSize}
                            itemCount={flatGroupedContacts.length}
                            itemData={flatGroupedContacts}
                            onItemsRendered={onItemsRendered}
                            outerElementType={Scrollable}
                        >
                            {({ style, index, data }) => {
                                const item = data[index]

                                if (typeof item === 'string') {
                                    return (
                                        <div style={style} role="listitem">
                                            <ListTitle sx={{ paddingLeft: 0, paddingRight: 0 }}>
                                                {item}
                                            </ListTitle>
                                        </div>
                                    )
                                }

                                return (
                                    <div style={{ ...style }} role="listitem">
                                        <ContactsRow
                                            key={item.ID}
                                            data={item}
                                            isActive={selectedUserIDs?.has(item.externalID)}
                                            onClick={() => onClickContact(item.externalID)}
                                            renderTick
                                        />
                                    </div>
                                )
                            }}
                        </VariableSizeList>
                    )}
                </AutoSizer>
            </Box>
        </VirtualPaginationContextProvider>
    )
}
