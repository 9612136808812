import { selectAuthenticatedUser, useDispatch, useSelector } from '@missionlabs/api'
import { LocalCache, useLocalCache } from '@missionlabs/react'
import { SearchActivitiesRequest, UserActivity } from '@missionlabs/types'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { useUpdateEffect } from 'react-use'
import { ListOnItemsRenderedProps } from 'react-window'
import { poll, refetch, reset } from 'shared/slices/paginationSlice'
import { useUpdateUserActivityMutation } from 'shared/store'

import { usePaginatedActivitySearch } from './usePaginatedActivitySearch'

export type UseGetUserActivitiesOptions = Omit<SearchActivitiesRequest, 'userID'> & {
    skip?: boolean
}

interface UseGetUserActivitiesReturn {
    all: UserActivity[]
    handleRead: (id: string) => Promise<UserActivity>
    handleReadAll: () => Promise<void>
    refetch: () => void
    isLoading: boolean
    isFetching: boolean
    isError: boolean
    cache: LocalCache<string, Partial<UserActivity>>
    onItemsRendered: (props: ListOnItemsRenderedProps) => void
    setItemCount: Dispatch<SetStateAction<number>>
}

const POLLING_INTERVAL = 10000

// TODO: Need to programmatically invalidate queries when a missed call/voicemail is received via the call controller
export const useGetUserActivities = (
    options?: UseGetUserActivitiesOptions
): UseGetUserActivitiesReturn => {
    const dispatch = useDispatch()
    const user = useSelector(selectAuthenticatedUser)

    const [updateActivity, { isError: isUpdateError }] = useUpdateUserActivityMutation()
    const cache = useLocalCache<string, Partial<UserActivity>>(isUpdateError)

    const args = useMemo(() => {
        return {
            ...(options ?? {}),
            userID: user?.userID ?? ''
        }
    }, [options, user])

    const {
        activities: _activities = [],
        isLoading,
        isFetching,
        isError,
        onItemsRendered,
        setItemCount
    } = usePaginatedActivitySearch(args, {
        skip: !user || options?.skip
    })

    const paginationKey = 'searchActivities'
    const refetchActivities = useCallback(() => dispatch(refetch(paginationKey)), [dispatch])
    const resetActivities = useCallback(() => dispatch(reset(paginationKey)), [dispatch])
    const pollActivities = useCallback(() => dispatch(poll(paginationKey)), [dispatch])

    // reset paginated activities when args change.
    useUpdateEffect(() => {
        resetActivities()
    }, [args, resetActivities])

    useEffect(() => {
        const interval = setInterval(() => pollActivities(), POLLING_INTERVAL)

        return () => clearInterval(interval)
    }, [pollActivities])

    // Since we poll this endpoint, optimistic updates can be overwritten.
    // We have a local cache to persist changes between polls and is reset only after an error state.
    const activities = useMemo(() => {
        return _activities.map(activity => {
            const cacheItem = cache.getItem(activity.ID)
            return cacheItem ? { ...activity, ...cacheItem } : activity
        })
    }, [_activities, cache])

    const isUnread = (item: UserActivity) => item.isUnread || item.isUnread === undefined

    async function handleRead(id: string) {
        if (!activities) return Promise.reject()

        const activity = activities.find(item => item.ID === id)
        if (!activity) return Promise.reject(`No activity found matching ID ${id}`)
        if (!user?.userID) return Promise.reject(`No user exists to mark activity as read`)

        // If an activity is already read, don't mark it as unread
        if (!isUnread(activity)) return activity

        cache.setItem(activity.ID, { isUnread: !isUnread(activity) })

        return updateActivity({
            ID: activity.ID,
            // FIXME: this was previously activity.userID - I think so that users can mark team activities as read
            // however not every activity seems to have a `userID` so this was `undefined` and would break the network request
            userID: user.userID,
            entry: { ...activity, isUnread: !isUnread(activity) }
        }).unwrap()
    }

    async function handleReadAll() {
        const unread = activities.filter(isUnread)
        if (!unread?.length) return Promise.resolve()
        await Promise.all(unread.map(item => handleRead(item.ID)))
    }

    return {
        all: activities,
        handleRead,
        handleReadAll,
        refetch: refetchActivities,
        isLoading,
        isFetching,
        isError,
        cache,
        onItemsRendered,
        setItemCount
    }
}
