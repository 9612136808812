import { $isAutoLinkNode, $isLinkNode } from '@lexical/link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import {
    $insertNodes,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand,
    TextNode
} from 'lexical'
import { FC, useEffect } from 'react'
import { useGetEmoticonsQuery } from 'shared/store'

import { $createEmojiNode } from '../nodes/EmojiNode'

export const INSERT_EMOJI_COMMAND: LexicalCommand<string> = createCommand('INSERT_EMOJI_COMMAND')

function findAndTransformEmoji(node: TextNode, emoticons: Record<string, string>): TextNode | null {
    // don't transform link contents
    if ($isAutoLinkNode(node.getParent()) || $isLinkNode(node.getParent())) return null

    const text = node.getTextContent()

    for (let i = 0; i < text.length; i++) {
        // attempt to match an emoticon within 4 characters
        const input = text.slice(i, i + 5)

        // Check the last character is an empty space
        const chars = input.split('')
        if (chars[chars.length - 1] !== ' ') continue

        const emojiText = emoticons[input.trim()]
        if (emojiText === undefined) continue
        let targetNode: TextNode
        if (i === 0) targetNode = node.splitText(i + 3)[0]
        else targetNode = node.splitText(i, i + 3)[1]

        const emojiNode = $createEmojiNode(emojiText)
        targetNode.replace(emojiNode)
        return emojiNode
    }

    return null
}

function emojiTransform(node: TextNode, emoticons: Record<string, string>): void {
    let targetNode: TextNode | null = node

    while (targetNode !== null) {
        if (!targetNode.isSimpleText()) return
        targetNode = findAndTransformEmoji(targetNode, emoticons)
    }
}

export interface EmojiPluginProps {}

export const EmojiPlugin: FC<EmojiPluginProps> = () => {
    const [editor] = useLexicalComposerContext()

    const { data } = useGetEmoticonsQuery()

    useEffect(() => {
        return mergeRegister(
            editor.registerNodeTransform(TextNode, node => {
                if (data) emojiTransform(node, data)
            }),
            editor.registerCommand(
                INSERT_EMOJI_COMMAND,
                text => {
                    $insertNodes([$createEmojiNode(text)])
                    return true
                },
                COMMAND_PRIORITY_EDITOR
            )
        )
    }, [editor, data])

    return null
}
