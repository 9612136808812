import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { WhatsAppBusinessTemplate } from '@missionlabs/types'
import { getDestinationOSName } from '@missionlabs/utils'
import isElectron from 'is-electron'
import {
    $createParagraphNode,
    $createTextNode,
    $getRoot,
    CLEAR_EDITOR_COMMAND,
    COMMAND_PRIORITY_EDITOR,
    COMMAND_PRIORITY_HIGH,
    createCommand,
    KEY_ENTER_COMMAND,
    LexicalCommand
} from 'lexical'
import { FC, useCallback, useEffect } from 'react'

// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/getModifierState#modifier_keys_on_gecko
function getPlatformModifier() {
    const platform = isElectron()
        ? getDestinationOSName(window.platform)
        : window.navigator.platform
    if (/Mac|iPod|iPhone|iPad/i.test(platform)) return 'Meta'
    return 'Control'
}

export const SEND_MESSAGE_COMMAND: LexicalCommand<void> = createCommand('SEND_MESSAGE_COMMAND')

export interface SMSSendMessagePluginProps {
    onSendMessage: (message: { text: string }) => void
    channelID: string
    showCounter?: boolean
    template?: WhatsAppBusinessTemplate
}

export const SMSSendMessagePlugin: FC<SMSSendMessagePluginProps> = ({
    onSendMessage,
    channelID,
    template
}) => {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        if (template) {
            const body = template.components.find(component => component.type === 'BODY')
            const text = body?.text ?? ''
            editor.update(() => {
                const root = $getRoot()
                root.clear()
                const p = $createParagraphNode()
                p.append($createTextNode(text))
                root.append(p)
            })
        } else {
            editor.update(() => {
                const root = $getRoot()
                root.clear()
            })
        }
    }, [editor, template])

    const handleSendMessage = useCallback(() => {
        //remove draft message form storage
        sessionStorage.removeItem(channelID)
        const text = editor.getRootElement()?.textContent ?? ''
        const message = {
            text
        }
        onSendMessage({ ...message })
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
        return true
    }, [channelID, editor, onSendMessage])

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                SEND_MESSAGE_COMMAND,
                handleSendMessage,
                COMMAND_PRIORITY_EDITOR
            ),
            editor.registerCommand(
                KEY_ENTER_COMMAND,
                event => {
                    // user must press platform modifier + enter in order to send a message
                    const modifier = event?.getModifierState(getPlatformModifier())
                    if (!modifier) return false
                    event?.preventDefault()
                    return handleSendMessage()
                },
                COMMAND_PRIORITY_HIGH
            )
        )
    }, [editor, handleSendMessage])

    return null
}
