import { useDisclosure } from '@chakra-ui/react'
import {
    Body,
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    useToast
} from '@missionlabs/react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDeleteWhatsAppBusinessMutation } from 'shared/store'

export const FacebookDisconnectDialog = () => {
    const { t } = useTranslation()
    const ref = useRef<HTMLButtonElement>(null)
    const navigate = useNavigate()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const { toast } = useToast()

    const [deleteBusiness, { isLoading: isDeleting }] = useDeleteWhatsAppBusinessMutation()

    const handleConfirm = async () => {
        try {
            await deleteBusiness().unwrap()
            toast({
                title: t('admin.whatsapp.disconnect.success'),
                status: 'success'
            })
            handleClose()
        } catch (error) {
            toast({
                title: t('admin.whatsapp.disconnect.error'),
                status: 'error'
            })
        }
    }

    const handleClose = () => {
        onClose()
    }

    const handleTransition = () => {
        navigate('/admin/whatsapp')
    }

    return (
        <Dialog
            leastDestructiveRef={ref}
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={handleTransition}
        >
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton onClick={handleClose} />
                <DialogHeader>{t('admin.whatsapp.disconnect.title')}</DialogHeader>
                <DialogBody gap="12px">
                    <Body>{t('admin.whatsapp.disconnect.body')}</Body>
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel'), onClick: handleClose }}
                    confirm={{
                        label: t('admin.whatsapp.disconnect.disconnect'),
                        isDestructive: true,
                        variant: 'negatory',
                        onClick: () => handleConfirm()
                    }}
                    isLoading={isDeleting}
                />
            </DialogContent>
        </Dialog>
    )
}
