const listAnatomy: { keys: ('item' | 'title' | 'heading')[] } = {
    keys: ['item', 'title', 'heading']
}
const navbarAnatomy: { keys: ('bar' | 'item' | 'itemContainer')[] } = {
    keys: ['bar', 'item', 'itemContainer']
}

const searchInputAnatomy: { keys: ('group' | 'input' | 'rightAside')[] } = {
    keys: ['group', 'input', 'rightAside']
}

const bannerAnatomy: { keys: ('container' | 'label' | 'icon')[] } = {
    keys: ['container', 'label', 'icon']
}

const emptyPageAnatomy: { keys: ('logo' | 'title' | 'subtitle' | 'link')[] } = {
    keys: ['logo', 'title', 'subtitle', 'link']
}

const countIndicatorAnatomy: { keys: ('container' | 'icon' | 'count')[] } = {
    keys: ['container', 'icon', 'count']
}

const callsAnatomy: {
    keys: (
        | 'container'
        | 'details'
        | 'timeContainer'
        | 'duration'
        | 'time'
        | 'dualCallContainer'
        | 'callStatus'
        | 'callWarning'
    )[]
} = {
    keys: [
        'container',
        'details',
        'timeContainer',
        'duration',
        'time',
        'dualCallContainer',
        'callStatus',
        'callWarning'
    ]
}

const toastAnatomy: { keys: ('info' | 'warning' | 'error' | 'success')[] } = {
    keys: ['info', 'warning', 'error', 'success']
}

export {
    bannerAnatomy,
    callsAnatomy,
    countIndicatorAnatomy,
    emptyPageAnatomy,
    listAnatomy,
    navbarAnatomy,
    searchInputAnatomy,
    toastAnatomy
}
