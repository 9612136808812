/*  The idea is that if we want to store non default selections, and restore to those values if the device is back 

    So when ever Audio settings change to a non 'default' device, we want to store it here,
    And when device list changes we revert back to default, or keep the same device if it is still present.
*/

import { PreferencesState } from '@missionlabs/api/src/slices/preferencesSlice'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AudioPreferenceState
    extends Pick<
        PreferencesState,
        'userID' | 'inputDeviceID' | 'outputDeviceID' | 'ringingOutputDeviceID'
    > {
    restoreOnReconnect: boolean
}

const initialState: AudioPreferenceState = {
    restoreOnReconnect: false
}

export const audioPreferencesSlice = createSlice({
    name: 'audioPreferencesSlice',
    initialState,
    reducers: {
        updateAudioPreferences: (state, action: PayloadAction<AudioPreferenceState>) => {
            return _updateAudioPreferences(state, action.payload)
        }
    }
})

export type AudioPreferenceSliceRootState = {
    audioPreferenceSlice: ReturnType<typeof audioPreferencesSlice.getInitialState>
}

const _updateAudioPreferences = (state, audioPreferences: AudioPreferenceState) => {
    const localStorage = JSON.parse(
        window.localStorage.getItem(`userAudioPreferencesID=${state.userID}`) ?? '{}'
    )

    state = {
        ...state,
        ...localStorage,
        ...audioPreferences
    }

    window.localStorage.setItem(
        `userAudioPreferencesID=${state.userID ?? 'guest'}`,
        JSON.stringify(state)
    )

    return state
}

export const selectAudioPreferences = (state: AudioPreferenceSliceRootState) =>
    state.audioPreferenceSlice

export const { updateAudioPreferences } = audioPreferencesSlice.actions
