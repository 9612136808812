import { IVRMenu, NewIVRMenu } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildIVRMenusApi = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'ivrMenusAPI',
        tagTypes: ['IVRMenus'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getIVRMenus: builder.query<IVRMenu[], void>({
                query: () => '/menus',
                transformResponse: (response: { data: IVRMenu[] }) => response.data,
                providesTags: result => {
                    return result
                        ? [
                              ...result.map(menu => ({ type: 'IVRMenus' as const, id: menu.ID })),
                              'IVRMenus'
                          ]
                        : ['IVRMenus']
                }
            }),
            getIVRMenu: builder.query<IVRMenu, string>({
                query: menuID => `/menus/${menuID}`,
                providesTags: result => {
                    return result
                        ? [{ type: 'IVRMenus' as const, id: result.ID }, 'IVRMenus']
                        : ['IVRMenus']
                }
            }),
            createIVRMenu: builder.mutation<IVRMenu, NewIVRMenu>({
                query: menu => ({
                    url: `/menus`,
                    method: 'POST',
                    body: menu
                }),
                invalidatesTags: (result, _error, _args) => [
                    { type: 'IVRMenus', id: result?.ID },
                    'IVRMenus'
                ]
            }),
            updateIVRMenu: builder.mutation<IVRMenu, IVRMenu>({
                query: menu => ({
                    url: `/menus/${menu.ID}`,
                    method: 'PUT',
                    body: menu
                }),
                invalidatesTags: (result, _error, _args) => [
                    { type: 'IVRMenus', id: result?.ID },
                    'IVRMenus'
                ]
            }),
            deleteIVRMenu: builder.mutation<void, string>({
                query: menuID => ({
                    url: `/menus/${menuID}`,
                    method: 'DELETE'
                }),
                invalidatesTags: (_result, _error, args) => [
                    { type: 'IVRMenus', id: args },
                    'IVRMenus'
                ]
            })
        })
    })

    return { api, ...api }
}
