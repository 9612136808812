import { VStack } from '@chakra-ui/react'
import { Scrollable, useFieldArray } from '@missionlabs/react'
import { DirectoryEntry } from '@missionlabs/types'
import { isValidEmail } from '@missionlabs/utils'
import { ContactsRow } from 'features/contacts/ContactsList/ContactsRow'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ShareBasicList } from './ShareBasicList'

type MeetingCreateShareFormProps = {}

export const SelectedForm: FC<MeetingCreateShareFormProps> = () => {
    const { t } = useTranslation()

    const { fields: selectedUsers, remove } = useFieldArray<{ invitedUsers: DirectoryEntry[] }>({
        name: 'invitedUsers'
    })

    const handleToggle = useCallback(
        index => {
            remove(index)
        },
        [remove]
    )

    const listItems = useMemo(() => {
        return selectedUsers.map((item, index) => {
            if (isValidEmail(item?.userID ?? '')) {
                item.fullName = item.userID
                item.companyName = t('Unknown')
            }

            return (
                <div role="listitem" key={item.userID}>
                    <ContactsRow
                        key={item.ID}
                        data={item}
                        isActive={true}
                        renderTick
                        onClick={() => handleToggle(index)}
                    />
                </div>
            )
        })
    }, [selectedUsers, handleToggle, t])

    return (
        <VStack mx="24px" height="100%" p="24px">
            <ShareBasicList>
                <Scrollable height="100%">{listItems}</Scrollable>
            </ShareBasicList>
        </VStack>
    )
}
