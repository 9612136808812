import { UserActivity } from '@missionlabs/types'

export type Section = {
    key: string
    object: string
    startOfDay: number
    endOfDay: number
    title?: string
}

export const isActivitySection = (x: any): x is Section =>
    typeof x === 'object' && 'object' in x && x.object === 'section'

export type VirtualisedActivityItem =
    | (UserActivity & {
          section: Section
      })
    | Section

export enum ScrollDirection {
    /** Start at the top and view older items whilst scrolling downwards */
    DOWN = 'DOWN',
    /** Start at the bottom and view older items whilst scrolling upwards */
    UP = 'UP'
}
