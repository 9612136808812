import { HStack, StackProps, useColorMode, VStack } from '@chakra-ui/react'
import { IconButton } from '@missionlabs/react'
import { XIconSolid } from '@missionlabs/react/zeta'
import { FC, PropsWithChildren, useContext } from 'react'
import { AppBannerContext } from 'shared/components/MainLayout'

interface VoiceWrapperProps extends StackProps {
    onClose: () => void
}

export const VoiceWrapper: FC<PropsWithChildren<VoiceWrapperProps>> = ({
    children,
    onClose,
    ...props
}) => {
    const { colorMode } = useColorMode()

    const { appBannerHeight } = useContext(AppBannerContext)

    return (
        <VStack
            bg={`${colorMode}.tones.midnightExpress`}
            position="absolute"
            top={appBannerHeight + 'px'}
            right="0"
            bottom="0"
            mr="64px"
            spacing={0}
            {...props}
        >
            <HStack spacing="4px" w="full" h="min-content" pt="16px" px="16px">
                <IconButton
                    size="sm"
                    variant="transparent"
                    icon={<XIconSolid color="white" />}
                    aria-label="Close drawer"
                    onClick={onClose}
                />
            </HStack>
            {children}
        </VStack>
    )
}
