import { HStack, VStack } from '@chakra-ui/react'
import { CodeVerificationBody, NumberVerificationBody } from '@missionlabs/api'
import {
    Body,
    Button,
    FormProvider,
    Input,
    useFormatToNumberE164,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useVerifyCodeMutation, useVerifyNumberMutation } from 'shared/store'

interface VerifyNumberFormProps {
    onClose: () => void
    defaultValues: Partial<NumberVerificationBody>
    setFormState: (data: any) => void
}

const VerifyCodeForm: FC<VerifyNumberFormProps> = ({ onClose, defaultValues, setFormState }) => {
    const { t } = useTranslation()

    const { isValidPhoneNumber } = useFormatToNumberE164()

    const [putVerifyCode, { isLoading }] = useVerifyCodeMutation()
    const [resendCode] = useVerifyNumberMutation()

    const verifySchema = yup.object().shape({
        userID: yup.string().required(),
        clientID: yup.string().required(),
        verifyNumber: yup
            .string()
            .test({
                message: {
                    key: 'validations.invalidNumber'
                },
                test: value => isValidPhoneNumber(value)
            })
            .required(),
        ID: yup.string().required(),
        code: yup.string().length(6).required()
    })

    const methods = useYupForm<CodeVerificationBody>(verifySchema, {
        defaultValues
    })

    const {
        setValue,
        setError,
        formState: { isValid }
    } = methods

    const onSubmitCode = useCallback(
        async (data: CodeVerificationBody) => {
            await putVerifyCode(data)
                .unwrap()
                .then(() => {
                    onClose()
                })
                .catch(() => {
                    setValue('code', '')
                    setError('code', { message: t('Incorrect code') })
                })
        },
        [putVerifyCode, onClose, setError, setValue, t]
    )

    const initialFormReset = useCallback(() => {
        setFormState(prev => ({
            ...prev,
            ID: '',
            code: ''
        }))
    }, [setFormState])

    const resendCodeHandler = useCallback(async () => {
        const { verifyNumber = '', verifyMethod = '', userID = '', clientID = '' } = defaultValues
        const { ID = '' } = await resendCode({
            verifyNumber,
            verifyMethod: verifyMethod as 'call' | 'sms',
            userID,
            clientID
        }).unwrap()

        setValue('ID', ID)
    }, [resendCode, defaultValues, setValue])

    return (
        <FormProvider {...methods}>
            <VStack as="form" onSubmit={methods.handleSubmit(onSubmitCode)} w="full" h="100%" p={4}>
                <VStack h={'90%'} w="full">
                    <HStack w="full" align="flex-start" justify="space-evenly">
                        <ValidatedInput
                            name="code"
                            label={t('admin.numbers.mirror.enterVerificationCode', {
                                value: defaultValues.verifyMethod
                            })}
                        >
                            <Input autoComplete="off" />
                        </ValidatedInput>
                    </HStack>

                    <VStack w="full" align="flex-start" pt={2}>
                        <Body>
                            <b>{t('admin.numbers.mirror.noCodeReceived')}</b>
                        </Body>
                        <Button onClick={resendCodeHandler} variant="link-underline" size={'md'}>
                            {t('admin.numbers.mirror.resendVerify', {
                                value: defaultValues.verifyNumber
                            })}
                        </Button>
                        <Button onClick={initialFormReset} variant="link-underline" size={'md'}>
                            {t('admin.numbers.mirror.checkDifferent')}
                        </Button>
                    </VStack>
                </VStack>

                <HStack w="full">
                    <Button w="full" size="lg" variant="secondary" onClick={onClose}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        w="full"
                        size="lg"
                        variant="creationary"
                        isLoading={isLoading}
                        isDisabled={!isValid}
                        type="submit"
                    >
                        {t('Submit')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}

export default VerifyCodeForm
