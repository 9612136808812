import {
    MeetingParticipant,
    ParticipantType,
    ParticipantVideoStatus,
    selectActiveMeeting,
    useSelector
} from '@missionlabs/api'
import { RosterAttendeeType, useRosterState } from 'amazon-chime-sdk-component-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export interface Participant {
    name: string
    chimeAttendeeId?: string
    externalUserId: string
    type: ParticipantType
    companyName?: string
    jobTitle?: string
    inWaitingRoom: boolean
    status: ParticipantVideoStatus
}

const isJoined = (
    participant: MeetingParticipant & { accepted?: boolean },
    attendees: RosterAttendeeType[]
): undefined | RosterAttendeeType => {
    return attendees.find(({ externalUserId }) => externalUserId === participant.userID)
}
const isLeft = (participant: MeetingParticipant & { accepted?: boolean }) => {
    return (
        participant.status === ParticipantVideoStatus.GONE ||
        participant.status === ParticipantVideoStatus.REJECTED
    )
}
const isPending = (participant: MeetingParticipant & { accepted?: boolean }) => {
    return (
        !participant.accepted &&
        participant.status !== ParticipantVideoStatus.GONE &&
        participant.status !== ParticipantVideoStatus.WAITING &&
        participant.status !== ParticipantVideoStatus.REJECTED
    )
}
const isWaitingRoom = (participant: MeetingParticipant & { accepted?: boolean }) => {
    return participant.status === ParticipantVideoStatus.WAITING
}

const addLabelToArray = (label: string, array: Array<Participant>): Array<Participant | string> => {
    if (!array.length) return array
    return [label, ...array]
}

export interface UseParticipantsDataReturn {
    participantsData: Array<Participant | string>
    waitingRoomParticipants: Array<Participant>
    pendingParticipants: Array<Participant>
    joinedParticipants: Array<Participant>
    leftParticipants: Array<Participant>
}

export const useParticipantsData = (): UseParticipantsDataReturn => {
    const { roster } = useRosterState()
    const { t } = useTranslation()

    const meeting = useSelector(selectActiveMeeting)

    const data = useMemo(() => {
        const otherParticipants = meeting?.otherParticipants ?? []

        const joinedParticipants: Array<Participant> = [],
            leftParticipants: Array<Participant> = [],
            pendingParticipants: Array<Participant> = [],
            waitingRoomParticipants: Array<Participant> = []

        for (const participant of otherParticipants) {
            const {
                userID: externalUserId,
                status,
                type,
                fullName,
                displayName,
                company
            } = participant

            const attendee = {
                externalUserId: externalUserId,
                name: fullName ?? displayName ?? 'Unknown',
                type,
                status,
                companyName: company,
                inWaitingRoom: false
            }

            const rosterAttendee = isJoined(participant, Object.values(roster))
            if (rosterAttendee) {
                joinedParticipants.push({
                    ...attendee,
                    chimeAttendeeId: rosterAttendee.chimeAttendeeId
                })
                continue
            }
            if (isLeft(participant)) {
                leftParticipants.push(attendee)
                continue
            }
            if (isPending(participant)) {
                pendingParticipants.push(attendee)
                continue
            }
            if (isWaitingRoom(participant)) {
                waitingRoomParticipants.push({ ...attendee, inWaitingRoom: true })
                continue
            }
        }
        return {
            participantsData: [
                ...addLabelToArray(t('Waiting'), waitingRoomParticipants),
                ...addLabelToArray(t('Pending'), pendingParticipants),
                ...addLabelToArray(t('In the call'), joinedParticipants),
                ...addLabelToArray(t('Left'), leftParticipants)
            ],
            waitingRoomParticipants,
            pendingParticipants,
            joinedParticipants,
            leftParticipants
        }
    }, [roster, meeting, t])

    return data
}
