import { AlertDialogBody, Flex, FlexProps, ModalBodyProps, useModalStyles } from '@chakra-ui/react'
import { Body } from 'atoms'
import { BoldTranslation } from 'molecules/BoldTranslation'
import { Children, FC, isValidElement, PropsWithChildren } from 'react'

export type DialogBodyProps = ModalBodyProps & FlexProps

export const DialogBody: FC<PropsWithChildren<DialogBodyProps>> = ({ children, ...props }) => {
    const { body } = useModalStyles()
    return (
        <AlertDialogBody direction="column" {...props} as={Flex}>
            {Children.map(children, child => {
                // immediately render element if valid, text will otherwise be wrapped in body with styling
                if (isValidElement(child) && child.type !== BoldTranslation) return child
                return (
                    <Body size="sm" sx={{ color: body.color }}>
                        {child}
                    </Body>
                )
            })}
        </AlertDialogBody>
    )
}
