import { AuthenticatedUser, buildDestinationAPI, buildUsersAPI } from '@missionlabs/api'

export function useGetUserData(
    auth: AuthenticatedUser | undefined,
    useGetUserQuery: ReturnType<typeof buildUsersAPI>['useGetUserQuery'],
    useGetUserCallRouteQuery: ReturnType<typeof buildUsersAPI>['useGetUserCallRouteQuery'],
    useGetDestinationForUserQuery: ReturnType<
        typeof buildDestinationAPI
    >['useGetDestinationForUserQuery'],
    skip?: {
        user?: boolean
        callRoute?: boolean
        destination?: boolean
    }
) {
    //Lets poll for any updates every 10mins
    const POLLING_INTERVAL = 600_000

    const { data: user, ...userMeta } = useGetUserQuery(auth?.userID ?? '', {
        skip: !auth || skip?.user,
        pollingInterval: POLLING_INTERVAL
    })
    const { data: callRoute, ...callRouteMeta } = useGetUserCallRouteQuery(auth?.userID ?? '', {
        skip: !auth || skip?.callRoute,
        pollingInterval: POLLING_INTERVAL
    })
    const { data: destination, ...destinationMeta } = useGetDestinationForUserQuery(
        { destinationID: auth?.destinationID ?? '', userID: auth?.userID ?? '' },
        {
            skip: !auth || skip?.destination,
            pollingInterval: POLLING_INTERVAL
        }
    )

    return {
        user,
        callRoute,
        destination,
        __meta: {
            user: userMeta,
            callRoute: callRouteMeta,
            destination: destinationMeta
        }
    }
}
