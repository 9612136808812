import { AnalyticsGraphView } from 'features/analytics/components/AnalyticsGraph/AnalyticsGraphView'
import { AnalyticsPieChartTotals } from 'features/analytics/components/AnalyticsPieChartTotals/AnalyticsPieChartTotals'
import AnalyticsTabsView from 'features/analytics/components/AnalyticsTabView'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetSummaryAnalyticsQuery } from 'shared/store'

interface Props {}

const Summary: React.FC<Props> = () => {
    const { t } = useTranslation()
    const { dateScroller, group } = useAnalytics()

    const summary = useGetSummaryAnalyticsQuery(
        {
            from: String(dateScroller.start?.getTime()),
            to: String(dateScroller.end?.getTime()),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            group
        },
        { skip: !dateScroller.start || !dateScroller.end }
    )

    const isLoading = summary.isFetching || summary.isLoading

    const isNoResults = !summary.data?.data.length

    return (
        <div>
            <SettingsPage
                title={t('analytics.summary')}
                subtitle={t('analytics.summarySubtitle')}
                isLoading={isLoading}
                contentProps={{ padding: 0, maxW: 'unset' }}
            >
                <AnalyticsTabsView isLoading={isLoading} noResults={isNoResults} header={null}>
                    {summary.data?.total && (
                        <AnalyticsPieChartTotals
                            durations={summary.data.durations}
                            totals={summary.data.total}
                        />
                    )}
                    <AnalyticsGraphView data={summary.data?.data} />
                </AnalyticsTabsView>
            </SettingsPage>
        </div>
    )
}

export default Summary
