import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const IncomingChannelIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'IncomingChannelIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 3C5.55228 3 6 3.44772 6 4V14H17.5858L14.7929 11.2071C14.4024 10.8166 14.4024 10.1834 14.7929 9.79289C15.1834 9.40237 15.8166 9.40237 16.2071 9.79289L20.7071 14.2929C21.0976 14.6834 21.0976 15.3166 20.7071 15.7071L16.2071 20.2071C15.8166 20.5976 15.1834 20.5976 14.7929 20.2071C14.4024 19.8166 14.4024 19.1834 14.7929 18.7929L17.5858 16H5C4.44772 16 4 15.5523 4 15V4C4 3.44772 4.44772 3 5 3Z"
            fill="currentColor"
        />
    )
})
