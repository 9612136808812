import { DrawerFooter, HStack } from '@chakra-ui/react'
import { Button, DrawerBody, DrawerHeader, useMeasure, useToast } from '@missionlabs/react'
import { type NumberE164, isUser, UserOrTeam } from '@missionlabs/types'
import { isMenu, isTeam } from '@missionlabs/utils'
import { UTMTabs } from 'features/settings/components/UTMTabs'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAssignNumberMutation } from 'shared/store'

interface AssignNumberPanelProps {
    number: NumberE164
    onSubmit?: () => void
}

export const AssignNumberPanel: FC<AssignNumberPanelProps> = ({ number, onSubmit }) => {
    const navigate = useNavigate()
    const { toast } = useToast()
    const { t } = useTranslation()

    const [headerRef, { blockSize: headerHeight }] = useMeasure<HTMLDivElement>()
    const [footerRef, { blockSize: footerHeight }] = useMeasure<HTMLDivElement>()

    const [assignNumber, { isLoading: isAssigning }] = useAssignNumberMutation()
    const [selectedItem, setSelectedItem] = useState<UserOrTeam>()

    const handleCancel = () => {
        navigate(-1)
    }

    const handleSubmit = async () => {
        try {
            if (!selectedItem) {
                throw Error(`Can't assign number: ${JSON.stringify(number)}`)
            }

            const key = (function () {
                if (isTeam(selectedItem)) return 'teamID'
                if (isMenu(selectedItem)) return 'menuID'
                if (isUser(selectedItem)) return 'userID'
            })()

            if (!key) throw Error(`Can't assign number to: ${key}`)

            await assignNumber({ ID: number.ID, [key]: selectedItem.ID }).unwrap()
        } catch {
            toast({
                title: t('numbers.assignError'),
                status: 'error'
            })
        } finally {
            handleCancel()
            onSubmit?.()
        }
    }

    const handleSelect = (data: UserOrTeam) => {
        setSelectedItem(selectedItem => (selectedItem === data ? undefined : data))
    }

    return (
        <>
            <DrawerHeader ref={headerRef}>{t('Assign to')}</DrawerHeader>
            <DrawerBody>
                <UTMTabs
                    siblingHeight={headerHeight + footerHeight}
                    selectedItem={selectedItem}
                    onSelect={handleSelect}
                />
            </DrawerBody>
            <DrawerFooter ref={footerRef} p="24px">
                <HStack w="full">
                    <Button flex="1" variant="secondary" size="lg" onClick={handleCancel}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        flex="1"
                        variant="creationary"
                        size="lg"
                        isDisabled={!selectedItem}
                        isLoading={isAssigning}
                        onClick={handleSubmit}
                    >
                        {t('Done')}
                    </Button>
                </HStack>
            </DrawerFooter>
        </>
    )
}
