import { yupResolver } from '@hookform/resolvers/yup'
import { FieldValues, useForm, UseFormProps } from 'react-hook-form'
import { yup } from 'utils'

export function useYupForm<
    TFieldValues extends FieldValues = FieldValues,
    TContext extends object = object
>(schema: yup.AnyObjectSchema, props: UseFormProps<TFieldValues, TContext> = {}) {
    return useForm<TFieldValues>({
        ...props,
        resolver: yupResolver(schema),
        mode: props.mode || 'onSubmit'
    })
}
