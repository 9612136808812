import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ArrowForwardIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowForwardIcon',
    viewBox: '0 0 16 16',
    path: (
        <path
            d="M7.53268 12.5833C7.41046 12.461 7.34646 12.3055 7.34068 12.1166C7.33535 11.9277 7.39379 11.7722 7.51602 11.6499L10.7827 8.38326H3.33268C3.14379 8.38326 2.98535 8.31926 2.85735 8.19126C2.72979 8.06371 2.66602 7.90548 2.66602 7.7166C2.66602 7.52771 2.72979 7.36926 2.85735 7.24126C2.98535 7.11371 3.14379 7.04993 3.33268 7.04993H10.7827L7.51602 3.78326C7.39379 3.66104 7.33535 3.50548 7.34068 3.3166C7.34646 3.12771 7.41046 2.97215 7.53268 2.84993C7.6549 2.72771 7.81046 2.6666 7.99935 2.6666C8.18824 2.6666 8.34379 2.72771 8.46602 2.84993L12.866 7.24993C12.9327 7.30548 12.98 7.37482 13.008 7.45793C13.0356 7.54148 13.0493 7.62771 13.0493 7.7166C13.0493 7.80548 13.0356 7.88882 13.008 7.9666C12.98 8.04437 12.9327 8.1166 12.866 8.18326L8.46602 12.5833C8.34379 12.7055 8.18824 12.7666 7.99935 12.7666C7.81046 12.7666 7.6549 12.7055 7.53268 12.5833Z"
            fill="currentColor"
        />
    )
})

export const ArrowDownIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowDownIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M5.33518 7.39133C4.80098 6.86956 3.93486 6.86956 3.40065 7.39133C2.86645 7.91311 2.86645 8.75908 3.40065 9.28086L10.8868 16.5929C11.14 16.8402 11.4678 16.9703 11.7994 16.9832C12.2097 17.0472 12.6447 16.9286 12.9666 16.625L20.5823 9.4417C21.1258 8.92911 21.1409 8.08327 20.6161 7.55246C20.0913 7.02166 19.2253 7.0069 18.6818 7.51949L11.9588 13.8608L5.33518 7.39133Z"
            fill="currentColor"
        />
    )
})

export const ArrowDownRightIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowDownRightIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.4834 18.5258L4 5.0424L5.0424 4L18.5258 17.4834V9.4351H20V20H9.4351V18.5258H17.4834Z"
            fill="currentColor"
        />
    )
})

export const ArrowDownCircleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowDownCircleIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM17.752 9.46002C18.1022 9.82272 18.0921 10.4007 17.7294 10.751L12.6466 15.6594C12.4318 15.8668 12.1414 15.9479 11.8676 15.9041C11.6463 15.8953 11.4275 15.8064 11.2586 15.6374L6.26219 10.641C5.90566 10.2845 5.90566 9.70645 6.26219 9.34992C6.61873 8.99339 7.19678 8.99338 7.55332 9.34992L11.974 13.7706L16.461 9.43749C16.8237 9.08723 17.4017 9.09732 17.752 9.46002Z"
                fill="currentColor"
            />
            <mask
                id="mask0_506_46"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM17.752 9.46002C18.1022 9.82272 18.0921 10.4007 17.7294 10.751L12.6466 15.6594C12.4318 15.8668 12.1414 15.9479 11.8676 15.9041C11.6463 15.8953 11.4275 15.8064 11.2586 15.6374L6.26219 10.641C5.90566 10.2845 5.90566 9.70645 6.26219 9.34992C6.61873 8.99339 7.19678 8.99338 7.55332 9.34992L11.974 13.7706L16.461 9.43749C16.8237 9.08723 17.4017 9.09732 17.752 9.46002Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_506_46)"></g>
        </g>
    )
})

export const ArrowDownCircleOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowDownCircleOutlineIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM17.752 9.46002C18.1022 9.82272 18.0921 10.4007 17.7294 10.751L12.6466 15.6594C12.4318 15.8668 12.1414 15.9479 11.8676 15.9041C11.6463 15.8953 11.4275 15.8064 11.2586 15.6374L6.26219 10.641C5.90566 10.2845 5.90566 9.70645 6.26219 9.34992C6.61873 8.99339 7.19678 8.99338 7.55332 9.34992L11.974 13.7706L16.461 9.43749C16.8237 9.08723 17.4017 9.09732 17.752 9.46002Z"
                fill="currentColor"
            />
            <mask
                id="mask0_506_54"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM17.752 9.46002C18.1022 9.82272 18.0921 10.4007 17.7294 10.751L12.6466 15.6594C12.4318 15.8668 12.1414 15.9479 11.8676 15.9041C11.6463 15.8953 11.4275 15.8064 11.2586 15.6374L6.26219 10.641C5.90566 10.2845 5.90566 9.70645 6.26219 9.34992C6.61873 8.99339 7.19678 8.99338 7.55332 9.34992L11.974 13.7706L16.461 9.43749C16.8237 9.08723 17.4017 9.09732 17.752 9.46002Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_506_54)"></g>
        </g>
    )
})

export const ArrowExternalLinkIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowExternalLinkIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M4 20L4 4L9 4V5.5L5.5 5.5L5.5 18.5H18.5L18.5 14L20 14L20 20L4 20Z"
                fill="currentColor"
            />
            <path
                d="M9.46973 13.4696L17.4394 5.5L12 5.5V4L20 4L20 12L18.5 12V6.56077L10.5304 14.5303L9.46973 13.4696Z"
                fill="currentColor"
            />
        </g>
    )
})

export const ArrowLeftCircleOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowLeftCircleOutlineIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 11.9998C0 18.6272 5.37258 23.9998 12 23.9998C18.6274 23.9998 24 18.6272 24 11.9998C24 5.37234 18.6274 -0.000244141 12 -0.000244141C5.37258 -0.000244141 0 5.37234 0 11.9998ZM1 11.9998C1 18.0749 5.92487 22.9998 12 22.9998C18.0751 22.9998 23 18.0749 23 11.9998C23 5.92462 18.0751 0.999756 12 0.999756C5.92487 0.999756 1 5.92462 1 11.9998ZM14.04 18.2517C13.6773 18.602 13.0993 18.5919 12.749 18.2292L7.84064 13.1464C7.63319 12.9315 7.55214 12.6412 7.59591 12.3674C7.60471 12.146 7.6936 11.9273 7.86259 11.7583L12.859 6.76195C13.2155 6.40541 13.7935 6.40541 14.1501 6.76195C14.5066 7.11848 14.5066 7.69654 14.1501 8.05307L9.72942 12.4737L14.0625 16.9608C14.4128 17.3235 14.4027 17.9015 14.04 18.2517Z"
                fill="currentColor"
            />
            <mask
                id="mask0_506_52"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="-1"
                width="24"
                height="25"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 11.9998C0 18.6272 5.37258 23.9998 12 23.9998C18.6274 23.9998 24 18.6272 24 11.9998C24 5.37234 18.6274 -0.000244141 12 -0.000244141C5.37258 -0.000244141 0 5.37234 0 11.9998ZM1 11.9998C1 18.0749 5.92487 22.9998 12 22.9998C18.0751 22.9998 23 18.0749 23 11.9998C23 5.92462 18.0751 0.999756 12 0.999756C5.92487 0.999756 1 5.92462 1 11.9998ZM14.04 18.2517C13.6773 18.602 13.0993 18.5919 12.749 18.2292L7.84064 13.1464C7.63318 12.9315 7.55214 12.6412 7.59591 12.3674C7.60472 12.146 7.69361 11.9273 7.86259 11.7583L12.859 6.76195C13.2155 6.40541 13.7935 6.40541 14.1501 6.76195C14.5066 7.11848 14.5066 7.69654 14.1501 8.05307L9.72942 12.4737L14.0625 16.9608C14.4128 17.3235 14.4027 17.9015 14.04 18.2517Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_506_52)"></g>
        </g>
    )
})

export const ArrowLeftCircleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowLeftCircleIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM14.04 18.252C13.6773 18.6022 13.0993 18.5921 12.749 18.2294L7.84064 13.1466C7.63319 12.9318 7.55214 12.6414 7.59591 12.3676C7.60471 12.1463 7.6936 11.9275 7.86259 11.7586L12.859 6.76219C13.2155 6.40566 13.7935 6.40566 14.1501 6.76219C14.5066 7.11873 14.5066 7.69678 14.1501 8.05332L9.72942 12.474L14.0625 16.961C14.4128 17.3237 14.4027 17.9017 14.04 18.252Z"
                fill="currentColor"
            />
            <mask
                id="mask0_506_49"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM14.04 18.252C13.6773 18.6022 13.0993 18.5921 12.749 18.2294L7.84064 13.1466C7.63318 12.9318 7.55214 12.6414 7.59591 12.3676C7.60472 12.1463 7.69361 11.9275 7.86259 11.7586L12.859 6.76219C13.2155 6.40566 13.7935 6.40566 14.1501 6.76219C14.5066 7.11873 14.5066 7.69678 14.1501 8.05332L9.72942 12.474L14.0625 16.961C14.4128 17.3237 14.4027 17.9017 14.04 18.252Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_506_49)"></g>
        </g>
    )
})

export const ArrowLeftIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowLeftIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M16.6087 5.33518C17.1304 4.80098 17.1304 3.93486 16.6087 3.40065C16.0869 2.86645 15.2409 2.86645 14.7191 3.40065L7.40711 10.8868C7.1598 11.14 7.02971 11.4678 7.01683 11.7994C6.95278 12.2097 7.07138 12.6447 7.37499 12.9666L14.5583 20.5823C15.0709 21.1258 15.9167 21.1409 16.4475 20.6161C16.9783 20.0913 16.9931 19.2253 16.4805 18.6818L10.1392 11.9588L16.6087 5.33518Z"
            fill="#616E7C"
        />
    )
})

export const ArrowRightCircleOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowRightCircleOutlineIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 11.9998C24 5.37234 18.6274 -0.000244141 12 -0.000244141C5.37258 -0.000244141 0 5.37234 0 11.9998C0 18.6272 5.37258 23.9998 12 23.9998C18.6274 23.9998 24 18.6272 24 11.9998ZM23 11.9998C23 5.92462 18.0751 0.999756 12 0.999756C5.92487 0.999756 1 5.92462 1 11.9998C1 18.0749 5.92487 22.9998 12 22.9998C18.0751 22.9998 23 18.0749 23 11.9998ZM9.96002 6.7478C10.3227 6.39754 10.9007 6.40763 11.251 6.77033L16.1594 11.8531C16.3668 12.068 16.4479 12.3583 16.4041 12.6321C16.3953 12.8535 16.3064 13.0722 16.1374 13.2412L11.141 18.2376C10.7845 18.5941 10.2065 18.5941 9.84992 18.2376C9.49339 17.881 9.49338 17.303 9.84992 16.9464L14.2706 12.5258L9.93749 8.03873C9.58723 7.67603 9.59732 7.09806 9.96002 6.7478Z"
                fill="currentColor"
            />
            <mask
                id="mask0_506_50"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="-1"
                width="24"
                height="25"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 11.9998C24 5.37234 18.6274 -0.000244141 12 -0.000244141C5.37258 -0.000244141 0 5.37234 0 11.9998C0 18.6272 5.37258 23.9998 12 23.9998C18.6274 23.9998 24 18.6272 24 11.9998ZM23 11.9998C23 5.92462 18.0751 0.999756 12 0.999756C5.92487 0.999756 1 5.92462 1 11.9998C1 18.0749 5.92487 22.9998 12 22.9998C18.0751 22.9998 23 18.0749 23 11.9998ZM9.96002 6.7478C10.3227 6.39754 10.9007 6.40763 11.251 6.77033L16.1594 11.8531C16.3668 12.068 16.4479 12.3583 16.4041 12.6321C16.3953 12.8535 16.3064 13.0722 16.1374 13.2412L11.141 18.2376C10.7845 18.5941 10.2065 18.5941 9.84992 18.2376C9.49339 17.881 9.49338 17.303 9.84992 16.9464L14.2706 12.5258L9.93749 8.03873C9.58723 7.67603 9.59732 7.09806 9.96002 6.7478Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_506_50)"></g>
        </g>
    )
})

export const ArrowRightCircleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowRightCircleIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM9.96002 6.74804C10.3227 6.39779 10.9007 6.40787 11.251 6.77057L16.1594 11.8534C16.3668 12.0682 16.4479 12.3586 16.4041 12.6324C16.3953 12.8537 16.3064 13.0725 16.1374 13.2414L11.141 18.2378C10.7845 18.5943 10.2065 18.5943 9.84992 18.2378C9.49339 17.8813 9.49338 17.3032 9.84992 16.9467L14.2706 12.526L9.93749 8.03897C9.58723 7.67627 9.59732 7.0983 9.96002 6.74804Z"
                fill="currentColor"
            />
            <mask
                id="mask0_506_51"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM9.96002 6.74804C10.3227 6.39779 10.9007 6.40787 11.251 6.77057L16.1594 11.8534C16.3668 12.0682 16.4479 12.3586 16.4041 12.6324C16.3953 12.8537 16.3064 13.0725 16.1374 13.2414L11.141 18.2378C10.7845 18.5943 10.2065 18.5943 9.84992 18.2378C9.49339 17.8813 9.49338 17.3032 9.84992 16.9467L14.2706 12.526L9.93749 8.03897C9.58723 7.67627 9.59732 7.0983 9.96002 6.74804Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_506_51)"></g>
        </g>
    )
})

export const ArrowRightIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowRightIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M7.39133 18.6648C6.86956 19.199 6.86956 20.0651 7.39133 20.5993C7.91311 21.1336 8.75908 21.1336 9.28086 20.5993L16.5929 13.1132C16.8402 12.86 16.9703 12.5322 16.9832 12.2006C17.0472 11.7903 16.9286 11.3553 16.625 11.0334L9.4417 3.41768C8.92911 2.87424 8.08327 2.85912 7.55246 3.38392C7.02166 3.90872 7.0069 4.77471 7.51949 5.31816L13.8608 12.0412L7.39133 18.6648Z"
            fill="currentColor"
        />
    )
})

export const ArrowUpCircleOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowUpCircleOutlineIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 -0.000488281C5.37258 -0.000488281 0 5.3721 0 11.9995C0 18.6269 5.37258 23.9995 12 23.9995C18.6274 23.9995 24 18.6269 24 11.9995C24 5.3721 18.6274 -0.000488281 12 -0.000488281ZM12 0.999512C5.92487 0.999512 1 5.92438 1 11.9995C1 18.0746 5.92487 22.9995 12 22.9995C18.0751 22.9995 23 18.0746 23 11.9995C23 5.92438 18.0751 0.999512 12 0.999512ZM6.24804 14.5395C5.89779 14.1768 5.90787 13.5988 6.27057 13.2486L11.3534 8.34016C11.5682 8.1327 11.8586 8.05166 12.1324 8.09542C12.3537 8.10422 12.5725 8.19312 12.7414 8.3621L17.7378 13.3585C18.0943 13.715 18.0943 14.2931 17.7378 14.6496C17.3813 15.0061 16.8032 15.0061 16.4467 14.6496L12.026 10.2289L7.53897 14.562C7.17627 14.9123 6.5983 14.9022 6.24804 14.5395Z"
                fill="currentColor"
            />
            <mask
                id="mask0_506_53"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="-1"
                width="24"
                height="25"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 -0.000488281C5.37258 -0.000488281 0 5.3721 0 11.9995C0 18.6269 5.37258 23.9995 12 23.9995C18.6274 23.9995 24 18.6269 24 11.9995C24 5.3721 18.6274 -0.000488281 12 -0.000488281ZM12 0.999512C5.92487 0.999512 1 5.92438 1 11.9995C1 18.0746 5.92487 22.9995 12 22.9995C18.0751 22.9995 23 18.0746 23 11.9995C23 5.92438 18.0751 0.999512 12 0.999512ZM6.24804 14.5395C5.89779 14.1768 5.90787 13.5988 6.27057 13.2486L11.3534 8.34016C11.5682 8.1327 11.8586 8.05166 12.1324 8.09542C12.3537 8.10422 12.5725 8.19312 12.7414 8.3621L17.7378 13.3585C18.0943 13.715 18.0943 14.2931 17.7378 14.6496C17.3813 15.0061 16.8032 15.0061 16.4467 14.6496L12.026 10.2289L7.53897 14.562C7.17627 14.9123 6.5983 14.9022 6.24804 14.5395Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_506_53)"></g>
        </g>
    )
})

export const ArrowUpCircleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowUpCircleIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM6.24804 14.54C5.89779 14.1773 5.90787 13.5993 6.27057 13.249L11.3534 8.34064C11.5682 8.13319 11.8586 8.05214 12.1324 8.09591C12.3537 8.10471 12.5725 8.1936 12.7414 8.36259L17.7378 13.359C18.0943 13.7155 18.0943 14.2935 17.7378 14.6501C17.3813 15.0066 16.8032 15.0066 16.4467 14.6501L12.026 10.2294L7.53897 14.5625C7.17627 14.9128 6.5983 14.9027 6.24804 14.54Z"
                fill="currentColor"
            />
            <mask
                id="mask0_506_47"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM6.24804 14.54C5.89779 14.1773 5.90787 13.5993 6.27057 13.249L11.3534 8.34064C11.5682 8.13319 11.8586 8.05214 12.1324 8.09591C12.3537 8.10471 12.5725 8.1936 12.7414 8.36259L17.7378 13.359C18.0943 13.7155 18.0943 14.2935 17.7378 14.6501C17.3813 15.0066 16.8032 15.0066 16.4467 14.6501L12.026 10.2294L7.53897 14.5625C7.17627 14.9128 6.5983 14.9027 6.24804 14.54Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_506_47)"></g>
        </g>
    )
})

export const ArrowUpIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowUpIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M18.6648 16.6087C19.199 17.1304 20.0651 17.1304 20.5993 16.6087C21.1336 16.0869 21.1336 15.2409 20.5993 14.7191L13.1132 7.40711C12.86 7.1598 12.5322 7.02971 12.2006 7.01683C11.7903 6.95278 11.3553 7.07138 11.0334 7.37499L3.41768 14.5583C2.87424 15.0709 2.85912 15.9167 3.38392 16.4475C3.90872 16.9783 4.77471 16.9931 5.31816 16.4805L12.0412 10.1392L18.6648 16.6087Z"
            fill="currentColor"
        />
    )
})
