import { PaginationState, Spinner, Table } from '@missionlabs/react'
import { Activity, UserActivity } from '@missionlabs/types'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetClientActivitiesQuery } from 'shared/store'

const columnHelper = createColumnHelper<UserActivity>()

export function renderDescription(activity: UserActivity, formatNumber: Function) {
    switch (activity.activityType) {
        case Activity.CLIENTADDON_CREATED:
        case Activity.CLIENTADDON_DELETED:
            return formatNumber(activity.numberE164)
        case Activity.NUMBER_ASSOCIATED:
            return `${formatNumber(activity.numberE164)} to ${
                activity.teamName || activity.userFullName
            }`
        case Activity.NUMBER_UNASSOCIATED:
            return `${formatNumber(activity.numberE164)} from ${activity.oldAssociationName}`
        case Activity.MENU_CREATED:
        case Activity.MENU_DELETED:
        case Activity.TEAM_CREATED:
        case Activity.TEAM_DELETED:
            return activity.teamName
        case Activity.USER_CREATED:
        case Activity.USER_DELETED:
            return activity.referencedUserFullName
        default:
            return ''
    }
}

export const ActivityLogTable: FC = () => {
    const { t } = useTranslation()
    const { formatToLocalNumber } = useFormatToNumberE164()

    const [page, setPage] = useState(1)
    const [fromTime, setFromTime] = useState(0)

    const {
        data: activities,
        isLoading,
        isFetching
    } = useGetClientActivitiesQuery({ fromTime, page })

    const columns = [
        columnHelper.accessor('activityType', {
            cell: info => t(`activity.${info.getValue()}`),
            header: t('Type')
        }),
        columnHelper.display({
            cell: info => renderDescription(info.row.original, formatToLocalNumber),
            header: t('Description')
        }),
        columnHelper.accessor('adminUserFullName', {
            cell: info => info.getValue(),
            header: t('Actioned by')
        }),
        columnHelper.accessor('updatedTimestamp', {
            cell: info => format(info.getValue() * 1000, 'dd/MM/yyyy, HH:mm'),
            header: t('Date'),
            meta: { align: 'right' }
        })
    ]

    function handlePaginationChange(data: PaginationState) {
        if (!activities?.data) return

        const clone = [...activities.data]
        setFromTime(clone.pop()?.createdTimestamp ?? 0)
        setPage(data.pageIndex + 1)
    }

    if (isLoading) return <Spinner size="sm" />

    return (
        <Table
            data={activities?.data ?? []}
            columns={columns}
            isLoading={isFetching}
            paginate="manual"
            pageCount={activities?.total}
            onPaginationChange={handlePaginationChange}
            sorting={[{ id: 'updatedTimestamp', desc: true }]}
        />
    )
}
