import { Flex, Text } from '@chakra-ui/react'
import { Banner } from '@missionlabs/react'
import { WarningIconSolid } from '@missionlabs/react/zeta'
import { useTranslation } from 'react-i18next'
import { AppConfig } from 'shared/utils/env'

const AccountSuspendedBanner = () => {
    const { t } = useTranslation()

    return (
        <Banner id="account-suspended-header" h="60px" variant="darkRed">
            <Flex align="center" gap="18px">
                <WarningIconSolid />
                <div>
                    {AppConfig.brand === 'phonelineplus' ? (
                        <span>{t('accountSuspendedWarningPL.text')} </span>
                    ) : (
                        <>
                            <span>{t('accountSuspendedWarning.text')} </span>
                            <Text
                                target="_blank"
                                color="#fff"
                                textDecoration="underline"
                                as="a"
                                href="https://help.circleloop.com/"
                            >
                                {t('accountSuspendedWarning.help')}
                            </Text>
                        </>
                    )}
                </div>
            </Flex>
        </Banner>
    )
}

export default AccountSuspendedBanner
