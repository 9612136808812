import { Center, StackProps, useMediaQuery, VStack } from '@chakra-ui/react'
import { EmptyPage, Scrollable, useMeasure } from '@missionlabs/react'
import { FC, PropsWithChildren, useContext } from 'react'

import { AppBannerContext } from '../MainLayout'
import { SettingsHeader, SettingsHeaderProps } from './SettingsHeader'

export interface SettingsPageProps extends SettingsHeaderProps {
    contentProps?: StackProps
    isEmpty?: boolean
}

export const SettingsPage: FC<PropsWithChildren<SettingsPageProps>> = ({
    title,
    subtitle,
    isLoading,
    isEmpty,
    children,
    actions,
    contentProps,
    ...props
}) => {
    const [headerRef, { blockSize: headerHeight }] = useMeasure<HTMLDivElement>()
    const [isSmallerThan1440] = useMediaQuery('(max-width: 1440px)')

    const { appBannerHeight } = useContext(AppBannerContext)

    const contentHeight = `calc(100dvh - ${headerHeight ?? 78}px - ${appBannerHeight}px)`

    return (
        <main>
            <SettingsHeader
                ref={headerRef}
                title={title}
                subtitle={subtitle}
                isLoading={isLoading}
                actions={actions}
                {...props}
            />
            {isEmpty ? (
                <Center w="100%" h={contentHeight}>
                    <EmptyPage />
                </Center>
            ) : (
                <Scrollable h={contentHeight} options={{ overflow: { x: 'hidden' } }}>
                    <VStack
                        spacing="24px"
                        maxW={isSmallerThan1440 ? '100%' : '80%'}
                        align="start"
                        p="24px"
                        sx={{ transition: 'all 200ms ease' }}
                        {...contentProps}
                    >
                        {children}
                    </VStack>
                </Scrollable>
            )}
        </main>
    )
}
