import { Table, useFuzzySearch } from '@missionlabs/react'
import { HuntGroup } from '@missionlabs/types'
import { createColumnHelper } from '@tanstack/react-table'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const columnHelper = createColumnHelper<HuntGroup>()

export interface TeamsTableProps {
    teams: HuntGroup[]
    searchTerm: string
}

export const TeamsTable: FC<TeamsTableProps> = ({ teams, searchTerm }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { results } = useFuzzySearch<HuntGroup>({
        items: teams,
        searchValue: searchTerm,
        keys: ['name'],
        options: { minMatchCharLength: 1 }
    })

    const columns = [
        columnHelper.accessor('name', {
            cell: ({ getValue }) => getValue(),
            header: t('Name')
        }),
        columnHelper.accessor('phoneNumbers', {
            cell: ({ getValue }) => getValue().length,
            header: t('Numbers')
        }),
        columnHelper.accessor('callRoute.teamMembers', {
            cell: ({ getValue }) => getValue().length,
            header: t('Members')
        })
    ]

    function handleRowClicked(data: HuntGroup) {
        navigate(`/admin/teams/${data.ID}/edit`)
    }

    return <Table data={results} columns={columns} paginate onRowClicked={handleRowClicked} />
}
