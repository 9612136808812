import { Box, useColorMode } from '@chakra-ui/react'
import { Button, Input, useUpdateParams } from '@missionlabs/react'
import { AddCircleIcon, SearchIcon } from '@missionlabs/react/circleloop'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetHuntGroupsQuery } from 'shared/store'

import { TeamsTable } from './TeamsTable'

export const Teams: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const [searchParams, updateParams] = useUpdateParams()

    const { data: teams = [], isLoading } = useGetHuntGroupsQuery()

    const search = searchParams.get('search') || ''
    const setSearch = (value: string) => updateParams({ search: value })

    return (
        <div>
            <SettingsPage
                maxW="100%"
                title={t('admin.teams.title')}
                subtitle={t('admin.teams.subtitle')}
                isLoading={isLoading}
                isEmpty={!teams.length}
                actions={
                    <Button
                        variant="creationary"
                        leftIcon={<AddCircleIcon boxSize="16px" />}
                        onClick={() => navigate('/admin/teams/add')}
                    >
                        {t('admin.teams.add')}
                    </Button>
                }
            >
                <Box w="50%">
                    <Input
                        value={search}
                        onChange={e => setSearch(e.currentTarget.value)}
                        placeholder={t('admin.teams.search_placeholder')}
                        rightIcon={<SearchIcon boxSize="20px" color={`${colorMode}.coolGrey.60`} />}
                    />
                </Box>
                <TeamsTable teams={teams} searchTerm={search} />
            </SettingsPage>
        </div>
    )
}
