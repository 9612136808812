import { ChatMessage } from '@missionlabs/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ChatMessageNotification = {
    channelID: string
    messageBody: string
    messageID: string
    sender: Sender
    userID: string
    contactID: string
    channelName?: string
}

export type Sender = {
    name: string
    company: string
}

export type ChatState = {
    messageNotifications: ChatMessageNotification[]
    activeViewChannel: string | null
    newMessages: ChatMessage[]
    searching: boolean
    threadIsOpen: boolean | string
}

const initialState: ChatState = {
    messageNotifications: [],
    activeViewChannel: null,
    newMessages: [],
    searching: false,
    threadIsOpen: false
}

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        addMessage: (state, action: PayloadAction<ChatMessageNotification>) => {
            if (window.location.pathname.includes(action.payload.channelID)) {
                return
            }
            state.messageNotifications.push(action.payload)
        },
        removeMessage: (state, action: PayloadAction<string>) => {
            state.messageNotifications = state.messageNotifications.filter(
                message => message.messageID !== action.payload
            )
        },
        setActiveViewChannel: (state, action: PayloadAction<string | null>) => {
            state.activeViewChannel = action.payload
        },
        addNewMessage: (state, action: PayloadAction<ChatMessage>) => {
            state.newMessages = [...state.newMessages, action.payload]
        },
        resetNewMessages: state => {
            state.newMessages = []
        },
        setSearching: (state, action: PayloadAction<boolean>) => {
            state.searching = action.payload
        },
        setThreadIsOpen: (state, action: PayloadAction<boolean | string>) => {
            state.threadIsOpen = action.payload
        }
    }
})

export type ChatRootState = {
    chatSlice: ReturnType<typeof chatSlice.getInitialState>
}

export const {
    addMessage,
    removeMessage,
    setActiveViewChannel,
    addNewMessage,
    resetNewMessages,
    setSearching,
    setThreadIsOpen
} = chatSlice.actions

// Create a selector to retrieve messages from the state
export const selectMessages = (state: ChatRootState) => state.chatSlice.messageNotifications
export const selectActiveViewChannel = (state: any) => state.chatSlice.activeViewChannel
export const selectNewMessages = (state: ChatRootState) => state.chatSlice.newMessages
export const selectNewMessagesForChannel = (state: ChatRootState, channelID: string) => {
    return state.chatSlice.newMessages.filter(message => message.channelID === channelID)
}
