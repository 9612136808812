/**
 * A FAC is a shortcode that is between 2 and 4 characters long, including a * or # character.
 * (i.e, *1, #12, *123, *12# are all FAC's).
 * */
import { ContactPhoneNumber, NumbersPhoneNumber, NumbersTeam } from '@missionlabs/types'

/**
 * Any number that **must** start with 1 * or # character and **may** end with 1 * or # character
 */
export const isFAC = (value: string) => {
    return /^([*#])\d+([*#]?)$/.test(value)
}

/**
 * See https://missionlabs.atlassian.net/wiki/spaces/ZCS/pages/2335178787/Speed+Dial+-+PBX#Technical-Documentation
 */
export const isSpeedDial = (value: string) => {
    return /^[2-9]#$|(?!(#21)|(#31)|(#40)|(#41)|(#43)|(#58)$)(^#7[0-8]|^#[0-6]\d)#?$/.test(value)
}

/**
 * Any number that **may** start with 1 * or # character and **may** end with 1 * or # character
 * (i.e. #10, *62*, *881003, 01577335477, #01577335477 are all valid)
 */
export const isValidNumber = (value: string) => {
    return /^([*#]?)\d+([*#]?)$/.test(value)
}

type PhoneNumberCategory = 'mobile' | 'home' | 'work' | 'other'
export type CategorisedPhoneNumbers = Record<PhoneNumberCategory, string[]>

export function categorisePhoneNumbers(
    phoneNumbers?: ContactPhoneNumber[]
): CategorisedPhoneNumbers {
    if (!phoneNumbers) {
        return {
            home: [],
            mobile: [],
            work: [],
            other: []
        }
    }

    return phoneNumbers.reduce(
        (result, curr) => {
            const { label = 'other', numberE164 } = curr
            if (numberE164) {
                const hasCategory = ['mobile', 'home', 'work'].includes(label.toLowerCase())
                // treat any field that is not mobile, home or work as other.
                const field = hasCategory ? label.toLowerCase() : 'other'
                result[field].push(numberE164)
            }
            return result
        },
        { home: [], mobile: [], work: [], other: [] }
    )
}

export function isNumbersTeam(number: NumbersPhoneNumber | NumbersTeam): number is NumbersTeam {
    return typeof number === 'object' && 'name' in number
}
