import {
    selectAuthenticatedUser,
    selectPreferences,
    updatePreferences,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { UserPreferences } from '@missionlabs/browser-calling'
import { useGetDevices, useSyncedState } from '@missionlabs/react'
import { useFlags } from 'flagsmith/react'
import { FC, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { AudioPreferenceState, updateAudioPreferences } from 'shared/slices/audioDeviceSlice'
import { selectJabraSlice, webHIDConsent } from 'shared/slices/jabraSlice'

import { AdvancedSetting, settingsList, SoundAdvancedSetting } from './SoundAdvancedSetting'
import { SoundDeviceSetting } from './SoundDeviceSetting'
import { SoundJabraDeviceSetting } from './SoundJabraDeviceSetting'
import { SoundRingtoneSetting } from './SoundRingtoneSetting'

export const Sounds: FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const audio = useRef<HTMLAudioElement>(new Audio())

    const flags = useFlags(['sound_jabra_device_setting'])

    const user = useSelector(selectAuthenticatedUser)
    const preferences = useSelector(selectPreferences)
    const jabraDevice = useSelector(selectJabraSlice)
    const { inputDevices, inputDeviceID, outputDevices, outputDeviceID, ringingOutputDeviceID } =
        useGetDevices()

    const [audioVolume, setAudioVolume] = useSyncedState(preferences?.audioVolume)
    const [ringtoneVolume, setRingtoneVolume] = useSyncedState(preferences?.ringingVolume)
    const [ringTone] = useSyncedState(preferences?.ringTone)

    const defaultAdvancedSettings = useMemo(() => {
        const settingsArray: AdvancedSetting[] = []
        if (!preferences?.advancedSettings) return settingsArray

        for (const setting in preferences.advancedSettings) {
            if (preferences.advancedSettings[setting]) {
                settingsArray.push(setting as AdvancedSetting)
            }
        }

        return settingsArray
    }, [preferences?.advancedSettings])

    const [advancedSettings] = useSyncedState(defaultAdvancedSettings)

    const handleUpdateAudioPreferences = useCallback(
        (audioPreferences: Partial<UserPreferences>) => {
            dispatch(updatePreferences({ ...audioPreferences }))
            dispatch(
                updateAudioPreferences({
                    userID: user?.userID,
                    ...(audioPreferences as AudioPreferenceState)
                })
            )
        },
        [dispatch, user?.userID]
    )

    const handleUpdatePreferences = useCallback(
        (preferences: Partial<UserPreferences>) => dispatch(updatePreferences({ ...preferences })),
        [dispatch]
    )

    const handleJabraDeviceClick = useCallback(() => dispatch(webHIDConsent()), [dispatch])

    const handleRingToneChange = (ringTone: string) => {
        handleUpdatePreferences({ ringTone })
        playPreview(ringTone)
    }

    const handleAdvancedSettingsChange = (settings: AdvancedSetting[]) => {
        const settingsObj = Object.fromEntries(
            settingsList.map(setting => [setting, settings.includes(setting)])
        )
        handleUpdatePreferences({ advancedSettings: settingsObj })
    }

    const playPreview = (ringTone: string) => {
        let file
        if (ringTone === 'office') {
            file = 'ringtone-1.mp3'
        } else if (ringTone === 'xylophone') {
            file = 'ringtone-2.mp3'
        } else if (ringTone === 'modern') {
            file = 'video-ringtone.mp3'
        }
        audio.current.src = new URL(`../../../../assets/sounds/${file}`, import.meta.url).href
        if (ringingOutputDeviceID) audio.current.setSinkId(ringingOutputDeviceID)
        if (ringtoneVolume) audio.current.volume = ringtoneVolume
        audio.current.loop = false
        audio.current.play()
    }

    return (
        <SettingsPage title={t('settings.sounds.title')}>
            <SoundDeviceSetting
                label={t('settings.sounds.microphone')}
                dropdown={{
                    label: t('settings.sounds.useMicOn'),
                    options: inputDevices,
                    value: inputDeviceID,
                    onChange: inputDeviceID => handleUpdateAudioPreferences({ inputDeviceID })
                }}
            />
            <SoundDeviceSetting
                label={t('settings.sounds.callAudio')}
                dropdown={{
                    label: t('settings.sounds.playCallAudio'),
                    options: outputDevices,
                    value: outputDeviceID,
                    onChange: outputDeviceID => handleUpdateAudioPreferences({ outputDeviceID })
                }}
                slider={{
                    label: t('settings.sounds.callAudioVolume'),
                    value: audioVolume,
                    onChange: setAudioVolume,
                    onChangeEnd: audioVolume => handleUpdatePreferences({ audioVolume })
                }}
            />
            <SoundDeviceSetting
                label={t('settings.sounds.callRinging')}
                dropdown={{
                    label: t('settings.sounds.playRingtone'),
                    options: outputDevices,
                    value: ringingOutputDeviceID,
                    onChange: ringingOutputDeviceID =>
                        handleUpdateAudioPreferences({ ringingOutputDeviceID })
                }}
                slider={{
                    label: t('settings.sounds.ringtoneVolume'),
                    value: ringtoneVolume,
                    onChange: setRingtoneVolume,
                    onChangeEnd: ringingVolume => handleUpdatePreferences({ ringingVolume })
                }}
            />
            <SoundRingtoneSetting value={ringTone ?? 'office'} onChange={handleRingToneChange} />
            <SoundAdvancedSetting
                values={advancedSettings}
                onChange={handleAdvancedSettingsChange}
            />
            {flags.sound_jabra_device_setting.enabled && (
                <SoundJabraDeviceSetting
                    deviceName={jabraDevice.deviceName}
                    onClick={handleJabraDeviceClick}
                />
            )}
        </SettingsPage>
    )
}
