import { Box, Collapse } from '@chakra-ui/react'
import { Radio, RadioProps } from '@missionlabs/react'
import { FC, PropsWithChildren, useMemo } from 'react'

interface InterstitialRadioProps extends RadioProps {
    value: string
    current: string
    label: React.ReactNode
}

export const InterstitialRadio: FC<PropsWithChildren<InterstitialRadioProps>> = ({
    value,
    current,
    label,
    children,
    minW,
    ...props
}) => {
    const isChecked = useMemo(() => current === value, [current, value])
    return (
        <>
            <Radio value={value} py="4px" display="flex" alignItems="flex-start" {...props}>
                {label}
            </Radio>
            <Box
                as={Collapse}
                in={isChecked}
                unmountOnExit
                position="relative" // Resolves z-indexing inside a Collapse menu.
                overflow="visible !important"
                zIndex="popover"
            >
                <Box py="8px" minW={minW} w="full">
                    {children}
                </Box>
            </Box>
        </>
    )
}
