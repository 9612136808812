import { useDisclosure } from '@chakra-ui/react'
import {
    declineMeetingRequest,
    MeetingEventType,
    MeetingRequest,
    openMeetingWindow,
    selectAuthenticatedUser,
    useSelector
} from '@missionlabs/api'
import { Notification } from '@missionlabs/react'
import { Channel } from '@missionlabs/types'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationProps } from 'shared/components/Notifications/types'
import { useCreateChannelMessageMutation, useCreateChannelMutation } from 'shared/store'

// import { Chatbox } from '../Chatbox'

export const IncomingMeetingNotification: FC<NotificationProps<MeetingRequest>> = ({
    call: meeting,
    dispatch,
    onClose
}) => {
    const { t } = useTranslation()
    const { isOpen: _isOpen, onToggle } = useDisclosure()

    const user = useSelector(selectAuthenticatedUser)

    const [chatChannel, setChatChannel] = useState<Channel | null>(null)

    const [findOrCreateChat] = useCreateChannelMutation()
    const [sendMessage] = useCreateChannelMessageMutation()

    const onAnswer = () => {
        if (!meeting || !user) return
        dispatch(
            openMeetingWindow({
                ...meeting,
                event: MeetingEventType.ACCEPT_VIDEO_CALL,
                token: user.token,
                userID: user.userID,
                clientID: user.clientID,
                username: user.username
            })
        )
        onClose(meeting.meetingID)
    }

    const onHangup = () => {
        if (!meeting) return
        dispatch(declineMeetingRequest({ ...meeting }))
        onClose(meeting.meetingID)
    }

    const prepareToSendChatMessage = async () => {
        onToggle()
        if (chatChannel || !meeting || !user) return
        try {
            const channel = await findOrCreateChat({
                type: 'USER' as const,
                members: [meeting.caller.userID, user.userID]
            }).unwrap()
            setChatChannel(channel)
        } catch (e) {
            console.error(`Couldn't findOrCreateChat for ${user.userID}`, e)
        }
    }

    const _dismissWithMessage = async (message: { html: string; plainText: string }) => {
        if (!chatChannel) return console.error('Chat channel has has not been defined')
        try {
            onHangup()
            await sendMessage({
                ID: chatChannel.ID,
                entry: {
                    contentHTML: message.html,
                    contentPlainText: message.plainText,
                    type: 'STANDARD'
                }
            }).unwrap()
        } catch (e) {
            console.error(`Couldn't send message for ${user?.userID}`, e)
        }
    }

    if (!meeting) return null

    return (
        <Notification
            variant="meetings"
            header={t('notifications.incomingVideo')}
            name={meeting.caller.displayName || meeting.caller.fullName || 'Unknown'}
            role={meeting.caller.company}
            // todo
            // messageBox={isOpen && <Chatbox onSendMessage={dismissWithMessage} minimal />}
            onAnswer={onAnswer}
            onHangup={onHangup}
            onDismiss={onHangup}
            onMessage={prepareToSendChatMessage}
        />
    )
}
