import { HStack, SimpleGrid, VStack } from '@chakra-ui/react'
import { selectInData } from '@missionlabs/api'
import { Body, Button, ErrorMessage } from '@missionlabs/react'
import { AvailableNumber } from '@missionlabs/types'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetAvailableNumbersQuery } from 'shared/store'

import { AddNumberFields } from './AddNumberForm'
import { useAddNumberUtils } from './useAddNumberUtils'

const selectAvailableNumbers = selectInData<AvailableNumber[], AddNumberListGroup>(data => {
    if (!data) return
    return data.reduce((obj, number) => {
        const bucket = number.bucket.split('_')[0]
        const current = obj[bucket] ?? {
            numbers: [],
            price: number.price,
            localisedPrices: number.localisedPrices
        }
        current.numbers.push(number)
        return { ...obj, [bucket]: current }
    }, {})
})

interface AddNumberListGroup {
    [k: string]: {
        numbers: AvailableNumber[]
        localisedPrices: AvailableNumber['localisedPrices']
        price: number
    }
}

export interface AddNumberListProps {
    values: AddNumberFields
    error: string | undefined
    onChange: (value: AvailableNumber) => void
}

export const AddNumberList: FC<AddNumberListProps> = ({ values, error, onChange }) => {
    const { areaCode, numberType, selectedNumberE164 } = values

    const { t } = useTranslation()
    const { formatNumber, formatPrice } = useAddNumberUtils()

    const { availableNumbers = {} } = useGetAvailableNumbersQuery(
        { areaCode: areaCode ?? '', numberType: numberType ?? '' },
        {
            refetchOnMountOrArgChange: true,
            skip: !numberType || !areaCode,
            selectFromResult: result => ({
                ...result,
                availableNumbers: selectAvailableNumbers(result)
            })
        }
    )

    const sortedAvailableNumbers = Object.entries(availableNumbers).sort((a, b) =>
        a[1].price < b[1].price ? 1 : -1
    )

    return (
        <VStack spacing="16px" align="inherit" data-testid="add-number-list">
            {sortedAvailableNumbers.map(([k, v]) => (
                <Fragment key={k}>
                    <HStack w="full" justify="space-between">
                        <Body variant="bold">{t(`admin.numbers.bucket.${k}`)}</Body>
                        <Body variant="bold">
                            {v.price === 0
                                ? t('Free')
                                : t('admin.numbers.add.price', {
                                      price: formatPrice(v.price, v.localisedPrices)
                                  })}
                        </Body>
                    </HStack>
                    <SimpleGrid w="full" columns={3} gap="12px 16px">
                        {v.numbers.map(item => (
                            <Button
                                key={item.ID}
                                size="md"
                                variant="ghost"
                                isActive={selectedNumberE164?.ID === item.ID}
                                onClick={() => onChange(item)}
                            >
                                {formatNumber(item.numberE164, numberType)}
                            </Button>
                        ))}
                    </SimpleGrid>
                </Fragment>
            ))}
            {error && <ErrorMessage error={error} />}
        </VStack>
    )
}
