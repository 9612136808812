import { HStack, VStack } from '@chakra-ui/react'
import { NumberVerificationBody } from '@missionlabs/api'
import {
    Button,
    Dropdown,
    FormProvider,
    Input,
    useFormatToNumberE164,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthenticatedUser } from 'shared/hooks/useAuthenticatedUser'
import { useVerifyNumberMutation } from 'shared/store'

interface VerifyNumberFormProps {
    onClose: () => void
    defaultValues: Partial<NumberVerificationBody>
    setFormState: (data: any) => void
}

const VerifyNumberForm: FC<VerifyNumberFormProps> = ({ onClose, defaultValues, setFormState }) => {
    const { t } = useTranslation()

    const { data: user } = useAuthenticatedUser()

    const userID = user?.ID,
        clientID = user?.clientID

    const { isUKMobileNumber, isValidPhoneNumber } = useFormatToNumberE164()
    const [postVerifyNumber, { isLoading }] = useVerifyNumberMutation()

    const onSubmitNumber = useCallback(
        async (data: NumberVerificationBody) => {
            const { ID = '' } = await postVerifyNumber(data).unwrap()
            console.log('ID: ', ID)
            setFormState(prev => ({ ...prev, ...data, ID }))
        },
        [postVerifyNumber, setFormState]
    )

    const verifySchema = yup.object().shape(
        {
            userID: yup.string().required(),
            clientID: yup.string().required(),
            verifyNumber: yup
                .string()
                .test({
                    message: {
                        key: 'validations.invalidNumber'
                    },
                    test: value => isValidPhoneNumber(value)
                })
                .required(),
            verifyMethod: yup.string().when('verifyNumber', {
                is: value => isUKMobileNumber(value),
                then: () => yup.string().oneOf(['call', 'sms']).required(),
                otherwise: () => yup.string().oneOf(['call']).required()
            })
        },
        [['verifyMethod', 'verifyNumber']]
    )

    const methods = useYupForm<NumberVerificationBody>(verifySchema, {
        defaultValues
    })

    const {
        watch,
        setValue,
        formState: { isValid }
    } = methods

    const { verifyMethod, verifyNumber } = watch()

    const isMobile = useMemo(() => {
        return isUKMobileNumber(verifyNumber)
    }, [verifyNumber, isUKMobileNumber])

    useEffect(
        function unsetSmsVerifyMethodIfNotMobile() {
            if (verifyMethod === 'sms' && !isMobile) {
                setValue('verifyMethod', 'call')
            }
        },
        [isMobile, verifyMethod, setValue]
    )

    useEffect(
        function setUserAndClientIDs() {
            if (userID) setValue('userID', userID)
            if (clientID) setValue('clientID', clientID)
        },
        [userID, clientID, setValue]
    )

    const verificationMethodOptions = useMemo(() => {
        if (isMobile) {
            return [
                { label: t('Call'), value: 'call' },
                { label: t('SMS'), value: 'sms' }
            ]
        }
        return [{ label: 'Call', value: 'call' }]
    }, [isMobile, t])

    return (
        <FormProvider {...methods}>
            <VStack as="form" onSubmit={methods.handleSubmit(onSubmitNumber)} h="full" p={4}>
                <HStack w="full" align="flex-start" justify="space-evenly" h={'90%'}>
                    <ValidatedInput
                        name="verifyNumber"
                        label={t('admin.numbers.mirror.enterNumber')}
                    >
                        <Input autoComplete="off" />
                    </ValidatedInput>

                    <ValidatedInput name="verifyMethod" label={t('admin.numbers.mirror.sendBy')}>
                        <Dropdown
                            width="full"
                            options={verificationMethodOptions}
                            value={watch('verifyMethod')}
                            onChange={value =>
                                setValue('verifyMethod', value, {
                                    shouldDirty: true,
                                    shouldTouch: true
                                })
                            }
                        />
                    </ValidatedInput>
                </HStack>

                <HStack h={'10%'} w="full">
                    <Button w="full" size="lg" variant="secondary" onClick={onClose}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        w="full"
                        size="lg"
                        variant="creationary"
                        isLoading={isLoading}
                        isDisabled={!isValid}
                        type="submit"
                    >
                        {t('Submit')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}

export default VerifyNumberForm
