import dayjs, { ManipulateType } from 'dayjs'
// @ts-ignore
import { IDBPDatabase, openDB } from 'idb'

import Transport, { Log, TransportConfig, Transports } from './transport'

const DB_NAME = 'logs'
const DB_VERSION = 1

interface IndexedDBTransportConfig extends TransportConfig {
    expiry?: { value: number; unit: ManipulateType }
}

const defaultConfig: Partial<IndexedDBTransportConfig> = {
    template: ({ message }) => message,
    expiry: { value: 1, unit: 'hour' },
    level: 'info'
}

class IndexedDBTransport extends Transport<IndexedDBTransportConfig> {
    public readonly name = Transports.indexeddb

    constructor(config = {} as IndexedDBTransportConfig) {
        super({ ...defaultConfig, ...config })
        this.purge()
    }

    public static find(x: any): x is InstanceType<typeof IndexedDBTransport> {
        return x.name === Transports.indexeddb
    }

    public format(value: any): string {
        return JSON.stringify(value)
    }

    public log(value: Log): string {
        this.write({
            logLevel: value.level,
            logLine: value.message,
            timeStamp: Math.round(Date.now() / 1000)
        })
        return value.message
    }

    public postLog() {
        this.purge()
    }

    public async dumpLogs() {
        const db = await this.open()
        return db.getAll(DB_NAME)
    }

    private async purge() {
        // query anything older than 1 hour by default, using IDBKeyRange.upperBound queries <= the given date
        const date = dayjs()
            .subtract(this.config.expiry?.value ?? 1, this.config.expiry?.unit ?? 'hour')
            .unix()
        const range = IDBKeyRange.upperBound(date)

        const db = await this.open()
        const tx = db.transaction(DB_NAME, 'readwrite')
        // iterate over each timeStamp key in our range and delete the record
        let cursor = await tx.store.index('timeStamp').openCursor(range)
        while (cursor) {
            cursor.delete()
            cursor = await cursor.continue()
        }

        await tx.done
    }

    private async open(): Promise<IDBPDatabase> {
        return openDB(DB_NAME, DB_VERSION, {
            upgrade: db => {
                const store = db.createObjectStore(DB_NAME, {
                    keyPath: 'id',
                    autoIncrement: true
                })
                store.createIndex('timeStamp', 'timeStamp')
            }
        })
    }

    private async write(value: object): Promise<void> {
        const db = await this.open()
        await db.add(DB_NAME, value)
    }
}

export default IndexedDBTransport
