import { MenuItem } from '@chakra-ui/react'
import { IconButton, Menu, MenuButton, MenuList } from '@missionlabs/react'
import { MoreHorizontalIcon, TickCircleIcon } from '@missionlabs/react/circleloop'
import { categorisePhoneNumbers, isTeamOrMenu } from '@missionlabs/utils'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { VirtualContactRow } from './ContactRow'

interface AddNumberOverflowProps extends VirtualContactRow {}

export const AddNumberOverflow: FC<AddNumberOverflowProps> = ({
    contact,
    onSelect,
    selectedContact
}) => {
    const { t } = useTranslation()

    const { mobile, home, work, other } = useMemo(() => {
        if (isTeamOrMenu(contact)) {
            return categorisePhoneNumbers(
                contact.phoneNumbers.map(number => ({ numberE164: number }))
            )
        }

        return categorisePhoneNumbers(contact.phoneNumbers)
    }, [contact])

    const hasNumbers = useMemo(() => {
        return mobile.length || home.length || work.length || other.length
    }, [home.length, mobile.length, other.length, work.length])

    if (!hasNumbers) return null

    const renderIcon = () => {
        if (selectedContact && selectedContact.contactID === contact.ID) {
            return <TickCircleIcon boxSize="16px" />
        }
        return <MoreHorizontalIcon boxSize="16px" />
    }

    return (
        <Menu variant="compact">
            <IconButton
                as={MenuButton}
                icon={renderIcon()}
                variant="transparent"
                aria-label="transfer"
            />
            <MenuList>
                {work.map(number => (
                    <MenuItem
                        background="#fff"
                        key={number}
                        onClick={() => onSelect({ numberE164: number, contactID: contact.ID })}
                    >
                        {t('numbers.work', { number })}
                    </MenuItem>
                ))}
                {home.map(number => (
                    <MenuItem
                        background="#fff"
                        key={number}
                        onClick={() => onSelect({ numberE164: number, contactID: contact.ID })}
                    >
                        {t('numbers.home', { number })}
                    </MenuItem>
                ))}
                {mobile.map(number => (
                    <MenuItem
                        background="#fff"
                        key={number}
                        onClick={() => onSelect({ numberE164: number, contactID: contact.ID })}
                    >
                        {t('numbers.mobile', { number })}
                    </MenuItem>
                ))}
                {other.map(number => (
                    <MenuItem
                        background="#fff"
                        key={number}
                        onClick={() => onSelect({ numberE164: number, contactID: contact.ID })}
                    >
                        {t('numbers.other', { number })}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}
