import { HStack, useColorMode, useDisclosure, VStack } from '@chakra-ui/react'
import {
    MeetingEventType,
    OpenCreateMeetingWindow,
    openMeetingWindow,
    selectAuthenticatedUser,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { Body, Button, HeaderAvatar, Heading, IconButton, MenuButton } from '@missionlabs/react'
import { WhatsappIcon } from '@missionlabs/react/circleloop'
import {
    ChatIconRegular,
    ChatIconSolid,
    PhoneIconRegular,
    StarIconLight,
    StarIconSolid,
    VideoIconSolid
} from '@missionlabs/react/zeta'
import {
    ContactType,
    DirectoryEntry,
    isContactGroup,
    OutboundMessageType,
    UserStatus,
    WhatsAppContentType
} from '@missionlabs/types'
import { useFlags } from 'flagsmith/react'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useBlockContact } from 'shared/hooks/useBlockContact'
import { useFavouriteContact } from 'shared/hooks/useFavouriteContact'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetMyNumbers } from 'shared/hooks/useGetMyNumbers'
import { useGetPresence, useGetPresenceID } from 'shared/hooks/useGetPresence'
import { Features, useHasClientFeature } from 'shared/hooks/useHasClientFeature'
import { useHasFeature } from 'shared/hooks/useHasFeature'
import { useIsSMSEnabled } from 'shared/hooks/useIsSMSEnabled'
import { addMessage } from 'shared/slices/messagingSlice'
import { addPresenceID } from 'shared/slices/presenceSlice'
import { useCreateChannelMutation } from 'shared/store'

import { DeleteContactDialog } from '../DeleteContactDialog'
import { ContactNumberOverflow } from './ContactNumberOverflow'
import { ContactsHeaderMenu } from './ContactsHeaderMenu'

interface ContactHeaderProps {
    contact: DirectoryEntry
    type: ContactType
}

export const ContactHeader: FC<ContactHeaderProps> = ({ contact, type }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { allNumbersBlocked } = useBlockContact(contact)
    const deleteDialog = useDisclosure()
    const { isUKMobileNumber } = useFormatToNumberE164()

    const user = useSelector(selectAuthenticatedUser)
    const hasVideoPermission = useHasFeature(Features.meetings)

    const { isFavourited, toggleFavourite } = useFavouriteContact(contact)

    const [findOrCreateChat] = useCreateChannelMutation()

    const presenceID = useGetPresenceID(contact)

    const flags = useFlags(['chat'])

    useEffect(() => {
        if (!presenceID) return
        dispatch(addPresenceID({ userID: presenceID, persist: true }))

        return () => {
            dispatch(addPresenceID({ userID: presenceID, persist: false }))
        }
    }, [dispatch, presenceID])

    const { status } = useGetPresence(presenceID)

    async function handleChat() {
        if (type !== 'internal' || !user || !contact.externalID) return

        const body =
            contact.source === 'teamprofile'
                ? { type: 'GROUP' as const, teamID: contact.externalID }
                : { type: 'USER' as const, members: [contact.externalID, user.userID] }

        try {
            const channel = await findOrCreateChat(body).unwrap()
            navigate(`/chat/${channel.ID}`)
        } catch (e) {
            console.error(`Couldn't findOrCreateChat for ${user.userID}`, e)
        }
    }

    function handleVideoCall() {
        if (!user || !contact.externalID) return

        const payload: OpenCreateMeetingWindow = {
            event: MeetingEventType.CREATE_VIDEO_CALL,
            otherParticipants: [{ userID: contact.externalID }],
            // todo
            // sources,
            userID: user.userID,
            clientID: user.clientID,
            token: user.token,
            username: user.username
        }
        dispatch(openMeetingWindow(payload))
    }

    const mobileNumbers = useGetMyNumbers({ mobileOnly: true })
    const contactMobileNumbers =
        contact.phoneNumbers?.filter(pn => isUKMobileNumber(pn.numberE164)) ?? []

    const smsEnabled = useIsSMSEnabled(contact.phoneNumbers?.map(pn => pn.numberE164))

    const handleSMS = () => {
        dispatch(
            addMessage({
                type: OutboundMessageType.SMS,
                contact,
                text: '',
                fromNumber: mobileNumbers[0].value,
                toNumber: contactMobileNumbers[0].numberE164 ?? ''
            })
        )
    }

    const handleWhatsApp = () => {
        dispatch(
            addMessage({
                type: OutboundMessageType.WhatsApp,
                contentType: WhatsAppContentType.TEXT,
                contact,
                toNumber: contactMobileNumbers[0].numberE164 ?? '',
                numberID: '',
                text: ''
            })
        )
    }

    const whatsappEnabled = useHasClientFeature(Features.whatsapp)

    return (
        <HStack
            as="header"
            h="176px"
            w="full"
            p="24px 16px 24px 24px"
            spacing="10px"
            bg={`${colorMode}.tones.ghostWhite`}
            borderBottom="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
            align="flex-start"
            justify="space-between"
        >
            <HStack spacing="24px" align="stretch">
                <HeaderAvatar
                    name={contact.fullName}
                    status={status?.status as UserStatus | undefined}
                    src={contact?.photoURL}
                />
                <VStack spacing={0} align="start" justify="space-between">
                    <VStack spacing={0} align="inherit">
                        <Heading size="h3" fontWeight="500">
                            {isContactGroup(contact) ? contact.name : contact.fullName}
                        </Heading>
                        <Body size="sm" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                            {contact.companyName}
                        </Body>
                        <Body size="sm" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                            {contact.location}
                        </Body>
                    </VStack>
                    <HStack spacing="16px">
                        <IconButton
                            variant="tertiary"
                            aria-label="Favourite contact"
                            onClick={toggleFavourite}
                            backgroundColor={'#fff'}
                        >
                            {isFavourited ? (
                                <StarIconSolid color={`${colorMode}.avatar.amber`} />
                            ) : (
                                <StarIconLight />
                            )}
                        </IconButton>
                        {type === 'internal' && (
                            <>
                                {flags.chat.enabled && (
                                    <Button
                                        variant="secondary"
                                        leftIcon={<ChatIconSolid />}
                                        onClick={handleChat}
                                    >
                                        {t('Chat')}
                                    </Button>
                                )}
                                {hasVideoPermission && (
                                    <Button
                                        variant="primary"
                                        leftIcon={<VideoIconSolid />}
                                        onClick={handleVideoCall}
                                    >
                                        {t('Video Call')}
                                    </Button>
                                )}
                            </>
                        )}
                    </HStack>
                </VStack>
            </HStack>
            <HStack spacing={0}>
                {smsEnabled && (
                    <IconButton variant="ghost" aria-label="SMS" onClick={handleSMS}>
                        <ChatIconRegular />
                    </IconButton>
                )}
                {whatsappEnabled && (
                    <IconButton variant="ghost" aria-label="WhatsApp" onClick={handleWhatsApp}>
                        <WhatsappIcon />
                    </IconButton>
                )}
                {!isContactGroup(contact) && !allNumbersBlocked && (
                    <ContactNumberOverflow
                        contact={contact}
                        trigger={
                            <MenuButton as={IconButton} variant="ghost">
                                <PhoneIconRegular />
                            </MenuButton>
                        }
                    />
                )}
                <ContactsHeaderMenu
                    contact={contact}
                    type={type}
                    onDelete={deleteDialog.onOpen}
                    isFavourited={isFavourited}
                    onToggleFavourite={toggleFavourite}
                />
                {!!contact && (
                    <DeleteContactDialog
                        contact={contact}
                        isOpen={deleteDialog.isOpen}
                        onClose={deleteDialog.onClose}
                    />
                )}
            </HStack>
        </HStack>
    )
}
