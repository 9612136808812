import { Box, HStack, useColorMode } from '@chakra-ui/react'
import { Body, IconButton } from '@missionlabs/react'
import { ArrowLeftCorner } from '@missionlabs/react/zeta'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export interface ReplyToProps {
    from: string
    message: string
    href?: string
}

export const ReplyTo: FC<ReplyToProps> = ({ from, message, href }) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleClick = (href: string) => {
        navigate(`/chat/${href}`)
    }

    return (
        <Box mb="12px">
            <HStack spacing={0}>
                {href && (
                    <IconButton
                        onClick={() => handleClick(href)}
                        w="20px"
                        paddingInline={0}
                        size="sm"
                        boxSize={10}
                        variant="transparent"
                        aria-label="Go To Message"
                        icon={<ArrowLeftCorner boxSize={5} color={`${colorMode}.primary.gamma`} />}
                    />
                )}
                <Body size="sm" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                    <Box as="span" sx={{ color: `${colorMode}.primary.gamma` }}>
                        {`${t('Replying To ')}${from} `}
                    </Box>
                    <Box as="span" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                        {`- "${message}"`}
                    </Box>
                </Body>
            </HStack>
        </Box>
    )
}
