import {
    Box,
    Grid,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react'
import { Banner, Button, Slider } from '@missionlabs/react'
import { useSettingsContext } from 'features/settings/components/settingsContext'
import { FC, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { Trans, useTranslation } from 'react-i18next'
import { useCreateUserFileMutation } from 'shared/store'

export interface UploadImageModelProps {
    isOpen: boolean
    onClose: () => void
}

export const UploadImageModal: FC<UploadImageModelProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation()

    const [src, setSrc] = useState<string>()
    const [error, setError] = useState<string>()
    const [scaleValue, setScaleValue] = useState<number>(5)

    const { user, onUpdateUser: updateUser } = useSettingsContext()
    const [createUserFile, { isLoading }] = useCreateUserFileMutation()

    const cropRef = useRef<any>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const handleImgChange = e => {
        if (!e.target?.files?.length) return
        const file = e.target.files[0]
        setSrc(URL.createObjectURL(file))
    }

    const handleSave = async () => {
        if (!user) return
        setError(undefined)

        if (!cropRef.current) return
        const dataURL = cropRef.current.getImage().toDataURL()

        try {
            const { URL } = await createUserFile({ ID: user.ID, fileData: dataURL }).unwrap()
            await updateUser({ ID: user.ID, photoURL: URL })
            closeModal()
        } catch (e) {
            const error = e.data.message
            if (!!error && typeof error === 'string') setError(error.toLowerCase())
        }
    }

    const closeModal = () => {
        setSrc(undefined)
        onClose()
    }

    if (!isOpen) return null

    return (
        <Modal isOpen={isOpen} onClose={closeModal} isCentered={true}>
            <ModalOverlay />
            <ModalContent fontSize="14px" maxW="480px">
                <ModalHeader>{t('myDetails.profile.uploadHeading')}</ModalHeader>
                <ModalBody>
                    <ModalCloseButton />
                    <input
                        onChange={handleImgChange}
                        type="file"
                        id="file"
                        ref={inputRef}
                        style={{ display: 'none' }}
                        accept="image/*"
                    />
                    {src ? (
                        <>
                            <AvatarEditor
                                ref={cropRef}
                                image={src}
                                style={{ width: '100%', height: '100%' }}
                                border={50}
                                borderRadius={150}
                                color={[0, 0, 0, 0.72]}
                                scale={scaleValue / 10}
                                rotate={0}
                            />
                            <Slider
                                min={1}
                                max={50}
                                size="lg"
                                value={scaleValue}
                                aria-label="Audio volume"
                                onChange={val => setScaleValue(val)}
                            />
                        </>
                    ) : (
                        <Box>{t('myDetails.profile.uploadDescription')}</Box>
                    )}

                    {!!error && (
                        <Banner>
                            <Trans
                                i18nKey="error"
                                components={{
                                    bold: <span style={{ color: 'white' }} />
                                }}
                                values={{ error }}
                            />
                        </Banner>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Grid gap="24px" gridTemplateColumns="1fr 1fr" w="100%">
                        {src ? (
                            <Button onClick={handleSave} variant="negatory" isLoading={isLoading}>
                                {t('Save')}
                            </Button>
                        ) : (
                            <Button onClick={() => inputRef?.current?.click()} variant="negatory">
                                {t('myDetails.profile.uploadSave')}
                            </Button>
                        )}
                        <Button onClick={closeModal} variant="negatory-outline">
                            {t('Cancel')}
                        </Button>
                    </Grid>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
