import { SimpleGrid } from '@chakra-ui/react'
import { IntegrationApp } from '@missionlabs/types'
import { matchSorter } from 'match-sorter'
import { FC, useMemo } from 'react'

import { IntegrationsCard } from './IntegrationsCard'
import { SkeletonIntegrationsCard } from './SkeletonIntegrationsCard'

export interface IntegrationsListProps {
    apps: IntegrationApp[]
    filters: {
        category: string
        search: string
        showInstalled: boolean
    }
    isLoading: boolean
}

export const IntegrationsList: FC<IntegrationsListProps> = ({ apps, filters, isLoading }) => {
    const { category, search, showInstalled } = filters

    const filteredApps = useMemo(() => {
        let _apps = [...apps]
        if (category !== 'All') _apps = _apps.filter(item => item.tags?.includes(category))
        if (search) _apps = matchSorter(_apps, search, { keys: ['name'] })
        if (showInstalled) _apps = _apps.filter(item => !!item.appInstallationID)
        return _apps
    }, [apps, category, search, showInstalled])

    return (
        <SimpleGrid
            w="full"
            columns={{ sm: 1, md: 2, lg: 3 }}
            spacing="24px"
            data-testid="integrations-list"
        >
            {isLoading &&
                Array.from({ length: 9 }, (_, i) => i).map(i => (
                    <SkeletonIntegrationsCard key={i} />
                ))}
            {filteredApps.map(item => (
                <IntegrationsCard key={item.ID} app={item} />
            ))}
        </SimpleGrid>
    )
}
