import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const RemoveIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'RemoveIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.4851 20.4853C25.1714 15.799 25.1714 8.20101 20.4851 3.51472C15.7988 -1.17157 8.20087 -1.17157 3.51458 3.51472C-1.17171 8.20101 -1.17171 15.799 3.51458 20.4853C8.20087 25.1716 15.7988 25.1716 20.4851 20.4853ZM17.8909 12.8312L6.10886 12.8312C5.64874 12.8312 5.27575 12.4582 5.27575 11.9981C5.27575 11.538 5.64874 11.165 6.10886 11.165L17.8909 11.165C18.351 11.165 18.724 11.538 18.724 11.9981C18.724 12.4582 18.351 12.8312 17.8909 12.8312Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_171"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.4851 20.4853C25.1714 15.799 25.1714 8.20101 20.4851 3.51472C15.7988 -1.17157 8.20087 -1.17157 3.51458 3.51472C-1.17171 8.20101 -1.17171 15.799 3.51458 20.4853C8.20087 25.1716 15.7988 25.1716 20.4851 20.4853ZM17.8909 12.8312L6.10886 12.8312C5.64874 12.8312 5.27575 12.4582 5.27575 11.9981C5.27575 11.538 5.64874 11.165 6.10886 11.165L17.8909 11.165C18.351 11.165 18.724 11.538 18.724 11.9981C18.724 12.4582 18.351 12.8312 17.8909 12.8312Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_171)"></g>
        </g>
    )
})
