import { CheckboxGroup, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body, IconButton, Input, Tooltip } from '@missionlabs/react'
import { CloseIcon, DoubleTickIcon, RefreshIcon } from '@missionlabs/react/circleloop'
import { DateFilterParam } from '@missionlabs/types'
import { endOfDay, endOfToday, startOfDay } from 'date-fns'
import * as React from 'react'
import { ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
    CallsFilterOptions,
    DateFilterOptions,
    DirectionFilterOptions,
    MessagesFilterOptions,
    StatusFilterOptions
} from '../../components/FilterOptions'
import { FilterSection } from '../../components/FilterSection'
import { formatDate } from '../../utils/filters'
import { useActivitySidebarContext } from '../ActivitySidebarContext'
import { MarkAllAsReadDialog } from './MarkAllAsReadDialog'

const getFrom = (value: string) => (value ? startOfDay(new Date(value)).toISOString() : '')
const getTo = (value: string) => (value ? endOfDay(new Date(value)).toISOString() : '')

interface ActivitySidebarFiltersProps {
    siblingHeight: number
}

export const ActivitySidebarFilters: React.FC<ActivitySidebarFiltersProps> = ({
    siblingHeight
}) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const {
        showFilters,
        filters,
        setFilters,
        resetFilters,
        setShowFilters,
        selectDateFilter,
        dateFilterOption,
        markAllAsReadDialog
    } = useActivitySidebarContext()

    const fromValue = filters[DateFilterParam.FROM] as string
    const toValue = filters[DateFilterParam.TO] as string

    const handleFromChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const from = getFrom(e.currentTarget.value)
            selectDateFilter({ selectedOption: 'custom', from })
        },
        [selectDateFilter]
    )

    const handleToChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const to = getTo(e.currentTarget.value)
            selectDateFilter({ selectedOption: 'custom', to })
        },
        [selectDateFilter]
    )

    if (!showFilters) return null

    return (
        <>
            <VStack
                spacing="24px"
                w="full"
                h={`calc(100% - ${siblingHeight}px)`}
                align="start"
                id="activity-sidebar-filters"
                p={4}
                pl={6}
                overflowY="auto"
            >
                {/* Header */}
                <HStack
                    justify="space-between"
                    w="full"
                    pb={4}
                    borderBottom="1px solid"
                    borderBottomColor={`${colorMode}.tones.periwinkle`}
                >
                    <Body size="md" variant="bold">
                        {t('activities.Filters')}
                    </Body>
                    <HStack spacing={0}>
                        <Tooltip label={t('activities.markAllAsRead')}>
                            <IconButton
                                variant="transparent"
                                size="sm"
                                aria-label={t('activities.markAllAsRead')}
                                icon={<DoubleTickIcon boxSize="20px" />}
                                onClick={markAllAsReadDialog.onOpen}
                            />
                        </Tooltip>
                        <Tooltip label={t('activities.filters.reset')}>
                            <IconButton
                                variant="transparent"
                                size="sm"
                                aria-label={t('activities.filters.reset')}
                                icon={<RefreshIcon boxSize="16px" />}
                                onClick={resetFilters}
                            />
                        </Tooltip>
                        <Tooltip label={t('close')}>
                            <IconButton
                                size="sm"
                                variant="transparent"
                                aria-label={t('close')}
                                icon={<CloseIcon boxSize="16px" />}
                                onClick={() => setShowFilters(false)}
                            />
                        </Tooltip>
                    </HStack>
                </HStack>

                {/* Filters */}
                <VStack align="start" spacing="24px">
                    <FilterSection label={t('Date')} mt={0}>
                        <CheckboxGroup
                            value={dateFilterOption ? [dateFilterOption] : []}
                            onChange={values => {
                                if (!values.length) {
                                    selectDateFilter({ selectedOption: undefined })
                                } else {
                                    // Date filter should only have one value,
                                    // so take the most recent value from the end of the array
                                    const lastOption = [...values].pop()
                                    selectDateFilter({ selectedOption: lastOption as any })
                                }
                            }}
                        >
                            <DateFilterOptions />
                        </CheckboxGroup>

                        {dateFilterOption === 'custom' && (
                            <HStack>
                                <Input
                                    title={t('From')}
                                    type="date"
                                    value={formatDate(fromValue)}
                                    onChange={handleFromChange}
                                    max={
                                        toValue
                                            ? formatDate(startOfDay(new Date(toValue)))
                                            : formatDate(endOfToday())
                                    }
                                />
                                <Input
                                    title={t('To')}
                                    type="date"
                                    value={formatDate(toValue)}
                                    onChange={handleToChange}
                                    min={
                                        // Can't go any earlier than the "from" date if it exists
                                        fromValue
                                            ? formatDate(startOfDay(new Date(fromValue)))
                                            : undefined
                                    }
                                    max={formatDate(endOfToday())}
                                />
                            </HStack>
                        )}
                    </FilterSection>

                    <FilterSection label={t('Messages')}>
                        <CheckboxGroup
                            value={filters.type}
                            onChange={values => setFilters({ type: values as string[] })}
                        >
                            <MessagesFilterOptions />
                        </CheckboxGroup>
                    </FilterSection>

                    <FilterSection label={t('Calls')}>
                        <CheckboxGroup
                            value={filters.type}
                            onChange={values => {
                                // Voicemail type and outgoing direction are mutually exclusive
                                // so remove the outgoing direction if voicemail is selected
                                const directions = values.includes('voicemail')
                                    ? filters.directions?.filter(dir => dir !== 'outgoing')
                                    : filters.directions
                                setFilters({ type: values as string[], directions })
                            }}
                        >
                            <CallsFilterOptions
                                disableVoicemail={filters.directions?.includes('outgoing')}
                            />
                        </CheckboxGroup>
                    </FilterSection>

                    <FilterSection label={t('Direction')}>
                        <CheckboxGroup
                            value={filters.directions}
                            onChange={values => {
                                // Voicemail type and outgoing direction are mutually exclusive
                                // so remove the voicemail type if outgoing is selected
                                const type = values.includes('outgoing')
                                    ? filters.type?.filter(t => t !== 'voicemail')
                                    : filters.type
                                setFilters({ directions: values as string[], type })
                            }}
                        >
                            <DirectionFilterOptions
                                disabledOutbound={filters.type?.includes('voicemail')}
                            />
                        </CheckboxGroup>
                    </FilterSection>

                    <FilterSection label={t('Status')}>
                        <CheckboxGroup
                            value={filters.status}
                            onChange={values => setFilters({ status: values as string[] })}
                        >
                            <StatusFilterOptions />
                        </CheckboxGroup>
                    </FilterSection>
                </VStack>
            </VStack>
            <MarkAllAsReadDialog />
        </>
    )
}
