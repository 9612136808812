import { Box, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { selectAuthenticatedUser, useDispatch, useSelector } from '@missionlabs/api'
import {
    Body,
    Button,
    ContactDetail,
    Dropdown,
    Heading,
    SelectOption,
    Textarea,
    useFormatToNumberE164,
    useToast
} from '@missionlabs/react'
import { OutboundMessageType, OutboundSMS } from '@missionlabs/types'
import { titleCase } from '@missionlabs/utils'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetMyNumbers } from 'shared/hooks/useGetMyNumbers'
import { removeMessage, updateMessage } from 'shared/slices/messagingSlice'
import { useSendMessageMutation } from 'shared/store'

interface SMSPanelProps {
    message?: OutboundSMS
}

export const SMSPanel: FC<SMSPanelProps> = ({ message }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const [sendSMS, query] = useSendMessageMutation()
    const { formatToLocalNumber, isUKMobileNumber } = useFormatToNumberE164()
    const user = useSelector(selectAuthenticatedUser)
    const { toast } = useToast()

    const myNumbers = useGetMyNumbers({ mobileOnly: true })

    const numbers: SelectOption[] = useMemo(() => {
        const phoneNumbers = message?.contact?.phoneNumbers ?? []
        return phoneNumbers
            .filter(number => isUKMobileNumber(number.numberE164))
            .map(number => ({
                value: number.numberE164,
                label: `${titleCase(number.label ?? t('Other'))} - ${formatToLocalNumber(
                    number.numberE164
                )}`
            }))
    }, [formatToLocalNumber, isUKMobileNumber, message, t])

    const handleChangeText = (value: string) => {
        if (!message) return
        dispatch(updateMessage({ ...message, text: value }))
    }

    const handleSendSMS = async () => {
        if (!message || !user) return
        try {
            await sendSMS({
                type: OutboundMessageType.SMS,
                toNumber: message.toNumber,
                fromNumber: message.fromNumber,
                text: message.text
            }).unwrap()
            toast({
                title: t('sms.success'),
                status: 'success'
            })

            if (message.ID) dispatch(removeMessage(message.ID))
        } catch (e) {
            toast({
                title: t('sms.error'),
                status: 'error'
            })
        }
    }

    const handleChangeToNumber = (toNumber: string) => {
        if (!message) return
        dispatch(updateMessage({ ...message, toNumber }))
    }

    const handleChangeFromNumber = (fromNumber: string) => {
        if (!message) return
        dispatch(updateMessage({ ...message, fromNumber }))
    }

    return (
        <Box w="full" py="16px">
            <Box
                w="full"
                border="1px solid"
                borderColor="transparent"
                borderBottomColor={`${colorMode}.tones.stormGrey`}
                pb="16px"
                mb="16px"
                px="24px"
            >
                <ContactDetail
                    name={message?.contact?.fullName ?? message?.toNumber}
                    subText={message?.contact ? message.contact.companyName : 'Unknown'}
                    variant="horizontal-dark"
                    sx={{ width: '100%' }}
                />
            </Box>
            <VStack px="24px" w="full" spacing="16px" align="flex-start">
                <Heading size="h3" variant="bold" color="white">
                    {t('sms.new')}
                </Heading>

                <VStack spacing="8px" w="full" align="flex-start">
                    <Body size="sm" sx={{ color: 'white' }}>
                        {t('sms.sendFrom')}
                    </Body>
                    <Dropdown
                        width="full"
                        variant="dark"
                        options={myNumbers}
                        value={message?.fromNumber}
                        onChange={handleChangeFromNumber}
                    />
                </VStack>

                {message?.contact && (
                    <VStack spacing="8px" w="full" align="flex-start">
                        <Body size="sm" sx={{ color: 'white' }}>
                            {t('sms.sendTo')}
                        </Body>
                        <Dropdown
                            width="full"
                            variant="dark"
                            options={numbers}
                            value={message?.toNumber}
                            onChange={handleChangeToNumber}
                        />
                    </VStack>
                )}

                <VStack spacing="8px" w="full" align="flex-start">
                    <Body size="sm" color="white">
                        {t('sms.smsContent')}
                    </Body>
                    <Textarea
                        aria-label="sms content"
                        h="168px"
                        placeholder={t('sms.enterMessage')}
                        variant="dark"
                        value={message?.text}
                        onChange={e => handleChangeText(e.target.value)}
                    />
                </VStack>
                <HStack pt="8px" w="full">
                    <Button
                        isLoading={query.isLoading}
                        w="full"
                        isDisabled={!message?.text}
                        onClick={handleSendSMS}
                    >
                        {t('sms.sendSMS')}
                    </Button>
                </HStack>
            </VStack>
        </Box>
    )
}
