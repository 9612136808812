import { selectPreferences, useSelector } from '@missionlabs/api'
import { UseGetDevicesReturn } from '@missionlabs/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useAudioInputSelection from './useAudioInputSelection'
import useAudioOutputSelection from './useAudioOutputSelection'
import { useDeviceOptions } from './useDeviceOptions'
import useVideoInputSelection from './useVideoInputSelection'

export type UseGetChimeDevicesReturn = UseGetDevicesReturn & {
    audioInputVolume: number
    handleChangeAudioInput: (value: string) => Promise<void>
    setAudioInputVolumeCallback: (gainValue: any) => void
    handleChangeAudioOutput: (value: string, volume?: number) => Promise<void>
}

export const useGetChimeDevices = (isPreview?: boolean): UseGetChimeDevicesReturn => {
    const { t } = useTranslation()
    const preferences = useSelector(selectPreferences)

    const {
        selectedDeviceId: inputDeviceID,
        devices: audioInputDevices = [],
        audioInputVolume,
        handleChangeAudioInput,
        setAudioInputVolumeCallback
    } = useAudioInputSelection(
        preferences?.inputDeviceID ?? 'default',
        preferences?.inputCaptureLevel
    )

    const {
        selectedDevice: outputDeviceID,
        devices: audioOutputDevices = [],
        handleChangeAudioOutput
    } = useAudioOutputSelection(preferences?.outputDeviceID ?? 'default', preferences?.audioVolume)

    const { devices: videoCameraDevices = [] } = useVideoInputSelection(
        preferences?.cameraDeviceID ?? '',
        isPreview,
        isPreview
    )
    const cameraDeviceID = useMemo(() => {
        return (
            videoCameraDevices.find(cam => {
                return cam.deviceId === preferences?.cameraDeviceID
            })?.deviceId ?? ''
        )
    }, [videoCameraDevices, preferences])

    const inputDevices = useDeviceOptions(audioInputDevices, false)
    const outputDevices = useDeviceOptions(audioOutputDevices)
    const cameraDevices = [
        ...(isPreview ? [{ label: t('Off'), value: '' }] : []),
        ...useDeviceOptions(videoCameraDevices, false)
    ]

    const ringingOutputDeviceID = preferences?.ringingOutputDeviceID

    return {
        inputDevices,
        outputDevices,
        cameraDevices,
        inputDeviceID,
        outputDeviceID: outputDeviceID ?? undefined,
        ringingOutputDeviceID,
        cameraDeviceID,
        audioInputVolume,
        handleChangeAudioInput,
        setAudioInputVolumeCallback,
        handleChangeAudioOutput
    }
}
