import { HStack } from '@chakra-ui/react'
import { Body, Toggle } from '@missionlabs/react'
import { useSettingsContext } from 'features/settings/components/settingsContext'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {}

const AnonymousCallSetting: React.FC<Props> = () => {
    const { t } = useTranslation()

    const { userCallRoute, onUpdateUserCallRoute } = useSettingsContext()

    return (
        <SettingsRow label={t('calls.anonymousCalls.label')}>
            <HStack w="full" justify="space-between" align="flex-start">
                <Body size="sm" variant="bold">
                    {t('calls.anonymousCalls.description')}
                </Body>
                <Toggle
                    isChecked={!!userCallRoute?.blockAnonymousCalls}
                    onChange={e => onUpdateUserCallRoute({ blockAnonymousCalls: e.target.checked })}
                />
            </HStack>
        </SettingsRow>
    )
}

export default AnonymousCallSetting
