import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PhoneDownIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PhoneDownIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M14.3365 2.70778L19.3227 2.02029C20.5148 1.85608 21.5853 2.70709 21.6947 3.90604L21.991 7.15276C22.1013 8.36069 21.1853 9.36349 19.9725 9.36349L16.1527 9.36349C15.7768 9.36349 15.2037 8.93734 14.6095 8.34312C14.3772 8.1104 13.8099 8.19131 13.3718 8.62998L8.62967 13.3721C8.19141 13.8098 8.11068 14.3768 8.3434 14.6099C8.93481 15.2014 9.36343 15.7806 9.36343 16.1577L9.36343 19.9718C9.36343 21.1857 8.36134 22.1004 7.15194 21.9911L3.90516 21.6949C2.70687 21.5854 1.8561 20.5147 2.02029 19.3227L2.70777 14.3366C2.84579 13.3336 3.51644 11.9841 4.23243 11.269L11.2692 4.23142C11.9852 3.51633 13.3348 2.84564 14.3365 2.70778Z"
            fill="currentColor"
        />
    )
})

export const PhoneUpIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PhoneUpIcon',
    viewBox: '0 0 28 28',
    path: (
        <path
            d="M1.6107 10.8846L0.694041 4.23647C0.475105 2.647 1.60977 1.21957 3.20837 1.07371L7.53734 0.6787C9.14791 0.531617 10.485 1.7529 10.485 3.36999V8.46304C10.485 8.96425 9.91678 9.72839 9.12449 10.5207C8.81419 10.8304 8.92207 11.5868 9.50696 12.171L15.8298 18.4938C16.4134 19.0781 17.1694 19.1858 17.4802 18.8755C18.2688 18.0869 19.0411 17.5154 19.5439 17.5154H24.6295C26.248 17.5154 27.4675 18.8516 27.3219 20.4641L26.9268 24.7931C26.7809 26.3909 25.3533 27.5252 23.764 27.3063L17.1159 26.3897C15.7785 26.2056 13.9792 25.3114 13.0257 24.3568L3.64221 14.9744C2.68876 14.0198 1.79451 12.2203 1.6107 10.8846Z"
            fill="currentColor"
        />
    )
})

export const PhoneHangupIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PhoneHangupIcon',
    viewBox: '0 0 16 8',
    path: (
        <path
            d="M13.3532 7.35109C13.843 7.54597 14.4042 7.35879 14.6675 6.91519L15.7382 5.10745C15.9745 4.71001 15.9031 4.21 15.5683 3.88691C11.3891 -0.143945 4.61327 -0.143945 0.431591 3.88691C0.096859 4.21 0.0254824 4.71001 0.261764 5.10745L1.33241 6.91519C1.59577 7.35879 2.15693 7.54341 2.64673 7.35109L4.92093 6.44851C5.35165 6.27671 5.61255 5.85619 5.56824 5.41002L5.42549 4.04333C7.0893 3.4869 8.90817 3.4869 10.572 4.04333L10.4292 5.41002C10.3825 5.85619 10.6458 6.27927 11.0765 6.44851L13.3532 7.35109Z"
            fill="currentColor"
        />
    )
})

export const PhoneMissedInboundIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PhoneMissedInboundIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M9.5 3H6C5.58579 3 5.25 3.33579 5.25 3.75V7.25C5.25 7.66421 5.58579 8 6 8C6.41421 8 6.75 7.66421 6.75 7.25V5.56066L11.4697 10.2803C11.7626 10.5732 12.2374 10.5732 12.5303 10.2803L18.5303 4.28033C18.8232 3.98744 18.8232 3.51256 18.5303 3.21967C18.2374 2.92678 17.7626 2.92678 17.4697 3.21967L12 8.68934L7.81066 4.5H9.5C9.91421 4.5 10.25 4.16421 10.25 3.75C10.25 3.33579 9.91421 3 9.5 3Z"
                fill="currentColor"
            />
            <path
                d="M19.5505 12.9457L23.2342 15.5791C24.1149 16.2088 24.2574 17.3859 23.55 18.1874L21.6343 20.3579C20.9216 21.1655 19.6758 21.2187 18.8884 20.4757L16.4082 18.1356C16.1642 17.9054 16.0688 17.2932 16.0688 16.5652C16.069 16.2803 15.6482 15.9823 15.0789 15.9827L8.92091 15.9827C8.35216 15.9823 7.9316 16.2802 7.93134 16.5656C7.93133 17.2902 7.83356 17.9076 7.58869 18.1387L5.11223 20.4752C4.32408 21.2189 3.07957 21.1653 2.36525 20.3575L0.449539 18.187C-0.257437 17.3859 -0.114622 16.2087 0.765922 15.5791L4.44966 12.9457C5.19052 12.4159 6.50217 12 7.43134 12.0005L16.5696 12C17.4988 12.0006 18.8105 12.4165 19.5505 12.9457Z"
                fill="currentColor"
            />
        </g>
    )
})

export const PhonebookOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PhonebookOutlineIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M14.6528 9.33773C14.6554 9.29073 14.6577 9.24329 14.6595 9.19543C14.6642 9.07432 14.6667 8.95037 14.6667 8.82353C14.6667 7.26412 13.4728 6 12 6C10.5272 6 9.33333 7.26412 9.33333 8.82353C9.33333 9.00668 9.33838 9.18388 9.34814 9.35514C9.16992 9.49671 9.08415 9.81544 9.15202 10.1537C9.27132 10.7477 9.47852 11.0784 9.69271 11.1638C9.85889 11.6185 10.0767 11.9913 10.3333 12.2824V12.9609C7.84993 13.9316 7 14.5704 7 16.8706C7 17.4943 7.44775 18 8 18H16C16.5522 18 17 17.4943 17 16.8706C17 14.5704 16.1501 13.9316 13.6667 12.9609V12.2824C13.9215 11.9935 14.138 11.6239 14.3037 11.1737C14.5278 11.1069 14.7482 10.7738 14.8727 10.1537C14.9437 9.80075 14.847 9.46914 14.6528 9.33773Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 19V22C2 23.1046 2.89543 24 4 24H20C21.1046 24 22 23.1046 22 22V2C22 0.895431 21.1046 0 20 0H4C2.89543 0 2 0.89543 2 2V6H1.5C1.22386 6 1 6.22386 1 6.5C1 6.77614 1.22386 7 1.5 7H2V10H1.5C1.22386 10 1 10.2239 1 10.5C1 10.7761 1.22386 11 1.5 11H2V14H1.5C1.22386 14 1 14.2239 1 14.5C1 14.7761 1.22386 15 1.5 15H2V18H1.5C1.22386 18 1 18.2239 1 18.5C1 18.7761 1.22386 19 1.5 19H2ZM20 1H4C3.44771 1 3 1.44772 3 2V6H3.5C3.77614 6 4 6.22386 4 6.5C4 6.77614 3.77614 7 3.5 7H3V10H3.5C3.77614 10 4 10.2239 4 10.5C4 10.7761 3.77614 11 3.5 11H3V14H3.5C3.77614 14 4 14.2239 4 14.5C4 14.7761 3.77614 15 3.5 15H3V18H3.5C3.77614 18 4 18.2239 4 18.5C4 18.7761 3.77614 19 3.5 19H3V22C3 22.5523 3.44772 23 4 23H20C20.5523 23 21 22.5523 21 22V2C21 1.44772 20.5523 1 20 1Z"
                fill="currentColor"
            />
        </g>
    )
})

export const PhonebookIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PhonebookIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 2C3 0.89543 3.89543 0 5 0H19C20.1046 0 21 0.895431 21 2V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V18H4C5.10457 18 6 17.1046 6 16C6 14.8954 5.10457 14 4 14H3V13H4C5.10457 13 6 12.1046 6 11C6 9.89543 5.10457 9 4 9H3V8H4C5.10457 8 6 7.10457 6 6C6 4.89543 5.10457 4 4 4H3V2ZM15.1223 8.78144C15.1243 8.74227 15.1262 8.70274 15.1276 8.66286C15.1314 8.56193 15.1333 8.45864 15.1333 8.35294C15.1333 7.05343 14.1783 6 13 6C11.8217 6 10.8667 7.05343 10.8667 8.35294C10.8667 8.50556 10.8707 8.65323 10.8785 8.79595C10.7359 8.91393 10.6673 9.17954 10.7216 9.46141C10.8171 9.95644 10.9828 10.232 11.1542 10.3032C11.2871 10.6821 11.4613 10.9928 11.6667 11.2353V11.8007C9.67995 12.6096 9 13.142 9 15.0588C9 15.5786 9.3582 16 9.8 16H16.2C16.6418 16 17 15.5786 17 15.0588C17 13.142 16.3201 12.6096 14.3333 11.8007V11.2353C14.5372 10.9945 14.7104 10.6866 14.843 10.3114C15.0223 10.2557 15.1986 9.97816 15.2982 9.46141C15.3549 9.16729 15.2776 8.89095 15.1223 8.78144Z"
                fill="currentColor"
            />
            <path
                d="M5.17677 22.8233C5.49058 23.5172 6.18891 24 7.00001 24H19C21.2092 24 23 22.2092 23 20V4.00001C23 3.18891 22.5172 2.49058 21.8233 2.17677C21.9368 2.42784 22 2.70654 22 3.00001V20C22 21.6569 20.6569 23 19 23H6.00001C5.70654 23 5.42784 22.9368 5.17677 22.8233Z"
                fill="currentColor"
            />
            <path
                d="M2 5C1.44772 5 1 5.44772 1 6C1 6.55228 1.44772 7 2 7H4C4.55228 7 5 6.55228 5 6C5 5.44772 4.55228 5 4 5H2Z"
                fill="currentColor"
            />
            <path
                d="M2 10C1.44772 10 1 10.4477 1 11C1 11.5523 1.44772 12 2 12H4C4.55228 12 5 11.5523 5 11C5 10.4477 4.55228 10 4 10H2Z"
                fill="currentColor"
            />
            <path
                d="M1 16C1 15.4477 1.44772 15 2 15H4C4.55228 15 5 15.4477 5 16C5 16.5523 4.55228 17 4 17H2C1.44772 17 1 16.5523 1 16Z"
                fill="currentColor"
            />
        </g>
    )
})
