import { useDisclosure } from '@chakra-ui/react'
import {
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    useToast
} from '@missionlabs/react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDeleteWhatsAppNumberMutation } from 'shared/store'

export const DeleteWhatsAppNumberDialog = () => {
    const navigate = useNavigate()
    const { toast } = useToast()
    const { t } = useTranslation()
    const { id: numberID = '' } = useParams()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const ref = useRef<HTMLButtonElement>(null)
    const [deleteNumber, { isLoading: isDeleting }] = useDeleteWhatsAppNumberMutation()

    const handleDeleteNumber = async (numberID: string) => {
        try {
            await deleteNumber(numberID).unwrap()
            toast({
                title: t('admin.whatsapp.delete.success'),
                status: 'success'
            })
            handleLeave()
        } catch (error) {
            toast({
                title: t('admin.whatsapp.delete.error'),
                status: 'error'
            })
        }
    }

    const handleLeave = () => {
        navigate('/admin/whatsapp')
        onClose()
    }

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton onClick={handleLeave} />
                <DialogHeader>{t('admin.whatsapp.delete.title')}</DialogHeader>
                <DialogBody gap="12px">
                    <p>{t('admin.whatsapp.delete.content')}</p>
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel'), onClick: handleLeave }}
                    confirm={{
                        label: t('admin.whatsapp.confirmDelete'),
                        isDestructive: true,
                        variant: 'negatory',
                        onClick: () => void handleDeleteNumber(numberID)
                    }}
                    isLoading={isDeleting}
                />
            </DialogContent>
        </Dialog>
    )
}
