import { FormProvider, useYupForm, yup } from '@missionlabs/react'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import { FC, useEffect, useState } from 'react'
import { useRequestReportMutation } from 'shared/store'

import {
    callsDefaultValues,
    callsParamSchema,
    callsVisibilitySchema,
    defaultValues as ReportFormDefaultValues,
    itemisedBillingDefaultValues,
    itemisedBillingParamSchema,
    itemisedBillingVisibilitySchema,
    ReportFormFields,
    smsDefaultValues,
    smsParamSchema,
    smsVisibilitySchema
} from './form'
import ReportFormInitial from './ReportFormInitial'
import ReportFormItems from './ReportFormItems/index'

interface ReportFormProps {
    defaultValues?: ReportFormFields
}

export const ReportForm: FC<ReportFormProps> = ({ defaultValues = {} }) => {
    const [requestReport, { isLoading }] = useRequestReportMutation()
    const { setCreate } = useAnalytics().report

    const methods = useYupForm<ReportFormFields>(
        yup.object().shape({
            name: yup.string().required(),
            type: yup.string().oneOf(['call', 'sms', 'itemized_bill']).required(),
            // Alter the params schema based on report type
            params: yup.lazy(({ type }) => {
                switch (type) {
                    case 'call': {
                        return callsParamSchema
                    }
                    case 'sms': {
                        return smsParamSchema
                    }
                    case 'itemized_bill': {
                        return itemisedBillingParamSchema
                    }
                    default: {
                        return yup.object().shape({})
                    }
                }
            }),
            // Alter the visibility schema based on report type
            visibility: yup.lazy(({ type }) => {
                switch (type) {
                    case 'call': {
                        return callsVisibilitySchema
                    }
                    case 'sms': {
                        return smsVisibilitySchema
                    }
                    case 'itemized_bill': {
                        return itemisedBillingVisibilitySchema
                    }
                    default: {
                        return yup.object().shape({})
                    }
                }
            })
        }),
        {
            defaultValues: {
                ...ReportFormDefaultValues,
                ...defaultValues
            }
        }
    )

    const { watch, setValue } = methods

    const formType = watch('type')

    useEffect(
        function setFormDefaultValuesForType() {
            switch (formType) {
                case 'call': {
                    setValue('params', callsDefaultValues.params)
                    setValue('visibility', callsDefaultValues.visibility)
                    return
                }
                case 'sms': {
                    setValue('params', smsDefaultValues.params)
                    setValue('visibility', smsDefaultValues.visibility)
                    return
                }
                case 'itemized_bill': {
                    setValue('params', itemisedBillingDefaultValues.params)
                    setValue('visibility', itemisedBillingDefaultValues.visibility)
                    return
                }
                default: {
                    return
                }
            }
        },
        [formType, setValue]
    )

    const [step, setStep] = useState(0)
    const nextStep = () => setStep(step => step + 1)

    const onSubmit = async data => {
        await requestReport({
            ...data
        }).unwrap()

        setCreate(false)
    }

    return (
        <FormProvider {...methods}>
            <form style={{ height: '100%' }} onSubmit={methods.handleSubmit(onSubmit)}>
                {step === 1 ? (
                    <ReportFormItems isSubmitting={isLoading} />
                ) : (
                    <ReportFormInitial goToNextStep={nextStep} isSubmitting={isLoading} />
                )}
            </form>
        </FormProvider>
    )
}
