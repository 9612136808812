import { Radio } from '@missionlabs/react'
import { UserCallRoute } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface RingOutRadioProps {
    onUpdate: (payload: Partial<UserCallRoute>) => void
}

export const RingOutRadio: FC<RingOutRadioProps> = ({ onUpdate }) => {
    const { t } = useTranslation()

    /** onClick wasn't reliable when switching between the radio btns */
    const handleOnChange = () => onUpdate({ inHoursUnanswered: 'ring_out' })

    return (
        <Radio
            value="ring_out"
            py="4px"
            display="flex"
            alignItems="center"
            onChange={handleOnChange}
        >
            {t('calls.keepRinging')}
        </Radio>
    )
}
