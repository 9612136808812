import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const HangupIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HangupIconLight',
    viewBox: '0 0 26 12',
    path: (
        <path
            d="M24.9729 5.43011C21.7789 2.14251 17.5281 0.383667 13.0003 0.383667C8.47255 0.383667 4.21779 2.17644 1.02769 5.43011C0.456125 6.01119 0.356171 6.90426 0.783948 7.59844L2.73623 10.7819C3.18003 11.5081 4.07115 11.808 4.86478 11.4901L8.77635 9.89585C9.48574 9.60457 9.92799 8.86122 9.85116 8.09099L9.65422 6.04239C11.8307 5.38799 14.1742 5.38721 16.3542 6.04299L16.1574 8.05377C16.0757 8.83024 16.5199 9.57472 17.2361 9.8637L21.1438 11.4552C21.3558 11.5418 21.5751 11.5832 21.7919 11.5832C22.3781 11.5832 22.9424 11.281 23.2672 10.751L25.2186 7.59844C25.6437 6.90426 25.5423 6.01119 24.9729 5.43011ZM24.1189 6.94716L22.1646 10.1341C22.0397 10.3389 21.7911 10.4242 21.575 10.3352L17.6673 8.7425C17.4662 8.66209 17.337 8.44147 17.3608 8.21485L17.607 5.7006C17.635 5.41361 17.4631 5.14487 17.1912 5.05049C15.8282 4.57627 14.3966 4.33955 12.9638 4.33955C11.5329 4.33955 10.102 4.57596 8.7402 5.0501C8.50765 5.14152 8.33606 5.41061 8.36336 5.6992L8.60831 8.21579C8.63085 8.44245 8.5041 8.66076 8.30182 8.74344L4.39804 10.3338C4.1793 10.424 3.92694 10.3387 3.80136 10.1315L1.84744 6.94326C1.7207 6.73851 1.74995 6.47527 1.91811 6.30407C4.87297 3.29297 8.80794 1.63163 12.9652 1.63163C17.1225 1.63163 21.0926 3.29102 24.0487 6.30368C24.2515 6.47527 24.2788 6.70536 24.1189 6.94716Z"
            fill="currentColor"
        />
    )
})

export const HangupIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HangupIconRegular',
    viewBox: '0 0 26 13',
    path: (
        <path
            d="M24.9105 5.44866C21.7394 2.23069 17.5151 0.459839 12.9826 0.459839C8.48525 0.459839 4.26094 2.23108 1.08978 5.44866C0.425907 6.12268 0.309671 7.15437 0.806368 7.95711L2.70867 11.0421C3.22355 11.8819 4.26149 12.222 5.18202 11.8612L8.99131 10.317C9.85045 9.97721 10.3302 9.11909 10.2756 8.22586L10.1118 6.54862C12.0086 6.03062 14.028 6.03179 15.9275 6.54862L15.7636 8.21806C15.6679 9.11753 16.1852 9.98072 17.018 10.3158L20.8218 11.8577C21.0687 11.9576 21.3246 12.0052 21.577 12.0052C22.2584 12.0052 22.9137 11.6565 23.2909 11.0421L25.1936 7.95559C25.6906 7.1532 25.5736 6.11955 24.9105 5.44866ZM23.5999 6.97378L21.6961 10.0619C21.6601 10.1228 21.5845 10.1472 21.5242 10.1228L17.718 8.57972C17.6589 8.55656 17.6181 8.48462 17.626 8.40907L17.8656 5.97317C17.9076 5.54215 17.6492 5.13884 17.2403 4.99725C14.5173 4.0537 11.5182 4.05448 8.79948 4.99664C8.3907 5.13803 8.13209 5.54155 8.17422 5.97256L8.41313 8.41041C8.42105 8.48355 8.38022 8.55668 8.31987 8.58106L4.51994 10.1218C4.45047 10.1498 4.37733 10.1242 4.33833 10.0596L2.43431 6.93867C2.39164 6.86979 2.40139 6.78507 2.45808 6.72781C5.27509 3.90404 9.02353 2.33211 12.9826 2.33211C16.9768 2.33211 20.7252 3.90521 23.5414 6.76315C23.635 6.81776 23.6428 6.90357 23.5999 6.97378Z"
            fill="currentColor"
        />
    )
})

export const HangupIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HangupIconSolid',
    viewBox: '0 0 26 11',
    path: (
        <path
            d="M21.4848 10.6543C22.2612 10.9508 23.1506 10.666 23.568 9.99107L25.265 7.24078C25.6395 6.63611 25.5264 5.87539 24.9958 5.38385C18.3717 -0.748718 7.63195 -0.748718 1.00394 5.38385C0.473385 5.87539 0.360252 6.63611 0.73476 7.24078L2.43175 9.99107C2.84917 10.666 3.73862 10.9468 4.51495 10.6543L8.11959 9.28107C8.80228 9.01969 9.2158 8.37991 9.14558 7.70111L8.91932 5.62182C11.5565 4.77527 14.4394 4.77527 17.0766 5.62182L16.8503 7.70111C16.7762 8.37991 17.1936 9.0236 17.8763 9.28107L21.4848 10.6543Z"
            fill="currentColor"
        />
    )
})
