import { Button, HStack, useColorMode } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { MessageIconSolid } from '@missionlabs/react/zeta'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface ChatHistoryThreadCountProps {
    count: number
    onClick: () => void
}

export const ChatHistoryThreadCount: FC<ChatHistoryThreadCountProps> = ({ count, onClick }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    return (
        <Button height="28px" variant="ghost" p={0} onClick={onClick}>
            <HStack
                borderColor={`${colorMode}.tones.periwinkle`}
                p="4px 4px"
                borderWidth="1px"
                borderRadius="4px"
                spacing="4px"
                cursor="pointer"
            >
                <MessageIconSolid p="1px" mr="4px" />
                <Body size="xs">{count}</Body>
                <Body size="xs">{t('Replies')}</Body>
            </HStack>
        </Button>
    )
}
