import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { searchInputAnatomy } from 'theme/anatomy'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(searchInputAnatomy.keys)

const resetAsideStyles = {
    w: 'unset',
    h: 'unset',
    top: 'unset',
    fontSize: 'unset',
    position: 'relative'
}

export const SearchInputStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: {
        group: {
            pl: '24px',
            pr: '16px',
            py: '12px',
            alignItems: 'center',
            borderBottom: '1px solid'
        },
        input: {
            p: 0,
            border: 'none',
            bg: 'transparent',
            color: 'inherit',
            fontFamily: 'body',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '150%',
            _focusVisible: {},
            _placeholder: { color: 'inherit' }
        },
        rightAside: {
            ...resetAsideStyles,
            justifyContent: 'flex-end',
            right: 'unset'
        }
    },
    variants: {
        light: ({ colorMode }) => {
            const hoverStyles = {
                bg: `${colorMode}.tones.whiteLilac`,
                color: `${colorMode}.tones.midnightExpress`
            }
            return {
                group: {
                    borderColor: `${colorMode}.tones.periwinkle`,
                    color: `${colorMode}.tones.stormGrey`,
                    _active: {
                        color: `${colorMode}.primary.gammaHover`
                    },
                    _hover: hoverStyles,
                    _focus: hoverStyles
                }
            }
        },
        dark: ({ colorMode }) => {
            return {
                group: {
                    bg: `${colorMode}.tones.vantaBlue`,
                    borderColor: `${colorMode}.tones.stormGrey`,
                    color: `${colorMode}.tones.stormGrey`,
                    _active: {
                        color: `${colorMode}.tones.white`
                    }
                }
            }
        }
    }
})
