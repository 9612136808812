import { Button, ButtonGroup, Flex, useColorMode } from '@chakra-ui/react'
import { SearchFilter } from '@missionlabs/api'
import { Tooltip } from '@missionlabs/react'
import {
    FavouriteIcon,
    InternalContactsIcon,
    SharedContactIcon,
    UserIcon
} from '@missionlabs/react/circleloop'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

export type ContactTypeFilterType = 'all' | 'favourites' | SearchFilter
interface Props {
    onClick(type: ContactTypeFilterType): void
}

interface Filter {
    id: ContactTypeFilterType
    icon: ReactNode
}

const filters: Filter[] = [
    {
        id: 'all',
        icon: 'All'
    },
    {
        id: 'clPersonal',
        icon: <UserIcon height="18px" width="18px" />
    },
    {
        id: 'clCompany',
        icon: <InternalContactsIcon height="18px" width="18px" />
    },
    {
        id: 'shared',
        icon: <SharedContactIcon height="18px" width="18px" />
    },
    {
        id: 'favourites',
        icon: <FavouriteIcon height="18px" width="18px" />
    }
]

const ContactTypeFilters: React.FC<Props> = ({ onClick }) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    const [params] = useSearchParams()

    const isButtonActive = (id: ContactTypeFilterType) => {
        const type = params.get('type')
        return (!type && id === 'all') || id === type
    }

    const getFilterTooltip = (filter: Filter) => {
        switch (filter.id) {
            case 'all':
                return t('contacts.filters.all')
            case 'clPersonal':
                return t('contacts.filters.personal')
            case 'clCompany':
                return t('contacts.filters.clCompany')
            case 'shared':
                return t('contacts.filters.shared')
            case 'favourites':
                return t('contacts.filters.favourites')
            default:
                return filter.id
        }
    }

    return (
        <Flex
            borderColor={`${colorMode}.tones.periwinkle`}
            borderBottomWidth={1}
            py={'12px'}
            bg={`${colorMode}.tones.ghostWhite`}
        >
            <ButtonGroup isAttached spacing={0} w="90%" margin="auto" variant="outline">
                {filters.map(filter => (
                    <Tooltip
                        key={filter.id}
                        label={getFilterTooltip(filter)}
                        aria-label={getFilterTooltip(filter)}
                        placement="top"
                        hasArrow
                    >
                        <Button
                            background={`${colorMode}.tones.whiteLilac`}
                            borderColor={`${colorMode}.tones.periwinkle`}
                            color={`${colorMode}.tones.stormGrey`}
                            _active={{ color: `${colorMode}.tones.navy`, background: '#fff' }}
                            _hover={{ color: `${colorMode}.tones.navy`, background: '#fff' }}
                            onClick={() => onClick(filter.id)}
                            isActive={isButtonActive(filter.id)}
                            w="25%"
                            px="26px"
                        >
                            {filter.icon}
                        </Button>
                    </Tooltip>
                ))}
            </ButtonGroup>
        </Flex>
    )
}

export default ContactTypeFilters
