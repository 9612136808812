import { VStack } from '@chakra-ui/react'
import { Body, Checkbox, useFormContext, ValidatedInput } from '@missionlabs/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReportFormFields } from '../form'
import UserTeamMenuVisabilityInputs from './UserTeamMenuVisabilityInputs'

const CallsForm: React.FC = () => {
    const { watch, setValue } = useFormContext<ReportFormFields>()
    const { t } = useTranslation()

    return (
        <VStack mt={4} w="full" alignItems="left">
            <Body>{t('analytics.reports.form.fieldsToInclude')}</Body>

            <ValidatedInput name="visibility.showDuration">
                <Checkbox
                    checked={watch('visibility.showDuration')}
                    onChange={e => setValue('visibility.showDuration', e.target.checked)}
                >
                    {t('analytics.reports.form.duration')}
                </Checkbox>
            </ValidatedInput>

            <UserTeamMenuVisabilityInputs />
        </VStack>
    )
}

export default CallsForm
