import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'

export type ICallNote = {
    note: string
    savedNote: string
    isSaved: boolean
    callTraceID: string
    ID: string | undefined
}

type NewCallNote = Pick<ICallNote, 'note' | 'callTraceID'>
type UpdateCallNote = Partial<ICallNote> & Pick<ICallNote, 'callTraceID'>

export type CallNoteState = {
    callNotes: Record<ICallNote['callTraceID'], ICallNote>
}

export type CallNoteActions = {
    addCallNote: (state: CallNoteState, action: PayloadAction<NewCallNote>) => void
    updateCallNote: (state: CallNoteState, action: PayloadAction<UpdateCallNote>) => void
    removeCallNote: (state: CallNoteState, action: PayloadAction<{ callTraceID: string }>) => void
}

const initialState: CallNoteState = {
    callNotes: {}
}

export const callNoteSlice: Slice<CallNoteState, CallNoteActions, 'callNoteSlice'> = createSlice({
    name: 'callNoteSlice',
    initialState,
    reducers: {
        addCallNote: (state, action) => {
            const { callTraceID } = action.payload
            if (state.callNotes[callTraceID]) {
                console.warn(`Call note with callTraceID ${callTraceID} already exists`)
            } else {
                state.callNotes[callTraceID] = {
                    ...action.payload,
                    savedNote: '',
                    isSaved: false,
                    ID: undefined
                }
            }
        },
        updateCallNote: (state, action) => {
            const { callTraceID } = action.payload
            const note = state.callNotes[callTraceID]
            if (!note) {
                console.warn(`Call note with callTraceID ${callTraceID} does not exist`)
            } else {
                state.callNotes[callTraceID] = { ...note, ...action.payload }
            }
        },
        removeCallNote: (state, action) => {
            if (state.callNotes[action.payload.callTraceID]) {
                delete state.callNotes[action.payload.callTraceID]
            }
        }
    }
})

export type CallNoteSliceRootState = {
    callNoteSlice: ReturnType<typeof callNoteSlice.getInitialState>
}

export const selectCallNotes = (state: CallNoteSliceRootState) => {
    return state.callNoteSlice?.callNotes
}

export const selectCallNote = (state: CallNoteSliceRootState, callTraceID: string | undefined) => {
    if (!callTraceID) return
    const callNotes = selectCallNotes(state)
    return callNotes[callTraceID]
}

/**
 * Get all the call notes under the same activity callTraceID
 *
 * @param state
 * @param callTraceID
 * @returns A list of call notes
 */
export const selectActivityCallNotes = (
    state: CallNoteSliceRootState,
    callTraceID: string | undefined
) => {
    if (!callTraceID) return
    const callNotes = selectCallNotes(state)
    const filteredCallNotes = Object.values(callNotes).filter(
        callNote => callNote.callTraceID === callTraceID
    )
    return filteredCallNotes
}

/**
 * Obtain a specific call note (index based),
 * from the specified activity callTraceID
 * @param state
 * @param callTraceID
 * @param idx
 * @returns
 */
export const selectCallNoteContent = (
    state: CallNoteSliceRootState,
    callTraceID: string | undefined,
    idx: number = 0
) => {
    if (!callTraceID) return
    const callNotes = selectActivityCallNotes(state, callTraceID)
    return callNotes?.[idx]?.savedNote || callNotes?.[idx]?.note || ''
}

export const { addCallNote, updateCallNote, removeCallNote } = callNoteSlice.actions
