export function getUnixTimestampsForDay(date: Date) {
    const startOfDay = new Date(date)
    startOfDay.setHours(0, 0, 0, 0) // Set time to 00:00

    const endOfDay = new Date(date)
    endOfDay.setHours(23, 59, 59, 0) // Set time to 23:59:59

    return {
        timeRangeStart: (startOfDay.getTime() / 1000).toString(),
        timeRangeEnd: (endOfDay.getTime() / 1000).toString()
    }
}

export function isSameDate(date1, date2) {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    )
}

export function getUnixTimestampForChosenDateAndTime(chosenDate: Date, time: string): number {
    if (!chosenDate) return Date.now() // Return current Unix timestamp if chosenDate is not provided
    const newDate = new Date(chosenDate)

    const [hours, minutes] = time.split(':')

    newDate.setHours(parseInt(hours))
    newDate.setMinutes(parseInt(minutes))
    newDate.setSeconds(0)
    newDate.setMilliseconds(0) // Ensure milliseconds are set to 0 for Unix timestamp

    return newDate.getTime() / 1000 // Return Unix timestamp (seconds since January 1, 1970)
}

export function getDateFromUnixTimestamp(startTime: number): Date {
    const startDate = new Date(startTime * 1000) // Convert Unix timestamp to milliseconds
    startDate.setHours(0, 0, 0, 0) // Set hours, minutes, seconds, and milliseconds to 0
    return startDate
}

export function getMillisecondsUnix(time: number | undefined) {
    if (!time) return 0
    return time * 1000
}
