import { useDisclosure } from '@chakra-ui/react'
import { useFormContext } from '@missionlabs/react'
import { isUser } from '@missionlabs/types'
import { isMenu, isTeam } from '@missionlabs/utils'
import { UTMDrawer, UTMDrawerProps } from 'features/settings/components/UTMDrawer'
import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AddEditTeamFields } from './AddEditTeam/AddEditTeamForm'

export const AssignToTeamDrawer: FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const state = location.state as string | null

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const { setValue, watch } = useFormContext<AddEditTeamFields>()
    const redirect = watch('unansweredRedirectInternal')

    const handleSubmit: UTMDrawerProps['onSubmit'] = data => {
        if (!data || !state) return

        const values: Record<string, string> = {}
        values.display = isUser(data) ? data.fullName : data.name

        let idKey = ''
        if (isMenu(data)) idKey = 'menuID'
        if (isTeam(data)) idKey = 'teamID'
        if (isUser(data)) idKey = 'userID'
        values[idKey] = data.ID

        setValue(state as any, values)
        return onClose()
    }

    function handleTransition() {
        navigate(-1)
    }

    useEffect(() => {
        if (!state) navigate('/admin/teams')
    }, [navigate, onClose, state])

    return (
        <UTMDrawer
            defaultValue={redirect?.menuID ?? redirect?.teamID ?? redirect?.userID}
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={handleTransition}
            onSubmit={handleSubmit}
        />
    )
}
