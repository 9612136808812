import { useMatches } from 'kbar'
import { ComponentProps, useCallback, useMemo } from 'react'

import { KBarResults } from './KBarResults'
import { filterUniqueResults, renderResultItem } from './utils'

export function SearchResults() {
    const { results, rootActionId } = useMatches()

    // By default, KBar will show all results, including duplicates. This is because
    // the same action can be registered in multiple places. This will filter
    // out duplicates so that each action is only shown once.
    const uniqueResults = useMemo(() => filterUniqueResults(results), [results])

    const onRender: ComponentProps<typeof KBarResults>['onRender'] = useCallback(
        ({ item, active }) => renderResultItem({ item, active }, rootActionId),
        [rootActionId]
    )

    return <KBarResults items={uniqueResults} onRender={onRender} />
}
