import { IPageGroup } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildPageGroupsApi = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'pageGroupsAPI',
        tagTypes: ['PageGroups'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getPageGroups: builder.query<IPageGroup[], void>({
                query: () => '/pagegroups',
                transformResponse: (response: { data: IPageGroup[] }) => response.data,
                providesTags: result => {
                    return result
                        ? [
                              ...result.map(group => ({
                                  type: 'PageGroups' as const,
                                  id: group.ID
                              })),
                              'PageGroups'
                          ]
                        : ['PageGroups']
                }
            })
        })
    })

    return { api, ...api }
}
