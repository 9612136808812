import { TableContainer } from '@chakra-ui/react'
import { Table } from '@missionlabs/react'
import { AnalyticsReport } from '@missionlabs/types'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NoResultsState } from 'shared/components/NoResultsState'

import { AnalyticsReportsTableDownload } from './AnalyticsReportsTableDownload'

type AnalyticsReportsTableProps = {
    reportsData?: AnalyticsReport[]
    isLoading: boolean
}

const columnHelper = createColumnHelper<AnalyticsReport>()

export const AnalyticsReportsTable: FC<AnalyticsReportsTableProps> = ({
    reportsData = [],
    isLoading
}) => {
    const { t } = useTranslation()

    const columns = useMemo(
        () => [
            columnHelper.accessor('name', {
                header: t('analytics.reports.table.name')
            }),
            columnHelper.accessor('type', {
                header: t('analytics.reports.table.type')
            }),
            columnHelper.accessor('created', {
                header: t('analytics.reports.table.created'),
                cell: data => format(new Date(data.getValue()), 'dd/MM/yyyy k:mm')
            }),
            columnHelper.display({
                cell: ctx => <AnalyticsReportsTableDownload data={ctx.row.original} />,
                id: 'download',
                header: t('analytics.reports.table.download')
            })
        ],
        [t]
    )

    if (reportsData.length === 0) {
        return <NoResultsState />
    }

    return (
        <TableContainer w="100%">
            <Table
                isLoading={isLoading}
                columns={columns}
                data={reportsData}
                sorting={[{ id: 'created', desc: true }]}
            />
        </TableContainer>
    )
}
