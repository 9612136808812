import { DirectoryEntry } from '@missionlabs/types'
import { useMemo } from 'react'
import { useContactNumbersDropdown } from 'shared/hooks/useContactNumbersDropdown'

/**
 * Get a list of numbers that can be replied to
 * @returns list of numbers
 */
export const useGetActivityContactNumberOptions = (
    contact?: DirectoryEntry,
    contactNumber?: string
) => {
    const contactMobileNumbers = useContactNumbersDropdown({ contact, mobileOnly: false })

    const numbers = useMemo(() => {
        return contactNumber
            ? [{ value: contactNumber, label: contactNumber }]
            : contactMobileNumbers.map(number => ({
                  label: number.label,
                  value: number.value
              }))
    }, [contactMobileNumbers, contactNumber])
    return numbers
}
