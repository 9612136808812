import { useDisclosure } from '@chakra-ui/react'
import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from '@missionlabs/react'
import { AddWhatsAppNumbersForm } from 'features/admin/pages/WhatsApp/AddNumber/AddWhatsAppNumbersForm'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export interface AddWhatsAppNumberDrawerProps {}

export const AddWhatsAppNumberDrawer: FC<AddWhatsAppNumberDrawerProps> = () => {
    const navigate = useNavigate()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const { t } = useTranslation()

    const handleTransition = (): void => {
        navigate('/admin/whatsapp')
    }

    return (
        <Drawer size="lg" isOpen={isOpen} onClose={onClose} onCloseComplete={handleTransition}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader pb="0 !important">{t('admin.whatsapp.add.header')}</DrawerHeader>
                <DrawerBody>
                    <AddWhatsAppNumbersForm onClose={onClose} />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
