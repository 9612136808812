import {
    AuthenticatedUser,
    BaseURL,
    loginSuccess,
    selectAuthEnvironment,
    setAPIBaseURL,
    setUser,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { populateUrl } from '@missionlabs/utils'
import { useNavigate } from 'react-router-dom'
import { useSuperLoginMutation } from 'shared/store'

import { useSetUpDestination } from './useSetUpDestination'

export const useSuperLogin = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [superLogin, { isLoading }] = useSuperLoginMutation()
    const setUpDestination = useSetUpDestination()
    const { country, dataCentre, environment, product } = useSelector(selectAuthEnvironment)

    const pbxLogin = async (login: {
        email: string
        token: string
    }): Promise<AuthenticatedUser> => {
        dispatch(
            setAPIBaseURL(populateUrl(BaseURL.API, { country, dataCentre, environment, product }))
        )
        const { clientID, userID, ID } = await superLogin(login).unwrap()
        const user: AuthenticatedUser = {
            token: ID,
            userID,
            clientID
        }

        dispatch(setUser(user))

        await setUpDestination(user)
        dispatch(loginSuccess())
        navigate('/home')

        return user
    }

    const loginAsSuper = async (hash: string) => {
        if (isLoading) return
        const [email, token] = window.atob(hash).split(':')
        await pbxLogin({ email, token })
    }

    return { loginAsSuper, isLoading }
}
