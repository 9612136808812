import { DateFilterParam, UnreadActivity } from '@missionlabs/types'
import {
    endOfDay,
    endOfToday,
    endOfWeek,
    endOfYesterday,
    format,
    isThisWeek,
    isToday,
    isYesterday,
    startOfDay,
    startOfToday,
    startOfWeek,
    startOfYesterday
} from 'date-fns'
import { TFunction } from 'i18next'
import { UseGetUserActivitiesOptions } from 'shared/hooks/useGetUserActivities'

import {
    ActivityFilters,
    AppliedFilterLabel,
    SelectedDateOption,
    SelectedDatePayload
} from '../types'

export const initialFilterState: ActivityFilters = {
    directories: [],
    directions: [],
    type: [],
    status: [],
    searchTerm: '',
    [DateFilterParam.FROM]: undefined,
    [DateFilterParam.TO]: undefined
}

/**
 * Initial state for the main activity feed filters
 * should come from the state of activity sidebar filters,
 * which stores it's state in search params.
 *
 * We only call this when first setting up the reducer as
 * we don't want to keep the state in sync, but merely clone
 * the state initially.
 */
export function getInitialFilters(): ActivityFilters {
    const params = new URLSearchParams(window.location.search)

    // When viewing activities in the contact feed tab, we don't want to apply filters.
    // There's also a possibility of the contacts page "type" filter interfering with the activity feed filters.
    if (window.location.pathname.includes('contacts')) return initialFilterState

    return {
        ...initialFilterState,
        directions: params.get('directions')?.split(',') ?? [],
        directories: params.get('directories')?.split(',') ?? [],
        type: params.get('type')?.split(',') ?? [],
        status: params.get('status')?.split(',') ?? [],
        [DateFilterParam.FROM]: params.get(DateFilterParam.FROM) ?? undefined,
        [DateFilterParam.TO]: params.get(DateFilterParam.TO) ?? undefined
    }
}

/** Given a selected date option, get the "sinceTime" and "fromTime" date values */
export function getDateRange(
    state: Pick<ActivityFilters, DateFilterParam.FROM | DateFilterParam.TO>,
    payload: SelectedDatePayload
): Pick<ActivityFilters, DateFilterParam.FROM | DateFilterParam.TO> {
    switch (payload.selectedOption) {
        case 'today': {
            return {
                sinceTime: startOfToday().toISOString(),
                fromTime: endOfToday().toISOString()
            }
        }
        case 'yesterday': {
            return {
                sinceTime: startOfYesterday().toISOString(),
                fromTime: endOfYesterday().toISOString()
            }
        }
        case 'this_week': {
            const options = { weekStartsOn: 1 as const /*Monday*/ }
            return {
                sinceTime: startOfWeek(new Date(), options).toISOString(),
                fromTime: endOfWeek(new Date(), options).toISOString()
            }
        }
        case 'custom': {
            const sinceTime = payload.from || state.sinceTime
            const fromTime = payload.to || state.fromTime || endOfToday().toISOString()
            return { sinceTime, fromTime }
        }
        default: {
            return { sinceTime: undefined, fromTime: undefined }
        }
    }
}

/** Given "from" and "to" date values, get the relevant SelectedDateOption */
export function getDateRangeLabel(
    fromStr?: string,
    toStr?: string
): SelectedDateOption | undefined {
    if (!fromStr || !toStr) return
    try {
        const from = new Date(fromStr)
        const to = new Date(toStr)

        if (isToday(from) && isToday(to)) return 'today'
        if (isYesterday(from) && isYesterday(to)) return 'yesterday'

        const MONDAY = 1 as const
        const options = { weekStartsOn: MONDAY }
        if (isThisWeek(from, options) && isThisWeek(to, options)) return 'this_week'

        return 'custom'
    } catch (e) {
        console.error(`Unable to parse date. From ${fromStr}. To ${toStr}`)
        return
    }
}

export const getFilterLabels = (t: TFunction) => ({
    date: {
        today: t('activities.filters.today'),
        yesterday: t('activities.filters.yesterday'),
        this_week: t('activities.filters.thisWeek'),
        custom: t('activities.filters.specificDate')
    },
    type: {
        sms: t('activities.filters.sms'),
        whatsapps: t('activities.filters.whatsapp'),
        voicemail: t('activities.filters.voicemail'),
        missed_calls: t('activities.filters.missedCalls'),
        voice_calls: t('activities.filters.voiceCalls'),
        meetings: t('activities.filters.videoCalls')
    },
    directions: {
        incoming: t('activities.filters.inbound'),
        outgoing: t('activities.filters.outbound')
    },
    status: {
        isUnread: t('activities.filters.new'),
        flagged: t('activities.filters.flagged')
    }
})

/**
 * Generate display labels to show applied filters in ActivityFilterDisplay
 * @example "New", "Missed Calls", "Voicemail"
 */
export function generateFilterLabels(filters: ActivityFilters, t: TFunction): AppliedFilterLabel[] {
    const labels = [] as AppliedFilterLabel[]
    const filterLabels = getFilterLabels(t)

    const ignoredFilterNames: string[] = [
        // Skip the "from" and "to" filters as they're handled by `generateDateLabel`
        DateFilterParam.FROM,
        DateFilterParam.TO
    ]

    for (const [filterName, value] of Object.entries(filters)) {
        const values = Array.isArray(value) ? value : [value]
        for (const v of values) {
            if (ignoredFilterNames.includes(filterName)) continue
            if (value) {
                const label = filterLabels[filterName]?.[`${v}`] ?? `${v}`
                labels.push({ filterName, value: `${v}`, label })
            }
        }
    }

    return labels
}

/**
 * Generate display label for date filter.
 * For custom ranges, the `to` date will default to today if not applied.
 * @example "Today", "Yesterday", "08/03/2024 - 12/03/2024"
 */
export function generateDateLabel(
    option: SelectedDateOption | undefined,
    filters: ActivityFilters,
    t: TFunction
) {
    if (!option) return
    if (option !== 'custom') {
        const label = getFilterLabels(t).date[option]
        return { filterName: 'date', label, value: option }
    }

    const fromParam = filters[DateFilterParam.FROM] as string
    const fromTime = new Date(fromParam).toLocaleDateString()

    const toParam = filters[DateFilterParam.TO] as string

    if (fromParam && toParam) {
        const to = new Date(toParam).toLocaleDateString()
        return { filterName: 'date', label: `${fromTime} - ${to}`, value: option }
    }

    if (fromParam && !toParam) {
        // Use today as the "to" day if one doesn't exist
        const to = new Date().toLocaleDateString()
        return { filterName: 'date', label: `${fromTime} - ${to}`, value: option }
    }
}

export function hasAppliedFilters(filters: ActivityFilters, excludeSearch = true): boolean {
    return Object.entries(filters).some(([filterName, filter]) => {
        if (excludeSearch && filterName === 'searchTerm') return false
        if (Array.isArray(filter)) return filter.length > 0
        return !!filter
    })
}

export const formatDate = (d?: string | Date): string => {
    if (!d) return ''
    let date = d
    if (typeof date === 'string') date = new Date(d)
    return format(date, 'yyyy-MM-dd')
}

export const getFrom = (value: string) => (value ? startOfDay(new Date(value)).toISOString() : '')
export const getTo = (value: string) => (value ? endOfDay(new Date(value)).toISOString() : '')

/** Given a quick-filter name, get the relevant filter values */
export function getQuickFilterValues(quickFilter: UnreadActivity): UseGetUserActivitiesOptions {
    switch (quickFilter) {
        case UnreadActivity.FLAGGED:
            return { type: [], status: ['flagged'] }
        case UnreadActivity.VOICEMAILS:
            return { type: ['voicemail'], status: [] }
        case UnreadActivity.MISSED_CALLS:
            return { type: ['missed_calls'], status: [] }
        case UnreadActivity.SMS:
            return { type: ['sms'], status: [] }
        case UnreadActivity.WHATSAPPS:
            return { type: ['whatsapps'], status: [] }
        default:
            return { type: [], status: [] }
    }
}
