import { Box, ButtonGroup, useColorMode } from '@chakra-ui/react'
import { IconButton } from '@missionlabs/react'
import { SummaryIcon, SummaryIcon2 } from '@missionlabs/react/circleloop'
import { AnalyticsDataPoints } from '@missionlabs/types'
import { FC } from 'react'
import { NoResultsState } from 'shared/components/NoResultsState'

import { useAnalytics } from '../../hooks/useAnalytics'
import { AnalyticsBarChart } from './AnalyticsBarChart'
import AnalyticsGroupControls from './AnalyticsGroupControls'
import { AnalyticsLineGraph } from './AnalyticsLineGraph'

type AnalyticsGraphViewProps = {
    data: AnalyticsDataPoints[] | undefined
}

export const AnalyticsGraphView: FC<AnalyticsGraphViewProps> = ({ data }) => {
    const {
        period,
        group,
        graph: { graphType, setGraphType }
    } = useAnalytics()

    const { colorMode } = useColorMode()

    if (!data?.length) {
        return (
            <div data-testid="analytics-graph-view-no-results">
                <NoResultsState />
            </div>
        )
    }

    return (
        <Box position="relative">
            {graphType === 'bar' && <AnalyticsBarChart data={data} group={group} />}
            {graphType === 'line' && <AnalyticsLineGraph data={data} period={period} />}
            <Box position="absolute" right="8" top="8">
                <ButtonGroup isAttached>
                    <IconButton
                        variant="outline"
                        id="analytics__graph-view-line"
                        backgroundColor={`${colorMode}.tones.periwinkle`}
                        color={`${colorMode}.tones.stormGrey`}
                        _active={{
                            bg: `#fff`,
                            color: `${colorMode}.tones.midnightExpress`
                        }}
                        icon={<SummaryIcon w="16px" h="16px" />}
                        isActive={graphType === 'line'}
                        onClick={() => setGraphType('line')}
                        aria-label="Line Chart"
                    />
                    <IconButton
                        variant="outline"
                        id="analytics__graph-view-bar"
                        backgroundColor={`${colorMode}.tones.periwinkle`}
                        color={`${colorMode}.tones.stormGrey`}
                        _active={{
                            bg: `#fff`,
                            color: `${colorMode}.tones.midnightExpress`
                        }}
                        icon={<SummaryIcon2 w="16px" h="16px" />}
                        isActive={graphType === 'bar'}
                        onClick={() => setGraphType('bar')}
                        aria-label="Bar Chart"
                        data-testid="analytics-graph-view-bar-button"
                    />
                </ButtonGroup>
            </Box>
            <Box position="absolute" left="16" top="8">
                <AnalyticsGroupControls />
            </Box>
        </Box>
    )
}
