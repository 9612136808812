import { useDisclosure } from '@chakra-ui/react'
import {
    Body,
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    useToast
} from '@missionlabs/react'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGetNumberByID } from 'shared/hooks/useGetNumberByID'
import { useUnassignNumberMutation } from 'shared/store'

interface UnassignNumberDialogProps {}

export const UnassignNumberDialog: FC<UnassignNumberDialogProps> = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { toast } = useToast()

    const { t } = useTranslation()
    const { id: numberID = '' } = useParams()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    const number = useGetNumberByID(numberID)
    const ref = useRef<HTMLButtonElement>(null)

    const [unassignNumber, { isLoading: isUnassigning }] = useUnassignNumberMutation()

    const handleTransition = () => {
        const end = location.pathname.split('/').pop()
        if (end === 'unassign') navigate('/admin/numbers')
    }

    const handleConfirm = async () => {
        try {
            if (!number) throw Error(`Can't unassign number: ${number}`)
            await unassignNumber(number.ID).unwrap()
        } catch {
            toast({
                title: t('numbers.unassignError'),
                status: 'error'
            })
        } finally {
            onClose()
        }
    }

    if (!number) return null

    return (
        <Dialog
            leastDestructiveRef={ref}
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={handleTransition}
        >
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>
                    {t('numbers.unassignDialog.heading', { number: number.numberE164 })}
                </DialogHeader>
                <DialogBody>
                    <Body>{t('numbers.unassignDialog.body')}</Body>
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('numbers.unassignDialog.unassignNumber'),
                        variant: 'negatory',
                        onClick: handleConfirm
                    }}
                    isLoading={isUnassigning}
                />
            </DialogContent>
        </Dialog>
    )
}
