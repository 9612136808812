import { chakra, shouldForwardProp as _shouldForwardProp } from '@chakra-ui/react'
import { isValidMotionProp, motion } from 'framer-motion'
import { ComponentProps } from 'react'

/**
 * Allow motion props and non-Chakra props to be forwarded.
 * https://chakra-ui.com/getting-started/with-framer#chakra-factory
 */
const shouldForwardProp = prop => isValidMotionProp(prop) || _shouldForwardProp(prop)

export const AnimatedSpan = chakra(motion.span, { shouldForwardProp })
export const AnimatedDiv = chakra(motion.span, { shouldForwardProp })

type FlashProps = ComponentProps<typeof AnimatedSpan> & {
    duration?: number
    repeat?: number
}

export const Flash = ({ duration = 1.5, repeat = Infinity, ...props }: FlashProps) => (
    <AnimatedSpan
        {...props}
        animate={{ opacity: [1, 0.25, 1] }}
        // @ts-ignore no problem in operation, although type error appears.
        transition={{ duration, repeat, ease: 'linear', repeatType: 'loop' }}
    />
)
