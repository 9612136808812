import { CallDetails, CallStatus, NWayMember, ParkedCall } from '@missionlabs/browser-calling'
import { DirectoryEntry, TeamVmNotificationPayload } from '@missionlabs/types'
import { isFAC } from '@missionlabs/utils'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Call = {
    callTraceID: string
    userID?: string
    teamID?: string
    direction?: 'inbound' | 'outbound'
    status: CallStatus
    remoteNumber: string
    contactName?: string
    localNumber?: string
    duration?: number
    muted?: boolean
    recordingState?: 'ON' | 'OFF' | 'PAUSED'
    startTimestamp?: number
    displayName?: string
    teamName?: string
    clientName?: string
    nWayMembers?: NWayMember[]
    companyName?: string
    pageGroupName?: string
    userGroupName?: string
    isTransfer?: boolean
    transfereeContact?: DirectoryEntry
    transfereeNumber?: string
    transfererContactName?: string
    transfererCompanyName?: string
    transfererNumber?: string
    transferToContact?: DirectoryEntry
    transferType?: TransferType
    alphaTag?: string
}

export type TransferType = 'attended' | 'blind'

type UpdatedCall = Omit<Call, 'callTraceID' | 'webSocket'>

export type CallState = {
    calls: Call[]
    parkedCalls: ParkedCall[]
    callGroupMessages: TeamVmNotificationPayload[]
    // circleloop
    presentationNumber?: string
    focusedCallTraceID: string | undefined
}

const initialState: CallState = {
    calls: [],
    parkedCalls: [],
    callGroupMessages: [],
    focusedCallTraceID: undefined
}

export const callSlice = createSlice({
    name: 'callSlice',
    initialState,
    reducers: {
        focusCall: (state, action: PayloadAction<{ callTraceID: string | undefined }>) => {
            state.focusedCallTraceID = action.payload?.callTraceID
        },
        addCall: (state, action: PayloadAction<Call>) => {
            state.calls.push(action.payload)
        },
        removeCall: (state, action: PayloadAction<{ callTraceID: string }>) => {
            state.calls = state.calls.filter(
                call => call.callTraceID !== action.payload?.callTraceID
            )
        },
        updateCall(
            state,
            action: PayloadAction<{ updatedCall: UpdatedCall; callTraceID: string }>
        ) {
            const { callTraceID, updatedCall } = action.payload
            state.calls = state.calls.map(call =>
                call.callTraceID === callTraceID
                    ? {
                          ...call,
                          ...updatedCall,
                          startTimestamp: call.startTimestamp ?? updatedCall.startTimestamp
                      }
                    : call
            )
        },
        updateParkedCalls(state, action: PayloadAction<{ parkedCalls: ParkedCall[] }>) {
            state.parkedCalls = action.payload.parkedCalls
        },
        updateCallGroupMessages(state, action: PayloadAction<TeamVmNotificationPayload>) {
            state.callGroupMessages.push({ ...action.payload, callTraceID: crypto.randomUUID() })
        },
        updateDetails(
            state,
            action: PayloadAction<{ callTraceID: string; callDetails: CallDetails }>
        ) {
            const { callTraceID, callDetails } = action.payload
            state.calls = state.calls.map(call =>
                call.callTraceID === callTraceID
                    ? {
                          ...call,
                          ...callDetails
                      }
                    : call
            )
        },
        setPresentationNumber(state, action: PayloadAction<string | undefined>) {
            state.presentationNumber = action.payload
        }
    }
})

export type CallSliceRootState = {
    callSlice: ReturnType<typeof callSlice.getInitialState>
}

export const selectIncomingCalls = (state: CallSliceRootState) => {
    return state.callSlice.calls.filter(
        call => call.status === 'RINGING_LOCAL' && call.direction === 'inbound'
    )
}

export const selectConnectedCalls = (state: CallSliceRootState) => {
    return state.callSlice.calls.filter(call => ['CONNECTED', 'ON_HOLD'].includes(call.status))
}

export const selectConnectedCall = (state: CallSliceRootState) => {
    return state.callSlice.calls.find(call => call.status === 'CONNECTED')
}

export const selectConnectedCallOrFAC = (state: CallSliceRootState) => {
    const connected = state.callSlice.calls.find(call => call.status === 'CONNECTED')
    if (connected) return connected

    const fac = state.callSlice.calls.find(call => isFAC(call.remoteNumber))
    if (fac) return fac
}

export const selectAnsweredCalls = (state: CallSliceRootState) => {
    return state.callSlice.calls.filter(call => call.status === 'ANSWERED')
}

export const selectCallStatus = (state: CallSliceRootState, callTraceID: string): CallStatus => {
    const call = state.callSlice.calls.find(call => call.callTraceID === callTraceID)
    return call?.status || 'NO_CALL'
}

export const selectCall = (state: CallSliceRootState, callTradeID: string) =>
    state.callSlice.calls.find(call => call.callTraceID === callTradeID)

export const selectCalls = (state: CallSliceRootState) => state.callSlice.calls

export const selectParkedCalls = (state: CallSliceRootState) => state.callSlice.parkedCalls

export const selectCallGroupMessages = (state: CallSliceRootState) =>
    state.callSlice.callGroupMessages

export const selectPresentationNumber = (state: CallSliceRootState) =>
    state.callSlice.presentationNumber

export const selectFocusedCall = (state: CallSliceRootState) => {
    return state.callSlice.calls.find(
        call => call.callTraceID === state.callSlice.focusedCallTraceID
    )
}

export const {
    addCall,
    removeCall,
    updateCall,
    updateDetails,
    updateParkedCalls,
    updateCallGroupMessages,
    setPresentationNumber,
    focusCall
} = callSlice.actions
