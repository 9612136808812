import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react'
import { UserActivity } from '@missionlabs/types'
import { ActivityFooterMessageType } from 'features/activity/types'
import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react'

interface IActivityFooterContext extends UseDisclosureReturn {
    messageType: ActivityFooterMessageType
    activity: UserActivity | undefined
    setMessageType: (type: ActivityFooterMessageType) => void
    setActivity: (activity: UserActivity) => void
}

const ActivityFooterContext = createContext<IActivityFooterContext | null>(null)

export const ActivityFooterProvider = ({ children }: PropsWithChildren) => {
    const [messageType, setMessageType] = useState<ActivityFooterMessageType>(
        ActivityFooterMessageType.SMS
    )

    const [activity, setActivity] = useState<UserActivity>()

    const drawer = useDisclosure()

    const contextValue = useMemo(
        () => ({
            messageType,
            activity,
            setMessageType,
            setActivity,
            ...drawer
        }),
        [drawer, messageType, activity, setMessageType, setActivity]
    )

    return (
        <ActivityFooterContext.Provider value={contextValue}>
            {children}
        </ActivityFooterContext.Provider>
    )
}

export const useActivityFooterState = () => {
    const context = useContext(ActivityFooterContext)
    if (!context)
        throw new Error('useActivityFooterState must be used within a ActivityFooterProvider')
    return context
}
