import { buttonAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'

const disabledStyles = {
    bg: 'light.tones.stormGrey',
    opacity: 0.5,
    cursor: 'not-allowed',
    _hover: { bg: 'light.tones.stormGrey' }
}

const defaultKeyStyles = {
    display: 'inline-block',
    boxSizing: 'border-box',
    textAlign: 'center',
    width: '64px',
    height: '64px',
    borderRadius: '100%',
    cursor: 'pointer',
    outline: 'none'
}
const { definePartsStyle } = createMultiStyleConfigHelpers([...buttonAnatomy.keys])

export const DiallerKeyStyles: ComponentStyleConfig = {
    baseStyle: definePartsStyle({
        container: {
            ...defaultKeyStyles,
            bg: 'light.tones.whiteLilac',
            _hover: {
                bg: 'light.tones.periwinkle'
            },
            _disabled: disabledStyles
        },
        label: {
            color: 'light.tones.midnightExpress',
            fontSize: '24px',
            fontWeight: 800,
            lineHeight: '22px'
        },
        subLabel: {
            color: 'light.tones.stormGrey',
            fontSize: '12px',
            fontWeight: 500
        },
        callKey: {
            ...defaultKeyStyles,
            bg: 'light.avatar.aqua',
            border: 'none',
            _hover: { bg: 'light.alerts.algae' },
            _disabled: disabledStyles
        }
    })
}

export const DialPadStyles: ComponentStyleConfig = {
    baseStyle: definePartsStyle({
        container: {
            width: 'full',
            maxW: 'fit-content',
            gap: '8px',
            gridTemplate: 'repeat(5, 1fr) / repeat(3, 1fr)'
        }
    })
}
