import { AlertDialog, AlertDialogProps } from '@chakra-ui/react'
import { FC, PropsWithChildren } from 'react'

export type DialogProps = AlertDialogProps

export const Dialog: FC<PropsWithChildren<DialogProps>> = ({
    isCentered = true,
    children,
    ...props
}) => {
    return (
        <AlertDialog {...props} isCentered={isCentered} variant="dialog">
            {children}
        </AlertDialog>
    )
}
