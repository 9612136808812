import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const HelpIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HelpIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 18.75C5.15625 18.75 1.25 14.8438 1.25 10C1.25 5.19531 5.15625 1.25 10 1.25C14.8047 1.25 18.75 5.19531 18.75 10C18.75 14.8438 14.8047 18.75 10 18.75ZM9.375 13.4375C8.82812 13.4375 8.4375 13.8672 8.4375 14.375C8.4375 14.9219 8.82812 15.3125 9.375 15.3125C9.88281 15.3125 10.3125 14.9219 10.3125 14.375C10.3125 13.8672 9.88281 13.4375 9.375 13.4375ZM11.1328 5H8.82812C7.38281 5 6.25 6.17188 6.25 7.61719V7.96875C6.25 8.32031 6.52344 8.59375 6.875 8.59375C7.1875 8.59375 7.5 8.32031 7.5 7.96875V7.61719C7.5 6.875 8.08594 6.25 8.82812 6.25H11.1328C11.875 6.25 12.5 6.875 12.5 7.61719C12.5 8.08594 12.2266 8.55469 11.7969 8.78906L9.45312 9.96094C9.02344 10.1953 8.75 10.6641 8.75 11.1719V11.875C8.75 12.2266 9.02344 12.5 9.375 12.5C9.6875 12.5 10 12.2266 10 11.875V11.1719C10 11.1328 10 11.0938 10.0391 11.0547L12.3828 9.88281C13.2031 9.41406 13.75 8.55469 13.75 7.61719C13.75 6.17188 12.5781 5 11.1328 5Z"
            fill="currentColor"
        />
    )
})

export const HelpIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HelpIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 18.125C5.50781 18.125 1.875 14.4922 1.875 10C1.875 5.54688 5.50781 1.875 10 1.875C14.4531 1.875 18.125 5.54688 18.125 10C18.125 14.4922 14.4531 18.125 10 18.125ZM10 13.125C9.29688 13.125 8.75 13.6719 8.75 14.375C8.75 15.0781 9.25781 15.625 10 15.625C10.6641 15.625 11.25 15.0781 11.25 14.375C11.25 13.6719 10.6641 13.125 10 13.125ZM11.2891 5H9.29688C7.77344 5 6.5625 6.21094 6.5625 7.73438C6.5625 8.24219 6.99219 8.67188 7.5 8.67188C8.00781 8.67188 8.4375 8.24219 8.4375 7.73438C8.4375 7.26562 8.78906 6.875 9.25781 6.875H11.25C11.7578 6.875 12.1875 7.26562 12.1875 7.73438C12.1875 8.04688 12.0312 8.32031 11.7578 8.47656L9.53125 9.80469C9.21875 10 9.0625 10.3125 9.0625 10.625V11.25C9.0625 11.7578 9.49219 12.1875 10 12.1875C10.5078 12.1875 10.9375 11.7578 10.9375 11.25V11.1719L12.6953 10.0781C13.5156 9.57031 14.0234 8.67188 14.0234 7.73438C14.0625 6.21094 12.8516 5 11.2891 5Z"
            fill="currentColor"
        />
    )
})

export const HelpIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HelpIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 15.625C9.29688 15.625 8.75 15.0781 8.75 14.375C8.75 13.6719 9.25781 13.125 10 13.125C10.6641 13.125 11.25 13.6719 11.25 14.375C11.25 15.0781 10.6641 15.625 10 15.625ZM12.6953 10.0781L10.9375 11.1719V11.25C10.9375 11.7578 10.5078 12.1875 10 12.1875C9.49219 12.1875 9.0625 11.7578 9.0625 11.25V10.625C9.0625 10.3125 9.21875 10 9.53125 9.80469L11.7578 8.47656C12.0312 8.32031 12.1875 8.04688 12.1875 7.73438C12.1875 7.26562 11.7578 6.875 11.2891 6.875H9.29688C8.78906 6.875 8.4375 7.26562 8.4375 7.73438C8.4375 8.24219 8.00781 8.67188 7.5 8.67188C6.99219 8.67188 6.5625 8.24219 6.5625 7.73438C6.5625 6.21094 7.77344 5 9.25781 5H11.25C12.8516 5 14.0625 6.21094 14.0625 7.73438C14.0625 8.67188 13.5547 9.57031 12.6953 10.0781Z"
            fill="currentColor"
        />
    )
})
