import { ContactGroup, DirectoryEntry, SingleState } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'
import {
    CreateContactGroupQueryArgs,
    CreateContactQueryArgs,
    DeleteContactQueryArgs,
    GetContactGroupQueryArgs,
    SearchQueryArgs,
    UpdateContactGroupQueryArgs,
    UpdateContactQueryArgs,
    UserFavouriteContactsResponse
} from './types/contactApi'

export * from './types/contactApi'

export const buildContactsAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'contactsAPI',
        tagTypes: [
            'Contacts',
            'ContactGroups',
            'FavouriteContacts',
            'ContactSources',
            'ContactPresence'
        ],
        baseQuery: baseQuery,
        endpoints: builder => ({
            contactsSearch: builder.query<DirectoryEntry[], SearchQueryArgs>({
                query: args => {
                    return {
                        url: '/search/contacts',
                        method: 'GET',
                        params: {
                            searchTerm: args.searchTerm,
                            max: args.maxResults ?? 10000,
                            sortBy: args.sortBy,
                            from: args.from,
                            filter: args.filter ?? [],
                            sources: args.sources ?? [],
                            myTeamsOnly: args.myTeamsOnly,
                            favouritesOnly: args.favouritesOnly
                        }
                    }
                }
            }),
            contactGroupSearch: builder.query<
                ContactGroup[],
                { searchTerm: string; userID: string }
            >({
                query: args => {
                    const params = new URLSearchParams()
                    params.set('searchTerm', args.searchTerm ?? '')

                    return {
                        url: `/users/${args.userID}/search/contactGroups?${params.toString()}`,
                        method: 'GET'
                    }
                },
                transformResponse: (response: { data: ContactGroup[] }) => response.data
            }),
            getContact: builder.query<DirectoryEntry, string>({
                query: id => `/contacts/${id}`,
                providesTags: (result, error, id) => [{ type: 'Contacts', id }]
            }),
            createUserContact: builder.mutation<DirectoryEntry, CreateContactQueryArgs>({
                query: ({ userID, entry }) => ({
                    url: `/users/${userID}/contacts`,
                    method: 'POST',
                    body: entry
                }),
                onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                    try {
                        await queryFulfilled
                        setTimeout(() => {
                            dispatch({ type: 'pagination/refetch', payload: 'contacts' })
                        }, 1000)
                    } catch {
                        dispatch(api.util.invalidateTags(['Contacts']))
                    }
                },
                invalidatesTags: ['Contacts']
            }),
            updateUserContact: builder.mutation<DirectoryEntry, UpdateContactQueryArgs>({
                query: ({ ID, userID, entry }) => ({
                    url: `/users/${userID}/contacts/${ID}`,
                    method: 'PUT',
                    body: entry
                }),
                // optimistically update cache entry
                onQueryStarted: async ({ ID, entry }, { dispatch, queryFulfilled }) => {
                    dispatch(
                        api.util.updateQueryData('getContact', ID, draft => {
                            Object.assign(draft, {
                                ...entry,
                                fullName: `${entry.firstName} ${entry.lastName}`
                            })
                        })
                    )

                    try {
                        await queryFulfilled
                        setTimeout(() => {
                            dispatch({ type: 'pagination/refetch', payload: 'contacts' })
                        }, 1000)
                    } catch {
                        dispatch(api.util.invalidateTags(['Contacts', 'FavouriteContacts']))
                    }
                }
            }),
            deleteUserContact: builder.mutation<string, DeleteContactQueryArgs>({
                query: ({ ID, userID }) => ({
                    url: `/users/${userID}/contacts/${ID}`,
                    method: 'DELETE'
                }),
                // optimistically update cache entry
                onQueryStarted: async ({ ID, userID }, { dispatch, queryFulfilled }) => {
                    dispatch(
                        api.util.updateQueryData('getContact', ID, _ => {
                            return null as any // Forcefully returning `null` allows us to mimic deletion of the cache entry.
                        })
                    )

                    dispatch(
                        api.util.updateQueryData('getUserFavouriteContacts', userID, draft => ({
                            ...draft,
                            contacts: draft.contacts.filter(item => item.ID !== ID)
                        }))
                    )

                    try {
                        await queryFulfilled
                        // we are unable to optimistically delete paginated contacts as we would lose track of the page size / indices.
                        // instead we refetch after a delay.
                        setTimeout(() => {
                            dispatch({ type: 'pagination/refetch', payload: 'contacts' })
                        }, 1000)
                    } catch {
                        dispatch(api.util.invalidateTags(['Contacts', 'FavouriteContacts']))
                    }
                }
            }),
            getUserContactGroup: builder.query<ContactGroup, Partial<GetContactGroupQueryArgs>>({
                query: ({ ID, userID }) => {
                    if (!ID) {
                        throw new Error('getUserContactGroup | ID is required')
                    }
                    if (!userID) {
                        throw new Error('getUserContactGroup | userID is required')
                    }
                    return `/users/${userID}/contactGroups/${ID}`
                },
                providesTags: (result, error, { ID }) => [{ type: 'ContactGroups', id: ID }]
            }),
            getUserContactGroups: builder.query<ContactGroup[], string>({
                query: userID => `/users/${userID}/contactGroups`,
                transformResponse: (response: { data: ContactGroup[] }) => response.data,
                providesTags: results =>
                    results
                        ? [
                              ...results.map(entry => ({
                                  type: 'ContactGroups' as const,
                                  id: entry.ID
                              })),
                              'ContactGroups'
                          ]
                        : ['ContactGroups']
            }),
            createUserContactGroup: builder.mutation<ContactGroup, CreateContactGroupQueryArgs>({
                query: ({ entry, userID }) => ({
                    url: `/users/${userID}/contactGroups`,
                    method: 'POST',
                    body: entry
                }),
                invalidatesTags: ['ContactGroups']
            }),
            updateUserContactGroup: builder.mutation<ContactGroup, UpdateContactGroupQueryArgs>({
                query: ({ ID, entry, userID }) => ({
                    url: `/users/${userID}/contactGroups/${ID}`,
                    method: 'PUT',
                    body: entry
                }),
                // optimistically update cache entry
                onQueryStarted: async (
                    { ID, entry, userID, optimisticData },
                    { dispatch, queryFulfilled }
                ) => {
                    // We can choose to pass in an assumption of what extra data will be provided in the response, otherwise fallback to only what is available from the request body.
                    const _contacts = optimisticData?._contacts ?? entry._contacts ?? []

                    dispatch(
                        api.util.updateQueryData('getUserContactGroup', { ID, userID }, draft => {
                            Object.assign(draft, {
                                ...entry,
                                _contacts
                            })
                        })
                    )

                    dispatch(
                        api.util.updateQueryData('getUserContactGroups', userID, draft => {
                            const index = draft.findIndex(item => item.ID === ID)
                            if (index === -1) return draft
                            draft[index] = { ...draft[index], ...{ ...entry, _contacts } }
                        })
                    )

                    try {
                        await queryFulfilled
                    } catch {
                        dispatch(api.util.invalidateTags(['ContactGroups', 'FavouriteContacts']))
                    }
                }
            }),
            deleteUserContactGroup: builder.mutation<string, GetContactGroupQueryArgs>({
                query: ({ ID, userID }) => ({
                    url: `/users/${userID}/contactGroups/${ID}`,
                    method: 'DELETE'
                }),
                // optimistically update cache entry
                onQueryStarted: async ({ ID, userID }, { dispatch, queryFulfilled }) => {
                    dispatch(
                        api.util.updateQueryData('getUserContactGroup', { ID, userID }, _ => {
                            return null as any // Forcefully returning `null` allows us to mimic deletion of the cache entry.
                        })
                    )

                    dispatch(
                        api.util.updateQueryData('getUserContactGroups', userID, draft => {
                            return draft.filter(item => item.ID !== ID)
                        })
                    )

                    dispatch(
                        api.util.updateQueryData('getUserFavouriteContacts', userID, draft => ({
                            ...draft,
                            groups: draft.groups.filter(item => item.ID !== ID)
                        }))
                    )

                    try {
                        await queryFulfilled
                    } catch {
                        dispatch(api.util.invalidateTags(['ContactGroups', 'FavouriteContacts']))
                    }
                }
            }),
            getUserFavouriteContacts: builder.query<UserFavouriteContactsResponse, string>({
                query: userID => `/users/${userID}/favouriteContacts?includeContactGroups=true`,
                providesTags: ['FavouriteContacts']
            }),
            getContactSources: builder.query<string[], string>({
                query: userID => `/users/${userID}/contactsources`,
                transformResponse: (response: { sources: string[] }) => response.sources,
                providesTags: ['ContactSources']
            }),
            getContactPresence: builder.query<Record<string, SingleState>, void>({
                query: () => `/presencev2`,
                providesTags: ['ContactPresence'],
                transformResponse: (response: SingleState[]) => {
                    return response.reduce((states, state) => {
                        states[state.userID] = state
                        return states
                    }, {})
                }
            }),
            exportContacts: builder.mutation<{ ID: string }, void>({
                query: () => ({ url: '/contacts/export', method: 'POST' })
            }),
            getContactExportLog: builder.query<
                { status: 'error' | 'loading' } | { status: 'ready'; fileURL: string },
                string
            >({
                query: exportID => `/exportlogs/${exportID}`
            }),
            importContacts: builder.mutation<any, { userID: string; body: string }>({
                query: ({ userID, body }) => ({
                    url: `/users/${userID}/contacts/import?source=csv`,
                    headers: { 'Content-Type': 'text/csv' },
                    method: 'POST',
                    body
                })
            }),
            getContactImportLog: builder.query<
                { status: 'FAILED' | 'COMPLETED'; fileURL: string },
                string
            >({
                query: exportID => `/importlogs/${exportID}`
            })
        })
    })

    return { api, ...api }
}
