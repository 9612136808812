import { Skeleton, useColorMode } from '@chakra-ui/react'
import { FC } from 'react'

export const ImagePlaceholder: FC = () => {
    const { colorMode } = useColorMode()
    return (
        <Skeleton
            boxSize="64px"
            borderRadius="16px"
            startColor={`${colorMode}.coolGrey.40`}
            endColor={`${colorMode}.coolGrey.10`}
        />
    )
}
