import { SingleState, userSubscriptionCalls } from '@missionlabs/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SubscriptionState {
    presenceSubscriptions?: SingleState[]
    userGroupCalls?: userSubscriptionCalls[]
    userCalls?: userSubscriptionCalls[]
}

const initialState: SubscriptionState = {
    presenceSubscriptions: [],
    userGroupCalls: [],
    userCalls: []
}

export const subscriptionSlice = createSlice({
    name: 'subscriptionSlice',
    initialState,
    reducers: {
        sendSubscriptionRequest(
            _state,
            _action: PayloadAction<{
                userIDs?: string[]
                userGroupIDs?: string[]
                eventGroup: string
            }>
        ) {},
        updatePresenceSubscriptions(state, action: PayloadAction<SingleState[]>) {
            state.presenceSubscriptions = [...action.payload]
        },
        updatePresenceStates(state, action: PayloadAction<SingleState[]>) {
            state.presenceSubscriptions = state.presenceSubscriptions?.map(sub => {
                const updatedSub = action.payload.find(user => user.userID === sub.userID)
                return updatedSub ?? sub
            })
        },
        updateUserCalls(state, action: PayloadAction<userSubscriptionCalls[]>) {
            state.userCalls = action.payload
        },
        updateGroupCalls(state, action: PayloadAction<userSubscriptionCalls[]>) {
            const payload = action.payload[0] // always just one event so safe to index at 0
            const existingUserIDs = state.userGroupCalls?.map(call => call.userID)

            if (existingUserIDs?.includes(payload.userID)) {
                const filteredEvents =
                    state.userGroupCalls?.filter(call => call.userID !== payload.userID) ?? []

                state.userGroupCalls = [...filteredEvents, ...action.payload]
            } else {
                const userGroupCalls = state.userGroupCalls ?? []
                state.userGroupCalls = [...userGroupCalls, ...action.payload]
            }
        }
        // deleteUserCall(state, action: PayloadAction<string>) {
        //     state.userCalls = state.userGroupCalls?.filter(
        //         state => state.call.callTraceID !== action.payload
        //     )
        // },
        // deleteGroupCall(state, action: PayloadAction<string>) {
        //     state.userGroupCalls = state.userGroupCalls?.filter(
        //         state => state.call.callTraceID !== action.payload
        //     )
        // }
    }
})

export type SubscriptionSliceRootState = {
    subscriptionSlice: ReturnType<typeof subscriptionSlice.getInitialState>
}

export const selectPresenceSubscription = (state: SubscriptionSliceRootState) =>
    state.subscriptionSlice.presenceSubscriptions
export const selectUserCalls = (state: SubscriptionSliceRootState) =>
    state.subscriptionSlice.userCalls
export const selectUserGroupCalls = (state: SubscriptionSliceRootState) =>
    state.subscriptionSlice.userGroupCalls

export const {
    updatePresenceSubscriptions,
    sendSubscriptionRequest,
    updatePresenceStates,
    updateUserCalls,
    updateGroupCalls
    // deleteUserCall,
    // deleteGroupCall
} = subscriptionSlice.actions
