import { SingleNodeAudioTransformDevice } from 'amazon-chime-sdk-js'

export class VolumeTransformDevice extends SingleNodeAudioTransformDevice<GainNode> {
    private volume: number = 1.0 // So we can adjust volume prior to creating the node.

    // eslint-disable-next-line @typescript-eslint/require-await
    async createSingleAudioNode(context: AudioContext): Promise<GainNode> {
        const node = context.createGain()

        // Start at whatever volume the user already picked for this device, whether
        // or not we were connected to the audio graph.
        node.gain.setValueAtTime(this.volume, context.currentTime)
        return node
    }

    setVolume(volume: number): void {
        this.volume = volume
        if (this.node) {
            this.node.gain.setValueAtTime(volume, this.node.context.currentTime)
        }
    }
}
