import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { FC } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

export interface RequireAuthProps {}

export const RequireAuth: FC<RequireAuthProps> = () => {
    const location = useLocation()
    const user = useSelector(selectAuthenticatedUser)

    if (!user) return <Navigate to="/login" replace state={{ redirect: location.pathname }} />

    return <Outlet />
}
