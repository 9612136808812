import { Box, Skeleton, SkeletonCircle, useMultiStyleConfig, VStack } from '@chakra-ui/react'

import { DirectionIndicator } from './DirectionIndicator'

export interface SkeletonActivityProps {
    direction?: 'inbound' | 'outbound'
}

export const SkeletonActivity = ({ direction = 'inbound' }: SkeletonActivityProps) => {
    const styles = useMultiStyleConfig('Activity')

    return (
        <Box
            boxShadow={'sm'}
            className={`activity`}
            tabIndex={0}
            sx={{ ...styles.container }}
            marginInlineStart={direction === 'outbound' ? '48px' : '32px'}
            marginInlineEnd={direction !== 'outbound' ? '48px' : '32px'}
        >
            <DirectionIndicator direction={direction} />
            <Box w="20px" h="20px" ml="20px" pt="16px" zIndex={1}>
                <SkeletonCircle size="16px" />
            </Box>
            <VStack spacing="1px" align="flex-start" flexGrow={1} p="16px" pl="4px">
                <Skeleton mb={1} w={'100%'} height="20px" />
                <Skeleton w={'100%'} height="20px" />
            </VStack>
        </Box>
    )
}
