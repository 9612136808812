import { FC } from 'react'
import { isRouteErrorResponse, Navigate, useRouteError } from 'react-router-dom'

export interface RouterBoundaryProps {}

export const RouterBoundary: FC<RouterBoundaryProps> = () => {
    const error = useRouteError()

    if (isRouteErrorResponse(error) && error.status === 404) {
        return <Navigate to="/" replace />
    }

    throw error
}
