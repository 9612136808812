import Metric, { MetricData } from './metric'

export interface CallMetricData extends MetricData {
    type: string
    payload?: Record<string, any>
}

class CallMetric extends Metric<CallMetricData> {
    constructor(type: string, payload?: Record<string, any>, meta?: CallMetricData['meta']) {
        const prettyType = type.split('/')[1]
        super({ type: prettyType, payload, meta })
    }
}

export default CallMetric
