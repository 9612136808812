import { HStack } from '@chakra-ui/react'
import { selectInData } from '@missionlabs/api'
import { Dropdown, Input, useFormContext, ValidatedInput } from '@missionlabs/react'
import { Country, Option, Timezone } from '@missionlabs/types'
import { localeToOption, timezoneToOption } from '@missionlabs/utils'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetLocalesQuery, useGetTimezonesQuery } from 'shared/store'
import { APP_BRAND, AppConfig } from 'shared/utils/env'

import { GlobalSettingsFormFields } from './GlobalSettings'

const selectLocaleOptions = selectInData<Country[], Option[]>(
    data => data?.map(localeToOption).sort((a, b) => a.label.localeCompare(b.label)) ?? []
)

const selectTimezoneOptions = selectInData<Timezone[], Option[]>(
    data => data?.map(timezoneToOption) ?? []
)

export const CompanyDetailsForm: FC = () => {
    const { t } = useTranslation()

    const { locales } = useGetLocalesQuery(undefined, {
        selectFromResult: result => ({ ...result, locales: selectLocaleOptions(result) })
    })
    const { timezones } = useGetTimezonesQuery(undefined, {
        selectFromResult: result => ({ ...result, timezones: selectTimezoneOptions(result) })
    })

    const { watch, setValue } = useFormContext<GlobalSettingsFormFields>()

    const { locale, timezone } = watch()

    return (
        <>
            <SettingsRow label={t('globalSettings.companyName')}>
                <ValidatedInput name="name" shouldDisable>
                    <Input
                        w="294px"
                        data-testid="name-input"
                        isDisabled={AppConfig.brand !== APP_BRAND.CIRCLELOOP}
                    />
                </ValidatedInput>
            </SettingsRow>
            <SettingsRow label={t('myDetails.localisation')}>
                <HStack align="inherit" spacing="24px" w="full">
                    <ValidatedInput
                        name="locale"
                        label={t('myDetails.myLocale')}
                        shouldDisable
                        shouldRegister={false}
                    >
                        <Dropdown
                            width="full"
                            options={locales}
                            value={locale}
                            onChange={value => setValue('locale', value, { shouldDirty: true })}
                        />
                    </ValidatedInput>

                    <ValidatedInput
                        name="timezone"
                        label={t('myDetails.myTimezone')}
                        shouldDisable
                        shouldRegister={false}
                    >
                        <Dropdown
                            width="full"
                            options={timezones}
                            value={timezone}
                            onChange={value => setValue('timezone', value, { shouldDirty: true })}
                        />
                    </ValidatedInput>
                </HStack>
            </SettingsRow>
        </>
    )
}
