import { Scrollable } from '@missionlabs/react'
import { IVRMenu } from '@missionlabs/types'
import { FC } from 'react'
import { useGetIVRMenusQuery } from 'shared/store'

import CreateReportDataList from '../components/CreateReportDataList'
import CreateReportListItem from '../components/CreateReportListItem'
import CreateReportSearch from '../components/CreateReportSearch'
import { useCreateReportSearch } from '../hooks/useCreateReportSearch'

interface ReportFormMenusProps {
    onSelect(ID: string): void
    isSelected(ID: string): boolean
}

export const ReportFormMenus: FC<ReportFormMenusProps> = ({ onSelect, isSelected }) => {
    const { data: menus = [] } = useGetIVRMenusQuery()

    const { searchValue, setSearchValue, results } = useCreateReportSearch<IVRMenu>(menus, 'name')

    return (
        <CreateReportDataList>
            <CreateReportSearch search={searchValue} setSearch={setSearchValue} />
            <Scrollable height="container.sm">
                {results.map(menu => (
                    <CreateReportListItem
                        isSelected={isSelected(menu.ID)}
                        onClick={() => onSelect(menu.ID)}
                        key={menu.ID}
                    >
                        <h3>{menu.name}</h3>
                    </CreateReportListItem>
                ))}
            </Scrollable>
        </CreateReportDataList>
    )
}
