import { ComponentStyleConfig, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
    'container',
    'option'
])

export const ImageSelectStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: definePartsStyle(({ colorMode }) => ({
        container: defineStyle({
            borderColor: `${colorMode}.tones.periwinkle`,
            backgroundColor: `${colorMode}.tones.white`
        }),
        option: defineStyle({
            backgroundColor: `${colorMode}.tones.periwinkle`,
            color: `${colorMode}.tones.navy`
        })
    })),
    variants: {
        dark: definePartsStyle(({ colorMode }) => ({
            container: defineStyle({
                backgroundColor: `${colorMode}.tones.vantaBlue`,
                borderColor: `${colorMode}.tones.stormGrey`
            })
        }))
    }
})
