import { ComponentStyleConfig, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
    'container',
    'input',
    'resize'
])

const baseStyle = definePartsStyle(({ colorMode }) => ({
    container: defineStyle({ position: 'relative' }),
    input: defineStyle({
        position: 'relative',
        p: '10px',
        background: `${colorMode}.tones.white`,
        color: `${colorMode}.tones.navy`,
        border: '1px solid',
        borderColor: `${colorMode}.tones.periwinkle`,
        borderRadius: '4px',
        _placeholder: {
            color: `${colorMode}.tones.stormGrey`
        },
        _hover: {
            borderColor: `${colorMode}.tones.stormGrey`
        },
        '&[aria-invalid=true]': {
            color: `${colorMode}.alerts.rose !important`,
            border: '1px solid !important',
            borderColor: `${colorMode}.alerts.rose !important`,
            boxShadow: 'none !important'
        },
        '&:focus-visible': {
            borderColor: `${colorMode}.tones.stormGrey !important`,
            border: '1px solid !important',
            boxShadow: 'none !important'
        },
        _disabled: {
            opacity: 1,
            bg: `${colorMode}.tones.whiteLilac`,
            pointerEvents: 'none',
            color: `${colorMode}.tones.stormGrey`
        }
    }),
    resize: defineStyle({
        position: 'absolute',
        bottom: '8px',
        right: '8px',
        boxSize: '8px',
        color: `${colorMode}.tones.stormGrey`,
        zIndex: 10
    })
}))

const variants = {
    dark: definePartsStyle(({ colorMode }) => ({
        container: defineStyle({
            fontSize: '200px'
        }),
        input: defineStyle({
            bg: `${colorMode}.tones.vantaBlue`,
            color: `${colorMode}.tones.white`,
            borderColor: `${colorMode}.tones.navy`,
            _placeholder: {
                color: `${colorMode}.tones.white`
            },
            _disabled: {
                bg: `${colorMode}.tones.midnightExpress`
            }
        })
    }))
}

export const TextareaStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle,
    variants
})
