import { yup } from '@missionlabs/react'

export const outOfHoursRedirectSchema = () =>
    yup.object({
        numberE164: yup.string().required(),
        flipCLI: yup.boolean(),
        announce: yup.boolean()
    })

export const outOfHoursVoicemailSchema = () =>
    yup.object({
        customGreetingURL: yup.string().default(''),
        customNameURL: yup.string(),
        greeting: yup.string().oneOf(['name', 'custom', 'default']),
        greetingNoMessage: yup.string(),
        label: yup.string(),
        transcribe: yup.boolean(),
        customGreetingNoMessageURL: yup.string(),
        status: yup.string().oneOf(['active', 'inactive']),
        customGreetingFileName: yup.string(),
        customNameFileName: yup.string()
    })

export const outOfHoursRedirectInternalSchema = () =>
    yup.object({
        display: yup.string().required(),
        menuID: yup.string(),
        userID: yup.string(),
        teamID: yup.string()
    })
