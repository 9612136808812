import { useColorMode, VStack } from '@chakra-ui/react'
import { DatePicker, Themes } from '@missionlabs/react'
import { ScheduledMeeting } from '@missionlabs/types'
import { getUnixTimestampsForDay, isSameDate } from '@missionlabs/utils'
import { FC, useEffect, useMemo, useState } from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useGetClientQuery } from 'shared/store'

type CalendarViewProps = {
    meetings: ScheduledMeeting[]
}

export const CalendarView: FC<CalendarViewProps> = ({ meetings }) => {
    const { colorMode } = useColorMode()
    const { data: client } = useGetClientQuery()
    const navigate = useNavigate()
    const [date, setDate] = useState<Date>()
    const [params] = useSearchParams()

    const timeRangeStart = params.get('timeRangeStart') ?? ''

    useEffect(() => {
        setDate(new Date(parseInt(timeRangeStart) * 1000))
    }, [setDate, timeRangeStart])

    const onDayClick = (newDate: Date | null) => {
        if (!newDate) return
        else {
            navigate({
                pathname: `/meetings/day`,
                search: `?${createSearchParams({
                    ...getUnixTimestampsForDay(newDate)
                })}`
            })
        }
    }

    const dateList = useMemo(() => {
        const dateSet = new Set()

        meetings.forEach(meeting => {
            const startDate = new Date(meeting.startTime * 1000)
            const endDate = new Date(meeting.endTime * 1000)

            // Set the times to midnight (00:00)
            startDate.setHours(0, 0, 0, 0)
            endDate.setHours(0, 0, 0, 0)

            // Add the start date if it's not already in the set
            if (!dateSet.has(startDate)) {
                dateSet.add(startDate)
            }

            // Check if the end date is on a different day and add it if necessary
            if (!isSameDate(startDate, endDate) && !dateSet.has(endDate)) {
                dateSet.add(endDate)
            }
        })

        // Convert the set back to an array
        const dateArray = Array.from(dateSet)

        return dateArray as Date[]
    }, [meetings])

    return (
        <VStack
            spacing={6}
            h="calc(100dvh - 65px)"
            w="full"
            px="16px"
            bg={`${colorMode}.tones.ghostWhite`}
        >
            <DatePicker
                theme={Themes.CIRCLELOOP}
                existingValues={dateList}
                numberOfColumns={1}
                onChange={onDayClick}
                value={date}
                locale={client?.locale}
            />
        </VStack>
    )
}
