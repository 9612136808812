import { Box, HStack, useMultiStyleConfig } from '@chakra-ui/react'
import { Body, Tooltip } from 'atoms'
import React from 'react'

type Variant = 'selected' | 'hasCount' | 'noCount' | 'flagged'

interface Props {
    variant: Variant
    count: number
    icon?: React.ReactNode
    tooltipLabel?: string
    onClick(): void
}

export const CountIndicator: React.FC<Props> = ({
    variant,
    count,
    tooltipLabel,
    icon,
    onClick
}) => {
    const styles = useMultiStyleConfig('CountIndicator', { variant })

    return (
        <Tooltip
            isDisabled={!tooltipLabel}
            label={tooltipLabel}
            aria-label={tooltipLabel}
            hasArrow
            placement="top"
        >
            <HStack
                onClick={onClick}
                sx={{ ...styles.container }}
                data-testid={`unread-activity-count-${variant}`}
            >
                <Box sx={{ ...styles.icon }}>{icon}</Box>
                <Box sx={{ ...styles.count }}>
                    <Body sx={{ color: styles.count.color }} size="sm" variant="extra-bold">
                        {count}
                    </Body>
                </Box>
            </HStack>
        </Tooltip>
    )
}
