import { useEffect, useState } from 'react'

type DelayedContentProps = {
    delay: boolean
    timeout?: number
}

export const useDelayedContent = ({ delay, timeout = 200 }: DelayedContentProps) => {
    const [showDelayedContent, setShowDelayedContent] = useState(false)

    useEffect(() => {
        // Delay rendering after 200 milliseconds if noSearchResults is true
        if (delay) {
            const timeoutId = setTimeout(() => {
                setShowDelayedContent(true)
            }, timeout)

            // Clean up the timeout when the component unmounts or when noSearchResults changes
            return () => clearTimeout(timeoutId)
        } else {
            // Reset the state if noSearchResults is false

            setShowDelayedContent(false)
        }
    }, [delay, timeout, setShowDelayedContent])

    return { showDelayedContent }
}
