export function forceArray<T>(item: T | T[]): T[] {
    return Array.isArray(item) ? item : [item]
}

export function removeDuplicates<T>(key: keyof T, array: T[]): T[] {
    return array.reduce((previous, current) => {
        const alreadyExists = previous.find(item => item[key] === current[key])
        if (!alreadyExists) previous.push(current)

        return previous
    }, [] as T[])
}

export function keysInObject(keys: readonly string[], obj: object): boolean {
    return keys.some(key => key in obj)
}
