import { useColorMode } from '@chakra-ui/react'
import { ListTitle } from '@missionlabs/react'
import { FC } from 'react'

import { Section } from './ChatList'

export interface ChatListSectionProps {
    item: Section
}

export const ChatListSection: FC<ChatListSectionProps> = ({ item }) => {
    const { colorMode } = useColorMode()
    return (
        <ListTitle borderBottom="solid 1px" borderBottomColor={`${colorMode}.tones.periwinkle`}>
            {item.title}
        </ListTitle>
    )
}
