import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const OutgoingCallIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'OutgoingCallIconLight',
    viewBox: '0 0 20 22',
    path: (
        <path
            d="M18.9062 13.9336L14.9219 12.2148C14.2188 11.9414 13.3594 12.1367 12.8906 12.7617L11.5625 14.3242C9.53125 13.1914 7.8125 11.4727 6.67969 9.44141L8.28125 8.11328C8.86719 7.60547 9.0625 6.7461 8.75 6.04297L7.07031 2.0586C6.71875 1.27735 5.85938 0.847658 5.03906 1.04297L1.36719 1.90235C0.546875 2.09766 0 2.80078 0 3.66016C0 13.2305 7.77344 21.0039 17.3438 21.0039C18.2031 21.0039 18.9062 20.457 19.1016 19.6367L19.9219 15.9648C20.1172 15.1445 19.6875 14.2852 18.9062 13.9336ZM17.8516 19.3633C17.8125 19.5977 17.6172 19.7539 17.3438 19.7539C8.47656 19.7539 1.25 12.5273 1.25 3.66016C1.25 3.38672 1.40625 3.19141 1.64062 3.15235L5.3125 2.29297C5.35156 2.29297 5.39062 2.25391 5.42969 2.25391C5.625 2.25391 5.82031 2.41016 5.89844 2.60547L7.61719 6.55078C7.69531 6.78516 7.65625 7.01953 7.46094 7.17578L5.50781 8.77735C5.27344 8.97266 5.19531 9.28516 5.35156 9.5586C6.64062 12.1758 8.82812 14.3633 11.4453 15.6914C11.7188 15.8086 12.0312 15.7305 12.2266 15.4961L13.8281 13.543C13.9844 13.3477 14.2188 13.3086 14.4531 13.3867L18.3984 15.1055C18.6328 15.1836 18.7891 15.418 18.7109 15.6914L17.8516 19.3633ZM19.375 1.00391H14.375C14.0234 1.00391 13.75 1.31641 13.75 1.62891C13.75 1.98047 14.0234 2.25391 14.375 2.25391H17.8516L11.4062 8.69922C11.1719 8.9336 11.1719 9.36328 11.4062 9.59766C11.5234 9.71485 11.6797 9.75391 11.875 9.75391C12.0312 9.75391 12.1875 9.71485 12.3047 9.59766L18.75 3.15235V6.62891C18.75 6.98047 19.0234 7.25391 19.375 7.25391C19.6875 7.25391 20 6.98047 20 6.62891V1.62891C20 1.31641 19.6875 1.00391 19.375 1.00391Z"
            fill="currentColor"
        />
    )
})

export const OutgoingCallIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'OutgoingCallIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M18.75 12.5391L14.9219 10.8984C14.0625 10.5078 13.0859 10.7812 12.5 11.4844L11.4453 12.7734C9.6875 11.7578 8.24219 10.3125 7.22656 8.55469L8.51562 7.5C9.21875 6.91406 9.49219 5.9375 9.10156 5.11719L7.46094 1.25C7.07031 0.351562 6.09375 -0.15625 5.11719 0.078125L1.5625 0.898438C0.625 1.09375 0 1.91406 0 2.89062C0 12.3438 7.65625 20 17.1094 20C18.0859 20 18.9062 19.375 19.1016 18.4375L19.9219 14.8828C20.1562 13.9062 19.6484 12.9297 18.75 12.5391ZM18.0859 14.4531L17.2656 18.0078C17.2656 18.0859 17.1875 18.125 17.1094 18.125C8.71094 18.125 1.83594 11.2891 1.83594 2.89062C1.83594 2.8125 1.875 2.73438 1.95312 2.73438L5.54688 1.91406C5.58594 1.91406 5.58594 1.91406 5.58594 1.91406C5.66406 1.91406 5.74219 1.95312 5.74219 1.99219L7.38281 5.82031C7.42188 5.89844 7.42188 5.97656 7.34375 6.01562L5.42969 7.57812C5.11719 7.85156 5 8.35938 5.19531 8.75C6.48438 11.3672 8.63281 13.5156 11.25 14.8047C11.6406 15 12.1094 14.8828 12.4219 14.5703L13.9844 12.6562C14.0234 12.5781 14.1016 12.5781 14.1406 12.6172L18.0078 14.2578C18.0859 14.2969 18.125 14.375 18.0859 14.4531ZM19.0625 0H14.6875C14.1406 0 13.75 0.429688 13.75 0.9375C13.75 1.48438 14.1406 1.875 14.6875 1.875H16.7969L11.5234 7.14844C11.1328 7.53906 11.1328 8.125 11.5234 8.47656C11.875 8.86719 12.4609 8.86719 12.8516 8.47656L18.125 3.20312V5.3125C18.125 5.85938 18.5156 6.25 19.0625 6.25C19.5703 6.25 20 5.85938 20 5.3125V0.9375C20 0.429688 19.5703 0 19.0625 0Z"
            fill="currentColor"
        />
    )
})

export const OutgoingCallIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'OutgoingCallIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M19.2578 13.75L15 11.9141C14.4922 11.7188 13.9062 11.8359 13.5938 12.2656L11.875 14.375C9.14062 13.0469 6.95312 10.8594 5.625 8.125L7.73438 6.40625C8.16406 6.09375 8.28125 5.50781 8.08594 5L6.25 0.742188C6.01562 0.234375 5.42969 -0.078125 4.84375 0.0390625L0.9375 0.976562C0.351562 1.09375 0 1.5625 0 2.14844C0 11.9922 8.00781 20 17.8516 20C18.4375 20 18.9062 19.6484 19.0234 19.0625L19.9609 15.1172C20.0781 14.5703 19.8047 13.9844 19.2578 13.75ZM18.75 0H15C14.2969 0 13.75 0.585938 13.75 1.25C13.75 1.95312 14.2969 2.5 15 2.5H15.7031L11.6016 6.64062C11.0938 7.10938 11.0938 7.92969 11.6016 8.39844C12.0703 8.90625 12.8906 8.90625 13.3594 8.39844L17.5 4.29688V5C17.5 5.70312 18.0469 6.25 18.75 6.25C19.4141 6.25 20 5.70312 20 5V1.25C20 0.585938 19.4141 0 18.75 0Z"
            fill="currentColor"
        />
    )
})
