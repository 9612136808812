import {
    answer,
    Call,
    CallSliceRootState,
    cancelCallTransfer,
    declineCall,
    hangup,
    selectCall,
    useSelector
} from '@missionlabs/api'
import { Notification } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useCallDetails } from 'shared/hooks/useCallDetails'

import { NotificationProps } from './types'

export const IncomingCallNotification: FC<NotificationProps<Call>> = ({
    call: _call,
    dispatch,
    navigate,
    onClose
}) => {
    const { t } = useTranslation()
    const call = useSelector<CallSliceRootState, typeof _call>(state =>
        selectCall(state, _call?.callTraceID ?? '')
    )
    const { contactName: name, team, transferer, localNumber } = useCallDetails(call)

    const onAnswer = () => {
        if (!call) return
        dispatch(answer({ callTraceID: call.callTraceID }))
        onClose(call.callTraceID)
    }

    const onHangup = () => {
        if (!call) return
        if (call.isTransfer && call.transferType === 'attended')
            dispatch(cancelCallTransfer({ callTraceID: call.callTraceID }))
        else dispatch(declineCall({ callTraceID: call.callTraceID }))
        onClose(call.callTraceID)
    }

    // todo: update route to go to messages with this contact.
    const onMessage = () => {
        if (!call) return
        navigate(`/contacts/${call.userID}`)
    }

    const onDismiss = () => {
        if (!call) return
        // Hangup on regular dismiss to stop call on this device only.
        dispatch(hangup({ callTraceID: call.callTraceID }))
        onClose(call.callTraceID)
    }

    if (!call) return null

    return (
        <Notification
            variant="call"
            header={t('notifications.incomingCall')}
            name={name}
            via={
                team
                    ? t('notifications.via', { value: team })
                    : t('notifications.directCall', { value: localNumber })
            }
            parked={transferer}
            onAnswer={onAnswer}
            onHangup={onHangup}
            onMessage={call.userID ? onMessage : undefined}
            onDismiss={onDismiss}
        />
    )
}
