import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const GrabIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'GrabIconLight',
    viewBox: '0 0 8 16',
    path: (
        <path
            d="M0.25 1.75C0.25 1.08594 0.796875 0.5 1.5 0.5C2.16406 0.5 2.75 1.08594 2.75 1.75C2.75 2.45312 2.16406 3 1.5 3C0.796875 3 0.25 2.45312 0.25 1.75ZM0.25 8C0.25 7.33594 0.796875 6.75 1.5 6.75C2.16406 6.75 2.75 7.33594 2.75 8C2.75 8.70312 2.16406 9.25 1.5 9.25C0.796875 9.25 0.25 8.70312 0.25 8ZM2.75 14.25C2.75 14.9531 2.16406 15.5 1.5 15.5C0.796875 15.5 0.25 14.9531 0.25 14.25C0.25 13.5859 0.796875 13 1.5 13C2.16406 13 2.75 13.5859 2.75 14.25ZM5.25 1.75C5.25 1.08594 5.79688 0.5 6.5 0.5C7.16406 0.5 7.75 1.08594 7.75 1.75C7.75 2.45312 7.16406 3 6.5 3C5.79688 3 5.25 2.45312 5.25 1.75ZM7.75 8C7.75 8.70312 7.16406 9.25 6.5 9.25C5.79688 9.25 5.25 8.70312 5.25 8C5.25 7.33594 5.79688 6.75 6.5 6.75C7.16406 6.75 7.75 7.33594 7.75 8ZM5.25 14.25C5.25 13.5859 5.79688 13 6.5 13C7.16406 13 7.75 13.5859 7.75 14.25C7.75 14.9531 7.16406 15.5 6.5 15.5C5.79688 15.5 5.25 14.9531 5.25 14.25Z"
            fill="currentColor"
        />
    )
})

export const GrabIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'GrabIconRegular',
    viewBox: '0 0 10 18',
    path: (
        <path
            d="M3.75 2.75C3.75 3.80469 2.89062 4.625 1.875 4.625C0.820312 4.625 0 3.80469 0 2.75C0 1.73438 0.820312 0.875 1.875 0.875C2.89062 0.875 3.75 1.73438 3.75 2.75ZM1.875 2.125C1.52344 2.125 1.25 2.4375 1.25 2.75C1.25 3.10156 1.52344 3.375 1.875 3.375C2.1875 3.375 2.5 3.10156 2.5 2.75C2.5 2.4375 2.1875 2.125 1.875 2.125ZM3.75 9C3.75 10.0547 2.89062 10.875 1.875 10.875C0.820312 10.875 0 10.0547 0 9C0 7.98438 0.820312 7.125 1.875 7.125C2.89062 7.125 3.75 7.98438 3.75 9ZM1.875 8.375C1.52344 8.375 1.25 8.6875 1.25 9C1.25 9.35156 1.52344 9.625 1.875 9.625C2.1875 9.625 2.5 9.35156 2.5 9C2.5 8.6875 2.1875 8.375 1.875 8.375ZM0 15.25C0 14.2344 0.820312 13.375 1.875 13.375C2.89062 13.375 3.75 14.2344 3.75 15.25C3.75 16.3047 2.89062 17.125 1.875 17.125C0.820312 17.125 0 16.3047 0 15.25ZM1.875 15.875C2.1875 15.875 2.5 15.6016 2.5 15.25C2.5 14.9375 2.1875 14.625 1.875 14.625C1.52344 14.625 1.25 14.9375 1.25 15.25C1.25 15.6016 1.52344 15.875 1.875 15.875ZM10 2.75C10 3.80469 9.14062 4.625 8.125 4.625C7.07031 4.625 6.25 3.80469 6.25 2.75C6.25 1.73438 7.07031 0.875 8.125 0.875C9.14062 0.875 10 1.73438 10 2.75ZM8.125 2.125C7.77344 2.125 7.5 2.4375 7.5 2.75C7.5 3.10156 7.77344 3.375 8.125 3.375C8.4375 3.375 8.75 3.10156 8.75 2.75C8.75 2.4375 8.4375 2.125 8.125 2.125ZM6.25 9C6.25 7.98438 7.07031 7.125 8.125 7.125C9.14062 7.125 10 7.98438 10 9C10 10.0547 9.14062 10.875 8.125 10.875C7.07031 10.875 6.25 10.0547 6.25 9ZM8.125 9.625C8.4375 9.625 8.75 9.35156 8.75 9C8.75 8.6875 8.4375 8.375 8.125 8.375C7.77344 8.375 7.5 8.6875 7.5 9C7.5 9.35156 7.77344 9.625 8.125 9.625ZM10 15.25C10 16.3047 9.14062 17.125 8.125 17.125C7.07031 17.125 6.25 16.3047 6.25 15.25C6.25 14.2344 7.07031 13.375 8.125 13.375C9.14062 13.375 10 14.2344 10 15.25ZM8.125 14.625C7.77344 14.625 7.5 14.9375 7.5 15.25C7.5 15.6016 7.77344 15.875 8.125 15.875C8.4375 15.875 8.75 15.6016 8.75 15.25C8.75 14.9375 8.4375 14.625 8.125 14.625Z"
            fill="currentColor"
        />
    )
})

export const GrabIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'GrabIconSolid',
    viewBox: '0 0 10 18',
    path: (
        <path
            d="M0 2.75C0 1.73438 0.820312 0.875 1.875 0.875C2.89062 0.875 3.75 1.73438 3.75 2.75C3.75 3.80469 2.89062 4.625 1.875 4.625C0.820312 4.625 0 3.80469 0 2.75ZM0 9C0 7.98438 0.820312 7.125 1.875 7.125C2.89062 7.125 3.75 7.98438 3.75 9C3.75 10.0547 2.89062 10.875 1.875 10.875C0.820312 10.875 0 10.0547 0 9ZM3.75 15.25C3.75 16.3047 2.89062 17.125 1.875 17.125C0.820312 17.125 0 16.3047 0 15.25C0 14.2344 0.820312 13.375 1.875 13.375C2.89062 13.375 3.75 14.2344 3.75 15.25ZM6.25 2.75C6.25 1.73438 7.07031 0.875 8.125 0.875C9.14062 0.875 10 1.73438 10 2.75C10 3.80469 9.14062 4.625 8.125 4.625C7.07031 4.625 6.25 3.80469 6.25 2.75ZM10 9C10 10.0547 9.14062 10.875 8.125 10.875C7.07031 10.875 6.25 10.0547 6.25 9C6.25 7.98438 7.07031 7.125 8.125 7.125C9.14062 7.125 10 7.98438 10 9ZM6.25 15.25C6.25 14.2344 7.07031 13.375 8.125 13.375C9.14062 13.375 10 14.2344 10 15.25C10 16.3047 9.14062 17.125 8.125 17.125C7.07031 17.125 6.25 16.3047 6.25 15.25Z"
            fill="currentColor"
        />
    )
})
