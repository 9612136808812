import { Notification } from '@missionlabs/react'
import { UserActivity } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useActivityInfo } from 'shared/hooks/useActivityInfo'

import { NotificationProps } from './types'

export const MissedCallNotification: FC<NotificationProps<UserActivity>> = ({
    call,
    navigate,
    onClose
}) => {
    const { t } = useTranslation()
    const { name, company, team } = useActivityInfo(call)

    // todo: update route to go to messages with this contact.
    const onMessage = () => {
        if (!call) return
        navigate(`/contacts/${call.contact?.ID}`)
    }

    const onDismiss = () => {
        if (!call) return
        onClose(call.callTraceID)
    }

    return (
        <Notification
            variant="reminder"
            header={t('notifications.missedCall')}
            name={name}
            role={company}
            via={team}
            onMessage={call?.contact?.ID ? onMessage : undefined}
            onDismiss={onDismiss}
        />
    )
}
