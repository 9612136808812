import { HStack } from '@chakra-ui/react'
import { Button, Input } from '@missionlabs/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'

import SearchNumberView from './SearchNumberView'

interface Props {}

const SearchNumber: React.FC<Props> = () => {
    const { t } = useTranslation()

    const [params, setParams] = useSearchParams()

    const [numberField, setNumberField] = useState('')
    const [number, setNumber] = useState<string | null>(params.get('search'))

    const onSearch = () => {
        setNumber(numberField)
        setParams({ search: numberField })
    }

    const onClear = () => {
        setNumber(null)
        setParams({})
    }

    return (
        <div>
            {number ? (
                <SearchNumberView number={number} clearNumber={onClear} />
            ) : (
                <SettingsPage
                    title={t('analytics.search-number')}
                    subtitle={t('analytics.search-number-subtitle')}
                    contentProps={{ maxW: 'unset' }}
                >
                    <HStack w="40%">
                        <Input
                            placeholder="Enter number"
                            value={numberField}
                            onChange={e => setNumberField(e.target.value)}
                        />
                        <Button onClick={onSearch}>Search</Button>
                    </HStack>
                </SettingsPage>
            )}
        </div>
    )
}

export default SearchNumber
