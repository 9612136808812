import { Box, Grid, useMultiStyleConfig } from '@chakra-ui/react'
import { DiallerKey } from 'atoms'
import { PhoneIconSolid } from 'atoms/Icons/zeta'
import { forwardRef, ReactNode } from 'react'
import { Themes, useTheme } from 'theme'

import { Key, keys } from './keys'

export type DiallerProps = {
    onClick: (key: string) => void
    disableCall?: boolean
    disableRetrieveCall?: boolean
    hideCall?: boolean
    hideRetrieveCall?: boolean
    callIcon?: ReactNode
    size?: 'xs' | 'sm' | 'md' | 'lg'
}

export const Dialler = forwardRef<HTMLDivElement, DiallerProps>(
    (
        {
            onClick,
            disableCall,
            disableRetrieveCall,
            hideCall,
            hideRetrieveCall,
            callIcon,
            size,
            ...props
        },
        ref
    ) => {
        const padStyles = useMultiStyleConfig('DialPad', { size })
        const keyStyles = useMultiStyleConfig('DiallerKey', { size })
        const { variant: theme = Themes.ZETA } = useTheme()

        function renderKey(key: Key, index: number) {
            if (key.label === 'retrieveCall' && hideRetrieveCall) {
                return (
                    <Box
                        sx={{
                            ...keyStyles.container,
                            bg: 'none',
                            border: 'none',
                            pointerEvents: 'none'
                        }}
                    />
                )
            }

            return (
                <DiallerKey
                    key={`${key.label}-${index}`}
                    label={key.label}
                    subLabel={key.subLabel}
                    icon={key.icon}
                    bg={key.bg}
                    onKeyClick={onClick}
                    isDisabled={key.label === 'retrieveCall' ? disableRetrieveCall : undefined}
                    size={size}
                />
            )
        }

        function renderCallIcon() {
            if (callIcon) return callIcon
            return <PhoneIconSolid aria-label="Call" boxSize="28px" />
        }

        return (
            <Grid sx={{ ...padStyles.container }} {...props} ref={ref}>
                {keys[theme].map(renderKey)}
                {!hideCall && (
                    <DiallerKey
                        sx={{ ...keyStyles.callKey }}
                        label="call"
                        onKeyClick={onClick}
                        isDisabled={disableCall}
                        icon={renderCallIcon()}
                        size={size}
                    />
                )}
            </Grid>
        )
    }
)
