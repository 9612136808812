import { Fade, useColorMode } from '@chakra-ui/react'
import { Avatar, Heading, IconButton, ListItem } from '@missionlabs/react'
import { TickCircleIcon } from '@missionlabs/react/circleloop'
import { isUser, UserOrTeam } from '@missionlabs/types'
import { isTeamOrMenu } from '@missionlabs/utils'
import { CSSProperties, FC } from 'react'

export type UTMListItemData = {
    item: UserOrTeam
    isSelected: boolean
    style?: CSSProperties
    onClick: (item: UserOrTeam) => void
}

export const UTMListItem: FC<UTMListItemData> = ({ item, isSelected, style, onClick }) => {
    const { colorMode } = useColorMode()

    const name = isUser(item) ? item.fullName : item.name
    const src = (isUser(item) && item.photoURL) || undefined

    return (
        <ListItem style={style} data-active={isSelected || undefined} onClick={() => onClick(item)}>
            <Avatar size="md" name={name} src={src} team={isTeamOrMenu(item)} />
            <Heading flex={1} size="h4">
                {name}
            </Heading>
            <Fade in={isSelected}>
                <IconButton
                    icon={<TickCircleIcon color={`${colorMode}.teal.default`} />}
                    variant="transparent"
                    aria-label={`Select ${name}`}
                />
            </Fade>
        </ListItem>
    )
}
