import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const TeamIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TeamIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9985 1.5C11.8296 1.5 11.6914 1.51733 11.5907 1.53628C11.5403 1.54576 11.4992 1.55568 11.4682 1.56409C11.453 1.56821 11.4403 1.57197 11.4299 1.57516C10.4704 1.83204 9.76936 2.71403 9.76936 3.7567C9.76936 4.66392 10.3001 5.44949 11.07 5.8075L11.07 10.4283C9.81012 10.8297 8.90224 12.0197 8.90224 13.4188C8.90224 13.6542 8.92799 13.884 8.97692 14.1053L5.05282 16.4002C4.36025 15.8889 3.4058 15.8053 2.6135 16.2687C1.54497 16.8936 1.18368 18.272 1.79675 19.3476C2.41194 20.427 3.7802 20.8019 4.85259 20.1747C5.62435 19.7233 6.02608 18.8797 5.96054 18.0421L9.91032 15.7321C10.461 16.2414 11.1958 16.5539 12.0036 16.5539C12.8145 16.5539 13.5519 16.239 14.1032 15.7262L18.0395 18.0283C17.9598 18.8775 18.362 19.7383 19.1444 20.1959C20.2168 20.8231 21.5851 20.4482 22.2003 19.3689C22.8134 18.2932 22.4521 16.9148 21.3835 16.2899C20.6019 15.8328 19.6624 15.908 18.9722 16.4011L15.0322 14.0968C15.0799 13.8781 15.105 13.6512 15.105 13.4188C15.105 12.0197 14.1971 10.8297 12.9372 10.4283L12.9372 5.8075C13.7071 5.44949 14.2378 4.66392 14.2378 3.7567C14.2378 2.71262 13.5349 1.82965 12.5734 1.57412L12.5702 1.57317C12.56 1.57018 12.5471 1.56656 12.5316 1.56255C12.5006 1.55455 12.4592 1.54496 12.4084 1.53573C12.3071 1.51729 12.1673 1.5 11.9985 1.5ZM11.57 2.0553C11.4057 1.58306 11.406 1.58298 11.406 1.58298L11.4078 1.58235L11.4101 1.58156L11.4155 1.57976L11.4299 1.57516L11.4439 1.57147L11.57 2.0553Z"
            fill="currentColor"
        />
    )
})
