import { useDisclosure } from '@chakra-ui/react'
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    useToast
} from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetInvoiceQuery, usePayInvoiceMutation } from 'shared/store'

import { PayInvoiceDetails } from './PayInvoiceDetails'

const noop = () => {}

export const PayInvoiceDrawer: FC = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { t } = useTranslation()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const { toast } = useToast()
    const { data: invoice } = useGetInvoiceQuery({ invoiceID: id ?? '' })
    const [payInvoice, { isLoading }] = usePayInvoiceMutation()

    function handleTransition() {
        navigate('/admin/billing')
    }

    const onSubmit = async () => {
        if (!id) return
        try {
            const response = await payInvoice(id).unwrap()
            toast({
                title: t('admin.billing.invoice.successPaying'),
                status: 'success'
            })
            if (response) onClose()
        } catch (error) {
            toast({
                title: error.data?.message || t('admin.billing.invoice.errorPaying'),
                status: 'error'
            })
        }
    }

    return (
        <Drawer
            size="lg"
            isOpen={isOpen}
            onClose={!isLoading ? onClose : noop}
            onCloseComplete={handleTransition}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>
                    {t('admin.billing.invoice.payInvoice', { invoiceNumber: id })}
                </DrawerHeader>
                <DrawerBody>
                    <PayInvoiceDetails
                        invoice={invoice}
                        onSubmit={onSubmit}
                        onClose={onClose}
                        isLoading={isLoading}
                    />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
