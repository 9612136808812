import {
    Box,
    Collapse,
    Flex,
    HStack,
    RadioGroup,
    Stack,
    useColorMode,
    VStack
} from '@chakra-ui/react'
import { Body, Toggle, useFormContext } from '@missionlabs/react'
import {
    OutOfHoursForwardType,
    OutOfHoursRedirectInternal,
    UserCallRoute
} from '@missionlabs/types'
import { Schedule } from 'features/settings/pages/Calls/OutOfHoursSetting/Schedule/Schedule'
import { useTranslation } from 'react-i18next'
import { RedirectExternalRadio } from 'shared/components/OutOfHours/RedirectExternalRadio'
import { RedirectInternalRadio } from 'shared/components/OutOfHours/RedirectInternalRadio'
import { VoicemailNoMessageRadio } from 'shared/components/OutOfHours/VoicemailNoMessageRadio'
import { VoicemailRadio } from 'shared/components/OutOfHours/VoicemailRadio'

import { absolveInternalRedirectData } from '../../utils/redirect'
import { AddEditTeamFields } from './AddEditTeamForm'

interface TeamsOutOfHoursProps {}

export const TeamsOutOfHours: React.FC<TeamsOutOfHoursProps> = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const { setValue, watch } = useFormContext<AddEditTeamFields>()

    const callRoute = {
        outOfHours: watch('callRoute.outOfHours') as OutOfHoursForwardType,
        outOfHoursRedirectInternal: watch('outOfHoursRedirectInternal'),
        outOfHoursRedirect: watch('outOfHoursRedirect'),
        outOfHoursVoicemail: watch('outOfHoursVoicemail')
    }

    const updateValues = (
        key: 'outOfHoursRedirectInternal' | 'outOfHoursRedirect' | 'outOfHoursVoicemail',
        update: Partial<UserCallRoute>
    ) => {
        const {
            outOfHours,
            outOfHoursRedirect = {},
            outOfHoursRedirectInternal = {},
            outOfHoursVoicemail = {}
        } = update
        const options = { shouldDirty: true }

        setValue('callRoute.outOfHours', outOfHours, options)

        switch (key) {
            case 'outOfHoursRedirectInternal': {
                const updateRedirectInternal = outOfHoursRedirectInternal ?? {}
                const data = { ...watch(key), ...updateRedirectInternal }
                const updateData = absolveInternalRedirectData(data, updateRedirectInternal)
                setValue(key, updateData as OutOfHoursRedirectInternal, options)
                break
            }
            case 'outOfHoursRedirect': {
                setValue(key, { ...watch(key), ...outOfHoursRedirect }, options)
                break
            }
            case 'outOfHoursVoicemail': {
                setValue(key, { ...watch(key), ...outOfHoursVoicemail }, options)
                break
            }
            default:
                break
        }
    }

    return (
        <>
            <Flex direction="column" align="flex-start" id="out-of-hours">
                <VStack align="flex-start" gap={2} w="100%">
                    <HStack justify="space-between" w="100%">
                        <Body variant="bold">{t('calls.outOfHours')}</Body>
                        <Toggle
                            isChecked={watch('notAvailable.enabled')}
                            onChange={e =>
                                setValue('notAvailable.enabled', e.target.checked, {
                                    shouldDirty: true
                                })
                            }
                        />
                    </HStack>
                    <Body size="sm" variant="bold" color={`${colorMode}.tones.stormGrey`}>
                        {t('calls.certainTimes')}
                    </Body>
                </VStack>
                <Box
                    as={Collapse}
                    in={watch('notAvailable.enabled')}
                    w="100%"
                    startingHeight="0px"
                    mt={4}
                >
                    <Schedule
                        notAvailable={watch('notAvailable')}
                        onUpdate={update => {
                            const updatedNotAvailable = {
                                ...watch('notAvailable'),
                                ...update
                            }
                            setValue('notAvailable', updatedNotAvailable, {
                                shouldDirty: true
                            })
                        }}
                    />
                    <Box maxW="100%" mt={4}>
                        <RadioGroup
                            value={callRoute.outOfHours}
                            onChange={(value: OutOfHoursForwardType) => {
                                setValue('callRoute.outOfHours', value, {
                                    shouldDirty: true
                                })
                            }}
                            alignSelf="start"
                        >
                            <Body
                                w="full"
                                textAlign="start"
                                py="8px"
                                size="sm"
                                variant="bold"
                                color={`${colorMode}.tones.stormGrey`}
                            >
                                {t('calls.whatHappens')}
                            </Body>
                            <Stack spacing={1}>
                                <VoicemailRadio
                                    uploadType="shared"
                                    type="outOfHours"
                                    forwardType={callRoute.outOfHours}
                                    callRoute={callRoute}
                                    onUpdate={update => updateValues('outOfHoursVoicemail', update)}
                                />
                                <VoicemailNoMessageRadio
                                    uploadType="shared"
                                    type="outOfHours"
                                    callRoute={callRoute}
                                    forwardType={callRoute.outOfHours}
                                    onUpdate={update => updateValues('outOfHoursVoicemail', update)}
                                />
                                <RedirectInternalRadio
                                    type="outOfHours"
                                    forwardType={callRoute.outOfHours}
                                    callRoute={callRoute}
                                    onUpdate={update =>
                                        updateValues('outOfHoursRedirectInternal', update)
                                    }
                                />
                                <RedirectExternalRadio
                                    type="outOfHours"
                                    forwardType={callRoute.outOfHours}
                                    callRoute={callRoute}
                                    onUpdate={update => updateValues('outOfHoursRedirect', update)}
                                />
                            </Stack>
                        </RadioGroup>
                    </Box>
                </Box>
            </Flex>
        </>
    )
}
