import 'dayjs/locale/es'

import { Flex, VStack } from '@chakra-ui/layout'
import { Link } from '@chakra-ui/react'
import { DatePicker, DatePickerProps } from '@mantine/dates'
import { Maybe } from '@missionlabs/types/src/util'
import { Themes } from 'index'
import { FC, useState } from 'react'

import { colors } from '../../theme/circleloop/colors/colors'
import clStyles from '../../theme/circleloop/rangeCalendar'
import zetaStyles from '../../theme/zeta/rangeCalendar'

const styles = {
    [Themes.CIRCLELOOP]: clStyles,
    [Themes.ZETA]: zetaStyles
}

export type RangeCalendarDate = Maybe<number>

export type RangeCalendarProps = Omit<DatePickerProps, 'value'> & {
    startDate?: RangeCalendarDate
    endDate?: RangeCalendarDate
    numberOfMonths?: number
    label?: string
    isOutsideRange?: boolean
    theme: Themes
    labels?: {
        today: string
        clear: string
    }
}

type RangeCalendarRange = [Date | null, Date | null]

type RangeCalendarDateRange = [RangeCalendarDate, RangeCalendarDate]

const toDates = ([start, end]: RangeCalendarDateRange): RangeCalendarRange => [
    start ? new Date(start) : null,
    end ? new Date(end) : null
]

const toTimestamps = ([start, end]: RangeCalendarRange): RangeCalendarDateRange => [
    start?.getTime(),
    end?.getTime()
]

export const RangeCalendar: FC<RangeCalendarProps> = ({
    startDate,
    endDate,
    numberOfMonths = 1,
    locale,
    theme,
    labels,
    ...props
}) => {
    const [dates, setDates] = useState<RangeCalendarDateRange>([startDate, endDate])

    const handleTodayClick = () => {
        const today = new Date()
        setDates([today.getTime(), today.getTime()])
    }

    const handleClearClick = () => {
        setDates([null, null])
    }

    return (
        <VStack alignItems="start">
            <DatePicker
                {...props}
                type="range"
                value={toDates(dates)}
                defaultValue={toDates(dates)}
                styles={styles[theme]}
                allowDeselect={undefined}
                numberOfColumns={numberOfMonths}
                allowSingleDateInRange
                onChange={dates => setDates(toTimestamps(dates))}
                locale={locale}
                ariaLabels={{
                    previousMonth: 'Previous month',
                    nextMonth: 'Next month'
                }}
            />
            {labels && (
                <Flex justifyContent="space-between" w="290px">
                    <Link
                        sx={{
                            color: `${colors.light.red.default}`,
                            textDecorationLine: 'underline'
                        }}
                        onClick={handleClearClick}
                    >
                        {labels.clear}
                    </Link>
                    <Link
                        sx={{
                            color: `${colors.light.red.default}`,
                            textDecorationLine: 'underline'
                        }}
                        onClick={handleTodayClick}
                    >
                        {labels.today}
                    </Link>
                </Flex>
            )}
        </VStack>
    )
}
