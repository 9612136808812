import { ImageSelectOption, SelectOption } from '@missionlabs/react'
import {
    AddIconLight,
    GridIconLight,
    GridIconRegular,
    GridIconSolid,
    XCircleIconLight
} from '@missionlabs/react/zeta'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useBackgroundFilterOptions = (): SelectOption[] => {
    const { t } = useTranslation()

    const imageOptions = useMemo((): ImageSelectOption[] => {
        const storage = localStorage.getItem('vsbg')
        const images: string[] = JSON.parse(storage || '[]')
        return images.map(image => ({
            image: true,
            label: image,
            value: `image_${image}`,
            src: image
        }))
    }, [])

    return [
        {
            image: true,
            value: 'none',
            label: t('meeting.background.none'),
            src: <XCircleIconLight />
        },
        {
            image: true,
            value: 'blur_3',
            label: t('meeting.background.soft'),
            src: <GridIconLight />
        },
        {
            image: true,
            value: 'blur_6',
            label: t('meeting.background.medium'),
            src: <GridIconRegular />
        },
        {
            image: true,
            value: 'blur_10',
            label: t('meeting.background.hard'),
            src: <GridIconSolid />
        },
        ...imageOptions,
        {
            image: true,
            value: 'image_select',
            label: 'image',
            src: <AddIconLight />
        }
    ]
}
