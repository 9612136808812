import { Grid } from '@chakra-ui/react'
import { NotAvailable, NotAvailableDayTimes } from '@missionlabs/types'
import { FC, Fragment, useMemo } from 'react'

import { ScheduleDataRow } from './ScheduleDataRow'
import { ScheduleHeadingRow } from './ScheduleHeadingRow'
import { Availability, TimeSpan } from './scheduleOptions'
import { ScheduleSeparatorRow } from './ScheduleSeparatorRow'

interface ScheduleProps {
    notAvailable: NotAvailable | undefined
    onUpdate: (payload?: NotAvailable) => void
}

export const Schedule: FC<ScheduleProps> = ({ notAvailable, onUpdate }) => {
    const days = useMemo(() => {
        if (!notAvailable) return []

        function getScheduleForDay(day: keyof NotAvailable) {
            const config = notAvailable?.[day] as NotAvailableDayTimes | undefined

            // When a range is set, we need to invert the unavailable / before & after logic. Therefore, the schedule is available between before and after.
            const hasRange = config && ('before' in config || 'after' in config)
            // When a day is not provided, we assume it's always available. An empty object means always unavailable.
            const notSet = config === undefined

            return {
                data: config,
                day: day,
                availability:
                    hasRange || notSet ? Availability.AVAILABLE : Availability.UNAVAILABLE,
                time: hasRange ? TimeSpan.BETWEEN : TimeSpan.ALL_DAY,
                from: config?.before,
                to: config?.after
            }
        }

        return (
            [
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday'
            ] as (keyof NotAvailable)[]
        ).map(getScheduleForDay)
    }, [notAvailable])

    return (
        <Grid
            templateColumns="0.5fr 1.5fr 1fr 0.8fr 0.8fr"
            w="full"
            rowGap="8px"
            columnGap="16px"
            maxW="600px"
        >
            <ScheduleHeadingRow />
            {days.map(data => (
                <Fragment key={data.day}>
                    <ScheduleSeparatorRow />
                    <ScheduleDataRow {...data} onUpdate={onUpdate} />
                </Fragment>
            ))}
        </Grid>
    )
}
