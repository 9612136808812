import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { DocumentTitle } from 'shared/components/DocumentTitle'
import { MainLayout } from 'shared/components/MainLayout'

import { SettingsProvider } from './settingsContext'
import { SettingsSidebar } from './SettingsSidebar'

export interface SettingsDashboardProps {}

export const SettingsDashboard: FC<SettingsDashboardProps> = () => {
    return (
        <SettingsProvider>
            <DocumentTitle breadcrumbs={['Settings']} />
            <MainLayout left={<SettingsSidebar />} leftWidth={320} right={<Outlet />} />
        </SettingsProvider>
    )
}
