import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const VideoIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VideoIconLight',
    viewBox: '0 0 24 16',
    path: (
        <path
            d="M22.5469 1.90625C22.1562 1.71094 21.6484 1.71094 21.2578 1.98438L17.2344 4.79688C16.9609 4.99219 16.8828 5.38281 17.0781 5.69531C17.2734 5.96875 17.6641 6.00781 17.9766 5.8125L22 3V13L17.9766 10.1875C17.6641 9.99219 17.2734 10.0703 17.0781 10.3438C16.8828 10.6562 16.9609 11.0469 17.2344 11.2422L21.2578 14.0547C21.4922 14.2109 21.7266 14.25 22 14.25C22.1953 14.25 22.3906 14.2109 22.5469 14.1328C22.9766 13.8984 23.25 13.4688 23.25 13V3C23.25 2.53125 22.9766 2.14062 22.5469 1.90625ZM13.25 0.5H3.25C1.84375 0.5 0.75 1.63281 0.75 3V13C0.75 14.4062 1.84375 15.5 3.25 15.5H13.25C14.6172 15.5 15.75 14.4062 15.75 13V3C15.75 1.63281 14.6172 0.5 13.25 0.5ZM14.5 13C14.5 13.7031 13.9141 14.25 13.25 14.25H3.25C2.54688 14.25 2 13.7031 2 13V3C2 2.33594 2.54688 1.75 3.25 1.75H13.25C13.9141 1.75 14.5 2.33594 14.5 3V13Z"
            fill="currentColor"
        />
    )
})

export const VideoIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VideoIconRegular',
    viewBox: '0 0 24 16',
    path: (
        <path
            d="M22.5078 2.02344C22.0781 1.71094 21.4922 1.67188 21.0234 1.90625L17 3.74219V3C17 1.63281 15.8672 0.5 14.5 0.5H3.25C1.84375 0.5 0.75 1.63281 0.75 3V13C0.75 14.4062 1.84375 15.5 3.25 15.5H14.5C15.8672 15.5 17 14.4062 17 13V12.2578L21.0234 14.1328C21.2188 14.2109 21.4531 14.2891 21.6484 14.2891C21.9609 14.2891 22.2734 14.1719 22.5078 14.0156C22.9766 13.7422 23.25 13.2344 23.25 12.6875V3.3125C23.25 2.80469 22.9766 2.29688 22.5078 2.02344ZM15.125 13C15.125 13.3516 14.8125 13.625 14.5 13.625H3.25C2.89844 13.625 2.625 13.3516 2.625 13V3C2.625 2.6875 2.89844 2.375 3.25 2.375H14.5C14.8125 2.375 15.125 2.6875 15.125 3V13ZM21.375 12.2188L17 10.2266V5.8125L21.375 3.82031V12.2188Z"
            fill="currentColor"
        />
    )
})

export const VideoIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VideoIconSolid',
    viewBox: '0 0 24 16',
    path: (
        <path
            d="M15.75 2.375V13.625C15.75 14.6797 14.8906 15.5 13.875 15.5H2.625C1.57031 15.5 0.75 14.6797 0.75 13.625V2.375C0.75 1.35938 1.57031 0.5 2.625 0.5H13.875C14.8906 0.5 15.75 1.35938 15.75 2.375ZM23.25 3V13.0391C23.25 14.0156 22.0781 14.6016 21.2578 14.0547L17 11.0859V4.95312L21.2578 1.98438C22.0781 1.4375 23.25 2.02344 23.25 3Z"
            fill="currentColor"
        />
    )
})

export const VideoSlashIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VideoSlashIconLight',
    viewBox: '0 0 26 20',
    path: (
        <path
            d="M19 7.80382L23.0234 4.99132V14.7179L24.1172 15.5773C24.1953 15.421 24.2734 15.2257 24.2734 14.9913V4.99132C24.2734 4.56163 24 4.13194 23.5703 3.89757C23.1797 3.70225 22.6719 3.70225 22.2812 3.97569L18.2578 6.78819C17.9844 6.9835 17.9062 7.37413 18.1015 7.68663C18.2969 7.96007 18.6875 7.99913 19 7.80382ZM14.2734 3.74132C14.9375 3.74132 15.5234 4.32725 15.5234 4.99132V8.78038L16.7734 9.796V4.99132C16.7734 3.62413 15.6406 2.49132 14.2734 2.49132H7.55467L9.11717 3.74132H14.2734ZM25.25 18.8976L1.49998 0.147567C1.22655 -0.0868083 0.835921 -0.00868335 0.640609 0.264754C0.406234 0.499129 0.484359 0.928817 0.757796 1.12413L24.5078 19.8741C24.625 19.9523 24.7422 19.9913 24.8594 19.9913C25.0156 19.9913 25.2109 19.9132 25.3281 19.7569C25.6015 19.4835 25.5234 19.0929 25.25 18.8976ZM14.2734 16.2413H4.27342C3.5703 16.2413 3.02342 15.6944 3.02342 14.9913V5.30382L1.85155 4.36632C1.77342 4.60069 1.77342 4.796 1.77342 4.99132V14.9913C1.77342 16.3976 2.86717 17.4913 4.27342 17.4913H14.2734C15.289 17.4913 16.1875 16.9054 16.539 16.0069L15.4844 15.1476C15.4062 15.7726 14.8984 16.2413 14.2734 16.2413Z"
            fill="currentColor"
        />
    )
})

export const VideoSlashIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VideoSlashIconRegular',
    viewBox: '0 0 26 20',
    path: (
        <path
            d="M25.1289 18.3529L18.0195 12.7279V7.806L22.3945 5.81381V13.431C22.3945 13.9779 22.7851 14.3685 23.332 14.3685C23.8398 14.3685 24.2695 13.9779 24.2695 13.431V5.306C24.2695 4.79819 23.9961 4.29037 23.5273 4.01694C23.0976 3.70444 22.5117 3.66537 22.0429 3.89975L18.0195 5.73569V4.9935C18.0195 3.62631 16.8867 2.4935 15.5195 2.4935H4.93356L2.00387 0.227873C1.61325 -0.12369 1.02731 -0.0455648 0.714811 0.384123C0.363249 0.774748 0.441374 1.36069 0.871061 1.67319L23.957 19.7591C24.1523 19.9544 24.3476 19.9935 24.5429 19.9935C24.8164 19.9935 25.0898 19.8763 25.2851 19.6419C25.6367 19.2513 25.5586 18.6654 25.1289 18.3529ZM16.1445 11.2826L7.35544 4.3685H15.5195C15.832 4.3685 16.1445 4.681 16.1445 4.9935V11.2826ZM15.5195 15.6185H4.2695C3.91794 15.6185 3.6445 15.3451 3.6445 14.9935V6.20444L1.7695 4.75912C1.7695 4.83725 1.7695 4.91537 1.7695 4.9935V14.9935C1.7695 16.3997 2.86325 17.4935 4.2695 17.4935H15.5195C16.1445 17.4935 16.7304 17.2591 17.1992 16.8685L15.5976 15.6185C15.5586 15.6185 15.5586 15.6185 15.5195 15.6185Z"
            fill="currentColor"
        />
    )
})

export const VideoSlashIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VideoSlashIconSolid',
    viewBox: '0 0 26 20',
    path: (
        <path
            d="M1.77304 15.6316C1.77304 16.6472 2.59335 17.4675 3.60898 17.4675H14.8199C15.5621 17.4675 16.2262 16.9988 16.5387 16.3347L1.77304 4.77222V15.6316ZM25.1324 18.366L21.6559 15.6316L22.2809 16.0613C23.1012 16.6082 24.234 16.0222 24.234 15.0457V5.00659C24.234 4.03003 23.1012 3.44409 22.2809 3.99097L18.023 6.95972V12.741L16.773 11.7644V4.42065C16.773 3.36597 15.9137 2.54565 14.898 2.54565H4.9371L2.00741 0.240967C1.85116 0.0847168 1.65585 0.0065918 1.46054 0.0065918C1.14804 0.0065918 0.874601 0.162842 0.718351 0.397217C0.366789 0.787842 0.444914 1.37378 0.874601 1.68628L23.9605 19.7722C24.3512 20.1238 24.9371 20.0457 25.2496 19.616C25.6402 19.2644 25.5621 18.6785 25.1324 18.366Z"
            fill="currentColor"
        />
    )
})

export const VideoIncomingIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VideoIncomingIconSolid',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 16.5V7.5C15 6.6875 14.3125 6 13.5 6H4.5C3.65625 6 3 6.6875 3 7.5V16.5C3 17.3438 3.65625 18 4.5 18H13.5C14.3125 18 15 17.3438 15 16.5ZM21 16.0312V8C21 7.21875 20.0625 6.75 19.4062 7.1875L16 9.5625V14.4688L19.4062 16.8438C20.0625 17.2812 21 16.8125 21 16.0312ZM10.4 14.6234H7.4C6.86875 14.6234 6.4 14.1547 6.4 13.6234V10.6234C6.4 10.0609 6.86875 9.62344 7.4 9.62344C7.9625 9.62344 8.4 10.0609 8.4 10.6234V11.1859L10.1125 9.50469C10.4875 9.09844 11.1438 9.09844 11.5188 9.50469C11.925 9.87969 11.925 10.5359 11.5188 10.9109L9.8375 12.6234H10.4C10.9625 12.6234 11.4 13.0609 11.4 13.6234C11.4 14.1547 10.9625 14.6234 10.4 14.6234Z"
            fill="currentColor"
        />
    )
})

export const VideoOutgoingIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VideoOutgoingIconSolid',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 7.5V16.5C15 17.3438 14.3125 18 13.5 18H4.5C3.65625 18 3 17.3438 3 16.5V7.5C3 6.6875 3.65625 6 4.5 6H13.5C14.3125 6 15 6.6875 15 7.5ZM21 8V16.0312C21 16.8125 20.0625 17.2812 19.4062 16.8438L16 14.4688V9.5625L19.4062 7.1875C20.0625 6.75 21 7.21875 21 8ZM10.8234 9.2H7.82344C7.26094 9.2 6.82344 9.66875 6.82344 10.2C6.82344 10.7625 7.26094 11.2 7.82344 11.2H8.38594L6.70469 12.9125C6.29844 13.2875 6.29844 13.9438 6.70469 14.3188C7.07969 14.725 7.73594 14.725 8.11094 14.3188L9.82344 12.6375V13.2C9.82344 13.7625 10.2609 14.2 10.8234 14.2C11.3547 14.2 11.8234 13.7625 11.8234 13.2V10.2C11.8234 9.66875 11.3547 9.2 10.8234 9.2Z"
            fill="currentColor"
        />
    )
})

export const VideoMissedIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VideoMissedIconSolid',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M6.88715 7.68293C6.71553 7.85989 6.40089 8.00736 6.05765 7.85989C5.77162 7.74192 5.6 7.47648 5.6 7.12256V3.78982C5.6 3.46539 5.82883 3.19995 6.14346 3.19995H9.40425C9.71888 3.19995 9.97631 3.4064 10.0907 3.70134C10.2337 3.99627 10.1479 4.3207 9.94771 4.55664L9.06101 5.47093L10.6914 7.15205C11.4065 7.88938 12.5792 7.88938 13.2943 7.15205L16.9841 3.34742C17.1558 3.17046 17.4418 3.17046 17.6134 3.34742L18.2713 4.02576C18.4429 4.20272 18.4429 4.49766 18.2713 4.67462L14.5815 8.47925C13.7806 9.30506 12.665 9.71797 11.5209 9.5705C10.6628 9.45253 9.8905 9.01013 9.28983 8.39077L7.77385 6.79813L6.88715 7.68293Z"
                fill="currentColor"
            />
            <path
                d="M14.6667 19.5V11.7C14.6667 10.9958 14.0556 10.4 13.3333 10.4H5.33333C4.58333 10.4 4 10.9958 4 11.7V19.5C4 20.2312 4.58333 20.7999 5.33333 20.7999H13.3333C14.0556 20.7999 14.6667 20.2312 14.6667 19.5Z"
                fill="currentColor"
            />
            <path
                d="M20 19.0937V12.1333C20 11.4562 19.1667 11.05 18.5833 11.4291L15.5556 13.4875V17.7395L18.5833 19.7979C19.1667 20.177 20 19.7708 20 19.0937Z"
                fill="currentColor"
            />
        </g>
    )
})
