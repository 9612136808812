import {
    Spinner as ChakraSpinner,
    SpinnerProps as ChakraSpinnerProps,
    useColorMode
} from '@chakra-ui/react'
import { FC, useMemo } from 'react'

export interface SpinnerProps extends Omit<ChakraSpinnerProps, 'size' | 'variant'> {
    size?: 'sm' | 'md' | 'lg'
    variant?: 'brand' | 'white'
}

export const Spinner: FC<SpinnerProps> = ({ size = 'lg', variant, ...props }) => {
    const { colorMode } = useColorMode()

    const emptyColor =
        variant === 'brand'
            ? `${colorMode}.primary.gamma.alpha-10`
            : `${colorMode}.tones.white.alpha-10`

    const thickness = useMemo(() => {
        switch (size) {
            case 'sm':
                return '2px'
            case 'md':
                return '5px'
            case 'lg':
                return '8px'
        }
    }, [size])

    return (
        <ChakraSpinner
            {...props}
            emptyColor={emptyColor}
            thickness={thickness}
            size={size}
            variant={variant}
        />
    )
}
