import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const EyeIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EyeIconLight',
    viewBox: '0 0 24 18',
    path: (
        <path
            d="M17.0195 9C17.0195 11.7734 14.7539 14 12.0195 14C9.24609 14 7.01953 11.7734 7.01953 9C7.01953 6.26562 9.24609 4 12.0195 4C14.7539 4 17.0195 6.26562 17.0195 9ZM12.0195 5.25C9.91016 5.25 8.26953 6.92969 8.26953 9C8.26953 11.0703 9.91016 12.75 12.0195 12.75C14.0898 12.75 15.7695 11.0703 15.7695 9C15.7695 6.92969 14.0898 5.25 12.0195 5.25ZM19.5195 3.41406C21.3555 5.09375 22.5664 7.125 23.1523 8.53125C23.2695 8.84375 23.2695 9.19531 23.1523 9.50781C22.5664 10.875 21.3555 12.9062 19.5195 14.625C17.6836 16.3438 15.1445 17.75 12.0195 17.75C8.85547 17.75 6.31641 16.3438 4.48047 14.625C2.64453 12.9062 1.43359 10.875 0.847656 9.50781C0.730469 9.19531 0.730469 8.84375 0.847656 8.53125C1.43359 7.125 2.64453 5.09375 4.48047 3.41406C6.31641 1.69531 8.85547 0.25 12.0195 0.25C15.1445 0.25 17.6836 1.69531 19.5195 3.41406ZM2.01953 9C2.52734 10.25 3.66016 12.125 5.33984 13.6875C7.01953 15.25 9.24609 16.5 12.0195 16.5C14.7539 16.5 16.9805 15.25 18.6602 13.6875C20.3398 12.125 21.4727 10.25 22.0195 9C21.4727 7.75 20.3398 5.875 18.6602 4.3125C16.9805 2.75 14.7539 1.5 12.0195 1.5C9.24609 1.5 7.01953 2.75 5.33984 4.3125C3.66016 5.875 2.52734 7.75 2.01953 9Z"
            fill="currentColor"
        />
    )
})

export const EyeIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EyeIconRegular',
    viewBox: '0 0 24 18',
    path: (
        <path
            d="M7.01953 9C7.01953 6.26562 9.24609 4 12.0195 4C14.7539 4 17.0195 6.26562 17.0195 9C17.0195 11.7734 14.7539 14 12.0195 14C9.24609 14 7.01953 11.7734 7.01953 9ZM12.0195 12.125C13.7383 12.125 15.1445 10.7578 15.1445 9C15.1445 7.28125 13.7383 5.875 12.0195 5.875C11.9805 5.875 11.9414 5.875 11.9023 5.875C11.9805 6.10938 12.0195 6.30469 12.0195 6.5C12.0195 7.90625 10.8867 9 9.51953 9C9.28516 9 9.08984 9 8.89453 8.92188C8.89453 8.96094 8.89453 9 8.89453 9C8.89453 10.7578 10.2617 12.125 12.0195 12.125ZM4.48047 3.41406C6.31641 1.69531 8.85547 0.25 12.0195 0.25C15.1445 0.25 17.6836 1.69531 19.5195 3.41406C21.3555 5.09375 22.5664 7.125 23.1523 8.53125C23.2695 8.84375 23.2695 9.19531 23.1523 9.50781C22.5664 10.875 21.3555 12.9062 19.5195 14.625C17.6836 16.3438 15.1445 17.75 12.0195 17.75C8.85547 17.75 6.31641 16.3438 4.48047 14.625C2.64453 12.9062 1.43359 10.875 0.847656 9.50781C0.730469 9.19531 0.730469 8.84375 0.847656 8.53125C1.43359 7.125 2.64453 5.09375 4.48047 3.41406ZM12.0195 2.125C9.44141 2.125 7.37109 3.29688 5.76953 4.78125C4.24609 6.1875 3.23047 7.82812 2.68359 9C3.23047 10.1719 4.24609 11.8516 5.76953 13.2578C7.37109 14.7422 9.44141 15.875 12.0195 15.875C14.5586 15.875 16.6289 14.7422 18.2305 13.2578C19.7539 11.8516 20.7695 10.1719 21.3164 9C20.7695 7.82812 19.7539 6.1875 18.2305 4.78125C16.6289 3.29688 14.5586 2.125 12.0195 2.125Z"
            fill="currentColor"
        />
    )
})

export const EyeIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EyeIconSolid',
    viewBox: '0 0 24 18',
    path: (
        <path
            d="M11.668 5.28906C11.7852 5.28906 11.9023 5.25 12.0195 5.25C14.0898 5.25 15.7695 6.92969 15.7695 9C15.7695 11.0703 14.0898 12.75 12.0195 12.75C9.91016 12.75 8.26953 11.0703 8.26953 9C8.26953 8.92188 8.26953 8.80469 8.26953 8.6875C8.62109 8.88281 9.05078 9 9.51953 9C10.8867 9 12.0195 7.90625 12.0195 6.5C12.0195 6.07031 11.8633 5.64062 11.668 5.28906ZM19.5195 3.41406C21.3555 5.09375 22.5664 7.125 23.1523 8.53125C23.2695 8.84375 23.2695 9.19531 23.1523 9.50781C22.5664 10.875 21.3555 12.9062 19.5195 14.625C17.6836 16.3438 15.1445 17.75 12.0195 17.75C8.85547 17.75 6.31641 16.3438 4.48047 14.625C2.64453 12.9062 1.43359 10.875 0.847656 9.50781C0.730469 9.19531 0.730469 8.84375 0.847656 8.53125C1.43359 7.125 2.64453 5.09375 4.48047 3.41406C6.31641 1.69531 8.85547 0.25 12.0195 0.25C15.1445 0.25 17.6836 1.69531 19.5195 3.41406ZM12.0195 3.375C8.89453 3.375 6.39453 5.91406 6.39453 9C6.39453 12.125 8.89453 14.625 12.0195 14.625C15.1055 14.625 17.6445 12.125 17.6445 9C17.6445 5.91406 15.1055 3.375 12.0195 3.375Z"
            fill="currentColor"
        />
    )
})

export const EyeOffIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EyeOffIconLight',
    viewBox: '0 0 26 20',
    path: (
        <path
            d="M25.2461 18.8951C25.5195 19.0904 25.5977 19.481 25.3633 19.7544C25.168 20.0279 24.7774 20.0669 24.5039 19.8716L0.75392 1.12163C0.480483 0.926314 0.402358 0.535689 0.636733 0.262252C0.832045 -0.0111858 1.22267 -0.0893108 1.49611 0.145064L25.2461 18.8951ZM24.1524 10.4966C23.7617 11.4341 23.0586 12.7232 22.043 13.9732L21.0664 13.1919C22.0039 12.0201 22.6289 10.8873 23.0195 9.98881C22.4727 8.73881 21.3399 6.86381 19.6602 5.30131C17.9805 3.73881 15.7539 2.48881 13.0195 2.48881C11.457 2.48881 10.0508 2.9185 8.83986 3.5435L7.78517 2.68413C9.26955 1.82475 10.9883 1.23881 13.0195 1.23881C16.1445 1.23881 18.6836 2.68413 20.5195 4.40288C22.3555 6.08256 23.5664 8.11381 24.1524 9.52006C24.2695 9.83256 24.2695 10.1841 24.1524 10.4966ZM3.95705 6.0435L4.93361 6.82475C3.99611 7.95756 3.37111 9.12944 2.98048 9.98881C3.52736 11.2388 4.66017 13.1138 6.33986 14.6763C8.01955 16.2388 10.2461 17.4888 12.9805 17.4888C14.543 17.4888 15.9492 17.0982 17.1602 16.4732L18.2149 17.3326C16.7305 18.1919 15.0117 18.7388 12.9805 18.7388C9.85548 18.7388 7.31642 17.3326 5.48048 15.6138C3.64455 13.8951 2.43361 11.8638 1.84767 10.4966C1.73048 10.1841 1.73048 9.83256 1.84767 9.52006C2.2383 8.58256 2.94142 7.2935 3.95705 6.0435ZM7.98048 9.98881C7.98048 9.75444 8.01955 9.52006 8.05861 9.28569L9.26955 10.2232C9.38673 12.2154 10.9883 13.7388 12.9805 13.7388C13.2149 13.7388 13.4492 13.7388 13.6445 13.6998L14.8555 14.6373C14.2695 14.8716 13.6445 14.9888 12.9805 14.9888C10.2461 14.9888 7.98048 12.7623 7.98048 9.98881ZM18.0195 9.98881C18.0195 10.2623 17.9805 10.4966 17.9414 10.731L16.7305 9.7935C16.6133 7.80131 15.0117 6.23881 12.9805 6.23881C12.7852 6.23881 12.5508 6.27788 12.3555 6.31694L11.1445 5.37944C11.7305 5.14506 12.3555 4.98881 12.9805 4.98881C15.7539 4.98881 18.0195 7.25444 18.0195 9.98881Z"
            fill="currentColor"
        />
    )
})

export const EyeOffIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EyeOffIconRegular',
    viewBox: '0 0 26 22',
    path: (
        <path
            d="M6.3789 4.61331C8.13672 3.28518 10.3242 2.2305 13.0195 2.2305C16.1445 2.2305 18.6836 3.67581 20.5195 5.39456C22.3555 7.07425 23.5664 9.1055 24.1523 10.5117C24.2695 10.8242 24.2695 11.1758 24.1523 11.4883C23.6445 12.7383 22.5898 14.5352 21.0273 16.0977L25.1289 19.3399C25.5586 19.6524 25.6367 20.2383 25.2852 20.6289C24.9727 21.0586 24.3867 21.1367 23.9961 20.7852L0.871092 2.66018C0.441404 2.34768 0.363279 1.76175 0.714842 1.37112C1.02734 0.941435 1.61328 0.86331 2.0039 1.21487L6.3789 4.61331ZM7.90234 5.82425L9.69922 7.2305C10.5977 6.44925 11.7305 5.9805 13.0195 5.9805C15.7539 5.9805 18.0195 8.24612 18.0195 10.9805C18.0195 11.8399 17.7852 12.6211 17.4336 13.2852L19.543 14.9258C20.8711 13.5977 21.8086 12.0742 22.3164 10.9805C21.7695 9.80862 20.7539 8.168 19.2305 6.76175C17.6289 5.27737 15.5586 4.1055 13.0195 4.1055C11.0273 4.1055 9.30859 4.80862 7.90234 5.82425ZM15.9102 12.1133C16.0664 11.7617 16.1445 11.3711 16.1445 10.9805C16.1445 9.26175 14.7383 7.8555 13.0195 7.8555C12.9805 7.8555 12.9414 7.8555 12.9023 7.8555C12.9805 8.08987 13.0195 8.28518 13.0195 8.4805C13.0195 8.91018 12.9023 9.26175 12.7461 9.61331L15.9102 12.1133ZM16.3008 17.1914L17.9414 18.4805C16.4961 19.2617 14.8555 19.7305 13.0195 19.7305C9.85547 19.7305 7.3164 18.3242 5.48047 16.6055C3.64453 14.8867 2.43359 12.8555 1.84765 11.4883C1.73047 11.1758 1.73047 10.8242 1.84765 10.5117C2.23828 9.61331 2.86328 8.4805 3.76172 7.30862L5.20703 8.4805C4.5039 9.37893 3.99609 10.2774 3.68359 10.9805C4.23047 12.1524 5.24609 13.8321 6.76953 15.2383C8.37109 16.7227 10.4414 17.8555 13.0195 17.8555C14.1914 17.8555 15.2852 17.6211 16.3008 17.1914ZM8.01953 10.9805C8.01953 10.9024 8.01953 10.7852 8.01953 10.668L10.207 12.3867C10.5977 13.2071 11.3789 13.8321 12.3164 14.0274L14.5039 15.7852C14.0352 15.9024 13.5273 15.9805 12.9805 15.9805C10.2461 15.9805 7.98047 13.7539 7.98047 10.9805H8.01953Z"
            fill="currentColor"
        />
    )
})

export const EyeOffIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EyeOffIconSolid',
    viewBox: '0 0 26 22',
    path: (
        <path
            d="M6.3789 4.61331C8.13672 3.28518 10.3242 2.2305 13.0195 2.2305C16.1445 2.2305 18.6836 3.67581 20.5195 5.39456C22.3555 7.07425 23.5664 9.1055 24.1523 10.5117C24.2695 10.8242 24.2695 11.1758 24.1523 11.4883C23.6445 12.7383 22.5898 14.5352 21.0273 16.0977L25.1289 19.3399C25.5586 19.6524 25.6367 20.2383 25.2852 20.6289C24.9727 21.0586 24.3867 21.1367 23.9961 20.7852L0.871092 2.66018C0.441404 2.34768 0.363279 1.76175 0.714842 1.37112C1.02734 0.941435 1.61328 0.86331 2.0039 1.21487L6.3789 4.61331ZM9.23047 6.83987L12.7461 9.61331C12.9023 9.26175 13.0195 8.91018 13.0195 8.4805C13.0195 8.05081 12.8633 7.62112 12.668 7.26956C12.7852 7.26956 12.9023 7.2305 13.0195 7.2305C15.0898 7.2305 16.7695 8.91018 16.7695 10.9805C16.7695 11.5274 16.6523 12.0352 16.418 12.5039L17.9414 13.6758C18.3711 12.8946 18.6445 11.9571 18.6445 10.9805C18.6445 7.89456 16.1055 5.3555 13.0195 5.3555C11.5352 5.3555 10.207 5.94143 9.23047 6.83987ZM13.0195 19.7305C9.85547 19.7305 7.3164 18.3242 5.48047 16.6055C3.64453 14.8867 2.43359 12.8555 1.84765 11.4883C1.73047 11.1758 1.73047 10.8242 1.84765 10.5117C2.23828 9.61331 2.86328 8.4805 3.76172 7.30862L7.43359 10.1992C7.39453 10.4727 7.39453 10.7461 7.39453 10.9805C7.39453 14.1055 9.89453 16.6055 13.0195 16.6055C13.7227 16.6055 14.4258 16.4883 15.0898 16.2149L17.9414 18.4805C16.4961 19.2617 14.8555 19.7305 13.0195 19.7305Z"
            fill="currentColor"
        />
    )
})
