import { Collapse } from '@chakra-ui/react'
import {
    call,
    selectLastCall,
    selectPresentationNumber,
    setPresentationNumber,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import {
    Scrollable,
    useDebouncedFunction,
    useFormatToNumberE164,
    useMeasure,
    VirtualPaginationContextProvider
} from '@missionlabs/react'
import { DirectoryEntry } from '@missionlabs/types'
import isElectron from 'is-electron'
import { useCallback, useEffect, useMemo } from 'react'
import { SearchInput } from 'shared/components/SearchInput'
import { useClickToDial } from 'shared/hooks/useClickToDial'
import { useGetUserData } from 'shared/hooks/useGetUserData'

import { DrawerMode } from './ContactDrawer'
import { DiallerContacts } from './DiallerContacts'
import { DiallerOptions } from './DiallerOptions'
import { Dialpad } from './Dialpad/Dialpad'
import { useContactDrawerState } from './hooks/useContactDrawerState'
import { NoContacts } from './NoContacts'
import { RecentActivities } from './RecentActivities/RecentActivities'

export const DiallerView = () => {
    const [dialpadRef, { blockSize: dialpadHeight }] = useMeasure<HTMLDivElement>()
    const dispatch = useDispatch()
    const presentationNumber = useSelector(selectPresentationNumber)
    const {
        value: searchValue,
        setValue,
        searchContacts,
        contacts,
        setFoundContact,
        calls,
        setMode
    } = useContactDrawerState()

    const handleSearchChange = useCallback(
        (value: string) => {
            setValue(value)
            // TODO: We should probably do this within the useContactDrawerState hook
            if (value.length > 3) searchContacts({ searchTerm: value })
        },
        [searchContacts, setValue]
    )

    const handleActivityClick = useCallback(
        (numberE164: string, contact?: DirectoryEntry) => {
            setValue(numberE164)
            setFoundContact(contact)
        },
        [setValue, setFoundContact]
    )

    /** Does the search value contain any alpha characters? Or just numeric? */
    const isAlphaSearch = useMemo(() => /[a-zA-Z]/.test(searchValue), [searchValue])

    const handleNumberChange = (value: string) => {
        dispatch(setPresentationNumber(value))
    }

    const lastCall = useSelector(selectLastCall)
    const { formatToLocalNumber, formatToNumberE164 } = useFormatToNumberE164()
    const { callRoute } = useGetUserData()

    const fromNumber = useMemo(
        () => presentationNumber ?? callRoute?.defaultPhoneNumber ?? '',
        [presentationNumber, callRoute?.defaultPhoneNumber]
    )

    function _handleCall(number: string, contact?: DirectoryEntry) {
        if (!fromNumber) return
        dispatch(call({ to: formatToNumberE164(number), from: fromNumber, contact }))
        setMode(DrawerMode.VOICE)
        if (calls.length) setValue('')
    }
    const handleCall = useDebouncedFunction(_handleCall, 200)

    function handleDialpadChange(key: string) {
        if (key === 'retrieveCall') {
            setMode(DrawerMode.RETRIEVE_CALL)
            return
        }
        // Dial the number that has been input
        if (key === 'call' && searchValue) return handleCall(searchValue)

        // Populate the last dialled number into the dialpad
        if (key === 'call' && lastCall) {
            setValue(formatToLocalNumber(lastCall.remoteNumber))
            return
        }

        if (key !== 'call') {
            setValue((previous = '') => `${previous}${key}`)
        }
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            event.stopPropagation()
            handleDialpadChange('call')
        }
    }

    useEffect(() => {
        if (searchValue === 'debug' && isElectron()) {
            window.openDebug()
        }
    }, [searchValue])

    useClickToDial(_handleCall)

    return (
        <>
            <div ref={dialpadRef} style={{ width: '100%' }}>
                <DiallerOptions selected={presentationNumber} onChange={handleNumberChange} />
                <SearchInput
                    size="sm"
                    variant="dark"
                    w="full"
                    value={searchValue}
                    onChange={e => handleSearchChange(e.target.value)}
                    placeholder="Number, name or ext"
                    data-testid="voice-input"
                    hideFilters
                    hideFavourites
                    sx={{ py: '10px' }}
                    onKeyDown={handleKeyDown}
                />
            </div>

            <Scrollable h={`calc(100dvh - ${dialpadHeight + 48}px)`} w="full">
                {/* Hide the dialpad if search value contains any non-numeric characters */}
                <Collapse in={!isAlphaSearch} style={{ flexShrink: 0 }}>
                    <Dialpad handleDialpadChange={handleDialpadChange} />
                </Collapse>

                {searchValue.length > 3 &&
                    (contacts.length || isAlphaSearch ? (
                        <DiallerContacts />
                    ) : (
                        <NoContacts
                            presentationNumber={presentationNumber}
                            searchedNumber={searchValue}
                        />
                    ))}

                {/* Replace recent activities with contacts when user has searched */}
                <Collapse in={searchValue.length <= 3} style={{ width: '100%' }}>
                    <VirtualPaginationContextProvider pageSize={100}>
                        <RecentActivities
                            handleActivityClick={handleActivityClick}
                            siblingHeight={dialpadHeight + 48}
                        />
                    </VirtualPaginationContextProvider>
                </Collapse>
            </Scrollable>
        </>
    )
}
