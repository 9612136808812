import { PreferencesState } from '@missionlabs/api'
import { MediaDevice } from '@missionlabs/types'

// Reset preferences, pointing to any unavailable devices, to next available device of kind.
export function resetPreferences(devices: MediaDevice[], prefs: PreferencesState = {}) {
    const devicesToRestore = JSON.parse(
        window.localStorage.getItem(`userAudioPreferencesID=${prefs.userID ?? 'guest'}`) ?? '{}'
    )

    function isDefault(deviceID: string) {
        return deviceID === 'default'
    }

    function isUnavailable(deviceID?: string) {
        return deviceID && !deviceIds.includes(deviceID)
    }

    function getPreviouslyDesiredDevice(identifier: string, kind: MediaDeviceKind) {
        const previouslyUsedDevice = deviceIds.find(
            deviceID => deviceID === devicesToRestore?.[identifier]
        )

        return previouslyUsedDevice ?? getNextAvailableDevice(kind)
    }

    function getNextAvailableDevice(kind: MediaDeviceKind) {
        return devices.find(device => device.kind === kind)?.deviceId
    }

    const defaultPrefs: Partial<PreferencesState> = {}
    // IDs of all devices that are not set to 'default' or undefined (i.e, explicitly assigned).
    const deviceIds = devices
        .map(device => (!isDefault(device.deviceId) ? device.deviceId : undefined))
        .filter((deviceId): deviceId is string => !!deviceId)

    if (isUnavailable(prefs.cameraDeviceID)) {
        defaultPrefs.cameraDeviceID = getNextAvailableDevice('videoinput')
    }

    if (isUnavailable(prefs.inputDeviceID)) {
        defaultPrefs.inputDeviceID = getPreviouslyDesiredDevice('inputDeviceID', 'audioinput')
    }

    if (isUnavailable(prefs.outputDeviceID)) {
        defaultPrefs.outputDeviceID = getPreviouslyDesiredDevice('outputDeviceID', 'audiooutput')
    }

    if (isUnavailable(prefs.ringingOutputDeviceID)) {
        defaultPrefs.ringingOutputDeviceID = getPreviouslyDesiredDevice(
            'ringingOutputDeviceID',
            'audiooutput'
        )
    }

    return defaultPrefs
}
