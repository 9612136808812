import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const TextFormatIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TextFormatIconLight',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M17.75 0.875V3.375C17.75 3.72656 17.4375 4 17.125 4C16.7734 4 16.5 3.72656 16.5 3.375V1.5H9.625V16.5H12.125C12.4375 16.5 12.75 16.8125 12.75 17.125C12.75 17.4375 12.4375 17.75 12.125 17.75H5.875C5.52344 17.75 5.25 17.4375 5.25 17.125C5.25 16.8125 5.52344 16.5 5.83594 16.5H8.375V1.5H1.5V3.375C1.5 3.72656 1.1875 4 0.875 4C0.523438 4 0.25 3.72656 0.25 3.375V0.875C0.25 0.5625 0.523438 0.25 0.875 0.25H17.125C17.4375 0.25 17.75 0.5625 17.75 0.875Z"
            fill="currentColor"
        />
    )
})

export const TextFormatIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TextFormatIconRegular',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M17.75 1.1875V4.3125C17.75 4.85938 17.3203 5.25 16.8125 5.25C16.2656 5.25 15.875 4.85938 15.875 4.3125V2.125H9.9375V15.875H11.8125C12.3203 15.875 12.75 16.3047 12.75 16.8125C12.75 17.3594 12.3203 17.75 11.8125 17.75H6.1875C5.64062 17.75 5.25 17.3594 5.25 16.8125C5.25 16.3047 5.64062 15.875 6.1875 15.875H8.0625V2.125H2.125V4.3125C2.125 4.85938 1.69531 5.25 1.1875 5.25C0.640625 5.25 0.25 4.85938 0.25 4.3125V1.1875C0.25 0.679688 0.640625 0.25 1.1875 0.25H16.8125C17.3203 0.25 17.75 0.679688 17.75 1.1875Z"
            fill="currentColor"
        />
    )
})

export const TextFormatIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TextFormatIconSolid',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M17.75 1.51953V4.01953C17.75 4.68359 17.1641 5.26953 16.5 5.26953C15.7969 5.26953 15.25 4.68359 15.25 4.01953V2.76953H10.25V15.2695H12.125C12.7891 15.2695 13.375 15.8164 13.375 16.5195C13.375 17.1836 12.7891 17.7305 12.125 17.7305H5.875C5.17188 17.7305 4.625 17.1445 4.625 16.5195C4.625 15.8555 5.17188 15.2695 5.875 15.2695H7.75V2.76953H2.75V4.01953C2.75 4.68359 2.16406 5.26953 1.5 5.26953C0.796875 5.26953 0.25 4.68359 0.25 4.01953V1.51953C0.25 0.816406 0.796875 0.269531 1.5 0.269531H16.5C17.1641 0.269531 17.75 0.816406 17.75 1.51953Z"
            fill="currentColor"
        />
    )
})
