import { VStack } from '@chakra-ui/react'
import { VirtualPaginationContextProvider } from '@missionlabs/react'
import { ContactType, DirectoryEntry } from '@missionlabs/types'
import { ActivityFeedContent } from 'features/activity/ActivityFeed/ActivityFeedContent'
import { AddNumberToContactDrawerProvider } from 'features/activity/ActivityFeed/components/AddNumberToContact/useAddNumberToContactDrawer'
import { ActivityFilterProvider } from 'features/activity/ActivityFeed/hooks/useActivityFeedFilters'
import { ActivityFooterProvider } from 'features/activity/ActivityFeed/hooks/useActivityFooterState'
import { useMemo } from 'react'
import { ActivityContactEntity } from 'shared/utils/activities'

export interface ContactFeedTabProps {
    contact: DirectoryEntry
    type: ContactType
}

export const ContactFeedTab = ({ contact }: ContactFeedTabProps) => {
    const entity: ActivityContactEntity = useMemo(
        () => ({ type: 'contact', contactID: contact.ID, contact }),
        [contact]
    )
    return (
        <>
            <VStack spacing="24px" justify="space-between" w="100%" pt={4} h="full">
                <VirtualPaginationContextProvider pageSize={100} direction="up">
                    <AddNumberToContactDrawerProvider>
                        <ActivityFooterProvider>
                            <ActivityFilterProvider key={contact.ID}>
                                <ActivityFeedContent
                                    key={contact.ID}
                                    siblingHeight={0}
                                    entity={entity}
                                    selectedActivity={undefined}
                                />
                            </ActivityFilterProvider>
                        </ActivityFooterProvider>
                    </AddNumberToContactDrawerProvider>
                </VirtualPaginationContextProvider>
            </VStack>
        </>
    )
}
