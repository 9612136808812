import { VStack } from '@chakra-ui/react'
import { selectAuthenticatedUser, selectInData, useSelector } from '@missionlabs/api'
import { Body, Button } from '@missionlabs/react'
import { AddCircleIcon } from '@missionlabs/react/circleloop'
import { Destination, DestinationTypes } from '@missionlabs/types'
import { SettingsTitle } from 'features/settings/components/SettingsTitle'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetDestinationsForUserQuery } from 'shared/store'

import { DevicesTable } from './DevicesTable'

const allowedDestinations = [
    DestinationTypes.desktopApp,
    DestinationTypes.webApp,
    DestinationTypes.android,
    DestinationTypes.ios
]

const selectDestinations = selectInData<Destination[]>(data =>
    data?.filter(item => allowedDestinations.includes(item.type))
)

const selectManualDestinations = selectInData<Destination[]>(data =>
    data?.filter(item => !allowedDestinations.includes(item.type))
)

export const Devices: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const user = useSelector(selectAuthenticatedUser)

    const {
        destinations = [],
        manualDestinations = [],
        isLoading
    } = useGetDestinationsForUserQuery(user?.userID ?? '', {
        skip: !user,
        selectFromResult: result => ({
            ...result,
            destinations: selectDestinations(result),
            manualDestinations: selectManualDestinations(result)
        })
    })

    return (
        <SettingsPage
            title={t('devices.Devices')}
            actions={
                <Button
                    variant="creationary"
                    leftIcon={<AddCircleIcon />}
                    onClick={() => navigate('/settings/devices/add')}
                >
                    {t('devices.addDevice')}
                </Button>
            }
        >
            <SettingsTitle label={t('settings.automaticallyAddedDevices')} divider={false} />
            <DevicesTable data={destinations} isLoading={isLoading} />
            <VStack spacing={0} align="inherit">
                <SettingsTitle label={t('settings.manuallyAddedDevices')} divider={false} />
                <Body>{t('settings.manuallyAddedDevicesSubtext')}</Body>
            </VStack>
            <DevicesTable data={manualDestinations} isLoading={isLoading} />
        </SettingsPage>
    )
}
