import { Center, HStack, Spacer, useColorMode } from '@chakra-ui/react'
import { selectPreferences, useSelector } from '@missionlabs/api'
import { Heading, IconButton } from '@missionlabs/react'
import { MicIcon, StopIcon } from '@missionlabs/react/circleloop'
import { formatDuration } from '@missionlabs/utils'
import { FC, useEffect, useState } from 'react'

import { AudioVisualiser } from './AudioVisualiser'

interface AudioRecorderProps {
    onEnd: (file: File) => void
}

export const AudioRecorder: FC<AudioRecorderProps> = ({ onEnd }) => {
    const [audioContext] = useState(new AudioContext())
    const [analyser, setAnalyser] = useState<AnalyserNode>()
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>()
    const [chunks, setChunks] = useState<Blob[]>([])
    const [timer, setTimer] = useState<number>(0)
    const [timeout, setTimeout] = useState<NodeJS.Timeout>()
    const [isRecording, setIsRecording] = useState<boolean>(false)

    const preferences = useSelector(selectPreferences)

    const { colorMode } = useColorMode()

    const onStart = () => {
        if (!preferences) return

        navigator.mediaDevices
            .getUserMedia({
                audio: { deviceId: { ideal: preferences?.inputDeviceID ?? 'default' } }
            })
            .then(stream => {
                const source = audioContext.createMediaStreamSource(stream)
                const analyser = audioContext.createAnalyser()
                const mediaRecorder = new MediaRecorder(stream)

                setAnalyser(analyser)
                setMediaRecorder(mediaRecorder)

                source.connect(analyser)

                mediaRecorder.onerror = console.error
                mediaRecorder.onstop = console.log
                mediaRecorder.ondataavailable = e => setChunks(chunks => [...chunks, e.data])

                mediaRecorder.start(1000)
                setIsRecording(true)

                const timeout = setInterval(() => setTimer(t => t + 1000), 1000)
                setTimeout(timeout)
            })
    }

    const onStop = () => {
        if (!mediaRecorder) return
        mediaRecorder.stop()
        setIsRecording(false)

        clearInterval(timeout)
        setTimeout(undefined)
        setTimer(0)

        onEnd(new File(chunks, 'New Recording', { type: 'audio/webm' }))
        setChunks([])

        setMediaRecorder(undefined)
        setAnalyser(undefined)
    }

    useEffect(() => {
        // 60 seconds max.
        if (timer >= 59000) onStop()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timer])

    return (
        <HStack spacing="0" w="full" h="48px" bg={`${colorMode}.coolGrey.0`}>
            <IconButton
                size="lg"
                variant="negatory"
                icon={<MicIcon />}
                aria-label="start recording"
                borderTopRightRadius="0px !important"
                borderBottomRightRadius="0px !important"
                borderRight="1px solid"
                borderColor={`${colorMode}.coolGrey.30`}
                onClick={onStart}
                isDisabled={isRecording}
            />
            <Center w="350px" h="full" pl="16px" py="8px">
                {isRecording && <AudioVisualiser analyser={analyser} height={48} width={326} />}
            </Center>
            <Spacer />
            <Center h="full" p="16px">
                <Heading size="h5" color={`${colorMode}.coolGrey.80`}>
                    {formatDuration(timer)}
                </Heading>
            </Center>
            <IconButton
                size="lg"
                variant="tertiary"
                icon={<StopIcon />}
                aria-label="stop recording"
                borderTopLeftRadius="0px !important"
                borderBottomLeftRadius="0px !important"
                borderLeft="1px solid"
                borderColor={`${colorMode}.coolGrey.30`}
                onClick={onStop}
                isDisabled={!isRecording}
            />
        </HStack>
    )
}
