import { Box, useDisclosure } from '@chakra-ui/react'
import { getSelectedOptionLabel } from '@missionlabs/utils'
import { Input } from 'atoms'
import { SearchIconRegular } from 'atoms/Icons/zeta'
import { matchSorter } from 'match-sorter'
import { DropdownProps, Select, SelectOption } from 'molecules'
import { ChangeEvent, forwardRef, useState } from 'react'

export const Combobox = forwardRef<HTMLInputElement, DropdownProps>(
    ({ placeholder, isDisabled, isInvalid, variant, ...props }, ref) => {
        const disclosure = useDisclosure()

        const display = getSelectedOptionLabel(props.options, props.value) ?? ''

        const [input, setInput] = useState<string>('')
        const [options, setOptions] = useState(props.options)

        function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
            const value = e.target.value
            setInput(e.target.value)

            const fuzzy = matchSorter(props.options, value, { keys: ['label'], sorter: i => i })
            if (fuzzy.length > 0) setOptions(fuzzy)
            else setOptions(props.options)
            disclosure.onOpen()
        }

        function handleSelectChange(value: SelectOption['value']) {
            setInput('')
            props.onChange?.(value)
            disclosure.onClose()
        }

        return (
            <Box
                w={props.w || props.width}
                maxW={props.maxW || props.maxWidth}
                minW={props.minW || props.maxWidth}
            >
                <Select
                    {...props}
                    options={options}
                    variant={variant}
                    disclosure={disclosure}
                    isDisabled={isDisabled}
                    onChange={handleSelectChange}
                >
                    <Input
                        ref={ref}
                        variant={variant}
                        value={disclosure.isOpen ? input : display}
                        placeholder={placeholder}
                        isDisabled={isDisabled}
                        isInvalid={isInvalid}
                        rightIcon={<SearchIconRegular boxSize="20px" />}
                        onChange={handleInputChange}
                        aria-labelledby={props['aria-labelledby']}
                        // aria-labelledby takes precedence over aria-label
                        aria-label={props['aria-label']}
                    />
                </Select>
            </Box>
        )
    }
)
