import { Currency } from './billing'
import { HuntGroup } from './hunt-groups'
import { IVRMenu } from './ivr-menus'
import { InheritedFromType } from './location'
import { Pod } from './pod'
import { User } from './user'
import { UserGroup } from './userGroups'

export interface AvailableNumber {
    ID: string
    numberE164: string
    status: string
    bucket: string
    price: number
    localisedPrices: { currencyCode: keyof typeof Currency; price: number }[]
}

/** zeta types **/

export type NumberE164 = {
    ID: string
    bucket: string
    callRouteID: string
    clientAddOnID: string
    clientID: string
    created: string
    displayNumber?: string
    displayNumberType: string
    numberE164: string
    object: string
    price: number
    provider: string
    scheduledChanges: any //for now
    status: string
    type: string
    presentationType?: string // | 'pod' | 'ivr' | 'user' | 'unassigned' | 'hunt-group'
    updated: string
    user?: User
    userID?: string
    teamID?: string
    userGroupID?: string
    callGroupID?: string
    pod?: Pod
    team?: HuntGroup | IVRMenu
    userGroup?: UserGroup
    isDefaultClientNumber?: boolean
    alphaTag?: string
}

export type InheritedNumber = NumberE164 & { inheritedFrom: InheritedFromType }

/** numbersAPI types **/

export interface NumbersPhoneNumber {
    ID: string
    displayNumberType: string
    isDefault: boolean
    numberE164: string
}

export interface NumbersTeam {
    ID: string
    name: string
    phoneNumbers?: NumbersPhoneNumber[]
}

export interface NumbersResponse {
    phoneNumbers?: NumbersPhoneNumber[]
    teams?: NumbersTeam[]
}

export enum NumberPortabilityProbability {
    NO = 'N',
    LIKELY = 'Likely',
    YES = 'Y'
}

export interface NumberPortability {
    portable: NumberPortabilityProbability
    isMobile: boolean
    rangeHolder: string
}
