import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const BuildingSingleIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BuildingSingleIconLight',
    viewBox: '0 0 16 20',
    path: (
        <path
            d="M3 4.6875C3 4.17969 3.39062 3.75 3.9375 3.75H5.8125C6.32031 3.75 6.75 4.17969 6.75 4.6875V6.5625C6.75 7.10938 6.32031 7.5 5.8125 7.5H3.9375C3.39062 7.5 3 7.10938 3 6.5625V4.6875ZM4.25 5V6.25H5.5V5H4.25ZM12.0625 3.75C12.5703 3.75 13 4.17969 13 4.6875V6.5625C13 7.10938 12.5703 7.5 12.0625 7.5H10.1875C9.64062 7.5 9.25 7.10938 9.25 6.5625V4.6875C9.25 4.17969 9.64062 3.75 10.1875 3.75H12.0625ZM11.75 6.25V5H10.5V6.25H11.75ZM3 9.6875C3 9.17969 3.39062 8.75 3.9375 8.75H5.8125C6.32031 8.75 6.75 9.17969 6.75 9.6875V11.5625C6.75 12.1094 6.32031 12.5 5.8125 12.5H3.9375C3.39062 12.5 3 12.1094 3 11.5625V9.6875ZM4.25 10V11.25H5.5V10H4.25ZM12.0625 8.75C12.5703 8.75 13 9.17969 13 9.6875V11.5625C13 12.1094 12.5703 12.5 12.0625 12.5H10.1875C9.64062 12.5 9.25 12.1094 9.25 11.5625V9.6875C9.25 9.17969 9.64062 8.75 10.1875 8.75H12.0625ZM11.75 11.25V10H10.5V11.25H11.75ZM3 20C1.59375 20 0.5 18.9062 0.5 17.5V2.5C0.5 1.13281 1.59375 0 3 0H13C14.3672 0 15.5 1.13281 15.5 2.5V17.5C15.5 18.9062 14.3672 20 13 20H3ZM1.75 2.5V17.5C1.75 18.2031 2.29688 18.75 3 18.75H5.5V16.25C5.5 14.8828 6.59375 13.75 8 13.75C9.36719 13.75 10.5 14.8828 10.5 16.25V18.75H13C13.6641 18.75 14.25 18.2031 14.25 17.5V2.5C14.25 1.83594 13.6641 1.25 13 1.25H3C2.29688 1.25 1.75 1.83594 1.75 2.5ZM9.25 16.25C9.25 15.5859 8.66406 15 8 15C7.29688 15 6.75 15.5859 6.75 16.25V18.75H9.25V16.25Z"
            fill="currentColor"
        />
    )
})

export const BuildingSingleIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BuildingSingleIconRegular',
    viewBox: '0 0 16 20',
    path: (
        <path
            d="M3.9375 4.0625C3.9375 3.75 4.21094 3.4375 4.5625 3.4375H6.4375C6.75 3.4375 7.0625 3.75 7.0625 4.0625V5.9375C7.0625 6.28906 6.75 6.5625 6.4375 6.5625H4.5625C4.21094 6.5625 3.9375 6.28906 3.9375 5.9375V4.0625ZM11.4375 3.4375C11.75 3.4375 12.0625 3.75 12.0625 4.0625V5.9375C12.0625 6.28906 11.75 6.5625 11.4375 6.5625H9.5625C9.21094 6.5625 8.9375 6.28906 8.9375 5.9375V4.0625C8.9375 3.75 9.21094 3.4375 9.5625 3.4375H11.4375ZM3.9375 9.0625C3.9375 8.75 4.21094 8.4375 4.5625 8.4375H6.4375C6.75 8.4375 7.0625 8.75 7.0625 9.0625V10.9375C7.0625 11.2891 6.75 11.5625 6.4375 11.5625H4.5625C4.21094 11.5625 3.9375 11.2891 3.9375 10.9375V9.0625ZM11.4375 8.4375C11.75 8.4375 12.0625 8.75 12.0625 9.0625V10.9375C12.0625 11.2891 11.75 11.5625 11.4375 11.5625H9.5625C9.21094 11.5625 8.9375 11.2891 8.9375 10.9375V9.0625C8.9375 8.75 9.21094 8.4375 9.5625 8.4375H11.4375ZM0.5 2.5C0.5 1.13281 1.59375 0 3 0H13C14.3672 0 15.5 1.13281 15.5 2.5V17.5C15.5 18.9062 14.3672 20 13 20H3C1.59375 20 0.5 18.9062 0.5 17.5V2.5ZM2.375 2.5V17.5C2.375 17.8516 2.64844 18.125 3 18.125H6.125V15.625C6.125 14.6094 6.94531 13.75 8 13.75C9.01562 13.75 9.875 14.6094 9.875 15.625V18.125H13C13.3125 18.125 13.625 17.8516 13.625 17.5V2.5C13.625 2.1875 13.3125 1.875 13 1.875H3C2.64844 1.875 2.375 2.1875 2.375 2.5Z"
            fill="currentColor"
        />
    )
})

export const BuildingSingleIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BuildingSingleIconSolid',
    viewBox: '0 0 16 20',
    path: (
        <path
            d="M13.625 0C14.6406 0 15.5 0.859375 15.5 1.875V18.125C15.5 19.1797 14.6406 20 13.625 20H9.875V16.875C9.875 15.8594 9.01562 15 8 15C6.94531 15 6.125 15.8594 6.125 16.875V20H2.375C1.32031 20 0.5 19.1797 0.5 18.125V1.875C0.5 0.859375 1.32031 0 2.375 0H13.625ZM3 10.625C3 10.9766 3.27344 11.25 3.625 11.25H4.875C5.1875 11.25 5.5 10.9766 5.5 10.625V9.375C5.5 9.0625 5.1875 8.75 4.875 8.75H3.625C3.27344 8.75 3 9.0625 3 9.375V10.625ZM7.375 8.75C7.02344 8.75 6.75 9.0625 6.75 9.375V10.625C6.75 10.9766 7.02344 11.25 7.375 11.25H8.625C8.9375 11.25 9.25 10.9766 9.25 10.625V9.375C9.25 9.0625 8.9375 8.75 8.625 8.75H7.375ZM10.5 10.625C10.5 10.9766 10.7734 11.25 11.125 11.25H12.375C12.6875 11.25 13 10.9766 13 10.625V9.375C13 9.0625 12.6875 8.75 12.375 8.75H11.125C10.7734 8.75 10.5 9.0625 10.5 9.375V10.625ZM3.625 3.75C3.27344 3.75 3 4.0625 3 4.375V5.625C3 5.97656 3.27344 6.25 3.625 6.25H4.875C5.1875 6.25 5.5 5.97656 5.5 5.625V4.375C5.5 4.0625 5.1875 3.75 4.875 3.75H3.625ZM6.75 5.625C6.75 5.97656 7.02344 6.25 7.375 6.25H8.625C8.9375 6.25 9.25 5.97656 9.25 5.625V4.375C9.25 4.0625 8.9375 3.75 8.625 3.75H7.375C7.02344 3.75 6.75 4.0625 6.75 4.375V5.625ZM11.125 3.75C10.7734 3.75 10.5 4.0625 10.5 4.375V5.625C10.5 5.97656 10.7734 6.25 11.125 6.25H12.375C12.6875 6.25 13 5.97656 13 5.625V4.375C13 4.0625 12.6875 3.75 12.375 3.75H11.125Z"
            fill="currentColor"
        />
    )
})

export const BuildingDoubleIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BuildingDoubleIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M7.5 13.4375C7.5 12.918 7.08203 12.5 6.5625 12.5H4.6875C4.16797 12.5 3.75 12.918 3.75 13.4375V15.3125C3.75 15.832 4.16797 16.25 4.6875 16.25H6.5625C7.08203 16.25 7.5 15.832 7.5 15.3125V13.4375ZM5 15V13.75H6.25V15H5ZM7.5 3.4375C7.5 2.91992 7.08203 2.5 6.5625 2.5H4.6875C4.16797 2.5 3.75 2.91992 3.75 3.4375V5.3125C3.75 5.83203 4.16797 6.25 4.6875 6.25H6.5625C7.08203 6.25 7.5 5.83203 7.5 5.3125V3.4375ZM6.25 3.75V5H5V3.75H6.25ZM6.5625 11.25C7.08203 11.25 7.5 10.832 7.5 10.3125V8.4375C7.5 7.91797 7.08203 7.5 6.5625 7.5H4.6875C4.16797 7.5 3.75 7.91797 3.75 8.4375V10.3125C3.75 10.832 4.16797 11.25 4.6875 11.25H6.5625ZM6.25 8.75V10H5V8.75H6.25ZM2.5 0C1.12109 0 0 1.11914 0 2.5V17.5C0 18.8789 1.12109 20 2.5 20H8.75C10.1289 20 11.25 18.8789 11.25 17.5V2.5C11.25 1.11914 10.1289 0 8.75 0H2.5ZM2.5 1.25H8.75C9.44141 1.25 10 1.80977 10 2.5V17.5C10 18.1914 9.44141 18.75 8.75 18.75H2.5C1.80859 18.75 1.25 18.1914 1.25 17.5V2.5C1.25 1.80977 1.80859 1.25 2.5 1.25ZM13.125 6.25H17.5C18.1902 6.25 18.75 6.80859 18.75 7.5V17.5C18.75 18.1914 18.1902 18.75 17.5 18.75H12.5C12.1562 18.75 11.875 19.0312 11.875 19.375C11.875 19.7188 12.1562 20 12.5 20H17.5C18.8809 20 20 18.8789 20 17.5V7.5C20 6.12109 18.8809 5 17.5 5H13.125C12.7812 5 12.5 5.28125 12.5 5.625C12.5 5.96875 12.7812 6.25 13.125 6.25ZM14.6875 12.5C14.168 12.5 13.75 12.918 13.75 13.4375V15.3125C13.75 15.832 14.168 16.25 14.6875 16.25H16.5625C17.0801 16.25 17.5 15.832 17.5 15.3125V13.4375C17.5 12.918 17.0801 12.5 16.5625 12.5H14.6875ZM15 15V13.75H16.25V15H15ZM14.6875 7.5C14.168 7.5 13.75 7.91797 13.75 8.4375V10.3125C13.75 10.832 14.168 11.25 14.6875 11.25H16.5625C17.0801 11.25 17.5 10.832 17.5 10.3125V8.4375C17.5 7.91797 17.0801 7.5 16.5625 7.5H14.6875ZM15 10V8.75H16.25V10H15Z"
            fill="currentColor"
        />
    )
})

export const BuildingDoubleIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BuildingDoubleIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M6.875 11.875C6.875 11.5312 6.59375 11.25 6.25 11.25H5C4.65625 11.25 4.375 11.5312 4.375 11.875V13.125C4.375 13.4688 4.65625 13.75 5 13.75H6.25C6.59375 13.75 6.875 13.4688 6.875 13.125V11.875ZM6.875 4.375C6.875 4.03125 6.59375 3.75 6.25 3.75H5C4.65625 3.75 4.375 4.03125 4.375 4.375V5.625C4.375 5.96875 4.65625 6.25 5 6.25H6.25C6.59375 6.25 6.875 5.96875 6.875 5.625V4.375ZM6.25 10C6.59375 10 6.875 9.71875 6.875 9.375V8.125C6.875 7.78125 6.59375 7.5 6.25 7.5H5C4.65625 7.5 4.375 7.78125 4.375 8.125V9.375C4.375 9.71875 4.65625 10 5 10H6.25ZM2.5 0C1.12109 0 0 1.11914 0 2.5V17.5C0 18.8789 1.12109 20 2.5 20H8.75C10.1289 20 11.25 18.8789 11.25 17.5V2.5C11.25 1.11914 10.1289 0 8.75 0H2.5ZM2.5 1.875H8.75C9.09375 1.875 9.375 2.15469 9.375 2.5V17.5C9.375 17.8438 9.09375 18.125 8.75 18.125H2.5C2.15625 18.125 1.875 17.8438 1.875 17.5V2.5C1.875 2.15469 2.15625 1.875 2.5 1.875ZM12.5 6.875H17.1875C17.7051 6.875 18.125 7.29297 18.125 7.8125V17.1875C18.125 17.707 17.7051 18.125 17.1875 18.125H12.4492C12.3281 18.8398 12.0078 19.4844 11.5469 20H17.1875C18.7406 20 20 18.7422 20 17.1875V7.8125C20 6.25781 18.7406 5 17.1875 5H12.5V6.875ZM14.375 12.5C14.0312 12.5 13.75 12.7812 13.75 13.125V14.375C13.75 14.7188 14.0312 15 14.375 15H15.625C15.9688 15 16.25 14.7188 16.25 14.375V13.125C16.25 12.7812 15.9688 12.5 15.625 12.5H14.375ZM14.375 8.75C14.0312 8.75 13.75 9.03125 13.75 9.375V10.625C13.75 10.9688 14.0312 11.25 14.375 11.25H15.625C15.9688 11.25 16.25 10.9688 16.25 10.625V9.375C16.25 9.03125 15.9688 8.75 15.625 8.75H14.375Z"
            fill="currentColor"
        />
    )
})

export const BuildingDoubleIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BuildingDoubleIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 0C11.3789 0 12.5 1.12109 12.5 2.5V5H17.5C18.8789 5 20 6.12109 20 7.5V17.5C20 18.8789 18.8789 20 17.5 20H2.5C1.12109 20 0 18.8789 0 17.5V2.5C0 1.12109 1.12109 0 2.5 0H10ZM17.5 11.875C17.5 11.5312 17.2188 11.25 16.875 11.25H15.625C15.2812 11.25 15 11.5312 15 11.875V13.125C15 13.4688 15.2812 13.75 15.625 13.75H16.875C17.2188 13.75 17.5 13.4688 17.5 13.125V11.875ZM9.375 12.5C9.71875 12.5 10 12.2188 10 11.875V10.625C10 10.2812 9.71875 10 9.375 10H8.125C7.78125 10 7.5 10.2812 7.5 10.625V11.875C7.5 12.2188 7.78125 12.5 8.125 12.5H9.375ZM5 11.875V10.625C5 10.2812 4.71875 10 4.375 10H3.125C2.78125 10 2.5 10.2812 2.5 10.625V11.875C2.5 12.2188 2.78125 12.5 3.125 12.5H4.375C4.71875 12.5 5 12.2188 5 11.875ZM16.875 7.5H15.625C15.2812 7.5 15 7.78125 15 8.125V9.375C15 9.71875 15.2812 10 15.625 10H16.875C17.2188 10 17.5 9.71875 17.5 9.375V8.125C17.5 7.78125 17.2188 7.5 16.875 7.5ZM10 3.125C10 2.78125 9.71875 2.5 9.375 2.5H8.125C7.78125 2.5 7.5 2.78125 7.5 3.125V4.375C7.5 4.71875 7.78125 5 8.125 5H9.375C9.71875 5 10 4.71875 10 4.375V3.125ZM4.375 2.5H3.125C2.78125 2.5 2.5 2.78125 2.5 3.125V4.375C2.5 4.71875 2.78125 5 3.125 5H4.375C4.71875 5 5 4.71875 5 4.375V3.125C5 2.78125 4.71875 2.5 4.375 2.5ZM10 8.125V6.875C10 6.53125 9.71875 6.25 9.375 6.25H8.125C7.78125 6.25 7.5 6.53125 7.5 6.875V8.125C7.5 8.46875 7.78125 8.75 8.125 8.75H9.375C9.71875 8.75 10 8.46875 10 8.125ZM4.375 8.75C4.71875 8.75 5 8.46875 5 8.125V6.875C5 6.53125 4.71875 6.25 4.375 6.25H3.125C2.78125 6.25 2.5 6.53125 2.5 6.875V8.125C2.5 8.46875 2.78125 8.75 3.125 8.75H4.375Z"
            fill="currentColor"
        />
    )
})
