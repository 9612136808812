import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PhonelinePlusLogoIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'IconLogo',
    viewBox: '0 0 540 540',
    path: (
        <g id="PL / Logo">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M118 151.015V151C118 133.327 132.327 119 150 119H190C207.673 119 222 133.327 222 151H421C438.673 151 453 165.327 453 183V239H437V183C437 174.163 429.837 167 421 167H222V357C222 374.673 207.673 389 190 389H150C132.327 389 118 374.673 118 357V167.031C109.629 167.547 103 174.499 103 183V389C103 397.837 110.163 405 119 405H421C429.837 405 437 397.837 437 389V319H453V389C453 406.673 438.673 421 421 421H119C101.327 421 87 406.673 87 389V183C87 165.661 100.79 151.544 118 151.015ZM150 135H190C198.837 135 206 142.163 206 151V357C206 365.837 198.837 373 190 373H150C141.163 373 134 365.837 134 357V151C134 142.163 141.163 135 150 135Z"
                fill="currentColor"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M263 206C258.582 206 255 209.582 255 214V254C255 258.418 258.582 262 263 262H375C379.418 262 383 258.418 383 254V214C383 209.582 379.418 206 375 206H263ZM271 222V246H367V222H271Z"
                fill="currentColor"
            />
            <path d="M311 278H327V294H311V278Z" fill="currentColor" />
            <path d="M343 278H359V294H343V278Z" fill="currentColor" />
            <path d="M279 278H295V294H279V278Z" fill="currentColor" />
            <path d="M311 310H327V326H311V310Z" fill="currentColor" />
            <path d="M343 310H359V326H343V310Z" fill="currentColor" />
            <path d="M279 310H295V326H279V310Z" fill="currentColor" />
            <path d="M311 342H327V358H311V342Z" fill="currentColor" />
            <path d="M343 342H359V358H343V342Z" fill="currentColor" />
            <path d="M279 342H295V358H279V342Z" fill="currentColor" />
            <path
                d="M453 255C453 259.418 449.418 263 445 263C440.582 263 437 259.418 437 255C437 250.582 440.582 247 445 247C449.418 247 453 250.582 453 255Z"
                fill="currentColor"
            />
            <path
                d="M453 279C453 283.418 449.418 287 445 287C440.582 287 437 283.418 437 279C437 274.582 440.582 271 445 271C449.418 271 453 274.582 453 279Z"
                fill="currentColor"
            />
            <path
                d="M445 311C449.418 311 453 307.418 453 303C453 298.582 449.418 295 445 295C440.582 295 437 298.582 437 303C437 307.418 440.582 311 445 311Z"
                fill="currentColor"
            />
        </g>
    )
})
