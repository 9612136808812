import { SavedNumberList } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildSavedNumbersListAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'savedNumbersListAPI',
        tagTypes: ['SavedNumbersList'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getSavedNumbersList: builder.query<SavedNumberList, string>({
                query: userID => `/users/${userID}/savednumberlists`,
                transformResponse: (response: { data: SavedNumberList }) => response.data[0],
                providesTags: (result, error, ID) => [{ type: 'SavedNumbersList', id: ID }]
            }),
            createSavedNumbersList: builder.mutation<
                SavedNumberList,
                { savedNumbers: Pick<SavedNumberList, 'savedNumbers'>; userID: string }
            >({
                query: list => ({
                    url: `/users/${list.userID}/savednumberlists`,
                    method: 'POST',
                    body: list.savedNumbers
                }),
                invalidatesTags: ['SavedNumbersList']
            }),
            updateSavedNumbersList: builder.mutation<SavedNumberList, SavedNumberList>({
                query: list => ({
                    url: `/users/${list.userID}/savednumberlists/${list.ID}`,
                    method: 'PUT',
                    body: { savedNumbers: list.savedNumbers }
                }),
                invalidatesTags: ['SavedNumbersList']
            }),
            deleteSavedNumbersList: builder.mutation<
                SavedNumberList,
                Pick<SavedNumberList, 'ID' | 'userID'>
            >({
                query: list => ({
                    url: `/users/${list.userID}/savednumberlists/${list.ID}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['SavedNumbersList']
            })
        })
    })

    const {
        useGetSavedNumbersListQuery,
        useCreateSavedNumbersListMutation,
        useUpdateSavedNumbersListMutation,
        useDeleteSavedNumbersListMutation
    } = api
    return {
        api,
        useGetSavedNumbersListQuery,
        useCreateSavedNumbersListMutation,
        useUpdateSavedNumbersListMutation,
        useDeleteSavedNumbersListMutation
    }
}
