import { Box, Divider, HStack, useColorMode } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    Body,
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Input,
    Scrollable,
    VirtualPaginationContextProvider
} from '@missionlabs/react'
import { SearchIcon } from '@missionlabs/react/circleloop'
import { DirectoryEntry } from '@missionlabs/types'
import { alphabetizeContacts, isPersonalContact } from '@missionlabs/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList } from 'react-window'
import { usePaginatedContacts } from 'shared/hooks/usePaginatedContacts'

import { AddNumberToContactContext } from './context'
import { AddNumberToContactRenderer } from './Renderer'
import { useAddNumberToContactDrawer } from './useAddNumberToContactDrawer'

export const AddNumberToContactDrawer = () => {
    const user = useSelector(selectAuthenticatedUser)
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const navigate = useNavigate()

    const { isOpen, numberE164, onClose } = useAddNumberToContactDrawer()

    const [searchTerm, setSearchTerm] = useState('')
    const [selectedContact, setSelectedContact] = useState<DirectoryEntry | undefined>()
    const paginationArgs = useMemo(() => ({ searchTerm }), [searchTerm])
    const {
        contacts: allContacts,
        setItemCount,
        onItemsRendered
    } = usePaginatedContacts(paginationArgs)
    const listRef = useRef<VariableSizeList>(null)

    const groupedContacts = useMemo(() => {
        if (!user?.userID) return []

        // Only show personal contacts, including ones that have been shared
        const filtered = allContacts.filter(contact => isPersonalContact(contact, user.userID))

        // Group the contacts by the first letter of their last name
        const alphabetized = alphabetizeContacts(filtered, { sortField: 'lastName' })

        // Sort the groups by letter
        return Object.entries(alphabetized).sort(([letterA], [letterB]) => {
            if (letterA === letterB) return 0
            return letterA < letterB ? -1 : 1
        })
    }, [allContacts, user])

    const flatGroupedContacts = useMemo(() => {
        return groupedContacts.reduce((prev, [letter, contacts]) => {
            if (contacts.length < 1) return prev
            return [...prev, letter, ...contacts]
        }, [] as (string | DirectoryEntry)[])
    }, [groupedContacts])

    const rowHeights = useMemo(() => {
        return flatGroupedContacts.map(item => (typeof item === 'string' ? 72 : 78))
    }, [flatGroupedContacts])

    const getRowSize = useCallback((index: number) => rowHeights[index], [rowHeights])

    useEffect(() => {
        setItemCount(flatGroupedContacts.length)
    }, [flatGroupedContacts, setItemCount])

    function handleSubmit() {
        if (!selectedContact) return
        navigate(`/contacts/${selectedContact.ID}/edit?numberE164=${numberE164}`)
        onClose()
    }

    return (
        <Drawer isOpen={isOpen} onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent data-testid="add-number-to-contact-drawer">
                <DrawerHeader>
                    <Body variant="bold">Add number to contact</Body>
                </DrawerHeader>
                <DrawerBody p={6} bg={`${colorMode}.tones.ghostWhite`}>
                    <Input
                        type="search"
                        placeholder="Search name or number..."
                        mb={6}
                        rightIcon={<SearchIcon />}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.currentTarget.value)}
                    />
                    <Divider />
                    <VirtualPaginationContextProvider pageSize={25}>
                        <Box w="full" flexGrow="1" role="list" height={`calc(100% - 65px)`}>
                            <AddNumberToContactContext.Provider
                                value={{ selectedContact, setSelectedContact }}
                            >
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <VariableSizeList
                                            ref={listRef}
                                            height={height}
                                            width={width}
                                            itemSize={getRowSize}
                                            itemCount={flatGroupedContacts.length}
                                            itemData={flatGroupedContacts}
                                            onItemsRendered={onItemsRendered}
                                            outerElementType={Scrollable}
                                        >
                                            {AddNumberToContactRenderer}
                                        </VariableSizeList>
                                    )}
                                </AutoSizer>
                            </AddNumberToContactContext.Provider>
                        </Box>
                    </VirtualPaginationContextProvider>
                </DrawerBody>
                <DrawerFooter p={6} bg={`${colorMode}.tones.ghostWhite`}>
                    <HStack w="full" spacing={4}>
                        <Button variant="secondary" w="full" onClick={onClose}>
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="creationary"
                            w="full"
                            isDisabled={!selectedContact}
                            isLoading={false}
                            onClick={handleSubmit}
                        >
                            {t('Continue')}
                        </Button>
                    </HStack>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}
