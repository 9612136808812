import { IconButton, Tooltip } from '@missionlabs/react'
import { KeypadOutlineIcon } from '@missionlabs/react/circleloop'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface DialpadButtonProps {
    isOpen?: boolean
    onToggle: () => void
}

export const DialpadButton: FC<DialpadButtonProps> = ({ isOpen, onToggle }) => {
    const { t } = useTranslation()
    return (
        <Tooltip label={t('Dialpad')}>
            <IconButton
                icon={<KeypadOutlineIcon boxSize="16px" />}
                variant="dialler"
                isActive={isOpen}
                boxSize="48px"
                flex={1}
                aria-label="dialpad"
                onClick={onToggle}
            />
        </Tooltip>
    )
}
