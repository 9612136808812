import {
    useAudioInputs,
    useMeetingManager,
    useVoiceFocus
} from 'amazon-chime-sdk-component-library-react'
import { AudioInputDevice, Device, VoiceFocusTransformDevice } from 'amazon-chime-sdk-js'
import { useCallback, useEffect, useState } from 'react'

import { VolumeTransformDevice } from './VolumeTransformDevice'

const useNoiseSuppression = (initialVoiceFocus?: boolean) => {
    const meetingManager = useMeetingManager()
    const { selectedDevice } = useAudioInputs()
    const [isVoiceFocusEnabled, setIsVoiceFocusEnabled] = useState(false)
    const { isVoiceFocusSupported, addVoiceFocus } = useVoiceFocus()

    useEffect(() => {
        if (selectedDevice instanceof VoiceFocusTransformDevice) {
            setIsVoiceFocusEnabled(true)
        } else {
            setIsVoiceFocusEnabled(false)
        }
    }, [selectedDevice])

    const onChangeNoiseSuppression = useCallback(
        async (isVoiceFocusOn: boolean) => {
            try {
                let current = selectedDevice
                if (isVoiceFocusOn) {
                    if (
                        typeof selectedDevice === 'string' ||
                        selectedDevice instanceof VolumeTransformDevice
                    ) {
                        current = await addVoiceFocus(
                            selectedDevice instanceof VolumeTransformDevice
                                ? selectedDevice[`inner`]
                                : (selectedDevice as Device)
                        )
                    }
                } else if (selectedDevice instanceof VoiceFocusTransformDevice) {
                    current = selectedDevice.getInnerDevice()
                }

                await meetingManager.startAudioInputDevice(current as AudioInputDevice)
            } catch (error) {
                console.error('Failed to toggle Voice Focus')
            }
        },
        [addVoiceFocus, selectedDevice, meetingManager]
    )

    const [isSetupComplete, setIsSetupComplete] = useState(false)

    useEffect(() => {
        if (!isSetupComplete && initialVoiceFocus === undefined) {
            return setIsSetupComplete(true)
        }
        if (isVoiceFocusSupported && selectedDevice && !isSetupComplete) {
            setIsSetupComplete(true)
            onChangeNoiseSuppression(!!initialVoiceFocus)
        }
    }, [
        isVoiceFocusSupported,
        selectedDevice,
        isSetupComplete,
        initialVoiceFocus,
        onChangeNoiseSuppression
    ])

    return {
        onChangeNoiseSuppression,
        isVoiceFocusEnabled,
        isVoiceFocusSupported
    }
}

export default useNoiseSuppression
