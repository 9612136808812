import { Box, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { call, useDispatch } from '@missionlabs/api'
import {
    Body,
    ContactDetail,
    IconButton,
    ListTitle,
    Menu,
    MenuButton,
    MenuItem,
    MenuList
} from '@missionlabs/react'
import { SMSIcon, WhatsappIcon } from '@missionlabs/react/circleloop'
import { DotsVerticalIconRegular, PhoneIconRegular } from '@missionlabs/react/zeta'
import { OutboundMessageType, WhatsAppContentType } from '@missionlabs/types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { Features, useHasClientFeature } from 'shared/hooks/useHasClientFeature'
import { useIsSMSEnabled } from 'shared/hooks/useIsSMSEnabled'
import { addMessage } from 'shared/slices/messagingSlice'

import { DrawerMode } from './ContactDrawer'
import { useContactDrawerState } from './hooks/useContactDrawerState'

interface NoContactsProps {
    searchedNumber?: string
    presentationNumber?: string
}

export const NoContacts = ({ searchedNumber, presentationNumber }: NoContactsProps) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const dispatch = useDispatch()
    const { formatToNumberE164 } = useFormatToNumberE164()
    const { setMode } = useContactDrawerState()

    const borderColor = `${colorMode}.tones.stormGrey`

    const handleCall = useCallback(() => {
        if (!searchedNumber || !presentationNumber) return
        dispatch(call({ to: formatToNumberE164(searchedNumber), from: presentationNumber }))
        setMode(DrawerMode.VOICE)
    }, [dispatch, searchedNumber, presentationNumber, formatToNumberE164, setMode])

    const handleSMS = useCallback(() => {
        if (!searchedNumber || !presentationNumber) return
        dispatch(
            addMessage({
                type: OutboundMessageType.SMS,
                toNumber: searchedNumber,
                fromNumber: presentationNumber,
                text: ''
            })
        )
    }, [dispatch, searchedNumber, presentationNumber])

    const smsEnabled = useIsSMSEnabled([searchedNumber ?? ''], presentationNumber)

    const handleWhatsApp = useCallback(() => {
        if (!searchedNumber || !presentationNumber) return
        dispatch(
            addMessage({
                type: OutboundMessageType.WhatsApp,
                contentType: WhatsAppContentType.TEXT,
                toNumber: searchedNumber,
                fromNumber: presentationNumber,
                text: ''
            })
        )
    }, [dispatch, searchedNumber, presentationNumber])

    const whatsappEnabled = useHasClientFeature(Features.whatsapp)

    return (
        <VStack spacing={0} w="100%" align="stretch">
            <ListTitle color="white">{t('Contacts')}</ListTitle>
            <Box px="24px">
                <HStack
                    p="16px"
                    w="full"
                    border="1px solid"
                    borderColor="transparent"
                    // By default, only show bottom border
                    borderBottomColor={borderColor}
                    // First element in list can have top border
                    _first={{ borderTopColor: borderColor }}
                    // Show side borders when hovered
                    _hover={{
                        borderLeftColor: borderColor,
                        borderRightColor: borderColor,
                        borderRadius: '4px'
                    }}
                    transition="all 200ms ease"
                    role="listitem"
                >
                    {/* Show name & company */}
                    <ContactDetail
                        name={searchedNumber}
                        subText={t('Unknown')}
                        variant="horizontal-dark"
                        sx={{ width: '100%' }}
                    />
                    <Menu variant={'dark'}>
                        <MenuButton
                            as={IconButton}
                            variant="ghost"
                            color={`${colorMode}.tones.stormGrey`}
                            aria-label={`Select action for ${searchedNumber}`}
                            data-testid="no-contact-menu-trigger"
                        >
                            <DotsVerticalIconRegular />
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                icon={<PhoneIconRegular />}
                                variant="dark"
                                onClick={handleCall}
                                name="Call"
                            >
                                {t('Call')}
                            </MenuItem>
                            {smsEnabled && (
                                <MenuItem
                                    name="SMS"
                                    icon={<SMSIcon />}
                                    variant="dark"
                                    onClick={handleSMS}
                                >
                                    {t('SMS')}
                                </MenuItem>
                            )}
                            {whatsappEnabled && (
                                <MenuItem
                                    name="WhatsApp"
                                    icon={<WhatsappIcon />}
                                    variant="dark"
                                    onClick={handleWhatsApp}
                                >
                                    {t('WhatsApp')}
                                </MenuItem>
                            )}
                        </MenuList>
                    </Menu>
                </HStack>
                <Body size="sm" py="16px" color="white">
                    {t('No matching results')}
                </Body>
            </Box>
        </VStack>
    )
}
