import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const MenuIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MenuIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 0C2.68629 0 0 2.68629 0 6V18C0 21.3137 2.68629 24 6 24H18C21.3137 24 24 21.3137 24 18V6C24 2.68629 21.3137 0 18 0H6ZM5 7.00013C5 8.10295 5.89731 9 7 9C8.10269 9 9 8.10289 9 7.00013C9 5.89737 8.10269 5 7 5C5.89731 5 5 5.8973 5 7.00013ZM12 9C10.8973 9 10 8.10295 10 7.00013C10 5.8973 10.8973 5 12 5C13.1027 5 14 5.89737 14 7.00013C14 8.10289 13.1027 9 12 9ZM15 7.00013C15 8.10295 15.8973 9 17 9C18.1027 9 19 8.10289 19 7.00013C19 5.89737 18.1027 5 17 5C15.8973 5 15 5.8973 15 7.00013ZM7 14C5.89731 14 5 13.1026 5 12.0001C5 10.8974 5.89731 10 7 10C8.10269 10 9 10.8974 9 12.0001C9 13.1026 8.10269 14 7 14ZM10 12.0001C10 13.1026 10.8973 14 12 14C13.1027 14 14.0001 13.1026 14 12.0001C14 10.8974 13.1027 10 12 10C10.8973 10 10 10.8974 10 12.0001ZM17 14C15.8973 14 15 13.1026 15 12.0001C15 10.8974 15.8973 10 17 10C18.1027 10 19 10.8974 19 12.0001C19.0001 13.1026 18.1027 14 17 14ZM5 17C5 18.1027 5.89731 19 7 19C8.10269 19 9 18.1027 9 17C9 15.8973 8.10269 15 7 15C5.89731 15 5 15.8973 5 17ZM12 19C10.8973 19 10 18.1027 10 17C10 15.8973 10.8973 15 12 15C13.1027 15 14 15.8973 14 17C14 18.1027 13.1027 19 12 19ZM15 17C15 18.1027 15.8973 19 17 19C18.1027 19 19 18.1027 19 17C19 15.8973 18.1027 15 17 15C15.8973 15 15 15.8973 15 17Z"
                fill="currentColor"
            />
            <mask
                id="mask0_420_167"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 0C2.68629 0 0 2.68629 0 6V18C0 21.3137 2.68629 24 6 24H18C21.3137 24 24 21.3137 24 18V6C24 2.68629 21.3137 0 18 0H6ZM5 7.00013C5 8.10295 5.89731 9 7 9C8.10269 9 9 8.10289 9 7.00013C9 5.89737 8.10269 5 7 5C5.89731 5 5 5.8973 5 7.00013ZM12 9C10.8973 9 10 8.10295 10 7.00013C10 5.8973 10.8973 5 12 5C13.1027 5 14 5.89737 14 7.00013C14 8.10289 13.1027 9 12 9ZM15 7.00013C15 8.10295 15.8973 9 17 9C18.1027 9 19 8.10289 19 7.00013C19 5.89737 18.1027 5 17 5C15.8973 5 15 5.8973 15 7.00013ZM7 14C5.89731 14 5 13.1026 5 12.0001C5 10.8974 5.89731 10 7 10C8.10269 10 9 10.8974 9 12.0001C9 13.1026 8.10269 14 7 14ZM10 12.0001C10 13.1026 10.8973 14 12 14C13.1027 14 14.0001 13.1026 14 12.0001C14 10.8974 13.1027 10 12 10C10.8973 10 10 10.8974 10 12.0001ZM17 14C15.8973 14 15 13.1026 15 12.0001C15 10.8974 15.8973 10 17 10C18.1027 10 19 10.8974 19 12.0001C19.0001 13.1026 18.1027 14 17 14ZM5 17C5 18.1027 5.89731 19 7 19C8.10269 19 9 18.1027 9 17C9 15.8973 8.10269 15 7 15C5.89731 15 5 15.8973 5 17ZM12 19C10.8973 19 10 18.1027 10 17C10 15.8973 10.8973 15 12 15C13.1027 15 14 15.8973 14 17C14 18.1027 13.1027 19 12 19ZM15 17C15 18.1027 15.8973 19 17 19C18.1027 19 19 18.1027 19 17C19 15.8973 18.1027 15 17 15C15.8973 15 15 15.8973 15 17Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_420_167)"></g>
        </g>
    )
})

export const KebabMenuIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'KebabMenuIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M13.8002 4.8C13.8002 5.79411 12.9943 6.6 12.0002 6.6C11.0061 6.6 10.2002 5.79411 10.2002 4.8C10.2002 3.80589 11.0061 3 12.0002 3C12.9943 3 13.8002 3.80589 13.8002 4.8Z"
                fill="currentColor"
            />
            <path
                d="M13.8002 12C13.8002 12.9941 12.9943 13.8 12.0002 13.8C11.0061 13.8 10.2002 12.9941 10.2002 12C10.2002 11.0059 11.0061 10.2 12.0002 10.2C12.9943 10.2 13.8002 11.0059 13.8002 12Z"
                fill="currentColor"
            />
            <path
                d="M12.0002 21C12.9943 21 13.8002 20.1941 13.8002 19.2C13.8002 18.2059 12.9943 17.4 12.0002 17.4C11.0061 17.4 10.2002 18.2059 10.2002 19.2C10.2002 20.1941 11.0061 21 12.0002 21Z"
                fill="currentColor"
            />
        </g>
    )
})
