import {
    Popover,
    PopoverContent,
    PopoverTrigger,
    useColorMode,
    useDisclosure
} from '@chakra-ui/react'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { IconButton, IconButtonProps } from '@missionlabs/react'
import { SmileEmojiPlusIconSolid } from '@missionlabs/react/zeta'
import { REACTION_EMOJIS } from 'const'
import { FC } from 'react'

import { useDataMessages } from '../DataMessagesProvider'

export interface ReactionControlProps extends Omit<IconButtonProps, 'aria-label' | 'onClick'> {}

const ReactionControl: FC<ReactionControlProps> = props => {
    const { colorMode } = useColorMode()
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { sendReaction } = useDataMessages()

    function handleEmojiSelect(e: any) {
        sendReaction({ shortcodes: e.shortcodes, unified: e.unified })
        onClose()
    }

    return (
        <Popover
            placement="top"
            computePositionOnMount
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
        >
            <PopoverTrigger>
                <IconButton
                    {...props}
                    variant={isOpen ? 'negatory' : 'tertiary'}
                    isRounded
                    sx={!isOpen ? { color: `${colorMode}.alerts.red` } : undefined}
                    aria-label="Meeting Settings"
                    data-testid="video-menu_reactions_btn"
                >
                    <SmileEmojiPlusIconSolid />
                </IconButton>
            </PopoverTrigger>
            <PopoverContent sx={{ bg: 'none', border: 'none' }}>
                <Picker
                    perLine={8}
                    custom={REACTION_EMOJIS}
                    categories={['reactions']}
                    data={data}
                    onEmojiSelect={handleEmojiSelect}
                />
            </PopoverContent>
        </Popover>
    )
}

export default ReactionControl
