import {
    Box,
    Textarea as ChakraTextarea,
    TextareaProps,
    useMultiStyleConfig
} from '@chakra-ui/react'
import { ResizeIcon } from 'atoms/Icons/zeta'
import autosize from 'autosize'
import { FC, useEffect, useRef } from 'react'

export const Textarea: FC<TextareaProps & { variant?: 'dark' }> = ({ variant, ...props }) => {
    const ref = useRef<HTMLTextAreaElement>(null)

    const styles = useMultiStyleConfig('Textarea', { variant })

    useEffect(() => {
        const current = ref.current
        if (current) autosize(current)

        return () => {
            if (current) autosize.destroy(current)
        }
    })

    return (
        <Box sx={{ ...styles.container }} w="full">
            <ChakraTextarea
                resize="none"
                {...props}
                variant={variant}
                ref={ref}
                sx={{ ...styles.input, ...(props.sx ?? {}) }}
            />
            <ResizeIcon sx={{ ...styles.resize }} />
        </Box>
    )
}
