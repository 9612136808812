import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { FC } from 'react'
import { SearchInput } from 'shared/components/SearchInput'
import { useGetScheduledMeetingsQuery } from 'shared/store'

import { CalendarView } from './CalendarView'
import { POLLING_INTERVAL } from './MeetingsDashboard'

export const MeetingsSidebar: FC = () => {
    const user = useSelector(selectAuthenticatedUser)
    const { data: scheduledMeetings = [] } = useGetScheduledMeetingsQuery(
        { userID: user?.userID ?? '' },
        { skip: !user?.userID, pollingInterval: POLLING_INTERVAL }
    )

    return (
        <div>
            <SearchInput placeholder="Search meetings" hideFavourites />
            <CalendarView meetings={scheduledMeetings} />
        </div>
    )
}
