import { Channel, NewChannel } from '@missionlabs/types'
import isElectron from 'is-electron'
import type {
    NotificationActionFn,
    NotificationActionType,
    NotificationChatMessage,
    NotificationCreateChatFn,
    NotificationCreateChatReplyFn,
    NotificationCreateFn,
    NotificationSendChatMessageFn,
    NotificationTypes,
    SharedData
} from 'types/global'

type NotificationSize = { width?: number; height?: number }

export class ElectronNotification {
    static disabled = !isElectron()

    static create(route: string, call: NotificationTypes, shared?: SharedData) {
        if (this.disabled) return
        return window.notificationAPI.create(route, call, shared)
    }

    static action(id: number, type: NotificationActionType, call: NotificationTypes | undefined) {
        if (this.disabled || !call) return
        window.notificationAPI.action(id, type, call)
    }

    static createChat(id: number, channel: NewChannel) {
        if (this.disabled) return
        window.notificationAPI.createChat(id, channel)
    }

    static createChatReply(id: number, channel: Channel) {
        if (this.disabled) return
        window.notificationAPI.createChatReply(id, channel)
    }

    static sendChatMessage(message: NotificationChatMessage) {
        if (this.disabled) return
        window.notificationAPI.sendChatMessage(message)
    }

    static adjustSize(id: number, size: NotificationSize) {
        if (this.disabled) return
        window.notificationAPI.adjustSize(id, size)
    }

    static onAction(callback: NotificationActionFn) {
        if (this.disabled) return
        window.notificationAPI.onAction(callback)
    }

    static onCreate(callback: NotificationCreateFn) {
        if (this.disabled) return
        window.notificationAPI.onCreate(callback)
    }

    static onCreateChat(callback: NotificationCreateChatFn) {
        if (this.disabled) return
        window.notificationAPI.onCreateChat(callback)
    }

    static onCreateChatReply(callback: NotificationCreateChatReplyFn) {
        if (this.disabled) return
        window.notificationAPI.onCreateChatReply(callback)
    }

    static onSendChatMessage(callback: NotificationSendChatMessageFn) {
        if (this.disabled) return
        window.notificationAPI.onSendChatMessage(callback)
    }

    static removeOnAction(callback: NotificationActionFn) {
        if (this.disabled) return
        window.notificationAPI.removeOnAction(callback)
    }

    static removeOnCreate(callback: NotificationCreateFn) {
        if (this.disabled) return
        window.notificationAPI.removeOnCreate(callback)
    }

    static removeOnCreateChat(callback: NotificationCreateChatFn) {
        if (this.disabled) return
        window.notificationAPI.removeOnCreateChat(callback)
    }

    static removeOnCreateChatReply(callback: NotificationCreateChatReplyFn) {
        if (this.disabled) return
        window.notificationAPI.removeOnCreateChatReply(callback)
    }

    static removeOnSendChatMessage(callback: NotificationSendChatMessageFn) {
        if (this.disabled) return
        window.notificationAPI.removeOnSendChatMessage(callback)
    }
}
