import { useAudioVideo } from 'amazon-chime-sdk-component-library-react'
import { useEffect, useRef } from 'react'

export const useBindVideoElement = (tileId: number | null, isVideoEnabled: boolean) => {
    const audioVideo = useAudioVideo()
    const videoEl = useRef<HTMLVideoElement>(null)

    useEffect(() => {
        if (!audioVideo || !tileId || !videoEl.current || !isVideoEnabled) {
            return
        }

        audioVideo.bindVideoElement(tileId, videoEl.current)

        return () => {
            const tile = audioVideo.getVideoTile(tileId)
            if (tile) {
                audioVideo.unbindVideoElement(tileId)
            }
        }
    }, [audioVideo, tileId, isVideoEnabled])

    return isVideoEnabled ? videoEl : undefined
}
