import { CheckboxGroup, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { SearchFilter, selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { Body, Checkbox, IconButton } from '@missionlabs/react'
import { CloseIcon, RefreshIcon } from '@missionlabs/react/circleloop'
import { FilterSection } from 'features/activity/components/FilterSection'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetContactSourcesQuery } from 'shared/store'

import { ContactContext } from './context/contactsContext'
import { ContactFilterSort } from './hooks/useContactsFilterParams'
import { useContactsSearchParams } from './hooks/useContactsSearchParams'

interface Props {
    onClose(): void
}

interface FilterCheckbox<T> {
    label: string
    key: T
}

const ContactFilterOptions: React.FC<Props> = ({ onClose }) => {
    const { colorMode } = useColorMode()
    const user = useSelector(selectAuthenticatedUser)

    const { data: contactSources } = useGetContactSourcesQuery(user!.userID, { skip: !user })

    const { sortBy, updateParams } = useContactsSearchParams()

    const { t } = useTranslation()

    const { filters, toggleSource, toggleType, resetFilters } = useContext(ContactContext)

    const sortCheckboxes: FilterCheckbox<ContactFilterSort>[] = [
        {
            label: t('filters.contacts.surnameAz'),
            key: 'surname-az'
        },
        {
            label: t('filters.contacts.surnameZa'),
            key: 'surname-za'
        },
        {
            label: t('filters.contacts.firstNameAz'),
            key: 'firstname-az'
        },
        {
            label: t('filters.contacts.firstNameZa'),
            key: 'firstname-za'
        }
    ]

    const typeCheckboxes: FilterCheckbox<SearchFilter>[] = [
        {
            label: t('filters.contacts.personal'),
            key: 'clPersonal'
        },
        {
            label: t('filters.contacts.shared'),
            key: 'shared'
        },
        {
            label: t('filters.contacts.users'),
            key: 'users'
        },
        {
            label: t('filters.contacts.teams'),
            key: 'teams'
        }
    ]

    return (
        <VStack
            spacing="24px"
            w="full"
            align="start"
            id="activity-sidebar-filters"
            p={4}
            pl={6}
            overflowY="auto"
        >
            <HStack
                justify="space-between"
                w="full"
                pb={4}
                borderBottom="1px solid"
                borderBottomColor={`${colorMode}.tones.periwinkle`}
            >
                <Body size="md" variant="bold">
                    {t('Filters')}
                </Body>
                <HStack spacing={0}>
                    <IconButton
                        variant="transparent"
                        size="sm"
                        onClick={resetFilters}
                        icon={<RefreshIcon boxSize="16px" />}
                        aria-label={t('activities.filters.reset')}
                    />

                    <IconButton
                        size="sm"
                        variant="transparent"
                        aria-label={t('close')}
                        onClick={onClose}
                        icon={<CloseIcon boxSize="16px" />}
                    />
                </HStack>
            </HStack>

            <FilterSection label={t('Sort')}>
                <CheckboxGroup>
                    {sortCheckboxes.map(({ label, key }) => (
                        <Checkbox
                            key={key}
                            isChecked={sortBy === key}
                            onChange={() => updateParams('sort', key)}
                        >
                            <Body as="span" size="sm">
                                {label}
                            </Body>
                        </Checkbox>
                    ))}
                </CheckboxGroup>
            </FilterSection>

            <FilterSection label={t('Type')}>
                <CheckboxGroup>
                    {typeCheckboxes.map(({ label, key }) => (
                        <Checkbox
                            key={key}
                            isChecked={filters.type.includes(key)}
                            onChange={e => toggleType(key, e.target.checked)}
                        >
                            <Body as="span" size="sm">
                                {label}
                            </Body>
                        </Checkbox>
                    ))}
                </CheckboxGroup>
            </FilterSection>

            <FilterSection label={t('Directory Source')}>
                <CheckboxGroup>
                    {contactSources?.map(contactSource => (
                        <Checkbox
                            key={contactSource}
                            isChecked={filters.source.includes(contactSource)}
                            onChange={e => toggleSource(contactSource, e.target.checked)}
                        >
                            <Body as="span" size="sm">
                                {contactSource}
                            </Body>
                        </Checkbox>
                    ))}
                </CheckboxGroup>
            </FilterSection>
        </VStack>
    )
}

export default ContactFilterOptions
