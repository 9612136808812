import { add, format, isEqual, startOfDay, startOfMonth, startOfWeek } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'

import { DatePeriod } from '../context'

const getInitialStart = (period: DatePeriod) => {
    const now = new Date()
    switch (period) {
        case 'days':
            return startOfDay(now)
        case 'weeks':
            return startOfWeek(now, { weekStartsOn: 1 })
        case 'months':
            return startOfMonth(now)
        default:
            return now
    }
}

const getInitialEnd = (period: DatePeriod) => {
    return getNextDate(getInitialStart(period), period)
}

const getNextDate = (date: Date | null, period: DatePeriod) => {
    if (!date) return null
    return add(date, { [period]: 1 })
}

const getPreviousDate = (date: Date | null, period: DatePeriod) => {
    if (!date) return null
    return add(date, { [period]: -1 })
}

const formatDate = (period: DatePeriod, start: Date | null, end: Date | null) => {
    if (!start || !end) return ''
    const formatString = 'EEEE do MMMM yyyy'

    const range =
        period === 'days'
            ? `${format(start, formatString)}`
            : `${format(start, formatString)} - ${format(end, formatString)}`

    const isCurrentPeriod = isEqual(start, getInitialStart(period))

    const prefix = isCurrentPeriod
        ? {
              days: 'Today, ',
              weeks: 'This Week, ',
              months: 'This Month, '
          }[period]
        : ''

    return prefix + range
}

const getTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const useDateScroll = (period: DatePeriod) => {
    const [start, setStart] = useState<Date | null>(null)
    const [end, setEnd] = useState<Date | null>(null)

    const formattedTime = formatDate(period, start, end)

    const isCurrentPeriod = isEqual(start ?? new Date(0), getInitialStart(period))

    const timezone = getTimezone()

    useEffect(() => {
        if (period === 'custom') {
            setStart(null)
            setEnd(null)
            return
        }
        setStart(getInitialStart(period))
        setEnd(getInitialEnd(period))
    }, [period])

    const next = useCallback(() => {
        setStart(start => getNextDate(start, period))
        setEnd(end => getNextDate(end, period))
    }, [period])

    const previous = useCallback(() => {
        setStart(start => getPreviousDate(start, period))
        setEnd(end => getPreviousDate(end, period))
    }, [period])

    return {
        period,
        start,
        end,
        formattedTime,
        isCurrentPeriod,
        timezone,
        next,
        previous,
        setStart,
        setEnd
    }
}
