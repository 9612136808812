import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useContext,
    useState
} from 'react'
import { ListOnItemsRenderedProps } from 'react-window'

import { useDebouncedFunction } from './useDebouncedFunction'

interface IVirtualPaginationContext {
    page: number
    pageSize: number
    reset: () => void
    onItemsRendered: (props: ListOnItemsRenderedProps) => void
    setItemCount: Dispatch<SetStateAction<number>>
    setIsLoading: Dispatch<SetStateAction<boolean>>
    setIsEndOfList: Dispatch<SetStateAction<boolean>>
}

const VirtualPaginationContext = createContext<IVirtualPaginationContext>({
    page: 0,
    pageSize: 100,
    reset: () => null,
    onItemsRendered: () => null,
    setItemCount: () => null,
    setIsLoading: () => null,
    setIsEndOfList: () => null
})

interface VirtualPaginationContextProviderProps {
    pageSize?: number
    // The direction the list scrolls, used to configure whether the start or stop index is used in pagination.
    direction?: 'up' | 'down'
}

export const VirtualPaginationContextProvider: FC<
    PropsWithChildren<VirtualPaginationContextProviderProps>
> = ({ pageSize = 100, direction = 'down', children }) => {
    const [page, setPage] = useState(0)
    const [itemCount, setItemCount] = useState(0)
    const [isEndOfList, setIsEndOfList] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const reset = useCallback(() => {
        setPage(0)
        setItemCount(0)
        setIsEndOfList(false)
        setIsLoading(false)
    }, [])

    const onItemsRendered = useCallback(
        ({ overscanStartIndex, overscanStopIndex }: ListOnItemsRenderedProps) => {
            if (isLoading) return

            // increment page after scanning the last rendered item.
            // offset by page size to keep a page in reserve as we scroll.

            if (direction === 'down' && overscanStopIndex >= itemCount - 1 && !isEndOfList) {
                return setPage(prev => prev + 1)
            }

            if (direction === 'up' && overscanStartIndex === 0 && !isEndOfList) {
                return setPage(prev => prev + 1)
            }
        },
        [direction, isEndOfList, isLoading, itemCount]
    )

    const onItemsRenderedThrottled = useDebouncedFunction(onItemsRendered, 300)

    return (
        <VirtualPaginationContext.Provider
            value={{
                page,
                pageSize,
                reset,
                onItemsRendered: onItemsRenderedThrottled,
                setItemCount,
                setIsLoading,
                setIsEndOfList
            }}
        >
            {children}
        </VirtualPaginationContext.Provider>
    )
}

export const useVirtualPagination = () => {
    const ctx = useContext(VirtualPaginationContext)

    if (!ctx) {
        throw Error(
            'No VirtualPaginationContext exists. Component must be wrapped in <VirtualPaginationContextProvider/>'
        )
    }

    return ctx
}
