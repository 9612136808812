import { StackProps, useColorMode, VStack } from '@chakra-ui/react'
import {
    Avatar,
    ErrorMessage,
    get,
    Heading,
    IconButton,
    Input,
    ListItem,
    Scrollable,
    useFormContext,
    useFuzzySearch
} from '@missionlabs/react'
import { CloseCircleIcon, SearchIcon } from '@missionlabs/react/circleloop'
import { User } from '@missionlabs/types'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AddEditTeamFields } from './AddEditTeamForm'

export interface AddEditTeamMembersProps extends StackProps {
    users: User[]
}

export const AddEditTeamMembers: FC<AddEditTeamMembersProps> = ({ users, ...props }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const [search, setSearch] = useState('')

    const {
        formState: { errors },
        setValue,
        watch
    } = useFormContext<AddEditTeamFields>()
    const teamMembers = watch('callRoute.teamMembers')
    const error = get(errors, 'callRoute.teamMembers')

    const { results } = useFuzzySearch<User>({
        items: [...users].sort((a, b) => a.fullName.localeCompare(b.fullName)),
        searchValue: search,
        keys: ['fullName', 'email']
    })

    function isSelected(user: User) {
        return teamMembers.some(item => item.userID === user.ID)
    }

    function toggle(user: User) {
        if (isSelected(user)) {
            const newMembers = teamMembers.filter(item => item.userID !== user.ID)
            setValue('callRoute.teamMembers', newMembers, { shouldDirty: true })
        } else {
            const newMembers = [...teamMembers, { order: teamMembers.length + 1, userID: user.ID }]
            setValue('callRoute.teamMembers', newMembers, { shouldDirty: true })
        }
    }

    return (
        <VStack pt="24px" px="24px" spacing="16px" align="inherit" {...props}>
            <Input
                value={search}
                placeholder={t('admin.teams.form.search_placeholder')}
                rightIcon={<SearchIcon boxSize="20px" color={`${colorMode}.coolGrey.60`} />}
                onChange={e => setSearch(e.target.value)}
            />
            <Scrollable h={`calc(100dvh - ${307 + (error ? 36 : 0)}px)`}>
                {results.map(user => (
                    <AddEditTeamMembersItem
                        key={user.ID}
                        user={user}
                        isSelected={isSelected(user)}
                        onClick={toggle}
                    />
                ))}
            </Scrollable>
            <ErrorMessage error={error} />
        </VStack>
    )
}

interface AddEditTeamMembersItemProps {
    user: User
    isSelected: boolean
    onClick: (user: User) => void
}

const AddEditTeamMembersItem: FC<AddEditTeamMembersItemProps> = ({ user, isSelected, onClick }) => {
    const { colorMode } = useColorMode()
    return (
        <ListItem data-active={isSelected || undefined} onClick={() => onClick(user)}>
            <Avatar size="md" name={user.fullName} src={user.photoURL ?? undefined} />
            <Heading flex={1} size="h4">
                {user.fullName}
            </Heading>
            {isSelected && (
                <IconButton
                    icon={<CloseCircleIcon color={`${colorMode}.red.default`} />}
                    variant="transparent"
                    aria-label={`Remove ${user.fullName}`}
                />
            )}
        </ListItem>
    )
}
