import { Avatar } from '@missionlabs/react'
import { SpeakerIconSolid } from '@missionlabs/react/zeta'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import { BaseProps } from '../Base'
import { StyledVideoTile } from './StyledPlaceholderTile'

type ObjectFit = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'

export interface PlaceholderVideoTileProps
    extends Omit<HTMLAttributes<HTMLDivElement>, 'css'>,
        BaseProps {
    /** The name to show on the video tile */
    nameplate?: string
    /** Specifies which CSS object-fit value to apply to the VideoTile so that it resizes to fit its container  */
    objectFit?: ObjectFit

    placeholder?: string
    placeholderElement?: ReactNode

    muted: boolean
    nameplateRef?: any
    speakerIconRef?: any
}

export const PlaceholderVideoTile = forwardRef<HTMLVideoElement, PlaceholderVideoTileProps>(
    (
        {
            tag,
            className,
            nameplate,
            placeholder,
            placeholderElement,
            muted: _muted,
            nameplateRef,
            speakerIconRef,
            ...rest
        },
        ref
    ) => {
        const Placeholder = placeholderElement ?? (
            <Avatar size="xl" name={placeholder} className="placeholder" />
        )

        return (
            <StyledVideoTile
                as={tag}
                className={className || ''}
                data-testid="video-tile"
                background={
                    'linear-gradient(156deg, rgba(10, 12, 28, 0.20) 15.93%, rgba(27, 31, 59, 0.20) 52.79%, rgba(110, 112, 144, 0.20) 87.7%)'
                }
                {...rest}
            >
                {ref ? <video ref={ref} className="ch-video" /> : Placeholder}
                {nameplate && nameplateRef && speakerIconRef && (
                    <header ref={nameplateRef} className="ch-nameplate">
                        <p className="ch-text">{nameplate}</p>
                        <div ref={speakerIconRef} className="speaker-icon">
                            <SpeakerIconSolid />
                        </div>
                    </header>
                )}
            </StyledVideoTile>
        )
    }
)

export default PlaceholderVideoTile
