import { HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Heading } from '@missionlabs/react'
import { WarningIconRegular } from '@missionlabs/react/zeta'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type MeetingDetailsProps = {
    name: string
    time: string
    description: string
    isInternalAndExternal: boolean
    meetingOverlaps?: boolean
    hasExpired?: boolean
    isCancelled?: boolean
}

export const MeetingDetails: FC<MeetingDetailsProps> = ({
    name,
    time,
    description,
    isInternalAndExternal,
    meetingOverlaps,
    hasExpired,
    isCancelled
}) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    return (
        <VStack spacing={0} align="start">
            <VStack spacing={0} align="start">
                {isCancelled && (
                    <Heading
                        size="h4"
                        sx={{
                            color: `${colorMode}.alerts.rose`,
                            fontWeight: '700',
                            opacity: 1
                        }}
                    >
                        {t('Cancelled')}
                    </Heading>
                )}
                <Body
                    size="sm"
                    sx={{ color: `${colorMode}.tones.midnightExpress`, fontWeight: '700' }}
                    opacity={isCancelled ? 0.6 : 1}
                >
                    {name}
                </Body>
            </VStack>
            <HStack spacing={3}>
                <Body size="sm" opacity={isCancelled || hasExpired ? 0.6 : 1}>
                    {time}
                </Body>
                {meetingOverlaps && (
                    <HStack
                        spacing={1}
                        color={`${colorMode}.alerts.rose`}
                        opacity={isCancelled || hasExpired ? 0.6 : 1}
                    >
                        <WarningIconRegular />
                        <Body size="sm" sx={{ color: 'inherit' }}>
                            {t('meetings.meetingOverlaps')}
                        </Body>
                    </HStack>
                )}
            </HStack>
            <Body size="sm" opacity={isCancelled || hasExpired ? 0.6 : 1}>
                {isInternalAndExternal ? t('Internal & External') : t('Internal only')}
            </Body>
            <Body size="sm" sx={{ pt: '3px' }}>
                {description}
            </Body>
        </VStack>
    )
}
