import { Box, useMultiStyleConfig, useToken } from '@chakra-ui/react'

interface DirectionIndicatorProps {
    direction: 'inbound' | 'outbound'
    variant?: 'selected' | 'new'
}

/**
 * The little sticky-out-triangle on activities which indicates
 * whether the activity is inbound or outbound
 */
export const DirectionIndicator = ({ direction, variant }: DirectionIndicatorProps) => {
    const styles = useMultiStyleConfig('Activity', { variant })

    /*
     * useToken will give us the actual value of the color rather than `tones.periwinkle` for example.
     * We need this as Chakra doesn't seem to interpolate value the value
     * when there are multiple values in a string
     * i.e. border: `1px solid ${color}`
     */
    const backgroundColor = useToken('colors', styles.container.backgroundColor as string)

    // Align inbound calls to the left and outbound to the right
    const directionalProps =
        direction === 'inbound'
            ? { left: '-16px', right: 'auto' }
            : { left: 'auto', right: '-16px' }

    return (
        <Box
            aria-hidden={true}
            className="direction-indicator"
            sx={{
                ':before': {
                    ...styles.directionIndicator,
                    ...directionalProps,
                    borderColor: `${backgroundColor} transparent transparent transparent`
                }
            }}
        />
    )
}
