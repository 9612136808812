import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ErrorIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ErrorIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 18.75C5.15625 18.75 1.25 14.8438 1.25 10C1.25 5.19531 5.15625 1.25 10 1.25C14.8047 1.25 18.75 5.19531 18.75 10C18.75 14.8438 14.8047 18.75 10 18.75ZM10 11.875C10.3125 11.875 10.625 11.6016 10.625 11.25V5C10.625 4.6875 10.3125 4.375 10 4.375C9.64844 4.375 9.375 4.6875 9.375 5V11.25C9.375 11.6016 9.64844 11.875 10 11.875ZM10 13.4375C9.45312 13.4375 9.0625 13.8672 9.0625 14.375C9.0625 14.9219 9.45312 15.3125 10 15.3125C10.5078 15.3125 10.9375 14.9219 10.9375 14.375C10.9375 13.8672 10.5078 13.4375 10 13.4375Z"
            fill="currentColor"
        />
    )
})

export const ErrorIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ErrorIconRegular',
    viewBox: '0 0 22 20',
    path: (
        <path
            d="M10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 18.125C5.50781 18.125 1.875 14.4922 1.875 10C1.875 5.54688 5.50781 1.875 10 1.875C14.4531 1.875 18.125 5.54688 18.125 10C18.125 14.4922 14.4531 18.125 10 18.125ZM10 11.875C10.5078 11.875 10.9375 11.4844 10.9375 10.9375V5.9375C10.9375 5.42969 10.5078 5 10 5C9.45312 5 9.0625 5.42969 9.0625 5.9375V10.9375C9.0625 11.4844 9.45312 11.875 10 11.875ZM10 13.2031C9.29688 13.2031 8.75 13.75 8.75 14.4141C8.75 15.0781 9.29688 15.625 10 15.625C10.6641 15.625 11.2109 15.0781 11.2109 14.4141C11.2109 13.75 10.6641 13.2031 10 13.2031Z"
            fill="currentColor"
        />
    )
})

export const ErrorIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ErrorIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM9.0625 5.9375C9.0625 5.42969 9.45312 5 10 5C10.5078 5 10.9375 5.42969 10.9375 5.9375V10.9375C10.9375 11.4844 10.5078 11.875 10 11.875C9.45312 11.875 9.0625 11.4844 9.0625 10.9375V5.9375ZM10 15.625C9.29688 15.625 8.75 15.0781 8.75 14.4141C8.75 13.75 9.29688 13.2031 10 13.2031C10.6641 13.2031 11.2109 13.75 11.2109 14.4141C11.2109 15.0781 10.6641 15.625 10 15.625Z"
            fill="currentColor"
        />
    )
})
