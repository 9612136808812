import { Breadcrumb } from '@missionlabs/react'
import { AnalyticsActivities } from 'features/analytics/components/AnalyticsActivites/AnalyticsActivities'
import { AnalyticsPieChartTotals } from 'features/analytics/components/AnalyticsPieChartTotals/AnalyticsPieChartTotals'
import AnalyticsTabsView from 'features/analytics/components/AnalyticsTabView'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import {
    useGetSingleNumberRecentCallHistoryQuery,
    useGetSingleNumberTotalsAnalyticsQuery
} from 'shared/store'

interface Props {}

const AnalyticsNumberExpanded: React.FC<Props> = () => {
    const { numberE164 } = useParams()

    const { t } = useTranslation()

    const navigate = useNavigate()

    const {
        dateScroller: { timezone, start, end },
        group
    } = useAnalytics()

    const apiArgs = {
        from: start?.getTime().toString(),
        to: end?.getTime().toString(),
        timezone,
        group,
        numberE164
    }

    const { data: numberTotal } = useGetSingleNumberTotalsAnalyticsQuery(apiArgs)

    const {
        data: numberRecentActivity,
        isLoading: isLoadingRecentActivity,
        isFetching: isFetchingRecentActivity
    } = useGetSingleNumberRecentCallHistoryQuery(apiArgs)

    const isNoResults = !numberTotal?.data.length && !numberRecentActivity?.data.length

    return (
        <div>
            <SettingsPage
                title={
                    <Breadcrumb
                        variant="title"
                        items={[
                            {
                                title: t('analytics.numbers'),
                                onClick: () => navigate('../numbers'),
                                isCurrentPage: false
                            },
                            { title: numberE164!, isCurrentPage: true }
                        ]}
                    >
                        Users
                    </Breadcrumb>
                }
                subtitle={t('analytics.numbersSubtitle')}
                isLoading={isLoadingRecentActivity || isFetchingRecentActivity}
                contentProps={{ padding: 0, maxW: 'unset' }}
            >
                <AnalyticsTabsView
                    noResults={isNoResults}
                    isLoading={isLoadingRecentActivity || isFetchingRecentActivity}
                    header={null}
                >
                    {!!numberTotal && (
                        <AnalyticsPieChartTotals
                            durations={numberTotal.durations}
                            totals={numberTotal.total}
                        />
                    )}
                    <AnalyticsActivities activities={numberRecentActivity?.data ?? []} />
                </AnalyticsTabsView>
            </SettingsPage>
        </div>
    )
}

export default AnalyticsNumberExpanded
