import { UserPreferences } from '@missionlabs/browser-calling'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PreferencesState extends UserPreferences {
    userID?: string
    showAfterCallScreen?: 'always' | 'unsaved' | 'never'
    closeAfterCallScreenOnSave?: boolean
}

const initialState: PreferencesState = {
    showAfterCallScreen: 'unsaved',
    closeAfterCallScreenOnSave: true
}

export const preferencesSlice = createSlice({
    name: 'preferencesSlice',
    initialState,
    reducers: {
        updatePreferences: (state, action: PayloadAction<PreferencesState>) => {
            return _updatePreferences(state, action.payload)
        },
        updatePreferencesWithoutSettingStorage: (
            state,
            action: PayloadAction<PreferencesState>
        ) => {
            return _updatePreferences(state, action.payload, false)
        }
    }
})

export type PresencesSliceRootState = {
    preferencesSlice: ReturnType<typeof preferencesSlice.getInitialState>
}

const _updatePreferences = (state, preferences: PreferencesState, shouldSetStorage = true) => {
    state = {
        ...state,
        ...preferences
    }
    if (shouldSetStorage) {
        window.localStorage.setItem(
            `userPreferencesID=${state.userID ?? 'guest'}`,
            JSON.stringify(state)
        )
    }
    return state
}

export const selectPreferences = (state: PresencesSliceRootState) => state.preferencesSlice

export const { updatePreferences, updatePreferencesWithoutSettingStorage } =
    preferencesSlice.actions
