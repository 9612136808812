import { AuthenticatedUser } from '@missionlabs/api'
import isElectron from 'is-electron'
import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'

import pkg from '../../../package.json'

const defaultUpdateChannel = getDefaultUpdateChannel()

function getDefaultUpdateChannel() {
    // Default to alpha/beta channels before user is logged in
    // This logic can be removed when zeta-web-clients takes over from circleloop-hybrid
    if (pkg.version.includes('-alpha.')) return 'alpha'
    if (pkg.version.includes('-beta.')) return 'beta'
    return 'latest'
}

/**
 * Set the electron update channel when the user logs in.
 * Used a stored value until the user is not logged in, or fall back to a default value.
 */
export function useElectronUpdateChannel(user: AuthenticatedUser | undefined) {
    const [storedUpdateChannel, setStoredUpdateChannel] = useLocalStorage<string>('updateChannel')
    const updateChannel = user?.updateChannel ?? storedUpdateChannel ?? defaultUpdateChannel

    useEffect(() => {
        if (isElectron()) {
            window.autoUpdater.setUpdateChannel({ channel: updateChannel ?? 'latest' })
            setStoredUpdateChannel(updateChannel)
        }
    }, [updateChannel, setStoredUpdateChannel])
}
