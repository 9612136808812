import { createSelector } from '@reduxjs/toolkit'
// suppress TS portable type annotations error
import type {} from 'reselect'

/**
 * Memoized selector function for selectFromResult usage in queries.
 *
 * https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#selecting-values-from-results
 *
 * @param callback A function to provide a mutation on the queries data
 * @example
 * // the function must be out of the components block scope of memoized within the component
 * const selectICGs = selectInData<UserGroup[]>(data =>
 *      data?.filter(item => item.type === 'INSTANT_CONFERENCE')
 * )
 *
 * export const useGetUsedExtensions = (): UseGetUsedExtensionsValues => {
 *     const {
 *         userGroups = [],
 *         isLoading: userGroupsLoading,
 *         isError: userGroupsError
 *     } = useGetUserGroupsQuery(undefined, {
 *         selectFromResult: result => ({
 *             ...result,
 *             userGroups: selectICGs(result)
 *         })
 *     })
 *     // cont.
 * }
 */
export function selectInData<T = any, R = T>(callback: (data?: T) => R | undefined) {
    return createSelector(
        result => result.data,
        data => callback(data)
    )
}

// similar to above, just for currentData property
export function selectInCurrentData<T = any, R = T>(callback: (currentData?: T) => R | undefined) {
    return createSelector(
        result => result.currentData,
        currentData => callback(currentData)
    )
}
