import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const MenuIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MenuIconLight',
    viewBox: '0 0 18 14',
    path: (
        <path
            d="M0.25 0.75C0.25 0.4375 0.523438 0.125 0.875 0.125H17.125C17.4375 0.125 17.75 0.4375 17.75 0.75C17.75 1.10156 17.4375 1.375 17.125 1.375H0.875C0.523438 1.375 0.25 1.10156 0.25 0.75ZM0.25 7C0.25 6.6875 0.523438 6.375 0.875 6.375H17.125C17.4375 6.375 17.75 6.6875 17.75 7C17.75 7.35156 17.4375 7.625 17.125 7.625H0.875C0.523438 7.625 0.25 7.35156 0.25 7ZM17.125 13.875H0.875C0.523438 13.875 0.25 13.6016 0.25 13.25C0.25 12.9375 0.523438 12.625 0.875 12.625H17.125C17.4375 12.625 17.75 12.9375 17.75 13.25C17.75 13.6016 17.4375 13.875 17.125 13.875Z"
            fill="currentColor"
        />
    )
})

export const MenuIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MenuIconRegular',
    viewBox: '0 0 18 16',
    path: (
        <path
            d="M0.25 1.75C0.25 1.24219 0.640625 0.8125 1.1875 0.8125H16.8125C17.3203 0.8125 17.75 1.24219 17.75 1.75C17.75 2.29688 17.3203 2.6875 16.8125 2.6875H1.1875C0.640625 2.6875 0.25 2.29688 0.25 1.75ZM0.25 8C0.25 7.49219 0.640625 7.0625 1.1875 7.0625H16.8125C17.3203 7.0625 17.75 7.49219 17.75 8C17.75 8.54688 17.3203 8.9375 16.8125 8.9375H1.1875C0.640625 8.9375 0.25 8.54688 0.25 8ZM16.8125 15.1875H1.1875C0.640625 15.1875 0.25 14.7969 0.25 14.25C0.25 13.7422 0.640625 13.3125 1.1875 13.3125H16.8125C17.3203 13.3125 17.75 13.7422 17.75 14.25C17.75 14.7969 17.3203 15.1875 16.8125 15.1875Z"
            fill="currentColor"
        />
    )
})

export const MenuIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MenuIconSolid',
    viewBox: '0 0 18 16',
    path: (
        <path
            d="M0.25 1.75C0.25 1.08594 0.796875 0.5 1.5 0.5H16.5C17.1641 0.5 17.75 1.08594 17.75 1.75C17.75 2.45312 17.1641 3 16.5 3H1.5C0.796875 3 0.25 2.45312 0.25 1.75ZM0.25 8C0.25 7.33594 0.796875 6.75 1.5 6.75H16.5C17.1641 6.75 17.75 7.33594 17.75 8C17.75 8.70312 17.1641 9.25 16.5 9.25H1.5C0.796875 9.25 0.25 8.70312 0.25 8ZM16.5 15.5H1.5C0.796875 15.5 0.25 14.9531 0.25 14.25C0.25 13.5859 0.796875 13 1.5 13H16.5C17.1641 13 17.75 13.5859 17.75 14.25C17.75 14.9531 17.1641 15.5 16.5 15.5Z"
            fill="currentColor"
        />
    )
})
