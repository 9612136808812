import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const CustomIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CustomIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <g clipPath="url(#clip0_644_58)">
                <path
                    d="M2 18.6667C2 19.2778 2.5 19.7778 3.11111 19.7778H8.66667V17.5556H3.11111C2.5 17.5556 2 18.0556 2 18.6667ZM2 5.33333C2 5.94444 2.5 6.44444 3.11111 6.44444H13.1111V4.22222H3.11111C2.5 4.22222 2 4.72222 2 5.33333ZM13.1111 20.8889V19.7778H20.8889C21.5 19.7778 22 19.2778 22 18.6667C22 18.0556 21.5 17.5556 20.8889 17.5556H13.1111V16.4444C13.1111 15.8333 12.6111 15.3333 12 15.3333C11.3889 15.3333 10.8889 15.8333 10.8889 16.4444V20.8889C10.8889 21.5 11.3889 22 12 22C12.6111 22 13.1111 21.5 13.1111 20.8889ZM6.44444 9.77778V10.8889H3.11111C2.5 10.8889 2 11.3889 2 12C2 12.6111 2.5 13.1111 3.11111 13.1111H6.44444V14.2222C6.44444 14.8333 6.94444 15.3333 7.55556 15.3333C8.16667 15.3333 8.66667 14.8333 8.66667 14.2222V9.77778C8.66667 9.16667 8.16667 8.66667 7.55556 8.66667C6.94444 8.66667 6.44444 9.16667 6.44444 9.77778ZM22 12C22 11.3889 21.5 10.8889 20.8889 10.8889H10.8889V13.1111H20.8889C21.5 13.1111 22 12.6111 22 12ZM16.4444 8.66667C17.0556 8.66667 17.5556 8.16667 17.5556 7.55556V6.44444H20.8889C21.5 6.44444 22 5.94444 22 5.33333C22 4.72222 21.5 4.22222 20.8889 4.22222H17.5556V3.11111C17.5556 2.5 17.0556 2 16.4444 2C15.8333 2 15.3333 2.5 15.3333 3.11111V7.55556C15.3333 8.16667 15.8333 8.66667 16.4444 8.66667Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_644_58">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </g>
    )
})
