import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildExtensionsApi = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'extensionsAPI',
        tagTypes: ['Extensions'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getExtensions: builder.query<string[], void>({
                query: () => '/extensions',
                providesTags: result => {
                    return result
                        ? [
                              ...result.map(extension => ({
                                  type: 'Extensions' as const,
                                  id: extension
                              })),
                              'Extensions'
                          ]
                        : ['Extensions']
                }
            })
        })
    })

    return { api, ...api }
}
