import { createStylesContext, SystemStyleObject } from '@chakra-ui/react'
import { Context, Provider } from 'react'

const [StylesProvider, useStyles]: [
    Provider<Record<string, SystemStyleObject>>,
    () => Record<string, SystemStyleObject>,
    Context<Record<string, SystemStyleObject>>
] = createStylesContext('Navbar')

export { StylesProvider, useStyles }
