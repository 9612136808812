import { RedirectInternal } from '@missionlabs/types'

export function absolveInternalRedirectData(
    redirectInternal: RedirectInternal,
    updated: RedirectInternal = {}
): RedirectInternal {
    const IDs = ['menuID', 'number', 'teamID', 'userID']

    const IDField = Object.keys(updated).find(key => IDs.includes(key))
    if (IDField) {
        IDs.forEach(ID => {
            if (ID !== IDField) delete redirectInternal[ID]
        })
    }

    return redirectInternal
}
