import {
    selectAuthenticatedUser,
    updatePreferencesWithoutSettingStorage,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { useEffect } from 'react'

export const useUserPreferencesListener = () => {
    const dispatch = useDispatch()
    const user = useSelector(selectAuthenticatedUser)

    const userID = user?.userID ?? 'guest'

    useEffect(() => {
        if (userID) {
            const handlerListener = event => {
                if (event.key === `userPreferencesID=${userID}`) {
                    dispatch(
                        updatePreferencesWithoutSettingStorage(JSON.parse(event.newValue ?? '{}'))
                    )
                }
            }
            window.addEventListener('storage', handlerListener)
            return () => {
                window.removeEventListener('storage', handlerListener)
            }
        }
    }, [userID, dispatch])
}
