import { Scrollable } from '@missionlabs/react'
import { HuntGroup } from '@missionlabs/types'
import { FC } from 'react'
import { useGetHuntGroupsQuery } from 'shared/store'

import CreateReportDataList from '../components/CreateReportDataList'
import CreateReportListItem from '../components/CreateReportListItem'
import CreateReportSearch from '../components/CreateReportSearch'
import { useCreateReportSearch } from '../hooks/useCreateReportSearch'

interface ReportFormTeamsProps {
    onSelect(ID: string): void
    isSelected(ID: string): boolean
}

export const ReportFormTeams: FC<ReportFormTeamsProps> = ({ onSelect, isSelected }) => {
    const { data: teams = [] } = useGetHuntGroupsQuery()

    const { searchValue, setSearchValue, results } = useCreateReportSearch<HuntGroup>(teams, 'name')

    return (
        <CreateReportDataList>
            <CreateReportSearch search={searchValue} setSearch={setSearchValue} />
            <Scrollable height="container.sm">
                {results.map(team => (
                    <CreateReportListItem
                        isSelected={isSelected(team.ID)}
                        onClick={() => onSelect(team.ID)}
                        key={team.ID}
                    >
                        {team.name}
                    </CreateReportListItem>
                ))}
            </Scrollable>
        </CreateReportDataList>
    )
}
