import { User } from './user'

type LocalisedName = {
    local: string
    name: string
}

export enum BoltOnType {
    CALL_RECORDING = 'Call recording'
}

export const CALL_RECORDING_30_DAYS = 'CALL_RECORDING_THIRTY_DAYS_RETENTION'

type ServicePackType = 'SERVICE_PACK' | 'BOLT_ON'

export type ServicePack = {
    ID: string
    name: string
    displayName?: string
    localisedNames?: LocalisedName[]
    type: ServicePackType
    features: ServicePackFeatureOrBoltOn[]
    permittedBoltOns?: string[]
}

export type BoltOn = ServicePack & {
    boltOnType?: BoltOnType
}

export type GroupedBoltOns = {
    type: string
    packs: BoltOn[]
}

type AssignedBoltOnOrServicePack = {
    assignedPacks: number
    totalPacks: number
    users: User[]
}

export type AssignedBoltOn = {
    boltOnType: string
    packs: AssignedBoltOnPack[]
}

export type AssignedBoltOnPack = Pick<
    BoltOn,
    'ID' | 'name' | 'displayName' | 'type' | 'boltOnType'
> &
    AssignedBoltOnOrServicePack

export type AssignedServicePack = Pick<
    ServicePack,
    'ID' | 'name' | 'displayName' | 'type' | 'features' | 'permittedBoltOns'
> &
    AssignedBoltOnOrServicePack

export type ServicePackResponse = {
    ID: string
    boltOns: AssignedBoltOn[]
    servicePacks: AssignedServicePack[]
}

export interface UserAssignedPacks {
    userID: string
    servicePacks: AssignedServicePack[]
}

export type ServicePackComparison = {
    featuresGained: string[]
    featuresLost: string[]
}

export type ServicePackFeatureOrBoltOn = ServicePackFeatures | ServicePackBoltOns

export enum ServicePackFeatures {
    // G1 Service Pack
    ANONYMOUS_CALL_REJECTION = 'ANONYMOUS_CALL_REJECTION',
    BLOCK_CUSTOMER_INCOMING_OUTGOING = 'BLOCK_CUSTOMER_INCOMING_OUTGOING',
    CALL_BARRING = 'CALL_BARRING',
    CALL_HOLD = 'CALL_HOLD',
    CALL_LOGS = 'CALL_LOGS',
    CALL_TRANSFER = 'CALL_TRANSFER',
    CALLING_NAME_RETRIEVAL = 'CALLING_NAME_RETRIEVAL',
    CLI_BLOCKING_OUTBOUND = 'CLI_BLOCKING_OUTBOUND',
    CLI_DELIVERY = 'CLI_DELIVERY',
    DIRECTORY = 'DIRECTORY',
    EXTENSION_DIALLING = 'EXTENSION_DIALLING',
    LOCAL_DIALLING = 'LOCAL_DIALLING',
    MUSIC_ON_HOLD = 'MUSIC_ON_HOLD',
    NETWORK_TELEPHONE_NUMBER = 'NETWORK_TELEPHONE_NUMBER',
    TELEPHONY_PRESENCE = 'TELEPHONY_PRESENCE',

    // G2 Service Pack
    BLF = 'BLF',
    CALL_ANALYTICS = 'CALL_ANALYTICS',
    CALL_FORWARDING = 'CALL_FORWARDING',
    CALL_PARK = 'CALL_PARK',
    CALL_PICKUP = 'CALL_PICKUP',
    CALL_RECORDING_THIRTY_DAYS_RETENTION = 'CALL_RECORDING_THIRTY_DAYS_RETENTION',
    CALL_WAITING = 'CALL_WAITING',
    CLI_PRESENTATION = 'CLI_PRESENTATION',
    CONFERENCE = 'CONFERENCE',
    // DAY_NIGHT_SERVICE = 'DAY_NIGHT_SERVICE',
    INSTANT_CONFERENCE_GROUP = 'INSTANT_CONFERENCE_GROUP',
    PAGE_GROUP = 'PAGE_GROUP',
    SELECTIVE_CALL_REJECTION = 'SELECTIVE_CALL_REJECTION',
    SPEED_DIAL = 'SPEED_DIAL',
    DEVICE_CUSTOMISATION = 'DEVICE_CUSTOMISATION',
    DO_NOT_DISTURB = 'DO_NOT_DISTURB',
    VOICEMAIL = 'VOICEMAIL',
    // SOFT_PHONE_ONLY_DESKTOP = 'SOFT_PHONE_ONLY_DESKTOP',
    // SOFT_PHONE_ONLY_MOBILE = 'SOFT_PHONE_ONLY_MOBILE',
    CALL_RETRIEVE_BETWEEN_DEVICES = 'CALL_RETRIEVE_BETWEEN_DEVICES',
    SEND_CALLS_ANYWHERE = 'SEND_CALLS_ANYWHERE',
    MEETINGS = 'MEETINGS',
    VIDEO = 'VIDEO'
}

export enum ServicePackBoltOns {
    CALL_RECORDING_3_MONTHS_RETENTION = 'CALL_RECORDING_3_MONTHS_RETENTION',
    CALL_RECORDING_6_MONTHS_RETENTION = 'CALL_RECORDING_6_MONTHS_RETENTION',
    CALL_RECORDING_12_MONTHS_RETENTION = 'CALL_RECORDING_12_MONTHS_RETENTION',
    CALL_RECORDING_36_MONTHS_RETENTION = 'CALL_RECORDING_36_MONTHS_RETENTION',
    CALL_RECORDING_60_MONTHS_RETENTION = 'CALL_RECORDING_60_MONTHS_RETENTION',
    CALL_RECORDING_84_MONTHS_RETENTION = 'CALL_RECORDING_84_MONTHS_RETENTION'
}
