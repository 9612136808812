import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const VoicemailIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VoicemailIconLight',
    viewBox: '0 0 26 12',
    path: (
        <path
            d="M25.5 6C25.5 2.91406 22.9609 0.375 19.875 0.375C16.75 0.375 14.25 2.91406 14.25 6C14.25 7.79688 15.0703 9.35938 16.3594 10.375H9.60156C10.8906 9.35938 11.75 7.79688 11.75 6C11.75 2.91406 9.21094 0.375 6.125 0.375C3 0.375 0.5 2.91406 0.5 6C0.5 8.89062 2.6875 11.2734 5.5 11.5859V11.625H20.5V11.5859C23.2734 11.2734 25.5 8.89062 25.5 6ZM1.75 6C1.75 3.61719 3.70312 1.625 6.125 1.625C8.50781 1.625 10.5 3.61719 10.5 6C10.5 8.42188 8.50781 10.375 6.125 10.375C3.70312 10.375 1.75 8.42188 1.75 6ZM19.875 10.375C17.4531 10.375 15.5 8.42188 15.5 6C15.5 3.61719 17.4531 1.625 19.875 1.625C22.2578 1.625 24.25 3.61719 24.25 6C24.2109 8.42188 22.2578 10.375 19.875 10.375Z"
            fill="currentColor"
        />
    )
})

export const VoicemailIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VoicemailIconRegular',
    viewBox: '0 0 26 12',
    path: (
        <path
            d="M19.8359 0.375C16.75 0.375 14.25 2.91406 14.25 6C14.25 7.44531 14.7969 8.77344 15.6953 9.75H10.2656C11.1641 8.77344 11.75 7.44531 11.75 6C11.75 2.91406 9.21094 0.375 6.125 0.375C3 0.375 0.5 2.91406 0.5 6C0.5 9.125 3 11.625 6.08594 11.625H19.8359C22.9609 11.625 25.5 9.125 25.5 6C25.5 2.91406 22.9609 0.375 19.8359 0.375ZM2.375 6C2.375 3.92969 4.05469 2.25 6.125 2.25C8.19531 2.25 9.875 3.92969 9.875 6C9.875 8.07031 8.19531 9.75 6.125 9.75C4.05469 9.75 2.375 8.07031 2.375 6ZM19.875 9.75C17.8047 9.75 16.125 8.07031 16.125 6C16.125 3.92969 17.8047 2.25 19.875 2.25C21.9453 2.25 23.625 3.92969 23.625 6C23.625 8.07031 21.9453 9.75 19.875 9.75Z"
            fill="currentColor"
        />
    )
})

export const VoicemailIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VoicemailIconSolid',
    viewBox: '0 0 26 12',
    path: (
        <path
            d="M19.8428 0.375C17.7335 0.375 15.8585 1.54688 14.8819 3.38281C13.9053 5.21875 13.9835 7.40625 15.1553 9.16406H10.7803C12.2257 7.01562 11.9913 4.16406 10.2725 2.25C8.55378 0.335938 5.74128 -0.171875 3.47566 1.03906C1.17097 2.28906 0.0381565 4.86719 0.663156 7.36719C1.28816 9.86719 3.51472 11.625 6.09284 11.625H19.8428C22.9678 11.625 25.5069 9.125 25.5069 6C25.5069 2.91406 22.9678 0.375 19.8428 0.375ZM2.96784 6C2.96784 4.28125 4.37409 2.875 6.09284 2.875C7.85066 2.875 9.21784 4.28125 9.21784 6C9.21784 7.75781 7.85066 9.125 6.09284 9.125C4.37409 9.125 2.96784 7.75781 2.96784 6ZM19.8428 9.125C18.085 9.125 16.7178 7.75781 16.7178 6.03906C16.7178 4.35938 18.085 2.91406 19.8428 2.91406C21.5616 2.91406 22.9678 4.32031 22.9678 6.03906C22.9678 7.79688 21.6007 9.125 19.8428 9.125Z"
            fill="currentColor"
        />
    )
})

export const VoicemailGroupIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VoicemailGroupIconRegular',
    viewBox: '0 0 24 12',
    path: [
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2222 4.83005C12.2222 2.18023 14.4445 0 17.1875 0C19.9653 0 22.2223 2.18023 22.2223 4.83005C22.2223 7.51341 19.9653 9.6601 17.1875 9.6601H4.96529C2.22223 9.6601 0 7.51341 0 4.83005C0 2.18023 2.22223 0 5.00001 0C7.74307 0 10 2.18023 10 4.83005C10 6.0711 9.47919 7.21153 8.68057 8.05008H13.507C12.7084 7.21153 12.2222 6.0711 12.2222 4.83005ZM5.00001 1.61002C3.15973 1.61002 1.66667 3.05232 1.66667 4.83005C1.66667 6.60778 3.15973 8.05008 5.00001 8.05008C6.84029 8.05008 8.33335 6.60778 8.33335 4.83005C8.33335 3.05232 6.84029 1.61002 5.00001 1.61002ZM13.8889 4.83005C13.8889 6.60778 15.382 8.05008 17.2223 8.05008C19.0625 8.05008 20.5556 6.60778 20.5556 4.83005C20.5556 3.05232 19.0625 1.61002 17.2223 1.61002C15.382 1.61002 13.8889 3.05232 13.8889 4.83005Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M6.74302 12C5.46423 12 4.29864 11.5335 3.41863 10.7635C3.91326 10.8841 4.43136 10.9481 4.96524 10.9481H17.1875C18.4632 10.9481 19.6546 10.5883 20.6537 9.96611C20.867 9.84807 21.0656 9.7079 21.246 9.54884C22.5703 8.49822 23.4457 6.94055 23.5459 5.17113C23.8375 5.78154 24 6.45884 24 7.16995C24 9.85331 21.7431 12 18.9653 12H6.74302Z"
            fill="currentColor"
            key="2"
        />
    ]
})

export const VoicemailGroupIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VoicemailGroupIconSolid',
    viewBox: '0 0 24 12',
    path: [
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5543 2.6736C13.4064 1.04166 15.0423 0 16.8828 0C19.6093 0 21.8247 2.25693 21.8247 4.99997C21.8247 7.77774 19.6093 9.99995 16.8828 9.99995H4.88584C2.63642 9.99995 0.69374 8.43746 0.148425 6.21524C-0.396889 3.99303 0.591493 1.70138 2.60234 0.590275C4.57911 -0.486109 7.03302 -0.034722 8.53263 1.66666C10.0322 3.36804 10.2367 5.90275 8.9757 7.81246H12.7929C11.7704 6.24997 11.7023 4.30553 12.5543 2.6736ZM4.88584 2.22221C3.38623 2.22221 2.15927 3.4722 2.15927 4.99997C2.15927 6.56246 3.38623 7.77774 4.88584 7.77774C6.41954 7.77774 7.61242 6.56246 7.61242 4.99997C7.61242 3.4722 6.41954 2.22221 4.88584 2.22221ZM14.1562 5.0347C14.1562 6.56246 15.3491 7.77774 16.8828 7.77774C18.4165 7.77774 19.6093 6.59719 19.6093 5.0347C19.6093 3.50693 18.3824 2.25693 16.8828 2.25693C15.3491 2.25693 14.1562 3.54165 14.1562 5.0347Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M24 8.00003C24 7.25126 23.8349 6.53871 23.5394 5.89841C23.2579 9.15471 20.5649 11.6667 17.3131 11.6667H5.31616C4.67121 11.6667 4.04642 11.5655 3.45763 11.3764C4.35815 12.3844 5.64923 13 7.06117 13H19.0581C21.7847 13 24 10.7778 24 8.00003Z"
            fill="currentColor"
            key="2"
        />
    ]
})
