import { Box, ButtonGroup, useColorMode, useDisclosure } from '@chakra-ui/react'
import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@missionlabs/react'
import {
    ClipboardIconLight,
    DotsOutlineVerticalIconRegular,
    EditIconRegular,
    FlagIcon,
    ForwardAllIconRegular,
    ReplyIconRegular,
    SmileEmojiPlusIconRegular
} from '@missionlabs/react/zeta'
import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { EmojiPicker } from 'shared/components/EmojiPicker'

import { ChatContext } from '../context/chatContext'

export interface ChatHistoryActionsProps {
    menuIsOpen: boolean
    onEmojiClick: (unicode: string) => void
    onReplyClick?: () => void
    onEditClick?: () => void
    onCopyToClipboard?: () => void
    onThreadReplyClick?: () => void
    onMarkUnreadClick?: () => void
    onForwardMessageClick?: () => void
    isEditable?: boolean
    isThread: boolean
}

export const ChatHistoryActions: FC<ChatHistoryActionsProps> = ({
    onEmojiClick,
    onReplyClick,
    onEditClick,
    onCopyToClipboard,
    onThreadReplyClick,
    onMarkUnreadClick,
    onForwardMessageClick,
    isEditable,
    isThread,
    menuIsOpen
}) => {
    const { colorMode } = useColorMode()
    const { isOpen, onClose, onToggle } = useDisclosure()
    const { t } = useTranslation()
    function handleEmojiClick(unicode: string) {
        onEmojiClick(unicode)
        onClose()
    }
    const { enabledFeatures } = useContext(ChatContext)
    const showSubMenu = enabledFeatures.threads || isEditable

    if (!menuIsOpen && !isOpen) return null

    return (
        <Box
            position="absolute"
            top="-15px"
            right="24px"
            bg={`${colorMode}.tones.ghostWhite`}
            borderRadius="8px"
        >
            <EmojiPicker
                isOpen={isOpen}
                placement="auto-start"
                offset={[0, 0]}
                onClick={handleEmojiClick}
                onClose={onClose}
            />
            <Menu variant="compact">
                {showSubMenu && (
                    <MenuList>
                        {enabledFeatures.threads && !isThread && (
                            <MenuItem
                                icon={<ReplyIconRegular boxSize="24px" p="4px" />}
                                onClick={onThreadReplyClick}
                            >
                                {t('chat.replyInThread')}
                            </MenuItem>
                        )}

                        {isEditable && (
                            <MenuItem
                                icon={<EditIconRegular boxSize="24px" p="4px" />}
                                onClick={onEditClick}
                            >
                                {t('chat.editMessage')}
                            </MenuItem>
                        )}
                        {enabledFeatures.markUnread && (
                            <MenuItem
                                icon={<FlagIcon boxSize="24px" p="4px" />}
                                onClick={onMarkUnreadClick}
                            >
                                {t('chat.markUnread')}
                            </MenuItem>
                        )}
                        <MenuItem
                            icon={<ClipboardIconLight boxSize="24px" p="4px" />}
                            onClick={onCopyToClipboard}
                        >
                            {t('chat.copy')}
                        </MenuItem>
                        <MenuItem
                            icon={<ForwardAllIconRegular boxSize="24px" p="4px" />}
                            onClick={onForwardMessageClick}
                        >
                            {t('chat.forwardMessage')}
                        </MenuItem>
                    </MenuList>
                )}
                <ButtonGroup spacing={0}>
                    <IconButton
                        size="sm"
                        variant="transparent"
                        sx={{
                            border: '1px solid',
                            borderRight: !onReplyClick ? 'none' : undefined,
                            borderColor: `${colorMode}.tones.periwinkle`,
                            borderRadius: '8px',
                            borderTopRightRadius: 'none',
                            borderBottomRightRadius: 'none'
                        }}
                        aria-label="Add reaction"
                        onClick={onToggle}
                    >
                        <SmileEmojiPlusIconRegular boxSize="24px" p="4px" />
                    </IconButton>
                    {!!onReplyClick && (
                        <IconButton
                            size="sm"
                            variant="transparent"
                            sx={{
                                borderTop: '1px solid',
                                borderBottom: '1px solid',
                                borderColor: `${colorMode}.tones.periwinkle`,
                                borderRadius: 'none'
                            }}
                            aria-label="Reply to message"
                            onClick={onReplyClick}
                        >
                            <ReplyIconRegular boxSize="24px" p="4px" />
                        </IconButton>
                    )}

                    <IconButton
                        size="sm"
                        variant="transparent"
                        disabled={!showSubMenu}
                        sx={{
                            border: '1px solid',
                            borderColor: `${colorMode}.tones.periwinkle`,
                            borderRadius: '8px',
                            borderTopLeftRadius: 'none',
                            borderBottomLeftRadius: 'none'
                        }}
                        aria-label="More"
                        as={MenuButton}
                    >
                        <DotsOutlineVerticalIconRegular boxSize="24px" p="5px" />
                    </IconButton>
                </ButtonGroup>
            </Menu>
        </Box>
    )
}
