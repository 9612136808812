import { useColorMode, VStack } from '@chakra-ui/react'
import {
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    FormProvider,
    useYupForm
} from '@missionlabs/react'
import { AttendeeDetails } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useAddEditMeetingSchema } from '../hooks/useAddEditMeetingSchema'
import { AddEditMeetingFormFields } from './AddEditMeetingDrawer'
import { SharedForm } from './SharedForm'

type MeetingShareFormFields = Pick<AddEditMeetingFormFields, 'invitedUsers'> & {
    alreadyInvitedUsers: AttendeeDetails[]
}

type ViewSharedMeetingDrawerContentProps = {
    onClose: () => void
    isLoading?: boolean
    values: MeetingShareFormFields
}

export const ViewSharedMeetingDrawerContent: FC<ViewSharedMeetingDrawerContentProps> = ({
    values
}) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    const { meetingShareSchema } = useAddEditMeetingSchema()

    const methods = useYupForm<MeetingShareFormFields>(meetingShareSchema, {
        values: { invitedUsers: [], alreadyInvitedUsers: values?.alreadyInvitedUsers }
    })

    return (
        <DrawerContent>
            <DrawerHeader>{t('Attendees')}</DrawerHeader>
            <DrawerBody>
                <FormProvider {...methods}>
                    <VStack
                        as="form"
                        h="100%"
                        spacing="16px"
                        align="stretch"
                        justify="space-between"
                        bg={`${colorMode}.tones.ghostWhite`}
                    >
                        <SharedForm />
                    </VStack>
                </FormProvider>
            </DrawerBody>
        </DrawerContent>
    )
}
