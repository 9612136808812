import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const WhatsappIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'WhatsappIcon',
    viewBox: '0 0 30 30',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.40583 19.8642L5 25L10.2542 23.6217C11.7017 24.4117 13.3358 24.8275 14.9942 24.8283C20.4567 24.8283 24.9025 20.3825 24.905 14.9183C24.9058 12.27 23.8758 9.78 22.005 7.90667C20.1333 6.03333 17.645 5.00083 14.9942 5C9.53167 5 5.08583 9.44583 5.08333 14.9092C5.0825 16.6567 5.53833 18.3608 6.40583 19.8642ZM19.6478 17.0515C19.8206 17.1348 19.937 17.191 19.9866 17.2741C20.0491 17.3774 20.0491 17.8733 19.8424 18.4524C19.6358 19.0308 18.6466 19.5591 18.1708 19.6299C17.7441 19.6933 17.2041 19.7199 16.6108 19.5316C16.2516 19.4174 15.7899 19.2649 15.1991 19.0099C12.8816 18.0094 11.3147 15.765 11.0141 15.3344C10.9925 15.3034 10.9774 15.2818 10.9691 15.2708L10.9661 15.2668C10.8323 15.0879 9.95825 13.919 9.95825 12.7091C9.95825 11.5701 10.5169 10.9729 10.7752 10.6968C10.7933 10.6774 10.81 10.6596 10.8249 10.6433C11.0516 10.3949 11.3199 10.3333 11.4849 10.3333L11.9599 10.3416C11.9776 10.3425 11.996 10.3423 12.015 10.3422C12.1591 10.3414 12.3392 10.3403 12.5174 10.7666C12.5809 10.9195 12.6718 11.1411 12.7691 11.3782C12.9874 11.91 13.2375 12.5196 13.2808 12.6049C13.3433 12.7291 13.3841 12.8741 13.3016 13.0391C13.2889 13.0644 13.2771 13.0883 13.2659 13.111C13.2038 13.2374 13.1582 13.3301 13.0516 13.4516C13.0101 13.5001 12.9672 13.5523 12.9245 13.6045C12.8393 13.7083 12.7542 13.812 12.6799 13.8858C12.5566 14.0091 12.4274 14.1433 12.5716 14.3908C12.7158 14.6391 13.2133 15.4499 13.9491 16.1066C14.7393 16.8119 15.4266 17.11 15.7757 17.2614C15.8444 17.2912 15.9 17.3153 15.9408 17.3358C16.1883 17.4599 16.3333 17.4391 16.4774 17.2741C16.6216 17.1091 17.0966 16.5508 17.2616 16.3033C17.4274 16.0549 17.5924 16.0966 17.8191 16.1791C18.0466 16.2616 19.2641 16.8608 19.5116 16.9849C19.5602 17.0092 19.6057 17.0312 19.6478 17.0515Z"
            fill="currentColor"
        />
    )
})
