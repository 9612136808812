import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const CircleloopLogoIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CircleloopLogoIcon',
    viewBox: '0 0 127 128',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.3569 96.976C11.5204 79.0976 11.4075 50.544 30.5038 31.4016C49.4945 12.3718 77.6302 12.3718 95.243 30.0259C100.366 35.1616 103.864 40.9184 106.001 47.072L85.9438 67.1456C86.7246 60.192 83.9694 53.1104 78.5486 47.6864C69.2814 38.3936 54.8718 37.0208 45.259 46.6496C35.3102 56.624 37.0286 70.2688 46.059 79.3216C55.3294 88.6144 69.7422 89.9872 79.3518 80.3584C79.3806 80.3232 107.454 52.1216 107.454 52.1216C107.461 52.1536 121.265 38.416 121.265 38.416C118.2 31.5642 113.867 25.1373 108.257 19.5101C83.4926 -5.31456 43.3422 -5.31456 18.5774 19.5101C-6.18965 44.3328 -6.18965 84.5789 18.5771 109.401C24.2011 115.04 30.6206 119.382 37.4734 122.457L46.9806 112.924C51.355 114.201 55.9118 114.889 60.5646 114.889C66.5262 114.889 72.3694 113.753 77.9342 111.676L61.6782 127.974C78.4814 128.432 95.4318 122.256 108.257 109.401C121.064 96.5629 127.227 79.5997 126.789 62.7773L94.3598 95.3437C75.1182 114.636 46.9774 114.636 29.3582 96.9757L29.3569 96.976Z"
            fill="currentColor"
        />
    )
})
