import { MediaDevice } from '@missionlabs/types'
import RecordRTC from 'recordrtc'
import adapter from 'webrtc-adapter'

import Logger from './Logger'
import { preferCodec, updateBandwidthRestriction } from './sdpUtils'

export type webrtcConfig = RTCConfiguration & {
    enableRecording?: boolean
    audio?: { deviceId: { ideal: string } }
    video?: { deviceId: { ideal: string } }
    goog?: boolean
    disableDSCP?: boolean
    bandwidthLimit?: number
    preferCodec?: string
    forbiddenCodecs?: string[]
    duration?: number
    gap?: number
    muted?: boolean
}
export default class webrtc {
    callTraceID: string
    config: webrtcConfig
    icestatus: any
    pc?: RTCPeerConnection
    dtmfSender?: any

    _recorder: any
    _localStream?: MediaStream
    _remoteStream?: MediaStream
    _recording: boolean

    onofferrestarted = (_callTraceID: string, _offer: any) => {}
    onoffercreated = (_callTraceID: string, _offer: any) => {}
    onremotestream = (_callTraceID: string, _stream: MediaStream) => {}
    onready = (..._args: any) => {}
    onanswercreated = (_callTraceID: string, _args: any) => {}
    onicecandidate = (_callTraceID: string, _candidate: any) => {}
    onicecomplete = (_callTraceID: string) => {}
    onicedisconnect = (..._args: any) => {}
    onicefailed = (_callTraceID: string) => {}
    onerror = (_callTraceID: string, _args: any) => {}
    onrecordingfinished = (..._args: any) => {}
    onnegotionationneeded = (..._args: any) => {}

    constructor(callTraceID: string, config?) {
        this.config = config || {}
        this.callTraceID = callTraceID
        this.config.muted = false

        if (!RTCPeerConnection) {
            Logger.log('error', 'no webrtc support')
            throw new Error('No browser support')
        }

        this._recorder = null
        this._recording = false
    }
    updateDeviceList(devices: MediaDevice[]) {
        if (!this.config.audio) this.config.audio = { deviceId: { ideal: 'default' } }

        // If disconnected devices is not the device in use return,  else update mic to default
        const audioDevices = devices.filter(device => device.kind === 'audioinput')

        const isConfigMicStillConnected = audioDevices.find(
            device => device.deviceId === this.config.audio?.deviceId.ideal
        )

        if (isConfigMicStillConnected) {
            this.updateAudio()
        } else {
            console.log('Microphone disconnected, updating to default')
            this.updateAudio(true)
        }
    }

    init() {
        if (!this.config?.audio) {
            this.config.audio = { deviceId: { ideal: 'default' } }
        }

        Logger.log('debug', 'creating RTC connection')
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let constraints: any = { optional: [{ googDscp: true }] } //  http://www.rtcbits.com/2017/01/using-dscp-for-webrtc-packet-marking.html
        if (this.config.disableDSCP) {
            Logger.log('info', 'webRTC saw disableDSCP set in config, not using DSCP')

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            constraints = null
        }
        this.pc = new RTCPeerConnection(this.config)

        this.pc.onicecandidate = e => this._onicecandidate(e)

        this.pc.oniceconnectionstatechange = _e => {
            Logger.log(
                'trace',
                'ice connection state changed to ' + this.pc?.iceConnectionState,
                null,
                'ICE_CHANGE'
            )

            switch (this.pc?.iceConnectionState) {
                case 'disconnected':
                    // this.onicedisconnect()
                    // this.pc.restartIce()
                    // this.createOffer(true)
                    break
                case 'failed':
                    // this.pc.restartIce()
                    // this.createOffer(true)
                    // this.onicefailed()
                    break
            }
        }

        this.pc.onnegotiationneeded = e => this.onnegotionationneeded(e)

        this.pc.ontrack = remoteStream => {
            this.onremotestream(this.callTraceID, remoteStream.streams[0])
            this._remoteStream = remoteStream.streams[0]
            if (this.config.enableRecording) {
                this._startRecording()
            }
        }

        this.addStream()
    }

    _onicecandidate(event) {
        if (event.candidate) {
            this.onicecandidate(this.callTraceID, event.candidate)
        } else {
            this.onicecomplete(this.callTraceID)
        }
    }

    _startRecording() {
        if (!this.config.enableRecording) {
            return
        }
        if (!this._remoteStream || !this._localStream) {
            console.log('Waiting for both streams before we start recording')
            return
        }
        if (this._recording === true) {
            console.log('Already recording, so not starting recording')
            return
        }
        console.log('Got both streams: recording')
        Logger.log('info', 'recording stream')

        this._recorder = new RecordRTC(
            this._localStream, //huh?
            { type: 'audio' }
        )

        this._recorder.startRecording()
        this._recording = true
    }

    _endRecording() {
        if (!this.config.enableRecording) {
            return
        }
        try {
            if (this._recorder) {
                const recorder = this._recorder
                this._recorder.stopRecording(dataURL => {
                    console.log('Stopped recording, url is: ', dataURL)
                    const recordedBlob = recorder.getBlob()
                    //saveData(dataURL, "call.webm", "video/webm");
                    this.onrecordingfinished({
                        dataURL: dataURL,
                        extension: 'webm',
                        blob: recordedBlob
                    })
                    recorder.getDataURL(function (dataURL) {
                        console.log('recording data url from callback is: ', dataURL)
                        //^ this is the data as a big text blob. i.e. for uploading.
                    })
                })
            }
        } catch (e) {
            Logger.log('info', 'There was an error stopping the recording:', e)
        }
        this._localStream = undefined
        this._remoteStream = undefined
        this._recording = false
        this._recorder = null
    }

    getAudioConstraints() {
        let audio = this.config.audio ?? true
        const video = this.config.video ?? false

        const callOptions = localStorage.getItem('advanced-call-options')
        if (typeof audio === 'object' && callOptions) {
            Logger.log('info', 'callOptions', JSON.parse(callOptions))
            audio = Object.assign(audio, JSON.parse(callOptions))
        }
        if (this.config.goog) {
            audio = Object.assign(audio, {
                echoCancellation: false,
                autoGainControl: false,
                noiseSuppression: false,
                highpassFilter: false
            })
        }
        return {
            audio: audio,
            video: video
        }
    }

    addStream() {
        const timeout = setTimeout(() => {
            this.onerror(this.callTraceID, { type: 'audio' })
        }, 4000)
        navigator.mediaDevices
            .getUserMedia(this.getAudioConstraints())
            .then(stream => {
                clearTimeout(timeout)
                Logger.log('debug', 'add stream', stream)
                stream.getTracks().forEach(track => this.pc?.addTrack(track))
                if (this.config.enableRecording) {
                    this._localStream = stream
                    this._startRecording()
                }
                this.onready()
            })
            .catch(err => {
                clearTimeout(timeout)
                Logger.log('error', 'No permissions for user media', err)
                this.onerror(this.callTraceID, {
                    type: err.name === 'NotAllowedError' ? 'Permissions' : 'Mic'
                })
            })
    }

    createOffer(iceRestart = false) {
        this.pc
            ?.createOffer({ iceRestart, offerToReceiveAudio: true, offerToReceiveVideo: false })
            .then(offer => {
                this.pc
                    ?.setLocalDescription(offer)
                    .then(() => {
                        const localDescription = {
                            type: this.pc?.localDescription?.type,
                            sdp: this.pc?.localDescription?.sdp
                        }
                        if (this.config.bandwidthLimit) {
                            Logger.log(
                                'info',
                                'Adding bandwidth limit to sdp offer before sending',
                                {
                                    limit: this.config.bandwidthLimit
                                }
                            )
                            localDescription.sdp = updateBandwidthRestriction(
                                localDescription.sdp,
                                this.config.bandwidthLimit
                            )
                        }
                        if (this.config.preferCodec) {
                            Logger.log(
                                'debug',
                                'Altering SDP to prefer codec: ' + this.config.preferCodec
                            )
                            if (localDescription.sdp) {
                                localDescription.sdp = preferCodec(
                                    localDescription.sdp,
                                    this.config.preferCodec,
                                    this.config.forbiddenCodecs ?? []
                                )
                            }
                        }
                        Logger.log('debug', 'set localDescription success', localDescription)

                        if (iceRestart) {
                            this.onofferrestarted(this.callTraceID, localDescription)
                        } else {
                            this.onoffercreated(this.callTraceID, localDescription)
                        }
                    })
                    .catch(e => this.onerror(this.callTraceID, e))
            })
    }

    createAnswer() {
        this.pc?.createAnswer(
            answer => {
                this.pc
                    ?.setLocalDescription(answer)
                    .then(() => {
                        const localDescription = {
                            type: this.pc?.localDescription?.type,
                            sdp: this.pc?.localDescription?.sdp
                        }
                        if (this.config.bandwidthLimit) {
                            Logger.log(
                                'info',
                                'Adding bandwidth limit to sdp answer before sending',
                                {
                                    limit: this.config.bandwidthLimit
                                }
                            )
                            localDescription.sdp = updateBandwidthRestriction(
                                localDescription.sdp,
                                this.config.bandwidthLimit
                            )
                        }
                        if (this.config.preferCodec) {
                            Logger.log(
                                'info',
                                'Altering SDP to prefer codec: ' + this.config.preferCodec
                            )
                            if (localDescription.sdp) {
                                localDescription.sdp = preferCodec(
                                    localDescription.sdp,
                                    this.config.preferCodec
                                )
                            }
                        }
                        Logger.log('info', 'set localDescription success', answer)
                        this.onanswercreated(this.callTraceID, localDescription)
                    })
                    .catch(e => {
                        this.onerror(this.callTraceID, e)
                    })
            },
            e => this.onerror(this.callTraceID, e)
        )
    }

    _getMediaConstraints() {
        const audio = !!this.config.audio
        const video = !!this.config.video
        let mediaConstraints: any = null
        if (
            adapter.browserDetails.browser === 'firefox' ||
            adapter.browserDetails.browser === 'edge'
        ) {
            mediaConstraints = {
                offerToReceiveAudio: audio,
                offerToReceiveVideo: video
            }
        } else {
            mediaConstraints = {
                mandatory: {
                    OfferToReceiveAudio: audio,
                    OfferToReceiveVideo: video
                }
            }
        }
        return mediaConstraints
    }

    setRemoteDescription(desc: RTCSessionDescriptionInit) {
        this.pc
            ?.setRemoteDescription(new RTCSessionDescription(desc))
            .then(() => {
                // Logger.log('info', 'set remote description', desc)
            })
            .catch(err => {
                Logger.log('error', 'error remote description', err)
            })
    }

    close() {
        Logger.log('info', 'Close peer connection')
        this._endRecording()
        const audioTrack = this.getAudioTrack()
        if (audioTrack) audioTrack.stop()
        if (this.pc?.signalingState !== 'closed') {
            this.pc?.close()
        }
    }

    unmute() {
        this.config.muted = false
        const audioTrack = this.getAudioTrack()
        if (audioTrack) {
            audioTrack.enabled = true
        }
    }

    mute() {
        this.config.muted = true
        const audioTrack = this.getAudioTrack()
        if (audioTrack) {
            audioTrack.enabled = false
        }
    }

    getAudioTrack() {
        const sender = this.pc?.getSenders()

        if (!sender?.length) return
        const audioTrack = sender[0].track
        if (audioTrack) {
            return audioTrack
        }
    }

    sendDTMFInband(tones) {
        if (!this.dtmfSender) {
            const audioTrack = this.getAudioTrack()
            if (!audioTrack) return

            // @ts-ignore createDTMDSender is deprecated
            this.dtmfSender = this.pc.createDTMFSender(audioTrack)
            this.dtmfSender.ontonechange = tone => {
                Logger.log('info', 'Sent DTMF tone: ' + tone.tone)
            }
        }
        if (!this.dtmfSender) {
            Logger.log('error', 'Invalid dtmf config')
            return
        }
        const duration = this.config.duration ?? 500
        const gap = this.config.gap ?? 50
        this.dtmfSender.insertDTMF(tones, duration, gap)
    }

    setInputDevice(audio: { deviceId: { ideal: string } }) {
        this.config.audio = audio
    }

    updateAudio(switchToDefaultDevice?: boolean) {
        const audioConstraints = this.getAudioConstraints()
        if (switchToDefaultDevice) audioConstraints.audio = { deviceId: { ideal: 'default' } }

        navigator.mediaDevices
            .getUserMedia(audioConstraints)
            .then(stream => {
                const audioTrack = stream.getAudioTracks()[0]
                Logger.log('debug', 'add stream', stream)
                const sender = this.pc?.getSenders().find(function (s) {
                    return s.track?.kind === audioTrack.kind
                })

                if (this.config.muted) audioTrack.enabled = false

                sender?.replaceTrack(audioTrack)
                Logger.log('debug', `Input device: ${audioTrack.label}`)
            })
            .catch(err => {
                Logger.log('error', 'No permissions for user media', err)
                this.onerror(this.callTraceID, {
                    type: err.name === 'NotAllowedError' ? 'Permissions' : 'Mic'
                })
            })
    }

    getRemoteSteam() {
        return this._remoteStream
    }
}
