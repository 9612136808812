import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'
// import { selectAuthEnvironment } from 'features/auth/authSlice'
// import { populateUrl } from '@missionlabs/utils'

export interface SuccessfulAuthResponse {
    access_token: string
    token_type: 'Bearer' | string
    expires_in: number
    config: {
        web: string
        sipsrv: string
        turn: string
        ldap: string
    }
}

export type SuccessfulOTPAuthResponse = Omit<SuccessfulAuthResponse, 'config'> & {
    config: {
        web: string
        roleID: string
    }
}

export interface IncorrectCredentialsAuthResponse {
    data: { userName: string }
    status: 401
    message: 'Invalid credentials'
}

export interface LockedAuthResponse {
    data: { userName: string; locked: true }
    status: 401
    message: 'The account is locked out'
}

export type ZetaIDAuthResponse =
    | SuccessfulAuthResponse
    | IncorrectCredentialsAuthResponse
    | LockedAuthResponse

export const buildZetaIDAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'zetaIDApi',
        baseQuery: baseQuery,
        endpoints: builder => ({
            authenticate: builder.mutation<
                SuccessfulAuthResponse,
                { userName: string; password: string }
            >({
                query: body => ({
                    url: '/auth',
                    body,
                    method: 'POST'
                })
            }),
            forgotPassword: builder.mutation<void, { userName: string }>({
                query: body => ({
                    url: '/resetpassword',
                    body,
                    method: 'POST'
                })
            })
        })
    })

    return { api, ...api }
}
