import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const MakeANoteIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MakeANoteIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.2416 0.660513C20.6509 0.241811 21.2105 0.0040141 21.796 0L21.9128 0.00232352C22.4559 0.0275969 22.9721 0.253895 23.3602 0.640445C23.7735 1.05519 24.0038 1.61797 24 2.20354C23.9959 2.78905 23.758 3.34865 23.3423 3.75467L23.0057 4.09127L23.4036 3.69034C23.2539 3.54754 23.1643 3.46137 23.1348 3.43185L20.568 0.864963L20.3015 0.604006C20.1777 0.725931 20.0897 0.812917 20.0377 0.864963C19.9741 0.928497 19.8871 1.01594 19.7766 1.12728C19.9166 1.27185 20.0036 1.36118 20.0377 1.39527L22.6045 3.96216L22.8785 4.21845L16.0955 11.0016L16.3604 10.7362L16.095 10.4713L13.5257 7.90642L13.2537 7.64892L12.9957 7.90656L20.2416 0.660513ZM16.0105 11.0666C16.0435 11.0472 16.0735 11.0236 16.0955 11.0016L16.0928 11.0043C16.0682 11.0289 16.0405 11.0498 16.0105 11.0666ZM15.7998 11.1334L15.9208 11.1024C15.9522 11.0944 15.9824 11.0823 16.0105 11.0666C15.9862 11.0808 15.9604 11.0927 15.9348 11.1L15.7998 11.1334ZM12.863 8.21208C12.8592 8.2277 12.8546 8.24561 12.8493 8.26583C12.9266 8.3597 12.9755 8.41682 12.9959 8.43719L15.5652 11.0021L15.7225 11.1526L15.7998 11.1334L12.4685 11.9879C12.1924 12.0587 11.9413 11.8077 12.0121 11.5315L12.863 8.21208ZM12.8963 8.08203L12.863 8.21208C12.8652 8.20303 12.8671 8.19475 12.8688 8.18724C12.8779 8.146 12.8871 8.11135 12.8963 8.08203ZM12.9363 7.98357C12.9233 8.00764 12.9098 8.03913 12.8963 8.08203L12.897 8.0792C12.9057 8.04551 12.9189 8.01335 12.9363 7.98357ZM12.9363 7.98357C12.9569 7.94529 12.9765 7.92581 12.9931 7.90922L12.9951 7.90715C12.9722 7.93009 12.9524 7.95579 12.9363 7.98357ZM22.8785 4.21845L22.879 4.21891L23.0057 4.09127L22.8785 4.21845ZM4 3.42857H15.2611L14.2611 4.42857H4C2.34315 4.42857 1 5.77172 1 7.42857V20C1 21.6569 2.34315 23 4 23H16.5714C18.2283 23 19.5714 21.6569 19.5714 20V9.73793L20.5714 8.7379V20C20.5714 22.2091 18.7806 24 16.5714 24H4C1.79086 24 0 22.2091 0 20V7.42857C0 5.21944 1.79086 3.42857 4 3.42857Z"
            fill="currentColor"
        />
    )
})

export const NoteIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NoteIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 4C3 2.89543 3.89543 2 5 2H15V5C15 6.65685 16.3431 8 18 8H21V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V4ZM7 10.5C7 10.2239 7.22386 10 7.5 10H16.5C16.7761 10 17 10.2239 17 10.5C17 10.7761 16.7761 11 16.5 11H7.5C7.22386 11 7 10.7761 7 10.5ZM7.5 13C7.22386 13 7 13.2239 7 13.5C7 13.7761 7.22386 14 7.5 14H16.5C16.7761 14 17 13.7761 17 13.5C17 13.2239 16.7761 13 16.5 13H7.5ZM7 16.5C7 16.2239 7.22386 16 7.5 16H16.5C16.7761 16 17 16.2239 17 16.5C17 16.7761 16.7761 17 16.5 17H7.5C7.22386 17 7 16.7761 7 16.5ZM7.5 7C7.22386 7 7 7.22386 7 7.5C7 7.77614 7.22386 8 7.5 8H12.5C12.7761 8 13 7.77614 13 7.5C13 7.22386 12.7761 7 12.5 7H7.5Z"
                fill="currentColor"
            />
            <path d="M21 7L16 2V5C16 6.10457 16.8954 7 18 7H21Z" fill="currentColor" />
        </g>
    )
})
