import { Spinner, Table } from '@missionlabs/react'
import { NumberE164 } from '@missionlabs/types'
import { createColumnHelper } from '@tanstack/react-table'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetNumbersQuery, useGetWhatsAppNumbersQuery } from 'shared/store'

const columnHelper = createColumnHelper<Partial<NumberE164>>()

export const SelectWhatsAppNumberTable: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { formatToLocalNumber, isUKMobileNumber } = useFormatToNumberE164()
    const { data: waNumbers, isLoading: waNumbersLoading } = useGetWhatsAppNumbersQuery()
    const { data: clNumbers = [], isLoading: clNumbersLoading } = useGetNumbersQuery({
        loadAssociations: true
    })

    const isLoading = waNumbersLoading || clNumbersLoading
    const numbers = useMemo(() => {
        const ignoreIDs: string[] = waNumbers ? waNumbers.map(waNumber => waNumber.numberID) : []
        return clNumbers.filter(number => !ignoreIDs.includes(number.ID))
    }, [clNumbers, waNumbers])

    const formatNumber = (number: NumberE164) => {
        return { ...number, displayNumber: formatToLocalNumber(number.numberE164) }
    }

    const results = numbers.filter(number => isUKMobileNumber(number.numberE164)).map(formatNumber)

    const getAssigneeType = (row: Partial<NumberE164>) => {
        if ('userID' in row) return t('User')
        if (row?.team?.object === 'team') return t('Team')
        if (row?.team?.object === 'menu') return t('Menu')
        return ''
    }

    const getUserOrTeam = (row: Partial<NumberE164>) =>
        row.user?.fullName ?? row.team?.name ?? t('numbers.unassigned')

    const columns = [
        columnHelper.accessor('displayNumber', {
            cell: info => info.getValue(),
            header: t('Number')
        }),
        columnHelper.accessor('displayNumberType', {
            cell: info => info.getValue(),
            header: 'Locale'
        }),
        columnHelper.accessor(row => `${getAssigneeType(row)}`, {
            cell: info => info.getValue(),
            header: t('Type')
        }),
        columnHelper.accessor(row => `${getUserOrTeam(row)}`, {
            cell: info => info.getValue(),
            header: t('Assigned to'),
            meta: { align: 'right' }
        })
    ]

    const handleRowClicked = (data: Partial<NumberE164>) => {
        navigate(`/admin/whatsapp/${data.ID}/add`)
    }

    if (isLoading) return <Spinner size="sm" />

    return <Table data={results} columns={columns} paginate onRowClicked={handleRowClicked} />
}
