import { Pagination } from 'features/chat/ChatView'
import { useGetChannelMessagesQuery, useGetMessageThreadQuery } from 'shared/store'

export const useGetMessages = (
    channelID: string,
    threadMessageID?: string,
    pagination?: Pagination
) => {
    const threadQuery = !!threadMessageID
    const {
        data: thread,
        isLoading: threadLoading,
        isError: threadError,
        isFetching: threadFetching
    } = useGetMessageThreadQuery(threadMessageID || '', { skip: !threadMessageID })

    const {
        data: messages,
        isLoading: messagesLoading,
        isError: messagesError,
        isFetching: messagesFetching
    } = useGetChannelMessagesQuery({ channelID, ...pagination }, { skip: threadQuery })

    return {
        data: thread || messages,
        isLoading: threadLoading || messagesLoading,
        isError: threadError || messagesError,
        isFetching: threadFetching || messagesFetching
    }
}
