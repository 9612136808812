import {
    Slider as ChakraSlider,
    SliderFilledTrack,
    SliderProps as ChakraSliderProps,
    SliderThumb,
    SliderTrack,
    useMultiStyleConfig
} from '@chakra-ui/react'
import { FC } from 'react'

export interface SliderProps extends Omit<ChakraSliderProps, 'variant'> {
    'aria-label': string
    size?: 'sm' | 'lg'
}

export const Slider: FC<SliderProps> = ({ size = 'sm', isReadOnly, ...props }) => {
    const styles = useMultiStyleConfig('Slider', { size })
    return (
        <ChakraSlider
            {...props}
            sx={{
                ...styles.container,
                cursor: isReadOnly ? 'default' : 'pointer',
                pointerEvents: isReadOnly ? 'none' : undefined
            }}
        >
            <SliderTrack sx={styles.track}>
                <SliderFilledTrack sx={styles.filledTrack} />
            </SliderTrack>
            {!isReadOnly && <SliderThumb sx={styles.thumb} />}
        </ChakraSlider>
    )
}
