import {
    ActivitiesRequest,
    Activity,
    ALL_ACTIVITIES,
    AnalyticsSingleUserRecentCallResponse,
    AudioClip,
    DetailedState,
    Message,
    NewUser,
    SearchActivitiesRequest,
    SingleState,
    StateType,
    SubscriptionGroup,
    User,
    UserActivity,
    UserActivityCountsResponse,
    UserCallRoute,
    UserMessageResponse
} from '@missionlabs/types'
import { generateActivityTypes, hasFilters } from '@missionlabs/utils'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'
import {
    DeleteFlaggedActivitiesQueryArgs,
    DeleteSubscriptionGroupQueryArgs,
    GetFlaggedActivitiesResponse,
    UpdateFlaggedActivitiesQueryArgs,
    UpdateFlaggedActivitiesResponse
} from './types/usersApi'

function generateActivityParams(
    request: ActivitiesRequest,
    paramNames = { max: 'max', from: 'sinceTime', to: 'fromTime' }
) {
    const {
        from,
        to,
        status = [],
        directories = [],
        max = 100,
        sinceTime,
        fromTime,
        sortDirection
    } = request

    const activityClasses = ['call', 'chat', 'message', 'meeting']

    // Activity types will only be filtered if at least one filter value exists
    const activityTypes = hasFilters(request) ? generateActivityTypes(request) : ALL_ACTIVITIES

    const searchParams = new URLSearchParams({
        activityClass: activityClasses.join(','),
        activityType: activityTypes.join(','),
        [paramNames.max ?? 'max']: String(max),
        hideDeletedActivities: 'true'
    })

    if (status.includes('flagged')) searchParams.set('flaggedActivities', 'true')
    if (status.includes('non_flagged')) searchParams.set('flaggedActivities', 'false')
    if (status.includes('isUnread')) searchParams.set('isUnread', 'true')

    // if there's a company or personal directory picked (but not "all")
    // then set the `directory` param
    // i.e. ?directory=company,personal
    const directoryParam = directories
        ?.filter(directory => ['company', 'personal'].includes(directory))
        ?.join(',')

    if (directoryParam) searchParams.set('directory', directoryParam)

    if (from) searchParams.set(paramNames?.from ?? 'sinceTime', from)
    if (to) searchParams.set(paramNames?.to ?? 'fromTime', to)

    // Normally `sinceTime` won't be set explicitly, it'll be set using the `from` parameter instead
    // however, searchUserActivities needs to use `sinceTime` to be able to request new activities
    // but can't do it using the "from" param, since its index-based rather than time-based
    if (sinceTime) {
        searchParams.set('sinceTime', sinceTime)
    }
    if (fromTime) {
        searchParams.set('fromTime', fromTime)
    }
    if (sortDirection) {
        searchParams.set('sortDirection', sortDirection)
    }

    return searchParams
}

export function getNewUnreadCount(
    current: UserActivityCountsResponse,
    activities: UserActivity[],
    isUnread: boolean
) {
    const missedCalls =
        activities.filter(activity => activity.activityType === Activity.CALL_MISSED).length *
        (isUnread ? 1 : -1)
    const voicemails =
        activities.filter(activity => activity.activityType === Activity.MESSAGE_RECEIVED).length *
        (isUnread ? 1 : -1)
    const smsMessages =
        activities.filter(activity => activity.activityType === Activity.CHAT_MESSAGE_RECEIVED)
            .length * (isUnread ? 1 : -1)
    return {
        missedCalls: Math.max(current.missedCalls + missedCalls, 0),
        voicemails: Math.max(current.voicemails + voicemails, 0),
        smsMessages: Math.max(current.smsMessages + smsMessages, 0),
        flagged: current.flagged
    }
}

export const buildUsersAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'usersAPI',
        tagTypes: [
            'Users',
            'UserAudioClips',
            'UserCallRoute',
            'UserDirectories',
            'UserVoicemails',
            'SingleUserAnalyticsRecentActivity',
            'SingleUserAnalyticsSearchActivity',
            'FlaggedActivities',
            'SubscriptionGroups',
            'SingleUserAnalyticsRecentActivityCount',
            'UserPresence',
            'CallsOnOtherDevices'
        ],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getUser: builder.query<User, User['ID']>({
                query: userID => `/users/${userID}`,
                providesTags: (result, error, userID) => [{ type: 'Users', id: userID }]
            }),
            getUsers: builder.query<User[], { includeDestinations?: boolean } | void>({
                query: options => {
                    const params = new URLSearchParams({ userType: 'all' })

                    if (options && options?.includeDestinations)
                        params.set('includeDestinations', 'true')

                    return `/users?${params.toString()}`
                },
                transformResponse: (response: { data: User[] }) => response.data,
                providesTags: result => {
                    return result
                        ? [...result.map(({ ID }) => ({ type: 'Users' as const, id: ID })), 'Users']
                        : ['Users']
                }
            }),
            getUserActivities: builder.query<UserActivity[], ActivitiesRequest>({
                query: request => {
                    const { userID } = request
                    const searchParams = generateActivityParams(request)
                    return {
                        headers: { 'Cache-Control': 'no-cache' },
                        url: `/users/${userID}/activities?${searchParams.toString()}`
                    }
                },
                transformResponse: (result: AnalyticsSingleUserRecentCallResponse) =>
                    result?.data ?? [],
                providesTags: (result, error, arg) => [
                    { type: 'SingleUserAnalyticsRecentActivity', id: arg.userID }
                ]
            }),
            getUserActivity: builder.query<UserActivity, { userID: string; activityID: string }>({
                query: request => {
                    const { userID, activityID } = request
                    return {
                        headers: { 'Cache-Control': 'no-cache' },
                        url: `/users/${userID}/activities/${activityID}`
                    }
                },
                providesTags: (result, error, arg) => [
                    { type: 'SingleUserAnalyticsRecentActivity', id: arg.userID }
                ]
            }),
            searchUserActivities: builder.query<UserActivity[], SearchActivitiesRequest>({
                query: request => {
                    const { userID, searchTerm, contactID } = request
                    // The search endpoint uses `maxResults` instead of `max`, so use that for the param name
                    const paramNames = { max: 'maxResults', from: 'from', to: 'to' }
                    const searchParams = generateActivityParams(request, paramNames)
                    if (searchTerm) searchParams.set('searchTerm', searchTerm.trim())
                    if (contactID) searchParams.set('contactID', contactID.trim())
                    return {
                        headers: { 'Cache-Control': 'no-cache' },
                        url: `/users/${userID}/search/activities?${searchParams.toString()}`
                    }
                },
                onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                    //Only invalidate when main activity gets "new" activities
                    if (!arg.sinceTime || arg.contactID || arg.type?.length) return

                    const { data: activities } = await queryFulfilled
                    if (!activities.length) return
                    dispatch(
                        api.util.invalidateTags([
                            {
                                type: 'SingleUserAnalyticsRecentActivityCount',
                                id: arg.userID
                            }
                        ])
                    )
                },
                transformResponse: (result: AnalyticsSingleUserRecentCallResponse) =>
                    result?.data ?? [],
                providesTags: (result, error, arg) => [
                    {
                        type: 'SingleUserAnalyticsSearchActivity',
                        id: arg.userID
                    }
                ]
            }),
            getUserActivityCounts: builder.query<UserActivityCountsResponse, string>({
                query: userID => `/users/${userID}/unread-activities-count`,
                providesTags: (_r, _e, userID) => [
                    { type: 'SingleUserAnalyticsRecentActivityCount', id: userID }
                ]
            }),
            getFlaggedActivities: builder.query<UserActivity[], string>({
                query: userID => `/users/${userID}/flaggedActivities`,
                transformResponse: (result: GetFlaggedActivitiesResponse) => {
                    return result?.data ?? []
                },
                providesTags: (result, error, userID) => [{ type: 'FlaggedActivities', id: userID }]
            }),
            updateFlaggedActivities: builder.mutation<
                UpdateFlaggedActivitiesResponse,
                UpdateFlaggedActivitiesQueryArgs
            >({
                query: ({ userID, activities }) => ({
                    url: `/users/${userID}/flaggedActivities`,
                    method: 'POST',
                    body: { activities }
                }),
                invalidatesTags: (result, error, args) => [
                    { type: 'FlaggedActivities', id: args.userID },
                    // { type: 'SingleUserAnalyticsSearchActivity', id: args.userID },
                    { type: 'SingleUserAnalyticsRecentActivityCount', id: args.userID }
                ],
                onQueryStarted: async (
                    { userID, activities },
                    { dispatch, queryFulfilled, getState }
                ) => {
                    updateFlaggedActivities(userID, activities, { dispatch, getState }, true)
                    await queryFulfilled
                    // Wait a second before refetching the activities as API doesn't update immediately
                    setTimeout(() => {
                        dispatch({ type: 'pagination/refetch', payload: 'searchActivities' })
                    }, 1000)
                }
            }),
            deleteFlaggedActivities: builder.mutation<string, DeleteFlaggedActivitiesQueryArgs>({
                query: ({ userID, activities }) => ({
                    url: `/users/${userID}/flaggedActivities`,
                    method: 'DELETE',
                    body: { activities }
                }),
                invalidatesTags: (result, error, args) => [
                    { type: 'FlaggedActivities', id: args.userID },
                    // { type: 'SingleUserAnalyticsSearchActivity', id: args.userID },
                    { type: 'SingleUserAnalyticsRecentActivityCount', id: args.userID }
                ],
                onQueryStarted: async (
                    { userID, activities },
                    { dispatch, queryFulfilled, getState }
                ) => {
                    updateFlaggedActivities(userID, activities, { dispatch, getState }, false)
                    await queryFulfilled
                    // Wait a second before refetching the activities as API doesn't update immediately
                    setTimeout(() => {
                        dispatch({ type: 'pagination/refetch', payload: 'searchActivities' })
                    }, 1000)
                }
            }),
            getUserCallRoute: builder.query<UserCallRoute, string>({
                query: userID => `/users/${userID}/callroute`,
                providesTags: (result, error, userID) => [{ type: 'UserCallRoute', id: userID }]
            }),
            getUserVoicemails: builder.query<UserMessageResponse, string>({
                query: userID => `/users/${userID}/messages`,
                providesTags: result =>
                    result?.data
                        ? [
                              ...result.data.map(entry => ({
                                  type: 'UserVoicemails' as const,
                                  id: entry.ID
                              })),
                              'UserVoicemails'
                          ]
                        : ['UserVoicemails']
            }),
            getUserAudioClips: builder.query<AudioClip[], string>({
                query: userID => `/users/${userID}/audioclips`,
                transformResponse: (response: { data: AudioClip[] }) =>
                    response.data.sort((a, b) => a.label.localeCompare(b.label)),
                providesTags: (result = []) =>
                    result.map(({ ID }) => ({ type: 'UserAudioClips', id: ID }))
            }),
            createUser: builder.mutation<User, Partial<NewUser>>({
                query: user => ({
                    url: `/users`,
                    method: 'POST',
                    body: { ...user }
                }),
                invalidatesTags: ['Users']
            }),
            updateUser: builder.mutation<User, Partial<User>>({
                query: user => ({
                    url: `/users/${user.ID}`,
                    method: 'PUT',
                    body: user
                }),
                onCacheEntryAdded({ ID, notifications }, { dispatch }) {
                    if (!ID) return
                    dispatch(
                        api.util.updateQueryData('getUser', ID, draft => {
                            return { ...draft, notifications: notifications }
                        })
                    )
                },
                invalidatesTags: (result, error, user) => [
                    { type: 'UserCallRoute', id: user.ID },
                    { type: 'Users', id: user.ID }
                ]
            }),
            generateNewPin: builder.mutation<User, Partial<User>>({
                query: user => ({
                    url: `/users/${user.ID}/conference`,
                    method: 'PUT',
                    body: {}
                }),
                invalidatesTags: ['Users']
            }),
            markActivitiesAsRead: builder.mutation<
                UserActivity[],
                { userID: string; activityIDs: string[] }
            >({
                query: ({ userID, activityIDs }) => ({
                    method: 'PUT',
                    url: `/users/${userID}/activities/read`,
                    body: { activityIDs }
                }),
                invalidatesTags: (result, error, args) => [
                    { type: 'SingleUserAnalyticsRecentActivityCount', id: args.userID }
                ],
                // optimistically update cache entry
                onQueryStarted: async ({ userID, activityIDs }, { dispatch, queryFulfilled }) => {
                    dispatch(
                        api.util.updateQueryData('getUserActivities', { userID }, draft => {
                            for (let i = 0; i < draft.length; i++) {
                                if (activityIDs.includes(draft[i].ID)) {
                                    draft[i] = { ...draft[i], isUnread: false }
                                }
                            }
                            return draft
                        })
                    )

                    try {
                        await queryFulfilled
                        dispatch({ type: 'pagination/refetch', payload: 'activities' })
                        dispatch({ type: 'pagination/refetch', payload: 'searchActivities' })
                    } catch {
                        dispatch(api.util.invalidateTags(['SingleUserAnalyticsRecentActivity']))
                    }
                }
            }),
            markAllActivitiesAsRead: builder.mutation<UserActivity[], string>({
                query: userID => ({
                    method: 'PUT',
                    url: `/users/${userID}/activities/read/all`,
                    body: {}
                }),
                onQueryStarted: async (userID, { dispatch, queryFulfilled }) => {
                    dispatch(
                        api.util.updateQueryData('getUserActivityCounts', userID, draft => {
                            draft.missedCalls = 0
                            draft.voicemails = 0
                            draft.smsMessages = 0
                            return draft
                        })
                    )

                    try {
                        await queryFulfilled
                        dispatch({ type: 'pagination/refetch', payload: 'activities' })
                        dispatch({ type: 'pagination/refetch', payload: 'searchActivities' })
                    } catch {
                        dispatch(
                            api.util.invalidateTags([
                                { type: 'SingleUserAnalyticsRecentActivityCount', id: userID }
                            ])
                        )
                    }
                }
            }),
            updateUserActivity: builder.mutation<
                UserActivity,
                { ID: string; userID: string; entry: Partial<UserActivity> }
            >({
                query: ({ ID, userID, entry }) => ({
                    url: `/users/${userID}/activities/${ID}`,
                    method: 'PUT',
                    body: entry
                }),
                // when we update an activity (usually to mark it as read)
                // we should re-fetch the unread activity counts
                // optimistically update cache entry
                onQueryStarted: async ({ ID, userID, entry }, { dispatch, queryFulfilled }) => {
                    dispatch(
                        api.util.updateQueryData('getUserActivities', { userID }, draft => {
                            const index = draft.findIndex(item => item.ID === ID)
                            if (index === -1) return draft
                            draft[index] = { ...draft[index], ...entry }
                        })
                    )

                    dispatch(
                        api.util.updateQueryData('getUserActivityCounts', userID, draft => {
                            if (entry.isUnread === false) {
                                return getNewUnreadCount(draft, [entry] as UserActivity[], false)
                            }
                        })
                    )

                    try {
                        await queryFulfilled
                        dispatch({ type: 'pagination/refetch', payload: 'activities' })
                        dispatch({ type: 'pagination/refetch', payload: 'searchActivities' })
                    } catch {
                        dispatch(api.util.invalidateTags(['SingleUserAnalyticsRecentActivity']))
                    }
                }
            }),
            batchDeleteUserActivities: builder.mutation<
                {
                    message: string
                    /** IDs of deleted activities */
                    data: string[]
                },
                { userID: string; activityIDs: string[] }
            >({
                query: ({ userID, activityIDs }) => ({
                    url: `/users/${userID}/activities/batchDelete`,
                    method: 'POST',
                    body: {
                        activityIDs
                    }
                }),
                // optimistically update cache entry
                onQueryStarted: async ({ userID, activityIDs }, { dispatch, queryFulfilled }) => {
                    dispatch(
                        api.util.updateQueryData('getUserActivities', { userID }, draft => {
                            return draft.filter(activity => !activityIDs.includes(activity.ID))
                        })
                    )

                    try {
                        await queryFulfilled
                        dispatch({ type: 'pagination/refetch', payload: 'activities' })
                        dispatch({ type: 'pagination/refetch', payload: 'searchActivities' })
                    } catch {
                        dispatch(api.util.invalidateTags(['SingleUserAnalyticsRecentActivity']))
                    }
                }
            }),
            createUserAudioClip: builder.mutation<
                { URL: string },
                {
                    userID: string
                    data: ArrayBuffer | string
                    label?: string
                    /**
                     * If marked with `global: true` then the clip can be
                     * accessed by other users on teams/menus/etc
                     */
                    global?: boolean
                }
            >({
                query: ({ userID, data, label, global }) => {
                    const params = new URLSearchParams()
                    if (label) params.set('label', label)
                    if (global) params.set('global', global.toString())

                    let url = `/users/${userID}/audioclips`
                    if (params.toString()) url = `${url}?${params.toString()}`
                    return { url, method: 'POST', body: data }
                },
                invalidatesTags: ['UserAudioClips']
            }),
            createAudioClip: builder.mutation<AudioClip, FormData>({
                query: clip => ({
                    url: '/audioclips',
                    method: 'POST',
                    body: clip
                }),
                invalidatesTags: ['UserAudioClips']
            }),
            updateUserAudioClip: builder.mutation<
                AudioClip,
                { ID: string; userID: string; entry: Partial<AudioClip> }
            >({
                query: ({ ID, userID, entry }) => ({
                    url: `/users/${userID}/audioclips/${ID}`,
                    method: 'PUT',
                    body: entry
                }),
                // optimistically update cache entry
                onQueryStarted: async ({ ID, userID, entry }, { dispatch, queryFulfilled }) => {
                    dispatch(
                        api.util.updateQueryData('getUserAudioClips', userID, draft => {
                            const index = draft.findIndex(item => item.ID === ID)
                            if (index === -1) return draft
                            draft[index] = { ...draft[index], ...entry }
                        })
                    )

                    try {
                        await queryFulfilled
                    } catch {
                        dispatch(api.util.invalidateTags(['UserAudioClips']))
                    }
                }
            }),
            updateUserCallRoute: builder.mutation<UserCallRoute, Partial<UserCallRoute>>({
                query: callRoute => ({
                    url: `/users/${callRoute.userID}/callroute`,
                    method: 'PUT',
                    body: callRoute
                }),
                invalidatesTags: (result, error, callRoute) => [
                    { type: 'UserCallRoute', id: callRoute.userID },
                    { type: 'Users', id: callRoute.userID },
                    'UserAudioClips'
                ]
            }),
            createUserFile: builder.mutation<{ URL: string }, { ID: string; fileData: string }>({
                query: ({ ID, fileData }) => ({
                    url: `/users/${ID}/files`,
                    method: 'POST',
                    body: fileData
                }),
                invalidatesTags: ['UserAudioClips']
            }),
            updateUserPassword: builder.mutation<
                { status: { message: string }; token: string },
                { ID: string; currentPassword: string; newPassword: string }
            >({
                query: ({ ID, currentPassword, newPassword }) => ({
                    url: `/users/${ID}/password`,
                    method: 'PUT',
                    body: { currentPassword, newPassword }
                }),
                invalidatesTags: (_r, _e, { ID }) => [{ type: 'Users', id: ID }]
            }),
            updateUserVoicemail: builder.mutation<Message, Partial<Message>>({
                query: message => ({
                    url: `/users/${message.userID}/messages/${message.ID}`,
                    method: 'PUT',
                    body: message
                }),
                invalidatesTags: (_r, _e, message) => [{ type: 'UserVoicemails', id: message.ID }]
            }),
            getSubscriptionGroups: builder.query<SubscriptionGroup[], User['ID']>({
                query: userID => `/users/${userID}/subscriptionGroups`,
                transformResponse: (response: { data: SubscriptionGroup[] }) =>
                    response?.data ?? [],
                providesTags: (_result, _error, userID) => [
                    { type: 'SubscriptionGroups', id: userID }
                ]
            }),
            createSubscriptionGroup: builder.mutation<
                SubscriptionGroup,
                Partial<SubscriptionGroup>
            >({
                query: subscriptionGroup => ({
                    url: `/users/${subscriptionGroup.userID}/subscriptionGroups`,
                    method: 'POST',
                    body: subscriptionGroup
                }),
                invalidatesTags: (_result, _error, { userID }) => [
                    { type: 'SubscriptionGroups', id: userID }
                ]
            }),
            updateSubscriptionGroup: builder.mutation<
                SubscriptionGroup,
                Partial<SubscriptionGroup>
            >({
                query: subscriptionGroup => ({
                    url: `/users/${subscriptionGroup.userID}/subscriptionGroups/${subscriptionGroup.ID}`,
                    method: 'PUT',
                    body: subscriptionGroup
                }),
                invalidatesTags: (_result, _error, { userID }) => [
                    { type: 'SubscriptionGroups', id: userID }
                ]
            }),
            deleteSubscriptionGroup: builder.mutation<string, DeleteSubscriptionGroupQueryArgs>({
                query: ({ userID, subscriptionGroupID }) => ({
                    url: `/users/${userID}/subscriptionGroups/${subscriptionGroupID}`,
                    method: 'DELETE'
                }),
                invalidatesTags: (_result, _error, { userID }) => [
                    { type: 'SubscriptionGroups', id: userID }
                ]
            }),
            deleteUser: builder.mutation<undefined, string>({
                query: userID => ({
                    url: `/users/${userID}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['Users']
            }),
            deleteUserAudioClip: builder.mutation<string, { ID: string; userID: string }>({
                query: ({ ID, userID }) => ({
                    url: `/users/${userID}/audioclips/${ID}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['UserAudioClips']
            }),
            deleteUserCallRecording: builder.mutation<void, { activityID: string; userID: string }>(
                {
                    query: ({ activityID, userID }) => ({
                        url: `/users/${userID}/activities/${activityID}/userrecording`,
                        method: 'DELETE'
                    }),
                    // when we update an activity (usually to mark it as read)
                    // we should re-fetch the unread activity counts
                    invalidatesTags: (result, error, args) => [
                        { type: 'SingleUserAnalyticsRecentActivityCount', id: args.userID }
                    ],
                    // optimistically update cache entry
                    onQueryStarted: async (
                        { activityID, userID },
                        { dispatch, queryFulfilled }
                    ) => {
                        dispatch(
                            api.util.updateQueryData('getUserActivities', { userID }, draft => {
                                const index = draft.findIndex(item => item.ID === activityID)
                                if (index === -1) return draft
                                draft[index] = {
                                    ...draft[index],
                                    userRecordingURL: '',
                                    userRecordingURLs: {}
                                }
                            })
                        )

                        try {
                            await queryFulfilled
                            dispatch({ type: 'pagination/refetch', payload: 'activities' })
                            dispatch({ type: 'pagination/refetch', payload: 'searchActivities' })
                        } catch {
                            dispatch(api.util.invalidateTags(['SingleUserAnalyticsRecentActivity']))
                        }
                    }
                }
            ),
            getUserPresence: builder.query<DetailedState, User['ID']>({
                query: userID => `/users/${userID}/presencev2`,
                providesTags: (_result, _err, userID) => [{ type: 'UserPresence', id: userID }],
                transformResponse: (response: { data: DetailedState[] }) =>
                    Array.isArray(response.data) ? response.data[0] : response.data
            }),

            getCallsOnOtherDevices: builder.query<
                SingleState[],
                { userID: string; currentDestinationID: string }
            >({
                query: ({ userID }) => `/users/${userID}/presencev2?detailed=true`,
                providesTags: (_result, _err, { userID }) => [
                    { type: 'CallsOnOtherDevices', id: userID }
                ],
                transformResponse: (response: SingleState[], _, { currentDestinationID }) => {
                    return response.filter(
                        sub =>
                            sub.type === StateType.DETAIL &&
                            sub.detailedState === DetailedState.INCALL &&
                            sub.metadata.displayDestinationID !== currentDestinationID
                    )
                }
            })
        })
    })

    const updateFlaggedActivities = (
        userID: string,
        activities: string[],
        { dispatch, getState },
        isFlaggedByUser
    ) => {
        for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
            { type: 'SingleUserAnalyticsSearchActivity', id: userID }
        ])) {
            // we only want to update `searchUserActivities` here
            if (endpointName !== 'searchUserActivities') continue
            dispatch(
                api.util.updateQueryData(endpointName, originalArgs, draft => {
                    draft.forEach(activity => {
                        if (activities.includes(activity.ID)) {
                            activity.isFlaggedByUser = isFlaggedByUser
                        }
                    })
                })
            )
        }
    }

    return { api, ...api }
}
