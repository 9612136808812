import { call, selectPresentationNumber, useDispatch, useSelector } from '@missionlabs/api'
import { Menu, MenuItem, MenuList, useFormatToNumberE164 } from '@missionlabs/react'
import { SMSIcon, WhatsappIcon } from '@missionlabs/react/circleloop'
import { DirectoryEntry, OutboundMessageType, WhatsAppContentType } from '@missionlabs/types'
import { DrawerMode } from 'features/voice/ContactDrawer'
import { FC, ReactNode, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ContactNumberMenuItem } from 'shared/components/ContactNumberMenuItem'
import { useCanSendWhatsApp } from 'shared/hooks/useCanSendWhatsApp'
import { useIsSMSEnabled } from 'shared/hooks/useIsSMSEnabled'
import { addMessage } from 'shared/slices/messagingSlice'

import { useContactDrawerState } from '../../voice/hooks/useContactDrawerState'

interface ContactNumberOverflowProps {
    contact: DirectoryEntry
    trigger: ReactNode
    variant?: 'dark'
}

export const ContactNumberOverflow: FC<ContactNumberOverflowProps> = ({
    contact,
    trigger,
    variant
}) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { setMode } = useContactDrawerState()

    const { phoneNumbers = [] } = contact

    const { isUKMobileNumber, formatToNumberE164 } = useFormatToNumberE164()

    const presentationNumber = useSelector(selectPresentationNumber) ?? ''

    const handleCall = useCallback(
        (numberOrExt: string) => {
            dispatch(call({ to: numberOrExt, from: presentationNumber, contact }))
            setMode(DrawerMode.VOICE)
        },
        [dispatch, presentationNumber, contact, setMode]
    )

    const handleSMS = useCallback(
        (numberOrExt: string) => {
            dispatch(
                addMessage({
                    type: OutboundMessageType.SMS,
                    contact,
                    toNumber: numberOrExt,
                    fromNumber: presentationNumber,
                    text: ''
                })
            )
        },
        [dispatch, presentationNumber, contact]
    )

    const smsOptions = useMemo(() => {
        return phoneNumbers.filter(({ numberE164 }) => isUKMobileNumber(numberE164))
    }, [phoneNumbers, isUKMobileNumber])

    const smsEnabled = useIsSMSEnabled(
        smsOptions.map(({ numberE164 }) => numberE164),
        presentationNumber
    )

    const handleWhatsApp = (toNumber: string) => {
        dispatch(
            addMessage({
                type: OutboundMessageType.WhatsApp,
                contentType: WhatsAppContentType.TEXT,
                contact,
                toNumber,
                numberID: '',
                text: ''
            })
        )
    }

    const whatsappOptions = phoneNumbers

    const whatsappEnabled = useCanSendWhatsApp()

    return (
        <Menu variant={variant}>
            {trigger}
            <MenuList>
                {contact.extension && (
                    <MenuItem variant={variant} onClick={() => handleCall(contact.extension!)}>
                        {t('internalExt', { ext: contact.extension })}
                    </MenuItem>
                )}
                {contact.phoneNumbers?.map(number => (
                    <ContactNumberMenuItem
                        key={number.numberE164}
                        number={number}
                        onClick={numberOrExt => handleCall(formatToNumberE164(numberOrExt))}
                        variant={variant}
                    />
                ))}
                {smsEnabled && (
                    <>
                        {smsOptions.map(option => {
                            return (
                                <MenuItem
                                    name="SMS"
                                    key={`sms-${option.numberE164}`}
                                    icon={<SMSIcon />}
                                    variant={variant}
                                    onClick={() => handleSMS(option.numberE164)}
                                >
                                    {option.numberE164}
                                </MenuItem>
                            )
                        })}
                    </>
                )}
                {whatsappEnabled && (
                    <>
                        {whatsappOptions.map(option => {
                            return (
                                <MenuItem
                                    name="WhatsApp"
                                    key={`whatsapp-${option.numberE164}`}
                                    icon={<WhatsappIcon />}
                                    variant={variant}
                                    onClick={() => handleWhatsApp(option.numberE164)}
                                >
                                    {option.numberE164}
                                </MenuItem>
                            )
                        })}
                    </>
                )}
            </MenuList>
        </Menu>
    )
}
