import { ComponentStyleConfig } from '@chakra-ui/react'

const backgroundColour = 'light.tones.midnightExpress'

export const TooltipStyles: ComponentStyleConfig = {
    baseStyle: {
        '--popper-arrow-bg': `colors.${backgroundColour}`,
        bg: backgroundColour,
        px: 4,
        py: 2,
        borderRadius: '8px',
        color: 'light.tones.white',
        fontFamily: 'heading',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '24px'
    }
}
