import { SmartphoneInIconLight } from 'atoms/Icons/zeta'
import { Themes } from 'theme'

export type Key = {
    label: string
    subLabel?: string
    icon?: JSX.Element
    bg?: string
}

type KeyConfig = {
    [theme in Themes]: Readonly<Array<Key>>
}

export const keys: KeyConfig = {
    [Themes.ZETA]: [
        {
            label: '1'
        },
        {
            label: '2',
            subLabel: 'ABC'
        },
        {
            label: '3',
            subLabel: 'DEF'
        },
        {
            label: '4',
            subLabel: 'GHI'
        },
        {
            label: '5',
            subLabel: 'JKL'
        },
        {
            label: '6',
            subLabel: 'MNO'
        },
        {
            label: '7',
            subLabel: 'PQRS'
        },
        {
            label: '8',
            subLabel: 'TUV'
        },
        {
            label: '9',
            subLabel: 'WXYZ'
        },
        {
            label: '*',
            subLabel: ''
        },
        {
            label: '0',
            subLabel: ''
        },
        {
            label: '#',
            subLabel: ''
        },
        {
            label: 'retrieveCall',
            icon: <SmartphoneInIconLight aria-label="Retrieve call" boxSize="28px" mr="3px" />,
            bg: 'light.tones.stormGrey'
        },
        {
            label: '+'
        }
    ] as const,
    [Themes.CIRCLELOOP]: [
        {
            label: '1'
        },
        {
            label: '2'
        },
        {
            label: '3'
        },
        {
            label: '#'
        },
        {
            label: '4'
        },
        {
            label: '5'
        },
        {
            label: '6'
        },
        {
            label: '*'
        },
        {
            label: '7'
        },
        {
            label: '8'
        },
        {
            label: '9'
        },
        {
            label: '+'
        },
        {
            label: 'retrieveCall',
            icon: <SmartphoneInIconLight aria-label="Retrieve call" boxSize="28px" mr="3px" />,
            bg: 'light.tones.stormGrey'
        },
        {
            label: '0'
        },
        {
            label: 'hidden'
        }
    ] as const,
    [Themes.PHONELINEPLUS]: [
        {
            label: '1'
        },
        {
            label: '2'
        },
        {
            label: '3'
        },
        {
            label: '#'
        },
        {
            label: '4'
        },
        {
            label: '5'
        },
        {
            label: '6'
        },
        {
            label: '*'
        },
        {
            label: '7'
        },
        {
            label: '8'
        },
        {
            label: '9'
        },
        {
            label: '+'
        },
        {
            label: 'retrieveCall',
            icon: <SmartphoneInIconLight aria-label="Retrieve call" boxSize="28px" mr="3px" />,
            bg: 'light.tones.stormGrey'
        },
        {
            label: '0'
        },
        {
            label: 'hidden'
        }
    ] as const
}
