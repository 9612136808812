import { HStack, useColorMode } from '@chakra-ui/react'
import { Body, Button } from '@missionlabs/react'
import backgroundImage from 'assets/images/features-numbers-instantly.png'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AuthLayout } from 'shared/components/AuthLayout'

export interface WelcomeSuccessProps {}

export const WelcomeSuccess: FC<WelcomeSuccessProps> = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    return (
        <AuthLayout label={t('welcome.success.title')} contentWidth="512px" image={backgroundImage}>
            <Body size="sm" alignSelf="start" sx={{ color: `${colorMode}.tones.navy` }}>
                {t('welcome.success.text')}
            </Body>
            <HStack pt="8px" gap={8}>
                <Button as={Link} to="/download" size="lg">
                    {t('welcome.success.downloadDesktop')}
                </Button>
                <Button as={Link} to="/login" size="lg" variant="outline" colorScheme="teal">
                    {t('welcome.success.webappSignIn')}
                </Button>
            </HStack>
        </AuthLayout>
    )
}
