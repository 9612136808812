import { sliderAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(sliderAnatomy.keys)

export const SliderStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => {
        return {
            track: {
                bg: `${colorMode}.tones.periwinkle`,
                height: '12px',
                borderRadius: '4px'
            },
            filledTrack: {
                bg: `${colorMode}.primary.gamma`
            },
            thumb: {
                height: '12px',
                width: '12px',
                borderRadius: '0'
            }
        }
    },
    sizes: {
        sm: {
            thumb: {
                height: '12px',
                width: '12px'
            }
        },
        md: {
            thumb: {
                height: '24px',
                width: '24px'
            }
        },
        lg: {
            thumb: {
                marginTop: '7px',
                height: '24px',
                width: '24px',
                borderRadius: '4px'
            },
            track: {
                marginTop: '7px',
                height: '12px',
                borderRadius: '4px'
            },
            filledTrack: {
                height: '24px'
            }
        }
    }
})
