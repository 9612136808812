import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerTitle,
    useToast
} from '@missionlabs/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCreateChannelMutation } from 'shared/store'

import { ChatContactsList } from './ChatContactsList'

interface Props {}

export const NewChatDrawer: React.FC<Props> = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [findOrCreateChannel] = useCreateChannelMutation()
    const user = useSelector(selectAuthenticatedUser)
    const { toast } = useToast()

    const handleNewChat = async (participantID: string) => {
        if (!user) return
        try {
            const channel = await findOrCreateChannel({
                type: 'USER' as const,
                members: [participantID, user.userID]
            }).unwrap()
            navigate(`/chat/${channel.ID}`)
        } catch (error) {
            toast({ status: 'error', title: t('chat.newChatError') })
        }
    }

    return (
        <Drawer isOpen={true} onClose={() => navigate(-1)}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>
                    <DrawerTitle>{t('chat.newChat')}</DrawerTitle>
                </DrawerHeader>

                <DrawerBody p={6}>
                    <ChatContactsList onClickContact={handleNewChat} />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
