import { $getListDepth, $isListItemNode, $isListNode } from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import {
    $getSelection,
    $isElementNode,
    $isRangeSelection,
    COMMAND_PRIORITY_HIGH,
    INDENT_CONTENT_COMMAND,
    RangeSelection
} from 'lexical'
import { FC, useEffect } from 'react'

function getElementNodesInSelection(selection: RangeSelection) {
    const nodesInSelection = selection.getNodes()

    if (nodesInSelection.length === 0) {
        return new Set([
            selection.anchor.getNode().getParentOrThrow(),
            selection.focus.getNode().getParentOrThrow()
        ])
    }

    return new Set(nodesInSelection.map(n => ($isElementNode(n) ? n : n.getParentOrThrow())))
}

function isIndentPermitted(maxDepth: number) {
    const selection = $getSelection()
    if (!$isRangeSelection(selection)) return false

    const elementNodesInSelection = getElementNodesInSelection(selection)

    let totalDepth = 0

    for (const elementNode of elementNodesInSelection) {
        if ($isListNode(elementNode)) {
            totalDepth = Math.max($getListDepth(elementNode) + 1, totalDepth)
        } else if ($isListItemNode(elementNode)) {
            const parent = elementNode.getParent()
            if (!$isListNode(parent)) {
                throw new Error(
                    'ListMaxIndentLevelPlugin: A ListItemNode must have a ListNode for a parent.'
                )
            }

            totalDepth = Math.max($getListDepth(parent) + 1, totalDepth)
        }
    }

    return totalDepth <= maxDepth
}

export interface ListMaxIndentLevelPluginProps {
    maxDepth?: number
}

export const ListMaxIndentLevelPlugin: FC<ListMaxIndentLevelPluginProps> = ({ maxDepth = 3 }) => {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        return editor.registerCommand(
            INDENT_CONTENT_COMMAND,
            () => !isIndentPermitted(maxDepth),
            COMMAND_PRIORITY_HIGH
        )
    }, [editor, maxDepth])

    return (
        <>
            <ListPlugin />
            <TabIndentationPlugin />
        </>
    )
}
