import { Center, Grid } from '@chakra-ui/react'
import logger from '@missionlabs/logger'
import { EmptyPage } from '@missionlabs/react'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Navbar } from './Navbar'

export const ForbiddenPage: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { state } = useLocation()

    useEffect(() => {
        logger.warn(`FORBIDDEN: User trying to access: ${JSON.stringify(state)}`)
    }, [state])

    return (
        <Grid h="100dvh" w="100dvw" templateColumns="64px 1fr" as="main">
            <Navbar />
            <Center h="80vh">
                <EmptyPage
                    title={t('403.heading')}
                    subtitle={t('403.subheading')}
                    link={{ label: t('403.homeLink'), onClick: () => navigate('/') }}
                    showLogo={false}
                />
            </Center>
        </Grid>
    )
}
