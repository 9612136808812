import {
    AttendeeDetails,
    AttendeeStatus,
    NewScheduledMeeting,
    ScheduledMeeting,
    UpdateScheduledMeeting
} from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildScheduledMeetingsApi = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'scheduledMeetingsAPI',
        tagTypes: ['ScheduledMeeting'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getScheduledMeetings: builder.query<
                ScheduledMeeting[],
                { userID: string; timeRangeStart?: string; timeRangeEnd?: string }
            >({
                query: ({ userID, timeRangeStart, timeRangeEnd }) => {
                    const params = new URLSearchParams()
                    if (timeRangeStart) params.set('timeRangeStart', timeRangeStart)
                    if (timeRangeEnd) params.set('timeRangeEnd', timeRangeEnd)
                    return `/users/${userID}/scheduledMeetings?${params.toString()}`
                },
                providesTags: result =>
                    result
                        ? [
                              ...result.map(({ ID }) => ({
                                  type: 'ScheduledMeeting' as const,
                                  id: ID
                              })),
                              'ScheduledMeeting'
                          ]
                        : ['ScheduledMeeting']
            }),
            getScheduledMeeting: builder.query<
                ScheduledMeeting,
                { meetingID: string; userID: string }
            >({
                query: ({ userID, meetingID }) => `/users/${userID}/scheduledMeetings/${meetingID}`,
                providesTags: (result, error, id) => [
                    { type: 'ScheduledMeeting', id: id.meetingID }
                ]
            }),
            createScheduledMeeting: builder.mutation<
                { meetingID: string },
                NewScheduledMeeting & { userID: string }
            >({
                query: ({ userID, ...body }) => ({
                    url: `/users/${userID}/scheduledMeetings`,
                    method: 'POST',
                    body
                }),
                invalidatesTags: (result, _error, _args) => [
                    { type: 'ScheduledMeeting', id: result?.meetingID },
                    'ScheduledMeeting'
                ]
            }),
            updateScheduledMeeting: builder.mutation<void, UpdateScheduledMeeting>({
                query: ({ userID, meetingID, ...body }) => ({
                    url: `/users/${userID}/scheduledMeetings/${meetingID}`,
                    method: 'PUT',
                    body
                }),
                invalidatesTags: (_result, _error, args) => [
                    { type: 'ScheduledMeeting', id: args?.meetingID }
                ]
            }),
            updateScheduledMeetingParticipants: builder.mutation<
                ScheduledMeeting,
                {
                    invitedUsers: Pick<AttendeeDetails, 'userID'>[]
                    meetingID: string
                    userID: string
                }
            >({
                query: ({ meetingID, userID, invitedUsers }) => {
                    return {
                        url: `/users/${userID}/scheduledMeetings/${meetingID}/addParticipant`,
                        method: 'PATCH',
                        body: { invitedUsers }
                    }
                },
                invalidatesTags: (result, error, { meetingID }) => [
                    { type: 'ScheduledMeeting', id: meetingID }
                ]
            }),
            updateScheduledMeetingAttendance: builder.mutation<
                undefined,
                {
                    userID: string
                    meetingID: string
                    attendanceStatus: Exclude<AttendeeStatus, AttendeeStatus.NO_RESPONSE>
                }
            >({
                query: ({ userID, meetingID, ...body }) => ({
                    url: `/users/${userID}/scheduledMeetings/${meetingID}`,
                    method: 'PATCH',
                    body
                }),
                invalidatesTags: (_result, _error, args) => [
                    { type: 'ScheduledMeeting', id: args?.meetingID }
                ]
            }),
            cancelScheduledMeeting: builder.mutation<
                undefined,
                { userID: string; meetingID: string }
            >({
                query: ({ userID, meetingID }) => ({
                    url: `/users/${userID}/scheduledMeetings/${meetingID}`,
                    method: 'DELETE'
                }),
                invalidatesTags: (_result, _error, args) => [
                    { type: 'ScheduledMeeting', id: args?.meetingID }
                ]
            })
        })
    })

    return { api, ...api }
}
