import { Center, Heading, HStack, IconButton, useColorMode, VStack } from '@chakra-ui/react'
import { useDispatch, useSelector } from '@missionlabs/api'
import { ActivityIcon, useToast } from '@missionlabs/react'
import { CloseIcon } from '@missionlabs/react/circleloop'
import { Activity as ActivityType, CallNote } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
    addCallNote,
    CallNoteSliceRootState,
    selectCallNoteContent,
    updateCallNote
} from 'shared/slices/callNoteSlice'
import { useCreateCallNoteMutation, useUpdateCallNoteMutation } from 'shared/store'

import { ActivityFeedNotesInput } from './components/ActivityNotesInput/ActivityFeedNotesInput'
import { useActivityFooterState } from './hooks/useActivityFooterState'

export const ActivityFeedFooterNotesContent: FC = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { onClose, activity } = useActivityFooterState()
    const dispatch = useDispatch()
    const { toast } = useToast()

    const [createCallNote] = useCreateCallNoteMutation()
    const [editCallNote] = useUpdateCallNoteMutation()

    const content = useSelector((state: CallNoteSliceRootState) =>
        selectCallNoteContent(state, activity?.callTraceID)
    )

    const handleSaveCallNotes = async (
        callTraceID: string,
        newCallNoteContent: string,
        callNote?: CallNote
    ) => {
        if (!callTraceID) throw new Error('No UserActivity Selected')
        if (callNote?.content === newCallNoteContent) return

        try {
            let callNoteID: string
            if (!callNote?.ID || content === '') {
                const { ID } = await createCallNote({
                    content: newCallNoteContent,
                    callTraceID
                }).unwrap()
                callNoteID = ID

                dispatch(
                    addCallNote({
                        callTraceID,
                        note: newCallNoteContent
                    })
                )
            } else {
                await editCallNote({
                    callNoteID: callNote.ID,
                    content: newCallNoteContent
                }).unwrap()
                callNoteID = callNote.ID
            }

            dispatch(
                updateCallNote({
                    ID: callNoteID,
                    callTraceID,
                    isSaved: true,
                    savedNote: newCallNoteContent
                })
            )

            toast({ title: t('Call Note Saved'), status: 'success' })
        } catch (err) {
            toast({ title: t('Call Note Saved Error'), status: 'error' })
        }
    }

    const callNote = activity?.callNotes?.[0]

    return (
        <VStack
            w="full"
            pt="18px"
            pb="24px"
            px="24px"
            borderTop="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
        >
            <HStack w="full" justify="space-between">
                <HStack>
                    <Center
                        boxSize="32px"
                        bg={`${colorMode}.tones.ghostWhite`}
                        border="1px solid"
                        borderColor={`${colorMode}.tones.periwinkle`}
                        borderRadius="full"
                    >
                        <ActivityIcon activityType={ActivityType.MESSAGE_RECEIVED} />
                    </Center>
                    <Heading size="16px">{t('Call Notes')}</Heading>
                </HStack>
                <IconButton
                    aria-label="Close call notes"
                    icon={<CloseIcon boxSize="16px" />}
                    variant="transparent"
                    size="md"
                    onClick={onClose}
                />
            </HStack>
            <ActivityFeedNotesInput
                callTraceID={activity?.callTraceID ?? ''}
                callNote={callNote ?? { content: content ?? '' }}
                onSaveCallNotes={handleSaveCallNotes}
            />
        </VStack>
    )
}
