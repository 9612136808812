import { useColorMode } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { FC } from 'react'
import { useCopyToClipboard } from 'react-use'

export interface CopySipEndpointDetailsProps {
    label: string
    value?: string
}

export const CopySipEndpointDetails: FC<CopySipEndpointDetailsProps> = ({ label, value }) => {
    const { colorMode } = useColorMode()
    const [, copyToClipboard] = useCopyToClipboard()

    const handleCopyToClipboard = () => {
        if (value) {
            copyToClipboard(value)
        }
    }

    return (
        <>
            <Body size="sm" variant="bold">
                {label}
            </Body>
            <Body
                onClick={handleCopyToClipboard}
                size="sm"
                variant="link-bold"
                color={`${colorMode}.alerts.red`}
                cursor={'pointer'}
            >
                {value}
            </Body>
        </>
    )
}
