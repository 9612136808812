import { Box, Heading } from '@chakra-ui/react'
import { Call, nWayInitiate, useDispatch } from '@missionlabs/api'
import { Input, Scrollable, useFormatToNumberE164 } from '@missionlabs/react'
import { SearchIcon } from '@missionlabs/react/circleloop'
import { DirectoryEntry } from '@missionlabs/types'
import { isTeamOrMenu } from '@missionlabs/utils'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { useContactsSearch } from 'shared/hooks/useContactsSearch'
import { useGetUserData } from 'shared/hooks/useGetUserData'

import { VirtualAddPersonContact } from './AddPersonContact'
import { AddPersonListRenderer } from './AddPersonListRenderer'

export type AddPersonCallTarget = {
    type: 'numberE164' | 'teamID' | 'userID'
    value: string
    contact?: DirectoryEntry
}

interface AddPersonListProps {
    call: Call
    isOpen: boolean
    onClose: (target?: AddPersonCallTarget) => void
}

export const AddPersonList: FC<AddPersonListProps> = ({ call, onClose, isOpen }) => {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const { isValidPhoneNumber, formatToNumberE164 } = useFormatToNumberE164()

    const { user } = useGetUserData()

    const [searchContacts, { data: contacts }] = useContactsSearch()

    const [contactSearch, setContactSearch] = useState('')
    const [externalE164Number, setExternalE164Number] = useState('')

    const handleSearchChange = useCallback(
        (searchTerm: string) => {
            setContactSearch(searchTerm)
            searchContacts({ searchTerm })
        },
        [searchContacts]
    )

    useEffect(() => {
        if (isOpen) {
            handleSearchChange(contactSearch)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    useDebounce(
        function setExternalNumber() {
            if (isValidPhoneNumber(contactSearch)) {
                setExternalE164Number(formatToNumberE164(contactSearch))
            } else {
                setExternalE164Number('')
            }
        },
        500,
        [contactSearch, setExternalE164Number, isValidPhoneNumber, formatToNumberE164]
    )

    const onAddPerson = useCallback(
        (target: AddPersonCallTarget) => {
            const payload = nWayInitiate({
                callTraceID: call.callTraceID,
                [target.type]: target.value
            })

            dispatch(payload)
            onClose(target)
        },
        [call, onClose, dispatch]
    )

    const virtualContacts: VirtualAddPersonContact[] = useMemo(() => {
        const isExternalNumber = !contacts.length && externalE164Number

        if (isExternalNumber) {
            // If there are no contacts found & they've searched a valid number
            // then show that number as a contact in the list for external numbers
            const contact = {
                fullName: externalE164Number,
                phoneNumbers: [{ label: 'other', numberE164: externalE164Number }]
            } as DirectoryEntry

            return [{ contact, onClick: onAddPerson }]
        }

        return contacts
            .filter(contact => !isTeamOrMenu(contact) && contact.externalID !== user?.ID)
            .map(contact => ({ contact, onClick: onAddPerson }))
    }, [contacts, onAddPerson, user?.ID, externalE164Number])

    return (
        <>
            <Heading
                size="h4"
                pb="5"
                variant="bold"
                fontSize="16px"
                color="white"
                textTransform="capitalize"
            >
                {t('call.addPerson.heading')}
            </Heading>

            <Input
                mb="12px"
                onChange={e => handleSearchChange(e.target.value)}
                placeholder={t('call.addPerson.searchContact')}
                rightIcon={<SearchIcon />}
                value={contactSearch}
                variant="dark"
            />
            <Box h="calc(100dvh - 500px)" w="full">
                <AutoSizer>
                    {({ height, width }) => (
                        <FixedSizeList
                            height={height}
                            width={width}
                            itemCount={virtualContacts.length}
                            itemData={virtualContacts}
                            itemSize={70}
                            outerElementType={Scrollable}
                        >
                            {AddPersonListRenderer}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </Box>
        </>
    )
}
