import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const HeartIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HeartIconLight',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M9.96094 2.53741L10.4297 2.10773C11.7188 0.818663 13.5547 0.232726 15.3125 0.545226C18.0078 0.974913 20 3.31866 20 6.05304V6.24835C20 7.88898 19.2969 9.45148 18.125 10.5452L11.0547 17.1468C10.7812 17.4202 10.3906 17.5374 10 17.5374C9.57031 17.5374 9.17969 17.4202 8.90625 17.1468L1.83594 10.5452C0.664062 9.45148 0 7.88898 0 6.24835V6.05304C0 3.31866 1.95312 0.974913 4.64844 0.545226C6.40625 0.232726 8.24219 0.818663 9.53125 2.10773L9.96094 2.53741ZM9.96094 4.33429L8.63281 2.9671C7.65625 1.99054 6.25 1.52179 4.84375 1.75616C2.77344 2.10773 1.21094 3.9046 1.21094 6.05304V6.24835C1.21094 7.53741 1.75781 8.74835 2.69531 9.60773L9.76562 16.2093C9.80469 16.2874 9.88281 16.2874 9.96094 16.2874C10.0781 16.2874 10.1562 16.2874 10.1953 16.2093L17.2656 9.60773C18.2031 8.74835 18.75 7.53741 18.75 6.24835V6.05304C18.75 3.9046 17.1875 2.10773 15.1172 1.75616C13.7109 1.52179 12.3047 1.99054 11.3281 2.9671L9.96094 4.33429Z"
            fill="currentColor"
        />
    )
})

export const HeartIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HeartIconRegular',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M9.53125 2.06864L9.96094 2.53739L10.4297 2.1077C11.7188 0.818638 13.5547 0.232701 15.3125 0.545201C18.0078 0.974888 20 3.31864 20 6.05301V6.24833C20 7.88895 19.2969 9.45145 18.125 10.5452L11.0547 17.1468C10.7812 17.4202 10.3906 17.5374 10 17.5374C9.57031 17.5374 9.17969 17.4202 8.90625 17.1468L1.83594 10.5452C0.664062 9.45145 0 7.88895 0 6.24833V6.05301C0 3.31864 1.95312 0.974888 4.64844 0.545201C6.40625 0.232701 8.24219 0.818638 9.53125 2.06864C9.53125 2.1077 9.49219 2.06864 9.53125 2.06864ZM9.96094 5.19364L8.20312 3.39676C7.34375 2.57645 6.13281 2.18583 4.96094 2.38114C3.16406 2.69364 1.875 4.21708 1.875 6.05301V6.24833C1.875 7.38114 2.30469 8.43583 3.125 9.17801L10 15.5843L16.8359 9.17801C17.6562 8.43583 18.125 7.38114 18.125 6.24833V6.05301C18.125 4.21708 16.7969 2.69364 15 2.38114C13.8281 2.18583 12.6172 2.57645 11.7578 3.39676L9.96094 5.19364Z"
            fill="currentColor"
        />
    )
})

export const HeartIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HeartIconSolid',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M0 6.24811V6.05279C0 3.31842 1.95312 0.974669 4.64844 0.544982C6.40625 0.232482 8.24219 0.818419 9.53125 2.10748L10 2.53717L10.4297 2.10748C11.7188 0.818419 13.5547 0.232482 15.3125 0.544982C18.0078 0.974669 20 3.31842 20 6.05279V6.24811C20 7.88873 19.2969 9.45123 18.125 10.545L11.0547 17.1465C10.7812 17.42 10.3906 17.5372 10 17.5372C9.57031 17.5372 9.17969 17.42 8.90625 17.1465L1.83594 10.545C0.664062 9.45123 0 7.88873 0 6.24811Z"
            fill="currentColor"
        />
    )
})
