import { ComponentStyleConfig, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
    'container',
    'input',
    'label',
    'error',
    'icon'
])

const baseStyle = definePartsStyle(({ colorMode }) => {
    const textStyles = {
        fontFamily: 'body',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '21px'
    }
    return {
        container: defineStyle({
            maxH: '70px'
        }),
        input: defineStyle({
            bg: `${colorMode}.tones.white`,
            color: `${colorMode}.tones.navy`,
            border: '1px solid',
            borderColor: `${colorMode}.tones.periwinkle`,
            borderRadius: '4px',
            padding: '10px',
            ...textStyles,
            _placeholder: {
                ...textStyles,
                color: `${colorMode}.tones.stormGrey`
            },
            _hover: {
                borderColor: `${colorMode}.tones.stormGrey`
            },
            _invalid: {
                color: `${colorMode}.alerts.rose`,
                border: '1px solid',
                borderColor: `${colorMode}.alerts.rose`,
                boxShadow: 'none'
            },
            _focusVisible: {
                borderColor: `${colorMode}.tones.stormGrey`,
                border: '1px solid'
            },
            _disabled: {
                bg: `${colorMode}.tones.whiteLilac`,
                pointerEvents: 'none',
                color: `${colorMode}.tones.stormGrey`
            }
        }),
        label: defineStyle({
            ...textStyles,
            color: `${colorMode}.tones.navy`,
            fontWeight: 700
        }),
        error: defineStyle({
            ...textStyles,
            color: `${colorMode}.alerts.rose`,
            fontWeight: 700
        }),
        icon: defineStyle({
            color: `${colorMode}.tones.midnightExpress`,
            _disabled: {
                color: `${colorMode}.tones.stormGrey`
            }
        })
    }
})

const variants = {
    dark: definePartsStyle(({ colorMode }) => ({
        input: defineStyle({
            bg: `${colorMode}.tones.vantaBlue`,
            color: `${colorMode}.tones.white`,
            borderColor: `${colorMode}.tones.navy`,
            _placeholder: {
                color: `${colorMode}.tones.white`
            },
            _disabled: {
                bg: `${colorMode}.tones.midnightExpress`
            }
        }),
        icon: defineStyle({
            color: `${colorMode}.tones.white`
        })
    }))
}

export const InputStyles: ComponentStyleConfig = defineMultiStyleConfig({ baseStyle, variants })
