export function titleCase(str: string): string {
    const first = str.charAt(0).toLocaleUpperCase()
    const rest = str.slice(1)
    return `${first}${rest}`
}

type ThingWithNames = {
    firstName?: string
    lastName?: string
    fullName?: string
}

/**
 * Gets first and last names, or "guesses" them from fullName if it exists
 */
export function inferFirstLastName<T extends ThingWithNames>(subject?: T) {
    let firstName = subject?.firstName?.trim() ?? ''
    let lastName = subject?.lastName?.trim() ?? ''
    let fullName = subject?.fullName?.trim() ?? ''

    if (subject?.fullName && (!firstName || !lastName)) {
        const [guessedFirst, ...otherNames] = subject.fullName.split(' ')
        if (!firstName) firstName = guessedFirst
        if (!lastName) lastName = otherNames.join(' ')
    }

    if (!fullName && (firstName || lastName)) {
        fullName = [firstName, lastName].filter(Boolean).join(' ')
    }

    return { firstName, lastName, fullName }
}
