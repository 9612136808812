import { VideoTile } from 'amazon-chime-sdk-component-library-react'
import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base'
import { forwardRef, PropsWithChildren } from 'react'
import styled from 'styled-components'

const StyledPreview = styled(VideoTile)`
    height: auto;
    background: unset;

    video {
        position: static;
    }
`

const PreviewVideo = forwardRef<HTMLVideoElement, PropsWithChildren<BaseSdkProps>>(
    (props, videoEl) => {
        return <StyledPreview {...props} ref={videoEl} />
    }
)

export default PreviewVideo
