import { Divider, VStack } from '@chakra-ui/react'
import { NotAvailable, UserCallRoute } from '@missionlabs/types'
import { UnansweredCallRadio } from 'features/settings/pages/Calls/UnansweredCallSetting/UnansweredCallRadio'
import { useFlags } from 'flagsmith/react'
import { FC } from 'react'
import { OutOfHours } from 'shared/components/OutOfHours/OutOfHours'
import { AdminAnonymousCallSetting } from 'shared/components/settings/AdminAnonymousCallSetting'

import { AssignedNumbers } from '../../components/AssignedNumbers'
import { CallBarring } from './CallBarring'
import { UserDetailsForm } from './UserDetailsForm'

type EditUserDetailsProps = {
    callRoute: UserCallRoute
    phoneNumbers: UserCallRoute['phoneNumbers']
    onUpdate: (payload: Partial<UserCallRoute>) => void
    onUpdateNotAvailable: (setting?: NotAvailable) => void
    onUpdateBlockAnonymousCalls: (name: string, value: boolean) => void
}

export const EditUserDetails: FC<EditUserDetailsProps> = ({
    callRoute,
    phoneNumbers,
    onUpdate,
    onUpdateNotAvailable,
    onUpdateBlockAnonymousCalls
}) => {
    const { call_barring } = useFlags(['call_barring'])

    return (
        <VStack spacing="16px" p="32px" divider={<Divider />}>
            <UserDetailsForm w="full" p="0px" />
            <AssignedNumbers numbers={phoneNumbers} />
            {call_barring.enabled && <CallBarring />}
            <UnansweredCallRadio callRoute={callRoute} onUpdate={onUpdate} />
            <OutOfHours
                callRoute={callRoute}
                notAvailable={callRoute.notAvailable}
                onUpdate={onUpdate}
                onUpdateNotAvailable={onUpdateNotAvailable}
            />
            <AdminAnonymousCallSetting
                onChange={onUpdateBlockAnonymousCalls}
                anonymousCallSetting={callRoute.blockAnonymousCalls ?? false}
            />
        </VStack>
    )
}
