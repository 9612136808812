import { MenuDivider } from '@chakra-ui/react'
import { AuthenticatedUser, selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { IconButton, Menu, MenuButton, MenuItem, MenuList, useToast } from '@missionlabs/react'
import {
    DeleteIconOutline,
    EditOutlineIcon,
    NotificationIconOutline,
    NotificationOffIcon,
    SharedContactIcon,
    ShareIcon
} from '@missionlabs/react/circleloop'
import { DotsVerticalIconRegular, StarIconLight } from '@missionlabs/react/zeta'
import { ContactType, DirectoryEntry, UserRole } from '@missionlabs/types'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useUserRole } from 'shared/hooks/useUserRole'
import { useUpdateUserContactMutation } from 'shared/store'
import { AppConfig } from 'shared/utils/env'
import { vCardGenerator } from 'shared/utils/vCardGenerator'

import { VALID_SOURCES } from '../const'
import { ContactContext } from '../context/contactsContext'

interface ContactsHeaderMenuProps {
    contact: DirectoryEntry
    type: ContactType
    isFavourited?: boolean
    onDelete: () => void
    onToggleFavourite: () => void
}

function isCreatedByUser(user?: AuthenticatedUser, contact?: DirectoryEntry) {
    if (!user) return false
    return contact?.userID === user?.userID
}

function isCanDeleteRole(role: UserRole) {
    return [UserRole.ADMIN, UserRole.END_USER].includes(role)
}

function isValidSource(contact: DirectoryEntry) {
    return VALID_SOURCES.includes(contact?.source ?? '') || contact.source === AppConfig.name
}

export const ContactsHeaderMenu = ({
    contact,
    isFavourited,
    onToggleFavourite,
    onDelete
}: ContactsHeaderMenuProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const role = useUserRole()
    const user = useSelector(selectAuthenticatedUser)
    const [_updateContact] = useUpdateUserContactMutation()
    const { toast } = useToast()
    const { replaceContact } = useContext(ContactContext)

    const updateContact = async (args: Parameters<typeof _updateContact>[0]) => {
        try {
            const newContact = await _updateContact(args).unwrap()
            replaceContact(newContact)
            toast({ status: 'success', title: t('contacts.succeedUpdate') })
        } catch (error) {
            toast({ status: 'error', title: t('contacts.failedUpdate') })
        }
    }

    const canEdit = useMemo(() => {
        // Non-admin users can only edit contacts they created
        if (role === UserRole.END_USER && !isCreatedByUser(user, contact)) {
            return false
        }
        return isValidSource(contact)
    }, [contact, role, user])

    const canDelete = useMemo(() => {
        if (isCanDeleteRole(role)) {
            return isValidSource(contact) && isCreatedByUser(user, contact)
        }
        return isValidSource(contact)
    }, [contact, role, user])

    // TODO
    const notificationsMuted = false

    return (
        <Menu>
            <MenuButton
                as={IconButton}
                variant="ghost"
                aria-label="More"
                data-testid="activity-header-menu-trigger"
            >
                <DotsVerticalIconRegular />
            </MenuButton>
            <MenuList data-testid="activity-header-menu">
                {canEdit && (
                    <MenuItem icon={<EditOutlineIcon />} onClick={() => navigate(`edit`)}>
                        {t('contacts.editContact')}
                    </MenuItem>
                )}
                <MenuItem icon={<StarIconLight />} onClick={onToggleFavourite}>
                    {t(isFavourited ? 'contacts.removeFromFavourites' : 'contacts.addToFavourites')}
                </MenuItem>
                {canEdit && (
                    <MenuItem
                        icon={<SharedContactIcon />}
                        onClick={() => {
                            if (!user) return
                            updateContact({
                                ID: contact.ID,
                                userID: user.userID,
                                entry: {
                                    ...contact,
                                    global: !contact.global
                                }
                            })
                        }}
                    >
                        {contact.global ? t('contacts.setAsNotShared') : t('contacts.setAsShared')}
                    </MenuItem>
                )}
                {notificationsMuted ? (
                    <MenuItem
                        isDisabled
                        icon={<NotificationIconOutline />}
                        onClick={() => {
                            /* TODO */
                        }}
                    >
                        {t('contacts.unmuteNotifications')}
                    </MenuItem>
                ) : (
                    <MenuItem
                        isDisabled
                        icon={<NotificationOffIcon />}
                        onClick={() => {
                            /* TODO */
                        }}
                    >
                        {t('contacts.muteNotifications')}
                    </MenuItem>
                )}
                <MenuItem
                    icon={<ShareIcon />}
                    onClick={() => {
                        vCardGenerator(contact)
                    }}
                >
                    {t('contacts.exportVcard')}
                </MenuItem>
                {canDelete && (
                    <>
                        <MenuDivider />
                        <MenuItem
                            variant="destructive"
                            icon={<DeleteIconOutline />}
                            onClick={onDelete}
                        >
                            {t('Delete Contact')}
                        </MenuItem>
                    </>
                )}
            </MenuList>
        </Menu>
    )
}
