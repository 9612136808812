import { Box, Flex, useColorMode } from '@chakra-ui/react'
import { ActionId, ActionImpl } from 'kbar'
import { forwardRef, useMemo } from 'react'

interface ResultItemProps {
    action: ActionImpl
    active: boolean
    currentRootActionId: ActionId | null | undefined
}

export const ResultItem = forwardRef<HTMLDivElement, ResultItemProps>(
    ({ action, active, currentRootActionId }, ref) => {
        const { colorMode } = useColorMode()

        const ancestors = useMemo(
            () => getActionAncestors(action.ancestors, currentRootActionId),
            [action.ancestors, currentRootActionId]
        )

        return (
            <Flex
                ref={ref}
                alignItems="center"
                justifyContent="space-between"
                p="12px 16px"
                bg={active ? 'rgba(0 0 0 / 0.05)' : 'transparent'}
                borderLeft="2px solid"
                borderLeftColor={active ? `${colorMode}.alerts.red` : 'transparent'}
                cursor="pointer"
                minH="53px"
            >
                <Flex alignItems="center" gap="8px" fontSize="14px">
                    {action.icon ? action.icon : null}
                    <Flex direction="column">
                        <div>
                            {ancestors.map(({ id, name }) => (
                                <ActionAncestor key={id} name={name} />
                            ))}
                            <span>{action.name}</span>
                        </div>
                        {action.subtitle && <span style={{ fontSize: 12 }}>{action.subtitle}</span>}
                    </Flex>
                </Flex>
                {action.shortcut?.length ? (
                    <Box aria-hidden display="grid" gridAutoFlow="column" gap="4px">
                        {action.shortcut.map(sc => (
                            <ActionShortcut key={sc} shortcut={sc} />
                        ))}
                    </Box>
                ) : null}
            </Flex>
        )
    }
)

function ActionAncestor({ name }: { name: string }) {
    return (
        <>
            <span style={{ opacity: 0.5, marginRight: 8 }}>{name}</span>
            <span style={{ marginRight: 8 }}>&rsaquo;</span>
        </>
    )
}

function ActionShortcut({ shortcut }: { shortcut: string }) {
    return (
        <kbd
            key={shortcut}
            style={{
                padding: '4px 6px',
                background: 'rgba(0 0 0 / .1)',
                borderRadius: '4px',
                fontSize: 14
            }}
        >
            {shortcut}
        </kbd>
    )
}

/**
 * Get the parent action names for the current action.
 * If viewing a child "page", we should only display the
 * current action name and not the parent actions.
 *
 * @example
 * When searching from the root, ancestors would be:
 * [{ id: 'settings'}, { id: 'my-numbers'}]
 * and would display "Settings... > My Numbers"
 *
 * But when viewing the `settings` page, the `settings`
 * action would not be included as an ancestor
 */
export function getActionAncestors(
    ancestors: ResultItemProps['action']['ancestors'],
    currentRootActionId: ResultItemProps['currentRootActionId']
) {
    if (!currentRootActionId) return ancestors

    const rootItemIndex = ancestors.findIndex(ancestor => ancestor.id === currentRootActionId)

    // +1 Includes any ancestors *after* the root action.
    // This would mostly be [] but could be more if the
    // there are nested actions within this action.
    return ancestors.slice(rootItemIndex + 1) ?? []
}
