import { Center, Heading, HStack, IconButton, useColorMode, VStack } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { ActivityIcon, Dropdown, useToast } from '@missionlabs/react'
import { CloseIcon, MobileIconOutline } from '@missionlabs/react/circleloop'
import {
    Activity as ActivityType,
    DirectoryEntry,
    OutboundMessageType,
    OutboundSMS,
    UserActivity
} from '@missionlabs/types'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContactNumbersDropdown } from 'shared/hooks/useContactNumbersDropdown'
import { useGetMyNumbers } from 'shared/hooks/useGetMyNumbers'
import { useIsSMSEnabled } from 'shared/hooks/useIsSMSEnabled'
import { useSendMessageMutation } from 'shared/store'

import { SMSMessageInput } from './components/SMSMessageInput/SMSMessageInput'
import { useActivityFooterState } from './hooks/useActivityFooterState'

export interface ActivityFeedFooterSMSContentProps {
    activity: UserActivity
    contact?: DirectoryEntry
    contactNumber?: string
}

export const ActivityFeedFooterSMSContent: FC<ActivityFeedFooterSMSContentProps> = ({
    contact,
    contactNumber,
    activity
}) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const myNumbers = useGetMyNumbers({ mobileOnly: true, preferDefault: true })
    const [sendSMS] = useSendMessageMutation()
    const { toast } = useToast()
    const user = useSelector(selectAuthenticatedUser)
    const { onClose } = useActivityFooterState()

    const contactMobileNumbers = useContactNumbersDropdown({ contact, mobileOnly: true })

    const toNumber = useMemo(() => {
        return contactMobileNumbers[0]?.value || contactNumber
    }, [contactMobileNumbers, contactNumber])

    const smsEnabled = useIsSMSEnabled([toNumber])

    useEffect(() => {
        if (!smsEnabled) onClose()
    }, [onClose, smsEnabled])

    useEffect(() => {
        setSMS({ ...sms, toNumber })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toNumber])

    const [sms, setSMS] = useState<OutboundSMS>({
        type: OutboundMessageType.SMS,
        toNumber: toNumber,
        fromNumber: myNumbers[0]?.value || '',
        text: '',
        ID: contact?.ID || toNumber
    })

    const setFromNumber = (value: string) => {
        setSMS({ ...sms, fromNumber: value })
    }

    const setToNumber = (value: string) => {
        setSMS({ ...sms, toNumber: value })
    }

    const handleSendSMS = async ({ text }: { text: string }) => {
        if (!text || !user) return
        try {
            await sendSMS({
                type: OutboundMessageType.SMS,
                userID: user.userID,
                toNumber: sms.toNumber,
                fromNumber: sms.fromNumber,
                text
            }).unwrap()
            onClose()
            toast({
                title: t('sms.success'),
                status: 'success'
            })
        } catch (e) {
            toast({
                title: t('sms.error'),
                status: 'error'
            })
        }
    }

    return (
        <VStack
            w="full"
            pt="18px"
            pb="24px"
            px="24px"
            borderTop="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
        >
            <HStack w="full" justify="space-between">
                <HStack>
                    <Center
                        boxSize="32px"
                        bg={`${colorMode}.tones.ghostWhite`}
                        border="1px solid"
                        borderColor={`${colorMode}.tones.periwinkle`}
                        borderRadius="full"
                    >
                        <ActivityIcon activityType={ActivityType.CHAT_MESSAGE_RECEIVED} />
                    </Center>
                    <Heading size="16px">{t('activity.replyToSMS')}</Heading>
                </HStack>
                <IconButton
                    aria-label="Close filters"
                    icon={<CloseIcon boxSize="16px" />}
                    variant="transparent"
                    size="md"
                    onClick={onClose}
                />
            </HStack>
            <HStack w="full">
                {contact && (
                    <VStack w="50%" align="flex-start">
                        <Dropdown
                            format={val => `${t('sms.to')}: ${val}`}
                            onChange={setToNumber}
                            value={sms.toNumber}
                            w="full"
                            options={contactMobileNumbers}
                            leftIcon={<MobileIconOutline />}
                        />
                    </VStack>
                )}
                <VStack w="50%" align="flex-start">
                    <Dropdown
                        format={val => `${t('sms.from')}: ${val}`}
                        onChange={setFromNumber}
                        value={sms.fromNumber}
                        w="full"
                        options={myNumbers}
                        leftIcon={<MobileIconOutline />}
                    />
                </VStack>
            </HStack>
            <SMSMessageInput
                onSendMessage={handleSendSMS}
                channelID={activity.ID}
                showCounter={true}
            />
        </VStack>
    )
}
