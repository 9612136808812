import { Box, List } from '@chakra-ui/react'
import React from 'react'

interface Props {
    children: React.ReactNode
}

const CreateReportDataStack: React.FC<Props> = ({ children }) => {
    return (
        <Box w="full" flexGrow="1">
            <List>{children}</List>
        </Box>
    )
}

export default CreateReportDataStack
