import { useColorMode } from '@chakra-ui/react'
import { Call, selectFocusedCall, useSelector } from '@missionlabs/api'
import { Flash } from '@missionlabs/react'
import { PhoneIconSolid } from '@missionlabs/react/zeta'
import { useMemo } from 'react'

import { DrawerMode } from '../ContactDrawer'
import { useContactDrawerState } from '../hooks/useContactDrawerState'
import { NavButton } from './NavButton'

interface CallButtonProps {
    callTraceID: Call['callTraceID']
    status: Call['status']
    onClick: (mode?: DrawerMode, id?: string) => void
}

export const CallButton = ({ callTraceID, status, onClick }: CallButtonProps) => {
    const focusedCall = useSelector(selectFocusedCall)
    const { mode } = useContactDrawerState()

    const isActive = useMemo(() => {
        if (mode !== DrawerMode.VOICE) return
        return callTraceID === focusedCall?.callTraceID
    }, [callTraceID, focusedCall, mode])

    return (
        <NavButton
            key={callTraceID}
            aria-label={`call button - ${status}`}
            icon={
                <>
                    <PhoneIconSolid color="inherit" boxSize="20px" />
                    {status === 'CONNECTED' && <ActiveCallIndicator />}
                    {status === 'ON_HOLD' && <OnHoldCallIndicator />}
                </>
            }
            isActive={isActive}
            onClick={() => onClick(DrawerMode.VOICE, callTraceID)}
        />
    )
}

function OnHoldCallIndicator() {
    const { colorMode } = useColorMode()
    return (
        <Flash
            w="8px"
            h="8px"
            bg={`${colorMode}.alerts.rose`}
            position="absolute"
            top="20px"
            right="20px"
            borderRadius="16px"
        />
    )
}

function ActiveCallIndicator() {
    const { colorMode } = useColorMode()
    return (
        <Flash
            w="8px"
            h="8px"
            bg={`${colorMode}.alerts.algae`}
            position="absolute"
            top="20px"
            right="20px"
            borderRadius="16px"
        />
    )
}
