import { extendTheme } from '@chakra-ui/react'

import { ActivityStyles } from './activity'
import { ActivityIconStyles } from './activityIcon'
import { ActivityRowStyles } from './activityRow'
import { AvatarBadgeStyles, AvatarStyles } from './avatar'
import { BannerStyles } from './banner'
import { borders } from './borders/borders'
import { ButtonStyles } from './button'
import { ActiveCallStyles, ParkedCallStyles, RingingCallStyles } from './calls'
import { CheckboxStyles } from './checkbox'
import { colors } from './colors/colors'
import { ContactDetailStyles } from './contactDetail'
import { ContainerStyles } from './container'
import { CountIndicatorStyles } from './countIndicator'
import { DiallerKeyStyles, DialPadStyles } from './dialler'
import { DividerStyles } from './divider'
import { DrawerStyles } from './drawer'
import { EmptyPageStyles } from './emptyPage'
import { HeaderAvatarStyles } from './headerAvatar'
import { IconButtonStyles } from './iconButton'
import { ImageSelectStyles } from './imageSelect'
import { InputStyles } from './input'
import { LabelStyles } from './label'
import { ListStyles } from './list'
import { MenuStyles } from './menu'
import { ModalStyles } from './modal'
import { NavbarStyles } from './navbar'
import { NotificationStyles } from './notification'
import { PlayerStyles } from './player'
import { ProgressBarStyles } from './progressBar'
import { RadioStyles } from './radio'
import { ScrollableStyles } from './scrollable'
import { SearchInputStyles } from './searchInput'
import { SelectStyles } from './select'
import { shadows } from './shadows/shadows'
import { SliderStyles } from './slider'
import { SpinnerStyles } from './spinner'
import { StepsStyles, StepStyles } from './steps'
import { TableStyles } from './table'
import { TabsStyles } from './tabs'
import { TagStyles } from './tag'
import { TextareaStyles } from './textarea'
import { ToastStyles } from './toast'
import { ToggleStyles } from './toggle'
import { TooltipStyles } from './tooltip'
import { BodyStyles, HeadingStyles } from './typography'

export const theme = {
    config: {
        initialColorMode: 'light',
        useSystemColorMode: false
    },
    fonts: {
        body: 'Rubik',
        heading: 'Rubik'
    },
    colors,
    shadows,
    borders,
    radii: {},
    components: {
        ActiveCall: ActiveCallStyles,
        Activity: ActivityStyles,
        ActivityIcon: ActivityIconStyles,
        ActivityRow: ActivityRowStyles,
        Text: BodyStyles,
        Button: ButtonStyles,
        IconButton: IconButtonStyles,
        Heading: HeadingStyles,
        Checkbox: CheckboxStyles,
        CountIndicator: CountIndicatorStyles,
        DiallerKey: DiallerKeyStyles,
        DialPad: DialPadStyles,
        Tooltip: TooltipStyles,
        Avatar: AvatarStyles,
        AvatarBadge: AvatarBadgeStyles,
        Tabs: TabsStyles,
        Switch: ToggleStyles,
        Spinner: SpinnerStyles,
        Step: StepStyles,
        Steps: StepsStyles,
        Label: LabelStyles,
        Menu: MenuStyles,
        Drawer: DrawerStyles,
        ContactDetail: ContactDetailStyles,
        Notification: NotificationStyles,
        Navbar: NavbarStyles,
        CustomInput: InputStyles,
        Divider: DividerStyles,
        Container: ContainerStyles,
        Textarea: TextareaStyles,
        RingingCall: RingingCallStyles,
        Radio: RadioStyles,
        Player: PlayerStyles,
        SearchInput: SearchInputStyles,
        List: ListStyles,
        HeaderAvatar: HeaderAvatarStyles,
        Modal: ModalStyles,
        Scrollable: ScrollableStyles,
        Slider: SliderStyles,
        Table: TableStyles,
        Banner: BannerStyles,
        ParkedCall: ParkedCallStyles,
        CustomSelect: SelectStyles,
        Tag: TagStyles,
        ImageSelect: ImageSelectStyles,
        EmptyPage: EmptyPageStyles,
        Toast: ToastStyles,
        ProgressBar: ProgressBarStyles
    }
}

/**
 * @see https://chakra-ui.com/docs/theming/customize-theme
 */
const phonelineplusTheme = extendTheme(theme)

export default phonelineplusTheme
