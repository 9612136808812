import {
    Button as ChakraButton,
    ButtonProps as ChakraButtonProps,
    ComponentWithAs
} from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface ButtonProps extends Omit<ChakraButtonProps, 'size' | 'variant'> {
    size?: 'sm' | 'md' | 'lg' | 'xl'
    variant?:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'creationary'
        | 'negatory'
        | 'negatory-outline'
        | 'ghost'
        | 'transparent'
        | 'link'
        | 'link-underline'
        | 'outline'
        | 'light-outline'
        | 'quaternary'
        | 'dark'
        | 'dialler'
        | 'facebook'
}

export const Button: ComponentWithAs<'button', ButtonProps> = forwardRef<
    HTMLButtonElement,
    ButtonProps
>((props, ref) => {
    return <ChakraButton {...props} ref={ref} iconSpacing="8px" />
}) as any

Button.displayName = 'Button'
