import { useColorMode } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps,
    useUpdateParams
} from '@missionlabs/react'
import { DirectoryEntry, UserRole } from '@missionlabs/types'
import { inferFirstLastName } from '@missionlabs/utils'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useUserRole } from 'shared/hooks/useUserRole'
import {
    useCreateUserContactMutation,
    useGetContactQuery,
    useUpdateUserContactMutation
} from 'shared/store'
import { AppConfig } from 'shared/utils/env'

import { VALID_SOURCES } from '../const'
import { AddEditContactForm, AddEditContactFormFields, defaultValues } from './AddEditContactForm'

const noop = () => {}

export interface AddEditContactDrawerProps extends Omit<DrawerProps, 'children'> {}

export const AddEditContactDrawer: FC<AddEditContactDrawerProps> = ({
    isOpen,
    onClose,
    ...props
}) => {
    const navigate = useNavigate()
    const { id = '' } = useParams()
    const [params] = useUpdateParams()
    const { t } = useTranslation()
    const { formatToNumberE164, formatToLocalNumber } = useFormatToNumberE164()

    const role = useUserRole()
    const user = useSelector(selectAuthenticatedUser)
    const { colorMode } = useColorMode()

    /**
     * If the drawer is opened with a number, we want to pre-fill the phone number field
     * i.e. when adding a number to a contact from the activity feed
     */
    const initialNumber = params.get('numberE164')

    const { currentData: contact } = useGetContactQuery(id, { skip: !id || !isOpen })
    const [createContact, { isLoading: createLoading, data: createdContact }] =
        useCreateUserContactMutation()
    const [updateContact, { isLoading: updateLoading, data: updatedContact }] =
        useUpdateUserContactMutation()

    const mutatedContact = createdContact ?? updatedContact
    const isLoading = createLoading || updateLoading

    const canEdit = useMemo(() => {
        if (!id) return true

        const isValidSource = VALID_SOURCES.includes(contact?.source ?? '')
        const isCreatedByUser = role === UserRole.END_USER ? contact?.userID === user?.userID : true

        return isValidSource && isCreatedByUser
    }, [contact, id, role, user])

    const initialValues = useMemo(() => {
        const values = { ...defaultValues }

        if (contact) {
            const { firstName, lastName } = inferFirstLastName(contact)
            Object.assign(values, {
                firstName: firstName,
                lastName: lastName,
                companyName: contact.companyName ?? '',
                jobTitle: contact.jobTitle ?? '',
                phoneNumbers: contact.phoneNumbers?.length
                    ? contact.phoneNumbers.map(item => ({
                          ...item,
                          numberE164: formatToLocalNumber(item.numberE164)
                      }))
                    : defaultValues.phoneNumbers,
                email: contact.emails?.length ? contact.emails[0].email : '',
                tags: contact.tags ?? [],
                shared: contact.global
            })
        }

        if (initialNumber) {
            values.phoneNumbers.push({ numberE164: formatToLocalNumber(initialNumber) })
        }

        return values
    }, [contact, formatToLocalNumber, initialNumber])

    function handleTransition() {
        if (mutatedContact) {
            return navigate(`/contacts/${mutatedContact.ID}`)
        }
        return navigate(-1)
    }

    async function onSubmit(data: AddEditContactFormFields) {
        if (!user) throw new Error('[AddEditContactDrawer] No user!')

        try {
            const { email, phoneNumbers, shared, ...rest } = data

            const sanitizedData: Partial<DirectoryEntry> = {
                ...rest,
                emails: email ? [{ email }] : [],
                phoneNumbers: phoneNumbers.map(item => ({
                    label: item.label,
                    numberE164: formatToNumberE164(item.numberE164)
                })),
                global: !!shared,
                source: AppConfig.source
            }
            if (contact) {
                await updateContact({
                    ID: contact.ID,
                    userID: user.userID,
                    entry: sanitizedData
                }).unwrap()
            } else {
                await createContact({ userID: user.userID, entry: sanitizedData }).unwrap()
            }

            return onClose()
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Drawer
            {...props}
            size="sm"
            isOpen={isOpen}
            onClose={!isLoading ? onClose : noop}
            onCloseComplete={handleTransition}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>
                    {contact
                        ? `${initialValues.firstName} ${initialValues.lastName}`
                        : t('contacts.form.create.contact')}
                </DrawerHeader>
                <DrawerBody bgColor={`${colorMode}.tones.ghostWhite`}>
                    <AddEditContactForm
                        values={initialValues}
                        isCreate={!contact}
                        canEdit={canEdit}
                        onClose={onClose}
                        onSubmit={onSubmit}
                    />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
