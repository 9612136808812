import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ListIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ListIconLight',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M0.3125 1.8125C0.3125 1.30469 0.703125 0.875 1.25 0.875H3.125C3.63281 0.875 4.0625 1.30469 4.0625 1.8125V3.6875C4.0625 4.23438 3.63281 4.625 3.125 4.625H1.25C0.703125 4.625 0.3125 4.23438 0.3125 3.6875V1.8125ZM2.8125 3.375V2.125H1.5625V3.375H2.8125ZM19.0625 2.125C19.375 2.125 19.6875 2.4375 19.6875 2.75C19.6875 3.10156 19.375 3.375 19.0625 3.375H6.5625C6.21094 3.375 5.9375 3.10156 5.9375 2.75C5.9375 2.4375 6.21094 2.125 6.5625 2.125H19.0625ZM19.0625 8.375C19.375 8.375 19.6875 8.6875 19.6875 9C19.6875 9.35156 19.375 9.625 19.0625 9.625H6.5625C6.21094 9.625 5.9375 9.35156 5.9375 9C5.9375 8.6875 6.21094 8.375 6.5625 8.375H19.0625ZM19.0625 14.625C19.375 14.625 19.6875 14.9375 19.6875 15.25C19.6875 15.6016 19.375 15.875 19.0625 15.875H6.5625C6.21094 15.875 5.9375 15.6016 5.9375 15.25C5.9375 14.9375 6.21094 14.625 6.5625 14.625H19.0625ZM3.125 7.125C3.63281 7.125 4.0625 7.55469 4.0625 8.0625V9.9375C4.0625 10.4844 3.63281 10.875 3.125 10.875H1.25C0.703125 10.875 0.3125 10.4844 0.3125 9.9375V8.0625C0.3125 7.55469 0.703125 7.125 1.25 7.125H3.125ZM1.5625 8.375V9.625H2.8125V8.375H1.5625ZM0.3125 14.3125C0.3125 13.8047 0.703125 13.375 1.25 13.375H3.125C3.63281 13.375 4.0625 13.8047 4.0625 14.3125V16.1875C4.0625 16.7344 3.63281 17.125 3.125 17.125H1.25C0.703125 17.125 0.3125 16.7344 0.3125 16.1875V14.3125ZM2.8125 15.875V14.625H1.5625V15.875H2.8125Z"
            fill="currentColor"
        />
    )
})

export const ListIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ListIconRegular',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M3.125 0.875C3.63281 0.875 4.0625 1.30469 4.0625 1.8125V3.6875C4.0625 4.23438 3.63281 4.625 3.125 4.625H1.25C0.703125 4.625 0.3125 4.23438 0.3125 3.6875V1.8125C0.3125 1.30469 0.703125 0.875 1.25 0.875H3.125ZM18.75 1.8125C19.2578 1.8125 19.6875 2.24219 19.6875 2.75C19.6875 3.29688 19.2578 3.6875 18.75 3.6875H6.875C6.32812 3.6875 5.9375 3.29688 5.9375 2.75C5.9375 2.24219 6.32812 1.8125 6.875 1.8125H18.75ZM18.75 8.0625C19.2578 8.0625 19.6875 8.49219 19.6875 9C19.6875 9.54688 19.2578 9.9375 18.75 9.9375H6.875C6.32812 9.9375 5.9375 9.54688 5.9375 9C5.9375 8.49219 6.32812 8.0625 6.875 8.0625H18.75ZM18.75 14.3125C19.2578 14.3125 19.6875 14.7422 19.6875 15.25C19.6875 15.7969 19.2578 16.1875 18.75 16.1875H6.875C6.32812 16.1875 5.9375 15.7969 5.9375 15.25C5.9375 14.7422 6.32812 14.3125 6.875 14.3125H18.75ZM0.3125 8.0625C0.3125 7.55469 0.703125 7.125 1.25 7.125H3.125C3.63281 7.125 4.0625 7.55469 4.0625 8.0625V9.9375C4.0625 10.4844 3.63281 10.875 3.125 10.875H1.25C0.703125 10.875 0.3125 10.4844 0.3125 9.9375V8.0625ZM3.125 13.375C3.63281 13.375 4.0625 13.8047 4.0625 14.3125V16.1875C4.0625 16.7344 3.63281 17.125 3.125 17.125H1.25C0.703125 17.125 0.3125 16.7344 0.3125 16.1875V14.3125C0.3125 13.8047 0.703125 13.375 1.25 13.375H3.125Z"
            fill="currentColor"
        />
    )
})

export const ListIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ListIconSolid',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M3.125 0.875C3.63281 0.875 4.0625 1.30469 4.0625 1.8125V3.6875C4.0625 4.23438 3.63281 4.625 3.125 4.625H1.25C0.703125 4.625 0.3125 4.23438 0.3125 3.6875V1.8125C0.3125 1.30469 0.703125 0.875 1.25 0.875H3.125ZM18.4375 1.5C19.1016 1.5 19.6875 2.08594 19.6875 2.75C19.6875 3.45312 19.1016 4 18.4375 4H7.1875C6.48438 4 5.9375 3.45312 5.9375 2.75C5.9375 2.08594 6.48438 1.5 7.1875 1.5H18.4375ZM18.4375 7.75C19.1016 7.75 19.6875 8.33594 19.6875 9C19.6875 9.70312 19.1016 10.25 18.4375 10.25H7.1875C6.48438 10.25 5.9375 9.70312 5.9375 9C5.9375 8.33594 6.48438 7.75 7.1875 7.75H18.4375ZM18.4375 14C19.1016 14 19.6875 14.5859 19.6875 15.25C19.6875 15.9531 19.1016 16.5 18.4375 16.5H7.1875C6.48438 16.5 5.9375 15.9531 5.9375 15.25C5.9375 14.5859 6.48438 14 7.1875 14H18.4375ZM0.3125 8.0625C0.3125 7.55469 0.703125 7.125 1.25 7.125H3.125C3.63281 7.125 4.0625 7.55469 4.0625 8.0625V9.9375C4.0625 10.4844 3.63281 10.875 3.125 10.875H1.25C0.703125 10.875 0.3125 10.4844 0.3125 9.9375V8.0625ZM3.125 13.375C3.63281 13.375 4.0625 13.8047 4.0625 14.3125V16.1875C4.0625 16.7344 3.63281 17.125 3.125 17.125H1.25C0.703125 17.125 0.3125 16.7344 0.3125 16.1875V14.3125C0.3125 13.8047 0.703125 13.375 1.25 13.375H3.125Z"
            fill="currentColor"
        />
    )
})
