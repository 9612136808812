import { Box, VStack } from '@chakra-ui/react'
import {
    Button,
    ErrorMessage,
    get,
    Heading,
    useFieldArray,
    useFormContext
} from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { AddEditContactFormFields } from './AddEditContactForm'
import { AddEditContactPhoneNumbersField } from './AddEditContactPhoneNumbersField'

export interface AddEditContactPhoneNumbersProps {}

export const AddEditContactPhoneNumbers: FC<AddEditContactPhoneNumbersProps> = () => {
    const { t } = useTranslation()
    const {
        formState: { errors },
        clearErrors
    } = useFormContext()
    const { fields, append, update, remove } = useFieldArray<AddEditContactFormFields>({
        name: 'phoneNumbers'
    })

    const error = get(errors, 'phoneNumbers')

    function createField() {
        append({ label: undefined, numberE164: '' })
        clearErrors('phoneNumbers')
    }

    function updateField(item: (typeof fields)[number], index: number, value: string) {
        update(index, { ...item, label: value })
        clearErrors('phoneNumbers')
    }

    return (
        <VStack spacing="16px" align="inherit" data-testid="add-edit-contact-phone-numbers">
            <Heading size="h3" fontSize="16px" fontWeight="500">
                {t('contacts.form.contactDetails')}
            </Heading>
            {fields.map((item, i) => (
                <AddEditContactPhoneNumbersField
                    key={item.id}
                    item={item}
                    index={i}
                    update={updateField}
                    remove={remove}
                />
            ))}
            <Box w="114px">
                <Button variant="tertiary" onClick={createField}>
                    {t('Add new')}
                </Button>
            </Box>
            <ErrorMessage error={error} />
        </VStack>
    )
}
