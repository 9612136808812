import {
    Input as ChakraInput,
    InputGroup,
    InputProps as ChakraInputProps,
    InputRightElement,
    useMultiStyleConfig
} from '@chakra-ui/react'
import {
    ChangeEventHandler,
    forwardRef,
    ReactNode,
    startTransition,
    useEffect,
    useState
} from 'react'

export interface SearchInputProps extends Omit<ChakraInputProps, 'defaultValue'> {
    variant?: 'light' | 'dark'
    rightElement?: ReactNode
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
    (
        { variant = 'light', value, rightElement, isDisabled, isInvalid, onChange, ...props },
        ref
    ) => {
        const styles = useMultiStyleConfig('SearchInput', { variant })

        const [innerValue, setInnerValue] = useState(value)

        const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
            const value = e.target.value
            // debounce outer state change
            startTransition(() => onChange?.(e))
            setInnerValue(value)
        }

        useEffect(() => {
            if (innerValue !== value) setInnerValue(value)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value])

        return (
            <InputGroup
                sx={{ ...styles.group, ...(props.sx ?? {}) }}
                data-active={!!innerValue || undefined}
            >
                <ChakraInput
                    {...props}
                    ref={ref}
                    sx={{ ...styles.input }}
                    isDisabled={isDisabled}
                    isInvalid={isInvalid}
                    value={innerValue}
                    onChange={handleChange}
                />
                {!!rightElement && (
                    <InputRightElement aria-disabled={isDisabled} sx={{ ...styles.rightAside }}>
                        {rightElement}
                    </InputRightElement>
                )}
            </InputGroup>
        )
    }
)
