import { useColorMode, useDisclosure } from '@chakra-ui/react'
import { Button, IconButton, Scrollable, useMeasure } from '@missionlabs/react'
import { AddIcon } from '@missionlabs/react/circleloop'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SearchInput } from 'shared/components/SearchInput'
import { useChatChannelsSearch } from 'shared/hooks/useChatChannelsSearch'
import { useMessagesSearch } from 'shared/hooks/useMessagesSearch'

import { ChatList } from './ChatList'
import { ChatSearchTab } from './ChatSearchTab'

export interface ChatSidebarProps {
    alwaysSearch?: boolean
}

export const ChatSidebar: FC<ChatSidebarProps> = ({ alwaysSearch }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const fab = useDisclosure()
    const navigate = useNavigate()

    const [searchOnFocus, setSearchOnFocus] = useState(false)

    const [sectionRef, { blockSize: sectionHeight }] = useMeasure<HTMLDivElement>()

    const [searchMessages, { data: messages, isLoading: messagesLoading }] = useMessagesSearch()
    const [searchChannels, { data: channels, isLoading: channelsLoading }] = useChatChannelsSearch()

    function search(searchTerm: string) {
        if (searchTerm.trim().length === 0) return setSearchOnFocus(false)
        searchMessages({ searchTerm, maxResults: 1000 })
        searchChannels(searchTerm)
        setSearchOnFocus(true)
    }

    function renderList() {
        if (searchOnFocus || alwaysSearch) {
            return (
                <ChatSearchTab
                    channels={channels}
                    channelsLoading={channelsLoading}
                    messages={messages}
                    messagesLoading={messagesLoading}
                    setSearchOnFocus={setSearchOnFocus}
                />
            )
        }

        return <ChatList />
    }

    return (
        <aside style={{ position: 'relative' }}>
            <section ref={sectionRef} aria-label="search">
                <SearchInput
                    hideFavourites
                    hideFilters
                    placeholder={t('Search')}
                    onChange={e => search(e.target.value)}
                />
            </section>
            <Scrollable
                h={`calc( 100% - ${sectionHeight}px )`}
                bg={`${colorMode}.tones.ghostWhite`}
            >
                {renderList()}
            </Scrollable>
            <div
                id="fab-container"
                style={{
                    position: 'absolute',
                    bottom: '16px',
                    right: '32px',
                    zIndex: fab.isOpen ? 1 : 0
                }}
                onMouseLeave={fab.onClose}
            >
                <Button
                    onClick={() => {
                        fab.onClose()
                        navigate('/chat/groups/add')
                    }}
                    sx={{
                        height: '48px',
                        borderRadius: '100px',
                        position: 'relative',
                        right: fab.isOpen ? '20px' : 0,
                        opacity: fab.isOpen ? 1 : 0,
                        visibility: fab.isOpen ? 'visible' : 'collapse',
                        transition: 'all 200ms ease',
                        transitionDelay: '100ms'
                    }}
                >
                    {t('chatGroups.createCTA')}
                </Button>
                <Button
                    onClick={() => {
                        fab.onClose()
                        navigate('/chat/add')
                    }}
                    sx={{
                        height: '48px',
                        borderRadius: '100px',
                        position: 'relative',
                        right: fab.isOpen ? '10px' : 0,
                        opacity: fab.isOpen ? 1 : 0,
                        visibility: fab.isOpen ? 'visible' : 'collapse',
                        transition: 'all 200ms ease'
                    }}
                >
                    {t('chat.newChat')}
                </Button>

                <IconButton
                    aria-label="More actions"
                    variant={fab.isOpen ? 'secondary' : 'primary'}
                    onMouseEnter={fab.onOpen}
                    icon={<AddIcon rounded="full" boxSize="32px" />}
                    sx={{
                        width: '48px',
                        height: '48px',
                        borderRadius: '100%',
                        position: 'relative',
                        zIndex: 1
                    }}
                />
            </div>
        </aside>
    )
}
