import { RefObject, useRef } from 'react'

type ActiveNotification = {
    id: string
    dismiss?: () => void
}

export interface UseNotificationRefsReturn {
    notifications: RefObject<ActiveNotification[]>
    has: (id: string) => boolean
    add: (id: string, dismiss?: () => Promise<void> | void) => Promise<void> | void
    remove: (id: string) => Promise<void> | void
    dismissOthers: (ids: string[]) => Promise<void> | void
}

export const useNotificationRefs = (): UseNotificationRefsReturn => {
    const notifications = useRef<ActiveNotification[]>([])

    const has = (id: string) => {
        return !!notifications.current.find(ref => ref.id === id)
    }

    const add = (id: string, dismiss?: () => void) => {
        if (has(id)) return
        notifications.current.push({ id, dismiss })
    }

    const remove = (id: string) => {
        if (!has(id)) return
        notifications.current = notifications.current.filter(ref => {
            if (ref.id !== id) return true

            ref.dismiss?.()
            return false
        })
    }

    // Dismisses all notifications which IDs are not provided to this function.
    // The `dismiss` function for each notification is called and then references are removed from the array.
    const dismissOthers = (ids: string[]) => {
        notifications.current = notifications.current.filter(ref => {
            if (ids.includes(ref.id)) {
                return true
            }

            ref.dismiss?.()
            return false
        })
    }

    return { notifications: notifications, has, add, remove, dismissOthers }
}
