import { useDisclosure } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { useGetUserActivities } from 'shared/hooks/useGetUserActivities'
import { useMarkActivitiesAsReadMutation } from 'shared/store'

import { useActivitySidebarFilters } from './hooks/useActivitySidebarFilters'

type IActivitySidebarContext = ReturnType<typeof useGetUserActivities> &
    ReturnType<typeof useActivitySidebarFilters> & {
        markAllAsReadDialog: ReturnType<typeof useDisclosure>
    }

const ActivitySidebarContext = createContext<IActivitySidebarContext | null>(null)
export const ActivitySidebarContextProvider = ({ children }: PropsWithChildren) => {
    const markAllAsReadDialog = useDisclosure()
    const filterHelpers = useActivitySidebarFilters()
    const activities = useGetUserActivities(filterHelpers.filters)

    const value = useMemo(
        () => ({ ...activities, ...filterHelpers, markAllAsReadDialog }),
        [activities, filterHelpers, markAllAsReadDialog]
    )

    return (
        <ActivitySidebarContext.Provider value={value}>{children}</ActivitySidebarContext.Provider>
    )
}

export const useActivitySidebarContext = () => {
    const ctx = useContext(ActivitySidebarContext)
    if (!ctx) {
        throw Error('useActivitySidebarContext used outside of <ActivitySidebarContextProvider />')
    }
    return ctx
}

/**
 * If inside ActivitySidebarContext, use the handleRead function from it.
 * Otherwise, provide a simple implementation which doesn't update the sidebar.
 */
export function useHandleReadContext() {
    const ctx = useContext(ActivitySidebarContext)
    const user = useSelector(selectAuthenticatedUser)
    const [markAsRead] = useMarkActivitiesAsReadMutation()

    const handleReadFallback = useCallback(
        async (id: string) => {
            if (!user) return

            // We can't use updateActivity here like ActivitySidebarContext does, but this endpoint allows us
            // to mark as read using only the activity ID.
            await markAsRead({
                userID: user.userID,
                activityIDs: [id]
            }).unwrap()
        },
        [user, markAsRead]
    )

    if (ctx) return ctx.handleRead

    return handleReadFallback
}
