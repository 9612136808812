import {
    BreadcrumbItem,
    BreadcrumbLink as ChakraBreadcrumbLink,
    BreadcrumbLinkProps as ChakraBreadcrumbLinkProps,
    BreadcrumbSeparator,
    ResponsiveValue,
    useStyleConfig
} from '@chakra-ui/react'
import { Body } from 'atoms'
import { forwardRef } from 'react'

export interface BreadcrumbLinkProps extends ChakraBreadcrumbLinkProps {
    title: string
    variant?: ResponsiveValue<string> | undefined
    isLast?: boolean
    isDisabled?: boolean
}

export const BreadcrumbLink = forwardRef<HTMLLIElement, BreadcrumbLinkProps>(
    ({ isLast, isCurrentPage, title, variant, isDisabled, ...props }, ref) => {
        const linkStyles = useStyleConfig('BreadcrumbLink', { variant })
        const separatorStyles = useStyleConfig('BreadcrumbSeparator')

        return (
            <BreadcrumbItem ref={ref}>
                <ChakraBreadcrumbLink
                    sx={{ ...linkStyles }}
                    pointerEvents={isDisabled ? 'none' : 'auto'}
                    isCurrentPage={isCurrentPage}
                    variant={variant}
                    {...props}
                >
                    <Body variant={isCurrentPage ? 'none' : 'link'}>{title}</Body>
                </ChakraBreadcrumbLink>
                {!isLast && (
                    <BreadcrumbSeparator sx={{ ...separatorStyles }}>
                        <Body>/</Body>
                    </BreadcrumbSeparator>
                )}
            </BreadcrumbItem>
        )
    }
)
