import { useColorMode } from '@chakra-ui/react'
import {
    BoldTranslation,
    Checkbox,
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    Heading
} from '@missionlabs/react'
import { SubscriptionType } from '@missionlabs/types'
import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateUserMutation } from 'shared/store'

interface SubscriptionModalProps {
    selected: { id: string; subscription: SubscriptionType } | undefined
    isOpen: boolean
    onClose: () => void
}

export const SubscriptionModal: FC<SubscriptionModalProps> = ({ selected, isOpen, onClose }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const ref = useRef<HTMLButtonElement>(null)
    const [confirm, setConfirm] = useState<boolean>(false)

    const [updateUser, { isError, isLoading, reset }] = useUpdateUserMutation()

    async function handleSwitch() {
        if (!selected) return
        try {
            const { id, subscription } = selected
            await updateUser({ ID: id, subscriptionType: subscription?.subscriptionType }).unwrap()
            return onClose()
        } catch {
            // noop
        }
    }

    function handleTransition() {
        setConfirm(false)
        reset()
    }

    return (
        <Dialog
            leastDestructiveRef={ref}
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={handleTransition}
        >
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>{t('billing.switchSubscription')}</DialogHeader>
                <DialogBody>
                    {selected?.subscription.subscriptionType === 'pro'
                        ? t('billing.switchUnlimitedDescription')
                        : t('billing.switchPaygDescription')}
                    <br />
                    <BoldTranslation
                        i18nKey="billing.newPrice"
                        color={`${colorMode}.red.dark`}
                        fontWeight={500}
                        values={{ price: (selected?.subscription.price ?? 0) / 100 }}
                    />
                    {isError ? (
                        <Heading
                            size="h5"
                            variant="bold"
                            color={`${colorMode}.red.dark`}
                            fontWeight={500}
                        >
                            {t('error.updatedSubscriptionError')}
                        </Heading>
                    ) : (
                        <Checkbox
                            m="16px 0px 0px 12px"
                            isChecked={confirm}
                            onChange={e => setConfirm(e.target.checked)}
                        >
                            {t('billing.confirm')}
                        </Checkbox>
                    )}
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('Switch'),
                        isDisabled: isError || !confirm,
                        onClick: handleSwitch
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
