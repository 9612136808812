import { useMultiStyleConfig } from '@chakra-ui/react'
import { ErrorIconSolid } from 'atoms/Icons/zeta'
import { FC } from 'react'

import { BannerProps } from './Banner'

export interface BannerIconProps extends BannerProps {}

export const BannerIcon: FC<BannerIconProps> = ({ variant }) => {
    const styles = useMultiStyleConfig('Banner')

    switch (variant) {
        case 'error':
            return <ErrorIconSolid sx={styles.icon} p="4.33px" />
    }

    return null
}
