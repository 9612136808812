import { useColorMode } from '@chakra-ui/react'
import { Navbar as MLNavbar, NavbarGroup, Tooltip } from '@missionlabs/react'
import { AdminSettingsIcon, ChartIcon } from '@missionlabs/react/circleloop'
import {
    CalendarIconRegular,
    ContactBookIconRegular,
    ListIconSolid,
    MessageIconRegular,
    SettingsIconRegular
} from '@missionlabs/react/zeta'
import { UserRole } from '@missionlabs/types'
import { getUnixTimestampsForDay } from '@missionlabs/utils'
import { useFlags } from 'flagsmith/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams } from 'react-router-dom'
import { RequireRole } from 'routes/RequireRole'
import { Features } from 'shared/hooks/useHasClientFeature'
import { useHasFeature } from 'shared/hooks/useHasFeature'

import { GlobalNavMenu } from '../GlobalNavMenu/GlobalNavMenu'
import { NavbarItem } from './NavbarItem'

export interface NavbarProps {}

export const Navbar: FC<NavbarProps> = () => {
    const { t } = useTranslation()
    const hasVideoPermission = useHasFeature(Features.meetings)
    const flags = useFlags(['chat'])
    const { colorMode } = useColorMode()

    return (
        <MLNavbar>
            <NavbarGroup mt={window.platform === 'darwin' ? '26px' : '0'}>
                <NavbarItem>
                    <GlobalNavMenu />
                </NavbarItem>
                <Tooltip
                    bg={`${colorMode}.alerts.red`}
                    label={t('Home')}
                    shouldWrapChildren={true}
                    placement="end"
                >
                    <NavbarItem to="/home">
                        <ListIconSolid aria-label="Home" />
                    </NavbarItem>
                </Tooltip>
                <Tooltip
                    bg={`${colorMode}.alerts.red`}
                    label={t('Contacts')}
                    shouldWrapChildren={true}
                    placement="end"
                >
                    <NavbarItem to="/contacts">
                        <ContactBookIconRegular aria-label="Contacts" />
                    </NavbarItem>
                </Tooltip>
                <Tooltip
                    bg={`${colorMode}.alerts.red`}
                    label={t('analytics.title')}
                    shouldWrapChildren={true}
                    placement="end"
                >
                    <NavbarItem to="/analytics">
                        <ChartIcon aria-label="Analytics" />
                    </NavbarItem>
                </Tooltip>
                {flags.chat.enabled && (
                    <Tooltip
                        bg={`${colorMode}.alerts.red`}
                        label={t('Messages')}
                        shouldWrapChildren={true}
                        placement="end"
                    >
                        <NavbarItem to="/chat">
                            <MessageIconRegular aria-label="Messages" />
                        </NavbarItem>
                    </Tooltip>
                )}
                {hasVideoPermission && (
                    <Tooltip
                        bg={`${colorMode}.alerts.red`}
                        label={t('Meetings')}
                        shouldWrapChildren={true}
                        placement="end"
                    >
                        <NavbarItem
                            to={{
                                pathname: `/meetings/day`,
                                search: `?${createSearchParams({
                                    ...getUnixTimestampsForDay(new Date())
                                })}`
                            }}
                        >
                            <CalendarIconRegular aria-label="Meetings" />
                        </NavbarItem>
                    </Tooltip>
                )}
            </NavbarGroup>
            <NavbarGroup>
                <Tooltip
                    bg={`${colorMode}.alerts.red`}
                    label={t('settings.title')}
                    shouldWrapChildren={true}
                    placement="end"
                >
                    <NavbarItem to="/settings">
                        <SettingsIconRegular aria-label="Settings" />
                    </NavbarItem>
                </Tooltip>
                <Tooltip
                    bg={`${colorMode}.alerts.red`}
                    label={t('admin.title')}
                    shouldWrapChildren={true}
                    placement="end"
                >
                    <RequireRole role={UserRole.ADMIN} redirect={false}>
                        <NavbarItem to="/admin">
                            <AdminSettingsIcon aria-label="Admin Settings" />
                        </NavbarItem>
                    </RequireRole>
                </Tooltip>
            </NavbarGroup>
        </MLNavbar>
    )
}
