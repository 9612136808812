import { BoxProps, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Heading, Spinner } from '@missionlabs/react'
import { forwardRef, ReactNode } from 'react'

export interface SettingsHeaderProps extends Omit<BoxProps, 'title'> {
    title?: ReactNode
    subtitle?: ReactNode
    isLoading?: boolean
    actions?: ReactNode
}

export const SettingsHeader = forwardRef(
    ({ title = '', subtitle = '', isLoading, actions, ...props }: SettingsHeaderProps, ref) => {
        const { colorMode } = useColorMode()
        return (
            <HStack
                p="18px 24px"
                bg="white"
                borderBottom="1px solid"
                borderColor={`${colorMode}.tones.periwinkle`}
                justify="space-between"
                minH="90px"
                {...props}
                ref={ref}
            >
                <VStack spacing={0} align="start">
                    {typeof title === 'string' ? (
                        <Heading size="h3" fontSize="20px" lineHeight="32px" fontWeight={400}>
                            {title}
                        </Heading>
                    ) : (
                        title
                    )}
                    {typeof subtitle === 'string' ? (
                        <Body size="sm" color={`${colorMode}.tones.stormGrey`}>
                            {subtitle}
                        </Body>
                    ) : (
                        subtitle
                    )}
                </VStack>
                {isLoading && <Spinner size="sm" ml="auto" mr="16px" />}
                {actions}
            </HStack>
        )
    }
)

SettingsHeader.displayName = 'SettingsHeader'
