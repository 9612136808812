import { Box } from '@chakra-ui/react'
import { AnalyticsTotals } from '@missionlabs/types'
import { Pie } from '@nivo/pie'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import { FC, useMemo } from 'react'

import { AnalyticsGraphProps } from '../AnalyticsGraph/AnalyticsGraphProps'
import { CenteredMetric } from './CenteredMetric'

type AnalyticsPieChartProps = Omit<AnalyticsGraphProps, 'data'> & {
    data: AnalyticsTotals
    dataKey: keyof AnalyticsTotals
}

export type AnalyticsPieChartDatum = { id: string; label: string; value: number; color: string }

const reducer = (acc: number, cur: AnalyticsPieChartDatum): number => acc + cur.value

const createDatumSingletons = (
    data: AnalyticsTotals,
    colours: Record<string, string>
): Record<string, AnalyticsPieChartDatum> => {
    return Object.assign(
        {},
        ...['inbound', 'outbound', 'missed'].map(key => ({
            [key]: { id: key, label: key, value: data[key], color: colours[key] }
        }))
    )
}

export const AnalyticsPieChart: FC<AnalyticsPieChartProps> = ({ data, dataKey }) => {
    const { colours } = useAnalytics()

    const graphData = useMemo(() => {
        const singletons = createDatumSingletons(data, colours)
        const total = Object.values(singletons).reduce(reducer, 0)

        const singletonForKey = singletons[dataKey]
        const remainder = {
            id: 'remainder',
            label: 'remainder',
            value: total - singletonForKey.value,
            color: colours['remainder']
        }

        return [singletonForKey, remainder]
    }, [data, dataKey, colours])

    return (
        <Box data-testid="analytics-pie-chart">
            <Pie
                height={56}
                width={56}
                data={graphData}
                colors={d => d.data.color}
                layers={['arcs', CenteredMetric]}
                legends={undefined}
                innerRadius={0.85}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                isInteractive={false}
            />
        </Box>
    )
}
