import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const BinIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BinIconLight',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M5.875 15.625C5.875 15.9766 5.5625 16.25 5.25 16.25C4.89844 16.25 4.625 15.9766 4.625 15.625V6.875C4.625 6.5625 4.89844 6.25 5.25 6.25C5.5625 6.25 5.875 6.5625 5.875 6.875V15.625ZM9.625 15.625C9.625 15.9766 9.3125 16.25 9 16.25C8.64844 16.25 8.375 15.9766 8.375 15.625V6.875C8.375 6.5625 8.64844 6.25 9 6.25C9.3125 6.25 9.625 6.5625 9.625 6.875V15.625ZM13.375 15.625C13.375 15.9766 13.0625 16.25 12.75 16.25C12.3984 16.25 12.125 15.9766 12.125 15.625V6.875C12.125 6.5625 12.3984 6.25 12.75 6.25C13.0625 6.25 13.375 6.5625 13.375 6.875V15.625ZM12.3594 0.898438L13.375 2.5H17.125C17.4375 2.5 17.75 2.8125 17.75 3.125C17.75 3.47656 17.4375 3.75 17.125 3.75H16.5V16.875C16.5 18.6328 15.0938 20 13.375 20H4.625C2.86719 20 1.5 18.6328 1.5 16.875V3.75H0.875C0.523438 3.75 0.25 3.47656 0.25 3.125C0.25 2.8125 0.523438 2.5 0.875 2.5H4.58594L5.60156 0.898438C5.91406 0.351562 6.53906 0 7.16406 0H10.7969C11.4219 0 12.0469 0.351562 12.3594 0.898438ZM6.03125 2.5H11.9297L11.3047 1.5625C11.1875 1.36719 10.9922 1.25 10.7969 1.25H7.16406C6.96875 1.25 6.77344 1.36719 6.65625 1.5625L6.03125 2.5ZM2.75 16.875C2.75 17.9297 3.57031 18.75 4.625 18.75H13.375C14.3906 18.75 15.25 17.9297 15.25 16.875V3.75H2.75V16.875Z"
            fill="currentColor"
        />
    )
})

export const BinIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BinIconRegular',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M6.5 15.625C6.5 15.9766 6.1875 16.25 5.875 16.25C5.52344 16.25 5.25 15.9766 5.25 15.625V7.5C5.25 7.1875 5.52344 6.875 5.875 6.875C6.1875 6.875 6.5 7.1875 6.5 7.5V15.625ZM9.625 15.625C9.625 15.9766 9.3125 16.25 9 16.25C8.64844 16.25 8.375 15.9766 8.375 15.625V7.5C8.375 7.1875 8.64844 6.875 9 6.875C9.3125 6.875 9.625 7.1875 9.625 7.5V15.625ZM12.75 15.625C12.75 15.9766 12.4375 16.25 12.125 16.25C11.7734 16.25 11.5 15.9766 11.5 15.625V7.5C11.5 7.1875 11.7734 6.875 12.125 6.875C12.4375 6.875 12.75 7.1875 12.75 7.5V15.625ZM12.6328 0.976562L14.0781 3.125H16.8125C17.3203 3.125 17.75 3.55469 17.75 4.0625C17.75 4.60938 17.3203 5 16.8125 5H16.5V16.875C16.5 18.6328 15.0938 20 13.375 20H4.625C2.86719 20 1.5 18.6328 1.5 16.875V5H1.1875C0.640625 5 0.25 4.60938 0.25 4.0625C0.25 3.55469 0.640625 3.125 1.1875 3.125H3.88281L5.32812 0.976562C5.71875 0.390625 6.42188 0 7.16406 0H10.7969C11.5391 0 12.2422 0.390625 12.6328 0.976562ZM6.14844 3.125H11.8125L11.0703 2.03125C11.0312 1.95312 10.9141 1.875 10.7969 1.875H7.16406C7.04688 1.875 6.92969 1.95312 6.89062 2.03125L6.14844 3.125ZM3.375 16.875C3.375 17.5781 3.92188 18.125 4.625 18.125H13.375C14.0391 18.125 14.625 17.5781 14.625 16.875V5H3.375V16.875Z"
            fill="currentColor"
        />
    )
})

export const BinIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BinIconSolid',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M5.52344 0.703125C5.71875 0.273438 6.14844 0 6.61719 0H11.3438C11.8125 0 12.2422 0.273438 12.4375 0.703125L12.75 1.25H16.5C17.1641 1.25 17.75 1.83594 17.75 2.5C17.75 3.20312 17.1641 3.75 16.5 3.75H1.5C0.796875 3.75 0.25 3.20312 0.25 2.5C0.25 1.83594 0.796875 1.25 1.5 1.25H5.25L5.52344 0.703125ZM1.46094 5H16.5V17.5C16.5 18.9062 15.3672 20 14 20H3.96094C2.59375 20 1.46094 18.9062 1.46094 17.5V5ZM4.58594 8.125V16.875C4.58594 17.2266 4.89844 17.5 5.21094 17.5C5.5625 17.5 5.83594 17.2266 5.83594 16.875V8.125C5.83594 7.8125 5.5625 7.5 5.21094 7.5C4.89844 7.5 4.58594 7.8125 4.58594 8.125ZM8.33594 8.125V16.875C8.33594 17.2266 8.64844 17.5 8.96094 17.5C9.3125 17.5 9.625 17.2266 9.625 16.875V8.125C9.625 7.8125 9.3125 7.5 8.96094 7.5C8.64844 7.5 8.33594 7.8125 8.33594 8.125ZM12.125 8.125V16.875C12.125 17.2266 12.3984 17.5 12.75 17.5C13.0625 17.5 13.375 17.2266 13.375 16.875V8.125C13.375 7.8125 13.0625 7.5 12.75 7.5C12.3984 7.5 12.125 7.8125 12.125 8.125Z"
            fill="currentColor"
        />
    )
})
