import { CheckCircleIcon, InfoIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons'
import { Box, CloseButton, Flex, HStack, useMultiStyleConfig } from '@chakra-ui/react'
import { Body } from 'index'
import { ReactNode, useCallback } from 'react'
import toast, { ToastPosition } from 'react-hot-toast'

export type Params = {
    title?: string
    component?: ReactNode
    duration?: number | undefined
    position?: ToastPosition
    status?: 'success' | 'warning' | 'error' | 'info'
    icon?: ReactNode
    fontColor?: string
}

const useToast = () => {
    const { info, warning, error, success } = useMultiStyleConfig('Toast')

    const renderBody = useCallback((config: Params) => {
        return (
            <HStack h="100%" spacing={2}>
                {config.icon ?? null}
                <Body as="span" size="md" color={config.fontColor ?? 'white'}>
                    {config.title}
                </Body>
            </HStack>
        )
    }, [])

    const renderToast = useCallback(
        (config: Params) => {
            let bgColor, iconColor, icon

            switch (config.status) {
                case 'info':
                    bgColor = info.backgroundColor
                    iconColor = info.color
                    icon = <InfoIcon w={4} h={4} color={iconColor} />
                    break
                case 'warning':
                    bgColor = warning.backgroundColor
                    iconColor = warning.color
                    icon = <WarningTwoIcon w={4} h={4} color={iconColor} />
                    break
                case 'error':
                    bgColor = error.backgroundColor
                    iconColor = error.color
                    icon = <WarningIcon w={4} h={4} color={iconColor} />
                    break
                default:
                    bgColor = success.backgroundColor
                    iconColor = success.color
                    icon = <CheckCircleIcon w={4} h={4} color={iconColor} />
                    break
            }

            return toast.custom(
                <Flex
                    color="interface.dark1"
                    p={2}
                    pl={4}
                    bg={bgColor}
                    borderRadius="md"
                    border="1px"
                    borderColor={iconColor}
                    alignItems="center"
                    justifyContent="space-between"
                    minWidth="300px"
                >
                    <Box w="100%">
                        {renderBody({ ...config, icon: config.icon ?? icon, fontColor: iconColor })}
                    </Box>

                    <CloseButton
                        alignSelf="flex-start"
                        onClick={() => toast.dismiss()}
                        size="sm"
                        color={iconColor}
                        ml={2}
                    />
                </Flex>,
                {
                    position: config.position ?? 'top-right',
                    duration: config.duration,
                    style: {
                        color: 'white'
                    }
                }
            )
        },
        [renderBody, info, error, warning, success]
    )
    return { toast: renderToast, remove: toast.remove }
}

export { useToast }
