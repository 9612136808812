import { useGetMeetingPayload } from '@missionlabs/meetings'
import { useMemo } from 'react'

export const useMeetingTitle = () => {
    const { payload, isAcceptMeetingPayload, isJoinMeetingPayload } = useGetMeetingPayload(false)

    const title = useMemo(() => {
        if (isAcceptMeetingPayload(payload)) {
            const numOthers = Math.max((payload?.otherParticipants || []).length - 2, 0)
            const callerName = payload.caller?.displayName ?? payload.caller?.fullName ?? 'Unknown'
            const plusOthers = numOthers
                ? ` and ${numOthers} ${numOthers > 1 ? 'others' : 'other'}`
                : ''
            return `${callerName}${plusOthers}`
        }

        if (isJoinMeetingPayload(payload)) return payload.meetingTitle

        return ''
    }, [payload, isAcceptMeetingPayload, isJoinMeetingPayload])

    return title
}
