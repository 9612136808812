import { GridItem, Heading } from '@chakra-ui/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const ScheduleHeadingRow: FC = () => {
    const { t } = useTranslation()

    return (
        <>
            <GridItem>
                <Heading size="xs" variant="bold">
                    {t('calls.day')}
                </Heading>
            </GridItem>
            <GridItem>
                <Heading size="xs" variant="bold">
                    {t('calls.availability')}
                </Heading>
            </GridItem>
            <GridItem>
                <Heading size="xs" variant="bold">
                    {t('calls.time')}
                </Heading>
            </GridItem>
            <GridItem>
                <Heading size="xs" variant="bold">
                    {t('calls.from')}
                </Heading>
            </GridItem>
            <GridItem>
                <Heading size="xs" variant="bold">
                    {t('calls.to')}
                </Heading>
            </GridItem>
        </>
    )
}
