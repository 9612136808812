import { HStack } from '@chakra-ui/react'
import { Button } from '@missionlabs/react'
import { AddCircleIcon } from '@missionlabs/react/circleloop'
import { FacebookLoginProvider } from 'features/admin/pages/WhatsApp/hooks/useFacebookLogin'
import { WhatsAppLanding } from 'features/admin/pages/WhatsApp/WhatsAppLanding'
import { WhatsAppSettings } from 'features/admin/pages/WhatsApp/WhatsAppSettings'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetWhatsAppBusinessQuery } from 'shared/store'

export const WhatsApp: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { data: business, isLoading, isError } = useGetWhatsAppBusinessQuery()
    const hasBusiness = !!business && !isError

    return (
        <FacebookLoginProvider>
            <SettingsPage
                maxW="100%"
                title={t('admin.whatsapp.title')}
                subtitle={t('admin.whatsapp.subheading')}
                actions={
                    <HStack spacing="8px">
                        {hasBusiness && (
                            <Button
                                variant="creationary"
                                leftIcon={<AddCircleIcon boxSize="16px" />}
                                onClick={() => navigate('/admin/whatsapp/select')}
                            >
                                {t('admin.whatsapp.addNumber')}
                            </Button>
                        )}
                    </HStack>
                }
                isLoading={isLoading}
            >
                {hasBusiness && !isLoading && <WhatsAppSettings />}
                {!hasBusiness && !isLoading && <WhatsAppLanding />}
            </SettingsPage>
            <Outlet />
        </FacebookLoginProvider>
    )
}
