import { Button, HStack, Link as ALink, Spacer, VStack } from '@chakra-ui/react'
import {
    Body,
    FormProvider,
    Input,
    useToast,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { PostWhatsAppNumberRequest } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { usePostWhatsAppNumberMutation } from 'shared/store'

export interface AddWhatsAppNumbersFormProps {
    isLoading?: boolean
    onClose: () => void
}

export const AddWhatsAppNumbersForm: FC<AddWhatsAppNumbersFormProps> = ({ onClose }) => {
    const { t } = useTranslation()
    const { toast } = useToast()
    const { id: numberID = '' } = useParams()

    const [createNumber, { isLoading }] = usePostWhatsAppNumberMutation()

    const values: PostWhatsAppNumberRequest = {
        name: '',
        numberID,
        pin: ''
    }

    async function onSubmit(data: PostWhatsAppNumberRequest) {
        try {
            await createNumber(data).unwrap()
            toast({
                title: t('admin.whatsapp.add.success'),
                status: 'success'
            })
            onClose()
        } catch (error) {
            console.error('Error creating number', error)
            toast({
                title: t('admin.whatsapp.add.error'),
                status: 'error'
            })
        }
    }

    const addNumberSchema = yup.object({
        numberID: yup.string().required(),
        name: yup.string().required(),
        pin: yup
            .string()
            .required()
            .matches(/^\d{6}$/, 'Pin must be exactly 6 digits')
    })

    const methods = useYupForm<PostWhatsAppNumberRequest>(addNumberSchema, {
        values
    })
    const {
        formState: { isDirty }
    } = methods

    return (
        <FormProvider {...methods}>
            <VStack
                as="form"
                h="full"
                align="stretch"
                spacing="16px"
                padding="24px"
                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <VStack align="start">
                    <ValidatedInput
                        name="name"
                        label={t('admin.whatsapp.add.fields.name.name')}
                        mb={2}
                    >
                        <Input />
                    </ValidatedInput>
                    <Body mb={2}>
                        {t('admin.whatsapp.add.fields.name.description')}{' '}
                        <ALink
                            href="https://www.facebook.com/business/help/338047025165344#display-name-guidelines"
                            isExternal
                        >
                            {t('admin.whatsapp.add.fields.name.link')}
                        </ALink>
                    </Body>
                    <ValidatedInput name="pin" label={t('admin.whatsapp.add.fields.pin.name')}>
                        <Input type="password" />
                    </ValidatedInput>
                </VStack>
                <Spacer />
                <HStack spacing="16px" align="inherit">
                    <Button
                        w="full"
                        size="lg"
                        variant="secondary"
                        isDisabled={isLoading}
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        type="submit"
                        w="full"
                        size="lg"
                        variant="creationary"
                        isDisabled={!isDirty}
                        isLoading={isLoading}
                    >
                        {t('admin.whatsapp.add.fields.submit')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}
