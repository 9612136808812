import { Box, VStack } from '@chakra-ui/react'
import { ListTitle } from '@missionlabs/react'
import { DirectoryEntry, UserActivity } from '@missionlabs/types'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetUserActivities } from 'shared/hooks/useGetUserActivities'
import { ScrollDirection } from 'shared/types/feed'
import { getDirectionOfActivity, sortActivities } from 'shared/utils/activities'

import { RecentActivityRow } from './RecentActivityRow'

type RecentActivitiesProps = {
    handleActivityClick: (numberE164: string, contact?: DirectoryEntry) => void
    siblingHeight?: number
}

export type UserActivityItem = {
    activity: UserActivity
    onClick: (numberE164: string, contact?: DirectoryEntry) => void
}

const ACTIVITY_ARGS = {
    directories: [],
    directions: [],
    type: ['missed_calls', 'voice_calls']
}

/**
 * Given a list of activities, return a list of activities with unique contacts.
 * Unique contacts are determined by originating/dialled number for inbound/outbound calls.
 */
function getUniqueActivities(activities: UserActivity[], limit = 10): UserActivity[] {
    const uniqueContacts = new Set<string>()
    const uniqueActivities: UserActivity[] = []

    for (const activity of activities) {
        const contactNumber =
            getDirectionOfActivity(activity) === 'inbound'
                ? activity.originatingNumber
                : activity.dialledNumber

        if (!uniqueContacts.has(contactNumber)) {
            uniqueContacts.add(contactNumber)
            uniqueActivities.push(activity)
        }

        if (uniqueActivities.length === limit) break
    }
    return uniqueActivities
}

export const RecentActivities: FC<RecentActivitiesProps> = ({ handleActivityClick }) => {
    const { t } = useTranslation()

    const { all } = useGetUserActivities(ACTIVITY_ARGS)
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1)

    const filteredActivities: UserActivityItem[] = useMemo(() => {
        const sortedActivities = sortActivities(all, ScrollDirection.DOWN)
        return getUniqueActivities(sortedActivities, 10).map(activity => ({
            activity,
            onClick: handleActivityClick
        }))
    }, [all, handleActivityClick])

    return (
        <VStack spacing={0} w="100%" align="stretch" overflow="hidden">
            <ListTitle color="white">{t('Recent')}</ListTitle>
            <Box w="full" px="24px">
                {filteredActivities.map((activity, index) => (
                    <RecentActivityRow
                        key={activity.activity.ID}
                        item={activity}
                        index={index}
                        hoveredIndex={hoveredIndex}
                        setHoveredIndex={setHoveredIndex}
                    />
                ))}
            </Box>
        </VStack>
    )
}
