import { ComponentStyleConfig } from '@chakra-ui/react'

const handleSelector = '.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle'
const activeHandleSelector = `${handleSelector}:hover, ${handleSelector}.active`

export const ScrollableStyles: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        [handleSelector]: {
            width: '0.4rem',
            maxHeight: '100px',
            background: `${colorMode}.tones.periwinkle`
        },
        [activeHandleSelector]: {
            width: '0.6rem',
            background: `${colorMode}.tones.stormGrey`
        }
    })
}
