import { Collapse } from '@chakra-ui/react'
import { SettingsCheckboxList } from 'features/settings/components/SettingsCheckboxList'
import { useSettingsContext } from 'features/settings/components/settingsContext'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { SettingSwitch } from 'features/settings/components/SettingsSwitch'
import { SettingsTitle } from 'features/settings/components/SettingsTitle'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export interface EmailProps {}

export const Email: FC<EmailProps> = () => {
    const { t } = useTranslation()
    const { channels, events, onUpdateUser } = useSettingsContext()

    const handleChannel = useCallback(
        (value: boolean) => {
            const updatedChannels = { ...channels, email: value }
            onUpdateUser({ notifications: { channels: updatedChannels, events } })
        },
        [channels, onUpdateUser, events]
    )

    const handleEvent = useCallback(
        (event: string) => {
            const updatedEvents = [...events]
            if (events.includes(event)) {
                const keyIndex = events.indexOf(event)
                updatedEvents.splice(keyIndex, 1)
            } else updatedEvents.push(event)

            onUpdateUser({ notifications: { channels, events: updatedEvents } })
        },
        [channels, events, onUpdateUser]
    )

    return (
        <>
            <SettingsTitle label={t('Email')} />
            <SettingsRow label={t('notifications.email')}>
                <SettingSwitch
                    label={t('notifications.send')}
                    isChecked={channels.email}
                    onUpdate={handleChannel}
                />
                <div />
                <Collapse in={channels.email} startingHeight="0px" unmountOnExit>
                    <SettingsCheckboxList
                        options={[
                            {
                                ID: 'call_missed',
                                label: t('notifications.callsIncMissed'),
                                isChecked: events.includes('call_missed')
                            },
                            {
                                ID: 'message_received',
                                label: t('notifications.voicemails'),
                                isChecked: events.includes('message_received')
                            },
                            {
                                ID: 'chat_message_received',
                                label: t('notifications.sms'),
                                isChecked: events.includes('chat_message_received')
                            }
                        ]}
                        onClick={handleEvent}
                    />
                </Collapse>
            </SettingsRow>
        </>
    )
}
