import { UserActivity } from '@missionlabs/types'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Features, useHasClientFeature } from 'shared/hooks/useHasClientFeature'
import { useActivityContactEntity } from 'shared/hooks/useRelatedActivities'
import { WhatsAppSendMessageProvider } from 'shared/hooks/useWhatsAppSendMessage'

import { ActivityFeedMain } from './ActivityFeedMain'
import { AddNumberToContactDrawerProvider } from './components/AddNumberToContact/useAddNumberToContactDrawer'
import { ActivityFooterProvider } from './hooks/useActivityFooterState'

export const ActivityFeed = () => {
    const { state } = useLocation()

    const activity = useMemo(() => state?.activity as UserActivity | undefined, [state])

    const entity = useActivityContactEntity(activity)

    const hasWhatsApp = useHasClientFeature(Features.whatsapp)

    return (
        <ActivityFooterProvider>
            <AddNumberToContactDrawerProvider num={(entity as any)?.number}>
                {hasWhatsApp ? (
                    <WhatsAppSendMessageProvider>
                        <ActivityFeedMain />
                    </WhatsAppSendMessageProvider>
                ) : (
                    <ActivityFeedMain />
                )}
            </AddNumberToContactDrawerProvider>
        </ActivityFooterProvider>
    )
}
