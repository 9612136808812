import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PlaceholderIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PlaceholderIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M14 18.5C14 17.6716 13.3284 17 12.5 17C11.6716 17 11 17.6716 11 18.5C11 19.3284 11.6716 20 12.5 20C13.3284 20 14 19.3284 14 18.5Z"
                fill="currentColor"
            />
            <path
                d="M13.9923 11.8466C13.9154 11.0903 13.2766 10.5 12.5 10.5C11.7234 10.5 11.0846 11.0903 11.0077 11.8466C11.0026 11.8971 11 11.9482 11 12C11 12.8284 11.6716 13.5 12.5 13.5C12.5032 13.5 12.5065 13.5 12.5097 13.5C13.3336 13.4948 14 12.8252 14 12C14 11.9482 13.9974 11.8971 13.9923 11.8466Z"
                fill="currentColor"
            />
            <path
                d="M14 5.5C14 4.67157 13.3284 4 12.5 4C11.6716 4 11 4.67157 11 5.5C11 6.32843 11.6716 7 12.5 7C13.3284 7 14 6.32843 14 5.5Z"
                fill="currentColor"
            />
            <path
                d="M6 13.5C6.8284 13.5 7.5 12.8284 7.5 12C7.5 11.1716 6.8284 10.5 6 10.5C5.1716 10.5 4.5 11.1716 4.5 12C4.5 12.8284 5.1716 13.5 6 13.5Z"
                fill="currentColor"
            />
            <path
                d="M19 13.5C19.8284 13.5 20.5 12.8284 20.5 12C20.5 11.1716 19.8284 10.5 19 10.5C18.1716 10.5 17.5 11.1716 17.5 12C17.5 12.8284 18.1716 13.5 19 13.5Z"
                fill="currentColor"
            />
        </g>
    )
})
