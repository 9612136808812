import { APP_BRAND, APP_SOURCE } from './env'

export const mapLegacyContactSource = (source: string) => {
    const map = {
        CircleLoop: APP_SOURCE.CIRCLELOOP,
        app: APP_SOURCE.CIRCLELOOP,
        'PhoneLine+': APP_SOURCE.PHONELINEPLUS
    }

    const mappedSource = map[source]

    return mappedSource ?? source
}

export const prettifySource = (source?: string) => {
    if (!source) return

    const map: Record<string, string> = {
        circleloop: APP_BRAND.CIRCLELOOP,
        app: APP_BRAND.CIRCLELOOP,
        'phoneline+': APP_BRAND.PHONELINEPLUS
    }

    const mappedSource = map[source]

    return mappedSource ?? source
}
