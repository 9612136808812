import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const CalendarIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CalendarIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M7.41667 1C6.91041 1 6.5 1.41041 6.5 1.91667V4.66667C6.5 5.17293 6.91041 5.58333 7.41667 5.58333C7.92293 5.58333 8.33333 5.17293 8.33333 4.66667V1.91667C8.33333 1.41041 7.92293 1 7.41667 1Z"
                fill="currentColor"
            />
            <path
                d="M5.58333 4.66667V3.75H2.83333C1.82081 3.75 1 4.57081 1 5.58333V9.25H23V5.58333C23 4.57081 22.1792 3.75 21.1667 3.75H18.4167V4.66667C18.4167 5.67919 17.5959 6.5 16.5833 6.5C15.5708 6.5 14.75 5.67919 14.75 4.66667V3.75H9.25V4.66667C9.25 5.67919 8.42919 6.5 7.41667 6.5C6.40414 6.5 5.58333 5.67919 5.58333 4.66667Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 10.1667H23V21.1667C23 22.1792 22.1792 23 21.1667 23H2.83333C1.82081 23 1 22.1792 1 21.1667V10.1667ZM9.57853 15.6917H8.88924V16.7033H9.57406C10.2902 16.7033 10.6483 17.0285 10.6483 17.679C10.6483 17.9476 10.5602 18.1639 10.3842 18.328C10.2111 18.4892 9.97092 18.5697 9.66357 18.5697C9.39502 18.5697 9.16824 18.4922 8.98324 18.337C8.80122 18.1818 8.71021 17.9819 8.71021 17.7372H7.41667C7.41667 18.3012 7.62256 18.7547 8.03434 19.0979C8.44911 19.4411 8.98174 19.6126 9.63224 19.6126C10.3275 19.6126 10.8855 19.4381 11.3062 19.0889C11.73 18.7398 11.9418 18.2758 11.9418 17.6969C11.9418 17.3389 11.8508 17.0285 11.6688 16.766C11.4897 16.5034 11.2167 16.3064 10.8497 16.1751C11.1451 16.0409 11.3838 15.8484 11.5658 15.5977C11.7479 15.3471 11.8389 15.0726 11.8389 14.7742C11.8389 14.1953 11.6434 13.7417 11.2525 13.4135C10.8616 13.0823 10.3215 12.9167 9.63224 12.9167C9.23538 12.9167 8.87134 12.9928 8.54012 13.1449C8.21189 13.2971 7.95527 13.509 7.77026 13.7805C7.58824 14.0491 7.49723 14.3534 7.49723 14.6936H8.79077C8.79077 14.4758 8.87432 14.2997 9.04142 14.1654C9.20852 14.0282 9.41591 13.9596 9.66357 13.9596C9.9381 13.9596 10.1529 14.0327 10.3081 14.1789C10.4663 14.3251 10.5453 14.5295 10.5453 14.7921C10.5453 15.0636 10.4663 15.2814 10.3081 15.4456C10.15 15.6097 9.90677 15.6917 9.57853 15.6917ZM14.6855 19.5231H15.9791V12.9972H15.8403L13.1414 13.964V15.0159L14.6855 14.5369V19.5231Z"
                fill="currentColor"
            />
            <path
                d="M15.6667 1.91667C15.6667 1.41041 16.0771 1 16.5833 1C17.0896 1 17.5 1.41041 17.5 1.91667V4.66667C17.5 5.17293 17.0896 5.58333 16.5833 5.58333C16.0771 5.58333 15.6667 5.17293 15.6667 4.66667V1.91667Z"
                fill="currentColor"
            />
        </g>
    )
})
