import { useDisclosure } from '@chakra-ui/react'
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    useToast
} from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCreateUserMutation } from 'shared/store'

import { AddUserForm, AddUserFormFields } from './AddUserForm'

const noop = () => {}

export const AddUserDrawer: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const { toast } = useToast()
    const [createUser, { isLoading }] = useCreateUserMutation()

    function handleTransition() {
        navigate('/admin/users')
    }

    const onSubmit = async (data: AddUserFormFields) => {
        try {
            const response = await createUser({
                fullName: `${data.firstName} ${data.lastName}`,
                ...data
            }).unwrap()

            if (response) onClose()
        } catch (error) {
            toast({
                title: t('admin.users.errorCreating'),
                status: 'error'
            })
        }
    }

    return (
        <Drawer
            size="lg"
            isOpen={isOpen}
            onClose={!isLoading ? onClose : noop}
            onCloseComplete={handleTransition}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>{t('admin.users.add')}</DrawerHeader>
                <DrawerBody>
                    <AddUserForm onSubmit={onSubmit} onClose={onClose} isLoading={isLoading} />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
