import { Grid, GridItem, useColorMode, VStack } from '@chakra-ui/react'
import {
    call as ml_call,
    selectAuthenticatedUser,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { Avatar, Body, IconButton, ListTitle } from '@missionlabs/react'
import { SmartphoneInIconSolid } from '@missionlabs/react/zeta'
import { DecoratedCall } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useCallDetails } from '../../../shared/hooks/useCallDetails'
import { useFindContact } from '../../../shared/hooks/useFindContact'

interface CallPullProps {
    call: DecoratedCall
}

export const CallPull: FC<CallPullProps> = ({ call }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const { contact } = useFindContact(
        call.direction === 'outbound' ? call.dialledNumber : call.originatingNumber
    )

    const user = useSelector(selectAuthenticatedUser)
    const { team } = useCallDetails(call)

    const pull = () => {
        if (!user?.extension) return
        dispatch(ml_call({ to: '*11', from: user.extension }))
    }

    return (
        <VStack spacing={0} w="100%" align="stretch" overflow="hidden">
            <ListTitle color="white">{t('Call Pull')}</ListTitle>
            <Grid
                templateColumns="repeat(12, 1fr)"
                p="16px 8px"
                borderBottom="1px solid"
                borderTop="1px solid"
                borderColor={`${colorMode}.tones.periwinkle`}
            >
                <GridItem colSpan={1} paddingLeft={2}>
                    <Avatar size="sm" name={contact?.name || '?'} />
                </GridItem>
                <GridItem colSpan={10} paddingLeft={5}>
                    <VStack spacing="0" align="flex-start">
                        {contact ? (
                            <>
                                <Body
                                    sx={{ color: `${colorMode}.tones.periwinkle` }}
                                    variant="bold"
                                >
                                    {contact?.name}
                                </Body>
                                <Body sx={{ color: `${colorMode}.tones.periwinkle` }} size="sm">
                                    {contact?.companyName}
                                </Body>
                            </>
                        ) : (
                            <Body sx={{ color: `${colorMode}.tones.periwinkle` }} variant="bold">
                                {call.direction === 'outbound'
                                    ? call.dialledNumber
                                    : call.originatingNumber}
                            </Body>
                        )}
                        {team && (
                            <Body sx={{ color: `${colorMode}.tones.periwinkle` }} size="sm">
                                {`${t('call.via')}: ${team}`}
                            </Body>
                        )}
                    </VStack>
                </GridItem>
                <GridItem colSpan={1} paddingRight={3}>
                    <IconButton
                        aria-label="pull call"
                        icon={<SmartphoneInIconSolid />}
                        variant="creationary"
                        size="sm"
                        isRounded
                        onClick={pull}
                    />
                </GridItem>
            </Grid>
        </VStack>
    )
}
