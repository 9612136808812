import { selectActiveMeeting, useSelector } from '@missionlabs/api'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useEffect, useState } from 'react'

dayjs.extend(duration)

export const useMeetingDuration = () => {
    const meeting = useSelector(selectActiveMeeting)

    const [elapsedTime, setElapsedTime] = useState<string>('00:00')

    useEffect(() => {
        if (!meeting?.startTime) return

        const interval = setInterval(() => {
            const start = dayjs(meeting.startTime)
            const duration = dayjs.duration(dayjs().diff(start))
            setElapsedTime(duration.format('mm:ss'))
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [meeting])

    return elapsedTime
}
