import { ComponentStyleConfig, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
    'container',
    'button',
    'item',
    'icon'
])

export const SelectStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: definePartsStyle(({ colorMode }) => ({
        container: defineStyle({
            border: '1px solid',
            borderColor: `${colorMode}.tones.periwinkle`,
            borderRadius: 'none',
            boxShadow: 'md',
            padding: '12px'
        }),
        button: defineStyle({
            pl: '24px',
            pr: '16px',
            py: '12px',
            borderBottom: '1px',
            borderColor: `${colorMode}.tones.periwinkle`,
            color: `${colorMode}.tones.stormGrey`,
            letterSpacing: '-0.02em',
            lineHeight: '28.16px',
            fontFamily: 'heading',
            fontSize: '16px',
            fontWeight: 800,
            _active: {
                color: `${colorMode}.tones.midnightExpress`
            },
            _focus: {
                bg: `${colorMode}.tones.whiteLilac`,
                color: `${colorMode}.tones.midnightExpress`
            },
            _hover: {
                bg: `${colorMode}.tones.whiteLilac`,
                color: `${colorMode}.tones.midnightExpress`
            }
        }),
        item: defineStyle({
            pl: '12px',
            pr: '16px',
            py: '9.5px',
            borderRadius: '8px',
            fontFamily: 'body',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '21px',
            color: `${colorMode}.tones.midnightExpress`,
            _active: {
                bg: `${colorMode}.tones.whiteLilac`,
                outline: '1px solid',
                outlineColor: `${colorMode}.tones.periwinkle`,
                outlineOffset: -1
            },
            _focus: {
                bg: `${colorMode}.tones.whiteLilac`,
                outline: '1px solid',
                outlineColor: `${colorMode}.tones.periwinkle`,
                outlineOffset: -1
            },
            _hover: {
                bg: `${colorMode}.tones.whiteLilac`
            }
        }),
        icon: defineStyle({
            boxSize: '24px',
            padding: '8px 5px',
            color: `${colorMode}.tones.navy`
        })
    })),
    variants: {
        dark: definePartsStyle(({ colorMode }) => ({
            container: defineStyle({
                backgroundColor: `${colorMode}.tones.vantaBlue`,
                borderColor: `${colorMode}.tones.stormGrey`
            }),
            item: defineStyle({
                backgroundColor: `${colorMode}.tones.vantaBlue`,
                color: `${colorMode}.tones.white`,
                _active: {
                    color: `${colorMode}.tones.midnightExpress`
                },
                _focus: {
                    color: `${colorMode}.tones.midnightExpress`
                },
                _hover: {
                    color: `${colorMode}.tones.midnightExpress`
                }
            })
        }))
    }
})
