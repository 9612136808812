import { useDispatch } from '@missionlabs/api'
import { useCallback } from 'react'
import { addPresenceID } from 'shared/slices/presenceSlice'

export const useUpdatePresence = (userID?: string) => {
    const dispatch = useDispatch()

    const updatePresence = useCallback(
        (isIntersecting: boolean) => {
            if (!userID) return
            if (isIntersecting) dispatch(addPresenceID({ userID }))
        },
        [userID, dispatch]
    )

    return { updatePresence: userID ? updatePresence : undefined }
}
