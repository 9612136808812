import { MeetingRecording, MeetingRecordingStatus } from '@missionlabs/api'
import { IconButton, IconButtonProps } from '@missionlabs/react'
import {
    PauseIconSolid,
    PlayIconSolid,
    StopCircleIconSolid,
    StopIconSolid
} from '@missionlabs/react/zeta'
import { FC } from 'react'

export interface RecordingControlProps extends Omit<IconButtonProps, 'aria-label' | 'onClick'> {
    recording?: MeetingRecording
    /** The labels that will be shown for video input control */
    labels?: Record<MeetingRecordingStatus, string>
    onClick: (status: MeetingRecordingStatus) => void
}

// todo: new recording icon
const RecordingControl: FC<RecordingControlProps> = ({
    recording,
    labels = {
        RECORDING: 'Recording, Click to pause',
        STARTED: 'Click to record',
        PAUSED: 'Recording paused'
    },
    onClick,
    ...props
}) => {
    const status = recording?.status ?? MeetingRecordingStatus.STARTED

    function renderIcon() {
        if (!recording) return <StopCircleIconSolid />
        switch (recording.status) {
            case MeetingRecordingStatus.PAUSED:
                return <PauseIconSolid />
            case MeetingRecordingStatus.RECORDING:
            case MeetingRecordingStatus.STARTED:
                return <PlayIconSolid />
            case MeetingRecordingStatus.ENDED:
                return <StopIconSolid />
        }
    }

    return (
        <IconButton
            {...props}
            variant="negatory"
            isRounded
            title={labels[status]}
            onClick={() => onClick(status)}
            aria-label={labels[status] ?? ''}
            data-testid="video-recording-control"
        >
            {renderIcon()}
        </IconButton>
    )
}

export default RecordingControl
