import { RadioGroup, Stack, useColorMode } from '@chakra-ui/react'
import { Body, Radio } from '@missionlabs/react'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface SoundRingtoneSettingProps {
    value: string
    onChange: (ringtone: string) => void
}

export const SoundRingtoneSetting: FC<SoundRingtoneSettingProps> = ({ value, onChange }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    return (
        <SettingsRow label={t('settings.sounds.ringtone')}>
            <Body size="sm" variant="bold" sx={{ color: `${colorMode}.tones.navy` }}>
                {t('settings.sounds.chooseRingtone')}
            </Body>
            <div />
            <RadioGroup value={value} onChange={onChange}>
                <Stack spacing={2}>
                    <Radio value="office">{t('settings.sounds.office')}</Radio>
                    <Radio value="xylophone">{t('settings.sounds.xylophone')}</Radio>
                    <Radio value="modern">{t('settings.sounds.modern')}</Radio>
                </Stack>
            </RadioGroup>
        </SettingsRow>
    )
}
