import { useModalContext, useModalStyles } from '@chakra-ui/react'
import { callAllHandlers, cx } from '@chakra-ui/shared-utils'
import { IconButton, IconButtonProps } from 'atoms'
import { XIconRegular } from 'atoms/Icons/zeta'
import { FC, MouseEvent } from 'react'

export type DialogCloseButtonProps = Omit<IconButtonProps, 'aria-label'>

export const DialogCloseButton: FC<DialogCloseButtonProps> = ({ className, onClick, ...props }) => {
    const { onClose } = useModalContext()
    const { closeButton } = useModalStyles()

    const _className = cx('chakra-modal__close-btn', className)

    return (
        <IconButton
            size="md"
            variant="transparent"
            {...props}
            __css={closeButton}
            aria-label="Close button"
            className={_className}
            onClick={callAllHandlers(onClick, (event: MouseEvent) => {
                event.stopPropagation()
                onClose()
            })}
        >
            <XIconRegular boxSize="24px" p="6.4px" />
        </IconButton>
    )
}
