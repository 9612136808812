import {
    CallMonitoringEvent,
    DirectoryEntry,
    EventType,
    MediaDevice,
    SingleState,
    TeamVmNotificationPayload
} from '@missionlabs/types'

import { CallStatsController } from './CallStatsController'
import { LiveServices, LiveServicesStatus } from './LiveServices'
import LiveServicesSignaller, { CallDetails, NWayMemberEvent } from './LiveServicesSignaller'
import Logger from './Logger'
import { generateCallTraceID } from './util'
import webrtc, { webrtcConfig } from './webrtc'

export type ParkedCall = {
    callTraceID: string
    parked: {
        remoteNumber: string
        contactName?: string
        companyName?: string
    }
    parker: {
        remoteNumber: string
        contactName?: string
        companyName?: string
        teamName?: string
    }
}

export type DeviceCaptureLevel = {
    [deviceId: string]: number
}

export type AdvancedSettingPreferences = {
    autoGainControl?: boolean
    echoCancellation?: boolean
    noiseSuppression?: boolean
}

export type UserPreferences = {
    advancedSettings?: AdvancedSettingPreferences
    audioVolume?: number
    autoAnswer?: boolean
    autoAnswerDuration?: number
    autoLogin?: boolean
    callWaiting?: boolean
    cameraDeviceID?: string
    distinctiveRingExternal?: boolean
    inputCaptureLevel?: DeviceCaptureLevel
    inputDeviceID?: string
    noiseCancellation?: boolean
    outputDeviceID?: string
    ringTone?: string
    ringingOutputDeviceID?: string
    ringingVolume?: number
    videoBackgroundFilter?: string
    videoNoiseSuppression?: boolean
}

export type RecordingMode =
    | 'always'
    | 'never'
    | 'always-pause-resume'
    | 'on-demand'
    | 'on-demand-user-start'

export type CallStatus =
    | 'NO_CALL'
    | 'CONNECTED'
    | 'RINGING_LOCAL'
    | 'TRANSFERRING'
    | 'START_CALL'
    | 'ANSWERED'
    | 'RINGING_REMOTE'
    | 'TRANSFERRED'
    | 'ON_HOLD'
    | 'CALL_WAITING'

export type CallState = CallDetails & {
    status: CallStatus
}

type Status = {
    [key: string]: CallStatus
}

export type AudioConfig = {
    deviceId: { ideal: string }
    noiseSuppression?: boolean
}
export type CallControllerConfig = {
    session?: {}
    sounds?: {
        ringTone: string
        ringTone2: string
        callWaitingTone: string
        dtmf0: string
        dtmf1: string
        dtmf2: string
        dtmf3: string
        dtmf4: string
        dtmf5: string
        dtmf6: string
        dtmf7: string
        dtmf8: string
        dtmf9: string
        dtmfStar: string
        dtmfHash: string
        videoRingTone: string
    }
    turn: string
    turnConfig?: {
        username: string
        password: string
    }
    bandwidthLimit?: number
    audio?: AudioConfig
    preferCodec?: string
    forbiddenCodecs?: string[]
    disableDSCP?: boolean
    enableRecording?: boolean
    goog?: boolean
    appPublicKey?: string
    sw?: string
    swoptions?: {}
    notifications?: boolean
    autoAnswer?: boolean
    autoAnswerDuration?: number
    preventCallStats?: boolean
}

export class CallController {
    ls: LiveServices
    status: Status
    connected: boolean
    incall: boolean
    calling: boolean
    config: CallControllerConfig
    candidates: any[]
    callDetails: any
    signaller: LiveServicesSignaller
    remoteOffer: { [key: string]: any }
    callStats?: any
    wrtc?: webrtc[]
    swRegistration?: any
    autoAnswerTimeout?: ReturnType<typeof setTimeout>
    parkedCalls: ParkedCall[]

    oncallstart = (_calldetails: CallState) => {}
    oncallend = (_calldetails: CallState) => {}
    attachstream = (_stream: any, _callTraceID: string) => {}
    onstatuschange = (_calldetails: CallState) => {}
    ondisconnect = (..._args: any) => {}
    onreconnect = (..._args: any) => {}
    onincomingcall = (_calldetails: CallState, _callWaiting?: boolean) => {}
    oncallerror = (..._args: any) => {}
    oncalling = (_calldetails?: CallState) => {}
    onbusy = (..._args: any) => {}
    oninvalidnumber = (..._args: any) => {}
    onconnectionerror = (..._args: any) => {}
    onpermissionserror = (..._args: any) => {}
    onsupporterror = (..._args: any) => {}
    onsubscribe = (..._args: any) => {}
    onunsubscribe = (..._args: any) => {}
    onsubscribeerror = (..._args: any) => {}
    ontransferenabled = (..._args: any) => {}
    oncallqualityreport = (..._args: any) => {}
    onrecordingfinished = (..._args: any) => {}
    onexternalcall = (..._args: any) => {}
    oncallparkevent = (_parkedCalls: ParkedCall[]) => {}
    onrecordingmodechanged = (_recordingMode: RecordingMode) => {}
    oncallgroupvoicemail = (_voicemail: TeamVmNotificationPayload) => {}
    onsubscriptionupdated = (..._args: any) => {}
    onLSstatuschange = (_status: LiveServicesStatus) => {}
    onpresencesubscriptionchanged = (_users: { users: SingleState[] }) => {}
    oncallmonitoringevent = (_event: CallMonitoringEvent) => {}

    constructor(
        ls: LiveServices,
        config: CallControllerConfig,
        private userID = '',
        private clientID = '',
        private env = '',
        private brand = '',
        private locale = ''
    ) {
        this.ls = ls
        this.signaller = new LiveServicesSignaller(ls)
        this.connected = true
        this.incall = false
        this.calling = false
        this.config = config
        this.candidates = []
        this.parkedCalls = []
        this.callDetails = null
        this._initCallStats(config)
        this._initSignaller()
        this._initLsEvents()

        this.remoteOffer = {}
        this.status = {}
    }

    getWrtc(callTraceID?: string) {
        return this.wrtc?.find(wrtc => wrtc.callTraceID === callTraceID)
    }
    updateWrtcDevices(devices: MediaDevice[]) {
        this.wrtc?.forEach(wrtc => wrtc.updateDeviceList(devices))
    }

    private _initLsEvents() {
        this.ls.onstatuschange = (status: LiveServicesStatus) => {
            this.onLSstatuschange(status)
        }
    }

    private _initCallStats(config) {
        this.callStats = new CallStatsController(
            Logger,
            config,
            this.userID,
            this.clientID,
            this.env,
            this.brand,
            this.locale
        )
        this.callStats.onInboundMediaFlatline = () => {}

        this.callStats.onCallQualityReport = report => {
            // Logger.log('info', 'call quality reported as ' + (report && report.quality))
            this.oncallqualityreport(report)
        }
    }

    private _initSignaller() {
        if (this.config.session) {
            //this is so we can load a previous session from service worker
            Logger.log('info', 'loading session from service worker', this.config.session)
            // this.signaller.handleID = this.config.session.handleID
            // this.signaller.sessionID = this.config.session.sessionID
            // this.signaller.calldetails = this.config.session.calldetails
        }

        this.signaller.onready = (callTraceID: string) => {
            if (!this.candidates.length) return
            //send ice candidates
            this.candidates.forEach(candidate => {
                this.signaller.sendICECandidate(callTraceID, candidate)
            })
            this.candidates = []
            this.signaller.sendICEComplete(callTraceID)
        }

        this.signaller.ondisconnect = () => {
            this.connected = false
            this.signaller.cs?.forEach(cs => {
                const status = this.getStatus(cs.callTraceID)
                if (status === 'RINGING_REMOTE' || status === 'NO_CALL')
                    this.endCall(cs.callTraceID)
            })
            this.ondisconnect()
        }

        this.signaller.onreconnect = (callTraceID: string) => {
            this.connected = true
            const wrtc = this.getWrtc(callTraceID)
            if (!wrtc?.pc) return
            wrtc?.createOffer(true)
            this.onreconnect()
        }

        this.signaller.onremoteoffer = (callTraceID, offer) => {
            this.remoteOffer[callTraceID] = offer
            Logger.log('debug', 'received offer', offer, 'WEBSOCKET_OFFER')
            Logger.putEvent('call/webRTCOffer/receive', null, 'Received a webRTCOffer from remote')
        }

        this.signaller.onremoteanswer = (callTraceID: string, desc) => {
            const wrtc = this.getWrtc(callTraceID)
            if (wrtc) {
                wrtc.setRemoteDescription(desc)
                Logger.log('debug', 'received answer', desc, 'WEBSOCKET_ANSWER')
                Logger.putEvent(
                    'call/webRTCAnswer/receive',
                    null,
                    'Received a webRTCAnswer from remote'
                )
            } else {
                this.signaller.hangup(callTraceID)
            }
        }

        this.signaller.onwebrtcup = (callTraceID: string) => {
            Logger.log('debug', 'onwebrtcup', null, 'WEBSOCKET_WEBRTCUP')
            Logger.putEvent('call/connect', null, 'App saw the call connect (call started)')

            const callDetails = this.getCallDetails(callTraceID)
            // TODO: Handle no callDetails
            if (!callDetails) return

            let status: CallStatus = 'CONNECTED'
            if (callDetails.isTransfer && callDetails.transferType === 'attended') {
                status = 'TRANSFERRING'
            }

            this.setStatus(callTraceID, status)
            this.oncallstart({ ...callDetails, status: this.getStatus(callTraceID) })
        }

        this.signaller.oncallstatuschange = (callTraceID: string, status: CallStatus) => {
            this.setStatus(callTraceID, status)
        }
        this.signaller.onstatuschange = (callTraceID: string, callDetails: CallDetails) => {
            this.onstatuschange({
                ...callDetails,
                status: this.getStatus(callTraceID)
            })
        }

        this.signaller.oncalling = (callTraceID: string) => {
            this.setStatus(callTraceID, 'RINGING_REMOTE')
            Logger.log('trace', 'call all setup', null, 'UI_RINGING')
            Logger.putEvent('call/ringing/receive', null, 'App received remove ringing notif.')

            const callDetails = this.getCallDetails(callTraceID)
            if (callDetails) this.oncalling({ ...callDetails, status: this.getStatus(callTraceID) })
        }

        this.signaller.onincomingcall = calldetails => {
            this.signaller.sendExternalMessage({ ...calldetails, event: 'incoming_call' })

            if (calldetails) {
                this.onincomingcall(
                    { ...calldetails, status: 'RINGING_LOCAL' },
                    this.signaller.cs && this.signaller.cs.length > 1
                )
                this.setStatus(calldetails?.callTraceID, 'RINGING_LOCAL')
                Logger.log('trace', 'call all setup', null, 'UI_RINGING')

                if (calldetails.autoAnswer && this.signaller.cs && this.signaller.cs?.length <= 1) {
                    this.answerCall(calldetails.callTraceID)
                }
            }

            if (
                !!this.config.autoAnswer &&
                !!calldetails?.callTraceID &&
                !!this.signaller.cs &&
                this.signaller.cs.length <= 1
            ) {
                this.autoAnswerTimeout = this.setupAutoAnswer(
                    calldetails.callTraceID,
                    this.config.autoAnswerDuration ?? 10000
                )
            }
        }

        this.signaller.onhangup = (ev, calldetails) => {
            const wrtc = this.getWrtc(calldetails?.callTraceID)
            if (wrtc) wrtc.close()
            switch (ev.code) {
                case 200:
                    console.log('normal hangup - 200')
                    break
                case 486:
                    this.onbusy()
                    break
                case 404:
                case 403:
                    this.oninvalidnumber()
                    break
                case 500:
                    this.onconnectionerror()
                    break
                default:
                    console.log('unknown hangup')
                    break
            }
            if (this.getStatus(calldetails?.callTraceID) === 'NO_CALL') {
                //Looks like janus sometimes is sending us multiple hangups for the same call.
                console.log('Hangup received when already in status NO_CALL.. doing nothing')
                return
            }
            Logger.putEvent(
                'call/hangup/receive',
                { hangupCode: ev.code },
                'The app received a hangup'
            )
            this._callend({ remoteHangupCode: ev.code }, calldetails)
        }

        this.signaller.onerror = () => {
            this.onconnectionerror()
        }

        this.signaller.ontransferenabled = () => {
            Logger.putEvent(
                'call/transferEnable/receive',
                null,
                'The app received the transfer enable flag'
            )
            this.ontransferenabled()
        }

        this.signaller.onmessage = () => {}

        this.signaller.onexternalmessage = json => {
            //Need assume only 1 call at a time for external messages
            const call = this.signaller.cs?.at(0)
            switch (json.event) {
                case 'end_call':
                    if (!call) return
                    return this.endCall(call.callTraceID)
                case 'answer_call':
                    if (!call) return
                    return this.answerCall(call.callTraceID)
                case 'make_call':
                    return this.onexternalcall(json.number, json.from)
                default:
                    console.log('unknown event', json)
                    return
            }
        }

        this.signaller.oncallparkevent = event => {
            if (event.eventType === EventType.CALL_PARKED) {
                const { payload } = event
                const newParkedCall: ParkedCall = {
                    callTraceID: payload.callTraceID,
                    parked: {
                        remoteNumber: payload.dialledNumber,
                        contactName: payload.parkedContactName,
                        companyName: payload.parkedContactCompany
                    },
                    parker: {
                        remoteNumber: payload.originatingNumber,
                        contactName: payload.parkerContactName,
                        companyName: payload.parkerContactCompany,
                        teamName: ''
                    }
                }
                this.parkedCalls = [...this.parkedCalls, newParkedCall]
            }

            if (event.eventType === EventType.CALL_UNPARKED) {
                const { payload } = event
                this.parkedCalls = this.parkedCalls.filter(
                    call => call.callTraceID !== payload.callTraceID
                )
            }

            this.oncallparkevent(this.parkedCalls)
        }
        this.signaller.onrecordingmodechanged = data => {
            this.onrecordingmodechanged(data.payload.mode)
        }
        this.signaller.oncallgroupvoicemail = data => {
            this.oncallgroupvoicemail(data.payload)
        }
        this.signaller.onsubscriptionupdated = data => {
            this.onsubscriptionupdated(data)
        }
        this.signaller.onpresencesubscriptionchanged = data => {
            this.onpresencesubscriptionchanged(data)
        }
        this.signaller.oncallmonitoringevent = (event: CallMonitoringEvent) => {
            this.oncallmonitoringevent(event)
        }
        this.signaller.onnwaymemberupdate = (callTraceID: string, data: NWayMemberEvent) => {
            const call = this.signaller.getCall(callTraceID)

            if (!call?.calldetails) return

            call.calldetails.nWayMembers ||= []

            if (data.event === 'answer') {
                call.calldetails.nWayMembers = [...call.calldetails.nWayMembers, data]
            } else {
                const legID = data.replacementLegID ?? data.legID

                if (data.replacementLegID) {
                    const foundMember = call.calldetails.nWayMembers.find(
                        member => member.legID === legID
                    )

                    if (foundMember) {
                        call.calldetails.contactName = foundMember.displayName
                        call.calldetails.displayName = foundMember.displayName
                        if (foundMember.numberE164) {
                            call.calldetails.remoteNumber = foundMember.numberE164
                        }
                    }
                }

                call.calldetails.nWayMembers = call.calldetails.nWayMembers.filter(
                    member => member.legID !== legID
                )
            }

            this.onstatuschange({
                ...call.calldetails,
                status: this.getStatus(callTraceID)
            })
        }
    }

    getExternal() {
        return this.signaller.getExternal()
    }

    setupAutoAnswer(callTraceID: string, timeout: number) {
        return setTimeout(() => {
            const status = this.getStatus(callTraceID)
            if (status !== 'RINGING_LOCAL') {
                this.clearAutoAnswer()
                return
            }
            this.answerCall(callTraceID)
        }, timeout)
    }

    clearAutoAnswer() {
        if (this.autoAnswerTimeout) clearTimeout(this.autoAnswerTimeout)
    }

    call(
        to: string,
        from: string,
        contact?: { fullName?: string; companyName?: string; source?: string; externalID?: string },
        callPickupID?: string
    ) {
        Logger.log('trace', 'start call', { to, from }, 'USER_CALL')
        //remove spaces in number
        to = to.replace(/ /g, '')
        if (!this.ls.isOpen()) {
            return this.onconnectionerror()
        }
        const callTraceID = generateCallTraceID()
        this.signaller.sendExternalMessage({
            event: 'call_made',
            dialledNumber: to,
            callTraceID,
            contact: contact
                ? [{ source: contact.source, externalID: contact.externalID }]
                : undefined
        })

        /* Hold any CONNECTED CALLS */
        if (this.signaller.cs?.length) {
            this.signaller.cs.forEach(({ callTraceID }) => {
                if (this.getStatus(callTraceID) === 'CONNECTED') this.hold(callTraceID, () => {})
            })
        }

        this.setStatus(callTraceID, 'START_CALL')

        this._webrtc(callTraceID, () => {
            const wrtc = this.getWrtc(callTraceID)
            if (!wrtc) return
            wrtc.createOffer()
            wrtc.onoffercreated = (callTraceID, offer) => {
                this.signaller.sendOffer(offer, to, from, callTraceID, contact, callPickupID)
            }
        })
    }

    loadSession(_session) {
        // this.signaller.calldetails = {
        //     direction: session.direction,
        //     localNumber: session.dialledNumber,
        //     remoteNumber: session.originatingNumber,
        //     callTraceID: session.callTraceID
        // }
    }

    answerCall(callTraceID: string) {
        Logger.log('trace', 'answering call', null, 'USER_ANSWERED')
        Logger.putEvent('ui/in/callAnswer', null, 'user answered the call')
        if (this.getStatus(callTraceID) !== 'RINGING_LOCAL') {
            return this.oncallerror()
        }
        this.signaller.sendExternalMessage({ event: 'call_answered' })
        this.clearAutoAnswer()

        /* Hold any CONNECTED CALLS */
        if (this.signaller.cs?.length) {
            this.signaller.cs.forEach(({ callTraceID }) => {
                if (this.getStatus(callTraceID) === 'CONNECTED') this.hold(callTraceID, () => {})
            })
        }

        this.setStatus(callTraceID, 'ANSWERED')
        this._webrtc(callTraceID, () => {
            const wrtc = this.getWrtc(callTraceID)
            if (!wrtc) return
            wrtc?.setRemoteDescription(this.remoteOffer[callTraceID])
            wrtc?.createAnswer()
            delete this.remoteOffer[callTraceID]
        })
    }

    setupIncomingCall(callID) {
        this.signaller.incomingCall({
            eventType: 'incoming_call',
            callID: callID
        })
    }

    private _webrtc(callTraceID: string, callback) {
        this.signaller.getTurnConfig(response => {
            let iceServers
            if (response?.type !== 'error') {
                const { body } = response
                const uri = body.uri.replace('turn:', '')
                let stunURI = `stun:${uri}`
                let turnURI = `turn:${uri}`

                if (!stunURI.includes(':3478')) {
                    stunURI = `${stunURI}:3478`
                }

                // new RTCPeerConnection() will fail if we keep the ?transport parameter in the url
                // so remove it if it exists
                if (stunURI.includes('?transport=udp')) {
                    stunURI = stunURI.replace('?transport=udp', '')
                }

                if (!turnURI.includes(':443')) {
                    turnURI = `${turnURI}:443?transport=tcp`
                }

                iceServers = [
                    {
                        urls: [stunURI, turnURI],
                        username: body.username,
                        credential: body.password
                    }
                ]
            } else if (this.config.turnConfig) {
                const stunURI = `stun:${this.config.turn}:3478`
                const turnURI = `turn:${this.config.turn}:443?transport=tcp`
                iceServers = [
                    {
                        urls: [stunURI, turnURI],
                        username: this.config.turnConfig.username,
                        credential: this.config.turnConfig.password
                    }
                ]
            }
            const wconfig: webrtcConfig = {
                iceServers,
                iceTransportPolicy: 'relay',
                bandwidthLimit: this.config.bandwidthLimit,
                preferCodec: this.config.preferCodec,
                disableDSCP: this.config.disableDSCP,
                enableRecording: this.config.enableRecording,
                forbiddenCodecs: this.config.forbiddenCodecs
            }

            if (this.config.audio) {
                wconfig.audio = this.config.audio
            }
            try {
                this.wrtc = [...(this.wrtc || []), new webrtc(callTraceID, wconfig)]
            } catch (er) {
                return this.onsupporterror()
            }

            const wrtc = this.getWrtc(callTraceID)
            if (!wrtc) return
            wrtc.onready = () => {
                // this.callStats?.startLogging(wrtc?.pc)
                callback()
            }
            wrtc.onicecandidate = (callTraceID: string, candidate: any) => {
                if (this.signaller.ready) {
                    this.signaller.sendICECandidate(callTraceID, candidate)
                } else {
                    this.candidates.push(candidate)
                }
            }
            wrtc.onicecomplete = (callTraceID: string) => {
                if (this.signaller.ready && !this.candidates.length) {
                    this.signaller.sendICEComplete(callTraceID)
                }
                if (!this.config.preventCallStats) {
                    this.callStats.startLogging(wrtc.pc, callTraceID)
                }
            }

            wrtc.onremotestream = (callTraceID, stream) => this.attachstream(stream, callTraceID)

            wrtc.onicedisconnect = () => this.ondisconnect()
            // this.wrtc.onicefailed = () => this.endCall(undefined)
            wrtc.onanswercreated = (callTraceID, answer) =>
                this.signaller.sendAnswer(callTraceID, answer)
            wrtc.onofferrestarted = (callTraceID: string, offer) => {
                this.signaller._keepCallAlive(() => {
                    const call = this.signaller.getCall(callTraceID)
                    this.signaller?.send(callTraceID, {
                        jsep: offer,
                        webrtc: 'message',
                        session_id: call?.sessionID,
                        handle_id: call?.handleID,
                        body: {
                            request: 'update'
                        }
                    })
                })
            }

            wrtc.onerror = (callTraceID: string, er) => {
                if (er.type === 'Permissions') {
                    this.onpermissionserror()
                } else {
                    Logger.log('error', 'webrtc error', er)
                    this.onconnectionerror(er)
                }
                this.endCall(callTraceID)
            }
            wrtc.onrecordingfinished = data => {
                console.info('Call controller saw the call recording finish')
                data.date = new Date()
                this.onrecordingfinished && this.onrecordingfinished(data)
            }

            wrtc.init()
        })
    }

    getCallDetails(callTraceID: string) {
        return this.signaller.getCall(callTraceID)?.calldetails
    }

    updateCallDetails(callTraceID: string, callDetails: CallDetails) {
        const call = this.signaller.getCall(callTraceID)
        if (call?.calldetails) call.calldetails = { ...call.calldetails, ...callDetails }
    }

    mute(callTraceID: string) {
        const wrtc = this.getWrtc(callTraceID)
        Logger.putEvent('call/mute/on', null, 'The user muted the call')
        if (wrtc) wrtc.mute()
        const call = this.signaller.getCall(callTraceID)
        if (call?.calldetails) {
            call.calldetails.muted = true
            this.onstatuschange({ ...call.calldetails, status: this.getStatus(callTraceID) })
        }
    }

    unmute(callTraceID: string) {
        const wrtc = this.getWrtc(callTraceID)
        if (wrtc) wrtc.mute()
        Logger.putEvent('call/mute/off', null, 'The user unmuted the call')
        if (wrtc) wrtc.unmute()
        const call = this.signaller.getCall(callTraceID)
        if (call?.calldetails) {
            call.calldetails.muted = false
            this.onstatuschange({ ...call.calldetails, status: this.getStatus(callTraceID) })
        }
    }

    sendDTMF(callTraceID: string, digit: string) {
        Logger.putEvent('call/DTMF/send', { dtmfDigit: digit }, 'The user sent DTMF digit ' + digit)
        this.signaller.sendDTMF(callTraceID, digit)
    }

    removeWrtc(callTraceID: string) {
        this.wrtc = [...(this.wrtc?.filter(wrtc => wrtc.callTraceID !== callTraceID) || [])]
    }

    endCall(callTraceID: string, calldetails?: CallDetails) {
        Logger.log('trace', 'user ends call', null, 'USER_END_CALL')
        Logger.putEvent(
            'call/hangup/send',
            null,
            'App sending hangup after the user hungup the call'
        )
        const call = this.signaller.getCall(callTraceID)
        this._callend(undefined, calldetails || call?.calldetails)
        this.signaller.hangup(callTraceID)
        const wrtc = this.getWrtc(callTraceID)
        if (wrtc) wrtc.close()
        this.removeWrtc(callTraceID)
    }

    declineCall(callTraceID: string, calldetails?: CallDetails) {
        Logger.log('trace', 'user ends call', null, 'USER_DECLINES_CALL')
        Logger.putEvent(
            'call/decline/send',
            null,
            'App sending declineCall after the user declining the call'
        )

        const callDetails = this.getCallDetails(callTraceID)

        this._callend(undefined, calldetails || callDetails)
        this.signaller.declineCall(callTraceID)
        const wrtc = this.getWrtc(callTraceID)
        if (wrtc) wrtc.close()
        this.removeWrtc(callTraceID)
    }

    private _callend(_info?, calldetails?: CallDetails) {
        if (!calldetails) return
        this.setStatus(calldetails.callTraceID, 'NO_CALL')
        this.signaller.sendExternalMessage({
            event: 'call_ended',
            callTraceID: calldetails.callTraceID
        })
        let links: any
        if (this.callDetails?.callTraceID) {
            links = { callID: this.callDetails.callTraceID }
        }
        Logger.putEvent('call/end', null, 'App saw the call end', links)
        this.callStats?.stopLogging(this.callDetails?.callTraceID)
        delete this.remoteOffer[calldetails.callTraceID]
        this.oncallend({ ...calldetails, status: this.getStatus(calldetails.callTraceID) })
    }

    requestNotifications() {
        Notification.requestPermission()
    }

    setStatus(callTraceID: string, status: CallStatus) {
        Logger.log('debug', 'Call Status Change to ' + status)
        this.status[callTraceID] = status

        this.signaller.sendExternalMessage({
            event: 'call_status',
            body: { status: status }
        })

        const callDetails = this.getCallDetails(callTraceID)
        if (callDetails) {
            this.onstatuschange({ ...callDetails, status })
        }
    }

    getStatus(callTraceID?: string) {
        return callTraceID ? this.status[callTraceID] : 'NO_CALL'
    }

    close(callTraceID: string) {
        if (this.signaller) this.signaller.destroy(callTraceID)
    }

    closeAllCallSockets() {
        if (this.signaller.cs && this.wrtc) {
            this.wrtc?.forEach(connection => {
                connection.close()
            })
            this.signaller.destroyAllCallSockets()
        }
    }

    private _initNotifications() {
        if (!this.config.notifications) return
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            this.getServiceWorker()
        } else {
            Logger.log('error', 'Push not supported')
        }
    }

    getServiceWorker(callback?) {
        if (this.swRegistration)
            return callback ? callback(this.swRegistration) : this.swRegistration
        const sw = this.config.sw ?? './src/sw.js'
        const options = this.config.swoptions ?? {}
        if (!navigator.serviceWorker) {
            if (callback) return callback()
            return
        }
        navigator.serviceWorker
            .register(sw, options)
            .then(swReg => {
                console.log('Service Worker is registered', swReg)
                this.swRegistration = swReg
                if (callback) callback(this.swRegistration)
            })
            .catch(function (error) {
                Logger.log('error', 'Service Worker Error', error)
                if (callback) callback()
            })
    }

    subscribe() {
        if (!this.swRegistration) {
            Logger.log('error', 'Notifications not supported')
            throw new Error('Notifications not supported')
        }
        const applicationServerPublicKey =
            this.config.appPublicKey ??
            'BMO0BplJamMXQnmScuv9DAjperRlxGDrDFjFhXQGbpE92PLZT2v7HBLMMjV0FXTGrEnQ4SSDeOh7CVrs3a_n7TM'
        const applicationServerKey = this.urlB64ToUint8Array(applicationServerPublicKey)
        this.swRegistration.pushManager
            .subscribe({
                userVisibleOnly: true,
                applicationServerKey: applicationServerKey
            })
            .then(subscription => {
                Logger.log('info', 'User is subscribed:', subscription)
                this.onsubscribe(subscription)
            })
            .catch(err => {
                Logger.log('error', 'Failed to subscribe the user: ', err)
                this.onsubscribeerror(err)
            })
    }

    unsubscribe() {
        if (!this.swRegistration) return Promise.resolve()
        return this.swRegistration.pushManager
            .getSubscription()
            .then(subscription => {
                if (subscription) {
                    return subscription.unsubscribe()
                }
            })
            .catch(error => {
                console.log('Error unsubscribing', error)
            })
            .then(() => {
                this.onunsubscribe(null)
            })
    }

    isSubscribed(callback) {
        this.getServiceWorker(swRegistration => {
            if (!swRegistration) return callback(false)
            swRegistration.pushManager.getSubscription().then(subscription => {
                const isSubscribed = subscription != null
                callback(isSubscribed)
            })
        })
    }

    urlB64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
        // eslint-disable-next-line
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

        const rawData = window.atob(base64)
        // eslint-disable-next-line
        const outputArray = new Uint8Array(rawData.length)

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i)
        }
        return outputArray
    }

    setPreferences(preferences: UserPreferences) {
        const customAudio: AudioConfig = {
            ...(this.config.audio || {}),
            deviceId: this.config.audio?.deviceId || { ideal: 'default' },
            noiseSuppression: this.config.audio?.noiseSuppression || true
        }

        if (typeof preferences.inputDeviceID !== 'undefined')
            customAudio.deviceId.ideal = preferences.inputDeviceID
        if (typeof preferences.noiseCancellation !== 'undefined')
            customAudio.noiseSuppression = preferences.noiseCancellation
        if (typeof preferences.autoAnswer !== 'undefined')
            this.config = { ...this.config, autoAnswer: preferences.autoAnswer }
        if (typeof preferences.autoAnswerDuration !== 'undefined')
            this.config = {
                ...this.config,
                autoAnswerDuration: preferences.autoAnswerDuration * 1000
            }

        this.config = {
            ...this.config,
            audio: {
                ...customAudio
            }
        }

        this.updateWrtAudio()
    }

    updateWrtAudio() {
        if (!this.wrtc?.length) return

        this.wrtc?.forEach(rtc => {
            if (this.config.audio) {
                rtc.setInputDevice(this.config.audio)
                rtc.updateAudio()
            }
        })
    }

    nWayInitiate(
        callTraceID: string,
        userID?: string,
        teamID?: string,
        numberE164?: string,
        callback?
    ) {
        Logger.log('info', 'adding person to call', userID, teamID, numberE164)

        const call = this.signaller.getCall(callTraceID)
        const nWayMembers = call?.calldetails?.nWayMembers

        if (nWayMembers?.length && nWayMembers.length > 3) {
            Logger.log('error', 'Maximum number of people on the call exceeded')
            return
        }

        this.signaller.nWayInitiate(callTraceID, userID, teamID, numberE164, data => {
            if (data.type === 'error') {
                Logger.log('error', 'error adding person to call' + JSON.stringify(data.body))
            } else {
                Logger.log('info', 'adding person to call')
            }
            callback(data)
        })
    }

    nWayHangup(
        callTraceID: string,
        legID: string,
        userID?: string,
        teamID?: string,
        numberE164?: string,
        callback?
    ) {
        Logger.log('info', 'hanging up person from call', userID, teamID, numberE164)

        const call = this.signaller.getCall(callTraceID)
        const nWayMembers = call?.calldetails?.nWayMembers

        if (!nWayMembers?.length) {
            Logger.log('error', 'No people on the call to hangup')
            return
        }

        this.signaller.nWayHangup(callTraceID, legID, userID, teamID, numberE164, data => {
            if (data.type === 'error') {
                Logger.log('error', 'error hanging up person from call' + JSON.stringify(data.body))
            } else {
                Logger.log('info', 'hangup person from call')
            }
            callback(data)
        })
    }

    transfer(
        callTraceID: string,
        userID?: string | undefined,
        teamID?: string | undefined,
        numberE164?: string | undefined,
        isUnattended?: boolean,
        contact?: DirectoryEntry,
        callback?
    ) {
        Logger.log('info', 'transfering call to', userID, teamID)
        const call = this.signaller.getCall(callTraceID)

        this.signaller.transfer(callTraceID, userID, teamID, numberE164, isUnattended, data => {
            if (data.type === 'error') {
                Logger.log('error', 'error transferring call' + JSON.stringify(data.body))
            } else {
                Logger.log('info', 'transferring call')
                this.setStatus(callTraceID, 'TRANSFERRING')
                if (call?.calldetails) {
                    call.calldetails.transferToContact = contact
                    this.onstatuschange({
                        ...call.calldetails,
                        status: this.getStatus(callTraceID)
                    })
                }
            }
            callback(data)
        })
    }

    confirmTransfer(callTraceID: string, callback) {
        Logger.log('info', 'confirming call transfer')
        this.signaller.confirmTransfer(callTraceID, data => {
            if (data.type === 'error') {
                Logger.log(
                    'error',
                    'error confirming transferring call' + JSON.stringify(data.body)
                )
            } else {
                Logger.log('info', 'call transferred')
                this.setStatus(callTraceID, 'TRANSFERRED')
                callback(data)
            }
        })
    }
    cancelTransfer(callTraceID: string, callback) {
        Logger.log('info', 'cancelling call transfer')
        this.signaller.cancelTransfer(callTraceID, data => {
            if (data.type === 'error') {
                Logger.log(
                    'error',
                    'error cancelling transferring call' + JSON.stringify(data.body)
                )
            } else {
                Logger.log('info', 'call transferred cancelled')
            }
            this.setStatus(callTraceID, 'CONNECTED')
            callback(data)
        })
    }

    blindTransfer(
        callTraceID: string,
        userID?: string | undefined,
        teamID?: string | undefined,
        numberE164?: string | undefined,
        callback?
    ) {
        Logger.log('info', 'transfering call to', userID, teamID)
        this.signaller.blindTransfer(callTraceID, userID, teamID, numberE164, data => {
            if (data.type === 'error') {
                Logger.log('error', 'error transferring call' + JSON.stringify(data.body))
            } else {
                Logger.log('info', 'blind_transfering call')
                this.setStatus(callTraceID, 'TRANSFERRED')
            }
            callback(data)
        })
    }

    enableRecording() {
        this.config.enableRecording = true
        console.log('callController enabled recording')
    }

    disableRecording() {
        this.config.enableRecording = false
        console.log('callController disable recording')
    }

    pauseRecording(callTraceID: string, callback?) {
        const call = this.signaller.getCall(callTraceID)

        this.signaller.pauseRecording(callTraceID, data => {
            if (data.type === 'error') {
                Logger.log('error', 'error pausing call recording' + JSON.stringify(data.body))
            } else {
                Logger.log('info', 'pausing recording')
                if (call?.calldetails) {
                    call.calldetails.recordingState = 'PAUSED'
                    this.onstatuschange({
                        ...call.calldetails,
                        status: this.getStatus(callTraceID)
                    })
                }
            }
            callback(data)
        })
    }

    resumeRecording(callTraceID: string, callback?) {
        const call = this.signaller.getCall(callTraceID)

        this.signaller.resumeRecording(callTraceID, data => {
            if (data.type === 'error') {
                Logger.log('error', 'error resuming call recording' + JSON.stringify(data.body))
            } else {
                Logger.log('info', 'resuming recording')
                if (call?.calldetails) {
                    call.calldetails.recordingState = 'ON'
                    this.onstatuschange({
                        ...call.calldetails,
                        status: this.getStatus(callTraceID)
                    })
                }
            }
            callback(data)
        })
    }

    startRecording(callTraceID: string, callback?) {
        const call = this.signaller.getCall(callTraceID)

        this.signaller.startRecording(callTraceID, data => {
            if (data.type === 'error') {
                Logger.log('error', 'error starting call recording' + JSON.stringify(data.body))
            } else {
                Logger.log('info', 'started recording')
                if (call?.calldetails) {
                    call.calldetails.recordingState = 'ON'
                    this.onstatuschange({
                        ...call.calldetails,
                        status: this.getStatus(callTraceID)
                    })
                }
            }
            callback(data)
        })
    }

    stopRecording(callTraceID: string, callback?) {
        const call = this.signaller.getCall(callTraceID)

        this.signaller.stopRecording(callTraceID, data => {
            if (data.type === 'error') {
                Logger.log('error', 'error stopping call recording' + JSON.stringify(data.body))
            } else {
                Logger.log('info', 'stopped recording')
                if (call?.calldetails) {
                    call.calldetails.recordingState = 'OFF'
                    this.onstatuschange({
                        ...call.calldetails,
                        status: this.getStatus(callTraceID)
                    })
                }
            }
            callback(data)
        })
    }

    sendPresenceSubscription(eventGroup: string, userIDs?: string[], userGroupIDs?: string[]) {
        this.signaller.sendPresenceSubscription(eventGroup, userIDs, userGroupIDs)
    }

    hold(callTraceID: string, callback) {
        Logger.log('info', 'call set to hold')
        this.setStatus(callTraceID, 'ON_HOLD')
        this.signaller.hold(callTraceID, callback)
    }

    unhold(callTraceID: string, callback) {
        /*
            If we have any other calls, attach the correct stream, and hold all other calls.
        */
        if (this.signaller.cs && this.signaller.cs.length > 0) {
            const wrtc = this.getWrtc(callTraceID)
            this.attachstream(wrtc?._remoteStream, callTraceID)

            this.signaller.cs.forEach(({ callTraceID }) => {
                if (this.getStatus(callTraceID) === 'CONNECTED') this.hold(callTraceID, () => {})
            })
        }

        Logger.log('info', 'call set to unhold')
        this.setStatus(callTraceID, 'CONNECTED')
        this.signaller.unhold(callTraceID, callback)
    }

    parkCall(callTraceID: string, extension: string, callback) {
        Logger.log('info', 'parking call')
        this.signaller.parkCall(callTraceID, extension, callback)
    }

    parkCallGroup(callTraceID: string, callback) {
        Logger.log('info', 'parking call group')
        this.signaller.parkCallGroup(callTraceID, callback)
    }

    /**
     * Pickup a call from a specific callTraceID and user
     *
     * @param  {string}  extension   User extension we want to pickup the call from
     * @param  {string}  from  Our number
     * @param  {string}  callTraceID  Id of the call we want to pickup
     */
    callPickup(
        extension: string,
        from: string,
        callTraceID: string,
        contact: Partial<DirectoryEntry>
    ) {
        this.call(extension, from, contact, callTraceID)
    }
}
