// Need to export for Jest to work
export const createObjectURL = blob => {
    if (process.env.NODE_ENV === 'test') return 'worker_script'
    return URL.createObjectURL(blob)
}

const initWebWorker = () => {
    let pulseTimer: number
    let keepAlive: ReturnType<typeof setInterval>
    const sendPulse = () => {
        // eslint-disable-next-line no-restricted-globals
        self.postMessage({ type: 'pulse' })
    }

    // eslint-disable-next-line no-restricted-globals
    self.onmessage = ({ data: { type, pulse } }) => {
        if (type === 'init') {
            console.log('⚙️ Initializing WebWorker')
            pulseTimer = pulse
        }
        if (type === 'startPing') {
            console.log('⚙️ WebWorker starting Socket keepAlive')
            keepAlive = setInterval(() => sendPulse(), pulseTimer) // Start sending pings when receiving the 'startPing' message
        }
        if (type === 'stopPing') {
            console.log('⚙️ WebWorker stopping Socket keepAlive')
            clearInterval(keepAlive) // Stop sending pings when receiving the 'stopPing' message
        }
    }
}

let code = initWebWorker.toString()
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'))

const blob = new Blob([code], { type: 'application/javascript' })
const worker_script = createObjectURL(blob)

export default worker_script
