import { HStack, Spacer, useColorMode, VStack } from '@chakra-ui/react'
import { Avatar, Body, IconButton } from '@missionlabs/react'
import { PhoneIconSolid } from '@missionlabs/react/zeta'
import { DirectoryEntry, HuntGroup, IVRMenu } from '@missionlabs/types'
import { isInternal, isTeamOrMenu } from '@missionlabs/utils'
import { FC, useMemo } from 'react'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetPresence, useGetPresenceID } from 'shared/hooks/useGetPresence'

import { AddPersonCallTarget } from './AddPersonList'
import { AddPersonNumberOverflow } from './AddPersonNumberOverflow'

export type VirtualAddPersonContact = {
    contact: DirectoryEntry | HuntGroup | IVRMenu
    onClick: (target: AddPersonCallTarget) => void
}

interface AddPersonContactProps extends VirtualAddPersonContact {}

export const AddPersonContact: FC<AddPersonContactProps> = ({ contact, onClick }) => {
    const { colorMode } = useColorMode()
    const { isValidPhoneNumber } = useFormatToNumberE164()

    const presenceID = useGetPresenceID(contact as DirectoryEntry)
    const { status } = useGetPresence(presenceID)

    const info = useMemo(() => {
        if (isTeamOrMenu(contact)) return { fullName: contact.name, jobTitle: undefined }
        return contact
    }, [contact])

    const onAddPerson = (value: string) => {
        const type = (() => {
            if (isTeamOrMenu(contact) || contact.source === 'teamprofile') return 'teamID'
            if (isInternal(contact)) return 'userID'
            if (isValidPhoneNumber(value)) return 'numberE164'
        })()

        if (!type) {
            return console.error('contact cannot be added to call')
        }

        const contactToUse = !isTeamOrMenu(contact) ? contact : undefined

        onClick({ type, value, contact: contactToUse })
    }

    return (
        <HStack
            h="70px"
            w="full"
            p="12px"
            borderColor={`${colorMode}.coolGrey.60`}
            borderTop="1px solid"
            spacing="8px"
        >
            <Avatar
                boxShadow="shallow"
                name={info.fullName}
                size="md"
                src={isTeamOrMenu(contact) ? undefined : contact.photoURL}
                status={status}
                sx={{ border: 'none' }}
                team={isTeamOrMenu(contact) || contact.source === 'teamprofile'}
            />

            <VStack w="full" align="flex-start" spacing="0">
                {info.fullName && (
                    <Body size="sm" variant="bold" color="white">
                        {info.fullName}
                    </Body>
                )}
                {info.jobTitle && (
                    <Body size="xs" color="white">
                        {info.jobTitle}
                    </Body>
                )}
            </VStack>

            <Spacer />

            {contact.extension ? (
                <IconButton
                    aria-label="add person"
                    icon={<PhoneIconSolid color={`${colorMode}.tones.whiteLilac`} />}
                    ml="auto"
                    size="md"
                    variant="transparent"
                    onClick={() =>
                        contact.extension &&
                        onAddPerson(
                            isTeamOrMenu(contact) || !contact.externalID
                                ? contact.ID
                                : contact.externalID
                        )
                    }
                />
            ) : (
                <AddPersonNumberOverflow contact={contact} onAddPerson={onAddPerson} />
            )}
        </HStack>
    )
}
