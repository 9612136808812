import {
    AnalyticsCallGroupResponse,
    AnalyticsNumberResponse,
    AnalyticsReport,
    AnalyticsReportsResponse,
    AnalyticsRequest,
    AnalyticsSingleCallGroupResponse,
    AnalyticsSingleNumberResponse,
    AnalyticsSingleUserRecentCallResponse,
    AnalyticsSingleUserResponse,
    AnalyticsSummaryResponse,
    AnalyticsUsersResponse,
    CreateReportRequest
} from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildAnalyticsAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'analyticsAPI',
        tagTypes: [
            'SummaryAnalytics',
            'UsersAnalytics',
            'SingleUserAnalyticsRecentActivity',
            'SingleUserAnalyticsTotals',
            'CallGroupAnalytics',
            'CallGroupsAnalyticsTotals',
            'CallGroupsAnalyticsRecentActivity',
            'NumbersAnalytics',
            'NumbersAnalyticsTotals',
            'NumbersAnalyticsRecentActivity',
            'Reports'
        ],
        baseQuery: baseQuery,
        endpoints: builder => ({
            // Analytics
            getSummaryAnalytics: builder.query<AnalyticsSummaryResponse, AnalyticsRequest>({
                query: ({ from, to, group, timezone }) => ({
                    url: `/analytics?from=${from}&to=${to}&group=${group}&timezone=${timezone}`,
                    method: 'GET'
                }),
                providesTags: () => ['SummaryAnalytics']
            }),

            getUsersAnalytics: builder.query<AnalyticsUsersResponse, AnalyticsRequest>({
                query: ({ from, to, group, timezone }) => ({
                    url: `/users/analytics?from=${from}&to=${to}&group=${group}&timezone=${timezone}`,
                    method: 'GET'
                }),
                providesTags: () => ['UsersAnalytics']
            }),

            getSingleUserRecentCallsAnalytics: builder.query<
                AnalyticsSingleUserRecentCallResponse,
                AnalyticsRequest
            >({
                query: ({ from, to, userID }) => ({
                    url: `/users/${userID}/activities?userOnly=true&sinceTime=${from}&fromTime=${to}&activityClass=call&activityType=call_received,call_missed,call_made,internal_call_received,internal_call_missed,internal_call_made&max=1000`,
                    method: 'GET'
                }),
                providesTags: (result, error, arg) => [
                    { type: 'SingleUserAnalyticsRecentActivity', id: arg.userID }
                ]
            }),
            deleteAdminCallRecording: builder.mutation<void, { activityID: string }>({
                query: ({ activityID }) => ({
                    url: `/activities/${activityID}/adminrecording`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['SingleUserAnalyticsRecentActivity']
            }),
            getSingleUserTotalsAnalytics: builder.query<
                AnalyticsSingleUserResponse,
                AnalyticsRequest
            >({
                query: ({ from, to, group, timezone, userID }) => ({
                    url: `/users/${userID}/analytics?from=${from}&to=${to}&group=${group}&timezone=${timezone}`,
                    method: 'GET'
                }),
                transformResponse: (response: AnalyticsSingleUserResponse) => {
                    if (response?.data && Array.isArray(response.data)) {
                        response.data = null
                    }
                    return response
                },
                providesTags: (result, error, arg) => [
                    { type: 'SingleUserAnalyticsTotals', id: arg.userID }
                ]
            }),

            getCallGroupsAnalytics: builder.query<AnalyticsCallGroupResponse, AnalyticsRequest>({
                query: ({ from, to, group, timezone }) => ({
                    url: `/teams/analytics?from=${from}&to=${to}&group=${group}&timezone=${timezone}`,
                    method: 'GET'
                }),
                providesTags: () => ['CallGroupAnalytics']
            }),

            getSingleCallGroupTotalsAnalytics: builder.query<
                AnalyticsSingleCallGroupResponse,
                AnalyticsRequest
            >({
                query: ({ from, to, group, timezone, teamID }) => ({
                    url: `/teams/${teamID}/analytics?from=${from}&to=${to}&group=${group}&timezone=${timezone}`,
                    method: 'GET'
                }),
                providesTags: (result, error, arg) => [
                    { type: 'CallGroupsAnalyticsTotals', id: arg.teamID }
                ]
            }),
            getSingleCallGroupRecentCallHistory: builder.query<
                AnalyticsSingleUserRecentCallResponse,
                AnalyticsRequest
            >({
                query: ({ from, to, teamID }) => ({
                    url: `/teams/${teamID}/activities?sinceTime=${from}&fromTime=${to}&activityClass=call&activityType=call_received,call_missed,call_made,internal_call_received,internal_call_missed,internal_call_made&max=1000`,
                    method: 'GET'
                }),
                providesTags: (result, error, arg) => [
                    { type: 'CallGroupsAnalyticsRecentActivity', id: arg.teamID }
                ]
            }),

            getNumbersAnalytics: builder.query<AnalyticsNumberResponse, AnalyticsRequest>({
                query: ({ from, to, group, timezone }) => ({
                    url: `/numbers/analytics?from=${from}&to=${to}&group=${group}&timezone=${timezone}`,
                    method: 'GET'
                }),
                providesTags: () => ['NumbersAnalytics']
            }),

            getSingleNumberTotalsAnalytics: builder.query<
                AnalyticsSingleNumberResponse,
                AnalyticsRequest
            >({
                query: ({ from, to, group, timezone, numberE164 }) => ({
                    url: `/numbers/${numberE164}/analytics?from=${from}&to=${to}&group=${group}&timezone=${timezone}`,
                    method: 'GET'
                }),
                providesTags: (result, error, arg) => [
                    { type: 'NumbersAnalyticsTotals', id: arg.numberE164 }
                ]
            }),

            getSingleNumberRecentCallHistory: builder.query<
                AnalyticsSingleUserRecentCallResponse,
                AnalyticsRequest
            >({
                query: ({ from, to, numberE164 }) => ({
                    url: `/activities/user?sinceTime=${from}&fromTime=${to}&number=${numberE164}&activityClass=call&activityType=call_received,call_missed,call_made,internal_call_received,internal_call_missed,internal_call_made&max=1000`,
                    method: 'GET'
                }),
                providesTags: (result, error, arg) => [
                    { type: 'NumbersAnalyticsRecentActivity', id: arg.numberE164 }
                ]
            }),

            getReports: builder.query<AnalyticsReport[], void>({
                query: () => ({
                    url: `/reports`,
                    method: 'GET'
                }),
                transformResponse: (resp: AnalyticsReportsResponse) => resp.data,
                providesTags: result =>
                    result
                        ? [
                              ...result.map(({ ID }) => ({ type: 'Reports' as const, id: ID })),
                              'Reports'
                          ]
                        : ['Reports']
            }),

            getReport: builder.query<AnalyticsReport, string>({
                query: reportID => ({
                    url: `/reports/${reportID}`,
                    method: 'GET'
                }),
                providesTags: (_r, _e, arg) => [{ type: 'Reports', id: arg }]
            }),

            requestReport: builder.mutation<AnalyticsReport, CreateReportRequest>({
                query: reportRequest => ({
                    url: `/reports`,
                    method: 'POST',
                    body: reportRequest
                }),
                onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
                    const newReport = {
                        ID: '',
                        name: patch.name,
                        type: patch.type,
                        status: 'unconfirmed' as const,
                        created: new Date().toISOString(),
                        params: { params: patch.params, visibility: patch.visibility }
                    }

                    try {
                        dispatch(
                            api.util.updateQueryData('getReports', undefined, draft => {
                                draft.push({ ...newReport })
                            })
                        )

                        await queryFulfilled
                    } catch {
                        dispatch(api.util.invalidateTags(['Reports']))
                    }
                },
                invalidatesTags: ['Reports']
            })
        })
    })

    return { api }
}
