import { Divider, Grid, VStack } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { Destination, DestinationTypes } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetMetadataQuery } from 'shared/store'

import { CopySipEndpointDetails } from './CopySipEndpointDetails'
import {
    EditDeviceDefaultFields,
    EditDeviceDefaultForm,
    EditDeviceDefaultFormProps
} from './EditDeviceDefaultForm'
import { SipProvisioningFlow } from './SipProvisioningFlow'

export type EditDeviceSipEndpointFields = EditDeviceDefaultFields<{
    type: DestinationTypes.sipEndpoint
}>

export const defaultValues: EditDeviceSipEndpointFields = {
    type: DestinationTypes.sipEndpoint,
    label: '',
    nonRinging: false
}

export const EditDeviceSipEndpointForm: FC<
    EditDeviceDefaultFormProps<EditDeviceSipEndpointFields>
> = ({ values, onClose, onDelete, onSubmit }) => {
    const { t } = useTranslation()

    const steps = values?.provisioningFlow ?? []
    const currentStep = steps.findLastIndex(item => item.success) + 1

    const { data } = useGetMetadataQuery()

    function getValues(data: Destination | undefined): EditDeviceSipEndpointFields {
        if (!data) return defaultValues
        return {
            type: data.type,
            label: (data.label || data.name) ?? defaultValues.label,
            nonRinging: data.nonRinging ?? defaultValues.nonRinging
        } as EditDeviceSipEndpointFields
    }

    return (
        <EditDeviceDefaultForm<EditDeviceSipEndpointFields>
            values={values}
            getValues={getValues}
            onClose={onClose}
            onDelete={onDelete}
            onSubmit={onSubmit}
            data-testid="edit-device-sip-endpoint-form"
        >
            <Divider />
            <SipProvisioningFlow steps={steps} stepIndex={currentStep} />
            {values?.autoProvision === false && (
                <>
                    <Divider />
                    <VStack spacing="16px" align="inherit">
                        <VStack spacing={1} align="inherit">
                            <Body variant="bold">{t('devices.edit.sipEndpoint.sip_details')}</Body>
                            <Body size="sm">{t('CopyToClipboard')}</Body>
                        </VStack>
                        <Divider />
                        <VStack spacing={2} align="center">
                            <Grid templateColumns="130px 1fr" gap="8px 10px">
                                <CopySipEndpointDetails
                                    label={t('Registration server')}
                                    value={values.registrationServer}
                                />
                                <CopySipEndpointDetails label={t('SIP ID')} value={values.sipID} />
                                <CopySipEndpointDetails
                                    label={t('SIP Password')}
                                    value={values.sipPassword}
                                />
                            </Grid>
                        </VStack>
                        <Divider />
                        <Body size="sm">{t('devices.edit.sipEndpoint.stun_details')}</Body>
                        <Divider />
                        <VStack spacing={2} align="center">
                            <Grid templateColumns="124px 1fr" gap="8px 10px">
                                <CopySipEndpointDetails
                                    label={t('STUN Server')}
                                    value={data?.servers.stunServer}
                                />
                                <CopySipEndpointDetails label={t('STUN Port')} value={'3478'} />
                            </Grid>
                        </VStack>
                    </VStack>
                </>
            )}
        </EditDeviceDefaultForm>
    )
}
