import { RadioGroup, Stack, VStack } from '@chakra-ui/react'
import { Body, useSyncedState } from '@missionlabs/react'
import { UserCallRoute } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BusyToneRadio } from 'shared/components/OutOfHours/BusyToneRadio'
import { RedirectExternalRadio } from 'shared/components/OutOfHours/RedirectExternalRadio'
import { RedirectInternalRadio } from 'shared/components/OutOfHours/RedirectInternalRadio'
import { VoicemailRadio } from 'shared/components/OutOfHours/VoicemailRadio'

type UnansweredCallRadioProp = {
    callRoute: UserCallRoute
    onUpdate: (payload: Partial<UserCallRoute>) => void
    minW?: string
}

export const LineBusyRadio: FC<UnansweredCallRadioProp> = ({ callRoute, onUpdate, minW }) => {
    const { t } = useTranslation()

    const [forwardType, setForwardType] = useSyncedState<string>(callRoute?.busy ?? '')

    return (
        <VStack spacing="16px" align="flex-start" w="100%">
            <RadioGroup value={forwardType} onChange={setForwardType} alignSelf="start">
                <Body size="sm" variant="bold" py="12px">
                    {t('calls.busy')}
                </Body>
                <Stack spacing={1}>
                    <BusyToneRadio onUpdate={onUpdate} />
                    <VoicemailRadio
                        type={'busy'}
                        forwardType={forwardType}
                        callRoute={callRoute}
                        onUpdate={onUpdate}
                        minW={minW}
                    />
                    <RedirectInternalRadio
                        type={'busy'}
                        forwardType={forwardType}
                        callRoute={callRoute}
                        onUpdate={onUpdate}
                        minW={minW}
                    />
                    <RedirectExternalRadio
                        type={'busy'}
                        forwardType={forwardType}
                        callRoute={callRoute}
                        onUpdate={onUpdate}
                        minW={minW}
                    />
                </Stack>
            </RadioGroup>
        </VStack>
    )
}
