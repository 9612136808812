import { HStack, Skeleton, useColorMode, VStack } from '@chakra-ui/react'
import { Avatar } from '@missionlabs/react'
import { FC } from 'react'

export interface ContactsItemSkeletonProps {}

export const ContactsItemSkeleton: FC<ContactsItemSkeletonProps> = () => {
    const { colorMode } = useColorMode()
    return (
        <HStack
            spacing="8px"
            w="full"
            py="12px"
            px="8px"
            borderBottom="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
            data-testid="contacts-item-skeleton"
        >
            <Avatar size="sm" />
            <VStack spacing="3.5px" align="start">
                <Skeleton width="150px" height="14px" />
                <Skeleton width="75px" height="14px" />
            </VStack>
        </HStack>
    )
}
