import { Box, BoxProps } from '@chakra-ui/react'
import { TFuncKey } from 'i18next'
import { FC } from 'react'
import { Trans, TransProps } from 'react-i18next'

type BoldTranslationProps = TransProps<TFuncKey> & BoxProps

export const BoldTranslation: FC<BoldTranslationProps> = ({
    i18nKey,
    values,
    color = 'black',
    fontWeight = '900',
    ...props
}) => {
    return (
        <Box as="span" display="inline-block" w="full" {...props}>
            <Trans
                i18nKey={i18nKey}
                values={values}
                components={{
                    bold: <Box as="span" color={color} fontWeight={fontWeight} />
                }}
            />
        </Box>
    )
}
