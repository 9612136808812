import { setToken, useDispatch } from '@missionlabs/api'
import { Banner, Button, FormProvider, useYupForm, yup } from '@missionlabs/react'
import { Client, User } from '@missionlabs/types'
import { useSettingsContext } from 'features/settings/components/settingsContext'
import { FC, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useUpdateUserPasswordMutation } from 'shared/store'

import { MyDetailsForm } from './MyDetailsForm'

export type MyDetailsFormFields = Pick<User, 'email' | 'locale' | 'timezone' | 'address'> & {
    currentPassword?: string
    newPassword?: string
    confirmPassword?: string
}

function getValues(
    user: User | undefined,
    client: Client | undefined
): MyDetailsFormFields | undefined {
    if (!client || !user) return
    return {
        email: user.email,
        locale: user.locale ?? client.locale,
        timezone: user.timezone ?? client.timezone,
        address: user?.address,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    }
}

const userAddressSchema = yup.object().shape({
    premises: yup.string().test({
        message: {
            key: 'validations.required'
        },
        test: value => !!value
    }),
    street: yup.string(),
    town: yup.string(),
    county: yup.string(),
    postcode: yup.string().test({
        message: {
            key: 'validations.required'
        },
        test: value => !!value
    })
})

const myDetailsSchema = yup.object({
    email: yup.string().email().required(),
    locale: yup.string(),
    timezone: yup.string(),
    address: userAddressSchema,
    currentPassword: yup.string().test({
        message: {
            key: 'validations.required'
        },
        test: (value, context) => {
            const {
                parent: { newPassword, confirmPassword }
            } = context
            return !(!value && (!!newPassword || !!confirmPassword))
        }
    }),
    newPassword: yup.string().when('currentPassword', {
        is: val => !!val,
        then: schema =>
            schema
                .required()
                .min(8)
                .test('invalid', { key: 'myDetails.passwordValidation' }, value => {
                    const regex = /^(?=.*[A-Za-z])(?=.*\d).+$/
                    return regex.test(value)
                })
    }),
    confirmPassword: yup.string().when('newPassword', {
        is: val => !!val,
        then: schema =>
            schema.required().oneOf([yup.ref('newPassword')], { key: "Passwords don't match" })
    })
})

export const MyDetails: FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { client, user, onUpdateUser: updateUser } = useSettingsContext()

    const [error, setError] = useState<string>()

    const [updatePassword] = useUpdateUserPasswordMutation()

    const methods = useYupForm<MyDetailsFormFields>(myDetailsSchema, {
        shouldFocusError: false,
        values: getValues(user, client),
        mode: 'onChange'
    })

    const {
        watch,
        formState: { isValid, isDirty }
    } = methods

    const handleSubmit = async (data: MyDetailsFormFields) => {
        if (!user) return
        setError(undefined)

        try {
            const updatedUser = await updateUser({ ID: user.ID, ...data })

            if (data.currentPassword && data.confirmPassword) {
                const { token } = await updatePassword({
                    ID: user.ID,
                    currentPassword: data.currentPassword,
                    newPassword: data.confirmPassword
                }).unwrap()
                // refresh auth token after successful update
                dispatch(setToken(token))
            }

            methods.reset(getValues(updatedUser, client))
        } catch (e) {
            if (!!e.data.message && typeof e.data.message === 'string')
                setError(e.data.message.toLowerCase())
        }
    }

    const { currentPassword, newPassword, confirmPassword } = watch()

    const isDisabled = useMemo(() => {
        if (
            (currentPassword || newPassword || confirmPassword) &&
            (!currentPassword || !newPassword || !confirmPassword)
        ) {
            // If any of the password fields have a value but not all three of them, disable the button
            return true
        }

        return !isValid || !isDirty
    }, [currentPassword, newPassword, confirmPassword, isDirty, isValid])

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <SettingsPage
                    title={t('settings.myDetails')}
                    subtitle={t('settings.myDetailsSubtitle')}
                    actions={
                        <Button type="submit" variant="creationary" isDisabled={isDisabled}>
                            {t('Save Changes')}
                        </Button>
                    }
                >
                    {!!error && (
                        <Banner>
                            <Trans
                                i18nKey="error"
                                components={{
                                    bold: <span style={{ color: 'white' }} />
                                }}
                                values={{ error }}
                            />
                        </Banner>
                    )}

                    <MyDetailsForm />
                </SettingsPage>
            </form>
        </FormProvider>
    )
}
