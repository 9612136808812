import { useMemo } from 'react'

interface DeviceOptions {
    deviceId: string
    label: string
}

export const useDeviceOptions = (devices: Array<DeviceOptions>, includeDefault = true) => {
    const options = useMemo(() => {
        const options = devices.map(({ deviceId, label }) => ({
            value: deviceId,
            label
        }))

        if (!includeDefault) {
            return options.filter(({ value }) => value !== 'default')
        }
        return options
    }, [devices, includeDefault])

    return options
}
