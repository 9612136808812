import { Box, Grid, useColorMode, useDisclosure } from '@chakra-ui/react'
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { FC } from 'react'
import { ContentEditable } from 'shared/components/Lexical/components/ContentEditable'
import { EditorEmojiPicker2 } from 'shared/components/Lexical/components/EditorEmojiPicker2'
import { Placeholder } from 'shared/components/Lexical/components/Placeholder'
import { EmojiNode } from 'shared/components/Lexical/nodes/EmojiNode'
import { EmojiPickerPlugin } from 'shared/components/Lexical/plugins/EmojiPickerPlugin'
import { EmojiPlugin } from 'shared/components/Lexical/plugins/EmojiPlugin'
import theme from 'shared/components/Lexical/theme'

import { SMSMessageToolbar } from './SMSMessageToolbar'
import { SMSSendMessagePlugin, SMSSendMessagePluginProps } from './SMSSendMessagePlugin'

const config: InitialConfigType = {
    namespace: 'SMSMessageInput',
    nodes: [EmojiNode],
    theme: theme,
    onError(error) {
        throw error
    }
}

export const SMSMessageInput: FC<SMSSendMessagePluginProps> = ({
    onSendMessage,
    channelID,
    showCounter,
    template
}) => {
    const { colorMode } = useColorMode()
    const emojiPicker = useDisclosure()

    return (
        <LexicalComposer initialConfig={config}>
            <Grid
                templateColumns={'1fr min-content'}
                position="relative"
                w="full"
                minH={'94px'}
                alignItems={'start'}
                px="16px"
                bg={`${colorMode}.tones.ghostWhite`}
                border="1px solid"
                borderColor={`${colorMode}.tones.periwinkle`}
                borderRadius="8px"
            >
                <Box>
                    <EditorEmojiPicker2 disclosure={emojiPicker} />
                    <RichTextPlugin
                        contentEditable={
                            <ContentEditable py="12px" data-testid="SMSBox-content-editable" />
                        }
                        placeholder={<Placeholder />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <SMSMessageToolbar showCounter={showCounter} />
                </Box>
                <EmojiPlugin />
                <EmojiPickerPlugin disclosure={emojiPicker} />
                <SMSSendMessagePlugin
                    onSendMessage={onSendMessage}
                    channelID={channelID}
                    template={template}
                />
            </Grid>
        </LexicalComposer>
    )
}
