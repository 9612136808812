import { useColorMode } from '@chakra-ui/react'
import { Call } from '@missionlabs/api'
import { Button } from '@missionlabs/react'
import { RecordIcon, RecordPausedIcon } from '@missionlabs/react/circleloop'
import { FC } from 'react'

interface RecordButtonProps {
    recordingState: Call['recordingState']
    onToggle: () => void
}

export const RecordButton: FC<RecordButtonProps> = ({ recordingState, onToggle }) => {
    const { colorMode } = useColorMode()
    const baseProps = {
        variant: 'dialler',
        height: '48px',
        w: 'full',
        onClick: onToggle
    } as const

    if (recordingState === 'ON')
        return (
            <Button
                leftIcon={<RecordPausedIcon boxSize="24px" color={`${colorMode}.alerts.red`} />}
                {...baseProps}
            >
                Call Recording. Click to Pause
            </Button>
        )

    if (recordingState === 'PAUSED')
        return (
            <Button leftIcon={<RecordIcon boxSize="24px" />} {...baseProps}>
                Click to Resume Call Recording
            </Button>
        )

    return (
        <Button leftIcon={<RecordIcon boxSize="24px" />} {...baseProps}>
            Click to Start Call Recording
        </Button>
    )
}
