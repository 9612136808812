import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { Numbers } from './Numbers'

export const NumbersDashboard: FC = () => {
    return (
        <>
            <Numbers />
            <Outlet />
        </>
    )
}
