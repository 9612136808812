import { UseDisclosureReturn } from '@chakra-ui/react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import EmojiPicker from 'emoji-picker-react'
import { MouseDownEvent } from 'emoji-picker-react/dist/config/config'
import { FC } from 'react'

import { INSERT_EMOJI_COMMAND } from '../plugins/EmojiPlugin'

export interface EditorEmojiPicker2Props {
    disclosure: UseDisclosureReturn
}

export const EditorEmojiPicker2: FC<EditorEmojiPicker2Props> = ({ disclosure }) => {
    const [editor] = useLexicalComposerContext()

    const handleClick: MouseDownEvent = event => {
        editor.dispatchCommand(INSERT_EMOJI_COMMAND, event.emoji)
        disclosure.onClose()
    }

    return (
        <EmojiPicker
            open={disclosure.isOpen}
            onEmojiClick={handleClick}
            style={{ position: 'absolute', bottom: '50px', zIndex: 1000 }}
            previewConfig={{ showPreview: false }}
        />
    )
}
