import { Flex, useColorMode } from '@chakra-ui/react'
import { selectAuthenticatedUser, selectInData, useSelector } from '@missionlabs/api'
import { Dropdown, Input, SelectOption, Toggle, useUpdateParams } from '@missionlabs/react'
import { SearchIcon } from '@missionlabs/react/circleloop'
import { IntegrationApp } from '@missionlabs/types'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetIntegrationsQuery } from 'shared/store'

import { IntegrationsList } from './IntegrationsList'

function getBaseApps(apps: IntegrationApp[]) {
    return apps.filter(app => app.brand === 'circleloop' && !app.preview)
}

export interface IntegrationsProps {}

export const Integrations: FC<IntegrationsProps> = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const [searchParams, updateParams] = useUpdateParams()

    const category = searchParams.get('category') || 'All'
    const setCategory = (value: string) => updateParams({ category: value })

    const search = searchParams.get('search') || ''
    const setSearch = (value: string) => updateParams({ search: value })

    const showInstalled = searchParams.get('installed') === 'true'
    const setShowInstalled = (value: boolean) => updateParams({ installed: value.toString() })

    const user = useSelector(selectAuthenticatedUser)

    const selectApps = useMemo(() => {
        return selectInData<IntegrationApp[]>(data => {
            if (!data) return

            const baseApps = getBaseApps(data)

            const featured: IntegrationApp[] = []
            const ordered: IntegrationApp[] = []
            const other: IntegrationApp[] = []

            for (const app of baseApps) {
                if ('featured' in app) featured.push(app)
                else if ('order' in app) ordered.push(app)
                else other.push(app)
            }

            ordered.sort((a, b) => a.order! - b.order!)
            other.sort((a, b) => {
                if (a.source > b.source) return 1
                return b.source > a.source ? -1 : 0
            })

            return [...featured, ...ordered, ...other]
        })
    }, [])

    const selectTags = useMemo(() => {
        return selectInData<IntegrationApp[], SelectOption[]>(data => {
            if (!data) return

            const baseApps = getBaseApps(data)
            const uniqueTags = new Set(baseApps.flatMap(app => app.tags))
            const tags = [...uniqueTags].map(item => {
                const label = item!.toLowerCase()
                return { label: t(`admin.integrations.category.${label}`), value: item! }
            })
            tags.sort((a, b) => a.label.localeCompare(b.label))
            return [{ label: t('admin.integrations.category.all'), value: 'All' }, ...tags]
        })
    }, [t])

    const {
        apps = [],
        tags = [],
        isLoading
    } = useGetIntegrationsQuery(user?.userID ?? skipToken, {
        refetchOnFocus: true,
        selectFromResult: result => ({
            ...result,
            apps: selectApps(result),
            tags: selectTags(result)
        })
    })

    return (
        <SettingsPage
            maxW="full"
            title={t('admin.integrations.title')}
            subtitle={t('admin.integrations.subtitle')}
        >
            <Flex w="full" align="center" justify="space-between">
                <Flex gap="16px">
                    <Input
                        w="272px"
                        value={search}
                        placeholder={t('admin.integrations.search_placeholder')}
                        rightIcon={<SearchIcon boxSize="20px" color={`${colorMode}.coolGrey.60`} />}
                        onChange={e => setSearch(e.target.value)}
                    />
                    <Dropdown w="272px" options={tags} value={category} onChange={setCategory} />
                </Flex>
                <Toggle
                    label={t('admin.integrations.installed')}
                    isChecked={showInstalled}
                    onChange={e => setShowInstalled(e.target.checked)}
                />
            </Flex>
            <IntegrationsList
                apps={apps}
                filters={{ category, search, showInstalled }}
                isLoading={isLoading}
            />
        </SettingsPage>
    )
}
