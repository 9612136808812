import { HStack } from '@chakra-ui/react'
import { Heading } from '@missionlabs/react'
// import { ArrowUpIcon } from '@missionlabs/react/circleloop'
import { FC } from 'react'
import { useSectionTitle } from 'shared/hooks/useSectionTitle'
import { Section } from 'shared/types/feed'

export interface ActivitySidebarSectionProps {
    section: Section
    index: number
}

export const ActivitySidebarSection: FC<ActivitySidebarSectionProps> = ({ section, index }) => {
    const title = useSectionTitle(section)

    return (
        <HStack
            w="full"
            pb="16px"
            pt={index === 0 ? '16px' : '8px'}
            px="24px"
            data-testid="activity-feed-section"
            justify="space-between"
        >
            <Heading size="h4" variant="bold" sx={{ fontSize: '16px' }}>
                {title}
            </Heading>
            {/*<ArrowUpIcon w="24px" h="24px" color="#2E3667" />*/}
        </HStack>
    )
}
