import { HStack, StackProps, useMultiStyleConfig } from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface ListItemProps extends StackProps {
    variant?: 'interactive' | 'non-interactive'
}

export const ListItem = forwardRef<HTMLDivElement, ListItemProps>(
    ({ variant, children, ...props }, ref) => {
        const styles = useMultiStyleConfig('List', { variant })
        return (
            // @ts-ignore
            <HStack role="listitem" {...styles.item} {...props} ref={ref}>
                {children}
            </HStack>
        )
    }
)
