import { AdminSettingsIcon } from '@missionlabs/react/circleloop'
import { UserRole } from '@missionlabs/types'
import { Action, useRegisterActions } from 'kbar'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserRole } from 'shared/hooks/useUserRole'

import { commandMenuShortcuts } from './shortcuts'

export function useAdminActions() {
    const navigate = useNavigate()
    const role = useUserRole()
    const isAdmin = useMemo(() => role === UserRole.ADMIN, [role])

    const adminActions: Action[] = useMemo(() => {
        if (!isAdmin) return []
        return [
            {
                id: 'admin-settings',
                name: 'Admin Settings...',
                keywords: 'admin settings',
                section: 'Settings',
                shortcut: commandMenuShortcuts.adminSettings,
                icon: <AdminSettingsIcon />
            },
            {
                id: 'admin-settings-users',
                name: 'Users',
                parent: 'admin-settings',
                perform: () => navigate('/admin/users')
            },
            {
                id: 'admin-settings-teams',
                name: 'Teams',
                parent: 'admin-settings',
                perform: () => navigate('/admin/teams')
            },
            {
                id: 'admin-settings-numbers',
                name: 'Numbers',
                parent: 'admin-settings',
                perform: () => navigate('/admin/numbers')
            },
            {
                id: 'admin-settings-menus',
                name: 'Menus',
                parent: 'admin-settings',
                perform: () => navigate('/admin/menus')
            },
            {
                id: 'admin-settings-call-recording',
                name: 'Call Recording',
                parent: 'admin-settings',
                perform: () => navigate('/admin/call-recording')
            },
            {
                id: 'admin-settings-blocking',
                name: 'Blocking',
                parent: 'admin-settings',
                perform: () => navigate('/admin/blocked-numbers')
            },
            {
                id: 'admin-settings-directories',
                name: 'Directories',
                parent: 'admin-settings',
                perform: () => navigate('/admin/directories')
            },
            {
                id: 'admin-settings-global-settings',
                name: 'Global Settings',
                parent: 'admin-settings',
                perform: () => navigate('/admin/global-settings')
            },
            {
                id: 'admin-settings-activity-log',
                name: 'Activity Log',
                parent: 'admin-settings',
                perform: () => navigate('/admin/activity-log')
            },
            {
                id: 'admin-settings-billing',
                name: 'Billing',
                parent: 'admin-settings',
                perform: () => navigate('/admin/billing')
            },
            {
                id: 'admin-settings-subscription',
                name: 'Subscription',
                parent: 'admin-settings',
                perform: () => navigate('/admin/subscription')
            },
            {
                id: 'analytics-search',
                name: 'Search number',
                parent: 'analytics',
                perform: () => navigate('/analytics/search-number'),
                priority: -1
            },
            {
                id: 'analytics-reports',
                name: 'Reports',
                parent: 'analytics',
                perform: () => navigate('/analytics/reports'),
                priority: -1
            }
        ]
    }, [isAdmin, navigate])

    useRegisterActions(adminActions, [isAdmin])
}
