import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const SubtitlesIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SubtitlesIconLight',
    viewBox: '0 0 20 20',
    path: [
        <path
            d="M8.5742 8.203C8.5742 7.36633 8.36656 6.57816 8.00001 5.88721L8.79533 5.46616C9.22853 6.28274 9.47392 7.21421 9.47392 8.203C9.47392 9.19179 9.22853 10.1233 8.79532 10.9398L8 10.5188C8.36656 9.82784 8.5742 9.03966 8.5742 8.203Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M11.1813 12.203L10.386 11.7819C10.9525 10.7141 11.2734 9.49603 11.2734 8.203C11.2734 6.90997 10.9525 5.69189 10.386 4.62406L11.1813 4.203C11.8144 5.39647 12.1731 6.75785 12.1731 8.203C12.1731 9.64815 11.8144 11.0095 11.1813 12.203Z"
            fill="currentColor"
            key="2"
        />,
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 0H17.5C18.9062 0 20 1.13281 20 2.5V13.7109C20 15.0391 18.8672 16.1719 17.5 16.1719H11.875L6.99219 19.8047C6.67969 20.0391 6.25 19.8438 6.25 19.4531V16.1719H2.5C1.09375 16.1719 0 15.0781 0 13.7109V2.5C0 1.13281 1.09375 0 2.5 0ZM17.5391 15C18.2031 15 18.7891 14.4531 18.7891 13.75V2.5C18.7891 1.83594 18.2031 1.25 17.5391 1.25H2.53906C1.83594 1.25 1.28906 1.83594 1.28906 2.5V13.75C1.28906 14.4531 1.83594 15 2.53906 15H6.91406C7.22656 15 7.53906 15.3125 7.53906 15.625V17.9688L11.1328 15.2734C11.3672 15.1172 11.6016 15 11.875 15H17.5391Z"
            fill="currentColor"
            key="3"
        />
    ]
})

export const SubtitlesIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SubtitlesIconRegular',
    viewBox: '0 0 20 20',
    path: [
        <path
            d="M10.7999 4.203L10.9638 4.51192C11.6231 5.75468 11.9964 7.17224 11.9964 8.67595C11.9964 10.1797 11.6231 11.5972 10.9638 12.84L10.7999 13.1489L9.38597 12.4003L9.55014 12.0909C10.0905 11.0723 10.3967 9.91042 10.3967 8.67595C10.3967 7.44148 10.0905 6.27957 9.55014 5.26103L9.38597 4.95158L10.7999 4.203Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M8.41398 5.46616L8.57787 5.77508C9.03722 6.64095 9.29728 7.62859 9.29728 8.67594C9.29728 9.72329 9.03722 10.7109 8.57786 11.5768L8.41397 11.8857L7 11.1372L7.16417 10.8277C7.50458 10.186 7.69755 9.45405 7.69755 8.67594C7.69755 7.89784 7.50458 7.16584 7.16417 6.52418L7.00001 6.21473L8.41398 5.46616Z"
            fill="currentColor"
            key="2"
        />,
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 0H17.5C18.9062 0 20 1.13281 20 2.5V13.7109C20 15.0391 18.8672 16.1719 17.5 16.1719H11.875L6.99219 19.8047C6.67969 20.0391 6.25 19.8438 6.25 19.4531V16.1719H2.5C1.09375 16.1719 0 15.0781 0 13.7109V2.5C0 1.13281 1.09375 0 2.5 0ZM17.5391 14.375C17.8516 14.375 18.1641 14.1016 18.1641 13.75V2.5C18.1641 2.1875 17.8516 1.875 17.5391 1.875H2.53906C2.1875 1.875 1.91406 2.1875 1.91406 2.5V13.75C1.91406 14.1016 2.1875 14.375 2.53906 14.375H8.16406V16.7188L11.2891 14.375H17.5391Z"
            fill="currentColor"
            key="3"
        />
    ]
})

export const SubtitlesIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SubtitlesIconSolid',
    viewBox: '0 0 20 21',
    path: (
        <path
            d="M20 3.29712V14.5081C20 15.8752 18.8672 16.969 17.5 16.969H11.875L6.99219 20.6018C6.67969 20.8362 6.25 20.6409 6.25 20.2502V16.969H2.5C1.09375 16.969 0 15.8362 0 14.5081V3.29712C0 1.89087 1.09375 0.797119 2.5 0.797119H17.5C18.9062 0.797119 20 1.92993 20 3.29712Z"
            fill="currentColor"
        />
    )
})
