import { HStack, useColorMode, useMultiStyleConfig } from '@chakra-ui/react'
import { RecordingModeEnum } from '@missionlabs/types'
import { Button } from 'atoms'
import {
    MusicIconSolid,
    PauseCircleIconSolid,
    PlayCircleIconSolid,
    StopCircleIconSolid
} from 'atoms/Icons/zeta'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CallState } from '../types'

export type CallStatusProps = {
    state: CallState
    onToggleRecording: () => void
    onStartStopRecording: (save: boolean) => void
}

export const CallStatus: FC<CallStatusProps> = ({
    state,
    onToggleRecording,
    onStartStopRecording
}) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { callStatus } = useMultiStyleConfig('ActiveCall')

    const { recordingMode, recording, onHold } = state

    const isDisabled = onHold || recordingMode === RecordingModeEnum.ALWAYS
    const isOnDemandUserStart = useMemo(() => {
        return (
            recording !== 'OFF' &&
            recordingMode === RecordingModeEnum.ON_DEMAND_USER_START &&
            !onHold
        )
    }, [onHold, recording, recordingMode])

    function handleClick() {
        if (isDisabled) return
        return onToggleRecording()
    }

    const renderLabel = useCallback(() => {
        if (onHold) return t('call.onHold')
        if (recordingMode === RecordingModeEnum.ALWAYS) return t('call.alwaysRecording')
        if (recordingMode === RecordingModeEnum.ON_DEMAND && recording === 'OFF') {
            return t('call.recording.onUserControl')
        }
        if (recording === 'OFF' && recordingMode === RecordingModeEnum.PAUSE_RESUME)
            return t('call.recording.onUserControl')

        if (recording === 'PAUSED') return t('call.pausedRecording')
        if (recording === 'OFF') return t('call.startRecording')
        if (recording === 'ON') return t('call.recording.onUserControl')

        return
    }, [onHold, recording, recordingMode, t])

    const renderIcon = useCallback(() => {
        if (onHold) {
            return <MusicIconSolid boxSize="20px" py="3.33px" color="inherit" />
        }

        if (recording === 'ON' || recordingMode === RecordingModeEnum.ALWAYS) {
            return <PlayCircleIconSolid boxSize="20px" p="3px" color={`${colorMode}.alerts.rose`} />
        }

        if (recording === 'OFF' && recordingMode === RecordingModeEnum.PAUSE_RESUME)
            return <PlayCircleIconSolid boxSize="20px" p="3px" color={`${colorMode}.alerts.rose`} />

        if (recording === 'OFF' && recordingMode === RecordingModeEnum.ON_DEMAND)
            return <PlayCircleIconSolid boxSize="20px" p="3px" color={`${colorMode}.alerts.rose`} />

        if (recording === 'PAUSED' || recording === 'OFF') {
            return <PauseCircleIconSolid boxSize="20px" p="3px" color="inherit" />
        }

        return
    }, [colorMode, onHold, recording, recordingMode])

    if (recordingMode === RecordingModeEnum.NEVER && !onHold) return null
    if (!recordingMode && !onHold) return null

    return (
        <HStack spacing="8px" w="full">
            <Button
                w="full"
                variant="tertiary"
                leftIcon={renderIcon()}
                sx={{
                    ...callStatus,
                    pointerEvents: isDisabled ? 'none' : undefined
                }}
                onClick={handleClick}
            >
                {renderLabel()}
            </Button>
            {isOnDemandUserStart && (
                <Button
                    w="35%"
                    variant="tertiary"
                    leftIcon={
                        <StopCircleIconSolid
                            boxSize="20px"
                            p="3px"
                            color={`${colorMode}.alerts.rose`}
                        />
                    }
                    sx={{
                        ...callStatus
                    }}
                    onClick={() => onStartStopRecording(false)}
                >
                    {t('call.stopRecording')}
                </Button>
            )}
        </HStack>
    )
}
