import { HStack, VStack } from '@chakra-ui/react'
import { selectInData } from '@missionlabs/api'
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps,
    Dropdown,
    FormProvider,
    Input,
    useToast,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { ClientPlan, Country, Option } from '@missionlabs/types'
import { localeToOption } from '@missionlabs/utils'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    useGetClientPlansQuery,
    useGetLocalesQuery,
    useUpdateClientPlanMutation
} from 'shared/store'

import { acceptedPaymentCountryCodes } from '../Subscription/AddPaymentMethod/AddCardPaymentMethodForm'

interface Props extends Omit<DrawerProps, 'children' | 'isOpen'> {
    planId: string
}

type Form = NonNullable<ClientPlan['invoiceAddress']>

const selectLocaleOptions = selectInData<Country[], Option[]>(
    data => data?.map(localeToOption).sort((a, b) => a.label.localeCompare(b.label)) ?? []
)

const yupSchema = yup.object<Form>({
    company: yup.string().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    line1: yup.string().required(),
    line2: yup.string(),
    town: yup.string().required(),
    postcode: yup.string().required(),
    county: yup.string(),
    country: yup
        .string()
        .test(
            'is-accepted-country',
            () => `Country not accepted`,
            value => acceptedPaymentCountryCodes.includes((value ?? '').toUpperCase())
        )
        .required()
})

const UpdateInvoiceDetailsDrawer: React.FC<Props> = ({ onClose, planId, ...props }) => {
    const { t } = useTranslation()

    const { data } = useGetClientPlansQuery()
    const [updateClientPlan] = useUpdateClientPlanMutation()

    const { locales = [] } = useGetLocalesQuery(undefined, {
        selectFromResult: result => ({ ...result, locales: selectLocaleOptions(result) })
    })

    const acceptedLocales = useMemo(
        () =>
            locales.filter(({ value }) =>
                acceptedPaymentCountryCodes.includes(value.toUpperCase())
            ),
        [locales]
    )

    const plan = useMemo(() => {
        return data?.find(plan => plan.ID === planId)
    }, [data, planId])

    const methods = useYupForm<Form>(yupSchema, { values: plan?.invoiceAddress })

    const { isDirty, isValid, isSubmitting } = methods.formState

    const { toast } = useToast()

    const onSubmit = async (data: Form) => {
        if (!plan) return

        try {
            await updateClientPlan({
                ...plan,
                invoiceAddress: data
            }).unwrap()

            toast({
                status: 'success',
                title: t('admin.billing.invoice.updateSuccess')
            })

            onClose()
        } catch (error) {
            toast({
                status: 'error',
                title: t('admin.billing.invoice.updateFail')
            })
        }
    }

    return (
        <Drawer {...props} size="lg" isOpen={!!planId} onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>{t('admin.billing.invoice.update')}</DrawerHeader>
                <DrawerBody>
                    <FormProvider {...methods}>
                        <VStack
                            as="form"
                            m="24px"
                            spacing="16px"
                            align="inherit"
                            data-testid="add-payment-method-address"
                            onSubmit={methods.handleSubmit(onSubmit)}
                        >
                            <ValidatedInput
                                name="company"
                                label={t('admin.subscription.paymentMethod.form.company')}
                            >
                                <Input />
                            </ValidatedInput>
                            <HStack spacing="16px" align="inherit">
                                <ValidatedInput
                                    name="first_name"
                                    label={t('admin.subscription.paymentMethod.form.firstName')}
                                >
                                    <Input autoComplete="billing first-name" />
                                </ValidatedInput>
                                <ValidatedInput
                                    name="last_name"
                                    label={t('admin.subscription.paymentMethod.form.lastName')}
                                >
                                    <Input autoComplete="billing last-name" />
                                </ValidatedInput>
                            </HStack>

                            <ValidatedInput
                                name="line1"
                                label={t('admin.subscription.paymentMethod.form.line1')}
                            >
                                <Input autoComplete="billing address-line1" />
                            </ValidatedInput>
                            <ValidatedInput
                                name="line2"
                                label={t('admin.subscription.paymentMethod.form.line2')}
                            >
                                <Input autoComplete="billing address-line2" />
                            </ValidatedInput>
                            <HStack spacing="16px" align="inherit">
                                <ValidatedInput
                                    name="town"
                                    label={t('admin.subscription.paymentMethod.form.town')}
                                >
                                    <Input autoComplete="billing address-level2" />
                                </ValidatedInput>
                                <ValidatedInput
                                    name="postcode"
                                    label={t('admin.subscription.paymentMethod.form.postcode')}
                                >
                                    <Input autoComplete="billing postal-code" />
                                </ValidatedInput>
                            </HStack>
                            <ValidatedInput
                                name="county"
                                label={t('admin.subscription.paymentMethod.form.county')}
                            >
                                <Input autoComplete="billing address-level1" />
                            </ValidatedInput>
                            <ValidatedInput
                                name="country"
                                label={t('admin.subscription.paymentMethod.form.country')}
                                shouldRegister={false}
                            >
                                <Dropdown
                                    options={acceptedLocales}
                                    value={methods.watch('country')}
                                    onChange={(value: string) => {
                                        methods.setValue('country', value, {
                                            shouldDirty: true
                                        })
                                    }}
                                />
                            </ValidatedInput>
                            <HStack>
                                <Button
                                    type="submit"
                                    w="full"
                                    size="lg"
                                    variant="creationary"
                                    isDisabled={!isDirty || !isValid}
                                    isLoading={isSubmitting}
                                >
                                    {t('Save')}
                                </Button>
                                <Button
                                    onClick={onClose}
                                    type="button"
                                    w="full"
                                    size="lg"
                                    variant="tertiary"
                                >
                                    {t('Cancel')}
                                </Button>
                            </HStack>
                        </VStack>
                    </FormProvider>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default UpdateInvoiceDetailsDrawer
