import {
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    useToast
} from '@missionlabs/react'
import { User } from '@missionlabs/types'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDeleteUserMutation } from 'shared/store'

type DeleteUserDialogProps = {
    user: User
    isOpen: boolean
    onClose: () => void
}

export const DeleteUserDialog: FC<DeleteUserDialogProps> = ({ user, isOpen, onClose }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { toast } = useToast()

    const [deleteUser, { isLoading }] = useDeleteUserMutation()

    const ref = useRef<HTMLButtonElement>(null)

    const handleRemove = async () => {
        try {
            const response = await deleteUser(user.ID).unwrap()

            if (response) navigate('/admin/users')
        } catch {
            toast({
                title: t('admin.users.errorDeleting'),
                status: 'error'
            })
        }
    }

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>{t('admin.users.removeUser')}</DialogHeader>
                <DialogBody gap="12px">
                    {t('admin.users.removeUserDescription', { name: user.fullName })}
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('admin.users.removeUser'),
                        isDestructive: true,
                        onClick: () => {
                            handleRemove()
                        }
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
