import { Divider, DrawerFooter, HStack, useColorMode, VStack } from '@chakra-ui/react'
import {
    Body,
    Button,
    DrawerBody,
    DrawerHeader,
    FormProvider,
    Input,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { type NumberE164 } from '@missionlabs/types'
import { useFlags } from 'flagsmith/react'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetClientPlansQuery } from 'shared/store'
import { APP_BRAND, AppConfig } from 'shared/utils/env'

import { AssignedTo } from './AssignedTo'

interface EditNumbersPanelProps {
    number: NumberE164
    isDone: boolean
    onClose: () => void
    onRemove: () => void
    onAssign: () => void
    onUnassign: () => void
    handleAlphaTag: (alphaTag: string) => Promise<void>
}

const editNumberSchema = yup.object().shape({
    alphaTag: yup.string()
})

type editNumberFields = yup.InferType<typeof editNumberSchema>

export const EditNumbersPanel: FC<EditNumbersPanelProps> = ({
    number,
    isDone,
    onClose,
    onRemove,
    onAssign,
    onUnassign,
    handleAlphaTag
}) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    const { number_alpha_tagging } = useFlags(['number_alpha_tagging'])

    const { data: clientPlan } = useGetClientPlansQuery()
    const hasAlphaTaggingPlanFeature = useMemo(
        () => clientPlan?.[0].features.includes('alpha_tagging') ?? false,
        [clientPlan]
    )

    const methods = useYupForm<editNumberFields>(editNumberSchema, {
        values: { alphaTag: number?.alphaTag }
    })

    const onSubmit = async (data: editNumberFields) => {
        if (data.alphaTag) {
            await handleAlphaTag(data.alphaTag)
        }
        onClose()
    }

    const isDirectDialNumber = 'userID' in number
    const hasAlphaTagging = number_alpha_tagging.enabled && hasAlphaTaggingPlanFeature

    return (
        <FormProvider {...methods}>
            <VStack as="form" align="left" h="full" onSubmit={methods.handleSubmit(onSubmit)}>
                <DrawerHeader>{t('numbers.editNumber')}</DrawerHeader>
                <DrawerBody flexGrow={1}>
                    <VStack p="24px" spacing="24px" align="left">
                        <VStack w="full" spacing="8px" align="flex-start">
                            <VStack spacing={0} align="flex-start">
                                <Body size="md" color={`${colorMode}.tones.navy`}>
                                    {number.numberE164}
                                </Body>
                            </VStack>
                            <Body size="sm" variant="bold" color={`${colorMode}.tones.stormGrey`}>
                                {number.displayNumberType} {t('Number')}
                            </Body>
                        </VStack>
                        <Divider />
                        <AssignedTo number={number} onAssign={onAssign} onUnassign={onUnassign} />

                        {isDirectDialNumber && hasAlphaTagging && (
                            <ValidatedInput name="alphaTag" label="Tag" w="50%">
                                <Input />
                            </ValidatedInput>
                        )}
                    </VStack>
                </DrawerBody>
                <DrawerFooter p="24px">
                    <HStack w="full">
                        {AppConfig.brand === APP_BRAND.CIRCLELOOP && (
                            <Button
                                flex="1"
                                variant="negatory-outline"
                                size="lg"
                                onClick={onRemove}
                            >
                                {t('Remove')}
                            </Button>
                        )}
                        <Button
                            type="button"
                            flex="1"
                            variant="secondary"
                            size="lg"
                            isDisabled={isDone}
                            onClick={onClose}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            type="submit"
                            flex="1"
                            variant="creationary"
                            size="lg"
                            isDisabled={!methods.formState.isDirty && !isDone}
                        >
                            {t('Done')}
                        </Button>
                    </HStack>
                </DrawerFooter>
            </VStack>
        </FormProvider>
    )
}
