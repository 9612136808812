import { CallControllerConfig, CallDetails, LiveServicesConfig } from '@missionlabs/browser-calling'
import { DirectoryEntry, MediaDevice, NewChatMessage, RecordingMode } from '@missionlabs/types'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

type LiveServicesStatus =
    | 'CONNECTED'
    | 'CONNECTING'
    | 'DISCONNECTED'
    | 'RECONNECTING'
    | 'FAILED'
    | 'NOT_CONNECTED'

export type TelecomConfig = {
    liveServicesConfig: LiveServicesConfig
    callControllerConfig?: CallControllerConfig
}

export interface LiveServicesState {
    connectionStatus: LiveServicesStatus
    authenticated: boolean
    token?: string
    destinationID?: string
    config?: TelecomConfig
}

const initialState = {
    connectionStatus: 'NOT_CONNECTED',
    token: undefined,
    destination: undefined,
    authenticated: false
} as LiveServicesState

export const liveServicesSlice = createSlice({
    name: 'liveServicesSlice',
    initialState,
    reducers: {
        addToConference(_state, _action: PayloadAction<{ callTraceID: string }>) {},
        callAndAddToConference(
            _state,
            _action: PayloadAction<{ to: string; from: string; contact?: any }>
        ) {},
        closeAllCallSockets(_state) {},
        closeSocket() {},
        call(
            _state,
            _action: PayloadAction<{
                to: string
                from: string
                contact?: DirectoryEntry
            }>
        ) {},
        callWithVideo(
            _state,
            _action: PayloadAction<{
                myUserId: string
                clientId: string
                invitedUserIds: Array<string>
            }>
        ) {},
        transferCall(
            _state,
            _action: PayloadAction<{
                callTraceID: string
                contact?: DirectoryEntry
                userID?: string
                teamID?: string
                numberE164?: string
                isUnattended?: boolean
            }>
        ) {},
        blindTransfer(
            _state,
            _action: PayloadAction<{
                callTraceID: string
                userID?: string
                teamID?: string
                numberE164?: string
            }>
        ) {},
        nWayInitiate(
            _state,
            _action: PayloadAction<{
                callTraceID: string
                userID?: string
                teamID?: string
                numberE164?: string
            }>
        ) {},
        nWayHangup(
            _state,
            _action: PayloadAction<{
                callTraceID: string
                legID: string
                userID?: string
                teamID?: string
                numberE164?: string
            }>
        ) {},
        confirmCallTransfer(_state, _action: PayloadAction<{ callTraceID: string }>) {},
        cancelCallTransfer(_state, _action: PayloadAction<{ callTraceID: string }>) {},
        setInputDevice(_state, _action: PayloadAction<string>) {},
        answer(_state, _action: PayloadAction<{ callTraceID: string }>) {},
        hangup(_state, _action: PayloadAction<{ callTraceID: string }>) {},
        hold(_state, _action: PayloadAction<{ callTraceID: string }>) {},
        declineCall(_state, _action: PayloadAction<{ callTraceID: string }>) {},
        unhold(_state, _action: PayloadAction<{ callTraceID: string }>) {},
        toggleRecording(
            _state,
            _action: PayloadAction<{
                callTraceID: string
                state: 'ON' | 'OFF' | 'PAUSED' | undefined
                recordingMode: RecordingMode | undefined
            }>
        ) {},
        startStopRecording(
            _state,
            _action: PayloadAction<{ callTraceID: string; start: boolean }>
        ) {},
        sendDTMF(_state, _action: PayloadAction<{ callTraceID: string; dtmfKey: string }>) {},
        setOutputDevice(_state, _action: PayloadAction<string>) {},

        toggleMute(_state, _action: PayloadAction<{ callTraceID: string; enabled: boolean }>) {},
        updateConnection(state, action: PayloadAction<{ status: LiveServicesStatus }>) {
            if (action.payload.status === 'CONNECTED') state.authenticated = true
            state.connectionStatus = action.payload.status
        },
        parkCall(_state, _action: PayloadAction<{ callTraceID: string; extension: string }>) {},
        parkCallGroup(_state, _action: PayloadAction<{ callTraceID: string }>) {},
        retrieveParkedCall(
            _state,
            _action: PayloadAction<{ contact?: DirectoryEntry } | undefined>
        ) {},
        /**
         * Pickup a call from a specific callTraceID and user
         * @param  {string}  to User extension we want to pickup the call for
         * @param  {string}  from  Our number
         * @param  {string}  callTraceID  Id of the call we want to pickup
         */
        callPickup(
            _state,
            _action: PayloadAction<{
                to: string
                from: string
                callTraceID: string
                contact: Partial<DirectoryEntry>
            }>
        ) {},
        liveServicesConnect() {},
        liveServicesAuth(
            state,
            action: PayloadAction<{
                token: string
                destinationID: string
                config: TelecomConfig
            }>
        ) {
            state.token = action.payload.token
            state.destinationID = action.payload.destinationID
            state.config = action.payload.config
        },
        updateCallDetails(
            _state,
            _action: PayloadAction<{
                callTraceID: string
                callDetails: CallDetails
            }>
        ) {},
        updateWrtcDevices(_state, _action: PayloadAction<MediaDevice[]>) {},
        sendChatMessage(_state, _action: PayloadAction<NewChatMessage & { channelID: string }>) {},
        updateChatMessage(
            _state,
            _action: PayloadAction<{
                messageID: string
                contentPlainText: string
                contentHTML: string
            }>
        ) {},
        sendChatReaction(_state, _action: PayloadAction<{ unicode: string; messageID: string }>) {}
    }
})

export type LiveServicesSliceRootState = {
    liveServicesSlice: ReturnType<typeof liveServicesSlice.getInitialState>
}

export const selectLiveServicesStatus = (state: LiveServicesSliceRootState) =>
    state.liveServicesSlice.connectionStatus

export const selectCurrentDestinationID = (state: LiveServicesSliceRootState) => {
    return state.liveServicesSlice.destinationID
}

export const {
    addToConference,
    answer,
    blindTransfer,
    call,
    callAndAddToConference,
    callPickup,
    callWithVideo,
    cancelCallTransfer,
    closeAllCallSockets,
    closeSocket,
    confirmCallTransfer,
    declineCall,
    hangup,
    nWayHangup,
    hold,
    nWayInitiate,
    liveServicesAuth,
    liveServicesConnect,
    parkCall,
    parkCallGroup,
    retrieveParkedCall,
    sendChatMessage,
    sendChatReaction,
    sendDTMF,
    setInputDevice,
    setOutputDevice,
    startStopRecording,
    toggleMute,
    toggleRecording,
    transferCall,
    unhold,
    updateCallDetails,
    updateChatMessage,
    updateConnection,
    updateWrtcDevices
} = liveServicesSlice.actions
