import { FacebookLoginStatus } from '@missionlabs/types'
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'

export type WhatsAppState = {
    signup: {
        status: FacebookLoginStatus
        wabaid: string
        code: string
    }
    facebookLoginStatus: FacebookLoginStatus
}

export type WhatsAppActions = {
    setSignupStatus: (state: WhatsAppState, action: PayloadAction<FacebookLoginStatus>) => void
    setSignupWabaid: (state: WhatsAppState, action: PayloadAction<string>) => void
    setSignupCode: (state: WhatsAppState, action: PayloadAction<string>) => void
    setFacebookLoginStatus: (
        state: WhatsAppState,
        action: PayloadAction<FacebookLoginStatus>
    ) => void
}

const initialState: WhatsAppState = {
    signup: {
        status: FacebookLoginStatus.NO_ACCOUNT,
        wabaid: '',
        code: ''
    },
    facebookLoginStatus: FacebookLoginStatus.NO_ACCOUNT
}

export const whatsappSlice: Slice<WhatsAppState, WhatsAppActions, 'whatsappSlice'> = createSlice({
    name: 'whatsappSlice',
    initialState,
    reducers: {
        setSignupStatus: (state, action) => {
            state.signup.status = action.payload
        },
        setSignupWabaid: (state, action) => {
            state.signup.wabaid = action.payload
        },
        setSignupCode: (state, action) => {
            state.signup.code = action.payload
        },
        setFacebookLoginStatus: (state, action) => {
            state.facebookLoginStatus = action.payload
        }
    }
})

export type WhatsAppSliceRootState = {
    whatsappSlice: ReturnType<typeof whatsappSlice.getInitialState>
}

export const selectFacebookLoginStatus = (state: WhatsAppSliceRootState) => {
    return state.whatsappSlice.facebookLoginStatus
}

export const selectSignupStatus = (state: WhatsAppSliceRootState) => {
    return state.whatsappSlice.signup.status
}

export const selectSignupWabaid = (state: WhatsAppSliceRootState) => {
    return state.whatsappSlice.signup.wabaid
}

export const selectSignupCode = (state: WhatsAppSliceRootState) => {
    return state.whatsappSlice.signup.code
}

export const { setFacebookLoginStatus, setSignupStatus, setSignupWabaid, setSignupCode } =
    whatsappSlice.actions
