import { useFlags } from 'flagsmith/react'
import { PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'
export interface RequireFeatureProps {
    feature: string
    /** If true will redirect to /forbidden, otherwise will render nothing. */
    redirect?: boolean
}

export const RequireFeature = ({
    feature,
    redirect = false,
    children
}: PropsWithChildren<RequireFeatureProps>) => {
    const flag = useFlags([feature])
    if (flag[feature]) return <>{children}</>
    if (redirect) return <Navigate to="/forbidden" replace state={{ feature }} />
    return null
}
