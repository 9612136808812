import { HStack, useColorMode } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { openNewEmail } from '@missionlabs/utils'
import { FC } from 'react'

import { ContactDetailsRow } from './ContactDetailsRow'

interface ContactDetailsEmailRowProps {
    field: string
    email: string
}

export const ContactDetailsEmailRow: FC<ContactDetailsEmailRowProps> = ({ field, email }) => {
    const { colorMode } = useColorMode()
    const onOpenEmail = () => openNewEmail(email)

    return (
        <ContactDetailsRow field={field} onClick={onOpenEmail}>
            <HStack spacing="4px">
                <Body
                    size="sm"
                    variant="bold"
                    cursor="pointer"
                    color={`${colorMode}.alerts.red`}
                    _hover={{ color: `${colorMode}.alerts.redDark` }}
                    textDecoration="underline"
                >
                    {email}
                </Body>
            </HStack>
        </ContactDetailsRow>
    )
}
