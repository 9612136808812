import { Link } from '@chakra-ui/react'
import { MenuDivider, MenuGroup, MenuItem, Toggle } from '@missionlabs/react'
import { HelpIcon, LogoutIcon, UserCircleIcon, WarningIcon } from '@missionlabs/react/circleloop'
import { User } from '@missionlabs/types'
import { openURL } from '@missionlabs/utils'
import { useFlags } from 'flagsmith/react'
import { FC, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useOnLogout } from 'shared/hooks/useOnLogout'
import { AppConfig } from 'shared/utils/env'

export interface GlobalNavItemsProps {
    user: User
    loggingEnabled: boolean
    onClose: () => void
    onSaveWithholdNumber: (ev: SyntheticEvent) => void
}

const reportIssueFormUrl = 'https://share.hsforms.com/1SA3pS_VbTRu6EZou12CmWw2ci9m'
const knowledgeBaseUrl =
    AppConfig.brand === 'phonelineplus'
        ? 'https://customerhelp.phonelineplus.com/'
        : 'https://help.circleloop.com/'

export const GlobalNavItems: FC<GlobalNavItemsProps> = ({
    user,
    onClose,
    onSaveWithholdNumber
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { handleLogout } = useOnLogout()
    const flags = useFlags(['nav_report_an_issue'])

    // Hidden for now ZETA-7694
    // const [tempState, setTempState] = useState({
    //     muteNotifications: false
    // })
    // const { muteNotifications } = tempState

    // const onChange = (ev, name) => {
    //     setTempState({ ...tempState, [name]: ev.target.checked })
    // }

    const handleClick = (cb?: Function) => {
        cb?.()
        onClose()
    }

    return (
        <MenuGroup>
            <MenuItem
                spacing={0}
                leftElement={
                    <Toggle
                        mr="6px"
                        size="sm"
                        isChecked={user.withholdNumber || false}
                        onChange={onSaveWithholdNumber}
                        aria-label="witholdNumber"
                    />
                }
            >
                {t('nav.witholdCallerID')}
            </MenuItem>
            {/* Hidden for now ZETA-7694
                <MenuItem
                spacing={0}
                leftElement={
                    <Toggle
                        mr="6px"
                        size="sm"
                        isChecked={muteNotifications}
                        onChange={ev => onChange(ev, 'muteNotifications')}
                    />
                }
            >
                {t('nav.muteNotifications')}
            </MenuItem> */}
            <MenuDivider />
            <MenuItem
                icon={<UserCircleIcon />}
                onClick={() => handleClick(() => navigate('/settings/my-details'))}
            >
                {t('nav.editProfile')}
            </MenuItem>
            <MenuItem
                icon={<HelpIcon />}
                as={Link}
                href={knowledgeBaseUrl}
                rel="noopener"
                referrerPolicy="no-referrer"
                onClick={e => {
                    e.preventDefault()
                    onClose()
                    openURL(knowledgeBaseUrl)
                }}
            >
                {t('Help')}
            </MenuItem>
            {flags.nav_report_an_issue.enabled && (
                <MenuItem
                    icon={<WarningIcon />}
                    as={Link}
                    href={reportIssueFormUrl}
                    rel="noopener"
                    referrerPolicy="no-referrer"
                    onClick={e => {
                        // This item is a link for accessibility purposes, for single click we need to use openURL
                        // so need to suppress the default link opening behaviour.
                        e.preventDefault()
                        onClose()
                        openURL(reportIssueFormUrl)
                    }}
                >
                    {t('nav.reportAnIssue')}
                </MenuItem>
            )}
            <MenuDivider />
            <MenuItem
                icon={<LogoutIcon />}
                variant="destructive"
                onClick={() => handleClick(handleLogout)}
            >
                {t('signOut')}
            </MenuItem>
        </MenuGroup>
    )
}
