import { useDisclosure } from '@chakra-ui/react'
import { Drawer, DrawerContent, DrawerOverlay } from '@missionlabs/react'
import { FC, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGetNumberByID } from 'shared/hooks/useGetNumberByID'
import { useAddAlphaTagMutation } from 'shared/store'

import { AssignNumberPanel } from './AssignNumberPanel'
import { EditNumbersPanel } from './EditNumbersPanel'

interface EditNumbersDrawerProps {}

export const EditNumbersDrawer: FC<EditNumbersDrawerProps> = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { id: numberID = '' } = useParams()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    const [isDone, setIsDone] = useState(false)
    const number = useGetNumberByID(numberID)
    const [setAlphaTag] = useAddAlphaTagMutation()

    const isAssigning = useMemo(() => {
        const end = location.pathname.split('/').pop() ?? ''
        return end === 'assign'
    }, [location])

    const handleTransition = () => {
        const end = location.pathname.split('/').pop() ?? ''
        if (['edit', 'assign'].includes(end)) {
            if (!isDone) navigate(-1)
            else navigate('/admin/numbers')
        }

        setIsDone(false)
    }

    const handleRemove = () => {
        navigate(`/admin/numbers/${numberID}/remove`)
    }

    const handleAssign = () => {
        navigate(`/admin/numbers/${numberID}/assign`)
    }

    const handleUnassign = () => {
        navigate(`/admin/numbers/${numberID}/unassign`)
    }

    const handleSubmit = () => {
        setIsDone(true)
    }

    const handleAlphaTag = async (alphaTag: string) => {
        await setAlphaTag({ numberID, alphaTag }).unwrap()
    }

    if (!number) return null

    return (
        <Drawer size="lg" isOpen={isOpen} onClose={onClose} onCloseComplete={handleTransition}>
            <DrawerOverlay />
            <DrawerContent>
                {isAssigning ? (
                    <AssignNumberPanel number={number} onSubmit={handleSubmit} />
                ) : (
                    <EditNumbersPanel
                        number={number}
                        isDone={isDone}
                        onClose={onClose}
                        onRemove={handleRemove}
                        onAssign={handleAssign}
                        onUnassign={handleUnassign}
                        handleAlphaTag={handleAlphaTag}
                    />
                )}
            </DrawerContent>
        </Drawer>
    )
}
