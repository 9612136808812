import {
    useBackgroundBlur,
    useBackgroundReplacement,
    useLocalVideo,
    useLogger,
    useMeetingManager,
    useVideoInputs
} from 'amazon-chime-sdk-component-library-react'
import { isVideoTransformDevice, VideoTransformDevice } from 'amazon-chime-sdk-js'
import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

import { BackgroundFilterType, useMeetingContext } from '../provider'
import useImageUploader from './useImageUploader'

const useBackgroundFilter = (
    onChangeVideoBackgroundFilter: (newFilter: string) => void,
    previewEl?: RefObject<HTMLVideoElement>,
    initialVideoEnabled?: boolean
) => {
    const [started, setStarted] = useState(false)
    const meetingManager = useMeetingManager()
    const { selectedDevice } = useVideoInputs()
    const { isVideoEnabled, setIsVideoEnabled, toggleVideo } = useLocalVideo()
    const { videoBackgroundFilter = 'none', videoBackgroundFilterType } = useMeetingContext()

    const { isBackgroundBlurSupported, createBackgroundBlurDevice, backgroundBlurProcessor } =
        useBackgroundBlur()
    const {
        isBackgroundReplacementSupported,
        createBackgroundReplacementDevice,
        backgroundReplacementProcessor
    } = useBackgroundReplacement()

    const [lastVideoBackgroundFilter, setLastVideoBackgroundFilter] =
        useState<string>(videoBackgroundFilter)
    const [lastIsVideoEnabled, setLastIsVideoEnabled] = useState<boolean>(isVideoEnabled)
    const [lastSelectedDevice, setLastSelectedDevice] = useState<any>(selectedDevice)

    const [bgImage, setBgImage] = useState('')

    const uploadImage = useImageUploader('vsbg', (value: string) => {
        onChangeVideoBackgroundFilter(`image_${value}`)
    })
    const currentVideoDevice = useRef<VideoTransformDevice>()
    const logger = useLogger()

    const handleDeviceChange = useCallback(
        async (
            device: string | MediaTrackConstraints | MediaStream | VideoTransformDevice,
            isVideoEnabled: boolean
        ) => {
            // Restart the video input device when filter is changed
            if (isVideoEnabled) {
                await meetingManager.startVideoInputDevice(device)
                if (previewEl?.current) {
                    meetingManager.audioVideo?.startVideoPreviewForVideoInput(previewEl.current)
                }
            } else {
                meetingManager.selectVideoInputDevice(device)
            }
        },
        [meetingManager, previewEl]
    )

    const handleStopDevice = useCallback(
        async (device: string | MediaTrackConstraints | MediaStream | VideoTransformDevice) => {
            if (isVideoTransformDevice(device)) {
                // Switch back to intrinsicDevice.
                const intrinsicDevice = await device.intrinsicDevice()
                // Stop existing VideoTransformDevice.
                await device.stop()
                if (previewEl?.current) {
                    meetingManager.audioVideo?.stopVideoPreviewForVideoInput(previewEl.current)
                }
                return intrinsicDevice
            }
            return device
        },
        [meetingManager.audioVideo, previewEl]
    )

    const handleUpdate = useCallback(
        async (isVideoEnabled, videoBackgroundFilter) => {
            const [type, variant] = videoBackgroundFilter.split('_')

            if (type === 'blur' && Number(variant)) {
                backgroundBlurProcessor?.setBlurStrength(Number(variant))
            }

            if (variant === 'select') {
                return uploadImage()
            }

            if (type === 'image' && variant) {
                setBgImage(variant)
            }

            let current = selectedDevice

            if (!current) {
                return
            }

            try {
                current = await handleStopDevice(current)

                if (type === BackgroundFilterType.BLUR) {
                    // Enable video transform on the default device.
                    current = (await createBackgroundBlurDevice(current)) as VideoTransformDevice
                    logger.info(
                        `Video filter turned on - selecting video transform device: ${JSON.stringify(
                            current
                        )}`
                    )
                } else if (type === BackgroundFilterType.IMAGE) {
                    current = (await createBackgroundReplacementDevice(
                        current
                    )) as VideoTransformDevice
                    logger.info(
                        `Video filter turned on - selecting video transform device: ${JSON.stringify(
                            current
                        )}`
                    )
                } else {
                    logger.info(
                        `Video filter was turned off - selecting inner device: ${JSON.stringify(
                            current
                        )}`
                    )
                }
            } catch (e) {
                logger.error(`Error trying to toggle background blur ${e}`)
            }

            // @ts-ignore
            currentVideoDevice.current = current
            await handleDeviceChange(current, isVideoEnabled)
        },
        [
            selectedDevice,
            createBackgroundBlurDevice,
            logger,
            handleDeviceChange,
            handleStopDevice,
            backgroundBlurProcessor,
            createBackgroundReplacementDevice,
            uploadImage
        ]
    )

    useEffect(() => {
        ;(async () => {
            if (bgImage) {
                const res = await fetch(bgImage)
                const blob = await res.blob()
                await backgroundReplacementProcessor?.setImageBlob(blob)
            }
        })()
    }, [bgImage, backgroundReplacementProcessor])

    const [attemptsToStartPreviewVideo, setAttemptsToStartPreviewVideo] = useState(0)

    useEffect(() => {
        if (
            started ||
            !selectedDevice ||
            (!isBackgroundBlurSupported && videoBackgroundFilter.includes('blur')) ||
            (!isBackgroundReplacementSupported && videoBackgroundFilter.includes('image'))
        )
            return

        if (initialVideoEnabled && !previewEl && !isVideoEnabled) {
            toggleVideo()
            setStarted(true)
        }

        /**
         * Note, in the absence of a more elegant solution:
         *
         * In rare instances, the video device fails to start at this point.
         * However when the preview video does start successfully, the video element
         * has an `autoplay` attribute set to "true".
         *
         * Therefore, we can check if the `autoplay` attribute has been set and if not,
         * we can try again after a short period
         *  */
        if (previewEl?.current) {
            if (previewEl.current.getAttribute('autoplay') === null) {
                setStarted(true)
                setIsVideoEnabled(true)
            } else {
                setTimeout(
                    () => setAttemptsToStartPreviewVideo(attemptsToStartPreviewVideo + 1),
                    500
                )
            }
        }

        return () => {
            if (isVideoEnabled) toggleVideo()
            if (previewEl) setIsVideoEnabled(false)
        }
    }, [
        previewEl,
        selectedDevice,
        started,
        isVideoEnabled,
        setIsVideoEnabled,
        toggleVideo,
        attemptsToStartPreviewVideo,
        initialVideoEnabled,
        videoBackgroundFilter,
        isBackgroundBlurSupported,
        isBackgroundReplacementSupported
    ])

    useEffect(() => {
        ;(async () => {
            if (
                lastVideoBackgroundFilter === videoBackgroundFilter &&
                isVideoEnabled === lastIsVideoEnabled &&
                ((selectedDevice as any)?.device ?? selectedDevice) ===
                    (lastSelectedDevice?.device ?? lastSelectedDevice)
            )
                return
            setLastVideoBackgroundFilter(videoBackgroundFilter)
            setLastIsVideoEnabled(isVideoEnabled)
            setLastSelectedDevice((selectedDevice as any)?.device ?? selectedDevice)
            await handleUpdate(isVideoEnabled, videoBackgroundFilter)
        })()
    }, [
        videoBackgroundFilter,
        handleUpdate,
        isVideoEnabled,
        selectedDevice,
        lastIsVideoEnabled,
        lastSelectedDevice,
        lastVideoBackgroundFilter
    ])

    return {
        videoBackgroundFilterType,
        videoBackgroundFilter,
        isBackgroundBlurSupported,
        isBackgroundReplacementSupported
    }
}

export default useBackgroundFilter
