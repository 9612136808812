import { Box, Flex, SystemStyleObject, useColorMode, useMultiStyleConfig } from '@chakra-ui/react'
import { LabelledPresenceStatus } from '@missionlabs/types'
import { Body } from 'atoms'
import { Avatar, AvatarProps } from 'molecules/Avatar/Avatar'
import { FC, ReactNode } from 'react'

import ContactDetailTextOverflow from './ContactDetailTextOverflow'

export type ContactDetailProps = Omit<AvatarProps, 'role'> & {
    direction?: 'horizontal' | 'vertical'
    role?: ReactNode
    subText?: string
    transfer?: ReactNode
    parked?: ReactNode
    via?: ReactNode
    avatar?: ReactNode
    status?: LabelledPresenceStatus
    containerSx?: SystemStyleObject
    truncatedWidth?: string
    subTextSize?: 'sm' | 'md'
}

function parseHTML(html: string) {
    const parser = new DOMParser()
    const dom = parser.parseFromString(html, 'text/html')
    return dom.body.innerHTML
}

export const ContactDetail: FC<ContactDetailProps> = ({
    name,
    src,
    role,
    size = 'sm',
    variant,
    direction = 'horizontal',
    subText,
    transfer,
    parked,
    status,
    via,
    avatar,
    containerSx = {},
    truncatedWidth,
    subTextSize = 'sm',
    ...props
}) => {
    const { colorMode } = useColorMode()
    const styles = useMultiStyleConfig('ContactDetail', { size, variant: variant ?? direction })

    return (
        <Flex sx={{ ...styles.wrapper, ...(props.sx ?? {}) }} data-testid="contact-detail">
            {avatar ?? (
                <Avatar name={name} {...props} size={size} status={status} src={src ?? undefined} />
            )}
            <Box sx={{ ...styles.container, ...containerSx }}>
                <ContactDetailTextOverflow
                    label={name ?? ''}
                    size={'sm'}
                    variant="bold"
                    color={`${colorMode}.tones.navy`}
                    sx={{ ...styles.name }}
                    maxW={truncatedWidth}
                />

                {!!role && (
                    <Body
                        size={'sm'}
                        sx={{ ...styles.role }}
                        isTruncated={!!truncatedWidth}
                        maxW={truncatedWidth}
                    >
                        {role}
                    </Body>
                )}

                {!!via && (
                    <Body
                        size="sm"
                        sx={{ ...styles.role }}
                        isTruncated={!!truncatedWidth}
                        maxW={truncatedWidth}
                    >
                        {via}
                    </Body>
                )}

                {!!parked && (
                    <Body
                        size="sm"
                        sx={{ ...styles.role, color: `${colorMode}.tones.navy` }}
                        isTruncated={!!truncatedWidth}
                        maxW={truncatedWidth}
                    >
                        {parked}
                    </Body>
                )}
                {!!transfer && (
                    <Body
                        size="sm"
                        sx={{ ...styles.role, color: `${colorMode}.tones.navy` }}
                        isTruncated={!!truncatedWidth}
                        maxW={truncatedWidth}
                    >
                        {transfer}
                    </Body>
                )}
                {!!subText && (
                    <ContactDetailTextOverflow
                        label={parseHTML(subText)}
                        size={subTextSize}
                        sx={{ ...styles.role }}
                        maxW={truncatedWidth}
                    />
                )}
            </Box>
        </Flex>
    )
}
