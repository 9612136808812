import { Activity } from './activities/activity'

export enum DestinationTypes {
    android = 'android',
    desktopApp = 'desktopApp',
    ios = 'ios',
    number = 'number',
    sipEndpoint = 'sipEndpoint',
    webApp = 'webApp',
    webSocket = 'webSocket',
    mobile = 'mobile',
    landline = 'landline',
    mobileTerminal = 'mobileTerminal'
}

export type ProvisioningFlow = {
    name: string
    success: boolean
    lines?: [{ text: string; bullets?: [{ text: string; link?: string; linkText?: string }] }]
}

export type Destination = {
    ID: string
    type: DestinationTypes
    autoAnswer?: boolean
    autoAnswerDuration?: number
    autoProvision?: boolean
    installationID?: string
    userID?: string
    callRouteID: string
    name?: string
    MSISDN?: string
    registrationServer?: string
    sipID?: string
    sipPassword?: string
    lastIPAddress?: string
    created?: string
    updated?: string
    metadata?: Record<string, string>
    lastAppActivity?: number
    status?: string
    nonRinging?: boolean
    label?: string
    numberE164?: string
    announce?: boolean
    flipCLI?: boolean
    provisioningFlow?: ProvisioningFlow[]
    notifications?: {
        events?: Array<Activity>
        muteAllNotifications?: boolean
    }
    APNSToken?: string
}
export type NotificationType = { display: string; events: Array<Activity> }
export type NotificationResponse = { notificationTypes: Array<NotificationType> }
