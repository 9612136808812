import { Box, BoxProps, ResponsiveValue, useStyleConfig } from '@chakra-ui/react'
import { forwardRef } from 'react'

interface AvatarBadgeProps extends BoxProps {
    variant?: ResponsiveValue<string> | undefined
    size?:
        | ResponsiveValue<string | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '2xs' | 'xs' | 'full'>
        | undefined
}

export const AvatarBadge = forwardRef<HTMLDivElement, AvatarBadgeProps>(
    ({ variant, size, ...props }, ref) => {
        const styles = useStyleConfig('AvatarBadge', { variant, size })

        return <Box {...props} ref={ref} sx={{ ...styles, ...props.sx }} />
    }
)

AvatarBadge.displayName = 'AvatarBadge'
