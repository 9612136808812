import { selectInData } from '@missionlabs/api'
import { AudioClip, Option } from '@missionlabs/types'
import { useGetClientAudioClipsQuery } from 'shared/store'

const selectAllRecordings = selectInData<AudioClip[], Option[]>(data =>
    data?.map(({ label, URL }) => ({ value: URL, label }))
)

export function useAllClientAudioClips(): Option[] {
    const { allClips = [] } = useGetClientAudioClipsQuery('', {
        selectFromResult: result => ({ ...result, allClips: selectAllRecordings(result) })
    })
    return allClips
}
