import { BillingSummary, ClientPlan, CreditNote, Invoice, PaymentMethod } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import { CardNumberElement } from '@stripe/react-stripe-js'
import type { PaymentMethodCreateParams, Stripe, StripeElements } from '@stripe/stripe-js'

import { encryptData } from '../utils/crypto'
import { customCreateApi } from './customCreateAPI'

export interface UpdateCardDetailsArgs {
    elements: StripeElements
    stripe: Stripe
    billing_details: PaymentMethodCreateParams.BillingDetails
}

interface PaymentMethodsSCAResponse {
    client_secret: string
    paymentMethodID: string
    requiresAction: boolean
}

export const buildBillingApi = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'billingAPI',
        tagTypes: [
            'CreditNotes',
            'EstimatedInvoice',
            'Invoices',
            'PaymentMethods',
            'Summary',
            'Plans'
        ],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getCreditNotes: builder.query<CreditNote[], void>({
                query: () => '/credits',
                transformResponse: (response: { data: CreditNote[] }) => response.data,
                providesTags: ['CreditNotes']
            }),
            getEstimatedInvoice: builder.query<Invoice, void>({
                query: () => '/estimatedinvoice',
                transformResponse: (response: { data: Invoice[] }) => response.data[0],
                providesTags: ['EstimatedInvoice']
            }),
            getInvoices: builder.query<Invoice[], void>({
                query: () => '/invoices',
                transformResponse: (response: { data: Invoice[] }) => response.data,
                providesTags: ['Invoices']
            }),
            getInvoice: builder.query<Invoice, { invoiceID: string }>({
                query: ({ invoiceID }) => `/invoices/${invoiceID}`,
                providesTags: invoice => [{ type: 'Invoices', id: invoice?.invoiceID ?? '' }]
            }),
            getPaymentMethods: builder.query<PaymentMethod[], void>({
                query: () => '/paymentmethods',
                transformResponse: (response: { data: PaymentMethod[] }) => response.data,
                providesTags: ['PaymentMethods']
            }),
            getSummary: builder.query<BillingSummary, void>({
                query: () => '/invoices/subscription/summary',
                providesTags: ['Summary']
            }),
            generateCreditNotePDF: builder.mutation<string, string>({
                query: reference => ({ url: `/credits/${reference}`, method: 'GET' }),
                transformResponse: (response: CreditNote) => response.PDF!
            }),
            generateInvoicePDF: builder.mutation<string, string>({
                query: reference => ({ url: `/invoices/${reference}`, method: 'GET' }),
                transformResponse: (response: Invoice) => response.PDF!
            }),
            payInvoice: builder.mutation<string, string>({
                query: reference => ({ url: `/invoices/${reference}/pay`, method: 'POST' }),
                invalidatesTags: invoiceID => [{ type: 'Invoices', id: invoiceID ?? '' }],
                async onQueryStarted(invoiceID, { dispatch, queryFulfilled }) {
                    await queryFulfilled
                    dispatch(
                        api.util.updateQueryData('getInvoices', undefined, draft => {
                            const invoice = draft.find(i => i.invoiceID === invoiceID)
                            if (invoice) invoice.status = 'paid'
                        })
                    )
                }
            }),
            addDirectDebit: builder.mutation<string, Object>({
                async queryFn(form, _, __, baseQuery) {
                    const encryption = await encryptData(form)

                    return baseQuery({
                        url: `/directdebit`,
                        method: 'POST',
                        body: encryption
                    })
                }
            }),
            updateCardDetails: builder.mutation<any, UpdateCardDetailsArgs>({
                async queryFn({ elements, stripe, billing_details }, _, extraOptions, baseQuery) {
                    const card = elements.getElement(CardNumberElement)
                    if (!card) return { error: 'An unexpected error occurred, please try again.' }

                    const { error: createError, paymentMethod } = await stripe.createPaymentMethod({
                        type: 'card',
                        card,
                        billing_details
                    })
                    if (createError) return { error: createError }

                    const { data, error: pmsError } = await baseQuery({
                        url: '/paymentmethodssca',
                        method: 'POST',
                        body: paymentMethod
                    })
                    // @ts-ignore
                    if (pmsError) return { error: pmsError.data.message }

                    const { client_secret, requiresAction, ...body } =
                        data as PaymentMethodsSCAResponse

                    if (requiresAction) {
                        const { error } = await stripe.confirmCardPayment(client_secret)
                        if (error) return { error: error }
                    }

                    return baseQuery({ url: '/confirmpaymentmethodsca', method: 'POST', body })
                },
                invalidatesTags: ['PaymentMethods']
            }),
            getClientPlans: builder.query<ClientPlan[], void>({
                query: () => ({ url: '/plans', method: 'GET' }),
                transformResponse: ({ data }) => data,
                providesTags: ['Plans']
            }),
            updateClientPlan: builder.mutation<ClientPlan, ClientPlan>({
                query: plan => ({
                    url: `/plans/${plan.ID}`,
                    method: 'PUT',
                    body: {
                        invoiceAddress: plan.invoiceAddress
                    }
                }),
                invalidatesTags: ['Plans']
            })
        })
    })

    return { api, ...api }
}
