import { useDisclosure } from '@chakra-ui/react'
import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from '@missionlabs/react'
import { SelectWhatsAppNumberTable } from 'features/admin/pages/WhatsApp/SelectNumber/SelectWhatsAppNumberTable'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export interface SelectWhatsAppNumberDrawerProps {}

export const SelectWhatsAppNumberDrawer: FC<SelectWhatsAppNumberDrawerProps> = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    function handleTransition() {
        return navigate('/admin/whatsapp')
    }

    return (
        <Drawer size="lg" isOpen={isOpen} onClose={onClose} onCloseComplete={handleTransition}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader pb="0 !important">{t('admin.whatsapp.select.header')}</DrawerHeader>
                <DrawerBody>
                    <SelectWhatsAppNumberTable />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
