import {
    AreaCodes,
    Country,
    IntegrationImages,
    Locale,
    MonitoringConfig,
    NumberType,
    PortingURLs,
    SharedAppData,
    Timezone
} from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

type KVPResponse = {
    kvps: {
        coturn: {
            uri: string
            password: string
            username: string
        }
    }
}

export const buildMiscAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'misc',
        tagTypes: [
            'AreaCodes',
            'Images',
            'Locales',
            'MonitoringConfig',
            'NumberTypes',
            'PortingURLs',
            'SipPhones',
            'Timezones'
        ],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getAreaCodes: builder.query<AreaCodes, void>({
                query: () => '/kvpgroups/areacodes',
                transformResponse: (response: { kvps: AreaCodes }) => {
                    const areacodes = response.kvps
                    return Object.assign(
                        {},
                        ...Object.entries(areacodes).map(([k, v]) => ({
                            [k]: v.flatMap(i => i.data ?? i)
                        }))
                    )
                },
                providesTags: ['AreaCodes']
            }),
            getLocales: builder.query<Country[], void>({
                query: () => '/kvpgroups/allCountries',
                transformResponse: (response: { kvps: { countries: Locale } }) => {
                    const countries = response.kvps.countries
                    return Object.keys(countries).map(name => ({
                        ...countries[name],
                        country: name
                    }))
                },
                providesTags: ['Locales']
            }),
            getNumberTypes: builder.query<NumberType[], void>({
                query: () => '/kvpgroups/numberTypes',
                transformResponse: (response: { kvps: { countries: [{ data: NumberType[] }] } }) =>
                    response.kvps.countries.flatMap(i => i.data),
                providesTags: ['NumberTypes']
            }),
            getImages: builder.query<IntegrationImages, void>({
                query: () => '/kvpgroups/images',
                transformResponse: (response: { kvps: { integrations: IntegrationImages } }) =>
                    response.kvps.integrations,
                providesTags: ['Images']
            }),
            getMonitoringConfig: builder.query<MonitoringConfig, void>({
                query: () => '/kvpgroups/monitoringConfig',
                transformResponse: (response: { kvps: MonitoringConfig }) => response.kvps,
                providesTags: ['MonitoringConfig']
            }),
            getTimezones: builder.query<Timezone[], void>({
                query: () => '/kvpgroups/timezones',
                transformResponse: (response: { kvps: Timezone[] }) => response.kvps,
                providesTags: ['Timezones']
            }),
            getServerData: builder.query<KVPResponse, void>({
                query: () => ({
                    headers: { 'cache-control': 'no-cache' },
                    url: `/kvpgroups/serverData`
                })
            }),
            getSipPhones: builder.query<Record<string, string[]>, void>({
                query: () => '/kvpgroups/sipPhones',
                transformResponse: (response: { kvps: Record<string, string[]> }) => response.kvps,
                providesTags: ['SipPhones']
            }),
            getSharedAppData: builder.query<SharedAppData, void>({
                query: () => ({
                    url: `/kvpgroups/appShared`
                })
            }),
            getPortingFormURLs: builder.query<PortingURLs, void>({
                query: () => ({
                    url: `/kvpgroups/appShared/URLs`
                }),
                providesTags: ['PortingURLs']
            })
        })
    })

    return { api, ...api }
}
