import { Button, HStack, VStack } from '@chakra-ui/react'
import { FormProvider, Input, useToast, useYupForm, ValidatedInput, yup } from '@missionlabs/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAddDirectDebitMutation } from 'shared/store'

interface Props {
    onClose(): void
}

const addDirectDebitSchema = yup.object({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    bank_name: yup.string().required(),
    routing_number: yup.string().required(),
    account_number: yup.string().required()
})

type DirectDebitSchema = yup.InferType<typeof addDirectDebitSchema>

const defaultValues: DirectDebitSchema = {
    first_name: '',
    last_name: '',
    bank_name: '',
    routing_number: '',
    account_number: ''
}

const AddDirectDebitPaymentMethodForm: React.FC<Props> = ({ onClose }) => {
    const methods = useYupForm<DirectDebitSchema>(addDirectDebitSchema, {
        defaultValues: defaultValues
    })

    const [addDirectDebit] = useAddDirectDebitMutation()

    const {
        formState: { isDirty, isValid, isSubmitting }
    } = methods
    const { t } = useTranslation()

    const { toast } = useToast()

    const handleSubmit = async (data: DirectDebitSchema) => {
        try {
            await addDirectDebit(data).unwrap()
            toast({
                status: 'success',
                title: 'Added direct debit'
            })
            return onClose()
        } catch {
            toast({
                status: 'error',
                title: 'Failed to add direct debit'
            })
            return onClose()
        }
    }

    return (
        <FormProvider {...methods}>
            <VStack
                onSubmit={methods.handleSubmit(handleSubmit)}
                as="form"
                m="24px"
                spacing="16px"
                align="inherit"
            >
                <HStack>
                    <ValidatedInput
                        label={t('admin.subscription.paymentMethod.form.firstName')}
                        name="first_name"
                    >
                        <Input />
                    </ValidatedInput>
                    <ValidatedInput
                        label={t('admin.subscription.paymentMethod.form.lastName')}
                        name="last_name"
                    >
                        <Input />
                    </ValidatedInput>
                </HStack>
                <HStack>
                    <ValidatedInput
                        label={t('admin.subscription.paymentMethod.form.bankName')}
                        name="bank_name"
                    >
                        <Input />
                    </ValidatedInput>
                </HStack>
                <HStack>
                    <ValidatedInput
                        label={t('admin.subscription.paymentMethod.form.sortCode')}
                        name="routing_number"
                    >
                        <Input />
                    </ValidatedInput>
                    <ValidatedInput
                        label={t('admin.subscription.paymentMethod.form.accountNumber')}
                        name="account_number"
                    >
                        <Input />
                    </ValidatedInput>
                </HStack>
                <HStack px="24px" pb="24px" spacing="16px" align="inherit">
                    <Button
                        w="full"
                        size="lg"
                        variant="secondary"
                        isDisabled={isSubmitting}
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        type="submit"
                        w="full"
                        size="lg"
                        variant="creationary"
                        isDisabled={!isDirty || !isValid}
                        isLoading={isSubmitting}
                    >
                        {t('Save')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}
export default AddDirectDebitPaymentMethodForm
