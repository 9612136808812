import { useColorMode } from '@chakra-ui/react'
import { Scrollable, useMeasure } from '@missionlabs/react'
import { FC, useContext, useState } from 'react'
import { SearchInput } from 'shared/components/SearchInput'

import ContactFilterOptions from './ContactFilterOptions'
import { ContactsList } from './ContactsList/ContactsList'
import { ContactsSkeleton } from './ContactsSkeleton'
import ContactTypeFilters, { ContactTypeFilterType } from './ContactTypeFilters'
import { ContactContext } from './context/contactsContext'
import { useContactsSearchParams } from './hooks/useContactsSearchParams'

type ContactsSidebarProps = {}

export const ContactsSidebar: FC<ContactsSidebarProps> = () => {
    const { colorMode } = useColorMode()
    const { updateParams, searchTerm } = useContactsSearchParams()

    const [filterOptionsActive, setFilterOptionsActive] = useState(false)
    const { contacts, filters, isLoadingContacts } = useContext(ContactContext)

    const [searchSectionRef, { blockSize: searchSectionHeight }] = useMeasure<HTMLDivElement>()
    const [filterSectionRef, { blockSize: filterSectionHeight }] = useMeasure<HTMLDivElement>()

    /** When search or type query string param changes, search contacts */

    const onContactTypeClick = (filterType: ContactTypeFilterType) => {
        updateParams('type', filterType)
    }

    const hasFilters = filters.type.length > 0 || filters.source.length > 0

    return (
        <aside>
            <section ref={searchSectionRef} aria-label="search">
                <SearchInput
                    value={searchTerm}
                    hideFavourites
                    filtersActive={filterOptionsActive || hasFilters}
                    toggleFiltersActive={() => setFilterOptionsActive(!filterOptionsActive)}
                    onChange={e => updateParams('search', e.target.value)}
                    sx={{
                        backgroundColor: `${colorMode}.tones.white`,
                        _hover: { backgroundColor: `${colorMode}.tones.white` },
                        _active: { color: `${colorMode}.tones.midnightExpress` }
                    }}
                />
            </section>

            {filterOptionsActive ? (
                <ContactFilterOptions onClose={() => setFilterOptionsActive(false)} />
            ) : (
                <>
                    <section ref={filterSectionRef} aria-label="filter">
                        <ContactTypeFilters onClick={onContactTypeClick} />
                    </section>

                    {isLoadingContacts ? (
                        <ContactsSkeleton />
                    ) : (
                        <Scrollable
                            height={`calc(100% - ${searchSectionHeight + filterSectionHeight}px)`}
                        >
                            <ContactsList searchedContacts={contacts} />
                        </Scrollable>
                    )}
                </>
            )}
        </aside>
    )
}
