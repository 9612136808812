import { useFormatToNumberE164 } from '@missionlabs/react'

import { useGetMyNumbers } from './useGetMyNumbers'
import { Features, useHasClientFeature } from './useHasClientFeature'

/**
 * Check if SMS is enabled for the client and if the numbers are UK mobile numbers
 * @param toNumbers
 * @param fromNumber
 * @returns boolean
 */
export const useIsSMSEnabled = (toNumbers: string[] = [], fromNumber?: string) => {
    const smsClientEnabled = useHasClientFeature(Features.sms)
    const mobileNumbers = useGetMyNumbers({ mobileOnly: true })
    const { isUKMobileNumber } = useFormatToNumberE164()

    //Check clientplan feature
    if (!smsClientEnabled) return false
    //Check user has a mobile number
    if (!mobileNumbers.length) return false
    //If from number supplied check its a mobile
    if (fromNumber && !isUKMobileNumber(fromNumber)) return false
    //Check at least one of the to numbers is a mobile
    return toNumbers.some(toNumber => isUKMobileNumber(toNumber))
}
