import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const DownloadCloudIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DownloadCloudIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.6572 15.6044C23.6572 18.5283 21.3379 20.998 18.5918 20.998H5.18256C3.10546 20.998 1.31296 19.5888 0.615161 17.4082C-0.123639 15.0996 0.642961 12.6924 2.56926 11.2153C3.17376 7.5698 5.85446 4.9458 9.01016 4.9458C10.4706 4.9458 11.8549 5.5098 13.0283 6.5791C15.541 6.3257 17.6201 7.7871 18.4355 10.2148C21.2373 10.1176 23.6572 12.6357 23.6572 15.6044ZM11.5337 10.019C11.2573 10.019 11.0337 10.2426 11.0337 10.519V16.5761L8.62296 14.1655C8.42766 13.9702 8.11126 13.9702 7.91596 14.1655C7.72066 14.3608 7.72066 14.6772 7.91596 14.8725L11.1802 18.1367C11.3755 18.332 11.6919 18.332 11.8872 18.1367L15.1514 14.8725C15.3467 14.6772 15.3467 14.3608 15.1514 14.1655C15.0537 14.0678 14.9258 14.019 14.7979 14.019C14.67 14.019 14.542 14.0678 14.4444 14.1655L12.0332 16.5767V10.519C12.0332 10.2426 11.8101 10.019 11.5337 10.019Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_183"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="4"
                width="24"
                height="17"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.6572 15.6044C23.6572 18.5283 21.3379 20.998 18.5918 20.998H5.18256C3.10546 20.998 1.31296 19.5888 0.615161 17.4082C-0.123639 15.0996 0.642961 12.6924 2.56926 11.2153C3.17376 7.5698 5.85446 4.9458 9.01016 4.9458C10.4706 4.9458 11.8549 5.5098 13.0283 6.5791C15.541 6.3257 17.6201 7.7871 18.4355 10.2148C21.2373 10.1176 23.6572 12.6357 23.6572 15.6044ZM11.5337 10.019C11.2573 10.019 11.0337 10.2426 11.0337 10.519V16.5761L8.62296 14.1655C8.42766 13.9702 8.11126 13.9702 7.91596 14.1655C7.72066 14.3608 7.72066 14.6772 7.91596 14.8725L11.1802 18.1367C11.3755 18.332 11.6919 18.332 11.8872 18.1367L15.1514 14.8725C15.3467 14.6772 15.3467 14.3608 15.1514 14.1655C15.0537 14.0678 14.9258 14.019 14.7979 14.019C14.67 14.019 14.542 14.0678 14.4444 14.1655L12.0332 16.5767V10.519C12.0332 10.2426 11.8101 10.019 11.5337 10.019Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_183)"></g>
        </g>
    )
})

export const DownloadIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DownloadIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V14.0858L7.70711 10.7929C7.31658 10.4024 6.68342 10.4024 6.29289 10.7929C5.90237 11.1834 5.90237 11.8166 6.29289 12.2071L11.2929 17.2071C11.6834 17.5976 12.3166 17.5976 12.7071 17.2071L17.7071 12.2071C18.0976 11.8166 18.0976 11.1834 17.7071 10.7929C17.3166 10.4024 16.6834 10.4024 16.2929 10.7929L13 14.0858V3ZM5 18C5 17.4477 4.55228 17 4 17C3.44772 17 3 17.4477 3 18V21C3 21.5523 3.44772 22 4 22H20C20.5523 22 21 21.5523 21 21V18C21 17.4477 20.5523 17 20 17C19.4477 17 19 17.4477 19 18V20H5V18Z"
            fill="currentColor"
        />
    )
})
