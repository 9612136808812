import { ListTitle } from '@missionlabs/react'
import { FC } from 'react'

type ContactsListTitleProps = {
    label: string
}

export const ContactsListTitle: FC<ContactsListTitleProps> = ({ label }) => {
    return <ListTitle>{label}</ListTitle>
}
