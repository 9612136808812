import { Avatar } from '@missionlabs/react'
import { RosterType } from 'amazon-chime-sdk-component-library-react'
import { motion, useDragControls } from 'framer-motion'
import { FC, RefObject } from 'react'

import LocalVideoTileExtended from '../VideoTiles/LocalVideoTile'

export interface SelfTileProps {
    chimeAttendeeId: string
    constraintsRef: RefObject<HTMLDivElement>
    roster: RosterType
}

export const SelfTile: FC<SelfTileProps> = ({ chimeAttendeeId, constraintsRef, roster }) => {
    const controls = useDragControls()

    const placeholder = (
        <Avatar className="placeholder" name={roster[chimeAttendeeId]?.name} size="lg" />
    )

    return (
        <motion.div
            dragControls={controls}
            style={{
                position: 'absolute',
                height: '160px',
                width: '250px',
                cursor: 'grab',
                zIndex: 5
            }}
            initial={{ right: '24px', bottom: '24px' }}
            drag
            dragConstraints={constraintsRef}
            dragElastic={false}
            dragMomentum={false}
        >
            <LocalVideoTileExtended
                name="Me"
                chimeAttendeeId={chimeAttendeeId}
                placeholderElement={placeholder}
                css="display: flex;"
            />
        </motion.div>
    )
}
