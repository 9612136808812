import { HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Call, removeCall, selectPreferences, useDispatch, useSelector } from '@missionlabs/api'
import { ActivityIcon, Body, Button } from '@missionlabs/react'
import { IncomingChannelIcon } from '@missionlabs/react/circleloop'
import { Activity as ActivityType } from '@missionlabs/types'
import { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CallNoteSliceRootState, removeCallNote, selectCallNote } from 'shared/slices/callNoteSlice'

import { CallNotes } from '../ActiveCall/CallNotes'
import { useContactDrawerState } from '../hooks/useContactDrawerState'

interface EndedCallProps {
    call: Call
}

export const AfterCall: FC<EndedCallProps> = ({ call }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const dispatch = useDispatch()
    const preferences = useSelector(selectPreferences)
    const {
        drawer: { onClose: closeDrawer }
    } = useContactDrawerState()

    const callNote = useSelector((state: CallNoteSliceRootState) =>
        selectCallNote(state, call.callTraceID)
    )

    const handleSubmit = useCallback(() => {
        dispatch(removeCall({ callTraceID: call.callTraceID }))
        dispatch(removeCallNote({ callTraceID: call.callTraceID }))
        closeDrawer()
    }, [dispatch, call, closeDrawer])

    useEffect(() => {
        // If the user's preference is for the after call screen never to be shown
        // then automatically remove the call and the call note
        if (preferences?.showAfterCallScreen === 'never') {
            handleSubmit()
        }

        // If the user's preference is for the after call screen to only show when there are unsaved notes
        // and there isn't a note, or the note has been saved already
        // then automatically remove the call and the call note
        if (preferences?.showAfterCallScreen === 'unsaved' && (!callNote || callNote?.isSaved)) {
            handleSubmit()
        }
    }, [preferences, callNote, handleSubmit])

    return (
        <HStack spacing="4px" w="full" h="min-content" pt="16px" px="16px">
            <VStack w="full" align="left">
                <Body size="sm" variant="bold" color={`${colorMode}.tones.periwinkle`} as="h2">
                    {t('Call ended')}
                </Body>
                <Body size="sm" variant="bold" sx={{ color: 'white' }}>
                    {call.contactName || call.remoteNumber}
                </Body>
                <HStack spacing="6px" pb="10px">
                    <IncomingChannelIcon color={`${colorMode}.tones.stormGrey`} />
                    <ActivityIcon
                        activityType={
                            call.direction === 'outbound'
                                ? ActivityType.CALL_MADE
                                : ActivityType.CALL_RECEIVED
                        }
                        callLength={1}
                    />
                    <Body
                        size="sm"
                        sx={{ color: `${colorMode}.tones.periwinkle` }}
                        textTransform="capitalize"
                    >
                        {call.direction}
                    </Body>
                </HStack>
                <CallNotes
                    call={call}
                    onSave={() => {
                        if (preferences?.closeAfterCallScreenOnSave) handleSubmit()
                    }}
                />
                <Button variant="dialler" h="48px" w="full" onClick={handleSubmit}>
                    Dismiss
                </Button>
            </VStack>
        </HStack>
    )
}
