import { selectAuthenticatedUser, useDispatch, useSelector } from '@missionlabs/api'
import { ContactGroup, DirectoryEntry, isContactGroup } from '@missionlabs/types'
import { useEffect, useState } from 'react'
import {
    contactsAPI,
    useGetUserFavouriteContactsQuery,
    useUpdateUserContactGroupMutation,
    useUpdateUserMutation
} from 'shared/store'

interface UseFavouriteContactReturn {
    isFavourited: boolean
    isLoading: boolean
    toggleFavourite: () => Promise<void>
}

export const useFavouriteContact = (
    contact?: ContactGroup | DirectoryEntry
): UseFavouriteContactReturn => {
    const dispatch = useDispatch()
    const user = useSelector(selectAuthenticatedUser)

    const { data } = useGetUserFavouriteContactsQuery(user?.userID ?? '', { skip: !user })
    const [updateUser, { isLoading: userLoading }] = useUpdateUserMutation()
    const [updateContactGroup, { isLoading: groupLoading }] = useUpdateUserContactGroupMutation()

    const [isFavourited, setIsFavourited] = useState<boolean>(false)

    const isLoading = userLoading || groupLoading

    useEffect(() => {
        if (!data || !contact) return

        const predicate = (item: DirectoryEntry | ContactGroup) => item.ID === contact.ID

        if (isContactGroup(contact)) {
            setIsFavourited(data.groups.some(predicate))
        } else {
            setIsFavourited(data.contacts.some(predicate))
        }
    }, [contact, data])

    async function toggleFavourite() {
        if (!data) return

        if (!user || !contact) {
            console.warn('useFavouriteContact | No user or contact')
            return
        }

        setIsFavourited(!isFavourited)

        if (isContactGroup(contact)) {
            await updateContactGroup({
                ID: contact.ID,
                userID: user.userID,
                entry: { favourite: isFavourited }
            }).unwrap()
        } else {
            let newFC = data.contacts.map(item => item.ID)
            if (isFavourited) newFC = newFC.filter(id => contact.ID !== id)
            else newFC.push(contact.ID)
            await updateUser({ ID: user.userID, favouriteContacts: newFC }).unwrap()
        }

        // We manually invalidate tags to avoid re-fetching favourite contacts on every user update.
        dispatch(contactsAPI.util.invalidateTags(['FavouriteContacts']))
    }

    return { isFavourited, isLoading, toggleFavourite }
}
