import {
    useAudioInputs,
    useLogger,
    useMeetingManager
} from 'amazon-chime-sdk-component-library-react'
import { Device, VoiceFocusTransformDevice } from 'amazon-chime-sdk-js'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import useRealDevice from './useRealDevice'
import { VolumeTransformDevice } from './VolumeTransformDevice'

const useAudioInputSelection = (preferenceDeviceID, preferenceAudioInputLevel = {}) => {
    const meetingManager = useMeetingManager()
    const { selectedDevice, devices } = useAudioInputs()
    const [audioInputVolume, setAudioInputVolume] = useState<number>(1)
    const { getRealAudioInputDeviceId, getRealAudioInputDevice } = useRealDevice()

    const transformedDeviceRef = useRef<VolumeTransformDevice | undefined | any>()

    const selectedDeviceId = useMemo(() => {
        if (!selectedDevice) {
            return
        }
        if (selectedDevice instanceof VoiceFocusTransformDevice) {
            const id = selectedDevice.getInnerDevice().toString()
            return getRealAudioInputDeviceId(id)
        }
        if (selectedDevice['inner'] !== undefined) {
            const id = selectedDevice['inner']

            return getRealAudioInputDeviceId(id)
        }

        const id = selectedDevice.toString()

        return getRealAudioInputDeviceId(id)
    }, [selectedDevice, getRealAudioInputDeviceId])

    const logger = useLogger()

    const setAudioInputVolumeCallback = useCallback(
        gainValue => {
            if (!transformedDeviceRef.current) {
                return
            }
            setAudioInputVolume(_v => gainValue)
        },
        [transformedDeviceRef]
    )

    const setNodeGain = useCallback(
        gainValue => {
            if (
                !transformedDeviceRef.current ||
                transformedDeviceRef.current[`setVolume`] === undefined
            ) {
                return
            }

            transformedDeviceRef.current.setVolume(gainValue)
        },
        [transformedDeviceRef]
    )

    useEffect(() => {
        setNodeGain(audioInputVolume)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioInputVolume, selectedDevice])

    useEffect(() => {
        const vol =
            preferenceAudioInputLevel[selectedDeviceId] !== undefined
                ? preferenceAudioInputLevel[selectedDeviceId]
                : 1

        if (selectedDeviceId) {
            setAudioInputVolumeCallback(vol)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preferenceAudioInputLevel, selectedDeviceId])

    const handleChangeAudioInput = useCallback(
        async (value: string) => {
            if (!devices.length) return

            let foundDevice = getRealAudioInputDevice(value)

            if (!foundDevice) {
                logger.warn(
                    `AudioInputSelection | failed finding audio input device ${value}${
                        devices.length ? ', finding default' : ''
                    }`
                )
                if (!devices.length) {
                    return
                }

                foundDevice = getRealAudioInputDevice('default')

                if (!foundDevice) {
                    logger.warn(
                        'AudioInputSelection | failed to find default audio input device, finding any audio input device'
                    )
                    foundDevice = devices[0]
                }
            }

            try {
                logger.info(`Setting device ${foundDevice?.deviceId}`)
                const transformedDevice = new VolumeTransformDevice(foundDevice?.deviceId as Device)

                await meetingManager.startAudioInputDevice(transformedDevice)

                transformedDeviceRef.current = transformedDevice
            } catch (error) {
                logger.error('AudioInputSelection | failed to select audio input device')
            }
        },
        [logger, meetingManager, getRealAudioInputDevice, devices]
    )

    useEffect(() => {
        const handleChange = async (deviceId, inputVolumePreferences) => {
            if (deviceId) {
                await handleChangeAudioInput(deviceId)
                if (inputVolumePreferences && inputVolumePreferences[deviceId] !== undefined) {
                    setAudioInputVolumeCallback(inputVolumePreferences[deviceId])
                }
            }
        }

        handleChange(preferenceDeviceID, preferenceAudioInputLevel)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices, getRealAudioInputDevice])

    return {
        selectedDevice,
        selectedDeviceId,
        devices,
        audioInputVolume,
        handleChangeAudioInput,
        setAudioInputVolumeCallback
    }
}

export default useAudioInputSelection
