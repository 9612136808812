import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const NumberAddedIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NumberAddedIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 0C2.68629 0 0 2.68629 0 6V18C0 21.3137 2.68629 24 6 24H18C21.3137 24 24 21.3137 24 18V6C24 2.68629 21.3137 0 18 0H6ZM15 5.5C15 5.22386 14.7761 5 14.5 5C14.2239 5 14 5.22386 14 5.5V9H10V5.5C10 5.22386 9.77614 5 9.5 5C9.22386 5 9 5.22386 9 5.5V9H5.5C5.22386 9 5 9.22386 5 9.5C5 9.77614 5.22386 10 5.5 10H9V14H5.5C5.22386 14 5 14.2239 5 14.5C5 14.7761 5.22386 15 5.5 15H9V18.5C9 18.7761 9.22386 19 9.5 19C9.77614 19 10 18.7761 10 18.5V15H14V18.5C14 18.7761 14.2239 19 14.5 19C14.7761 19 15 18.7761 15 18.5V15H18.5C18.7761 15 19 14.7761 19 14.5C19 14.2239 18.7761 14 18.5 14H15V10H18.5C18.7761 10 19 9.77614 19 9.5C19 9.22386 18.7761 9 18.5 9H15V5.5ZM10 14V10H14V14H10Z"
                fill="currentColor"
            />
            <mask
                id="mask0_2_2834"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 0C2.68629 0 0 2.68629 0 6V18C0 21.3137 2.68629 24 6 24H18C21.3137 24 24 21.3137 24 18V6C24 2.68629 21.3137 0 18 0H6ZM15 5.5C15 5.22386 14.7761 5 14.5 5C14.2239 5 14 5.22386 14 5.5V9H10V5.5C10 5.22386 9.77614 5 9.5 5C9.22386 5 9 5.22386 9 5.5V9H5.5C5.22386 9 5 9.22386 5 9.5C5 9.77614 5.22386 10 5.5 10H9V14H5.5C5.22386 14 5 14.2239 5 14.5C5 14.7761 5.22386 15 5.5 15H9V18.5C9 18.7761 9.22386 19 9.5 19C9.77614 19 10 18.7761 10 18.5V15H14V18.5C14 18.7761 14.2239 19 14.5 19C14.7761 19 15 18.7761 15 18.5V15H18.5C18.7761 15 19 14.7761 19 14.5C19 14.2239 18.7761 14 18.5 14H15V10H18.5C18.7761 10 19 9.77614 19 9.5C19 9.22386 18.7761 9 18.5 9H15V5.5ZM10 14V10H14V14H10Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_2_2834)"></g>
        </g>
    )
})
