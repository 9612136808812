import { tabsAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys)

const baseStyle = definePartsStyle({
    tab: defineStyle({
        color: 'light.tones.stormGrey',
        gap: '4px'
    }),
    tabpanel: defineStyle({
        padding: '0'
    })
})

const variants = {
    lineBottom: definePartsStyle({
        tab: defineStyle({
            borderBottom: '1px solid',
            borderColor: 'light.tones.periwinkle',
            mb: '-1px',
            _hover: {
                borderColor: 'light.tones.stormGrey',
                color: 'light.tones.navy'
            },
            _selected: {
                borderColor: 'light.alerts.red',
                color: 'light.tones.midnightExpress'
            }
        }),
        tablist: defineStyle({
            borderBottom: '1px solid',
            borderColor: 'light.tones.periwinkle'
        })
    }),
    lineTop: definePartsStyle({
        tab: defineStyle({
            borderTop: '1px solid',
            borderColor: 'light.tones.periwinkle',
            mt: '-1px',
            _hover: {
                borderColor: 'light.tones.stormGrey',
                color: 'light.tones.navy'
            },
            _selected: {
                borderColor: 'light.alerts.red',
                color: 'light.tones.midnightExpress'
            }
        }),
        tablist: defineStyle({
            borderTop: '1px solid',
            borderColor: 'light.tones.periwinkle'
        })
    }),
    fill: definePartsStyle({
        tab: defineStyle({
            _hover: {
                bg: 'light.tones.whiteLilac',
                color: 'light.tones.navy'
            },
            _selected: {
                bg: 'light.alerts.red',
                color: 'light.tones.white'
            }
        })
    }),
    pill: definePartsStyle(({ colorMode }) => ({
        tab: defineStyle({
            padding: '9.5px 16px',
            bg: `${colorMode}.tones.whiteLilac`,
            borderTop: '1px',
            borderRight: '1px',
            borderBottom: '1px',
            borderColor: `${colorMode}.tones.periwinkle`,
            borderStyle: 'solid',
            color: `${colorMode}.tones.stormGrey`,
            _first: {
                borderLeft: '1px',
                borderColor: `${colorMode}.tones.periwinkle`,
                borderLeftRadius: '4px'
            },
            _last: {
                borderRightRadius: '4px'
            },
            _hover: {
                bg: 'transparent',
                color: `${colorMode}.tones.midnightExpress !important`
            },
            _selected: {
                bg: 'transparent',
                color: `${colorMode}.tones.navy`
            }
        })
    }))
}

const sizes = {
    sm: definePartsStyle({
        tab: defineStyle({
            padding: '10px 12px 9px',
            fontFamily: 'body',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '150%'
        })
    }),
    md: definePartsStyle({
        tab: defineStyle({
            padding: '10px 16px 9px',
            fontFamily: 'heading',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '176%',
            letterSpacing: '-0.32px'
        })
    }),
    lg: definePartsStyle({
        tab: defineStyle({
            padding: '16px 16px 15px',
            fontFamily: 'heading',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '176%',
            letterSpacing: '-0.32px'
        })
    })
}

export const TabsStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle,
    defaultProps: {
        size: 'md',
        variant: 'lineBottom'
    },
    sizes,
    variants
})
