import { sliderAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(sliderAnatomy.keys)

const reset = defineStyle({
    position: 'absolute !important'
})

export const SliderStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => ({
        container: {
            padding: '0 !important'
        },
        track: {
            bg: `${colorMode}.tones.whiteLilac`,
            height: '9px',
            borderRadius: '4px',
            ...reset
        },
        filledTrack: {
            bg: `${colorMode}.tones.stormGrey`,
            ...reset
        },
        thumb: {
            bg: `${colorMode}.tones.white`,
            borderRadius: 'none',
            boxShadow: 'none'
        }
    }),
    sizes: {
        sm: {
            thumb: {
                height: '12px',
                width: '12px'
            }
        },
        lg: ({ colorMode }) => ({
            track: {
                bg: `${colorMode}.tones.periwinkle`
            },
            thumb: {
                height: '16px',
                width: '16px',
                border: '2px solid',
                borderColor: `${colorMode}.tones.stormGrey`,
                borderRadius: '100%',
                boxShadow: 'sm'
            }
        })
    }
})
