import { ComponentStyleConfig, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
    'container',
    'input',
    'label',
    'error',
    'icon'
])

const textStyles = {
    fontFamily: 'body',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%'
}

const baseStyle = definePartsStyle({
    container: defineStyle({
        maxH: '70px'
    }),
    input: defineStyle({
        border: '1px solid',
        borderRadius: '4px',
        padding: '10px',
        ...textStyles,
        _placeholder: { ...textStyles },
        _invalid: { border: '1px solid', boxShadow: 'none' },
        _disabled: { pointerEvents: 'none' }
    }),
    label: defineStyle({
        ...textStyles,
        fontWeight: 500
    }),
    error: defineStyle({
        ...textStyles,
        fontWeight: 500
    })
})

const variants = {
    light: definePartsStyle(({ colorMode }) => ({
        input: defineStyle({
            bg: `${colorMode}.tones.white`,
            color: `${colorMode}.tones.navy`,
            borderColor: `${colorMode}.tones.periwinkle`,
            _hover: {
                borderColor: `${colorMode}.tones.stormGrey`
            },
            _focus: {
                borderColor: `${colorMode}.tones.stormGrey`
            },
            _placeholder: {
                color: `${colorMode}.tones.stormGrey`
            },
            _invalid: {
                color: `${colorMode}.alerts.red`,
                borderColor: `${colorMode}.alerts.red`
            },
            _disabled: {
                bg: `${colorMode}.tones.whiteLilac`,
                color: `${colorMode}.tones.stormGrey`
            }
        }),
        label: defineStyle({
            color: `${colorMode}.tones.navy`
        }),
        error: defineStyle({
            color: `${colorMode}.alerts.red`
        }),
        icon: defineStyle({
            color: `${colorMode}.tones.stormGrey`
        })
    })),
    dark: definePartsStyle(({ colorMode }) => ({
        input: defineStyle({
            bg: `${colorMode}.tones.vantaBlue`,
            color: `${colorMode}.tones.white`,
            borderColor: `${colorMode}.tones.stormGrey`,
            _hover: {
                borderColor: `${colorMode}.tones.periwinkle`
            },
            _focus: {
                borderColor: `${colorMode}.tones.periwinkle`
            },
            _placeholder: {
                color: `${colorMode}.tones.stormGrey`
            },
            _invalid: {
                color: `${colorMode}.alerts.red`,
                borderColor: `${colorMode}.alerts.red`
            },
            _disabled: {
                borderColor: `${colorMode}.tones.navy`,
                color: `${colorMode}.tones.stormGrey`
            }
        }),
        label: defineStyle({
            color: `${colorMode}.tones.white`
        }),
        error: defineStyle({
            color: `${colorMode}.alerts.red !important`
        }),
        icon: defineStyle({
            color: `${colorMode}.tones.periwinkle`
        })
    }))
}

export const InputStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle,
    variants,
    defaultProps: { variant: 'light' }
})
