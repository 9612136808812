import {
    ContactGroup,
    DetailedState,
    DirectoryEntry,
    isContactGroup,
    UserStatus
} from '@missionlabs/types'

export const isPresenceAllowed = (
    contact?: DirectoryEntry | ContactGroup
): contact is DirectoryEntry => {
    return (
        !isContactGroup(contact) &&
        contact?.source === 'userprofile' &&
        Boolean(contact?.externalID)
    )
}

export const PresenceToStatus = new Map<DetailedState, UserStatus>([
    [DetailedState.AVAILABLE, UserStatus.Active],
    [DetailedState.DIALLING, UserStatus.Busy],
    [DetailedState.DND, UserStatus.DND],
    [DetailedState.INCALL, UserStatus.Busy],
    [DetailedState.OFFHOOK, UserStatus.Away],
    [DetailedState.PARKED, UserStatus.Busy],
    [DetailedState.RINGING, UserStatus.Busy],
    [DetailedState.UNKNOWN, UserStatus.Offline]
])
