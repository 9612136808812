import { Text, Tooltip } from '@chakra-ui/react'
import { BodyProps } from 'atoms'
import { memo, useEffect, useRef, useState } from 'react'

interface ContactDetailTextOverflowProps extends Omit<BodyProps, 'size' | 'variant'> {
    label: string
    noOfLines?: number
    size?: 'xs' | 'sm' | 'md' | 'lg'
    variant?: 'bold' | 'extra-bold' | 'link' | 'link-bold' | 'none'
}

const ContactDetailTextOverflow = ({
    label,
    noOfLines = 1,
    ...props
}: ContactDetailTextOverflowProps) => {
    const textElementRef = useRef<HTMLDivElement>(null)
    const [isOverflown, setIsOverflown] = useState(false)

    /**
     * Check whether the element is overflow or not
     */
    const compareSize = () => {
        const element = textElementRef.current

        const compare = element
            ? element.offsetWidth < element.scrollWidth ||
              element.offsetHeight < element.scrollHeight
            : false

        setIsOverflown(compare)
    }

    useEffect(() => {
        compareSize()
    }, [])

    return (
        <Tooltip label={label} isDisabled={!isOverflown}>
            <Text
                noOfLines={noOfLines}
                ref={textElementRef}
                textOverflow="ellipsis"
                overflow="hidden"
                {...props}
            >
                {label}
            </Text>
        </Tooltip>
    )
}

export default memo(ContactDetailTextOverflow)
