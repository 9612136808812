import { SelectOption } from '@missionlabs/react'
import {
    NumberCheckCapability,
    OutboundMessageType,
    OutboundWhatsApp,
    PostWhatsAppSendMessageRequest,
    PostWhatsAppSendMessageResponse,
    WhatsAppBusinessTemplate,
    WhatsAppContentType
} from '@missionlabs/types'
import { BaseQueryFn, MutationDefinition } from '@reduxjs/toolkit/dist/query'
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate'
import {
    createContext,
    Dispatch,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState
} from 'react'
import { usePostNumberCheckMutation, usePostWhatsAppMessageMutation } from 'shared/store'

import { useGetMyWhatsAppNumbers } from './useGetMyWhatsAppNumbers'
import { useWhatsAppTemplates } from './useWhatsAppTemplates'

type IWhatsAppSendMessageContext = {
    dispatch: Dispatch<MessageAction>
    draftMessage: OutboundWhatsApp
    templates: WhatsAppBusinessTemplate[]
    template: WhatsAppBusinessTemplate
    templateOptions: SelectOption[]
    myWhatsAppNumberOptions: SelectOption[]
    send: (text?: string) => MutationActionCreatorResult<
        MutationDefinition<
            PostWhatsAppSendMessageRequest & {
                numberID: string
            },
            BaseQueryFn,
            'WhatsAppBusiness' | 'WhatsAppNumbers',
            PostWhatsAppSendMessageResponse,
            'whatsappAPI'
        >
    >
    isSending: boolean
    sendError: string | null
}

type MessageAction =
    | { type: 'SET_MESSAGE'; draftMessage: Partial<OutboundWhatsApp> }
    | { type: 'RESET_MESSAGE' }

export const initialMessageState: OutboundWhatsApp = {
    type: OutboundMessageType.WhatsApp,
    numberID: '',
    toNumber: '',
    templateName: '',
    text: '',
    contentType: WhatsAppContentType.TEMPLATE
}

export function messageReducer(state: OutboundWhatsApp, action: MessageAction) {
    switch (action.type) {
        case 'SET_MESSAGE':
            return { ...state, ...action.draftMessage }
        case 'RESET_MESSAGE':
            return initialMessageState
        default:
            return state
    }
}

const WhatsAppSendMessageContext = createContext<IWhatsAppSendMessageContext | null>(null)

export const WhatsAppSendMessageProvider = ({ children }: PropsWithChildren) => {
    const [draftMessage, dispatch] = useReducer(messageReducer, initialMessageState)
    const [sendWhatsApp, { isLoading: isSending, error: sendError }] =
        usePostWhatsAppMessageMutation()
    const [numberCheck] = usePostNumberCheckMutation()
    const { myWhatsAppNumberOptions, myWhatsAppNumbers } = useGetMyWhatsAppNumbers()
    const { templates = [], templateOptions = [] } = useWhatsAppTemplates()
    const [previousFromAndTo, setPreviousFromAndTo] = useState<string | null>(null)

    const { numberID, templateName, contentType, toNumber } = draftMessage

    const template = useMemo(() => {
        return templates.find(t => t.name === templateName)
    }, [templateName, templates])
    // If we have numbers and no numberID, set the first number as the default
    useEffect(() => {
        if (myWhatsAppNumberOptions.length > 0 && !numberID) {
            dispatch({
                type: 'SET_MESSAGE',
                draftMessage: { numberID: myWhatsAppNumberOptions[0].value }
            })
        }
    }, [myWhatsAppNumberOptions, numberID])

    // If we have tmplates and no templateName, set the first template as the default
    useEffect(() => {
        if (
            templateOptions.length > 0 &&
            !templateName &&
            contentType === WhatsAppContentType.TEMPLATE
        ) {
            dispatch({
                type: 'SET_MESSAGE',
                draftMessage: { templateName: templateOptions[0].value }
            })
        }
    }, [templateOptions, templateName, contentType])

    // We need to check if we can send a text message or a template message using the numberCheck API
    useEffect(() => {
        const checkNumberCapability = async (fromNumber: string, toNumbers: string[]) => {
            if (!toNumber || !fromNumber) return
            const response = await numberCheck({ fromNumber, toNumbers }).unwrap()
            if (!response.length) return

            const { capabilities = [] } = response[0]
            const messageType = capabilities.includes(NumberCheckCapability.WHATSAPP_TEXT)
                ? WhatsAppContentType.TEXT
                : WhatsAppContentType.TEMPLATE

            if (messageType !== contentType) {
                dispatch({
                    type: 'SET_MESSAGE',
                    draftMessage: {
                        contentType: messageType,
                        templateName:
                            messageType === WhatsAppContentType.TEMPLATE ? templateName : ''
                    }
                })
            }
        }

        const fromAndTo = `${numberID}-${toNumber}`
        if (previousFromAndTo === fromAndTo) return

        setPreviousFromAndTo(fromAndTo)
        const fromNumber = myWhatsAppNumbers.find(number => number.ID === numberID)
        if (!fromNumber) return
        checkNumberCapability(fromNumber.numberE164, [toNumber])
    }, [
        toNumber,
        numberID,
        myWhatsAppNumbers,
        myWhatsAppNumberOptions.length,
        previousFromAndTo,
        numberCheck,
        contentType,
        templateName
    ])

    const send = useCallback(
        async (overrideText?: string) => {
            const { numberID, toNumber, contentType, templateName } = draftMessage
            const text = overrideText ?? draftMessage.text
            if (!numberID || !toNumber) throw new Error('Missing numberID or toNumber')
            if (contentType === WhatsAppContentType.TEMPLATE && !templateName) {
                throw new Error('Missing templateName')
            }
            if (contentType === WhatsAppContentType.TEXT && !text) {
                throw new Error('Missing text')
            }
            const payload: PostWhatsAppSendMessageRequest & { numberID?: string } = {
                to: toNumber,
                type: contentType,
                numberID,
                text: contentType === WhatsAppContentType.TEXT ? { body: text ?? '' } : undefined,
                template:
                    contentType === WhatsAppContentType.TEMPLATE
                        ? { name: templateName ?? '', language: { code: 'en_GB' } }
                        : undefined
            }
            await sendWhatsApp(payload as PostWhatsAppSendMessageRequest & { numberID: string })
            dispatch({
                type: 'SET_MESSAGE',
                draftMessage: {
                    text: ''
                }
            })
        },
        [draftMessage, sendWhatsApp]
    )

    // Memoize the context value to avoid unnecessary re-renders
    const value = useMemo(
        () => ({
            dispatch,
            draftMessage,
            templateOptions,
            templates,
            template,
            myWhatsAppNumberOptions,
            send,
            isSending,
            sendError
        }),
        [
            draftMessage,
            templateOptions,
            templates,
            template,
            myWhatsAppNumberOptions,
            send,
            isSending,
            sendError
        ]
    )

    return (
        <WhatsAppSendMessageContext.Provider value={value}>
            {children}
        </WhatsAppSendMessageContext.Provider>
    )
}

export const useWhatsAppSendMessage = () => {
    const ctx = useContext(WhatsAppSendMessageContext)
    if (!ctx) {
        throw Error('useWhatsAppSendMessage used outside of <WhatsAppSendMessageContextProvider />')
    }
    return ctx
}
