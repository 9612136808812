import logger, { metrics } from '@missionlabs/logger'
import type { Middleware } from '@reduxjs/toolkit'
import { isAnyOf, isRejectedWithValue } from '@reduxjs/toolkit'

import {
    answer,
    blindTransfer,
    call,
    callPickup,
    cancelCallTransfer,
    confirmCallTransfer,
    declineCall,
    hangup,
    hold,
    liveServicesAuth,
    parkCall,
    parkCallGroup,
    retrieveParkedCall,
    startStopRecording,
    toggleMute,
    transferCall,
    unhold,
    updateCall,
    updateConnection
} from '../slices'
import { isAPIError } from '../utils/api'

const CALL_METRIC_ACTIONS = [
    liveServicesAuth,
    call,
    hangup,
    hold,
    unhold,
    declineCall,
    answer,
    transferCall,
    blindTransfer,
    confirmCallTransfer,
    cancelCallTransfer,
    callPickup,
    toggleMute,
    parkCall,
    parkCallGroup,
    retrieveParkedCall,
    startStopRecording,
    updateConnection,
    updateCall
]

export const loggerMiddleware: Middleware = api => next => action => {
    const state = api.getState()

    const user = state.userSlice?.user

    const meta: Record<string, string> = {
        clientID: user?.clientID,
        destinationID: user?.destinationID,
        userID: user?.userID
    }
    if (user) {
        // fixme: use api utils to get query data instead of manually traversing the object like this
        const fetchedClient = state.clientAPI.queries[`getClient(undefined)`]?.data
        const fetchedUser = state.usersAPI.queries[`getUser("${user?.userID}")`]?.data
        if (fetchedClient && fetchedUser) {
            meta.username = `${fetchedUser.username}@${fetchedClient.domain}`
        }

        // general logging
        if (isAnyOf(...CALL_METRIC_ACTIONS)(action)) {
            logger.info(new metrics.call(action.type, action.payload, meta))
        }
    }
    // error handling
    if (isRejectedWithValue(action)) {
        if (isAPIError(action)) {
            const req = action.meta.baseQueryMeta?.request
            const res = action.meta.baseQueryMeta?.response

            logger.error(new metrics.api(action.payload.data, req, res, meta))
        }
    }

    return next(action)
}
