import { ComponentWithAs, Text as ChakraText, TextProps as ChakraTextProps } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

export interface BodyProps extends Omit<ChakraTextProps, 'size' | 'variant'> {
    size?: 'xs' | 'sm' | 'md' | 'lg'
    variant?: 'bold' | 'extra-bold' | 'link' | 'link-bold' | 'none' | 'light'
}

export const Body: ComponentWithAs<'p', PropsWithChildren<BodyProps>> = ({
    children,
    ...props
}) => {
    return <ChakraText {...props}>{children}</ChakraText>
}
