import { useToast } from '@chakra-ui/react'
import {
    ChatMessageNotification,
    removeMessage,
    selectAuthenticatedUser,
    selectMessages,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { usePromisedDebouncedFunction } from '@missionlabs/react'
import { NewChannel } from '@missionlabs/types'
import isElectron from 'is-electron'
import { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { IncomingChatMessageNotification } from 'shared/components/Notifications/IncomingChatMessageNotification'
import { useCreateChannelMessageMutation, useCreateChannelMutation } from 'shared/store'
import { createNotification } from 'shared/utils/notifications/browserNotifications'
import { ElectronNotification } from 'shared/utils/notifications/electronNotifications'
import { NotificationChatMessage } from 'types/global'

import { useMuteChatChannelNotifications } from './useMuteChatChannelNotifications'
import { useNotificationRefs } from './useNotificationRefs'

export const useChatNotificationListener = (
    isEnabled: boolean,
    browserNotificationsEnabled: boolean
) => {
    const toast = useToast()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const messages = useSelector(selectMessages)
    const user = useSelector(selectAuthenticatedUser)

    const { has: isRecentlyDismissed, add: addRecentlyDismissed } = useNotificationRefs()
    const { has, add, remove, dismissOthers } = useNotificationRefs()

    const { onElectronMuteChatChannelNotifications } = useMuteChatChannelNotifications()

    const [_createChat] = useCreateChannelMutation()
    const [_sendMessage] = useCreateChannelMessageMutation()

    const createChat = usePromisedDebouncedFunction(_createChat, 300)
    const sendMessage = usePromisedDebouncedFunction(_sendMessage, 300)

    const presentNotification = useCallback(
        (item: ChatMessageNotification) => {
            toast({
                position: 'top-right',
                duration: null,
                render: ({ onClose: closeToast }) => {
                    const { messageID } = item

                    add(messageID, () => {
                        addRecentlyDismissed(messageID)
                        closeToast()
                    })

                    return (
                        <IncomingChatMessageNotification
                            call={item}
                            navigate={navigate}
                            dispatch={dispatch}
                            onClose={remove}
                        />
                    )
                }
            })
        },
        [add, addRecentlyDismissed, dispatch, navigate, remove, toast]
    )

    useEffect(() => {
        const actionNotificationHandle = (
            _: any,
            type: string,
            message: ChatMessageNotification
        ) => {
            const { messageID, channelID, contactID } = message

            const dismiss = () => {
                dispatch(removeMessage(messageID))
                remove(messageID)
            }

            switch (type) {
                case 'view_contact':
                    navigate(`/contacts/${contactID}`)
                    dismiss()
                    break
                case 'chat_message':
                    navigate(`/chat/${channelID}`)
                    dismiss()
                    break
                case 'chat_mute':
                    onElectronMuteChatChannelNotifications(channelID)
                    dismiss()
                    break
                case 'dismiss':
                    dismiss()
                    break
                default:
                    break
            }
        }
        ElectronNotification.onAction(actionNotificationHandle)

        return () => {
            ElectronNotification.removeOnAction(actionNotificationHandle)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // Listen for new chat messages.
    useEffect(() => {
        if (!isEnabled || !messages) return

        // Dismiss all stale notifications.
        dismissOthers(messages.map(message => message.messageID))

        if (messages.length < 1) return

        messages.forEach(item => {
            const { messageID, channelID, messageBody, sender } = item

            // Ignore notifications already being displayed to prevent spam.
            if (has(messageID) || isRecentlyDismissed(messageID)) return

            // if currently viewing associated channel prevent notification
            if (location.pathname === `/chat/${channelID}`) return

            if (!isElectron()) {
                // In-app notification
                presentNotification(item)
            } else {
                // Electron API notification
                const handle = ElectronNotification.create('/notification/chat/incoming', item)
                add(messageID, async () => {
                    const id = await handle
                    if (!id) return
                    addRecentlyDismissed(messageID)
                    ElectronNotification.action(id, 'dismiss', item)
                })
            }

            // Browser API notification
            if (browserNotificationsEnabled) {
                const { name, company } = sender
                createNotification(`${name} (${company})`, messageBody, { click: window.focus })
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnabled, messages])

    useEffect(() => {
        // sends a request to create a new chat and then after resolution sends the result back to the popup window.
        const createChatHandle = async (_: any, id: number, newChannel: NewChannel) => {
            const result = await createChat(newChannel)
            if ('data' in result) {
                ElectronNotification.createChatReply(id, result.data)
            }
        }
        const sendMessageHandle = (_: any, message: NotificationChatMessage) => {
            sendMessage(message)
        }

        ElectronNotification.onCreateChat(createChatHandle)
        ElectronNotification.onSendChatMessage(sendMessageHandle)

        return () => {
            ElectronNotification.removeOnCreateChat(createChatHandle)
            ElectronNotification.removeOnSendChatMessage(sendMessageHandle)
        }
    }, [createChat, sendMessage])
}
