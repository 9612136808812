import { useVirtualPagination } from '@missionlabs/react'
import { SearchActivitiesRequest, UserActivity } from '@missionlabs/types'
import { generateActivityTypes } from '@missionlabs/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'

import { useGetContactQuery, useLazySearchUserActivitiesQuery } from '../store'
import { ActivityContactEntity, getActivityContactEntity } from '../utils/activities'
import { useAuthenticatedUser } from './useAuthenticatedUser'
import { useFormatToNumberE164 } from './useFormatToNumberE164'

function getSearchParams(entity: ActivityContactEntity) {
    if (entity?.type === 'contact') return { contactID: entity.contactID }
    if (entity?.type === 'number') return { searchTerm: entity.number }
}

/**
 * Get the contact or number "entity" associated with an activity,
 * including the full contact, if it exists
 */
export function useActivityContactEntity(activity?: UserActivity): ActivityContactEntity {
    const { data: contact } = useGetContactQuery(activity?.contactID ?? '', {
        skip: !activity?.contactID || activity?.contact
    })

    const { formatToNumberE164 } = useFormatToNumberE164()

    return useMemo(
        () => getActivityContactEntity(activity, contact, formatToNumberE164),
        [activity, contact, formatToNumberE164]
    )
}

/**
 * Handles pagination for an activity search in a single direction (older or newer).
 *
 * For older activities, also handles reversing the items to keep them in oldest-newest order
 * (since the API returns them in newest-oldest).
 */
function useSearchActivities(
    initialActivity: UserActivity | undefined,
    searchParams: SearchActivitiesRequest | undefined,
    sortDirection: 'asc' | 'desc'
) {
    const { pageSize } = useVirtualPagination()

    const [searchActivities, queryState] = useLazySearchUserActivitiesQuery()
    const [activities, setActivities] = useState<UserActivity[]>()
    const [hasNextPage, setHasNextPage] = useState(true)

    // Keep track to avoid fetching while a fetch is already in progress.
    // For some reason using queryState.isFetching was not working and didn't prevent duplicate fetches
    const fetching = useRef(false)

    const cursor =
        sortDirection === 'desc'
            ? (activities?.at(0) ?? initialActivity)?.created
            : (activities?.at(-1) ?? initialActivity)?.created

    //If an activity isFlaggedByUser field updates need to update state
    useEffect(() => {
        setActivities(activities =>
            activities?.map(activity => {
                const updated = queryState.data?.find(u => u.ID === activity.ID)
                return {
                    ...activity,
                    isFlaggedByUser: updated?.isFlaggedByUser ?? activity.isFlaggedByUser
                }
            })
        )
    }, [queryState.data])

    /** Check for new activities since the latest loaded activity. */
    const fetchMore = useCallback(async () => {
        if (fetching.current) return

        // Only load newer activities if we already have at least one activity to start from
        if (sortDirection === 'asc' && !cursor) return

        try {
            const params: SearchActivitiesRequest = {
                ...searchParams,
                max: pageSize,
                sortDirection
            }

            if (cursor) {
                if (sortDirection === 'desc') {
                    params.fromTime = new Date(new Date(cursor).getTime() - 1).toISOString()
                } else {
                    params.sinceTime = new Date(new Date(cursor).getTime() + 1).toISOString()
                }
            }

            fetching.current = true
            const { data } = await searchActivities(params)

            // If loading older activites, reverse since they are returned newest-first
            const sortedData = data && sortDirection === 'desc' ? [...data].reverse() : data

            if (sortedData) {
                setActivities(activities => {
                    const last = activities?.at(sortDirection === 'desc' ? 0 : -1)?.created
                    if (!last) return sortedData

                    // Avoid duplicates if duplicate fetches were triggered.
                    const newActivities = sortedData.filter(a =>
                        sortDirection === 'desc' ? a.created < last : a.created > last
                    )

                    return sortDirection === 'desc'
                        ? [...newActivities, ...activities]
                        : [...activities, ...newActivities]
                })
            }

            // If we requested 5 activities but only 3 were returned, we've reached the end
            setHasNextPage(Boolean(data && data.length >= pageSize))

            fetching.current = false
            return sortedData
        } catch (e) {
            console.error('Failed to poll activities, resetting pagination:', e)
        }
        fetching.current = false
    }, [cursor, searchParams, searchActivities, setActivities, pageSize, sortDirection])

    // Reset search when params change
    useDeepCompareEffect(() => {
        console.log(`Resetting ${sortDirection} activity search due to parameter change`)
        setActivities(undefined)
    }, [searchParams, sortDirection])

    // Fetch activities if we have none
    // This needs to be a separate effect from the above, to avoid constant resets
    const shouldPerformInitialFetch = activities === undefined && searchParams !== undefined
    useEffect(() => {
        if (shouldPerformInitialFetch) {
            fetchMore()
        }
    }, [shouldPerformInitialFetch, fetchMore])

    return {
        ...queryState,
        activities,
        fetchMore,
        hasNextPage
    }
}

/** Fetch all activities relating to the given activity's contact/number */
export function useRelatedActivities(
    entity: ActivityContactEntity,
    activity?: UserActivity,
    filters: Partial<SearchActivitiesRequest> = {}
) {
    const { data: user } = useAuthenticatedUser()

    // Based on the "entity", we either need to search by contactID or by number
    // The API accepts contactID param for contacts and searchTerm for numbers
    // If the entity isn't known yet, do nothing until it's known.
    const searchParams = useMemo(() => {
        const searchParams = getSearchParams(entity)
        return (
            user &&
            searchParams && {
                ...filters,
                ...searchParams,
                userID: user?.ID
            }
        )
    }, [entity, filters, user])

    // When called from the main activity feed we will have a specific activity that is selected and be displaying
    // activities related to that, so we can do both backwards and forwards pagination from that point in time.
    //
    // When called from the contact feed, we don't have a specific activity we're relating to, we're just fetching all
    // activities, so we need to skip the forward fetch
    const backward = useSearchActivities(activity, searchParams, 'desc')

    // The forward query won't load any data unless there's some reference point to search from.
    // When we've loaded some initial data from the backwards search, at that point we have a valid
    // reference point to enable polling.
    const forwardActivity = activity ?? backward.activities?.at(-1)
    const forward = useSearchActivities(activity ?? forwardActivity, searchParams, 'asc')

    const relatedActivities = useMemo(() => {
        if (!searchParams) return activity ? [activity] : []
        // Don't show anything until the "previous" list loads, otherwise content will jump around
        // Also it looks a bit weird with only 1 activity loaded
        if (!backward.activities) return []

        //Make sure selected activity type is in the filters
        const types = generateActivityTypes(searchParams)
        const selectedActivity = activity && types.includes(activity.activityType) ? [activity] : []

        // We don't need to worry about the "forward" list, adding items at the end won't make the content jump around
        return [...backward.activities, ...selectedActivity, ...(forward.activities || [])]
    }, [searchParams, backward.activities, activity, forward.activities])

    return {
        relatedActivities,
        forward,
        backward,
        isLoadingRelated: forward.isFetching || backward.isFetching,
        isErrorRelated: forward.isError || backward.isError,
        hasNextPage: forward.hasNextPage,
        hasPreviousPage: backward.hasNextPage,
        entity,
        reset: () => {
            // Do something
        },
        refetch: () => {
            // Do something
        }
    }
}
