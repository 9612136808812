import { openURL } from '@missionlabs/utils'
import { Action, useRegisterActions } from 'kbar'
import { useMemo } from 'react'

import { commandMenuShortcuts } from './shortcuts'

export function useHelpActions() {
    const helpActions = useMemo(() => {
        const actions: Action[] = [
            {
                id: 'knowledge-base',
                name: 'Knowledge Base',
                keywords: 'help',
                section: 'Help',
                perform: () => openURL('https://help.circleloop.com'),
                shortcut: commandMenuShortcuts.help,
                priority: -1
            },
            {
                id: 'report-issue',
                name: 'Report an Issue',
                keywords: 'help error',
                section: 'Help',
                perform: () => openURL('https://share.hsforms.com/1SA3pS_VbTRu6EZou12CmWw2ci9m'),
                priority: -1
            }
        ]

        return actions
    }, [])

    useRegisterActions(helpActions)
}
