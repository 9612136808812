export function formatDuration(totalSeconds: number) {
    const seconds = totalSeconds % 60
    const minutes = Math.floor(totalSeconds / 60) % 60
    const hours = Math.floor(totalSeconds / 3600) % 24
    const days = Math.floor(totalSeconds / 86400)

    let duration = ''
    if (days) duration += ` ${days}d`
    if (hours) duration += ` ${hours}h`
    if (minutes) duration += ` ${minutes}m`
    if (seconds || !duration) duration += ` ${seconds}s`
    return duration.trimStart()
}
