import { ControlBar as ChimeControlBar } from 'amazon-chime-sdk-component-library-react'
import styled from 'styled-components'

// Create a new styled component based on one of the components from the component library
const ControlBar = styled(ChimeControlBar)`
    position: absolute;
    bottom: 48px;
    justify-self: center;
    z-index: 5;

    & .ch-control-bar-item-caret {
        margin-left: 3px;
    }

    & > div {
        margin: 0 4px;
    }
`

export default ControlBar
