import { DirectoryEntry, HuntGroup, IVRMenu, User } from '@missionlabs/types'

import { createDirectionalSorter, SortDirection } from './sort'
import { inferFirstLastName } from './string'

export type AlphabetizedContacts = Record<string, DirectoryEntry[]>

export const contactsSortFields = ['firstName', 'lastName'] as const
export type ContactsSortFields = (typeof contactsSortFields)[number]

export interface ContactsFilterOptions {
    sortDirection: SortDirection
    sortField?: ContactsSortFields | ''
    contactTypes: string[]
    contactGroups: string[]
    contactSources: string[]
    contactIntegrations: string[]
}

export type ContactSortOptions = Pick<ContactsFilterOptions, 'sortDirection' | 'sortField'>

/**
 * Contacts grouped by first letter (uppercase). Letters without contacts are omitted.
 * @param contacts The contacts to be alphabetized
 * @param sortField The field of the contact to sort on
 * @example output: { A: DirectoryEntry[], D: DirectoryEntry[], ... }
 */
export function alphabetizeContacts(
    contacts: DirectoryEntry[],
    { sortField }: Omit<ContactSortOptions, 'sortDirection'>
): AlphabetizedContacts {
    return contacts.reduce((result, contact) => {
        // Not all contacts have firstName or lastName, so we use this `inferFirstLastName` util
        // to "guess" the firstName/lastName from the fullName or vice-versa.
        const contactWithAllFields = inferFirstLastName(contact)
        const firstLetter = contactWithAllFields[sortField ?? 'firstName'].charAt(0).toUpperCase()
        if (!result[firstLetter]) result[firstLetter] = [contact]
        else result[firstLetter].push(contact)
        return result
    }, {} as AlphabetizedContacts)
}

export function sortContacts(
    contacts: DirectoryEntry[],
    { sortDirection, sortField }: ContactSortOptions
) {
    if (!sortField) return contacts
    const sorter = createDirectionalSorter(sortDirection)
    return [...contacts].sort((a, b) =>
        sorter(inferFirstLastName(a)[sortField], inferFirstLastName(b)[sortField])
    )
}

export function sortAlphabetizedContacts(
    contacts: AlphabetizedContacts,
    { sortDirection, sortField }: ContactSortOptions
): AlphabetizedContacts {
    const sorter = createDirectionalSorter(sortDirection)

    // Sort the letters themselves
    const withSortedLetters = [...Object.entries(contacts)].sort(([a], [b]) => sorter(a, b))

    if (!sortField) return Object.fromEntries(withSortedLetters)

    // Then sort the contacts within each letter
    const withSortedContacts = withSortedLetters.map(([letter, contacts]) => {
        return [letter, sortContacts(contacts, { sortDirection, sortField })]
    })

    // Convert back to an object
    return Object.fromEntries(withSortedContacts)
}

const internalContactSources = ['userprofile', 'teamprofile']

/**
 * For the purposes of this app, internal contacts are those **created automatically** by the API
 * for users and teams. External contacts are those created within the app or imported from integrations.
 */
export function isInternalContact(contact: DirectoryEntry) {
    return (
        'externalID' in contact &&
        !('importType' in contact) &&
        contact.source &&
        internalContactSources.includes(contact.source)
    )
}

export function isExternalContact(contact: DirectoryEntry) {
    return !internalContactSources.includes(contact.source ?? '')
}

export function isPersonalContact(contact: DirectoryEntry, userID: string) {
    return contact.userID === userID
}
export function isSharedContact(contact: DirectoryEntry) {
    return contact.global && !internalContactSources.includes(contact.source ?? '')
}

export function isTeamContact(contact: DirectoryEntry) {
    return contact.source === 'teamprofile'
}

export function isUserContact(contact: DirectoryEntry) {
    return contact.source === 'userprofile'
}

export function isTeam(
    item: string | DirectoryEntry | HuntGroup | IVRMenu | User
): item is HuntGroup {
    if (typeof item !== 'string' && item.object === 'team') return true
    return false
}

export function isMenu(
    item: string | DirectoryEntry | HuntGroup | IVRMenu | User
): item is IVRMenu {
    if (typeof item !== 'string' && item.object === 'menu') return true
    return false
}

export function isTeamOrMenu(
    item: string | DirectoryEntry | HuntGroup | IVRMenu | User
): item is HuntGroup | IVRMenu {
    return isTeam(item) || isMenu(item)
}

export function isInternal(
    item: string | DirectoryEntry | HuntGroup | IVRMenu
): item is DirectoryEntry {
    if (typeof item !== 'string' && item.object === 'contact' && isInternalContact(item))
        return true
    return false
}

export function isExternal(
    item: string | DirectoryEntry | HuntGroup | IVRMenu
): item is DirectoryEntry {
    if (typeof item !== 'string' && item.object === 'contact' && !isInternalContact(item))
        return true
    return false
}
