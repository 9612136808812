import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const DropdownCircleOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DropdownCircleOutlineIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM15.8233 14.75C16.0545 14.9894 16.0479 15.371 15.8084 15.6023L12.4067 18.8872C12.1673 19.1185 11.7857 19.1118 11.5544 18.8724C11.5454 18.863 11.5367 18.8534 11.5284 18.8436L11.5278 18.843L8.18394 15.4992C7.94855 15.2638 7.94855 14.8821 8.18394 14.6467C8.41934 14.4113 8.80099 14.4113 9.03639 14.6467L11.9968 17.6072L14.971 14.7351C15.2104 14.5038 15.592 14.5105 15.8233 14.75ZM15.8233 10.25C16.0545 10.0106 16.0479 9.62896 15.8084 9.39771L12.4067 6.11277C12.1673 5.88151 11.7857 5.88818 11.5544 6.12764C11.5454 6.13701 11.5367 6.1466 11.5284 6.15638C11.5282 6.15659 11.528 6.1568 11.5278 6.157L8.18394 9.50081C7.94855 9.73621 7.94855 10.1179 8.18394 10.3533C8.41934 10.5887 8.80099 10.5887 9.03639 10.3533L11.9968 7.39282L14.971 10.2649C15.2104 10.4962 15.592 10.4895 15.8233 10.25Z"
                fill="#616E7C"
            />
            <mask
                id="mask0_506_48"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM15.8233 14.75C16.0545 14.9894 16.0479 15.371 15.8084 15.6023L12.4067 18.8872C12.1673 19.1185 11.7857 19.1118 11.5544 18.8724C11.5454 18.863 11.5367 18.8534 11.5284 18.8436L11.5278 18.843L8.18394 15.4992C7.94855 15.2638 7.94855 14.8821 8.18394 14.6467C8.41934 14.4113 8.80099 14.4113 9.03639 14.6467L11.9968 17.6072L14.971 14.7351C15.2104 14.5038 15.592 14.5105 15.8233 14.75ZM15.8233 10.25C16.0545 10.0106 16.0479 9.62896 15.8084 9.39771L12.4067 6.11277C12.1673 5.88151 11.7857 5.88818 11.5544 6.12764C11.5454 6.13701 11.5367 6.1466 11.5284 6.15639C11.5282 6.15659 11.528 6.1568 11.5278 6.157L8.18394 9.50081C7.94855 9.73621 7.94855 10.1179 8.18394 10.3533C8.41934 10.5887 8.80099 10.5887 9.03639 10.3533L11.9968 7.39282L14.971 10.2649C15.2104 10.4962 15.592 10.4895 15.8233 10.25Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_506_48)"></g>
        </g>
    )
})

export const DropdownCircleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DropdownCircleIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM15.8233 14.75C16.0545 14.9894 16.0479 15.371 15.8084 15.6023L12.4067 18.8872C12.1673 19.1185 11.7857 19.1118 11.5544 18.8724C11.5454 18.863 11.5367 18.8534 11.5284 18.8436L11.5278 18.843L8.18394 15.4992C7.94855 15.2638 7.94855 14.8821 8.18394 14.6467C8.41934 14.4113 8.80099 14.4113 9.03639 14.6467L11.9968 17.6072L14.971 14.7351C15.2104 14.5038 15.592 14.5105 15.8233 14.75ZM15.8233 10.25C16.0545 10.0106 16.0479 9.62896 15.8084 9.39771L12.4067 6.11277C12.1673 5.88151 11.7857 5.88818 11.5544 6.12764C11.5454 6.13701 11.5367 6.1466 11.5284 6.15638C11.5282 6.15659 11.528 6.1568 11.5278 6.157L8.18394 9.50081C7.94855 9.73621 7.94855 10.1179 8.18394 10.3533C8.41934 10.5887 8.80099 10.5887 9.03639 10.3533L11.9968 7.39282L14.971 10.2649C15.2104 10.4962 15.592 10.4895 15.8233 10.25Z"
                fill="currentColor"
            />
            <mask
                id="mask0_506_45"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM15.8233 14.75C16.0545 14.9894 16.0479 15.371 15.8084 15.6023L12.4067 18.8872C12.1673 19.1185 11.7857 19.1118 11.5544 18.8724C11.5454 18.863 11.5367 18.8534 11.5284 18.8436L11.5278 18.843L8.18394 15.4992C7.94855 15.2638 7.94855 14.8821 8.18394 14.6467C8.41934 14.4113 8.80099 14.4113 9.03639 14.6467L11.9968 17.6072L14.971 14.7351C15.2104 14.5038 15.592 14.5105 15.8233 14.75ZM15.8233 10.25C16.0545 10.0106 16.0479 9.62896 15.8084 9.39771L12.4067 6.11277C12.1673 5.88151 11.7857 5.88818 11.5544 6.12764C11.5454 6.13701 11.5367 6.1466 11.5284 6.15639C11.5282 6.15659 11.528 6.1568 11.5278 6.157L8.18394 9.50081C7.94855 9.73621 7.94855 10.1179 8.18394 10.3533C8.41934 10.5887 8.80099 10.5887 9.03639 10.3533L11.9968 7.39282L14.971 10.2649C15.2104 10.4962 15.592 10.4895 15.8233 10.25Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_506_45)"></g>
        </g>
    )
})

export const DropdownMultiIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DropdownMultiIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M8.32322 13.668L12.009 17.354L15.7114 13.7808C16.0164 13.4862 16.5034 13.4956 16.8038 13.8066C17.1021 14.1155 17.1001 14.6013 16.7915 14.8993L12.5467 18.9985C12.2817 19.2543 11.8796 19.2809 11.5816 19.0797C11.5158 19.0425 11.4529 18.9967 11.3964 18.9402L7.22371 14.7676C6.92384 14.4677 6.92477 13.9806 7.23051 13.6748C7.53413 13.3712 8.01988 13.3647 8.32322 13.668ZM11.5796 4.13451C11.8778 3.93187 12.2811 3.95795 12.5467 4.21436L16.7915 8.31356C17.1001 8.61156 17.1021 9.09734 16.8038 9.40622C16.5034 9.71724 16.0164 9.72667 15.7114 9.43209L12.01 5.857L8.32322 9.54482C8.04746 9.82058 7.62094 9.84026 7.31756 9.61344L7.23051 9.53801C6.92477 9.23228 6.92384 8.74517 7.22371 8.44531L11.3964 4.27265C11.4515 4.2175 11.5127 4.17259 11.5777 4.13785L11.5796 4.13451Z"
            fill="currentColor"
        />
    )
})
