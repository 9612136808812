import { UserRole } from '@missionlabs/types'
import { PropsWithChildren } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthenticatedUser } from 'shared/hooks/useAuthenticatedUser'
import { useUserRole } from 'shared/hooks/useUserRole'

export interface RequireRoleProps {
    role: UserRole
    /** If true will redirect to /forbidden, otherwise will render nothing. */
    redirect?: boolean
}

export const RequireRole = ({
    role,
    redirect = false,
    children
}: PropsWithChildren<RequireRoleProps>) => {
    const { data: user } = useAuthenticatedUser()
    const location = useLocation()
    const userRole = useUserRole()

    const hasRequiredRole = userRole === UserRole.ADMIN || userRole === role

    if (!user) return null
    if (hasRequiredRole) return <>{children}</>
    if (redirect) return <Navigate to="/forbidden" replace state={{ path: location.pathname }} />
    return null
}
