import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const UnderlineIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'UnderlineIconLight',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M0.875 1.28906L2.75 1.25V9.80469C2.75 13.125 5.25 16.0156 8.57031 16.25C12.2031 16.4844 15.25 13.5938 15.25 10V1.25L17.125 1.28906C17.4375 1.28906 17.75 0.976562 17.75 0.625C17.75 0.3125 17.4375 0 17.125 0L12.125 0.0390625C11.7734 0.0390625 11.5 0.3125 11.5 0.625C11.5 0.976562 11.7734 1.25 12.125 1.25H14V9.84375C14 12.5 11.9688 14.8438 9.3125 15C6.38281 15.1953 4 12.8906 4 10V1.25H5.875C6.1875 1.25 6.5 0.976562 6.5 0.625C6.5 0.3125 6.1875 0.0390625 5.875 0.0390625L0.875 0C0.523438 0 0.25 0.3125 0.25 0.625C0.25 0.976562 0.523438 1.28906 0.875 1.28906ZM17.125 18.75H0.875C0.523438 18.75 0.25 19.0625 0.25 19.375C0.25 19.7266 0.523438 20 0.875 20H17.125C17.4375 20 17.75 19.7266 17.75 19.375C17.75 19.0625 17.4375 18.75 17.125 18.75Z"
            fill="currentColor"
        />
    )
})

export const UnderlineIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'UnderlineIconRegular',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M1.8125 1.875H2.75V9.375C2.75 12.8516 5.52344 15.625 9 15.625C12.4375 15.625 15.25 12.8516 15.25 9.375V1.875H16.1875C16.6953 1.875 17.125 1.48438 17.125 0.9375C17.125 0.429688 16.6953 0 16.1875 0H12.4375C11.8906 0 11.5 0.429688 11.5 0.9375C11.5 1.48438 11.8906 1.875 12.4375 1.875H13.375V9.375C13.375 11.7969 11.3828 13.75 9 13.75C6.57812 13.75 4.625 11.7969 4.625 9.375V1.875H5.5625C6.07031 1.875 6.5 1.48438 6.5 0.9375C6.5 0.429688 6.07031 0 5.5625 0H1.8125C1.26562 0 0.875 0.429688 0.875 0.9375C0.875 1.48438 1.26562 1.875 1.8125 1.875ZM16.8125 18.125H1.1875C0.640625 18.125 0.25 18.5547 0.25 19.0625C0.25 19.6094 0.640625 20 1.1875 20H16.8125C17.3203 20 17.75 19.6094 17.75 19.0625C17.75 18.5547 17.3203 18.125 16.8125 18.125Z"
            fill="currentColor"
        />
    )
})

export const UnderlineIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'UnderlineIconSolid',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M16.5 17.5H1.5C0.796875 17.5 0.25 18.0859 0.25 18.75C0.25 19.4531 0.796875 20 1.5 20H16.5C17.1641 20 17.75 19.4531 17.75 18.75C17.75 18.0859 17.1641 17.5 16.5 17.5ZM2.125 2.53906H2.75V8.78906C2.75 12.2266 5.52344 15 9 15C12.4375 15 15.25 12.1875 15.25 8.78906V2.53906H15.875C16.5391 2.53906 17.125 1.95312 17.125 1.28906C17.125 0.585938 16.5391 0.0390625 15.875 0.0390625H12.125C11.4219 0.0390625 10.875 0.625 10.875 1.28906C10.875 1.99219 11.4219 2.53906 12.125 2.53906H12.75V8.78906C12.75 10.8594 11.0312 12.5391 9 12.5391C6.92969 12.5391 5.25 10.8203 5.25 8.75V2.5H5.875C6.53906 2.5 7.125 1.95312 7.125 1.25C7.125 0.585938 6.53906 0 5.875 0L2.125 0.0390625C1.42188 0.0390625 0.875 0.585938 0.875 1.25C0.875 1.875 1.42188 2.53906 2.125 2.53906Z"
            fill="currentColor"
        />
    )
})
