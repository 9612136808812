import { TableContainer } from '@chakra-ui/react'
import { ActivityIcon, Player, Table } from '@missionlabs/react'
import { UserActivity, UserRole } from '@missionlabs/types'
import { downloadURL } from '@missionlabs/utils'
import { createColumnHelper } from '@tanstack/react-table'
import { format, isToday, isYesterday } from 'date-fns'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useUserRole } from 'shared/hooks/useUserRole'
import { useDeleteAdminCallRecordingMutation } from 'shared/store'
import { getNameAndNumberFromActivity } from 'shared/utils/activities'

import AnalyticsActivitiesCallerColumn from './AnalyticsActivitiesCallerColumn'

type AnalyticsActivitiesProps = {
    activities: UserActivity[]
}
export type UserActivityWithNumberColumnDisplay = UserActivity & { numberColumnDisplay: string }

const columnhelper = createColumnHelper<UserActivityWithNumberColumnDisplay>()

export const AnalyticsActivities: FC<AnalyticsActivitiesProps> = ({ activities }) => {
    const { t } = useTranslation()

    const { formatToLocalNumber } = useFormatToNumberE164()
    const [deleteAdminRecording] = useDeleteAdminCallRecordingMutation()

    const userRole = useUserRole()

    /** Appends property numberColumnDisplay to the activities for the table so that we can
     *  use the accessor to get the value and keeps the ability to sort by the number column */
    const activitiesWithNumberColumnDisplay = useMemo(() => {
        return activities.map(activity => ({
            ...activity,
            numberColumnDisplay: getNameAndNumberFromActivity(activity, formatToLocalNumber, t)
        }))
    }, [activities, formatToLocalNumber, t])

    const columns = useMemo(
        () => [
            columnhelper.accessor('activityType', {
                cell: ({ row }) => (
                    <ActivityIcon
                        activityType={row.original.activityType}
                        callLength={row.original.callLength}
                    />
                ),
                id: 'activityType',
                header: '',
                size: 12,
                meta: {
                    align: 'center'
                }
            }),
            columnhelper.accessor('numberColumnDisplay', {
                cell: ({ row }) => <AnalyticsActivitiesCallerColumn activity={row.original} />,
                header: t('Caller'),
                id: 'caller',
                meta: {
                    align: 'start'
                }
            }),

            columnhelper.accessor(activity => activity.created, {
                cell: info => {
                    const parsedDate = new Date(info.getValue())
                    if (isToday(parsedDate)) {
                        return `${t('Today')}, ${format(parsedDate, 'HH:mm')}`
                    } else if (isYesterday(parsedDate)) {
                        return `${t('Yesterday')}, ${format(parsedDate, 'HH:mm')}`
                    } else {
                        return format(parsedDate, 'dd/MM/yy HH:mm')
                    }
                },
                id: 'time',
                header: 'Time'
            }),
            columnhelper.display({
                header: 'Call Recording',
                id: 'adminRecordingURL',
                cell: data => {
                    const { adminRecordingURL, userRecordingURL } = data.row.original
                    if (adminRecordingURL && userRole === UserRole.ADMIN) {
                        return (
                            <Player
                                variant="table"
                                sx={{ w: '352px' }}
                                url={adminRecordingURL}
                                onDownload={() => downloadURL(adminRecordingURL)}
                                onDelete={() => {
                                    deleteAdminRecording({ activityID: data.row.original.ID })
                                }}
                            />
                        )
                    } else if (userRecordingURL) {
                        return (
                            <Player
                                variant="table"
                                sx={{ w: '352px' }}
                                url={userRecordingURL}
                                onDownload={() => downloadURL(userRecordingURL)}
                            />
                        )
                    }

                    return null
                }
            })
        ],
        [t, deleteAdminRecording, userRole]
    )

    return (
        <TableContainer>
            <Table
                columns={columns}
                data={activitiesWithNumberColumnDisplay}
                pageCount={activitiesWithNumberColumnDisplay.length}
                paginate={true}
                sorting={[{ id: 'time', desc: true }]}
            />
        </TableContainer>
    )
}
