import { Code } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import logger, { transports } from '@missionlabs/logger'
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay
} from '@missionlabs/react'
import { DestinationTypes } from '@missionlabs/types'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import isElectron from 'is-electron'
import { FC, useRef, useState } from 'react'
import { ErrorLayout } from 'shared/components/ErrorLayout'
import { monitoring } from 'shared/monitoring/monitoring'
import { useGetLogsConfigQuery, useLazyGetLogsURLQuery, useUploadLogsMutation } from 'shared/store'

const noop = () => {}

interface ErrorFallbackProps {
    error: string | undefined
}

export const ErrorFallback: FC<ErrorFallbackProps> = ({ error }) => {
    const ref = useRef<HTMLButtonElement>(null)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const user = useSelector(selectAuthenticatedUser)

    const { data: config, isLoading: configLoading } = useGetLogsConfigQuery(user ?? skipToken)
    const [getURL] = useLazyGetLogsURLQuery()
    const [uploadLogs] = useUploadLogsMutation()

    async function uploadLogsAndReload() {
        try {
            // Record error to aws-rum monitoring
            if (error) monitoring.recordError(error)

            if (!config?.loggingEnabled) throw new Error('Logging disabled')

            setIsSubmitting(true)

            const device = isElectron() ? DestinationTypes.desktopApp : DestinationTypes.webApp
            const url = await getURL({ brand: 'cl', device }).unwrap()
            const transport = logger.transports.find(transports.indexeddb.find)
            if (!transport) throw new Error('No logs found')

            const logLines = await transport.dumpLogs()
            await uploadLogs({ ...url, logLines, name: __NAME__, version: __VERSION__ }).unwrap()
        } catch (e) {
            console.error('Failed to process logs!', e)
        } finally {
            setIsSubmitting(false)
            window.location.reload()
        }
    }

    return (
        <>
            <ErrorLayout />
            <Dialog leastDestructiveRef={ref} isOpen onClose={noop}>
                <DialogOverlay backdropFilter="blur(4px)" />
                <DialogContent>
                    <DialogHeader>Uh oh! Something went wrong 😥</DialogHeader>
                    <DialogBody>
                        Unfortunately, Circleloop encountered an unrecoverable error.
                        {__DEV__ && <Code>{error}</Code>}
                        <br />
                        We'll automatically report the issue to our team, please click below to
                        reload the app.
                    </DialogBody>
                    <DialogFooter
                        cancel={{ ref, label: 'Reload', onClick: uploadLogsAndReload }}
                        isDisabled={configLoading}
                        isLoading={isSubmitting}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}
