import { CheckboxGroup, VStack } from '@chakra-ui/react'
import { Checkbox } from '@missionlabs/react'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export type AdvancedSetting = 'autoGainControl' | 'noiseSuppression' | 'echoCancellation'
export const settingsList = ['autoGainControl', 'noiseSuppression', 'echoCancellation'] as const

interface SoundAdvancedSettingProps {
    values: AdvancedSetting[]
    onChange: (values: AdvancedSetting[]) => void
}

export const SoundAdvancedSetting: FC<SoundAdvancedSettingProps> = ({ values, onChange }) => {
    const { t } = useTranslation()
    return (
        <SettingsRow label={t('settings.sounds.advancedSettings')}>
            <VStack spacing="8px" align="inherit">
                <CheckboxGroup value={values} onChange={onChange}>
                    {settingsList.map(setting => (
                        <Checkbox key={setting} value={setting}>
                            {t(`settings.sounds.${setting}`)}
                        </Checkbox>
                    ))}
                </CheckboxGroup>
            </VStack>
        </SettingsRow>
    )
}
