import { HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Call, useDispatch, useSelector } from '@missionlabs/api'
import { Body, Button, Textarea, useToast } from '@missionlabs/react'
import { ChangeEvent, FC, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
    addCallNote,
    CallNoteSliceRootState,
    selectCallNote,
    updateCallNote
} from 'shared/slices/callNoteSlice'
import { useCreateCallNoteMutation, useUpdateCallNoteMutation } from 'shared/store'

const MAX_CHARACTERS = 300

interface CallNotesProps {
    call: Call
    onSave?: () => void
}

export const CallNotes: FC<CallNotesProps> = ({ call, onSave }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { toast } = useToast()
    const dispatch = useDispatch()

    const { callTraceID } = call
    const callNote = useSelector((state: CallNoteSliceRootState) =>
        selectCallNote(state, callTraceID)
    )

    const [createCallNote] = useCreateCallNoteMutation()
    const [editCallNote] = useUpdateCallNoteMutation()

    // When the textarea value changes, update the call note in the store.
    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        // `value.slice` is used to cap the string length so other actions like pasting text are supported.
        const value = e.target.value.slice(0, MAX_CHARACTERS)

        if (!callNote) {
            dispatch(addCallNote({ callTraceID, note: value }))
            return
        }

        dispatch(
            updateCallNote({ callTraceID, note: value, isSaved: value === callNote.savedNote })
        )
    }

    const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (!callNote) return
        const content = callNote.note

        if (content === callNote.savedNote) return
        try {
            // If we haven't saved this note to the API yet, it won't have an ID
            // so we save it, then update the store with the new ID.
            if (!callNote.ID) {
                const { ID } = await createCallNote({ content, callTraceID }).unwrap()
                dispatch(updateCallNote({ callTraceID, ID, isSaved: true, savedNote: content }))
            } else {
                await editCallNote({ callNoteID: callNote.ID, content }).unwrap()
                dispatch(updateCallNote({ callTraceID, isSaved: true, savedNote: content }))
            }
            toast({ title: t('Call Note Saved'), status: 'success' })
            onSave?.()
        } catch (ex) {
            toast({ title: t('Call Note Saved Error'), status: 'error' })
            dispatch(updateCallNote({ callTraceID, isSaved: false }))
        }
    }

    return (
        <VStack w="full" spacing="8px">
            <HStack justifyContent="space-between" w="full">
                <Body size="sm" sx={{ color: `${colorMode}.tones.periwinkle` }}>
                    {t('Call Notes')} {callNote?.note && !callNote.isSaved && `(${t('Unsaved')})`}
                </Body>
                <Body size="sm" sx={{ color: `${colorMode}.tones.periwinkle` }}>
                    {callNote?.note?.length || 0}/{MAX_CHARACTERS}
                </Body>
            </HStack>
            <Textarea
                aria-label="call notes"
                value={callNote?.note || ''}
                onChange={onChange}
                placeholder={t('Enter notes')}
                data-testid="call-notes"
                height="177px"
                variant="dark"
            />
            <Button variant="dialler" h="48px" w="full" onClick={handleSubmit}>
                Update call notes
            </Button>
        </VStack>
    )
}
