import { useColorMode } from '@chakra-ui/react'
import {
    BoldTranslation,
    Checkbox,
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay
} from '@missionlabs/react'
import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAddNumberMutation } from 'shared/store'

import { AddNumberFields } from './AddNumberForm'
import { useAddNumberUtils } from './useAddNumberUtils'

const noop = () => {}

export interface AddNumberDialogProps {
    selected: AddNumberFields | undefined
    onClose: (parent?: boolean) => void
}

export const AddNumberDialog: FC<AddNumberDialogProps> = ({ selected, onClose }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { formatNumber, formatPrice } = useAddNumberUtils()

    const ref = useRef<HTMLButtonElement>(null)
    const [confirm, setConfirm] = useState<boolean>(false)

    const [addNumber, { isLoading }] = useAddNumberMutation()

    const selectedNumberE164 = selected?.selectedNumberE164
    const number = formatNumber(selectedNumberE164?.numberE164, selected?.numberType)
    const price = formatPrice(selectedNumberE164?.price, selectedNumberE164?.localisedPrices)

    async function handleAdd() {
        if (!selectedNumberE164) return
        const { ID, numberE164 } = selectedNumberE164
        await addNumber({ numberID: ID, numberE164 }).unwrap()
        return onClose(true)
    }

    if (!selected) return null

    return (
        <Dialog leastDestructiveRef={ref} isOpen onClose={isLoading ? noop : onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>{t('admin.numbers.dialog.header', { number })}</DialogHeader>
                <DialogBody gap="4px">
                    <BoldTranslation
                        i18nKey="admin.numbers.dialog.body"
                        color={`${colorMode}.red.dark`}
                        fontWeight={500}
                        values={{ number, price }}
                    />
                    <Checkbox ml="16px" onChange={e => setConfirm(e.target.checked)}>
                        {t('admin.numbers.dialog.confirm')}
                    </Checkbox>
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('admin.numbers.dialog.submit'),
                        isDisabled: !confirm,
                        onClick: handleAdd
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
