import { useFuzzySearch } from '@missionlabs/react'
import { useState } from 'react'

export const useCreateReportSearch = <T>(items: T[], key: keyof T) => {
    const [searchValue, setSearchValue] = useState('')

    const { results } = useFuzzySearch<T>({ items, searchValue, keys: [key] })

    return { results, searchValue, setSearchValue }
}
