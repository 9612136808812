import { useColorMode, VStack } from '@chakra-ui/react'
import { Scrollable, useMeasure, VirtualPaginationContextProvider } from '@missionlabs/react'
import { SearchIconRegular } from '@missionlabs/react/zeta'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchInput } from 'shared/components/SearchInput'

import { useAddEditMeetingDirectorySearch } from '../hooks/useAddEditMeetingDirectorySearch'
import { DirectoryList } from './DirectoryList'
import { ShareToGuest } from './ShareToGuest'

type MeetingCreateShareFormProps = {}

export const ShareDirectoryForm: FC<MeetingCreateShareFormProps> = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const [linkRef, { blockSize: linkHeight }] = useMeasure<HTMLDivElement>()

    const {
        contacts,
        search,
        noSearchResults,
        showEmailWarning,
        handleSearch,
        clearSearch,
        filterParams
    } = useAddEditMeetingDirectorySearch()

    return (
        <VStack h={'100%'} mx="24px">
            <div
                ref={linkRef}
                style={{
                    padding: '16px 24px 0px 24px',
                    height: '81px',
                    gap: '24px',
                    width: '100%',
                    borderBottom: '1px solid'
                }}
            >
                <SearchInput
                    value={search}
                    hideFilters
                    onChange={handleSearch}
                    placeholder={t('Enter name or email')}
                    rightElement={<SearchIconRegular />}
                    sx={{
                        border: '1px solid !important',
                        borderColor: `${colorMode}.tones.periwinkle !important`,
                        height: '40px'
                    }}
                    width="100%"
                />
            </div>
            {showEmailWarning ? (
                <ShareToGuest email={search} />
            ) : (
                <VStack
                    spacing={0}
                    flex={1}
                    w="full"
                    px="16px"
                    align="stretch"
                    overflow="hidden"
                    bg={`${colorMode}.tones.ghostWhite`}
                    h={'100%'}
                >
                    <VirtualPaginationContextProvider>
                        <DirectoryList
                            searchedContacts={search.length ? contacts : []}
                            clearSearch={clearSearch}
                            scrollElement={
                                <Scrollable h={`100% `} bg={`${colorMode}.tones.ghostWhite`} />
                            }
                            noSearchResults={noSearchResults}
                            filterParams={filterParams}
                            siblingHeight={linkHeight}
                        />
                    </VirtualPaginationContextProvider>
                </VStack>
            )}
        </VStack>
    )
}
