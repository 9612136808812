import { DeploymentEnvironments } from '@missionlabs/types'

const getEnvRegex = (env?: string) => {
    const url = '(meetings|guestapp|webapp|cl\\-app)\\.(viazeta|circleloop)\\.com'
    if (env) return new RegExp(`^${env}-${url}$`, 'i')
    return new RegExp(`^${url}$`, 'i')
}

const getSubDomain = (host: string) => {
    // Split the hostname into parts
    const parts = host.split('.')
    //subdomain will have more than 2 parts
    return parts.length > 2 ? parts[0] : undefined
}

const checkForEnv = (host: string, env: string) => {
    //First check if we have {env}.domain.com
    const subdomain = getSubDomain(host)
    if (subdomain === env) return true
    return getEnvRegex(env).test(host)
}

export function getDeploymentEnvironment(): DeploymentEnvironments | undefined {
    // web deployment environment
    const { host } = window.location
    if (host.startsWith('localhost')) return DeploymentEnvironments.dev
    if (checkForEnv(host, 'dev')) return DeploymentEnvironments.dev
    if (checkForEnv(host, 'uat')) return DeploymentEnvironments.uat
    if (checkForEnv(host, 'beta')) return DeploymentEnvironments.prod
    if (checkForEnv(host, 'prod')) return DeploymentEnvironments.prod
    if (getEnvRegex().test(host)) return DeploymentEnvironments.prod

    // app deployment environment
    const env = process.env.DEPLOY_ENV as DeploymentEnvironments | undefined
    if (env) return env
}

export const isBeta = getSubDomain(window.location.host) === 'beta'

const flagsmithEnvironments: Record<DeploymentEnvironments, string> = {
    [DeploymentEnvironments.dev]: process.env.FLAGSMITH_DEV as string,
    [DeploymentEnvironments.uat]: process.env.FLAGSMITH_UAT as string,
    [DeploymentEnvironments.prod]: process.env.FLAGSMITH_PROD as string
}

export const getFlagsmithEnvironment = () => {
    const deploymentEnvironment = getDeploymentEnvironment()
    return deploymentEnvironment
        ? flagsmithEnvironments[deploymentEnvironment]
        : flagsmithEnvironments.dev
}

const logsEnvironments: Record<DeploymentEnvironments, string> = {
    [DeploymentEnvironments.dev]: process.env.DEV_APPLICATION_LOG_API_KEY as string,
    [DeploymentEnvironments.uat]: process.env.PRD_APPLICATION_LOG_API_KEY as string,
    [DeploymentEnvironments.prod]: process.env.PRD_APPLICATION_LOG_API_KEY as string
}

export const getLogsEnvironment = () => {
    const deploymentEnvironment = getDeploymentEnvironment()
    return deploymentEnvironment ? logsEnvironments[deploymentEnvironment] : logsEnvironments.dev
}

const stripeEnvironments: Record<DeploymentEnvironments, string> = {
    [DeploymentEnvironments.dev]: process.env.DEV_STRIPE_API_KEY as string,
    [DeploymentEnvironments.uat]: process.env.DEV_STRIPE_API_KEY as string,
    [DeploymentEnvironments.prod]: process.env.PRD_STRIPE_API_KEY as string
}

export const getStripeEnvironment = () => {
    const deploymentEnvironment = getDeploymentEnvironment()
    return deploymentEnvironment
        ? stripeEnvironments[deploymentEnvironment]
        : stripeEnvironments.dev
}

interface PopulateUrlParams {
    dataCentre: string
    environment: string
    country: string
    product?: string
}

export function populateUrl(
    url: string,
    params: PopulateUrlParams,
    replacePreceding?: boolean
): string {
    const { dataCentre, environment, country, product } = params
    if (dataCentre === 'localhost') return 'http://localhost:8000/zeta'

    let sanitizedUrl: string = url

    // We want -dev and -uat, but for prod we just want to remove the environment altogether
    if (environment === DeploymentEnvironments.prod) {
        sanitizedUrl = sanitizedUrl.replace('-{environment}', '')
        if (replacePreceding) sanitizedUrl = sanitizedUrl.replace('{environment}-', '')
    }

    return sanitizedUrl
        .replace('{datacentre}', dataCentre)
        .replace('{environment}', environment)
        .replace('{country}', country)
        .replace('{product}', product ?? '')
}
