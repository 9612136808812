import { useSelect, UseSelectMode } from '@missionlabs/react'
import { DirectoryEntry, HuntGroup, IVRMenu } from '@missionlabs/types'
import { CSSProperties } from 'react'

import { ContactsListTitle } from './ContactsListTitle'
import { ContactsRow } from './ContactsRow'

interface ContactsRowRendererProps {
    data: (string | DirectoryEntry | HuntGroup | IVRMenu)[]
    index: number
    style: CSSProperties
}

function isTitle(item: string | DirectoryEntry | HuntGroup | IVRMenu): item is string {
    return typeof item === 'string'
}

export const ContactsRowRenderer = ({ data, index, style }: ContactsRowRendererProps) => {
    const { toggle, isSelected } = useSelect(UseSelectMode.contacts)
    const item = data[index]

    if (isTitle(item)) {
        return (
            <div style={style} role="listitem">
                <ContactsListTitle label={item} />
            </div>
        )
    }

    return (
        <div style={{ ...style, padding: '0px 24px' }} role="listitem">
            <ContactsRow
                key={item.ID}
                data={item}
                isActive={isSelected(item.ID)}
                onClick={() => toggle(item.ID, true)}
            />
        </div>
    )
}
