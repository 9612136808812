import { useColorMode, useMultiStyleConfig, useToken } from '@chakra-ui/react'
import { get, Input, useFormContext, ValidatedInput, ValidatedInputProps } from '@missionlabs/react'
import {
    StripeCardCvcElementChangeEvent,
    StripeCardExpiryElementChangeEvent,
    StripeCardNumberElementChangeEvent
} from '@stripe/stripe-js'
import { ComponentProps, ElementType, ReactElement, useState } from 'react'

import { AddPaymentMethodFields } from './AddCardPaymentMethodForm'

type ChangeEvent =
    | StripeCardNumberElementChangeEvent
    | StripeCardExpiryElementChangeEvent
    | StripeCardCvcElementChangeEvent

type FocusEvent = {
    elementType: 'cardCvc' | 'cardExpiry' | 'cardNumber'
}

type BaseProps<T extends ElementType> = Omit<ValidatedInputProps, 'as'> & ComponentProps<T>

export type StripeValidatedInputProps<T extends ElementType> = BaseProps<T> & {
    as: Required<ValidatedInputProps['as']>
}

export const StripeValidatedInput = ({
    as,
    name,
    label,
    ...props
}: StripeValidatedInputProps<typeof as>): ReactElement => {
    const { colorMode } = useColorMode()
    const { input } = useMultiStyleConfig('CustomInput')
    const {
        formState: { errors },
        setValue,
        watch
    } = useFormContext<AddPaymentMethodFields>()

    const [stripeError, setStripeError] = useState<string | undefined>()

    const [baseColor, invalidColor] = useToken('colors', [
        input.color as string,
        // @ts-ignore
        input._invalid.color as string
    ])
    const styles = {
        container: {
            borderColor: `${colorMode}.red.default`
        },
        input: {
            color: baseColor,
            fontFamily: input.fontFamily,
            fontSize: input.fontSize,
            fontWeight: input.fontWeight,
            fontSmoothing: 'antialiased'
        }
    }

    const error = stripeError ?? get(errors, name)
    const value = watch(name)

    function handleChange(e: ChangeEvent) {
        const name = `_${e.elementType}` as const
        setValue(name, !e.empty ? '_' : '', { shouldDirty: !e.empty, shouldValidate: true })
        setStripeError(e.error?.message)
    }

    function handleFocus(e: FocusEvent) {
        setValue(`_${e.elementType}`, value, { shouldTouch: true })
    }

    return (
        <ValidatedInput
            name={name}
            label={label}
            error={error}
            containerSx={{
                // @ts-ignore
                '.StripeElement--empty': error ? styles.container : undefined,
                '.StripeElement--invalid': styles.container
            }}
            errorSx={{ whiteSpace: 'nowrap' }}
            shouldRegister={false}
        >
            <Input
                {...props}
                as={as}
                options={{
                    ...props.options,
                    style: { base: styles.input, invalid: { color: invalidColor } }
                }}
                onChange={handleChange}
                onFocus={handleFocus}
            />
        </ValidatedInput>
    )
}
