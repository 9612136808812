import { Box } from '@chakra-ui/react'
import { selectAuthenticatedUser, useDispatch, useSelector } from '@missionlabs/api'
import { useMeasure } from '@missionlabs/react'
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { useGetMessages } from 'shared/hooks/useGetChatMessages'
import {
    chatAPI,
    useCreateChannelMessageMutation,
    useEditChannelMessageMutation,
    useGetChannelQuery
} from 'shared/store'

import { Chatbox } from './Chatbox'
import { ChatHeader } from './ChatHeader'
import { ChatHistory } from './ChatHistory'
import { useSearchMessages } from './hooks/useSearchMessages'

export interface ChatViewProps {}
export interface Pagination {
    channelID: string
    startFrom?: string
    direction?: 'next' | 'prev'
    size?: number
    sort?: 'asc' | 'desc'
    reverse?: boolean
    includeFirstMessage?: boolean
    clearCache?: boolean
    search?: string
    offset?: number
}

export const ChatView: FC<ChatViewProps> = () => {
    const { id = '', messageID, threadMessageID } = useParams()
    const [ref, { height }] = useMeasure<HTMLDivElement>()
    const user = useSelector(selectAuthenticatedUser)
    const [pagination, setPagination] = useState<Pagination>({
        channelID: id,
        startFrom: messageID,
        includeFirstMessage: !!messageID,
        size: 50,
        sort: messageID ? 'asc' : 'desc',
        clearCache: true
    })
    const {
        data: channel,
        isLoading: channelLoading,
        isError: channelError
    } = useGetChannelQuery(id, { skip: !id })

    const {
        data: messages,
        isLoading: messagesLoading,
        isError: messagesError,
        isFetching: messagesFetching
    } = useGetMessages(id, threadMessageID, pagination)

    const searchMessageResults = useSearchMessages(id)

    const isLoading = channelLoading || messagesLoading
    const isError = channelError || messagesError
    const [sendMessage] = useCreateChannelMessageMutation()
    const [editMessage] = useEditChannelMessageMutation()
    const dispatch = useDispatch()
    useEffectOnce(() => {
        setPagination({
            channelID: id,
            startFrom: messageID,
            includeFirstMessage: !!messageID,
            size: 50,
            sort: messageID ? 'asc' : 'desc',
            clearCache: true
        })
    })

    async function handleSendMessage({
        html,
        plainText,
        replyToMessageID,
        forwardedMessageID,
        attachments
    }: {
        html: string
        plainText: string
        replyToMessageID?: string
        forwardedMessageID?: string
        attachments?: string[]
    }) {
        try {
            const isEmptyChat = !messages?.length

            await sendMessage({
                ID: id,
                entry: {
                    contentHTML: html,
                    contentPlainText: plainText,
                    type: 'STANDARD',
                    parentMessageID: threadMessageID,
                    forwardedMessageID,
                    replyToMessageID,
                    attachments
                }
            }).unwrap()

            if (isEmptyChat) {
                dispatch(chatAPI.util.invalidateTags(['Channels', 'ChatGroups']))
            }
        } catch (e) {
            console.error('handleSendMessage', e)
        }
    }
    async function handleMessageEdit({
        html,
        plainText,
        messageID
    }: {
        html: string
        plainText: string
        messageID: string
    }) {
        try {
            const editedMessage = {
                contentHTML: html,
                contentPlainText: plainText.replace('Edit:', '')
            }

            await editMessage({ channelID: id, messageID, editedMessage })
        } catch (e) {
            console.error('handleMessageEdit', e)
        }
    }
    // todo: skeleton ui
    if (isLoading) {
        return null
    }

    // todo: handle no chat loaded
    if (isError || !channel) {
        return null
    }

    return (
        <div>
            <ChatHeader item={channel} isThread={!!threadMessageID} />

            <ChatHistory
                channelID={id}
                siblingHeight={height + 104}
                messages={messages || []}
                messagesLoading={messagesFetching}
                searchMessageResults={searchMessageResults}
                setPagination={setPagination}
                isThread={!!threadMessageID}
            />

            <Box ref={ref} px="24px" pb="16px" w="full">
                <Chatbox
                    onSendMessage={handleSendMessage}
                    onEditMessage={handleMessageEdit}
                    channelID={id}
                    userID={user?.userID || ''}
                />
            </Box>
        </div>
    )
}
