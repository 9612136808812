import { t } from 'i18next'
import { useGetImagesQuery } from 'shared/store'

export const useGetIntegrations = (source?: string) => {
    let sourceName = ''
    let sourceLogo = ''

    const { data: integrations } = useGetImagesQuery()

    if (source && integrations) {
        const name = source.replace('Dev', '')
        const sourceData = integrations[name]

        sourceName = sourceData?.displayName || t('contacts.integration')
        sourceLogo = sourceData?.image || ''
    }

    return { integrations, sourceName, sourceLogo }
}
