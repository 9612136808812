import { SelectOption, useFormatToNumberE164 } from '@missionlabs/react'
import { DirectoryEntry } from '@missionlabs/types'
import { titleCase } from '@missionlabs/utils'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export interface UseContactNumbersDropdownOpts {
    contact?: DirectoryEntry
    mobileOnly?: boolean
}

export const useContactNumbersDropdown = ({
    contact,
    mobileOnly
}: UseContactNumbersDropdownOpts) => {
    const { t } = useTranslation()
    const { formatToLocalNumber, isUKMobileNumber } = useFormatToNumberE164()

    const numbers: SelectOption[] = useMemo(() => {
        const phoneNumbers = contact?.phoneNumbers ?? []
        return phoneNumbers
            .filter(number => {
                if (mobileOnly) {
                    return isUKMobileNumber(number.numberE164)
                }
                return true
            })
            .map(number => ({
                value: number.numberE164,
                label: `${titleCase(number.label ?? t('Other'))} - ${formatToLocalNumber(
                    number.numberE164
                )}`
            }))
    }, [formatToLocalNumber, isUKMobileNumber, mobileOnly, contact, t])
    return numbers
}
