import { Box, Center, VStack } from '@chakra-ui/react'
import { Scrollable } from '@missionlabs/react'
import { FC, PropsWithChildren } from 'react'

import { DiallerOptions } from '../DiallerOptions'

interface CallWrapperProps {
    showDiallerOptions?: boolean
    mobileOnly?: boolean
    onChange?: (presentationNumber: string) => void
}

export const CallWrapper: FC<PropsWithChildren<CallWrapperProps>> = ({
    showDiallerOptions = true,
    mobileOnly,
    onChange,
    children
}) => {
    return (
        <VStack spacing="24px" w="full">
            {showDiallerOptions && (
                <Box px="24px" w="full">
                    <DiallerOptions mobileOnly={mobileOnly} onChange={onChange} />
                </Box>
            )}
            <Scrollable h="calc(100dvh - 120px)" w="full" px="24px">
                <Center m={0} w="full">
                    {children}
                </Center>
            </Scrollable>
        </VStack>
    )
}
