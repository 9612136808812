import { Box, HStack, VStack } from '@chakra-ui/react'
import {
    Button,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    ErrorMessage,
    FormProvider,
    useYupForm
} from '@missionlabs/react'
import { getMillisecondsUnix, isValidEmail } from '@missionlabs/utils'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { ContactsFilterProvider } from 'shared/hooks/useContactsFilters'

import { useAddEditMeetingSchema } from '../hooks/useAddEditMeetingSchema'
import { AddEditMeetingFormFields } from './AddEditMeetingDrawer'
import { MeetingCreateShareForm } from './MeetingCreateShareForm'
import { MeetingDetailsForm } from './MeetingDetailsForm'

export const CreateMeetingDrawerContent = ({ onClose, onSubmit, isLoading }) => {
    const { t } = useTranslation()

    const [params] = useSearchParams()

    const { fullSchema, meetingDetailsSchema } = useAddEditMeetingSchema()
    const [index, setIndex] = useState(0)

    const selectedDay = useMemo(() => {
        const day = params.get('timeRangeStart') ?? ''

        return new Date(getMillisecondsUnix(parseInt(day)))
    }, [params])

    const methods = useYupForm<AddEditMeetingFormFields>(fullSchema, {
        defaultValues: {
            date: selectedDay,
            allowGuestUsers: 'ALLOW',
            microphoneInitialState: 'UNMUTED',
            invitedUsers: []
        }
    })

    const {
        watch,
        formState: { isValid }
    } = methods

    const { invitedUsers, ...formValues } = watch()

    const showEditWarning = useMemo(() => {
        return invitedUsers.some(({ userID }) => isValidEmail(userID ?? ''))
    }, [invitedUsers])

    const drawerHeader = useMemo(() => {
        if (index === 1) {
            return t('scheduledMeeting.share.title')
        }

        return t('scheduledMeeting.create.title')
    }, [index, t])

    const formToShow = useMemo(() => {
        switch (index) {
            case 0: {
                return <MeetingDetailsForm />
            }

            case 1: {
                return (
                    <ContactsFilterProvider>
                        <MeetingCreateShareForm />
                    </ContactsFilterProvider>
                )
            }
        }
    }, [index])

    const { disableNextButton, disableShareButton } = useMemo(() => {
        return {
            disableShareButton: !invitedUsers?.length ? true : !isValid,
            disableNextButton: !meetingDetailsSchema.isValidSync(formValues)
        }
    }, [invitedUsers, isValid, meetingDetailsSchema, formValues])

    const nextOrShareBtn = useMemo(() => {
        switch (index) {
            case 0: {
                return (
                    <Button
                        w="full"
                        size="lg"
                        variant="primary"
                        isDisabled={disableNextButton}
                        isLoading={isLoading}
                        data-testid="next-form"
                        onClick={() => setIndex(v => v + 1)}
                    >
                        {t('Next')}
                    </Button>
                )
            }
            case 1: {
                return (
                    <Button
                        type="submit"
                        w="full"
                        size="lg"
                        variant="primary"
                        isDisabled={disableShareButton}
                        isLoading={isLoading}
                        data-testid="submit-form"
                    >
                        {t('Share')}
                    </Button>
                )
            }
        }
    }, [index, t, isLoading, disableShareButton, disableNextButton])

    return (
        <DrawerContent data-testid="create-meeting-drawer-content">
            <DrawerHeader>{drawerHeader}</DrawerHeader>
            <DrawerBody>
                <FormProvider {...methods}>
                    <VStack
                        as="form"
                        h="100%"
                        spacing="16px"
                        align="stretch"
                        justify="space-between"
                        onSubmit={methods.handleSubmit(onSubmit)}
                    >
                        {formToShow}

                        {showEditWarning && (
                            <Box px="24px">
                                <ErrorMessage
                                    error={t('scheduledMeeting.create.externalEditWarning')}
                                />
                            </Box>
                        )}

                        <HStack px="24px" pb="24px" spacing="24px" align="inherit">
                            <Button
                                w="full"
                                size="lg"
                                variant="secondary"
                                isDisabled={isLoading}
                                onClick={onClose}
                            >
                                {t('Cancel')}
                            </Button>
                            {nextOrShareBtn}
                        </HStack>
                    </VStack>
                </FormProvider>
            </DrawerBody>
        </DrawerContent>
    )
}
