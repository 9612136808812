import { call, selectPresentationNumber, useDispatch, useSelector } from '@missionlabs/api'
import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@missionlabs/react'
import { PhoneIconRegular } from '@missionlabs/react/zeta'
import { DirectoryEntry } from '@missionlabs/types'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ContactNumberMenuItem } from 'shared/components/ContactNumberMenuItem'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetUserData } from 'shared/hooks/useGetUserData'

interface ActivityFeedNumberMenuProps {
    // For contacts:
    contact?: DirectoryEntry
    // For unsaved contacts:
    contactNumber?: string
}

export function ActivityFeedNumberMenu({ contact, contactNumber }: ActivityFeedNumberMenuProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { formatToNumberE164 } = useFormatToNumberE164()
    const { callRoute } = useGetUserData()

    const presentationNumber = useSelector(selectPresentationNumber)
    const fromNumber = useMemo(
        () => presentationNumber ?? callRoute?.defaultPhoneNumber ?? '',
        [presentationNumber, callRoute?.defaultPhoneNumber]
    )

    const handleCall = useCallback(
        (numberOrExt: string) => {
            dispatch(call({ to: numberOrExt, from: fromNumber, contact }))
        },
        [dispatch, fromNumber, contact]
    )

    return (
        <Menu variant="compact">
            <MenuButton as={IconButton} variant="ghost">
                <PhoneIconRegular />
            </MenuButton>
            <MenuList>
                {contact?.extension && (
                    <MenuItem onClick={() => handleCall(contact.extension!)}>
                        {t('internalExt', { ext: contact.extension })}
                    </MenuItem>
                )}
                {contact?.phoneNumbers?.map(number => (
                    <ContactNumberMenuItem
                        key={number.numberE164}
                        number={number}
                        onClick={num => handleCall(formatToNumberE164(num))}
                    />
                ))}
                {contactNumber && (
                    <ContactNumberMenuItem
                        number={{ numberE164: formatToNumberE164(contactNumber) }}
                        onClick={() => handleCall(formatToNumberE164(contactNumber))}
                    />
                )}
            </MenuList>
        </Menu>
    )
}
