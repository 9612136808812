import { useFieldArray } from '@missionlabs/react'
import { DirectoryEntry } from '@missionlabs/types'
import { ContactsListTitle } from 'features/contacts/ContactsList/ContactsListTitle'
import { ContactsRow } from 'features/contacts/ContactsList/ContactsRow'
import { CSSProperties } from 'react'

import { MeetingsCallAddDrawerFields } from './MeetingsCallAddDrawer'

export interface MeetingsCallAddDirectoryRowRendererProps {
    data: (string | DirectoryEntry)[]
    index: number
    style: CSSProperties
}

function isTitle(item: string | DirectoryEntry): item is string {
    return typeof item === 'string'
}

export const MeetingsCallAddDirectoryRowRenderer = ({
    data,
    index,
    style
}: MeetingsCallAddDirectoryRowRendererProps) => {
    const { fields, append, remove } = useFieldArray<MeetingsCallAddDrawerFields>({ name: 'users' })

    const item = data[index]

    function handleToggle() {
        if (isTitle(item) || !item.externalID) return

        const index = fields.findIndex(field => item.externalID === field.userID)
        if (index !== -1) remove(index)
        else append({ userID: item.externalID })
    }

    if (isTitle(item)) {
        return (
            <div style={style} role="listitem">
                <ContactsListTitle label={item} />
            </div>
        )
    }

    return (
        <div style={{ ...style, padding: '0px 24px' }} role="listitem">
            <ContactsRow
                key={item.ID}
                data={item}
                isActive={fields.some(field => item.externalID === field.userID)}
                renderTick
                onClick={handleToggle}
            />
        </div>
    )
}
