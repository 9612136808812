import { Participant } from '@missionlabs/meetings'
import { ListTitle } from '@missionlabs/react'
import { CSSProperties, FC } from 'react'

import { MeetingsCallParticipantsRow } from './MeetingsCallParticipantsRow'

export interface MeetingsCallParticipantsRowRendererProps {
    data: (Participant | string)[]
    index: number
    style: CSSProperties
}

export const MeetingsCallParticipantsRowRenderer: FC<MeetingsCallParticipantsRowRendererProps> = ({
    data,
    index,
    style
}) => {
    const item = data[index]

    if (typeof item === 'string') {
        return (
            <div role="listitem" style={style}>
                <ListTitle>{item}</ListTitle>
            </div>
        )
    }

    return (
        <div role="listitem" style={{ ...style, padding: '0px 24px' }}>
            <MeetingsCallParticipantsRow data={item} />
        </div>
    )
}
