import { DestinationTypes, MonitoringConfig } from '@missionlabs/types'
import { AwsRum } from 'aws-rum-web'
import isElectron from 'is-electron'

class Monitoring {
    public client?: AwsRum

    setupClient(monitoringConfig?: MonitoringConfig) {
        if (!monitoringConfig || this.client || isElectron()) return

        const {
            application: { ID, version, region },
            config
        } = monitoringConfig

        this.client = new AwsRum(ID, version, region, config)

        this.recordAppData()
    }

    recordAppData() {
        const deviceType = isElectron() ? DestinationTypes.desktopApp : DestinationTypes.webApp

        this.client?.recordEvent('app_data', {
            appName: __NAME__,
            appVersion: __VERSION__,
            deviceType
        })
    }

    recordPageView(pathname: string) {
        // This regex will replace any instance of a UUID & replace it to group page views
        const regex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g
        const pathWithoutIDs = pathname.replace(regex, ':ID')

        this.client?.recordPageView(pathWithoutIDs)
    }

    recordError(error: string) {
        this.client?.recordError(error)
    }
}

export const monitoring = new Monitoring()
