import { UserGroup } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildUserGroupsApi = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'userGroupsAPI',
        tagTypes: ['UserGroups'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getUserGroups: builder.query<UserGroup[], void>({
                query: () => '/usergroups',
                transformResponse: (response: { data: UserGroup[] }) => response.data,
                providesTags: result => {
                    return result
                        ? [
                              ...result.map(group => ({
                                  type: 'UserGroups' as const,
                                  id: group.ID
                              })),
                              'UserGroups'
                          ]
                        : ['UserGroups']
                }
            }),
            getUserGroup: builder.query<UserGroup, string>({
                query: (userGroupID: string) => {
                    return `/usergroups/${userGroupID}`
                },
                transformResponse: (response: { data: UserGroup }) => response.data,
                providesTags: (result, error, userGroupID) => [
                    { type: 'UserGroups', id: userGroupID }
                ]
            })
        })
    })

    return { api, ...api }
}
