import { Center, useColorMode, VStack } from '@chakra-ui/react'
import { selectAuthenticatedUser, useDispatch, useSelector } from '@missionlabs/api'
import {
    Body,
    ContactDetail,
    ListItem,
    useOnScreenObserver,
    useSelect,
    UseSelectMode
} from '@missionlabs/react'
import { AvatarIcon, IncomingChannelIcon } from '@missionlabs/react/circleloop'
import { Channel, isContactGroup } from '@missionlabs/types'
import { format, isToday, isYesterday } from 'date-fns'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetPresence } from 'shared/hooks/useGetPresence'
import { useUpdatePresence } from 'shared/hooks/useUpdatePresence'
import { chatAPI } from 'shared/store'
export interface ChatListRowProps {
    item: Channel
    onClick?(): void
}

export const getChannelPresenceID = (channel: Channel, currentUserID?: string) => {
    if (channel?.team || !currentUserID) return
    return channel?.members?.find(member => member !== currentUserID)
}

export const ChatListRow: FC<ChatListRowProps> = ({ item, onClick }) => {
    const { colorMode } = useColorMode()
    const { toggle, isSelected } = useSelect(
        isContactGroup(item) ? UseSelectMode.chatGroup : UseSelectMode.chat
    )
    const user = useSelector(selectAuthenticatedUser)
    const presenceID = getChannelPresenceID(item, user?.userID)
    const { status } = useGetPresence(presenceID)

    const { updatePresence } = useUpdatePresence(presenceID)
    const { ref } = useOnScreenObserver(updatePresence)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const formatLastMsgTime = (date: number) => {
        if (isToday(date)) {
            return format(date, 'hh:mm')
        }

        if (isYesterday(date)) {
            return t('Yest')
        }

        return format(date, 'd/M')
    }

    function clickHandler(ID: string) {
        if (!isSelected(ID))
            dispatch(
                chatAPI.util.updateQueryData('getChannels', undefined, channels => {
                    const channel = channels.find(channel => channel.ID === ID)
                    if (channel) {
                        channel.unreadCount = 0
                    }
                }) as any
            )
        toggle(ID)
    }

    return (
        <ListItem
            data-active={isSelected(item.ID) || undefined}
            onClick={() => onClick?.() ?? clickHandler(item.ID)}
            ref={ref}
            borderTop="none"
        >
            <ContactDetail
                name={item.channelName}
                subText={item.lastMessagePreview}
                truncatedWidth="243px"
                status={status}
                avatar={
                    <VStack spacing="2px">
                        <AvatarIcon h="21px" w="21px" />
                        <IncomingChannelIcon ml="4px" color="#CACEE9" />
                    </VStack>
                }
                team={!!item.team}
            />
            <VStack spacing={1}>
                {item.lastMessageSent && (
                    <Body
                        fontWeight="400"
                        fontSize={'14px'}
                        sx={{ color: `${colorMode}.tones.navy` }}
                    >
                        {formatLastMsgTime(item.lastMessageSent)}
                    </Body>
                )}
                {item.unreadCount && (
                    <Center h="20px" w="20px" bg={`${colorMode}.alerts.red`} borderRadius="100px">
                        <Body fontSize="12px" variant="bold" sx={{ color: 'white' }}>
                            {item.unreadCount}
                        </Body>
                    </Center>
                )}
            </VStack>
        </ListItem>
    )
}
