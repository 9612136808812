import { DeploymentEnvironments } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export type LoginResponse = {
    ID: string // User Token
    object: string
    extendable: boolean
    expires: number
    brand: string
    clientID: string
    userID: string
    type: string
    created: Date
    updated: Date
}
export type Login = {
    email?: string
    username?: string
    password: string
}

type KVPResponse = {
    kvps: {
        coturn: {
            uri: string
            password: string
            username: string
        }
    }
}

type Metadata = {
    status: string
    apiVersion: string
    commonVersion: string
    mode: DeploymentEnvironments
    brand: string
    timestamp: number
    servers: {
        ldapHost: string
        registrationDomain: string
        stunServer: string
    }
    countryCode: string
}

export const buildRootAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'rootApi',
        baseQuery: baseQuery,
        endpoints: builder => ({
            getMetadata: builder.query<Metadata, void>({
                query: () => '/'
            }),
            getServerData: builder.query<KVPResponse, void>({
                query: () => ({
                    url: `/kvpgroups/serverData`
                })
            }),
            loginWithJWT: builder.mutation<LoginResponse, string>({
                query: token => ({
                    url: `/login/zjwt`,
                    method: 'POST',
                    body: {
                        zjwt: token
                    }
                })
            }),
            temp: builder.query<string, void>({
                query: () => ({
                    url: `/tokens/temp`,
                    method: 'GET'
                }),
                transformResponse(token: TokenResponse) {
                    return token.ID
                }
            }),
            login: builder.mutation<LoginResponse, { login: Login; token: string }>({
                query: ({ login, token }) => ({
                    url: `/login`,
                    method: 'POST',
                    body: login,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-token': token
                    }
                })
            }),
            superLogin: builder.mutation<LoginResponse, { email: string; token: string }>({
                query: body => ({
                    url: `/login`,
                    method: 'POST',
                    body: {
                        email: body.email,
                        password: body.token
                    }
                })
            })
        })
    })

    return { api, ...api }
}
