import { ActivitiesRequest, Activity, UserActivity } from '@missionlabs/types'

export function isVoicemail(activity: UserActivity) {
    return (
        activity.activityType === Activity.MESSAGE_RECEIVED ||
        activity.activityType === Activity.INTERNAL_MESSAGE_RECEIVED
    )
}

export function isMissedCall(activity: UserActivity) {
    const { activityType } = activity
    return activityType === Activity.CALL_MISSED || activityType === Activity.INTERNAL_CALL_MISSED
}

export function isSMS({ activityType }: UserActivity) {
    return activityType === Activity.CHAT_MESSAGE_RECEIVED
}

export function isWhatsapp({ activityType }: UserActivity) {
    return activityType === Activity.WHATSAPP_MESSAGE_RECEIVED
}

export function hasFilters({ directions, type, directories, status }: ActivitiesRequest): boolean {
    // TODO: Should we check that some of these values has a value?
    return [directions, type, directories, status].some(value => !!value)
}

/** Determines whether any values or the string "all" are in the array */
export const has = (allValues?: string[], itemsToCheck: string[] = []): boolean => {
    // If filter is empty, it's all filters should be applied
    if (!allValues || allValues.length === 0) return true
    if (!itemsToCheck.length) return false
    // Checks whether *any* of the itemsToCheck are present in allValues
    return itemsToCheck.some(item => allValues.includes(item))
}

/** Generate the corresponding activity types we need to fetch from the API based on filters */
export function generateActivityTypes({
    directions,
    type,
    directories
}: Pick<ActivitiesRequest, 'directions' | 'directories' | 'type'>) {
    const hasDirection = (values: string[]) => has(directions, values)
    const hasDirectory = (values: string[]) => has(directories, values)
    const hasType = (values: string[]) => has(type, values)

    const activityTypes: string[] = []

    // Voicemail
    if (hasDirection(['incoming']) && hasType(['voicemail'])) {
        if (hasDirectory(['internal'])) activityTypes.push(Activity.INTERNAL_MESSAGE_RECEIVED)
        if (hasDirectory(['company', 'personal'])) activityTypes.push(Activity.MESSAGE_RECEIVED)
    }

    // Missed Calls
    if (hasDirection(['incoming']) && hasType(['missed_calls'])) {
        if (hasDirectory(['internal'])) activityTypes.push(Activity.INTERNAL_CALL_MISSED)
        if (hasDirectory(['company', 'personal'])) activityTypes.push(Activity.CALL_MISSED)
    }

    // Calls Received
    if (hasDirection(['incoming']) && hasType(['voice_calls'])) {
        if (hasDirectory(['internal'])) activityTypes.push(Activity.INTERNAL_CALL_RECEIVED)
        if (hasDirectory(['company', 'personal'])) activityTypes.push(Activity.CALL_RECEIVED)
    }

    // Calls Made
    if (hasDirection(['outgoing']) && hasType(['voice_calls'])) {
        if (hasDirectory(['internal'])) activityTypes.push(Activity.INTERNAL_CALL_MADE)
        if (hasDirectory(['company', 'personal'])) activityTypes.push(Activity.CALL_MADE)
    }

    // Meetings Inbound
    if (hasDirection(['incoming']) && hasType(['meetings'])) {
        if (hasDirectory(['internal'])) {
            activityTypes.push(Activity.INTERNAL_MEETING_MISSED)
            activityTypes.push(Activity.INTERNAL_MEETING_REJECTED)
            activityTypes.push(Activity.INTERNAL_MEETING_RECEIVED)
        }
    }

    // Meetings Made
    if (hasDirection(['outgoing']) && hasType(['meetings'])) {
        if (hasDirectory(['internal'])) activityTypes.push(Activity.INTERNAL_MEETING_MADE)
    }

    if (hasDirection(['incoming']) && hasType(['sms'])) {
        activityTypes.push(Activity.CHAT_MESSAGE_RECEIVED)
    }

    if (hasDirection(['outgoing']) && hasType(['sms'])) {
        activityTypes.push(Activity.CHAT_MESSAGE_SENT)
    }

    if (hasDirection(['incoming']) && hasType(['whatsapps'])) {
        activityTypes.push(Activity.WHATSAPP_MESSAGE_RECEIVED)
    }

    if (hasDirection(['outgoing']) && hasType(['whatsapps'])) {
        activityTypes.push(Activity.WHATSAPP_MESSAGE_SENT)
    }

    return activityTypes
}
