import { useColorMode, VStack } from '@chakra-ui/react'
import { Call } from '@missionlabs/api'
import { Avatar, Body, Heading } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useCallDetails } from 'shared/hooks/useCallDetails'

import { CallWrapper } from '../CallWrapper/CallWrapper'

interface CancelledCallProps {
    call: Call
}

export const CancelledCall: FC<CancelledCallProps> = ({ call }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const periwinkle = `${colorMode}.tones.periwinkle`

    const { company, contactName, number, isWithheld } = useCallDetails(call)

    return (
        <CallWrapper showDiallerOptions={false}>
            <VStack h="full" w="full" spacing="8px">
                <Body size="sm" variant="bold" color={periwinkle} as="h2">
                    {t('Call ended')}
                </Body>

                {contactName && !isWithheld && <Avatar name={contactName} size="lg" />}

                <VStack w="full" spacing={0}>
                    {contactName && (
                        <Heading size="h2" color="white" as="h1" lineHeight="40px">
                            {contactName}
                        </Heading>
                    )}

                    {company && (
                        <Body size="sm" color={periwinkle} as="h4">
                            {company}
                        </Body>
                    )}

                    {number && contactName !== number && (
                        <Body size="sm" color={periwinkle} as="h4">
                            {number}
                        </Body>
                    )}
                </VStack>
            </VStack>
        </CallWrapper>
    )
}
