import { Box } from '@chakra-ui/react'
import { selectAuthenticatedUser, selectInData, useSelector } from '@missionlabs/api'
import { Tab, TabList, TabPanel, TabPanels, Tabs, useMeasure } from '@missionlabs/react'
import { User, UserOrTeam } from '@missionlabs/types'
import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetHuntGroupsQuery, useGetIVRMenusQuery, useGetUsersQuery } from 'shared/store'

import { UTMList } from './UTMList/UTMList'

interface UTMTabsProps {
    omitSelf?: boolean
    siblingHeight?: number
    defaultValue?: string
    selectedItem?: UserOrTeam
    onSelect: (data: UserOrTeam) => void
    tabs?: AvailableTabs[]
}

export enum AvailableTabs {
    USERS,
    TEAMS,
    MENUS
}

export const UTMTabs: FC<UTMTabsProps> = ({
    omitSelf = false,
    siblingHeight = 0,
    defaultValue,
    selectedItem,
    onSelect,
    tabs = [AvailableTabs.MENUS, AvailableTabs.TEAMS, AvailableTabs.USERS]
}) => {
    const { t } = useTranslation()

    const [tabListRef, { blockSize: tabListHeight }] = useMeasure<HTMLDivElement>()

    const auth = useSelector(selectAuthenticatedUser)

    const selectUsers = useMemo(() => {
        return selectInData<User[], User[]>(data => data?.filter(user => user.ID !== auth?.userID))
    }, [auth])

    const { data: teams = [] } = useGetHuntGroupsQuery()
    const { data: menus = [] } = useGetIVRMenusQuery()
    const { data: users = [] } = useGetUsersQuery(
        undefined,
        omitSelf
            ? { selectFromResult: result => ({ ...result, data: selectUsers(result) }) }
            : undefined
    )

    // Defaults internal selection to provided default value when unset.
    useEffect(() => {
        if (selectedItem) return

        const selection = (function () {
            const team = teams.find(team => team.ID === defaultValue)
            if (team) return team

            const menu = menus.find(menu => menu.ID === defaultValue)
            if (menu) return menu

            const user = users.find(user => user.ID === defaultValue)
            if (user) return user
        })()

        if (selection) onSelect(selection)
    }, [defaultValue, menus, onSelect, selectedItem, teams, users])

    return (
        <Tabs w="full" px="24px" size="lg">
            <TabList ref={tabListRef}>
                {tabs.includes(AvailableTabs.USERS) && <Tab w="full">{t('Users')}</Tab>}
                {tabs.includes(AvailableTabs.TEAMS) && <Tab w="full">{t('Teams')}</Tab>}
                {tabs.includes(AvailableTabs.MENUS) && <Tab w="full">{t('Menus')}</Tab>}
            </TabList>
            <TabPanels>
                {tabs.includes(AvailableTabs.USERS) && (
                    <TabPanel p={0}>
                        <Box h={`calc(100vh - ${siblingHeight}px - ${tabListHeight}px)`}>
                            <UTMList
                                items={users}
                                selectedItem={selectedItem}
                                onSelect={onSelect}
                            />
                        </Box>
                    </TabPanel>
                )}
                {tabs.includes(AvailableTabs.TEAMS) && (
                    <TabPanel p={0}>
                        <Box h={`calc(100vh - ${siblingHeight}px - ${tabListHeight}px)`}>
                            <UTMList
                                items={teams}
                                selectedItem={selectedItem}
                                onSelect={onSelect}
                            />
                        </Box>
                    </TabPanel>
                )}
                {tabs.includes(AvailableTabs.MENUS) && (
                    <TabPanel p={0}>
                        <Box h={`calc(100vh - ${siblingHeight}px - ${tabListHeight}px)`}>
                            <UTMList
                                items={menus}
                                selectedItem={selectedItem}
                                onSelect={onSelect}
                            />
                        </Box>
                    </TabPanel>
                )}
            </TabPanels>
        </Tabs>
    )
}
