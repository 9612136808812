import { useColorMode } from '@chakra-ui/react'
import { Button, ButtonProps } from '@missionlabs/react'
import { FC, ReactElement } from 'react'

type MeetingButtonProps = ButtonProps & { icon: ReactElement; label: string }

export const MeetingButton: FC<MeetingButtonProps> = ({ icon, label, ...props }) => {
    const { colorMode } = useColorMode()
    return (
        <Button
            leftIcon={icon}
            variant="transparent"
            color={`${colorMode}.tones.navy`}
            p={0}
            _hover={{
                color: `${colorMode}.primary.gammaHover`
            }}
            {...props}
        >
            {label}
        </Button>
    )
}
