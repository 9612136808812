import {
    ButtonGroup,
    HStack,
    RangeSlider,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    RangeSliderTrack,
    StackProps,
    useMultiStyleConfig
} from '@chakra-ui/react'
import { formatDuration } from '@missionlabs/utils'
import { IconButton } from 'atoms/IconButton/IconButton'
import { DeleteIcon, DownloadIcon, TranscriptIcon } from 'atoms/Icons/circleloop'
import { PauseIconSolid, PlayIconSolid } from 'atoms/Icons/zeta'
import { Body } from 'atoms/Typography/Body'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

export type PlayerProps = StackProps & {
    url: string
    variant?: 'mini' | 'table' | 'micro'
    onClick?: () => void
    onDownload?: () => void
    onTranscript?: () => void
    onDelete?: () => void
}

type ReactPlayerState = {
    playedSeconds: number
    loadedSeconds: number
}

type PlayerState = ReactPlayerState & { playing: boolean; totalSeconds: number }

export type PlayerConsumerRef = {
    pause: () => void
}

// set default loaded seconds to 0.1 to prevent layout shifting when file has loaded
const initialPlayedState: PlayerState = {
    playing: false,
    playedSeconds: 0,
    loadedSeconds: 0.1,
    totalSeconds: 0
}

export const Player = forwardRef<PlayerConsumerRef, PlayerProps>(
    ({ url, variant, onClick, onDownload, onTranscript, onDelete, ...props }, ref) => {
        const styles = useMultiStyleConfig('Player', { variant })

        const playerRef = useRef<ReactPlayer>(null)

        const [playerState, setPlayerState] = useState<PlayerState>(initialPlayedState)
        const { playing, playedSeconds, totalSeconds } = playerState

        const beautifySeconds = useCallback((seconds: number) => {
            return formatDuration(seconds * 1000)
        }, [])

        const handleOnReady = useCallback(() => {
            setPlayerState(prev => ({
                ...prev,
                totalSeconds: playerRef.current?.getDuration() ?? 0
            }))
        }, [])

        const handleEnded = useCallback(() => {
            setPlayerState(prev => ({ ...prev, playing: false }))
        }, [])

        const handleProgress = useCallback((state: ReactPlayerState) => {
            setPlayerState(prev => ({ ...prev, ...state }))
        }, [])

        const handleSeek = useCallback(value => {
            const playedSeconds = value[0]
            playerRef.current?.seekTo(playedSeconds)
            setPlayerState(prev => ({ ...prev, playedSeconds }))
        }, [])

        const handleTogglePlaying = useCallback(() => {
            if (onClick) onClick()
            else setPlayerState(prev => ({ ...prev, playing: !playing }))
        }, [onClick, playing])

        useImperativeHandle(ref, () => {
            return {
                pause() {
                    setPlayerState(prev => ({ ...prev, playing: false }))
                }
            }
        })

        if (variant === 'micro') {
            return (
                <>
                    <ReactPlayer
                        ref={playerRef}
                        url={url}
                        playing={playing}
                        loop={false}
                        height={0}
                        width={0}
                        style={{ display: 'none' }}
                        onReady={handleOnReady}
                        onProgress={handleProgress}
                        onEnded={handleEnded}
                    />
                    <IconButton
                        size={'xs'}
                        bg="none"
                        icon={
                            playing ? (
                                <PauseIconSolid sx={{ ...styles.icon }} />
                            ) : (
                                <PlayIconSolid sx={{ ...styles.icon }} />
                            )
                        }
                        variant="tertiary"
                        aria-label={playing ? 'Pause' : 'Play'}
                        onClick={handleTogglePlaying}
                    />
                </>
            )
        }

        return (
            <HStack spacing={0} onClick={e => e.stopPropagation()}>
                <ReactPlayer
                    ref={playerRef}
                    url={url}
                    playing={playing}
                    loop={false}
                    height={0}
                    width={0}
                    style={{ display: 'none' }}
                    onReady={handleOnReady}
                    onProgress={handleProgress}
                    onEnded={handleEnded}
                />

                <HStack spacing={0} {...props} sx={{ ...styles.container, ...(props.sx ?? {}) }}>
                    <IconButton
                        size={variant === 'table' || variant === 'mini' ? 'xs' : 'md'}
                        sx={{ ...styles.iconButton, borderLeft: 'none' }}
                        icon={
                            playing ? (
                                <PauseIconSolid sx={{ ...styles.icon }} />
                            ) : (
                                <PlayIconSolid sx={{ ...styles.icon }} />
                            )
                        }
                        variant="tertiary"
                        aria-label={playing ? 'Pause' : 'Play'}
                        onClick={handleTogglePlaying}
                    />

                    {/* Slider, track and duration */}
                    <HStack spacing="16px" sx={{ ...styles.playerContainer }}>
                        {variant !== 'mini' && (
                            <RangeSlider
                                // eslint-disable-next-line jsx-a11y/aria-proptypes
                                aria-label={['Seek']}
                                min={0}
                                max={totalSeconds}
                                step={0}
                                value={[playedSeconds]}
                                onChange={handleSeek}
                            >
                                <RangeSliderTrack sx={{ ...styles.sliderTrack }}>
                                    <RangeSliderFilledTrack sx={{ ...styles.filledTrack }} />
                                </RangeSliderTrack>
                                <RangeSliderThumb sx={{ ...styles.sliderThumb }} index={0} />
                            </RangeSlider>
                        )}

                        <HStack spacing="2px">
                            {variant !== 'mini' && variant !== 'table' && (
                                <>
                                    <Body sx={{ ...styles.label, color: styles.label?.color }}>
                                        {beautifySeconds(playedSeconds)}
                                    </Body>
                                    <Body sx={{ ...styles.label }}>/</Body>
                                </>
                            )}

                            <Body sx={{ ...styles.label }}>{beautifySeconds(totalSeconds)}</Body>
                        </HStack>
                    </HStack>

                    <ButtonGroup
                        isAttached
                        borderLeftRadius={0}
                        borderRightRadius="4px"
                        sx={{
                            button: {
                                ...styles.iconButton,
                                borderLeftRadius: 'inherit',
                                borderRightRadius: 'inherit'
                            }
                        }}
                    >
                        {/* Buttons, optional */}
                        {onTranscript && (
                            <IconButton
                                sx={{ ...styles.iconButton }}
                                icon={<TranscriptIcon sx={{ ...styles.icon }} boxSize="24px" />}
                                variant="transparent"
                                aria-label="Transcript"
                                onClick={onTranscript}
                            />
                        )}
                        {onDownload && (
                            <IconButton
                                sx={{ ...styles.iconButton }}
                                icon={<DownloadIcon sx={{ ...styles.icon }} boxSize="22px" />}
                                variant="transparent"
                                aria-label="Download"
                                onClick={onDownload}
                            />
                        )}
                        {onDelete && (
                            <IconButton
                                sx={{ ...styles.iconButton }}
                                icon={<DeleteIcon sx={{ ...styles.icon }} boxSize="22px" />}
                                variant="transparent"
                                aria-label="Delete"
                                onClick={onDelete}
                            />
                        )}
                    </ButtonGroup>
                </HStack>
            </HStack>
        )
    }
)
