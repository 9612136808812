import { Box, List } from '@chakra-ui/react'
import { FC } from 'react'

type ShareBasicListProps = {
    children: React.ReactNode
}

export const ShareBasicList: FC<ShareBasicListProps> = ({ children }) => {
    return (
        <Box w="full" flexGrow="1" h={'100%'}>
            <List h={'100%'}>{children}</List>
        </Box>
    )
}
