import { useTheme } from '@missionlabs/react'
import { AnalyticsGroup } from '@missionlabs/types'
import React, { useEffect, useMemo, useState } from 'react'

import { useDateScroll } from '../hooks/useDateScroll'
import { getAnalyticsColours } from '../utils/graph'
import { AnalyticsContext, AnalyticsContextType, DatePeriod } from '.'

interface Props {
    children: React.ReactNode
}

const AnalyticsProvider: React.FC<Props> = ({ children }) => {
    const [period, setPeriod] = useState<DatePeriod>('weeks')
    const [group, setGroup] = useState<AnalyticsGroup>('hour')
    const [create, setCreate] = useState(false)
    const [graphType, setGraphType] = useState<AnalyticsContextType['graph']['graphType']>('line')

    const dateScroller = useDateScroll(period)

    const theme = useTheme().variant

    const colours = useMemo(() => {
        return getAnalyticsColours(theme)
    }, [theme])

    // Sets default group for period
    useEffect(() => {
        const groups = {
            days: 'hour',
            weeks: 'day',
            months: 'week'
        }
        const group = groups[period] ?? 'hour'
        setGroup(group)
    }, [period])

    const value = useMemo<AnalyticsContextType>(
        () => ({
            period,
            group,
            setPeriod,
            setGroup,
            dateScroller,
            report: {
                create,
                setCreate
            },
            graph: {
                graphType,
                setGraphType
            },
            colours
        }),
        [period, group, setPeriod, setGroup, dateScroller, create, graphType, colours]
    )

    return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>
}

export default AnalyticsProvider
