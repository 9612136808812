import { NewSpeedDialList, SpeedDialList } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildSpeedDialListApi = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'speedDialListsAPI',
        baseQuery: baseQuery,
        tagTypes: ['SpeedDialList'],
        endpoints: builder => ({
            getSpeedDialListsForClient: builder.query<SpeedDialList[], void>({
                query: () => `/speeddiallists`,
                transformResponse: (response: { data: SpeedDialList[] }) => response.data,
                providesTags: result => {
                    return result
                        ? [
                              ...result.map(({ ID }) => ({
                                  type: 'SpeedDialList' as const,
                                  id: ID
                              })),
                              'SpeedDialList'
                          ]
                        : ['SpeedDialList']
                }
            }),

            getSpeedDialListForClient: builder.query<SpeedDialList, SpeedDialList['ID']>({
                query: speedDialListID => `/speeddiallists/${speedDialListID}`,
                providesTags: data => [{ type: 'SpeedDialList', id: data?.ID }]
            }),

            createSpeedDialListForClient: builder.mutation<SpeedDialList, NewSpeedDialList>({
                query: data => ({
                    url: `/speeddiallists`,
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: data => [{ type: 'SpeedDialList', id: data?.ID }]
            }),

            updateSpeedDialListForClient: builder.mutation<SpeedDialList, Partial<SpeedDialList>>({
                query: data => ({
                    url: `/speeddiallists/${data.ID}`,
                    method: 'PUT',
                    body: data
                }),
                invalidatesTags: data => [{ type: 'SpeedDialList', id: data?.ID }]
            }),

            deleteSpeedDialListForClient: builder.mutation<SpeedDialList, Partial<SpeedDialList>>({
                query: data => ({
                    url: `/speeddiallists/${data.ID}`,
                    method: 'DELETE'
                }),
                invalidatesTags: data => [{ type: 'SpeedDialList', id: data?.ID }]
            }),

            getSpeedDialListForUser: builder.query<SpeedDialList, { userID: string }>({
                async queryFn({ userID }, _api, _extraOptions, baseQuery) {
                    const res = await baseQuery(`/users/${userID}/speeddiallists`)
                    const error = res.error as { status: number }
                    // prevent 404's rejecting promise
                    if (error && error.status === 404) return { data: null as any }
                    if (error) return { error: res.error }
                    return { data: res.data as SpeedDialList }
                },
                providesTags: data => [{ type: 'SpeedDialList', id: data?.ID }]
            }),

            createSpeedDialListForUser: builder.mutation<SpeedDialList, NewSpeedDialList>({
                query: data => ({
                    url: `/users/${data.userID}/speeddiallists`,
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: data => [{ type: 'SpeedDialList', id: data?.ID }]
            }),

            updateSpeedDialListForUser: builder.mutation<SpeedDialList, Partial<SpeedDialList>>({
                query: data => ({
                    url: `/users/${data.userID}/speeddiallists/${data.ID}`,
                    method: 'PUT',
                    body: data
                }),
                invalidatesTags: data => [{ type: 'SpeedDialList', id: data?.ID }]
            }),

            deleteSpeedDialListForUser: builder.mutation<SpeedDialList, Partial<SpeedDialList>>({
                query: data => ({
                    url: `/speeddiallists/${data.ID}`,
                    method: 'DELETE'
                }),
                invalidatesTags: data => [{ type: 'SpeedDialList', id: data?.ID }]
            })
        })
    })

    return { api, ...api }
}
