import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { IconButton } from '@missionlabs/react'
import { SendIconSolid } from '@missionlabs/react/zeta'
import { $getRoot, COMMAND_PRIORITY_LOW, SELECTION_CHANGE_COMMAND } from 'lexical'
import { FC, useCallback, useEffect, useState } from 'react'

import { SEND_MESSAGE_COMMAND } from './SendMessagePlugin'

export interface ToolbarPluginMinimalProps {}

export const ToolbarPluginMinimal: FC<ToolbarPluginMinimalProps> = () => {
    const [editor] = useLexicalComposerContext()

    const [isDirty, setIsDirty] = useState(false)

    const updateToolbar = useCallback(() => {
        // Update dirty state
        const root = $getRoot()
        setIsDirty(root.getAllTextNodes().length > 0)
    }, [])

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(updateToolbar)
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateToolbar()
                    return true
                },
                COMMAND_PRIORITY_LOW
            )
        )
    }, [editor, updateToolbar])

    return (
        <IconButton
            size="sm"
            variant="ghost"
            isActive={isDirty}
            isDisabled={!isDirty}
            aria-label="Send message"
            onClick={() => editor.dispatchCommand(SEND_MESSAGE_COMMAND, undefined)}
            data-testid="chat-toolbar-minimal"
        >
            <SendIconSolid boxSize="24px" p="4px" />
        </IconButton>
    )
}
