import {
    AvailableNumber,
    ClientAddOn,
    NumberE164,
    NumberPortability,
    NumbersResponse
} from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { SearchQueryArgs } from './contactsApi'
import { customCreateApi } from './customCreateAPI'

export type BaseUserNumberSearchResponse = {
    phoneNumbers: Array<NumberE164>
}

export type PhoneNumberGroup = BaseUserNumberSearchResponse & {
    ID: string
    name: string
    object: string
}

export type UserNumberSearchResponse = BaseUserNumberSearchResponse & {
    teams: Array<PhoneNumberGroup>
    pod?: Partial<PhoneNumberGroup>
}

export type AssignedNumber = {
    ID: string
    userID?: string
    teamID?: string
    menuID?: string
}

export type NumberVerificationBody = {
    userID: string
    clientID: string
    verifyNumber: string
    verifyMethod: 'call' | 'sms'
}

export type NumberVerificationResponse = {
    ID: string
}

export type CodeVerificationBody = {
    userID: string
    clientID: string
    ID: string // The value from the response of Number verification
    verifyNumber: string // The number to mirror
    code: string
}

export type AddAlphaTagBody = {
    numberID: string
    alphaTag: string
}

export const buildNumbersAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'numbersAPI',
        baseQuery: baseQuery,
        tagTypes: ['AvailableNumbers', 'Numbers', 'NumberPortabilities'],
        endpoints: builder => ({
            getAvailableNumbers: builder.query<
                AvailableNumber[],
                { areaCode: string; numberType: string }
            >({
                query: args => {
                    const params = new URLSearchParams(args)
                    return `/numbers/available?${params.toString()}`
                },
                transformResponse: (response: { data: AvailableNumber[] }) => response.data,
                providesTags: ['AvailableNumbers']
            }),
            getNumbersForUser: builder.query<NumbersResponse, string>({
                query: userID => `/users/${userID}/numbers`,
                providesTags: (_r, _e, userID) => [{ type: 'Numbers', id: userID }]
            }),
            // Presentation Numbers
            getNumbers: builder.query<
                NumberE164[],
                { loadAssociations?: boolean; showAvailable?: boolean } | void
            >({
                query: options => {
                    const params = new URLSearchParams()

                    if (options) {
                        if (options.loadAssociations) params.set('loadAssociations', 'true')
                        if (options.showAvailable) params.set('showAvailable', 'true')
                    }
                    return {
                        url: `/numbers?${params.toString()}`,
                        method: 'GET'
                    }
                },
                transformResponse: (response: { data: NumberE164[] }) => response.data,
                providesTags: result => {
                    return result
                        ? [
                              ...result.map(number => ({
                                  type: 'Numbers' as const,
                                  id: number.ID
                              })),
                              'Numbers'
                          ]
                        : ['Numbers']
                }
            }),
            numbersSearch: builder.query<NumberE164[], SearchQueryArgs>({
                query: ({ searchTerm, maxResults, from }) => {
                    const params = new URLSearchParams()
                    params.append('loadAssociations', 'true')

                    if (searchTerm) params.append('searchTerm', searchTerm)
                    if (maxResults) params.append('max', maxResults.toString())
                    if (from) params.append('from', from.toString())

                    return `/numbers?${params.toString()}`
                },
                transformResponse: (response: { data: NumberE164[] }) => response.data,
                providesTags: result => {
                    return result
                        ? [
                              ...result.map(number => ({
                                  type: 'Numbers' as const,
                                  id: number.ID
                              })),
                              'Numbers'
                          ]
                        : ['Numbers']
                }
            }),
            getMyNumbers: builder.query<NumberE164[], string>({
                query: userID => `/users/${userID}/numbers?loadAssociations=true`,
                transformResponse: ({ phoneNumbers, teams, pod }: UserNumberSearchResponse) => [
                    ...phoneNumbers,
                    ...teams.reduce(
                        (res, team) => [...res, ...team.phoneNumbers],
                        [] as Array<NumberE164>
                    ),
                    ...(pod?.phoneNumbers || [])
                ],
                providesTags: (_r, _e, userID) => [{ type: 'Numbers', id: userID }]
            }),
            getNumberPortability: builder.query<NumberPortability, string>({
                query: numberE164 => `/numbers/${numberE164}/lookup`,
                providesTags: (_r, _e, numberE164) => [
                    { type: 'NumberPortabilities', id: numberE164 }
                ]
            }),
            addNumber: builder.mutation<ClientAddOn[], { numberID: string; numberE164: string }>({
                query: body => ({ url: '/addons', method: 'POST', body }),
                invalidatesTags: ['Numbers']
            }),
            assignNumber: builder.mutation<void, AssignedNumber>({
                query: ({ ID, ...rest }) => {
                    return {
                        url: `/numbers/${ID}`,
                        method: 'PUT',
                        body: rest
                    }
                },
                invalidatesTags: (result, error, { ID }) => [{ type: 'Numbers', id: ID }]
            }),
            unassignNumber: builder.mutation<void, string>({
                query: ID => {
                    return {
                        url: `/numbers/${ID}`,
                        method: 'PUT',
                        body: { disassociateNumber: true }
                    }
                },
                invalidatesTags: (result, error, ID) => [{ type: 'Numbers', id: ID }]
            }),
            deleteNumber: builder.mutation<void, string>({
                query: ID => {
                    return {
                        url: `/numbers/${ID}`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: (result, error, ID) => [{ type: 'Numbers', id: ID }]
            }),
            verifyNumber: builder.mutation<NumberVerificationResponse, NumberVerificationBody>({
                query: body => ({
                    url: `/numberverification`,
                    method: 'POST',
                    body
                })
            }),
            verifyCode: builder.mutation<void, CodeVerificationBody>({
                query: body => ({
                    url: `/numberverification`,
                    method: 'PUT',
                    body
                })
            }),
            addAlphaTag: builder.mutation<NumberE164, AddAlphaTagBody>({
                query: ({ numberID, alphaTag }) => ({
                    url: `/numbers/${numberID}`,
                    method: 'PATCH',
                    body: { alphaTag }
                }),
                invalidatesTags: (result, error, { numberID }) => [
                    { type: 'Numbers', id: numberID }
                ]
            })
        })
    })

    return { api, ...api }
}
