import { HStack, StackProps, useMultiStyleConfig } from '@chakra-ui/react'
import { Spinner } from 'atoms/Spinner/Spinner'
import { Heading } from 'atoms/Typography/Heading'
import { forwardRef, PropsWithChildren } from 'react'

import { BannerIcon } from './BannerIcon'

export interface BannerProps extends StackProps {
    variant?: 'error' | 'darkRed'
    isLoading?: boolean
}

export const Banner = forwardRef<HTMLDivElement, PropsWithChildren<BannerProps>>(
    ({ variant = 'error', children, isLoading, ...props }, ref) => {
        const styles = useMultiStyleConfig('Banner', { variant })

        return (
            <HStack
                role="banner"
                spacing="8px"
                align="center"
                {...props}
                ref={ref}
                sx={{ ...styles.container, ...(props.sx ?? {}) }}
            >
                <BannerIcon variant={variant} />
                <Heading size="h3" sx={styles.label}>
                    {children}
                </Heading>
                {isLoading && <Spinner size="sm" variant="brand" />}
            </HStack>
        )
    }
)

Banner.displayName = 'Banner'
