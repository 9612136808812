import { Button, Table } from '@missionlabs/react'
import { AddCircleIcon } from '@missionlabs/react/circleloop'
import { User } from '@missionlabs/types'
import { createColumnHelper } from '@tanstack/react-table'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetUsersQuery } from 'shared/store'
import { APP_BRAND, AppConfig } from 'shared/utils/env'

export const Users: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { data: users = [] } = useGetUsersQuery()

    const columnHelper = createColumnHelper<Partial<User>>()

    const columns = [
        columnHelper.accessor('fullName', {
            cell: info => info.getValue(),
            header: t('admin.users.name')
        }),
        columnHelper.accessor('type', {
            cell: info => t(`admin.users.role.${info.getValue()}`),
            header: t('admin.users.role.title')
        }),
        columnHelper.accessor('subscriptionType', {
            cell: info => t(`admin.users.plan.${info.getValue()}`),
            header: t('admin.users.plan.title')
        })
    ]

    const onAddUser = () => {
        navigate('/admin/users/add')
    }

    const onRowClicked = (user: Partial<User>) => {
        navigate(`/admin/users/${user.ID}`)
    }

    return (
        <div>
            <SettingsPage
                maxW="full"
                title={t('admin.users.title')}
                subtitle={t('admin.users.subtitle')}
                isEmpty={
                    // In reality, users should always have a length since the current user exists
                    // However, if the request ever fails for some reason,
                    // we should still show a nice empty state rather than empty table
                    !users.length
                }
                actions={
                    AppConfig.brand === APP_BRAND.CIRCLELOOP && (
                        <Button
                            variant="creationary"
                            leftIcon={<AddCircleIcon boxSize="16px" />}
                            aria-label="Add user"
                            onClick={onAddUser}
                        >
                            {t('admin.users.add')}
                        </Button>
                    )
                }
            >
                <Table
                    data={users}
                    columns={columns}
                    paginate
                    onRowClicked={onRowClicked}
                    sorting={[{ id: 'fullName', desc: false }]}
                />
            </SettingsPage>
            <Outlet />
        </div>
    )
}
