import { useGetMyWhatsAppNumbers } from 'shared/hooks/useGetMyWhatsAppNumbers'

import { Features, useHasClientFeature } from './useHasClientFeature'

/**
 * Check a message can be sent via WhatsApp
 * @param toNumbers
 * @param fromNumber
 * @returns boolean
 */
export const useCanSendWhatsApp = () => {
    const whatsappClientEnabled = useHasClientFeature(Features.whatsapp)
    const { myWhatsAppNumbers } = useGetMyWhatsAppNumbers()
    return whatsappClientEnabled && myWhatsAppNumbers.length > 0
}
