import { useToast } from '@chakra-ui/react'
import { Call, useDispatch } from '@missionlabs/api'
import { ParkedCall } from '@missionlabs/browser-calling'
import { TeamVmNotificationPayload, UserActivity } from '@missionlabs/types'
import isElectron from 'is-electron'
import { ElementType, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IncomingCallNotification } from 'shared/components/Notifications/IncomingCallNotification'
import { MissedCallNotification } from 'shared/components/Notifications/MissedCallNotification'
import { ParkedCallNotification } from 'shared/components/Notifications/ParkedCallNotification'
import { NotificationProps } from 'shared/components/Notifications/types'
import { VoicemailNotification } from 'shared/components/Notifications/VoicemailNotification'
import { getNameFromCall } from 'shared/hooks/useCallDetails'
import { getNameFromActivity } from 'shared/utils/activities'
import { createNotification } from 'shared/utils/notifications/browserNotifications'
import { ElectronNotification } from 'shared/utils/notifications/electronNotifications'

import { useFormatToNumberE164 } from './useFormatToNumberE164'
import { useNotificationRefs, UseNotificationRefsReturn } from './useNotificationRefs'

export type UseCallNotificationBuilderMode = 'incoming' | 'missed' | 'parked' | 'voicemail'

const getPropsForMode = (
    mode: UseCallNotificationBuilderMode
): {
    element: ElementType<NotificationProps<any>> | null
    route: string
    title: string
    formatter: Function
} => {
    if (mode === 'incoming') {
        return {
            element: IncomingCallNotification,
            route: '/notification/call/incoming',
            title: 'Incoming Call',
            formatter: getNameFromCall
        }
    }
    if (mode === 'missed') {
        return {
            element: MissedCallNotification,
            route: '/notification/call/missed',
            title: 'Missed Call',
            formatter: getNameFromActivity
        }
    }
    if (mode === 'parked') {
        return {
            element: ParkedCallNotification,
            route: '/notification/call/parked',
            title: 'Parked Call',
            formatter: (call, format, t) => {
                if (!call) return t('details.unknown')
                return call.parked.contactName ?? format(call.parked.remoteNumber)
            }
        }
    }
    if (mode === 'voicemail') {
        return {
            element: VoicemailNotification,
            route: '/notification/call/voicemail',
            title: 'Voicemail',
            formatter: () => {}
        }
    }
    throw new Error('useCallNotificationListenerBuilder | Invalid mode!')
}

interface UseCallNotificationBuilderReturn
    extends Omit<UseNotificationRefsReturn, 'notifications'> {}

export const useCallNotificationListenerBuilder = (
    mode: UseCallNotificationBuilderMode,
    items: (Call | ParkedCall | UserActivity | TeamVmNotificationPayload)[] | undefined,
    recentlyDismissedRefs: UseNotificationRefsReturn,
    isEnabled: boolean,
    browserNotificationsEnabled: boolean,
    calls?: Call[]
): UseCallNotificationBuilderReturn => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()
    const { t } = useTranslation()
    const { formatToLocalNumber } = useFormatToNumberE164()

    const { has, add, remove, dismissOthers } = useNotificationRefs()
    const { element, route, title, formatter } = getPropsForMode(mode)
    const { has: isRecentlyDismissed, add: addRecentlyDismissed } = recentlyDismissedRefs

    const presentNotification = useCallback(
        (item: Call | ParkedCall | UserActivity | TeamVmNotificationPayload) => {
            if (!element) return null
            const Element = element
            toast({
                position: 'top-right',
                duration: null,
                render: ({ onClose: closeToast }) => {
                    const { callTraceID } = item

                    add(callTraceID, () => {
                        addRecentlyDismissed(callTraceID)
                        closeToast()
                    })

                    return (
                        <Element
                            call={item}
                            dispatch={dispatch}
                            navigate={navigate}
                            onClose={remove}
                        />
                    )
                }
            })
        },
        [add, addRecentlyDismissed, dispatch, element, navigate, remove, toast]
    )

    useEffect(() => {
        if (!isEnabled || !items) return

        // Dismiss all stale notifications (any notification that no longer has an associated call).
        dismissOthers(items.map(call => call.callTraceID))

        if (items.length < 1) return

        items.forEach(item => {
            const { callTraceID } = item

            // Ignore notifications already being displayed to prevent spam.
            if (has(callTraceID) || isRecentlyDismissed(callTraceID)) return

            if (!isElectron()) {
                // In-app notification

                // Only show incoming call notification if user already has an active call
                if (calls && calls?.length <= 1 && mode === 'incoming') return

                presentNotification(item)
            } else {
                // Electron API notification
                const handle = ElectronNotification.create(route, item)
                add(callTraceID, async () => {
                    const id = await handle
                    if (!id) return
                    addRecentlyDismissed(callTraceID)
                    ElectronNotification.action(id, 'dismiss', item)
                })
            }

            // Browser API notification
            if (browserNotificationsEnabled) {
                createNotification(
                    title,
                    t('details.from', { value: formatter(item, formatToLocalNumber, t) }),
                    { click: window.focus }
                )
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnabled, items])

    return { has, add, remove, dismissOthers }
}
