import { Box } from '@chakra-ui/react'
import { AnalyticsDataPoints } from '@missionlabs/types'
import { LineSvgProps, ResponsiveLine } from '@nivo/line'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import { FC, useCallback, useMemo } from 'react'

import {
    getPrecisionForPeriod,
    getTickValuesForPeriod,
    getTimeFormatForPeriod
} from '../../utils/graph'
import { AnalyticsGraphCommonProps, AnalyticsGraphProps } from './AnalyticsGraphProps'

export const AnalyticsLineGraph: FC<AnalyticsGraphProps> = ({ data, period }) => {
    const { colours } = useAnalytics()

    const transformDataPointsToGraphData = useCallback(
        (key: keyof AnalyticsDataPoints, item: AnalyticsDataPoints) => ({
            x: new Date(item.fromMs),
            y: item[key]
        }),
        []
    )

    const graphData = useMemo(() => {
        const inbound = data.map(item => transformDataPointsToGraphData('inbound', item))
        const outbound = data.map(item => transformDataPointsToGraphData('outbound', item))
        const missed = data.map(item => transformDataPointsToGraphData('missed', item))

        return [
            { id: 'inbound', color: colours.inbound, data: inbound },
            { id: 'outbound', color: colours.outbound, data: outbound },
            { id: 'missed', color: colours.missed, data: missed }
        ]
    }, [data, colours, transformDataPointsToGraphData])

    const xAxis = useMemo<Partial<LineSvgProps>>(() => {
        const timeFormat = getTimeFormatForPeriod(period)
        const tickValues = getTickValuesForPeriod(period)
        const precision = getPrecisionForPeriod(period)

        return {
            axisBottom: {
                ...AnalyticsGraphCommonProps.axisBottom,
                format: timeFormat,
                tickValues
            },
            xFormat: `time:${timeFormat}`,
            xScale: {
                type: 'time',
                format: 'native',
                precision
            }
        }
    }, [period])

    return (
        <Box h={500} w="100%" data-testid="analytics-line-graph">
            <ResponsiveLine
                {...AnalyticsGraphCommonProps}
                {...xAxis}
                data={graphData}
                yScale={{
                    type: 'linear',
                    max: 'auto',
                    min: 0
                }}
                enableGridY={false}
                curve="monotoneX"
                enableSlices={'x'}
                colors={d => d.color}
                lineWidth={3}
                pointSize={0}
                animate
                isInteractive
            />
        </Box>
    )
}
