import { HStack, Spacer, useColorMode, VStack } from '@chakra-ui/react'
import { Avatar, Body, IconButton } from '@missionlabs/react'
import { PhoneIconSolid } from '@missionlabs/react/zeta'
import { DirectoryEntry, HuntGroup, IVRMenu } from '@missionlabs/types'
import { isInternal, isTeamOrMenu } from '@missionlabs/utils'
import { FC, useMemo } from 'react'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetPresence, useGetPresenceID } from 'shared/hooks/useGetPresence'

import { TransferCallTarget } from './TransferList'
import { TransferNumberOverflow } from './TransferNumberOverflow'

export type VirtualTransferContact = {
    contact: DirectoryEntry | HuntGroup | IVRMenu
    onClick: (target: TransferCallTarget) => void
}

interface TransferContactProps extends VirtualTransferContact {}

export const TransferContact: FC<TransferContactProps> = ({ contact, onClick }) => {
    const { colorMode } = useColorMode()
    const { isValidPhoneNumber } = useFormatToNumberE164()

    const presenceID = useGetPresenceID(contact as DirectoryEntry)
    const { status } = useGetPresence(presenceID)

    const info = useMemo(() => {
        if (isTeamOrMenu(contact)) return { fullName: contact.name, jobTitle: undefined }
        return contact
    }, [contact])

    const onTransfer = (value: string) => {
        const type = (function () {
            if (isTeamOrMenu(contact) || contact.source === 'teamprofile') return 'teamID'
            if (isInternal(contact)) return 'userID'
            if (isValidPhoneNumber(value)) return 'numberE164'
        })()

        if (!type) return console.error('contact is not transferrable')

        onClick({ type, value, contact: !isTeamOrMenu(contact) ? contact : undefined })
    }

    return (
        <HStack
            h="70px"
            w="full"
            spacing="8px"
            p="12px"
            borderTop="1px solid"
            borderColor={`${colorMode}.coolGrey.60`}
        >
            <Avatar
                name={info.fullName}
                team={isTeamOrMenu(contact) || contact.source === 'teamprofile'}
                src={isTeamOrMenu(contact) ? undefined : contact.photoURL}
                size="md"
                boxShadow="shallow"
                sx={{ border: 'none' }}
                status={status}
            />
            <VStack w="full" align="flex-start" spacing="0">
                {info.fullName && (
                    <Body size="sm" variant="bold" color="white">
                        {info.fullName}
                    </Body>
                )}
                {info.jobTitle && (
                    <Body size="xs" color="white">
                        {info.jobTitle}
                    </Body>
                )}
            </VStack>
            <Spacer />
            {contact.extension ? (
                <IconButton
                    ml="auto"
                    size="md"
                    variant="transparent"
                    icon={<PhoneIconSolid color={`${colorMode}.tones.whiteLilac`} />}
                    aria-label="transfer"
                    onClick={() =>
                        contact.extension &&
                        onTransfer(
                            isTeamOrMenu(contact) || !contact.externalID
                                ? contact.ID
                                : contact.externalID
                        )
                    }
                />
            ) : (
                <TransferNumberOverflow contact={contact} onTransfer={onTransfer} />
            )}
        </HStack>
    )
}
