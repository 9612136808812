import { HStack, useColorMode, useDisclosure, useMultiStyleConfig } from '@chakra-ui/react'
import {
    call,
    MeetingEventType,
    OpenCreateMeetingWindow,
    openMeetingWindow,
    selectAuthenticatedUser,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import {
    ContactDetail,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList
} from '@missionlabs/react'
import { PinIcon, UsersIcon } from '@missionlabs/react/circleloop'
import {
    ArrowLeftIconSolid,
    BinIconRegular,
    DotsOutlineVerticalIconRegular,
    EditIconRegular,
    GridIconSolid,
    NotificationIconSolid,
    NotificationOffIconSolid,
    PhoneIconRegular,
    SearchIconRegular,
    VideoIconRegular
} from '@missionlabs/react/zeta'
import { Channel, isContactGroup } from '@missionlabs/types'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useGetPresence } from 'shared/hooks/useGetPresence'
import { useGetUserData } from 'shared/hooks/useGetUserData'
import { useMuteChatChannelNotifications } from 'shared/hooks/useMuteChatChannelNotifications'
// import { useVideoCall } from 'shared/hooks/useVideoCall'
import {
    useGetChatGroupsQuery,
    usePinChannelMutation,
    useRemoveUserFromChannelMutation
} from 'shared/store'

import { DeleteChatGroupDialog } from './ChatGroups/DeleteChatGroupDialog'
import { LeaveChatGroupDialog } from './ChatGroups/LeaveChatGroupDialog'
import { getChannelPresenceID } from './ChatList/ChatListRow'
import { useChatContext } from './context/useChatContext'

export interface ChatHeaderProps {
    item: Channel
    isThread?: boolean
}

export const ChatHeader: FC<ChatHeaderProps> = ({ item, isThread }) => {
    const { colorMode } = useColorMode()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const leaveGroupModal = useDisclosure()
    const deleteGroupModal = useDisclosure()
    const { t } = useTranslation()
    const [removeUserFromChannel] = useRemoveUserFromChannelMutation()
    const styles = useMultiStyleConfig('Notification')
    const chatExtension = item.user?.extension ?? item.team?.extension
    const userData = useGetUserData().user
    const user = useSelector(selectAuthenticatedUser)
    const { data: chatGroups } = useGetChatGroupsQuery()

    const { onMuteChatChannelNotifications } = useMuteChatChannelNotifications(item.ID)

    const muted = !!userData?.notifications?.mutedChatChannels?.includes(item.ID)

    // Is the current user the owner of the chat group?
    const isGroupOwner = useMemo(() => {
        const currentGroupID = item.group?.groupID
        if (!currentGroupID) return false
        const matchingGroup = chatGroups?.find(({ ID }) => ID === currentGroupID)
        return matchingGroup?.ownerID === user?.userID
    }, [item, chatGroups, user])

    const [pinChannel] = usePinChannelMutation()
    const presenceID = getChannelPresenceID(item, userData?.ID)
    const { status } = useGetPresence(presenceID)

    const { setSearchOpen } = useChatContext().search

    async function leaveChat() {
        try {
            await removeUserFromChannel(item.ID)
            navigate('/chat')
        } catch (e) {
            console.error('Error Leaving Chat', e)
        }
    }

    const handleCall = useCallback(() => {
        if (!chatExtension || !user?.extension) return
        dispatch(call({ to: chatExtension, from: user.extension }))
    }, [dispatch, chatExtension, user])

    const handleVideoCall = useCallback(() => {
        if (!user) return

        const otherParticipants = item.user?.ID
            ? [{ userID: item.user.ID }]
            : item.members?.map(memberID => ({ userID: memberID })) ?? []

        const payload: OpenCreateMeetingWindow = {
            event: MeetingEventType.CREATE_VIDEO_CALL,
            otherParticipants,
            userID: user?.userID,
            clientID: user.clientID,
            token: user.token,
            username: user.username
        }
        dispatch(openMeetingWindow(payload))
    }, [user, item, dispatch])

    function navigateToChannel() {
        navigate(-1)
    }

    const name = isContactGroup(item) ? item.name : item.channelName

    return (
        <>
            <HStack
                spacing="10px"
                w="full"
                h="80px"
                px="24px"
                py="16px"
                align="center"
                justify="space-between"
                bg={`${colorMode}.tones.ghostWhite`}
                borderBottom="1px solid"
                borderColor={`${colorMode}.tones.periwinkle`}
            >
                <HStack>
                    {isThread && (
                        <IconButton
                            alignSelf="start"
                            variant="transparent"
                            icon={<ArrowLeftIconSolid boxSize="20px" onClick={navigateToChannel} />}
                            aria-label="Back To Chat"
                        />
                    )}

                    <ContactDetail
                        size="md"
                        icon={isContactGroup(item) ? <GridIconSolid p="3.5px" /> : undefined}
                        name={isThread ? `${name} - Thread` : name}
                        subText={item.user?.companyName || item.team?.companyName}
                        containerSx={{ marginLeft: '8px' }}
                        status={status}
                        team={!!item.team}
                        subTextSize="md"
                    />
                </HStack>
                <HStack spacing={0}>
                    <IconButton
                        size="md"
                        variant="transparent"
                        aria-label="Search chat"
                        onClick={handleVideoCall}
                    >
                        <VideoIconRegular boxSize="24px" p="4px" />
                    </IconButton>

                    {chatExtension && (
                        <IconButton
                            size="md"
                            variant="transparent"
                            aria-label="Search chat"
                            onClick={handleCall}
                        >
                            <PhoneIconRegular boxSize="24px" p="4px" />
                        </IconButton>
                    )}

                    <Menu placement="bottom-end" offset={[0, 0]}>
                        <MenuButton size="md" variant="transparent" aria-label="Search chat">
                            <DotsOutlineVerticalIconRegular boxSize="24px" p="4px" />
                        </MenuButton>
                        <MenuList>
                            {item.group?.groupID && (
                                <MenuItem
                                    icon={<UsersIcon />}
                                    onClick={() => navigate(`/chat/groups/${item.group?.groupID}`)}
                                >
                                    {t('chatGroups.view')}
                                </MenuItem>
                            )}
                            {isGroupOwner && (
                                <MenuItem
                                    icon={<EditIconRegular />}
                                    onClick={() =>
                                        navigate(`/chat/groups/${item.group?.groupID}/edit`)
                                    }
                                >
                                    {t('chatGroups.edit')}
                                </MenuItem>
                            )}
                            <MenuItem
                                icon={
                                    <PinIcon
                                        sx={{
                                            color: item?.isPinned
                                                ? `${colorMode}.primary.gamma`
                                                : `${colorMode}.coolGrey.40`
                                        }}
                                    />
                                }
                                onClick={() => pinChannel(item.ID)}
                            >
                                {item?.isPinned ? t('Unfavourite') : t('Favourite')}
                            </MenuItem>
                            <MenuItem
                                icon={
                                    muted ? (
                                        <NotificationIconSolid sx={{ ...styles.muteIcon }} />
                                    ) : (
                                        <NotificationOffIconSolid sx={{ ...styles.muteIcon }} />
                                    )
                                }
                                onClick={onMuteChatChannelNotifications}
                            >
                                {muted ? t('chat.unmuteChat') : t('chat.muteChat')}
                            </MenuItem>
                            <MenuItem
                                icon={<SearchIconRegular />}
                                onClick={() => setSearchOpen(true)}
                            >
                                Search in feed
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                icon={<BinIconRegular />}
                                variant="destructive"
                                onClick={leaveChat}
                            >
                                {t('chat.deleteChat')}
                            </MenuItem>
                            {item.group && isGroupOwner && (
                                <MenuItem
                                    icon={<BinIconRegular />}
                                    variant="destructive"
                                    onClick={leaveGroupModal.onOpen}
                                >
                                    {t('chatGroups.leave.cta')}
                                </MenuItem>
                            )}
                            {item.group && isGroupOwner && (
                                <MenuItem
                                    icon={<BinIconRegular />}
                                    variant="destructive"
                                    onClick={deleteGroupModal.onOpen}
                                >
                                    {t('chatGroups.delete.cta')}
                                </MenuItem>
                            )}
                        </MenuList>
                    </Menu>
                </HStack>
            </HStack>
            {item.group?.groupID && (
                <LeaveChatGroupDialog
                    groupID={item.group.groupID}
                    isOpen={leaveGroupModal.isOpen}
                    onClose={leaveGroupModal.onClose}
                />
            )}
            {item.group?.groupID && isGroupOwner && (
                <DeleteChatGroupDialog
                    groupID={item.group.groupID}
                    isOpen={deleteGroupModal.isOpen}
                    onClose={deleteGroupModal.onClose}
                />
            )}
        </>
    )
}
