import { Center, Spinner } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { useEffect } from 'react'
import { useOnLogout } from 'shared/hooks/useOnLogout'

export const Logout = () => {
    const user = useSelector(selectAuthenticatedUser)
    const { handleLogout } = useOnLogout()

    useEffect(() => {
        if (user) handleLogout()

        return () => {}
    }, [handleLogout, user])

    return (
        <Center w="100vw" h="100vh">
            <Spinner size="xl" />
        </Center>
    )
}
