import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const EmailIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EmailIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 4H19C20.6569 4 22 5.34315 22 7V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V7C2 5.34315 3.34315 4 5 4ZM4.08983 6.87802C4.2279 6.63887 4.5337 6.55693 4.77284 6.695L12 10.8676L19.2272 6.695C19.4663 6.55693 19.7721 6.63887 19.9102 6.87802L19.9461 6.94018C20.0841 7.17933 20.0022 7.48513 19.763 7.6232L12.2753 11.9462C12.1886 11.9963 12.0931 12.0174 12 12.0126C11.9069 12.0174 11.8114 11.9963 11.7247 11.9462L4.23695 7.6232C3.99781 7.48513 3.91587 7.17933 4.05394 6.94018L4.08983 6.87802Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_179"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="2"
                y="4"
                width="20"
                height="16"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 4H19C20.6569 4 22 5.34315 22 7V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V7C2 5.34315 3.34315 4 5 4ZM4.08983 6.87802C4.2279 6.63887 4.5337 6.55693 4.77284 6.695L12 10.8676L19.2272 6.695C19.4663 6.55693 19.7721 6.63887 19.9102 6.87802L19.9461 6.94018C20.0841 7.17933 20.0022 7.48512 19.763 7.6232L12.2753 11.9462C12.1886 11.9963 12.0931 12.0174 12 12.0126C11.9069 12.0174 11.8114 11.9963 11.7247 11.9462L4.23695 7.6232C3.99781 7.48513 3.91587 7.17933 4.05394 6.94018L4.08983 6.87802Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_179)"></g>
        </g>
    )
})
