import { Checkbox, useFormContext, ValidatedInput } from '@missionlabs/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReportFormFields } from '../form'

const UserTeamMenuVisabilityInputs: React.FC = () => {
    const { watch, setValue } = useFormContext<ReportFormFields>()
    const { t } = useTranslation()

    return (
        <>
            <ValidatedInput name="visibility.showUserName">
                <Checkbox
                    checked={watch('visibility.showUserName')}
                    onChange={e => setValue('visibility.showUserName', e.target.checked)}
                >
                    {t('analytics.reports.form.username')}
                </Checkbox>
            </ValidatedInput>

            <ValidatedInput name="visibility.showTeamName">
                <Checkbox
                    checked={watch('visibility.showTeamName')}
                    onChange={e => setValue('visibility.showTeamName', e.target.checked)}
                >
                    {t('analytics.reports.form.teamname')}
                </Checkbox>
            </ValidatedInput>

            <ValidatedInput name="visibility.showMenuName">
                <Checkbox
                    checked={watch('visibility.showMenuName')}
                    onChange={e => setValue('visibility.showMenuName', e.target.checked)}
                >
                    {t('analytics.reports.form.menuname')}
                </Checkbox>
            </ValidatedInput>
        </>
    )
}

export default UserTeamMenuVisabilityInputs
