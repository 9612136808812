import {
    Box,
    DrawerHeader as ChakraDrawerHeader,
    HStack,
    ModalHeaderProps,
    useModalContext
} from '@chakra-ui/react'
import { Button } from 'atoms'
import { XIconRegular } from 'atoms/Icons/zeta'
import { forwardRef } from 'react'

import { DrawerTitle } from './DrawerTitle'

export interface DrawerHeaderProps extends ModalHeaderProps {}

export const DrawerHeader = forwardRef<HTMLDivElement, DrawerHeaderProps>(
    ({ alignItems, children, ...props }, ref) => {
        const { onClose } = useModalContext()
        return (
            <Box ref={ref}>
                <ChakraDrawerHeader {...props}>
                    <HStack spacing={0} align={alignItems ?? 'center'} justify="space-between">
                        <DrawerTitle>{children}</DrawerTitle>
                        <Button
                            aria-label="Close"
                            boxSize="40px"
                            variant="transparent"
                            onClick={onClose}
                        >
                            <XIconRegular boxSize="24px" p="6px" />
                        </Button>
                    </HStack>
                </ChakraDrawerHeader>
            </Box>
        )
    }
)
