import { Divider, HStack, Spacer, useColorMode, VStack } from '@chakra-ui/react'
import {
    Body,
    Button,
    Checkbox,
    FormProvider,
    Heading,
    Input,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetClientQuery } from 'shared/store'

export type BlockedNumberFormFields = {
    numberE164: string
    label: string
    inbound: boolean
    outbound: boolean
}

export const defaultValues = {
    numberE164: '',
    label: '',
    inbound: false,
    outbound: false
}

export interface BlockedNumberFormProps {
    values?: BlockedNumberFormFields
    isLoading?: boolean
    onClose: () => void
    onDelete: () => void
    onSubmit: (data: BlockedNumberFormFields) => Promise<void>
}

export const BlockedNumberForm: FC<BlockedNumberFormProps> = ({
    values,
    isLoading,
    onClose,
    onDelete,
    onSubmit
}) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const { data: client } = useGetClientQuery()

    const blockedNumberSchema = yup.object({
        numberE164: yup
            .string()
            .phone(client?.locale ?? 'GB')
            .required(),
        label: yup.string().defined({ key: 'validations.required' }),
        incoming: yup.boolean(),
        outgoing: yup.boolean()
    })

    const methods = useYupForm<BlockedNumberFormFields>(blockedNumberSchema, {
        defaultValues: defaultValues,
        values: values
    })
    const {
        formState: { isDirty }
    } = methods

    return (
        <FormProvider {...methods}>
            <VStack
                as="form"
                h="full"
                align="stretch"
                spacing="16px"
                padding="24px"
                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <HStack spacing="16px" align="start">
                    <ValidatedInput name="numberE164" label={t('Number')}>
                        <Input />
                    </ValidatedInput>
                    <ValidatedInput name="label" label={t('Reason')}>
                        <Input />
                    </ValidatedInput>
                </HStack>
                <div style={{ padding: '10px' }}>
                    <Divider />
                </div>
                <VStack width="full" align="start">
                    <Heading size="h4" variant="bold">
                        {t('blockedNumbers.subTitle')}
                    </Heading>
                    <Body size="sm" color={`${colorMode}.tones.stormGrey`}>
                        {t('blockedNumbers.blockedNumbersDescription')}
                    </Body>
                </VStack>

                <ValidatedInput name="inbound">
                    <Checkbox>{t('blockedNumbers.inboundCalls')}</Checkbox>
                </ValidatedInput>
                <ValidatedInput name="outbound">
                    <Checkbox>{t('blockedNumbers.outboundCalls')}</Checkbox>
                </ValidatedInput>
                <Spacer />
                <HStack spacing="16px" align="inherit">
                    {values && (
                        <Button
                            w="full"
                            size="lg"
                            variant="negatory-outline"
                            isDisabled={isLoading}
                            onClick={onDelete}
                        >
                            {t('Remove')}
                        </Button>
                    )}
                    <Button
                        w="full"
                        size="lg"
                        variant="secondary"
                        isDisabled={isLoading}
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        type="submit"
                        w="full"
                        size="lg"
                        variant="creationary"
                        isDisabled={!isDirty}
                        isLoading={isLoading}
                    >
                        {t('Save')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}
