import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const FavouriteOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'FavouriteOutlineIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <g clipPath="url(#clip0_38_122)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.1162 1.94327C10.7162 0.164335 13.3126 0.147976 13.9103 1.9209L15.9485 7.58331L22.0906 7.74637L22.2618 7.76327C23.1577 7.91141 23.7567 8.56568 23.9402 9.30578C24.1238 10.0465 23.8931 10.8856 23.1313 11.3915L18.2575 15.0182L20.0526 20.7669C20.3184 21.69 19.9367 22.5415 19.3048 23.0121C18.6721 23.4833 17.7726 23.5819 17.0224 22.9889L11.9699 19.5122L6.83588 23.0417C5.28877 23.9627 3.31725 22.4674 3.89701 20.7359L5.68266 15.0181L0.770159 11.3618C0.05275 10.7924 -0.140366 9.94818 0.0951681 9.21577C0.330219 8.48486 0.98681 7.87682 1.93417 7.74747L1.94693 7.74573L8.08579 7.58331L10.1162 1.94327ZM11.6199 2.46061C11.6781 2.2874 11.8376 2.19086 12.0187 2.1887C12.201 2.18652 12.3533 2.27972 12.4065 2.4381L14.8187 9.13964L22.0538 9.33522C22.4317 9.41033 22.5204 9.89337 22.2212 10.091L16.4042 14.4185L18.5277 21.2186C18.5893 21.4358 18.5077 21.6164 18.3838 21.7144C18.26 21.8123 18.1065 21.8236 17.9774 21.7208L11.9702 17.5854L5.98383 21.7033C5.85833 21.7739 5.69239 21.7523 5.55571 21.6434C5.4187 21.5342 5.3596 21.3756 5.40994 21.2258L7.53601 14.4185L1.7447 10.1086C1.59166 9.98543 1.55759 9.8162 1.61051 9.66656C1.66347 9.51679 1.81388 9.36563 2.07818 9.3251L9.21557 9.13981L11.6183 2.46533L11.6199 2.46061Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_38_122">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </g>
    )
})

export const FavouriteIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'FavouriteIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <g clipPath="url(#clip0_38_139)">
                <path
                    d="M10.1162 1.94327C10.7162 0.164335 13.3126 0.147976 13.9103 1.9209L15.9485 7.58331L22.0906 7.74637L22.2618 7.76327C23.1577 7.91141 23.7567 8.56568 23.9402 9.30578C24.1238 10.0465 23.8931 10.8856 23.1313 11.3915L18.2575 15.0182L20.0526 20.7669C20.3184 21.69 19.9367 22.5415 19.3048 23.0121C18.6721 23.4833 17.7726 23.5819 17.0224 22.9889L11.9699 19.5122L6.83588 23.0417C5.28877 23.9627 3.31725 22.4674 3.89701 20.7359L5.68266 15.0181L0.770159 11.3618C0.05275 10.7924 -0.140366 9.94818 0.0951681 9.21577C0.330219 8.48486 0.98681 7.87682 1.93417 7.74747L1.94693 7.74573L8.08579 7.58331L10.1162 1.94327Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_38_139">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </g>
    )
})
