import { DirectoryEntry, UserActivity } from '@missionlabs/types'
import { ActivityFeedFooterSMSContent } from 'features/activity/ActivityFeed/ActivityFeedFooterSMSContent'
import { ActivityFeedFooterWhatsAppContent } from 'features/activity/ActivityFeed/ActivityFeedFooterWhatsAppContent'
import { ActivityFooterMessageType } from 'features/activity/types'
import { FC } from 'react'

import { ActivityFeedFooterNotesContent } from './ActivityFeedFooterNotesContent'
import { useActivityFooterState } from './hooks/useActivityFooterState'

export interface ActivityFeedFooterProps {
    activity: UserActivity
    contact?: DirectoryEntry
    contactNumber?: string
}

export const ActivityFeedFooter: FC<ActivityFeedFooterProps> = ({
    contact,
    contactNumber,
    activity
}) => {
    const { messageType } = useActivityFooterState()
    switch (messageType) {
        case ActivityFooterMessageType.WhatsApp:
            return (
                <ActivityFeedFooterWhatsAppContent
                    activity={activity}
                    contact={contact}
                    contactNumber={contactNumber}
                />
            )

        case ActivityFooterMessageType.Notes:
            return <ActivityFeedFooterNotesContent />

        case ActivityFooterMessageType.SMS:
        default:
            return (
                <ActivityFeedFooterSMSContent
                    activity={activity}
                    contact={contact}
                    contactNumber={contactNumber}
                />
            )
    }
}
