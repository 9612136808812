import {
    BaseURL,
    selectAPIBaseURL,
    selectAuthenticatedUser,
    selectAuthEnvironment
} from '@missionlabs/api'
import { DeploymentEnvironments } from '@missionlabs/types'
import { getLogsEnvironment, populateUrl } from '@missionlabs/utils'
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import isElectron from 'is-electron'

export const emojiBaseQuery = (args, api, extraOptions) => {
    const rawBaseQuery = fetchBaseQuery({
        baseUrl: `https://cdn.jsdelivr.net/npm/@emoji-mart/data@latest/sets/14`
    })

    return rawBaseQuery(args, api, extraOptions)
}
function getAuth(api) {
    const user = selectAuthenticatedUser(api.getState())

    /*
     * If no user is found in the Store we return every request with a 401.
     */
    if (!user) {
        return {
            error: {
                status: 401,
                statusText: 'Unauthorized Request',
                data: 'User is undefined'
            }
        }
    }

    const { clientID, token } = user

    if (!token) {
        return {
            error: {
                status: 401,
                statusText: 'Unauthorized Request',
                data: 'User Token is undefined'
            }
        }
    }

    if (!clientID) {
        return {
            error: {
                status: 401,
                statusText: 'Unauthorized Request',
                data: 'Client ID is undefined'
            }
        }
    }

    return { clientID, token }
}

export const authenticatedBaseQuery = (args, api, extraOptions) => {
    const auth = getAuth(api)
    if (auth.error) return auth
    const user = selectAuthenticatedUser(api.getState())

    if (!user) {
        return {
            error: {
                status: 401,
                statusText: 'Unauthorized Request',
                data: 'User is undefined'
            }
        }
    }

    const { userID } = user
    const { clientID, token } = auth

    /*
     * Dynamically configure the Authenticated Users Client in every request URL.
     * Auto-populate the X-TOKEN header with the users Token.
     */

    let urlEnd = typeof args === 'string' ? args : args.url

    // Automatically prepend the `/clients/:clientID` part of the URL
    // so that we don't have to specify it manually in each request
    let adjustedUrl = `clients/${clientID}${urlEnd}`

    // Some paths are global and shouldn't be prepended with clientID
    const globalPaths = [
        'kvpgroups',
        'numbers/available',
        'address/lookup',
        '/numberverification',
        '/lookup'
    ]
    const isGlobal = globalPaths.some(path => urlEnd.includes(path))
    if (isGlobal) {
        adjustedUrl = urlEnd
    }

    // For any requests that go via chat/
    // we need to re-route them through the /chat/ endpoint which acts as a proxy
    const isChat = urlEnd.includes('chat/')
    if (isChat) {
        urlEnd = urlEnd.replace('chat/', '')
        adjustedUrl = `chat/clients/${clientID}/users/${userID}/${urlEnd}`
    }

    const isMessaging = urlEnd.startsWith('/messaging/')
    if (isMessaging) {
        urlEnd = urlEnd.replace(':clientID', clientID).replace(':userID', userID)
        adjustedUrl = urlEnd
    }

    const additionalArgs = typeof args !== 'string' ? args : ({} as any)
    const adjustedHeaders = {
        'X-TOKEN': token,
        'X-AppVersion': `${isElectron() ? 'desktop' : 'webapp'}-${__VERSION__}`,
        ...args.headers
    }

    const adjustedArgs = {
        ...additionalArgs,
        url: adjustedUrl,
        headers: adjustedHeaders
    }

    const { environment, dataCentre, country, product } = selectAuthEnvironment(api.getState())

    const API_BASE_URL = selectAPIBaseURL(api.getState())
    const rawBaseQuery = fetchBaseQuery({
        baseUrl: populateUrl(API_BASE_URL, { dataCentre, environment, country, product })
    })

    return rawBaseQuery(adjustedArgs, api, extraOptions)
}

export const logsBaseQuery = (args, api, extraOptions) => {
    const { environment: env, dataCentre, country, product } = selectAuthEnvironment(api.getState())

    const LOGS_BASE_URL = BaseURL.LOGS
    const environment = env === DeploymentEnvironments.prod ? 'prd' : 'dev'
    const rawBaseQuery = fetchBaseQuery({
        baseUrl: populateUrl(LOGS_BASE_URL, { dataCentre, environment, country, product }),
        prepareHeaders: headers => {
            headers.set('x-api-key', getLogsEnvironment())
        }
    })

    return rawBaseQuery(args, api, extraOptions)
}

export const pbxBaseQuery = (args, api, extraOptions) => {
    const { environment, dataCentre, country, product } = selectAuthEnvironment(api.getState())

    const API_BASE_URL = selectAPIBaseURL(api.getState())
    const rawBaseQuery = fetchBaseQuery({
        baseUrl: populateUrl(API_BASE_URL, { dataCentre, environment, country, product })
    })

    return rawBaseQuery(args, api, extraOptions)
}

interface TempToken {
    object: 'token'
    deleteFlag: boolean
    extendable: boolean
    type: 'temp'
    brand: string
    expires: number
    ID: string
    ttl: number
    created: string
    updated: string
}

const tempTokenCache = new Map<string, TempToken>()

// retrieve a cached temp token or fetch a new one when not appropriate
async function fetchTempToken(
    baseUrl: string,
    cache = tempTokenCache
): Promise<TempToken | undefined> {
    const cachedToken = cache.get(baseUrl)

    if (cachedToken && cachedToken.expires > Date.now()) return cachedToken

    const response = await fetch(`${baseUrl}/tokens/temp`)
    if (response.ok) {
        const token: TempToken = await response.json()
        cache.set(baseUrl, token)
        return token
    }

    return undefined
}

export async function pbxTempTokenBaseQuery(args, api, extraOptions) {
    const { environment, dataCentre, country, product } = selectAuthEnvironment(api.getState())

    const baseUrl = populateUrl(selectAPIBaseURL(api.getState()), {
        dataCentre,
        environment,
        country,
        product
    })
    const rawBaseQuery = fetchBaseQuery({ baseUrl })

    const token = await fetchTempToken(baseUrl)
    const adjustedHeaders = {
        'X-Token': token?.ID,
        'X-AppVersion': `${isElectron() ? 'desktop' : 'webapp'}-${__VERSION__}`,
        ...args.headers
    }

    const additionalArgs = typeof args !== 'string' ? args : ({} as any)
    const adjustedArgs = {
        ...additionalArgs,
        url: typeof args === 'string' ? args : args.url,
        headers: adjustedHeaders
    }
    return rawBaseQuery(adjustedArgs, api, extraOptions)
}

export const zetaIDBaseQuery = (args, api, extraOptions) => {
    const { environment, dataCentre, country } = selectAuthEnvironment(api.getState())

    const ID_BASE_URL = BaseURL.ZETAID
    const rawBaseQuery = fetchBaseQuery({
        baseUrl: populateUrl(ID_BASE_URL, { dataCentre, environment, country, product: 'css' })
    })

    return rawBaseQuery(args, api, extraOptions)
}

export const lsBaseQuery = (args, api, extraOptions) => {
    const auth = getAuth(api)
    if (auth.error) return auth

    const { token } = auth

    const additionalArgs = typeof args !== 'string' ? args : ({} as any)
    const adjustedHeaders = {
        'X-TOKEN': token,
        ...args.headers
    }
    const adjustedArgs = {
        ...additionalArgs,
        headers: adjustedHeaders
    }

    const { environment, dataCentre, country, product } = selectAuthEnvironment(api.getState())

    const LS_BASE_URL = BaseURL.LS_API
    const rawBaseQuery = fetchBaseQuery({
        baseUrl: populateUrl(LS_BASE_URL, { dataCentre, environment, country, product })
    })

    return rawBaseQuery(adjustedArgs, api, extraOptions)
}
