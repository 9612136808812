import { Center, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body, ListItem, SearchInput, useFormContext, useFuzzySearch } from '@missionlabs/react'
import { CloseCircleIcon, SearchIcon } from '@missionlabs/react/circleloop'
import { HuntGroup } from '@missionlabs/types'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetHuntGroupsQuery } from 'shared/store'

export const AddUserToTeams: FC = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { data: teams = [] } = useGetHuntGroupsQuery()
    const { watch, setValue } = useFormContext()
    const [query, setQuery] = useState('')

    const { teams: selectedTeams } = watch()

    const { results } = useFuzzySearch<HuntGroup>({
        items: teams,
        searchValue: query,
        keys: ['name'],
        options: {
            minMatchCharLength: 1
        }
    })

    const isAdded = useCallback(
        (teamID: string) => selectedTeams.some(t => t.ID === teamID),
        [selectedTeams]
    )

    const onAddTeam = useCallback(
        (team: HuntGroup) => {
            if (isAdded(team.ID)) {
                setValue(
                    'teams',
                    selectedTeams.filter(t => t.ID !== team.ID)
                )
            } else {
                setValue('teams', [
                    ...selectedTeams,
                    { ID: team.ID, name: team.name, object: 'team' }
                ])
            }
        },
        [isAdded, setValue, selectedTeams]
    )

    return (
        <VStack spacing="16px" w="full" p="24px">
            <SearchInput
                placeholder="Search name"
                bg={`${colorMode}.coolGrey.0`}
                rightElement={<SearchIcon color={`${colorMode}.coolGrey.60`} />}
                onChange={e => setQuery(e.target.value)}
            />
            <VStack spacing={0} w="full">
                {results.length > 0 ? (
                    results.map(team => (
                        <ListItem
                            key={team.ID}
                            onClick={() => onAddTeam(team)}
                            border={isAdded(team.ID) ? '1px auto' : 'auto'}
                            bg={isAdded(team.ID) ? `${colorMode}.coolGrey.0` : 'auto'}
                        >
                            <HStack w="full" px="16px" align="center" justify="space-between">
                                <VStack spacing={1} align="start">
                                    <Body size="md" sx={{ color: `${colorMode}.coolGrey.80` }}>
                                        {team.name}
                                    </Body>
                                    <Body size="md" sx={{ color: `${colorMode}.coolGrey.80` }}>
                                        {t('membersCount', {
                                            count: team.callRoute.teamMembers.length
                                        })}
                                    </Body>
                                </VStack>
                                {isAdded(team.ID) && (
                                    <CloseCircleIcon
                                        color={`${colorMode}.red.default`}
                                        data-testid="clear-icon"
                                    />
                                )}
                            </HStack>
                        </ListItem>
                    ))
                ) : (
                    <Center p={5} role="listitem">
                        <Body>{t('No results found')}</Body>
                    </Center>
                )}
            </VStack>
        </VStack>
    )
}
