import {
    IntegrationTypeEnum,
    NewSubscriptionGroup,
    UpdateSubscriptionGroup
} from '@missionlabs/types'
import { useSettingsContext } from 'features/settings/components/settingsContext'
import { SettingsTitle } from 'features/settings/components/SettingsTitle'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { SubscriptionGroup } from './SubscriptionGroup'

export interface IntegrationsProps {}

export const Integrations: FC<IntegrationsProps> = () => {
    const { t } = useTranslation()

    const {
        user,
        subscriptionGroups,
        isLoading,
        onCreateSubscriptionGroup,
        onUpdateSubscriptionGroup,
        onDeleteSubscriptionGroup
    } = useSettingsContext()

    const handleCreate = useCallback(
        (subscriptionGroup: NewSubscriptionGroup) => {
            const updated = { ...subscriptionGroup, userID: user?.ID }

            onCreateSubscriptionGroup(updated)
        },
        [onCreateSubscriptionGroup, user]
    )

    const handleUpdate = useCallback(
        (subscriptionGroup: UpdateSubscriptionGroup) => {
            const updated = { ...subscriptionGroup, userID: user?.ID }

            onUpdateSubscriptionGroup(updated)
        },
        [onUpdateSubscriptionGroup, user]
    )

    const handleDelete = useCallback(
        (subscriptionGroupID: string) => {
            onDeleteSubscriptionGroup(subscriptionGroupID)
        },
        [onDeleteSubscriptionGroup]
    )

    return (
        <>
            <SettingsTitle label={t('Integrations')} />
            <SubscriptionGroup
                label={t('Webhooks')}
                integrationType={IntegrationTypeEnum.HTTP}
                subscriptionGroup={subscriptionGroups.find(
                    ({ integrationType }) => integrationType === IntegrationTypeEnum.HTTP
                )}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                isLoading={isLoading}
            />
            <SubscriptionGroup
                label="Slack"
                integrationType={IntegrationTypeEnum.SLACK}
                subscriptionGroup={subscriptionGroups.find(
                    ({ integrationType }) => integrationType === IntegrationTypeEnum.SLACK
                )}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                isLoading={isLoading}
            />
        </>
    )
}
