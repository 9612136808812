import { chakra } from '@chakra-ui/react'
import { ContentEditable as LexicalContentEditable } from '@lexical/react/LexicalContentEditable'
import { ComponentProps, FC } from 'react'

const ChakraContentEditable = chakra(LexicalContentEditable, {
    baseStyle: {
        _focusVisible: {
            boxShadow: 'none',
            outline: 'none'
        }
    },
    shouldForwardProp: () => true
})

export type ContentEditableProps = ComponentProps<typeof ChakraContentEditable>

export const ContentEditable: FC<ContentEditableProps> = props => {
    return <ChakraContentEditable {...props} />
}
