import { Box, Flex, HStack, useColorMode, useDisclosure, VStack } from '@chakra-ui/react'
import { Body, Button, Dropdown, Player, Toggle } from '@missionlabs/react'
import { RecordingDrawer } from 'features/settings/components/RecordingDrawer'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAllClientAudioClips } from 'shared/hooks/useAllAudioClips'
import { useUploadRecording } from 'shared/hooks/useUploadRecording'

interface WaitMusicProps {
    enabled?: boolean
    waitMusicUrl?: string
    onToggle: (isChecked: boolean) => void
    onChangeWaitMusicUrl: (url: string) => void
}

export function WaitMusic({
    enabled = false,
    waitMusicUrl = '',
    onToggle,
    onChangeWaitMusicUrl
}: WaitMusicProps) {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const recordingDrawer = useDisclosure()
    const uploadRecording = useUploadRecording()

    const userAndSharedAudioClips = useAllClientAudioClips()

    const userAndSharedAudioClipsWithDefault = useMemo(() => {
        return [{ label: t('Default'), value: '' }, ...userAndSharedAudioClips]
    }, [userAndSharedAudioClips, t])

    const onSaveAudio = async (file: File | null) => {
        try {
            const url = await uploadRecording(file)
            if (url) {
                onChangeWaitMusicUrl(url)
                recordingDrawer.onClose()
            }
        } catch (error) {
            console.error('components: Could not upload file', error)
        }
    }

    return (
        <Flex direction="column" align="flex-start" id="wait-music">
            <VStack align="flex-start" gap={2} w="100%">
                <HStack justify="space-between" w="100%">
                    <Body variant="bold">{t('admin.teams.form.waitMusic')}</Body>
                    <Toggle isChecked={enabled} onChange={e => onToggle(e.target.checked)} />
                </HStack>
                <Body size="sm" variant="bold" color={`${colorMode}.tones.stormGrey`}>
                    {t('admin.teams.form.waitMusic_subtext')}
                </Body>
            </VStack>
            {enabled && (
                <Box w="100%">
                    <HStack gap={4} w="100%" mt={6}>
                        <Dropdown
                            w="100%"
                            value={waitMusicUrl}
                            options={userAndSharedAudioClipsWithDefault}
                            onChange={onChangeWaitMusicUrl}
                        />
                        <Button minW="20%" onClick={recordingDrawer.onOpen}>
                            {t('globalSettings.uploadNew')}
                        </Button>
                    </HStack>
                    {waitMusicUrl && (
                        <Box w="100%" mt={4}>
                            <Player url={waitMusicUrl} width="100%" />
                        </Box>
                    )}
                    <RecordingDrawer
                        isOpen={recordingDrawer.isOpen}
                        onClose={recordingDrawer.onClose}
                        onSubmit={file => onSaveAudio(file)}
                    />
                </Box>
            )}
        </Flex>
    )
}
