import { SelectOption } from '@missionlabs/react'
import { TFunction } from 'i18next'

export const getTimeoutOptions = (t: TFunction): SelectOption[] => [
    { value: 30, label: t('calls.timeoutAfter', { timeout: 30 }) },
    { value: 25, label: t('calls.timeoutAfter', { timeout: 25 }) },
    { value: 20, label: t('calls.timeoutAfter', { timeout: 20 }) },
    { value: 15, label: t('calls.timeoutAfter', { timeout: 15 }) },
    { value: 10, label: t('calls.timeoutAfter', { timeout: 10 }) }
]
