import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const AsteriskIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AsteriskIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M10.7737 19L10.9781 13.6528L6.22628 16.5208L5 14.4792L10.0073 12L5 9.52083L6.22628 7.47917L10.9781 10.3472L10.7737 5H13.2263L13.0219 10.3472L17.7737 7.47917L19 9.52083L13.9927 12L19 14.4792L17.7737 16.5208L13.0219 13.6528L13.2263 19H10.7737Z"
            fill="currentColor"
        />
    )
})
