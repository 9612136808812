import { Flex, HStack } from '@chakra-ui/react'
import {
    Badge,
    Button,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useFormContext
} from '@missionlabs/react'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ParamFormKey, ReportFormFields } from '../form'
import { ReportFormMenus } from './ReportFormMenus'
import { ReportFormNumbers } from './ReportFormNumbers'
import { ReportFormTeams } from './ReportFormTeams'
import { ReportFormUsers } from './ReportFormUsers'

interface Props {
    isSubmitting: boolean
}

const ReportFormItems: React.FC<Props> = ({ isSubmitting }) => {
    const ctx = useFormContext<ReportFormFields>()
    const { setCreate } = useAnalytics().report

    const { t } = useTranslation()

    const onClickItem = (formKey: ParamFormKey, ID: string) => {
        const values = ctx.getValues(formKey)

        const newValues = values.includes(ID) ? values.filter(v => v !== ID) : [...values, ID]

        ctx.setValue(formKey, newValues)
    }

    const isSelected = (formKey: ParamFormKey, ID: string) => {
        return ctx.watch(formKey).includes(ID)
    }

    const renderBadge = (formKey: ParamFormKey) => {
        const values = ctx.watch(formKey)
        return values.length ? (
            <Badge ml={2} bgColor="red.500" colorScheme="red" color="#fff">
                {values.length}
            </Badge>
        ) : null
    }

    return (
        <Flex mx={8} direction={'column'}>
            <Tabs isLazy>
                <TabList w="100%">
                    <Tab>
                        {t('analytics.numbers')}
                        {renderBadge('params.reportNumbers')}
                    </Tab>
                    <Tab>
                        {t('analytics.users')}
                        {renderBadge('params.reportUsers')}
                    </Tab>
                    <Tab>
                        {t('analytics.menus')}
                        {renderBadge('params.reportMenus')}
                    </Tab>
                    <Tab>
                        {t('analytics.teams2')}
                        {renderBadge('params.reportTeams')}
                    </Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <ReportFormNumbers
                            onSelect={(ID: string) => onClickItem('params.reportNumbers', ID)}
                            isSelected={(ID: string) => isSelected('params.reportNumbers', ID)}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ReportFormUsers
                            onSelect={(ID: string) => onClickItem('params.reportUsers', ID)}
                            isSelected={(ID: string) => isSelected('params.reportUsers', ID)}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ReportFormMenus
                            onSelect={(ID: string) => onClickItem('params.reportMenus', ID)}
                            isSelected={(ID: string) => isSelected('params.reportMenus', ID)}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ReportFormTeams
                            onSelect={(ID: string) => onClickItem('params.reportTeams', ID)}
                            isSelected={(ID: string) => isSelected('params.reportTeams', ID)}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <HStack pt={4} w="full">
                <Button w="50%" variant="secondary" onClick={() => setCreate(false)}>
                    {t('Cancel')}
                </Button>
                <Button w="50%" isLoading={isSubmitting} type="submit" variant="creationary">
                    {t('analytics.reports.create')}
                </Button>
            </HStack>
        </Flex>
    )
}

export default ReportFormItems
