import {
    Box,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    Stepper,
    StepSeparator,
    StepStatus,
    StepTitle,
    useSteps,
    VStack
} from '@chakra-ui/react'
import { RegisterWhatsAppNumberForm } from 'features/admin/pages/WhatsApp/VerifyNumber/RegisterWhatsAppNumberForm'
import { RequestWhatsAppCodeForm } from 'features/admin/pages/WhatsApp/VerifyNumber/RequestWhatsAppCodeForm'
import { VerifyWhatsAppNumberForm } from 'features/admin/pages/WhatsApp/VerifyNumber/VerifyWhatsAppNumberForm'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetWhatsAppNumbersQuery } from 'shared/store'

const stages = [
    'CREATED',
    'CODE_REQUEST_SUCCESS',
    'CODE_VERIFICATION_SUCCESS',
    'NUMBER_REGISTRATION_SUCCESS'
]

const getStepIndex = (status?: string) => {
    if (!status) return 0
    return stages.indexOf(status)
}

interface RequestCodeStepsProps {
    onClose: () => void
}

export const RequestCodeSteps = ({ onClose }: RequestCodeStepsProps) => {
    const { t } = useTranslation()
    const { data: numbers = [] } = useGetWhatsAppNumbersQuery()
    const { id: numberID = '' } = useParams()
    const number = numbers.find(n => n.numberID === numberID)
    const stepper = useSteps({
        index: getStepIndex(number?.provisioningStatus),
        count: stages.length
    })
    const { activeStep, setActiveStep } = stepper
    useEffect(() => {
        setActiveStep(getStepIndex(number?.provisioningStatus))
    }, [number, setActiveStep])

    if (!number) return null
    return (
        <VStack align="start" p={8}>
            <Stepper size="lg" index={activeStep} w="full" orientation="vertical">
                <Step>
                    <StepIndicator>
                        <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                        />
                    </StepIndicator>

                    <Box flexShrink="0">
                        <StepTitle>{t('admin.whatsapp.requestCode.steps.request.title')}</StepTitle>
                        <StepDescription>
                            {t('admin.whatsapp.requestCode.steps.request.description')}
                        </StepDescription>
                        <RequestWhatsAppCodeForm />
                    </Box>
                    <StepSeparator />
                </Step>

                <Step>
                    <StepIndicator>
                        <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                        />
                    </StepIndicator>

                    <Box flexShrink="0">
                        <StepTitle>{t('admin.whatsapp.requestCode.steps.verify.title')}</StepTitle>
                        <StepDescription>
                            {t('admin.whatsapp.requestCode.steps.verify.description')}
                        </StepDescription>
                        <VerifyWhatsAppNumberForm />
                    </Box>
                    <StepSeparator />
                </Step>

                <Step>
                    <StepIndicator>
                        <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                        />
                    </StepIndicator>

                    <Box flexShrink="0">
                        <StepTitle>
                            {t('admin.whatsapp.requestCode.steps.register.title')}
                        </StepTitle>
                        <StepDescription>
                            {t('admin.whatsapp.requestCode.steps.register.description')}
                        </StepDescription>
                        <RegisterWhatsAppNumberForm onClose={onClose} />
                    </Box>
                    <StepSeparator />
                </Step>
            </Stepper>
        </VStack>
    )
}
