import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ChevronUpIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChevronUpIconLight',
    viewBox: '0 0 18 10',
    path: (
        <path
            d="M17.5742 9.18945C17.457 9.3457 17.3008 9.38477 17.1445 9.38477C16.9883 9.38477 16.832 9.3457 16.7148 9.22852L9.01953 2.11914L1.28516 9.18945C1.05078 9.42383 0.660156 9.42383 0.425781 9.15039C0.191406 8.91602 0.191406 8.52539 0.464843 8.29102L8.58984 0.791016C8.82422 0.556641 9.17578 0.556641 9.41016 0.791016L17.5352 8.29102C17.8086 8.56445 17.8086 8.95508 17.5742 9.18945Z"
            fill="currentColor"
        />
    )
})

export const ChevronUpIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChevronUpIconRegular',
    viewBox: '0 0 18 10',
    path: (
        <path
            d="M0.875002 7.91748L8.33594 0.769043C8.57031 0.57373 8.80469 0.456543 9.03906 0.456543C9.27344 0.456543 9.46875 0.534668 9.66406 0.690918L17.125 7.83936C17.5156 8.22998 17.5156 8.81592 17.1641 9.16748C16.8125 9.55811 16.2266 9.55811 15.8359 9.20654L9.03906 2.72217L2.16406 9.28467C1.8125 9.63623 1.1875 9.63623 0.83594 9.24561C0.484377 8.85498 0.484377 8.26904 0.875002 7.91748Z"
            fill="currentColor"
        />
    )
})

export const ChevronUpIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChevronUpIconSolid',
    viewBox: '0 0 18 10',
    path: (
        <path
            d="M16.5195 10.9805C16.168 10.9805 15.8555 10.8633 15.6211 10.6289L9.01953 4.02734L2.37891 10.6289C1.91016 11.1367 1.08984 11.1367 0.621094 10.6289C0.113281 10.1602 0.113281 9.33984 0.621094 8.87109L8.12109 1.37109C8.58984 0.863281 9.41016 0.863281 9.87891 1.37109L17.3789 8.87109C17.8867 9.33984 17.8867 10.1602 17.3789 10.6289C17.1445 10.8633 16.832 10.9805 16.5195 10.9805Z"
            fill="currentColor"
        />
    )
})

export const ChevronDownIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChevronDownIconLight',
    viewBox: '0 0 18 10',
    path: (
        <path
            d="M0.425781 0.876747C0.542968 0.720497 0.699218 0.642372 0.894531 0.642372C1.01172 0.642372 1.16797 0.681434 1.28516 0.798622L8.98047 7.86893L16.7148 0.798622C16.9492 0.564247 17.3398 0.564247 17.5742 0.837684C17.8086 1.07206 17.8086 1.46268 17.5352 1.69706L9.41016 9.19706C9.17578 9.43143 8.82422 9.43143 8.58984 9.19706L0.464843 1.69706C0.191406 1.50175 0.191406 1.11112 0.425781 0.876747Z"
            fill="currentColor"
        />
    )
})

export const ChevronDownIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChevronDownIconRegular',
    viewBox: '0 0 18 10',
    path: (
        <path
            d="M17.1445 2.10224L9.6836 9.25068C9.44922 9.44599 9.21485 9.52412 9.01953 9.52412C8.78516 9.52412 8.55078 9.44599 8.35547 9.28974L0.855471 2.10224C0.464846 1.75068 0.464846 1.12568 0.816408 0.774119C1.16797 0.383494 1.79297 0.383494 2.14453 0.735056L9.01953 7.29756L15.8555 0.735056C16.207 0.383494 16.832 0.383494 17.1836 0.774119C17.5352 1.12568 17.5352 1.75068 17.1445 2.10224Z"
            fill="currentColor"
        />
    )
})

export const ChevronDownIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChevronDownIconSolid',
    viewBox: '0 0 18 10',
    path: (
        <path
            d="M9.01953 9.99512C8.66797 9.99512 8.35547 9.87793 8.12109 9.64355L0.621094 2.14355C0.113281 1.6748 0.113281 0.854492 0.621094 0.385742C1.08984 -0.12207 1.91016 -0.12207 2.37891 0.385742L9.01953 6.9873L15.6211 0.385742C16.0898 -0.12207 16.9102 -0.12207 17.3789 0.385742C17.8867 0.854492 17.8867 1.6748 17.3789 2.14355L9.87891 9.64355C9.64453 9.87793 9.33203 9.99512 9.01953 9.99512Z"
            fill="currentColor"
        />
    )
})

export const ChevronLeftIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChevronLeftIconLight',
    viewBox: '0 0 10 18',
    path: (
        <path
            d="M9.18945 0.425575C9.3457 0.542762 9.38477 0.699012 9.38477 0.855262C9.38477 1.01151 9.3457 1.16776 9.22852 1.28495L2.11914 8.98026L9.18945 16.7146C9.42383 16.949 9.42383 17.3396 9.15039 17.574C8.91602 17.8084 8.52539 17.8084 8.29102 17.535L0.791016 9.40995C0.556641 9.17558 0.556641 8.82401 0.791016 8.58964L8.29102 0.464637C8.56445 0.1912 8.95508 0.1912 9.18945 0.425575Z"
            fill="currentColor"
        />
    )
})

export const ChevronLeftIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChevronLeftIconRegular',
    viewBox: '0 0 10 18',
    path: (
        <path
            d="M7.89786 17.1252L0.74942 9.66426C0.554108 9.42988 0.475983 9.19551 0.475983 8.96113C0.475983 8.76582 0.554108 8.53144 0.710358 8.33613L7.8588 0.875193C8.21036 0.484568 8.83536 0.484568 9.18692 0.83613C9.57755 1.18769 9.57755 1.77363 9.22598 2.16426L2.70255 8.96113L9.26505 15.8361C9.61661 16.1877 9.61661 16.8127 9.22598 17.1643C8.87442 17.5158 8.24942 17.5158 7.89786 17.1252Z"
            fill="currentColor"
        />
    )
})

export const ChevronLeftIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChevronLeftIconSolid',
    viewBox: '0 0 12 18',
    path: (
        <path
            d="M9.76953 17.7451C9.41797 17.7451 9.10547 17.6279 8.87109 17.3936L1.37109 9.89355C0.863281 9.4248 0.863281 8.60449 1.37109 8.13574L8.87109 0.635742C9.33984 0.12793 10.1602 0.12793 10.6289 0.635742C11.1367 1.10449 11.1367 1.9248 10.6289 2.39355L4.02734 8.99512L10.6289 15.6357C11.1367 16.1045 11.1367 16.9248 10.6289 17.3936C10.3945 17.6279 10.082 17.7451 9.76953 17.7451Z"
            fill="currentColor"
        />
    )
})

export const ChevronRightIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChevronRightIconLight',
    viewBox: '0 0 10 18',
    path: (
        <path
            d="M0.849609 17.574C0.693359 17.4568 0.615234 17.3006 0.615234 17.1443C0.615234 16.9881 0.693359 16.8318 0.810547 16.7146L7.88086 9.01933L0.810547 1.28495C0.576172 1.05057 0.576172 0.65995 0.849609 0.425575C1.08398 0.1912 1.47461 0.1912 1.70898 0.464637L9.20898 8.58964C9.44336 8.82401 9.44336 9.17558 9.20898 9.40995L1.70898 17.535C1.47461 17.8084 1.08398 17.8084 0.849609 17.574Z"
            fill="currentColor"
        />
    )
})

export const ChevronRightIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChevronRightIconRegular',
    viewBox: '0 0 10 18',
    path: (
        <path
            d="M2.10215 0.894724L9.25059 8.35566C9.40684 8.55097 9.52403 8.78535 9.52403 9.01972C9.52403 9.2541 9.40684 9.48847 9.25059 9.64472L2.10215 17.1057C1.75059 17.4963 1.12559 17.4963 0.774027 17.1447C0.383402 16.7932 0.383402 16.2072 0.734965 15.8166L7.29746 8.98066L0.734965 2.18379C0.383402 1.83222 0.383402 1.20722 0.774027 0.855662C1.12559 0.504099 1.75059 0.504099 2.10215 0.894724Z"
            fill="currentColor"
        />
    )
})

export const ChevronRightIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChevronRightIconSolid',
    viewBox: '0 0 12 18',
    path: (
        <path
            d="M2.26953 17.7451C1.91797 17.7451 1.60547 17.6279 1.37109 17.3936C0.863281 16.9248 0.863281 16.1045 1.37109 15.6357L7.97266 8.99512L1.37109 2.39355C0.863281 1.9248 0.863281 1.10449 1.37109 0.635742C1.83984 0.12793 2.66016 0.12793 3.12891 0.635742L10.6289 8.13574C11.1367 8.60449 11.1367 9.4248 10.6289 9.89355L3.12891 17.3936C2.89453 17.6279 2.58203 17.7451 2.26953 17.7451Z"
            fill="currentColor"
        />
    )
})
