import { Box, Center, useColorMode } from '@chakra-ui/react'
import { Input, useFuzzySearch, useMeasure } from '@missionlabs/react'
import { SearchIcon } from '@missionlabs/react/circleloop'
import { isUser, UserOrTeam } from '@missionlabs/types'
import { isTeamOrMenu } from '@missionlabs/utils'
import { ChangeEvent, FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'

import { UTMListItemData } from './UTMListItem'
import { UTMListItemRenderer } from './UTMListItemRenderer'

interface UTMListProps {
    items: UserOrTeam[]
    selectedItem?: UserOrTeam
    onSelect: (data: UserOrTeam) => void
}

// User, Team or Menu List
export const UTMList: FC<UTMListProps> = ({ items, selectedItem, onSelect }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const [search, setSearch] = useState<string>('')
    const [searchRef, { blockSize: searchHeight }] = useMeasure<HTMLDivElement>()

    const { results } = useFuzzySearch<UTMListItemData['item']>({
        items: items,
        searchValue: search,
        keys: ['fullName', 'name'] as any
    })

    const listItems: UTMListItemData[] = useMemo(() => {
        return results
            .map(item => ({ item, isSelected: item === selectedItem, onClick: onSelect }))
            .sort(({ item: a }, { item: b }) => {
                if (isUser(a) && isUser(b)) return a.fullName.localeCompare(b.fullName)
                if (isTeamOrMenu(a) && isTeamOrMenu(b)) return a.name.localeCompare(b.name)
                return 0
            })
    }, [results, selectedItem, onSelect])

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    return (
        <Box h="full">
            <Center py="16px" ref={searchRef}>
                <Input
                    value={search}
                    onChange={handleSearch}
                    placeholder={t('Search')}
                    rightIcon={<SearchIcon boxSize="20px" color={`${colorMode}.coolGrey.60`} />}
                />
            </Center>
            <AutoSizer>
                {({ height, width }) => (
                    <FixedSizeList
                        height={height - searchHeight}
                        width={width}
                        itemSize={65}
                        itemCount={listItems.length}
                        itemData={listItems}
                    >
                        {UTMListItemRenderer}
                    </FixedSizeList>
                )}
            </AutoSizer>
        </Box>
    )
}
