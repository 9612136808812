import { ButtonGroup, HStack, Text, VStack } from '@chakra-ui/react'
import { Button, Dropdown, Input, useFormContext, ValidatedInput } from '@missionlabs/react'
import { AddressLookup } from '@missionlabs/types'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetAddressesByPostcodeQuery } from 'shared/store'

export interface AddEditAddressProps {
    isDisabled?: boolean
}

export const Address: FC<AddEditAddressProps> = ({ isDisabled }) => {
    const { t } = useTranslation()

    const { watch, setValue } = useFormContext<{ address?: AddressLookup }>()

    const address = watch('address')

    if (!address) {
        return <FindAddress isDisabled={isDisabled} />
    }

    return (
        <VStack h="full" spacing="16px" align="inherit" data-testid="add-user-address">
            <Text
                as="button"
                disabled={isDisabled}
                alignSelf={'flex-end'}
                onClick={() => setValue('address', undefined, { shouldDirty: true })}
                textColor={'blue'}
                textDecoration={'underline'}
                cursor={'pointer'}
                size={'sm'}
            >
                {t('myDetails.address.findAnotherAddress')}
            </Text>
            <HStack spacing="16px" align="inherit">
                <ValidatedInput name="address.premises" label={t('myDetails.address.premises')}>
                    <Input autoComplete="user address-premises" isDisabled={isDisabled} />
                </ValidatedInput>
                <ValidatedInput name="address.street" label={t('myDetails.address.street')}>
                    <Input autoComplete="user address-street" isDisabled={isDisabled} />
                </ValidatedInput>
            </HStack>
            <HStack spacing="16px" align="inherit">
                <ValidatedInput name="address.town" label={t('myDetails.address.town')}>
                    <Input autoComplete="user address-town" isDisabled={isDisabled} />
                </ValidatedInput>

                <ValidatedInput name="address.county" label={t('myDetails.address.county')}>
                    <Input autoComplete="user address-county" isDisabled={isDisabled} />
                </ValidatedInput>
            </HStack>
            <HStack spacing="16px" align="inherit">
                <ValidatedInput name="address.postcode" label={t('myDetails.address.postcode')}>
                    <Input autoComplete="user address-postalcode" isDisabled={isDisabled} />
                </ValidatedInput>
            </HStack>
        </VStack>
    )
}

interface FindAddressProps {
    isDisabled?: boolean
}

export const FindAddress: FC<FindAddressProps> = ({ isDisabled }) => {
    const { t } = useTranslation()

    const { watch, setValue } = useFormContext<{ address?: AddressLookup }>()

    const address = watch('address')

    const [postcode, setPostcode] = useState('')
    const [canFind, setCanFind] = useState(false)

    const isPostcodeValid = useMemo(() => {
        return /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i.test(postcode.replace(/\s/g, ''))
    }, [postcode])

    const { data: addresses = [], isLoading } = useGetAddressesByPostcodeQuery(postcode, {
        /** Only make the call when the user clicks the "find address" btn
         *  and ensure postcode is valid */
        skip: !isPostcodeValid || !canFind
    })

    const options = useMemo(() => {
        return addresses.map(value => {
            const { premises, street, town, county, postcode } = value
            const label = [premises, street, town, county, postcode].filter(v => !!v).join(', ')
            return { label, value }
        })
    }, [addresses])

    function handleChange(value: any) {
        setValue('address', value, { shouldDirty: true, shouldValidate: true })
    }

    function handleClear() {
        setCanFind(false)
        setPostcode('')
    }

    return (
        <>
            {!isPostcodeValid || !addresses.length ? (
                <HStack h="full" w="full" spacing="16px" data-testid="find-user-address">
                    <Input
                        isDisabled={isDisabled}
                        width={'fit-content'}
                        autoComplete="user address-postcode"
                        onChange={e => {
                            setPostcode(e.target.value)
                        }}
                        placeholder={t('myDetails.address.postcode')}
                    />
                    <ButtonGroup justifyContent="end" width="100%">
                        <Button
                            isDisabled={isDisabled || !isPostcodeValid || isLoading}
                            onClick={() => {
                                setCanFind(true)
                            }}
                            isLoading={isLoading}
                        >
                            {t('myDetails.address.findAddress')}
                        </Button>

                        <Button
                            isDisabled={isDisabled}
                            variant={'outline'}
                            onClick={() =>
                                handleChange({
                                    premises: '',
                                    street: '',
                                    town: '',
                                    county: '',
                                    postcode: ''
                                })
                            }
                        >
                            {t('myDetails.address.enterManually')}
                        </Button>
                    </ButtonGroup>
                </HStack>
            ) : (
                <VStack data-testid="select-user-address">
                    <Text
                        as="button"
                        alignSelf={'flex-end'}
                        onClick={handleClear}
                        textColor={'blue'}
                        textDecoration={'underline'}
                        cursor={'pointer'}
                        size={'sm'}
                    >
                        {t('myDetails.address.tryAgain')}
                    </Text>
                    <HStack h="full" w="full" align="inherit">
                        <Dropdown
                            width={'-webkit-fill-available'}
                            value={address}
                            options={options}
                            onChange={value => handleChange(value)}
                            placeholder={`${t('myDetails.address.chooseAddress')}`}
                        />
                    </HStack>
                </VStack>
            )}
        </>
    )
}
