function getShorthandWithoutColor(shorthand: string): string {
    const elements = shorthand.split(' ')
    elements.pop()
    return elements.join(' ')
}

function setShorthandColor(shorthand: string, color: string): string {
    return `${getShorthandWithoutColor(shorthand)} ${color}`
}

export function setBorderColor(border: string, color: string): string {
    return setShorthandColor(border, color)
}

export function setShadowColor(shadow: string, color: string): string {
    return setShorthandColor(shadow, color)
}
