import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const FullLogo: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'FullLogo',
    viewBox: '0 0 222 44',
    defaultProps: {
        width: '222px',
        height: '44px'
    },
    path: (
        <>
            <path
                d="M87.7948 4.40958C87.4126 5.96078 86.0128 7.11111 84.3445 7.11111C82.3819 7.11111 80.7909 5.51924 80.7909 3.55556C80.7909 1.59188 82.3819 0 84.3445 0C86.0501 0 87.475 1.20231 87.8189 2.8061H95.6929C96.0246 1.53258 97.1819 0.592593 98.5586 0.592593C99.9353 0.592593 101.093 1.53258 101.424 2.8061H110.525C110.838 1.86427 111.726 1.18519 112.773 1.18519C114.081 1.18519 115.142 2.24644 115.142 3.55556C115.142 4.86468 114.081 5.92593 112.773 5.92593C111.765 5.92593 110.905 5.2967 110.562 4.40958H101.395C101.029 5.62966 99.8973 6.51852 98.5586 6.51852C97.2199 6.51852 96.0886 5.62966 95.7222 4.40958H87.7948Z"
                fill="url(#paint0_linear_1648_52023)"
            />
            <path
                d="M80.5887 14.6667H88.7366C89.1936 13.6202 90.2372 12.8889 91.4515 12.8889C92.6658 12.8889 93.7094 13.6202 94.1664 14.6667H103.614C104.023 13.9582 104.789 13.4815 105.666 13.4815C106.974 13.4815 108.035 14.5427 108.035 15.8518C108.035 17.161 106.974 18.2222 105.666 18.2222C104.789 18.2222 104.023 17.7455 103.614 17.037H94.1664C93.7094 18.0835 92.6658 18.8148 91.4515 18.8148C90.2372 18.8148 89.1936 18.0835 88.7366 17.037H80.5887C80.1009 18.418 78.7846 19.4074 77.2374 19.4074C75.6901 19.4074 74.3739 18.418 73.886 17.037H65.0753C64.6657 17.7455 63.9001 18.2222 63.0232 18.2222C61.7149 18.2222 60.6542 17.161 60.6542 15.8518C60.6542 14.5427 61.7149 13.4815 63.0232 13.4815C63.9001 13.4815 64.6657 13.9582 65.0753 14.6667H73.886C74.3739 13.2857 75.6901 12.2963 77.2374 12.2963C78.7846 12.2963 80.1009 13.2857 80.5887 14.6667Z"
                fill="url(#paint1_linear_1648_52023)"
            />
            <path
                d="M80.7099 39.6863L88.5881 39.6863C88.9228 38.4172 90.078 37.4815 91.4515 37.4815C93.087 37.4815 94.4128 38.808 94.4128 40.4444C94.4128 42.0808 93.087 43.4074 91.4515 43.4074C90.1096 43.4074 88.9762 42.5143 88.6125 41.2898H80.6899C80.3107 42.8454 78.9088 44 77.2374 44C75.5659 44 74.164 42.8454 73.7849 41.2898L65.2372 41.2898C64.8969 42.1815 64.034 42.8148 63.0232 42.8148C61.7149 42.8148 60.6542 41.7536 60.6542 40.4444C60.6542 39.1353 61.7149 38.0741 63.0232 38.0741C64.0666 38.0741 64.9525 38.749 65.2685 39.6863L73.7648 39.6863C74.112 38.0869 75.5349 36.8889 77.2374 36.8889C78.9399 36.8889 80.3628 38.0869 80.7099 39.6863Z"
                fill="url(#paint2_linear_1648_52023)"
            />
            <path
                d="M81.0319 26.8584H72.1183C71.6959 26.208 70.9633 25.7778 70.1303 25.7778C69.2973 25.7778 68.5647 26.208 68.1423 26.8584L58.2003 26.8584C57.7779 26.208 57.0453 25.7778 56.2123 25.7778C54.9039 25.7778 53.8433 26.839 53.8433 28.1481C53.8433 29.4573 54.9039 30.5185 56.2123 30.5185C57.1176 30.5185 57.9043 30.0104 58.3031 29.2636L68.0395 29.2636C68.4382 30.0104 69.225 30.5185 70.1303 30.5185C71.0356 30.5185 71.8224 30.0104 72.2212 29.2636H80.9693C81.4369 30.6809 82.7713 31.7037 84.3444 31.7037C85.9176 31.7037 87.252 30.6809 87.7196 29.2636H95.8143C96.2547 30.3472 97.3175 31.1111 98.5586 31.1111C99.7997 31.1111 100.862 30.3472 101.303 29.2636L110.682 29.2636C111.081 30.0104 111.867 30.5185 112.773 30.5185C114.081 30.5185 115.142 29.4573 115.142 28.1482C115.142 26.839 114.081 25.7778 112.773 25.7778C111.94 25.7778 111.207 26.208 110.785 26.8584L101.225 26.8584C100.746 25.8681 99.732 25.1852 98.5586 25.1852C97.3852 25.1852 96.3711 25.8681 95.8918 26.8584H87.657C87.1409 25.5323 85.8523 24.5926 84.3445 24.5926C82.8366 24.5926 81.548 25.5323 81.0319 26.8584Z"
                fill="url(#paint3_linear_1648_52023)"
            />
            <path
                d="M148.27 15.9189C148.27 16.1957 148.223 16.4655 148.128 16.7284C148.047 16.9913 147.932 17.2403 147.783 17.4755L132.114 40.0774H147.966V44.0001H125.911V42.0906C125.911 41.8277 125.951 41.5856 126.032 41.3642C126.113 41.129 126.221 40.9076 126.357 40.7001L142.087 18.0359H126.782V14.1133H148.27V15.9189Z"
                fill="white"
            />
            <path
                d="M171.137 40.0774L171.116 44.0001H152.913V14.1133H171.116V18.0359H157.677V27.0435H168.42V30.8416H157.677V40.0774H171.137Z"
                fill="white"
            />
            <path
                d="M196.361 18.1605H187.259V44.0001H182.536V18.1605H173.414V14.1133H196.361V18.1605Z"
                fill="white"
            />
            <path
                d="M213.02 32.7925L209.006 21.8755C208.682 21.0315 208.351 19.9592 208.013 18.6586C207.864 19.3089 207.702 19.9108 207.526 20.4642C207.351 21.0038 207.189 21.4812 207.04 21.8963L203.026 32.7925H213.02ZM222 44.0001H218.331C217.912 44.0001 217.574 43.8963 217.318 43.6888C217.061 43.4674 216.865 43.1976 216.73 42.8793L214.297 36.2793H201.729L199.296 42.8793C199.188 43.156 198.999 43.412 198.729 43.6472C198.458 43.8825 198.121 44.0001 197.715 44.0001H194.046L205.621 14.1133H210.425L222 44.0001Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1648_52023"
                    x1="111.937"
                    y1="4.00871"
                    x2="62.0356"
                    y2="42.0642"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7B61FF" />
                    <stop offset="1" stopColor="#33E8C8" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1648_52023"
                    x1="111.937"
                    y1="4.00871"
                    x2="62.0356"
                    y2="42.0642"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7B61FF" />
                    <stop offset="1" stopColor="#33E8C8" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1648_52023"
                    x1="111.937"
                    y1="4.00871"
                    x2="62.0356"
                    y2="42.0642"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7B61FF" />
                    <stop offset="1" stopColor="#33E8C8" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_1648_52023"
                    x1="111.937"
                    y1="4.00871"
                    x2="62.0356"
                    y2="42.0642"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7B61FF" />
                    <stop offset="1" stopColor="#33E8C8" />
                </linearGradient>
            </defs>
        </>
    )
})
