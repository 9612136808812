import { VStack } from '@chakra-ui/react'
import { NumberPortability, NumberPortabilityProbability } from '@missionlabs/types'
import { FC, useMemo } from 'react'

import LikelyPortableComponent from './LikelyPortable'
import NoPortableComponent from './NoPorting'
import YesMobilePortableComponent from './YesMobile'
import YesNonMobilePortableComponent from './YesNonMobile'

interface ShowPortingFormProps {
    onReset: () => void
    toMirror: () => void
    portability: Partial<NumberPortability>
}

const ShowPortingForm: FC<ShowPortingFormProps> = ({ onReset, toMirror, portability }) => {
    const { isMobile = false, portable = '' } = portability

    const portabilityComponent = useMemo(() => {
        switch (portable) {
            case NumberPortabilityProbability.NO: {
                return <NoPortableComponent switchToMirror={toMirror} onReset={onReset} />
            }
            case NumberPortabilityProbability.LIKELY: {
                return <LikelyPortableComponent onReset={onReset} />
            }
            case NumberPortabilityProbability.YES: {
                if (isMobile) {
                    return <YesMobilePortableComponent onReset={onReset} />
                }
                return <YesNonMobilePortableComponent onReset={onReset} />
            }
        }
    }, [isMobile, portable, onReset, toMirror])

    return <VStack pt={4}>{portabilityComponent}</VStack>
}

export default ShowPortingForm
