import { Divider, HStack, VStack } from '@chakra-ui/react'
import { selectInData } from '@missionlabs/api'
import {
    Button,
    Combobox,
    Dropdown,
    FormProvider,
    get,
    SelectOption,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { AreaCodes, AvailableNumber, NumberType } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetAreaCodesQuery, useGetNumberTypesQuery } from 'shared/store'

import { AddNumberList } from './AddNumberList'

const selectAreaCodes = selectInData<AreaCodes, Record<string, SelectOption[]>>(data => {
    if (!data) return
    return Object.assign(
        {},
        ...Object.entries(data).map(([k, v]) => ({
            [k]: v.map(item => ({
                label: `${item.description} (${item.areaCodeDisplay})`,
                value: item.areaCode
            }))
        }))
    )
})

const selectNumberTypes = selectInData<NumberType[], SelectOption[]>(data => {
    if (!data) return
    return data.map(item => ({ label: item.name, value: item.name }))
})

export interface AddNumberFields {
    areaCode: string | undefined
    numberType: string | undefined
    selectedNumberE164: AvailableNumber | undefined
}

export const defaultValues: AddNumberFields = {
    areaCode: '',
    numberType: '',
    selectedNumberE164: undefined
}

const addNumberSchema = yup.object({
    areaCode: yup.string(),
    numberType: yup.string(),
    selectedNumberE164: yup.object().required()
})

export interface AddNumberFormProps {
    onClose: () => void
    onSubmit: (data: AddNumberFields) => void
}

export const AddNumberForm: FC<AddNumberFormProps> = ({ onClose, onSubmit }) => {
    const { t } = useTranslation()

    const methods = useYupForm<AddNumberFields>(addNumberSchema, {
        defaultValues: defaultValues
    })
    const {
        formState: { errors, isDirty, isSubmitting },
        setValue,
        watch
    } = methods
    const { areaCode, numberType, selectedNumberE164 } = watch()

    const { areaCodes } = useGetAreaCodesQuery(undefined, {
        selectFromResult: result => ({ ...result, areaCodes: selectAreaCodes(result) })
    })
    const { data: _numberTypes, numberTypes = [] } = useGetNumberTypesQuery(undefined, {
        selectFromResult: result => ({ ...result, numberTypes: selectNumberTypes(result) })
    })

    const selectedType = _numberTypes?.find(item => item.name === numberType)
    const selectedCode = selectedType?.pattern ?? areaCode

    function handleChange(name: string, value: any) {
        // reset values on type/area change
        if (name === 'areaCode' || name === 'numberType') setValue('selectedNumberE164', undefined)
        if (name === 'numberType') setValue('areaCode', '')

        setValue(name as any, value, { shouldDirty: true })
    }

    function handleSubmit(data: AddNumberFields) {
        return onSubmit({ ...data, numberType: selectedType?.numberType })
    }

    return (
        <FormProvider {...methods}>
            <VStack
                as="form"
                h="full"
                spacing="32px"
                align="stretch"
                justify="space-between"
                onSubmit={methods.handleSubmit(handleSubmit)}
                data-testid="add-number-form"
            >
                <VStack p="24px" pt="16px" spacing="24px" align="inherit">
                    <HStack spacing="16px">
                        <ValidatedInput
                            flex={0.5}
                            name="numberType"
                            label={t('admin.numbers.add.numberType')}
                            shouldRegister={false}
                        >
                            <Dropdown
                                value={numberType}
                                options={numberTypes}
                                onChange={value => handleChange('numberType', value)}
                            />
                        </ValidatedInput>
                        {selectedType?.areaCodeKvp && (
                            <ValidatedInput
                                flex={0.5}
                                name="areaCode"
                                label={t('admin.numbers.add.areaCode')}
                                shouldRegister={false}
                            >
                                <Combobox
                                    value={areaCode}
                                    options={areaCodes?.[selectedType.areaCodeKvp] ?? []}
                                    placeholder={t('admin.numbers.add.areaCode_placeholder')}
                                    onChange={value => handleChange('areaCode', value)}
                                />
                            </ValidatedInput>
                        )}
                    </HStack>
                    <Tabs size="md" h="full" isFitted>
                        <Divider />
                        <TabList>
                            <Tab>{t('admin.numbers.add.standard_tab')}</Tab>
                            <Tab>{t('admin.numbers.add.vip_tab')}</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel p="24px 0px">
                                <AddNumberList
                                    values={{
                                        areaCode: selectedCode,
                                        numberType: selectedType?.numberType,
                                        selectedNumberE164
                                    }}
                                    error={get(errors, 'selectedNumberE164')}
                                    onChange={handleChange.bind(this, 'selectedNumberE164')}
                                />
                            </TabPanel>
                            <TabPanel p="24px 0px">
                                <AddNumberList
                                    values={{
                                        areaCode: selectedCode,
                                        numberType: selectedType?.premiumType,
                                        selectedNumberE164
                                    }}
                                    error={get(errors, 'selectedNumberE164')}
                                    onChange={handleChange.bind(this, 'selectedNumberE164')}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </VStack>

                <HStack px="24px" pb="24px" spacing="16px" align="inherit">
                    <Button
                        w="full"
                        size="lg"
                        variant="secondary"
                        isDisabled={isSubmitting}
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        type="submit"
                        w="full"
                        size="lg"
                        variant="creationary"
                        isDisabled={!isDirty}
                        isLoading={isSubmitting}
                    >
                        {t('Done')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}
