import { VStack } from '@chakra-ui/react'
import { Body, Button } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useAuthenticatedUser } from 'shared/hooks/useAuthenticatedUser'
import { useGenerateNewPinMutation } from 'shared/store'

import { ConferenceCallingItem } from './ConferenceCallingItem'

export interface ConferenceCallingProps {}

export const ConferenceCalling: FC<ConferenceCallingProps> = () => {
    const { t } = useTranslation()

    const { data: user, isLoading: userIsLoading } = useAuthenticatedUser()
    const [generateNewPin, { isLoading: pinGenerationIsLoading }] = useGenerateNewPinMutation()

    const handleNewPIN = async () => {
        try {
            await generateNewPin({ ID: user?.ID }).unwrap()
        } catch (error) {
            console.error(`Couldn't generate new conference pin`)
        }
    }

    return (
        <SettingsPage
            title={t('conferenceCalling.title')}
            subtitle={t('conferenceCalling.subtitle')}
            actions={
                <Button
                    variant="creationary"
                    isLoading={pinGenerationIsLoading}
                    onClick={() => handleNewPIN()}
                >
                    {t('conferenceCalling.generateNewPin')}
                </Button>
            }
        >
            <VStack align="start">
                <Body>{t('conferenceCalling.helpTitle')}</Body>
                <Body size="sm">{t('conferenceCalling.help')}</Body>
            </VStack>
            <VStack spacing="16px" align="start">
                <ConferenceCallingItem
                    label={t('conferenceCalling.conferenceNumber')}
                    value={user?.conferenceNumber}
                    isLoading={userIsLoading}
                    isIcon={false}
                />
                <ConferenceCallingItem
                    label={t('conferenceCalling.altConferenceNumber')}
                    value={user?.altConferenceNumber}
                    isLoading={userIsLoading}
                    isIcon={false}
                />
                <ConferenceCallingItem
                    label={t('conferenceCalling.conferenceRoom')}
                    value={user?.conferenceRoom}
                    isLoading={userIsLoading}
                    isIcon={true}
                />
            </VStack>
        </SettingsPage>
    )
}
