import { Box, HStack, useColorMode } from '@chakra-ui/react'
import { Avatar, Body, ContactDetailTextOverflow, Dropdown, ListItem } from '@missionlabs/react'
import { TeamMember } from '@missionlabs/types'
import { animate, MotionValue, Reorder, useDragControls, useMotionValue } from 'framer-motion'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getTimeoutOptions } from 'shared/components/OutOfHours/timeoutOptions'

const inactiveShadow = '0px 0px 0px rgba(0,0,0,0.8)'

function useRaisedShadow(value: MotionValue<number>) {
    const boxShadow = useMotionValue(inactiveShadow)

    useEffect(() => {
        let isActive = false
        value.on('change', latest => {
            const wasActive = isActive
            if (latest !== 0) {
                isActive = true
                if (isActive !== wasActive) {
                    animate(boxShadow, '5px 5px 10px rgba(0,0,0,0.3)')
                }
            } else {
                isActive = false
                if (isActive !== wasActive) {
                    animate(boxShadow, inactiveShadow)
                }
            }
        })
    }, [value, boxShadow])

    return boxShadow
}

interface ReorderItemProps {
    item: TeamMember
    idx: number
    update: (idx: number, timeout: number) => any
}

export const ReorderItem = ({ item, idx, update }: ReorderItemProps) => {
    const { t } = useTranslation()
    const y = useMotionValue(0)
    const dragControls = useDragControls()
    const { colorMode } = useColorMode()
    const boxShadow = useRaisedShadow(y)
    const { userFullName } = item

    const handleRingForChange = (timeout: number) => {
        update(idx, timeout)
    }

    return (
        <Reorder.Item
            value={item}
            id={item.userID}
            style={{ boxShadow, y, width: '100%' }}
            dragListener={false}
            dragControls={dragControls}
            onPointerDown={e => dragControls.start(e)}
            as="ul"
        >
            <ListItem variant="interactive" sx={{ minHeight: '60px' }} w="full">
                <HStack display="flex" w="full">
                    {userFullName && <Avatar name={userFullName} size={'sm'} />}
                    <ContactDetailTextOverflow
                        label={userFullName ?? ''}
                        size={'sm'}
                        variant="bold"
                        color={`${colorMode}.tones.navy`}
                    />
                    <Box marginLeft="auto">
                        <Body size="xs">{t('advancedRouting.timeoutAfter')}</Body>
                        <Dropdown
                            options={getTimeoutOptions(t)}
                            value={item?.timeout ?? 10}
                            onChange={handleRingForChange}
                            variant="unstyled"
                            alignSelf="flex-end"
                        />
                    </Box>
                </HStack>
            </ListItem>
        </Reorder.Item>
    )
}
