import { Breadcrumb } from '@missionlabs/react'
import { AnalyticsActivities } from 'features/analytics/components/AnalyticsActivites/AnalyticsActivities'
import { AnalyticsPieChartTotals } from 'features/analytics/components/AnalyticsPieChartTotals/AnalyticsPieChartTotals'
import AnalyticsTabsView from 'features/analytics/components/AnalyticsTabView'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import {
    useGetSingleUserRecentCallsAnalyticsQuery,
    useGetSingleUserTotalsAnalyticsQuery
} from 'shared/store'

interface Props {}

const AnalyticsUserExpanded: React.FC<Props> = () => {
    const { userID } = useParams()

    const { dateScroller, group } = useAnalytics()

    const { t } = useTranslation()
    const navigate = useNavigate()

    const {
        state: { username }
    } = useLocation()

    const apiArgs = {
        from: dateScroller.start?.getTime().toString(),
        to: dateScroller.end?.getTime().toString(),
        timezone: dateScroller.timezone,
        group,
        userID
    }

    const {
        data: userTotals,
        isLoading: isUserTotalLoading,
        isFetching: isUserTotalFetching
    } = useGetSingleUserTotalsAnalyticsQuery(apiArgs)

    const {
        data: userActivity,
        isFetching: isUserActivitiesFetching,
        isLoading: isUserActivitiesLoading
        // isError
    } = useGetSingleUserRecentCallsAnalyticsQuery(
        {
            from: dateScroller.start?.getTime().toString(),
            to: dateScroller.end?.getTime().toString(),
            userID
        },
        { skip: !dateScroller.start || !dateScroller.end }
    )

    const isLoading =
        isUserTotalLoading ||
        isUserTotalFetching ||
        isUserActivitiesLoading ||
        isUserActivitiesFetching

    const isNoResults = !userTotals?.data && !userActivity?.data.length

    return (
        <SettingsPage
            isLoading={isLoading}
            title={
                <Breadcrumb
                    variant="title"
                    items={[
                        {
                            title: t('analytics.users'),
                            onClick: () => navigate('../users'),
                            isCurrentPage: false
                        },
                        { title: username ?? userID, isCurrentPage: true }
                    ]}
                >
                    Users
                </Breadcrumb>
            }
            subtitle={t('analytics.usersSubtitle')}
            contentProps={{ padding: 0, maxW: 'unset' }}
        >
            <AnalyticsTabsView noResults={isNoResults} isLoading={isLoading} header={null}>
                {userTotals && (
                    <AnalyticsPieChartTotals
                        durations={userTotals.durations}
                        totals={userTotals.total}
                    />
                )}
                <AnalyticsActivities activities={userActivity?.data ?? []} />
            </AnalyticsTabsView>
        </SettingsPage>
    )
}

export default AnalyticsUserExpanded
