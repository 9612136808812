import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const SMSOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SMSOutlineIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path d="M12 5H6V6H12V5Z" fill="currentColor" />
            <path d="M14 5H19V6H14V5Z" fill="currentColor" />
            <path d="M10 8H6V9H10V8Z" fill="currentColor" />
            <path d="M14 11V12H6V11H14Z" fill="currentColor" />
            <path d="M17 8H12V9H17V8Z" fill="currentColor" />
            <path d="M16 11H20V12H16V11Z" fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 8V4C3 2.89543 3.89543 2 5 2H21C22.1046 2 23 2.89543 23 4V14C23 15.1046 22.1046 16 21 16V20L17 16V16.8889C17 17.9935 16.1046 18.8889 15 18.8889H6.11111L3 22V18.8889C1.89543 18.8889 1 17.9935 1 16.8889V10C1 8.89543 1.89543 8 3 8ZM17.4142 15H5C4.44772 15 4 14.5523 4 14V4C4 3.44772 4.44772 3 5 3H21C21.5523 3 22 3.44772 22 4V14C22 14.5523 21.5523 15 21 15H20V17.5858L17.4142 15ZM16 16H5C3.89543 16 3 15.1046 3 14V9C2.44771 9 2 9.44772 2 10V16.8889C2 17.4412 2.44772 17.8889 3 17.8889H4V19.5858L5.6969 17.8889H15C15.5523 17.8889 16 17.4412 16 16.8889V16Z"
                fill="currentColor"
            />
        </g>
    )
})

export const SMSReceivedIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SMSReceivedIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.53998 24C6.4669 24 6.39383 23.9843 6.32533 23.9527C6.1459 23.8699 6.03109 23.6915 6.03109 23.4954V18.0638C3.00109 17.1374 0 13.6504 0 9.10882C0 4.1715 3.78885 0 8.27334 0H15.9275C20.3037 0 24 4.1597 24 9.08319C24 14.0072 20.3037 18.1664 15.9275 18.1664H13.7021L6.868 23.8808C6.77447 23.9596 6.65773 24 6.53998 24ZM8.1407 10.1046C8.1407 9.80357 7.89665 9.55953 7.59561 9.55953C7.29457 9.55953 7.05053 9.80357 7.05053 10.1046V13.5261C7.05053 13.8271 7.29457 14.0711 7.59561 14.0711H11.0171C11.3181 14.0711 11.5622 13.8271 11.5622 13.5261C11.5622 13.225 11.3181 12.981 11.0171 12.981H8.90254L14.8388 7.14746L16.3205 5.6535C16.5325 5.43975 16.5311 5.09463 16.3173 4.88264C16.1036 4.67066 15.7584 4.6721 15.5465 4.88585L14.0697 6.37486L8.1407 12.2012V10.1046Z"
                fill="currentColor"
            />
            <mask
                id="mask0_420_166"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.53998 24C6.4669 24 6.39383 23.9843 6.32533 23.9527C6.1459 23.8699 6.03109 23.6915 6.03109 23.4954V18.0638C3.00109 17.1374 0 13.6504 0 9.10882C0 4.1715 3.78885 0 8.27334 0H15.9275C20.3037 0 24 4.1597 24 9.08319C24 14.0072 20.3037 18.1664 15.9275 18.1664H13.7021L6.868 23.8808C6.77447 23.9596 6.65773 24 6.53998 24ZM8.1407 10.1046C8.1407 9.80357 7.89665 9.55953 7.59561 9.55953C7.29457 9.55953 7.05053 9.80357 7.05053 10.1046V13.5261C7.05053 13.8271 7.29457 14.0711 7.59561 14.0711H11.0171C11.3181 14.0711 11.5622 13.8271 11.5622 13.5261C11.5622 13.225 11.3181 12.981 11.0171 12.981H8.90254L14.8388 7.14746L16.3205 5.6535C16.5325 5.43975 16.5311 5.09463 16.3173 4.88264C16.1036 4.67066 15.7584 4.6721 15.5465 4.88585L14.0697 6.37486L8.1407 12.2012V10.1046Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_420_166)"></g>
        </g>
    )
})

export const SMSSentIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SMSSentIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.53998 24C6.4669 24 6.39383 23.9843 6.32533 23.9527C6.1459 23.8699 6.03109 23.6915 6.03109 23.4954V18.0638C3.00109 17.1374 0 13.6504 0 9.10882C0 4.1715 3.78885 0 8.27334 0H15.9275C20.3037 0 24 4.1597 24 9.08319C24 14.0072 20.3037 18.1664 15.9275 18.1664H13.7021L6.868 23.8808C6.77447 23.9596 6.65773 24 6.53998 24ZM15.472 8.52605C15.472 8.82709 15.716 9.07113 16.0171 9.07113C16.3181 9.07113 16.5622 8.82709 16.5622 8.52605V5.10461C16.5622 4.80357 16.3181 4.55953 16.0171 4.55953H12.5956C12.2946 4.55953 12.0505 4.80357 12.0505 5.10461C12.0505 5.40565 12.2946 5.64969 12.5956 5.64969H14.7691L9.5157 10.8121L8.03402 12.3061C7.82203 12.5198 7.82347 12.865 8.03722 13.0769C8.25097 13.2889 8.5961 13.2875 8.80808 13.0737L10.2848 11.5847L15.472 6.48739V8.52605Z"
                fill="currentColor"
            />
            <mask
                id="mask0_420_165"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.53998 24C6.4669 24 6.39383 23.9843 6.32533 23.9527C6.1459 23.8699 6.03109 23.6915 6.03109 23.4954V18.0638C3.00109 17.1374 0 13.6504 0 9.10882C0 4.1715 3.78885 0 8.27334 0H15.9275C20.3037 0 24 4.1597 24 9.08319C24 14.0072 20.3037 18.1664 15.9275 18.1664H13.7021L6.868 23.8808C6.77447 23.9596 6.65773 24 6.53998 24ZM15.472 8.52605C15.472 8.82709 15.716 9.07113 16.0171 9.07113C16.3181 9.07113 16.5622 8.82709 16.5622 8.52605V5.10461C16.5622 4.80357 16.3181 4.55953 16.0171 4.55953H12.5956C12.2946 4.55953 12.0505 4.80357 12.0505 5.10461C12.0505 5.40565 12.2946 5.64969 12.5956 5.64969H14.7691L9.5157 10.8121L8.03402 12.3061C7.82203 12.5198 7.82347 12.865 8.03722 13.0769C8.25097 13.2889 8.5961 13.2875 8.80808 13.0737L10.2848 11.5847L15.472 6.48739V8.52605Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_420_165)"></g>
        </g>
    )
})

export const SMSIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SMSIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M3 2C1.89543 2 1 2.89543 1 4V5.06575L12 10.3991L23 5.06575V4C23 2.89543 22.1046 2 21 2H3Z"
                fill="currentColor"
            />
            <path
                d="M23 6.2676L12 11.6009L1 6.2676V16C1 17.1046 1.89543 18 3 18H5V22L9 18H21C22.1046 18 23 17.1046 23 16V6.2676Z"
                fill="currentColor"
            />
        </g>
    )
})
