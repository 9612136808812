import { defineStyle, IconButton, IconButtonProps, useColorMode } from '@chakra-ui/react'
import { FC } from 'react'

export const NavButton: FC<IconButtonProps> = props => {
    const { colorMode } = useColorMode()

    const styles = defineStyle({
        boxSize: '64px',
        bg: 'auto',
        color: `${colorMode}.tones.periwinkle`,
        borderRadius: 0,
        _active: { bg: `${colorMode}.tones.midnightExpress`, color: 'white' },
        _focus: { bg: `${colorMode}.tones.midnightExpress` }
    })

    return <IconButton {...props} variant="unstyled" sx={styles} />
}
