import { useDisclosure } from '@chakra-ui/react'
import { Button, Table, useFormatToNumberE164 } from '@missionlabs/react'
import { AddCircleIcon } from '@missionlabs/react/circleloop'
import { BlockedNumber } from '@missionlabs/types'
import { createColumnHelper } from '@tanstack/react-table'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetDefaultBlockedNumberListQuery } from 'shared/store'

import { BlockedNumbersDrawer } from './BlockedNumbersDrawer'

export const BlockedNumbers: FC = () => {
    const { t } = useTranslation()
    const columnHelper = createColumnHelper<BlockedNumber>()
    const { data: blockedNumbers = [], isLoading } = useGetDefaultBlockedNumberListQuery()
    const { formatToLocalNumber } = useFormatToNumberE164()
    const [targetBlockedNumber, setTargetBlockedNumber] = useState<BlockedNumber | undefined>()

    const blockedNumbersDrawer = useDisclosure()

    const onCloseDrawer = useCallback(() => {
        blockedNumbersDrawer.onClose()
        setTargetBlockedNumber(undefined)
    }, [blockedNumbersDrawer])

    const onRowClicked = (data: BlockedNumber) => {
        setTargetBlockedNumber(data)
        blockedNumbersDrawer.onOpen()
    }

    const columns = [
        columnHelper.accessor('numberE164', {
            cell: ({ getValue }) => <span>{formatToLocalNumber(getValue())}</span>,
            header: t('Number')
        }),
        columnHelper.accessor('inbound', {
            cell: ({
                row: {
                    original: { inbound, outbound }
                }
            }) => {
                if (inbound && !outbound) return t('Inbound')
                if (!inbound && outbound) return t('Outbound')
                if (inbound && outbound) return t('Inbound and Outbound')
            },
            header: t('Call Direction')
        }),
        columnHelper.accessor('label', {
            cell: ({ getValue }) => getValue(),
            header: t('Label'),
            meta: { align: 'right' }
        })
    ]

    return (
        <div>
            <SettingsPage
                maxW="100%"
                title={t('blockedNumbers.heading')}
                subtitle={t('blockedNumbers.subheading')}
                isLoading={isLoading}
                isEmpty={!blockedNumbers.length}
                actions={
                    <Button
                        type="submit"
                        variant="creationary"
                        leftIcon={<AddCircleIcon />}
                        onClick={blockedNumbersDrawer.onOpen}
                    >
                        {t('blockedNumbers.blockNumber')}
                    </Button>
                }
            >
                <Table
                    data={blockedNumbers}
                    columns={columns}
                    onRowClicked={onRowClicked}
                    paginate
                />
            </SettingsPage>
            <BlockedNumbersDrawer
                isOpen={blockedNumbersDrawer.isOpen}
                onClose={onCloseDrawer}
                blockedNumber={targetBlockedNumber}
            />
        </div>
    )
}
