import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const EditIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EditIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M19.3756 2.31226C20.1959 3.17163 20.1959 4.53882 19.3756 5.39819L7.14906 17.6248C6.68031 18.0544 6.13343 18.406 5.54749 18.5623L0.820932 19.9685C0.586557 20.0076 0.352182 19.9685 0.195932 19.8123C0.039682 19.656 -0.038443 19.4216 0.039682 19.1873L1.44593 14.4607C1.60218 13.8748 1.95374 13.3279 2.38343 12.8591L14.61 0.632568C15.4694 -0.187744 16.8366 -0.187744 17.6959 0.632568L19.3756 2.31226ZM13.36 3.67944L16.3287 6.64819L18.4772 4.53882C18.8287 4.14819 18.8287 3.56226 18.4772 3.21069L16.7975 1.53101C16.4459 1.17944 15.86 1.17944 15.4694 1.53101L13.36 3.67944ZM12.4616 4.53882L3.28187 13.7576C2.96937 14.031 2.73499 14.4216 2.61781 14.8123L1.56312 18.4451L5.19593 17.3904C5.58656 17.2732 5.97718 17.0388 6.25062 16.7263L15.4303 7.54663L12.4616 4.53882Z"
            fill="currentColor"
        />
    )
})

export const EditIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EditIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M2.30117 12.625L14.1762 0.75C15.1527 -0.226562 16.7543 -0.226562 17.7309 0.75L19.2543 2.27344C19.3715 2.39062 19.4887 2.54688 19.5668 2.66406C20.2309 3.64062 20.1137 4.96875 19.2543 5.82812L7.3793 17.7031C7.34024 17.7422 7.26211 17.7812 7.22305 17.8594C6.83242 18.1719 6.40274 18.4062 5.93399 18.5625L1.20742 19.9297C0.894924 20.0469 0.543362 19.9688 0.308987 19.6953C0.0355494 19.4609 -0.0425756 19.1094 0.0355494 18.7969L1.4418 14.0703C1.59805 13.5234 1.91055 13.0156 2.30117 12.625ZM3.23867 14.6172L2.34024 17.6641L5.38711 16.7656C5.62149 16.6875 5.85586 16.5703 6.05117 16.375L14.9965 7.42969L12.5355 5.00781L3.6293 13.9531C3.59024 13.9531 3.59024 13.9922 3.55117 14.0312C3.39492 14.1875 3.3168 14.3828 3.23867 14.6172Z"
            fill="currentColor"
        />
    )
})

export const EditIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EditIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M14.1793 0.75C15.1559 -0.226562 16.7575 -0.226562 17.734 0.75L19.2575 2.27344C20.234 3.25 20.234 4.85156 19.2575 5.82812L17.3825 7.70312L12.3043 2.625L14.1793 0.75ZM16.484 8.60156L7.38247 17.7031C6.99185 18.0938 6.48404 18.4062 5.93716 18.5625L1.2106 19.9297C0.898098 20.0469 0.546536 19.9688 0.312161 19.6953C0.0387232 19.4609 -0.0394018 19.1094 0.0387232 18.7969L1.44497 14.0703C1.60122 13.5234 1.91372 13.0156 2.30435 12.625L11.4059 3.52344L16.484 8.60156Z"
            fill="currentColor"
        />
    )
})
