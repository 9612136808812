import { useDisclosure } from '@chakra-ui/react'
import { Avatar, Menu, MenuButton, MenuList } from '@missionlabs/react'
import { UserStatus } from '@missionlabs/types'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { FC, useState } from 'react'
import { useGetPresence } from 'shared/hooks/useGetPresence'
import { useGetUserData } from 'shared/hooks/useGetUserData'
import {
    useGetLogsConfigQuery,
    useUpdateUserCallRouteMutation,
    useUpdateUserMutation
} from 'shared/store'

import { GlobalNavHeader } from './GlobalNavHeader'
import { GlobalNavItems } from './GlobalNavItems'
import { PresenceMenu } from './PresenceMenu'

export interface GlobalNavMenuProps {}

export const GlobalNavMenu: FC<GlobalNavMenuProps> = () => {
    const navMenu = useDisclosure()

    const { user, callRoute } = useGetUserData()

    const { data: { loggingEnabled } = { loggingEnabled: true } } = useGetLogsConfigQuery(
        user ? { clientID: user.clientID, userID: user.ID } : skipToken
    )
    const [updateUser] = useUpdateUserMutation()
    const [updateUserCallRoute] = useUpdateUserCallRouteMutation()

    const { status, vanityStatus, isUserStatusEditable } = useGetPresence(user?.ID)

    const [statusActive, setStatusActive] = useState(false)

    const displayStatus = vanityStatus ?? status?.label ?? 'Unknown'
    const statusType = status?.status

    const onSaveWithholdNumber = ev => {
        const updatedCallRoute = {
            ID: user?.ID,
            withholdNumber: ev.target.checked
        }
        updateUser(updatedCallRoute)
    }

    const onToggleStatus = () => {
        if (!isUserStatusEditable) return
        setStatusActive(!statusActive)
    }

    const onSaveStatus = (newState: string) => {
        if (!user || !callRoute) return

        const dnd = newState === UserStatus.DND

        setStatusActive(!statusActive)

        updateUserCallRoute({
            ID: callRoute.ID,
            userID: user.ID,
            snoozeStatusName: newState.toLowerCase(),
            dnd,
            snoozeExpiry: {
                duration: Number(newState !== UserStatus.Active),
                timeFormat: 'days',
                statusName: newState.toLowerCase()
            }
        })
    }

    if (!user) return <Avatar size="sm" />

    return (
        <Menu
            placement="right-end"
            offset={[32, 12]}
            closeOnSelect={false}
            isOpen={navMenu.isOpen}
            onOpen={navMenu.onOpen}
            onClose={() => {
                setStatusActive(false)
                navMenu.onClose()
            }}
        >
            <MenuButton variant="blank" height="full">
                <Avatar
                    size="sm"
                    name={user?.fullName}
                    data-testid="navmenu-avatar"
                    src={user.photoURL ?? undefined}
                    status={status}
                />
            </MenuButton>
            <MenuList>
                <GlobalNavHeader
                    fullName={user?.fullName ?? ''}
                    onToggle={onToggleStatus}
                    status={displayStatus}
                    statusType={statusType as UserStatus}
                />
                {statusActive ? (
                    <PresenceMenu onSave={onSaveStatus} status={displayStatus} />
                ) : (
                    <GlobalNavItems
                        user={user}
                        loggingEnabled={loggingEnabled}
                        onClose={navMenu.onClose}
                        onSaveWithholdNumber={onSaveWithholdNumber}
                    />
                )}
            </MenuList>
        </Menu>
    )
}
