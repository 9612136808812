import { SelectOption } from '@missionlabs/react'
import { TFunction } from 'i18next'

export enum Availability {
    AVAILABLE = 'available',
    UNAVAILABLE = 'unavailable'
}

export enum TimeSpan {
    ALL_DAY = 'allDay',
    BETWEEN = 'between'
}

export function getAvailabilityOptions(t: TFunction): SelectOption[] {
    return [
        {
            value: Availability.AVAILABLE,
            label: t('calls.available')
        },
        {
            value: Availability.UNAVAILABLE,
            label: t('calls.unavailable')
        }
    ]
}

export function getTimeOptions(t: TFunction): SelectOption[] {
    return [
        {
            value: TimeSpan.ALL_DAY,
            label: t('calls.allDay')
        },
        {
            value: TimeSpan.BETWEEN,
            label: t('calls.between')
        }
    ]
}
