import { Flex } from '@chakra-ui/react'
import { AnalyticsDurations, AnalyticsTotals } from '@missionlabs/types'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnalyticsCard } from './AnalyticsCard'
import { AnalyticsPieChart } from './AnalyticsPieChart'

type AnalyticsCardStackProps = {
    totals: AnalyticsTotals
    durations: AnalyticsDurations
}

export const AnalyticsPieChartTotals: FC<AnalyticsCardStackProps> = ({ totals, durations }) => {
    const { t } = useTranslation()
    const { colours } = useAnalytics()

    const totalCalls = useMemo(() => {
        if (totals) {
            const { inbound, outbound, missed } = totals
            return inbound + outbound + missed
        }
        return 0
    }, [totals])

    return (
        <Flex my={6}>
            <Flex mx="auto" gap={12} flexWrap="wrap" data-testid={`analytics-card-stack`}>
                <AnalyticsCard
                    colour={colours.remainder}
                    label={t('analytics.totalCalls')}
                    total={totalCalls}
                    duration={durations.total}
                />

                <AnalyticsCard
                    colour={colours.inbound}
                    label={t('analytics.inbound')}
                    total={totals.inbound}
                    duration={durations.inbound}
                >
                    {!!totals.inbound && <AnalyticsPieChart data={totals} dataKey="inbound" />}
                </AnalyticsCard>

                <AnalyticsCard
                    colour={colours.outbound}
                    label={t('analytics.outbound')}
                    total={totals.outbound}
                    duration={durations.outbound}
                >
                    {!!totals.outbound && <AnalyticsPieChart data={totals} dataKey="outbound" />}
                </AnalyticsCard>

                <AnalyticsCard
                    colour={colours.missed}
                    label={t('analytics.missed')}
                    total={totals.missed}
                    duration={0}
                >
                    {!!totals.missed && <AnalyticsPieChart data={totals} dataKey="missed" />}
                </AnalyticsCard>
            </Flex>
        </Flex>
    )
}
