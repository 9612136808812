import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ArrowUpIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowUpIconLight',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M15.3242 7.79883C15.207 7.95508 15.0508 8.0332 14.8945 8.0332C14.7383 8.0332 14.582 7.95508 14.4649 7.83789L8.64454 2.36914V17.0957C8.64454 17.4473 8.33204 17.7598 8.01954 17.7598C7.70704 17.7598 7.39454 17.4473 7.39454 17.0957V2.36914L1.53516 7.83789C1.30079 8.07227 0.910164 8.07227 0.675789 7.79883C0.441414 7.52539 0.441414 7.13477 0.714851 6.90039L7.58985 0.416016C7.82423 0.181641 8.17579 0.181641 8.41016 0.416016L15.2852 6.90039C15.5586 7.13477 15.5586 7.52539 15.3242 7.79883Z"
            fill="currentColor"
        />
    )
})

export const ArrowUpIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowUpIconRegular',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M0.753916 7.41797L7.31642 0.542969C7.51173 0.347656 7.7461 0.269531 8.01954 0.269531C8.25392 0.269531 8.48829 0.347656 8.6836 0.542969L15.2461 7.41797C15.5977 7.80859 15.5977 8.39453 15.207 8.74609C14.8555 9.09766 14.2305 9.09766 13.8789 8.70703L8.95704 3.51172V16.793C8.95704 17.3398 8.52735 17.7305 8.01954 17.7305C7.55079 17.7305 7.08204 17.3398 7.08204 16.793V3.51172L2.1211 8.70703C1.76954 9.09766 1.14454 9.09766 0.792978 8.74609C0.402353 8.39453 0.402353 7.76953 0.753916 7.41797Z"
            fill="currentColor"
        />
    )
})

export const ArrowUpIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowUpIconSolid',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M15.1289 8.64355C14.8945 8.87793 14.582 8.99512 14.2695 8.99512C13.918 8.99512 13.6055 8.87793 13.3711 8.64355L9.26953 4.54199V16.4951C9.26953 17.1982 8.68359 17.7451 8.01953 17.7451C7.39453 17.7451 6.76953 17.1982 6.76953 16.4951V4.54199L2.62891 8.64355C2.16016 9.15137 1.33984 9.15137 0.871094 8.64355C0.363281 8.1748 0.363281 7.35449 0.871094 6.88574L7.12109 0.635742C7.58984 0.12793 8.41016 0.12793 8.87891 0.635742L15.1289 6.88574C15.6367 7.35449 15.6367 8.1748 15.1289 8.64355Z"
            fill="currentColor"
        />
    )
})

export const ArrowDownIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowDownIconLight',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M15.2852 11.1094L8.41016 17.5938C8.29298 17.7109 8.13673 17.75 8.01954 17.75C7.86329 17.75 7.70704 17.7109 7.58985 17.5938L0.714851 11.1094C0.441414 10.875 0.441414 10.4844 0.675789 10.2109C0.910164 9.9375 1.30079 9.9375 1.53516 10.1719L7.39454 15.6406V0.914062C7.39454 0.5625 7.66798 0.25 7.98048 0.25C8.29298 0.25 8.64454 0.5625 8.64454 0.914062V15.6406L14.4649 10.1719C14.6992 9.9375 15.0899 9.9375 15.3242 10.2109C15.5586 10.4844 15.5586 10.875 15.2852 11.1094Z"
            fill="currentColor"
        />
    )
})

export const ArrowDownIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowDownIconRegular',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M15.2461 10.582L8.6836 17.457C8.48829 17.6523 8.25392 17.7305 8.01954 17.7305C7.7461 17.7305 7.51173 17.6523 7.31642 17.457L0.753916 10.582C0.402353 10.1914 0.402353 9.60547 0.792978 9.25391C1.14454 8.90234 1.76954 8.90234 2.1211 9.29297L7.08204 14.4883V1.20703C7.08204 0.660156 7.47267 0.269531 7.98048 0.269531C8.44923 0.269531 8.95704 0.660156 8.95704 1.20703V14.4883L13.8789 9.29297C14.2305 8.90234 14.8555 8.90234 15.207 9.25391C15.5977 9.60547 15.5977 10.1914 15.2461 10.582Z"
            fill="currentColor"
        />
    )
})

export const ArrowDownIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowDownIconSolid',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M15.1289 11.1289L8.87891 17.3789C8.64453 17.6133 8.33203 17.7305 8.01953 17.7305C7.66797 17.7305 7.35547 17.6133 7.12109 17.3789L0.871094 11.1289C0.363281 10.6602 0.363281 9.83984 0.871094 9.37109C1.33984 8.86328 2.16016 8.86328 2.62891 9.37109L6.76953 13.4727V1.48047C6.76953 0.816406 7.31641 0.269531 7.98047 0.269531C8.60547 0.269531 9.26953 0.816406 9.26953 1.48047V13.4727L13.3711 9.37109C13.8398 8.86328 14.6602 8.86328 15.1289 9.37109C15.6367 9.83984 15.6367 10.6602 15.1289 11.1289Z"
            fill="currentColor"
        />
    )
})

export const ArrowLeftIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowLeftIconLight',
    viewBox: '0 0 18 16',
    path: (
        <path
            d="M17.7598 7.98807C17.7598 8.33964 17.4473 8.61308 17.0957 8.61308H2.36914L7.83789 14.4725C8.07227 14.7068 8.07227 15.0975 7.79883 15.3318C7.68164 15.449 7.52539 15.4881 7.36914 15.4881C7.17383 15.4881 7.01758 15.449 6.90039 15.2928L0.416016 8.41776C0.181641 8.18339 0.181641 7.83182 0.416016 7.59745L6.90039 0.72245C7.13477 0.449012 7.52539 0.449012 7.79883 0.683387C8.07227 0.917762 8.07227 1.30839 7.83789 1.54276L2.36914 7.36307H17.0957C17.4473 7.36307 17.7598 7.67557 17.7598 7.98807Z"
            fill="currentColor"
        />
    )
})

export const ArrowLeftIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowLeftIconRegular',
    viewBox: '0 0 18 16',
    path: (
        <path
            d="M17.7305 7.98066C17.7305 8.52754 17.3008 8.95722 16.793 8.95722H3.51172L8.70703 13.8791C9.09766 14.2307 9.09766 14.8557 8.74609 15.2072C8.39453 15.5978 7.80859 15.5978 7.41797 15.2463L0.542969 8.68379C0.347656 8.48847 0.269531 8.2541 0.269531 7.98066C0.269531 7.74629 0.347656 7.51191 0.542969 7.3166L7.41797 0.754099C7.80859 0.402537 8.39453 0.402537 8.74609 0.793162C9.09766 1.14472 9.09766 1.76972 8.70703 2.12129L3.51172 7.04316H16.793C17.3398 7.04316 17.7305 7.47285 17.7305 7.98066Z"
            fill="currentColor"
        />
    )
})

export const ArrowLeftIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowLeftIconSolid',
    viewBox: '0 0 18 16',
    path: (
        <path
            d="M17.7451 7.99512C17.7451 8.69824 17.1982 9.24512 16.5342 9.24512H4.54199L8.64355 13.3857C9.15137 13.8545 9.15137 14.6748 8.64355 15.1436C8.40918 15.3779 8.09668 15.4951 7.78418 15.4951C7.43262 15.4951 7.12012 15.3779 6.88574 15.1436L0.635742 8.89355C0.12793 8.4248 0.12793 7.60449 0.635742 7.13574L6.88574 0.885742C7.35449 0.37793 8.1748 0.37793 8.64355 0.885742C9.15137 1.35449 9.15137 2.1748 8.64355 2.64355L4.54199 6.74512H16.5342C17.1982 6.74512 17.7451 7.33105 17.7451 7.99512Z"
            fill="currentColor"
        />
    )
})

export const ArrowRightIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowRightIconLight',
    viewBox: '0 0 18 16',
    path: (
        <path
            d="M17.5645 8.41776L11.0801 15.2928C10.9629 15.449 10.7676 15.4881 10.6113 15.4881C10.4551 15.4881 10.2988 15.449 10.1816 15.3318C9.9082 15.0975 9.9082 14.7068 10.1426 14.4725L15.6113 8.61308H0.884766C0.533203 8.61308 0.259766 8.33964 0.259766 8.02714C0.259766 7.71464 0.533203 7.36307 0.884766 7.36307H15.6113L10.1426 1.54276C9.9082 1.30839 9.9082 0.917762 10.1816 0.683387C10.4551 0.449012 10.8457 0.449012 11.0801 0.72245L17.5645 7.59745C17.7988 7.83182 17.7988 8.18339 17.5645 8.41776Z"
            fill="currentColor"
        />
    )
})

export const ArrowRightIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowRightIconRegular',
    viewBox: '0 0 18 16',
    path: (
        <path
            d="M10.5625 0.754099L17.4375 7.3166C17.6328 7.51191 17.75 7.74629 17.75 8.01972C17.75 8.2541 17.6328 8.48847 17.4375 8.68379L10.5625 15.2463C10.2109 15.5978 9.58594 15.5978 9.23438 15.2072C8.88281 14.8557 8.88281 14.2307 9.27344 13.8791L14.4688 8.95722H1.1875C0.640625 8.95722 0.25 8.52754 0.25 8.01972C0.25 7.47285 0.640625 7.08222 1.1875 7.08222H14.4688L9.27344 2.12129C8.88281 1.76972 8.88281 1.14472 9.23438 0.793162C9.58594 0.402537 10.1719 0.402537 10.5625 0.754099Z"
            fill="currentColor"
        />
    )
})

export const ArrowRightIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowRightIconSolid',
    viewBox: '0 0 18 16',
    path: (
        <path
            d="M17.3643 8.89355L11.1143 15.1436C10.8799 15.3779 10.5674 15.4951 10.2549 15.4951C9.90332 15.4951 9.59082 15.3779 9.35645 15.1436C8.84863 14.6748 8.84863 13.8545 9.35645 13.3857L13.458 9.24512H1.50488C0.801758 9.24512 0.254883 8.69824 0.254883 7.99512C0.254883 7.33105 0.801758 6.74512 1.50488 6.74512H13.458L9.35645 2.64355C8.84863 2.1748 8.84863 1.35449 9.35645 0.885742C9.8252 0.37793 10.6455 0.37793 11.1143 0.885742L17.3643 7.13574C17.8721 7.60449 17.8721 8.4248 17.3643 8.89355Z"
            fill="currentColor"
        />
    )
})

export const ArrowLeftCorner: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ArrowLeftCorner',
    viewBox: '0 0 30 30',
    path: (
        <path
            d="M25 18.0926V22.7801C25 23.327 24.5703 23.7176 24.0625 23.7176C23.5156 23.7176 23.125 23.327 23.125 22.8192V18.1707C23.125 15.9442 21.2891 14.1473 19.0625 14.1473H8.4375L13.3984 18.4442C13.7891 18.7957 13.8281 19.3817 13.5156 19.7723C13.3203 19.8895 13.0469 19.9676 12.8125 19.9676C12.5781 19.9676 12.3438 19.9285 12.1875 19.7723L5.3125 13.8739C5.11719 13.6395 5 13.366 5 13.0926C5 12.8192 5.11719 12.5848 5.3125 12.3895L12.1875 6.49105C12.5391 6.17855 13.1641 6.21761 13.5156 6.60824C13.8281 6.99886 13.7891 7.5848 13.3984 7.93636L8.4375 12.1551H19.0625C22.3047 12.1551 25 14.8504 25 18.0926Z"
            fill="currentColor"
        />
    )
})
