import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const HashIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HashIconLight',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M17.125 4.63368H13.7656L14.5859 1.03993C14.6641 0.68837 14.4688 0.37587 14.1172 0.297745C13.7656 0.21962 13.4531 0.414932 13.375 0.727432L12.4766 4.63368H7.51562L8.33594 1.00087C8.41406 0.68837 8.21875 0.336807 7.86719 0.258682C7.51562 0.180557 7.20312 0.37587 7.125 0.68837L6.22656 4.63368H2.125C1.77344 4.63368 1.5 4.94618 1.5 5.25868C1.5 5.61024 1.77344 5.88368 2.125 5.88368H5.95312L4.50781 12.1337H0.875C0.523438 12.1337 0.25 12.4462 0.25 12.7587C0.25 13.1102 0.523438 13.3837 0.875 13.3837H4.19531L3.375 17.0165C3.29688 17.3681 3.49219 17.6806 3.84375 17.7587C3.88281 17.7587 3.92188 17.7587 4 17.7587C4.27344 17.7587 4.50781 17.6024 4.58594 17.2899L5.48438 13.3837H10.4453L9.625 17.0165C9.54688 17.3681 9.74219 17.6806 10.0938 17.7587C10.1328 17.7587 10.1719 17.7587 10.25 17.7587C10.5234 17.7587 10.7578 17.6024 10.8359 17.2899L11.7344 13.3837H15.875C16.1875 13.3837 16.5 13.1102 16.5 12.7587C16.5 12.4462 16.1875 12.1727 15.875 12.1727H12.0078L13.4531 5.92274H17.125C17.4375 5.92274 17.75 5.64931 17.75 5.29774C17.75 4.94618 17.4375 4.63368 17.125 4.63368ZM10.7578 12.1337H5.75781L7.20312 5.88368H12.2031L10.7578 12.1337Z"
            fill="currentColor"
        />
    )
})

export const HashIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HashIconRegular',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M16.8125 4.29903H13.8828L14.4297 1.36934C14.5078 0.861531 14.1953 0.353719 13.6875 0.275594C13.1797 0.197469 12.6719 0.509969 12.5938 1.01778L12.0078 4.29903H7.67188L8.17969 1.36934C8.25781 0.861531 7.94531 0.353719 7.4375 0.275594C6.92969 0.197469 6.42188 0.509969 6.34375 1.01778L5.75781 4.29903H2.4375C1.89062 4.29903 1.5 4.72872 1.5 5.27559C1.5 5.78341 1.89062 6.17403 2.4375 6.17403H5.40625L4.39062 11.799H1.1875C0.640625 11.799 0.25 12.2287 0.25 12.7365C0.25 13.2834 0.640625 13.674 1.1875 13.674H4.07812L3.53125 16.6428C3.45312 17.1506 3.76562 17.6584 4.27344 17.7365C4.35156 17.7365 4.39062 17.7365 4.46875 17.7365C4.89844 17.7365 5.28906 17.424 5.36719 16.9943L5.95312 13.674H10.2891L9.78125 16.6428C9.66406 17.1506 10.0156 17.6584 10.5234 17.7365C10.6016 17.7365 10.6406 17.7365 10.7188 17.7365C11.1484 17.7365 11.5391 17.424 11.6172 16.9943L12.2031 13.674H15.5625C16.0703 13.674 16.5 13.2834 16.5 12.7756C16.5 12.2678 16.0703 11.8381 15.5625 11.8381H12.5547L13.5703 6.21309L16.8125 6.17403C17.3203 6.17403 17.75 5.78341 17.75 5.27559C17.75 4.72872 17.3203 4.29903 16.8125 4.29903ZM10.6406 11.799H6.30469L7.32031 6.17403H11.6562L10.6406 11.799Z"
            fill="currentColor"
        />
    )
})

export const HashIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HashIconSolid',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M16.5 4.00896H14.1953L14.5859 1.70427C14.7031 1.00114 14.2344 0.376144 13.5703 0.258956C12.8672 0.141769 12.2422 0.610519 12.125 1.27458L11.6562 4.00896H7.94531L8.33594 1.70427C8.45312 1.00114 7.98438 0.376144 7.32031 0.258956C6.61719 0.141769 5.99219 0.610519 5.875 1.27458L5.40625 4.00896H2.75C2.04688 4.00896 1.5 4.55583 1.5 5.25896C1.5 5.96208 2.04688 6.50896 2.75 6.50896H5.01562L4.15625 11.509H1.5C0.796875 11.509 0.25 12.0558 0.25 12.759C0.25 13.423 0.796875 13.9699 1.5 13.9699H3.76562L3.375 16.2355C3.25781 16.9386 3.72656 17.5636 4.39062 17.6808C4.46875 17.759 4.54688 17.759 4.625 17.759C5.21094 17.759 5.71875 17.3293 5.83594 16.7433L6.30469 14.009H10.0156L9.625 16.3136C9.50781 17.0168 9.97656 17.6418 10.6406 17.759C10.7188 17.759 10.7969 17.759 10.875 17.759C11.4609 17.759 11.9688 17.3293 12.0859 16.7433L12.5547 14.009H15.25C15.9141 14.009 16.5 13.4621 16.5 12.798C16.5 12.134 15.9141 11.548 15.25 11.548H12.9453L13.8047 6.54802H16.5C17.1641 6.54802 17.75 6.00114 17.75 5.33708C17.75 4.59489 17.1641 4.00896 16.5 4.00896ZM10.4062 11.509H6.69531L7.55469 6.50896H11.2656L10.4062 11.509Z"
            fill="currentColor"
        />
    )
})
