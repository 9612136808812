import { DecoratorNode, EditorConfig, LexicalEditor, LexicalNode, NodeKey } from 'lexical'
import { ReactNode } from 'react'

import { ReplyTo } from '../components/ReplyTo'

export class ReplyNode extends DecoratorNode<any> {
    __ID: string
    __from: string
    __message: string

    static getType(): string {
        return 'reply'
    }

    static clone(node: ReplyNode): ReplyNode {
        return new ReplyNode(node.__from, node.__message, node.__key)
    }

    constructor(ID: string, from: string, message: string, key?: NodeKey) {
        super(key)
        this.__ID = ID
        this.__from = from
        this.__message = message
    }

    createDOM(_config: EditorConfig): HTMLElement {
        const div = document.createElement('div')
        div.style.display = 'contents'
        return div
    }

    isKeyboardSelectable(): boolean {
        return false
    }
    updateDOM(): false {
        return false
    }

    setURL(url: string): void {
        const writable = this.getWritable()
        writable.__url = url
    }

    decorate(_editor: LexicalEditor): ReactNode {
        return <ReplyTo message={this.__message} from={this.__from} />
    }
}

export function $createReplyNode(ID: string, from: string, message: string): ReplyNode {
    return new ReplyNode(ID, from, message)
}

export function $isReplyNode(node: LexicalNode | null): boolean {
    return node instanceof ReplyNode
}
