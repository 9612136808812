import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PDFIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PDFIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M6.91692 12.3643H7.86636C8.11781 12.3643 8.31073 12.3 8.44512 12.1714C8.57952 12.0381 8.64672 11.8429 8.64672 11.5857C8.64672 11.3476 8.58386 11.1571 8.45813 11.0143C8.33241 10.8667 8.13515 10.7929 7.86636 10.7929H6.91692V12.3643Z"
                fill="currentColor"
            />
            <path
                d="M11.1906 14.1786H12.0294C12.2722 14.1786 12.4695 14.1405 12.6212 14.0643C12.773 13.9881 12.8835 13.8667 12.9529 13.7C13.0266 13.5286 13.0656 13.3048 13.0699 13.0286C13.0743 12.8857 13.0764 12.7619 13.0764 12.6571C13.0808 12.5476 13.0808 12.4405 13.0764 12.3357C13.0764 12.2262 13.0743 12.1024 13.0699 11.9643C13.0612 11.5738 12.9724 11.2857 12.8033 11.1C12.6342 10.9143 12.3654 10.8214 11.9969 10.8214H11.1906V14.1786Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 2C3.89543 2 3 2.89543 3 4V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V8H18C16.3431 8 15 6.65685 15 5V2H5ZM6.15607 15C6.11272 15 6.07587 14.9833 6.04552 14.95C6.01517 14.9167 6 14.8762 6 14.8286V10.1786C6 10.1262 6.01517 10.0833 6.04552 10.05C6.07587 10.0167 6.11272 10 6.15607 10H7.89887C8.23703 10 8.53183 10.0595 8.78328 10.1786C9.03473 10.2929 9.22982 10.469 9.36855 10.7071C9.50728 10.9405 9.57664 11.2333 9.57664 11.5857C9.57664 11.9429 9.50728 12.2381 9.36855 12.4714C9.22982 12.7 9.03473 12.8714 8.78328 12.9857C8.53183 13.1 8.23703 13.1571 7.89887 13.1571H6.92993V14.8286C6.92993 14.8762 6.91475 14.9167 6.88441 14.95C6.85839 14.9833 6.82154 15 6.77386 15H6.15607ZM10.4427 15C10.3994 15 10.3625 14.9833 10.3322 14.95C10.3018 14.9167 10.2866 14.8762 10.2866 14.8286V10.1786C10.2866 10.1262 10.3018 10.0833 10.3322 10.05C10.3625 10.0167 10.3994 10 10.4427 10H12.0294C12.4673 10 12.8271 10.0738 13.1089 10.2214C13.3951 10.369 13.6097 10.5881 13.7527 10.8786C13.9001 11.169 13.976 11.5238 13.9803 11.9429C13.989 12.1524 13.9933 12.3381 13.9933 12.5C13.9933 12.6619 13.989 12.8452 13.9803 13.05C13.9717 13.4929 13.898 13.8595 13.7592 14.15C13.6205 14.4357 13.4102 14.65 13.1284 14.7929C12.851 14.931 12.4955 15 12.062 15H10.4427ZM14.9046 14.95C14.9349 14.9833 14.9718 15 15.0151 15H15.5874C15.6351 15 15.6741 14.9833 15.7044 14.95C15.7348 14.9167 15.75 14.8762 15.75 14.8286V13.0571H17.7074C17.7551 13.0571 17.7941 13.0405 17.8244 13.0071C17.8548 12.9738 17.8699 12.9333 17.8699 12.8857V12.3857C17.8699 12.3333 17.8548 12.2905 17.8244 12.2571C17.7941 12.2238 17.7551 12.2071 17.7074 12.2071H15.75V10.85H17.8374C17.8851 10.85 17.9241 10.8357 17.9545 10.8071C17.9848 10.7738 18 10.731 18 10.6786V10.1786C18 10.1262 17.9848 10.0833 17.9545 10.05C17.9241 10.0167 17.8851 10 17.8374 10H15.0151C14.9718 10 14.9349 10.0167 14.9046 10.05C14.8742 10.0833 14.8591 10.1262 14.8591 10.1786V14.8286C14.8591 14.8762 14.8742 14.9167 14.9046 14.95Z"
                fill="currentColor"
            />
            <path d="M16 2L21 7H18C16.8954 7 16 6.10457 16 5V2Z" fill="currentColor" />
        </g>
    )
})
