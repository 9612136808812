import {
    DeploymentCounties,
    DeploymentDataCenters,
    DeploymentEnvironments,
    DeploymentProducts
} from '@missionlabs/types'
import { getDeploymentEnvironment, populateUrl } from '@missionlabs/utils'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum BaseURL {
    ROOT = 'https://api-{country}-{product}-{datacentre}-{environment}.gammaapi.net',
    API = 'https://api-{country}-{product}-{datacentre}-{environment}.gammaapi.net/admin',
    LS = 'wss://api-{country}-{product}-{datacentre}-{environment}.gammaapi.net',
    LOGS = 'https://{environment}-logs.viazeta.com',
    TURN = 'turn.{country}-{product}-{datacentre}-{environment}.gammaapi.net',
    ZETAID = 'https://api-{product}-gb-{datacentre}-{environment}.gammaapi.net/zeta-id/zeta',
    LS_API = 'https://api-{country}-{product}-{datacentre}-{environment}.gammaapi.net/circleloop/',
    GUEST_APP = 'https://{environment}-guestapp.viazeta.com',
    INTEGRATIONS = 'https://{environment}-integrations.circleloop.com'
}

export interface AuthState {
    isAuthenticated: boolean
    apiBaseURL: string | undefined
    dataCentre: DeploymentDataCenters
    environment: DeploymentEnvironments
    country: DeploymentCounties
    product: DeploymentProducts
    loginTime: number
    firstTimeOptionsSet?: boolean
}

const initialState: AuthState = {
    isAuthenticated: false,
    apiBaseURL: undefined,
    dataCentre: DeploymentDataCenters.aws,
    environment: getDeploymentEnvironment() ?? DeploymentEnvironments.prod,
    country: DeploymentCounties.gb,
    product: DeploymentProducts.circleloop,
    loginTime: 0,
    // Assume that the first time options are set,
    // as they are only unset when client is first created
    // We have a useEffect that updates this when the client is fetched on login
    firstTimeOptionsSet: true
}

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        loginSuccess: state => {
            state.isAuthenticated = true
            state.loginTime = Date.now()
        },
        logoutSuccess: state => {
            state.isAuthenticated = false
            state.loginTime = 0
        },
        setAPIBaseURL: (state, action: PayloadAction<string>) => {
            state.apiBaseURL = action.payload
        },
        setDataCentre: (state, action: PayloadAction<string>) => {
            state.dataCentre = action.payload as DeploymentDataCenters
        },
        setEnvironment: (state, action: PayloadAction<string>) => {
            state.environment = action.payload as DeploymentEnvironments
        },
        setCountry: (state, action: PayloadAction<string>) => {
            state.country = action.payload as DeploymentCounties
        },
        setProduct: (state, action: PayloadAction<string>) => {
            state.product = action.payload as DeploymentProducts
        },
        setFirstTimeOptionsSet: (state, action: PayloadAction<boolean>) => {
            state.firstTimeOptionsSet = action.payload
        }
    }
})

export const {
    loginSuccess,
    logoutSuccess,
    setAPIBaseURL,
    setDataCentre,
    setEnvironment,
    setProduct,
    setCountry,
    setFirstTimeOptionsSet
} = authSlice.actions

export type AuthSliceRootState = {
    authSlice: ReturnType<typeof authSlice.getInitialState>
}
export const selectAuthState = (state: AuthSliceRootState) => state.authSlice
export const selectIsAuthenticated = (state: AuthSliceRootState) => state.authSlice.isAuthenticated
export const selectAPIBaseURL = (state: AuthSliceRootState) =>
    state.authSlice.apiBaseURL ?? BaseURL.API

/**
 * Returns the base URL for services based on the current deployment config (as opposed to
 * the apiBaseURL field in the state, which isn't set if we're not logged in yet)
 */
export const selectDefaultRootURL = (state: AuthSliceRootState) =>
    populateUrl(BaseURL.ROOT, state.authSlice)

export const selectAuthEnvironment = (state: AuthSliceRootState) => ({
    environment: state.authSlice.environment,
    dataCentre: state.authSlice.dataCentre,
    product: state.authSlice.product,
    country: state.authSlice.country
})
export const selectLoginTime = (state: AuthSliceRootState) => state.authSlice.loginTime
export const selectFirstTimeOptionsSet = (state: AuthSliceRootState) =>
    state.authSlice.firstTimeOptionsSet

export default authSlice.reducer
