import { ComponentStyleConfig } from '@chakra-ui/react'

export const ActivityStyles: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        container: {
            display: 'flex',
            backgroundColor: `${colorMode}.tones.ghostWhite`,
            borderRadius: '4px',
            position: 'relative',
            minH: '77px',
            transition: 'all 200ms ease'
        },
        name: { color: `${colorMode}.tones.midnightExpress` },
        text: { color: `${colorMode}.tones.navy` },
        directionIndicator: {
            content: '" "',
            position: 'absolute',
            width: 0,
            height: 0,
            top: '0px',
            bottom: 'auto',
            border: '32px solid',
            transition: 'all 200ms ease'
        },
        newIndicator: {
            w: '12px',
            h: '12px',
            position: 'absolute',
            right: '-6px',
            top: '-6px',
            backgroundColor: `${colorMode}.alerts.red`,
            borderRadius: '100%'
        }
    }),
    variants: {
        selected: ({ colorMode }) => ({
            container: { backgroundColor: `${colorMode}.tones.navy` },
            name: { color: `${colorMode}.tones.white` },
            text: { color: `${colorMode}.tones.periwinkle` }
        }),
        new: ({ colorMode }) => ({
            container: {
                backgroundColor: `${colorMode}.tones.white`,
                filter: 'drop-shadow(0px 2px 12px rgba(37, 62, 177, 0.08))'
            }
        })
    }
}
