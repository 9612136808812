import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const FullLogo: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'FullLogo',
    viewBox: '0 0 222 32',
    path: (
        <g id="PL / Logo" clipPath="url(#clip0_23338_223047)">
            <path
                id="Vector"
                d="M208.626 16.8526V7.92383H212.772V16.8526H221.715V20.9923H212.772V29.9211H208.626V20.9923H199.684V16.8526H208.626Z"
                fill="white"
                fillOpacity="0.6"
            />
            <path
                id="Vector_2"
                d="M194.674 27.8102C193.699 29.0548 192.466 30.0153 190.975 30.6917C189.486 31.3682 187.941 31.7063 186.342 31.7063C184.824 31.7063 183.388 31.4628 182.033 30.9758C180.706 30.4888 179.54 29.7988 178.537 28.906C177.562 27.986 176.789 26.8902 176.22 25.6185C175.651 24.3469 175.367 22.9264 175.367 21.3571C175.367 19.7878 175.651 18.3673 176.22 17.0956C176.789 15.824 177.562 14.7417 178.537 13.8488C179.54 12.9288 180.706 12.2254 182.033 11.7383C183.388 11.2513 184.824 11.0078 186.342 11.0078C187.751 11.0078 189.024 11.2513 190.163 11.7383C191.328 12.2254 192.304 12.9288 193.089 13.8488C193.902 14.7417 194.526 15.824 194.959 17.0956C195.393 18.3673 195.609 19.7878 195.609 21.3571V23.2646H181.464C181.708 24.4281 182.236 25.3615 183.049 26.065C183.862 26.7414 184.865 27.0796 186.057 27.0796C187.06 27.0796 187.9 26.8632 188.577 26.4302C189.282 25.9703 189.892 25.3885 190.406 24.6851L194.674 27.8102ZM189.512 19.1249C189.54 18.0967 189.201 17.2174 188.496 16.4868C187.792 15.7563 186.884 15.391 185.773 15.391C185.096 15.391 184.499 15.4993 183.984 15.7157C183.47 15.9321 183.023 16.2162 182.643 16.568C182.291 16.8927 182.006 17.285 181.789 17.745C181.6 18.1779 181.491 18.6379 181.464 19.1249H189.512Z"
                fill="white"
                fillOpacity="0.6"
            />
            <path
                id="Vector_3"
                d="M152.636 11.4948H158.489V14.1735H158.57C158.759 13.7947 159.031 13.4159 159.383 13.0371C159.735 12.6583 160.156 12.32 160.643 12.0224C161.131 11.7248 161.686 11.4813 162.31 11.2919C162.933 11.1025 163.611 11.0078 164.342 11.0078C165.887 11.0078 167.133 11.2513 168.082 11.7383C169.03 12.1983 169.762 12.8477 170.277 13.6864C170.819 14.5252 171.184 15.5128 171.374 16.6492C171.564 17.7856 171.659 19.0166 171.659 20.3424V31.2193H165.562V21.56C165.562 20.9918 165.534 20.4101 165.48 19.8148C165.453 19.1926 165.331 18.6244 165.115 18.1103C164.925 17.5961 164.613 17.1768 164.18 16.8521C163.773 16.5274 163.177 16.3651 162.391 16.3651C161.605 16.3651 160.969 16.5139 160.481 16.8115C159.993 17.0821 159.613 17.4609 159.343 17.9479C159.099 18.4079 158.936 18.9355 158.855 19.5307C158.774 20.126 158.733 20.7483 158.733 21.3977V31.2193H152.636V11.4948Z"
                fill="white"
                fillOpacity="0.6"
            />
            <path
                id="Vector_4"
                d="M142.158 11.4944H148.255V31.2189H142.158V11.4944ZM141.67 5.28483C141.67 4.31078 142.009 3.48555 142.687 2.80913C143.391 2.10565 144.231 1.75391 145.207 1.75391C146.182 1.75391 147.009 2.10565 147.686 2.80913C148.391 3.48555 148.743 4.31078 148.743 5.28483C148.743 6.25888 148.391 7.09765 147.686 7.80113C147.009 8.47757 146.182 8.81576 145.207 8.81576C144.231 8.81576 143.391 8.47757 142.687 7.80113C142.009 7.09765 141.67 6.25888 141.67 5.28483Z"
                fill="white"
                fillOpacity="0.6"
            />
            <path
                id="Vector_5"
                d="M121.26 2.48438H127.601V25.3745H139.349V31.2188H121.26V2.48438Z"
                fill="white"
                fillOpacity="0.6"
            />
            <path
                id="Vector_6"
                d="M115.602 27.8102C114.626 29.0548 113.393 30.0153 111.903 30.6917C110.412 31.3682 108.867 31.7063 107.269 31.7063C105.752 31.7063 104.315 31.4628 102.96 30.9758C101.632 30.4888 100.467 29.7988 99.4645 28.906C98.4889 27.986 97.7166 26.8902 97.1476 25.6185C96.5785 24.3469 96.2939 22.9264 96.2939 21.3571C96.2939 19.7878 96.5785 18.3673 97.1476 17.0956C97.7166 15.824 98.4889 14.7417 99.4645 13.8488C100.467 12.9288 101.632 12.2254 102.96 11.7383C104.315 11.2513 105.752 11.0078 107.269 11.0078C108.678 11.0078 109.952 11.2513 111.09 11.7383C112.255 12.2254 113.231 12.9288 114.016 13.8488C114.829 14.7417 115.452 15.824 115.886 17.0956C116.32 18.3673 116.537 19.7878 116.537 21.3571V23.2646H102.391C102.635 24.4281 103.163 25.3615 103.976 26.065C104.789 26.7414 105.792 27.0796 106.984 27.0796C107.987 27.0796 108.827 26.8632 109.505 26.4302C110.209 25.9703 110.819 25.3885 111.334 24.6851L115.602 27.8102ZM110.439 19.1249C110.467 18.0967 110.128 17.2174 109.423 16.4868C108.719 15.7563 107.811 15.391 106.7 15.391C106.022 15.391 105.426 15.4993 104.911 15.7157C104.396 15.9321 103.949 16.2162 103.57 16.568C103.217 16.8927 102.933 17.285 102.716 17.745C102.526 18.1779 102.418 18.6379 102.391 19.1249H110.439Z"
                fill="white"
            />
            <path
                id="Vector_7"
                d="M73.563 11.4948H79.4166V14.1735H79.4979C79.6871 13.7947 79.9583 13.4159 80.3109 13.0371C80.6626 12.6583 81.0832 12.32 81.5709 12.0224C82.0587 11.7248 82.6137 11.4813 83.2375 11.2919C83.8605 11.1025 84.5382 11.0078 85.2699 11.0078C86.8145 11.0078 88.0605 11.2513 89.0095 11.7383C89.9577 12.1983 90.6894 12.8477 91.2045 13.6864C91.7462 14.5252 92.112 15.5128 92.302 16.6492C92.4912 17.7856 92.5865 19.0166 92.5865 20.3424V31.2193H86.4893V21.56C86.4893 20.9918 86.462 20.4101 86.408 19.8148C86.3807 19.1926 86.2587 18.6244 86.0422 18.1103C85.8523 17.5961 85.5404 17.1768 85.1073 16.8521C84.7008 16.5274 84.1044 16.3651 83.3188 16.3651C82.5324 16.3651 81.8961 16.5139 81.4083 16.8115C80.9206 17.0821 80.5407 17.4609 80.2702 17.9479C80.0263 18.4079 79.8637 18.9355 79.7824 19.5307C79.7011 20.126 79.6605 20.7483 79.6605 21.3977V31.2193H73.563V11.4948Z"
                fill="white"
            />
            <path
                id="Vector_8"
                d="M48.022 21.3571C48.022 19.7878 48.3065 18.3673 48.8756 17.0956C49.4446 15.824 50.217 14.7417 51.1925 13.8488C52.1951 12.9288 53.3604 12.2254 54.6882 11.7383C56.0431 11.2513 57.4793 11.0078 58.9969 11.0078C60.5144 11.0078 61.9371 11.2513 63.2649 11.7383C64.6198 12.2254 65.7851 12.9288 66.7606 13.8488C67.7632 14.7417 68.5492 15.824 69.1182 17.0956C69.6873 18.3673 69.9718 19.7878 69.9718 21.3571C69.9718 22.9264 69.6873 24.3469 69.1182 25.6185C68.5492 26.8902 67.7632 27.986 66.7606 28.906C65.7851 29.7988 64.6198 30.4888 63.2649 30.9758C61.9371 31.4628 60.5144 31.7063 58.9969 31.7063C57.4793 31.7063 56.0431 31.4628 54.6882 30.9758C53.3604 30.4888 52.1951 29.7988 51.1925 28.906C50.217 27.986 49.4446 26.8902 48.8756 25.6185C48.3065 24.3469 48.022 22.9264 48.022 21.3571ZM54.1192 21.3571C54.1192 22.8452 54.5392 24.0492 55.3792 24.9692C56.2464 25.8891 57.4523 26.3491 58.9969 26.3491C60.5415 26.3491 61.7338 25.8891 62.5739 24.9692C63.441 24.0492 63.8747 22.8452 63.8747 21.3571C63.8747 19.8689 63.441 18.6649 62.5739 17.745C61.7338 16.825 60.5415 16.3651 58.9969 16.3651C57.4523 16.3651 56.2464 16.825 55.3792 17.745C54.5392 18.6649 54.1192 19.8689 54.1192 21.3571Z"
                fill="white"
            />
            <path
                id="Vector_9"
                d="M31.5098 0.537109V14.1738H31.5911C31.7265 13.795 31.9569 13.4162 32.2821 13.0374C32.6073 12.6586 33.0002 12.3204 33.4609 12.0228C33.9215 11.7252 34.4636 11.4817 35.0868 11.2922C35.71 11.1028 36.3875 11.0081 37.1192 11.0081C38.6638 11.0081 39.9104 11.2516 40.8588 11.7387C41.8072 12.1986 42.5389 12.848 43.0538 13.6868C43.5957 14.5256 43.9616 15.5131 44.1513 16.6495C44.341 17.7859 44.4358 19.017 44.4358 20.3428V31.2197H38.3386V21.5603C38.3386 20.9921 38.3115 20.4104 38.2573 19.8152C38.2302 19.1929 38.1083 18.6247 37.8915 18.1106C37.7018 17.5965 37.3902 17.1771 36.9566 16.8524C36.5501 16.5277 35.9539 16.3654 35.1681 16.3654C34.3823 16.3654 33.7454 16.5142 33.2576 16.8118C32.7699 17.0824 32.3905 17.4612 32.1195 17.9482C31.8756 18.4082 31.713 18.9358 31.6317 19.5311C31.5504 20.1263 31.5098 20.7486 31.5098 21.398V31.2197H25.4126V0.537109H31.5098Z"
                fill="white"
            />
            <path
                id="Vector_10"
                d="M0.581543 2.48438H11.2719C12.7624 2.48438 14.1715 2.61966 15.4993 2.89023C16.8272 3.16079 17.9788 3.63429 18.9544 4.31072C19.9571 4.96008 20.7429 5.85296 21.312 6.98935C21.9082 8.09866 22.2062 9.50564 22.2062 11.2102C22.2062 12.8877 21.9352 14.2947 21.3933 15.4311C20.8513 16.5675 20.1061 17.4739 19.1576 18.1503C18.2092 18.8268 17.0846 19.3138 15.7839 19.6114C14.4831 19.882 13.074 20.0173 11.5565 20.0173H6.92261V31.2188H0.581543V2.48438ZM6.92261 14.66H11.15C11.7191 14.66 12.261 14.6059 12.7759 14.4976C13.3179 14.3894 13.7921 14.2135 14.1986 13.97C14.6322 13.6994 14.9709 13.3478 15.2148 12.9148C15.4858 12.4549 15.6213 11.8867 15.6213 11.2102C15.6213 10.4797 15.4451 9.89799 15.0928 9.46506C14.7677 9.00512 14.334 8.65335 13.7921 8.40984C13.2772 8.16633 12.6946 8.01749 12.0442 7.9634C11.3939 7.88223 10.7706 7.84164 10.1744 7.84164H6.92261V14.66Z"
                fill="white"
            />
        </g>
    )
})
