import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const SmileEmojiPlusIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SmileEmojiPlusIconLight',
    viewBox: '0 0 20 20',
    path: [
        <path
            d="M10 0C11.6939 0 13.2894 0.421146 14.6875 1.16444V1.5625H14.0625C13.7162 1.5625 13.3918 1.65639 13.1134 1.82011C12.1461 1.45173 11.0966 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 8.90339 18.5483 7.85389 18.1799 6.8866C18.3436 6.60821 18.4375 6.28382 18.4375 5.9375V5.3125H18.8356C19.5789 6.71064 20 8.30614 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M8.125 7.8125C8.125 8.67544 7.56536 9.375 6.875 9.375C6.18464 9.375 5.625 8.67544 5.625 7.8125C5.625 6.94956 6.18464 6.25 6.875 6.25C7.56536 6.25 8.125 6.94956 8.125 7.8125Z"
            fill="currentColor"
            key="2"
        />,
        <path
            d="M6.85345 11.7123C6.76358 11.379 6.42055 11.1817 6.08728 11.2716C5.754 11.3614 5.55669 11.7045 5.64656 12.0377C5.89519 12.9597 6.50174 13.8445 7.26569 14.4983C8.02879 15.1513 9.01024 15.625 10.0421 15.625C11.0737 15.625 12.0564 15.1516 12.8117 14.4952C13.5668 13.8389 14.1586 12.9449 14.3609 12.0068C14.4337 11.6694 14.2192 11.3368 13.8818 11.2641C13.5444 11.1913 13.2118 11.4058 13.1391 11.7432C13.0044 12.3676 12.5849 13.0361 11.9917 13.5517C11.3987 14.0671 10.696 14.375 10.0421 14.375C9.38864 14.375 8.6847 14.0674 8.07842 13.5486C7.47298 13.0305 7.02616 12.3528 6.85345 11.7123Z"
            fill="currentColor"
            key="3"
        />,
        <path
            d="M14.375 7.8125C14.375 8.67544 13.8154 9.375 13.125 9.375C12.4346 9.375 11.875 8.67544 11.875 7.8125C11.875 6.94956 12.4346 6.25 13.125 6.25C13.8154 6.25 14.375 6.94956 14.375 7.8125Z"
            fill="currentColor"
            key="4"
        />,
        <path
            d="M17.1875 0.9375C17.1875 0.592322 16.9077 0.3125 16.5625 0.3125C16.2173 0.3125 15.9375 0.592322 15.9375 0.9375V2.8125H14.0625C13.7173 2.8125 13.4375 3.09232 13.4375 3.4375C13.4375 3.78268 13.7173 4.0625 14.0625 4.0625H15.9375V5.9375C15.9375 6.28268 16.2173 6.5625 16.5625 6.5625C16.9077 6.5625 17.1875 6.28268 17.1875 5.9375V4.0625H19.0625C19.4077 4.0625 19.6875 3.78268 19.6875 3.4375C19.6875 3.09232 19.4077 2.8125 19.0625 2.8125H17.1875V0.9375Z"
            fill="currentColor"
            key="5"
        />
    ]
})

export const SmileEmojiPlusIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SmileEmojiPlusIconRegular',
    viewBox: '0 0 20 20',
    path: [
        <path
            d="M10 0C11.5689 0 13.0535 0.361321 14.375 1.00529V1.25H14.0625C13.3162 1.25 12.6572 1.62369 12.2625 2.19416C11.5443 1.98637 10.7851 1.875 10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 9.21486 18.0136 8.45573 17.8058 7.73754C18.3763 7.34275 18.75 6.68377 18.75 5.9375V5.625H18.9947C19.6387 6.9465 20 8.43105 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M8.125 7.8125C8.125 8.67544 7.56536 9.375 6.875 9.375C6.18464 9.375 5.625 8.67544 5.625 7.8125C5.625 6.94956 6.18464 6.25 6.875 6.25C7.56536 6.25 8.125 6.94956 8.125 7.8125Z"
            fill="currentColor"
            key="2"
        />,
        <path
            d="M5.95054 10.9866C6.44119 10.8212 6.973 11.0849 7.13839 11.5755C7.26611 11.9544 7.63158 12.4298 8.20166 12.8201C8.76193 13.2037 9.4234 13.4375 10.0421 13.4375C10.6616 13.4375 11.3211 13.2032 11.8665 12.824C12.425 12.4357 12.7517 11.9762 12.8448 11.6309C12.9796 11.131 13.4942 10.835 13.9941 10.9698C14.494 11.1046 14.79 11.6191 14.6552 12.119C14.4112 13.0237 13.7267 13.8142 12.9369 14.3634C12.134 14.9217 11.108 15.3125 10.0421 15.3125C8.97548 15.3125 7.95155 14.9212 7.14245 14.3673C6.34314 13.8201 5.65524 13.0455 5.36161 12.1744C5.19623 11.6838 5.4599 11.1519 5.95054 10.9866Z"
            fill="currentColor"
            key="3"
        />,
        <path
            d="M14.375 7.8125C14.375 8.67544 13.8154 9.375 13.125 9.375C12.4346 9.375 11.875 8.67544 11.875 7.8125C11.875 6.94956 12.4346 6.25 13.125 6.25C13.8154 6.25 14.375 6.94956 14.375 7.8125Z"
            fill="currentColor"
            key="4"
        />,
        <path
            d="M17.5 0.9375C17.5 0.419733 17.0803 0 16.5625 0C16.0447 0 15.625 0.419733 15.625 0.9375V2.5H14.0625C13.5447 2.5 13.125 2.91973 13.125 3.4375C13.125 3.95527 13.5447 4.375 14.0625 4.375H15.625V5.9375C15.625 6.45527 16.0447 6.875 16.5625 6.875C17.0803 6.875 17.5 6.45527 17.5 5.9375V4.375H19.0625C19.5803 4.375 20 3.95527 20 3.4375C20 2.91973 19.5803 2.5 19.0625 2.5H17.5V0.9375Z"
            fill="currentColor"
            key="5"
        />
    ]
})

export const SmileEmojiPlusIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SmileEmojiPlusIconSolid',
    viewBox: '0 0 20 20',
    path: [
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 0C11.5689 0 13.0535 0.361321 14.375 1.00529V1.25H14.0625C12.8544 1.25 11.875 2.22938 11.875 3.4375C11.875 4.64562 12.8544 5.625 14.0625 5.625H14.375V5.9375C14.375 7.14562 15.3544 8.125 16.5625 8.125C17.7706 8.125 18.75 7.14562 18.75 5.9375V5.625H18.9947C19.6387 6.9465 20 8.43105 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM6.87501 9.375C7.56536 9.375 8.12501 8.67544 8.12501 7.8125C8.12501 6.94956 7.56536 6.25 6.87501 6.25C6.18465 6.25 5.62501 6.94956 5.62501 7.8125C5.62501 8.67544 6.18465 9.375 6.87501 9.375ZM7.13839 11.5755C6.973 11.0849 6.44119 10.8212 5.95054 10.9866C5.4599 11.1519 5.19623 11.6838 5.36161 12.1744C5.65524 13.0455 6.34314 13.8201 7.14245 14.3673C7.95155 14.9212 8.97548 15.3125 10.0421 15.3125C11.108 15.3125 12.134 14.9217 12.9369 14.3634C13.7267 13.8142 14.4112 13.0237 14.6552 12.119C14.79 11.6191 14.494 11.1046 13.9941 10.9698C13.4942 10.835 12.9796 11.131 12.8448 11.6309C12.7517 11.9762 12.425 12.4357 11.8665 12.824C11.3211 13.2032 10.6616 13.4375 10.0421 13.4375C9.4234 13.4375 8.76193 13.2037 8.20165 12.8201C7.63158 12.4298 7.26611 11.9544 7.13839 11.5755ZM14.375 7.8125C14.375 8.67544 13.8154 9.375 13.125 9.375C12.4347 9.375 11.875 8.67544 11.875 7.8125C11.875 6.94956 12.4347 6.25 13.125 6.25C13.8154 6.25 14.375 6.94956 14.375 7.8125Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M17.5 0.9375C17.5 0.419733 17.0803 0 16.5625 0C16.0447 0 15.625 0.419733 15.625 0.9375V2.5H14.0625C13.5447 2.5 13.125 2.91973 13.125 3.4375C13.125 3.95527 13.5447 4.375 14.0625 4.375H15.625V5.9375C15.625 6.45527 16.0447 6.875 16.5625 6.875C17.0803 6.875 17.5 6.45527 17.5 5.9375V4.375H19.0625C19.5803 4.375 20 3.95527 20 3.4375C20 2.91973 19.5803 2.5 19.0625 2.5H17.5V0.9375Z"
            fill="currentColor"
            key="2"
        />
    ]
})
