import { answer, Call, declineCall, hangup, useDispatch } from '@missionlabs/api'
import { FC, ReactNode } from 'react'

import { ActiveCall } from './ActiveCall/ActiveCall'
import { AfterCall } from './AfterCall/AfterCall'
import { CallWrapper } from './CallWrapper/CallWrapper'
import { IncomingCall } from './IncomingCall/IncomingCall'
import { OutgoingCall } from './OutgoingCall/OutgoingCall'

type VoiceCallViewProps = {
    call: Call
    dialpadElement: ReactNode
    afterHangUp: (call: Call) => void
}

export const VoiceCallView: FC<VoiceCallViewProps> = ({ call, dialpadElement, afterHangUp }) => {
    const dispatch = useDispatch()

    const { callTraceID, status } = call

    function doAnswerCall() {
        dispatch(answer({ callTraceID }))
    }

    function doDeclineCall() {
        dispatch(declineCall({ callTraceID }))
    }

    function doHangCall() {
        dispatch(hangup({ callTraceID }))
    }

    function doHangCallWithEffect() {
        doHangCall()
        afterHangUp(call)
    }

    function renderView() {
        switch (status) {
            case 'NO_CALL':
                return null
            case 'RINGING_REMOTE':
                return <OutgoingCall call={call} onHangup={doHangCallWithEffect} />
            case 'RINGING_LOCAL':
                return <IncomingCall call={call} onHangup={doDeclineCall} onAnswer={doAnswerCall} />
            case 'CONNECTED':
            case 'ON_HOLD':
            case 'TRANSFERRING':
                return (
                    <ActiveCall
                        call={call}
                        onHangup={doHangCallWithEffect}
                        dialpadElement={dialpadElement}
                    />
                )
            default:
                return null
        }
    }

    return status !== 'NO_CALL' ? (
        <CallWrapper showDiallerOptions={false}>{renderView()}</CallWrapper>
    ) : (
        <AfterCall call={call} />
    )
}
