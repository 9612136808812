import { FC } from 'react'
import { useGetUnreadThreadsQuery } from 'shared/store'

import { ChatHeaderUnreadThreads } from './ChatHeaderUnreadThreads'
import { ChatHistoryEmpty } from './ChatHistory/ChatHistoryEmpty'
import { ChatHistoryUnreadThreads } from './ChatHistory/ChatHistoryUnreadThreads'
export interface ChatViewUnreadThreadsProps {}

export const ChatViewUnreadThreads: FC<ChatViewUnreadThreadsProps> = () => {
    const { data: unreadThreads, isLoading } = useGetUnreadThreadsQuery()

    function renderer() {
        if (isLoading) return null
        if (!unreadThreads?.threads.length) return <ChatHistoryEmpty siblingHeight={80} />
        return <ChatHistoryUnreadThreads siblingHeight={80} data={unreadThreads} />
    }

    return (
        <div>
            <ChatHeaderUnreadThreads unreadThreadsCount={unreadThreads?.threads.length || 0} />
            {renderer()}
        </div>
    )
}
