import { useColorMode, VStack } from '@chakra-ui/react'
import { Scrollable } from '@missionlabs/react'
import { FC, useEffect, useMemo, useRef } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList } from 'react-window'
import { isActivitySection, ScrollDirection } from 'shared/types/feed'
import { groupBySection, sortActivities } from 'shared/utils/activities'

import { useActivitySidebarContext } from './ActivitySidebarContext'
import { ActivitySidebarEmptyState } from './ActivitySidebarEmptyState'
import { ActivitySidebarRenderer } from './ActivitySidebarRenderer'
import { ActivitySkeleton } from './ActivitySkeleton'

export interface ActivitySidebarProps {
    siblingHeight?: number
}

export const ActivitySidebar: FC<ActivitySidebarProps> = ({ siblingHeight = 0 }) => {
    const { colorMode } = useColorMode()
    const { all, isLoading, setItemCount, onItemsRendered, showFilters } =
        useActivitySidebarContext()

    const lastSize = useRef<number>(0)
    const ref = useRef<VariableSizeList>(null)

    const grouped = useMemo(() => {
        // First sort the activities by date
        const sorted = sortActivities(all, ScrollDirection.DOWN)
        // Then group them into sections, adding section objects between groups
        return groupBySection(sorted)
    }, [all])

    const rowHeights = useMemo(() => {
        return grouped.map(item => {
            if (isActivitySection(item)) return 56
            // TODO: Phase 2 - Handle the height of 3-line activities (number, via, sent by)
            return 93
        })
    }, [grouped])

    const getRowSize = (index: number) => rowHeights[index]

    useEffect(() => {
        setItemCount(grouped.length)
    }, [grouped.length, setItemCount])

    // We manually scroll to the first visible element before the next set of items have been loaded. This maintains the scroll position when new items are appended to the top of the list.
    useEffect(() => {
        // const diff = grouped.length - lastSize.current + 1

        if (ref.current) {
            // ref.current.scrollToItem(diff, 'start')
            // Recalculates height of each element as new items are added above existing ones. The stored sizes at each index will have changed when new items are appended.
            ref.current.resetAfterIndex(0)
        }

        lastSize.current = grouped.length
    }, [grouped.length])

    // todo
    if (isLoading) return <ActivitySkeleton siblingHeight={siblingHeight} />
    if (showFilters) return null

    if (grouped.length === 0) {
        return <ActivitySidebarEmptyState siblingHeight={siblingHeight} />
    }

    return (
        <VStack
            spacing="24px"
            w="full"
            h={`calc(100% - ${siblingHeight}px)`}
            align="start"
            bg={`${colorMode}.tones.whiteLilac`}
            id="activity-sidebar-content"
        >
            <AutoSizer>
                {({ height, width }) => (
                    <VariableSizeList
                        ref={ref}
                        height={height}
                        width={width}
                        itemSize={getRowSize}
                        itemCount={grouped.length}
                        itemData={grouped}
                        onItemsRendered={onItemsRendered}
                        outerElementType={Scrollable}
                    >
                        {ActivitySidebarRenderer}
                    </VariableSizeList>
                )}
            </AutoSizer>
        </VStack>
    )
}
