import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const StarIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'StarIconLight',
    viewBox: '0 0 20 19',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 0C10.2597 0 10.49 0.167337 10.5707 0.414746L12.6543 6.79752H19.3994C19.66 6.79752 19.8908 6.9659 19.9709 7.21439C20.051 7.46288 19.9621 7.7348 19.7508 7.88759L14.2975 11.8314L16.3799 18.2109C16.4608 18.4586 16.3734 18.7303 16.1635 18.8841C15.9535 19.0379 15.6687 19.0387 15.4578 18.8862L10 14.9392L4.54219 18.8862C4.33132 19.0387 4.0465 19.0379 3.83655 18.8841C3.6266 18.7303 3.53922 18.4586 3.62008 18.2109L5.70254 11.8314L0.249196 7.88759C0.0379188 7.7348 -0.0509705 7.46288 0.0291263 7.21439C0.109223 6.9659 0.340034 6.79752 0.600561 6.79752H7.34573L9.42926 0.414746C9.51002 0.167337 9.74029 0 10 0ZM10 2.53756L8.35184 7.58663C8.27108 7.83404 8.0408 8.00138 7.78109 8.00138H2.45924L6.7611 11.1124C6.97304 11.2657 7.06175 11.5388 6.98048 11.7877L5.33509 16.8283L9.64864 13.7088C9.85843 13.5571 10.1416 13.5571 10.3514 13.7088L14.6649 16.8283L13.0195 11.7877C12.9383 11.5388 13.027 11.2657 13.2389 11.1124L17.5408 8.00138H12.2189C11.9592 8.00138 11.7289 7.83404 11.6482 7.58663L10 2.53756Z"
            fill="currentColor"
        />
    )
})

export const StarIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'StarIconRegular',
    viewBox: '0 0 20 19',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 0C10.3576 0 10.6746 0.229212 10.7852 0.567812L12.7654 6.62931H19.1743C19.532 6.62931 19.8491 6.85875 19.9596 7.19759C20.0701 7.53642 19.9488 7.90755 19.6592 8.11679L14.4752 11.8628L16.4552 17.9236C16.5658 18.2622 16.4449 18.6333 16.1556 18.8428C15.8663 19.0522 15.4745 19.0524 15.185 18.8432L10 15.0965L4.81497 18.8432C4.52549 19.0524 4.13366 19.0522 3.84439 18.8428C3.55511 18.6333 3.43417 18.2622 3.54481 17.9236L5.52483 11.8628L0.340768 8.11679C0.0512006 7.90755 -0.0700718 7.53642 0.0404008 7.19759C0.150874 6.85875 0.467952 6.62931 0.82574 6.62931H7.23456L9.21481 0.567812C9.32543 0.229212 9.64239 0 10 0ZM10 3.47986L8.61944 7.70573C8.50882 8.04433 8.19186 8.27354 7.83425 8.27354H3.36922L6.98072 10.8832C7.27043 11.0926 7.39166 11.4639 7.28093 11.8029L5.90098 16.0269L9.51503 13.4154C9.80427 13.2064 10.1957 13.2064 10.485 13.4154L14.099 16.0269L12.7191 11.8029C12.6083 11.4639 12.7296 11.0926 13.0193 10.8832L16.6308 8.27354H12.1657C11.8081 8.27354 11.4912 8.04433 11.3806 7.70573L10 3.47986Z"
            fill="currentColor"
        />
    )
})

export const StarIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'StarIconSolid',
    viewBox: '0 0 20 19',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 0C10.3576 0 10.6746 0.229212 10.7852 0.567812L12.7654 6.62931H19.1743C19.532 6.62931 19.8491 6.85875 19.9596 7.19759C20.0701 7.53642 19.9488 7.90755 19.6592 8.11679L14.4752 11.8628L16.4552 17.9236C16.5658 18.2622 16.4449 18.6333 16.1556 18.8428C15.8663 19.0522 15.4745 19.0524 15.185 18.8432L10 15.0965L4.81497 18.8432C4.52549 19.0524 4.13366 19.0522 3.84439 18.8428C3.55511 18.6333 3.43417 18.2622 3.54481 17.9236L5.52483 11.8628L0.340768 8.11679C0.0512006 7.90755 -0.0700718 7.53642 0.0404008 7.19759C0.150874 6.85875 0.467952 6.62931 0.82574 6.62931H7.23456L9.21481 0.567812C9.32543 0.229212 9.64239 0 10 0Z"
            fill="currentColor"
        />
    )
})
