import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const BackspaceIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BackspaceIconLight',
    viewBox: '0 0 24 16',
    path: (
        <path
            d="M17.4297 5.96875L15.3594 8L17.4297 10.0703C17.6641 10.3047 17.6641 10.7344 17.4297 10.9688C17.1953 11.2031 16.7656 11.2031 16.5312 10.9688L14.4609 8.89844L12.4297 10.9688C12.1953 11.2031 11.7656 11.2031 11.5312 10.9688C11.2969 10.7344 11.2969 10.3047 11.5312 10.0703L13.6016 8L11.5312 5.96875C11.2969 5.73438 11.2969 5.30469 11.5312 5.07031C11.7656 4.83594 12.1953 4.83594 12.4297 5.07031L14.4609 7.14062L16.5312 5.07031C16.7656 4.83594 17.1953 4.83594 17.4297 5.07031C17.6641 5.30469 17.6641 5.73438 17.4297 5.96875ZM20.75 0.5C22.1172 0.5 23.25 1.63281 23.25 3V13C23.25 14.4062 22.1172 15.5 20.75 15.5H8.75781C8.09375 15.5 7.46875 15.2656 7 14.7969L1.10156 8.89844C0.867188 8.66406 0.75 8.35156 0.75 8C0.75 7.6875 0.867188 7.375 1.10156 7.14062L7 1.24219C7.46875 0.773438 8.09375 0.5 8.75781 0.5H20.75ZM22 3C22 2.33594 21.4141 1.75 20.75 1.75H8.75781C8.40625 1.75 8.09375 1.90625 7.85938 2.14062L1.96094 8L7.85938 13.8984C8.09375 14.1328 8.40625 14.25 8.75781 14.25H20.75C21.4141 14.25 22 13.7031 22 13V3Z"
            fill="currentColor"
        />
    )
})

export const BackspaceIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BackspaceIconRegular',
    viewBox: '0 0 24 16',
    path: (
        <path
            d="M17.625 6.16406L15.7891 8L17.625 9.83594C18.0156 10.2266 18.0156 10.8125 17.625 11.1641C17.2734 11.5547 16.6875 11.5547 16.3359 11.1641L14.5 9.32812L12.625 11.1641C12.2734 11.5547 11.6875 11.5547 11.3359 11.1641C10.9453 10.8125 10.9453 10.2266 11.3359 9.83594L13.1719 8L11.3359 6.16406C10.9453 5.8125 10.9453 5.22656 11.3359 4.83594C11.6875 4.48438 12.2734 4.48438 12.625 4.83594L14.5 6.71094L16.3359 4.83594C16.6875 4.48438 17.2734 4.48438 17.625 4.83594C18.0156 5.22656 18.0156 5.8125 17.625 6.16406ZM20.75 0.5C22.1172 0.5 23.25 1.63281 23.25 3V13C23.25 14.4062 22.1172 15.5 20.75 15.5H8.75781C8.09375 15.5 7.46875 15.2656 7 14.7969L1.10156 8.89844C0.867188 8.66406 0.75 8.35156 0.75 8C0.75 7.6875 0.867188 7.375 1.10156 7.14062L7 1.24219C7.46875 0.773438 8.09375 0.5 8.75781 0.5H20.75ZM21.375 3C21.375 2.6875 21.0625 2.375 20.75 2.375H8.75781C8.60156 2.375 8.40625 2.45312 8.28906 2.57031L2.85938 8L8.28906 13.4688C8.40625 13.5859 8.60156 13.625 8.75781 13.625H20.75C21.0625 13.625 21.375 13.3516 21.375 13V3Z"
            fill="currentColor"
        />
    )
})

export const BackspaceIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BackspaceIconSolid',
    viewBox: '0 0 24 16',
    path: (
        <path
            d="M23.25 13C23.25 14.4062 22.1172 15.5 20.75 15.5H8.75781C8.09375 15.5 7.46875 15.2656 7 14.7969L1.10156 8.89844C0.867188 8.66406 0.75 8.35156 0.75 8C0.75 7.6875 0.867188 7.375 1.10156 7.14062L7 1.24219C7.46875 0.773438 8.09375 0.5 8.75781 0.5H20.75C22.1172 0.5 23.25 1.63281 23.25 3V13ZM11.3359 6.16406L13.1719 8L11.3359 9.83594C10.9453 10.2266 10.9453 10.8125 11.3359 11.1641C11.6875 11.5547 12.2734 11.5547 12.625 11.1641L14.5 9.32812L16.3359 11.1641C16.6875 11.5547 17.2734 11.5547 17.625 11.1641C18.0156 10.8125 18.0156 10.2266 17.625 9.83594L15.7891 8L17.625 6.16406C18.0156 5.8125 18.0156 5.22656 17.625 4.83594C17.2734 4.48438 16.6875 4.48438 16.3359 4.83594L14.5 6.71094L12.625 4.83594C12.2734 4.48438 11.6875 4.48438 11.3359 4.83594C10.9453 5.22656 10.9453 5.8125 11.3359 6.16406Z"
            fill="currentColor"
        />
    )
})
