import { VStack } from '@chakra-ui/react'
import {
    Checkbox,
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    useFormatToNumberE164
} from '@missionlabs/react'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBlockContact } from 'shared/hooks/useBlockContact'

export interface BlockContactDialogProps {
    numberE164: string
    isOpen: boolean
    onClose: () => void
}

export const BlockContactDialog: FC<BlockContactDialogProps> = ({
    numberE164,
    isOpen,
    onClose
}) => {
    const { t } = useTranslation()
    const { block, unblock, isNumberBlocked, isLoading } = useBlockContact(numberE164)
    const { formatToLocalNumber } = useFormatToNumberE164()

    const ref = useRef<HTMLButtonElement>(null)

    const [inbound, setInbound] = useState<boolean>()
    const [outbound, setOutbound] = useState<boolean>()

    const isBlocked = isNumberBlocked.inbound || isNumberBlocked.outbound

    async function handleBlock() {
        if (!inbound && !outbound) {
            if (isBlocked) await unblock()
        } else {
            await block(outbound, inbound)
        }
        return onClose()
    }

    useEffect(() => {
        if (typeof isNumberBlocked.inbound === 'boolean') setInbound(isNumberBlocked.inbound)
        if (typeof isNumberBlocked.outbound === 'boolean') setOutbound(isNumberBlocked.outbound)
    }, [isNumberBlocked.inbound, isNumberBlocked.outbound])

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>
                    {t(`contacts.block.header.${isBlocked ? 'unblock' : 'block'}`, {
                        number: formatToLocalNumber(numberE164)
                    })}
                </DialogHeader>
                <DialogBody gap="4px">
                    {t(`contacts.block.body.${isBlocked ? 'unblock' : 'block'}`)}
                    <VStack spacing="8px" pl="16px" pt="8px" align="start">
                        <Checkbox isChecked={inbound} onChange={e => setInbound(e.target.checked)}>
                            {t('contacts.block.body.inbound')}
                        </Checkbox>
                        <Checkbox
                            isChecked={outbound}
                            onChange={e => setOutbound(e.target.checked)}
                        >
                            {t('contacts.block.body.outbound')}
                        </Checkbox>
                    </VStack>
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t(`contacts.block.confirm.${isBlocked ? 'unblock' : 'block'}`),
                        isDestructive: true,
                        onClick: handleBlock
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
