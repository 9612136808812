import './styles/style.css'

import {
    liveServicesConnect,
    logoutSuccess,
    selectAuthenticatedUser,
    selectIsAuthenticated,
    selectPreferences,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { useGetUserMedia } from '@missionlabs/react'
import isElectron from 'is-electron'
import { FC, useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useCallConnectionStatus } from 'shared/hooks/useCallConnectionStatus'
import { useElectronUpdateChannel } from 'shared/hooks/useElectronUpdateChannel'
import { useFlagsmithIdentify } from 'shared/hooks/useFlagsmithIdentify'
import { Features } from 'shared/hooks/useHasClientFeature'
import { useHasFeature } from 'shared/hooks/useHasFeature'
import { useNotificationManager } from 'shared/hooks/useNotificationManager'
import { usePresenceSubscriptions } from 'shared/hooks/usePresenceSubscriptions'
import { useSuperLogin } from 'shared/hooks/useSuperLogin'
import { useUserPreferencesListener } from 'shared/hooks/useUserPreferencesListener'
import { store } from 'shared/store'
import { useStoreListeners } from 'shared/useStoreListeners'

interface AppProps {}

export const Root: FC<AppProps> = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const { loginAsSuper } = useSuperLogin()

    const isAuthenticated = useSelector(selectIsAuthenticated)
    const user = useSelector(selectAuthenticatedUser)
    const preferences = useSelector(selectPreferences)

    const isVideoCallEnabled = useHasFeature(Features.meetings)
    const loginAsHash = searchParams.get('loginAs')

    const { getUserMedia, resetUserMediaReference } = useGetUserMedia(true, isVideoCallEnabled)
    const { addCall } = useStoreListeners()

    useFlagsmithIdentify()
    useCallConnectionStatus()
    useNotificationManager()
    useUserPreferencesListener()
    usePresenceSubscriptions()
    useElectronUpdateChannel(user)

    useEffect(() => {
        if (isAuthenticated && user) {
            getUserMedia(preferences)
        } else {
            resetUserMediaReference()
        }
    }, [getUserMedia, isAuthenticated, user, resetUserMediaReference, preferences])

    useEffect(() => {
        if (location.pathname === '/') {
            if (!user) navigate('/login')
            else navigate('/home')
        }
    }, [location.pathname, navigate, user])

    useEffect(() => {
        // listeners for store actions
        const addCallUnsubscribe = store.dispatch(addCall)

        dispatch(liveServicesConnect())

        return () => {
            addCallUnsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!loginAsHash) return
        //If there is currently a user then clear the user and logout
        if (user) {
            dispatch(logoutSuccess())
        } else {
            setSearchParams({})
            loginAsSuper(loginAsHash)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginAsHash, user?.userID])

    return (
        <div className={`kui-window ${isElectron() ? window.platform : ''}`}>
            <Outlet />
        </div>
    )
}
