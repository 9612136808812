import { ComponentStyleConfig } from '@chakra-ui/react'

export const ActivityIconStyles: ComponentStyleConfig = {
    baseStyle: () => ({
        boxSize: '16px'
    }),
    variants: {
        call_success: ({ colorMode }) => ({
            color: `${colorMode}.avatar.aqua`
        }),
        call_missed: ({ colorMode }) => ({
            color: `${colorMode}.alerts.red`
        }),
        voicemail: ({ colorMode }) => ({
            color: `${colorMode}.avatar.orange`
        }),
        whatsApp: ({ colorMode }) => ({
            color: `${colorMode}.avatar.green`
        }),
        chat: ({ colorMode }) => ({
            color: `${colorMode}.avatar.indigo`
        }),
        email: ({ colorMode }) => ({
            color: `${colorMode}.avatar.bloodOrange`
        })
    }
}
