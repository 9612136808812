import { motion, usePresence } from 'framer-motion'

import { ChatDataMessage } from './DataMessagesProvider/state'

export interface EmojiReactionProps {
    message: ChatDataMessage
    removeMessage: (message: ChatDataMessage) => void
    x: number
    lastY: string | number
    duration: number
}

const EmojiReaction: React.FC<EmojiReactionProps> = ({
    message: reactionMessage,
    removeMessage,
    x,
    lastY = '-700%',
    duration = 7
}) => {
    const [isPresent, safeToRemove] = usePresence()

    const animations = {
        // layout: true,
        initial: 'in',
        animate: isPresent ? 'in' : 'out',
        variants: {
            in: {
                y: ['10%', lastY],
                x: [x, x],
                opacity: 1,
                repeat: 0,
                transition: {
                    duration
                }
            },
            out: {
                opacity: 0
            }
        }
    }

    return (
        <motion.div
            key={`motion-li_emoji-${reactionMessage.id}`}
            aria-label="emoji-reaction"
            style={{ position: 'absolute' }}
            onAnimationComplete={() => {
                console.log(`removing message ${reactionMessage.id}`)
                removeMessage(reactionMessage)
                if (safeToRemove) {
                    safeToRemove()
                }
            }}
            {...animations}
        >
            <em-emoji shortcodes={reactionMessage.message.shortcodes} size={'2.5em'} />
        </motion.div>
    )
}

export default EmojiReaction
