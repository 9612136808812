import { MenuItem } from '@missionlabs/react'
import { ContactPhoneNumber } from '@missionlabs/types'
import { titleCase } from '@missionlabs/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useBlockContact } from 'shared/hooks/useBlockContact'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'

interface ContactNumberMenuItemProps {
    number: ContactPhoneNumber
    onClick: (value: string) => void
    variant?: 'dark'
}

export const ContactNumberMenuItem: FC<ContactNumberMenuItemProps> = ({
    number,
    onClick,
    variant
}) => {
    const { label, numberE164 } = number

    const { t } = useTranslation()
    const { isNumberBlocked } = useBlockContact(numberE164)
    const { formatToLocalNumber } = useFormatToNumberE164()

    return (
        <MenuItem
            variant={variant}
            onClick={() => {
                if (isNumberBlocked.outbound) return
                onClick(numberE164)
            }}
            isDisabled={isNumberBlocked.outbound}
        >
            {titleCase(label || t('Other'))} - {formatToLocalNumber(numberE164)}
        </MenuItem>
    )
}
