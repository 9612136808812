import {
    Input as ChakraInput,
    InputGroup,
    InputLeftElement,
    InputProps as ChakraInputProps,
    InputRightElement,
    SystemStyleObject,
    useMultiStyleConfig
} from '@chakra-ui/react'
import { IconButton } from 'atoms/IconButton/IconButton'
import { EyeIconRegular, EyeOffIconRegular } from 'atoms/Icons/zeta'
import { forwardRef, ReactNode, useState } from 'react'

export interface InputProps extends ChakraInputProps {
    leftIcon?: ReactNode
    rightIcon?: ReactNode
    rightSx?: SystemStyleObject
    variant?: 'light' | 'dark'
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        { type, variant = 'light', leftIcon, rightIcon, rightSx, isInvalid, isDisabled, ...props },
        ref
    ) => {
        const styles = useMultiStyleConfig('CustomInput', { variant })

        const [showPassword, setShowPassword] = useState<boolean>(false)

        return (
            <InputGroup
                width={props.w || props.width}
                maxW={props.maxW || props.maxWidth}
                minW={props.minW || props.minWidth}
            >
                {leftIcon && (
                    <InputLeftElement aria-disabled={isDisabled} sx={{ ...styles.icon }}>
                        {leftIcon}
                    </InputLeftElement>
                )}
                <ChakraInput
                    {...props}
                    ref={ref}
                    type={showPassword ? 'text' : type}
                    variant={variant}
                    sx={{
                        ...styles.input,
                        ...(props.sx ?? {}),
                        pl: leftIcon ? '40px' : undefined,
                        pr: rightIcon ? '40px' : undefined
                    }}
                    isDisabled={isDisabled}
                    isInvalid={isInvalid}
                />
                {type === 'password' && !rightIcon && (
                    <InputRightElement
                        as={IconButton}
                        isDisabled={isDisabled}
                        variant="transparent"
                        onClick={() => setShowPassword(!showPassword)}
                        title={showPassword ? 'Hide password' : 'Show password'}
                    >
                        {showPassword ? <EyeOffIconRegular /> : <EyeIconRegular />}
                    </InputRightElement>
                )}
                {type !== 'password' && rightIcon && (
                    <InputRightElement
                        aria-disabled={isDisabled}
                        sx={{ ...styles.icon, ...(rightSx ?? {}) }}
                    >
                        {rightIcon}
                    </InputRightElement>
                )}
            </InputGroup>
        )
    }
)
