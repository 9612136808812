import { HStack, Link, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Button, useToast } from '@missionlabs/react'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthenticatedUser } from 'shared/hooks/useAuthenticatedUser'
import { useImportContactsMutation, useLazyGetContactImportLogQuery } from 'shared/store'

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export function ContactsImport() {
    const { t } = useTranslation()
    const { toast } = useToast()
    const { colorMode } = useColorMode()
    const { data: user } = useAuthenticatedUser()

    const fileInputRef = useRef<HTMLInputElement>(null)

    const [loading, setLoading] = useState(false)
    const [csvData, setCsvData] = useState<string>('')

    const [postContactsImport] = useImportContactsMutation()
    const [getContactImportLog] = useLazyGetContactImportLogQuery()

    const exampleCSVHref = new URL('../../../../assets/import/import-contacts.csv', import.meta.url)
        .href

    const onClickImportContacts = () => {
        if (!fileInputRef.current) return
        setLoading(true)
        fileInputRef.current.click()
        setLoading(false)
    }

    const handleFileChange = e => {
        if (!e.target?.files?.length) return
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setCsvData(reader.result as string)
        }
    }

    const onImportContacts = useCallback(
        async (csvData: string) => {
            if (!user?.ID) return
            setLoading(true)
            try {
                const contactImport = await postContactsImport({
                    userID: user.ID,
                    body: csvData
                }).unwrap()

                /**
                 * Poll the import status until it's ready or errored
                 * 30 attempts with 10% exponential increment from initial 1s duration ~= 3 minutes total wait
                 */
                for (let attempt = 0; attempt < 30; attempt++) {
                    await wait(1000 * attempt * 1.1)
                    const result = await getContactImportLog(contactImport.ID).unwrap()
                    if (result.status === 'FAILED') throw new Error('Import log set to failed')
                    if (result.status === 'COMPLETED') {
                        toast({ status: 'success', title: 'Import complete' })
                        break
                    }
                }
            } catch (e) {
                console.error('Failed to import contacts', e)
                toast({ status: 'error', title: 'Failed to import contacts' })
            } finally {
                setLoading(false)
            }
        },
        [getContactImportLog, postContactsImport, toast, user?.ID]
    )

    useEffect(() => {
        if (!csvData) return
        onImportContacts(csvData)
    }, [csvData, onImportContacts])

    return (
        <SettingsRow label={t('globalSettings.importContacts.title')} divider={false}>
            <VStack align="flex-start">
                <HStack>
                    <Body size="sm" variant="bold" color={`${colorMode}.tones.navy`}>
                        {t('globalSettings.importContacts.description')}
                    </Body>
                    <Link href={exampleCSVHref} download={true}>
                        <Body
                            size="sm"
                            variant="link-bold"
                            sx={{ color: `${colorMode}.primary.gamma` }}
                        >
                            {t('globalSettings.importContacts.downloadTemplate')}
                        </Body>
                    </Link>
                </HStack>
                <input
                    onChange={handleFileChange}
                    type="file"
                    id="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept="csv/*"
                />
                <Button
                    w="150px"
                    variant="creationary"
                    onClick={onClickImportContacts}
                    isLoading={loading}
                >
                    {t('globalSettings.importContacts.title')}
                </Button>
            </VStack>
        </SettingsRow>
    )
}
