import { Box } from '@chakra-ui/react'
import { CSSProperties, FC } from 'react'

import { isSection, VirtualisedChatListItem } from './ChatList'
import { ChatListRow } from './ChatListRow'
import { ChatListSection } from './ChatListSection'
import { ChatListThreadsRow } from './ChatListThreadsRow'

export interface ChatListRendererProps {
    data: VirtualisedChatListItem[]
    index: number
    style: CSSProperties
}

export const ChatListRenderer: FC<ChatListRendererProps> = ({ data, index, style }) => {
    const item = data[index]

    if (isSection(item)) {
        return (
            <Box style={style}>
                <ChatListSection item={item} />
            </Box>
        )
    }

    if (item.ID === 'unread-threads') {
        return (
            <Box style={style}>
                <ChatListThreadsRow item={item} />
            </Box>
        )
    }

    return (
        <div style={style}>
            <ChatListRow item={item} />
        </div>
    )
}
