import { Box, useColorMode } from '@chakra-ui/react'
import { Spinner, Table, Toggle } from '@missionlabs/react'
import {
    AppleBrandIcon,
    DesktopIcon,
    PhoneUpIcon,
    RedirectIcon
} from '@missionlabs/react/circleloop'
import { Destination, DestinationTypes } from '@missionlabs/types'
import { createColumnHelper } from '@tanstack/react-table'
import { differenceInHours, format, formatDistance } from 'date-fns'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { usePutDestinationForUserMutation } from 'shared/store'

function formatDate(value?: number, showDistance?: boolean): string {
    if (!value) return ''
    const date = new Date(value * 1000)
    const now = new Date()

    if (showDistance && differenceInHours(now, date) <= 1) {
        return formatDistance(date, new Date(), { addSuffix: true })
    }
    return format(date, 'dd/MM/yyyy, HH:mm')
}

const columnHelper = createColumnHelper<Destination>()

export interface DevicesTableProps {
    data: Destination[]
    isLoading: boolean
}

export const DevicesTable: FC<DevicesTableProps> = ({ data, isLoading }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { formatToLocalNumber } = useFormatToNumberE164()

    const [updateDestination] = usePutDestinationForUserMutation()

    async function handleUpdate(update: Partial<Destination>) {
        await updateDestination(update).unwrap()
    }

    function renderIcon(type: Destination['type'], metadata: Destination['metadata']) {
        const props = { color: `${colorMode}.coolGrey.80`, boxSize: '20px' }
        if (type === DestinationTypes.number) return <RedirectIcon {...props} />
        if (type === DestinationTypes.sipEndpoint) return <PhoneUpIcon {...props} />
        if (type === DestinationTypes.desktopApp && metadata) {
            if ('os' in metadata && metadata.os === 'mac') return <AppleBrandIcon {...props} />
        }
        return <DesktopIcon {...props} />
    }

    const columns = [
        columnHelper.display({
            cell: ({ row }) => renderIcon(row.original.type, row.original.metadata),
            id: 'icon',
            meta: { padding: '12px !important' },
            size: 0
        }),
        columnHelper.display({
            cell: ({ row }) => {
                const { name, label, type, numberE164 } = row.original
                if (label) return label
                if (name) return name
                if (type === DestinationTypes.number) return formatToLocalNumber(numberE164)
                return t(`destinations.${type}`)
            },
            header: t('Type')
        }),
        columnHelper.accessor('lastAppActivity', {
            cell: info => formatDate(info.getValue(), true),
            header: t('settings.lastActivity')
        }),
        columnHelper.accessor('nonRinging', {
            cell: ({ row }) => (
                <Toggle
                    isChecked={!row.original.nonRinging}
                    onChange={e => handleUpdate({ ...row.original, nonRinging: !e.target.checked })}
                />
            ),
            header: t('settings.receiveCalls'),
            meta: { align: 'right', clickable: false },
            size: 0
        })
    ]

    const onRowClicked = (destination: Destination) => {
        navigate(`/settings/devices/${destination.ID}/edit`)
    }

    if (isLoading) return <Spinner size="sm" />

    return (
        <Box w="100%">
            <Table
                data={data}
                columns={columns}
                paginate
                sorting={[{ id: 'lastAppActivity', desc: true }]}
                onRowClicked={onRowClicked}
            />
        </Box>
    )
}
