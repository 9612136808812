import { Box } from '@chakra-ui/react'
import { Table, Toggle } from '@missionlabs/react'
import { User } from '@missionlabs/types'
import { createColumnHelper } from '@tanstack/react-table'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetClientQuery, useUpdateClientMutation } from 'shared/store'

interface RecordingTableProps {
    users: User[]
}

export const RecordingTable = ({ users }: RecordingTableProps) => {
    const { t } = useTranslation()
    const { data: client } = useGetClientQuery()
    const [updateClient] = useUpdateClientMutation()

    const adminCallRecordingExclusion = useMemo(
        () => client?.adminCallRecordingExclusion ?? [],
        [client?.adminCallRecordingExclusion]
    )

    const columnHelper = createColumnHelper<Partial<User>>()

    const handleUpdate = useCallback(
        async (userID: string) => {
            const isExcluded = adminCallRecordingExclusion.includes(userID)

            const payload = isExcluded
                ? adminCallRecordingExclusion.filter(excludedID => userID !== excludedID)
                : [...adminCallRecordingExclusion, userID]

            try {
                await updateClient({ adminCallRecordingExclusion: payload }).unwrap()
            } catch (error) {
                console.error(error)
            }
        },
        [adminCallRecordingExclusion, updateClient]
    )

    const columns = useMemo(
        () => [
            columnHelper.accessor('fullName', {
                cell: info => info.getValue(),
                header: t('admin.users.name')
            }),
            columnHelper.accessor('type', {
                cell: info => t(`admin.users.role.${info.getValue()}`),
                header: t('admin.users.role.title')
            }),
            columnHelper.accessor('callRecording', {
                cell: ({ row }) => (
                    <Toggle
                        isChecked={!adminCallRecordingExclusion.includes(row.original.ID ?? '')}
                        onChange={() => handleUpdate(row.original.ID ?? '')}
                        ml="auto"
                    />
                ),
                header: t('admin.callRecording.record'),
                meta: { clickable: false }
            })
        ],
        [columnHelper, handleUpdate, t, adminCallRecordingExclusion]
    )

    return (
        <Box w="100%">
            <Table
                data={users}
                columns={columns}
                paginate
                sorting={[{ id: 'fullName', desc: false }]}
            />
        </Box>
    )
}
