import { Box, HStack } from '@chakra-ui/react'
import { Body, IconButton, SearchInput } from '@missionlabs/react'
import { ArrowDownIcon, ArrowUpIcon, CloseIcon } from '@missionlabs/react/circleloop'
import { ChatMessage } from '@missionlabs/types'
import { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useChatContext } from '../context/useChatContext'

interface Props {
    searchMessageResults?: ChatMessage[]
    goToMessage(messageID: string): void
    onClose?(): void
}

const ChatHistorySearch = forwardRef<HTMLDivElement, Props>(
    ({ searchMessageResults = [], goToMessage, onClose }, ref) => {
        const { searchTerm, setSearchTerm, setSearchOpen } = useChatContext().search

        const { t } = useTranslation()

        const [selected, setSelected] = useState(-1)

        useEffect(() => {
            setSelected(-1)
        }, [searchMessageResults])

        const scrollNext = () => {
            goToMessage(searchMessageResults[selected + 1].ID)
            setSelected(selected => selected + 1)
        }

        const scrollPrev = () => {
            goToMessage(searchMessageResults[selected - 1].ID)
            setSelected(selected => selected - 1)
        }

        return (
            <Box ref={ref}>
                <SearchInput
                    bg="white"
                    placeholder={t('Search')}
                    value={searchTerm}
                    rightElement={
                        <HStack>
                            <Body whiteSpace="nowrap">{`${selected + 1} of ${
                                searchMessageResults.length
                            } results`}</Body>
                            <HStack gap={0}>
                                <IconButton
                                    onClick={scrollPrev}
                                    icon={<ArrowUpIcon />}
                                    isDisabled={selected === -1}
                                    size="sm"
                                    variant="outline"
                                    aria-label="Previous"
                                />
                                <IconButton
                                    borderLeft={0}
                                    onClick={scrollNext}
                                    icon={<ArrowDownIcon />}
                                    variant="outline"
                                    size="sm"
                                    isDisabled={
                                        searchMessageResults.length === 0 ||
                                        selected === searchMessageResults.length
                                    }
                                    aria-label="next"
                                />
                            </HStack>
                            <IconButton
                                onClick={() => {
                                    setSearchOpen(false)
                                    onClose?.()
                                }}
                                variant="ghost"
                                icon={<CloseIcon />}
                                aria-label="Close"
                            />
                        </HStack>
                    }
                    onChange={e => setSearchTerm(e.target.value)}
                />
            </Box>
        )
    }
)

export default ChatHistorySearch
