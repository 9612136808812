import { HStack, IconButton } from '@chakra-ui/react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { SendIconSolid } from '@missionlabs/react/zeta'
import { TextContentListener } from 'lexical/LexicalEditor'
import { FC, useEffect, useState } from 'react'

import { useActivityFooterState } from '../../hooks/useActivityFooterState'
import { SAVE_CALL_NOTES_COMMAND } from './ActivityFeedNotesPlugin'

export const ActivityFeedNotesToolbar: FC = () => {
    const [editor] = useLexicalComposerContext()
    const [canSave, setCanSave] = useState(false)
    const { activity } = useActivityFooterState()

    useEffect(() => {
        const handleContextChange: TextContentListener = content => {
            setCanSave(content.length > 0)
        }
        const teardown = editor.registerTextContentListener(handleContextChange)
        return () => teardown()
    }, [editor, activity, setCanSave])

    return (
        <HStack
            spacing={0}
            py="8px"
            w="full"
            h="full"
            justify="end"
            data-testid="call-notes-toolbar"
        >
            <HStack justify="end" gap="8px" alignItems="center">
                <IconButton
                    size="sm"
                    variant="ghost"
                    isActive={canSave}
                    isDisabled={!canSave}
                    aria-label="Submit Notes"
                    onClick={() => {
                        editor.dispatchCommand(SAVE_CALL_NOTES_COMMAND, undefined)
                    }}
                    pl="4px"
                >
                    <SendIconSolid boxSize="24px" p="4px" />
                </IconButton>
            </HStack>
        </HStack>
    )
}
