import { useMeasure, VirtualPaginationContextProvider } from '@missionlabs/react'
import { useFlags } from 'flagsmith/react'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useActivityContactEntity } from 'shared/hooks/useRelatedActivities'

import { ActivityEmptyPage } from '../ActivityEmptyPage'
import { useActivitySidebarContext } from '../ActivitySidebar/ActivitySidebarContext'
import { ActivityFeedContent } from './ActivityFeedContent'
import { ActivityFeedFilters } from './ActivityFeedFilters'
import { ActivityFeedFooter } from './ActivityFeedFooter'
import { ActivityFeedHeader } from './ActivityFeedHeader'
import { AddNumberToContactDrawer } from './components/AddNumberToContact/Drawer'
import { AddNumberToContactDrawerProvider } from './components/AddNumberToContact/useAddNumberToContactDrawer'
import { ActivityFilterProvider } from './hooks/useActivityFeedFilters'
import { useActivityFooterState } from './hooks/useActivityFooterState'

export const ActivityFeedMain = () => {
    const { state } = useLocation()
    const flags = useFlags(['activity_feed'])
    const [filterRef, { blockSize: filterHeight }] = useMeasure<HTMLDivElement>()
    const [footerRef, { blockSize: footerHeight }] = useMeasure<HTMLDivElement>()

    const activityID = useMemo(() => state?.activityID as string | undefined, [state])
    const { all: sidebarActivities } = useActivitySidebarContext()
    const activity = activityID
        ? sidebarActivities.find(activity => activity.ID === activityID)
        : undefined
    const { isOpen } = useActivityFooterState()

    const entity = useActivityContactEntity(activity)
    const activityFeedKey = useMemo(() => {
        if (entity?.type === 'number') return entity.number
        if (entity?.type === 'contact') return entity.contactID
    }, [entity])

    if (!flags.activity_feed.enabled) return null
    if (!activity) return <ActivityEmptyPage />

    return (
        <AddNumberToContactDrawerProvider num={(entity as any)?.number}>
            <main>
                <ActivityFilterProvider
                    key={
                        /* Specifying the key=activityID will make sure state of filters is reset when different activity is clicked */
                        activity.ID
                    }
                >
                    <div id="activity-feed-header">
                        {entity?.type === 'number' && (
                            <ActivityFeedHeader contactNumber={entity.number} />
                        )}
                        {entity?.type === 'contact' && (
                            <ActivityFeedHeader contact={entity.contact} />
                        )}
                        {/* TODO: Loading skeleton */}
                    </div>
                    <div id="activity-feed-filters" ref={filterRef}>
                        <ActivityFeedFilters />
                    </div>
                    <VirtualPaginationContextProvider pageSize={200} direction="up">
                        <ActivityFeedContent
                            key={
                                /*
                                 * This will reset the pagination and activities state automatically when
                                 * an activity from a different "entity" is selected in the sidebar
                                 */
                                activityFeedKey
                            }
                            siblingHeight={81 + filterHeight + footerHeight}
                            entity={entity}
                            selectedActivity={activity}
                        />
                    </VirtualPaginationContextProvider>
                    <div id="activity-feed-footer" ref={footerRef}>
                        {isOpen && entity?.type === 'number' && (
                            <ActivityFeedFooter activity={activity} contactNumber={entity.number} />
                        )}
                        {isOpen && entity?.type === 'contact' && (
                            <ActivityFeedFooter activity={activity} contact={entity.contact} />
                        )}
                    </div>
                </ActivityFilterProvider>
            </main>

            <AddNumberToContactDrawer />
        </AddNumberToContactDrawerProvider>
    )
}
