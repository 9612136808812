import { ComponentStyleConfig } from '@chakra-ui/react'

export const BodyStyles: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        color: `${colorMode}.tones.navy`,
        fontFamily: 'body',
        fontWeight: 400,
        lineHeight: '150%'
    }),
    defaultProps: {
        size: 'md'
    },
    variants: {
        bold: ({ colorMode }) => ({
            color: `${colorMode}.tones.midnightExpress`,
            fontWeight: 500
        }),
        light: {
            fontWeight: 400
        },
        'extra-bold': ({ colorMode }) => ({
            color: `${colorMode}.tones.midnightExpress`,
            fontWeight: 700
        }),
        link: {
            textDecoration: 'underline'
        },
        'link-bold': {
            fontWeight: 700,
            textDecoration: 'underline'
        }
    },
    sizes: {
        lg: ({ colorMode }) => ({
            color: `${colorMode}.tones.midnightExpress`,
            fontSize: '24px',
            lineHeight: '168%',
            letterSpacing: '-0.48px'
        }),
        md: { fontSize: '16px', fontWeight: 500 },
        sm: { fontSize: '14px' },
        xs: { fontSize: '12px' }
    }
}

export const HeadingStyles: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        color: `${colorMode}.tones.midnightExpress`,
        fontFamily: 'heading'
    }),
    sizes: {
        h1: {
            fontSize: '32px',
            fontWeight: 400,
            lineHeight: '160%',
            letterSpacing: '-0.64px'
        },
        h2: {
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '233%',
            letterSpacing: '-0.48px'
        },
        h3: {
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '176%',
            letterSpacing: '-0.36px'
        },
        h4: {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: 'normal',
            letterSpacing: '0.24px'
        }
    }
}
