import { retrieveParkedCall } from '@missionlabs/api'
import { ParkedCall } from '@missionlabs/browser-calling'
import { Notification } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationProps } from 'shared/components/Notifications/types'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'

export const ParkedCallNotification: FC<NotificationProps<ParkedCall>> = ({
    call,
    dispatch,
    onClose
}) => {
    const { t } = useTranslation()
    const { formatToLocalNumber } = useFormatToNumberE164()

    const onAnswer = () => {
        dispatch(retrieveParkedCall())
    }

    const onDismiss = () => {
        if (!call) return
        onClose(call.callTraceID)
    }

    if (!call) return null

    return (
        <Notification
            variant="call"
            header={t('notifications.incomingCall')}
            name={call.parked.contactName ?? formatToLocalNumber(call.parked.remoteNumber)}
            role={call.parked.companyName ?? t('details.unknownCompany')}
            parked={t('details.parked', {
                value: call.parker.contactName ?? formatToLocalNumber(call.parker.remoteNumber)
            })}
            onAnswer={onAnswer}
            onDismiss={onDismiss}
        />
    )
}
