import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'

import { logoutSuccess } from '../slices'
import { isAPIError } from '../utils/api'

export const authMiddleware: Middleware = api => next => action => {
    const { dispatch } = api

    if (isRejectedWithValue(action)) {
        if (isAPIError(action)) {
            if (action.payload.status === 401) {
                dispatch(logoutSuccess())
            }

            if (
                action.meta.arg.endpointName === 'getDestinationForUser' &&
                action.payload.status === 404
            ) {
                dispatch(logoutSuccess())
            }
        }
    }

    return next(action)
}
