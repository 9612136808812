import { Box, Center, Divider, HStack, useColorMode, useDisclosure, VStack } from '@chakra-ui/react'
import {
    selectAuthenticatedUser,
    sendChatReaction,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { Avatar, Body, useMeasure } from '@missionlabs/react'
import { ChatMessage } from '@missionlabs/types'
import { format } from 'date-fns'
import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'
import { ForwardMessage } from 'shared/components/Lexical/components/ForwardMessage'
import { ReplyTo } from 'shared/components/Lexical/components/ReplyTo'
import { Upload } from 'shared/components/Lexical/components/Upload'
import { dispatchEditMessageEvent } from 'shared/components/Lexical/plugins/EditMessagePlugin'
import { dispatchReplyEvent } from 'shared/components/Lexical/plugins/ReplyPlugin'
import { useGetPresence } from 'shared/hooks/useGetPresence'
import { useGetFileUploadQuery, useMarkUnreadMutation, useSendEmojiMutation } from 'shared/store'

import { ChatContext } from '../context/chatContext'
import { ChatHistoryActions } from './ChatHistoryActions'
import { ChatHistoryReactionCount } from './ChatHistoryReactionCount'
import { ChatHistoryThreadCount } from './ChatHistoryThreadCount'

function parseHTML(html: string) {
    const parser = new DOMParser()
    const dom = parser.parseFromString(html, 'text/html')
    return dom.body.innerHTML
}

export interface ChatHistoryRowProps {
    item: ChatMessage
    index: number
    isThread?: boolean
    newTag?: boolean
    isUnread?: boolean
    messagesLength: number
    searchSkipID?: string
}

export const ChatHistoryRow: FC<ChatHistoryRowProps> = ({
    item,
    index,
    isThread = false,
    newTag,
    messagesLength,
    searchSkipID
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [sendReaction] = useSendEmojiMutation()
    const [markUnread] = useMarkUnreadMutation()
    const [, copyToClipboard] = useCopyToClipboard()

    const user = useSelector(selectAuthenticatedUser)
    const isUpdated = item.lastEdited ? item.lastEdited > item.created : false
    const { isGuest, messageForwarding } = useContext(ChatContext)
    const dispatch = useDispatch()

    const [measureRef, { height }] = useMeasure<HTMLDivElement>()
    const { status } = useGetPresence(item.userID)
    const content = item.contentHTML || item.contentPlainText
    const { contentPlainText } = item
    const disableActions = isThread && index === 0
    const isLastMessage = messagesLength - 1 === index

    const { data: file } = useGetFileUploadQuery(
        {
            channelID: item.channelID,
            uploadID: item.attachments?.length ? item.attachments[0] : '',
            userID: user?.userID || ''
        },
        { skip: !item.channelID || !item.attachments?.length || !user?.userID }
    )

    function handleEmojiAdd(unicode: string) {
        if (isGuest) {
            dispatch(sendChatReaction({ unicode, messageID: item.ID }))
        } else {
            sendReaction({
                messageID: item.ID,
                unicode,
                channelID: item.channelID,
                userID: user?.userID || ''
            })
        }
    }

    function navigateToThread() {
        navigate(`/chat/${item.channelID}/thread/${item.ID}`)
    }

    function replyToMessage() {
        dispatchReplyEvent(item.ID, item.user.name, item.contentPlainText)
    }

    function editMessage() {
        dispatchEditMessageEvent(item)
    }
    function markChannelUnread() {
        markUnread({ timestamp: item.created, channelID: item.channelID })
    }

    const handleCopyToClipboard = () => {
        copyToClipboard(contentPlainText)
    }

    const handleForwardMessage = () => {
        messageForwarding?.setForwardMessage(item)
    }

    function renderAvatar() {
        if (!isThread)
            return <Avatar size="sm" variant="whiteLilac" name={item.user?.name} status={status} />

        if (item.parentMessageID) {
            return (
                <>
                    {!isLastMessage && (
                        <Center position="absolute" justifyContent="flex-start">
                            <Divider
                                h={`${height * 1.7}px`}
                                borderStyle="dotted"
                                orientation="vertical"
                            />
                        </Center>
                    )}

                    <HStack spacing={0}>
                        <Divider w="25px" borderStyle="dotted" orientation="horizontal" />
                        <Avatar
                            size="sm"
                            variant="whiteLilac"
                            name={item.user?.name}
                            status={status}
                        />
                    </HStack>
                </>
            )
        }

        return (
            <VStack spacing={0}>
                <Avatar size="sm" variant="whiteLilac" name={item.user?.name} status={status} />
                <VStack spacing={0} position="relative">
                    {!isLastMessage && (
                        <Divider
                            position="absolute"
                            h={`${height * 1.3}px`}
                            borderStyle="dotted"
                            orientation="vertical"
                        />
                    )}
                </VStack>
            </VStack>
        )
    }

    return (
        <Box
            position="inherit"
            ml={item.parentMessageID ? '15.5px' : undefined}
            px="24.5px"
            py="12px"
            _hover={!disableActions ? { bg: `${colorMode}.tones.ghostWhite` } : undefined}
            onMouseOver={!disableActions ? onOpen : undefined}
            onMouseOut={!disableActions ? onClose : undefined}
            ref={measureRef}
        >
            <HStack
                spacing="10px"
                align="start"
                alignItems={item.parentMessageID ? 'flex-start' : undefined}
                position="relative"
            >
                {renderAvatar()}
                <VStack spacing="4px" align="start">
                    <HStack spacing="8px">
                        {newTag && (
                            <Body
                                size="sm"
                                variant="bold"
                                sx={{
                                    color: `${colorMode}.primary.gamma`,
                                    fontWeight: 800
                                }}
                            >
                                {t('chat.new')}
                            </Body>
                        )}

                        <Body
                            size="lg"
                            sx={{
                                color: `${colorMode}.tones.navy`,
                                fontWeight: 500
                            }}
                        >
                            {item.user?.name}
                        </Body>

                        <Body size="lg" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                            {format(item.created, 'HH:mm')}
                        </Body>
                    </HStack>
                    <Box>
                        {file && (
                            <Upload
                                mime={file.mime}
                                name={file.fileName}
                                body={file.downloadURL}
                                ID={file.ID}
                                uploaded={true}
                                hideDelete
                            />
                        )}
                        {item.replyTo && (
                            <ReplyTo
                                href={`${item.channelID}/${item.replyToMessageID}`}
                                from={item.replyTo.from}
                                message={item.replyTo.message}
                            />
                        )}

                        {item.forwarded && (
                            <ForwardMessage
                                from={item.forwarded.from}
                                message={item.forwarded.message}
                            />
                        )}
                    </Box>
                    <div
                        style={{
                            background: searchSkipID === item.ID ? '#DD48335F' : 'initial'
                        }}
                        dangerouslySetInnerHTML={{ __html: parseHTML(content) }}
                    />
                    {isUpdated && (
                        <Body size="sm" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                            ({t('edited')})
                        </Body>
                    )}
                    <HStack spacing="4px">
                        {item.threadCount && item.threadCount > 0 && (
                            <ChatHistoryThreadCount
                                count={item.threadCount}
                                onClick={navigateToThread}
                            />
                        )}
                        {item.emojiReactions && (
                            <ChatHistoryReactionCount
                                reactions={item.emojiReactions}
                                onEmojiClick={handleEmojiAdd}
                            />
                        )}
                    </HStack>
                </VStack>
            </HStack>
            <ChatHistoryActions
                menuIsOpen={isOpen}
                onEmojiClick={handleEmojiAdd}
                onReplyClick={replyToMessage}
                onEditClick={editMessage}
                onCopyToClipboard={handleCopyToClipboard}
                onThreadReplyClick={navigateToThread}
                onMarkUnreadClick={markChannelUnread}
                onForwardMessageClick={handleForwardMessage}
                isEditable={item.userID === user?.userID}
                isThread={isThread}
            />
        </Box>
    )
}
