import { useFlags } from 'flagsmith/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SubNavLink, SubNavMenu } from 'shared/components/SubNav'

export interface SettingsSidebarProps {}

export const SettingsSidebar: FC<SettingsSidebarProps> = () => {
    const { t } = useTranslation()
    const flags = useFlags(['speed_dial'])

    return (
        <SubNavMenu title={t('settings.title')}>
            <SubNavLink label={t('settings.myDetails')} page="/settings/my-details" />
            <SubNavLink label={t('settings.myNumbers')} page="/settings/my-numbers" />
            <SubNavLink label={t('settings.calls')} page="/settings/calls" />
            <SubNavLink label={t('settings.devices')} page="/settings/devices" />
            {flags.speed_dial.enabled && (
                <SubNavLink label={t('settings.directories')} page="/settings/directories" />
            )}
            <SubNavLink label={t('settings.sounds.title')} page="/settings/sounds" />
            <SubNavLink label={t('admin.integrations.title')} page="/settings/integrations" />
            <SubNavLink label={t('settings.recordings')} page="/settings/recordings" />
            <SubNavLink label={t('settings.notifications')} page="/settings/notifications" />
            <SubNavLink
                label={t('settings.conferenceCalling')}
                page="/settings/conference-calling"
            />
        </SubNavMenu>
    )
}
