import { Box, ButtonGroup, Heading, useColorMode } from '@chakra-ui/react'
import {
    answer,
    Call,
    SearchFilter,
    selectCalls,
    transferCall,
    TransferType,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { Button, Input, Scrollable, useFormatToNumberE164, useLocalCache } from '@missionlabs/react'
import { SearchIcon } from '@missionlabs/react/circleloop'
import { DirectoryEntry } from '@missionlabs/types'
import { isTeamOrMenu } from '@missionlabs/utils'
import { PayloadAction } from '@reduxjs/toolkit'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { useContactsSearch } from 'shared/hooks/useContactsSearch'
import { useGetUserData } from 'shared/hooks/useGetUserData'

import { VirtualTransferContact } from './TransferContact'
import { TransferListRenderer } from './TransferListRenderer'

export type TransferCallTarget = {
    type: 'numberE164' | 'teamID' | 'userID'
    value: string
    contact?: DirectoryEntry
}

type TransferCall = PayloadAction<{
    callTraceID: string
    contact?: DirectoryEntry
    userID?: string
    teamID?: string
    numberE164?: string
    isUnattended?: boolean
}>

interface TransferListProps {
    call: Call
    onClose: (target?: TransferCallTarget) => void
    transferType: TransferType
}

type ContactType = 'internal' | 'external'

function isRinging(call: Call) {
    return ['RINGING_LOCAL', 'RINGING_REMOTE'].includes(call.status)
}

export const TransferList: FC<TransferListProps> = ({ call, onClose, transferType }) => {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const { colorMode } = useColorMode()

    const { isValidPhoneNumber, formatToNumberE164 } = useFormatToNumberE164()

    const { user } = useGetUserData()
    const calls = useSelector(selectCalls)
    const transferCache = useLocalCache<string, TransferCall>(calls.length < 1)

    const [searchContacts, { data: contacts }] = useContactsSearch()

    const [contactType, setContactType] = useState<ContactType>('internal')

    const [contactSearch, setContactSearch] = useState('')
    const [externalE164Number, setExternalE164Number] = useState('')

    const getSearchFilters = (type: ContactType): SearchFilter[] => {
        return type === 'internal' ? ['clCompany'] : ['clPersonal']
    }

    const handleSearchChange = useCallback(
        (searchTerm: string) => {
            setContactSearch(searchTerm)
            searchContacts({ searchTerm, filter: getSearchFilters(contactType) })
        },
        [contactType, searchContacts]
    )

    const handleContactTypeChange = useCallback(
        (type: ContactType) => {
            setContactType(type)
            searchContacts({ filter: getSearchFilters(type) })
        },
        [searchContacts]
    )

    useEffect(() => {
        handleContactTypeChange(contactType)
    }, [contactType, handleContactTypeChange])

    useDebounce(
        function setExternalTransferNumber() {
            if (isValidPhoneNumber(contactSearch)) {
                setExternalE164Number(formatToNumberE164(contactSearch))
            } else {
                setExternalE164Number('')
            }
        },
        500,
        [contactSearch, setExternalE164Number, isValidPhoneNumber, formatToNumberE164]
    )

    const onTransfer = useCallback(
        (target: TransferCallTarget) => {
            const payload = transferCall({
                callTraceID: call.callTraceID,
                [target.type]: target.value,
                contact: target.contact,
                isUnattended: transferType === 'blind'
            })

            if (isRinging(call)) {
                // When call is ringing (not answered), we need to answer the call first.
                // Transfer takes place once the call has been connected.
                dispatch(answer({ callTraceID: call.callTraceID }))
                transferCache.setItem(call.callTraceID, payload)
            } else {
                // Dispatch transfer payload as normal if call is already answered.
                dispatch(payload)
            }

            onClose(target)
        },
        [call, dispatch, onClose, transferCache, transferType]
    )

    const virtualContacts: VirtualTransferContact[] = useMemo(() => {
        const isExternalTransfer = !contacts.length && externalE164Number

        if (isExternalTransfer) {
            /**
             * If there are no contacts found & they've searched a valid number
             * then show that number as a contact in the list for external transfers
             */
            const contact = {
                fullName: externalE164Number,
                phoneNumbers: [{ label: 'other', numberE164: externalE164Number }]
            } as DirectoryEntry

            return [{ contact, onClick: onTransfer }]
        }

        return contacts
            .filter(contact => !isTeamOrMenu(contact) && contact.externalID !== user?.ID)
            .map(contact => ({ contact, onClick: onTransfer }))
    }, [contacts, onTransfer, user?.ID, externalE164Number])

    useEffect(() => {
        // Live services creates a new call object when a call becomes connected. We find the focused call by querying the cached callTraceID.
        const activeCall = calls.find(call => Boolean(transferCache.hasItem(call.callTraceID)))
        const payload = transferCache.getItem(activeCall?.callTraceID ?? '')

        if (!payload || !activeCall || isRinging(activeCall)) return

        // We only want to dispatch the transfer payload once the call has been connected.
        if (activeCall?.status !== 'CONNECTED') return

        // Transfer is sent once a call has been connected.
        dispatch(payload)
        transferCache.clear()
    }, [calls, dispatch, transferCache])

    return (
        <>
            <Heading
                size="h4"
                pb="5"
                variant="bold"
                fontSize="16px"
                color="white"
                textTransform="capitalize"
            >
                {transferType} Transfer
            </Heading>

            <ButtonGroup mb="24px" w="100%" variant="outline" isAttached>
                {[
                    { label: 'Internal', value: 'internal' },
                    { label: 'External', value: 'external' }
                ].map(({ label, value }) => (
                    <Button
                        key={value}
                        variant="dark"
                        borderColor={`${colorMode}.tones.stormGrey`}
                        w="50%"
                        isActive={contactType === value}
                        onClick={() => handleContactTypeChange(value as ContactType)}
                    >
                        {label}
                    </Button>
                ))}
            </ButtonGroup>

            <Input
                value={contactSearch}
                onChange={e => handleSearchChange(e.target.value)}
                variant="dark"
                placeholder={t('call.transferSearchPlaceholder')}
                rightIcon={<SearchIcon />}
                mb="12px"
            />
            <Box h="calc(100dvh - 500px)" w="full">
                <AutoSizer>
                    {({ height, width }) => (
                        <FixedSizeList
                            height={height}
                            width={width}
                            itemSize={70}
                            itemCount={virtualContacts.length}
                            itemData={virtualContacts}
                            outerElementType={Scrollable}
                            // onItemsRendered={onItemsRendered}
                        >
                            {TransferListRenderer}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </Box>
        </>
    )
}
