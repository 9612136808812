import { avatarAnatomy } from '@chakra-ui/anatomy'
import {
    ComponentStyleConfig,
    createMultiStyleConfigHelpers,
    defineStyle,
    defineStyleConfig
} from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
    ...avatarAnatomy.keys,
    'icon',
    'pattern'
])

export const baseStyle = definePartsStyle({
    label: defineStyle({
        fontFamily: 'heading',
        letterSpacing: '0.02em',
        textTransform: 'uppercase'
    }),
    container: defineStyle({
        bgColor: 'light.tones.stormGrey'
    }),
    pattern: defineStyle({
        color: 'light.tones.midnightExpress',
        mixBlendMode: 'overlay',
        opacity: 0.75
    }),
    tooltip: defineStyle({
        bg: 'light.tones.navy',
        py: 1,
        px: 2
    })
})

export const sizes = {
    sm: definePartsStyle({
        label: defineStyle({ fontSize: '12px', fontWeight: 700, lineHeight: '15px' }),
        container: defineStyle({ boxSize: '32px' }),
        icon: defineStyle({ boxSize: '20px' }),
        pattern: defineStyle({ boxSize: '57px' })
    }),
    md: definePartsStyle({
        label: defineStyle({ fontSize: '14px', fontWeight: 800, lineHeight: '21px' }),
        container: defineStyle({ boxSize: '40px' }),
        icon: defineStyle({ boxSize: '24px' }),
        pattern: defineStyle({ boxSize: '72px' })
    }),
    lg: definePartsStyle({
        label: defineStyle({
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '32px'
        }),
        container: defineStyle({ boxSize: '48px' }),
        icon: defineStyle({ boxSize: '32px' }),
        pattern: defineStyle({ boxSize: '87px' })
    }),
    xl: definePartsStyle({
        label: defineStyle({
            fontSize: '18px',
            fontWeight: 800,
            lineHeight: '32px'
        }),
        container: defineStyle({ boxSize: '56px' }),
        icon: defineStyle({ boxSize: '40px' }),
        pattern: defineStyle({ boxSize: '102px' })
    })
}

export const variants = {
    whiteLilac: definePartsStyle({
        container: defineStyle({ bgColor: 'light.tones.whiteLilac', color: 'light.tones.navy' }),
        label: defineStyle({ color: 'light.tones.navy' })
    }),
    navy: definePartsStyle({
        container: defineStyle({ bgColor: 'light.tones.navy' }),
        pattern: defineStyle({ color: 'light.tones.navy' })
    }),
    red: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.red' })
    }),
    bloodOrange: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.bloodOrange' })
    }),
    orange: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.orange' })
    }),
    amber: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.amber' })
    }),
    yellow: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.yellow' })
    }),
    lime: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.lime' })
    }),
    green: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.green' })
    }),
    aqua: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.aqua' })
    }),
    teal: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.teal' })
    }),
    blue: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.blue' })
    }),
    indigo: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.indigo' })
    }),
    purple: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.purple' })
    }),
    violet: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.violet' })
    }),
    pink: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.pink' })
    }),
    hotPink: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.hotPink' })
    }),
    rose: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.rose' })
    })
}

export const AvatarStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle,
    sizes,
    variants
})

export const AvatarBadgeStyles: ComponentStyleConfig = defineStyleConfig({
    baseStyle: defineStyle({
        position: 'absolute',
        border: '1.2px solid',
        borderColor: 'light.tones.white',
        borderRadius: '50%',
        top: '0px',
        right: '0px'
    }),
    sizes: {
        sm: defineStyle({ boxSize: '12px', top: '-1px', right: '-1px' }),
        md: defineStyle({ boxSize: '12px', top: '-1px', right: '-1px' }),
        lg: defineStyle({ boxSize: '14px', top: '-1.71px', right: '-1.71px' }),
        xl: defineStyle({ boxSize: '16px', top: '-2px', right: '-2px' })
    },
    variants: {
        active: defineStyle({ bg: 'light.alerts.aqua' }),
        away: defineStyle({ bg: 'light.alerts.selectiveYellow' }),
        busy: defineStyle({ bg: 'light.alerts.red' }),
        offline: defineStyle({ bg: 'light.tones.periwinkle' }),
        dnd: defineStyle({ bg: 'light.alerts.red' }),
        custom: defineStyle({ bg: 'light.alerts.selectiveYellow' })
    }
})
