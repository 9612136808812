import { Children, createContext, createRef, FC, HTMLAttributes, PropsWithChildren } from 'react'

import { StyledGrid } from './styled'

export type Layout = 'standard' | 'featured' | null

export interface VideoGridProps extends HTMLAttributes<HTMLDivElement> {
    /** The number of tiles to lay out. */
    size?: number
    /** The layout of the grid. */
    layout?: Layout
}

interface GridContextProps {
    usingGrid: boolean
}

const gridData = { usingGrid: true }
const GridContext = createContext<GridContextProps | null>(null)

export const VideoGrid: FC<PropsWithChildren<VideoGridProps>> = ({
    size,
    children,
    layout = 'standard',
    ...rest
}) => {
    const gridEl = createRef<HTMLDivElement>()

    const gridSize = typeof size === 'number' ? size : Children.count(children)

    return (
        <GridContext.Provider value={gridData}>
            <StyledGrid
                ref={gridEl}
                {...rest}
                size={gridSize}
                // ratio={ratio} ignore ratio
                featured={layout === 'featured'}
                data-testid="video-grid"
            >
                {children}
            </StyledGrid>
        </GridContext.Provider>
    )
}

export default VideoGrid
