import { VStack } from '@chakra-ui/react'
import { Checkbox } from '@missionlabs/react'
import { FC } from 'react'

type SettingsCheckboxListProps = {
    options: { ID: string; label: string; isChecked?: boolean }[]
    onClick: (ID: string) => void
}

export const SettingsCheckboxList: FC<SettingsCheckboxListProps> = ({ options, onClick }) => {
    return (
        <VStack align="start">
            {options.map(({ ID, label, isChecked }) => (
                <Checkbox
                    key={ID}
                    isChecked={isChecked}
                    onChange={() => onClick(ID)}
                    data-testid="setting-checkbox"
                >
                    {label}
                </Checkbox>
            ))}
        </VStack>
    )
}
