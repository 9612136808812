import { Box } from '@chakra-ui/react'
import { Input, InputProps } from '@missionlabs/react'
import { SearchIcon } from '@missionlabs/react/circleloop'
import React from 'react'

interface Props extends InputProps {
    search: string
    setSearch(search: string): void
}

const CreateReportSearch: React.FC<Props> = ({ search, setSearch, ...props }) => {
    return (
        <Box my={6}>
            <Input
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
                rightIcon={<SearchIcon />}
                {...props}
            />
        </Box>
    )
}

export default CreateReportSearch
