import { Grid, HStack, useColorMode } from '@chakra-ui/react'
import { useDispatch } from '@missionlabs/api'
import { Body, IconButton } from '@missionlabs/react'
import { BlockIcon, SMSIcon, WhatsappIcon } from '@missionlabs/react/circleloop'
import { PhoneIconSolid } from '@missionlabs/react/zeta'
import {
    ContactType,
    DirectoryEntry,
    OutboundMessageType,
    UserRole,
    WhatsAppContentType
} from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RequireRole } from 'routes/RequireRole'
import { useBlockContact } from 'shared/hooks/useBlockContact'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetMyNumbers } from 'shared/hooks/useGetMyNumbers'
import { Features, useHasClientFeature } from 'shared/hooks/useHasClientFeature'
import { addMessage } from 'shared/slices/messagingSlice'

export interface ContactDetailsNumberRowProps {
    contact?: DirectoryEntry
    field: string
    value: string
    type: ContactType
    onBlock?: (numberE164: string) => void
    onCall?: (numberOrExt: string) => void
}

export const ContactDetailsNumberRow: FC<ContactDetailsNumberRowProps> = ({
    contact,
    field,
    value: numberE164,
    type,
    onBlock,
    onCall
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { colorMode } = useColorMode()
    const { isNumberBlocked } = useBlockContact(numberE164)
    const { formatToLocalNumber, isUKMobileNumber } = useFormatToNumberE164()

    const isBlocked = isNumberBlocked.inbound || isNumberBlocked.outbound

    const mobileNumbers = useGetMyNumbers({ mobileOnly: true })
    const smsClientEnabled = useHasClientFeature(Features.sms)

    const handleSMS = (contact: DirectoryEntry | undefined, toNumberE164: string) => {
        dispatch(
            addMessage({
                type: OutboundMessageType.SMS,
                contact,
                text: '',
                fromNumber: mobileNumbers[0].value,
                toNumber: toNumberE164 ?? ''
            })
        )
    }

    const handleWhatsApp = (contact: DirectoryEntry | undefined, _toNumberE164: string) => {
        dispatch(
            addMessage({
                type: OutboundMessageType.WhatsApp,
                contact,
                contentType: WhatsAppContentType.TEXT,
                toNumber: '',
                numberID: '',
                text: ''
            })
        )
    }

    const whatsappEnabled = useHasClientFeature(Features.whatsapp)

    return (
        <Grid gap="24px" w="full" alignItems="center" templateColumns={`128px 1fr 1fr`}>
            <Body size="sm" variant="bold">
                {type.toLocaleLowerCase() === 'internal' || type.toLocaleLowerCase() === 'group'
                    ? t('Direct')
                    : field}
            </Body>

            <Body size="sm">
                {formatToLocalNumber(numberE164)}{' '}
                {isBlocked ? (
                    <Body as="span" size="md" sx={{ color: `${colorMode}.alerts.red` }}>
                        {t('(blocked)')}
                    </Body>
                ) : null}
            </Body>

            <HStack ml="auto">
                {smsClientEnabled && isUKMobileNumber(numberE164) && (
                    <IconButton
                        ml="auto"
                        size="md"
                        variant="transparent"
                        icon={<SMSIcon />}
                        isDisabled={isNumberBlocked.outbound}
                        aria-label={`Send SMS to ${numberE164}}`}
                        onClick={() => {
                            handleSMS(contact, numberE164)
                        }}
                    />
                )}
                {whatsappEnabled && (
                    <IconButton
                        ml="auto"
                        size="md"
                        variant="transparent"
                        icon={<WhatsappIcon />}
                        aria-label={`Send WhatsApp to ${numberE164}}`}
                        onClick={() => {
                            handleWhatsApp(contact, numberE164)
                        }}
                    />
                )}
                <IconButton
                    ml="auto"
                    size="md"
                    variant="transparent"
                    icon={<PhoneIconSolid color={`${colorMode}.alerts.aqua`} />}
                    isDisabled={isNumberBlocked.outbound}
                    aria-label={`Call ${numberE164}}`}
                    onClick={() => onCall?.(numberE164)}
                />
                {type === 'external' && (
                    <RequireRole role={UserRole.ADMIN}>
                        <IconButton
                            ml="auto"
                            size="md"
                            variant="transparent"
                            isDisabled={isBlocked}
                            icon={<BlockIcon color={`${colorMode}.alerts.red`} />}
                            aria-label={`Block ${numberE164}}`}
                            onClick={() => onBlock?.(numberE164)}
                        />
                    </RequireRole>
                )}
            </HStack>
        </Grid>
    )
}
