import { DialpadIconSolid } from '@missionlabs/react/zeta'
import { FC } from 'react'

import { DrawerMode } from '../ContactDrawer'
import { NavButton } from './NavButton'

interface DiallerButtonProps {
    isOpen: boolean
    onClick: (mode?: DrawerMode) => void
}

export const DiallerButton: FC<DiallerButtonProps> = ({ isOpen, onClick }) => {
    return (
        <NavButton
            aria-label="dialler button"
            icon={<DialpadIconSolid color="inherit" boxSize="20px" />}
            isActive={isOpen}
            onClick={() => onClick(DrawerMode.DIALLER)}
        />
    )
}
