import './index.css'

import { EditorThemeClasses } from 'lexical'

const theme: EditorThemeClasses = {
    paragraph: 'paragraph',
    list: {
        listitem: 'listitem',
        nested: {
            listitem: 'nested-listitem'
        },
        olDepth: ['ol1', 'ol2', 'ol3'],
        ul: 'ul'
    },
    link: 'link',
    text: {
        bold: 'bold',
        code: 'code',
        italic: 'italic',
        underline: 'underline'
    }
}

export default theme
