import { drawerAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([...drawerAnatomy.keys, 'title'])

export const DrawerStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => ({
        body: { p: 0 },
        dialog: { bg: 'white', boxShadow: 'none' },
        overlay: {
            bg: `${colorMode}.tones.navy.alpha-20`
        },
        header: {
            p: '12px 16px 12px 24px',
            bg: `${colorMode}.tones.ghostWhite`,
            borderBottom: '1px solid',
            borderColor: `${colorMode}.tones.periwinkle`
        },
        title: {
            fontFamily: 'heading',
            fontWeight: 800,
            fontSize: '16px',
            lineHeight: '28.16px',
            letterSpacing: '-0.02em',
            color: `${colorMode}.tones.midnightExpress`
        }
    }),
    defaultProps: {
        size: 'sm'
    },
    sizes: {
        sm: {
            dialog: {
                maxWidth: '378px'
            }
        },
        md: {
            dialog: {
                maxWidth: '428px'
            }
        },
        lg: {
            dialog: {
                maxWidth: '688px'
            }
        }
    }
})
