import { selectAuthenticatedUser, useDispatch, useSelector } from '@missionlabs/api'
import { Radio, Table } from '@missionlabs/react'
import { NumbersPhoneNumber, NumbersTeam } from '@missionlabs/types'
import { isNumbersTeam } from '@missionlabs/utils'
import { CellContext, createColumnHelper } from '@tanstack/react-table'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useUserRole } from 'shared/hooks/useUserRole'
import { numbersAPI, useGetNumbersForUserQuery } from 'shared/store'

import { useSettingsContext } from '../../components/settingsContext'

type NumberRow = {
    number: string
    assignedTo: string
    default: boolean
}

const columnHelper = createColumnHelper<NumberRow>()

const NumberCell = (ctx: CellContext<NumberRow, string>) => {
    const userRole = useUserRole()
    const { formatToLocalNumber } = useFormatToNumberE164()
    const num = formatToLocalNumber(ctx.getValue())
    if (userRole === 'ADMIN') {
        return <Link to={`/admin/numbers?numberE164=${ctx.row.original.number}`}>{num}</Link>
    }
    return num
}

export const MyNumbers: FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { onUpdateUserCallRoute } = useSettingsContext()

    const user = useSelector(selectAuthenticatedUser)
    const { data: numbers } = useGetNumbersForUserQuery(user?.userID ?? '', { skip: !user })

    const updateDefaultPhoneNumber = useCallback(
        async (row: NumberRow) => {
            await onUpdateUserCallRoute({ defaultPhoneNumber: row.number })
            dispatch(numbersAPI.util.invalidateTags(['Numbers'])) // update list after number has been assigned to user so table reflects changes.
        },
        [dispatch, onUpdateUserCallRoute]
    )

    const getAssignedTo = useCallback(
        (number: NumbersPhoneNumber | NumbersTeam) => {
            if (isNumbersTeam(number)) return number.name
            return t('You')
        },
        [t]
    )

    const getTableData = useCallback(
        (number: NumbersPhoneNumber, team?: NumbersTeam) => {
            return {
                number: number.numberE164,
                assignedTo: getAssignedTo(team ?? number),
                default: number.isDefault
            }
        },
        [getAssignedTo]
    )

    const tableColumns = useMemo(() => {
        return [
            columnHelper.accessor('number', {
                cell: NumberCell,
                header: t('Name')
            }),
            columnHelper.accessor('assignedTo', {
                cell: info => info.getValue(),
                header: t('assignedTo')
            }),
            columnHelper.accessor('default', {
                cell: ({ row }) => {
                    return (
                        <Radio
                            aria-label={row.original.number}
                            isChecked={row.original.default}
                            onClick={() => updateDefaultPhoneNumber(row.original)}
                        />
                    )
                },
                header: t('Default'),
                id: 'default'
            })
        ]
    }, [t, updateDefaultPhoneNumber])

    const tableData = useMemo(() => {
        if (!numbers) return []

        const phoneNumbers = numbers.phoneNumbers ?? []
        const teams = numbers.teams ?? []

        const phoneNumberTableData = phoneNumbers.map(number => getTableData(number))
        const teamTableData = teams
            .map(team => {
                const phoneNumbers = team.phoneNumbers ?? []
                return phoneNumbers.map(number => getTableData(number, team))
            })
            .flat()

        return [...phoneNumberTableData, ...teamTableData]
    }, [getTableData, numbers])

    return (
        <SettingsPage title={t('myNumbers.title')} isEmpty={!tableData.length}>
            <Table paginate columns={tableColumns} data={tableData} />
        </SettingsPage>
    )
}
