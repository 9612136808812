import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const AttachIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AttachIconLight',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M15.0645 2.68555C13.9316 1.55273 12.0957 1.55273 10.9629 2.68555L3.46289 10.1855C1.62695 12.0215 1.62695 14.9512 3.46289 16.7871C5.29883 18.623 8.22852 18.623 10.0645 16.7871L16.002 10.8496C16.2363 10.6152 16.666 10.6152 16.9004 10.8496C17.1348 11.084 17.1348 11.5137 16.9004 11.748L10.9629 17.6855C8.6582 19.9902 4.86914 19.9902 2.56445 17.6855C0.259766 15.3418 0.259766 11.5918 2.56445 9.28711L10.0645 1.78711C11.7051 0.185547 14.3223 0.185547 15.9629 1.78711C17.5645 3.42773 17.5645 6.04492 15.9629 7.68555L8.77539 14.873C7.68164 15.9668 5.8457 15.8496 4.86914 14.6387C4.04883 13.584 4.12695 12.0996 5.06445 11.1621L11.002 5.22461C11.2363 4.99023 11.666 4.99023 11.9004 5.22461C12.1348 5.45898 12.1348 5.88867 11.9004 6.12305L5.96289 12.0605C5.45508 12.5293 5.41602 13.3105 5.8457 13.8574C6.35352 14.4824 7.33008 14.5605 7.87695 13.9746L15.0645 6.78711C16.1973 5.6543 16.1973 3.81836 15.0645 2.68555Z"
            fill="currentColor"
        />
    )
})

export const AttachIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AttachIconRegular',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M14.8691 2.88086C13.8535 1.86523 12.1738 1.86523 11.1582 2.88086L3.6582 10.3809C1.97852 12.0996 1.97852 14.873 3.6582 16.5527C5.37695 18.2715 8.15039 18.2715 9.86914 16.5527L15.8066 10.6152C16.1582 10.2637 16.7441 10.2637 17.0957 10.6152C17.4863 11.0059 17.4863 11.5918 17.0957 11.9434L11.1582 17.8809C8.73633 20.3418 4.79102 20.3418 2.36914 17.8809C-0.0917969 15.459 -0.0917969 11.5137 2.36914 9.05273L9.86914 1.5918C11.5879 -0.166016 14.4395 -0.166016 16.1582 1.5918C17.916 3.31055 17.916 6.16211 16.1582 7.88086L9.00977 15.0684C7.75977 16.3184 5.72852 16.2012 4.63477 14.834C3.69727 13.6621 3.81445 11.9824 4.86914 10.9277L10.8066 4.99023C11.1582 4.63867 11.7441 4.63867 12.0957 4.99023C12.4863 5.38086 12.4863 5.9668 12.0957 6.31836L6.19727 12.2559C5.80664 12.6465 5.76758 13.2324 6.11914 13.6621C6.50977 14.1699 7.21289 14.209 7.68164 13.7402L14.8691 6.55273C15.8848 5.57617 15.8848 3.89648 14.8691 2.88086Z"
            fill="currentColor"
        />
    )
})

export const AttachIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AttachIconSolid',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M14.4834 3.16895C13.5068 2.23145 11.9834 2.23145 11.0068 3.16895L3.81934 10.3564C2.17871 11.9971 2.17871 14.6924 3.81934 16.333C5.45996 17.9736 8.15527 17.9736 9.7959 16.333L15.7334 10.3955C16.1631 9.96582 16.8271 9.96582 17.2568 10.3955C17.6865 10.8252 17.6865 11.4893 17.2568 11.9189L11.3193 17.8564C8.81934 20.3564 4.7959 20.3564 2.2959 17.8564C-0.204102 15.3564 -0.204102 11.333 2.2959 8.83301L9.4834 1.64551C11.2803 -0.19043 14.21 -0.19043 16.0068 1.64551C17.8428 3.44238 17.8428 6.37207 16.0068 8.16895L9.13184 15.0439C8.03809 16.1768 6.20215 16.1768 5.1084 15.0439C3.97559 13.9502 3.97559 12.1143 5.1084 11.0205L10.7334 5.39551C11.1631 4.96582 11.8271 4.96582 12.2568 5.39551C12.6865 5.8252 12.6865 6.48926 12.2568 6.91895L6.63184 12.5439C6.39746 12.8174 6.39746 13.2471 6.63184 13.5205C6.90527 13.7549 7.33496 13.7549 7.6084 13.5205L14.4834 6.64551C15.4209 5.66895 15.4209 4.14551 14.4834 3.16895Z"
            fill="currentColor"
        />
    )
})
