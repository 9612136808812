import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const BlockIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BlockIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 11.1 19.854 10.2333 19.562 9.4C19.2707 8.56667 18.85 7.8 18.3 7.1L7.1 18.3C7.8 18.85 8.56667 19.2707 9.4 19.562C10.2333 19.854 11.1 20 12 20ZM5.7 16.9L16.9 5.7C16.2 5.15 15.4333 4.72933 14.6 4.438C13.7667 4.146 12.9 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 12.9 4.146 13.7667 4.438 14.6C4.72933 15.4333 5.15 16.2 5.7 16.9Z"
            fill="currentColor"
        />
    )
})
