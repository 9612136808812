import { selectConnectedCallOrFAC, sendDTMF, useDispatch, useSelector } from '@missionlabs/api'
import { useCallback, useMemo } from 'react'

interface UseDTMFProps {
    isDisabled?: boolean
}

const dtmfKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '#']
const isAllowedKey = (key: string) => dtmfKeys.includes(key)

export const useDtmf = ({ isDisabled }: UseDTMFProps = {}) => {
    const dispatch = useDispatch()

    const connectedCall = useSelector(selectConnectedCallOrFAC)

    const canSendDtmf = useMemo(() => {
        return Boolean(connectedCall) && !isDisabled
    }, [connectedCall, isDisabled])

    const onDtmfPress = useCallback(
        (dtmfKey: string) => {
            if (!connectedCall) return
            if (isAllowedKey(dtmfKey)) {
                dispatch(sendDTMF({ dtmfKey, callTraceID: connectedCall.callTraceID }))
            }
        },
        [connectedCall, dispatch]
    )

    return { canSendDtmf, onDtmfPress }
}
