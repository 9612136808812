import isElectron from 'is-electron'

declare global {
    interface Window {
        shell: {
            download: (url: string) => Promise<void>
            openExternal: (url: string, options?: any) => Promise<void>
        }
    }
}

export function openURL(url: string) {
    if (!isElectron()) {
        window.open(url)
    } else {
        window.shell.openExternal(url)
    }
}

export function downloadURL(url: string) {
    if (!isElectron()) {
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', 'download')
        link.setAttribute('target', '_blank')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    } else {
        window.shell.download(url)
    }
}
