import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { searchInputAnatomy } from 'theme/anatomy'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(searchInputAnatomy.keys)

export const SearchInputStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => {
        const hoverStyles = {
            bg: `${colorMode}.tones.whiteLilac`,
            color: `${colorMode}.tones.midnightExpress`
        }
        const resetAsideStyles = {
            w: 'unset',
            h: 'unset',
            top: 'unset',
            fontSize: 'unset',
            position: 'relative'
        }

        return {
            group: {
                pl: '24px',
                pr: '16px',
                py: '12px',
                alignItems: 'center',
                borderBottom: '1px solid',
                borderColor: `${colorMode}.tones.periwinkle`,
                color: `${colorMode}.tones.stormGrey`,
                _active: {
                    color: `${colorMode}.primary.gammaHover`
                },
                _hover: hoverStyles,
                _focus: hoverStyles
            },
            input: {
                p: 0,
                border: 'none',
                bg: 'transparent',
                color: 'inherit',
                fontFamily: 'heading',
                fontWeight: 800,
                fontSize: '16px',
                lineHeight: '28.16px',
                letterSpacing: '-0.02em',
                _focusVisible: {},
                _placeholder: {
                    color: 'inherit'
                }
            },
            rightAside: {
                ...resetAsideStyles,
                justifyContent: 'flex-end',
                right: 'unset'
            }
        }
    }
})
