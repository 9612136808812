import { DirectoryEntry } from '@missionlabs/types'
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useMemo } from 'react'
import { ListOnItemsRenderedProps } from 'react-window'
import { usePaginatedContacts } from 'shared/hooks/usePaginatedContacts'

import {
    ContactFilters,
    ContactFilterType,
    useContactsFilterParams
} from '../hooks/useContactsFilterParams'
import { useContactsSearchParams } from '../hooks/useContactsSearchParams'

interface ContactContextType {
    contacts: DirectoryEntry[]
    replaceContact(contact: DirectoryEntry): void
    isLoadingContacts: boolean
    onItemsRendered: (props: ListOnItemsRenderedProps) => void
    setItemCount: Dispatch<SetStateAction<number>>
    filters: ContactFilters
    toggleSource: (value: string, checked: boolean) => void
    toggleType: (value: ContactFilterType, checked: boolean) => void
    resetFilters: () => void
}

export const ContactContext = createContext<ContactContextType>({} as never)

interface ContactsProviderProps {
    myTeamsOnly?: boolean
}

export const ContactsProvider = ({
    children,
    myTeamsOnly
}: PropsWithChildren<ContactsProviderProps>) => {
    const { type, searchTerm, sortBy } = useContactsSearchParams()
    const { filters, toggleSource, toggleType, reset: resetFilters } = useContactsFilterParams()

    const searchParams = useMemo(() => {
        const favouritesOnly = type === 'favourites' || undefined

        const filter =
            type === 'all' || type === 'favourites'
                ? filters.type
                : Array.from(new Set([type, ...filters.type]))

        return {
            searchTerm,
            sortBy,
            filter,
            favouritesOnly,
            myTeamsOnly,
            sources: filters.source
        }
    }, [searchTerm, sortBy, type, myTeamsOnly, filters])

    const {
        contacts,
        replaceContact,
        isLoading: isLoadingContacts,
        onItemsRendered,
        setItemCount
    } = usePaginatedContacts(searchParams)

    const value = useMemo(
        () => ({
            replaceContact,
            contacts,
            isLoadingContacts,
            onItemsRendered,
            setItemCount,
            filters,
            toggleSource,
            toggleType,
            resetFilters
        }),
        [
            contacts,
            isLoadingContacts,
            onItemsRendered,
            replaceContact,
            setItemCount,
            filters,
            toggleSource,
            toggleType,
            resetFilters
        ]
    )

    return <ContactContext.Provider value={value}>{children}</ContactContext.Provider>
}

export default ContactsProvider
