import { selectDefaultRootURL, useSelector } from '@missionlabs/api'
import { FormProvider, useYupForm, yup } from '@missionlabs/react'
import { AddressLookup } from '@missionlabs/types'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { FC, ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { DocumentTitle } from 'shared/components/DocumentTitle'
import { useGetResetTokenQuery, useUpdateResetTokenMutation } from 'shared/store'
import { AppConfig } from 'shared/utils/env'
import type { TestContext } from 'yup'

import { WelcomeError } from './WelcomeError'
import { WelcomeForm } from './WelcomeForm'
import { WelcomeSuccess } from './WelcomeSuccess'

export interface WelcomeProps {}

export interface WelcomeFields {
    password: string
    acceptTerms?: boolean
    address?: AddressLookup
}
const userAddressSchema = yup.object().shape({
    premises: yup.string().test({
        message: {
            key: 'validations.required'
        },
        test: value => !!value
    }),
    street: yup.string(), // Some UK addresses don't have a street, town or county
    town: yup.string(),
    county: yup.string(),
    postcode: yup.string().test({
        message: {
            key: 'validations.required'
        },
        test: value => !!value
    })
})

export const Welcome: FC<WelcomeProps> = () => {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const tokenID = useMemo(() => searchParams.get('token'), [searchParams])

    // The API URL isn't known at this point because we're not logged in, also
    // useUpdateResetTokenMutation expects the endpoint without "/admin"
    const baseUrl = useSelector(selectDefaultRootURL)

    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const { data: token, isError } = useGetResetTokenQuery(tokenID || skipToken)
    const [activateAccount, { isSuccess }] = useUpdateResetTokenMutation()

    const methods = useYupForm<WelcomeFields>(
        yup.object({
            password: yup.string().required().test(getPasswordError),
            address: userAddressSchema
        }),
        { defaultValues: { password: '' }, mode: 'onChange' }
    )
    const {
        formState: { isSubmitting, isValid }
    } = methods

    const onSubmit = async (data: WelcomeFields) => {
        try {
            if (!tokenID) throw Error('Missing required search params.')

            console.warn(`Activate:\n\n${JSON.stringify(data, null, 4)}`)
            await activateAccount({
                ID: tokenID,
                endpoint: baseUrl,
                password: data.password,
                address: data.address
            }).unwrap()
        } catch (e) {
            console.error(e)
            setErrorMessage('data' in e ? e.data.message : e.message)
        }
    }

    let content: ReactNode
    if (isSuccess) {
        content = <WelcomeSuccess />
    } else if (isError) {
        content = <WelcomeError />
    } else {
        content = (
            <WelcomeForm
                errorMessage={errorMessage}
                isValid={isValid}
                isSubmitting={isSubmitting}
                token={token}
            />
        )
    }

    return (
        <>
            <DocumentTitle breadcrumbs={[t('welcome.title', { appName: AppConfig.name })]} />
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>{content}</form>
            </FormProvider>
        </>
    )
}

function getPasswordError(password: string, ctx: TestContext) {
    if (password) {
        if (password.length < 8)
            return ctx.createError({ message: { key: 'setPassword.error.charactersLength' } })
        if (password.replace(/\D/g, '').length < 2)
            return ctx.createError({ message: { key: 'setPassword.error.includeLettersNumbers' } })
        if (!/\p{Uppercase_Letter}/u.test(password))
            return ctx.createError({ message: { key: 'setPassword.error.uppercaseLetter' } })
        if (!/[/\p{Lowercase_Letter}\p{Uppercase_Letter}]/u.test(password))
            return ctx.createError({ message: { key: 'setPassword.error.includeLetters' } })
    }
    return true
}
