import {
    Avatar as ChakraAvatar,
    AvatarProps as ChakraAvatarProps,
    Box,
    useMultiStyleConfig
} from '@chakra-ui/react'
import { LabelledPresenceStatus } from '@missionlabs/types'
import { WavyLines } from 'atoms/Patterns/WavyLines'
import { StatusTooltip } from 'atoms/StatusTooltip/StatusTooltip'
import { forwardRef, useMemo } from 'react'
import { GridIconRegular, UsersIconSolid } from 'zeta'

import { AvatarBadge } from './AvatarBadge'
import { AvatarIcon } from './AvatarIcon'

export interface AvatarProps extends ChakraAvatarProps {
    variant?:
        | 'whiteLilac'
        | 'navy'
        | 'red'
        | 'bloodOrange'
        | 'orange'
        | 'amber'
        | 'yellow'
        | 'lime'
        | 'green'
        | 'aqua'
        | 'teal'
        | 'blue'
        | 'indigo'
        | 'purple'
        | 'violet'
        | 'pink'
        | 'hotPink'
        | 'rose'
    size?: 'sm' | 'md' | 'lg' | 'xl'
    status?: LabelledPresenceStatus
    group?: boolean
    team?: boolean
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
    (
        {
            status,
            size,
            group,
            team,
            variant,
            name,
            icon: iconProp,
            boxShadow,
            sx = {},
            className,
            ...props
        },
        ref
    ) => {
        const styles = useMultiStyleConfig('Avatar', {
            size,
            variant: team ? 'whiteLilac' : variant
        })

        const icon = useMemo(() => {
            if (iconProp) return iconProp
            if (group) return <GridIconRegular sx={{ ...styles.icon, p: 1 }} />
            if (team) return <UsersIconSolid sx={{ ...styles.icon, p: 1 }} />
        }, [iconProp, group, team, styles.icon])

        const getName = useMemo(() => {
            if (icon) return undefined

            const regex = /^\d+$/
            const isNumber = regex.test(name ?? '')
            if (!name || isNumber) return '?'

            return name
        }, [icon, name])

        return (
            <StatusTooltip status={status}>
                <Box ref={ref} w="fit-content" position="relative" className={className}>
                    <Box overflow="hidden" borderRadius="50%" boxShadow={boxShadow}>
                        <Box
                            borderRadius="50%"
                            boxSize={styles.container.boxSize as string}
                            overflow="hidden"
                        >
                            <ChakraAvatar
                                size={size}
                                variant={team ? 'whiteLilac' : variant}
                                {...props}
                                icon={<AvatarIcon icon={icon} size={size} variant={variant} />}
                                name={getName}
                                position="absolute"
                                sx={{ ...styles.container, ...sx }}
                            />
                            <WavyLines position="relative" sx={{ ...styles.pattern }} />
                        </Box>
                    </Box>

                    {status && <AvatarBadge variant={status.status} size={size} />}
                </Box>
            </StatusTooltip>
        )
    }
)

Avatar.displayName = 'Avatar'
