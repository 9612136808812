import { Flex, HStack, VStack } from '@chakra-ui/react'
import {
    Button,
    DateInput,
    Dropdown,
    ErrorMessage,
    Input,
    useFormContext,
    ValidatedInput
} from '@missionlabs/react'
import { differenceInDays } from 'date-fns'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetReportTypesQuery } from 'shared/store'

import { MAX_DAYS, ReportFormFields, ReportFormType } from './form'
import CallsForm from './forms/CallsForm'
import ItemisedBillingForm from './forms/ItemisedBillingForm'
import SmsForm from './forms/SmsForm'

interface Props {
    goToNextStep(): void
    isSubmitting: boolean
}

const ReportFormInitial: React.FC<Props> = ({ goToNextStep, isSubmitting }) => {
    const { formState, watch, setValue } = useFormContext<ReportFormFields>()
    const { t } = useTranslation()
    const { setCreate } = useAnalytics().report
    const now = useMemo(() => new Date(), [])

    const { data: { kvps: { reportTypes } } = { kvps: { reportTypes: {} } } } =
        useGetReportTypesQuery()

    const reportTypeOptions = useMemo(() => {
        return Object.keys(reportTypes).map(key => {
            return {
                label: t(`analytics.reports.form.types.${key}`),
                value: `${key === 'calls' ? 'call' : key}`
            }
        })
    }, [reportTypes, t])

    const fromDate = watch('params.fromDate')
    const toDate = watch('params.toDate')

    const datesValid = differenceInDays(toDate, fromDate) <= MAX_DAYS

    const formType = watch('type')

    /** The itemised billing report doesn't require the next page so just return create */
    const renderNextOrCreate = useMemo(() => {
        if (formType === 'itemized_bill') {
            return (
                <Button
                    w="50%"
                    isDisabled={!formState.isValid || !datesValid}
                    isLoading={isSubmitting}
                    type="submit"
                    variant="creationary"
                >
                    {t('analytics.reports.create')}
                </Button>
            )
        }
        return (
            <Button
                isDisabled={!formState.isValid || !datesValid}
                w="50%"
                onClick={goToNextStep}
                variant="secondary"
            >
                Next Step
            </Button>
        )
    }, [formType, formState.isValid, goToNextStep, isSubmitting, t, datesValid])

    const formToShow = useMemo(() => {
        switch (formType) {
            case 'call': {
                return <CallsForm />
            }
            case 'sms': {
                return <SmsForm />
            }
            case 'itemized_bill': {
                return <ItemisedBillingForm />
            }
            default: {
                return null
            }
        }
    }, [formType])

    return (
        <Flex pb={8} h="full" direction="column" justify="space-between">
            <VStack w="full" px={8} pt={4}>
                <ValidatedInput
                    mb={2}
                    isRequired
                    name="name"
                    label={t('analytics.reports.form.name')}
                >
                    <Input data-testid="name-input" autoFocus />
                </ValidatedInput>

                <ValidatedInput mb={2} name="type" label={t('analytics.reports.form.type')}>
                    <Dropdown
                        options={reportTypeOptions}
                        value={watch('type')}
                        onChange={(value: string) => {
                            setValue('type', value as ReportFormType, { shouldDirty: true })
                        }}
                        data-testid="type-input"
                    />
                </ValidatedInput>

                <ValidatedInput mb={2} name="name" label={t('analytics.reports.form.dateFrom')}>
                    <DateInput
                        placeholder="DD/MM/YY"
                        value={new Date(fromDate)}
                        onChange={date => setValue('params.fromDate', date!.getTime())}
                        data-testid="start-date"
                        maxDate={now}
                    />
                </ValidatedInput>
                <ValidatedInput mb={2} name="Tyle" label={t('analytics.reports.form.dateTo')}>
                    <DateInput
                        placeholder="DD/MM/YY"
                        value={new Date(toDate)}
                        onChange={date => setValue('params.toDate', date!.getTime())}
                        data-testid="end-date"
                        maxDate={now}
                    />
                </ValidatedInput>
                {formToShow}
            </VStack>
            <VStack>
                <HStack>
                    {!datesValid && (
                        <ErrorMessage
                            error={t('analytics.reports.form.invalidDates', { value: MAX_DAYS })}
                        />
                    )}
                </HStack>
                <HStack px={6} w="full">
                    <Button onClick={() => setCreate(false)} w="50%" variant="secondary">
                        Cancel
                    </Button>
                    {renderNextOrCreate}
                </HStack>
            </VStack>
        </Flex>
    )
}

export default ReportFormInitial
