import { useColorMode, useDisclosure, VStack } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { ExtensionUser, IVRMenu, User } from '@missionlabs/types'
import { inferFirstLastName, sort } from '@missionlabs/utils'
import { AvailableTabs, UTMDrawer } from 'features/settings/components/UTMDrawer'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { buildIVROptionsArray } from '../utils/ivr'
import { IVRMenuOption } from './IVRMenuOption'
import { NewUserExtension } from './NewUserExtension'

interface UserExtensionsProps {
    extensions: IVRMenu['IVR']['extensions']
    onUpdateExtensions: (extensions: IVRMenu['IVR']['extensions']) => void
}

type EditingKeyState = { index: string; data: ExtensionUser }

const MIN_KEY = 700
const MAX_KEY = 799

export function UserExtensions({ extensions = {}, onUpdateExtensions }: UserExtensionsProps) {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const utmDrawer = useDisclosure()

    // Which is the current key being edited, and what is its existing value?
    const [editingKey, setEditingKey] = useState<EditingKeyState | undefined>()

    const menuOptions = useMemo(() => {
        return Object.entries(extensions)
            .filter(ex => Number(ex[0]) >= MIN_KEY)
            .sort(([a], [b]) => sort(a, b))
    }, [extensions])

    // The keys that are not currently in use
    const availableKeys = useCallback(
        (currentKey?: string) => getAvailableKeys(extensions, currentKey),
        [extensions]
    )

    const updateKey = (index: string, data: User) => {
        const updatedExtensions = { ...extensions }
        updatedExtensions[index] = { userID: data.ID, display: inferFirstLastName(data).fullName }
        onUpdateExtensions(updatedExtensions)
    }

    // Change a key's index from one to another
    const swapKeyIndex = (currentIndex: string, newIndex: string) => {
        const updatedExtensions = { ...extensions }
        updatedExtensions[newIndex] = updatedExtensions[currentIndex]
        delete updatedExtensions[currentIndex]
        onUpdateExtensions(updatedExtensions)
    }

    const deleteKey = (index: string) => {
        const updatedExtensions = { ...extensions }
        delete updatedExtensions[index]
        onUpdateExtensions(updatedExtensions)
    }

    const startEditingKey = (index: string, data: ExtensionUser) => {
        setEditingKey({ index, data })
        utmDrawer.onOpen()
    }

    const stopEditingKey = () => {
        setEditingKey(undefined)
        utmDrawer.onClose()
    }

    return (
        <VStack align="flex-start" gap={6}>
            <VStack align="flex-start" gap={2}>
                <Body variant="bold">{t('admin.menus.extensionsTitle')}</Body>
                <Body size="sm" variant="bold" color={`${colorMode}.tones.stormGrey`}>
                    {t('admin.menus.extensionsSubtitle', { min: MIN_KEY, max: MAX_KEY })}
                </Body>
            </VStack>

            <VStack align="flex-start" alignSelf="stretch" gap={4}>
                {menuOptions.map(([index, value]) => (
                    <IVRMenuOption
                        key={index}
                        index={index}
                        display={value.display}
                        availableKeys={availableKeys(index)}
                        onSwap={newIndex => swapKeyIndex(index, newIndex)}
                        onEdit={() => startEditingKey(index, value as ExtensionUser)}
                        onDelete={() => deleteKey(index)}
                    />
                ))}

                <NewUserExtension availableKeys={availableKeys()} onSubmit={updateKey} />
            </VStack>

            {/* Userß drawer used to select which destination to point a menu option to */}
            <UTMDrawer
                isOpen={utmDrawer.isOpen}
                onClose={stopEditingKey}
                defaultValue={editingKey?.data?.userID}
                onSubmit={data => {
                    if (!editingKey) return
                    updateKey(editingKey.index, data as User)
                    utmDrawer.onClose()
                }}
                tabs={[AvailableTabs.USERS]}
            />
        </VStack>
    )
}

function getAvailableKeys(
    extensions: IVRMenu['IVR']['extensions'] = {},
    currentKey?: string
): string[] {
    const ASSIGNABLE_KEYS = buildIVROptionsArray(MIN_KEY, MAX_KEY)
    const usedKeyPositions = Object.keys(extensions)
    return ASSIGNABLE_KEYS.filter(
        key => !usedKeyPositions.includes(key.toString()) || key === currentKey
    )
}
