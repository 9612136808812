import { Divider, Flex, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { selectInData } from '@missionlabs/api'
import {
    Body,
    Button,
    Dropdown,
    FormProvider,
    Input,
    SelectOption,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { DestinationTypes } from '@missionlabs/types'
import { titleCase } from '@missionlabs/utils'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetSipPhonesQuery } from 'shared/store'

const selectBrands = selectInData<Record<string, string[]>, SelectOption[]>(data => {
    if (!data) return
    return Object.keys(data).map(item => ({ label: titleCase(item), value: item }))
})

const selectModels = selectInData<Record<string, string[]>, SelectOption[]>(data => {
    if (!data) return
    const mapped = Object.entries(data).map(([k, v]) => ({
        [k]: v.map(item => ({ label: item, value: item }))
    }))
    return Object.assign({}, ...mapped)
})

export interface AddDeviceSipEndpointFields {
    type: DestinationTypes.sipEndpoint
    autoProvision: boolean
    metadata: {
        brand: string
        model: string
        settings: 1
    }
    name: string
    macAddress: string | undefined
}

export const defaultValues: AddDeviceSipEndpointFields = {
    type: DestinationTypes.sipEndpoint,
    autoProvision: true,
    metadata: {
        brand: '',
        model: '',
        settings: 1
    },
    name: '',
    macAddress: ''
}

export interface AddDeviceSipEndpointFormProps {
    onClose: () => void
    onSubmit: (data: AddDeviceSipEndpointFields) => Promise<void>
}

export const AddDeviceSipEndpointForm: FC<AddDeviceSipEndpointFormProps> = ({
    onClose,
    onSubmit
}) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const { brands = [], models } = useGetSipPhonesQuery(undefined, {
        selectFromResult: result => ({
            ...result,
            brands: selectBrands(result),
            models: selectModels(result)
        })
    })

    const addDeviceSipEndpointSchema = yup.object({
        metadata: yup.object({
            brand: yup.string().required(),
            model: yup.string().required()
        }),
        macAddress: yup.string().when('metadata.brand', {
            is: v => v !== 'other',
            then: () => yup.string().required().max(12)
        })
    })

    const methods = useYupForm<AddDeviceSipEndpointFields>(addDeviceSipEndpointSchema, {
        defaultValues: defaultValues
    })
    const {
        formState: { isDirty, isSubmitting },
        setValue,
        reset,
        watch
    } = methods
    const { brand, model } = watch('metadata')

    function handleChange(name: string, value: string) {
        if (name === 'metadata.brand') reset()
        setValue(name as any, value, { shouldDirty: true })
    }

    function handleSubmit(data: AddDeviceSipEndpointFields) {
        const isOther = data.metadata.brand === 'other'
        return onSubmit({
            ...data,
            autoProvision: !isOther,
            macAddress: isOther ? undefined : data.macAddress
        })
    }

    useEffect(() => {
        if (brand && model) {
            setValue('name', brand === 'other' ? model : `${titleCase(brand)} ${model}`)
        }
    }, [brand, model, setValue])

    return (
        <FormProvider {...methods}>
            <VStack
                as="form"
                h="full"
                spacing="32px"
                align="stretch"
                justify="space-between"
                onSubmit={methods.handleSubmit(handleSubmit)}
                data-testid="add-device-sip-endpoint-form"
            >
                <VStack p="24px" spacing="24px" align="inherit">
                    <Flex gap="10px 16px" wrap="wrap">
                        <ValidatedInput
                            name="metadata.brand"
                            label={t('devices.add.sipEndpoint.brand')}
                            flex={0.5}
                        >
                            <Dropdown
                                options={[...brands, { label: 'Other', value: 'other' }]}
                                value={brand}
                                placeholder={t('Select')}
                                onChange={value => handleChange('metadata.brand', value)}
                            />
                        </ValidatedInput>
                        <ValidatedInput
                            name="metadata.model"
                            label={t('devices.add.sipEndpoint.model')}
                            flex={0.5}
                        >
                            {brand !== 'other' ? (
                                <Dropdown
                                    options={models?.[brand] ?? []}
                                    value={model}
                                    placeholder={t('Select')}
                                    onChange={value => handleChange('metadata.model', value)}
                                />
                            ) : (
                                <Input placeholder="e.g. Grandstream G1000" />
                            )}
                        </ValidatedInput>
                        <Body sx={{ color: `${colorMode}.coolGrey.80` }}>
                            {t(
                                `devices.add.sipEndpoint.${brand === 'other' ? 'warning' : 'other'}`
                            )}
                        </Body>
                    </Flex>
                    {brand !== 'other' && (
                        <>
                            <Divider />
                            <ValidatedInput
                                name="macAddress"
                                label={t('devices.add.sipEndpoint.macAddress')}
                            >
                                <Input
                                    placeholder={t(
                                        'devices.add.sipEndpoint.macAddress_placeholder'
                                    )}
                                />
                            </ValidatedInput>
                        </>
                    )}
                </VStack>

                <HStack px="24px" pb="24px" spacing="16px" align="inherit">
                    <Button
                        w="full"
                        size="lg"
                        variant="secondary"
                        isDisabled={isSubmitting}
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        type="submit"
                        w="full"
                        size="lg"
                        variant="creationary"
                        isDisabled={!isDirty}
                        isLoading={isSubmitting}
                    >
                        {t('devices.add.sipEndpoint.submit')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}
