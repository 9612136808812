import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const FullLogo: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'FullLogo',
    viewBox: '0 0 157 40',
    path: (
        <g>
            <g clipPath="url(#clip0_212_60)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.174 30.305C3.6001 24.718 3.5648 15.795 9.5324 9.813C15.467 3.8662 24.2594 3.8662 29.7634 9.3831C31.3644 10.988 32.4574 12.787 33.1254 14.71L26.8574 20.983C27.1014 18.81 26.2404 16.597 24.5464 14.902C21.6504 11.998 17.1474 11.569 14.1434 14.578C11.0344 17.695 11.5714 21.959 14.3934 24.788C17.2904 27.692 21.7944 28.121 24.7974 25.112C24.8064 25.101 33.5794 16.288 33.5794 16.288C33.5814 16.298 37.8954 12.005 37.8954 12.005C36.9374 9.8638 35.5834 7.8554 33.8304 6.0969C26.0914 -1.6608 13.5444 -1.6608 5.8054 6.0969C-1.9343 13.854 -1.9343 26.4309 5.8053 34.1879C7.5628 35.9499 9.5689 37.3069 11.7104 38.2679L14.6814 35.2889C16.0484 35.6879 17.4724 35.9029 18.9264 35.9029C20.7894 35.9029 22.6154 35.5479 24.3544 34.8989L19.2744 39.9919C24.5254 40.1349 29.8224 38.2049 33.8304 34.1879C37.8324 30.1759 39.7584 24.8749 39.6214 19.6179L29.4874 29.7949C23.4744 35.8239 14.6804 35.8239 9.1744 30.3049L9.174 30.305Z"
                    fill="currentColor"
                />
                <path
                    d="M107.18 9.0723C107.18 8.5054 107.46 8.2208 108.03 8.2208H108.83C109.37 8.2208 109.68 8.5054 109.68 9.0723V22.5783C109.68 24.0503 110.32 24.2563 110.86 24.3083C111.25 24.3593 111.54 24.5673 111.54 25.0583V25.6763C111.54 26.2183 111.3 26.5553 110.66 26.5553C109.27 26.5553 107.18 26.1683 107.18 22.8873V9.0723Z"
                    fill="currentColor"
                />
                <path
                    d="M63.207 8.8146V9.8215C63.207 10.105 63.361 10.261 63.644 10.261H64.056C64.34 10.261 64.495 10.105 64.495 9.8215V8.8146C64.495 8.5308 64.34 8.3754 64.056 8.3754H63.644C63.361 8.3754 63.207 8.5308 63.207 8.8146Z"
                    fill="currentColor"
                />
                <path
                    d="M63.748 13.54C63.464 13.54 63.31 13.695 63.31 13.979V26.012C63.31 26.297 63.464 26.452 63.748 26.452H63.954C64.237 26.452 64.391 26.297 64.391 26.012V13.979C64.391 13.695 64.237 13.54 63.954 13.54H63.748Z"
                    fill="currentColor"
                />
                <path
                    d="M60.142 14.779C59.549 14.263 58.106 13.231 55.814 13.231C52.233 13.231 49.167 15.916 49.167 20.022C49.167 24.127 52.181 26.761 55.814 26.761C58.261 26.761 59.807 25.573 60.399 25.031C60.606 24.85 60.657 24.644 60.502 24.438L60.374 24.256C60.194 24.025 59.987 23.998 59.781 24.205C59.189 24.747 57.848 25.702 55.814 25.702C52.799 25.702 50.301 23.455 50.301 20.022C50.301 16.535 52.799 14.288 55.814 14.288C57.592 14.288 58.854 15.141 59.42 15.632C59.652 15.812 59.859 15.812 60.039 15.58L60.167 15.4C60.348 15.167 60.348 14.961 60.142 14.779Z"
                    fill="currentColor"
                />
                <path
                    d="M68.204 13.979C68.204 13.695 68.359 13.54 68.642 13.54H68.848C69.132 13.54 69.286 13.695 69.286 13.979V15.993C69.286 16.588 69.183 17.052 69.183 17.052H69.236C69.879 15.064 71.399 13.463 73.562 13.463C73.924 13.463 74.104 13.618 74.104 13.901V14.134C74.104 14.418 73.872 14.573 73.537 14.573C70.651 14.573 69.286 18.008 69.286 20.874V26.012C69.286 26.297 69.132 26.452 68.848 26.452H68.642C68.359 26.452 68.204 26.297 68.204 26.012V13.979Z"
                    fill="currentColor"
                />
                <path
                    d="M85.517 14.779C84.924 14.263 83.48 13.231 81.188 13.231C77.608 13.231 74.541 15.916 74.541 20.022C74.541 24.127 77.556 26.761 81.188 26.761C83.634 26.761 85.181 25.573 85.775 25.031C85.98 24.85 86.031 24.644 85.877 24.438L85.748 24.256C85.567 24.025 85.361 23.998 85.155 24.205C84.562 24.747 83.222 25.702 81.188 25.702C78.173 25.702 75.674 23.455 75.674 20.022C75.674 16.535 78.173 14.288 81.188 14.288C82.966 14.288 84.228 15.141 84.795 15.632C85.026 15.812 85.234 15.812 85.412 15.58L85.541 15.4C85.722 15.167 85.722 14.961 85.517 14.779Z"
                    fill="currentColor"
                />
                <path
                    d="M88.71 8.8146C88.71 8.5308 88.864 8.3754 89.148 8.3754H89.354C89.637 8.3754 89.792 8.5308 89.792 8.8146V23.4816C89.792 25.2886 90.668 25.4446 91.286 25.5216C91.518 25.5476 91.699 25.6246 91.699 25.9346V26.0896C91.699 26.3736 91.596 26.5546 91.235 26.5546C90.488 26.5546 88.71 26.2716 88.71 23.8426V8.8146Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M104.68 19.376C104.68 15.658 102.78 13.231 99.426 13.231C96.128 13.231 93.166 15.658 93.166 20.022C93.166 24.127 96.18 26.761 99.813 26.761C101.93 26.761 103.47 25.755 104.09 25.238C104.3 25.083 104.32 24.85 104.19 24.67L104.04 24.463C103.88 24.231 103.68 24.205 103.45 24.385C102.88 24.876 101.59 25.702 99.813 25.702C96.825 25.702 94.299 23.559 94.299 19.815H104.22C104.48 19.815 104.68 19.66 104.68 19.376ZM99.401 14.237C101.54 14.237 103.42 15.658 103.55 18.808H94.377C94.84 15.787 97.031 14.237 99.401 14.237Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M126.53 19.893C126.53 15.942 123.38 13.075 119.5 13.075C115.6 13.075 112.49 15.942 112.49 19.893C112.49 23.869 115.6 26.762 119.5 26.762C123.38 26.762 126.53 23.869 126.53 19.893ZM123.98 19.893C123.98 22.629 121.97 24.592 119.5 24.592C117.05 24.592 115.01 22.629 115.01 19.893C115.01 17.181 117.05 15.245 119.5 15.245C121.97 15.245 123.98 17.181 123.98 19.893Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M134.93 13.075C138.82 13.075 141.96 15.942 141.96 19.893C141.96 23.869 138.82 26.762 134.93 26.762C131.04 26.762 127.92 23.869 127.92 19.893C127.92 15.942 131.04 13.075 134.93 13.075ZM134.93 24.592C137.4 24.592 139.41 22.629 139.41 19.893C139.41 17.181 137.4 15.245 134.93 15.245C132.48 15.245 130.44 17.181 130.44 19.893C130.44 22.629 132.48 24.592 134.93 24.592Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M145.08 13.385C144.51 13.385 144.23 13.67 144.23 14.237V30.763C144.23 31.332 144.51 31.616 145.08 31.616H145.87C146.42 31.616 146.7 31.332 146.7 30.763V25.573C146.7 24.902 146.62 24.438 146.62 24.438H146.67C146.67 24.438 147.75 26.761 150.72 26.761C154.12 26.761 156.62 24.075 156.62 19.918C156.62 15.864 154.4 13.075 150.9 13.075C147.63 13.075 146.52 15.58 146.52 15.58H146.47C146.47 15.58 146.52 15.271 146.52 14.883V14.108C146.52 13.67 146.23 13.385 145.67 13.385H145.08ZM154.09 19.944C154.09 22.913 152.39 24.592 150.33 24.592C147.94 24.592 146.65 22.295 146.65 19.97C146.65 16.638 148.45 15.271 150.41 15.271C152.57 15.271 154.09 17.104 154.09 19.944Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_212_60">
                    <rect width="157" height="40" fill="white" />
                </clipPath>
            </defs>
        </g>
    )
})
