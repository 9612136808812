import { useDisclosure } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { AddDeviceDrawer } from './AddDevice/AddDeviceDrawer'
import { Devices } from './Devices'
import { EditDeviceDrawer } from './EditDevice/EditDeviceDrawer'

export interface DevicesDashboardProps {}

export const DevicesDashboard: FC<DevicesDashboardProps> = () => {
    const location = useLocation()
    const { isOpen: addOpen, onClose: addOnClose, onOpen: addOnOpen } = useDisclosure()
    const { isOpen: editOpen, onClose: editOnClose, onOpen: editOnOpen } = useDisclosure()

    useEffect(() => {
        const end = location.pathname.split('/').pop()
        if (!end) return

        if (['add'].includes(end)) addOnOpen()
        if (['edit'].includes(end)) editOnOpen()

        return () => {
            addOnClose()
            editOnClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        <>
            <AddDeviceDrawer isOpen={addOpen} onClose={addOnClose} />
            <EditDeviceDrawer isOpen={editOpen} onClose={editOnClose} />
            <Devices />
        </>
    )
}
