import { Address } from './client'

export type BillingSummary = {
    currencyCode: string
    estimatedBill: string
    users: {
        quantity: number
        total: string
        currencyCode: string
        totalPence: number
    }
    numbers: {
        quantity: number
        total: string
        currencyCode: string
        totalPence: number
    }
    callCosts: {
        duration: number
        total: string
        currencyCode: string
        breakdown: {}
        totalPence: number
    }
    currentTerm: {
        termStart: number
        termEnd: number
    }
    estimatedBillPence: number
}

export type CreditNote = {
    creditID: string
    status: string
    date: number
    total: number
    type: string
    currencyCode: string
    PDF?: string
}

export enum Currency {
    GBP = '£',
    EUR = '€',
    USD = '$',
    CAD = '$',
    AUD = '$',
    NZD = '$'
}

export type InvoiceLine = {
    termStart: number
    termEnd: number
    description: string
    unitAmount?: number
    quantity: number
    amount?: number
    taxAmount?: number
}

export type Invoice = {
    object: 'invoice'
    invoiceID: string
    invoiceDate: number
    total: number
    subtotal: number
    recurring: boolean
    currencyCode: keyof typeof Currency
    creditsApplied: number
    taxDescription?: string
    taxTotal?: number
    status: string
    PDF?: string
    lines: InvoiceLine[]
}

export interface Card {
    cardholder: string
    endMonth: number
    endYear: number
    lastFour: string
    type: string
    maskedNumber?: string
    name?: string
    providerID?: string
}

export type PaymentMethod = {
    object: 'paymentmethod'
    ID: string
    billingAddress?: Address
    card?: Card
    clientID: string
    created: string
    directDebit?: {
        bank_name: string
        email: string
        first_name: string
        last_name: string
        last4: string
        mandate_id: string
        name_on_account: string
        object?: string
    }
    failedAuthentication: boolean
    isDefault?: boolean
    mandateID?: string
    paymentSourceID: string
    provider: string
    providerID: string
    token?: string
    ttl?: number | null
    type: string
    updated: string
}
