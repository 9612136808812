import { ComponentStyleConfig } from '@chakra-ui/react'

export const ProgressBarStyles: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        container: { borderRadius: '4px', height: '12px' },
        filledTrack: { bg: `${colorMode}.primary.gamma` }
    }),
    variants: {
        light: ({ colorMode }) => ({
            filledTrack: { bg: `${colorMode}.primary.gamma` },
            remainingTrack: { bg: `${colorMode}.tones.periwinkle` }
        }),
        dark: ({ colorMode }) => ({
            filledTrack: { bg: `${colorMode}.primary.gamma` },
            remainingTrack: { bg: `${colorMode}.tones.periwinkle` }
        })
    }
}
