import { ActivityFeedFilterDisplay } from './components/ActivityFeedFilterDisplay'
import { ActivityFeedFilterMenu } from './components/ActivityFeedFilterMenu'
import { useActivityFilters } from './hooks/useActivityFeedFilters'

interface ActivityFeedFiltersProps {}

export const ActivityFeedFilters = (_props: ActivityFeedFiltersProps) => {
    const { filterMenu, hasAppliedFilters } = useActivityFilters()

    return (
        <>
            {filterMenu.isOpen && <ActivityFeedFilterMenu />}
            {!filterMenu.isOpen && hasAppliedFilters && <ActivityFeedFilterDisplay />}
        </>
    )
}
