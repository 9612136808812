import { Heading, VStack } from '@chakra-ui/react'
import { Checkbox, Input, TagInput, useFormContext, ValidatedInput } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { AddEditContactFormFields } from './AddEditContactForm'

export interface AddEditContactDetailsProps {}

export const AddEditContactDetails: FC<AddEditContactDetailsProps> = () => {
    const { t } = useTranslation()
    const { watch, setValue } = useFormContext<AddEditContactFormFields>()
    const tags = watch('tags')

    return (
        <VStack spacing="16px" align="inherit" data-testid="add-edit-contact-details">
            <Heading size="h3" fontSize="16px" fontWeight="500">
                {t('contacts.form.personalDetails')}
            </Heading>
            <ValidatedInput name="firstName" label={t('contacts.form.firstName')}>
                <Input />
            </ValidatedInput>
            <ValidatedInput name="lastName" label={t('contacts.form.lastName')}>
                <Input />
            </ValidatedInput>
            <ValidatedInput name="email" label={t('contacts.form.emails')}>
                <Input placeholder={t('Enter email address')} />
            </ValidatedInput>
            <ValidatedInput name="companyName" label={t('contacts.form.companyName')}>
                <Input />
            </ValidatedInput>
            <ValidatedInput name="jobTitle" label={t('contacts.form.jobTitle')}>
                <Input />
            </ValidatedInput>
            <ValidatedInput
                name="tags"
                label={t('contacts.form.tags')}
                containerSx={{ maxH: 'unset' }}
                shouldRegister={false}
            >
                <TagInput
                    tags={tags}
                    placeholder={t('Enter tag')}
                    onTagsChange={(value: string[]) =>
                        setValue('tags', value, { shouldDirty: true, shouldTouch: true })
                    }
                />
            </ValidatedInput>
            <ValidatedInput name="shared">
                <Checkbox>{t('contacts.setAsShared')}</Checkbox>
            </ValidatedInput>
        </VStack>
    )
}
