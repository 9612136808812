import { Box } from '@chakra-ui/react'
import { Button, Spinner, Table, useToast } from '@missionlabs/react'
import { SpeedDialCodeRow, SpeedDialList, UserRole } from '@missionlabs/types'
import { createColumnHelper } from '@tanstack/react-table'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useUserRole } from 'shared/hooks/useUserRole'
import {
    useUpdateSpeedDialListForClientMutation,
    useUpdateSpeedDialListForUserMutation
} from 'shared/store'

export interface SpeedDialListProps {
    speedDialLists: SpeedDialList[]
    isLoading: boolean
}

export const SpeedDialTable: FC<SpeedDialListProps> = ({ speedDialLists, isLoading }) => {
    const { t } = useTranslation()
    const { toast } = useToast()
    const userRole = useUserRole()
    const { pathname } = useLocation()
    const { formatToLocalNumber } = useFormatToNumberE164()

    const columnHelper = createColumnHelper<SpeedDialCodeRow>()

    const speedDialCodes = speedDialLists.flatMap(
        speedDialList =>
            speedDialList?.speedDialCodes?.map(speedDialCode => {
                return {
                    ...speedDialCode,
                    speedDialListID: speedDialList.ID
                }
            }) || []
    )

    const isAdmin = pathname.includes('/admin/') && userRole === UserRole.ADMIN

    const updateRequest = isAdmin
        ? useUpdateSpeedDialListForClientMutation
        : useUpdateSpeedDialListForUserMutation

    const [updateSpeedDialList, { isLoading: isUpdateLoading }] = updateRequest()

    const onRemoveUpdate = async (codeToRemove: SpeedDialCodeRow) => {
        const speedDialList = speedDialLists?.find(({ ID }) => ID === codeToRemove.speedDialListID)
        if (!speedDialList) return

        const filteredCodes =
            speedDialList?.speedDialCodes.filter(
                speedDialCode => speedDialCode.speedDialCode !== codeToRemove.speedDialCode
            ) || []

        const updateData: SpeedDialList = {
            ...speedDialList,
            ...{ speedDialCodes: filteredCodes }
        }

        try {
            await updateSpeedDialList(updateData)
        } catch (e) {
            console.log(e)
            toast({ status: 'error', title: 'Failed to remove code from speed dial list' })
        }
    }

    const renderRemoveButton = (row: SpeedDialCodeRow) => {
        return (
            <Button
                variant="secondary"
                size="sm"
                fontWeight="500"
                isLoading={isUpdateLoading}
                onClick={() => onRemoveUpdate(row)}
            >
                {t('Remove')}
            </Button>
        )
    }

    if (isLoading || !speedDialCodes) {
        return <Spinner size="sm" />
    }

    const columns = [
        columnHelper.accessor('speedDialCode', {
            cell: info => info.getValue(),
            header: t('directories.speedDial.code'),
            size: 0
        }),
        columnHelper.accessor('name', {
            cell: info => info.getValue(),
            header: t('directories.speedDial.name')
        }),
        columnHelper.accessor('replacementNumber', {
            cell: info => formatToLocalNumber(info.getValue()),
            header: t('directories.speedDial.number')
        }),
        columnHelper.display({
            cell: ({ row }) => renderRemoveButton(row.original),
            id: 'remove-speed-dial',
            size: 0
        })
    ]

    return (
        <Box w="100%">
            <Table
                data={speedDialCodes}
                columns={columns}
                paginate
                sorting={[{ id: 'speedDialCode', desc: false }]}
            />
        </Box>
    )
}
