/**
 * Updates URL search params, allowing multiple updates together.
 * Removes `undefined` values to prevent params with no value being in the URL
 * i.e. `?param1=&param2=&param3=foo`
 */
export function updateSearchParams(
    existingParams: URLSearchParams,
    params: Record<string, string | undefined>
): URLSearchParams {
    for (const [key, value] of Object.entries(params)) {
        if (value) {
            existingParams.set(key, value)
        } else {
            existingParams.delete(key)
        }
    }
    return existingParams
}
