import { guestAppBaseUrl, useSelector } from '@missionlabs/api'
import { useMemo } from 'react'

/** Given a `meetingID`, it will return the link needed for guest users to join the meeting */
export const useExternalMeetingLink = (meetingID: string = '') => {
    const baseUrl = useSelector(guestAppBaseUrl)
    const meetingLink = useMemo(() => {
        return `${baseUrl}/meeting/${meetingID}`
    }, [baseUrl, meetingID])
    return meetingLink
}
