import { updatePreferences, useDispatch } from '@missionlabs/api'
import {
    Channels,
    Client,
    Destination,
    Events,
    NotAvailable,
    SubscriptionGroup,
    User,
    UserCallRoute
} from '@missionlabs/types'
import { createContext, FC, PropsWithChildren, useCallback, useContext } from 'react'
import { useGetNotificationsData } from 'shared/hooks/useGetNotificationsData'
import { useGetUserData } from 'shared/hooks/useGetUserData'
import {
    useCreateSubscriptionGroupMutation,
    useDeleteSubscriptionGroupMutation,
    useGetClientQuery,
    usePutDestinationForUserMutation,
    useUpdateSubscriptionGroupMutation,
    useUpdateUserCallRouteMutation,
    useUpdateUserMutation
} from 'shared/store'
import { buildNotAvailable } from 'shared/utils/notAvailable'

export interface SettingsContextValues {
    client: Client | undefined
    user: User | undefined
    userCallRoute: UserCallRoute | undefined
    channels: Channels
    events: Events
    subscriptionGroups: SubscriptionGroup[]
    isLoading: boolean
    onUpdateUser: (setting: Partial<User>) => Promise<User> | undefined
    onUpdateUserCallRoute: (setting: Partial<UserCallRoute>) => Promise<UserCallRoute> | undefined
    onUpdateNotAvailable: (setting?: NotAvailable) => Promise<UserCallRoute> | undefined
    onUpdateUserDestination: (
        setting: Partial<Destination>
    ) => void | Promise<Destination> | undefined
    onCreateSubscriptionGroup: (
        subscriptionGroup: Partial<SubscriptionGroup>
    ) => void | Promise<SubscriptionGroup> | undefined
    onUpdateSubscriptionGroup: (
        subscriptionGroup: Partial<SubscriptionGroup>
    ) => void | Promise<SubscriptionGroup> | undefined
    onDeleteSubscriptionGroup: (
        subscriptionGroupID: string
    ) => void | Promise<SubscriptionGroup> | undefined
}

const defaultValues: SettingsContextValues = {
    client: undefined,
    user: undefined,
    userCallRoute: undefined,
    channels: {
        desktopApp: false,
        android: false,
        email: false,
        web: false,
        sms: false,
        ios: false
    },
    events: [],
    subscriptionGroups: [],
    isLoading: false,
    onUpdateUser: () => undefined,
    onUpdateUserCallRoute: () => undefined,
    onUpdateNotAvailable: () => undefined,
    onUpdateUserDestination: () => undefined,
    onCreateSubscriptionGroup: () => undefined,
    onUpdateSubscriptionGroup: () => undefined,
    onDeleteSubscriptionGroup: () => undefined
}

const SettingsContext = createContext<SettingsContextValues>(defaultValues)

interface SettingsProviderProps {}

export const SettingsProvider: FC<PropsWithChildren<SettingsProviderProps>> = ({ children }) => {
    const { data: client, isLoading: clientLoading } = useGetClientQuery()
    const {
        user,
        callRoute,
        __meta: {
            user: { isLoading: userLoading },
            callRoute: { isLoading: callRouteLoading }
        }
    } = useGetUserData()
    const { channels, events, subscriptionGroups, subscriptionGroupsLoading } =
        useGetNotificationsData()

    const [updateUser] = useUpdateUserMutation()
    const [updateUserCallRoute] = useUpdateUserCallRouteMutation()
    const [updateUserDestination] = usePutDestinationForUserMutation()
    const [createSubscriptionGroup, { isLoading: creatingSubscriptionGroup }] =
        useCreateSubscriptionGroupMutation()
    const [updateSubscriptionGroup, { isLoading: updatingSubscriptionGroup }] =
        useUpdateSubscriptionGroupMutation()
    const [deleteSubscriptionGroup, { isLoading: deletingSubscriptionGroup }] =
        useDeleteSubscriptionGroupMutation()
    const dispatch = useDispatch()

    const isLoading =
        clientLoading ||
        userLoading ||
        callRouteLoading ||
        subscriptionGroupsLoading ||
        creatingSubscriptionGroup ||
        updatingSubscriptionGroup ||
        deletingSubscriptionGroup

    const onUpdateUser = useCallback(
        (setting: Partial<User>) => {
            if (!user) return

            return updateUser({ ID: user.ID, ...setting }).unwrap()
        },
        [user, updateUser]
    )

    const onUpdateUserCallRoute = useCallback(
        (setting: Partial<UserCallRoute>) => {
            if (!callRoute) return

            const updatedCallRoute = {
                ID: callRoute.ID,
                userID: callRoute.userID,
                ...setting
            }

            return updateUserCallRoute(updatedCallRoute).unwrap()
        },
        [callRoute, updateUserCallRoute]
    )

    const onUpdateNotAvailable = useCallback(
        (notAvailable?: NotAvailable) => {
            if (!callRoute) return

            const updatedCallRoute = {
                ID: callRoute.ID,
                userID: callRoute.userID,
                notAvailable: buildNotAvailable({ ...callRoute.notAvailable, ...notAvailable })
            }

            return updateUserCallRoute(updatedCallRoute).unwrap()
        },
        [callRoute, updateUserCallRoute]
    )

    const onUpdateUserDestination = useCallback(
        (setting: Partial<Destination>) => {
            updateUserDestination(setting).unwrap()
            dispatch(updatePreferences(setting))
        },
        [updateUserDestination, dispatch]
    )

    const onCreateSubscriptionGroup = useCallback(
        (subscriptionGroup: Partial<SubscriptionGroup>) => {
            if (!subscriptionGroups) return

            createSubscriptionGroup(subscriptionGroup)
        },
        [createSubscriptionGroup, subscriptionGroups]
    )

    const onUpdateSubscriptionGroup = useCallback(
        (subscriptionGroup: Partial<SubscriptionGroup>) => {
            if (!subscriptionGroups) return

            updateSubscriptionGroup(subscriptionGroup)
        },
        [updateSubscriptionGroup, subscriptionGroups]
    )

    const onDeleteSubscriptionGroup = useCallback(
        (subscriptionGroupID: string) => {
            if (!subscriptionGroups) return

            deleteSubscriptionGroup({ userID: user?.ID || '', subscriptionGroupID })
        },
        [deleteSubscriptionGroup, subscriptionGroups, user?.ID]
    )

    return (
        <SettingsContext.Provider
            value={{
                client,
                user,
                userCallRoute: callRoute,
                channels,
                events,
                subscriptionGroups,
                isLoading,
                onUpdateUser,
                onUpdateUserCallRoute,
                onUpdateNotAvailable,
                onUpdateUserDestination,
                onCreateSubscriptionGroup,
                onUpdateSubscriptionGroup,
                onDeleteSubscriptionGroup
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}

export const useSettingsContext = () => {
    const context = useContext(SettingsContext)

    if (context === undefined) {
        throw new Error('useSettings must be used within a SettingsProvider')
    }

    return context
}
