import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const MicrophoneIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MicrophoneIconLight',
    viewBox: '0 0 14 20',
    path: (
        <path
            d="M12.625 7.5C12.2734 7.5 12 7.8125 12 8.125V10C12 12.8906 9.53906 15.1953 6.64844 15C3.99219 14.8438 2 12.5 2 9.84375V8.125C2 7.8125 1.6875 7.5 1.375 7.5C1.02344 7.5 0.75 7.8125 0.75 8.125V9.80469C0.75 13.0469 3.13281 15.8984 6.375 16.2109V18.75H3.875C3.52344 18.75 3.25 19.0625 3.25 19.375C3.25 19.7266 3.52344 20 3.875 20H10.125C10.4375 20 10.75 19.7266 10.75 19.375C10.75 19.0625 10.4375 18.75 10.125 18.75H7.625V16.2109C10.75 15.8984 13.25 13.2422 13.25 10V8.125C13.25 7.8125 12.9375 7.5 12.625 7.5ZM7 13.75C9.07031 13.75 10.75 12.0703 10.75 10V3.75C10.75 1.71875 9.07031 0 7 0C4.92969 0 3.25 1.67969 3.25 3.75V10C3.25 12.0703 4.92969 13.75 7 13.75ZM4.5 3.75C4.5 2.38281 5.59375 1.25 7 1.25C8.36719 1.25 9.5 2.38281 9.5 3.75V10C9.5 11.4062 8.36719 12.5 7 12.5C5.59375 12.5 4.5 11.4062 4.5 10V3.75Z"
            fill="currentColor"
        />
    )
})

export const MicrophoneIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MicrophoneIconRegular',
    viewBox: '0 0 14 20',
    path: (
        <path
            d="M12.9375 7.5C12.3906 7.5 12 7.92969 12 8.4375V10C12 12.9297 9.46094 15.2734 6.49219 15C3.875 14.7266 2 12.4219 2 9.80469V8.4375C2 7.92969 1.57031 7.5 1.0625 7.5C0.515625 7.5 0.125 7.92969 0.125 8.4375V9.6875C0.125 13.1641 2.625 16.2891 6.0625 16.7578V18.125H4.14844C3.64062 18.125 3.25 18.5547 3.25 19.0625C3.25 19.5312 3.64062 20 4.14844 20H9.77344C10.3203 20 10.75 19.6094 10.75 19.0625C10.75 18.5547 10.3203 18.125 9.8125 18.125H7.9375V16.8359C11.2578 16.3672 13.875 13.4766 13.875 10V8.4375C13.875 7.92969 13.4453 7.5 12.9375 7.5ZM7 13.75C9.07031 13.75 10.75 12.0703 10.75 10V3.75C10.75 1.71875 9.07031 0 7 0C4.92969 0 3.25 1.67969 3.25 3.75V10C3.25 12.0703 4.92969 13.75 7 13.75ZM5.125 3.75C5.125 2.73438 5.94531 1.875 7 1.875C8.01562 1.875 8.875 2.73438 8.875 3.75V10C8.875 11.0547 8.01562 11.875 7 11.875C5.94531 11.875 5.125 11.0547 5.125 10V3.75Z"
            fill="currentColor"
        />
    )
})

export const MicrophoneIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MicrophoneIconSolid',
    viewBox: '0 0 14 20',
    path: (
        <path
            d="M7 13.75C9.07031 13.75 10.75 12.1094 10.75 10V3.75C10.75 1.67969 9.07031 0 7 0C4.89062 0 3.25 1.67969 3.25 3.75V10C3.25 12.0703 4.89062 13.75 7 13.75ZM12.9375 7.5C12.3906 7.5 12 7.92969 12 8.4375V10C12 12.8906 9.57812 15.1953 6.64844 15C4.07031 14.8438 2 12.4219 2 9.80469V8.4375C2 7.92969 1.57031 7.5 1.0625 7.5C0.515625 7.5 0.125 7.92969 0.125 8.4375V9.6875C0.125 13.1641 2.58594 16.2891 6.0625 16.7578V18.125H4.5C3.75781 18.125 3.21094 18.75 3.25 19.4531C3.25 19.7656 3.52344 20 3.875 20H10.125C10.4375 20 10.7109 19.7656 10.7109 19.4531C10.75 18.75 10.2031 18.125 9.5 18.125H7.9375V16.8359C11.2578 16.3672 13.875 13.4766 13.875 10V8.4375C13.875 7.92969 13.4453 7.5 12.9375 7.5Z"
            fill="currentColor"
        />
    )
})

export const MicrophoneSlashIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MicrophoneSlashIconLight',
    viewBox: '0 0 26 20',
    path: (
        <path
            d="M19.2342 10V8.125C19.2342 7.8125 18.9217 7.5 18.6483 7.5C18.2967 7.5 18.0233 7.8125 18.0233 8.125V10C18.0233 10.2734 17.9842 10.5078 17.9842 10.7031L19.078 11.5625C19.1561 11.0938 19.2342 10.5469 19.2342 10ZM10.4842 3.75C10.4842 2.38281 11.578 1.25 12.9452 1.25C14.2733 1.25 15.4842 2.38281 15.4842 3.75V8.78906L16.6952 9.76562L16.7342 3.75C16.7342 1.67969 15.0545 0 13.0233 0C10.953 0 9.23424 1.67969 9.23424 3.75V3.86719L10.4452 4.84375V3.75H10.4842ZM25.2499 18.9062L1.49986 0.15625C1.22642 -0.078125 0.835799 0 0.640487 0.273438C0.406112 0.507812 0.484237 0.9375 0.757674 1.13281L24.5077 19.8828C24.6249 19.9609 24.742 20 24.8592 20C25.0155 20 25.2108 19.9219 25.328 19.7656C25.6014 19.4922 25.5233 19.1016 25.2499 18.9062ZM16.1092 18.75H13.6483V16.2109C14.4686 16.1328 15.2499 15.8984 15.953 15.5078L14.8592 14.6484C14.1561 14.9219 13.453 15.0781 12.6717 15C10.0155 14.8438 7.98424 12.5 7.98424 9.84375V9.25781L6.73424 8.28125V9.80469C6.73424 13.0469 9.11705 15.8984 12.3202 16.2109V18.75H9.85924C9.50767 18.75 9.23424 19.0625 9.23424 19.375C9.23424 19.7266 9.54674 20 9.85924 20H16.0702C16.4217 20 16.6561 19.7266 16.6561 19.4141C16.7342 19.0625 16.4608 18.75 16.1092 18.75Z"
            fill="currentColor"
        />
    )
})

export const MicrophoneSlashIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MicrophoneSlashIconRegular',
    viewBox: '0 0 26 20',
    path: (
        <path
            d="M25.1289 18.3594L18.9179 13.4766C19.5039 12.4609 19.8945 11.2891 19.8945 10V8.4375C19.8945 7.92969 19.4648 7.5 18.957 7.5C18.4882 7.5 18.0195 7.92969 18.0195 8.4375V10C18.0195 10.8203 17.8242 11.5625 17.4726 12.2656L16.457 11.4844C16.6523 11.0156 16.7695 10.5078 16.7695 10L16.7304 3.75C16.7304 1.67969 15.0898 0 13.0195 0C10.9492 0 9.23044 1.67969 9.23044 3.75V5.89844L2.00387 0.234375C1.61325 -0.117188 1.02731 -0.0390625 0.714811 0.390625C0.363249 0.78125 0.441374 1.36719 0.871061 1.67969L23.957 19.7656C24.1523 19.9609 24.3476 20 24.5429 20C24.8164 20 25.0898 19.8828 25.2851 19.6484C25.6367 19.2578 25.5586 18.6719 25.1289 18.3594ZM14.8945 10C14.8945 10.1172 14.8554 10.1953 14.8554 10.2734L11.1445 7.34375V3.75C11.1445 2.73438 11.9648 1.875 13.0195 1.875C14.0351 1.875 14.8945 2.73438 14.8945 3.75V10ZM15.832 18.125H13.957V16.8359C14.7382 16.7188 15.5195 16.4453 16.2226 16.0938L14.5429 14.7656C13.8789 14.9609 13.2148 15.0781 12.5117 15C9.8945 14.7266 8.0195 12.4219 8.0195 9.80469V9.64844L6.18356 8.20312C6.1445 8.28125 6.1445 8.35938 6.1445 8.4375L6.10544 9.72656C6.10544 13.2031 8.56637 16.3281 12.0039 16.7969V18.125H10.207C9.66012 18.125 9.2695 18.5547 9.2695 19.0625C9.2695 19.6094 9.66012 20 10.207 20H15.832C16.3398 20 16.7695 19.6094 16.7695 19.0625C16.7695 18.5547 16.3398 18.125 15.832 18.125Z"
            fill="currentColor"
        />
    )
})

export const MicrophoneSlashIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MicrophoneSlashIconSolid',
    viewBox: '0 0 26 20',
    path: (
        <path
            d="M15.4841 18.125H13.9607V16.8359C14.7419 16.7188 15.4841 16.4453 16.1872 16.0938L14.5075 14.7656C13.9216 14.9609 13.3357 15.0391 12.6716 15C10.0935 14.8438 7.9841 12.4219 7.9841 9.80469V9.6875L6.1091 8.20312V9.72656C6.1091 13.2031 8.6091 16.3281 12.0075 16.7969V18.125H10.445C9.74191 18.125 9.2341 18.7109 9.2341 19.375C9.2341 19.7266 9.5466 20 9.8591 20H16.07C16.4216 20 16.656 19.7266 16.656 19.4141C16.7341 18.7109 16.1872 18.125 15.4841 18.125ZM25.1325 18.3594L18.9216 13.4766C19.5075 12.4609 19.8982 11.2891 19.8982 10V8.4375C19.8982 7.92969 19.4685 7.5 18.9607 7.5C18.4528 7.5 18.0232 7.92969 18.0232 8.4375V10C18.0232 10.8203 17.8278 11.5625 17.4763 12.2656L16.4607 11.4844C16.656 11.0156 16.7732 10.5078 16.7732 10V3.90625C16.7732 1.875 15.2107 0.078125 13.1403 0.0390625C11.031 -0.0390625 9.27316 1.64062 9.27316 3.75V5.89844L2.00754 0.234375C1.85129 0.078125 1.65597 0 1.46066 0C1.14816 0 0.874723 0.15625 0.718473 0.390625C0.366911 0.78125 0.445036 1.36719 0.874723 1.67969L23.9607 19.7656C24.3513 20.1172 24.9372 20.0391 25.2497 19.6094C25.6403 19.2578 25.5622 18.6719 25.1325 18.3594Z"
            fill="currentColor"
        />
    )
})
