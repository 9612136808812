import 'shared/components/Lexical/theme/index.css'

import { Scrollable } from '@missionlabs/react'
import { ChatMessage, UnreadThreads } from '@missionlabs/types'
import { useVirtualizer } from '@tanstack/react-virtual'
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { FC, useRef } from 'react'
import { Section } from 'shared/hooks/useSectionTitle'

import { ChatHistoryUnreadThreadsRenderer } from './ChatHistoryUnreadThreadsRenderer'

export type VirtualisedChatHistoryItem = (ChatMessage & { section: Section }) | Section
export interface ChatHistoryUnreadThreadsProps {
    data: UnreadThreads
    siblingHeight: number
}

export const ChatHistoryUnreadThreads: FC<ChatHistoryUnreadThreadsProps> = ({
    data,
    siblingHeight
}) => {
    const ref = useRef<OverlayScrollbarsComponentRef>(null)

    // This is debounced because if the message is in view we empty the newMessages array on the next render, so this prevents the notification flickering in the meantime.
    function getScrollElement() {
        const instance = ref.current?.osInstance()
        return instance?.elements().viewport ?? null
    }

    const virtualiser = useVirtualizer({
        count: data.threads.length,
        overscan: 10,
        getScrollElement,
        estimateSize: () => 53
    })
    const items = virtualiser.getVirtualItems()
    return (
        <Scrollable
            ref={ref}
            h={`calc(100dvh - ${siblingHeight}px)`}
            w="full"
            mb="8px"
            events={{ initialized: virtualiser.measure }}
            options={{ overflow: { x: 'hidden' } }}
            className="chat-history"
        >
            <div
                style={{
                    position: 'relative',
                    height: virtualiser.getTotalSize(),
                    width: '100%'
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        transform: `translateY(${items[0].start}px)`
                    }}
                >
                    {items.map(virtualRow => (
                        <ChatHistoryUnreadThreadsRenderer
                            key={virtualRow.key}
                            ref={virtualiser.measureElement}
                            data={data.threads}
                            unreadCount={data.threads.length}
                            index={virtualRow.index}
                        />
                    ))}
                </div>
            </div>
        </Scrollable>
    )
}
