import { Box, Button, Collapse, HStack, useDisclosure } from '@chakra-ui/react'
import { selectAuthenticatedUser, selectInData, useSelector } from '@missionlabs/api'
import { Body, Dropdown, SelectOption, Toggle } from '@missionlabs/react'
import { AudioClip, UserCallRoute } from '@missionlabs/types'
import { readFile } from '@missionlabs/utils'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCreateUserAudioClipMutation, useGetUserAudioClipsQuery } from 'shared/store'

import { RecordingDrawer } from '../../components/RecordingDrawer'
import { useSettingsContext } from '../../components/settingsContext'

const selectAudioClips = selectInData<AudioClip[], SelectOption[]>(data =>
    data?.map(clip => ({ label: clip.label, value: clip.URL }))
)

export const HoldMusicSetting: FC = () => {
    const { t } = useTranslation()
    const recordingDrawer = useDisclosure()
    const [createUserAudioClip, { isLoading }] = useCreateUserAudioClipMutation()

    const user = useSelector(selectAuthenticatedUser)

    const { userCallRoute: callRoute, onUpdateUserCallRoute } = useSettingsContext()

    const { audioClips = [] } = useGetUserAudioClipsQuery(user?.userID ?? '', {
        skip: !user,
        selectFromResult: result => ({ ...result, audioClips: selectAudioClips(result) })
    })

    const audioClipsWithDefault = useMemo(() => {
        return [{ label: t('Default'), value: '' }, ...audioClips]
    }, [audioClips, t])

    const onUpdate = (payload: Partial<UserCallRoute>) => {
        if (!callRoute) return
        return onUpdateUserCallRoute?.(payload)
    }

    const onSaveAudio = async (file: File | null) => {
        if (!file) return
        try {
            const dataURL = await readFile(file)
            if (!user || !dataURL) return

            const response = await createUserAudioClip({
                userID: user.userID,
                data: dataURL,
                label: file?.name
            }).unwrap()

            const url = response.URL
            if (url) {
                onUpdate({ customHoldMusicURL: url, holdMusic: true })
            }
        } catch (error) {
            console.error('components: Could not upload file', error)
        }
    }

    return (
        <>
            <SettingsRow label={t('calls.holdMusic')}>
                <HStack w="full" justify="space-between" align="flex-start">
                    <Body size="sm" variant="bold">
                        {t('calls.setMusicToPlay')}
                    </Body>
                    <Toggle
                        isChecked={callRoute?.holdMusic}
                        onChange={e => onUpdate({ holdMusic: e.target.checked })}
                    />
                </HStack>
                <div />
                <Box as={Collapse} in={callRoute?.holdMusic}>
                    <HStack spacing="16px">
                        <Dropdown
                            width="274px"
                            value={callRoute?.customHoldMusicURL ?? ''}
                            options={audioClipsWithDefault}
                            onChange={(value: string) =>
                                onUpdate({ holdMusic: true, customHoldMusicURL: value })
                            }
                        />
                        <Button variant="creationary" onClick={recordingDrawer.onOpen}>
                            {t('calls.uploadNew')}
                        </Button>
                    </HStack>
                </Box>
            </SettingsRow>
            <RecordingDrawer
                isLoading={isLoading}
                onSubmit={onSaveAudio}
                isOpen={recordingDrawer.isOpen}
                onClose={recordingDrawer.onClose}
            />
        </>
    )
}
