import { Divider, GridItem } from '@chakra-ui/react'
import { FC } from 'react'

export const ScheduleSeparatorRow: FC = () => {
    return (
        <GridItem colSpan={5}>
            <Divider />
        </GridItem>
    )
}
