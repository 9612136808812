import {
    acceptMeetingRequest,
    createMeeting,
    joinMeeting,
    requestToPullMeeting,
    selectActiveMeeting,
    selectLiveServicesStatus,
    selectMeetingError,
    selectPreferences,
    stopMeeting,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { MeetingProvider, useAttendeeIDToName, useGetMeetingPayload } from '@missionlabs/meetings'
import { useTheme } from '@missionlabs/react'
import { FC, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { DevicesContextProvider } from 'shared/components/DevicesContext'

import { useGuestAuthentication } from './hooks/useGuestAuthentication'

export interface MeetingCallRootContext {
    meeting: ReturnType<typeof selectActiveMeeting>
    error: ReturnType<typeof selectMeetingError>
    isGuest: boolean
    setupComplete: boolean
    setSetupComplete: (value: boolean) => void
}

export interface MeetingsCallRootProps {
    isGuest?: boolean
}

// todo: use route params to fetch meeting
export const MeetingsCallRoot: FC<MeetingsCallRootProps> = ({ isGuest = false }) => {
    const dispatch = useDispatch()
    const { theme } = useTheme()

    const meeting = useSelector(selectActiveMeeting)
    const error = useSelector(selectMeetingError)
    const status = useSelector(selectLiveServicesStatus)
    const preferences = useSelector(selectPreferences)
    const attendeeIdToName = useAttendeeIDToName(meeting?.otherParticipants)

    const [setupComplete, setSetupComplete] = useState<boolean>(false)

    const {
        payload,
        clearPayload,
        isCreateMeetingPayload,
        isAcceptMeetingPayload,
        isJoinMeetingPayload,
        isRequestToPullMeetingPayload
    } = useGetMeetingPayload()

    useGuestAuthentication(isGuest)

    useEffect(() => {
        if (status !== 'CONNECTED' || !payload || meeting?.answered) return

        if (isCreateMeetingPayload(payload)) {
            const { otherParticipants, sources } = payload
            if (!otherParticipants.length) return
            // @ts-ignore
            dispatch(createMeeting({ otherParticipants, caller: { sources } }))
            clearPayload()
        }

        if (isAcceptMeetingPayload(payload)) {
            if (!payload.meetingID) return
            dispatch(acceptMeetingRequest(payload))
            clearPayload()
        }

        if (isJoinMeetingPayload(payload)) {
            if (!payload.meetingID) return
            dispatch(joinMeeting(payload))
            clearPayload()
        }

        if (isRequestToPullMeetingPayload(payload)) {
            if (!payload.meetingID) return
            dispatch(requestToPullMeeting(payload))
            clearPayload()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    function handleVideoCallPullComplete(meetingID: string) {
        dispatch(stopMeeting({ meetingID, error: 'Video call pull complete' }))
    }

    return (
        <MeetingProvider
            appTheme={theme}
            attendeeIdToName={attendeeIdToName}
            videoBackgroundFilter={preferences?.videoBackgroundFilter ?? 'none'}
            onVideoCallPullComplete={handleVideoCallPullComplete}
        >
            <DevicesContextProvider isPreview useChime>
                <Outlet
                    context={
                        {
                            meeting,
                            error,
                            isGuest,
                            setupComplete,
                            setSetupComplete
                        } as MeetingCallRootContext
                    }
                />
            </DevicesContextProvider>
        </MeetingProvider>
    )
}
