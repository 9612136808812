import { DirectoryEntry, HuntGroup, IVRMenu } from '@missionlabs/types'
import { categorisePhoneNumbers, isTeamOrMenu } from '@missionlabs/utils'
import { useMemo } from 'react'

export const useGetUserNumbers = (contact: DirectoryEntry | HuntGroup | IVRMenu) => {
    const { mobile, home, work, other, extension } = useMemo(() => {
        if (isTeamOrMenu(contact)) {
            return {
                ...categorisePhoneNumbers(
                    contact.phoneNumbers.map(number => ({ numberE164: number }))
                ),
                extension: { number: contact.extension, ID: contact.ID }
            }
        }

        return {
            ...categorisePhoneNumbers(contact.phoneNumbers),
            extension: { number: contact.extension, ID: contact.externalID }
        }
    }, [contact])

    const hasNumbers = useMemo(() => {
        return mobile.length || home.length || work.length || other.length || extension.ID
    }, [extension, home.length, mobile.length, other.length, work.length])

    return { hasNumbers, mobile, home, work, other, extension }
}
