import { HStack } from '@chakra-ui/react'
import { Tag, TagProps } from 'atoms/Tag'

export type TagWithID = {
    id: string
    label?: string
    value: string
}

type TagListProps = {
    tags: TagWithID[]
    tagProps?: Partial<TagProps>
    onClearFilter?: (filter: TagWithID) => void
}

export const TagList = ({ tags = [], onClearFilter, tagProps }: TagListProps) => {
    const handleOnClose = (tag: TagWithID) => {
        onClearFilter?.(tag)
    }

    return (
        <HStack spacing={0} as="ul" listStyleType="none" flexWrap="wrap" gap="8px">
            {tags.map(tag => (
                <li key={tag.id}>
                    <Tag
                        {...tagProps}
                        onClose={() => handleOnClose(tag)}
                        label={tag.label ?? tag.value}
                    />
                </li>
            ))}
        </HStack>
    )
}
