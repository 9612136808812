import { yup } from '@missionlabs/react'

export const notAvailableDaySchema = () => {
    return yup.object({
        from: yup.string(),
        to: yup.string()
    })
}

export const notAvailableSchema = () =>
    yup.object({
        enabled: yup.boolean().required(),
        monday: notAvailableDaySchema().nullable().default(undefined),
        tuesday: notAvailableDaySchema().nullable().default(undefined),
        wednesday: notAvailableDaySchema().nullable().default(undefined),
        thursday: notAvailableDaySchema().nullable().default(undefined),
        friday: notAvailableDaySchema().nullable().default(undefined),
        saturday: notAvailableDaySchema().nullable().default(undefined),
        sunday: notAvailableDaySchema().nullable().default(undefined)
    })
