import * as fs from 'fs'

import { createTemplate, format } from '../format'
import Transport, { Log, TransportConfig, Transports } from './transport'

interface FileTransportConfig extends TransportConfig {
    path: string
}

const defaultConfig: Partial<FileTransportConfig> = {
    template: createTemplate(
        format.date('DD/MM/YYYY HH:mm:ss'),
        format.level(false),
        format.message(),
        format.newLine()
    ),
    level: 'info'
}

class FileTransport extends Transport<FileTransportConfig> {
    public readonly name = Transports.file
    private fileStream: fs.WriteStream

    constructor(config = {} as FileTransportConfig) {
        super({ ...defaultConfig, ...config })
        this.fileStream = fs.createWriteStream(config.path)
    }

    public static find(x: any): x is InstanceType<typeof FileTransport> {
        return x.name === Transports.file
    }

    public log({ message }: Log): string {
        this.fileStream.write(`${message}\n`)
        return message
    }
}

export default FileTransport
