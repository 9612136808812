import { yup } from '@missionlabs/react'
import {
    IVRExtensions,
    IVRMenuCallRoute,
    NotAvailable,
    OutOfHoursRedirectInternal,
    Redirect,
    Voicemail
} from '@missionlabs/types'
import { notAvailableSchema } from 'shared/schemas/notAvailable'
import {
    outOfHoursRedirectInternalSchema,
    outOfHoursRedirectSchema,
    outOfHoursVoicemailSchema
} from 'shared/schemas/outOfHours'

export interface MenuFormFields {
    name: string
    IVR: {
        greetingEnabled?: boolean
        greeting: string
        extensions?: {
            [key: string]: IVRExtensions
        }
    }
    waitMusic?: boolean
    customWaitMusicURL?: string
    notAvailable?: NotAvailable
    callTimeout?: number
    callRoute: {
        outOfHours: IVRMenuCallRoute['outOfHours']
        teamMembers: IVRMenuCallRoute['teamMembers']
    }
    outOfHoursRedirectInternal?: OutOfHoursRedirectInternal
    outOfHoursRedirect?: Redirect
    outOfHoursVoicemail?: Voicemail
    blockAnonymousCalls: boolean
}

export const defaultMenuValues: MenuFormFields = {
    name: '',
    waitMusic: false,
    customWaitMusicURL: undefined,
    IVR: {
        greetingEnabled: true, // Should always be enabled for menus
        greeting: '',
        extensions: {}
    },
    notAvailable: {
        enabled: false
    },
    callTimeout: 10,
    callRoute: {
        outOfHours: 'voicemail',
        teamMembers: []
    },
    outOfHoursRedirectInternal: undefined,
    outOfHoursRedirect: undefined,
    outOfHoursVoicemail: undefined,
    blockAnonymousCalls: false
}

const needsUserTeamOrMenu = [
    'at-least-one',
    // TODO: Translate
    'At least one of userID, teamID, or menuID should be specified',
    value => {
        if (!value) return true
        const { userID, teamID, menuID } = value
        return Boolean(userID || teamID || menuID)
    }
] as const

export const extensionSchema = () => {
    return (
        yup
            .object({
                display: yup.string().required('Extension display is required'),
                prompt: yup.boolean(),
                userID: yup.string(),
                teamID: yup.string(),
                menuID: yup.string()
            })
            .nullable()
            // .default(undefined) allows us to have the schema itself as `nullable`
            // but have the `.required()` fields inside of it be actually required when the object exists
            // see: https://github.com/jquense/yup#object-schema-defaults
            .default(undefined)
            .test(...needsUserTeamOrMenu)
    )
}

export const menuSchema = yup.object({
    name: yup.string().required('Name is required'),
    waitMusic: yup.boolean().default(false),
    callRoute: yup.object({
        outOfHours: yup
            .string()
            .oneOf(['voicemail', 'voicemail_no_message', 'redirect', 'redirect_internal'])
            .default('voicemail')
    }),
    customWaitMusicURL: yup.string().default(undefined),
    IVR: yup
        .object({
            greetingEnabled: yup.boolean().default(false),
            greeting: yup.string().default(''),
            extensions: yup.object({
                // Ideally these keys would come from ASSIGNABLE_KEYS array
                '0': extensionSchema(),
                '1': extensionSchema(),
                '2': extensionSchema(),
                '3': extensionSchema(),
                '4': extensionSchema(),
                '5': extensionSchema(),
                '6': extensionSchema()
            })
        })
        .required('IVR is required'),
    notAvailable: notAvailableSchema().default(undefined),
    callTimeout: yup.number().default(10),
    outOfHoursRedirectInternal: outOfHoursRedirectInternalSchema()
        .default(undefined)
        .test(...needsUserTeamOrMenu),
    outOfHoursRedirect: outOfHoursRedirectSchema().default(undefined),
    outOfHoursVoicemail: outOfHoursVoicemailSchema().default(undefined),
    blockAnonymousCalls: yup.boolean().default(false)
})
