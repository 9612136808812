import {
    Channel,
    ChatGroup,
    ChatMessage,
    EditableChatGroup,
    NewChannel,
    NewChatMessage,
    UnreadThreads
} from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { addNewMessage, ChatRootState } from '../index'
import {
    mapChimeMessageToModel,
    mapSystemMessageToModel,
    parseChimeMessage,
    sortChannels,
    updateChannelCache,
    updateDraft,
    updateEmojiReacts
} from '../utils/chat'
import { customCreateApi } from './customCreateAPI'

export const buildChatAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'chatApi',
        tagTypes: ['Channels', 'Messages', 'ChatGroups'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getMessages: builder.query<ChatMessage[], { url: string }>({
                queryFn: () => {
                    return { data: [] }
                },
                async onCacheEntryAdded(
                    { url },
                    { cacheDataLoaded, cacheEntryRemoved, dispatch, getState, updateCachedData }
                ) {
                    const ws = new WebSocket(url)
                    try {
                        // wait for the initial query to resolve before proceeding
                        await cacheDataLoaded

                        // when data is received from the socket connection to the server,
                        // if it is a message and for the appropriate channel,
                        // update our query result with the received message
                        const listener = (event: MessageEvent) => {
                            const { content, metaData, chimeMessage } = parseChimeMessage(event)

                            const message =
                                content.type === 'USER_MESSAGE'
                                    ? mapChimeMessageToModel(content, metaData, chimeMessage)
                                    : content.type === 'SYSTEM_MESSAGE'
                                    ? mapSystemMessageToModel(content, metaData, chimeMessage)
                                    : undefined

                            const { channelID } = metaData
                            const state = getState() as unknown as ChatRootState

                            const activeChannel = state.chatSlice.activeViewChannel

                            if (content.type === 'REACTION_MESSAGE') {
                                updateCachedData(draft => {
                                    return updateEmojiReacts(
                                        draft,
                                        content.messageID,
                                        content.unicode,
                                        content.user.userID
                                    )
                                })
                                dispatch(
                                    api.util.updateQueryData(
                                        'getChannelMessages',
                                        { channelID: channelID || '' },
                                        draft => {
                                            return updateEmojiReacts(
                                                draft,
                                                content.messageID,
                                                content.unicode,
                                                content.user.userID
                                            )
                                        }
                                    )
                                )
                            }

                            if (message) {
                                // Notify the Chat UI a new message has been received.
                                if (
                                    content.type === 'USER_MESSAGE' &&
                                    content.action !== 'UPDATED'
                                ) {
                                    dispatch(addNewMessage(message))
                                }

                                updateCachedData(update => {
                                    if (!update.length) return [message]
                                    update.push(message)
                                })

                                dispatch(
                                    api.util.updateQueryData('getChannels', undefined, channels => {
                                        sortChannels(channels)
                                        if (message?.parentMessageID) return channels
                                        return updateChannelCache(
                                            channels,
                                            channelID,
                                            message,
                                            activeChannel
                                        )
                                    })
                                )
                            }

                            // If we are searching in the Chat UI, we don't want to append new messages to the list as it will not be appended to the actual end of the list (just what we have currently loaded).
                            // Instead this is handled by a button in the UI to refresh the list and scroll to the true end.
                            if (!state.chatSlice.searching) {
                                dispatch(
                                    api.util.updateQueryData(
                                        'getChannelMessages',
                                        { channelID: channelID || '' },
                                        draft => {
                                            if (message) return updateDraft(draft, message, content)
                                            return draft
                                        }
                                    )
                                )
                            }

                            if (message?.parentMessageID) {
                                dispatch(
                                    api.util.updateQueryData(
                                        'getMessageThread',
                                        message.parentMessageID,
                                        draft => {
                                            if (!draft) return [message]
                                            draft.push(message)
                                        }
                                    )
                                )
                                dispatch(
                                    api.util.updateQueryData(
                                        'getUnreadThreads',
                                        undefined,
                                        draft => {
                                            //IF chat is open return

                                            if (
                                                message.parentMessageID ===
                                                state.chatSlice.threadIsOpen
                                            )
                                                return draft

                                            if (!draft) draft = { unreadCount: 1, threads: [] }
                                            draft.unreadCount += 1
                                            const thread = draft.threads.find(
                                                thread =>
                                                    thread.parentMessageID ===
                                                    message?.parentMessageID
                                            )
                                            if (thread) {
                                                thread.messages.unshift(message)
                                            } else {
                                                draft.threads.push({
                                                    channel: message.channel!,
                                                    channelID: message.channelID,
                                                    parentMessageID: message.parentMessageID!,
                                                    messages: [message]
                                                })
                                            }
                                            return draft
                                        }
                                    )
                                )
                            }
                        }

                        ws.addEventListener('message', listener)
                    } catch {
                        // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                        // in which case `cacheDataLoaded` will throw
                    }
                    // cacheEntryRemoved will resolve when the cache subscription is no longer active
                    await cacheEntryRemoved
                    // perform cleanup steps once the `cacheEntryRemoved` promise resolves
                    ws.close()
                }
            }),
            getChannels: builder.query<Channel[], string | void>({
                query: searchValue =>
                    searchValue ? `chat/channels?search=${searchValue}` : 'chat/channels',
                transformResponse: (response: { data: Channel[] }) => {
                    sortChannels(response.data)
                    return response.data
                },
                providesTags: result => {
                    return result
                        ? [
                              ...result.map(channel => ({
                                  type: 'Channels' as const,
                                  id: channel.ID
                              })),
                              'Channels'
                          ]
                        : ['Channels']
                }
            }),
            getSearchChannels: builder.mutation<Channel[], string>({
                query: searchValue => `chat/channels?search=${searchValue}`,
                transformResponse: (response: { data: Channel[] }) => {
                    sortChannels(response.data)
                    return response.data
                }
            }),
            getChannel: builder.query<Channel, Channel['ID']>({
                query: channelID => `chat/channels/${channelID}`,
                providesTags: (result, error, channelID) => [{ type: 'Channels', id: channelID }]
            }),
            getChannelByID: builder.mutation<Channel, Channel['ID']>({
                query: channelID => `chat/channels/${channelID}`
            }),
            getMessage: builder.mutation<ChatMessage, ChatMessage['ID']>({
                query: messageID => `chat/message/${messageID}`
            }),
            searchChannelMessages: builder.query<
                ChatMessage[],
                {
                    channelID: string
                    search: string
                }
            >({
                query: ({ channelID, search }) => {
                    return {
                        url: `chat/channels/${channelID}/messages`,
                        params: { search }
                    }
                },
                transformResponse: (response: { messages: ChatMessage[] }) => response.messages
            }),
            getChannelMessages: builder.query<
                ChatMessage[],
                {
                    channelID: Channel['ID']
                    startFrom?: string
                    direction?: 'next' | 'prev'
                    sort?: 'asc' | 'desc'
                    size?: number
                    reverse?: boolean
                    includeFirstMessage?: boolean
                    clearCache?: boolean
                    search?: string
                    offset?: number
                }
            >({
                query: ({
                    channelID,
                    startFrom,
                    sort,
                    reverse,
                    includeFirstMessage,
                    size,
                    search,
                    offset
                }) => {
                    return {
                        url: `chat/channels/${channelID}/messages`,
                        params: {
                            startFrom,
                            sort,
                            reverse,
                            includeFirstMessage,
                            size,
                            search,
                            offset
                        }
                    }
                },
                transformResponse: (response: { messages: ChatMessage[] }) => response.messages,
                providesTags: result => {
                    return result
                        ? [
                              ...result.map(message => ({
                                  type: 'Messages' as const,
                                  id: message.ID
                              })),
                              'Messages'
                          ]
                        : ['Messages']
                },
                serializeQueryArgs: ({ endpointName, queryArgs }) => {
                    return `${endpointName}/${queryArgs.channelID}`
                },
                merge: (currentCache, newData = [], { arg }) => {
                    const { direction, clearCache } = arg
                    if (clearCache) return (currentCache = newData)
                    const newItems = newData.filter(m => !currentCache.find(i => i.ID === m.ID))

                    if (direction === 'next') currentCache.push(...newItems)
                    if (direction === 'prev') currentCache.unshift(...newItems)
                },
                // Refetch when the page arg changes
                forceRefetch({ currentArg, previousArg }) {
                    return currentArg !== previousArg
                }
            }),
            getMessageThread: builder.query<ChatMessage[], Channel['ID']>({
                query: messageID => `chat/message/${messageID}/thread`,
                providesTags: result => {
                    return result
                        ? [
                              ...result.map(message => ({
                                  type: 'Messages' as const,
                                  id: message.ID
                              })),
                              'Messages'
                          ]
                        : ['Messages']
                }
            }),
            getUnreadThreads: builder.query<UnreadThreads, void>({
                query: () => ({
                    url: 'chat/threads',
                    method: 'GET'
                })
            }),
            getSearchMessages: builder.mutation<
                ChatMessage[],
                { searchTerm: string; size?: number }
            >({
                query: ({ searchTerm, size }) =>
                    `chat/messages/search?searchTerm=${searchTerm}&size=${size}`
            }),
            getSession: builder.query<{ url: string }, void>({
                query: () => `chat/session`
            }),
            createChannel: builder.mutation<Channel, NewChannel>({
                query: body => ({
                    url: `chat/channels`,
                    method: 'POST',
                    body: body
                }),
                invalidatesTags: ['Channels']
            }),
            createChannelMessage: builder.mutation<
                ChatMessage,
                { ID: string; entry: NewChatMessage }
            >({
                query: ({ ID, entry }) => ({
                    url: `chat/channels/${ID}/messages`,
                    method: 'POST',
                    body: entry
                }),
                invalidatesTags: []
            }),
            editChannelMessage: builder.mutation<
                ChatMessage,
                {
                    channelID: string
                    messageID: string
                    editedMessage: { contentPlainText: string; contentHTML: string }
                }
            >({
                query: ({ channelID, messageID, editedMessage }) => ({
                    url: `chat/channels/${channelID}/messages/${messageID}`,
                    method: 'PUT',
                    body: editedMessage
                })
            }),
            pinChannel: builder.mutation<string, string>({
                query: channelID => ({
                    url: `chat/channels/${channelID}/pin`,
                    method: 'PUT',
                    body: {}
                }),
                onCacheEntryAdded(arg, { dispatch }) {
                    dispatch(
                        api.util.updateQueryData('getChannels', undefined, channels => {
                            const channel = channels.find(channel => channel.ID === arg)
                            if (channel) channel.isPinned = !channel.isPinned

                            return channels
                        })
                    )
                    dispatch(
                        api.util.updateQueryData('getChannel', arg, channel => {
                            channel.isPinned = !channel.isPinned
                            return channel
                        })
                    )
                }
            }),
            markUnread: builder.mutation<
                { unreadCount: number },
                { timestamp: number; channelID: string }
            >({
                query: ({ channelID, timestamp }) => ({
                    url: `chat/channels/${channelID}/unread`,
                    method: 'PUT',
                    body: { timestamp }
                }),
                onCacheEntryAdded({ channelID }, { dispatch, cacheDataLoaded }) {
                    cacheDataLoaded.then(res => {
                        const { unreadCount } = res.data
                        dispatch(
                            api.util.updateQueryData('getChannel', channelID, channel => {
                                channel.unreadCount = unreadCount
                                return channel
                            })
                        )
                        dispatch(
                            api.util.updateQueryData('getChannels', undefined, channels => {
                                const channel = channels.find(channel => channel.ID === channelID)
                                if (!channel) return channels
                                channel.unreadCount = unreadCount
                            })
                        )
                    })
                }
            }),
            removeUserFromChannel: builder.mutation<Channel, string>({
                query: channelID => ({
                    url: `chat/channels/${channelID}/close`,
                    method: 'PUT',
                    body: {}
                }),
                onCacheEntryAdded(arg, { dispatch }) {
                    dispatch(
                        api.util.updateQueryData('getChannels', undefined, channels =>
                            channels.filter(channel => channel.ID !== arg)
                        )
                    )
                }
            }),
            sendEmoji: builder.mutation<
                void,
                { messageID: string; unicode: string; channelID: string; userID: string }
            >({
                query: ({ messageID, unicode }) => ({
                    url: `chat/messages/${messageID}/react`,
                    method: 'POST',
                    body: { unicode }
                })
            }),
            getChatGroups: builder.query<ChatGroup[], void>({
                query: () => 'chat/groups',
                transformResponse: (response: { groups: ChatGroup[] }) => response.groups,
                providesTags: result => {
                    return result
                        ? [
                              ...result.map(({ ID }) => ({
                                  type: 'ChatGroups' as const,
                                  id: ID
                              })),
                              'ChatGroups'
                          ]
                        : ['ChatGroups']
                }
            }),
            getChatGroup: builder.query<ChatGroup, string>({
                query: (groupID: string) => `chat/groups/${groupID}`,
                providesTags: result => [{ type: 'ChatGroups' as const, id: result?.ID }]
            }),
            createChatGroup: builder.mutation<ChatGroup, EditableChatGroup>({
                query: body => ({ url: 'chat/groups', method: 'POST', body }),
                invalidatesTags: ['ChatGroups']
            }),
            editChatGroup: builder.mutation<ChatGroup, EditableChatGroup & { groupID: string }>({
                query: ({ groupID, ...body }) => ({
                    url: `chat/groups/${groupID}`,
                    method: 'PUT',
                    body
                }),
                invalidatesTags: ['ChatGroups'],
                onCacheEntryAdded(arg, { dispatch, getCacheEntry }) {
                    dispatch(
                        api.util.updateQueryData('getChannels', undefined, channels => {
                            channels.map(channel => {
                                if (channel.group?.groupID !== arg.groupID) return channel
                                const updatedData = getCacheEntry().data
                                if (!updatedData) return channel
                                // When a chat group is edited, update the group data in the channel
                                return {
                                    ...channel,
                                    channelName: updatedData.name,
                                    group: { ...channel.group, name: updatedData.name }
                                }
                            })
                        })
                    )
                }
            }),
            leaveChatGroup: builder.mutation</* TODO */ any, { groupID: string; userID: string }>({
                query: ({ groupID, userID }) => ({
                    url: `chat/groups/${groupID}/leave`,
                    method: 'PUT',
                    body: { userID }
                }),
                invalidatesTags: ['ChatGroups'],
                onCacheEntryAdded(arg, { dispatch }) {
                    dispatch(
                        // After leaving the chat group, remove the channel from the list of channels
                        api.util.updateQueryData('getChannels', undefined, channels =>
                            channels.filter(channel => channel.group?.groupID !== arg.groupID)
                        )
                    )
                }
            }),
            deleteChatGroup: builder.mutation</* TODO */ any, { groupID: string }>({
                query: ({ groupID }) => ({ url: `chat/groups/${groupID}`, method: 'DELETE' }),
                invalidatesTags: ['ChatGroups'],
                onCacheEntryAdded(arg, { dispatch }) {
                    dispatch(
                        // After deleting a channel, remove the channel from the list of channels
                        api.util.updateQueryData('getChannels', undefined, channels =>
                            channels.filter(channel => channel.group?.groupID !== arg.groupID)
                        )
                    )
                }
            })
        })
    })

    return { api, ...api }
}
