import { HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { Activity } from '@missionlabs/types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useActivityInfo } from 'shared/hooks/useActivityInfo'

import { UserActivityWithNumberColumnDisplay as UserActivity } from './AnalyticsActivities'

interface Props {
    activity: UserActivity
}

const renderDuration = (activity: UserActivity) => {
    const callLength = activity.callLength
    if (!callLength) return

    const hours = Math.floor(callLength / 3600)
    const minutes = Math.floor((callLength % 3600) / 60)
    const seconds = callLength % 60

    const hoursStr = hours > 0 ? `${hours}h ` : ''
    const minutesStr = minutes > 0 ? `${minutes}m ` : ''
    const secondsStr = `${seconds}s`

    return hoursStr + minutesStr + secondsStr
}

const AnalyticsActivitiesCallerColumn: React.FC<Props> = ({ activity }) => {
    const { colorMode } = useColorMode()

    const { direction, team, originatingNumber, dialledNumber } = useActivityInfo(activity)

    const { t } = useTranslation()

    const description = useMemo(() => {
        if ([Activity.CALL_MISSED, Activity.INTERNAL_CALL_MISSED].includes(activity.activityType)) {
            return direction === 'outbound' ? t('from') : t('to')
        }

        return direction === 'outbound' ? t('from') : t('answeredBy')
    }, [activity, direction, t])

    return (
        <VStack gap="0" align="start">
            <Body fontWeight="500" size="sm">
                {activity.numberColumnDisplay}
            </Body>
            <HStack>
                {!!activity.numberColumnDisplay && (
                    <>
                        <Body size="xs" color={`${colorMode}.tones.stormGrey`}>
                            {description}
                        </Body>
                        <Body size="xs">
                            {`${activity.userName} - ${
                                direction === 'outbound' ? originatingNumber : dialledNumber
                            }`}
                        </Body>
                    </>
                )}
                {!!team && (
                    <Body size="xs" color={`${colorMode}.tones.stormGrey`}>
                        <span>- </span>
                        <span>{team?.replace('Via:', '')}</span>
                    </Body>
                )}
                {!!activity.callLength && (
                    <Body size="xs" color={`${colorMode}.tones.stormGrey`}>
                        <span>- </span>
                        <span>{renderDuration(activity)}</span>
                    </Body>
                )}
            </HStack>
        </VStack>
    )
}

export default AnalyticsActivitiesCallerColumn
