import { useMemo } from 'react'
import { useEffectOnce } from 'react-use'
import { useContactsSearch } from 'shared/hooks/useContactsSearch'

export const useFindContact = (originatingNumber?: string) => {
    const [searchContacts, { data: contacts = [] }] = useContactsSearch()

    useEffectOnce(() => {
        if (originatingNumber) searchContacts({ searchTerm: originatingNumber })
    })

    const contact = useMemo(() => {
        if (contacts.length === 1) {
            const foundContact = contacts[0]
            return {
                name: foundContact.fullName,
                companyName: foundContact.companyName
            }
        }
    }, [contacts])

    return { contact }
}
