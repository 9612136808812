import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PrintIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PrintIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5 8H19.75C21.2688 8 22.5 9.23122 22.5 10.75V15.75C22.5 17.2688 21.2688 18.5 19.75 18.5H18.5V13.75C18.5 13.3358 18.1642 13 17.75 13H5.75C5.33579 13 5 13.3358 5 13.75V18.5H3.75C2.23122 18.5 1 17.2688 1 15.75V10.75C1 9.23122 2.23122 8 3.75 8H5V1.75C5 1.33579 5.33579 1 5.75 1H17.75C18.1642 1 18.5 1.33579 18.5 1.75V8ZM6.5 14.5V21H17V14.5H6.5Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_161"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="1"
                y="1"
                width="22"
                height="20"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.5 8H19.75C21.2688 8 22.5 9.23122 22.5 10.75V15.75C22.5 17.2688 21.2688 18.5 19.75 18.5H18.5V13.75C18.5 13.3358 18.1642 13 17.75 13H5.75C5.33579 13 5 13.3358 5 13.75V18.5H3.75C2.23122 18.5 1 17.2688 1 15.75V10.75C1 9.23122 2.23122 8 3.75 8H5V1.75C5 1.33579 5.33579 1 5.75 1H17.75C18.1642 1 18.5 1.33579 18.5 1.75V8ZM6.5 14.5V21H17V14.5H6.5Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_161)"></g>
        </g>
    )
})
