import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { getDestinationOSName } from '@missionlabs/utils'
import isElectron from 'is-electron'
import {
    $getRoot,
    $getSelection,
    $insertNodes,
    $setSelection,
    CLEAR_EDITOR_COMMAND,
    COMMAND_PRIORITY_EDITOR,
    COMMAND_PRIORITY_HIGH,
    createCommand,
    KEY_ENTER_COMMAND,
    LexicalCommand
} from 'lexical'
import { FC, useCallback, useEffect, useLayoutEffect } from 'react'

// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/getModifierState#modifier_keys_on_gecko
function getPlatformModifier() {
    const platform = isElectron()
        ? getDestinationOSName(window.platform)
        : window.navigator.platform
    if (/Mac|iPod|iPhone|iPad/i.test(platform)) return 'Meta'
    return 'Control'
}

export const SEND_MESSAGE_COMMAND: LexicalCommand<void> = createCommand('SEND_MESSAGE_COMMAND')

export interface SendMessagePluginProps {
    onSendMessage: (message: {
        html: string
        plainText: string
        replyToMessageID?: string
        forwardedMessageID?: string
        attachments?: string[]
    }) => void
    onEditMessage?: (message: { html: string; plainText: string; messageID: string }) => void
    channelID: string
}

export const SendMessagePlugin: FC<SendMessagePluginProps> = ({
    onSendMessage,
    onEditMessage,
    channelID
}) => {
    const [editor] = useLexicalComposerContext()

    const handleSendMessage = useCallback(() => {
        //remove draft message form storage
        sessionStorage.removeItem(channelID)

        const replyToMessageID = $getRoot()
            .getChildren()
            .find(n => n.__type === 'reply')?.__ID

        const forwardedMessageID = $getRoot()
            .getChildren()
            .find(n => n.__type === 'forward')?.__ID

        const attachment = $getRoot()
            .getChildren()
            .find(n => n.__type === 'upload')?.__uploadID
        const editingMessage = $getRoot()
            .getChildren()
            .find(n => n.__type === 'edit_message')

        const html = $generateHtmlFromNodes(editor, null)

        const plainText = editor.getRootElement()?.textContent ?? ''
        const message = {
            html,
            plainText,
            replyToMessageID,
            forwardedMessageID,
            attachments: attachment ? [attachment] : undefined
        }
        if (editingMessage)
            onEditMessage?.({ html, plainText, messageID: editingMessage.__message.ID })
        else onSendMessage({ ...message })

        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
        return true
    }, [channelID, editor, onEditMessage, onSendMessage])

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                SEND_MESSAGE_COMMAND,
                handleSendMessage,
                COMMAND_PRIORITY_EDITOR
            ),
            editor.registerCommand(
                KEY_ENTER_COMMAND,
                event => {
                    // user must press platform modifier + enter in order to send a message
                    const modifier = event?.getModifierState(getPlatformModifier())
                    if (!modifier) return false
                    event?.preventDefault()
                    return handleSendMessage()
                },
                COMMAND_PRIORITY_HIGH
            )
        )
    }, [editor, handleSendMessage])

    useLayoutEffect(() => {
        const onKeyDown = () => {
            editor.update(() => {
                const html = $generateHtmlFromNodes(editor, null)
                sessionStorage.setItem(channelID, html)
            })
        }

        return editor.registerRootListener(
            (rootElement: null | HTMLElement, prevRootElement: null | HTMLElement) => {
                if (prevRootElement !== null) {
                    prevRootElement.removeEventListener('keydown', onKeyDown)
                }
                if (rootElement !== null) {
                    rootElement.addEventListener('keydown', onKeyDown)
                }
            }
        )
    }, [editor, channelID])

    useEffect(() => {
        editor.update(() => {
            let draft = sessionStorage.getItem(channelID)
            if (!draft) draft = '<p></p>'
            const currSelection = $getSelection()
            const root = $getRoot()

            // In the browser you can use the native DOMParser API to parse the HTML string.
            const parser = new DOMParser()
            const dom = parser.parseFromString(draft, 'text/html')

            // Once you have the DOM instance it's easy to generate LexicalNodes.
            const draftNode = $generateNodesFromDOM(editor, dom)

            //clear root
            root.clear()

            // Insert them at a selection.
            $insertNodes(draftNode)
            $setSelection(currSelection)
        })
    }, [channelID, editor])
    return null
}
