import { Themes } from '@missionlabs/react'
import { DeploymentProducts } from '@missionlabs/types'

export interface IAppConfig {
    downloadBucket: string
    name: string
    brand: APP_BRAND
    product: DeploymentProducts
    source: string
    theme: Themes
    termsLink: string
    privacyLink: string
}

export enum APP_BRAND {
    CIRCLELOOP = 'circleloop',
    PHONELINEPLUS = 'phonelineplus'
}

export enum APP_NAME {
    CIRCLELOOP = 'CircleLoop',
    PHONELINEPLUS = 'PhoneLine+'
}

export enum APP_SOURCE {
    CIRCLELOOP = 'circleloop',
    PHONELINEPLUS = 'phoneline+'
}

function getAppConfig(): IAppConfig {
    const DEFAULT_BRAND = APP_BRAND.CIRCLELOOP
    const brand = (import.meta.env.VITE_APP_BRAND as APP_BRAND) || DEFAULT_BRAND

    switch (brand) {
        case APP_BRAND.PHONELINEPLUS: {
            return {
                downloadBucket: 'https://desktop-phonelineplus-{{env}}.s3.eu-west-2.amazonaws.com',
                name: APP_NAME.PHONELINEPLUS,
                brand,
                product: DeploymentProducts.phonelineplus,
                source: APP_SOURCE.PHONELINEPLUS,
                theme: Themes.PHONELINEPLUS,
                termsLink: 'https://customerhelp.phonelineplus.com/end-user-license/',
                privacyLink: 'https://gammagroup.co/privacy-policy/phoneline-plus-app/'
            }
        }
        case APP_BRAND.CIRCLELOOP:
        default: {
            return {
                downloadBucket: 'https://desktop-circleloop-{{env}}.s3.eu-west-2.amazonaws.com',
                name: APP_NAME.CIRCLELOOP,
                brand,
                product: DeploymentProducts.circleloop,
                source: APP_SOURCE.CIRCLELOOP,
                theme: Themes.CIRCLELOOP,
                termsLink: 'https://www.circleloop.com/terms/',
                privacyLink: 'https://www.circleloop.com/privacy/'
            }
        }
    }
}

export const AppConfig: IAppConfig = getAppConfig()
