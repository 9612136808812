import { DirectoryEntry } from '@missionlabs/types'
import { alphabetizeContacts } from '@missionlabs/utils'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { VariableSizeList } from 'react-window'

import { ContactContext } from '../context/contactsContext'
import { useContactsSearchParams } from './useContactsSearchParams'

export const useContactsSidebarData = (searchedContacts: DirectoryEntry[]) => {
    const { hasSearchedContacts, sortBy } = useContactsSearchParams()
    const { contacts: allContacts, setItemCount, onItemsRendered } = useContext(ContactContext)

    const listRef = useRef<VariableSizeList>(null)

    const groupedContacts = useMemo(() => {
        const contacts = hasSearchedContacts ? searchedContacts : allContacts

        const alphabetized = alphabetizeContacts(contacts, {
            sortField: sortBy?.includes('surname') ? 'lastName' : 'firstName'
        })

        return Object.entries(alphabetized)
    }, [hasSearchedContacts, searchedContacts, allContacts, sortBy])

    const flatGroupedContacts = useMemo(() => {
        return groupedContacts.reduce((prev, [letter, contacts]) => {
            if (contacts.length < 1) return prev
            return [...prev, letter, ...contacts]
        }, [] as (string | DirectoryEntry)[])
    }, [groupedContacts])

    const rowHeights = useMemo(() => {
        return flatGroupedContacts.map(item => (typeof item === 'string' ? 72 : 78))
    }, [flatGroupedContacts])

    const getRowSize = useCallback((index: number) => rowHeights[index], [rowHeights])

    useEffect(() => {
        setItemCount(flatGroupedContacts.length)
    }, [flatGroupedContacts, setItemCount])

    return { listRef, grouped: flatGroupedContacts, getRowSize, onItemsRendered }
}
