import {
    Avatar as ChakraAvatar,
    AvatarProps as ChakraAvatarProps,
    Box,
    useMultiStyleConfig
} from '@chakra-ui/react'
import { UserStatus } from '@missionlabs/types'
import { AvatarGroupPlaceholderIcon } from 'atoms/Icons/zeta/AvatarGroupPlaceholderIcon'
import { AvatarPlaceholderIcon } from 'atoms/Icons/zeta/AvatarPlaceholderIcon'
import { AvatarBadge } from 'molecules/Avatar/AvatarBadge'
import { forwardRef } from 'react'

export interface HeaderAvatarProps extends ChakraAvatarProps {
    size?: 'sm' | 'md' | 'lg'
    variant?:
        | 'stormGrey'
        | 'whiteLilac'
        | 'navy'
        | 'red'
        | 'bloodOrange'
        | 'orange'
        | 'amber'
        | 'yellow'
        | 'lime'
        | 'green'
        | 'aqua'
        | 'teal'
        | 'blue'
        | 'indigo'
        | 'purple'
        | 'violet'
        | 'pink'
        | 'hotPink'
        | 'rose'
    status?: UserStatus
    isGroup?: boolean
}

export const HeaderAvatar = forwardRef<HTMLDivElement, HeaderAvatarProps>(
    ({ status, isGroup = false, size, variant, name, ...props }, ref) => {
        const styles = useMultiStyleConfig('HeaderAvatar', { size, variant })

        const AvatarIcon = () => {
            if (isGroup) return <AvatarGroupPlaceholderIcon sx={{ ...styles.icon, p: 0 }} />
            return <AvatarPlaceholderIcon sx={{ ...styles.icon }} />
        }

        return (
            <Box ref={ref} w="fit-content" position="relative">
                <Box sx={{ ...styles.container }}>
                    <ChakraAvatar
                        size={size ?? 'xlg'}
                        variant={variant}
                        name={name}
                        {...props}
                        icon={<AvatarIcon />}
                        position="absolute"
                        sx={{ ...styles.container, fontSize: '24px', color: '#fff' }}
                    />
                </Box>
                {status && (
                    <AvatarBadge
                        variant={status}
                        size={size}
                        sx={{ ...styles.status, top: '8px', right: '8px' }}
                    />
                )}
            </Box>
        )
    }
)

HeaderAvatar.displayName = 'HeaderAvatar'
