import { Input, InputProps, useMultiStyleConfig } from '@chakra-ui/react'
import { forwardRef } from 'react'

type TimePickerProps = InputProps

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>(({ ...props }, ref) => {
    const styles = useMultiStyleConfig('CustomInput')

    return (
        <Input ref={ref} type="time" errorBorderColor="red" {...props} sx={{ ...styles.input }} />
    )
})
