import { Box, Grid, useColorMode } from '@chakra-ui/react'
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { CallNote } from '@missionlabs/types'
import { FC } from 'react'
import { ContentEditable } from 'shared/components/Lexical/components/ContentEditable'
import { Placeholder } from 'shared/components/Lexical/components/Placeholder'
import theme from 'shared/components/Lexical/theme'

import { ActivityFeedNotesPlugin } from './ActivityFeedNotesPlugin'
import { ActivityFeedNotesToolbar } from './ActivityFeedNotesToolbar'

const config: InitialConfigType = {
    namespace: 'PostCallNotesInput',
    theme: theme,
    onError(error) {
        throw error
    }
}

export interface ActivityFeedNotesInputProps {
    onSaveCallNotes: (callTraceID: string, callNoteContent: string, callNote?: CallNote) => void
    callNote: CallNote | { content: string } | undefined
    callTraceID: string
}

export const ActivityFeedNotesInput: FC<ActivityFeedNotesInputProps> = ({
    onSaveCallNotes,
    callNote,
    callTraceID
}) => {
    const { colorMode } = useColorMode()

    return (
        <LexicalComposer initialConfig={config}>
            <Grid
                templateColumns="1fr min-content"
                position="relative"
                w="full"
                minH="98px"
                alignItems="start"
                px="16px"
                bg={`${colorMode}.tones.ghostWhite`}
                border="1px solid"
                borderColor={`${colorMode}.tones.periwinkle`}
                borderRadius="8px"
            >
                <Box>
                    <RichTextPlugin
                        contentEditable={
                            <ContentEditable py="12px" data-testid="NotesBox-content-editable" />
                        }
                        placeholder={<Placeholder />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <ActivityFeedNotesToolbar />
                </Box>
                <ActivityFeedNotesPlugin
                    callTraceID={callTraceID}
                    callNote={callNote}
                    onSaveCallNotes={onSaveCallNotes}
                />
            </Grid>
        </LexicalComposer>
    )
}
