import { Spinner, Table, useFuzzySearch } from '@missionlabs/react'
import { WhatsAppNumber } from '@missionlabs/types'
import { createColumnHelper } from '@tanstack/react-table'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetWhatsAppNumbersQuery } from 'shared/store'

import NumberActions from './NumberActions'

const columnHelper = createColumnHelper<Partial<WhatsAppNumber>>()

export interface NumbersTableProps {
    searchTerm: string
}

export const NumbersTable: FC<NumbersTableProps> = ({ searchTerm }) => {
    const { t } = useTranslation()

    const { data: numbers = [], isLoading } = useGetWhatsAppNumbersQuery()

    const { results } = useFuzzySearch<WhatsAppNumber>({
        items: numbers,
        searchValue: searchTerm,
        keys: ['numberE164'],
        options: {
            minMatchCharLength: 1
        }
    })

    const renderActions = (numberID: string) => <NumberActions numberID={numberID} />

    const columns = [
        columnHelper.accessor('name', {
            cell: info => info.getValue(),
            header: t('Name')
        }),
        columnHelper.accessor('numberE164', {
            cell: info => info.getValue(),
            header: t('Number')
        }),
        columnHelper.accessor('provisioningStatus', {
            cell: info => info.getValue(),
            header: t('Status')
        }),
        columnHelper.accessor('numberID', {
            cell: info => renderActions(info.row.original.numberID ?? ''),
            header: ''
        })
    ]

    if (isLoading) return <Spinner size="sm" />
    if (!numbers.length) return <p>{t('admin.whatsapp.noNumbers')}</p>
    return <Table data={results} columns={columns} paginate />
}
