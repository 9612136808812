import { Radio } from '@missionlabs/react'
import { UserCallRoute } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface RingOutRadioProps {
    onUpdate: (payload: Partial<UserCallRoute>) => void
}

export const BusyToneRadio: FC<RingOutRadioProps> = ({ onUpdate }) => {
    const { t } = useTranslation()

    const handleOnChange = () => onUpdate({ busy: 'busy_tone' })

    return (
        <Radio
            value="busy_tone"
            py="4px"
            display="flex"
            alignItems="center"
            onChange={handleOnChange}
        >
            {t('calls.playEngagedTone')}
        </Radio>
    )
}
