import { useFlags } from 'flagsmith/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SubNavLink, SubNavMenu } from 'shared/components/SubNav'
import { Features, useHasClientFeature } from 'shared/hooks/useHasClientFeature'

export interface AdminSidebarProps {}

export const AdminSidebar: FC<AdminSidebarProps> = () => {
    const flags = useFlags(['billing', 'subscription'])
    const { t } = useTranslation()
    const whatsappClientEnabled = useHasClientFeature(Features.whatsapp)

    return (
        <SubNavMenu title={t('admin.title')}>
            <SubNavLink label={t('admin.users.title')} page="/admin/users" />
            <SubNavLink label={t('admin.teams.title')} page="/admin/teams" />
            <SubNavLink label={t('admin.numbers.title')} page="/admin/numbers" />
            <SubNavLink label={t('admin.menus.title')} page="/admin/menus" />
            <SubNavLink label={t('admin.callRecording.title')} page="/admin/call-recording" />
            <SubNavLink label={t('admin.blockedNumbers.title')} page="/admin/blocked-numbers" />
            <SubNavLink label={t('admin.directories.title')} page="/admin/directories" />
            <SubNavLink label={t('admin.globalSettings.title')} page="/admin/global-settings" />
            <SubNavLink label={t('admin.activityLog.title')} page="/admin/activity-log" />
            {flags.billing.enabled && (
                <SubNavLink label={t('admin.billing.title')} page="/admin/billing" />
            )}
            {whatsappClientEnabled && (
                <SubNavLink label={t('admin.whatsapp.title')} page="/admin/whatsapp" />
            )}
            {flags.subscription.enabled && (
                <SubNavLink label={t('admin.subscription.title')} page="/admin/subscription" />
            )}
        </SubNavMenu>
    )
}
