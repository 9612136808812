import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const DeleteCircleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DeleteCircleIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <circle cx="12" cy="12" r="12" fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.779 8.31861H15.8745H13.5872L13.1987 7.14969C13.169 7.06031 13.0842 7 12.9882 7H11.0118C10.9163 7 10.8314 7.06031 10.8018 7.14969L10.4132 8.31861H8.1255H7.22103C7.09902 8.31861 7 8.4158 7 8.53556C7 8.65531 7.09902 8.75251 7.22103 8.75251H7.91994L8.51408 16.7987C8.52248 16.9124 8.61841 17 8.73467 17H15.2658C15.3816 17 15.4775 16.9124 15.4864 16.7987L16.0801 8.75251H16.779C16.901 8.75251 17 8.65531 17 8.53556C17 8.4158 16.901 8.31861 16.779 8.31861ZM11.1722 7.43396H12.8282L13.1221 8.31867H10.8778L11.1722 7.43396ZM9.77026 9.87449C9.7623 9.75516 9.85425 9.65146 9.97626 9.64365C10.0947 9.63845 10.2035 9.72609 10.2114 9.84585L10.5894 15.4114C10.5974 15.5308 10.505 15.634 10.3834 15.6423C10.3781 15.6427 10.3732 15.6427 10.3684 15.6427C10.2526 15.6427 10.1557 15.5551 10.1478 15.4401L9.77026 9.87449ZM13.6253 15.6423C13.6306 15.6427 13.6351 15.6427 13.6404 15.6427C13.7558 15.6427 13.8526 15.5551 13.8605 15.4401L14.238 9.87449C14.2464 9.75516 14.1541 9.65146 14.032 9.64365C13.9127 9.63845 13.8048 9.72609 13.7969 9.84585L13.4193 15.4114C13.4114 15.5308 13.5038 15.634 13.6253 15.6423ZM11.7835 9.86018C11.7835 9.74042 11.8826 9.64323 12.0046 9.64323C12.1266 9.64323 12.2256 9.73999 12.2256 9.86018V15.4258C12.2256 15.5455 12.1266 15.6427 12.0046 15.6427C11.8826 15.6427 11.7835 15.5455 11.7835 15.4258V9.86018Z"
                fill="white"
            />
        </g>
    )
})

export const DeleteIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DeleteIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.6021 5.3735H18.9741H14.857L14.1576 3.26945C14.1043 3.10856 13.9515 3 13.7788 3H10.2212C10.0493 3 9.89651 3.10856 9.8432 3.26945L9.14376 5.3735H5.02591H3.39786C3.17824 5.3735 3 5.54844 3 5.764C3 5.97956 3.17824 6.15451 3.39786 6.15451H4.65589L5.72534 20.6376C5.74046 20.8422 5.91313 21 6.12241 21H17.8784C18.0869 21 18.2595 20.8422 18.2755 20.6376L19.3441 6.15451H20.6021C20.8218 6.15451 21 5.97956 21 5.764C21 5.54844 20.8218 5.3735 20.6021 5.3735ZM10.51 3.78101H13.4908L14.0199 5.3735H9.98006L10.51 3.78101ZM7.98678 8.17421C7.97246 7.95943 8.13797 7.77277 8.35759 7.75871C8.57084 7.74934 8.76659 7.9071 8.78091 8.12266L9.46125 18.1407C9.47558 18.3555 9.30927 18.5414 9.09045 18.5562C9.0809 18.557 9.07215 18.557 9.06339 18.557C8.85491 18.557 8.68065 18.3992 8.66633 18.1923L7.98678 8.17421ZM14.9255 18.5562C14.935 18.557 14.943 18.557 14.9525 18.557C15.1602 18.557 15.3345 18.3992 15.3488 18.1923L16.0283 8.17421C16.0435 7.95943 15.8771 7.77277 15.6575 7.75871C15.4427 7.74934 15.2485 7.9071 15.2342 8.12266L14.5547 18.1407C14.5403 18.3555 14.7066 18.5414 14.9255 18.5562ZM11.6097 8.14844C11.6097 7.93288 11.7879 7.75793 12.0076 7.75793C12.2272 7.75793 12.4054 7.9321 12.4054 8.14844V18.1665C12.4054 18.382 12.2272 18.557 12.0076 18.557C11.7879 18.557 11.6097 18.382 11.6097 18.1665V8.14844Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_191"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="3"
                y="3"
                width="18"
                height="18"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.6021 5.3735H18.9741H14.857L14.1576 3.26945C14.1043 3.10856 13.9515 3 13.7788 3H10.2212C10.0493 3 9.89651 3.10856 9.8432 3.26945L9.14376 5.3735H5.02591H3.39786C3.17824 5.3735 3 5.54844 3 5.764C3 5.97956 3.17824 6.15451 3.39786 6.15451H4.65589L5.72534 20.6376C5.74046 20.8422 5.91313 21 6.12241 21H17.8784C18.0869 21 18.2595 20.8422 18.2755 20.6376L19.3441 6.15451H20.6021C20.8218 6.15451 21 5.97956 21 5.764C21 5.54844 20.8218 5.3735 20.6021 5.3735ZM10.51 3.78101H13.4908L14.0199 5.3735H9.98006L10.51 3.78101ZM7.98678 8.17421C7.97246 7.95943 8.13797 7.77277 8.35759 7.75871C8.57084 7.74934 8.76659 7.9071 8.78091 8.12266L9.46125 18.1407C9.47558 18.3555 9.30927 18.5414 9.09045 18.5562C9.0809 18.557 9.07215 18.557 9.06339 18.557C8.85491 18.557 8.68065 18.3992 8.66633 18.1923L7.98678 8.17421ZM14.9255 18.5562C14.935 18.557 14.943 18.557 14.9525 18.557C15.1602 18.557 15.3345 18.3992 15.3488 18.1923L16.0283 8.17421C16.0435 7.95943 15.8771 7.77277 15.6575 7.75871C15.4427 7.74934 15.2485 7.9071 15.2342 8.12266L14.5547 18.1407C14.5403 18.3555 14.7066 18.5414 14.9255 18.5562ZM11.6097 8.14844C11.6097 7.93288 11.7879 7.75793 12.0076 7.75793C12.2272 7.75793 12.4054 7.9321 12.4054 8.14844V18.1665C12.4054 18.382 12.2272 18.557 12.0076 18.557C11.7879 18.557 11.6097 18.382 11.6097 18.1665V8.14844Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_191)"></g>
        </g>
    )
})

export const DeleteIconOutline: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DeleteIconOutline',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M6.5 15.625C6.5 15.9766 6.1875 16.25 5.875 16.25C5.52344 16.25 5.25 15.9766 5.25 15.625V7.5C5.25 7.1875 5.52344 6.875 5.875 6.875C6.1875 6.875 6.5 7.1875 6.5 7.5V15.625ZM9.625 15.625C9.625 15.9766 9.3125 16.25 9 16.25C8.64844 16.25 8.375 15.9766 8.375 15.625V7.5C8.375 7.1875 8.64844 6.875 9 6.875C9.3125 6.875 9.625 7.1875 9.625 7.5V15.625ZM12.75 15.625C12.75 15.9766 12.4375 16.25 12.125 16.25C11.7734 16.25 11.5 15.9766 11.5 15.625V7.5C11.5 7.1875 11.7734 6.875 12.125 6.875C12.4375 6.875 12.75 7.1875 12.75 7.5V15.625ZM12.6328 0.976562L14.0781 3.125H16.8125C17.3203 3.125 17.75 3.55469 17.75 4.0625C17.75 4.60938 17.3203 5 16.8125 5H16.5V16.875C16.5 18.6328 15.0938 20 13.375 20H4.625C2.86719 20 1.5 18.6328 1.5 16.875V5H1.1875C0.640625 5 0.25 4.60938 0.25 4.0625C0.25 3.55469 0.640625 3.125 1.1875 3.125H3.88281L5.32812 0.976562C5.71875 0.390625 6.42188 0 7.16406 0H10.7969C11.5391 0 12.2422 0.390625 12.6328 0.976562ZM6.14844 3.125H11.8125L11.0703 2.03125C11.0312 1.95312 10.9141 1.875 10.7969 1.875H7.16406C7.04688 1.875 6.92969 1.95312 6.89062 2.03125L6.14844 3.125ZM3.375 16.875C3.375 17.5781 3.92188 18.125 4.625 18.125H13.375C14.0391 18.125 14.625 17.5781 14.625 16.875V5H3.375V16.875Z"
            fill="currentColor"
        />
    )
})
