import { Center, Link } from '@chakra-ui/react'
import { Button, Spinner } from '@missionlabs/react'
import { AnalyticsReport } from '@missionlabs/types'
import { openURL } from '@missionlabs/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    data: AnalyticsReport
}

export const AnalyticsReportsTableDownload: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation()
    return (
        <>
            {data.status === 'unconfirmed' && (
                <Spinner size="md" color="brand" emptyColor="gray.200" thickness="5px" />
            )}
            {data.status === 'completed' && !!data.url && (
                <Center>
                    <Link
                        id="analytics__reports-download"
                        isExternal={false}
                        onClick={e => {
                            e.stopPropagation()
                            openURL(`${data.baseURL}/${data.url}`)
                        }}
                    >
                        <Button variant="secondary">{t('analytics.reports.table.download')}</Button>
                    </Link>
                </Center>
            )}
        </>
    )
}
