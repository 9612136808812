import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const SortUpDownIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SortUpDownIconLight',
    viewBox: '0 0 14 18',
    path: (
        <path
            d="M12 10.2305H2.00002C0.867211 10.2305 0.320336 11.5977 1.10159 12.3789L6.06252 17.3789C6.57034 17.8867 7.35159 17.8867 7.8594 17.3789L12.8203 12.3789C13.6797 11.5977 13.1328 10.2305 12 10.2305ZM7.03909 16.4805L2.03909 11.4805H12L7.03909 16.4805ZM2.03909 7.73047H12C13.1328 7.73047 13.6797 6.40234 12.8985 5.62109L7.93752 0.621094C7.42971 0.113281 6.64846 0.113281 6.17971 0.621094L1.14065 5.62109C0.359398 6.40234 0.906273 7.73047 2.03909 7.73047ZM7.03909 1.48047L12 6.48047H2.00002L7.03909 1.48047Z"
            fill="currentColor"
        />
    )
})

export const SortUpDownIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SortUpDownIconRegular',
    viewBox: '0 0 14 19',
    path: (
        <path
            d="M11.3554 10.7305H2.64451C0.964825 10.7305 0.10545 12.7617 1.31639 13.9336L5.69139 18.3086C6.39451 19.0508 7.60545 19.0508 8.34764 18.3086L12.6836 13.9336C13.8554 12.7617 13.0351 10.7305 11.3554 10.7305ZM7.01951 16.9805L2.64451 12.6055H11.3945L7.01951 16.9805ZM2.64451 8.23047H11.3554C13.0351 8.23047 13.8945 6.19922 12.6836 5.02734L8.30858 0.691406C7.60545 -0.0507812 6.39451 -0.0507812 5.69139 0.691406L1.31639 5.06641C0.144513 6.23828 0.964825 8.23047 2.64451 8.23047ZM7.01951 1.98047L11.3554 6.35547H2.64451L7.01951 1.98047Z"
            fill="currentColor"
        />
    )
})

export const SortUpDownIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SortUpDownIconSolid',
    viewBox: '0 0 14 18',
    path: (
        <path
            d="M1.8242 7.75H12.1758C13.1133 7.75 13.6211 6.61719 12.9179 5.91406L7.7617 0.601562C7.56638 0.367188 7.29295 0.25 7.01951 0.25C6.74607 0.25 6.43357 0.367188 6.23826 0.601562L1.08201 5.91406C0.378884 6.61719 0.886696 7.75 1.8242 7.75ZM12.1758 10.25H1.8242C0.886696 10.25 0.378884 11.4219 1.08201 12.125L6.23826 17.4375C6.43357 17.6719 6.70701 17.75 7.01951 17.75C7.29295 17.75 7.56638 17.6719 7.7617 17.4375L12.9179 12.125C13.6211 11.4219 13.1133 10.25 12.1758 10.25Z"
            fill="currentColor"
        />
    )
})

export const SortDownIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SortDownIconLight',
    viewBox: '0 0 14 8',
    path: (
        <path
            d="M12.0117 0.220703H2.01168C0.878868 0.220703 0.331993 1.58789 1.11324 2.36914L6.07418 7.36914C6.58199 7.87695 7.36324 7.87695 7.87106 7.36914L12.832 2.36914C13.6914 1.58789 13.1445 0.220703 12.0117 0.220703ZM7.01168 6.4707L2.05074 1.4707H12.0117L7.01168 6.4707Z"
            fill="currentColor"
        />
    )
})

export const SortDownIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SortDownIconRegular',
    viewBox: '0 0 14 9',
    path: (
        <path
            d="M11.3641 0.615234H2.65318C0.973492 0.615234 0.114117 2.64648 1.32505 3.81836L5.70005 8.19336C6.40318 8.93555 7.61412 8.93555 8.31724 8.19336L12.6922 3.81836C13.8641 2.64648 13.0438 0.615234 11.3641 0.615234ZM6.98912 6.86523L2.61412 2.49023H11.3641L6.98912 6.86523Z"
            fill="currentColor"
        />
    )
})

export const SortDownIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SortDownIconSolid',
    viewBox: '0 0 14 8',
    path: (
        <path
            d="M12.9179 2.125L7.7617 7.4375C7.56638 7.67188 7.29295 7.75 7.01951 7.75C6.70701 7.75 6.43357 7.67188 6.23826 7.4375L1.08201 2.125C0.378884 1.42188 0.886696 0.25 1.8242 0.25H12.1758C13.1133 0.25 13.6211 1.42188 12.9179 2.125Z"
            fill="currentColor"
        />
    )
})

export const SortUpIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SortUpIconLight',
    viewBox: '0 0 14 8',
    path: (
        <path
            d="M2.01955 7.74023H11.9805C13.1133 7.74023 13.6602 6.41211 12.8789 5.63086L7.91799 0.630859C7.41018 0.123047 6.62893 0.123047 6.16018 0.630859L1.12112 5.63086C0.339867 6.41211 0.886742 7.74023 2.01955 7.74023ZM7.01955 1.49023L11.9805 6.49023H1.98049L7.01955 1.49023Z"
            fill="currentColor"
        />
    )
})

export const SortUpIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SortUpIconRegular',
    viewBox: '0 0 14 9',
    path: (
        <path
            d="M2.63583 8.3457H11.3468C13.0265 8.3457 13.8858 6.35352 12.6749 5.18164L8.29989 0.806641C7.59677 0.0644531 6.38583 0.0644531 5.6827 0.806641L1.3077 5.18164C0.135829 6.35352 0.956141 8.3457 2.63583 8.3457ZM7.01083 2.0957L11.3858 6.4707H2.63583L7.01083 2.0957Z"
            fill="currentColor"
        />
    )
})

export const SortUpIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SortUpIconSolid',
    viewBox: '0 0 14 8',
    path: (
        <path
            d="M1.8242 7.75H12.1758C13.1133 7.75 13.6211 6.61719 12.9179 5.91406L7.7617 0.601562C7.56638 0.367188 7.29295 0.25 7.01951 0.25C6.74607 0.25 6.43357 0.367188 6.23826 0.601562L1.08201 5.91406C0.378884 6.61719 0.886696 7.75 1.8242 7.75Z"
            fill="currentColor"
        />
    )
})
