import { useColorMode } from '@chakra-ui/react'
import { IconButton, IconButtonProps } from '@missionlabs/react'
import { ChatIconSolid } from '@missionlabs/react/zeta'
import { FC } from 'react'

export interface ChatControlProps extends Omit<IconButtonProps, 'aria-label'> {}

const ChatControl: FC<ChatControlProps> = props => {
    const { colorMode } = useColorMode()
    return (
        <IconButton
            {...props}
            variant="tertiary"
            isRounded
            title="Show chat"
            sx={{ color: `${colorMode}.alerts.red` }}
            aria-label="Show chat"
            data-testid="video-chat-control"
        >
            <ChatIconSolid />
        </IconButton>
    )
}

export default ChatControl
