import { yup } from '@missionlabs/react'
import { notAvailableSchema } from 'shared/schemas/notAvailable'

export const editUserSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    type: yup.string().required(),
    subscriptionType: yup.string(),
    teams: yup.array(),
    callRoute: yup.object().shape({
        // When call goes unanswered
        inHoursUnanswered: yup.string(),
        callTimeout: yup.number(),
        voicemail: yup.object({
            greeting: yup.string(),
            customGreetingURL: yup.string().when('greeting', {
                is: v => v === 'custom',
                then: () => yup.string().required()
            }),
            greetingNoMessage: yup.string().nullable(),
            customGreetingNoMessageURL: yup.string().when('greetingNoMessage', {
                is: v => v === 'custom',
                then: () => yup.string().required()
            })
        }),
        unansweredRedirect: yup.object().when('callRoute.inHoursUnanswered', {
            is: v => v === 'redirect',
            then: () => yup.object({ numberE164: yup.string().required() })
        }),
        unansweredRedirectInternal: yup.object().when('callRoute.inHoursUnanswered', {
            is: v => v === 'redirect_internal',
            then: () => yup.object({ display: yup.string().required() })
        }),
        // Out of hours
        outOfHours: yup.string(),
        outOfHoursRedirect: yup.object().when('callRoute.outOfHours', {
            is: v => v === 'redirect',
            then: () =>
                yup.object({
                    annouce: yup.boolean(),
                    flipCLI: yup.boolean(),
                    numberE164: yup.string().required()
                })
        }),
        outOfHoursRedirectInternal: yup.object().when('callRoute.outOfHours', {
            is: v => v === 'redirect_internal',
            then: () =>
                yup.object({
                    display: yup.string().required(),
                    userID: yup.string().required()
                })
        }),
        outOfHoursVoicemail: yup.object({
            customGreetingURL: yup.string()
        }),
        notAvailable: notAvailableSchema().default(undefined),
        blockAnonymousCalls: yup.boolean().default(false)
    })
})
