import {
    Activity,
    Activity as ActivityType,
    ActivityDirection,
    DirectoryEntry,
    UserActivity
} from '@missionlabs/types'
import { TFunction } from 'i18next'
import { ScrollDirection, Section, VirtualisedActivityItem } from 'shared/types/feed'

export function isUnread(activity: UserActivity) {
    // activities can only be marked as read by setting `isUnread: false`
    // isUnread defaults to undefined, which has the same behaviour as `isUnread: true`
    const explicitlyRead = typeof activity.isUnread === 'boolean' && activity.isUnread === false
    if (explicitlyRead) return false

    // outbound activities shouldn't be shown as unread
    // TODO: should this change when we're showing activities from other users to customers?
    const isOutbound = getDirectionOfActivity(activity) === 'outbound'
    if (isOutbound) return false

    // inbound activities that were answered don't need to be shown as unread
    const isAnswered = isInternalCallAnswered(activity)
    if (isAnswered) return false

    return true
}

export function sortActivities(activities: UserActivity[], direction: ScrollDirection) {
    return [...activities].sort((_a, _b) => {
        const a = new Date(_a.created).valueOf()
        const b = new Date(_b.created).valueOf()

        // newest to oldest.
        return direction === ScrollDirection.DOWN ? b - a : a - b
    })
}

export function isWithinSection(section: Section, createdAt: number) {
    return createdAt >= section.startOfDay && createdAt <= section.endOfDay
}

export function groupBySection(activities: UserActivity[]): VirtualisedActivityItem[] {
    // This is not displayed, just gives us a way of starting the reduce off with a date that no data will fall within.
    const DEFAULT_SECTION: Section = {
        key: 'default',
        object: 'section',
        startOfDay: new Date('01/01/1970').setUTCHours(0, 0, 0, 0).valueOf(),
        endOfDay: new Date('01/01/1970').setUTCHours(23, 59, 59, 999).valueOf()
    }

    const result: VirtualisedActivityItem[] = []
    let lastSection: Section = DEFAULT_SECTION

    for (const activity of activities) {
        const date = new Date(activity.created)
        const created = date.valueOf()

        if (isWithinSection(lastSection, created)) {
            // Within the current sections day, so just append data and inherit section info.
            result.push({ ...activity, section: lastSection })
        } else {
            // Start of a new section, first appends section object and then data to maintain order.
            const startOfDay = date.setUTCHours(0, 0, 0, 0).valueOf()
            const endOfDay = date.setUTCHours(23, 59, 59, 999).valueOf()
            const newSection: Section = {
                object: 'section',
                startOfDay,
                endOfDay,
                key: startOfDay.toString()
            }
            result.push(newSection, { ...activity, section: newSection })
            lastSection = newSection
        }
    }

    return result
}

export const getDirectionOfActivity = (activity: UserActivity | undefined): ActivityDirection => {
    switch (activity?.activityType) {
        case ActivityType.CALL_RECEIVED:
        case ActivityType.INTERNAL_CALL_RECEIVED:
        case ActivityType.CALL_MISSED:
        case ActivityType.INTERNAL_CALL_MISSED:
        case ActivityType.MESSAGE_RECEIVED:
        case ActivityType.INTERNAL_MESSAGE_RECEIVED:
        case ActivityType.CHAT_MESSAGE_RECEIVED:
        case ActivityType.WHATSAPP_MESSAGE_RECEIVED:
        case ActivityType.DO_NOT_USE__EMAIL_RECEIVED:
        case ActivityType.INTERNAL_MEETING_RECEIVED:
        case ActivityType.INTERNAL_MEETING_MISSED:
        case ActivityType.INTERNAL_MEETING_REJECTED:
            return 'inbound'
        case ActivityType.CALL_MADE:
        case ActivityType.INTERNAL_CALL_MADE:
        case ActivityType.CHAT_MESSAGE_SENT:
        case ActivityType.WHATSAPP_MESSAGE_SENT:
        case ActivityType.DO_NOT_USE__EMAIL_SENT:
        case Activity.INTERNAL_MEETING_MADE:
            return 'outbound'
        default:
            return
    }
}

export const isInternalCallAnswered = (activity: UserActivity | undefined): boolean => {
    switch (activity?.activityType) {
        case ActivityType.CALL_RECEIVED:
        case ActivityType.INTERNAL_CALL_RECEIVED:
            return true
        default:
            return false
    }
}

export const getNameAndNumberFromActivity = (
    activity: UserActivity | undefined,
    format: Function,
    t: TFunction
) => {
    const name = getNameFromActivity(activity, format, t)

    const activityWithoutContact = {
        ...activity,
        contactInfo: undefined,
        contact: undefined,
        originatingContact: undefined
    } as UserActivity

    const number = getNameFromActivity(activityWithoutContact, format, t)

    if (name && number) {
        if (number !== name) {
            return `${name} - ${number}`
        }
        return number
    }

    return number
}

export const getNameFromActivity = (
    activity: UserActivity | undefined,
    format: Function,
    t: TFunction
): string => {
    if (activity?.contactInfo) return activity?.contactInfo?.fullName ?? t('details.unknown')
    if (activity?.contact) return activity.contact.fullName ?? t('details.unknown')

    const direction = getDirectionOfActivity(activity)
    if (direction === 'inbound') {
        if (activity?.originatingContact) {
            return activity.originatingContact.fullName ?? t('details.unknown')
        }
        if (activity?.originatingNumber === 'anonymous') return t('details.anonymous')
        if (activity?.originatingNumber === 'unavailable') return t('details.unavailable')
        return format(activity?.originatingNumber)
    }
    if (direction === 'outbound') {
        return format(activity?.dialledNumber)
    }

    return t('details.unknown')
}

export const getCompanyFromActivity = (activity: UserActivity | undefined): string | undefined => {
    if (activity?.contactInfo) return activity.contactInfo?.companyName
    if (activity?.contact?.companyName) return activity.contact.companyName
    if (activity?.originatingContact?.companyName) return activity.originatingContact.companyName
    return activity?.clientName
}
export const getTeamFromActivity = (
    activity: UserActivity | undefined,
    t: TFunction
): string | undefined => {
    if (!activity?.teamName) return
    return t('details.via', { value: activity.teamName })
}

/**
 * The contact or number "entity" associated with an activity.
 * @example Known contact:
 * { type: 'contact', contactID: '123', contact: {} }
 * @example Unknown contact:
 * { type: 'number', number: '447000111222' }
 */
export type ActivityContactEntity =
    | undefined
    | { type: 'number'; number?: string }
    | { type: 'contact'; contactID: string; contact?: DirectoryEntry }

/**
 * Get the contact or number "entity" associated with an activity
 * @example Known contact:
 * { type: 'contact', contactID: '123', contact: {} }
 * @example Unknown contact:
 * { type: 'number', number: '447000111222' }
 */
export function getActivityContactEntity(
    activity?: UserActivity,
    contact?: DirectoryEntry,
    format = (number: string) => number
): ActivityContactEntity {
    if (!activity) return
    const {
        contactID,
        originatingNumberE164,
        dialledNumberE164,
        dialledNumber,
        originatingNumber
    } = activity

    // Some activities already have the contact on them
    if (activity?.contact) {
        return { type: 'contact', contactID: activity.contact.ID, contact: activity.contact }
    }

    // Others just have contactID, so we need to fetch the contact
    if (contactID) {
        return { type: 'contact', contactID, contact: contact }
    }

    if (getDirectionOfActivity(activity) === 'outbound') {
        return { type: 'number', number: dialledNumberE164 ?? format(dialledNumber) }
    }

    const anonNumbers = ['anonymous', 'unavailable']
    if (originatingNumberE164 && !anonNumbers.includes(originatingNumberE164)) {
        return { type: 'number', number: originatingNumberE164 }
    }

    if (originatingNumber && !anonNumbers.includes(originatingNumber)) {
        return { type: 'number', number: format(originatingNumber) }
    }
}
