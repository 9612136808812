import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { countIndicatorAnatomy } from '../anatomy'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([...countIndicatorAnatomy.keys])

export const CountIndicatorStyles = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => ({
        container: {
            gap: '5px',
            padding: '4px 5px 4px 8px',
            borderRadius: '16px',
            transition: 'all 200ms ease',
            cursor: 'pointer',
            _hover: {
                borderColor: `${colorMode}.tones.periwinkle`
            }
        },
        icon: {
            w: '16px',
            h: '16px',
            color: `${colorMode}.tones.stormGrey`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        count: {
            p: '0 8px',
            borderRadius: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minW: '26px'
        }
    }),
    variants: {
        selected: ({ colorMode }) => ({
            container: {
                color: `${colorMode}.tones.white`,
                bg: `${colorMode}.tones.stormGrey`,
                borderColor: `${colorMode}.tones.stormGrey`
            },
            icon: {
                color: `${colorMode}.tones.white`
            },
            count: {
                color: `${colorMode}.tones.stormGrey`,
                bg: `${colorMode}.tones.white`
            }
        }),
        hasCount: ({ colorMode }) => ({
            count: {
                bg: `${colorMode}.alerts.red`,
                color: `${colorMode}.tones.white`
            }
        }),
        noCount: ({ colorMode }) => ({
            count: {
                bg: `${colorMode}.tones.periwinkle`,
                color: `${colorMode}.tones.white`
            }
        }),
        flagged: ({ colorMode }) => ({
            count: {
                bg: `${colorMode}.avatar.blue`,
                color: `${colorMode}.tones.white`
            }
        })
    }
})
