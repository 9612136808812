import { Divider, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Checkbox, ValidatedInput } from '@missionlabs/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {}

export const CallBarring: React.FC<Props> = () => {
    const { t } = useTranslation()

    const { colorMode } = useColorMode()

    return (
        <VStack width="full" spacing="16px" divider={<Divider />}>
            <VStack align="left" width="full">
                <Body size="md">{t('admin.users.callBarring.titleOutbound')}</Body>
                <Body size="sm" color={`${colorMode}.tones.stormGrey`}>
                    {t('admin.users.callBarring.descriptionOutbound')}
                </Body>
                <HStack>
                    <ValidatedInput name="outboundBarring.all_calls">
                        <Checkbox>{t('admin.users.callBarring.allCalls')}</Checkbox>
                    </ValidatedInput>

                    <ValidatedInput name="outboundBarring.international_calling">
                        <Checkbox>{t('admin.users.callBarring.international')}</Checkbox>
                    </ValidatedInput>

                    <ValidatedInput name="outboundBarring.premium_calling">
                        <Checkbox>{t('admin.users.callBarring.premium')}</Checkbox>
                    </ValidatedInput>
                </HStack>
            </VStack>

            <VStack align="left" width="full">
                <Body size="md">{t('admin.users.callBarring.titleInbound')}</Body>
                <Body size="sm" color={`${colorMode}.tones.stormGrey`}>
                    {t('admin.users.callBarring.descriptionInbound')}
                </Body>
                <HStack>
                    <ValidatedInput name="inboundBarring.all_calls">
                        <Checkbox>{t('admin.users.callBarring.allCalls')}</Checkbox>
                    </ValidatedInput>
                </HStack>
            </VStack>
        </VStack>
    )
}
