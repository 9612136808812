import { Tab as ChakraTab, TabProps as ChakraTabProps, useColorMode } from '@chakra-ui/react'
import { defineStyle } from '@chakra-ui/styled-system'
import { forwardRef } from 'react'

import { Body } from '../../atoms/Typography/Body'

export interface TabProps extends ChakraTabProps {
    count?: number
}

export const Tab = forwardRef<HTMLButtonElement, TabProps>(({ count, children, ...props }, ref) => {
    const { colorMode } = useColorMode()

    const countStyle = defineStyle({
        color: 'white',
        bg: `${colorMode}.alerts.redDark`,
        borderRadius: 'full',
        padding: '1px 8px'
    })

    return (
        <ChakraTab {...props} ref={ref}>
            {children}
            {!!count && (
                <Body as="span" size="xs" variant="bold" sx={countStyle}>
                    {count}
                </Body>
            )}
        </ChakraTab>
    )
})

Tab.displayName = 'Tab'
