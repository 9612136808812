import { Box, useColorMode, VStack } from '@chakra-ui/react'
import { Heading } from '@missionlabs/react'
import { FC, ReactNode } from 'react'

export interface SubNavProps {
    title: string
    children: ReactNode
}

export const SubNavMenu: FC<SubNavProps> = ({ children, title }) => {
    const { colorMode } = useColorMode()
    return (
        <Box bg={`${colorMode}.tones.ghostWhite`}>
            <Heading size="h3" p="24px" fontSize="20px" lineHeight="32px" fontWeight={400}>
                {title}
            </Heading>
            <VStack spacing="4px" px="24px" align="stretch">
                {children}
            </VStack>
        </Box>
    )
}
