import {
    $isTextNode,
    DOMConversion,
    DOMConversionMap,
    DOMConversionOutput,
    EditorConfig,
    SerializedTextNode,
    TextNode
} from 'lexical'

export function $createEmojiNode(text: string) {
    return new EmojiNode(text).setMode('token')
}

export function $isEmojiNode(node: unknown): node is EmojiNode {
    return node instanceof EmojiNode
}

function convertDOMToEmojiNode(
    originalDOMConverter?: (node: HTMLElement) => DOMConversion | null
): (node: HTMLElement) => DOMConversionOutput | null {
    return node => {
        const original = originalDOMConverter?.(node)
        if (!original) return null

        const originalOutput = original.conversion(node)
        if (!originalOutput) return originalOutput

        return {
            ...originalOutput,
            forChild: (lexicalNode, parent) => {
                const originalForChild = originalOutput?.forChild ?? (x => x)
                const result = originalForChild(lexicalNode, parent)
                if ($isTextNode(result) && node.className === 'emoji') {
                    return $createEmojiNode(result.getTextContent())
                }
                return result
            }
        }
    }
}

export class EmojiNode extends TextNode {
    static getType() {
        return 'emoji'
    }

    static clone(node: EmojiNode) {
        return new EmojiNode(node.__text, node.__key)
    }

    static importDOM(): DOMConversionMap | null {
        const importers = super.importDOM()
        return {
            ...importers,
            span: () => ({
                conversion: convertDOMToEmojiNode(importers?.span),
                priority: 1
            })
        }
    }

    static importJSON(serializedNode: SerializedTextNode): TextNode {
        const node = $createEmojiNode(serializedNode.text)
        node.setFormat(serializedNode.format)
        node.setDetail(serializedNode.detail)
        node.setMode(serializedNode.mode)
        node.setStyle(serializedNode.style)
        return node
    }

    createDOM(config: EditorConfig) {
        const dom = super.createDOM(config)
        dom.className = 'emoji'
        return dom
    }

    exportJSON(): SerializedTextNode {
        return {
            ...super.exportJSON(),
            type: 'emoji',
            version: 1
        }
    }
}
