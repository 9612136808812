import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const AppleBrandIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AppleBrandIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M19.6651 16.811C19.3781 17.475 19.0381 18.086 18.6441 18.648C18.1071 19.415 17.6661 19.945 17.3281 20.24C16.8031 20.722 16.2391 20.97 15.6361 20.984C15.2041 20.984 14.6821 20.861 14.0741 20.611C13.4641 20.362 12.9041 20.24 12.3911 20.24C11.8541 20.24 11.2781 20.362 10.6611 20.611C10.0451 20.861 9.54714 20.992 9.16614 21.004C8.58914 21.029 8.01214 20.775 7.43714 20.24C7.07014 19.92 6.61114 19.37 6.06014 18.592C5.47014 17.763 4.98514 16.798 4.60514 15.701C4.19814 14.514 3.99414 13.366 3.99414 12.254C3.99414 10.981 4.26914 9.88202 4.82014 8.96202C5.25414 8.22202 5.83014 7.63902 6.55014 7.21102C7.27114 6.78202 8.05114 6.56302 8.89014 6.54902C9.35014 6.54902 9.95314 6.69102 10.7001 6.97102C11.4471 7.25102 11.9271 7.39302 12.1361 7.39302C12.2941 7.39302 12.8251 7.22602 13.7291 6.89502C14.5821 6.58802 15.3021 6.46102 15.8921 6.51102C17.4921 6.64002 18.6931 7.27002 19.4921 8.40602C18.0621 9.27302 17.3551 10.486 17.3691 12.043C17.3811 13.256 17.8221 14.265 18.6861 15.066C19.0781 15.438 19.5151 15.725 20.0011 15.929C19.8951 16.236 19.7831 16.529 19.6651 16.811ZM15.9981 2.38002C15.9981 3.33002 15.6501 4.21802 14.9591 5.03902C14.1231 6.01502 13.1131 6.58002 12.0181 6.49102C12.0041 6.37702 11.9971 6.25702 11.9971 6.13102C11.9971 5.21802 12.3931 4.24202 13.1001 3.44302C13.4521 3.03902 13.9001 2.70202 14.4431 2.43402C14.9851 2.17002 15.4971 2.02402 15.9791 1.99902C15.9921 2.12702 15.9981 2.25402 15.9981 2.38002Z"
            fill="currentColor"
        />
    )
})
