import { Drawer, DrawerBody, DrawerContent, DrawerOverlay, useDisclosure } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { DrawerHeader, DrawerProps } from '@missionlabs/react'
import { DestinationTypes } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetDestinationForUserQuery, usePutDestinationForUserMutation } from 'shared/store'

import { DeleteDeviceDialog } from '../DeleteDeviceDialog'
import { EditDeviceDefaultFields, EditDeviceDefaultForm } from './EditDeviceDefaultForm'
import { EditDeviceNumberForm } from './EditDeviceNumberForm'
import { EditDeviceSipEndpointForm } from './EditDeviceSipEndpointForm'

const noop = () => ({})

export interface EditDeviceDrawerProps extends Omit<DrawerProps, 'children'> {}

export const EditDeviceDrawer: FC<EditDeviceDrawerProps> = ({ isOpen, onClose, ...props }) => {
    const navigate = useNavigate()
    const { id = '' } = useParams()
    const { t } = useTranslation()
    const { isOpen: deleteOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure()

    const user = useSelector(selectAuthenticatedUser)
    const userID = user?.userID ?? ''

    const { data, isLoading } = useGetDestinationForUserQuery(
        { destinationID: id, userID },
        { skip: !id || !user, refetchOnMountOrArgChange: true }
    )
    const [updateDestination] = usePutDestinationForUserMutation()

    async function handleSubmit(values: EditDeviceDefaultFields) {
        if (!data) return
        await updateDestination({ ...data, ...values }).unwrap()
        return onClose()
    }

    function handleTransition() {
        navigate('/settings/devices')
    }

    function renderForm() {
        const props = { values: data, onClose, onDelete: deleteOnOpen, onSubmit: handleSubmit }
        if (!data) return null
        switch (data.type) {
            case DestinationTypes.number:
                return <EditDeviceNumberForm {...props} />
            case DestinationTypes.sipEndpoint:
                return <EditDeviceSipEndpointForm {...props} />
            default:
                return <EditDeviceDefaultForm {...props} />
        }
    }

    return (
        <>
            {!!data && (
                <DeleteDeviceDialog device={data} isOpen={deleteOpen} onClose={deleteOnClose} />
            )}
            <Drawer
                {...props}
                size="lg"
                isOpen={isOpen}
                onClose={!isLoading ? onClose : noop}
                onCloseComplete={handleTransition}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader>{t('devices.edit.header')}</DrawerHeader>
                    <DrawerBody>{renderForm()}</DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}
