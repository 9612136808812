import { Button } from '@missionlabs/react'
import { ArrowDownIconRegular } from '@missionlabs/react/zeta'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface NewMessageFABProps {
    count: number
    onClick: () => void
}

export const NewMessageFAB: FC<NewMessageFABProps> = ({ count, onClick }) => {
    const { t } = useTranslation()

    return (
        <Button
            size="sm"
            position="absolute"
            top="8px"
            left={0}
            right={0}
            mx="auto"
            zIndex={1}
            rounded="full"
            onClick={onClick}
            leftIcon={<ArrowDownIconRegular />}
            w="200px"
        >
            {t('chat.newMessages', { count })}
        </Button>
    )
}
