import { avatarAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
    ...avatarAnatomy.keys,
    'icon',
    'status'
])

export const baseStyle = definePartsStyle({
    container: defineStyle({
        boxSize: '100%',
        borderRadius: '8px',
        bgColor: 'light.tones.stormGrey',
        overflow: 'hidden'
    }),
    status: defineStyle({
        position: 'absolute',
        border: '1.2px solid',
        borderColor: 'light.tones.white',
        borderRadius: '50%',
        top: '-16px',
        right: '-16px'
    }),
    icon: defineStyle({ boxSize: '100%' })
})

export const sizes = {
    sm: definePartsStyle({
        label: defineStyle({ fontSize: '12px', fontWeight: 700, lineHeight: '15px' }),
        container: defineStyle({ boxSize: '88px' }),
        status: defineStyle({ boxSize: '16px' }),
        icon: defineStyle({ pt: '11px', px: '8.25px' })
    }),
    md: definePartsStyle({
        label: defineStyle({ fontSize: '12px', fontWeight: 700, lineHeight: '15px' }),
        container: defineStyle({ boxSize: '128px' }),
        status: defineStyle({ boxSize: '16px' }),
        icon: defineStyle({ pt: '16px', px: '12px' })
    })
}

export const variants = {
    stormGrey: definePartsStyle({
        container: defineStyle({
            bgColor: 'light.tones.stormGrey',
            color: 'light.tones.periwinkle'
        })
    }),
    whiteLilac: definePartsStyle({
        container: defineStyle({ bgColor: 'light.tones.whiteLilac', color: 'light.tones.navy' })
    }),
    navy: definePartsStyle({
        container: defineStyle({ bgColor: 'light.tones.navy' })
    }),
    red: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.red' })
    }),
    bloodOrange: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.bloodOrange' })
    }),
    orange: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.orange' })
    }),
    amber: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.amber' })
    }),
    yellow: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.yellow' })
    }),
    lime: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.lime' })
    }),
    green: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.green' })
    }),
    aqua: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.aqua' })
    }),
    teal: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.teal' })
    }),
    blue: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.blue' })
    }),
    indigo: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.indigo' })
    }),
    purple: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.purple' })
    }),
    violet: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.violet' })
    }),
    pink: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.pink' })
    }),
    hotPink: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.hotPink' })
    }),
    rose: definePartsStyle({
        container: defineStyle({ bgColor: 'light.avatar.rose' })
    })
}

export const HeaderAvatarStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle,
    defaultProps: { size: 'md', variant: 'stormGrey' },
    sizes,
    variants
})
