import { DirectoryEntry } from '@missionlabs/types'
import { categorisePhoneNumbers, inferFirstLastName } from '@missionlabs/utils'
import vCardsJS from 'vcards-js'

export const vCardGenerator = (contact: DirectoryEntry) => {
    const vCard = vCardsJS()

    const { firstName, lastName, fullName } = inferFirstLastName(contact)
    const phoneNumbers = categorisePhoneNumbers(contact.phoneNumbers)

    vCard.firstName = `${firstName}`
    vCard.lastName = `${lastName}`
    vCard.organization = `${contact.companyName}`
    if (contact.emails?.length) {
        vCard.email = contact.emails.map(item => item.email)
    }

    vCard.homePhone = phoneNumbers.home
    vCard.cellPhone = phoneNumbers.mobile
    vCard.workPhone = phoneNumbers.work
    vCard.otherPhone = phoneNumbers.other

    const fileName = `${fullName}.vcf`
    const vcardData = new Blob([vCard?.getFormattedString()], { type: 'text/vcard' })
    const vcardUrl = URL.createObjectURL(vcardData)

    const link = document.createElement('a')

    link.href = vcardUrl
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
