import { Box } from '@chakra-ui/react'
import { SearchFilter } from '@missionlabs/api'
import { IconButton, ListTitle, Scrollable, useFieldArray } from '@missionlabs/react'
import { XIconRegular } from '@missionlabs/react/zeta'
import { AttendeeDetails, DirectoryEntry } from '@missionlabs/types'
import { alphabetizeContacts } from '@missionlabs/utils'
import { ContactsItemSkeleton } from 'features/contacts/ContactsList/ContactsItemSkeleton'
import { ContactsRow } from 'features/contacts/ContactsList/ContactsRow'
import { cloneElement, FC, ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList } from 'react-window'
import { useDelayedContent } from 'shared/hooks/useDelayedContent'
import { usePaginatedContacts } from 'shared/hooks/usePaginatedContacts'

import { AddEditMeetingFormFields } from './AddEditMeetingDrawer'
import { VirtualisedMeetingAttendeeItem } from './MeetingAttendeeRowRenderer'
import { ShareAttendeeRowRenderer } from './ShareAttendeeRowRenderer'

export interface AddAttendeesListProps {
    searchedContacts: DirectoryEntry[]
    clearSearch: () => void
    scrollElement: ReactElement
    noSearchResults: boolean
    filterParams: SearchFilter[]
    siblingHeight: number
}

export const DirectoryList: FC<AddAttendeesListProps> = ({
    searchedContacts,
    clearSearch,
    scrollElement,
    noSearchResults,
    filterParams
}) => {
    const { t } = useTranslation()

    const { fields, append, remove } = useFieldArray<AddEditMeetingFormFields>({
        name: 'invitedUsers'
    })

    const { fields: alreadyInvited } = useFieldArray<{ alreadyInvitedUsers: AttendeeDetails[] }>({
        name: 'alreadyInvitedUsers'
    })

    const { showDelayedContent } = useDelayedContent({ delay: noSearchResults })

    const pageOptions = useMemo(() => {
        return { filter: filterParams }
    }, [filterParams])

    const {
        contacts = [],
        isLoading,
        onItemsRendered,
        setItemCount
    } = usePaginatedContacts(pageOptions)

    const isAdded = useCallback(
        (contact: DirectoryEntry) => {
            return (
                fields.some(item => item.externalID === contact.externalID) ||
                alreadyInvited.some(item => item.userID === contact.externalID)
            )
        },
        [fields, alreadyInvited]
    )

    const handleToggle = useCallback(
        (contact: DirectoryEntry) => {
            const index = fields.findIndex(item => item.externalID === contact.externalID)
            if (index !== -1) remove(index)
            else append(contact)
        },
        [fields, remove, append]
    )

    const groupedContacts = useMemo(() => {
        const alphabetized = alphabetizeContacts(contacts, {
            sortField: 'firstName'
        })

        return [...Object.entries(alphabetized)]
    }, [contacts])

    const virtualisedItems: VirtualisedMeetingAttendeeItem[] = useMemo(() => {
        return groupedContacts.reduce((prev, curr) => {
            const [letter, contacts] = curr

            // Skip adding group if it has no contacts.
            if (contacts.length < 1) return prev
            return [
                ...prev,
                letter,
                ...(contacts as string[] | DirectoryEntry[]).map(contact => {
                    const isAlreadyInvited = alreadyInvited.some(
                        ({ userID }) => contact.externalID === userID
                    )
                    return {
                        ...{
                            companyName: t('Unknown'),
                            ...contact,
                            fullName: typeof contact === 'string' ? contact : contact.fullName
                        },
                        handleToggle: isAlreadyInvited ? () => {} : handleToggle,
                        isAdded,
                        isExistingAttendee: isAlreadyInvited
                    }
                })
            ]
        }, [] as (string | DirectoryEntry)[])
    }, [groupedContacts, alreadyInvited, handleToggle, isAdded, t])

    const rowHeights = useMemo(
        () => virtualisedItems.map(item => (typeof item === 'string' ? 57 : 79)),
        [virtualisedItems]
    )

    useEffect(() => {
        setItemCount(virtualisedItems.length)
    }, [virtualisedItems, setItemCount])

    if (isLoading) return <ContactsItemSkeleton />

    if (searchedContacts.length) {
        return (
            <div data-testid="contact-group-member-list" role="list" style={{ height: '100%' }}>
                {cloneElement(scrollElement, {
                    children: (
                        <>
                            <ListTitle
                                role="listitem"
                                rightElement={
                                    <IconButton
                                        icon={<XIconRegular />}
                                        aria-label="Clear results"
                                        variant="transparent"
                                        onClick={clearSearch}
                                    />
                                }
                            >
                                {t('Results')}
                            </ListTitle>
                            {searchedContacts.map(item => (
                                <ContactsRow
                                    key={item.ID}
                                    data={item}
                                    isActive={isAdded(item)}
                                    renderTick
                                    onClick={() => handleToggle(item)}
                                />
                            ))}
                        </>
                    )
                })}
            </div>
        )
    }

    if (showDelayedContent) {
        return (
            <div data-testid="contact-group-member-list" role="list" style={{ height: '100%' }}>
                {cloneElement(scrollElement, {
                    children: (
                        <>
                            <ListTitle
                                role="listitem"
                                rightElement={
                                    <IconButton
                                        icon={<XIconRegular />}
                                        aria-label="Clear results"
                                        variant="transparent"
                                        onClick={clearSearch}
                                    />
                                }
                            >
                                {t('No results found')}
                            </ListTitle>
                        </>
                    )
                })}
            </div>
        )
    }

    return (
        <Box w="full" h={`100%`} role="list">
            <AutoSizer>
                {({ height, width }) => (
                    <VariableSizeList
                        height={height}
                        width={width}
                        itemSize={index => rowHeights[index]}
                        itemCount={virtualisedItems.length}
                        itemData={virtualisedItems}
                        onItemsRendered={onItemsRendered}
                        outerElementType={Scrollable}
                    >
                        {ShareAttendeeRowRenderer}
                    </VariableSizeList>
                )}
            </AutoSizer>
        </Box>
    )
}
