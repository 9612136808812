import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react'
import {
    Call,
    focusCall,
    SearchQueryArgs,
    selectCalls,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { DirectoryEntry } from '@missionlabs/types'
import {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState
} from 'react'
import { useContactsSearch } from 'shared/hooks/useContactsSearch'
import { useDialState, UseDialStateReturn } from 'shared/hooks/useDialState'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetPresence } from 'shared/hooks/useGetPresence'
import { useGetUserData } from 'shared/hooks/useGetUserData'
import { focusMessage, removeEmptyMessages } from 'shared/slices/messagingSlice'

import { DrawerMode } from '../ContactDrawer'

interface IContactDrawerContext {
    drawer: UseDisclosureReturn & {
        onOpen: (mode?: DrawerMode, id?: string) => void
    }
    mode: DrawerMode

    value: string
    setValue: Dispatch<SetStateAction<string>>
    calls: Call[]
    dialState: UseDialStateReturn
    contacts: DirectoryEntry[]
    searchContacts: (args: SearchQueryArgs) => void
    foundContact?: DirectoryEntry
    setFoundContact: (contact?: DirectoryEntry) => void
    setMode: (mode: DrawerMode) => void
}

const ContactDrawerContext = createContext<IContactDrawerContext | null>(null)

export const ContactDrawerProvider = ({ children }: PropsWithChildren) => {
    const dispatch = useDispatch()
    const { formatToNumberE164 } = useFormatToNumberE164()

    const { user } = useGetUserData()
    const { isUserInCallOnOtherDevice, callsOnOtherDevices } = useGetPresence(user?.ID)

    /** The main drawer that contains all views for dialler, incoming call, etc. */
    const drawer = useDisclosure()
    const { onOpen: drawerOnOpen, onClose: drawerOnClose } = drawer

    /** Drawer can have different "modes" - Voice, Dialler and SMS */
    const [mode, setMode] = useState(DrawerMode.DIALLER)

    /** TODO: These two should really be in a separate context just for the dialpad */
    const [value, setValue] = useState<string>('')
    const [foundContact, setFoundContact] = useState<DirectoryEntry>()

    // Need to know which call trace is selected for calls on other devices.
    // These are not stored in the calls slice, so we cant use `selectFocusedCall`
    const [selectedCallTraceID, setSelectedCallTraceID] = useState<string>('')

    const callsSlice = useSelector(selectCalls)
    const dialState = useDialState()

    const [searchContacts, { data: contacts }] = useContactsSearch()

    const calls = useMemo(() => {
        const mappedCallsOnOtherDevice = callsOnOtherDevices?.map(sub => {
            return {
                ...(sub.metadata as unknown as Call),
                status: sub.metadata.onHold === 'false' ? 'CONNECTED' : 'ON_HOLD'
            }
        })
        return [...callsSlice, ...(mappedCallsOnOtherDevice ?? [])]
    }, [callsSlice, callsOnOtherDevices])

    useEffect(() => {
        if (foundContact && !contacts.length) return
        if (
            contacts.length === 1 && // if the search returns one user and the number matches the value -> show the contacts name before search
            contacts[0].phoneNumbers?.map(n => n.numberE164).includes(formatToNumberE164(value))
        ) {
            setFoundContact(contacts[0])
        } else {
            setFoundContact(undefined)
        }
    }, [contacts, value, setFoundContact, formatToNumberE164, foundContact])

    const onOpenDrawer = useCallback(
        (_mode?: DrawerMode, id?: string) => {
            drawerOnOpen()
            if (_mode) setMode(_mode)
            dispatch(focusMessage(id))
            setSelectedCallTraceID(id ?? '')
            if (id) dispatch(focusCall({ callTraceID: id }))
        },
        [drawerOnOpen, setMode, dispatch]
    )

    const onCloseDrawer = useCallback(() => {
        dispatch(removeEmptyMessages())
        dispatch(focusMessage())
        setSelectedCallTraceID('')
        drawerOnClose()
    }, [drawerOnClose, dispatch])

    const { view, setDialState } = dialState
    const isCancelledCall = view === 'cancelledCall'

    // This effect is responsible for setting the view of the dialler
    // i.e. show details of call happening on another device when detected
    // or show the calls view when a call is incoming
    useLayoutEffect(() => {
        if (isUserInCallOnOtherDevice) {
            drawerOnOpen()
            setDialState({
                view: 'inCallOnOtherDevice',
                callDetails: callsOnOtherDevices?.find(
                    call => call.metadata.callTraceID === selectedCallTraceID
                )?.metadata as any
            })
        } else if (calls.length) {
            // If the user has calls, we should always show the calls view
            drawerOnOpen()
            setDialState({ view: 'calls' })
        } else if (view !== 'cancelledCall') {
            // If the user has no calls, and the current state is not a cancelled call, we should show the dialpad
            setDialState({ view: 'dialpad' })
        }
    }, [
        calls.length,
        isCancelledCall,
        isUserInCallOnOtherDevice,
        view,
        callsOnOtherDevices,
        selectedCallTraceID,
        setDialState,
        drawerOnOpen
    ])

    const contextValue = useMemo(
        () => ({
            drawer: {
                ...drawer,
                onClose: onCloseDrawer,
                onOpen: onOpenDrawer
            },
            mode,
            value,
            setValue,
            setMode,
            calls,
            dialState,
            contacts: contacts ?? [],
            searchContacts,
            foundContact,
            setFoundContact
        }),
        [
            drawer,
            onOpenDrawer,
            onCloseDrawer,
            mode,
            value,
            calls,
            dialState,
            contacts,
            searchContacts,
            foundContact
        ]
    )

    return (
        <ContactDrawerContext.Provider value={contextValue}>
            {children}
        </ContactDrawerContext.Provider>
    )
}

export const useContactDrawerState = () => {
    const context = useContext(ContactDrawerContext)
    if (!context)
        throw new Error('useContactDrawerState must be used within a ContactDrawerProvider')
    return context
}
