import { HStack, Skeleton, StackDivider, useColorMode } from '@chakra-ui/react'
import { formatCurrency } from '@missionlabs/utils'
import { format } from 'date-fns'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetSummaryQuery } from 'shared/store'

import { BillingFigures } from './BillingFigures'

export const BillingOverview: FC = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const { data, isLoading } = useGetSummaryQuery()

    const summary = useMemo(() => {
        if (!data) return
        const { currentTerm, users, numbers, currencyCode, callCosts, estimatedBillPence } = data
        const usageStart = format(currentTerm.termStart * 1000, 'MMMM do')
        const usageEnd = format(currentTerm.termEnd * 1000, 'MMMM do yyyy')
        return {
            users: users.quantity,
            numbers: numbers.quantity,
            usageDate: `${usageStart} - ${usageEnd}`,
            callCosts: formatCurrency({
                currency: callCosts.currencyCode || currencyCode,
                amount: Number(callCosts.total)
            }),
            estimatedBill: formatCurrency({
                currency: currencyCode,
                amount: estimatedBillPence / 100
            })
        }
    }, [data])

    return (
        <HStack
            spacing={0}
            align="center"
            py="24px"
            border="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
            borderRadius="4px"
            data-testid="billing-overview"
            divider={<StackDivider />}
        >
            <BillingFigures
                data={[
                    { title: t('Users'), value: summary?.users },
                    { title: t('Numbers'), value: summary?.numbers }
                ]}
                title={t('admin.billing.overview')}
                isLoading={isLoading}
            />
            <BillingFigures
                data={[
                    { title: t('admin.billing.callsThisPeriod'), value: summary?.callCosts },
                    { title: t('admin.billing.estimatedInvoice'), value: summary?.estimatedBill }
                ]}
                title={
                    <Skeleton h="24px" w="full" isLoaded={!isLoading}>
                        {t('admin.billing.usage', { date: summary?.usageDate })}
                    </Skeleton>
                }
                isLoading={isLoading}
            />
        </HStack>
    )
}
