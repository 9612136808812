import {
    Box,
    Radio as ChakraRadio,
    RadioProps as ChakraRadioProps,
    useMultiStyleConfig
} from '@chakra-ui/react'
import { Label, LabelProps } from 'molecules/Label/Label'
import { FC, PropsWithChildren } from 'react'

export interface RadioProps extends Omit<ChakraRadioProps, 'children'> {
    labelProps?: Omit<LabelProps, 'children' | 'onChange' | 'size' | 'text'>
    variant?: 'destructive' | 'warning' | 'creationary'
}

export const Radio: FC<PropsWithChildren<RadioProps>> = ({
    children,
    labelProps,
    variant,
    ...props
}) => {
    const styles = useMultiStyleConfig('Radio', { variant })

    return (
        <Box sx={{ ...styles.container }}>
            <ChakraRadio {...props} sx={{ ...styles.control, ...(props?.sx ?? {}) }}>
                {typeof children === 'string' ? (
                    <Label
                        textProps={{ color: styles.label.color as string }}
                        {...labelProps}
                        text={children}
                    />
                ) : (
                    children
                )}
            </ChakraRadio>
        </Box>
    )
}
