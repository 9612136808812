import { useColorMode, useDisclosure, VStack } from '@chakra-ui/react'
import {
    selectActiveMeeting,
    selectPreferences,
    setNumberOfTiles,
    updatePreferences,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { useBackgroundFilter, useNoiseSuppression } from '@missionlabs/meetings'
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Dropdown,
    FormControl,
    Heading,
    Slider,
    useInputMonitor
} from '@missionlabs/react'
import {
    FileAudioIconSolid,
    Grid9IconSolid,
    MicrophoneIconRegular,
    SpeakerIconSolid,
    VideoIconRegular
} from '@missionlabs/react/zeta'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { isChime, useDevicesContext } from 'shared/components/DevicesContext'

import { useBackgroundFilterOptions } from './hooks/useBackgroundFilterOptions'
import { useFeedLayoutOptions } from './hooks/useFeedLayoutOptions'

export const MeetingsCallSettingsDrawer: FC = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ctx = useDevicesContext()
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    const _meeting = useSelector(selectActiveMeeting)
    const preferences = useSelector(selectPreferences)

    const backgroundFilterOptions = useBackgroundFilterOptions()
    const _feedLayoutOptions = useFeedLayoutOptions()

    const inputVolume = useInputMonitor(
        ctx.inputDeviceID,
        ctx.inputDevices.find(device => device.value === ctx.inputDeviceID)?.label
    )

    const { isBackgroundBlurSupported } = useBackgroundFilter(
        handleBackgroundFilterChange,
        undefined,
        !!ctx.cameraDeviceID
    )

    const { isVoiceFocusEnabled, isVoiceFocusSupported, onChangeNoiseSuppression } =
        useNoiseSuppression(preferences?.videoNoiseSuppression)

    function handleBackgroundFilterChange(newFilter: string) {
        dispatch(updatePreferences({ videoBackgroundFilter: newFilter }))
    }

    function handleCameraChange(value: string) {
        dispatch(updatePreferences({ cameraDeviceID: value }))
    }

    function _handleFeedLayoutChange(value: number) {
        dispatch(setNumberOfTiles(value))
    }

    function handleMicrophoneChange(value: string) {
        if (isChime(ctx)) ctx.handleChangeAudioInput(value)
        dispatch(updatePreferences({ inputDeviceID: value }))
    }

    function handleNoiseCancellationChange(value: boolean) {
        onChangeNoiseSuppression(value)
        dispatch(updatePreferences({ videoNoiseSuppression: value }))
    }

    function handleOutputChange(value: string) {
        if (isChime(ctx)) ctx.handleChangeAudioOutput(value, preferences?.audioVolume)
        dispatch(updatePreferences({ outputDeviceID: value }))
    }

    function handleOutputVolumeChange(value: number) {
        if (isChime(ctx) && ctx.outputDeviceID) {
            ctx.handleChangeAudioOutput(ctx.outputDeviceID, value)
        }
        dispatch(updatePreferences({ audioVolume: value }))
    }

    function handleTransition() {
        navigate('..')
    }

    return (
        <Drawer size="sm" isOpen={isOpen} onClose={onClose} onCloseComplete={handleTransition}>
            <DrawerOverlay />
            <DrawerContent mt="64px">
                <DrawerHeader>{t('settings.title')}</DrawerHeader>
                <DrawerBody px="24px" bg={`${colorMode}.tones.ghostWhite`}>
                    <Heading size="h3" py="22px" fontSize="16px">
                        {t('Video Settings')}
                    </Heading>
                    <VStack spacing="16px">
                        <FormControl label={t('Camera')}>
                            <Dropdown
                                value={ctx.cameraDeviceID}
                                options={ctx.cameraDevices}
                                placeholder={t('No device selected')}
                                isDisabled={ctx.cameraDevices.length <= 0}
                                leftIcon={<VideoIconRegular boxSize="20px" p="2px" />}
                                onChange={handleCameraChange}
                            />
                        </FormControl>
                        <FormControl label={t('Background')}>
                            <Dropdown
                                layout="grid"
                                value={preferences?.videoBackgroundFilter}
                                options={backgroundFilterOptions}
                                placeholder={t('None')}
                                isDisabled={
                                    ctx.cameraDevices.length <= 0 || !isBackgroundBlurSupported
                                }
                                leftIcon={<Grid9IconSolid boxSize="20px" p="3.33px" />}
                                onChange={handleBackgroundFilterChange}
                            />
                        </FormControl>
                        {/*
                        * Disabling feed layout options for MVP
                        <FormControl label={t('Feed layout')}>
                            <Dropdown
                                value={meeting?.numberOfTiles ?? 0}
                                options={feedLayoutOptions}
                                leftIcon={<GridIconRegular boxSize="20px" p="3.33px" />}
                                onChange={handleFeedLayoutChange}
                            />
                            </FormControl>
                         */}
                    </VStack>
                    <Heading size="h3" py="22px" fontSize="16px">
                        {t('Audio Settings')}
                    </Heading>
                    <VStack spacing="16px">
                        <FormControl label={t('Microphone')}>
                            <Dropdown
                                value={ctx.inputDeviceID}
                                options={ctx.inputDevices}
                                placeholder={t('No device selected')}
                                isDisabled={ctx.inputDevices.length <= 0}
                                leftIcon={<MicrophoneIconRegular boxSize="20px" p="3.33px" />}
                                onChange={handleMicrophoneChange}
                            />
                        </FormControl>
                        <Slider
                            value={inputVolume}
                            min={1}
                            step={10}
                            max={100}
                            isDisabled={ctx.inputDevices.length <= 0}
                            isReadOnly
                            aria-label="Microphone level"
                        />
                        <FormControl label={t('Background noise cancellation')}>
                            <Dropdown
                                value={isVoiceFocusEnabled}
                                options={[
                                    { label: t('Disabled'), value: false },
                                    { label: t('Enabled'), value: true }
                                ]}
                                isDisabled={!isVoiceFocusSupported}
                                leftIcon={<FileAudioIconSolid boxSize="20px" p="3.33px" />}
                                onChange={handleNoiseCancellationChange}
                            />
                        </FormControl>
                        <FormControl label={t('Audio output')}>
                            <Dropdown
                                value={ctx.outputDeviceID}
                                options={ctx.outputDevices}
                                placeholder={t('No device selected')}
                                isDisabled={ctx.outputDevices.length <= 0}
                                leftIcon={<SpeakerIconSolid boxSize="20px" p="3.33px" />}
                                onChange={handleOutputChange}
                            />
                        </FormControl>
                        <Slider
                            value={preferences?.audioVolume}
                            min={0}
                            step={0.1}
                            max={1}
                            isDisabled={ctx.outputDevices.length <= 0}
                            aria-label="Output volume"
                            sx={{ padding: 'none' }}
                            onChange={handleOutputVolumeChange}
                        />
                    </VStack>
                    {/* todo */}
                    {/*<Button size="lg" mt="24px" w="full">*/}
                    {/*    Push video to another device*/}
                    {/*</Button>*/}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
