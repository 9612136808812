import { BusyForwardType, UserCallRoute, Voicemail } from '@missionlabs/types'
import { useSettingsContext } from 'features/settings/components/settingsContext'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { FC } from 'react'

import { LineBusyRadio } from './LineBusyRadio'

function getVoicemail(route: UserCallRoute, busy?: BusyForwardType, voicemail?: Voicemail) {
    return {
        ...route.busyVoicemail,
        ...voicemail,
        status: busy === 'voicemail' ? 'active' : 'inactive',
        transcribe: true
    }
}

export const LineBusySetting: FC = () => {
    const { userCallRoute: callRoute, onUpdateUserCallRoute } = useSettingsContext()

    const onUpdate = (payload: Partial<UserCallRoute>) => {
        if (!callRoute) return

        return onUpdateUserCallRoute?.({
            callTimeout: payload.callTimeout,
            always: payload.always,
            alwaysRedirect: payload.busyRedirect,
            busy: payload.busy,
            busyRedirect: payload.busyRedirect,
            busyRedirectInternal: payload.busyRedirectInternal,
            busyVoicemail: getVoicemail(callRoute, payload.busy, payload.busyVoicemail)
        })
    }

    if (!callRoute) return null

    return (
        <SettingsRow label="">
            <LineBusyRadio callRoute={callRoute} onUpdate={onUpdate} />
        </SettingsRow>
    )
}
