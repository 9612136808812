import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const SnoozeIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SnoozeIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M9.99976 10.9999H12.6298L8.90976 15.3499C8.35976 15.9999 8.81976 16.9999 9.66976 16.9999H13.9998C14.5498 16.9999 14.9998 16.5499 14.9998 15.9999C14.9998 15.4499 14.5498 14.9999 13.9998 14.9999H11.3698L15.0898 10.6499C15.6398 9.9999 15.1798 8.9999 14.3298 8.9999H9.99976C9.44976 8.9999 8.99976 9.4499 8.99976 9.9999C8.99976 10.5499 9.44976 10.9999 9.99976 10.9999ZM21.2998 6.4199C20.9498 6.8399 20.3198 6.8999 19.8898 6.5499L16.8198 3.9899C16.3998 3.6299 16.3398 2.9999 16.6998 2.5799C17.0498 2.1599 17.6798 2.0999 18.1098 2.4499L21.1798 5.0099C21.5998 5.3699 21.6598 5.9999 21.2998 6.4199ZM2.69976 6.4199C3.04976 6.8499 3.67976 6.8999 4.09976 6.5499L7.16976 3.9899C7.59976 3.6299 7.65976 2.9999 7.29976 2.5799C6.94976 2.1499 6.31976 2.0999 5.89976 2.4499L2.81976 5.0099C2.39976 5.3699 2.33976 5.9999 2.69976 6.4199V6.4199ZM11.9998 5.9999C15.8598 5.9999 18.9998 9.1399 18.9998 12.9999C18.9998 16.8599 15.8598 19.9999 11.9998 19.9999C8.13976 19.9999 4.99976 16.8599 4.99976 12.9999C4.99976 9.1399 8.13976 5.9999 11.9998 5.9999ZM11.9998 3.9999C7.02976 3.9999 2.99976 8.0299 2.99976 12.9999C2.99976 17.9699 7.02976 21.9999 11.9998 21.9999C16.9698 21.9999 20.9998 17.9699 20.9998 12.9999C20.9998 8.0299 16.9698 3.9999 11.9998 3.9999Z"
            fill="currentColor"
        />
    )
})
