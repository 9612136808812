import {
    Box,
    HStack,
    Table as ChakraTable,
    TableContainer,
    Tbody,
    Td,
    Tfoot,
    Th,
    Thead,
    Tr,
    useMultiStyleConfig
} from '@chakra-ui/react'
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    SortingState,
    Updater,
    useReactTable,
    VisibilityState
} from '@tanstack/react-table'
import { Body, Button, Spinner } from 'atoms'
import { ChevronDownIconSolid, ChevronUpIconSolid } from 'atoms/Icons/zeta'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

type AnyObj = Record<string, any>

export { type PaginationState, type RowSelectionState } from '@tanstack/react-table'

export type TableProps<Data extends AnyObj> = {
    data: Data[]
    columns: ColumnDef<Data, any>[]
    isLoading?: boolean
    paginate?: boolean | 'manual'
    pageCount?: number
    onPaginationChange?: (data: PaginationState) => void
    sorting?: SortingState
    onRowClicked?: (data: Data) => void
    columnVisibility?: VisibilityState
}

const PAGE_SIZE = 10

export function Table<Data extends AnyObj>({
    data,
    columns,
    isLoading = false,
    paginate = false,
    pageCount: _pageCount,
    onPaginationChange,
    sorting: _sorting,
    onRowClicked,
    columnVisibility
}: TableProps<Data>) {
    const { t } = useTranslation()

    const styles = useMultiStyleConfig('Table')

    // related github issue: https://github.com/TanStack/table/issues/4423
    // TODO: accessorKey is currently not an property in ColumnDef but it looks to be added soon so remove the ts-ignore
    const [sorting, setSorting] = useState<SortingState>(
        // @ts-ignore
        _sorting ?? [{ id: columns[0]?.accessorKey ?? '', desc: false }]
    )
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: paginate ? PAGE_SIZE : data.length
    })

    const pageCount = Math.ceil((_pageCount ?? data.length) / pageSize) || -1

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize])

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        pageCount,
        onPaginationChange: handlePaginationChange,
        getPaginationRowModel:
            !paginate || paginate === 'manual' ? undefined : getPaginationRowModel(),
        manualPagination: paginate === 'manual',
        state: { sorting, pagination, columnVisibility }
    })

    const hasFooter = table.getFlatHeaders().some(v => 'footer' in v.column.columnDef)

    function handlePaginationChange(updater: Updater<PaginationState>) {
        const value = typeof updater === 'function' ? updater({ pageIndex, pageSize }) : updater
        setPagination(value)
        onPaginationChange?.(value)
    }

    useEffect(() => {
        // update pageSize if not paginating to ensure all rows are rendered
        if (!paginate && pageSize !== data.length) {
            setPagination(prev => ({ ...prev, pageSize: data.length }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.length])

    return (
        <TableContainer w="full">
            <ChakraTable>
                <Thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <Th
                                        key={header.id}
                                        w={header.column.columnDef.size ?? 'auto'}
                                        textAlign={header.column.columnDef.meta?.['align']}
                                        onClick={header.column.getToggleSortingHandler()}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {header.column.getIsSorted() ? (
                                                    <Box as="span" pl={3}>
                                                        {header.column.getIsSorted() === 'desc' ? (
                                                            <ChevronDownIconSolid
                                                                aria-label="sorted descending"
                                                                sx={{ ...styles.sortIcon }}
                                                            />
                                                        ) : (
                                                            <ChevronUpIconSolid
                                                                aria-label="sorted ascending"
                                                                sx={{ ...styles.sortIcon }}
                                                            />
                                                        )}
                                                    </Box>
                                                ) : null}
                                            </>
                                        )}
                                    </Th>
                                )
                            })}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map(row => (
                        <Tr key={row.id} data-testid={row.original.ID ?? row.id}>
                            {row.getVisibleCells().map(cell => {
                                const hasClickHandler = typeof onRowClicked !== 'undefined'

                                // allow columns to be marked as non-clickable
                                // with meta: { clickable: false }
                                // so that `onRowClicked` isn't fired when clicking in the cell
                                // this is handy for when there are clickable elements in the cell i.e. a toggle
                                const explicitNonClickable =
                                    cell.column.columnDef.meta?.['clickable'] === false

                                const isClickable = hasClickHandler && !explicitNonClickable

                                return (
                                    <Td
                                        key={cell.id}
                                        padding={cell.column.columnDef.meta?.['padding']}
                                        textAlign={cell.column.columnDef.meta?.['align']}
                                        cursor={isClickable ? 'pointer' : undefined}
                                        onClick={() => {
                                            if (isClickable) onRowClicked(row.original)
                                        }}
                                        maxWidth={cell.column.columnDef.meta?.['maxWidth']}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                )
                            })}
                        </Tr>
                    ))}
                </Tbody>
                {!hasFooter ? null : (
                    <Tfoot>
                        {table.getFooterGroups().map(footerGroup => (
                            <Tr key={footerGroup.id}>
                                {footerGroup.headers.map(footer => {
                                    return (
                                        <Th
                                            key={footer.id}
                                            w={footer.column.columnDef.size ?? 'auto'}
                                            textAlign={footer.column.columnDef.meta?.['align']}
                                        >
                                            {footer.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      footer.column.columnDef.footer,
                                                      footer.getContext()
                                                  )}
                                        </Th>
                                    )
                                })}
                            </Tr>
                        ))}
                    </Tfoot>
                )}
            </ChakraTable>
            {paginate && pageCount > 1 && (
                <HStack justify="space-between" spacing={0} px={3} py={4}>
                    <Button
                        variant="link-underline"
                        onClick={() => table.previousPage()}
                        isDisabled={isLoading || !table.getCanPreviousPage()}
                    >
                        {t('Previous')}
                    </Button>
                    <HStack spacing="4px">
                        <Body size="sm" sx={styles.pageCount}>
                            {t('pageCount', { page: pageIndex + 1, total: pageCount })}
                        </Body>
                        <Spinner size="sm" visibility={isLoading ? 'visible' : 'hidden'} />
                    </HStack>
                    <Button
                        variant="link-underline"
                        onClick={() => table.nextPage()}
                        isDisabled={isLoading || !table.getCanNextPage()}
                    >
                        {t('Next')}
                    </Button>
                </HStack>
            )}
        </TableContainer>
    )
}
