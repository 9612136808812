import { useColorMode, useDisclosure, VStack } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import {
    ExtensionMenu,
    ExtensionTeam,
    ExtensionUser,
    isUser,
    IVRMenu,
    UserOrTeam
} from '@missionlabs/types'
import { inferFirstLastName, isMenu, isTeam, sort } from '@missionlabs/utils'
import { UTMDrawer } from 'features/settings/components/UTMDrawer'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { APP_BRAND, AppConfig } from 'shared/utils/env'

import { buildIVROptionsArray } from '../utils/ivr'
import { IVRMenuOption } from './IVRMenuOption'
import { NewIVRMenuOption } from './NewIVRMenuOption'

interface IVRMenuOptionsProps {
    extensions: IVRMenu['IVR']['extensions']
    onUpdateExtensions: (extensions: IVRMenu['IVR']['extensions']) => void
}

type UserTeamOrMenu = ExtensionUser | ExtensionTeam | ExtensionMenu
type EditingKeyState = { index: string; data: UserTeamOrMenu }

const { MIN_KEY, MAX_KEY, ALLOW_SYMBOLS } =
    AppConfig.brand === APP_BRAND.CIRCLELOOP
        ? {
              MIN_KEY: 0,
              MAX_KEY: 6,
              ALLOW_SYMBOLS: false
          }
        : {
              MIN_KEY: 0,
              MAX_KEY: 9,
              ALLOW_SYMBOLS: true
          }

export function IVRMenuOptions({ extensions = {}, onUpdateExtensions }: IVRMenuOptionsProps) {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const utmDrawer = useDisclosure()

    // Which is the current key being edited, and what is its existing value?
    const [editingKey, setEditingKey] = useState<EditingKeyState | undefined>()

    const menuOptions = useMemo(() => {
        return Object.entries(extensions)
            .filter(ex => Number(ex[0]) <= MAX_KEY)
            .sort(([a], [b]) => sort(a, b))
    }, [extensions])
    // The keys that are not currently in use
    const availableKeys = useCallback(
        (currentKey?: string): string[] => getAvailableKeys(extensions, currentKey),
        [extensions]
    )

    const updateKey = (index: string, data: UserOrTeam) => {
        const updatedExtensions = { ...extensions }
        if (isUser(data)) {
            const { fullName } = inferFirstLastName(data)
            updatedExtensions[index] = { userID: data.ID, display: fullName }
        }
        if (isMenu(data)) updatedExtensions[index] = { menuID: data.ID, display: data.name }
        if (isTeam(data)) updatedExtensions[index] = { teamID: data.ID, display: data.name }
        onUpdateExtensions(updatedExtensions)
    }

    // Change a key's index from one to another
    const swapKeyIndex = (currentIndex: string, newIndex: string) => {
        const updatedExtensions = { ...extensions }
        updatedExtensions[newIndex] = updatedExtensions[currentIndex]
        delete updatedExtensions[currentIndex]
        onUpdateExtensions(updatedExtensions)
    }

    const deleteKey = (index: string) => {
        const updatedExtensions = { ...extensions }
        delete updatedExtensions[index]
        onUpdateExtensions(updatedExtensions)
    }

    const startEditingKey = (index: string, data: UserTeamOrMenu) => {
        setEditingKey({ index, data })
        utmDrawer.onOpen()
    }

    const stopEditingKey = () => {
        setEditingKey(undefined)
        utmDrawer.onClose()
    }

    return (
        <VStack align="flex-start" gap={6}>
            <VStack align="flex-start" gap={2}>
                <Body variant="bold">{t('admin.menus.optionsTitle')}</Body>
                <Body size="sm" variant="bold" color={`${colorMode}.tones.stormGrey`}>
                    {t('admin.menus.optionsSubtitle')}
                </Body>
            </VStack>

            <VStack align="flex-start" alignSelf="stretch" gap={4}>
                {menuOptions.map(([index, value]) => (
                    <IVRMenuOption
                        key={index}
                        index={index}
                        display={value.display}
                        availableKeys={availableKeys(index)}
                        onSwap={newIndex => swapKeyIndex(index, newIndex)}
                        onEdit={() => startEditingKey(index, value as UserTeamOrMenu)}
                        onDelete={() => deleteKey(index)}
                    />
                ))}

                <NewIVRMenuOption availableKeys={availableKeys()} onSubmit={updateKey} />
            </VStack>

            {/* User, teams and menus drawer used to select which destination to point a menu option to */}
            <UTMDrawer
                isOpen={utmDrawer.isOpen}
                onClose={stopEditingKey}
                defaultValue={
                    (editingKey?.data as ExtensionUser)?.userID ??
                    (editingKey?.data as ExtensionTeam)?.teamID ??
                    (editingKey?.data as ExtensionMenu)?.menuID
                }
                onSubmit={data => {
                    if (!editingKey) return
                    updateKey(editingKey.index, data)
                    utmDrawer.onClose()
                }}
            />
        </VStack>
    )
}

function getAvailableKeys(
    extensions: IVRMenu['IVR']['extensions'] = {},
    currentKey?: string
): string[] {
    const usedKeyPositions = Object.keys(extensions)
    const ASSIGNABLE_KEYS = buildIVROptionsArray(MIN_KEY, MAX_KEY, ALLOW_SYMBOLS)
    return ASSIGNABLE_KEYS.filter(key => {
        const isCurrentKey = key === currentKey
        const isExistingKey = usedKeyPositions.some(existingKey => existingKey === key)
        return isCurrentKey || !isExistingKey
    })
}
