import { selectCalls, useSelector } from '@missionlabs/api'
import { FC, useMemo } from 'react'

import { ContactDrawer } from './ContactDrawer'
import { ContactDrawerProvider } from './hooks/useContactDrawerState'
import { NavButtons } from './NavButtons/NavButtons'

type ContactNavbarProps = {}

export const ContactNavbar: FC<ContactNavbarProps> = () => {
    const calls = useSelector(selectCalls)
    const callsActive = useMemo(() => Boolean(calls.length), [calls])
    return (
        <ContactDrawerProvider>
            <ContactDrawer />
            <NavButtons isCallActive={callsActive} />
        </ContactDrawerProvider>
    )
}
