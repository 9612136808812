import { Emoji } from '@missionlabs/api'
import { CSSProperties } from 'react'

import { COLUMN_COUNT } from './EmojiPicker'
import { EmojiPickerItem, EmojiPickerItemProps } from './EmojiPickerItem'

export interface EmojiPickerRendererProps extends Pick<EmojiPickerItemProps, 'onClick'> {
    data: Emoji[] | undefined
    columnIndex: number
    rowIndex: number
    style: CSSProperties
}

export const EmojiPickerRenderer = ({
    data,
    columnIndex,
    rowIndex,
    style,
    onClick
}: EmojiPickerRendererProps) => {
    const index = rowIndex * COLUMN_COUNT + columnIndex
    const item = data?.[index]

    return (
        <div style={style}>
            <EmojiPickerItem item={item} onClick={onClick} />
        </div>
    )
}
