import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const TranscriptIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TranscriptIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 3C23 1.89543 22.1046 1 21 1H11C9.89543 1 9 1.89543 9 3V6.54573C10.2024 7.25689 11 8.58804 11 10.0877V11.751L11.9978 11.7488C12.2634 11.7482 12.5183 11.8533 12.7063 12.0409C12.8943 12.2285 13 12.4832 13 12.7488V13H19.5C19.7761 13 20 13.2239 20 13.5C20 13.7761 19.7761 14 19.5 14H13V14.7661C13 15.5525 12.8553 16.3063 12.591 17H16L20 21V17H21C22.1046 17 23 16.1046 23 15V3ZM19.5 4C19.7761 4 20 4.22386 20 4.5C20 4.77614 19.7761 5 19.5 5H12.5C12.2239 5 12 4.77614 12 4.5C12 4.22386 12.2239 4 12.5 4H19.5ZM20 7.5C20 7.22386 19.7761 7 19.5 7H14.5C14.2239 7 14 7.22386 14 7.5C14 7.77614 14.2239 8 14.5 8H19.5C19.7761 8 20 7.77614 20 7.5ZM13 10.5C13 10.2239 13.2239 10 13.5 10H19.5C19.7761 10 20 10.2239 20 10.5C20 10.7761 19.7761 11 19.5 11H13.5C13.2239 11 13 10.7761 13 10.5Z"
                fill="currentColor"
            />
            <path
                d="M9.55588 16.3837C9.02775 17.2658 8.08059 17.8538 7 17.8538C5.34315 17.8538 4 16.4714 4 14.7661V10.0877C4 8.38242 5.34315 7 7 7C7.76835 7 8.46924 7.2973 9 7.78623C9.61375 8.35161 10 9.17324 10 10.0877L10 14.7685C9.99955 15.3607 9.83713 15.9139 9.55588 16.3837Z"
                fill="currentColor"
            />
            <path
                d="M11.5056 17C10.8371 18.4258 9.54846 19.4858 8 19.8093V21.9708H10V23H4V21.9708H6V19.8093C3.71776 19.3325 2 17.2558 2 14.7661V12.7709L3 12.7687V14.8453C3 17.0753 4.79086 18.883 7 18.883C8.45822 18.883 9.73418 18.0954 10.4331 16.9185C10.7931 16.3125 11 15.6033 11 14.8453L11 12.751L12 12.7488L12 14.7692C12 14.7946 11.9998 14.82 11.9994 14.8453"
                fill="currentColor"
            />
        </g>
    )
})
