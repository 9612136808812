import { AvailableNumber, Currency } from '@missionlabs/types'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetClientQuery } from 'shared/store'

export function parseLocale(numberType: string | undefined) {
    return numberType?.split('_')[2]?.toUpperCase()
}

export const useAddNumberUtils = () => {
    const { formatToLocalNumber } = useFormatToNumberE164()

    const { data: client } = useGetClientQuery()

    function formatNumber(numberE164: string | undefined, numberType: string | undefined) {
        return formatToLocalNumber(numberE164, parseLocale(numberType))
    }

    function formatPrice(
        price: number | undefined,
        localisedPrices: AvailableNumber['localisedPrices'] | undefined
    ) {
        const currency = client?.currency ?? 'GBP'
        const localisedPrice = localisedPrices?.find(item => item.currencyCode === currency)?.price
        const formattedPrice = ((localisedPrice ?? price ?? 0) / 100).toFixed(2)
        return `${Currency[currency]}${formattedPrice}`
    }

    return { formatNumber, formatPrice }
}
