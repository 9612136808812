import { Button, HStack, Spacer, useDisclosure, VStack } from '@chakra-ui/react'
import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from '@missionlabs/react'
import { RequestCodeSteps } from 'features/admin/pages/WhatsApp/VerifyNumber/RequestCodeSteps'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export interface VerifyWhatsAppNumberDrawerProps {}

export const VerifyWhatsAppNumberDrawer: FC<VerifyWhatsAppNumberDrawerProps> = () => {
    const navigate = useNavigate()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const { t } = useTranslation()

    const handleTransition = (): void => {
        navigate('/admin/whatsapp')
    }

    return (
        <Drawer size="lg" isOpen={isOpen} onClose={onClose} onCloseComplete={handleTransition}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader pb="0 !important">{t('admin.whatsapp.verify.heading')}</DrawerHeader>
                <DrawerBody>
                    <VStack spacing="4" align="stretch" h="full">
                        <RequestCodeSteps onClose={onClose} />
                        <Spacer />
                        <HStack spacing="16px" padding="24px">
                            <Button w="full" size="lg" variant="secondary" onClick={onClose}>
                                {t('Close')}
                            </Button>
                        </HStack>
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
