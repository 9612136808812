import { useInterval } from '@chakra-ui/react'
import { Activity, UserActivity } from '@missionlabs/types'
import { useVirtualizer, VirtualItem, Virtualizer } from '@tanstack/react-virtual'
import { RefObject, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useIsAppActive } from 'shared/hooks/useIsAppActive'
import { useRelatedActivities } from 'shared/hooks/useRelatedActivities'
import { isActivitySection } from 'shared/types/feed'
import { ActivityContactEntity, groupBySection } from 'shared/utils/activities'

import { ActivityFeedRow } from './ActivityFeedRow'
import { ActivityFeedSection } from './ActivityFeedSection'
import { useActivityFilters } from './hooks/useActivityFeedFilters'

interface ActivityFeedContentProps {
    siblingHeight: number
    entity: ActivityContactEntity
    selectedActivity?: UserActivity
}

/** How often to poll for newer activities, in seconds */
const ACTIVITY_POLL_INTERVAL = 10_000

export const ActivityFeedContent = ({
    selectedActivity,
    entity,
    siblingHeight
}: ActivityFeedContentProps) => {
    const { filters } = useActivityFilters()

    const {
        relatedActivities = [],
        forward,
        backward
    } = useRelatedActivities(entity, selectedActivity, filters)

    // The activities are already sorted but we need to group them into sections
    // and add section headers between groups
    const groupedActivities = useMemo(() => groupBySection(relatedActivities), [relatedActivities])

    // Calculate the height of each row based on the activity type
    // so that we can tell @tanstack/virtual how tall each row should be
    const estimateSize = (index: number) => {
        const item = groupedActivities[index]
        if (isActivitySection(item)) return 76
        return estimateRowHeight(item)
    }

    const parentRef = useRef<HTMLDivElement>(null)

    const rowVirtualizer = useVirtualizer({
        count: groupedActivities.length,
        estimateSize,
        getScrollElement: () => parentRef.current,
        overscan: 2
    })

    const selectedIndex = useMemo(
        () => groupedActivities.findIndex(g => 'ID' in g && g.ID === selectedActivity?.ID),
        [groupedActivities, selectedActivity]
    )
    useAdjustScrollPosition(
        //Scroll to selected activity or to the end
        selectedActivity ? selectedIndex : groupedActivities.length,
        parentRef,
        rowVirtualizer
    )

    const virtualItems = rowVirtualizer.getVirtualItems()
    const firstItem = virtualItems.at(0)
    const lastItem = virtualItems.at(-1)

    // When we get near the start of the list, the virtualizer will start to render the first activity (even if it's out of view initially).
    // As soon as it's rendered we know we're near the start and might need to fetch more older activities.
    const isFirstItemLoaded = firstItem?.index === 0
    const isLastItemLoaded = lastItem?.index === groupedActivities.length - 1
    const shouldFetchOlder = isFirstItemLoaded && backward.hasNextPage && !backward.isFetching
    const shouldFetchNewer = isLastItemLoaded && forward.hasNextPage && !forward.isFetching

    // Poll for newer activities if we've reached the end of the list.
    // We need to make sure that we only do this if we've actually reached the end of pagination.
    // Also, pause polling if the app is minimized/in a background tab.
    const isAppActive = useIsAppActive()
    const shouldPollForNewer =
        isLastItemLoaded && !forward.hasNextPage && !forward.isFetching && isAppActive

    usePollForNewActivities(shouldPollForNewer, lastItem, forward.fetchMore, parentRef)

    useEffect(() => {
        if (shouldFetchOlder) backward.fetchMore()
    }, [shouldFetchOlder, backward])

    useEffect(() => {
        if (shouldFetchNewer) forward.fetchMore()
    }, [shouldFetchNewer, forward])

    return (
        // The scrollable element for the list
        <div
            ref={parentRef}
            id="activity-feed-content"
            style={{
                overflowY: 'auto',
                width: '100%',
                height: `calc(100% - ${siblingHeight}px)`,
                contain: 'strict'
            }}
        >
            {/* The large inner element to hold all of the items */}
            <div
                style={{
                    width: '100%',
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    position: 'relative'
                }}
            >
                {virtualItems.map(row => {
                    const activity = groupedActivities[row.index]
                    const style = {
                        position: 'absolute',
                        minHeight: `${row.size}px`,
                        paddingTop: 12,
                        paddingBottom: 12,
                        left: 0,
                        width: '100%',
                        transform: `translateY(${row.start}px)`,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        overflow: 'hidden'
                    } as const

                    if (isActivitySection(activity)) {
                        return (
                            <div
                                key={activity.key}
                                style={style}
                                ref={rowVirtualizer.measureElement}
                                data-index={row.index}
                            >
                                <ActivityFeedSection key={activity.startOfDay} section={activity} />
                            </div>
                        )
                    }

                    return (
                        <div
                            key={activity.ID}
                            style={style}
                            ref={rowVirtualizer.measureElement}
                            data-index={row.index}
                        >
                            <ActivityFeedRow
                                activity={activity}
                                highlighted={activity.ID === selectedActivity?.ID}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

function guestimateTextHeight(textLength = 0, heightPerRow = 15) {
    return Math.ceil(textLength / 100) * heightPerRow
}

function estimateRowHeight(activity: UserActivity) {
    const isVoicemail =
        activity.activityType === Activity.MESSAGE_RECEIVED ||
        activity.activityType === Activity.INTERNAL_MESSAGE_RECEIVED

    const isSMS =
        activity.activityType === Activity.CHAT_MESSAGE_RECEIVED ||
        activity.activityType === Activity.CHAT_MESSAGE_SENT

    if (isVoicemail) {
        if (activity.messageTranscription) {
            // If there's a transcription, we'll show the transcription text beneath
            // so we need to account for the extra height, adding 15px height for every 100 characters
            return 134 + guestimateTextHeight(activity.messageTranscription.length, 15)
        }
        return 134
    }
    if (isSMS) {
        //For every 100 chars add 20px height
        return 50 + guestimateTextHeight(activity.text?.length, 21)
    }
    const callNotesHeight = activity.callNotes?.length
        ? guestimateTextHeight(activity.callNotes[0].content?.length, 28)
        : 0

    // If there's a call recording, we'll show the player beneath
    if (Object.keys(activity.userRecordingURLs || {}).length) return 132 + callNotesHeight
    if (activity.callLength) return 90 + callNotesHeight
    return 70 + callNotesHeight
}

/**
 * Keep track of the index of the "selected" item.
 * If it increases, that means new items have been added at the start.
 * If 5 items are added, we add the offset of the 5th item to the scroll position to keep us in the same "place".
 * @param items The list of virtual items.
 * @param anchorItemIndex The "middle" item in the list we use to track if items were added at the top or bottom.
 * @param parentRef The scroll container.
 * @param rowVirtualizer The virtualizer so that we can calculate the new scroll offset.
 */
function useAdjustScrollPosition(
    anchorItemIndex: number,
    parentRef: RefObject<HTMLDivElement>,
    rowVirtualizer: Virtualizer<HTMLDivElement, Element>
) {
    const previousAnchorItemIndex = useRef(0)

    // If new items are added above, adjust the scroll position to account for the new items.
    useLayoutEffect(() => {
        if (anchorItemIndex === -1 || !parentRef.current) return

        const delta = anchorItemIndex - previousAnchorItemIndex.current
        if (delta > 0) {
            //If not previously scrolled then scroll straight to activity
            if (previousAnchorItemIndex.current === 0) {
                rowVirtualizer.scrollToIndex(anchorItemIndex)
                previousAnchorItemIndex.current = anchorItemIndex
            } else {
                // Get the offset of the Nth item where N is the number of rows added at the start
                const offset = rowVirtualizer.getOffsetForIndex(delta, 'start')[0]
                console.log(`Scrolling down by ${offset}px to account for ${delta} new items above`)
                previousAnchorItemIndex.current = anchorItemIndex
                parentRef.current.scrollTop += offset
            }
        }
    }, [rowVirtualizer, anchorItemIndex, parentRef])
}

/** Poll for new activities, and scroll to the end of the list if new ones are loaded (and we're already at the end of the list) */
function usePollForNewActivities(
    shouldPollForNewer: boolean | null | undefined,
    lastItem: VirtualItem | undefined,
    fetchMore: () => Promise<UserActivity[] | undefined>,
    parentRef: RefObject<HTMLElement>
) {
    // Ideally we'd just set parentRef.current.scrollTop as soon as the new items are loaded.
    //
    // However, we need to scroll _after_ the new items have been rendered.
    // Using a number state defers the scroll to after rendering, and can also be used to trigger the scroll effect.
    const [scrollToEnd, setScrollToEnd] = useState(0)

    useInterval(
        () => {
            fetchMore().then(newActivities => {
                if (!newActivities || newActivities.length === 0) return
                if (!parentRef.current) return

                // If the current last item is visible, schedule a scroll to the end to show the new items
                if (
                    lastItem &&
                    lastItem.start < parentRef.current.scrollTop + parentRef.current.clientHeight
                ) {
                    console.log(`Scrolling to ${newActivities.length} new activities`)
                    setScrollToEnd(s => (s + 1) % 1000000)
                } else {
                    console.log(`Polled and got ${newActivities.length} new activities`)
                }
            })
        },
        shouldPollForNewer ? ACTIVITY_POLL_INTERVAL : null
    )

    useLayoutEffect(() => {
        if (scrollToEnd && parentRef.current)
            parentRef.current.scrollTop =
                parentRef.current.scrollHeight - parentRef.current.clientHeight
    }, [scrollToEnd, parentRef])
}
