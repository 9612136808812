import { ComponentStyleConfig } from '@chakra-ui/react'

export const ActivityRowStyles: ComponentStyleConfig = {
    baseStyle: () => ({
        gap: '16px',
        gridTemplateColumns: '32px 1fr 32px',
        w: 'full',
        px: '24px',
        py: '4px',
        alignItems: 'start',
        transition: 'background-color 500ms ease'
    }),
    variants: {
        highlight: ({ colorMode }) => ({
            bg: `${colorMode}.alerts.redLight`
        })
    }
}
