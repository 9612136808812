import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const NotificationCountIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NotificationCountIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                fill="currentColor"
            />
            <path
                d="M9.34252 16C9.26252 16 9.19452 15.976 9.13852 15.928C9.09052 15.872 9.06652 15.804 9.06652 15.724V15.34C9.06652 15.268 9.08652 15.188 9.12652 15.1C9.16652 15.012 9.24652 14.916 9.36652 14.812L11.9105 12.268C12.3185 11.924 12.6385 11.632 12.8705 11.392C13.1105 11.144 13.2785 10.908 13.3745 10.684C13.4785 10.46 13.5305 10.228 13.5305 9.988C13.5305 9.532 13.4025 9.172 13.1465 8.908C12.8905 8.636 12.5105 8.5 12.0065 8.5C11.6865 8.5 11.4105 8.568 11.1785 8.704C10.9465 8.832 10.7625 9.012 10.6265 9.244C10.4985 9.468 10.4145 9.724 10.3745 10.012C10.3585 10.116 10.3145 10.188 10.2425 10.228C10.1785 10.268 10.1185 10.288 10.0625 10.288H9.43852C9.36652 10.288 9.30652 10.268 9.25852 10.228C9.21052 10.18 9.18652 10.124 9.18652 10.06C9.19452 9.756 9.25852 9.452 9.37852 9.148C9.49852 8.836 9.67452 8.556 9.90652 8.308C10.1465 8.06 10.4385 7.86 10.7825 7.708C11.1345 7.548 11.5425 7.468 12.0065 7.468C12.6385 7.468 13.1545 7.584 13.5545 7.816C13.9625 8.04 14.2625 8.34 14.4545 8.716C14.6465 9.092 14.7425 9.504 14.7425 9.952C14.7425 10.304 14.6825 10.628 14.5625 10.924C14.4425 11.212 14.2665 11.496 14.0345 11.776C13.8105 12.048 13.5305 12.328 13.1945 12.616L10.8905 14.956H14.6345C14.7225 14.956 14.7905 14.98 14.8385 15.028C14.8945 15.076 14.9225 15.144 14.9225 15.232V15.724C14.9225 15.804 14.8945 15.872 14.8385 15.928C14.7905 15.976 14.7225 16 14.6345 16H9.34252Z"
                fill="white"
            />
        </g>
    )
})

export const NotificationCrossedIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NotificationCrossedIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2821 20.7436C14.2821 21.9846 13.2667 23 12.0256 23C10.7733 23 9.76923 21.9846 9.76923 20.7436H14.2821ZM18.7949 10.5897V16.2308L21.0513 18.4872V19.6154H18.1692L8.81367 4.02278C9.27885 3.77938 9.78611 3.58914 10.3333 3.45949V2.69231C10.3333 1.7559 11.0892 1 12.0256 1C12.9621 1 13.7179 1.7559 13.7179 2.69231V3.45949C16.9446 4.22667 18.7949 7.12615 18.7949 10.5897ZM6.18958 6.6493L13.9692 19.6154H3V18.4872L5.25641 16.2308V10.5897C5.25641 9.13617 5.5782 7.78525 6.18958 6.6493Z"
                fill="currentColor"
            />
            <path
                d="M18.618 23.7198C18.895 23.5599 18.9867 23.2037 18.8215 22.9299L5.27924 0.479912C5.11846 0.213388 4.77393 0.124603 4.50437 0.280233C4.22739 0.440144 4.13562 0.79626 4.30082 1.07012L17.8431 23.5201C18.0039 23.7866 18.3484 23.8754 18.618 23.7198Z"
                fill="currentColor"
            />
        </g>
    )
})

export const NotificationOffIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NotificationOffIcon',
    viewBox: '0 0 26 22',
    path: (
        <path
            d="M7.66794 5.63538C8.56637 4.22913 10.0507 3.2135 11.7695 2.94006V2.23694C11.7695 1.57288 12.3164 0.986938 13.0195 0.986938C13.6836 0.986938 14.2695 1.57288 14.2695 2.23694V2.94006C17.082 3.40881 19.2695 5.86975 19.2695 8.79944V10.1276C19.2695 11.8854 19.8554 13.6041 20.9492 15.0104L21.5351 15.7135C21.7304 15.9869 21.8086 16.3385 21.6914 16.6119L25.1289 19.3463C25.5586 19.6588 25.6367 20.2448 25.2851 20.6354C24.9726 21.0651 24.3867 21.1432 23.9961 20.7916L0.871061 2.66663C0.441374 2.35413 0.363249 1.76819 0.714811 1.37756C1.02731 0.947876 1.61325 0.869751 2.00387 1.22131L7.66794 5.63538ZM9.15231 6.80725L18.0976 13.7994C17.6289 12.6666 17.3945 11.4166 17.3945 10.1276V8.79944C17.3945 6.57288 15.5586 4.73694 13.332 4.73694H12.707C11.1836 4.73694 9.85544 5.59631 9.15231 6.80725ZM6.7695 10.1276V9.69788L8.56637 11.1041C8.41012 12.6276 7.90231 14.1119 7.082 15.3619H13.9961L16.3789 17.2369H5.207C4.81637 17.2369 4.50387 17.0416 4.34762 16.7291C4.19137 16.4166 4.23044 16.026 4.46481 15.7135L5.05075 15.0104C6.1445 13.6041 6.7695 11.8854 6.7695 10.1276ZM15.5195 18.4869C15.5195 19.151 15.2461 19.8151 14.7773 20.2838C14.3086 20.7526 13.6445 20.9869 13.0195 20.9869C12.3554 20.9869 11.6914 20.7526 11.2226 20.2838C10.7539 19.8151 10.5195 19.151 10.5195 18.4869H15.5195Z"
            fill="currentColor"
        />
    )
})

export const NotificationIconOutline: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NotificationIconOutline',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M10.2695 1.25V1.95312C13.082 2.42188 15.2695 4.88281 15.2695 7.8125V9.14062C15.2695 10.8984 15.8555 12.6172 16.9492 14.0234L17.5352 14.7266C17.7695 15.0391 17.8086 15.4297 17.6523 15.7422C17.4961 16.0547 17.1836 16.25 16.832 16.25H1.20703C0.816406 16.25 0.503906 16.0547 0.347656 15.7422C0.191406 15.4297 0.230469 15.0391 0.464844 14.7266L1.05078 14.0234C2.14453 12.6172 2.76953 10.8984 2.76953 9.14062V7.8125C2.76953 4.88281 4.91797 2.42188 7.76953 1.95312V1.25C7.76953 0.585938 8.31641 0 9.01953 0C9.68359 0 10.2695 0.585938 10.2695 1.25ZM8.70703 3.75C6.44141 3.75 4.64453 5.58594 4.64453 7.8125V9.14062C4.64453 11.0156 4.09766 12.8125 3.08203 14.375H14.918C13.9023 12.8125 13.3945 11.0156 13.3945 9.14062V7.8125C13.3945 5.58594 11.5586 3.75 9.33203 3.75H8.70703ZM11.5195 17.5C11.5195 18.1641 11.2461 18.8281 10.7773 19.2969C10.3086 19.7656 9.64453 20 9.01953 20C8.35547 20 7.69141 19.7656 7.22266 19.2969C6.75391 18.8281 6.51953 18.1641 6.51953 17.5H11.5195Z"
            fill="currentColor"
        />
    )
})

export const NotificationIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NotificationIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M12.0256 23C13.2667 23 14.2821 21.9846 14.2821 20.7436H9.76923C9.76923 21.9846 10.7733 23 12.0256 23ZM18.7949 16.2308V10.5897C18.7949 7.12615 16.9446 4.22667 13.7179 3.45949V2.69231C13.7179 1.7559 12.9621 1 12.0256 1C11.0892 1 10.3333 1.7559 10.3333 2.69231V3.45949C7.09539 4.22667 5.25641 7.11487 5.25641 10.5897V16.2308L3 18.4872V19.6154H21.0513V18.4872L18.7949 16.2308Z"
            fill="currentColor"
        />
    )
})

export const NotifyIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NotifyIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.30106 4.99678L5.94256 3.62398C3.66256 5.38078 2.16156 8.08798 2.02856 11.16H3.92856C4.07106 8.61598 5.36306 6.38878 7.30106 4.99678ZM19.0716 11.16H20.9716C20.8291 8.08798 19.3281 5.38078 17.0576 3.62398L15.7086 4.99678C17.6276 6.38878 18.9291 8.61598 19.0716 11.16ZM17.2001 11.64C17.2001 8.69278 15.6421 6.22558 12.9251 5.57278V4.91998C12.9251 4.12318 12.2886 3.47998 11.5001 3.47998C10.7116 3.47998 10.0751 4.12318 10.0751 4.91998V5.57278C7.34856 6.22558 5.80006 8.68318 5.80006 11.64V16.44L3.90006 18.36V19.32H19.1001V18.36L17.2001 16.44V11.64ZM11.5001 22.2C11.6331 22.2 11.7566 22.1904 11.8801 22.1616C12.4976 22.0272 13.0011 21.6048 13.2481 21.0288C13.3431 20.7984 13.3906 20.5488 13.3906 20.28H9.59056C9.60006 21.336 10.4456 22.2 11.5001 22.2Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_162"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="2"
                y="3"
                width="19"
                height="20"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.30106 4.99678L5.94256 3.62398C3.66256 5.38078 2.16156 8.08798 2.02856 11.16H3.92856C4.07106 8.61598 5.36306 6.38878 7.30106 4.99678ZM19.0716 11.16H20.9716C20.8291 8.08798 19.3281 5.38078 17.0576 3.62398L15.7086 4.99678C17.6276 6.38878 18.9291 8.61598 19.0716 11.16ZM17.2001 11.64C17.2001 8.69278 15.6421 6.22558 12.9251 5.57278V4.91998C12.9251 4.12318 12.2886 3.47998 11.5001 3.47998C10.7116 3.47998 10.0751 4.12318 10.0751 4.91998V5.57278C7.34856 6.22558 5.80006 8.68318 5.80006 11.64V16.44L3.90006 18.36V19.32H19.1001V18.36L17.2001 16.44V11.64ZM11.5001 22.2C11.6331 22.2 11.7566 22.1904 11.8801 22.1616C12.4976 22.0272 13.0011 21.6048 13.2481 21.0288C13.3431 20.7984 13.3906 20.5488 13.3906 20.28H9.59056C9.60006 21.336 10.4456 22.2 11.5001 22.2Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_162)"></g>
        </g>
    )
})
