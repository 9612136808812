import {
    defineStyle,
    Popover,
    PopoverAnchor,
    PopoverContent,
    PopoverProps,
    PopoverTrigger,
    Portal,
    useDisclosure,
    UseDisclosureProps,
    useMultiStyleConfig,
    useOutsideClick
} from '@chakra-ui/react'
import { getSelectedOptionLabel } from '@missionlabs/utils'
import { SelectButton } from 'molecules/Select/SelectButton'
import { SelectItem } from 'molecules/Select/SelectItem'
import { SelectOption } from 'molecules/Select/types'
import {
    cloneElement,
    FC,
    Fragment,
    isValidElement,
    PropsWithChildren,
    useLayoutEffect,
    useRef
} from 'react'

const gridStyles = defineStyle({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '8px 8px',
    padding: '8px'
})

export interface SelectProps extends PopoverProps {
    options: SelectOption[]
    variant?: 'light' | 'dark'
    layout?: 'list' | 'grid'
    autoClose?: boolean
    disclosure?: UseDisclosureProps
    defaultValue?: string
    isDisabled?: boolean
    isReadOnly?: boolean
    placeholder?: string
    portal?: boolean
    value?: string
    onChange?: (value: SelectOption['value']) => void
}

// TODO: Keyboard navigation for options
export const Select: FC<PropsWithChildren<SelectProps>> = ({
    options,
    variant = 'light',
    layout = 'list',
    autoClose = true,
    disclosure: _disclosure,
    isDisabled = false,
    matchWidth = true,
    placeholder,
    portal = false,
    value,
    onChange,
    isReadOnly = false,
    children,
    ...props
}) => {
    const disclosure = useDisclosure(_disclosure)
    const { container } = useMultiStyleConfig('CustomSelect', { variant })

    const ref = useRef<HTMLElement>(null)

    const Trigger = _disclosure ? PopoverAnchor : PopoverTrigger

    const Wrapper = portal ? Portal : Fragment

    const handleChange: SelectProps['onChange'] = value => {
        if (isDisabled) return
        onChange?.(value)
        if (autoClose) disclosure.onClose()
    }

    useOutsideClick({ ref, handler: disclosure.onClose })

    useLayoutEffect(() => {
        if (!disclosure.isOpen) return

        const selected = ref.current?.querySelector(`[data-value="${value}"]`)
        if (!selected) return

        selected.scrollIntoView({ block: 'nearest' })
    }, [disclosure.isOpen, value])

    return (
        <Popover
            {...props}
            {...disclosure}
            placement="bottom-start"
            offset={[0, -1]}
            autoFocus={false}
            matchWidth={matchWidth}
            returnFocusOnClose={false}
        >
            <Trigger>
                {isValidElement(children) ? (
                    cloneElement(children, {
                        cursor: isDisabled ? 'not-allowed' : 'pointer'
                    } as any)
                ) : (
                    <SelectButton
                        variant={variant}
                        isDisabled={isDisabled}
                        placeholder={placeholder}
                        isReadOnly={isReadOnly}
                    >
                        {getSelectedOptionLabel(options, value) ?? placeholder ?? ''}
                    </SelectButton>
                )}
            </Trigger>
            <Wrapper>
                <PopoverContent
                    ref={ref}
                    sx={{
                        ...container,
                        ...(layout === 'grid' ? gridStyles : {}),
                        w: matchWidth ? 'full' : undefined
                    }}
                >
                    {options.map((option, i) => (
                        <SelectItem
                            key={`select-item-${i}`}
                            item={option}
                            variant={variant}
                            isSelected={option.value === value}
                            onClick={handleChange}
                        />
                    ))}
                </PopoverContent>
            </Wrapper>
        </Popover>
    )
}
