import { MinusIcon } from '@chakra-ui/icons'
import { Checkbox as ChakraCheckbox, CheckboxProps as ChakraCheckboxProps } from '@chakra-ui/react'
import { CheckIconSolid } from 'atoms/Icons/zeta'
import { Label } from 'molecules'
import { forwardRef, PropsWithChildren } from 'react'

type CheckboxProps = ChakraCheckboxProps

export const Checkbox = forwardRef<HTMLInputElement, PropsWithChildren<CheckboxProps>>(
    ({ children, ...props }, ref) => {
        // not typing props here otherwise ChakraCheckbox icon ain't happy
        const Icon = (props: any) => {
            if (props.isIndeterminate) return <MinusIcon boxSize="10px" />
            return <CheckIconSolid boxSize="10px" />
        }

        return (
            <ChakraCheckbox {...props} ref={ref} icon={<Icon />}>
                <Label text={children as string | undefined} />
            </ChakraCheckbox>
        )
    }
)

Checkbox.displayName = 'Checkbox'
