import { ComponentStyleConfig, defineStyle, defineStyleConfig } from '@chakra-ui/react'

export const IconButtonStyles: ComponentStyleConfig = defineStyleConfig({
    baseStyle: defineStyle({
        borderRadius: '4px'
    }),
    sizes: {
        sm: defineStyle({ fontSize: '13px', boxSize: '32px', minW: '32px' }),
        md: defineStyle({ fontSize: '16px', boxSize: '40px' }),
        lg: defineStyle({ fontSize: '21px', boxSize: '48px' }),
        xl: defineStyle({ fontSize: '27px', boxSize: '64px' })
    }
})
