import { HStack } from '@chakra-ui/react'
import {
    Banner,
    Body,
    Button,
    FormProvider,
    Input,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useSettingsContext } from '../../components/settingsContext'

export const VoicemailPortalPinSetting: FC = () => {
    const { t } = useTranslation()

    const [error, setError] = useState<string>()
    const { user, onUpdateUser: updateUser } = useSettingsContext()

    const methods = useYupForm<{ voicemailPin: string }>(
        yup.object({
            voicemailPin: yup
                .string()
                .required()
                .matches(/^\d+$/, 'Must be only digits')
                .min(4)
                .max(6)
        })
    )

    const handleSubmit = async (data: { voicemailPin: string }) => {
        console.log(data)
        if (!user) return
        setError(undefined)

        try {
            await updateUser({ ID: user.ID, ...data })
        } catch (e) {
            if (!!e.data.message && typeof e.data.message === 'string') {
                setError(e.data.message.toLowerCase())
            }
        }
    }

    return (
        <SettingsRow label={t('calls.voicePortalPin')}>
            <HStack w="full" justify="space-between" align="flex-start">
                <Body size="sm" variant="bold">
                    {t('calls.voicePortalPinSubtitle')}
                </Body>
            </HStack>
            <div />
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <HStack pt="8px" gap="16px" alignItems="flex-start" justifyContent="flex-start">
                        <ValidatedInput name="voicemailPin" containerSx={{ width: 'auto' }}>
                            <Input type="password" data-testid="voicemail-pin-input" />
                        </ValidatedInput>
                        <Button variant="creationary" flexShrink={0} type="submit">
                            {t('calls.savePin')}
                        </Button>
                    </HStack>

                    {!!error && (
                        <Banner>
                            <Trans
                                i18nKey="error"
                                components={{
                                    bold: <span style={{ color: 'white' }} />
                                }}
                                values={{ error }}
                            />
                        </Banner>
                    )}
                </form>
            </FormProvider>
        </SettingsRow>
    )
}
