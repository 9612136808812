export enum DeploymentEnvironments {
    'dev' = 'dev',
    'uat' = 'uat',
    'prod' = 'prod'
}

export enum DeploymentDataCenters {
    'aws' = 'aws',
    'localhost' = 'localhost'
}

export enum DeploymentCounties {
    'gb' = 'gb'
}

export enum DeploymentProducts {
    circleloop = 'cl',
    phonelineplus = 'plcl'
}
