import { StackProps, VStack } from '@chakra-ui/react'
import { IconButton, Input, InputProps } from 'atoms'
import { AddIcon } from 'atoms/Icons/circleloop'
import isEqual from 'lodash.isequal'
import { TagList, TagWithID } from 'molecules/TagList/TagList'
import { forwardRef, KeyboardEventHandler, startTransition, useEffect, useState } from 'react'

const createTagWithID = (value: string): TagWithID => ({ id: crypto.randomUUID(), value })

const mapToTagsWithID = (tags: string[]) => tags.map(createTagWithID)

const mapToTags = (tags: TagWithID[]) => tags.map(item => item.value)

export type TagInputProps = InputProps & {
    tags: string[]
    spacing?: StackProps['spacing']
    onTagsChange: (tags: string[]) => void
}

export const TagInput = forwardRef<HTMLInputElement, TagInputProps>(
    ({ tags, spacing = '8px', onTagsChange, ...props }, ref) => {
        const [innerTags, setInnerTags] = useState<TagWithID[]>([])
        const [innerValue, setInnerValue] = useState<string>('')

        function append(value: string) {
            const tag = value.toLowerCase().trim()
            if (!tag) return
            const _tags = [...innerTags, createTagWithID(tag)]
            startTransition(() => onTagsChange(mapToTags(_tags)))
            setInnerTags(_tags)
            setInnerValue('')
        }

        function remove(tag: TagWithID) {
            const _tags = innerTags.filter(item => item.id !== tag.id)
            startTransition(() => onTagsChange(mapToTags(_tags)))
            setInnerTags(_tags)
        }

        const onKeyUp: KeyboardEventHandler<HTMLInputElement> = e => {
            if (e.key === 'Enter') append(e.currentTarget.value)
        }

        useEffect(() => {
            if (!tags?.length) return
            if (!isEqual(tags, innerTags)) setInnerTags(mapToTagsWithID(tags))
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [tags])

        return (
            <VStack spacing={spacing} align="start" flexWrap="wrap">
                <Input
                    {...props}
                    ref={ref}
                    rightIcon={
                        innerValue ? (
                            <IconButton
                                variant="transparent"
                                aria-label="Add tag"
                                onClick={() => append(innerValue)}
                            >
                                <AddIcon />
                            </IconButton>
                        ) : undefined
                    }
                    value={innerValue}
                    onChange={e => setInnerValue(e.target.value)}
                    onKeyUp={onKeyUp}
                />
                {innerTags.length > 0 && (
                    <TagList
                        tags={innerTags}
                        tagProps={{ variant: 'whiteLilac' }}
                        onClearFilter={remove}
                    />
                )}
            </VStack>
        )
    }
)

TagInput.displayName = 'TagInput'
