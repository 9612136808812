import { ButtonGroup, useColorMode, UseDisclosureReturn, VStack } from '@chakra-ui/react'
import {
    Call,
    hold,
    toggleMute,
    toggleRecording,
    TransferType,
    unhold,
    useDispatch
} from '@missionlabs/api'
import { useDebouncedFunction } from '@missionlabs/react'
import { Dispatch, FC, SetStateAction } from 'react'
import { useAuthenticatedUser } from 'shared/hooks/useAuthenticatedUser'
import { Features } from 'shared/hooks/useHasClientFeature'
import { useHasFeature } from 'shared/hooks/useHasFeature'

import { AddPersonButton } from './AddPersonButton'
import { AttendedTransferButton } from './AttendedTransferButton'
import { BlindTransferButton } from './BlindTransferButton'
import { DialpadButton } from './DialpadButton'
import { HoldButton } from './HoldButton'
import { MuteButton } from './MuteButton'
import { RecordButton } from './RecordButton'

interface CallButtonsProps {
    call: Call
    dialpad: UseDisclosureReturn
    addPerson: UseDisclosureReturn
    transfer: UseDisclosureReturn
    transferType?: TransferType
    setTransferType: Dispatch<SetStateAction<TransferType | undefined>>
}

export const CallButtons: FC<CallButtonsProps> = ({
    call,
    dialpad,
    addPerson,
    transfer,
    transferType,
    setTransferType
}) => {
    const dispatch = useDispatch()
    const { colorMode } = useColorMode()
    const { data: user } = useAuthenticatedUser()

    const isNway = !!call.nWayMembers?.length

    const hasNWayPermission = useHasFeature(Features.nway_calling)

    const onRecord = useDebouncedFunction(() => {
        const action = toggleRecording({
            callTraceID: call.callTraceID,
            state: call.recordingState,
            recordingMode: user?.recordingMode
        })

        dispatch(action)
    }, 500)

    const onMute = useDebouncedFunction(() => {
        const action = toggleMute({
            callTraceID: call.callTraceID,
            enabled: call.muted || false
        })

        dispatch(action)
    }, 500)

    const onDialpad = useDebouncedFunction(dialpad.onToggle, 500)

    const onAddPerson = useDebouncedFunction(() => {
        if (!addPerson.isOpen && transfer.isOpen) {
            transfer.onToggle()
            setTransferType(undefined)
        }

        addPerson.onToggle()
    }, 500)

    const onTransfer = useDebouncedFunction((newTransferType?: TransferType) => {
        if (!transfer.isOpen && addPerson.isOpen) addPerson.onClose()

        if (!newTransferType || newTransferType === transferType) {
            transfer.onToggle()
            setTransferType(undefined)
            return
        }

        if (!transferType) transfer.onToggle()
        setTransferType(newTransferType)
    }, 500)

    const onHold = useDebouncedFunction(() => {
        const action =
            call.status === 'ON_HOLD'
                ? unhold({ callTraceID: call.callTraceID })
                : hold({ callTraceID: call.callTraceID })

        dispatch(action)
    }, 500)

    return (
        <VStack spacing="9px" w="full">
            <RecordButton recordingState={call.recordingState} onToggle={onRecord} />
            <ButtonGroup
                color={`${colorMode}.tones.periwinkle`}
                spacing="9px"
                w="full"
                justifyItems="stretch"
                justifyContent="stretch"
            >
                <DialpadButton isOpen={dialpad.isOpen} onToggle={onDialpad} />
                <MuteButton muted={call.muted} onToggle={onMute} />
                <HoldButton status={call.status} onToggle={onHold} isDisabled={isNway} />
                {hasNWayPermission && (
                    <AddPersonButton isOpen={addPerson.isOpen} onToggle={onAddPerson} />
                )}
                <BlindTransferButton
                    transferring={transfer.isOpen && transferType === 'blind'}
                    onToggle={() => onTransfer('blind')}
                    isDisabled={isNway}
                />
                <AttendedTransferButton
                    transferring={transfer.isOpen && transferType === 'attended'}
                    onToggle={() => onTransfer('attended')}
                    isDisabled={isNway}
                />
            </ButtonGroup>
        </VStack>
    )
}
