import { Divider, HStack, VStack } from '@chakra-ui/react'
import {
    Button,
    FormProvider,
    Input,
    useToast,
    useYupForm,
    ValidatedInput,
    VirtualPaginationContextProvider,
    yup
} from '@missionlabs/react'
import { NewSpeedDialList, SpeedDialCode, SpeedDialList, UserRole } from '@missionlabs/types'
import ContactsProvider from 'features/contacts/context/contactsContext'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useGetUserData } from 'shared/hooks/useGetUserData'
import { useUserRole } from 'shared/hooks/useUserRole'
import {
    useCreateSpeedDialListForClientMutation,
    useCreateSpeedDialListForUserMutation,
    useGetSpeedDialListForUserQuery,
    useGetSpeedDialListsForClientQuery,
    useUpdateSpeedDialListForClientMutation,
    useUpdateSpeedDialListForUserMutation
} from 'shared/store'

import { ContactList } from './ContactList'
import { SelectedContact } from './ContactRow'

export interface SpeedDialFormProps {
    onClose: () => void
}

export const defaultValues: SpeedDialCode = {
    speedDialCode: '',
    name: '',
    replacementNumber: ''
}

export const SpeedDialForm: FC<SpeedDialFormProps> = ({ onClose }) => {
    const { t } = useTranslation()
    const { toast } = useToast()
    const userRole = useUserRole()
    const { pathname } = useLocation()

    const { user } = useGetUserData()

    const [selectedContact, setSelectedContact] = useState<SelectedContact>()

    const isAdmin = pathname.includes('/admin/') && userRole === UserRole.ADMIN

    const getRequest = isAdmin
        ? useGetSpeedDialListsForClientQuery
        : useGetSpeedDialListForUserQuery
    const createRequest = isAdmin
        ? useCreateSpeedDialListForClientMutation
        : useCreateSpeedDialListForUserMutation
    const updateRequest = isAdmin
        ? useUpdateSpeedDialListForClientMutation
        : useUpdateSpeedDialListForUserMutation

    const params: any = isAdmin ? undefined : { userID: user?.ID ?? '' }

    const { data, isLoading: isGetLoading } = getRequest(params, { skip: !user })
    const [createSpeedDialList, { isLoading: isCreateLoading }] = createRequest()
    const [updateSpeedDialList, { isLoading: isUpdateLoading }] = updateRequest()

    const speedDialList = Array.isArray(data) ? data[0] : data

    const speedDialSchema = yup.object({
        speedDialCode: yup
            .string()
            .required()
            .matches(/^\d+$/, 'Must be only digits')
            .min(2)
            .max(2),
        name: yup.string().required()
    })

    const methods = useYupForm<SpeedDialCode>(speedDialSchema, {
        defaultValues
    })

    const {
        formState: { isSubmitting, errors },
        handleSubmit
    } = methods

    const handleCreate = async (data: SpeedDialCode) => {
        if (!data || !user || speedDialList) return

        const newSpeedDialList: NewSpeedDialList = {
            clientID: user.clientID,
            speedDialCodes: [data]
        }

        if (!isAdmin) {
            newSpeedDialList.userID = user.ID
        }

        try {
            await createSpeedDialList(newSpeedDialList).unwrap()
            onClose()
        } catch (e) {
            console.error(e)
            const error = e.data?.message || e.message
            toast({ status: 'error', title: error || 'Failed to create speed dial list' })
        }
    }

    const onSubmit = async (data: SpeedDialCode) => {
        if (!data) return
        if (!selectedContact?.numberE164) {
            toast({ status: 'error', title: `Please select a number from the contact list` })
            return
        }

        const submitData: SpeedDialCode = {
            replacementNumber: selectedContact.numberE164,
            speedDialCode: data.speedDialCode,
            name: data.name
        }

        if (!speedDialList) return handleCreate(submitData)

        const speedDialCodes = speedDialList.speedDialCodes || []

        const isCodeExisting = speedDialCodes.find(
            speedDialCode => speedDialCode.speedDialCode === data.speedDialCode
        )

        if (isCodeExisting) {
            toast({
                status: 'error',
                title: `Speed Dial Code ${data.speedDialCode} is already reserved`
            })
            return
        }

        const updateData: SpeedDialList = {
            ...speedDialList,
            ...{ speedDialCodes: [...speedDialCodes, ...[submitData]] }
        }

        try {
            await updateSpeedDialList(updateData).unwrap()

            onClose()
        } catch (e) {
            console.error(e)
            const error = e.data?.message || e.message
            toast({ status: 'error', title: error || `Failed to add to speed dial list code` })
        }
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing="24px" p="24px" align="stretch" justify="space-between">
                    <ValidatedInput
                        containerSx={{
                            mb:
                                (errors.speedDialCode?.message?.length || 0) > 40
                                    ? '24px'
                                    : undefined
                        }}
                        name="speedDialCode"
                        label={t('directories.speedDial.code')}
                        errorSx={{ whiteSpace: 'pre-wrap' }}
                    >
                        <Input w="100px" placeholder={'#'} />
                    </ValidatedInput>

                    <ValidatedInput name="name" label={t('directories.speedDial.name')}>
                        <Input />
                    </ValidatedInput>

                    <Divider />

                    <VStack spacing="10px" align="inherit">
                        <VirtualPaginationContextProvider>
                            <ContactsProvider>
                                <ContactList
                                    onSelect={setSelectedContact}
                                    selectedContact={selectedContact}
                                />
                            </ContactsProvider>
                        </VirtualPaginationContextProvider>
                    </VStack>

                    <HStack pb="24px" spacing="16px" align="inherit">
                        <Button
                            w="full"
                            size="lg"
                            variant="secondary"
                            isDisabled={isSubmitting}
                            onClick={onClose}
                        >
                            {t('Cancel')}
                        </Button>

                        <Button
                            type="submit"
                            w="full"
                            size="lg"
                            variant="creationary"
                            isLoading={isGetLoading || isCreateLoading || isUpdateLoading}
                        >
                            {t('Add')}
                        </Button>
                    </HStack>
                </VStack>
            </form>
        </FormProvider>
    )
}
