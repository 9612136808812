import { useDispatch } from '@missionlabs/api'
import { FacebookLoginStatus } from '@missionlabs/types'
import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo
} from 'react'
import { setSignupCode, setSignupStatus, setSignupWabaid } from 'shared/slices/whatsappSlice'

import { useFacebookConfig } from './useFacebookConfig'

interface IFacebookLoginContext {
    fbSignup: () => Promise<void>
    fbLogout: () => void
}

const FacebookLoginContext = createContext<IFacebookLoginContext | null>(null)

export const FacebookLoginProvider = ({ children }: PropsWithChildren) => {
    const { FB } = window
    const { appID, configID } = useFacebookConfig()

    const dispatch = useDispatch()

    const sessionInfoListener = useCallback(
        (event: { origin: string; data: string }) => {
            if (event.origin !== 'https://www.facebook.com') return
            try {
                const eventData = JSON.parse(event.data)
                if (eventData.type === 'WA_EMBEDDED_SIGNUP') {
                    // if user finishes the Embedded Signup flow
                    if (eventData.event === 'FINISH_ONLY_WABA') {
                        const { waba_id } = eventData.data
                        dispatch(setSignupWabaid(waba_id))
                    }
                    // if user cancels the Embedded Signup flow
                    else {
                        const { current_step } = eventData.data
                        console.warn(
                            `User cancelled the Embedded Signup flow at step ${current_step}`
                        )
                    }
                }
            } catch {
                // Don’t parse info that’s not a JSON
                console.warn('Non JSON Response', event.data)
            }
        },
        [dispatch]
    )

    useEffect(() => {
        if (!window.FB) return
        // Listen for the Embedded Signup flow
        window.addEventListener('message', sessionInfoListener)
        return () => window.removeEventListener('message', sessionInfoListener)
    }, [sessionInfoListener])

    const fbLogout = useCallback(() => {
        FB.logout()
        dispatch(setSignupStatus(FacebookLoginStatus.NO_ACCOUNT))
        dispatch(setSignupWabaid(''))
        dispatch(setSignupCode(''))
    }, [FB, dispatch])

    const fbSignup = useCallback((): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!FB || !appID || !configID) {
                reject(new Error('Facebook not available'))
            }
            FB.login(
                response => {
                    if (response.authResponse) {
                        const code = response.authResponse.code
                        if (!code) {
                            console.error('No code received from Facebook')
                            return reject(new Error('No code received from Facebook'))
                        }
                        dispatch(setSignupCode(code))
                        resolve()
                    } else {
                        console.log('User cancelled login or did not fully authorize.')
                        reject(new Error('User cancelled login or did not fully authorize.'))
                    }
                },
                {
                    config_id: configID,
                    feature: 'whatsapp_embedded_signup',
                    response_type: 'code', // must be set to 'code' for System User access token
                    override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
                    extras: {
                        featureType: 'only_waba_sharing',
                        sessionInfoVersion: 2
                    },
                    return_scopes: true
                }
            )
        })
    }, [FB, appID, configID, dispatch])

    const contextValue = useMemo(
        () => ({
            fbSignup,
            fbLogout
        }),
        [fbSignup, fbLogout]
    )

    return (
        <FacebookLoginContext.Provider value={contextValue}>
            {children}
        </FacebookLoginContext.Provider>
    )
}

export const useFacebookLogin = () => {
    const context = useContext(FacebookLoginContext)
    if (!context)
        throw new Error('useFacebookLoginState must be used within a FacebookLoginProvider')
    return context
}
