import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const SmartphoneIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SmartphoneIconLight',
    viewBox: '0 0 14 20',
    path: (
        <path
            d="M10.75 0H3.25C1.84375 0 0.75 1.13281 0.75 2.5V17.5C0.75 18.9062 1.84375 20 3.25 20H10.75C12.1172 20 13.25 18.9062 13.25 17.5V2.5C13.25 1.13281 12.1172 0 10.75 0ZM5.75 1.25H8.25V1.875H5.75V1.25ZM12 17.5C12 18.2031 11.4141 18.75 10.75 18.75H3.25C2.54688 18.75 2 18.2031 2 17.5V2.5C2 1.83594 2.54688 1.25 3.25 1.25H4.5V1.875C4.5 2.57812 5.04688 3.125 5.75 3.125H8.25C8.91406 3.125 9.5 2.57812 9.5 1.875V1.25H10.75C11.4141 1.25 12 1.83594 12 2.5V17.5ZM8.25 15.625H5.75C5.39844 15.625 5.125 15.9375 5.125 16.25C5.125 16.6016 5.39844 16.875 5.75 16.875H8.25C8.5625 16.875 8.875 16.6016 8.875 16.25C8.875 15.9375 8.5625 15.625 8.25 15.625Z"
            fill="currentColor"
        />
    )
})

export const SmartphoneIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SmartphoneIconRegular',
    viewBox: '0 0 14 20',
    path: (
        <path
            d="M8.875 15.625H5.125C4.77344 15.625 4.5 15.9375 4.5 16.25C4.5 16.6016 4.77344 16.875 5.125 16.875H8.875C9.1875 16.875 9.5 16.6016 9.5 16.25C9.5 15.9375 9.1875 15.625 8.875 15.625ZM11.375 0H2.625C1.21875 0 0.125 1.13281 0.125 2.5V17.5C0.125 18.9062 1.21875 20 2.625 20H11.375C12.7422 20 13.875 18.9062 13.875 17.5V2.5C13.875 1.13281 12.7422 0 11.375 0ZM12 17.5C12 17.8516 11.6875 18.125 11.375 18.125H2.625C2.27344 18.125 2 17.8516 2 17.5V2.5C2 2.1875 2.27344 1.875 2.625 1.875H4.5V3.125C4.5 3.47656 4.77344 3.75 5.125 3.75H8.875C9.1875 3.75 9.5 3.47656 9.5 3.125V1.875H11.375C11.6875 1.875 12 2.1875 12 2.5V17.5Z"
            fill="currentColor"
        />
    )
})

export const SmartphoneIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SmartphoneIconSolid',
    viewBox: '0 0 14 20',
    path: (
        <path
            d="M8.875 15H5.125C4.77344 15 4.5 15.3125 4.5 15.625C4.5 15.9766 4.77344 16.25 5.125 16.25H8.875C9.1875 16.25 9.5 15.9766 9.5 15.625C9.5 15.3125 9.1875 15 8.875 15ZM10.75 0H3.25C1.49219 0 0.125 1.40625 0.125 3.125V16.875C0.125 18.6328 1.49219 20 3.25 20H10.75C12.4688 20 13.875 18.6328 13.875 16.875V3.125C13.875 1.40625 12.4688 0 10.75 0ZM11.375 16.875C11.375 17.2266 11.0625 17.5 10.75 17.5H3.25C2.89844 17.5 2.625 17.2266 2.625 16.875V3.125C2.625 2.8125 2.89844 2.5 3.25 2.5H4.5V3.125C4.5 3.47656 4.77344 3.75 5.125 3.75H8.875C9.1875 3.75 9.5 3.47656 9.5 3.125V2.5H10.75C11.0625 2.5 11.375 2.8125 11.375 3.125V16.875Z"
            fill="currentColor"
        />
    )
})

export const SmartphoneInIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SmartphoneInIconLight',
    viewBox: '0 0 17 20',
    path: [
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.75 0H6.25C4.84375 0 3.75 1.13281 3.75 2.5V8H5V2.5C5 1.83594 5.54688 1.25 6.25 1.25H7.5V1.875C7.5 2.57812 8.04688 3.125 8.75 3.125H11.25C11.9141 3.125 12.5 2.57812 12.5 1.875V1.25H13.75C14.4141 1.25 15 1.83594 15 2.5V17.5C15 18.2031 14.4141 18.75 13.75 18.75H6.25C5.54688 18.75 5 18.2031 5 17.5V12H3.75V17.5C3.75 18.9062 4.84375 20 6.25 20H13.75C15.1172 20 16.25 18.9062 16.25 17.5V2.5C16.25 1.13281 15.1172 0 13.75 0ZM8.75 1.25H11.25V1.875H8.75V1.25Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M11.25 15.625H8.75C8.39844 15.625 8.125 15.9375 8.125 16.25C8.125 16.6016 8.39844 16.875 8.75 16.875H11.25C11.5625 16.875 11.875 16.6016 11.875 16.25C11.875 15.9375 11.5625 15.625 11.25 15.625Z"
            fill="currentColor"
            key="2"
        />,
        <path
            d="M9.45962 7.04048C9.20578 6.78664 8.79422 6.78664 8.54038 7.04048C8.28654 7.29432 8.28654 7.70588 8.54038 7.95972L9.93076 9.3501H0.999998C0.641013 9.3501 0.349998 9.64111 0.349998 10.0001C0.349998 10.3591 0.641013 10.6501 0.999998 10.6501H9.93076L8.54038 12.0405C8.28654 12.2943 8.28654 12.7059 8.54038 12.9597C8.79422 13.2136 9.20578 13.2136 9.45962 12.9597L11.9596 10.4597C12.2135 10.2059 12.2135 9.79432 11.9596 9.54048L9.45962 7.04048Z"
            fill="currentColor"
            key="3"
        />
    ]
})

export const SmartphoneInIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SmartphoneInIconRegular',
    viewBox: '0 0 14 20',
    path: [
        <path
            d="M8.125 15.625H11.875C12.1875 15.625 12.5 15.9375 12.5 16.25C12.5 16.6016 12.1875 16.875 11.875 16.875H8.125C7.77344 16.875 7.5 16.6016 7.5 16.25C7.5 15.9375 7.77344 15.625 8.125 15.625Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M5.625 0H14.375C15.7422 0 16.875 1.13281 16.875 2.5V17.5C16.875 18.9062 15.7422 20 14.375 20H5.625C4.21875 20 3.125 18.9062 3.125 17.5V13H5V17.5C5 17.8516 5.27344 18.125 5.625 18.125H14.375C14.6875 18.125 15 17.8516 15 17.5V2.5C15 2.1875 14.6875 1.875 14.375 1.875H12.5V3.125C12.5 3.47656 12.1875 3.75 11.875 3.75H8.125C7.77344 3.75 7.5 3.47656 7.5 3.125V1.875H5.625C5.27344 1.875 5 2.1875 5 2.5V7H3.125V2.5C3.125 1.13281 4.21875 0 5.625 0Z"
            fill="currentColor"
            key="2"
        />,
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.29289 6.79289C8.68342 6.40237 9.31658 6.40237 9.70711 6.79289L12.2071 9.29289C12.5976 9.68342 12.5976 10.3166 12.2071 10.7071L9.70711 13.2071C9.31658 13.5976 8.68342 13.5976 8.29289 13.2071C7.90237 12.8166 7.90237 12.1834 8.29289 11.7929L9.08579 11H1C0.447715 11 0 10.5523 0 10C0 9.44772 0.447715 9 1 9H9.08579L8.29289 8.20711C7.90237 7.81658 7.90237 7.18342 8.29289 6.79289Z"
            fill="currentColor"
            key="3"
        />
    ]
})

export const SmartphoneInIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SmartphoneIconSolid',
    viewBox: '0 0 20 20',
    path: [
        <path
            d="M11.875 15H8.125C7.77344 15 7.5 15.3125 7.5 15.625C7.5 15.9766 7.77344 16.25 8.125 16.25H11.875C12.1875 16.25 12.5 15.9766 12.5 15.625C12.5 15.3125 12.1875 15 11.875 15Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M13.75 0H6.25C4.49219 0 3.125 1.40625 3.125 3.125V7H5.625V3.125C5.625 2.8125 5.89844 2.5 6.25 2.5H7.5V3.125C7.5 3.47656 7.77344 3.75 8.125 3.75H11.875C12.1875 3.75 12.5 3.47656 12.5 3.125V2.5H13.75C14.0625 2.5 14.375 2.8125 14.375 3.125V16.875C14.375 17.2266 14.0625 17.5 13.75 17.5H6.25C5.89844 17.5 5.625 17.2266 5.625 16.875V13H3.125V16.875C3.125 18.6328 4.49219 20 6.25 20H13.75C15.4688 20 16.875 18.6328 16.875 16.875V3.125C16.875 1.40625 15.4688 0 13.75 0Z"
            fill="currentColor"
            key="2"
        />,
        <path
            d="M9.70711 6.79289C9.31658 6.40237 8.68342 6.40237 8.29289 6.79289C7.90237 7.18342 7.90237 7.81658 8.29289 8.20711L9.08579 9H1C0.447715 9 0 9.44772 0 10C0 10.5523 0.447715 11 1 11H9.08579L8.29289 11.7929C7.90237 12.1834 7.90237 12.8166 8.29289 13.2071C8.68342 13.5976 9.31658 13.5976 9.70711 13.2071L12.2071 10.7071C12.5976 10.3166 12.5976 9.68342 12.2071 9.29289L9.70711 6.79289Z"
            fill="currentColor"
            key="3"
        />
    ]
})

export const SmartphoneOutIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SmartphoneOutIconLight',
    viewBox: '0 0 19 20',
    path: [
        <path
            d="M15.7096 7.04048C15.4558 6.78664 15.0442 6.78664 14.7904 7.04048C14.5365 7.29432 14.5365 7.70588 14.7904 7.95972L16.1808 9.3501H7.25001C6.89102 9.3501 6.60001 9.64111 6.60001 10.0001C6.60001 10.3591 6.89102 10.6501 7.25001 10.6501H16.1808L14.7904 12.0405C14.5365 12.2943 14.5365 12.7059 14.7904 12.9597C15.0442 13.2136 15.4558 13.2136 15.7096 12.9597L18.2096 10.4597C18.4635 10.2059 18.4635 9.79432 18.2096 9.54048L15.7096 7.04048Z"
            fill="currentColor"
            key="1"
        />,
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 0H10C11.3672 0 12.5 1.13281 12.5 2.5V8H11.25V2.5C11.25 1.83594 10.6641 1.25 10 1.25H8.75V1.875C8.75 2.57812 8.16406 3.125 7.5 3.125H5C4.29688 3.125 3.75 2.57812 3.75 1.875V1.25H2.5C1.79688 1.25 1.25 1.83594 1.25 2.5V17.5C1.25 18.2031 1.79688 18.75 2.5 18.75H10C10.6641 18.75 11.25 18.2031 11.25 17.5V12H12.5V17.5C12.5 18.9062 11.3672 20 10 20H2.5C1.09375 20 0 18.9062 0 17.5V2.5C0 1.13281 1.09375 0 2.5 0ZM7.5 1.25H5V1.875H7.5V1.25Z"
            fill="currentColor"
            key="2"
        />,
        <path
            d="M5 15.625H7.5C7.8125 15.625 8.125 15.9375 8.125 16.25C8.125 16.6016 7.8125 16.875 7.5 16.875H5C4.64844 16.875 4.375 16.6016 4.375 16.25C4.375 15.9375 4.64844 15.625 5 15.625Z"
            fill="currentColor"
            key="3"
        />
    ]
})

export const SmartphoneOutIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SmartphoneOutIconRegular',
    viewBox: '0 0 20 20',
    path: [
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2929 6.79289C15.6834 6.40237 16.3166 6.40237 16.7071 6.79289L19.2071 9.29289C19.5976 9.68342 19.5976 10.3166 19.2071 10.7071L16.7071 13.2071C16.3166 13.5976 15.6834 13.5976 15.2929 13.2071C14.9024 12.8166 14.9024 12.1834 15.2929 11.7929L16.0858 11H8C7.44772 11 7 10.5523 7 10C7 9.44772 7.44772 9 8 9H16.0858L15.2929 8.20711C14.9024 7.81658 14.9024 7.18342 15.2929 6.79289Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M5.125 15.625H8.875C9.1875 15.625 9.5 15.9375 9.5 16.25C9.5 16.6016 9.1875 16.875 8.875 16.875H5.125C4.77344 16.875 4.5 16.6016 4.5 16.25C4.5 15.9375 4.77344 15.625 5.125 15.625Z"
            fill="currentColor"
            key="2"
        />,
        <path
            d="M2.625 0H11.375C12.7422 0 13.875 1.13281 13.875 2.5V7H12V2.5C12 2.1875 11.6875 1.875 11.375 1.875H9.5V3.125C9.5 3.47656 9.1875 3.75 8.875 3.75H5.125C4.77344 3.75 4.5 3.47656 4.5 3.125V1.875H2.625C2.27344 1.875 2 2.1875 2 2.5V17.5C2 17.8516 2.27344 18.125 2.625 18.125H11.375C11.6875 18.125 12 17.8516 12 17.5V13H13.875V17.5C13.875 18.9062 12.7422 20 11.375 20H2.625C1.21875 20 0.125 18.9062 0.125 17.5V2.5C0.125 1.13281 1.21875 0 2.625 0Z"
            fill="currentColor"
            key="3"
        />
    ]
})

export const SmartphoneOutIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SmartphoneOutIconSolid',
    viewBox: '0 0 14 20',
    path: [
        <path
            d="M11.875 15H8.125C7.77344 15 7.5 15.3125 7.5 15.625C7.5 15.9766 7.77344 16.25 8.125 16.25H11.875C12.1875 16.25 12.5 15.9766 12.5 15.625C12.5 15.3125 12.1875 15 11.875 15Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M13.75 0H6.25C4.49219 0 3.125 1.40625 3.125 3.125V7H5.625V3.125C5.625 2.8125 5.89844 2.5 6.25 2.5H7.5V3.125C7.5 3.47656 7.77344 3.75 8.125 3.75H11.875C12.1875 3.75 12.5 3.47656 12.5 3.125V2.5H13.75C14.0625 2.5 14.375 2.8125 14.375 3.125V16.875C14.375 17.2266 14.0625 17.5 13.75 17.5H6.25C5.89844 17.5 5.625 17.2266 5.625 16.875V13H3.125V16.875C3.125 18.6328 4.49219 20 6.25 20H13.75C15.4688 20 16.875 18.6328 16.875 16.875V3.125C16.875 1.40625 15.4688 0 13.75 0Z"
            fill="currentColor"
            key="2"
        />,
        <path
            d="M9.70711 6.79289C9.31658 6.40237 8.68342 6.40237 8.29289 6.79289C7.90237 7.18342 7.90237 7.81658 8.29289 8.20711L9.08579 9H1C0.447715 9 0 9.44772 0 10C0 10.5523 0.447715 11 1 11H9.08579L8.29289 11.7929C7.90237 12.1834 7.90237 12.8166 8.29289 13.2071C8.68342 13.5976 9.31658 13.5976 9.70711 13.2071L12.2071 10.7071C12.5976 10.3166 12.5976 9.68342 12.2071 9.29289L9.70711 6.79289Z"
            fill="currentColor"
            key="3"
        />
    ]
})
