export interface MetricData {
    meta?: { clientID?: string; destinationID?: string; userID?: string }
}

abstract class Metric<T extends MetricData = MetricData> {
    protected data: T

    protected constructor(data: T) {
        this.data = data
    }

    public toJSON() {
        return this.data
    }

    public toString() {
        return JSON.stringify(this.data)
    }
}

export default Metric
