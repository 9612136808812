import {
    useAudioInputs,
    useAudioOutputs,
    useVideoInputs
} from 'amazon-chime-sdk-component-library-react'
import { useCallback } from 'react'

const useRealDevice = () => {
    const { devices: audioInputDevices } = useAudioInputs()
    const { devices: audioOutputDevices } = useAudioOutputs()
    const { devices: videoInputDevices } = useVideoInputs()

    const getRealAudioInputDeviceId = useCallback(
        (id: string) => {
            if (id === 'default') {
                const device = audioInputDevices.find(device => device.deviceId === id)

                if (!device) {
                    return
                }
                const foundDevice = audioInputDevices.find(
                    //@ts-ignore
                    d => d.deviceId !== 'default' && d.groupId === device.groupId
                )
                return foundDevice?.deviceId
            }
            return id
        },
        [audioInputDevices]
    )

    const getRealAudioInputDevice = useCallback(
        (id: string) => {
            const device = audioInputDevices.find(device => device.deviceId === id)

            if (!device) return

            if (id !== 'default') {
                return device
            }

            const foundDevice = audioInputDevices.find(
                //@ts-ignore
                d => d.deviceId !== 'default' && d.groupId === device.groupId
            )
            return foundDevice
        },
        [audioInputDevices]
    )

    const getRealAudioOutputDeviceId = useCallback(
        (id: string) => {
            if (id === 'default') {
                const device = audioOutputDevices.find(device => device.deviceId === id)

                if (!device) {
                    return
                }
                const foundDevice = audioOutputDevices.find(
                    //@ts-ignore
                    d => d.deviceId !== 'default' && d.groupId === device.groupId
                )
                return foundDevice?.deviceId
            }
            return id
        },
        [audioOutputDevices]
    )

    const getRealAudioOutputDevice = useCallback(
        (id: string) => {
            const device = audioOutputDevices.find(device => device.deviceId === id)

            if (!device) return

            if (id !== 'default') {
                return device
            }

            const foundDevice = audioOutputDevices.find(
                //@ts-ignore
                d => d.deviceId !== 'default' && d.groupId === device.groupId
            )
            return foundDevice
        },
        [audioOutputDevices]
    )

    const getRealVideoInputDeviceId = useCallback(
        (id: string) => {
            if (id === 'default') {
                const device = videoInputDevices.find(device => device.deviceId === id)

                if (!device) {
                    return
                }
                const foundDevice = videoInputDevices.find(
                    //@ts-ignore
                    d => d.deviceId !== 'default' && d.groupId === device.groupId
                )
                return foundDevice?.deviceId
            }
            return id
        },
        [videoInputDevices]
    )

    const getRealVideoInputDevice = useCallback(
        (id: string) => {
            const device = videoInputDevices.find(device => device.deviceId === id)

            if (!device) return

            if (id !== 'default') {
                return device
            }

            const foundDevice = videoInputDevices.find(
                //@ts-ignore
                d => d.deviceId !== 'default' && d.groupId === device.groupId
            )
            return foundDevice
        },
        [videoInputDevices]
    )

    return {
        getRealAudioInputDevice,
        getRealAudioInputDeviceId,
        getRealAudioOutputDevice,
        getRealAudioOutputDeviceId,
        getRealVideoInputDevice,
        getRealVideoInputDeviceId
    }
}

export default useRealDevice
