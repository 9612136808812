import { HStack, VStack } from '@chakra-ui/react'
import { selectPreferences, updatePreferences, useDispatch, useSelector } from '@missionlabs/api'
import { Body, Dropdown, Toggle } from '@missionlabs/react'
import { useTranslation } from 'react-i18next'

import { SettingsRow } from '../../components/SettingsRow'

export const AfterCallSettings = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const preferences = useSelector(selectPreferences)
    const { showAfterCallScreen = 'unsaved', closeAfterCallScreenOnSave = true } = preferences

    return (
        <SettingsRow label={t('afterCallSettings.title')}>
            <VStack w="full" justify="space-between" align="flex-start" spacing={4}>
                <VStack w="full" justify="space-between" align="flex-start">
                    <Body size="sm" variant="bold">
                        {t('afterCallSettings.showWhen.title')}
                    </Body>
                    <Dropdown
                        w="full"
                        value={showAfterCallScreen}
                        onChange={val => dispatch(updatePreferences({ showAfterCallScreen: val }))}
                        options={[
                            { label: t('afterCallSettings.showWhen.always'), value: 'always' },
                            { label: t('afterCallSettings.showWhen.unsaved'), value: 'unsaved' },
                            { label: t('afterCallSettings.showWhen.never'), value: 'never' }
                        ]}
                    />
                </VStack>
                {showAfterCallScreen !== 'never' && (
                    <HStack w="full" justify="space-between" align="flex-start">
                        <Body size="sm" variant="bold">
                            {t('afterCallSettings.closeOnSave')}
                        </Body>
                        <Toggle
                            isChecked={closeAfterCallScreenOnSave ?? false}
                            onChange={e =>
                                dispatch(
                                    updatePreferences({
                                        closeAfterCallScreenOnSave: e.target.checked
                                    })
                                )
                            }
                        />
                    </HStack>
                )}
            </VStack>
        </SettingsRow>
    )
}
