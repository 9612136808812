import { useEffect } from 'react'
import { useUpdate } from 'react-use'

/** Returns true if the app window is not minimized */
export function useIsAppActive() {
    const update = useUpdate()

    useEffect(() => {
        document.addEventListener('visibilitychange', update)
        return () => {
            document.removeEventListener('visibilitychange', update)
        }
    }, [update])

    return !document.hidden
}
