import { useColorMode } from '@chakra-ui/react'
import {
    call,
    selectAuthenticatedUser,
    selectPresentationNumber,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { IconButton, Menu, MenuButton, MenuItem, MenuList, useToast } from '@missionlabs/react'
import {
    DeleteIconOutline,
    FlagIconOutline,
    KebabMenuIcon,
    UserCircleIconLight
} from '@missionlabs/react/circleloop'
import { ArrowLeftCorner, PhoneIconRegular } from '@missionlabs/react/zeta'
import { UserActivity } from '@missionlabs/types'
import { useActivityFooterState } from 'features/activity/ActivityFeed/hooks/useActivityFooterState'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetUserData } from 'shared/hooks/useGetUserData'
import { useIsSMSEnabled } from 'shared/hooks/useIsSMSEnabled'
import {
    addCallNote,
    CallNoteSliceRootState,
    removeCallNote,
    selectActivityCallNotes
} from 'shared/slices/callNoteSlice'
import {
    useDeleteCallNoteMutation,
    useDeleteFlaggedActivitiesMutation,
    useUpdateFlaggedActivitiesMutation
} from 'shared/store'
import { getDirectionOfActivity } from 'shared/utils/activities'

import { ActivityFooterMessageType } from '../types'
import { useAddNumberToContactDrawer } from './components/AddNumberToContact/useAddNumberToContactDrawer'

interface ActivityFeedRowMenuProps {
    activity: UserActivity
}

export const ActivityFeedRowMenu = memo(({ activity }: ActivityFeedRowMenuProps) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const drawer = useAddNumberToContactDrawer()
    const { formatToNumberE164 } = useFormatToNumberE164()
    const dispatch = useDispatch()
    const { callRoute } = useGetUserData()
    const { toast } = useToast()
    const presentationNumber = useSelector(selectPresentationNumber)

    const fromNumber = useMemo(
        () => presentationNumber ?? callRoute?.defaultPhoneNumber ?? '',
        [presentationNumber, callRoute?.defaultPhoneNumber]
    )

    const toNumber = useMemo(() => {
        const num =
            getDirectionOfActivity(activity) === 'inbound'
                ? activity.originatingNumber
                : activity.dialledNumber
        return formatToNumberE164(num)
    }, [formatToNumberE164, activity])

    const user = useSelector(selectAuthenticatedUser)
    const [updateFlaggedActivities] = useUpdateFlaggedActivitiesMutation()
    const [deleteFlaggedActivities] = useDeleteFlaggedActivitiesMutation()

    const [deleteCallNote] = useDeleteCallNoteMutation()

    const { onOpen, setMessageType, setActivity } = useActivityFooterState()
    const smsEnabled = useIsSMSEnabled([toNumber], fromNumber)

    const [removedCallNoteIdx, setRemovedCallNoteIdx] = useState<number[]>([])

    useEffect(() => {
        if (!activity) return
        activity.callNotes?.forEach((callNote, index) => {
            // To prevent call notes from being resurrected after they are deleted.
            // Skip call notes whose indexes have been removed.
            // (this prevents us from re-adding old activity notes)
            if (!removedCallNoteIdx.includes(index)) {
                dispatch(
                    addCallNote({
                        callTraceID: activity.callTraceID,
                        note: callNote.content
                    })
                )
            }
        })
    }, [dispatch, activity, removedCallNoteIdx])

    const callNotes = useSelector((state: CallNoteSliceRootState) =>
        selectActivityCallNotes(state, activity?.callTraceID)
    )

    const handleFlag = useCallback(() => {
        if (!user) throw Error('No user')
        if (activity.isFlaggedByUser) throw Error('Activity already flagged by user')
        const payload = {
            userID: user.userID,
            activities: [activity.ID]
        }
        updateFlaggedActivities(payload)
    }, [activity, user, updateFlaggedActivities])

    const handleUnflag = useCallback(() => {
        if (!user) throw Error('No user')
        if (!activity.isFlaggedByUser) throw Error('Activity is not flagged by user')
        const payload = {
            userID: user.userID,
            activities: [activity.ID]
        }
        deleteFlaggedActivities(payload)
    }, [activity, user, deleteFlaggedActivities])

    const handleReturnCall = useCallback(() => {
        if (toNumber && fromNumber) {
            dispatch(call({ to: toNumber, from: fromNumber, contact: activity.contact }))
        }
    }, [activity, dispatch, fromNumber, toNumber])

    const handleReply = useCallback(() => {
        onOpen()
    }, [onOpen])

    const handleAddEditNotes = useCallback(() => {
        setMessageType(ActivityFooterMessageType.Notes)
        setActivity(activity)
        onOpen()
    }, [onOpen, setMessageType, setActivity, activity])

    const handleDeleteNotes = useCallback(async () => {
        try {
            // Prefer using slice over activity
            const callNoteID = callNotes?.at(-1)?.ID ?? activity.callNotes?.at(-1)?.ID
            const callTraceID = activity.callTraceID ?? callNotes?.at(-1)?.callTraceID
            // If this is -1, it means activity is empty
            const _removedCallNoteIdx = (activity.callNotes?.length ?? 0) - 1

            if (!callNoteID) throw new Error('No call note ID on userActivity')
            if (!callTraceID) throw new Error('No call trace ID on userActivity')

            await deleteCallNote({ callNoteID }).unwrap()

            if (_removedCallNoteIdx >= 0) {
                // Current activity state contains call notes,
                // mark them as removed so they dont get resurrected
                setRemovedCallNoteIdx([...removedCallNoteIdx, _removedCallNoteIdx])
            }

            dispatch(removeCallNote({ callTraceID }))

            toast({ status: 'success', title: 'Call note successfully deleted' })
        } catch (err) {
            toast({ status: 'error', title: 'Error deleting call note' })
        }
    }, [
        activity,
        callNotes,
        deleteCallNote,
        dispatch,
        toast,
        removedCallNoteIdx,
        setRemovedCallNoteIdx
    ])

    return (
        <Menu>
            <MenuButton as={IconButton} size="sm" variant="ghost" aria-label={'More'}>
                <KebabMenuIcon />
            </MenuButton>
            <MenuList>
                {/* // Todo: Change to appropriate icon */}
                {activity.activityClass === 'call' && (
                    <>
                        <MenuItem icon={<FlagIconOutline />} onClick={handleAddEditNotes}>
                            {callNotes && callNotes?.length > 0
                                ? t('activities.editNotes')
                                : t('activities.saveNotes')}
                        </MenuItem>
                        {callNotes && callNotes?.length > 0 && (
                            <MenuItem icon={<DeleteIconOutline />} onClick={handleDeleteNotes}>
                                {t('activities.deleteNotes')}
                            </MenuItem>
                        )}
                    </>
                )}

                {activity.isFlaggedByUser ? (
                    <MenuItem icon={<FlagIconOutline />} onClick={handleUnflag}>
                        {t('activities.unflagItem')}
                    </MenuItem>
                ) : (
                    <MenuItem icon={<FlagIconOutline />} onClick={handleFlag}>
                        {t('activities.flagForLater')}
                    </MenuItem>
                )}
                <MenuItem
                    isDisabled={!smsEnabled}
                    icon={
                        <ArrowLeftCorner color={`${colorMode}.primary.gamma`} sx={{ padding: 0 }} />
                    }
                    onClick={handleReply}
                >
                    {t('activities.replyToMessage')}
                </MenuItem>
                {!activity.contactID && (
                    <MenuItem icon={<UserCircleIconLight />} onClick={drawer.onOpen}>
                        {t('contacts.addToExisting')}
                    </MenuItem>
                )}
                {fromNumber && toNumber && (
                    <MenuItem icon={<PhoneIconRegular />} onClick={handleReturnCall}>
                        {t('activities.returnCall')}
                    </MenuItem>
                )}
            </MenuList>
        </Menu>
    )
})
