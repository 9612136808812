import { Flex } from '@chakra-ui/react'
import { Banner, Button } from '@missionlabs/react'
import { WarningIconSolid } from '@missionlabs/react/zeta'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const IntegrationErrorBanner = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Banner id="account-suspended-header" h="60px" variant="darkRed">
            <Flex align="center" gap="18px">
                <WarningIconSolid />

                <>
                    <span>{t('integrationsWarning.text')} </span>
                    <Button
                        color="#fff"
                        _hover={{ color: '#fff' }}
                        variant="link-underline"
                        onClick={() => {
                            return navigate('/settings/integrations')
                        }}
                    >
                        {t('integrationsWarning.help')}
                    </Button>
                </>
            </Flex>
        </Banner>
    )
}

export default IntegrationErrorBanner
