import { Center, useColorMode, VStack } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { EmptyPage, Scrollable } from '@missionlabs/react'
import { MeetingStatus } from '@missionlabs/types'
import { isValid } from 'date-fns'
import { FC, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetScheduledMeetingsQuery } from 'shared/store'

import { MeetingDayHeader } from './MeetingDayHeader'
import { POLLING_INTERVAL } from './MeetingsDashboard'
import { ScheduledMeeting } from './ScheduledMeeting/ScheduledMeeting'

export const MeetingDay: FC = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const user = useSelector(selectAuthenticatedUser)
    const ref = useRef<HTMLDivElement>(null)

    const timeRangeStart = params.get('timeRangeStart') ?? ''
    const timeRangeEnd = params.get('timeRangeEnd') ?? ''

    const { data: scheduledMeetings = [] } = useGetScheduledMeetingsQuery(
        {
            userID: user?.userID ?? '',
            timeRangeStart,
            timeRangeEnd
        },
        {
            skip: !user?.userID || !timeRangeStart || !timeRangeEnd,
            pollingInterval: POLLING_INTERVAL
        }
    )

    useEffect(() => {
        if (!timeRangeStart || !timeRangeEnd) return navigate('/meetings')

        const unixFrom = parseInt(timeRangeStart) // if something other than a number it will return NaN so we can then prevent new Date causing an error
        const unixTo = parseInt(timeRangeEnd)

        if (!unixFrom || !unixTo || !isValid(new Date(unixFrom))) return navigate('/meetings')
    }, [timeRangeStart, timeRangeEnd, navigate])

    const allNonCancelledMeetings = useMemo(
        () => scheduledMeetings.filter(({ status }) => status !== MeetingStatus.CANCELLED),
        [scheduledMeetings]
    )

    return (
        <VStack spacing={0} w="full">
            <MeetingDayHeader ref={ref} timeRangeStart={timeRangeStart} />
            <Scrollable
                w="full"
                h={`calc(100dvh - ${ref.current?.offsetHeight ?? 108}px)`}
                bg={`${colorMode}.tones.ghostWhite`}
            >
                {!scheduledMeetings.length && (
                    <Center w="full" h="full" bg={`${colorMode}.tones.ghostWhite`}>
                        <EmptyPage showLogo={false} title={t('meetings.noMeetings')} />
                    </Center>
                )}
                <VStack spacing={0}>
                    {allNonCancelledMeetings.map(meeting => (
                        <ScheduledMeeting
                            key={meeting.ID}
                            meeting={meeting}
                            allMeetings={allNonCancelledMeetings}
                        />
                    ))}
                </VStack>
            </Scrollable>
        </VStack>
    )
}
