import { Button, HStack, VStack } from '@chakra-ui/react'
import {
    FormProvider,
    Input,
    useFormatToNumberE164,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'
import { useGetNumberPortabilityQuery } from 'shared/store'

interface InputPortingNumberProps {
    onClose: () => void
    setPortabilityState: (data: any) => void
}

interface PortingSchema {
    portingNumber: string
}

const InputPortingNumber: FC<InputPortingNumberProps> = ({ onClose, setPortabilityState }) => {
    const { t } = useTranslation()

    const { isValidPhoneNumber, formatToNumberE164 } = useFormatToNumberE164()

    const verifySchema = yup.object().shape({
        portingNumber: yup
            .string()
            .test({
                message: {
                    key: 'validations.invalidNumber'
                },
                test: value => isValidPhoneNumber(value)
            })
            .required()
    })

    const methods = useYupForm<PortingSchema>(verifySchema, {
        defaultValues: { portingNumber: '' }
    })

    const {
        watch,
        setValue,
        formState: { isValid, isSubmitted }
    } = methods

    const portingNumber = watch('portingNumber')

    useDebounce(
        () => {
            const formattedNumber = formatToNumberE164(portingNumber)
            /** The input number appends the country code again when removing the last two digits, after previously formatting the number,
             *  so this ensure it only sets when the number is valid  */
            if (formattedNumber && isValidPhoneNumber(formattedNumber)) {
                setValue('portingNumber', formattedNumber)
            }
        },
        1000,
        [portingNumber, formatToNumberE164, setValue]
    )

    const { data, isLoading } = useGetNumberPortabilityQuery(portingNumber, {
        skip: !isSubmitted || !isValid
    })

    useEffect(() => {
        setPortabilityState(prev => ({
            ...prev,
            number: portingNumber,
            ...data
        }))
    }, [setPortabilityState, data, portingNumber])

    return (
        <FormProvider {...methods}>
            <VStack as="form" onSubmit={methods.handleSubmit(() => {})} h="full" p={4}>
                <HStack w="full" align="flex-start" justify="space-evenly" h={'90%'}>
                    <ValidatedInput
                        name="portingNumber"
                        label={t('admin.numbers.porting.enterNumber')}
                    >
                        <Input autoComplete="off" />
                    </ValidatedInput>
                </HStack>

                <HStack h={'10%'} w="full">
                    <Button w="full" size="lg" variant="secondary" onClick={onClose}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        w="full"
                        size="lg"
                        variant="creationary"
                        isLoading={isLoading}
                        isDisabled={!isValid}
                        type="submit"
                    >
                        {t('Submit')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}

export default InputPortingNumber
