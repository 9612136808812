import { ServicePackResponse, UserAssignedPacks } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildServicePacksAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'servicePacksAPI',
        tagTypes: ['ServicePacks'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            // Get service packs on a client
            getAssignedServicePacksForClient: builder.query<ServicePackResponse, void>({
                query: () => '/servicepacks/counts',
                providesTags: () => ['ServicePacks']
            }),

            // Get Sevice packs assigned to a user
            getAssignedServicePacksForUser: builder.query<UserAssignedPacks, string>({
                query: userID => {
                    if (!userID) {
                        throw new Error('ServicePackAPI | userID is required')
                    }
                    return `/users/${userID}/servicepacks`
                },

                providesTags: (result, error, userID) => [{ type: 'ServicePacks', id: userID }]
            })
        })
    })

    return { api, ...api }
}
