import { Breadcrumb } from '@missionlabs/react'
import { AnalyticsActivities } from 'features/analytics/components/AnalyticsActivites/AnalyticsActivities'
import { AnalyticsPieChartTotals } from 'features/analytics/components/AnalyticsPieChartTotals/AnalyticsPieChartTotals'
import AnalyticsTabsView from 'features/analytics/components/AnalyticsTabView'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import {
    useGetSingleCallGroupRecentCallHistoryQuery,
    useGetSingleCallGroupTotalsAnalyticsQuery
} from 'shared/store'

interface Props {}

const AnalyticsTeamsExpanded: React.FC<Props> = () => {
    const { teamID } = useParams()
    const { t } = useTranslation()

    const navigate = useNavigate()

    const { dateScroller, group } = useAnalytics()

    const {
        state: { teamName }
    } = useLocation()

    const apiArgs = {
        from: dateScroller.start?.getTime().toString(),
        to: dateScroller.end?.getTime().toString(),
        timezone: dateScroller.timezone,
        group,
        teamID
    }

    const {
        data: userTotal,
        isLoading: isUserTotalLoading,
        isFetching: isUserTotalFetching
    } = useGetSingleCallGroupTotalsAnalyticsQuery(apiArgs, {
        skip: !dateScroller.start || !dateScroller.end
    })

    const {
        data: teamActivities,
        isLoading: isTeamActivitiesLoading,
        isFetching: isTeamActivitiesFetching
    } = useGetSingleCallGroupRecentCallHistoryQuery(
        {
            from: dateScroller.start?.getTime().toString(),
            to: dateScroller.end?.getTime().toString(),
            timezone: dateScroller.timezone,
            group,
            teamID
        },
        { skip: !dateScroller.start || !dateScroller.end }
    )

    const isLoading =
        isUserTotalLoading ||
        isUserTotalFetching ||
        isTeamActivitiesLoading ||
        isTeamActivitiesFetching

    const isNoResults = !teamActivities?.data.length && !userTotal?.data.length

    return (
        <SettingsPage
            title={
                <Breadcrumb
                    variant="title"
                    items={[
                        {
                            title: t('analytics.teams'),
                            onClick: () => navigate('../teams'),
                            isCurrentPage: false
                        },
                        { title: teamName ?? teamID!, isCurrentPage: true }
                    ]}
                >
                    Users
                </Breadcrumb>
            }
            subtitle={t('analytics.teamsSubtitle')}
            contentProps={{ padding: 0, maxW: 'unset' }}
        >
            <AnalyticsTabsView noResults={isNoResults} isLoading={isLoading} header={null}>
                {!!userTotal && (
                    <AnalyticsPieChartTotals
                        durations={userTotal.durations}
                        totals={userTotal.total}
                    />
                )}
                <AnalyticsActivities activities={teamActivities?.data ?? []} />
            </AnalyticsTabsView>
        </SettingsPage>
    )
}

export default AnalyticsTeamsExpanded
