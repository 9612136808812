import { DrawerFooter, HStack } from '@chakra-ui/react'
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps,
    useMeasure
} from '@missionlabs/react'
import { UserOrTeam } from '@missionlabs/types'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AvailableTabs, UTMTabs } from './UTMTabs'

export interface UTMDrawerProps extends Omit<DrawerProps, 'children'> {
    defaultValue?: string
    omitSelf?: boolean // Omits the currently authenticated user from appearing in the user list.
    isLoading?: boolean
    onSubmit: (data: UserOrTeam) => void
    tabs?: AvailableTabs[]
}

export { AvailableTabs }

/** User, Team and Menus Drawer */
export const UTMDrawer: FC<UTMDrawerProps> = ({
    defaultValue,
    omitSelf,
    isLoading,
    onSubmit,
    tabs,
    ...props
}) => {
    const { t } = useTranslation()

    const [headerRef, { blockSize: headerHeight }] = useMeasure<HTMLDivElement>()
    const [footerRef, { blockSize: footerHeight }] = useMeasure<HTMLDivElement>()

    const [selectedItem, setSelectedItem] = useState<UserOrTeam>()

    const handleSelect = (data: UserOrTeam) => {
        setSelectedItem(selectedItem => (selectedItem === data ? undefined : data))
    }

    const handleSubmit = () => {
        if (!selectedItem) return
        onSubmit(selectedItem)
    }

    const handleTransition = () => {
        setSelectedItem(undefined)
        props.onCloseComplete?.()
    }

    return (
        <Drawer {...props} size="lg" onCloseComplete={handleTransition}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader ref={headerRef}>{t('calls.UTM')}</DrawerHeader>
                <DrawerBody>
                    <UTMTabs
                        omitSelf={omitSelf}
                        siblingHeight={headerHeight + footerHeight}
                        defaultValue={defaultValue}
                        selectedItem={selectedItem}
                        onSelect={handleSelect}
                        tabs={tabs}
                    />
                </DrawerBody>
                <DrawerFooter ref={footerRef} pt="32px" p="24px" gap="16px">
                    <HStack w="full">
                        <Button
                            w="full"
                            size="lg"
                            variant="secondary"
                            isDisabled={isLoading}
                            onClick={props.onClose}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            w="full"
                            size="lg"
                            variant="creationary"
                            isDisabled={!selectedItem}
                            isLoading={isLoading}
                            onClick={handleSubmit}
                        >
                            {t('Done')}
                        </Button>
                    </HStack>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}
