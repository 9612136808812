import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const DesktopIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DesktopIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.9162 0.0268555H2.1686C1.59633 0.0268555 1.0685 0.243655 0.669567 0.638185C0.268207 1.03565 0.0455473 1.56543 0.0426173 2.15772V14H24.0002V15H0.0426173V15.8828C0.0362673 16.4492 0.251117 16.9844 0.647107 17.3887C1.04311 17.7939 1.57289 18.0205 2.16566 18.0273H3.03382L11.0416 18.0274H21.9151C23.088 18.0274 24.0392 17.0781 24.0421 15.8887V2.16209C24.047 1.59666 23.8321 1.06346 23.4356 0.660135C23.0392 0.256835 22.5099 0.0317355 21.9162 0.0268555ZM14.721 19.631H8.65535L8.64719 19.673C8.61986 19.6634 8.59133 19.6562 8.56177 19.6514C8.29517 19.6016 8.0315 19.791 7.98853 20.0645L7.50709 23.0284C7.49927 23.0733 7.4983 23.1192 7.50318 23.1651C7.55787 23.6426 7.969 24.002 8.44068 24.002C8.469 24.002 8.49829 24.001 8.52759 23.9981L11.4531 23.9998C11.4686 24.0012 11.4843 24.002 11.5002 24.002L14.8298 24C14.8591 24.003 14.8884 24.0039 14.9167 24.0039C15.3884 24.0039 15.7995 23.6446 15.8542 23.167C15.8591 23.1211 15.8581 23.0752 15.8503 23.0303L15.3688 20.0664C15.3259 19.793 15.0622 19.6035 14.7956 19.6533C14.7711 19.6573 14.7473 19.663 14.7243 19.6702L14.721 19.631ZM14.8324 23H11.8572C11.8722 23 11.887 23.0007 11.9017 23.002H14.8327L14.8324 23Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_185"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="25"
                height="25"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.9162 0.0268555H2.1686C1.59633 0.0268555 1.0685 0.243655 0.669567 0.638185C0.268207 1.03565 0.0455473 1.56543 0.0426173 2.15772V14H24.0002V15H0.0426173V15.8828C0.0362673 16.4492 0.251117 16.9844 0.647107 17.3887C1.04311 17.7939 1.57289 18.0205 2.16566 18.0273H3.03382L11.0416 18.0274H21.9151C23.088 18.0274 24.0392 17.0781 24.0421 15.8887V2.16209C24.047 1.59666 23.8321 1.06346 23.4356 0.660135C23.0392 0.256835 22.5099 0.0317355 21.9162 0.0268555ZM14.721 19.631H8.65535L8.64719 19.673C8.61986 19.6634 8.59133 19.6562 8.56177 19.6514C8.29517 19.6016 8.0315 19.791 7.98853 20.0645L7.50709 23.0284C7.49927 23.0733 7.4983 23.1192 7.50318 23.1651C7.55787 23.6426 7.969 24.002 8.44068 24.002C8.469 24.002 8.49829 24.001 8.52759 23.9981L11.4531 23.9998C11.4686 24.0012 11.4843 24.002 11.5002 24.002L14.8298 24C14.8591 24.003 14.8884 24.0039 14.9167 24.0039C15.3884 24.0039 15.7995 23.6446 15.8542 23.167C15.8591 23.1211 15.8581 23.0752 15.8503 23.0303L15.3688 20.0664C15.3259 19.793 15.0622 19.6035 14.7956 19.6533C14.7711 19.6573 14.7473 19.663 14.7243 19.6702L14.721 19.631ZM14.8324 23H11.8572C11.8722 23 11.887 23.0007 11.9017 23.002H14.8327L14.8324 23Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_185)"></g>
        </g>
    )
})
