import { Box } from '@chakra-ui/react'
import { Body, useToast } from '@missionlabs/react'
import { SettingSwitch } from 'features/settings/components/SettingsSwitch'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetClientQuery, useGetUsersQuery, useUpdateClientMutation } from 'shared/store'

import { RecordingTable } from './RecordingTable'

export const CallRecording: FC = () => {
    const { t } = useTranslation()
    const { data: users = [], isLoading } = useGetUsersQuery()
    const { data: client } = useGetClientQuery()
    const [updateClient] = useUpdateClientMutation()
    const { toast } = useToast()

    async function handleChange(enabled: boolean) {
        try {
            await updateClient({ callRecording: enabled }).unwrap()
            toast({ status: 'success', title: t('admin.callRecording.updateSuccess') })
        } catch (error) {
            console.error('Error changing call recording settings', error)
            toast({ status: 'error', title: t('admin.callRecording.updateError') })
        }
    }

    return (
        <div>
            <SettingsPage
                maxW="full"
                title={t('admin.callRecording.title')}
                subtitle={t('admin.callRecording.description')}
                isLoading={isLoading}
                isEmpty={!users.length}
            >
                <Box w="full">
                    <SettingSwitch
                        name="callRecording"
                        label={t('admin.callRecording.toggleLabel')}
                        isChecked={client?.callRecording || false}
                        onUpdate={handleChange}
                    />
                    <Body size="sm">{t('admin.callRecording.toggleDescription')}</Body>
                </Box>
                {client?.callRecording && <RecordingTable users={users} />}
            </SettingsPage>
        </div>
    )
}
