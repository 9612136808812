import { VStack } from '@chakra-ui/react'
import { Dropdown, Textarea, useFormContext, ValidatedInput } from '@missionlabs/react'
import { useTranslation } from 'react-i18next'

import { CancelSubscriptionFields } from './CancelSubscriptionDialog'

export const CancelSubscriptionForm: React.FC = () => {
    const { t } = useTranslation()

    const { watch, setValue } = useFormContext<CancelSubscriptionFields>()

    const { cancelCategory } = watch()

    const options = [
        { label: t('admin.subscription.cancel.reasons.tooExpensive'), value: 'tooExpensive' },
        {
            label: t('admin.subscription.cancel.reasons.poorCustomerService'),
            value: 'poorCustomerService'
        },
        { label: t('admin.subscription.cancel.reasons.callQuality'), value: 'callQuality' },
        {
            label: t('admin.subscription.cancel.reasons.notReceivingCalls'),
            value: 'notReceivingCalls'
        },
        {
            label: t('admin.subscription.cancel.reasons.DesktopAppIssues'),
            value: 'DesktopAppIssues'
        },
        {
            label: t('admin.subscription.cancel.reasons.AndroidAppIssues'),
            value: 'AndroidAppIssues'
        },
        { label: t('admin.subscription.cancel.reasons.IOSAppIssues'), value: 'IOSAppIssues' },
        {
            label: t('admin.subscription.cancel.reasons.integrationIssues'),
            value: 'integrationIssues'
        },
        { label: t('admin.subscription.cancel.reasons.missingFeatures'), value: 'missingFeatures' },
        { label: t('admin.subscription.cancel.reasons.doesntSuitNeeds'), value: 'doesntSuitNeeds' },
        { label: t('admin.subscription.cancel.reasons.other'), value: 'other' }
    ]

    const optionToCategory = {
        tooExpensive: 'cost',
        poorCustomerService: 'service_quality',
        callQuality: 'service_quality',
        notReceivingCalls: 'service_quality',
        DesktopAppIssues: 'service_quality',
        AndroidAppIssues: 'service_quality',
        IOSAppIssues: 'service_quality',
        integrationIssues: 'service_quality',
        missingFeatures: 'service_quality',
        doesntSuitNeeds: 'personal',
        other: 'other'
    }

    const handleCancellationReasonChange = (cancelReason: string) => {
        const cancallationCategory = optionToCategory[cancelReason]

        setValue('cancelCategory', cancallationCategory, { shouldValidate: true })

        if (cancallationCategory === 'other') {
            setValue('cancelReason', '', { shouldValidate: true })
            return
        }

        setValue('cancelReason', cancelReason, { shouldValidate: true })
    }

    return (
        <VStack py={2}>
            <ValidatedInput
                name="cancelCategory"
                flex={1}
                label={t('admin.subscription.cancel.cancelReason')}
            >
                <Dropdown
                    options={options}
                    value={cancelCategory === 'other' ? cancelCategory : watch('cancelReason')}
                    onChange={handleCancellationReasonChange}
                />
            </ValidatedInput>

            {cancelCategory === 'other' && (
                <ValidatedInput
                    flex={1}
                    name={'cancelReason'}
                    label={t('admin.subscription.cancel.cancelReasonNote')}
                >
                    <Textarea
                        aria-label="Cancellation Reason Note"
                        noOfLines={4}
                        variant="dark"
                        value={watch('cancelReason')}
                        onChange={e =>
                            setValue('cancelReason', e.target.value, {
                                shouldDirty: true,
                                shouldValidate: true
                            })
                        }
                    />
                </ValidatedInput>
            )}
        </VStack>
    )
}
