import { HStack } from '@chakra-ui/react'
import { Button, Heading } from '@missionlabs/react'
import { AddCircleIconSolid, CalendarDayIcon } from '@missionlabs/react/zeta'
import { getMillisecondsUnix } from '@missionlabs/utils'
import { format } from 'date-fns'
import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { SettingsHeader } from 'shared/components/settings/SettingsHeader'

type MeetingDayHeaderProps = {
    timeRangeStart: string
}

export const MeetingDayHeader = forwardRef<HTMLDivElement, MeetingDayHeaderProps>(
    ({ timeRangeStart }, ref) => {
        const { t } = useTranslation()
        const navigate = useNavigate()
        const location = useLocation()

        const formattedDate = useMemo(() => {
            const startDate = new Date(getMillisecondsUnix(parseInt(timeRangeStart)))
            return format(startDate, `EEEE do MMMM`)
        }, [timeRangeStart])

        const showButton = useMemo(() => {
            const today = new Date()
            today.setHours(0, 0, 0, 0)

            return today.getTime() <= getMillisecondsUnix(parseInt(timeRangeStart))
        }, [timeRangeStart])

        return (
            <SettingsHeader
                ref={ref}
                w="100%"
                title={
                    <HStack spacing={4} align="center">
                        <CalendarDayIcon boxSize="24px" />
                        <Heading size="h3" fontSize="20px" lineHeight="32px" fontWeight={400}>
                            {formattedDate}
                        </Heading>
                    </HStack>
                }
                actions={
                    <>
                        {showButton && (
                            <Button
                                variant="creationary"
                                leftIcon={<AddCircleIconSolid boxSize="18px" />}
                                onClick={() =>
                                    navigate(`${location.pathname}/new${location.search}`)
                                }
                            >
                                {t('scheduledMeeting.addMeeting')}
                            </Button>
                        )}
                    </>
                }
            />
        )
    }
)

MeetingDayHeader.displayName = 'MeetingDayHeader'
