import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const WarningIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'WarningIconLight',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M10.0195 13.0625C9.47266 13.0625 9.08203 13.4922 9.08203 14C9.08203 14.5469 9.51172 14.9375 10.0195 14.9375C10.5273 14.9375 10.918 14.5469 10.918 14C10.957 13.4922 10.5273 13.0625 10.0195 13.0625ZM10.0195 11.5C10.332 11.5 10.6055 11.2266 10.6055 10.875V5.25C10.6055 4.9375 10.293 4.625 10.0195 4.625C9.70703 4.625 9.39453 4.9375 9.39453 5.25V10.875C9.39453 11.2266 9.66797 11.5 10.0195 11.5ZM19.707 14.5469L11.8945 1.34375C11.5039 0.679688 10.8008 0.289062 10.0195 0.25C9.19922 0.25 8.49609 0.679688 8.10547 1.34375L0.292969 14.5469C-0.0976562 15.2109 -0.0976562 15.9922 0.292969 16.6562C0.683594 17.3594 1.38672 17.75 2.20703 17.75H17.832C18.6133 17.75 19.3164 17.3594 19.707 16.6562C20.0977 15.9922 20.0977 15.2109 19.707 14.5469ZM18.6133 16.0312C18.457 16.3438 18.1445 16.5 17.793 16.5H2.20703C1.85547 16.5 1.54297 16.3438 1.38672 16.0312C1.19141 15.7578 1.23047 15.4453 1.38672 15.1719L9.19922 1.96875C9.35547 1.69531 9.66797 1.5 10.0195 1.5C9.98047 1.5 10.0195 1.5 10.0195 1.5C10.332 1.53906 10.6445 1.69531 10.8008 1.96875L18.6133 15.1719C18.7695 15.4453 18.8086 15.7578 18.6133 16.0312Z"
            fill="currentColor"
        />
    )
})

export const WarningIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'WarningIconRegular',
    viewBox: '0 0 22 18',
    path: (
        <path
            d="M20.7939 15.2891L12.4735 1.07031C12.122 0.523438 11.5751 0.25 11.0282 0.25C10.4423 0.25 9.89542 0.523438 9.54385 1.07031L1.18448 15.2891C0.598541 16.3828 1.37979 17.75 2.66885 17.75H19.3485C20.6376 17.75 21.4189 16.3828 20.7939 15.2891ZM3.05948 15.875L10.9892 2.32031L18.9579 15.875H3.05948ZM11.0282 12.2031C10.3251 12.2031 9.77823 12.75 9.77823 13.4141C9.77823 14.0781 10.3251 14.625 11.0282 14.625C11.6923 14.625 12.2392 14.0781 12.2392 13.4141C12.2392 12.75 11.6923 12.2031 11.0282 12.2031ZM10.0907 6.1875V9.9375C10.0907 10.4844 10.4814 10.875 11.0282 10.875C11.536 10.875 11.9657 10.4844 11.9657 9.9375V6.1875C11.9657 5.67969 11.536 5.25 11.0282 5.25C10.4814 5.25 10.0907 5.67969 10.0907 6.1875Z"
            fill="currentColor"
        />
    )
})

export const WarningIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'WarningIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M20.7852 15.2891L12.4648 1.07031C11.8008 -0.0234375 10.1992 -0.0234375 9.57422 1.07031L1.21484 15.2891C0.589844 16.3828 1.37109 17.75 2.66016 17.75H19.3398C20.6289 17.75 21.4102 16.3828 20.7852 15.2891ZM10.082 5.5625C10.082 5.05469 10.4727 4.625 11.0195 4.625C11.5273 4.625 11.957 5.05469 11.957 5.5625V10.5625C11.957 11.1094 11.5273 11.5 11.0195 11.5C10.5508 11.5 10.082 11.1094 10.082 10.5625V5.5625ZM11.0195 15.25C10.3164 15.25 9.76953 14.7031 9.76953 14.0391C9.76953 13.375 10.3164 12.8281 11.0195 12.8281C11.6836 12.8281 12.2305 13.375 12.2305 14.0391C12.2305 14.7031 11.6836 15.25 11.0195 15.25Z"
            fill="currentColor"
        />
    )
})
