import { useDisclosure } from '@chakra-ui/react'
import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ExistingNumberForm } from './ExistingNumberForm'

export const ExistingNumberDrawer: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    function handleTransition() {
        return navigate('/admin/numbers')
    }

    return (
        <Drawer size="lg" isOpen={isOpen} onClose={onClose} onCloseComplete={handleTransition}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader pb="0 !important">{t('admin.numbers.existing.header')}</DrawerHeader>
                <DrawerBody>
                    <ExistingNumberForm />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
