import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { bannerAnatomy } from 'theme/anatomy'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(bannerAnatomy.keys)

export const BannerStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => ({
        container: {
            w: 'full',
            px: '24px',
            py: '16px'
        },
        label: {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '28.16px'
        },
        icon: {
            boxSize: '32px',
            color: `${colorMode}.alerts.red`
        }
    }),
    variants: {
        darkRed: {
            container: {
                background: '#B62A16'
            },
            label: {
                color: '#fff'
            }
        },
        error: ({ colorMode }) => ({
            container: {
                bg: `${colorMode}.tones.vantaBlue`
            },
            label: {
                color: `${colorMode}.tones.periwinkle`
            }
        })
    }
})
