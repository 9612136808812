import type { JoinInfo, MeetingSettings } from '@missionlabs/api'
import {
    BackgroundBlurProvider,
    BackgroundReplacementProvider,
    ContentShareProvider,
    GlobalStyles,
    MeetingProvider as ChimeMeetingProvider,
    useMeetingManager,
    VoiceFocusProvider
} from 'amazon-chime-sdk-component-library-react'
import {
    ConsoleLogger,
    DefaultEventController,
    LogLevel,
    MeetingSessionConfiguration,
    NoOpEventReporter,
    VoiceFocusModelName
} from 'amazon-chime-sdk-js'
import {
    createContext,
    FC,
    PropsWithChildren,
    useContext as useReactContext,
    useEffect,
    useState
} from 'react'
import { ThemeProvider } from 'styled-components'

import { DataMessagesProvider } from './components/DataMessagesProvider'
import { usePrioritisedAttendees } from './hooks/usePrioritisedAttendees'
import { demoLightTheme } from './theme/demoTheme'

export enum BackgroundFilterType {
    NONE = 'none',
    BLUR = 'blur',
    IMAGE = 'image'
}

export type MeetingContextType = {
    preview: (options?: JoinOptions) => Promise<void>
    join: (joinInfo: JoinInfo, options?: JoinOptions) => Promise<void>
    videoBackgroundFilterType: BackgroundFilterType
    videoBackgroundFilter: string
    reorderAttendees: (
        attendeeIds: string[],
        maxInView: number,
        setAttendeeOrder: (attendeesInOrder: string[]) => void
    ) => void
}

export type JoinOptions = {
    logLevel?: LogLevel
    meetingSettings?: MeetingSettings
}

export const MeetingContext = createContext<MeetingContextType>({} as MeetingContextType)
export const useMeetingContext = () => useReactContext(MeetingContext)

function voiceFocusName(name: string): VoiceFocusModelName {
    if (name && ['default', 'ns_es'].includes(name)) {
        return name as VoiceFocusModelName
    }
    return 'default'
}

function getVoiceFocusSpecName(joinInfo: JoinInfo): VoiceFocusModelName {
    if (joinInfo && joinInfo.Meeting?.MeetingFeatures?.Audio?.EchoReduction === 'AVAILABLE') {
        return voiceFocusName('ns_es')
    }
    return voiceFocusName('default')
}

function appendEchoReductionConfig(joinInfo: JoinInfo): JoinInfo {
    return {
        ...joinInfo,
        Meeting: {
            ...joinInfo.Meeting,
            MeetingFeatures: {
                ...joinInfo.Meeting.MeetingFeatures,
                Audio: {
                    ...joinInfo.Meeting?.MeetingFeatures?.Audio,
                    EchoReduction:
                        joinInfo.Meeting?.MeetingFeatures?.Audio?.EchoReduction || 'AVAILABLE'
                }
            }
        }
    }
}

const getDummyJoinInfo = (): JoinInfo => ({
    Attendee: {
        AttendeeId: '',
        ExternalUserId: '',
        JoinToken: ''
    },
    Errors: [],
    Meeting: {
        ExternalMeetingId: '',
        MediaPlacement: {
            AudioFallbackUrl: '',
            AudioHostUrl: '',
            EventIngestionUrl: '',
            ScreenDataUrl: '',
            ScreenSharingUrl: '',
            ScreenViewingUrl: '',
            SignalingUrl: '',
            TurnControlUrl: ''
        },
        MediaRegion: '',
        MeetingId: ''
    }
})

const MeetingProvider: FC<
    PropsWithChildren & {
        attendeeIdToName: Record<string, string>
        onVideoCallPullComplete?: (meetingID?: string) => void
        videoBackgroundFilter: string
    }
> = ({ children, attendeeIdToName, onVideoCallPullComplete, videoBackgroundFilter }) => {
    const meetingManager = useMeetingManager()
    const { reorderAttendeesFn } = usePrioritisedAttendees()

    useEffect(() => {
        meetingManager.subscribeToEventDidReceive((_name, attributes) => {
            if (
                attributes.meetingStatus &&
                attributes.meetingStatus === 'AudioJoinedFromAnotherDevice' &&
                onVideoCallPullComplete
            ) {
                onVideoCallPullComplete(attributes.externalMeetingId)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [voiceFocusConfig, setVoiceFocusConfig] = useState<any>()

    useEffect(() => {
        meetingManager.getAttendee = async (chimeAttendeeId, externalUserId) => {
            return await Promise.resolve({
                chimeAttendeeId,
                externalUserId,
                name: externalUserId ? attendeeIdToName[externalUserId] : 'Unknown'
            })
        }
    }, [attendeeIdToName, meetingManager])

    const contextValue: MeetingContextType = {
        preview: async ({ logLevel }: JoinOptions = {}): Promise<void> => {
            const joinInfo = getDummyJoinInfo()

            const config = new MeetingSessionConfiguration(joinInfo.Meeting, joinInfo.Attendee)

            const eventController = new DefaultEventController(
                config,
                new ConsoleLogger('SDK', logLevel ?? LogLevel.OFF),
                new NoOpEventReporter()
            )

            try {
                await meetingManager.join(config, {
                    enableWebAudio: true,
                    eventController
                })
            } catch (e) {
                console.error('Error previewing meeting:', e)
                return
            }
        },
        join: async (
            joinInfo: JoinInfo,
            { logLevel, meetingSettings }: JoinOptions = {}
        ): Promise<void> => {
            joinInfo = appendEchoReductionConfig(joinInfo)

            setVoiceFocusConfig({
                spec: { name: getVoiceFocusSpecName(joinInfo) },
                createMeetingResponse: joinInfo
            })

            const config = new MeetingSessionConfiguration(joinInfo.Meeting, joinInfo.Attendee)

            const eventController = new DefaultEventController(
                config,
                new ConsoleLogger('SDK', logLevel ?? LogLevel.OFF),
                new NoOpEventReporter()
            )

            try {
                await meetingManager.join(config, {
                    enableWebAudio: true,
                    eventController
                })
                await meetingManager.start()
                if (meetingSettings?.microphoneInitialState === 'MUTED')
                    meetingManager.audioVideo?.realtimeMuteLocalAudio()
            } catch (e) {
                console.error('Error starting meeting:', e)
                return
            }
        },
        videoBackgroundFilterType: BackgroundFilterType[videoBackgroundFilter.split('_')[0]],
        videoBackgroundFilter,
        reorderAttendees: reorderAttendeesFn
    }

    return (
        <MeetingContext.Provider value={contextValue}>
            <DataMessagesProvider>
                {/* @ts-ignore */}
                <ContentShareProvider>
                    {/* @ts-ignore */}
                    <BackgroundReplacementProvider>
                        {/* @ts-ignore */}
                        <BackgroundBlurProvider
                            options={{
                                blurStrength: Number(
                                    contextValue.videoBackgroundFilter.split('_')[1] ?? 0
                                )
                            }}
                        >
                            <VoiceFocusProvider {...voiceFocusConfig}>
                                {children}
                            </VoiceFocusProvider>
                        </BackgroundBlurProvider>
                    </BackgroundReplacementProvider>
                </ContentShareProvider>
            </DataMessagesProvider>
        </MeetingContext.Provider>
    )
}

const ChimeProvider = ({
    children,
    appTheme,
    attendeeIdToName,
    onVideoCallPullComplete,
    videoBackgroundFilter = ''
}) => {
    return (
        <ThemeProvider theme={{ ...demoLightTheme, appTheme }}>
            <GlobalStyles />
            {/* @ts-ignore */}
            <ChimeMeetingProvider>
                <MeetingProvider
                    attendeeIdToName={attendeeIdToName}
                    onVideoCallPullComplete={onVideoCallPullComplete}
                    videoBackgroundFilter={videoBackgroundFilter}
                >
                    {children}
                </MeetingProvider>
            </ChimeMeetingProvider>
        </ThemeProvider>
    )
}

export { useMeetingManager }
export default ChimeProvider
