import {
    PreferencesState,
    updateDevices,
    updatePreferences,
    updateWrtcDevices,
    useDispatch
} from '@missionlabs/api'
import { MediaDevice } from '@missionlabs/types'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { resetPreferences } from 'utils/devices'

// We want to call getUserMedia once on app load to get the devices because is a very expensive operation
export const useGetUserMedia = (audio = true, video = true) => {
    const { t } = useTranslation()
    const register = useRef<boolean>(false)
    const dispatch = useDispatch()

    // Need to map devices to objects because Redux will not accept MediaDeviceInfo
    const mapDevicesToObj = (devices: MediaDeviceInfo[]): MediaDevice[] => {
        return devices.map(device => ({
            deviceId: device.deviceId,
            groupId: device.groupId,
            kind: device.kind,
            label: device.label || t('unknownDevice')
        }))
    }

    const resetUserMediaReference = () => {
        register.current = false
    }

    const getUserMedia = useCallback((userPreferences: PreferencesState) => {
        if (register.current === true) return

        const enumPredicate = (devices: MediaDeviceInfo[], shouldUpdateWRTC = false) => {
            // Windows adds duplicate devices with the deviceId = 'communications'. Removing them here seems to fix changing audio mid-call
            const mappedDevices = mapDevicesToObj(
                devices.filter(({ deviceId }) => deviceId !== 'communications')
            )

            dispatch(updateDevices(mappedDevices))
            if (shouldUpdateWRTC) dispatch(updateWrtcDevices(mappedDevices))

            // Clean up any references to unavailable devices.
            const preferences = resetPreferences(devices, userPreferences)
            dispatch(updatePreferences(preferences))

            if (devices.length) register.current = true
        }

        const gumPredicate = (stream: MediaStream) => {
            stream.getTracks().forEach(track => {
                track.stop()
                track.enabled = false
            })
            navigator.mediaDevices.enumerateDevices().then(enumPredicate)
        }

        navigator.mediaDevices
            .getUserMedia({ audio })
            .then(gumPredicate)
            .catch(() => (register.current = true))
        navigator.mediaDevices
            .getUserMedia({ video })
            .then(gumPredicate)
            .catch(() => (register.current = true))

        navigator.mediaDevices.ondevicechange = () => {
            navigator.mediaDevices.enumerateDevices().then(devices => enumPredicate(devices, true))
        }

        return () => {
            navigator.mediaDevices.ondevicechange = () => {}
            dispatch(updateDevices([]))
            register.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { getUserMedia, resetUserMediaReference }
}
