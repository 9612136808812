import { Button, Spinner, Table } from '@missionlabs/react'
import { SubscriptionType, User } from '@missionlabs/types'
import { formatCurrency } from '@missionlabs/utils'
import { createColumnHelper } from '@tanstack/react-table'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetClientQuery, useGetUsersQuery } from 'shared/store'

import { SubscriptionModal } from './SubscriptionModal'

const columnHelper = createColumnHelper<Partial<User>>()

export const BillingUsersTable: FC = () => {
    const { t } = useTranslation()

    const { data: client, isLoading: clientLoading } = useGetClientQuery()
    const { data: users = [], isLoading: usersLoading } = useGetUsersQuery()

    const isLoading = clientLoading || usersLoading

    const [selected, setSelected] = useState<{ id: string; subscription: SubscriptionType }>()

    function handleClose() {
        setSelected(undefined)
    }

    const columns = [
        columnHelper.accessor('fullName', {
            cell: info => info.getValue(),
            header: t('Name')
        }),
        columnHelper.accessor('subscriptionTypeDisplayName', {
            cell: info => info.getValue(),
            header: t('Plan')
        }),
        columnHelper.accessor('subscriptionTypes.current.price', {
            cell: info => {
                const amount = info.getValue() / 100
                const currency = client?.currency
                return formatCurrency({ currency, amount })
            },
            header: t('Monthly')
        }),
        columnHelper.display({
            cell: ({ row }) => {
                const isStandard = row.original.subscriptionType === 'standard'
                const subscription = isStandard
                    ? row.original.subscriptionTypes?.upgrade
                    : row.original.subscriptionTypes?.downgrade

                if (!row.original.ID || !subscription) return null
                return (
                    <Button
                        variant="secondary"
                        size="sm"
                        fontWeight="500"
                        onClick={() => setSelected({ id: row.original.ID!, subscription })}
                    >
                        {isStandard
                            ? t('billing.switchToUnlimited')
                            : t('billing.switchToPayAsYouGo')}
                    </Button>
                )
            },
            id: 'upgrade-downgrade',
            meta: { align: 'end' }
            // size: 0
        })
    ]

    if (isLoading) return <Spinner size="sm" />

    return (
        <>
            <Table data={users} columns={columns} paginate />
            <SubscriptionModal selected={selected} isOpen={!!selected} onClose={handleClose} />
        </>
    )
}
