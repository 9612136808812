import { useEffect, useRef } from 'react'
import { useIntersection } from 'react-use'

type OnScreenObserverCallback = ((isIntersecting: boolean) => void) | undefined

// Setups an intersection observer for the entire window and triggers a callback when the intersection changes.
export const useOnScreenObserver = (callback?: OnScreenObserverCallback) => {
    const ref = useRef<HTMLDivElement>(null)
    const intersection = useIntersection(ref, {
        rootMargin: '0px 0px 0px 0px',
        threshold: 0
    })

    useEffect(() => {
        if (!intersection || !callback) return
        callback?.(intersection.isIntersecting)
    }, [callback, intersection])

    return { ref: callback ? ref : undefined }
}
