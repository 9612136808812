import { forwardRef } from 'react'
import { isSection } from 'shared/hooks/useSectionTitle'

import { VirtualisedChatHistoryItem } from './ChatHistory'
import { ChatHistoryRow } from './ChatHistoryRow'
import { ChatHistorySection } from './ChatHistorySection'

export interface ChatHistoryRendererProps {
    data: VirtualisedChatHistoryItem[]
    index: number
    isThread?: boolean
    searchSkipID?: string
}

export const ChatHistoryRenderer = forwardRef<HTMLDivElement, ChatHistoryRendererProps>(
    ({ data, index, isThread, searchSkipID }, ref) => {
        const item = data[index]
        if (isSection(item)) {
            if (isThread) return null
            return (
                <div ref={ref} data-index={index}>
                    <ChatHistorySection section={item} />
                </div>
            )
        }

        return (
            <div ref={ref} style={{ position: 'relative' }} data-index={index}>
                <ChatHistoryRow
                    item={item}
                    index={index}
                    isThread={isThread}
                    messagesLength={data.length}
                    searchSkipID={searchSkipID}
                />
            </div>
        )
    }
)
