import { Timezone } from '@missionlabs/types'
import { populateUrl } from '@missionlabs/utils'
import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query'

import { selectAPIBaseURL, selectAuthenticatedUser, selectAuthEnvironment } from '../index'
import { customCreateApi } from './customCreateAPI'
import { GetReportsTypeResponse } from './types/reports'

type KVPGroupsBaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>

interface Option {
    value: string
    label: string
}

export const kvpGroupsBaseQuery: KVPGroupsBaseQuery = async (args, api, extraOptions) => {
    const user = selectAuthenticatedUser(api.getState() as any)

    /** If no user is found in the Store we return every request with a 401. */
    if (!user) {
        return {
            error: {
                status: 401,
                statusText: 'Unauthorized Request',
                data: 'User is undefined'
            }
        }
    }

    const { token } = user

    if (!token) {
        return {
            error: {
                status: 401,
                statusText: 'Unauthorized Request',
                data: 'User Token is undefined'
            }
        }
    }

    const { environment, dataCentre, country } = selectAuthEnvironment(api.getState() as any)

    const API_BASE_URL = selectAPIBaseURL(api.getState() as any)

    const baseUrl = populateUrl(API_BASE_URL, { dataCentre, environment, country })

    const rawBaseQuery = fetchBaseQuery({
        baseUrl: `${baseUrl}/kvpgroups`,
        prepareHeaders: headers => {
            if (token) headers.set('X-TOKEN', token)
            return headers
        }
    })

    return rawBaseQuery(args, api, extraOptions)
}

export const buildKvpgroupsApi = () => {
    const api = customCreateApi({
        reducerPath: 'kvpgroupsApi',
        baseQuery: kvpGroupsBaseQuery,
        endpoints: builder => ({
            getTimezones: builder.query<Timezone[], void>({
                query: () => '/timezones',
                transformResponse: (response: { kvps: any[] }) => {
                    return response.kvps
                }
            }),
            getLocales: builder.query<Option[], void>({
                query: () => '/allCountries',
                transformResponse: (response: any) => {
                    const responseData = response.kvps.countries
                    const countries: Option[] = []
                    Object.keys(responseData).forEach(country => {
                        countries.push({ label: responseData[country].name, value: country })
                    })
                    return countries.sort((a, b) => a.label.localeCompare(b.label))
                }
            }),
            getReportTypes: builder.query<GetReportsTypeResponse, void>({
                query: () => ({
                    url: `/reportTypes`,
                    method: 'GET'
                })
            })
        })
    })

    return { api }
}
