import { Body } from '@missionlabs/react'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import isElectron from 'is-electron'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const AppVersion: FC = () => {
    const { t } = useTranslation()
    const [updateAvailable, setUpdateAvailable] = useState<boolean>(false)

    useEffect(() => {
        ;(async () => {
            if (!isElectron()) return

            setUpdateAvailable(await window.autoUpdater.checkForUpdates())
        })()
    }, [])
    return (
        <SettingsRow label={t('myDetails.appVersion')} divider={false}>
            <Body size="sm" variant="bold">
                v{__VERSION__} {!updateAvailable && '(latest)'}
            </Body>
        </SettingsRow>
    )
}
