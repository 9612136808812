import { createContext, FC, PropsWithChildren, useContext, useState } from 'react'

interface ITabControllerContext {
    tabIndex: number
    setTabIndex: (index: number) => void
}

interface TabControllerProviderProps {
    defaultTab?: number
}

export const TabControllerContext = createContext<ITabControllerContext>({
    tabIndex: 0,
    setTabIndex: () => {}
})

export const TabControllerProvider: FC<PropsWithChildren<TabControllerProviderProps>> = ({
    defaultTab,
    children
}) => {
    const [tabIndex, setTabIndex] = useState<number>(defaultTab ?? 0)

    return (
        <TabControllerContext.Provider value={{ tabIndex, setTabIndex }}>
            {children}
        </TabControllerContext.Provider>
    )
}

export const useTabController = () => {
    const ctx = useContext(TabControllerContext)
    if (!ctx) {
        throw Error(
            'No TabControllerContext exists. Component must be wrapped in <TabControllerProvider>'
        )
    }
    return ctx
}
