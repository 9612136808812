import { SharedContext } from '@missionlabs/api'
import logger, { metrics } from '@missionlabs/logger'
import { Component, createElement, ElementType, ErrorInfo, PropsWithChildren } from 'react'

type ErrorBoundaryProps = PropsWithChildren<{ fallback?: ElementType }>

interface ErrorBoundaryState {
    errorMessage: string
    hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    static contextType = SharedContext

    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { errorMessage: '', hasError: false }
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return {
            errorMessage: `[${error.name}] ${error.message || 'Unknown message'}`,
            hasError: true
        }
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        // @ts-ignore
        const state = this.context?.store.getState().userSlice
        const meta = {
            clientID: state.user?.clientID,
            destinationID: state.user?.destinationID,
            userID: state.user?.userID
        }
        logger.error(new metrics.error(error, info.componentStack, meta))
    }

    render() {
        if (this.state.hasError) {
            if (!this.props.fallback) return null
            return createElement(this.props.fallback, { error: this.state.errorMessage } as any)
        }
        return this.props.children
    }
}

export default ErrorBoundary
