import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const GridIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'GridIconLight',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M0.25 2.125C0.25 1.10938 1.07031 0.25 2.125 0.25H5.875C6.89062 0.25 7.75 1.10938 7.75 2.125V5.875C7.75 6.92969 6.89062 7.75 5.875 7.75H2.125C1.07031 7.75 0.25 6.92969 0.25 5.875V2.125ZM1.5 2.125V5.875C1.5 6.22656 1.77344 6.5 2.125 6.5H5.875C6.1875 6.5 6.5 6.22656 6.5 5.875V2.125C6.5 1.8125 6.1875 1.5 5.875 1.5H2.125C1.77344 1.5 1.5 1.8125 1.5 2.125ZM0.25 12.125C0.25 11.1094 1.07031 10.25 2.125 10.25H5.875C6.89062 10.25 7.75 11.1094 7.75 12.125V15.875C7.75 16.9297 6.89062 17.75 5.875 17.75H2.125C1.07031 17.75 0.25 16.9297 0.25 15.875V12.125ZM1.5 12.125V15.875C1.5 16.2266 1.77344 16.5 2.125 16.5H5.875C6.1875 16.5 6.5 16.2266 6.5 15.875V12.125C6.5 11.8125 6.1875 11.5 5.875 11.5H2.125C1.77344 11.5 1.5 11.8125 1.5 12.125ZM15.875 0.25C16.8906 0.25 17.75 1.10938 17.75 2.125V5.875C17.75 6.92969 16.8906 7.75 15.875 7.75H12.125C11.0703 7.75 10.25 6.92969 10.25 5.875V2.125C10.25 1.10938 11.0703 0.25 12.125 0.25H15.875ZM15.875 1.5H12.125C11.7734 1.5 11.5 1.8125 11.5 2.125V5.875C11.5 6.22656 11.7734 6.5 12.125 6.5H15.875C16.1875 6.5 16.5 6.22656 16.5 5.875V2.125C16.5 1.8125 16.1875 1.5 15.875 1.5ZM10.25 12.125C10.25 11.1094 11.0703 10.25 12.125 10.25H15.875C16.8906 10.25 17.75 11.1094 17.75 12.125V15.875C17.75 16.9297 16.8906 17.75 15.875 17.75H12.125C11.0703 17.75 10.25 16.9297 10.25 15.875V12.125ZM11.5 12.125V15.875C11.5 16.2266 11.7734 16.5 12.125 16.5H15.875C16.1875 16.5 16.5 16.2266 16.5 15.875V12.125C16.5 11.8125 16.1875 11.5 15.875 11.5H12.125C11.7734 11.5 11.5 11.8125 11.5 12.125Z"
            fill="currentColor"
        />
    )
})

export const GridIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'GridIconRegular',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M0.25 2.125C0.25 1.10938 1.07031 0.25 2.125 0.25H5.875C6.89062 0.25 7.75 1.10938 7.75 2.125V5.875C7.75 6.92969 6.89062 7.75 5.875 7.75H2.125C1.07031 7.75 0.25 6.92969 0.25 5.875V2.125ZM2.125 5.875H5.875V2.125H2.125V5.875ZM0.25 12.125C0.25 11.1094 1.07031 10.25 2.125 10.25H5.875C6.89062 10.25 7.75 11.1094 7.75 12.125V15.875C7.75 16.9297 6.89062 17.75 5.875 17.75H2.125C1.07031 17.75 0.25 16.9297 0.25 15.875V12.125ZM2.125 15.875H5.875V12.125H2.125V15.875ZM15.875 0.25C16.8906 0.25 17.75 1.10938 17.75 2.125V5.875C17.75 6.92969 16.8906 7.75 15.875 7.75H12.125C11.0703 7.75 10.25 6.92969 10.25 5.875V2.125C10.25 1.10938 11.0703 0.25 12.125 0.25H15.875ZM15.875 2.125H12.125V5.875H15.875V2.125ZM10.25 12.125C10.25 11.1094 11.0703 10.25 12.125 10.25H15.875C16.8906 10.25 17.75 11.1094 17.75 12.125V15.875C17.75 16.9297 16.8906 17.75 15.875 17.75H12.125C11.0703 17.75 10.25 16.9297 10.25 15.875V12.125ZM12.125 15.875H15.875V12.125H12.125V15.875Z"
            fill="currentColor"
        />
    )
})

export const GridIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'GridIconSolid',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M7.75 5.875C7.75 6.92969 6.89062 7.75 5.875 7.75H2.125C1.07031 7.75 0.25 6.92969 0.25 5.875V2.125C0.25 1.10938 1.07031 0.25 2.125 0.25H5.875C6.89062 0.25 7.75 1.10938 7.75 2.125V5.875ZM7.75 15.875C7.75 16.9297 6.89062 17.75 5.875 17.75H2.125C1.07031 17.75 0.25 16.9297 0.25 15.875V12.125C0.25 11.1094 1.07031 10.25 2.125 10.25H5.875C6.89062 10.25 7.75 11.1094 7.75 12.125V15.875ZM10.25 2.125C10.25 1.10938 11.0703 0.25 12.125 0.25H15.875C16.8906 0.25 17.75 1.10938 17.75 2.125V5.875C17.75 6.92969 16.8906 7.75 15.875 7.75H12.125C11.0703 7.75 10.25 6.92969 10.25 5.875V2.125ZM17.75 15.875C17.75 16.9297 16.8906 17.75 15.875 17.75H12.125C11.0703 17.75 10.25 16.9297 10.25 15.875V12.125C10.25 11.1094 11.0703 10.25 12.125 10.25H15.875C16.8906 10.25 17.75 11.1094 17.75 12.125V15.875Z"
            fill="currentColor"
        />
    )
})

export const Grid9IconLight = createIcon({
    displayName: 'Grid9IconLight',
    viewBox: '0 0 20 20',
    path: (
        <g>
            <path
                d="M5 4C5 4.55228 4.55228 5 4 5C3.44772 5 3 4.55228 3 4C3 3.44772 3.44772 3 4 3C4.55228 3 5 3.44772 5 4Z"
                fill="currentColor"
            />
            <path
                d="M5 10C5 10.5523 4.55228 11 4 11C3.44772 11 3 10.5523 3 10C3 9.44772 3.44772 9 4 9C4.55228 9 5 9.44772 5 10Z"
                fill="currentColor"
            />
            <path
                d="M4 17C4.55228 17 5 16.5523 5 16C5 15.4477 4.55228 15 4 15C3.44772 15 3 15.4477 3 16C3 16.5523 3.44772 17 4 17Z"
                fill="currentColor"
            />
            <path
                d="M10 5C10.5523 5 11 4.55228 11 4C11 3.44772 10.5523 3 10 3C9.44772 3 9 3.44772 9 4C9 4.55228 9.44772 5 10 5Z"
                fill="currentColor"
            />
            <path
                d="M10 11C10.5523 11 11 10.5523 11 10C11 9.44772 10.5523 9 10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11Z"
                fill="currentColor"
            />
            <path
                d="M11 16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16C9 15.4477 9.44772 15 10 15C10.5523 15 11 15.4477 11 16Z"
                fill="currentColor"
            />
            <path
                d="M16 5C16.5523 5 17 4.55228 17 4C17 3.44772 16.5523 3 16 3C15.4477 3 15 3.44772 15 4C15 4.55228 15.4477 5 16 5Z"
                fill="currentColor"
            />
            <path
                d="M16 11C16.5523 11 17 10.5523 17 10C17 9.44772 16.5523 9 16 9C15.4477 9 15 9.44772 15 10C15 10.5523 15.4477 11 16 11Z"
                fill="currentColor"
            />
            <path
                d="M17 16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16Z"
                fill="currentColor"
            />
        </g>
    )
})

export const Grid9IconRegular = createIcon({
    displayName: 'Grid9IconRegular',
    viewBox: '0 0 20 20',
    path: (
        <g>
            <path
                d="M4 5.5C4.82843 5.5 5.5 4.82843 5.5 4C5.5 3.17157 4.82843 2.5 4 2.5C3.17157 2.5 2.5 3.17157 2.5 4C2.5 4.82843 3.17157 5.5 4 5.5Z"
                fill="currentColor"
            />
            <path
                d="M4 11.5C4.82843 11.5 5.5 10.8284 5.5 10C5.5 9.17157 4.82843 8.5 4 8.5C3.17157 8.5 2.5 9.17157 2.5 10C2.5 10.8284 3.17157 11.5 4 11.5Z"
                fill="currentColor"
            />
            <path
                d="M4 17.5C4.82843 17.5 5.5 16.8284 5.5 16C5.5 15.1716 4.82843 14.5 4 14.5C3.17157 14.5 2.5 15.1716 2.5 16C2.5 16.8284 3.17157 17.5 4 17.5Z"
                fill="currentColor"
            />
            <path
                d="M10 5.5C10.8284 5.5 11.5 4.82843 11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4C8.5 4.82843 9.17157 5.5 10 5.5Z"
                fill="currentColor"
            />
            <path
                d="M10 11.5C10.8284 11.5 11.5 10.8284 11.5 10C11.5 9.17157 10.8284 8.5 10 8.5C9.17157 8.5 8.5 9.17157 8.5 10C8.5 10.8284 9.17157 11.5 10 11.5Z"
                fill="currentColor"
            />
            <path
                d="M10 17.5C10.8284 17.5 11.5 16.8284 11.5 16C11.5 15.1716 10.8284 14.5 10 14.5C9.17157 14.5 8.5 15.1716 8.5 16C8.5 16.8284 9.17157 17.5 10 17.5Z"
                fill="currentColor"
            />
            <path
                d="M17.5 4C17.5 4.82843 16.8284 5.5 16 5.5C15.1716 5.5 14.5 4.82843 14.5 4C14.5 3.17157 15.1716 2.5 16 2.5C16.8284 2.5 17.5 3.17157 17.5 4Z"
                fill="currentColor"
            />
            <path
                d="M17.5 10C17.5 10.8284 16.8284 11.5 16 11.5C15.1716 11.5 14.5 10.8284 14.5 10C14.5 9.17157 15.1716 8.5 16 8.5C16.8284 8.5 17.5 9.17157 17.5 10Z"
                fill="currentColor"
            />
            <path
                d="M17.5 16C17.5 16.8284 16.8284 17.5 16 17.5C15.1716 17.5 14.5 16.8284 14.5 16C14.5 15.1716 15.1716 14.5 16 14.5C16.8284 14.5 17.5 15.1716 17.5 16Z"
                fill="currentColor"
            />
        </g>
    )
})

export const Grid9IconSolid = createIcon({
    displayName: 'Grid9IconSolid',
    viewBox: '0 0 20 20',
    path: (
        <g>
            <path
                d="M6 4C6 5.10457 5.10457 6 4 6C2.89543 6 2 5.10457 2 4C2 2.89543 2.89543 2 4 2C5.10457 2 6 2.89543 6 4Z"
                fill="currentColor"
            />
            <path
                d="M6 10C6 11.1046 5.10457 12 4 12C2.89543 12 2 11.1046 2 10C2 8.89543 2.89543 8 4 8C5.10457 8 6 8.89543 6 10Z"
                fill="currentColor"
            />
            <path
                d="M6 16C6 17.1046 5.10457 18 4 18C2.89543 18 2 17.1046 2 16C2 14.8954 2.89543 14 4 14C5.10457 14 6 14.8954 6 16Z"
                fill="currentColor"
            />
            <path
                d="M10 6C11.1046 6 12 5.10457 12 4C12 2.89543 11.1046 2 10 2C8.89543 2 8 2.89543 8 4C8 5.10457 8.89543 6 10 6Z"
                fill="currentColor"
            />
            <path
                d="M10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12Z"
                fill="currentColor"
            />
            <path
                d="M10 18C11.1046 18 12 17.1046 12 16C12 14.8954 11.1046 14 10 14C8.89543 14 8 14.8954 8 16C8 17.1046 8.89543 18 10 18Z"
                fill="currentColor"
            />
            <path
                d="M16 6C17.1046 6 18 5.10457 18 4C18 2.89543 17.1046 2 16 2C14.8954 2 14 2.89543 14 4C14 5.10457 14.8954 6 16 6Z"
                fill="currentColor"
            />
            <path
                d="M16 12C17.1046 12 18 11.1046 18 10C18 8.89543 17.1046 8 16 8C14.8954 8 14 8.89543 14 10C14 11.1046 14.8954 12 16 12Z"
                fill="currentColor"
            />
            <path
                d="M16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18Z"
                fill="currentColor"
            />
        </g>
    )
})
