import { ScreenShareControlProps } from '@missionlabs/meetings'
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    Dropdown,
    SelectOption
} from '@missionlabs/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

// ScreenShareControlProps['electronWindowPicker'] is a strongly typed FC with appropriate props
export const ElectronScreenShareDialog: ScreenShareControlProps['electronWindowPicker'] = ({
    isOpen,
    onClose,
    onSelect
}) => {
    const { t } = useTranslation()

    const [selected, setSelected] = useState<string>('')
    const [sources, setSources] = useState<SelectOption[]>([])

    const ref = useRef<HTMLButtonElement>(null)

    const fetchSources = useCallback(async () => {
        const sources = await window.desktopCapturer.getSources({
            types: ['window', 'screen'],
            // as we aren't rendering thumbnails in this UI, set to 0 to prevent electron processing thumbnails unnecessarily
            thumbnailSize: { height: 0, width: 0 }
        })
        return sources.map<SelectOption>(item => ({ label: item.name, value: item.id }))
    }, [])

    useEffect(() => {
        fetchSources().then(setSources)
    }, [fetchSources])

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogHeader>{t('Share screen')}</DialogHeader>
                <DialogBody gap="10px">
                    {t('meeting.share.body')}
                    <Dropdown
                        options={sources}
                        value={selected}
                        isDisabled={!sources}
                        onChange={setSelected}
                    />
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel'), onClick: onClose }}
                    confirm={{ label: t('Confirm'), onClick: () => onSelect(selected) }}
                    isLoading={!sources}
                />
            </DialogContent>
        </Dialog>
    )
}
