import { selectAuthenticatedUser, selectInData, useSelector } from '@missionlabs/api'
import { Button } from '@missionlabs/react'
import { AddCircleIcon } from '@missionlabs/react/circleloop'
import { AudioClip } from '@missionlabs/types'
import { SettingsTitle } from 'features/settings/components/SettingsTitle'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetUserAudioClipsQuery } from 'shared/store'

import { RecordingsTable } from './RecordingsTable'

const selectPersonalRecordings = selectInData<AudioClip[]>(data => {
    return data?.filter(item => !item.global)
})

const selectSharedRecordings = selectInData<AudioClip[]>(data => {
    return data?.filter(item => item.global)
})

export interface RecordingsProps {}

export const Recordings: FC<RecordingsProps> = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const user = useSelector(selectAuthenticatedUser)

    const {
        personal = [],
        shared = [],
        isLoading
    } = useGetUserAudioClipsQuery(user?.userID ?? '', {
        skip: !user,
        selectFromResult: result => ({
            ...result,
            personal: selectPersonalRecordings(result),
            shared: selectSharedRecordings(result)
        })
    })

    return (
        <SettingsPage
            title={t('recordings.title')}
            actions={
                <Button
                    variant="creationary"
                    leftIcon={<AddCircleIcon boxSize="16px" />}
                    aria-label="Add recording"
                    onClick={() => navigate('/settings/recordings/add')}
                >
                    {t('recordings.add')}
                </Button>
            }
            isEmpty={!personal.length && !shared.length}
        >
            <SettingsTitle label={t('recordings.myRecordings')} divider={false} />
            <RecordingsTable data={personal} isLoading={isLoading} />
            <SettingsTitle label={t('recordings.sharedRecordings')} divider={false} />
            <RecordingsTable data={shared} isLoading={isLoading} />
        </SettingsPage>
    )
}
