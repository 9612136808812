import { Center, useColorMode, VStack } from '@chakra-ui/react'
import { leaveActiveMeeting, useDispatch } from '@missionlabs/api'
import { Body, Button, Heading } from '@missionlabs/react'
import { Logo } from '@missionlabs/react/circleloop'
import { useMeetingTitle } from 'features/meetings/hooks/useMeetingTitle'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export interface WaitingRoomProps {
    meetingID: string
}

export const WaitingRoom: FC<WaitingRoomProps> = ({ meetingID }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const title = useMeetingTitle()
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    function handleLeaveCall() {
        dispatch(leaveActiveMeeting({ meetingID }))
        navigate('/')
    }

    return (
        <Center w="100dvw" h="100dvh" bg={`${colorMode}.tones.midnightExpress`}>
            <VStack spacing="16px" p="24px">
                <VStack spacing="24px">
                    <Logo boxSize="48px" color={`${colorMode}.alerts.red`} />
                    <VStack spacing={0}>
                        <Heading size="h3" variant="bold" color="white" fontSize="16px">
                            {t('meeting.title', { title })}
                        </Heading>
                        <Body size="sm" sx={{ color: `${colorMode}.tones.periwinkle` }}>
                            {t('meeting.waiting')}
                        </Body>
                    </VStack>
                </VStack>
                <Button variant="negatory" onClick={handleLeaveCall}>
                    {t('Leave call')}
                </Button>
            </VStack>
        </Center>
    )
}
