import { useDisclosure } from '@chakra-ui/react'
import { removeMeetingParticipants, useDispatch } from '@missionlabs/api'
import { useParticipantsData } from '@missionlabs/meetings'
import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay
} from '@missionlabs/react'
import { FC, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const MeetingsCallRemoveParticipantDialog: FC = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id = '', attendeeID = '' } = useParams()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const { joinedParticipants } = useParticipantsData()

    const ref = useRef<HTMLButtonElement>(null)

    const participant = joinedParticipants.find(p => p.chimeAttendeeId === attendeeID)

    function handleRemove() {
        if (!id || !participant) return
        const payload = {
            meetingID: id,
            otherParticipants: [{ userID: participant.externalUserId }]
        }
        // @ts-ignore
        dispatch(removeMeetingParticipants(payload))
        onClose()
    }

    function handleTransition() {
        return navigate(-1)
    }

    return (
        <Dialog
            leastDestructiveRef={ref}
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={handleTransition}
        >
            <DialogOverlay />
            <DialogContent>
                <DialogHeader>Remove user from meeting</DialogHeader>
                <DialogBody>
                    {`Are you sure want to remove “${participant?.name}” from the meeting?`}
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: 'Cancel', onClick: onClose }}
                    confirm={{ label: 'Remove', isDestructive: true, onClick: handleRemove }}
                />
            </DialogContent>
        </Dialog>
    )
}
