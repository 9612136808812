import { ChartIcon } from '@missionlabs/react/circleloop'
import { Action, useRegisterActions } from 'kbar'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { commandMenuShortcuts } from './shortcuts'

export function useAnalyticsActions() {
    const navigate = useNavigate()

    const actions: Action[] = useMemo(
        () => [
            {
                id: 'analytics',
                name: 'Analytics and reports...',
                shortcut: commandMenuShortcuts.analyticsAndReports,
                section: 'Analytics',
                icon: <ChartIcon aria-label="Analytics" />
            },
            {
                id: 'analytics-summary',
                name: 'Summary',
                parent: 'analytics',
                perform: () => navigate('/analytics/summary'),
                priority: -1
            },
            {
                id: 'analytics-users',
                name: 'Users',
                parent: 'analytics',
                perform: () => navigate('/analytics/users'),
                priority: -1
            },
            {
                id: 'analytics-numbers',
                name: 'Numbers',
                parent: 'analytics',
                perform: () => navigate('/analytics/numbers'),
                priority: -1
            },
            {
                id: 'analytics-teams',
                name: 'Teams & menus',
                parent: 'analytics',
                perform: () => navigate('/analytics/teams'),
                priority: -1
            }
        ],
        [navigate]
    )

    useRegisterActions(actions)
}
