import { useSearchChannelMessagesQuery } from 'shared/store'

import { useChatContext } from '../context/useChatContext'

export const useSearchMessages = (channelID: string) => {
    const { searchTerm, searchOpen } = useChatContext().search

    const { data: messages } = useSearchChannelMessagesQuery(
        { channelID, search: searchTerm },
        { skip: !(searchOpen && searchTerm) }
    )

    return messages
}
