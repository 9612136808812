import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const IncomingCallIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'IncomingCallIconLight',
    viewBox: '0 0 20 22',
    path: (
        <path
            d="M18.916 13.9336L14.9316 12.2148C14.2285 11.9414 13.3691 12.1367 12.9004 12.7617L11.5723 14.3242C9.54102 13.1914 7.82227 11.4727 6.68945 9.44141L8.29102 8.11328C8.87695 7.60547 9.07227 6.7461 8.75977 6.04297L7.08008 2.0586C6.72852 1.27735 5.86914 0.847658 5.04883 1.04297L1.37695 1.90235C0.556641 2.09766 0.00976562 2.80078 0.00976562 3.66016C0.00976562 13.2305 7.7832 21.0039 17.3535 21.0039C18.2129 21.0039 18.916 20.457 19.1113 19.6367L19.9316 15.9648C20.127 15.1445 19.6973 14.2852 18.916 13.9336ZM17.8613 19.3633C17.8223 19.5977 17.627 19.7539 17.3535 19.7539C8.48633 19.7539 1.25977 12.5273 1.25977 3.66016C1.25977 3.38672 1.41602 3.19141 1.65039 3.15235L5.32227 2.29297C5.36133 2.29297 5.40039 2.25391 5.43945 2.25391C5.63477 2.25391 5.83008 2.41016 5.9082 2.60547L7.62695 6.55078C7.70508 6.78516 7.66602 7.01953 7.4707 7.17578L5.51758 8.77735C5.2832 8.97266 5.20508 9.28516 5.36133 9.5586C6.65039 12.1758 8.83789 14.3633 11.4551 15.6914C11.7285 15.8086 12.041 15.7305 12.2363 15.4961L13.8379 13.543C13.9941 13.3477 14.2285 13.3086 14.4629 13.3867L18.4082 15.1055C18.6426 15.1836 18.7988 15.418 18.7207 15.6914L17.8613 19.3633ZM11.8848 9.75391H16.8848C17.1973 9.75391 17.5098 9.48047 17.5098 9.12891C17.5098 8.81641 17.1973 8.50391 16.8848 8.50391H13.3691L19.8145 2.09766C20.0488 1.86328 20.0488 1.4336 19.8145 1.19922C19.6973 1.08203 19.541 1.00391 19.3848 1.00391C19.1895 1.00391 19.0332 1.08203 18.916 1.19922L12.5098 7.64453V4.12891C12.5098 3.81641 12.1973 3.50391 11.8848 3.50391C11.5332 3.50391 11.2598 3.81641 11.2598 4.12891V9.12891C11.2598 9.48047 11.5332 9.75391 11.8848 9.75391Z"
            fill="currentColor"
        />
    )
})

export const IncomingCallIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'IncomingCallIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M18.7598 12.5293L14.9316 10.8887C14.0723 10.498 13.0957 10.7715 12.5098 11.4746L11.4551 12.7637C9.69727 11.748 8.25195 10.3027 7.23633 8.54492L8.52539 7.49023C9.22852 6.9043 9.50195 5.92773 9.11133 5.10742L7.4707 1.24023C7.08008 0.341797 6.10352 -0.166016 5.12695 0.0683594L1.57227 0.888672C0.634766 1.08398 0.00976562 1.9043 0.00976562 2.88086C0.00976562 12.334 7.66602 19.9902 17.1191 19.9902C18.0957 19.9902 18.916 19.3652 19.1113 18.4277L19.9316 14.873C20.166 13.8965 19.6582 12.9199 18.7598 12.5293ZM18.0957 14.4434L17.2754 17.998C17.2754 18.0762 17.1973 18.1152 17.1191 18.1152C8.7207 18.1152 1.8457 11.2793 1.8457 2.88086C1.8457 2.80273 1.88477 2.72461 1.96289 2.72461L5.55664 1.9043C5.5957 1.9043 5.5957 1.9043 5.5957 1.9043C5.67383 1.9043 5.75195 1.94336 5.75195 1.98242L7.39258 5.81055C7.43164 5.88867 7.43164 5.9668 7.35352 6.00586L5.43945 7.56836C5.12695 7.8418 5.00977 8.34961 5.20508 8.74023C6.49414 11.3574 8.64258 13.5059 11.2598 14.7949C11.6504 14.9902 12.1191 14.873 12.4316 14.5605L13.9941 12.6465C14.0332 12.5684 14.1113 12.5684 14.1504 12.6074L18.0176 14.248C18.0957 14.2871 18.1348 14.3652 18.0957 14.4434ZM12.1973 8.74023H16.5723C17.0801 8.74023 17.5098 8.34961 17.5098 7.80273C17.5098 7.29492 17.0801 6.86523 16.5723 6.86523H14.4238L19.6973 1.5918C20.0879 1.24023 20.0879 0.654297 19.6973 0.302734C19.3457 -0.0878906 18.7598 -0.0878906 18.4082 0.302734L13.1348 5.57617V3.42773C13.1348 2.91992 12.7051 2.49023 12.1973 2.49023C11.6504 2.49023 11.2598 2.91992 11.2598 3.42773V7.80273C11.2598 8.34961 11.6504 8.74023 12.1973 8.74023Z"
            fill="currentColor"
        />
    )
})

export const IncomingCallIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'IncomingCallIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M19.2627 13.7451L15.0049 11.9092C14.4971 11.7139 13.9111 11.8311 13.5986 12.2607L11.8799 14.3701C9.14551 13.042 6.95801 10.8545 5.62988 8.12012L7.73926 6.40137C8.16895 6.08887 8.28613 5.50293 8.09082 4.99512L6.25488 0.737305C6.02051 0.229492 5.43457 -0.0830078 4.84863 0.0341797L0.942383 0.97168C0.356445 1.08887 0.00488281 1.55762 0.00488281 2.14355C0.00488281 11.9873 8.0127 19.9951 17.8564 19.9951C18.4424 19.9951 18.9111 19.6436 19.0283 19.0576L19.9658 15.1123C20.083 14.5654 19.8096 13.9795 19.2627 13.7451ZM12.5049 8.74512H16.2549C16.9189 8.74512 17.5049 8.19824 17.5049 7.49512C17.5049 6.83105 16.9189 6.24512 16.2549 6.24512H15.5127L19.6143 2.14355C20.1221 1.6748 20.1221 0.854492 19.6143 0.385742C19.1455 -0.12207 18.3252 -0.12207 17.8564 0.385742L13.7549 4.4873V3.74512C13.7549 3.08105 13.1689 2.49512 12.5049 2.49512C11.8018 2.49512 11.2549 3.08105 11.2549 3.74512V7.49512C11.2549 8.19824 11.8018 8.74512 12.5049 8.74512Z"
            fill="currentColor"
        />
    )
})
