// partially typed api error based on what rtk query responds with
export interface APIError {
    meta: {
        arg: Record<string, any>
        baseQueryMeta: { request: Request; response: Response }
    }
    payload: {
        data: { error: string; message: string; statusCode: number }
        status: number
    }
}

export const isAPIError = (x: any): x is APIError =>
    typeof x === 'object' &&
    typeof x.meta.baseQueryMeta === 'object' &&
    typeof x.payload.data !== 'undefined'
