import { Spacer, useColorMode, VStack } from '@chakra-ui/react'
import { useSelector } from '@missionlabs/api'
import { Tooltip } from '@missionlabs/react'
import { MessageButton } from 'features/voice/NavButtons/MessageButton'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { selectMessages } from 'shared/slices/messagingSlice'

import { DrawerMode } from '../ContactDrawer'
import { useContactDrawerState } from '../hooks/useContactDrawerState'
import { CallButton } from '../NavButtons/CallButton'
import { DiallerButton } from './DiallerButton'

interface NavButtonsProps {
    isCallActive: boolean
}

export const NavButtons: FC<NavButtonsProps> = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { mode, drawer, calls } = useContactDrawerState()
    const { isOpen, onOpen } = drawer

    const messages = useSelector(selectMessages)

    const isDiallerOpen = useMemo(() => isOpen && mode === DrawerMode.DIALLER, [isOpen, mode])

    return (
        <VStack h="full" bg={`${colorMode}.tones.navy`} zIndex={1}>
            <Tooltip
                label={
                    // Only show the tooltip if the dialler is closed
                    !isDiallerOpen ? t('Dialler') : undefined
                }
                shouldWrapChildren={true}
                placement="start"
            >
                <DiallerButton isOpen={isDiallerOpen} onClick={onOpen} />
            </Tooltip>
            {calls.map(({ callTraceID, status }) => (
                <CallButton
                    key={callTraceID}
                    callTraceID={callTraceID}
                    status={status}
                    onClick={onOpen}
                />
            ))}
            {messages.map(message => (
                <MessageButton key={message.ID} message={message} onOpen={onOpen} />
            ))}
            <Spacer />
        </VStack>
    )
}
