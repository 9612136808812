import { Grid, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Themes, useTheme } from '@missionlabs/react'
import { FullLogo as CircleLoopLogo } from '@missionlabs/react/circleloop'
import { FullLogo as PhoneLinePlusLogo } from '@missionlabs/react/phonelineplus'
import { FC, PropsWithChildren } from 'react'

export interface AuthLayoutProps {
    label: string
    contentWidth?: string
    image?: string
}

export const AuthLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({
    label,
    children,
    contentWidth,
    image
}) => {
    const { colorMode } = useColorMode()

    const theme = useTheme().variant

    const FullLogo = theme === Themes.PHONELINEPLUS ? PhoneLinePlusLogo : CircleLoopLogo

    let background = `var(--chakra-colors-${colorMode}-tones-ghostWhite)`
    if (image) {
        background = `right 50px bottom 70px / 350px no-repeat url(${image}), ` + background
    }

    return (
        <Grid as="main" h="100dvh" w="100dvw" bg={background} templateColumns="372px 1fr">
            <VStack spacing="24px" padding="64px" align="end" bg={`${colorMode}.alerts.red`}>
                <FullLogo h="auto" w="212px" color="white" />
                <Body sx={{ color: `${colorMode}.alerts.redLight` }}>v{__VERSION__}</Body>
            </VStack>
            <VStack spacing="16px" maxW={contentWidth || '312px'} margin="64px" align="center">
                <Body size="lg" alignSelf="start">
                    {label}
                </Body>
                <VStack spacing={6} w="100%">
                    {children}
                </VStack>
            </VStack>
        </Grid>
    )
}
