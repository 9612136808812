import { Divider, VStack } from '@chakra-ui/react'
import { Input, useFormContext, ValidatedInput } from '@missionlabs/react'
import { useFlags } from 'flagsmith/react'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAllClientAudioClips } from 'shared/hooks/useAllAudioClips'
import { Features, useHasClientFeature } from 'shared/hooks/useHasClientFeature'
import { APP_BRAND, AppConfig } from 'shared/utils/env'

import { AdminAnonymousCallSetting } from '../../../../../shared/components/settings/AdminAnonymousCallSetting'
import { AssignedNumbers } from '../../../components/AssignedNumbers'
import { AddEditTeamFields } from './AddEditTeamForm'
import { MusicToggle } from './MusicToggle'
import { TeamLineBusy } from './TeamLineBusy'
import { TeamsOutOfHours } from './TeamOutOfHours'
import { TeamRoutingSettings } from './TeamRoutingSettings'
import { TeamUnanswered } from './TeamUnanswered'

export const AddEditTeamDetails: FC = () => {
    const { t } = useTranslation()

    const { call_settings_line_busy } = useFlags(['call_settings_line_busy'])

    const { setValue, watch } = useFormContext<AddEditTeamFields>()
    const inHoursUnanswered = watch('callRoute.inHoursUnanswered')
    const busy = watch('callRoute.busy')

    const advancedRoutingEnabled = useHasClientFeature(Features.advanced_routing)

    const audioClips = useAllClientAudioClips()

    const audioClipsWithDefault = useMemo(() => {
        return [{ label: t('Default'), value: '' }, ...audioClips]
    }, [audioClips, t])

    function setVoicemail(value: any) {
        if (value === 'voicemail') setValue('voicemail.greeting', 'custom')
        else setValue('voicemail.greeting', 'default')

        if (value === 'voicemail_no_message') setValue('voicemail.greetingNoMessage', 'custom')
        else setValue('voicemail.greetingNoMessage', 'default')
    }

    function resetInHoursUnanswered() {
        setValue('unansweredRedirect', undefined)
        setValue('unansweredRedirectInternal', undefined)
        setValue('voicemail.customGreetingURL', '')
        setValue('voicemail.customGreetingNoMessageURL', '')
    }

    function handleChange(name: any, value: any) {
        if (name === 'callRoute.inHoursUnanswered') {
            setVoicemail(value)
            resetInHoursUnanswered()
        }

        setValue(name, value, { shouldDirty: true })
    }

    return (
        <VStack pt="24px" px="24px" spacing="24px" align="inherit" divider={<Divider />}>
            <ValidatedInput name="name" label={t('admin.teams.form.name')}>
                <Input placeholder={t('admin.teams.form.name_placeholder')} />
            </ValidatedInput>
            <AssignedNumbers numbers={watch('phoneNumbers')} />
            <MusicToggle
                name="IVR.greetingEnabled"
                dropdownName="IVR.greeting"
                label={t('admin.teams.form.greetingEnabled')}
                subtext={t('admin.teams.form.greetingEnabled_subtext')}
                options={audioClips}
            />
            <MusicToggle
                name="waitMusic"
                dropdownName="customWaitMusicURL"
                label={t('admin.teams.form.waitMusic')}
                subtext={t('admin.teams.form.waitMusic_subtext')}
                options={audioClipsWithDefault}
            />
            <MusicToggle
                name="holdMusic"
                dropdownName="customHoldMusicURL"
                label={t('admin.teams.form.holdMusic')}
                subtext={t('admin.teams.form.holdMusic_subtext')}
                options={audioClipsWithDefault}
            />
            {(advancedRoutingEnabled || AppConfig.brand === APP_BRAND.PHONELINEPLUS) && (
                <TeamRoutingSettings />
            )}
            <TeamUnanswered
                inHoursUnanswered={inHoursUnanswered}
                audioClipsWithDefault={audioClipsWithDefault}
                handleChange={handleChange}
            />
            {call_settings_line_busy.enabled && (
                <TeamLineBusy
                    busy={busy}
                    audioClipsWithDefault={audioClipsWithDefault}
                    handleChange={handleChange}
                />
            )}

            <TeamsOutOfHours />
            <AdminAnonymousCallSetting
                onChange={setValue}
                anonymousCallSetting={watch('blockAnonymousCalls')}
            />
        </VStack>
    )
}
