import { useDisclosure } from '@chakra-ui/react'
import rubik from '@missionlabs/react/assets/Rubik-Regular.ttf?raw-base64'
import { getStripeEnvironment } from '@missionlabs/utils'
import { Elements } from '@stripe/react-stripe-js'
import { CustomFontSource, loadStripe } from '@stripe/stripe-js'
import { ContactDrawerProvider } from 'features/voice/hooks/useContactDrawerState'
import { FC, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { AddPaymentMethodDrawer } from './AddPaymentMethod'
import { CancelSubscriptionDialog } from './CancelSubscription/CancelSubscriptionDialog'
import { Subscription } from './Subscription'

// convert base64 font string into css src
function btos() {
    return `url(data:font/ttf;base64,${rubik})`
}

const font = { family: 'body', src: btos(), weight: '400', style: 'normal', display: 'swap' }

const stripePromise = loadStripe(getStripeEnvironment())

export interface SubscriptionDashboardProps {}

export const SubscriptionDashboard: FC<SubscriptionDashboardProps> = () => {
    const location = useLocation()
    const { isOpen: addOpen, onClose: addOnClose, onOpen: addOnOpen } = useDisclosure()
    const { isOpen: cancelOpen, onClose: cancelOnClose, onOpen: cancelOnOpen } = useDisclosure()

    useEffect(() => {
        const end = location.pathname.split('/').pop()
        if (!end) return

        if (['payment-method'].includes(end)) addOnOpen()
        if (['cancel'].includes(end)) cancelOnOpen()

        return () => {
            addOnClose()
            cancelOnClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        <>
            <Elements stripe={stripePromise} options={{ fonts: [font as CustomFontSource] }}>
                <AddPaymentMethodDrawer isOpen={addOpen} onClose={addOnClose} />
            </Elements>
            <CancelSubscriptionDialog isOpen={cancelOpen} onClose={cancelOnClose} />
            <Outlet />
            <ContactDrawerProvider>
                <Subscription />
            </ContactDrawerProvider>
        </>
    )
}
