import { Box, HStack, useMultiStyleConfig } from '@chakra-ui/react'
import { Body, BodyProps, Tooltip, TooltipProps } from 'atoms'
import { InformationIconRegular } from 'atoms/Icons/zeta'
import { forwardRef } from 'react'

export interface LabelProps extends Omit<TooltipProps, 'children'> {
    textProps?: Omit<BodyProps, 'children'>
    text?: string
    supportingText?: string
}

export const Label = forwardRef<HTMLDivElement, LabelProps>(
    ({ id, text, supportingText, label = false, textProps, ...props }, ref) => {
        const styles = useMultiStyleConfig('Label')

        return (
            <Box ref={ref}>
                <HStack alignItems="flex-start">
                    <Body id={id} {...textProps} sx={{ ...styles.text, ...(textProps?.sx ?? {}) }}>
                        {text}
                    </Body>
                    {label && (
                        <Tooltip label={label} {...props}>
                            <InformationIconRegular
                                sx={{ ...styles.icon }}
                                data-testid="more information"
                            />
                        </Tooltip>
                    )}
                </HStack>
                {supportingText && <Body sx={{ ...styles.supportingText }}>{supportingText}</Body>}
            </Box>
        )
    }
)
