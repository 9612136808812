import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { Player, Spinner, Table, Toggle } from '@missionlabs/react'
import { AudioClip } from '@missionlabs/types'
import { downloadURL } from '@missionlabs/utils'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useUpdateUserAudioClipMutation } from 'shared/store'

const columnHelper = createColumnHelper<AudioClip>()

function formatDate(value?: string): string {
    if (!value) return ''
    return format(new Date(value), 'dd/MM/yyyy, HH:mm')
}

export interface RecordingsTableProps {
    data: AudioClip[]
    isLoading: boolean
}

export const RecordingsTable: FC<RecordingsTableProps> = ({ data, isLoading }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const user = useSelector(selectAuthenticatedUser)

    const [updateAudioClip] = useUpdateUserAudioClipMutation()

    const columns = [
        columnHelper.accessor('label', {
            cell: info => info.getValue(),
            header: t('recordings.table.name')
        }),
        columnHelper.accessor('URL', {
            cell: ({ getValue }) => {
                const url = getValue()
                return <Player variant="table" url={url} onDownload={() => downloadURL(url)} />
            },
            header: t('recordings.table.recording'),
            meta: { padding: 0 }
        }),
        columnHelper.accessor('created', {
            cell: info => formatDate(info.getValue()),
            header: t('recordings.table.created')
        }),
        columnHelper.accessor('global', {
            cell: ({ getValue, row }) => {
                const { ID, userID } = row.original
                return (
                    <Toggle
                        isChecked={getValue()}
                        isDisabled={userID !== user?.userID}
                        onChange={e => toggleGlobal(ID, e.target.checked)}
                        data-testid={`recordings-global-${ID}`}
                    />
                )
            },
            header: t('recordings.table.shared'),
            meta: { align: 'right', clickable: false },
            size: 0
        })
    ]

    function handleRowClicked(data: AudioClip) {
        if (data.userID !== user?.userID) return
        return navigate(`/settings/recordings/${data.ID}/edit`)
    }

    function toggleGlobal(id: string, value: boolean) {
        if (!user) return
        updateAudioClip({ ID: id, userID: user.userID, entry: { global: value } })
    }

    if (isLoading) return <Spinner size="sm" />

    return <Table data={data} columns={columns} onRowClicked={handleRowClicked} />
}
