import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const MessageIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MessageIconLight',
    viewBox: '0 0 20 21',
    path: (
        <path
            d="M17.5 0.796997H2.5C1.09375 0.796997 0 1.92981 0 3.297V14.5079C0 15.8751 1.09375 16.9689 2.5 16.9689H6.25V20.2501C6.25 20.6407 6.67969 20.8361 6.99219 20.6017L11.875 16.9689H17.5C18.8672 16.9689 20 15.8361 20 14.5079V3.297C20 1.92981 18.9062 0.796997 17.5 0.796997ZM18.7891 14.547C18.7891 15.2501 18.2031 15.797 17.5391 15.797H11.875C11.6016 15.797 11.3672 15.9142 11.1328 16.0704L7.53906 18.7657V16.422C7.53906 16.1095 7.22656 15.797 6.91406 15.797H2.53906C1.83594 15.797 1.28906 15.2501 1.28906 14.547V3.297C1.28906 2.63293 1.83594 2.047 2.53906 2.047H17.5391C18.2031 2.047 18.7891 2.63293 18.7891 3.297V14.547Z"
            fill="currentColor"
        />
    )
})

export const MessageIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MessageIconRegular',
    viewBox: '0 0 20 21',
    path: (
        <path
            d="M17.5 0.796997H2.5C1.09375 0.796997 0 1.92981 0 3.297V14.5079C0 15.8751 1.09375 16.9689 2.5 16.9689H6.25V20.2501C6.25 20.6407 6.67969 20.8361 6.99219 20.6017L11.875 16.9689H17.5C18.8672 16.9689 20 15.8361 20 14.5079V3.297C20 1.92981 18.9062 0.796997 17.5 0.796997ZM18.1641 14.547C18.1641 14.8986 17.8516 15.172 17.5391 15.172H11.2891L8.16406 17.5157V15.172H2.53906C2.1875 15.172 1.91406 14.8986 1.91406 14.547V3.297C1.91406 2.9845 2.1875 2.672 2.53906 2.672H17.5391C17.8516 2.672 18.1641 2.9845 18.1641 3.297V14.547Z"
            fill="currentColor"
        />
    )
})

export const MessageIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MessageIconSolid',
    viewBox: '0 0 20 21',
    path: (
        <path
            d="M20 3.29712V14.5081C20 15.8752 18.8672 16.969 17.5 16.969H11.875L6.99219 20.6018C6.67969 20.8362 6.25 20.6409 6.25 20.2502V16.969H2.5C1.09375 16.969 0 15.8362 0 14.5081V3.29712C0 1.89087 1.09375 0.797119 2.5 0.797119H17.5C18.9062 0.797119 20 1.92993 20 3.29712Z"
            fill="currentColor"
        />
    )
})
