import {
    Step,
    StepIndicator,
    Stepper,
    StepSeparator,
    StepStatus,
    UnorderedList,
    useColorMode,
    useSteps,
    VStack
} from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { CloseCircleIcon, TickCircleIcon } from '@missionlabs/react/circleloop'
import { ProvisioningFlow } from '@missionlabs/types'
import { FC } from 'react'

export interface SipProvisioningFlowProps {
    steps: ProvisioningFlow[]
    stepIndex: number
}

export const SipProvisioningFlow: FC<SipProvisioningFlowProps> = ({ steps, stepIndex }) => {
    const { colorMode } = useColorMode()
    const { activeStep } = useSteps({ index: stepIndex, count: steps.length })

    const iconProps = { boxSize: 'full', color: `${colorMode}.coolGrey.60` }

    return (
        <Stepper
            index={activeStep}
            orientation="vertical"
            sx={{ '--stepper-accent-color': 'none', '--stepper-indicator-size': '24px', gap: 0 }}
            data-testid="sip-provisioning-flow"
        >
            {steps.map((item, index) => (
                <Step
                    key={`${index}-${item.name}`}
                    // @ts-ignore chakra doesn't realise this is valid
                    _notLast={{ pb: '24px' }}
                >
                    <StepIndicator border="none">
                        <StepStatus
                            active={<CloseCircleIcon {...iconProps} />}
                            incomplete={<CloseCircleIcon {...iconProps} />}
                            complete={
                                <TickCircleIcon {...iconProps} color={`${colorMode}.teal.dark`} />
                            }
                        />
                    </StepIndicator>

                    <VStack spacing="16px" align="inherit">
                        <Body variant="bold">{item.name}</Body>
                        {item.lines?.map((line, index) => (
                            <>
                                <Body key={`line-${index}-${line.text}`} size="sm">
                                    {line.text}
                                </Body>
                                {line.bullets && (
                                    <UnorderedList ml="24px">
                                        {line.bullets.map((bullet, index) => (
                                            <Body
                                                key={`bullet-${index}-${bullet.text}`}
                                                as="li"
                                                size="sm"
                                                sx={{
                                                    '&::marker': {
                                                        color: `${colorMode}.coolGrey.50`
                                                    }
                                                }}
                                                _notLast={{ mb: '4px' }}
                                            >
                                                {bullet.text}&nbsp;
                                                {bullet.link && (
                                                    <Body
                                                        as="a"
                                                        // @ts-ignore
                                                        href={bullet.link}
                                                        target="_blank"
                                                        variant="link"
                                                        size="sm"
                                                    >
                                                        {bullet.linkText}
                                                    </Body>
                                                )}
                                            </Body>
                                        ))}
                                    </UnorderedList>
                                )}
                            </>
                        ))}
                    </VStack>

                    <StepSeparator
                        style={{
                            maxHeight: 'calc(100% - var(--stepper-indicator-size))',
                            top: 'var(--stepper-indicator-size)',
                            background: 'none',
                            border: '1px dashed',
                            borderColor: `var(--chakra-colors-${colorMode}-coolGrey-40)`
                        }}
                    />
                </Step>
            ))}
        </Stepper>
    )
}
