/**
 * @source https://github.com/timc1/kbar/blob/main/src/KBarSearch.tsx
 * Modified to fix an issue caused by this PR which prevents the search being cleared when a new root action is selected:
 * https://github.com/timc1/kbar/commit/3e0de569ec890100cf6bf051e72e8f855708bfaf
 */
import { useKBar, VisualState } from 'kbar'
import { InputHTMLAttributes, useEffect, useMemo } from 'react'

export const KBAR_LISTBOX = 'kbar-listbox'
export const getListboxItemId = (id: number) => `kbar-listbox-item-${id}`

const searchStyles = {
    padding: '12px 16px',
    fontSize: '16px',
    width: '100%',
    boxSizing: 'border-box' as React.CSSProperties['boxSizing'],
    outline: 'none',
    border: 'none',
    background: 'rgb(252 252 252)',
    color: 'rgb(28 28 29)'
}

export function KBarSearch(
    props: InputHTMLAttributes<HTMLInputElement> & {
        defaultPlaceholder?: string
    }
) {
    const { query, search, actions, currentRootActionId, activeIndex, showing, options } = useKBar(
        state => ({
            search: state.searchQuery,
            currentRootActionId: state.currentRootActionId,
            actions: state.actions,
            activeIndex: state.activeIndex,
            showing: state.visualState === VisualState.showing
        })
    )

    const { defaultPlaceholder, ...rest } = props

    useEffect(() => {
        query.setSearch('')
        query.getInput().focus()
        return () => query.setSearch('')
    }, [currentRootActionId, query])

    const placeholder = useMemo((): string => {
        const defaultText = defaultPlaceholder ?? 'Type a command or search…'
        return currentRootActionId && actions[currentRootActionId]
            ? actions[currentRootActionId].name
            : defaultText
    }, [actions, currentRootActionId, defaultPlaceholder])

    return (
        <input
            {...rest}
            style={searchStyles}
            ref={query.inputRefSetter}
            autoFocus
            autoComplete="off"
            role="combobox"
            spellCheck="false"
            aria-expanded={showing}
            aria-controls={KBAR_LISTBOX}
            aria-activedescendant={getListboxItemId(activeIndex)}
            value={search}
            placeholder={placeholder}
            onChange={event => {
                props.onChange?.(event)
                query.setSearch(event.target.value)
                options?.callbacks?.onQueryChange?.(event.target.value)
            }}
            onKeyDown={event => {
                props.onKeyDown?.(event)
                if (currentRootActionId && !search && event.key === 'Backspace') {
                    const parent = actions[currentRootActionId].parent
                    query.setCurrentRootAction(parent)
                }
            }}
        />
    )
}
