import {
    Channel,
    ChatMessage,
    ChimeMessage,
    MetaData,
    ReactionMessage,
    SystemMessage,
    UserMessage
} from '@missionlabs/types'
import { v4 } from 'uuid'

type Message = UserMessage | SystemMessage | ReactionMessage
export const incrementThreadCount = (messages: ChatMessage[], messageID: string) => {
    const message = messages.find(message => message.ID === messageID)
    if (message) {
        message.threadCount = (message.threadCount ?? 0) + 1
    }
    return messages
}

export const updateEmojiReacts = (
    messages: ChatMessage[],
    messageID: string,
    unicode: string,
    userID: string
) => {
    const index = messages.findIndex(message => message.ID === messageID)
    if (index === -1) return messages

    const message = messages[index]
    if (!message.emojiReactions) message.emojiReactions = []

    const reactionIndex = message.emojiReactions.findIndex(r => r.emoji === unicode)

    /* No users have made this reaction yet, add the first one */
    if (reactionIndex === -1) {
        messages[index].emojiReactions.push({ emoji: unicode, userIDs: [userID] })
        return messages
    }

    const reaction = messages[index].emojiReactions[reactionIndex]

    /* Other users have made this reaction, add the current user to the userID array */
    if (!reaction.userIDs.includes(userID)) {
        messages[index].emojiReactions[reactionIndex].userIDs.push(userID)
        return messages
    } else {
        /* User has already reacted to this emoji, remove them from the userIDs */
        messages[index].emojiReactions[reactionIndex].userIDs = message.emojiReactions[
            reactionIndex
        ].userIDs.filter(ID => ID !== userID)
        return messages
    }
}

export const updateChannelCache = (
    channels: Channel[],
    channelID: string,
    message: ChatMessage,
    activeChannelID: string | null
) => {
    const channel = channels.find(channel => channel.ID === channelID)

    if (channel) {
        channel.lastMessagePreview = message.contentPlainText
        channel.lastMessageSent = Date.now()

        if (typeof channel.unreadCount !== 'undefined' && channelID !== activeChannelID)
            channel.unreadCount += 1

        sortChannels(channels)
    }

    return channels
}

export const parseChimeMessage = (event: MessageEvent) => {
    const data = JSON.parse(event.data)
    const chimeMessage = JSON.parse(data.Payload) as ChimeMessage
    const content = JSON.parse(chimeMessage.Content) as Message

    const metaData = JSON.parse(chimeMessage.Metadata) as MetaData

    return { content, metaData, chimeMessage }
}

export const mapSystemMessageToModel = (
    message: SystemMessage,
    metaData: MetaData,
    chimeMessage: ChimeMessage
) => {
    const { contentPlainText } = message
    const { clientID, channelID } = metaData

    const mappedMessage: ChatMessage = {
        channelID,
        ID: v4(),
        clientID,
        userID: 'SYSTEM',
        redacted: false,
        created: new Date().getTime(),
        updated: new Date().getTime(),
        contentHTML: contentPlainText,
        contentPlainText,
        user: {
            name: 'System',
            company: 'System',
            extension: 'System'
        },
        senderTimeZone: '',
        sentTimestamp: chimeMessage.LastUpdatedTimestamp,
        childrenMessages: [],
        emojiReactions: []
    }

    return mappedMessage
}

export const mapChimeMessageToModel = (
    message: UserMessage,
    metaData: MetaData,
    chimeMessage: ChimeMessage
) => {
    const {
        parentMessageID,
        replyTo,
        contentHTML,
        contentPlainText,
        user,
        action,
        messageID,
        emojiReactions,
        attachments
    } = message

    const { clientID, channelID } = metaData

    const mappedMessage: ChatMessage = {
        channelID,
        ID: action === 'CREATED' ? chimeMessage.MessageId : messageID || 'ID',
        clientID,
        userID: user?.userID || chimeMessage.Sender.Arn.split('user/')[1],
        redacted: false,
        created: new Date().getTime(),
        updated: new Date().getTime(),
        contentHTML: contentHTML || contentPlainText,
        contentPlainText,
        user: {
            name: user?.name ?? chimeMessage.Sender.Name,
            company: user?.company ?? '',
            extension: user?.extension ?? ''
        },
        senderTimeZone: '',
        sentTimestamp: chimeMessage.LastUpdatedTimestamp,
        childrenMessages: [],
        parentMessageID,
        replyTo: replyTo?.from ? replyTo : undefined,
        emojiReactions: emojiReactions ?? [],
        attachments
    }

    return mappedMessage
}
export const sortChannels = (channels: Channel[]) => {
    channels.sort((a, b) => {
        if (a.lastMessageSent === undefined && b.lastMessageSent === undefined) {
            return 0
        }
        if (a.lastMessageSent === undefined) {
            return 1 // Move objects with undefined lastMessageSent to the bottom
        }
        if (b.lastMessageSent === undefined) {
            return -1 // Move objects with undefined lastMessageSent to the bottom
        }

        // Compare timestamps in descending order
        return new Date(b.lastMessageSent).valueOf() - new Date(a.lastMessageSent).valueOf()
    })
}
export const updateMessage = (messages: ChatMessage[], newMessage: ChatMessage) => {
    const indexToReplace = messages.findIndex(msg => msg.ID === newMessage.ID)
    const message = messages[indexToReplace]
    const updatedMessage: ChatMessage = {
        ...message,
        lastEdited: Date.now(),
        contentHTML: newMessage.contentHTML,
        contentPlainText: newMessage.contentPlainText
    }

    if (indexToReplace !== -1) {
        const updatedMessages = [...messages]
        updatedMessages.splice(indexToReplace, 1, updatedMessage)

        messages = updatedMessages
        return messages
    }
    return messages
}

export const updateDraft = (draft: any, message: ChatMessage, content: Message) => {
    if (content.type === 'USER_MESSAGE' && message) {
        if (message.parentMessageID) {
            return incrementThreadCount(draft, message.parentMessageID)
        }
        if (content.action === 'UPDATED') return updateMessage(draft, message)
        if (content.action === 'CREATED') return [...draft, message]
    }
}
