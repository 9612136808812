import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const TransferIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TransferIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M0.625 6.23047H17.8516L13.9062 10.1758C13.6719 10.4102 13.6719 10.8398 13.9062 11.0742C14.1406 11.3086 14.5703 11.3086 14.8047 11.0742L19.8047 6.07422C19.9219 5.95703 20 5.80078 20 5.60547C20 5.44922 19.9219 5.29297 19.8047 5.17578L14.8047 0.175781C14.5703 -0.0585938 14.1406 -0.0585938 13.9062 0.175781C13.6719 0.410156 13.6719 0.839844 13.9062 1.07422L17.8516 4.98047H0.625C0.273438 4.98047 0 5.29297 0 5.60547C0 5.95703 0.273438 6.23047 0.625 6.23047ZM19.375 13.7305H2.10938L6.05469 9.82422C6.28906 9.58984 6.28906 9.16016 6.05469 8.92578C5.82031 8.69141 5.39062 8.69141 5.15625 8.92578L0.15625 13.9258C0.0390625 14.043 0 14.1992 0 14.3555C0 14.5508 0.0390625 14.707 0.15625 14.8242L5.15625 19.8242C5.39062 20.0586 5.82031 20.0586 6.05469 19.8242C6.28906 19.5898 6.28906 19.1602 6.05469 18.9258L2.10938 14.9805H19.375C19.6875 14.9805 20 14.707 20 14.3555C20 14.043 19.6875 13.7305 19.375 13.7305Z"
            fill="currentColor"
        />
    )
})

export const TransferIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TransferIconRegular',
    viewBox: '0 0 22 20',
    path: (
        <path
            d="M20.0771 13.4375H4.10059L6.99121 10.3516C7.34277 9.96094 7.34277 9.375 6.95215 9.02344C6.56152 8.67188 5.97559 8.67188 5.62402 9.0625L1.24902 13.75C0.897461 14.1016 0.897461 14.6875 1.24902 15.0391L5.62402 19.7266C5.81934 19.9219 6.05371 20 6.32715 20C6.52246 20 6.75684 19.9219 6.95215 19.7656C7.34277 19.4141 7.34277 18.8281 6.99121 18.4375L4.10059 15.3125H20.0771C20.585 15.3125 21.0146 14.9219 21.0146 14.375C21.0146 13.8672 20.585 13.4375 20.0771 13.4375ZM1.95215 6.5625H17.8896L14.999 9.6875C14.6475 10.0781 14.6475 10.6641 15.0381 11.0156C15.4287 11.3672 16.0146 11.3672 16.3662 10.9766L20.7412 6.28906C21.0928 5.9375 21.0928 5.35156 20.7412 5L16.3662 0.3125C16.1709 0.117188 15.9365 0 15.7021 0C15.4678 0 15.2334 0.117188 15.0381 0.273438C14.6475 0.625 14.6475 1.21094 14.999 1.60156L17.8896 4.6875H1.95215C1.40527 4.6875 1.01465 5.11719 1.01465 5.625C1.01465 6.17188 1.40527 6.5625 1.95215 6.5625Z"
            fill="currentColor"
        />
    )
})

export const TransferIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TransferIconSolid',
    viewBox: '0 0 22 20',
    path: (
        <path
            d="M2.26465 6.87012H16.7178L14.4912 9.13574C13.9834 9.60449 13.9834 10.4248 14.4912 10.8936C14.7256 11.1279 15.0381 11.2451 15.3896 11.2451C15.7021 11.2451 16.0146 11.1279 16.249 10.8936L20.624 6.51855C21.1318 6.0498 21.1318 5.22949 20.624 4.76074L16.249 0.385742C15.7803 -0.12207 14.96 -0.12207 14.4912 0.385742C13.9834 0.854492 13.9834 1.6748 14.4912 2.14355L16.7178 4.37012H2.26465C1.56152 4.37012 1.01465 4.95605 1.01465 5.62012C1.01465 6.32324 1.56152 6.87012 2.26465 6.87012ZM19.7646 13.1201H5.27246L7.49902 10.8936C8.00684 10.4248 8.00684 9.60449 7.49902 9.13574C7.03027 8.62793 6.20996 8.62793 5.74121 9.13574L1.36621 13.5107C0.858398 13.9795 0.858398 14.7998 1.36621 15.2686L5.74121 19.6436C5.97559 19.8779 6.28809 19.9951 6.63965 19.9951C6.95215 19.9951 7.26465 19.8779 7.49902 19.6436C8.00684 19.1748 8.00684 18.3545 7.49902 17.8857L5.27246 15.6201H19.7646C20.4287 15.6201 21.0146 15.0732 21.0146 14.3701C21.0146 13.7061 20.4287 13.1201 19.7646 13.1201Z"
            fill="currentColor"
        />
    )
})
