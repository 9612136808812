import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const NotificationIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NotificationIconLight',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M8.33594 0.625C8.33594 0.3125 8.64844 0 8.96094 0C9.3125 0 9.625 0.3125 9.625 0.625V1.28906C12.75 1.60156 15.25 4.25781 15.25 7.5V8.67188C15.25 10.3516 15.9141 11.9922 17.125 13.2031L17.2422 13.3203C17.5547 13.6328 17.75 14.0625 17.75 14.5312C17.75 15.5078 16.9688 16.25 15.9922 16.25H1.96875C0.992188 16.25 0.25 15.5078 0.25 14.5312C0.25 14.0625 0.40625 13.6328 0.71875 13.3203L0.835938 13.2031C2.04688 11.9922 2.75 10.3516 2.75 8.67188V7.5C2.75 4.25781 5.21094 1.60156 8.375 1.28906L8.33594 0.625ZM8.96094 2.5C6.22656 2.5 3.96094 4.76562 3.96094 7.5V8.67188C3.96094 10.7031 3.17969 12.6562 1.73438 14.1016L1.61719 14.1797C1.53906 14.2969 1.46094 14.4141 1.46094 14.5312C1.46094 14.8047 1.69531 15 1.96875 15H15.9922C16.2656 15 16.5 14.8047 16.5 14.5312C16.5 14.4141 16.4219 14.2969 16.3438 14.1797L16.2266 14.1016C14.7812 12.6562 14 10.7031 14 8.67188V7.5C14 4.76562 11.7344 2.5 8.96094 2.5ZM8.96094 18.75C9.50781 18.75 9.97656 18.4375 10.1719 17.9297C10.2891 17.6172 10.6406 17.4219 10.9531 17.5391C11.2656 17.6562 11.4609 18.0078 11.3438 18.3594C10.9922 19.3359 10.0547 20 8.96094 20C7.90625 20 6.96875 19.3359 6.61719 18.3594C6.5 18.0078 6.69531 17.6562 7.00781 17.5391C7.32031 17.4219 7.67188 17.6172 7.78906 17.9297C7.98438 18.4375 8.45312 18.75 8.96094 18.75Z"
            fill="currentColor"
        />
    )
})

export const NotificationIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NotificationIconRegular',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M10.2695 1.25V1.95312C13.082 2.42188 15.2695 4.88281 15.2695 7.8125V9.14062C15.2695 10.8984 15.8555 12.6172 16.9492 14.0234L17.5352 14.7266C17.7695 15.0391 17.8086 15.4297 17.6523 15.7422C17.4961 16.0547 17.1836 16.25 16.832 16.25H1.20703C0.816406 16.25 0.503906 16.0547 0.347656 15.7422C0.191406 15.4297 0.230469 15.0391 0.464844 14.7266L1.05078 14.0234C2.14453 12.6172 2.76953 10.8984 2.76953 9.14062V7.8125C2.76953 4.88281 4.91797 2.42188 7.76953 1.95312V1.25C7.76953 0.585938 8.31641 0 9.01953 0C9.68359 0 10.2695 0.585938 10.2695 1.25ZM8.70703 3.75C6.44141 3.75 4.64453 5.58594 4.64453 7.8125V9.14062C4.64453 11.0156 4.09766 12.8125 3.08203 14.375H14.918C13.9023 12.8125 13.3945 11.0156 13.3945 9.14062V7.8125C13.3945 5.58594 11.5586 3.75 9.33203 3.75H8.70703ZM11.5195 17.5C11.5195 18.1641 11.2461 18.8281 10.7773 19.2969C10.3086 19.7656 9.64453 20 9.01953 20C8.35547 20 7.69141 19.7656 7.22266 19.2969C6.75391 18.8281 6.51953 18.1641 6.51953 17.5H11.5195Z"
            fill="currentColor"
        />
    )
})

export const NotificationIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NotificationIconSolid',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M10.2695 1.25V2.03125C13.1211 2.61719 15.2695 5.11719 15.2695 8.125V8.86719C15.2695 10.7031 15.9336 12.5 17.1445 13.8672L17.418 14.1797C17.7695 14.5703 17.8477 15.0781 17.6524 15.5469C17.457 15.9766 16.9883 16.25 16.5195 16.25H1.51955C1.01174 16.25 0.542986 15.9766 0.347674 15.5469C0.152361 15.0781 0.230486 14.5703 0.582049 14.1797L0.855486 13.8672C2.06642 12.5 2.76955 10.7031 2.76955 8.86719V8.125C2.76955 5.11719 4.87892 2.61719 7.76955 2.03125V1.25C7.76955 0.585938 8.31642 0 9.01955 0C9.68361 0 10.2695 0.585938 10.2695 1.25ZM9.01955 20C8.35549 20 7.69142 19.7656 7.22267 19.2969C6.75392 18.8281 6.51955 18.1641 6.51955 17.5H11.5195C11.5195 18.1641 11.2461 18.8281 10.7774 19.2969C10.3086 19.7656 9.64455 20 9.01955 20Z"
            fill="currentColor"
        />
    )
})

export const NotificationOffIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NotificationOffIconLight',
    viewBox: '0 0 26 22',
    path: (
        <path
            d="M15.2851 15.9976L16.8867 17.2476H5.98827C5.0117 17.2476 4.23045 16.5054 4.23045 15.5288C4.23045 15.0601 4.42577 14.6304 4.73827 14.3179L4.85545 14.2007C6.06639 12.9897 6.73045 11.3491 6.73045 9.66943V9.27881L7.98045 10.2163C7.8242 12.0522 7.04295 13.771 5.75389 15.0991L5.6367 15.1772C5.55858 15.2944 5.48045 15.4116 5.48045 15.5288C5.48045 15.8022 5.71483 15.9976 5.98827 15.9976H15.2851ZM12.3555 1.62256C12.3555 1.31006 12.668 0.997559 12.9805 0.997559C13.332 0.997559 13.6445 1.31006 13.6445 1.62256V2.28662C16.7695 2.59912 19.2695 5.25537 19.2695 8.49756V9.66943C19.2695 11.3491 19.9336 12.9897 21.1445 14.2007L21.2617 14.3179C21.2617 14.3569 21.3008 14.3569 21.3398 14.396L18.4101 12.0913C18.1367 11.3101 18.0195 10.4897 18.0195 9.66943V8.49756C18.0195 5.76318 15.7539 3.49756 12.9805 3.49756C11.6133 3.49756 10.3242 4.0835 9.42577 5.021L8.4492 4.23975C9.46483 3.18506 10.832 2.44287 12.3555 2.28662V1.62256ZM12.9805 19.7476C13.5273 19.7476 13.9961 19.4351 14.1914 18.9272C14.3086 18.6147 14.6601 18.4194 14.9726 18.5366C15.2851 18.6538 15.4805 19.0054 15.3633 19.3569C15.0117 20.3335 14.0742 20.9976 12.9805 20.9976C11.9258 20.9976 10.9883 20.3335 10.6367 19.3569C10.5195 19.0054 10.7148 18.6538 11.0273 18.5366C11.3398 18.4194 11.6914 18.6147 11.8086 18.9272C12.0039 19.4351 12.4726 19.7476 12.9805 19.7476ZM25.2461 19.9038C25.5195 20.0991 25.5976 20.4897 25.3633 20.7632C25.168 21.0366 24.7773 21.0757 24.5039 20.8804L0.75389 2.13037C0.480452 1.93506 0.402327 1.54443 0.636702 1.271C0.832015 0.997559 1.22264 0.919434 1.49608 1.15381L25.2461 19.9038Z"
            fill="currentColor"
        />
    )
})

export const NotificationOffIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NotificationOffIconRegular',
    viewBox: '0 0 26 22',
    path: (
        <path
            d="M7.66794 5.63538C8.56637 4.22913 10.0507 3.2135 11.7695 2.94006V2.23694C11.7695 1.57288 12.3164 0.986938 13.0195 0.986938C13.6836 0.986938 14.2695 1.57288 14.2695 2.23694V2.94006C17.082 3.40881 19.2695 5.86975 19.2695 8.79944V10.1276C19.2695 11.8854 19.8554 13.6041 20.9492 15.0104L21.5351 15.7135C21.7304 15.9869 21.8086 16.3385 21.6914 16.6119L25.1289 19.3463C25.5586 19.6588 25.6367 20.2448 25.2851 20.6354C24.9726 21.0651 24.3867 21.1432 23.9961 20.7916L0.871061 2.66663C0.441374 2.35413 0.363249 1.76819 0.714811 1.37756C1.02731 0.947876 1.61325 0.869751 2.00387 1.22131L7.66794 5.63538ZM9.15231 6.80725L18.0976 13.7994C17.6289 12.6666 17.3945 11.4166 17.3945 10.1276V8.79944C17.3945 6.57288 15.5586 4.73694 13.332 4.73694H12.707C11.1836 4.73694 9.85544 5.59631 9.15231 6.80725ZM6.7695 10.1276V9.69788L8.56637 11.1041C8.41012 12.6276 7.90231 14.1119 7.082 15.3619H13.9961L16.3789 17.2369H5.207C4.81637 17.2369 4.50387 17.0416 4.34762 16.7291C4.19137 16.4166 4.23044 16.026 4.46481 15.7135L5.05075 15.0104C6.1445 13.6041 6.7695 11.8854 6.7695 10.1276ZM15.5195 18.4869C15.5195 19.151 15.2461 19.8151 14.7773 20.2838C14.3086 20.7526 13.6445 20.9869 13.0195 20.9869C12.3554 20.9869 11.6914 20.7526 11.2226 20.2838C10.7539 19.8151 10.5195 19.151 10.5195 18.4869H15.5195Z"
            fill="currentColor"
        />
    )
})

export const NotificationOffIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'NotificationOffIconSolid',
    viewBox: '0 0 26 22',
    path: (
        <path
            d="M7.78512 5.7135C8.68356 4.34631 10.0898 3.33069 11.7695 3.01819V2.23694C11.7695 1.57288 12.3164 0.986938 13.0195 0.986938C13.6836 0.986938 14.2695 1.57288 14.2695 2.23694V3.01819C17.1211 3.60413 19.2695 6.10413 19.2695 9.11194V9.85413C19.2695 11.6901 19.9336 13.4869 21.1445 14.8541L21.4179 15.1666C21.7695 15.5573 21.8476 16.0651 21.6523 16.5338C21.6132 16.5338 21.6132 16.5338 21.6132 16.5729L25.1289 19.3463C25.5586 19.6588 25.6367 20.2448 25.2851 20.6354C24.9726 21.0651 24.3867 21.1432 23.9961 20.7916L0.871061 2.66663C0.441374 2.35413 0.363249 1.76819 0.714811 1.37756C1.02731 0.947876 1.61325 0.869751 2.00387 1.22131L7.78512 5.7135ZM6.7695 9.85413V9.69788L16.3789 17.2369H5.5195C5.01169 17.2369 4.54294 16.9635 4.34762 16.5338C4.15231 16.0651 4.23044 15.5573 4.582 15.1666L4.85544 14.8541C6.06637 13.4869 6.7695 11.6901 6.7695 9.85413ZM13.0195 20.9869C12.3554 20.9869 11.6914 20.7526 11.2226 20.2838C10.7539 19.8151 10.5195 19.151 10.5195 18.4869H15.5195C15.5195 19.151 15.2461 19.8151 14.7773 20.2838C14.3086 20.7526 13.6445 20.9869 13.0195 20.9869Z"
            fill="currentColor"
        />
    )
})
