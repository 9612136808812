import * as yup from 'yup'

yup.addMethod(
    // @ts-ignore
    yup.array,
    'maxOf',
    function (max: number, type: string, callback: (value: any[]) => boolean) {
        return this.test(
            'maxOf',
            { key: 'validations.arrayMaxOf', values: { max, type } },
            value => {
                if (!value) return true
                return callback(value)
            }
        )
    }
)
