import { CSSProperties } from 'react'

import { AddPersonContact, VirtualAddPersonContact } from './AddPersonContact'

interface AddPersonListRendererProps {
    data: VirtualAddPersonContact[]
    index: number
    style: CSSProperties
}

export const AddPersonListRenderer = ({ data, index, style }: AddPersonListRendererProps) => {
    const { contact, onClick } = data[index]

    return (
        <div style={style}>
            <AddPersonContact contact={contact} onClick={onClick} />
        </div>
    )
}
