import { Button, HStack } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface NumberActionsProps {
    numberID?: string
}

const NumberActions: React.FC<NumberActionsProps> = ({ numberID }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <HStack align="end">
            <Button
                variant="primary"
                onClick={() => navigate(`/admin/whatsapp/${numberID}/verify`)}
            >
                {t('admin.whatsapp.verify.button')}
            </Button>
            <Button
                variant="negatory"
                onClick={() => navigate(`/admin/whatsapp/${numberID}/delete`)}
            >
                {t('admin.whatsapp.deleteNumber')}
            </Button>
        </HStack>
    )
}

export default NumberActions
