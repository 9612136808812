import { Center } from '@chakra-ui/react'
import { EmptyPage } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const ContactsEmptyPage: FC = () => {
    const { t } = useTranslation()
    return (
        <Center>
            <EmptyPage
                title={t('contacts.nothingSelected')}
                subtitle={t('contacts.pleaseSelect')}
            />
        </Center>
    )
}
