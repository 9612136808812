import { Box, Center, HStack, Image, useColorMode, VStack } from '@chakra-ui/react'
import { Body, IconButton, Spinner } from '@missionlabs/react'
import { AttachmentIcon } from '@missionlabs/react/circleloop'
import { XIconRegular } from '@missionlabs/react/zeta'
import { downloadURL } from '@missionlabs/utils'
import { FC } from 'react'

import { dispatchUpdateUploadEvent } from '../plugins/UploadPlugin'

export interface UploadProps {
    ID: string
    mime: string
    body: string
    uploaded: boolean
    name?: string
    hideDelete?: boolean
}

export const Upload: FC<UploadProps> = ({ ID, mime, body, name, uploaded, hideDelete }) => {
    const mimetypes = ['image/png', 'image/jpeg', 'image/svg+xml', 'image/gif']
    const isImage = mimetypes.includes(mime)
    const { colorMode } = useColorMode()

    const handleCancel = () => {
        dispatchUpdateUploadEvent('REMOVE', ID)
    }

    const handleDownload = () => {
        downloadURL(body)
    }

    const sizeInBytes = 4 * Math.ceil(body.length / 3) * 0.5624896334383812
    const sizeInKb = Math.round(sizeInBytes / 1000)

    return (
        <Box mb="12px" pos="relative" w="300px">
            {!uploaded && (
                <>
                    <Center pos="absolute" left="0" right="0" top="0" bottom="0" zIndex="99">
                        <Spinner />
                    </Center>
                    <Center
                        position="absolute"
                        top="0"
                        bottom="0"
                        left="0"
                        right="0"
                        bg={`${colorMode}.tones.stormGrey`}
                        opacity="0.6"
                    ></Center>
                </>
            )}
            {!hideDelete && (
                <IconButton
                    position="absolute"
                    left="-10px"
                    top="-10px"
                    size="sm"
                    onClick={handleCancel}
                    bg={`${colorMode}.tones.stormGrey`}
                    isRounded
                    icon={<XIconRegular boxSize={3} color="white" />}
                    aria-label="remove attachment"
                />
            )}

            <HStack spacing={0}>
                {isImage ? (
                    <Image
                        src={body}
                        h="auto"
                        maxW="400px"
                        maxH="600px"
                        objectFit="contain"
                        alt={name}
                        fallbackSrc="https://via.placeholder.com/150"
                    />
                ) : (
                    <HStack
                        border="solid 1px"
                        borderColor={`${colorMode}.tones.periwinkle`}
                        borderRadius="8px"
                        padding={4}
                        align="start"
                    >
                        <IconButton
                            mr={1}
                            icon={<AttachmentIcon boxSize={5} />}
                            aria-label="Download attachment"
                            onClick={handleDownload}
                        />
                        <VStack alignItems="start" spacing={0}>
                            <Body
                                size="sm"
                                variant="bold"
                                sx={{ color: `${colorMode}.tones.midnightExpress` }}
                            >
                                {name}
                            </Body>
                            <Body size="sm" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                                {`${sizeInKb}kb`}
                            </Body>
                        </VStack>
                    </HStack>
                )}
            </HStack>
        </Box>
    )
}
