import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const AddIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0088 12.9867V19.0088C11.0088 19.5562 11.4526 20 12 20C12.5474 20 12.9912 19.5562 12.9912 19.0088L12.9912 12.9867H19.0088C19.5562 12.9867 20 12.543 20 11.9956C20 11.4481 19.5562 11.0044 19.0088 11.0044H12.9912L12.9912 4.99119C12.9912 4.44377 12.5474 4 12 4C11.4526 4 11.0088 4.44377 11.0088 4.9912L11.0088 11.0044L4.99119 11.0044C4.44377 11.0044 4 11.4481 4 11.9956C4 12.543 4.44377 12.9867 4.99119 12.9867L11.0088 12.9867Z"
            fill="currentColor"
        />
    )
})

export const AddCircleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddCircleIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.51472 20.4853C8.20101 25.1716 15.799 25.1716 20.4853 20.4853C25.1716 15.799 25.1716 8.20101 20.4853 3.51472C15.799 -1.17157 8.20101 -1.17157 3.51472 3.51472C-1.17157 8.20101 -1.17157 15.799 3.51472 20.4853ZM6.10713 11.1669H11.1688V6.109C11.1688 5.64889 11.5418 5.27589 12.0019 5.27589C12.462 5.27589 12.835 5.64889 12.835 6.109L12.835 11.1669L17.8891 11.1669C18.3492 11.1669 18.7222 11.5399 18.7222 12C18.7222 12.4601 18.3492 12.8331 17.8891 12.8331L12.835 12.8331L12.835 17.891C12.835 18.3511 12.462 18.7241 12.0019 18.7241C11.5418 18.7241 11.1688 18.3511 11.1688 17.891L11.1688 12.8331L6.10713 12.8331C5.64702 12.8331 5.27402 12.4601 5.27402 12C5.27402 11.5399 5.64702 11.1669 6.10713 11.1669Z"
            fill="currentColor"
        />
    )
})

export const AddCircleOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddCircleIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M6.10713 11.1669H11.1688V6.109C11.1688 5.64889 11.5418 5.27589 12.0019 5.27589C12.462 5.27589 12.835 5.64889 12.835 6.109L12.835 11.1669L17.8891 11.1669C18.3492 11.1669 18.7222 11.5399 18.7222 12C18.7222 12.4601 18.3492 12.8331 17.8891 12.8331L12.835 12.8331L12.835 17.891C12.835 18.3511 12.462 18.7241 12.0019 18.7241C11.5418 18.7241 11.1688 18.3511 11.1688 17.891L11.1688 12.8331L6.10713 12.8331C5.64702 12.8331 5.27402 12.4601 5.27402 12C5.27402 11.5399 5.64702 11.1669 6.10713 11.1669Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.4853 20.4853C15.799 25.1716 8.20101 25.1716 3.51472 20.4853C-1.17157 15.799 -1.17157 8.20101 3.51472 3.51472C8.20101 -1.17157 15.799 -1.17157 20.4853 3.51472C25.1716 8.20101 25.1716 15.799 20.4853 20.4853ZM19.7782 19.7782C15.4824 24.0739 8.51759 24.0739 4.22183 19.7782C-0.0739422 15.4824 -0.0739422 8.51759 4.22183 4.22183C8.51759 -0.0739417 15.4824 -0.0739417 19.7782 4.22183C24.0739 8.51759 24.0739 15.4824 19.7782 19.7782Z"
                fill="currentColor"
            />
        </g>
    )
})

export const AddEmojiIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddEmojiIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2C13.5689 2 15.0535 2.36132 16.375 3.00529V3.25H16.0625C14.8544 3.25 13.875 4.22938 13.875 5.4375C13.875 6.64562 14.8544 7.625 16.0625 7.625H16.375V7.9375C16.375 9.14562 17.3544 10.125 18.5625 10.125C19.7706 10.125 20.75 9.14562 20.75 7.9375V7.625H20.9947C21.6387 8.9465 22 10.4311 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM8.87501 11.375C9.56536 11.375 10.125 10.6754 10.125 9.8125C10.125 8.94956 9.56536 8.25 8.87501 8.25C8.18465 8.25 7.62501 8.94956 7.62501 9.8125C7.62501 10.6754 8.18465 11.375 8.87501 11.375ZM9.13839 13.5755C8.973 13.0849 8.44119 12.8212 7.95054 12.9866C7.4599 13.1519 7.19623 13.6838 7.36161 14.1744C7.65524 15.0455 8.34314 15.8201 9.14245 16.3673C9.95155 16.9212 10.9755 17.3125 12.0421 17.3125C13.108 17.3125 14.134 16.9217 14.9369 16.3634C15.7267 15.8142 16.4112 15.0237 16.6552 14.119C16.79 13.6191 16.494 13.1046 15.9941 12.9698C15.4942 12.835 14.9796 13.131 14.8448 13.6309C14.7517 13.9762 14.425 14.4357 13.8665 14.824C13.3211 15.2032 12.6616 15.4375 12.0421 15.4375C11.4234 15.4375 10.7619 15.2037 10.2017 14.8201C9.63158 14.4298 9.26611 13.9544 9.13839 13.5755ZM16.375 9.8125C16.375 10.6754 15.8154 11.375 15.125 11.375C14.4347 11.375 13.875 10.6754 13.875 9.8125C13.875 8.94956 14.4347 8.25 15.125 8.25C15.8154 8.25 16.375 8.94956 16.375 9.8125Z"
                fill="currentColor"
            />
            <path
                d="M19.5 2.9375C19.5 2.41973 19.0803 2 18.5625 2C18.0447 2 17.625 2.41973 17.625 2.9375V4.5H16.0625C15.5447 4.5 15.125 4.91973 15.125 5.4375C15.125 5.95527 15.5447 6.375 16.0625 6.375H17.625V7.9375C17.625 8.45527 18.0447 8.875 18.5625 8.875C19.0803 8.875 19.5 8.45527 19.5 7.9375V6.375H21.0625C21.5803 6.375 22 5.95527 22 5.4375C22 4.91973 21.5803 4.5 21.0625 4.5H19.5V2.9375Z"
                fill="currentColor"
            />
        </g>
    )
})
