import {
    AutoLinkPlugin as LexicalAutoLinkPlugin,
    LinkMatcher
} from '@lexical/react/LexicalAutoLinkPlugin'
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { FC } from 'react'

/*
 * AutoLinkPlugin enhances link detection, but it may not handle cases where a dot is typed after the link,
 * and certain domain patterns like .co.uk may not be detected without a predefined domainList.
 * Consider expanding the domainList, possibly by loading additional domains from a JSON file.
 */
const domainList = [
    'com',
    'net',
    'dev',
    'org',
    'edu',
    'gov',
    'io',
    'uk',
    'de',
    'jp',
    'fr',
    'au',
    'ca',
    'us',
    'ru',
    'br',
    'in',
    'cn'
]

const URL_MATCHER = new RegExp(
    `(?:https?:\\/\\/)?[-a-zA-Z0-9@:%\\._\\+~#=]{1,256}\\.(?:${domainList.join(
        '|'
    )})\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&//=]*)`,
    'gi'
)

const MATCHERS: LinkMatcher[] = [
    text => {
        const match = URL_MATCHER.exec(text)
        if (match === null) {
            return null
        }
        const fullMatch = match[0]

        return (
            match && {
                attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                },
                index: match.index,
                length: match[0].length,
                text: match[0],
                url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`
            }
        )
    }
]

export interface AutoLinkPluginProps {}

export const AutoLinkPlugin: FC<AutoLinkPluginProps> = () => {
    return (
        <>
            <LinkPlugin />
            <LexicalClickableLinkPlugin />
            <LexicalAutoLinkPlugin matchers={MATCHERS} />
        </>
    )
}
