import { useDisclosure } from '@chakra-ui/react'
import { VirtualPaginationContextProvider } from '@missionlabs/react'
import { AddEditContactDrawer } from 'features/contacts/AddEditContactDrawer'
import { ContactDrawerProvider } from 'features/voice/hooks/useContactDrawerState'
import { FC, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { DocumentTitle } from 'shared/components/DocumentTitle'
import { MainLayout } from 'shared/components/MainLayout'
import { ContactsFilterProvider } from 'shared/hooks/useContactsFilters'

import { ContactsSidebar } from './ContactsSidebar'
import ContactsProvider from './context/contactsContext'

export interface ContactsDashboardProps {}

export const ContactsDashboard: FC<ContactsDashboardProps> = () => {
    const location = useLocation()
    const { isOpen, onClose, onOpen } = useDisclosure()

    useEffect(() => {
        const end = location.pathname.split('/').pop()
        if (!end) return

        if (['add', 'edit'].includes(end)) onOpen()

        return () => {
            onClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        <VirtualPaginationContextProvider>
            <ContactsProvider>
                <ContactDrawerProvider>
                    <DocumentTitle breadcrumbs={['Contacts']} />
                    <AddEditContactDrawer isOpen={isOpen} onClose={onClose} />
                    <MainLayout
                        left={
                            <ContactsFilterProvider>
                                <ContactsSidebar />
                            </ContactsFilterProvider>
                        }
                        right={<Outlet />}
                    />
                </ContactDrawerProvider>
            </ContactsProvider>
        </VirtualPaginationContextProvider>
    )
}
