import { HStack, useDisclosure, VStack } from '@chakra-ui/react'
import {
    Button,
    FormProvider,
    Scrollable,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useYupForm
} from '@missionlabs/react'
import {
    InboundBarring,
    NotAvailable,
    OutboundBarring,
    OutOfHoursForwardType,
    PrefixedCallRouteSettingTypes,
    Redirect,
    RedirectInternal,
    UnansweredForwardType,
    User,
    UserCallRoute,
    Voicemail
} from '@missionlabs/types'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { APP_BRAND, AppConfig } from 'shared/utils/env'

import { AddUserToTeams } from './AddUserToTeams'
import { DeleteUserDialog } from './DeleteUserDialog'
import { EditUserDetails } from './EditUserDetails'
import { editUserSchema } from './editUserSchema'

type EditUserFormProps = {
    onSubmit: (data: EditUserFormFields) => Promise<void>
    onClose: () => void
    user: User
    isLoading: boolean
    values: EditUserFormFields
    phoneNumbers: UserCallRoute['phoneNumbers']
}

export type EditUserFormFields = {
    firstName: string
    lastName: string
    email: string
    type: string
    subscriptionType: string
    teams: { ID: string; name: string; object: string }[]
    outboundBarring?: OutboundBarring
    inboundBarring?: InboundBarring
    callRoute: {
        callTimeout: number
        inHoursUnanswered?: UnansweredForwardType
        unansweredRedirect?: Redirect
        unansweredRedirectInternal?: RedirectInternal
        voicemail?: Voicemail
        outOfHours?: OutOfHoursForwardType
        outOfHoursRedirect?: Redirect
        outOfHoursRedirectInternal?: RedirectInternal | null
        outOfHoursVoicemail?: Voicemail
        notAvailable?: NotAvailable
        blockAnonymousCalls?: boolean | null
    }
}

export const EditUserForm: FC<EditUserFormProps> = ({
    onSubmit,
    onClose,
    user,
    isLoading,
    values,
    phoneNumbers
}) => {
    const { t } = useTranslation()
    const deleteUserDisclosure = useDisclosure()

    const methods = useYupForm<EditUserFormFields>(editUserSchema, {
        values: {
            ...values,
            outboundBarring: {
                all_calls: values.outboundBarring?.all_calls ?? true,
                international_calling: values.outboundBarring?.international_calling ?? false,
                premium_calling: values.outboundBarring?.premium_calling ?? false
            },
            inboundBarring: {
                all_calls: values.inboundBarring?.all_calls ?? true
            }
        }
    })

    const { watch, setValue } = methods

    const { teams = [], callRoute } = watch()

    const onUpdateCallRouteSettings = useCallback(
        (payload: Partial<UserCallRoute>) => {
            Object.entries(payload).forEach(([key, value]) => {
                setValue(`callRoute.${key}` as PrefixedCallRouteSettingTypes, value as any)
            })
        },
        [setValue]
    )

    const onUpdateNotAvailable = useCallback(
        (setting: NotAvailable | undefined) => {
            const updatedSetting = {
                ...callRoute.notAvailable,
                ...setting,
                enabled: setting?.enabled ?? callRoute?.notAvailable?.enabled
            }

            setValue('callRoute.notAvailable', updatedSetting)
        },
        [setValue, callRoute.notAvailable]
    )

    const onUpdateBlockAnonymousCalls = useCallback(
        (name: string, value: boolean) => {
            setValue('callRoute.blockAnonymousCalls', value)
        },
        [setValue]
    )

    return (
        <>
            <FormProvider {...methods}>
                <VStack
                    as="form"
                    h="full"
                    align="stretch"
                    spacing="16px"
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <Tabs size="md" h="full" lazyBehavior="unmount" isLazy isFitted>
                        <TabList mx="24px">
                            <Tab>{t('Details')}</Tab>
                            <Tab>{t('teamsCount', { count: teams.length })}</Tab>
                        </TabList>
                        <Scrollable h="calc(100dvh - 215px)" w="full">
                            <TabPanels>
                                <TabPanel p={0} h="full">
                                    <EditUserDetails
                                        callRoute={callRoute as UserCallRoute}
                                        phoneNumbers={phoneNumbers}
                                        onUpdate={onUpdateCallRouteSettings}
                                        onUpdateNotAvailable={onUpdateNotAvailable}
                                        onUpdateBlockAnonymousCalls={onUpdateBlockAnonymousCalls}
                                    />
                                </TabPanel>
                                <TabPanel p={0} h="full">
                                    <AddUserToTeams />
                                </TabPanel>
                            </TabPanels>
                        </Scrollable>
                    </Tabs>
                    <HStack w="full" spacing="16px" align="inherit" px="24px" pb="24px" pt="0">
                        {AppConfig.brand === APP_BRAND.CIRCLELOOP && (
                            <Button
                                w="full"
                                size="lg"
                                variant="negatory-outline"
                                isDisabled={isLoading}
                                onClick={deleteUserDisclosure.onOpen}
                            >
                                {t('Remove')}
                            </Button>
                        )}
                        <Button
                            w="full"
                            size="lg"
                            variant="secondary"
                            isDisabled={isLoading}
                            onClick={onClose}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            type="submit"
                            w="full"
                            size="lg"
                            variant="creationary"
                            isLoading={isLoading}
                        >
                            {t('Update')}
                        </Button>
                    </HStack>
                </VStack>
            </FormProvider>
            <DeleteUserDialog
                user={user}
                isOpen={deleteUserDisclosure.isOpen}
                onClose={deleteUserDisclosure.onClose}
            />
        </>
    )
}
