import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ConferenceIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ConferenceIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 0C2.68629 0 0 2.68629 0 6V18C0 21.3137 2.68629 24 6 24H18C21.3137 24 24 21.3137 24 18V6C24 2.68629 21.3137 0 18 0H6ZM9.48374 8.00016C9.48374 9.38719 10.6127 10.5161 12 10.5161C13.3873 10.5161 14.5163 9.38719 14.5163 8.00016C14.5163 6.61281 13.3873 5.48386 12 5.48386C10.6127 5.48386 9.48374 6.61281 9.48374 8.00016ZM3 15.0002C3 16.3872 4.12893 17.5161 5.51626 17.5161C6.90359 17.5161 8.03252 16.3872 8.03252 15.0002C8.03252 13.6128 6.90359 12.4839 5.51626 12.4839C4.12893 12.4839 3 13.6128 3 15.0002ZM18.4837 17.5161C17.0964 17.5161 15.9675 16.3872 15.9675 15.0002C15.9675 13.6128 17.0964 12.4839 18.4837 12.4839C19.8711 12.4839 21 13.6128 21 15.0002C21.0001 16.3872 19.8711 17.5161 18.4837 17.5161Z"
                fill="currentColor"
            />
            <mask
                id="mask0_2_2821"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 0C2.68629 0 0 2.68629 0 6V18C0 21.3137 2.68629 24 6 24H18C21.3137 24 24 21.3137 24 18V6C24 2.68629 21.3137 0 18 0H6ZM9.48374 8.00016C9.48374 9.38719 10.6127 10.5161 12 10.5161C13.3873 10.5161 14.5163 9.38719 14.5163 8.00016C14.5163 6.61281 13.3873 5.48386 12 5.48386C10.6127 5.48386 9.48374 6.61281 9.48374 8.00016ZM3 15.0002C3 16.3872 4.12893 17.5161 5.51626 17.5161C6.90359 17.5161 8.03252 16.3872 8.03252 15.0002C8.03252 13.6128 6.90359 12.4839 5.51626 12.4839C4.12893 12.4839 3 13.6128 3 15.0002ZM18.4837 17.5161C17.0964 17.5161 15.9675 16.3872 15.9675 15.0002C15.9675 13.6128 17.0964 12.4839 18.4837 12.4839C19.8711 12.4839 21 13.6128 21 15.0002C21.0001 16.3872 19.8711 17.5161 18.4837 17.5161Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_2_2821)"></g>
        </g>
    )
})

export const ConferencingIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ConferencingIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M8.92457 4.07548C8.92457 5.42131 9.79368 6.56784 11 6.98371V9.09163C10.1074 9.39944 9.39941 10.1074 9.09155 11H6.98388C6.56784 9.79375 5.42109 8.92471 4.07543 8.92471C2.37981 8.92471 1 10.3045 1 12.0002C1 13.6955 2.37981 15.0753 4.07543 15.0753C5.42116 15.0753 6.56797 14.2062 6.98395 13H9.09148C9.39931 13.8925 10.1073 14.6005 11 14.9084V17.0159C9.79368 17.4319 8.92457 18.5788 8.92457 19.9245C8.92457 21.6202 10.3044 23 12 23C13.6956 23 15.0754 21.6202 15.0754 19.9245C15.0754 18.5788 14.2063 17.4319 13 17.0159V14.9084C13.8927 14.6006 14.6007 13.8926 14.9086 13H17.0161C17.432 14.2062 18.5788 15.0753 19.9246 15.0753C21.6202 15.0753 23.0001 13.6955 23 12.0002C23 10.3045 21.6202 8.92471 19.9246 8.92471C18.5789 8.92471 17.4322 9.79375 17.0161 11H14.9085C14.6006 10.1074 13.8926 9.39944 13 9.09163V6.9837C14.2063 6.56781 15.0754 5.42123 15.0754 4.07548C15.0754 2.37983 13.6956 1 12 1C10.3044 1 8.92457 2.37973 8.92457 4.07548Z"
            fill="currentColor"
        />
    )
})
