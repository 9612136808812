import { useColorMode, VStack } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    Body,
    ContactDetail,
    ListItem,
    useOnScreenObserver,
    useSelect,
    UseSelectMode
} from '@missionlabs/react'
import { GridIconSolid } from '@missionlabs/react/zeta'
import { Channel, ChatMessage, isContactGroup } from '@missionlabs/types'
import { format } from 'date-fns'
import { FC } from 'react'
import { useGetPresence } from 'shared/hooks/useGetPresence'
import { useUpdatePresence } from 'shared/hooks/useUpdatePresence'

export interface ChatMessageListRowProps {
    item: ChatMessage
}

const getChannelPresenceID = (channel: Channel, currentUserID?: string) => {
    if (channel.team || !currentUserID) return
    return channel.members?.find(member => member !== currentUserID)
}

export const ChatMessageListRow: FC<ChatMessageListRowProps> = ({ item }) => {
    const { colorMode } = useColorMode()
    const { toggleMessage, isSelected } = useSelect(
        isContactGroup(item) ? UseSelectMode.chatGroup : UseSelectMode.chat
    )
    const user = useSelector(selectAuthenticatedUser)
    const presenceID = getChannelPresenceID(item.channel!, user?.userID)
    const { status } = useGetPresence(presenceID)
    const { updatePresence } = useUpdatePresence(presenceID)
    const { ref } = useOnScreenObserver(updatePresence)

    return (
        <ListItem
            spacing="8px"
            h="full"
            data-active={isSelected(item.channelID, item.ID) || undefined}
            onClick={() => {
                if (item.channel) {
                    toggleMessage(item.channelID, item.ID)
                }
            }}
            ref={ref}
        >
            <ContactDetail
                icon={!item.channel?.user ? <GridIconSolid p="4px" /> : undefined}
                name={item.channel?.channelName}
                subText={item.contentPlainText}
                truncatedWidth="243px"
                status={status}
            />
            <VStack spacing={0} h="full" align="end" justify="end">
                {/* todo: unread message count */}
                {/* <Center px="8px" bg={`${colorMode}.primary.gamma`} borderRadius="100px">
                    <Body size="sm" variant="bold" sx={{ color: 'white' }}>
                        3
                    </Body>
                </Center> */}
                <Body size="sm" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                    {format(item.updated, 'd/M')}
                </Body>
            </VStack>
        </ListItem>
    )
}
