import { Box, useColorMode } from '@chakra-ui/react'
import {
    Body,
    Button,
    ErrorMessage,
    FormProvider,
    Input,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AuthLayout } from 'shared/components/AuthLayout'
import { useForgotPasswordMutation } from 'shared/store'

export interface ForgotPasswordFields {
    username: string
}

export const ForgotPassword: FC = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const [forgotPassword, { isSuccess }] = useForgotPasswordMutation()

    const methods = useYupForm<ForgotPasswordFields>(
        yup.object({ username: yup.string().required() }),
        { defaultValues: { username: '' } }
    )
    const {
        formState: { isSubmitting },
        watch
    } = methods

    const onSubmit = async (data: ForgotPasswordFields) => {
        setErrorMessage(null)

        try {
            await forgotPassword({ userName: data.username }).unwrap()
        } catch (e) {
            console.error(e)
            setErrorMessage('data' in e ? e.data.message : e.message)
        }
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <AuthLayout label={!isSuccess ? t('Reset Password') : t('Reset Email Sent')}>
                    {!isSuccess && (
                        <>
                            {errorMessage && <ErrorMessage error={errorMessage} />}
                            <ValidatedInput name="username" label={t('Username/Email')}>
                                <Input
                                    autoComplete="username"
                                    placeholder={t('Enter username/email')}
                                />
                            </ValidatedInput>
                            <Box w="full" pt="8px">
                                <Button type="submit" size="lg" w="full" isLoading={isSubmitting}>
                                    {t('Send reset email')}
                                </Button>
                            </Box>
                        </>
                    )}
                    {isSuccess && (
                        <>
                            <Body sx={{ color: `${colorMode}.coolGrey.80` }}>
                                {t('forgotPassword.body1')}
                            </Body>
                            <Body variant="bold" sx={{ color: `${colorMode}.red.default` }}>
                                {watch('username')}
                            </Body>
                            <Body sx={{ color: `${colorMode}.coolGrey.80` }}>
                                {t('forgotPassword.body2')}
                            </Body>
                        </>
                    )}
                    <Link to="/login">
                        <Body size="sm" variant="link-bold">
                            {t('Remembered it? Sign in here')}
                        </Body>
                    </Link>
                </AuthLayout>
            </form>
        </FormProvider>
    )
}
