import {
    Tab,
    TabControllerContext,
    TabControllerProvider,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from '@missionlabs/react'
import { ContactType, DirectoryEntry } from '@missionlabs/types'
import { useFlags } from 'flagsmith/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ContactDetails } from '../ContactDetails/ContactDetails'
import { ContactFeedTab } from '../ContactFeed/ContactFeedTab'

interface ContactTabsProps {
    contact: DirectoryEntry
    type: ContactType
}

export const ContactTabs: FC<ContactTabsProps> = ({ contact, type }) => {
    const { t } = useTranslation()
    const flags = useFlags(['contact_feed_tab'])
    const isGroup = type === 'group'

    return (
        <TabControllerProvider defaultTab={0}>
            <TabControllerContext.Consumer>
                {({ tabIndex, setTabIndex }) => (
                    <Tabs
                        variant="lineBottom"
                        w="full"
                        h="full"
                        bg="white"
                        pt="8px"
                        index={tabIndex}
                        onChange={setTabIndex}
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <TabList pl="8px">
                            <Tab fontWeight="500">{t('Details')}</Tab>
                            {flags.contact_feed_tab.enabled && !isGroup && (
                                <Tab fontWeight="500">{t('Feed')}</Tab>
                            )}
                        </TabList>
                        <TabPanels h="full" display="flex" flexDirection="column">
                            <TabPanel p="0">
                                <ContactDetails contact={contact} type={type} />
                            </TabPanel>
                            {flags.contact_feed_tab.enabled && !isGroup && (
                                <TabPanel p="0" h="full">
                                    <ContactFeedTab key={tabIndex} contact={contact} type={type} />
                                </TabPanel>
                            )}
                        </TabPanels>
                    </Tabs>
                )}
            </TabControllerContext.Consumer>
        </TabControllerProvider>
    )
}
