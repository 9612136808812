import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@missionlabs/react'
import { UserCircleIconLight } from '@missionlabs/react/circleloop'
import { DotsVerticalIconRegular } from '@missionlabs/react/zeta'
import { DirectoryEntry } from '@missionlabs/types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface ActivityFeedHeaderMenuProps {
    // For contacts:
    contact?: DirectoryEntry
    // For unsaved contacts:
    contactNumber?: string
    onAddToContact?: () => void
}

export const ActivityFeedHeaderMenu = ({
    contact,
    contactNumber,
    onAddToContact
}: ActivityFeedHeaderMenuProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <Menu>
            <MenuButton
                as={IconButton}
                variant="ghost"
                aria-label="More"
                data-testid="activity-header-menu-trigger"
            >
                <DotsVerticalIconRegular />
            </MenuButton>
            <MenuList data-testid="activity-header-menu">
                {!contact && contactNumber && (
                    <>
                        <MenuItem
                            icon={<UserCircleIconLight />}
                            onClick={() => {
                                navigate({
                                    pathname: '/contacts/add',
                                    search: `numberE164=${contactNumber}`
                                })
                            }}
                        >
                            {t('contacts.createContact')}
                        </MenuItem>
                        <MenuItem icon={<UserCircleIconLight />} onClick={onAddToContact}>
                            {t('contacts.addToExisting')}
                        </MenuItem>
                    </>
                )}
                {contact && (
                    <MenuItem
                        icon={<UserCircleIconLight />}
                        onClick={() => navigate(`/contacts/${contact.ID}`)}
                    >
                        {t('contacts.viewContact')}
                    </MenuItem>
                )}
            </MenuList>
        </Menu>
    )
}
