import { Box, HStack, useDisclosure, VStack } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { Button, Dropdown, Player } from '@missionlabs/react'
import { UserCallRoute } from '@missionlabs/types'
import { InterstitialRadio } from 'features/settings/components/InterstitialRadio'
import { RecordingDrawer } from 'features/settings/components/RecordingDrawer'
import { FC, useEffect, useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserAndSharedAudioClips } from 'shared/hooks/useUserAndSharedAudioClips'
import { useCreateUserAudioClipMutation } from 'shared/store'

import { getTimeoutOptions } from './timeoutOptions'

type RadioType = 'inHoursUnanswered' | 'outOfHours'

const propNames = {
    inHoursUnanswered: { forward: 'inHoursUnanswered', voicemail: 'voicemail' },
    outOfHours: { forward: 'outOfHours', voicemail: 'outOfHoursVoicemail' }
} as const

type InHoursProps = {
    type: 'inHoursUnanswered'
    callRoute: Pick<UserCallRoute, 'inHoursUnanswered' | 'voicemail' | 'callTimeout'>
}

type OutOfHoursProps = {
    type: 'outOfHours'
    callRoute: Pick<UserCallRoute, 'outOfHours' | 'outOfHoursVoicemail'>
}

type VoicemailNoMessageRadioProps = (InHoursProps | OutOfHoursProps) & {
    type: RadioType
    uploadType?: 'user' | 'shared'
    forwardType: string
    onUpdate: (payload: Partial<UserCallRoute>) => void
    minW?: string
}

type VoicemailNoMessageRadioState = { callTimeout?: number; voicemailURL: string }
type VoicemailNoMessageRadioAction =
    | { type: 'CALL_ROUTE_UPDATED'; payload: VoicemailNoMessageRadioState }
    | { type: 'UPDATE_CALL_TIMEOUT'; payload: VoicemailNoMessageRadioState['callTimeout'] }
    | { type: 'UPDATE_VOICEMAIL_URL'; payload: VoicemailNoMessageRadioState['voicemailURL'] }
    | { type: 'RESET' }

const defaultState: VoicemailNoMessageRadioState = { callTimeout: undefined, voicemailURL: '' }

function voicemailStateReducer(
    state: VoicemailNoMessageRadioState,
    action: VoicemailNoMessageRadioAction
): VoicemailNoMessageRadioState {
    switch (action.type) {
        case 'RESET':
            return defaultState
        case 'CALL_ROUTE_UPDATED': {
            const update = { ...state }
            // The callRoute was updated in the parent component,
            // so treat it as the source of truth for state (as long as the values aren't empty)
            if (action.payload.voicemailURL) update.voicemailURL = action.payload.voicemailURL
            if (action.payload.callTimeout) update.callTimeout = action.payload.callTimeout
            return update
        }
        case 'UPDATE_CALL_TIMEOUT':
            return { ...state, callTimeout: action.payload }
        case 'UPDATE_VOICEMAIL_URL':
            return { ...state, voicemailURL: action.payload }
        default:
            return state
    }
}

export const VoicemailNoMessageRadio: FC<VoicemailNoMessageRadioProps> = ({
    type,
    uploadType = 'user',
    forwardType,
    callRoute,
    onUpdate,
    minW
}) => {
    const { t } = useTranslation()
    const drawer = useDisclosure()

    const [state, dispatch] = useReducer(voicemailStateReducer, defaultState)
    const { callTimeout, voicemailURL } = state

    const user = useSelector(selectAuthenticatedUser)

    // User audio clips (including shared)
    const [createAudioClip] = useCreateUserAudioClipMutation()
    const userAudioOptions = useUserAndSharedAudioClips()

    const audioOptions = useMemo(
        () => [{ label: 'Default', value: '' }, ...userAudioOptions],
        [userAudioOptions]
    )

    useEffect(() => {
        if (forwardType !== 'voicemail_no_message') return
        const voicemailURL = callRoute[propNames[type].voicemail]?.customGreetingURL
        const callTimeout = type === 'inHoursUnanswered' ? callRoute.callTimeout : undefined
        dispatch({ type: 'CALL_ROUTE_UPDATED', payload: { callTimeout, voicemailURL } })
    }, [callRoute, type, forwardType])

    const updateValue = (key: keyof VoicemailNoMessageRadioState, value: any) => {
        if (forwardType !== 'voicemail_no_message') return
        const update: Partial<UserCallRoute> = {
            callTimeout,
            [propNames[type].forward]: 'voicemail_no_message',
            [propNames[type].voicemail]: {
                greetingNoMessage: voicemailURL ? 'custom' : 'default',
                customGreetingNoMessageURL: voicemailURL
            }
        }
        if (key === 'callTimeout') {
            update.callTimeout = value
            dispatch({ type: 'UPDATE_CALL_TIMEOUT', payload: value })
        }
        if (key === 'voicemailURL') {
            if (value) {
                update[propNames[type].voicemail] = {
                    greetingNoMessage: 'custom',
                    customGreetingNoMessageURL: value
                }
            } else {
                update[propNames[type].voicemail] = {
                    greetingNoMessage: 'default'
                }
            }
            dispatch({ type: 'UPDATE_VOICEMAIL_URL', payload: value })
        }
        onUpdate(update)
    }

    const onUpload = (file: File | null) => {
        if (!user || !file) return

        const fileReader = new FileReader()
        fileReader.onload = async e => {
            const dataURL = e.target?.result
            if (!dataURL) return
            try {
                const data = {
                    userID: user.userID,
                    data: dataURL,
                    label: file?.name,
                    // When uploading team/menu voicemail recordings,
                    // we want the file to still be accessible by other users,
                    // so we mark it as "global: true"
                    global: uploadType === 'shared' ? true : undefined
                }
                const { URL } = await createAudioClip(data).unwrap()
                updateValue('voicemailURL', URL)
            } catch (e) {
                console.log(e)
                throw Error('Failed to create audio clip.')
            }
        }

        fileReader.readAsDataURL(file)
    }

    return (
        <InterstitialRadio
            value="voicemail_no_message"
            current={forwardType}
            onClick={() => onUpdate({ [propNames[type].forward]: 'voicemail_no_message' })}
            minW={minW}
            label={t('calls.playMessage')}
            alignItems="center"
        >
            <VStack align="flex-start" spacing="8px">
                <HStack spacing="16px" w="full">
                    {type === 'inHoursUnanswered' && (
                        <Dropdown
                            w="full"
                            maxW="196px"
                            options={getTimeoutOptions(t)}
                            value={callTimeout}
                            onChange={(value: number) => updateValue('callTimeout', value)}
                        />
                    )}
                    <Dropdown
                        w="full"
                        maxW="296px"
                        options={audioOptions}
                        value={voicemailURL}
                        onChange={(value: string) => updateValue('voicemailURL', value)}
                    />
                    <Button variant="creationary" onClick={drawer.onOpen} flexShrink={0}>
                        {t('calls.uploadNew')}
                    </Button>
                </HStack>
                {voicemailURL && (
                    <Box w="100%" id="voicemail-player">
                        <Player url={voicemailURL} width="100%" />
                    </Box>
                )}
            </VStack>
            <RecordingDrawer isOpen={drawer.isOpen} onClose={drawer.onClose} onSubmit={onUpload} />
        </InterstitialRadio>
    )
}
