import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const UploadGreetingIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'UploadGreetingIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7867 20.5208H4.98609C2.84836 20.5208 1.00353 19.0705 0.285364 16.8262C-0.475002 14.4502 0.313976 11.9727 2.29651 10.4525C2.91865 6.7006 5.67761 4 8.92543 4C10.4285 4 11.8532 4.58046 13.0608 5.68098C15.6469 5.42018 17.7867 6.92424 18.6259 9.42281C21.5095 9.32277 24 11.9144 24 14.9697C24 17.979 21.613 20.5208 18.7867 20.5208ZM11.5226 17.4433C11.2382 17.4433 11.008 17.2132 11.008 16.9288V10.9783L8.52697 13.4592C8.32597 13.6602 8.00033 13.6602 7.79933 13.4592C7.59833 13.2582 7.59833 12.9326 7.79933 12.7316L11.1484 9.38255C11.2421 9.28325 11.3751 9.22134 11.5226 9.22134C11.6657 9.22134 11.7949 9.27957 11.888 9.37366L15.2459 12.7316C15.4469 12.9326 15.4469 13.2582 15.2459 13.4592C15.1454 13.5598 15.0138 13.61 14.8821 13.61C14.7505 13.61 14.6188 13.5598 14.5183 13.4592L12.0367 10.9776V16.9288C12.0367 17.2132 11.8071 17.4433 11.5226 17.4433Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_194"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="4"
                width="24"
                height="17"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.7867 20.5208H4.98609C2.84836 20.5208 1.00353 19.0705 0.285364 16.8262C-0.475002 14.4502 0.313976 11.9727 2.29651 10.4525C2.91865 6.7006 5.67761 4 8.92543 4C10.4285 4 11.8532 4.58046 13.0608 5.68098C15.6469 5.42018 17.7867 6.92424 18.6259 9.42281C21.5095 9.32277 24 11.9144 24 14.9697C24 17.979 21.613 20.5208 18.7867 20.5208ZM11.5226 17.4433C11.2382 17.4433 11.008 17.2132 11.008 16.9288V10.9783L8.52697 13.4592C8.32597 13.6602 8.00033 13.6602 7.79933 13.4592C7.59833 13.2582 7.59833 12.9326 7.79933 12.7316L11.1484 9.38255C11.2421 9.28325 11.3751 9.22134 11.5226 9.22134C11.6657 9.22134 11.7949 9.27957 11.888 9.37366L15.2459 12.7316C15.4469 12.9326 15.4469 13.2582 15.2459 13.4592C15.1454 13.5598 15.0138 13.61 14.8821 13.61C14.7505 13.61 14.6188 13.5598 14.5183 13.4592L12.0367 10.9776V16.9288C12.0367 17.2132 11.8071 17.4433 11.5226 17.4433Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_194)"></g>
        </g>
    )
})

export const UploadIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'UploadIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 16.5C13 17.0523 12.5523 17.5 12 17.5C11.4477 17.5 11 17.0523 11 16.5V5.41421L7.70711 8.70711C7.31658 9.09763 6.68342 9.09763 6.29289 8.70711C5.90237 8.31658 5.90237 7.68342 6.29289 7.29289L11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L17.7071 7.29289C18.0976 7.68342 18.0976 8.31658 17.7071 8.70711C17.3166 9.09763 16.6834 9.09763 16.2929 8.70711L13 5.41421V16.5ZM5 18C5 17.4477 4.55228 17 4 17C3.44772 17 3 17.4477 3 18V21C3 21.5523 3.44772 22 4 22H20C20.5523 22 21 21.5523 21 21V18C21 17.4477 20.5523 17 20 17C19.4477 17 19 17.4477 19 18V20H5V18Z"
            fill="currentColor"
        />
    )
})

export const UploadFileIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'UploadFileIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.1823 14.0883C11.2378 14.496 11.5881 14.8103 12.0119 14.8103C12.4743 14.8103 12.8491 14.4363 12.8491 13.9749V2.85597L16.1522 6.15203C16.4791 6.47828 17.0092 6.47828 17.3362 6.15203C17.6631 5.82578 17.6631 5.29682 17.3362 4.97056L12.6002 0.24469C12.2733 -0.081562 11.7432 -0.081562 11.4162 0.24469L6.68025 4.97056C6.3533 5.29682 6.3533 5.82578 6.68025 6.15203L6.77415 6.23292C7.1019 6.47559 7.56701 6.44862 7.86424 6.15203L11.1747 2.84929V13.9749L11.1823 14.0883Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.67442 19.8229C1.67442 21.2071 2.79891 22.3292 4.18605 22.3292H19.814C21.2011 22.3292 22.3256 21.2071 22.3256 19.8229V4.22829C22.3256 2.84412 21.2011 1.72202 19.814 1.72202H18.7947C18.3324 1.72202 17.9575 1.34799 17.9575 0.886599C17.9575 0.425207 18.3324 0.0511742 18.7947 0.0511742H19.814C22.1258 0.0511742 24 1.92133 24 4.22829V19.8229C24 22.1298 22.1258 24 19.814 24H4.18605C1.87416 24 0 22.1298 0 19.8229L0 4.22829C0 1.92134 1.87416 0.0511742 4.18605 0.0511742H5.20526C5.66764 0.0511742 6.04247 0.425207 6.04247 0.886599C6.04247 1.34799 5.66764 1.72202 5.20526 1.72202H4.18605C2.79891 1.72202 1.67442 2.84412 1.67442 4.22829L1.67442 19.8229Z"
                fill="currentColor"
            />
        </g>
    )
})
