import { Box, HStack, useColorMode } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { Button } from '@missionlabs/react'
import { EmojiReaction } from '@missionlabs/types'
import { FC } from 'react'

export interface ChatHistoryReactionCountProps {
    reactions: EmojiReaction[]
    onEmojiClick: (unicode: string) => void
}

export const ChatHistoryReactionCount: FC<ChatHistoryReactionCountProps> = ({
    reactions,
    onEmojiClick
}) => {
    const { colorMode } = useColorMode()
    const user = useSelector(selectAuthenticatedUser)
    return (
        <HStack spacing="4px">
            {reactions.map(({ emoji, userIDs }) => (
                <HStack key={emoji} spacing="4px">
                    {userIDs?.length && (
                        <Button
                            variant={!userIDs.includes(user?.userID || '') ? 'primary' : 'ghost'}
                            bg={
                                !userIDs.includes(user?.userID || '')
                                    ? `${colorMode}.alerts.aqua`
                                    : `${colorMode}.tones.white`
                            }
                            fontSize="16px"
                            lineHeight="100%"
                            w="40px"
                            h="28px"
                            borderRadius="4px"
                            borderColor={`${colorMode}.tones.periwinkle`}
                            borderWidth="1px"
                            onClick={() => onEmojiClick(emoji)}
                        >
                            {emoji}
                            <Box as="span" ml={1} fontSize="12px">
                                {userIDs?.length}
                            </Box>
                        </Button>
                    )}
                </HStack>
            ))}
        </HStack>
    )
}
