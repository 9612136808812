import { useColorMode } from '@chakra-ui/react'
import { useSelector } from '@missionlabs/api'
import { Body } from '@missionlabs/react'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { CallNoteSliceRootState, selectCallNoteContent } from 'shared/slices/callNoteSlice'

export interface ActivityCallNoteProps {
    callTraceID: string
    content: string
    idx: number
}

export const ActivityCallNote: FC<ActivityCallNoteProps> = ({ callTraceID, content, idx }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const updatedCallNote = useSelector((state: CallNoteSliceRootState) =>
        selectCallNoteContent(state, callTraceID, idx)
    )

    const text = updatedCallNote || content

    const textEmpty = useCallback(() => text === '' || text === undefined, [text])

    if (textEmpty()) return <></>

    return (
        <Body pt="1">
            <Body size="sm" variant="bold" as="span" color={`${colorMode}.alerts.red`}>
                {t('Call Note')}:
            </Body>
            <Body as="span" size="sm" color={`${colorMode}.tones.navy`} pl={1}>
                {text}
            </Body>
        </Body>
    )
}
