import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const LoadingIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'LoadingIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12H2C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2V0C18.6274 0 24 5.37258 24 12Z"
                fill="currentColor"
            />
            <mask
                id="mask0_2_2964"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12H2C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2V0C18.6274 0 24 5.37258 24 12Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_2_2964)"></g>
        </g>
    )
})
