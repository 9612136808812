import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const HomeIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HomeIconLight',
    viewBox: '0 0 24 20',
    path: (
        <path
            d="M23.0352 9.52148C23.3086 9.75586 23.3086 10.1465 23.0742 10.4199C22.8789 10.6934 22.4883 10.6934 22.2149 10.459L20.7695 9.16992V16.8652C20.7695 18.623 19.3633 19.9902 17.6445 19.9902H6.39454C4.63673 19.9902 3.26954 18.623 3.26954 16.8652V9.16992L1.78516 10.459C1.51173 10.6934 1.1211 10.6934 0.925789 10.4199C0.691414 10.1465 0.691414 9.75586 0.964851 9.52148L11.5899 0.185547C11.8242 -0.0488281 12.1758 -0.0488281 12.4102 0.185547L23.0352 9.52148ZM6.39454 18.7402H8.89454V12.4902C8.89454 11.8262 9.44141 11.2402 10.1445 11.2402H13.8945C14.5586 11.2402 15.1445 11.8262 15.1445 12.4902V18.7402H17.6445C18.6602 18.7402 19.5195 17.9199 19.5195 16.8652V8.07617L12.0195 1.47461L4.51954 8.07617V16.8652C4.51954 17.9199 5.33985 18.7402 6.39454 18.7402ZM10.1445 18.7402H13.8945V12.4902H10.1445V18.7402Z"
            fill="currentColor"
        />
    )
})

export const HomeIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HomeIconRegular',
    viewBox: '0 0 24 20',
    path: (
        <path
            d="M22.918 8.96973C23.3086 9.32129 23.3477 9.90723 23.0352 10.2979C22.6836 10.6885 22.0977 10.7275 21.707 10.415L20.7695 9.59473V16.8604C20.7695 18.6182 19.3633 19.9854 17.6445 19.9854H6.39454C4.63672 19.9854 3.26954 18.6182 3.26954 16.8604V9.59473L2.29297 10.415C1.90235 10.7275 1.31641 10.6885 0.964849 10.2979C0.652349 9.90723 0.691411 9.32129 1.08204 8.96973L11.3945 0.219727C11.7461 -0.0537109 12.2539 -0.0537109 12.6055 0.219727L22.918 8.96973ZM6.39454 18.1104H8.26954V12.1729C8.26954 11.3135 8.9336 10.6104 9.83204 10.6104H14.207C15.0664 10.6104 15.7695 11.3135 15.7695 12.1729V18.1104H17.6445C18.3086 18.1104 18.8945 17.5635 18.8945 16.8604V7.99316L12.0195 2.17285L5.14454 7.99316V16.8604C5.14454 17.5635 5.69141 18.1104 6.39454 18.1104ZM10.1445 18.1104H13.8945V12.4854H10.1445V18.1104Z"
            fill="currentColor"
        />
    )
})

export const HomeIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HomeIconSolid',
    viewBox: '0 0 24 20',
    path: (
        <path
            d="M23.2275 10C23.2275 10.7031 22.6416 11.25 21.9775 11.25H20.7275L20.7666 17.5C20.7666 17.6172 20.7666 17.7344 20.7666 17.8125V18.4375C20.7666 19.3359 20.0635 20 19.2041 20H18.5791C18.501 20 18.4619 20 18.4228 20C18.3838 20 18.3056 20 18.2666 20H16.0791C15.1806 20 14.5166 19.3359 14.5166 18.4375V15C14.5166 14.3359 13.9306 13.75 13.2666 13.75H10.7666C10.0635 13.75 9.51659 14.3359 9.51659 15V18.4375C9.51659 19.3359 8.81346 20 7.95409 20H5.76659C5.68846 20 5.6494 20 5.57127 20C5.53221 20 5.49315 20 5.45409 20H4.82909C3.93065 20 3.26659 19.3359 3.26659 18.4375V14.0625C3.26659 14.0625 3.26659 14.0234 3.26659 13.9844V11.25H2.01659C1.31346 11.25 0.766586 10.7031 0.766586 10C0.766586 9.64844 0.883774 9.33594 1.15721 9.0625L11.1572 0.351562C11.4306 0.078125 11.7431 0 12.0166 0C12.29 0 12.6025 0.117188 12.8369 0.3125L22.7978 9.0625C23.1103 9.33594 23.2666 9.64844 23.2275 10Z"
            fill="currentColor"
        />
    )
})
