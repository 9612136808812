import { useCallback } from 'react'

import { useCreateAudioClipMutation } from '../store'

export function useUploadRecording() {
    const [createAudioClip] = useCreateAudioClipMutation()
    const uploadRecording = useCallback(
        async (file: File | null) => {
            if (!file) throw Error('No file')
            const formData = new FormData()
            formData.append('importMP3', file)
            formData.append('label', file.name)
            const response = await createAudioClip(formData).unwrap()
            return response.URL
        },
        [createAudioClip]
    )
    return uploadRecording
}
