import { useDebouncedFunction } from '@missionlabs/react'
import { ChatMessage } from '@missionlabs/types'
import { useCallback, useState } from 'react'
import { useGetSearchMessagesMutation } from 'shared/store'

type useMessagesSearchType = (args: { searchTerm: string; maxResults?: number }) => Promise<void>

type useMessagesSearchReturn = [
    useMessagesSearchType,
    { data: ChatMessage[]; isLoading: boolean; isError: boolean }
]

export const useMessagesSearch = (): useMessagesSearchReturn => {
    const [search, { isLoading, isError }] = useGetSearchMessagesMutation()

    const [messages, setMessages] = useState<ChatMessage[]>([])

    const _searchMessages: useMessagesSearchType = useCallback(
        async ({ searchTerm, maxResults }) => {
            if (!searchTerm) return setMessages([])
            try {
                setMessages(
                    await search({
                        searchTerm,
                        size: maxResults ?? 1000
                    }).unwrap()
                )
            } catch {
                setMessages([])
            }
        },
        [setMessages, search]
    )

    const searchMessages = useDebouncedFunction(_searchMessages, 200)

    return [searchMessages, { data: messages, isLoading, isError }]
}
