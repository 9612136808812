import {
    CallController,
    CallControllerConfig,
    LiveServices,
    LiveServicesConfig,
    LiveServicesStatus,
    UserPreferences
} from '@missionlabs/browser-calling'
import {
    CallMonitoringEvent,
    DeploymentEnvironments,
    EventGroup,
    RecordingMode,
    RecordingModeEnum,
    TeamVmNotificationPayload
} from '@missionlabs/types'
import { forceArray, getDeploymentEnvironment } from '@missionlabs/utils'
import { createListenerMiddleware } from '@reduxjs/toolkit'

import appConfig from '../config'
import {
    acceptMeetingRequest,
    acceptPullMeeting,
    addCall,
    addMeetingParticipants,
    addMeetingRequest,
    addMessage,
    addRecording,
    addToCallHistory,
    admitGuest,
    CallState,
    createMeeting,
    declineMeetingRequest,
    endRecording,
    focusCall,
    guestRequestToJoinMeeting,
    joinMeeting,
    leaveActiveMeeting,
    MeetingRecordingStatus,
    MeetingState,
    openMeetingWindow,
    ParticipantVideoStatus,
    pauseRecording,
    rejectGuest,
    removeMeetingParticipants,
    removeRecording,
    requestToPullMeeting,
    resumeRecording,
    retrieveParkedCall,
    sendSubscriptionRequest,
    startRecording,
    stopMeeting,
    SubscriptionState,
    updateCall,
    updateCallGroupMessages,
    updateCallHistory,
    updateDetails,
    updateGroupCalls,
    updateMeeting,
    updateParkedCalls,
    updatePresenceStates,
    updatePresenceSubscriptions,
    updateRecording,
    updateUserCalls,
    UserState
} from '../slices'
import {
    answer,
    blindTransfer,
    call,
    callPickup,
    cancelCallTransfer,
    closeAllCallSockets,
    closeSocket,
    confirmCallTransfer,
    declineCall,
    hangup,
    hold,
    liveServicesAuth,
    liveServicesConnect,
    LiveServicesState,
    nWayHangup,
    nWayInitiate,
    parkCall,
    parkCallGroup,
    sendChatMessage,
    sendChatReaction,
    sendDTMF,
    setInputDevice,
    startStopRecording,
    toggleMute,
    toggleRecording,
    transferCall,
    unhold,
    updateCallDetails,
    updateChatMessage,
    updateConnection,
    updateWrtcDevices
} from '../slices/liveServicesSlice'
import { updatePreferences } from '../slices/preferencesSlice'
import { setUser, updateRecordingMode } from '../slices/userSlice'
import { CustomHTMLAudio } from '../types'

type RootState = {
    meetingSlice: MeetingState
    liveServicesSlice: LiveServicesState
    userSlice: UserState
    subscriptionSlice: SubscriptionState
    callSlice: CallState
    preferencesSlice: UserPreferences
}

export const liveServicesMiddleware = createListenerMiddleware<RootState>()

export const streamPlayer: CustomHTMLAudio = new Audio()
export let ringingNotification: CustomHTMLAudio = new Audio()
export let videoRingingNotification: CustomHTMLAudio = new Audio()
export let externalCallRingingNotification: CustomHTMLAudio = new Audio()
export let callWaitingNotification: CustomHTMLAudio = new Audio()
export let dtmf0Tone: CustomHTMLAudio = new Audio()
export let dtmf1Tone: CustomHTMLAudio = new Audio()
export let dtmf2Tone: CustomHTMLAudio = new Audio()
export let dtmf3Tone: CustomHTMLAudio = new Audio()
export let dtmf4Tone: CustomHTMLAudio = new Audio()
export let dtmf5Tone: CustomHTMLAudio = new Audio()
export let dtmf6Tone: CustomHTMLAudio = new Audio()
export let dtmf7Tone: CustomHTMLAudio = new Audio()
export let dtmf8Tone: CustomHTMLAudio = new Audio()
export let dtmf9Tone: CustomHTMLAudio = new Audio()
export let dtmfStarTone: CustomHTMLAudio = new Audio()
export let dtmfHashTone: CustomHTMLAudio = new Audio()

let liveServices: LiveServices | undefined, callController: CallController | undefined

export let callRingingAudio: CustomHTMLAudio

export function setCallRingingAudio(ringTone?: string) {
    switch (ringTone) {
        case 'office': {
            callRingingAudio = ringingNotification
            break
        }
        case 'xylophone': {
            callRingingAudio = externalCallRingingNotification
            break
        }
        case 'modern': {
            callRingingAudio = videoRingingNotification
            break
        }
        default: {
            callRingingAudio = ringingNotification
            break
        }
    }
    return callRingingAudio
}

function setRingingDevice(deviceId: string | undefined) {
    return Promise.all(
        [
            ringingNotification,
            externalCallRingingNotification,
            videoRingingNotification,
            callWaitingNotification,
            dtmf0Tone,
            dtmf1Tone,
            dtmf2Tone,
            dtmf3Tone,
            dtmf4Tone,
            dtmf5Tone,
            dtmf6Tone,
            dtmf7Tone,
            dtmf8Tone,
            dtmf9Tone,
            dtmfStarTone,
            dtmfHashTone
        ].map(audio => {
            if (!audio.setSinkId || !deviceId) return undefined
            return audio.setSinkId(deviceId || 'default')
        })
    )
}

liveServicesMiddleware.startListening({
    actionCreator: closeSocket,
    effect: _action => {
        liveServices?.close()
        liveServices = undefined
    }
})

liveServicesMiddleware.startListening({
    actionCreator: closeAllCallSockets,
    effect: _action => {
        callController?.closeAllCallSockets()
    }
})

liveServicesMiddleware.startListening({
    actionCreator: call,
    effect: action => {
        callController?.call(action.payload.to, action.payload.from, action.payload.contact)
    }
})
liveServicesMiddleware.startListening({
    actionCreator: callPickup,
    effect: action => {
        callController?.callPickup(
            action.payload.to,
            action.payload.from,
            action.payload.callTraceID,
            action.payload.contact
        )
    }
})

liveServicesMiddleware.startListening({
    actionCreator: acceptMeetingRequest,
    effect: (action, listenerApi) => {
        videoRingingNotification.loop = false
        videoRingingNotification.pause()

        if (!liveServices) return

        const {
            userSlice: { user }
        } = listenerApi.getState()

        const {
            meetingSlice: { active }
        } = listenerApi.getState()

        if (!user) return
        if (!active) return

        liveServices.send({
            type: 'accept_video_call',
            body: {
                meetingID: action.payload.meetingID,
                event: 'accept_video_call'
            }
        })
    }
})

liveServicesMiddleware.startListening({
    actionCreator: guestRequestToJoinMeeting,
    effect: (action, listenerApi) => {
        if (!liveServices) return

        const {
            meetingSlice: { active }
        } = listenerApi.getState()

        if (!active) return

        liveServices.send({
            type: 'guest_request_to_join_video_call',
            body: {
                meetingID: action.payload.meetingID,
                event: 'guest_request_to_join_video_call',
                name: action.payload.name,
                email: action.payload.email,
                company: action.payload.company
            }
        })
    }
})

liveServicesMiddleware.startListening({
    actionCreator: admitGuest,
    effect: (action, listenerApi) => {
        if (!liveServices) return

        const {
            userSlice: { user },
            meetingSlice: { active }
        } = listenerApi.getState()

        if (!user || !active) return

        liveServices.send({
            type: 'admit_guest_to_video_call',
            body: {
                meetingID: action.payload.meetingID,
                guestUserID: action.payload.guestUserID,
                event: 'admit_guest_to_video_call'
            }
        })
    }
})

liveServicesMiddleware.startListening({
    actionCreator: rejectGuest,
    effect: (action, listenerApi) => {
        if (!liveServices) return

        const {
            userSlice: { user },
            meetingSlice: { active }
        } = listenerApi.getState()

        if (!user || !active) return

        liveServices.send({
            type: 'reject_guest_from_video_call',
            body: {
                meetingID: action.payload.meetingID,
                guestUserID: action.payload.guestUserID,
                event: 'reject_guest_from_video_call'
            }
        })
    }
})

liveServicesMiddleware.startListening({
    actionCreator: removeMeetingParticipants,
    effect: (action, listenerApi) => {
        if (!liveServices) return

        const {
            meetingSlice: { active },
            userSlice: { user }
        } = listenerApi.getState()

        if (!user || !active) return

        liveServices.send({
            type: 'remove_attendees_from_video_call',
            body: {
                meetingID: action.payload.meetingID,
                attendees: action.payload.otherParticipants.map(({ userID }) => userID),
                event: 'remove_attendees_from_video_call'
            }
        })
    }
})

liveServicesMiddleware.startListening({
    actionCreator: addMeetingParticipants,
    effect: (action, listenerApi) => {
        if (!liveServices) return

        const {
            userSlice: { user }
        } = listenerApi.getState()

        const {
            meetingSlice: { active }
        } = listenerApi.getState()

        if (!user) return
        if (!active) return

        liveServices.send({
            type: 'add_attendees_to_video_call',
            body: {
                meetingID: action.payload.meetingID,
                attendees: action.payload.otherParticipants.map(({ userID }) => userID),
                event: 'add_attendees_to_video_call'
            }
        })
    }
})

liveServicesMiddleware.startListening({
    actionCreator: startRecording,
    effect: (action, listenerApi) => {
        if (!liveServices) return

        const {
            userSlice: { user }
        } = listenerApi.getState()

        const {
            meetingSlice: { active }
        } = listenerApi.getState()

        if (!user) return
        if (!active) return

        liveServices.send({
            type: 'start_video_call_recording',
            body: {
                meetingID: active.meetingID,
                event: 'start_video_call_recording'
            }
        })
    }
})

liveServicesMiddleware.startListening({
    actionCreator: pauseRecording,
    effect: (action, listenerApi) => {
        if (!liveServices) return

        const {
            userSlice: { user }
        } = listenerApi.getState()

        const {
            meetingSlice: { active }
        } = listenerApi.getState()

        if (!user) return
        if (!active) return

        liveServices.send({
            type: 'pause_video_call_recording',
            body: {
                meetingID: active.meetingID,
                event: 'pause_video_call_recording'
            }
        })
    }
})
liveServicesMiddleware.startListening({
    actionCreator: resumeRecording,
    effect: (action, listenerApi) => {
        if (!liveServices) return

        const {
            userSlice: { user }
        } = listenerApi.getState()

        const {
            meetingSlice: { active }
        } = listenerApi.getState()

        if (!user) return
        if (!active) return

        liveServices.send({
            type: 'resume_video_call_recording',
            body: {
                meetingID: active.meetingID,
                event: 'resume_video_call_recording'
            }
        })
    }
})

liveServicesMiddleware.startListening({
    actionCreator: endRecording,
    effect: (action, listenerApi) => {
        if (!liveServices) return

        const {
            userSlice: { user }
        } = listenerApi.getState()

        const {
            meetingSlice: { active }
        } = listenerApi.getState()

        if (!user) return
        if (!active) return

        liveServices.send({
            type: 'end_video_call_recording',
            body: {
                meetingID: active.meetingID,
                event: 'end_video_call_recording'
            }
        })
    }
})

liveServicesMiddleware.startListening({
    actionCreator: declineMeetingRequest,
    effect: action => {
        if (!liveServices) return

        videoRingingNotification.loop = false
        videoRingingNotification.pause()

        liveServices.send({
            type: 'reject_video_call',
            body: {
                meetingID: action.payload.meetingID,
                event: 'reject_video_call'
            }
        })
    }
})

liveServicesMiddleware.startListening({
    actionCreator: leaveActiveMeeting,
    effect: action => {
        if (!liveServices) return

        liveServices.send({
            type: 'hangup_from_video_call',
            body: {
                meetingID: action.payload.meetingID,
                endMeeting: action.payload.endMeeting,
                event: 'hangup_from_video_call'
            }
        })
    }
})

liveServicesMiddleware.startListening({
    actionCreator: openMeetingWindow,
    effect: (action, listenerApi) => {
        videoRingingNotification.loop = false
        videoRingingNotification.pause()

        const {
            callSlice: { calls }
        } = listenerApi.getState()

        const connectedCalls = calls.filter(({ status }) => status === 'CONNECTED')

        for (const call of connectedCalls) {
            if (call.callTraceID) {
                listenerApi.dispatch(hold({ callTraceID: call.callTraceID }))
            }
        }
    }
})

liveServicesMiddleware.startListening({
    actionCreator: createMeeting,
    effect: (action, listenerApi) => {
        if (!liveServices) return

        liveServices.send(
            {
                type: 'create_video_call',
                body: {
                    attendees: action.payload.otherParticipants.map(({ userID }) => userID),
                    sources: action.payload?.caller?.sources
                }
            },
            response => {
                const { code } = response.body

                if (!code || code < 400 || code > 500) return

                let message = 'There was a problem starting your meeting.'
                if (code === 409) message = 'You are already in another meeting.'

                listenerApi.dispatch(stopMeeting({ error: message }))
            }
        )
    }
})

liveServicesMiddleware.startListening({
    actionCreator: joinMeeting,
    effect: (action, listenerApi) => {
        if (!liveServices) return

        liveServices.send(
            {
                type: 'join_scheduled_video_call',
                body: {
                    meetingID: action.payload.meetingID,
                    event: 'join_scheduled_video_call'
                }
            },
            response => {
                const { code, meetingID } = response.body

                if (!code || code < 400 || code > 500) return

                let message = 'There was a problem starting your meeting.'
                if (code === 400) message = 'Meeting not found'
                if (code === 500) message = 'Something went wrong starting your meeting'

                listenerApi.dispatch(stopMeeting({ error: message, meetingID }))
            }
        )
    }
})

liveServicesMiddleware.startListening({
    actionCreator: requestToPullMeeting,
    effect: (action, listenerApi) => {
        if (!liveServices) return

        liveServices.send(
            {
                type: 'request_to_pull_video_call',
                body: {
                    meetingID: action.payload.meetingID,
                    event: 'request_to_pull_video_call'
                }
            },
            response => {
                const { code = -1, meetingID } = response.body

                if (code < 400 || code > 500) return

                let message = 'There was a problem pulling your meeting.'
                if (code === 400) message = 'Meeting not found'
                if (code === 500) message = 'Something went wrong pulling your meeting'

                listenerApi.dispatch(stopMeeting({ error: message, meetingID }))
            }
        )
    }
})

liveServicesMiddleware.startListening({
    actionCreator: acceptPullMeeting,
    effect: (action, listenerApi) => {
        if (!liveServices) return

        const { meetingSlice } = listenerApi.getState()

        if (
            !meetingSlice.active?.meetingID ||
            meetingSlice.active?.meetingID !== action.payload.meetingID
        ) {
            console.error(
                'Not currently in the meeting requested fo pull:',
                action.payload.meetingID
            )
            return
        }

        liveServices.send(
            {
                type: 'accept_video_call_pull',
                body: {
                    meetingID: action.payload.meetingID,
                    event: 'accept_video_call_pull',
                    joinInfo: meetingSlice.active?.joinInfo,
                    toWebsocketID: action.payload.toWebsocketID,
                    inMeetingDeviceState: meetingSlice.active?.transferDeviceState
                }
            },
            response => {
                const { code = -1, meetingID } = response.body

                if (code < 400 || code > 500) return

                let message = 'There was a problem pulling your meeting.'
                if (code === 400) message = 'Meeting not found'
                if (code === 500) message = 'Something went wrong pulling your meeting'

                listenerApi.dispatch(stopMeeting({ error: message, meetingID }))
            }
        )
    }
})

liveServicesMiddleware.startListening({
    actionCreator: nWayInitiate,
    effect: action => {
        callController?.nWayInitiate(
            action.payload.callTraceID,
            action.payload.userID,
            action.payload.teamID,
            action.payload.numberE164,
            () => {}
        )
    }
})

liveServicesMiddleware.startListening({
    actionCreator: nWayHangup,
    effect: action => {
        callController?.nWayHangup(
            action.payload.callTraceID,
            action.payload.legID,
            action.payload.userID,
            action.payload.teamID,
            action.payload.numberE164,
            () => {}
        )
    }
})

liveServicesMiddleware.startListening({
    actionCreator: answer,
    effect: action => {
        callController?.answerCall(action.payload.callTraceID)
    }
})

liveServicesMiddleware.startListening({
    actionCreator: hangup,
    effect: action => {
        callRingingAudio.loop = false
        callRingingAudio.pause()
        callController?.endCall(action.payload.callTraceID)
    }
})
liveServicesMiddleware.startListening({
    actionCreator: declineCall,
    effect: action => {
        callController?.declineCall(action.payload.callTraceID)
    }
})

liveServicesMiddleware.startListening({
    actionCreator: hold,
    effect: action => {
        callController?.hold(action.payload.callTraceID, () => {})
    }
})

liveServicesMiddleware.startListening({
    actionCreator: unhold,
    effect: action => {
        callController?.unhold(action.payload.callTraceID, () => {})
    }
})

liveServicesMiddleware.startListening({
    actionCreator: transferCall,
    effect: action => {
        callController?.transfer(
            action.payload.callTraceID,
            action.payload.userID,
            action.payload.teamID,
            action.payload.numberE164,
            action.payload.isUnattended,
            action.payload.contact,
            () => {}
        )
    }
})
liveServicesMiddleware.startListening({
    actionCreator: confirmCallTransfer,
    effect: action => {
        callController?.confirmTransfer(action.payload.callTraceID, () => {})
    }
})
liveServicesMiddleware.startListening({
    actionCreator: cancelCallTransfer,
    effect: action => {
        callController?.cancelTransfer(action.payload.callTraceID, () => {})
    }
})
liveServicesMiddleware.startListening({
    actionCreator: blindTransfer,
    effect: action => {
        callController?.blindTransfer(
            action.payload.callTraceID,
            action.payload.userID,
            action.payload.teamID,
            action.payload.numberE164,
            () => {}
        )
    }
})
liveServicesMiddleware.startListening({
    actionCreator: toggleRecording,
    effect: action => {
        const { payload } = action

        if (
            payload.recordingMode === RecordingModeEnum.ON_DEMAND_USER_START &&
            payload.state === 'OFF'
        )
            return callController?.startRecording(payload.callTraceID, () => {})

        if (payload.state === 'ON') callController?.pauseRecording(payload.callTraceID, () => {})
        else callController?.resumeRecording(payload.callTraceID, () => {})
    }
})
liveServicesMiddleware.startListening({
    actionCreator: startStopRecording,
    effect: action => {
        const { payload } = action
        if (payload.start) callController?.startRecording(payload.callTraceID, () => {})
        else callController?.stopRecording(payload.callTraceID, () => {})
    }
})

liveServicesMiddleware.startListening({
    actionCreator: toggleMute,
    effect: action => {
        if (!action.payload.enabled) callController?.mute(action.payload.callTraceID)
        else callController?.unmute(action.payload.callTraceID)
    }
})

liveServicesMiddleware.startListening({
    actionCreator: setInputDevice,
    effect: _action => {
        // callController?.setInputDevice(action.payload)
    }
})

liveServicesMiddleware.startListening({
    actionCreator: sendDTMF,
    effect: action => {
        callController?.sendDTMF(action.payload.callTraceID, action.payload.dtmfKey)
        const key = action.payload.dtmfKey

        let tone = dtmf0Tone
        if (key === '1') tone = dtmf1Tone
        if (key === '2') tone = dtmf2Tone
        if (key === '3') tone = dtmf3Tone
        if (key === '4') tone = dtmf4Tone
        if (key === '5') tone = dtmf5Tone
        if (key === '6') tone = dtmf6Tone
        if (key === '7') tone = dtmf7Tone
        if (key === '8') tone = dtmf8Tone
        if (key === '9') tone = dtmf9Tone
        if (key === '*') tone = dtmfStarTone
        if (key === '#') tone = dtmfHashTone
        tone.play().then(() => {})
    }
})

liveServicesMiddleware.startListening({
    actionCreator: parkCall,
    effect: action => {
        callController?.parkCall(action.payload.callTraceID, action.payload.extension, () => {})
    }
})

liveServicesMiddleware.startListening({
    actionCreator: parkCallGroup,
    effect: action => {
        callController?.parkCallGroup(action.payload.callTraceID, () => {})
    }
})

liveServicesMiddleware.startListening({
    actionCreator: retrieveParkedCall,
    effect: (action, api) => {
        const user = api.getState().userSlice.user
        if (!user?.extension) return
        callController?.call(`*88${user.extension}`, user.extension, action.payload?.contact)
    }
})

liveServicesMiddleware.startListening({
    actionCreator: liveServicesAuth,
    effect: (_action, listenerApi) => {
        listenerApi.dispatch(liveServicesConnect())
    }
})
liveServicesMiddleware.startListening({
    actionCreator: updateCallDetails,
    effect: (action, listenerApi) => {
        const { callTraceID, callDetails } = action.payload
        callController?.updateCallDetails(callTraceID, callDetails)
        listenerApi.dispatch(updateDetails({ callTraceID, callDetails }))
    }
})
liveServicesMiddleware.startListening({
    actionCreator: sendSubscriptionRequest,
    effect: action => {
        callController?.sendPresenceSubscription(
            action.payload.eventGroup,
            action.payload.userIDs,
            action.payload.userGroupIDs
        )
    }
})

liveServicesMiddleware.startListening({
    actionCreator: updatePreferences,
    effect: async (action, _listenerApi) => {
        callController?.setPreferences(action.payload)

        if (typeof action.payload.audioVolume !== 'undefined') {
            streamPlayer.volume = action.payload.audioVolume
        }
        if (typeof action.payload.ringingVolume !== 'undefined') {
            ringingNotification.volume = action.payload.ringingVolume
            externalCallRingingNotification.volume = action.payload.ringingVolume
            videoRingingNotification.volume = action.payload.ringingVolume
        }
        if (typeof action.payload.ringTone !== 'undefined') {
            const currentRingAudio = callRingingAudio
            const isPlaying = !currentRingAudio.paused
            const newAudio = setCallRingingAudio(action.payload.ringTone)
            //If currently playing then swap to new audio
            if (currentRingAudio !== newAudio && isPlaying) {
                currentRingAudio.loop = false
                currentRingAudio.pause()
                newAudio.loop = true
                newAudio.play().then(() => {})
            }
        }

        await Promise.all(
            [streamPlayer].map(audio => {
                if (!audio.setSinkId || !action.payload.outputDeviceID) return undefined
                return audio.setSinkId(action.payload?.outputDeviceID || 'default')
            })
        )

        await setRingingDevice(action.payload.ringingOutputDeviceID)
    }
})

liveServicesMiddleware.startListening({
    actionCreator: updateWrtcDevices,
    effect: action => {
        callController?.updateWrtcDevices(action.payload)
    }
})

liveServicesMiddleware.startListening({
    actionCreator: sendChatMessage,
    effect: action => {
        liveServices?.send({ type: 'send_chat_message', body: action.payload })
    }
})
liveServicesMiddleware.startListening({
    actionCreator: updateChatMessage,
    effect: action => {
        liveServices?.send({ type: 'update_chat_message', body: action.payload })
    }
})
liveServicesMiddleware.startListening({
    actionCreator: sendChatReaction,
    effect: action => {
        liveServices?.send({ type: 'send_chat_reaction', body: action.payload })
    }
})

liveServicesMiddleware.startListening({
    actionCreator: liveServicesConnect,
    effect: (_action, listenerApi) => {
        const {
            liveServicesSlice: { config, token, destinationID },
            preferencesSlice
        } = listenerApi.getState() as {
            liveServicesSlice: LiveServicesState
            preferencesSlice: UserPreferences
        }

        const preferences = preferencesSlice

        if (!config) {
            console.error('Telecom Config Not Found')
            return
        }

        if (liveServices) return

        listenerApi.dispatch(updateConnection({ status: 'CONNECTING' }))

        const callControllerConfig: CallControllerConfig | undefined = config.callControllerConfig
        const liveServicesConfig: LiveServicesConfig = { ...config.liveServicesConfig }

        if (callControllerConfig && callControllerConfig.sounds) {
            const {
                ringTone,
                ringTone2,
                callWaitingTone,
                dtmf0,
                dtmf1,
                dtmf2,
                dtmf3,
                dtmf4,
                dtmf5,
                dtmf6,
                dtmf7,
                dtmf8,
                dtmf9,
                dtmfStar,
                dtmfHash,
                videoRingTone
            } = callControllerConfig.sounds

            ringingNotification = new Audio(ringTone)
            videoRingingNotification = new Audio(videoRingTone)
            externalCallRingingNotification = new Audio(ringTone2)
            callWaitingNotification = new Audio(callWaitingTone)
            dtmf0Tone = new Audio(dtmf0)
            dtmf1Tone = new Audio(dtmf1)
            dtmf2Tone = new Audio(dtmf2)
            dtmf3Tone = new Audio(dtmf3)
            dtmf4Tone = new Audio(dtmf4)
            dtmf5Tone = new Audio(dtmf5)
            dtmf6Tone = new Audio(dtmf6)
            dtmf7Tone = new Audio(dtmf7)
            dtmf8Tone = new Audio(dtmf8)
            dtmf9Tone = new Audio(dtmf9)
            dtmfStarTone = new Audio(dtmfStar)
            dtmfHashTone = new Audio(dtmfHash)
        }
        setCallRingingAudio(preferences.ringTone)

        liveServices = LiveServices.getInstance(liveServicesConfig || {})

        liveServices.on('authenticated', () =>
            listenerApi.dispatch(updateConnection({ status: 'CONNECTED' }))
        )

        liveServices.on('disconnect', () =>
            listenerApi.dispatch(updateConnection({ status: 'DISCONNECTED' }))
        )

        liveServices.authenticate(token || '', destinationID || '', null, e => {
            if (e) {
                listenerApi.dispatch(updateConnection({ status: 'FAILED' }))
                console.log('🚨 Failed to Authenticate', e)
            }

            if (liveServices && config) {
                liveServices.on('video_call_created', meeting => {
                    const { meetingID, caller, otherParticipants } = meeting

                    const {
                        userSlice: { user },
                        meetingSlice: { active }
                    } = listenerApi.getState()

                    if (!active && caller.userID !== user?.userID) {
                        videoRingingNotification.loop = true
                        videoRingingNotification.play().then(() => {})

                        listenerApi.dispatch(addMeetingRequest(meeting))
                    } else {
                        listenerApi.dispatch(
                            updateMeeting({ meetingID, otherParticipants, caller })
                        )
                    }
                })

                liveServices.on('video_call_accepted', meeting => {
                    const {
                        meetingSlice: { active }
                    } = listenerApi.getState()

                    const getAccepted = (ID: string) => {
                        return active?.otherParticipants?.find(({ userID }) => userID === ID)
                            ?.accepted
                    }

                    videoRingingNotification.loop = false
                    videoRingingNotification.pause()

                    if (meeting.token && meeting.userID && meeting.clientID) {
                        listenerApi.dispatch(
                            setUser({
                                isGuest: true,
                                clientID: meeting.clientID,
                                userID: meeting.userID,
                                token: meeting.token
                            })
                        )
                    }

                    const additionalInformation = {}

                    if (meeting?.caller) {
                        additionalInformation['caller'] = meeting.caller
                    }

                    listenerApi.dispatch(
                        updateMeeting({
                            meetingID: meeting.meetingID,
                            startTime: meeting.startTime,
                            joinInfo: meeting.JoinInfo,
                            chatInfo: meeting.ChatInfo,
                            recordings: meeting?.recordings ?? [],
                            meetingSettings: meeting.meetingSettings,
                            initialDeviceState: meeting.inMeetingDeviceState,
                            otherParticipants:
                                meeting?.otherParticipants.map(part => ({
                                    ...part,
                                    accepted: getAccepted(part.userID)
                                })) ?? [],
                            ...additionalInformation
                        })
                    )
                })
                liveServices.on('video_call_callee_accepted', meeting => {
                    const { meetingID: _meetingID, userID: acceptedUserID } = meeting

                    const {
                        meetingSlice: { active },
                        userSlice: { user }
                    } = listenerApi.getState()

                    if (!active) return
                    if (!active.otherParticipants?.length) return

                    /* The event doesn't tell us /which/ callee
                     * accepted, so we just set all of them to
                     * accepted...
                     */

                    const previous = active.otherParticipants

                    const participants = previous.map(part => ({
                        ...part,
                        accepted: acceptedUserID === part.userID ? true : part.accepted,
                        status:
                            acceptedUserID === part.userID
                                ? ParticipantVideoStatus.ACCEPTED
                                : part.status
                    }))

                    const acceptedParticipants = participants.filter(
                        participant => participant.accepted
                    )

                    const shouldAutoAccept =
                        active?.caller?.userID === user?.userID && acceptedParticipants.length === 1

                    listenerApi.dispatch(
                        updateMeeting({
                            otherParticipants: [...participants]
                        })
                    )

                    if (shouldAutoAccept) {
                        liveServices?.send({
                            type: 'accept_video_call',
                            body: {
                                meetingID: active.meetingID,
                                event: 'accept_video_call'
                            }
                        })
                    }
                })

                liveServices.on('video_call_callee_rejected', meeting => {
                    const { meetingID, userID: rejectedUserID } = meeting

                    const {
                        meetingSlice: { active }
                    } = listenerApi.getState()

                    if (!active) return
                    if (!active.otherParticipants?.length) return

                    const updatedParticipants = active.otherParticipants.map(participant => {
                        if (rejectedUserID !== participant.userID) return participant

                        return {
                            ...participant,
                            accepted: false,
                            status: ParticipantVideoStatus.REJECTED
                        }
                    })

                    listenerApi.dispatch(
                        updateMeeting({
                            meetingID,
                            otherParticipants: updatedParticipants
                        })
                    )
                })

                liveServices.on('video_call_callee_left', meeting => {
                    const { meetingID: _meetingID, userID: leftUserID } = meeting

                    const {
                        meetingSlice: { active }
                    } = listenerApi.getState()

                    if (!active) return
                    if (!active.otherParticipants?.length) return

                    const previous = active.otherParticipants

                    const participants = previous.map(participant => ({
                        ...participant,
                        status:
                            leftUserID === participant.userID
                                ? ParticipantVideoStatus.GONE
                                : participant.status
                    }))

                    listenerApi.dispatch(
                        updateMeeting({
                            otherParticipants: [...participants]
                        })
                    )
                })

                liveServices.on('video_call_attendees_added', meeting => {
                    const {
                        meetingSlice: { active }
                    } = listenerApi.getState()

                    if (!active) return
                    if (!active.otherParticipants?.length) return

                    const previous = active.otherParticipants

                    const participants = meeting.otherParticipants.map(part => {
                        const existingUser = previous.find(({ userID }) => userID === part.userID)
                        return {
                            ...part,
                            accepted: existingUser ? existingUser.accepted : false
                        }
                    })

                    listenerApi.dispatch(
                        updateMeeting({
                            otherParticipants: [...participants]
                        })
                    )
                })

                liveServices.on('video_call_guest_waiting', meeting => {
                    const {
                        meetingSlice: { active }
                    } = listenerApi.getState()

                    if (!active) return
                    if (!active.otherParticipants?.length) return

                    const previous = active.otherParticipants

                    const participants = meeting.otherParticipants.map(part => {
                        const existingUser = previous.find(({ userID }) => userID === part.userID)
                        return {
                            ...part,
                            accepted: existingUser ? existingUser.accepted : false
                        }
                    })

                    listenerApi.dispatch(
                        updateMeeting({
                            otherParticipants: [...participants]
                        })
                    )
                })

                liveServices.on('video_call_recording_started', meetingRecording => {
                    listenerApi.dispatch(
                        addRecording({
                            meetingID: meetingRecording.meetingID,
                            recordingID: meetingRecording.recordingID,
                            owner: meetingRecording.owner
                        })
                    )
                })

                liveServices.on('video_call_recording_paused', meetingRecording => {
                    listenerApi.dispatch(
                        updateRecording({
                            meetingID: meetingRecording.meetingID,
                            recordingID: meetingRecording.recordingID,
                            status: MeetingRecordingStatus.PAUSED
                        })
                    )
                })

                liveServices.on('video_call_recording_resumed', meetingRecording => {
                    listenerApi.dispatch(
                        updateRecording({
                            meetingID: meetingRecording.meetingID,
                            recordingID: meetingRecording.recordingID,
                            status: MeetingRecordingStatus.RECORDING
                        })
                    )
                })
                liveServices.on('video_call_recording_ended', meetingRecording => {
                    listenerApi.dispatch(
                        removeRecording({
                            meetingID: meetingRecording.meetingID,
                            recordingID: meetingRecording.recordingID
                        })
                    )
                })

                liveServices.on('video_call_pull_requested', pullDetails => {
                    listenerApi.dispatch(
                        acceptPullMeeting({
                            meetingID: pullDetails.meetingID,
                            toWebsocketID: pullDetails.toWebsocketID
                        })
                    )
                })

                liveServices.on('video_call_hangup', body => {
                    const {
                        userSlice: { user }
                    } = listenerApi.getState()

                    const {
                        meetingSlice: { active }
                    } = listenerApi.getState()

                    videoRingingNotification.loop = false
                    videoRingingNotification.pause()

                    if (!user || !active?.meetingID) return

                    const error =
                        body?.reason === 'You have been disconnected from the call'
                            ? 'disconnected'
                            : body?.reason

                    // If we get a hangup default error to ended
                    listenerApi.dispatch(
                        stopMeeting({ meetingID: body.meetingID, error: error ?? 'ended' })
                    )
                })

                if (appConfig.isGuest) return

                liveServices.on('video_call_unanswered', _meetingJoinInfo => {
                    listenerApi.dispatch(stopMeeting())
                })

                liveServices.on('video_call_missed', body => {
                    videoRingingNotification.loop = false
                    videoRingingNotification.pause()

                    listenerApi.dispatch(stopMeeting(body))
                })

                liveServices.on('chat_channel_message_received', message => {
                    listenerApi.dispatch(addMessage(message.payload))
                })

                if (callController || !callControllerConfig) return

                const {
                    userSlice: { user }
                } = listenerApi.getState()
                callController = new CallController(
                    liveServices,
                    callControllerConfig,
                    user?.userID,
                    user?.clientID,
                    // UAT and dev should both point to dev
                    getDeploymentEnvironment() === DeploymentEnvironments.prod ? 'prod' : 'dev',
                    'circleloop',
                    'en'
                )

                if (preferences) {
                    callController.setPreferences(preferences)
                }

                let attachedCallTraceID: string | undefined

                /* Events Setup */
                callController.attachstream = (stream, callTraceID) => {
                    if (!stream) return
                    streamPlayer.autoplay = true
                    streamPlayer.srcObject = stream
                    attachedCallTraceID = callTraceID
                }

                callController.oncallstart = calldetails => {
                    if (!callRingingAudio.paused) {
                        callRingingAudio.loop = false
                        callRingingAudio.pause()
                    }
                    if (!externalCallRingingNotification.paused) {
                        externalCallRingingNotification.loop = false
                        externalCallRingingNotification.pause()
                    }

                    listenerApi.dispatch(
                        updateCall({
                            updatedCall: { ...calldetails, startTimestamp: Date.now() },
                            callTraceID: calldetails.callTraceID
                        })
                    )
                    listenerApi.dispatch(
                        updateCallHistory({
                            updatedCall: { ...calldetails, startTimestamp: Date.now() },
                            callTraceID: calldetails.callTraceID
                        })
                    )
                    listenerApi.dispatch(focusCall({ callTraceID: calldetails.callTraceID }))
                }
                callController.onpermissionserror = () => {}

                callController.oncallend = calldetails => {
                    if (!externalCallRingingNotification.paused) {
                        externalCallRingingNotification.loop = false
                        externalCallRingingNotification.pause()
                    }

                    if (!callRingingAudio.paused) {
                        callRingingAudio.loop = false
                        callRingingAudio.pause()
                    }

                    // Don't detach the audio if we're declining an incoming call with call
                    if (attachedCallTraceID && attachedCallTraceID === calldetails.callTraceID) {
                        streamPlayer.srcObject = null
                        attachedCallTraceID = undefined
                    }
                }

                callController.onstatuschange = calldetails => {
                    listenerApi.dispatch(
                        updateCall({
                            updatedCall: calldetails,
                            callTraceID: calldetails.callTraceID
                        })
                    )
                    listenerApi.dispatch(
                        updateCallHistory({
                            updatedCall: calldetails,
                            callTraceID: calldetails.callTraceID
                        })
                    )
                }

                callController.onincomingcall = async (calldetails, callWaiting) => {
                    if (!calldetails) return

                    const { ringingOutputDeviceID } = listenerApi.getState().preferencesSlice

                    await setRingingDevice(ringingOutputDeviceID)

                    listenerApi.dispatch(addCall({ ...calldetails, duration: 0 }))
                    listenerApi.dispatch(addToCallHistory({ ...calldetails, duration: 0 }))
                    if (callWaiting) {
                        callWaitingNotification.play().then(() => {})
                    } else if (
                        calldetails.distinctiveRinging &&
                        calldetails.distinctiveRinging === 'external'
                    ) {
                        externalCallRingingNotification.loop = true
                        externalCallRingingNotification.play().then(() => {})
                    } else {
                        callRingingAudio.currentTime = 0
                        callRingingAudio.loop = true
                        callRingingAudio.play().then(() => {})
                    }
                }

                callController.oncalling = calldetails => {
                    if (!calldetails) return
                    listenerApi.dispatch(addCall({ ...calldetails, duration: 0 }))
                    listenerApi.dispatch(addToCallHistory({ ...calldetails, duration: 0 }))
                }

                callController.oncallparkevent = parkedCalls => {
                    listenerApi.dispatch(updateParkedCalls({ parkedCalls }))
                }

                callController.onrecordingmodechanged = (recordingMode: RecordingMode) => {
                    listenerApi.dispatch(updateRecordingMode(recordingMode))
                }
                callController.oncallgroupvoicemail = (voicemail: TeamVmNotificationPayload) => {
                    listenerApi.dispatch(updateCallGroupMessages(voicemail))
                }

                callController.onsubscriptionupdated = data => {
                    listenerApi.dispatch(updatePresenceSubscriptions(data.users))
                }

                callController.onpresencesubscriptionchanged = data => {
                    listenerApi.dispatch(updatePresenceStates(data.users))
                }

                callController.oncallmonitoringevent = (event: CallMonitoringEvent) => {
                    if (event.type === EventGroup.USER_CALL) {
                        // User can be either a user object or an array of users.
                        listenerApi.dispatch(updateUserCalls(forceArray(event.user)))
                    } else if (event.type === EventGroup.USER_GROUP_CALL) {
                        listenerApi.dispatch(updateGroupCalls(event.userGroups))
                    }
                }

                callController.onLSstatuschange = (status: LiveServicesStatus) => {
                    listenerApi.dispatch(updateConnection({ status }))
                }

                callController.onexternalcall = (remoteNumber: string, localNumber: string) => {
                    listenerApi.dispatch(call({ to: remoteNumber, from: localNumber }))
                }

                /* Events Setup End */
            }
        })
    }
})
