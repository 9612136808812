import { useDisclosure } from '@chakra-ui/react'
import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'

interface IAddNumberToContactDrawerContext {
    isOpen: boolean
    numberE164?: string
    onOpen: () => void
    onClose: () => void
}

const AddNumberToContactDrawerContext = createContext<IAddNumberToContactDrawerContext | null>(null)

export const AddNumberToContactDrawerProvider = ({
    children,
    num
}: PropsWithChildren<{ num?: string }>) => {
    const drawer = useDisclosure()
    const { formatToNumberE164 } = useFormatToNumberE164()
    const numberE164 = useMemo(
        () => (num ? formatToNumberE164(num) : undefined),
        [num, formatToNumberE164]
    )

    const onOpen = useCallback(() => {
        if (!num) return
        drawer.onOpen()
    }, [num, drawer])

    const onClose = useCallback(() => {
        drawer.onClose()
    }, [drawer])

    const value = useMemo(
        () => ({
            isOpen: drawer.isOpen,
            numberE164,
            onOpen,
            onClose
        }),
        [drawer.isOpen, numberE164, onOpen, onClose]
    )

    return (
        <AddNumberToContactDrawerContext.Provider value={value}>
            {children}
        </AddNumberToContactDrawerContext.Provider>
    )
}

export function useAddNumberToContactDrawer() {
    const ctx = useContext(AddNumberToContactDrawerContext)
    if (!ctx) {
        throw new Error(
            'useAddNumberToContactDrawer must be used within AddNumberToContactDrawerProvider'
        )
    }
    return ctx
}
