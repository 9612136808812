import { HStack, Spacer, VStack } from '@chakra-ui/react'
import { Avatar, Body, Button } from '@missionlabs/react'
import { type NumberE164 } from '@missionlabs/types'
import { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface AssignedToProps {
    number: NumberE164
    onAssign: () => void
    onUnassign: () => void
}

const AssignedToWrapper = ({ heading = '', children }: PropsWithChildren<{ heading: string }>) => (
    <VStack w="full" spacing="16px" align="flex-start">
        <Body variant="bold">{heading}</Body>
        <HStack spacing="8px" w="full">
            {children}
        </HStack>
    </VStack>
)

export const AssignedTo: FC<AssignedToProps> = ({ number, onAssign, onUnassign }) => {
    const { t } = useTranslation()

    if (number.user) {
        return (
            <AssignedToWrapper heading={t('Assigned to')}>
                <HStack as={Link} to={`/admin/users/${number.user?.ID}`} spacing={4}>
                    <Avatar
                        name={number.user.fullName}
                        src={number.user.photoURL ?? undefined}
                        size="md"
                        boxShadow="shallow"
                        sx={{ border: 'none' }}
                    />
                    <Body size="sm" variant="bold">
                        {number.user.fullName}
                    </Body>
                </HStack>
                <Spacer />
                <Button variant="negatory-outline" onClick={onUnassign}>
                    {t('numbers.unassign')}
                </Button>
            </AssignedToWrapper>
        )
    }

    if (number.team) {
        return (
            <AssignedToWrapper heading={t('Assigned to')}>
                <HStack as={Link} to={`/admin/teams/${number.team.ID}/edit`} spacing={4}>
                    <Avatar
                        name={number.team.name}
                        size="md"
                        boxShadow="shallow"
                        sx={{ border: 'none' }}
                    />
                    <Body size="sm" variant="bold">
                        {number.team.name}
                    </Body>
                </HStack>
                <Spacer />
                <Button variant="negatory-outline" onClick={onUnassign}>
                    {t('numbers.unassign')}
                </Button>
            </AssignedToWrapper>
        )
    }

    return (
        <AssignedToWrapper heading={t('numbers.unassigned')}>
            <Button variant="negatory-outline" onClick={onAssign}>
                {t('numbers.assign')}
            </Button>
        </AssignedToWrapper>
    )
}
