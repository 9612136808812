import { Box, Divider, Grid, useColorMode, useDisclosure } from '@chakra-ui/react'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ChatMessage } from '@missionlabs/types'
import { FC, useEffect } from 'react'
import { useLocation } from 'react-use'
import { ContentEditable } from 'shared/components/Lexical/components/ContentEditable'
import { EditorEmojiPicker } from 'shared/components/Lexical/components/EditorEmojiPicker'
import { Placeholder } from 'shared/components/Lexical/components/Placeholder'
import { EditMessageNode } from 'shared/components/Lexical/nodes/EditMessageNode'
import { EmojiNode } from 'shared/components/Lexical/nodes/EmojiNode'
import { ForwardMessageNode } from 'shared/components/Lexical/nodes/ForwardedNode'
import { ReplyNode } from 'shared/components/Lexical/nodes/ReplyNode'
import { UploadNode } from 'shared/components/Lexical/nodes/UploadNode'
import { EditMessagePlugin } from 'shared/components/Lexical/plugins/EditMessagePlugin'
import { EmojiPickerPlugin } from 'shared/components/Lexical/plugins/EmojiPickerPlugin'
import { EmojiPlugin } from 'shared/components/Lexical/plugins/EmojiPlugin'
import {
    dispatchForwardEvent,
    ForwardPlugin
} from 'shared/components/Lexical/plugins/ForwardPlugin'
import {
    MarkdownShortcutNodes,
    MarkdownShortcutPlugin
} from 'shared/components/Lexical/plugins/MarkdownShortcutPlugin'
import { ReplyPlugin } from 'shared/components/Lexical/plugins/ReplyPlugin'
import {
    SendMessagePlugin,
    SendMessagePluginProps
} from 'shared/components/Lexical/plugins/SendMessagePlugin'
import { ToolbarPlugin } from 'shared/components/Lexical/plugins/ToolbarPlugin'
import { ToolbarPluginMinimal } from 'shared/components/Lexical/plugins/ToolbarPluginMinimal'
import { UploadPlugin } from 'shared/components/Lexical/plugins/UploadPlugin'
import theme from 'shared/components/Lexical/theme'

const config: InitialConfigType = {
    namespace: 'chatbox',
    nodes: [
        ...MarkdownShortcutNodes,
        EmojiNode,
        ReplyNode,
        EditMessageNode,
        ForwardMessageNode,
        UploadNode
    ],
    theme: theme,
    onError(error) {
        throw error
    }
}

// Parts of a union type to alter the type of channelID and userID based on the value of minimal.
interface MinimalChatBoxProps {
    minimal: true
    channelID: undefined
    userID: undefined
}
interface FullChatBoxProps {
    minimal?: false
    channelID: string
    userID: string
}

export type ChatBoxProps = SendMessagePluginProps & (MinimalChatBoxProps | FullChatBoxProps)

export const Chatbox: FC<ChatBoxProps> = ({
    onSendMessage,
    onEditMessage,
    minimal,
    userID,
    channelID
}) => {
    const { colorMode } = useColorMode()
    const emojiPicker = useDisclosure()

    const forwardMessage: ChatMessage = useLocation().state?.usr?.forwardMessage

    useEffect(() => {
        if (forwardMessage) {
            dispatchForwardEvent(
                forwardMessage.ID,
                forwardMessage.user.name,
                forwardMessage.contentHTML
            )
        }
    }, [forwardMessage])

    return (
        <LexicalComposer initialConfig={config}>
            <Grid
                templateColumns={minimal ? '1fr min-content' : '1fr'}
                position="relative"
                w="full"
                minH={minimal ? '0' : '94px'}
                alignItems={minimal ? 'center' : 'start'}
                px="16px"
                bg={`${colorMode}.tones.ghostWhite`}
                border="1px solid"
                borderColor={`${colorMode}.tones.periwinkle`}
                borderRadius="8px"
            >
                <Box>
                    <EditorEmojiPicker disclosure={emojiPicker} />
                    <ReplyPlugin />
                    <UploadPlugin />
                    <EditMessagePlugin />
                    <ForwardPlugin />
                    <RichTextPlugin
                        contentEditable={
                            <ContentEditable py="12px" data-testid="chatbox-content-editable" />
                        }
                        placeholder={<Placeholder />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                </Box>
                {minimal ? (
                    <ToolbarPluginMinimal />
                ) : (
                    <Box>
                        <Divider />
                        <ToolbarPlugin channelID={channelID} userID={userID} />
                    </Box>
                )}
            </Grid>
            <AutoFocusPlugin />
            <ClearEditorPlugin />
            <EmojiPlugin />
            <EmojiPickerPlugin disclosure={emojiPicker} />
            <HistoryPlugin />
            <MarkdownShortcutPlugin />
            <SendMessagePlugin
                onSendMessage={onSendMessage}
                onEditMessage={onEditMessage}
                channelID={channelID}
            />
        </LexicalComposer>
    )
}
