import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type JabraState = {
    deviceName?: string
}

const initialState: JabraState = {}

export const jabraSlice = createSlice({
    name: 'jabraSlice',
    initialState,
    reducers: {
        init() {},
        webHIDConsent() {},
        devicePaired(_state, action: PayloadAction<string | undefined>) {
            return { deviceName: action.payload }
        }
    }
})

export type JabraSliceRootState = {
    jabraSlice: ReturnType<typeof jabraSlice.getInitialState>
}

export const selectJabraSlice = (state: JabraSliceRootState) => {
    return state.jabraSlice
}

export const { init, webHIDConsent, devicePaired } = jabraSlice.actions
