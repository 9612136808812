import { VStack } from '@chakra-ui/react'
import { CodeVerificationBody, NumberVerificationBody } from '@missionlabs/api'
import { FC, useEffect, useState } from 'react'
import { useAuthenticatedUser } from 'shared/hooks/useAuthenticatedUser'

import VerifyCodeForm from './VerifyCodeForm'
import VerifyNumberForm from './VerifyNumberForm'

interface MirrorNumberFormProps {
    onClose: () => void
}

const MirrorNumberForm: FC<MirrorNumberFormProps> = ({ onClose }) => {
    const [formState, setFormState] = useState<
        Partial<NumberVerificationBody & CodeVerificationBody>
    >({
        userID: '',
        clientID: '',
        verifyMethod: 'call',
        verifyNumber: ''
    })

    const { data: user } = useAuthenticatedUser()

    const userID = user?.ID,
        clientID = user?.clientID

    useEffect(() => {
        setFormState(prev => ({ ...prev, userID, clientID }))
    }, [userID, clientID])

    return (
        <VStack h="full" align="stretch" justify="space-between" display="flex">
            {formState.ID ? (
                <VerifyCodeForm
                    onClose={onClose}
                    defaultValues={formState}
                    setFormState={setFormState}
                />
            ) : (
                <VerifyNumberForm
                    onClose={onClose}
                    defaultValues={{
                        userID: user?.ID,
                        clientID: user?.clientID,
                        verifyMethod: 'call',
                        verifyNumber: ''
                    }}
                    setFormState={setFormState}
                />
            )}
        </VStack>
    )
}

export default MirrorNumberForm
