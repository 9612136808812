import { GridItem, useColorMode, VStack } from '@chakra-ui/react'
import { Heading } from '@missionlabs/react'
import { FC, PropsWithChildren } from 'react'

export interface SetupSectionProps {
    title: string
}

export const SetupSection: FC<PropsWithChildren<SetupSectionProps>> = ({ title, children }) => {
    const { colorMode } = useColorMode()
    return (
        <GridItem>
            <Heading size="h3" variant="bold" py="22px" sx={{ color: `${colorMode}.tones.white` }}>
                {title}
            </Heading>
            <VStack spacing="16px">{children}</VStack>
        </GridItem>
    )
}
