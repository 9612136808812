import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const AdjustIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AdjustIconLight',
    viewBox: '0 0 16 20',
    path: (
        <path
            d="M3.875 0C4.1875 0 4.5 0.3125 4.5 0.625V3.20312C5.90625 3.51562 7 4.76562 7 6.25C7 7.77344 5.90625 9.02344 4.5 9.33594V19.375C4.5 19.7266 4.1875 20 3.875 20C3.52344 20 3.25 19.7266 3.25 19.375V9.33594C1.80469 9.02344 0.75 7.77344 0.75 6.25C0.75 4.76562 1.80469 3.51562 3.25 3.20312V0.625C3.25 0.3125 3.52344 0 3.875 0ZM3.875 8.125C4.89062 8.125 5.75 7.30469 5.75 6.25C5.75 5.23438 4.89062 4.375 3.875 4.375C2.82031 4.375 2 5.23438 2 6.25C2 7.30469 2.82031 8.125 3.875 8.125ZM15.25 13.75C15.25 15.2734 14.1562 16.5234 12.75 16.8359V19.375C12.75 19.7266 12.4375 20 12.125 20C11.7734 20 11.5 19.7266 11.5 19.375V16.8359C10.0547 16.5234 9 15.2734 9 13.75C9 12.2656 10.0547 10.9766 11.5 10.7031V0.625C11.5 0.3125 11.7734 0 12.125 0C12.4375 0 12.75 0.3125 12.75 0.625V10.7031C14.1562 10.9766 15.25 12.2656 15.25 13.75ZM12.125 15.625C13.1406 15.625 14 14.8047 14 13.75C14 12.7344 13.1406 11.875 12.125 11.875C11.0703 11.875 10.25 12.7344 10.25 13.75C10.25 14.8047 11.0703 15.625 12.125 15.625Z"
            fill="currentColor"
        />
    )
})

export const AdjustIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AdjustIconRegular',
    viewBox: '0 0 16 20',
    path: (
        <path
            d="M3.875 0C4.38281 0 4.8125 0.429688 4.8125 0.9375V3.28125C6.0625 3.67188 7 4.88281 7 6.25C7 7.65625 6.0625 8.86719 4.8125 9.25781V19.0625C4.8125 19.6094 4.38281 20 3.875 20C3.32812 20 2.9375 19.6094 2.9375 19.0625V9.25781C1.64844 8.86719 0.75 7.65625 0.75 6.25C0.75 4.88281 1.64844 3.67188 2.9375 3.28125V0.9375C2.9375 0.429688 3.32812 0 3.875 0ZM3.875 7.5C4.53906 7.5 5.125 6.95312 5.125 6.25C5.125 5.58594 4.53906 5 3.875 5C3.17188 5 2.625 5.58594 2.625 6.25C2.625 6.95312 3.17188 7.5 3.875 7.5ZM15.25 13.75C15.25 15.1562 14.3125 16.3672 13.0625 16.7578V19.0625C13.0625 19.6094 12.6328 20 12.125 20C11.5781 20 11.1875 19.6094 11.1875 19.0625V16.7578C9.89844 16.3672 9 15.1562 9 13.75C9 12.3828 9.89844 11.1719 11.1875 10.7812V0.9375C11.1875 0.429688 11.5781 0 12.125 0C12.6328 0 13.0625 0.429688 13.0625 0.9375V10.7812C14.3125 11.1719 15.25 12.3828 15.25 13.75ZM12.125 15C12.7891 15 13.375 14.4531 13.375 13.75C13.375 13.0859 12.7891 12.5 12.125 12.5C11.4219 12.5 10.875 13.0859 10.875 13.75C10.875 14.4531 11.4219 15 12.125 15Z"
            fill="currentColor"
        />
    )
})

export const AdjustIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AdjustIconSolid',
    viewBox: '0 0 16 20',
    path: (
        <path
            d="M3.875 0C4.53906 0 5.125 0.585938 5.125 1.25V3.39844C6.21875 3.86719 7 5 7 6.25C7 7.53906 6.21875 8.63281 5.125 9.14062V18.75C5.125 19.4531 4.53906 20 3.875 20C3.17188 20 2.625 19.4531 2.625 18.75V9.14062C1.49219 8.63281 0.75 7.53906 0.75 6.25C0.75 5 1.49219 3.86719 2.625 3.39844V1.25C2.625 0.585938 3.17188 0 3.875 0ZM3.875 7.5C4.53906 7.5 5.125 6.95312 5.125 6.25C5.125 5.58594 4.53906 5 3.875 5C3.17188 5 2.625 5.58594 2.625 6.25C2.625 6.95312 3.17188 7.5 3.875 7.5ZM15.25 13.75C15.25 15.0391 14.4688 16.1328 13.375 16.6406V18.75C13.375 19.4531 12.7891 20 12.125 20C11.4219 20 10.875 19.4531 10.875 18.75V16.6406C9.74219 16.1328 9 15.0391 9 13.75C9 12.5 9.74219 11.3672 10.875 10.8984V1.25C10.875 0.585938 11.4219 0 12.125 0C12.7891 0 13.375 0.585938 13.375 1.25V10.8984C14.4688 11.3672 15.25 12.5 15.25 13.75ZM12.125 15C12.7891 15 13.375 14.4531 13.375 13.75C13.375 13.0859 12.7891 12.5 12.125 12.5C11.4219 12.5 10.875 13.0859 10.875 13.75C10.875 14.4531 11.4219 15 12.125 15Z"
            fill="currentColor"
        />
    )
})
