import { ComponentStyleConfig } from '@chakra-ui/react'

const disabledStyles = {
    bg: 'light.tones.stormGrey',
    opacity: 0.5
}

export const ButtonStyles: ComponentStyleConfig = {
    baseStyle: {
        borderRadius: '4px',
        color: 'light.tones.white',
        fontFamily: 'body',
        textAlign: 'center',
        _disabled: disabledStyles
    },
    defaultProps: {
        variant: 'primary'
    },
    variants: {
        creationary: {
            bg: 'light.alerts.aqua',
            _hover: {
                bg: 'light.alerts.algae',
                _disabled: disabledStyles
            }
        },
        primary: {
            bg: 'light.primary.gamma',
            _hover: {
                bg: 'light.primary.gammaHover',
                _disabled: disabledStyles
            }
        },
        secondary: {
            bg: 'light.tones.stormGrey',
            _hover: {
                bg: 'light.tones.navy',
                _disabled: disabledStyles
            }
        },
        negatory: {
            bg: 'light.alerts.rose',
            _hover: {
                bg: 'light.alerts.jazberryJam',
                _disabled: disabledStyles
            }
        },
        'negatory-outline': {
            bg: 'transparent',
            border: '1px solid',
            borderColor: 'light.alerts.rose',
            color: 'light.alerts.rose',
            _hover: {
                bg: 'light.alerts.jazberryJam',
                border: 'none',
                color: 'light.tones.white',
                _disabled: {
                    bg: 'transparent',
                    border: '1px solid',
                    borderColor: 'light.tones.periwinkle',
                    color: 'light.tones.stormGrey'
                }
            },
            _disabled: {
                bg: 'transparent',
                borderColor: 'light.tones.periwinkle',
                color: 'light.tones.stormGrey'
            }
        },
        'negatory-light': {
            bg: 'light.alerts.rose24',
            border: 'none',
            color: 'light.alerts.rose',
            _hover: {
                bg: 'light.alerts.rose',
                border: 'none',
                color: 'light.tones.white',
                _disabled: {
                    bg: 'transparent',
                    border: '1px solid',
                    borderColor: 'light.tones.periwinkle',
                    color: 'light.tones.stormGrey'
                }
            },
            _disabled: {
                bg: 'transparent',
                borderColor: 'light.tones.periwinkle',
                color: 'light.tones.stormGrey'
            }
        },
        tertiary: {
            bg: 'light.tones.whiteLilac',
            border: '1px solid',
            borderColor: 'light.tones.periwinkle',
            color: 'light.tones.navy',
            _hover: {
                bg: 'light.tones.periwinkle',
                color: 'light.tones.midnightExpress',
                _disabled: {
                    bg: 'light.tones.whiteLilac',
                    border: '1px solid',
                    borderColor: 'light.tones.periwinkle',
                    color: 'light.tones.stormGrey',
                    opacity: 1
                }
            },
            _disabled: {
                bg: 'light.tones.whiteLilac',
                border: '1px solid',
                borderColor: 'light.tones.periwinkle',
                color: 'light.tones.stormGrey',
                opacity: 1
            }
        },
        ghost: {
            bg: 'transparent',
            color: 'light.tones.navy',
            _active: {
                bg: 'transparent',
                color: 'light.primary.gamma',
                _hover: {
                    bg: 'light.tones.whiteLilac'
                }
            },
            _hover: {
                bg: 'light.tones.whiteLilac',
                _disabled: {
                    bg: 'transparent',
                    color: 'light.tones.navy',
                    opacity: 1
                }
            },
            _disabled: {
                bg: 'transparent',
                color: 'light.tones.navy',
                opacity: 1
            }
        },
        transparent: {
            bg: 'transparent',
            color: 'light.tones.navy',
            _hover: {
                bg: 'transparent',
                color: 'light.tones.midnightExpress',
                _disabled: {
                    bg: 'transparent',
                    color: 'light.tones.navy',
                    opacity: 0.7
                }
            },
            _disabled: {
                bg: 'transparent',
                color: 'light.tones.navy',
                opacity: 0.7
            }
        },
        link: {
            color: 'light.primary.gamma',
            textDecoration: 'none',
            fontWeight: 800,
            _hover: {
                textDecoration: 'none',
                color: 'light.primary.gammaHover'
            }
        },
        'link-underline': {
            color: 'light.primary.gamma',
            textDecoration: 'underline',
            fontWeight: 800,
            verticalAlign: 'baseline',
            lineHeight: 'normal',
            height: 'auto',
            padding: 0,
            _hover: {
                textDecoration: 'underline',
                color: 'light.primary.gammaHover'
            }
        }
    },
    sizes: {
        sm: { py: '7px', px: '12px', fontWeight: 500, fontSize: '12px', lineHeight: '18px' },
        md: { py: '9.5px', px: '16px', fontWeight: 700, fontSize: '14px', lineHeight: '21px' },
        lg: { py: '13.5px', px: '16px', fontWeight: 700, fontSize: '14px', lineHeight: '21px' },
        xl: { py: '20px', px: '24px', fontWeight: 800, fontSize: '16px', lineHeight: '24px' }
    }
}
