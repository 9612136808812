import { Divider, useDisclosure, VStack } from '@chakra-ui/react'
import { selectAuthenticatedUser, selectInCurrentData, useSelector } from '@missionlabs/api'
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    FormProvider,
    Input,
    Player,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { AudioClip } from '@missionlabs/types'
import { downloadURL } from '@missionlabs/utils'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { FC, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetUserAudioClipsQuery, useUpdateUserAudioClipMutation } from 'shared/store'

const noop = () => {}

export const EditRecordingDrawer: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { id = '' } = useParams()

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    const formRef = useRef<HTMLFormElement>(null)

    const user = useSelector(selectAuthenticatedUser)

    const selectAudioClip = useMemo(() => {
        return selectInCurrentData<AudioClip[], AudioClip>(data =>
            data?.find(item => item.ID === id)
        )
    }, [id])
    const { audioClip } = useGetUserAudioClipsQuery(user?.userID ?? skipToken, {
        selectFromResult: result => ({ ...result, audioClip: selectAudioClip(result) })
    })
    const [updateClip] = useUpdateUserAudioClipMutation()

    const methods = useYupForm(yup.object({ label: yup.string().required() }), {
        values: { label: audioClip?.label }
    })
    const {
        formState: { isDirty, isSubmitting }
    } = methods

    function handleDelete() {
        navigate(`/settings/recordings/${id}/delete`)
    }

    async function handleSubmit(data: { label: string | undefined }) {
        try {
            if (!audioClip || !user) return
            await updateClip({ ID: audioClip.ID, userID: user.userID, entry: data }).unwrap()
            return onClose()
        } catch (e) {
            console.error(e)
        }
    }

    function handleTransition() {
        navigate('/settings/recordings')
    }

    return (
        <Drawer
            size="lg"
            isOpen={isOpen}
            onClose={!isSubmitting ? onClose : noop}
            onCloseComplete={handleTransition}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>{t('recordings.edit')}</DrawerHeader>
                <DrawerBody>
                    <FormProvider {...methods}>
                        <VStack
                            ref={formRef}
                            as="form"
                            p="24px"
                            spacing="24px"
                            align="stretch"
                            divider={<Divider />}
                            onSubmit={methods.handleSubmit(handleSubmit)}
                        >
                            <ValidatedInput name="label" label={t('recordings.recordingName')}>
                                <Input placeholder="Enter name" />
                            </ValidatedInput>
                            <Player
                                url={audioClip?.URL ?? ''}
                                onDownload={() => {
                                    if (audioClip?.URL) downloadURL(audioClip?.URL)
                                }}
                            />
                        </VStack>
                    </FormProvider>
                </DrawerBody>
                <DrawerFooter p="32px 24px 24px" gap="16px">
                    <Button
                        w="full"
                        size="lg"
                        variant="negatory-outline"
                        isDisabled={isSubmitting}
                        onClick={handleDelete}
                    >
                        {t('Remove')}
                    </Button>
                    <Button
                        w="full"
                        size="lg"
                        variant="secondary"
                        isDisabled={isSubmitting}
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        w="full"
                        size="lg"
                        variant="creationary"
                        isDisabled={!isDirty}
                        isLoading={isSubmitting}
                        onClick={() => formRef.current?.requestSubmit()}
                    >
                        {t('Save')}
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}
