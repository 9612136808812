import isElectron from 'is-electron'
import EventEmitter from 'wolfy87-eventemitter'

import Logger from './Logger'
import worker_script from './WebWorker'

export type WebWorkerMessage = {
    data: {
        type: WebWorkerMessageTypes
        pulse?: number
    }
}
export type WebWorkerMessageTypes = 'init' | 'startPing' | 'stopPing' | 'pulse'

export type LiveServicesConfig = {
    url: string
    connectToHost: boolean
    swoptions?: any
    sw?: any
    appPublicKey?: string
    bandwidthLimit?: number
    preferCodec?: string
    disableDSCP?: boolean
    enableRecording?: boolean
    goog?: any
    audio?: { deviceId: { ideal: string } } | {}
    notifications?: boolean
    session?: any
    timeout?: number
    pulse?: number
    retry?: number
    attempts?: number
    webSocketID?: string
    callTimer?: number
    keepAlive?: number
    appAPIVersion?: any
}
export type LiveServicesStatus =
    | 'CONNECTED'
    | 'CONNECTING'
    | 'DISCONNECTED'
    | 'RECONNECTING'
    | 'FAILED'
    | 'NOT_CONNECTED'

export interface LiveServicesWebSocketMessage {
    type: string
    body: Record<string, any>
    txID?: string
}

export interface LiveServicesResponseMessage<T> {
    type: 'error' | 'ack'
    body: T
    txID: string
}

export type LiveServicesMessageCallback<T = any> = (
    response: LiveServicesResponseMessage<T>
) => void | Promise<void>

export class LiveServices extends EventEmitter {
    config: LiveServicesConfig
    timeout: number
    pulse: number
    retry: number
    retries: number
    authenticated: boolean

    optionalSubscriptions: string[]
    subscribed: string[]
    callbacks: {
        [key: string]: {
            callback: (...args: any) => void | Promise<void>
            timeout?: ReturnType<typeof setTimeout>
        }
    } | null
    maxattempts: number
    webSocketID: string | null
    webWorker?: Worker
    ws?: WebSocket
    destinationID?: string
    token?: string
    host?: string
    keepAlive?: ReturnType<typeof setInterval>
    reconnecting?: ReturnType<typeof setTimeout>
    authenticating?: boolean
    id: string
    status: LiveServicesStatus
    reconnectionAttempts: number
    static _instance: LiveServices

    // events
    onstatuschange = (_status: LiveServicesStatus) => {}

    constructor(config: LiveServicesConfig) {
        super()

        this.id = this.generateID()
        this.config = config || {}
        this.timeout = config.timeout || 10000
        this.pulse = 25000
        this.retry = config.retry || 250
        this.retries = 0
        this.authenticated = false
        this.optionalSubscriptions = ['message', 'activity']
        this.subscribed = []
        this.callbacks = {}
        this.maxattempts = config.attempts || 10
        this.webSocketID = config.webSocketID ?? null
        this.status = 'NOT_CONNECTED'
        this.reconnectionAttempts = 0
        this._changeStatus('NOT_CONNECTED')

        if (!this.config.url) {
            throw new Error('No websocket url provided')
        }
        this.on('error', () => this.startReconnecting())
        this.on('authenticated', () => {
            this._initWebWorker(() => this.startKeepAlive(this.webWorker))
        })
        window.addEventListener('offline', () => this.connectionDropped())
        window.addEventListener('online', () => this.startReconnecting())
    }

    _createWebSocket(callback) {
        const url = this.config.url
        let checkForOpen
        try {
            Logger.log('info', `🚀 Websocket Launching to: ${url}`)

            // Reset websocket & ID when creating a new one
            if (this.webSocketID && this.ws) {
                this.webSocketID = null
                this.ws?.close()
            }

            this.ws = new WebSocket(url)

            checkForOpen = setTimeout(() => {
                // If the websocket is still connecting after 5 seconds, terminate it and callback with an error
                console.log('Failed to connect after 5 seconds')
                callback('Failed to connect after 5 seconds')
            }, 5000)
        } catch (ex) {
            console.error(ex)
        }

        if (!this.ws) throw Error('No Websocket Created')

        this.ws.onmessage = args => this._onmessage(args)
        this.ws.onclose = _e => {
            Logger.log('info', `🔽 Websocket Closing - ID:${this.webSocketID}`)
            clearTimeout(checkForOpen)
            this.terminateWebSocket()
        }

        this.ws.onerror = args => {
            Logger.log('error', `🚨 Websocket Error - ID:${this.webSocketID}`, args)
            Logger.log(
                'error',
                `🚨 Websocket State: ${this.ws?.readyState} - ID:${this.webSocketID}`
            )
            this._onerror(args)
        }

        this.ws.onopen = () => {
            Logger.log('info', `🎉 Websocket Connected to: ${url}`)
            clearTimeout(checkForOpen)
            callback()
        }
    }

    isOpen() {
        if (!this.ws) return false
        return this.ws.readyState <= 1
    }

    isConnecting() {
        if (!this.ws) return false
        return this.ws.readyState === 0
    }

    public static getInstance(config: any) {
        if (LiveServices._instance) return LiveServices._instance
        LiveServices._instance = new LiveServices(config)
        return LiveServices._instance
    }

    authenticate(token: string, destinationID: string, subscriptions: any, callback) {
        this.token = token
        this.destinationID = destinationID

        if (typeof subscriptions === 'function') {
            callback = subscriptions
            subscriptions = []
        }
        // If websocket is not open, create a new one
        if (!this.isOpen()) {
            this._createWebSocket(err => {
                if (err) {
                    // If error return callback (reconnect interval will try again to create a socket)
                    Logger.log('error', 'Error creating websocket', err)
                    return callback(err)
                } else {
                    // If no error, authenticate websocket
                    Logger.log('info', `🎉 Websocket Created Successfully`)
                    clearInterval(this.reconnecting)
                    this.reconnecting = undefined
                    return this._auth(token, destinationID, subscriptions, callback)
                }
            })
        } else {
            // If websocket is open, authenticate websocket
            this._auth(token, destinationID, subscriptions, callback)
        }
    }

    _auth(token: string, destinationID: string, subscriptions: any[] | string, callback: Function) {
        if (this.authenticating) return
        this.authenticating = true

        if (typeof subscriptions === 'string') {
            subscriptions = [subscriptions]
        }

        const message = {
            type: 'auth',
            body: {
                token: token,
                subscriptions: subscriptions,
                destinationID,
                webSocketID: this.webSocketID ?? ''
            }
        }

        this.send(message, response => {
            this.authenticating = false
            const body = response.body
            if (response.type === 'error') {
                if (body && body.retryable === false) {
                    console.error(`❌ Auth Fail: not retryable, logging out.`)
                    this.close()
                }

                if (body?.retryable) {
                    //Try and auth again
                    this.retries++
                    console.log('error', 'retry websocket auth, attempt ' + this.retries)
                    if (this.retries > 10) return callback(body)
                    return setTimeout(() => {
                        this._auth(token, destinationID, subscriptions, callback)
                    }, this.retry)
                } else if (body.code === 404) {
                    this.webSocketID = null
                    return this._auth(token, destinationID, subscriptions, callback)
                } else {
                    return callback(response.body)
                }
            }

            this.retries = 0
            this.authenticated = true

            if (!this.webSocketID) this.webSocketID = response.body.webSocketID
            console.log(`🎉 Websocket Authenticated - ID: ${this.webSocketID}`)

            this.destinationID = destinationID
            this.host = response.body.host
            this.emit('authenticated')
            callback()
        })
    }

    override on(key: any, callBack: Function): any {
        const name = key
        if (!name) return
        this.subscribeTo(name)
        return super.on(name, callBack)
    }

    subscribeTo(subscriptions: string | string[]) {
        if (!Array.isArray(subscriptions)) {
            subscriptions = [subscriptions]
        }
        let update = false
        subscriptions.forEach((sub: string) => {
            if (
                this.subscribed.indexOf(sub) === -1 &&
                this.optionalSubscriptions.indexOf(sub) > -1
            ) {
                this.subscribed.push(sub)
                update = true
            }
        })
        if (!update) return
        this.send({ type: 'update', body: { subscriptions: this.subscribed } })
    }

    _onmessage(event) {
        const data = JSON.parse(event.data)
        //Is there a callback?
        const txID = data.txID
        if (this.callbacks?.[txID]) {
            this.callbacks[txID].callback(data)
            clearTimeout(this.callbacks[txID].timeout)
            delete this.callbacks[txID]
        }
        this.emit(data.type, data.body, txID)
    }

    send(message: LiveServicesWebSocketMessage, callback?: LiveServicesMessageCallback) {
        let txID: string

        if (message.body?.transaction) {
            txID = message.body.transaction
        } else {
            txID = this.generateID()
        }
        if (!message.txID) message.txID = txID
        if (callback && this.callbacks) {
            this.callbacks[message.txID] = {
                callback,
                timeout: setTimeout(() => {
                    this.sendError(txID)
                }, this.timeout)
            }
        }
        if (!message.body) message.body = {}
        if (!this.isOpen()) return this.sendError(message.txID)
        if (this.isConnecting()) {
            //If still connecting set timeout to send when connected
            Logger.log('info', 'Still connecting to waiting to send message', message)
            return setTimeout(() => {
                this.send(message, callback)
            }, 100)
        }

        if (this.ws) {
            this.ws.send(JSON.stringify(message))
        }
    }

    sendError(txID: string) {
        if (this.callbacks?.[txID]) {
            Logger.log('info', 'send error with txID ' + txID)

            this.callbacks[txID].callback({
                type: 'error',
                body: { message: 'timeout' }
            })
            delete this.callbacks[txID]
        }
    }

    _keepAlive() {
        if (!this.isOpen()) {
            this.stopKeepAlive(this.webWorker)
            return
        }
        Logger.log('info', `Ping -> (${new Date().toUTCString()})`)
        this.send(
            { type: 'pulse', body: { destinationID: this.destinationID } },
            async response => {
                if (response.type === 'error') {
                    console.log(
                        'No Internet Connection or WS has timed out, Error:',
                        response.body.message
                    )
                    await this.onInternetTimeout()
                }
                Logger.log('info', `<- Pong (${new Date().toUTCString()})`)
            }
        )
    }

    async checkInternetConnectionViaFetch() {
        let hostname: string, url: string

        try {
            hostname = window.location.hostname
            if (hostname === 'localhost') hostname = 'dev-webapp.viazeta.com'
            url = `https://${hostname}/?rand=${Math.random()}`

            if (isElectron() && hostname !== 'localhost') {
                return true
            }

            await fetch(url, {
                method: 'HEAD',
                mode: 'no-cors',
                headers: { 'Access-Control-Allow-Origin': '*' }
            })
            return true
        } catch (error) {
            return false
        }
    }

    _onclose() {
        console.log('❌ Websocket Closed Successfully')
        clearInterval(this.reconnecting)
        this.reconnecting = undefined
        for (const txID in this.callbacks) {
            this.sendError(txID)
        }
        this.emit('closed')

        if (this.authenticated) this.startReconnecting()
        else this._changeStatus('FAILED')
    }

    async reconnect() {
        if (!this.token) {
            console.log('Unable to Reconnect: No Token')
            return
        }

        console.log(`🤖 Reconnection Attempt ${this.reconnectionAttempts}`)
        if (!navigator.onLine) {
            console.log('navigator online', navigator.onLine)
            return
        }

        const internetConnection = await this.checkInternetConnectionViaFetch()
        if (!internetConnection) {
            console.log('No internet connection while trying to reconnect')
            return
        }

        this.reconnectionAttempts++
        if (this.reconnectionAttempts > this.maxattempts) {
            console.log(`❌ Max Reconnect Attempts Reach`)
            clearInterval(this.reconnecting)
            this._changeStatus('FAILED')
            this.reconnecting = undefined
            return
        }
        const token = this.getToken() ?? this.token
        this.authenticate(token, this.destinationID!, this.subscribed, err => {
            if (!err) {
                clearInterval(this.reconnecting)
                this.reconnectionAttempts = 0
                this.reconnecting = undefined
                this._changeStatus('CONNECTED')
                this.emit('reconnected')
                return console.log('🎉 Websocket Reconnected Successfully')
            }
        })
    }

    close() {
        console.log(`🔽 Closing Websocket - ID:${this.webSocketID}`)
        this.authenticated = false
        this.authenticating = false

        // Need to remove all listeners
        window.removeEventListener('offline', () => this.connectionDropped())
        window.removeEventListener('online', () => this._changeStatus('RECONNECTING'))
        this.startReconnecting = () => {}
        this.connectionDropped = () => {}

        this.reconnecting = undefined
        clearInterval(this.reconnecting)
        this._changeStatus('FAILED')

        // Close the socket and remove all listeners
        this.ws?.close()
        this.stopKeepAlive(this.webWorker)
        this.killWebSocketListeners(this.ws)
        this.terminateWebWorker()

        this.ws = undefined
    }
    startReconnecting() {
        if (this.isOpen() || this.isConnecting()) {
            this.emit('reconnected')
            this._changeStatus('CONNECTED')
            return
        }
        if (this.reconnecting) {
            console.log('Reconnecting', this.reconnecting)
            this._changeStatus('RECONNECTING')
            return
        }
        if (!this.authenticated) {
            console.log('this is not authenticated', this.authenticated)
            return
        }

        // Wait a little, just incase the browser has just restarted.
        setTimeout(() => {
            console.log(`🤖 Starting Reconnecting, Navigator Online: ${navigator.onLine}`)
            this.reconnect()
            this.reconnecting = setInterval(() => {
                this.reconnect()
            }, 6000)
            this._changeStatus('RECONNECTING')
            this.emit('reconnecting')
        }, 500)
    }

    connectionDropped() {
        console.log(`Connection Dropped, Navigator Online: ${navigator.onLine}`)
        if (this.status === 'DISCONNECTED' || this.status === 'RECONNECTING') return
        clearInterval(this.reconnecting)
        this.reconnecting = undefined
        this._changeStatus('DISCONNECTED')
        this.emit('disconnect')
    }

    _changeStatus(status: LiveServicesStatus) {
        this.status = status
        this.onstatuschange(status)
    }
    _onerror(_error) {
        this.emit('error')
    }

    getURL() {
        return this.config.url
    }
    getToken() {
        return this.token
    }
    getDestinationID() {
        return this.destinationID
    }
    generateID() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1)
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
    }

    terminateWebSocket() {
        console.log(`⏬ Terminating Websocket - ID:${this.webSocketID}`)
        if (!this.ws) return
        this.stopKeepAlive(this.webWorker)

        this.ws.onopen = () => {}
        this.ws.onmessage = () => {}
        this.ws.onclose = () => {}
        this.ws.onerror = () => {}

        this.ws = undefined
        this._onclose()
    }
    killWebSocketListeners(ws: WebSocket | undefined) {
        if (!ws) return
        ws.onopen = () => {}
        ws.onmessage = () => {}
        ws.onclose = () => {}
        ws.onerror = () => {}
    }

    async onInternetTimeout() {
        if (this.reconnecting) return
        const internetConnection = await this.checkInternetConnectionViaFetch()
        if (internetConnection && navigator.onLine) {
            return this.terminateWebSocket()
        }
        return this.connectionDropped()
    }

    /* WEBWORKER LOGIC */
    _initWebWorker(callback: () => void) {
        this.webWorker = new Worker(worker_script)

        // Here we can send init config to the web worker
        this.webWorker.postMessage({ type: 'init', pulse: this.pulse })

        // event handlers registered
        this.webWorker.onmessage = (message: WebWorkerMessage) =>
            this.handleWebWorkerMessage(message)
        this.webWorker.onerror = (error: ErrorEvent) => {
            // TODO: Handle error
            console.log('WebWorker Error', error)
        }

        callback()
    }

    startKeepAlive(webWorker: Worker | undefined) {
        if (!webWorker) return console.log('Can not start ping pong, no web worker')
        webWorker.postMessage({ type: 'startPing' })
    }
    stopKeepAlive(webWorker: Worker | undefined) {
        if (!webWorker) return console.log('Can not start ping pong, no web worker')
        webWorker.postMessage({ type: 'stopPing' })
    }
    handleWebWorkerMessage(message: WebWorkerMessage) {
        if (message.data.type === 'pulse') return this._keepAlive()
    }
    terminateWebWorker() {
        if (!this.webWorker) return

        this.webWorker.onmessage = () => {}
        this.webWorker.onerror = () => {}
        this.webWorker.onmessageerror = () => {}

        this.webWorker.terminate()
        this.webWorker = undefined
    }
}
