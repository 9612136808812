import { DarkMode, MenuItem } from '@chakra-ui/react'
import { IconButton, Menu, MenuButton, MenuList } from '@missionlabs/react'
import { MoreHorizontalIcon } from '@missionlabs/react/circleloop'
import { DirectoryEntry, HuntGroup, IVRMenu } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetUserNumbers } from 'shared/hooks/useGetUserNumbers'

interface TransferNumberOverflowProps {
    contact: DirectoryEntry | HuntGroup | IVRMenu
    onTransfer: (value: string) => void
}

export const TransferNumberOverflow: FC<TransferNumberOverflowProps> = ({
    contact,
    onTransfer
}) => {
    const { t } = useTranslation()

    const { hasNumbers, mobile, home, work, other, extension } = useGetUserNumbers(contact)

    if (!hasNumbers) return null

    return (
        <DarkMode>
            <Menu variant="compact">
                <IconButton
                    as={MenuButton}
                    icon={<MoreHorizontalIcon boxSize="16px" color="#fff" />}
                    variant="transparent"
                    aria-label="transfer"
                />
                <MenuList>
                    {extension.ID && (
                        <MenuItem background="#fff" onClick={() => onTransfer(extension.ID!)}>
                            {t('numbers.extension', { number: extension.number })}
                        </MenuItem>
                    )}
                    {work.map(number => (
                        <MenuItem background="#fff" key={number} onClick={() => onTransfer(number)}>
                            {t('numbers.work', { number })}
                        </MenuItem>
                    ))}
                    {home.map(number => (
                        <MenuItem background="#fff" key={number} onClick={() => onTransfer(number)}>
                            {t('numbers.home', { number })}
                        </MenuItem>
                    ))}
                    {mobile.map(number => (
                        <MenuItem background="#fff" key={number} onClick={() => onTransfer(number)}>
                            {t('numbers.mobile', { number })}
                        </MenuItem>
                    ))}
                    {other.map(number => (
                        <MenuItem background="#fff" key={number} onClick={() => onTransfer(number)}>
                            {t('numbers.other', { number })}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </DarkMode>
    )
}
