import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { KBarAnimator, KBarPortal, KBarPositioner } from 'kbar'

import { useActivitiesActions } from './actions/activities'
import { useAdminActions } from './actions/admin'
import { useAnalyticsActions } from './actions/analytics'
import { useContactsActions, useContactsSearchActions } from './actions/contacts'
import { useHelpActions } from './actions/help'
import { useSettingsActions } from './actions/settings'
import { KBarSearch } from './KBarSearch'
import { SearchResults } from './SearchResults'

const animatorStyles = {
    maxWidth: '600px',
    width: '100%',
    background: 'rgb(252 252 252)',
    color: 'rgb(28 28 29)',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0px 6px 20px rgb(0 0 0 / 20%)'
}

export function CommandMenu() {
    const user = useSelector(selectAuthenticatedUser)

    useActivitiesActions()
    useContactsActions()
    useContactsSearchActions()
    useAnalyticsActions()
    useSettingsActions()
    useAdminActions()
    useHelpActions()

    /** We don't want to show the command menu to users if they're not logged in */
    if (!user) return null

    return (
        <KBarPortal>
            <KBarPositioner>
                <KBarAnimator style={animatorStyles}>
                    <KBarSearch />
                    <SearchResults />
                </KBarAnimator>
            </KBarPositioner>
        </KBarPortal>
    )
}
