import { useCallback, useRef } from 'react'

type Fn = (...args: any[]) => void
type PromiseFn = (...args: any[]) => Promise<any>

/**
 * Creates a debounced callback from the function provided.
 * @param fn The function to be called
 * @param delay The number of milliseconds to wait before triggering the function.
 */
export const useDebouncedFunction = <T extends Fn>(fn: T, delay: number) => {
    // Use a ref to store the timeout between renders
    // and prevent changes to it from causing re-renders
    const timeout = useRef<NodeJS.Timeout | undefined>()

    return useCallback(
        (...args: Parameters<typeof fn>) => {
            const later = () => {
                clearTimeout(timeout.current)
                fn(...args)
            }

            clearTimeout(timeout.current)
            timeout.current = setTimeout(later, delay)
        },
        [fn, delay]
    )
}

/**
 * Creates a debounced callback from the function provided.
 * @param fn The function to be called
 * @param delay The number of milliseconds to wait before triggering the function.
 */
export const usePromisedDebouncedFunction = <T extends PromiseFn>(fn: T, delay: number) => {
    // Use a ref to store the timeout between renders
    // and prevent changes to it from causing re-renders
    const timeout = useRef<NodeJS.Timeout | undefined>()

    return useCallback(
        (...args: Parameters<typeof fn>) => {
            return new Promise((resolve, reject) => {
                const later = () => {
                    clearTimeout(timeout.current)
                    const result = fn(...args)
                    if (!result) reject()

                    if ('then' in result) {
                        result.then(resolve)
                    } else {
                        resolve(result)
                    }
                }

                clearTimeout(timeout.current)
                timeout.current = setTimeout(later, delay)
            }) as ReturnType<typeof fn>
        },
        [fn, delay]
    )
}
