import { StackProps, useMultiStyleConfig, VStack } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

import { StylesProvider } from './context'

export interface NavbarProps extends StackProps {}

export const Navbar = ({ children, ...props }: PropsWithChildren<NavbarProps>): JSX.Element => {
    const styles = useMultiStyleConfig('Navbar')

    return (
        <VStack as="nav" sx={styles.bar} {...props} h="100%" align="center" justify="space-between">
            <StylesProvider value={styles}>{children}</StylesProvider>
        </VStack>
    )
}
