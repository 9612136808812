import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const AddCircleIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddCircleIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M9.375 13.75V10.625H6.25C5.89844 10.625 5.625 10.3516 5.625 10C5.625 9.6875 5.89844 9.375 6.25 9.375H9.375V6.25C9.375 5.9375 9.64844 5.625 10 5.625C10.3125 5.625 10.625 5.9375 10.625 6.25V9.375H13.75C14.0625 9.375 14.375 9.6875 14.375 10C14.375 10.3516 14.0625 10.625 13.75 10.625H10.625V13.75C10.625 14.1016 10.3125 14.375 10 14.375C9.64844 14.375 9.375 14.1016 9.375 13.75ZM20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10ZM10 1.25C5.15625 1.25 1.25 5.19531 1.25 10C1.25 14.8438 5.15625 18.75 10 18.75C14.8047 18.75 18.75 14.8438 18.75 10C18.75 5.19531 14.8047 1.25 10 1.25Z"
            fill="currentColor"
        />
    )
})

export const AddCircleIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddCircleIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M9.0625 13.4375V10.9375H6.5625C6.01562 10.9375 5.625 10.5469 5.625 10C5.625 9.49219 6.01562 9.0625 6.5625 9.0625H9.0625V6.5625C9.0625 6.05469 9.45312 5.625 10 5.625C10.5078 5.625 10.9375 6.05469 10.9375 6.5625V9.0625H13.4375C13.9453 9.0625 14.375 9.49219 14.375 10C14.375 10.5469 13.9453 10.9375 13.4375 10.9375H10.9375V13.4375C10.9375 13.9844 10.5078 14.375 10 14.375C9.45312 14.375 9.0625 13.9844 9.0625 13.4375ZM20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10ZM10 1.875C5.50781 1.875 1.875 5.54688 1.875 10C1.875 14.4922 5.50781 18.125 10 18.125C14.4531 18.125 18.125 14.4922 18.125 10C18.125 5.54688 14.4531 1.875 10 1.875Z"
            fill="currentColor"
        />
    )
})

export const AddCircleIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddCircleIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10ZM10 14.375C10.5078 14.375 10.9375 13.9844 10.9375 13.4375V10.9375H13.4375C13.9453 10.9375 14.375 10.5469 14.375 10C14.375 9.49219 13.9453 9.0625 13.4375 9.0625H10.9375V6.5625C10.9375 6.05469 10.5078 5.625 10 5.625C9.45312 5.625 9.0625 6.05469 9.0625 6.5625V9.0625H6.5625C6.01562 9.0625 5.625 9.49219 5.625 10C5.625 10.5469 6.01562 10.9375 6.5625 10.9375H9.0625V13.4375C9.0625 13.9844 9.45312 14.375 10 14.375Z"
            fill="currentColor"
        />
    )
})
