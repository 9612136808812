import { DecoratorNode, EditorConfig, LexicalEditor, LexicalNode, NodeKey } from 'lexical'
import { ReactNode } from 'react'

import { ForwardMessage } from '../components/ForwardMessage'

export class ForwardMessageNode extends DecoratorNode<any> {
    __ID: string
    __from: string
    __message: string

    static getType(): string {
        return 'forward'
    }

    static clone(node: ForwardMessageNode): ForwardMessageNode {
        return new ForwardMessageNode(node.__ID, node.__from, node.__message, node.__key)
    }

    constructor(ID: string, from: string, message: string, key?: NodeKey) {
        super(key)
        this.__ID = ID
        this.__from = from
        this.__message = message
    }

    createDOM(_config: EditorConfig): HTMLElement {
        const div = document.createElement('div')
        div.style.display = 'contents'
        return div
    }

    updateDOM(): false {
        return false
    }

    setURL(url: string): void {
        const writable = this.getWritable()
        writable.__url = url
    }

    decorate(_editor: LexicalEditor): ReactNode {
        return <ForwardMessage from={this.__from} message={this.__message} />
    }

    isKeyboardSelectable(): boolean {
        return false
    }
}

export function $createForwardMessageNode(
    ID: string,
    from: string,
    message: string
): ForwardMessageNode {
    return new ForwardMessageNode(ID, from, message)
}

export function $isForwardMessageNode(node: LexicalNode | null): boolean {
    return node instanceof ForwardMessageNode
}
