import { metrics } from '@missionlabs/logger'
import { createInstallationIdPayload } from '@missionlabs/utils'
import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/dist/query'

import { AuthenticatedUser, AuthSliceRootState, UserSliceRootState } from '../slices'
import { customCreateApi } from './customCreateAPI'

interface ConfigResponse {
    clientID: string
    userID: string
    loggingEnabled: boolean
}

interface URLResponse {
    uid: string
    s3URL: string
    fields: object
}

type UploadLogsArgs = URLResponse & { logLines: any[]; name: string; version: string }

export const buildLogsAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'logsAPI',
        tagTypes: ['Config'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getLogsConfig: builder.query<ConfigResponse, Partial<AuthenticatedUser>>({
                query: ({ clientID, userID }) => `/clients/${clientID}/users/${userID}/config`,
                transformResponse: (response: { data: ConfigResponse }) => response.data,
                providesTags: ['Config']
            }),
            getLogsURL: builder.query<URLResponse, Record<'brand' | 'device', string>>({
                // @ts-ignore rtk expects {data: res.data} but we just return res.data for brevity
                async queryFn(args, { getState }, _, baseQuery) {
                    const state = getState() as AuthSliceRootState & UserSliceRootState
                    const { environment: env, country } = state.authSlice
                    const user = state.userSlice.user
                    if (!user) {
                        return {
                            error: {
                                status: 401,
                                statusText: 'Unauthorized Request',
                                data: 'User is undefined'
                            }
                        }
                    }

                    const params = new URLSearchParams({
                        ...args,
                        env,
                        country,
                        clientid: user.clientID,
                        userid: user.userID
                    })
                    const res = await baseQuery(`/get-signed-s3-url?${params.toString()}`)
                    if (res.error) return { error: res.error }
                    return res.data as URLResponse
                }
            }),
            uploadLogs: builder.mutation<any, UploadLogsArgs>({
                async queryFn({ logLines, fields, name, s3URL, uid, version }, api, extraOptions) {
                    const logs = [...logLines]
                    const baseQuery = fetchBaseQuery()
                    const { user } = (api.getState() as UserSliceRootState).userSlice

                    // append user metadata to start of logs
                    const data = createInstallationIdPayload(user?.userID ?? '', name, version)
                    const userMetadata = {
                        loglevel: 'info',
                        logLine: JSON.stringify(
                            new metrics.user(data.metadata, navigator.language)
                        ),
                        logGroup: 'user_metadata',
                        timeStamp: Math.round(Date.now() / 1000)
                    }
                    logs.unshift(userMetadata)

                    const formData = new FormData()
                    for (const key in fields) {
                        if (key === 'key') continue
                        formData.append(key, fields[key])
                    }
                    formData.append('Content-Type', 'json')
                    formData.append('key', `raw/${uid}.json`)
                    formData.append('file', new File([JSON.stringify(logs)], `${uid}.json`))

                    const args = { url: s3URL, method: 'POST', body: formData }
                    const res = await baseQuery(args, api, extraOptions)
                    if (res.error) return { error: res.error }
                    return { data: res.data }
                }
            })
        })
    })

    return { api, ...api }
}
