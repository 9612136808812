export const ONE_HOUR = 3600000
export const MS_ONE_DAY = 86400000

/**
 * Format seconds into HH:MM:SS or MM:SS
 * @param ms The total number of seconds to be formatted.
 * @example 3600000 -> "01:00:00"
 */
export function formatDuration(ms?: number) {
    if (!ms) return '00:00'

    // ISO string example: 2023-06-01T13:39:50.698Z
    //                                ^  ^    ^
    // Characters 11 - 19: 13:39:50
    // Characters 14 - 19:    39:50

    // Between 14 and 19 characters returns MM:SS
    if (ms < ONE_HOUR) return new Date(ms).toISOString().substring(14, 19)

    // Between 11 and 19 returns HH:MM:SS
    return new Date(ms).toISOString().substring(11, 19)
}
