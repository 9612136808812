import {
    selectAuthenticatedUser,
    selectLiveServicesStatus,
    selectPresenceSubscription,
    sendSubscriptionRequest,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { useDebouncedFunction } from '@missionlabs/react'
import { numericSort } from '@missionlabs/utils'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useFlags } from 'flagsmith/react'
import { useCallback, useEffect, useMemo } from 'react'
import { addLastKnownStatus, selectPresenceSlice } from 'shared/slices/presenceSlice'
import { useGetUserPresenceQuery } from 'shared/store'

export const usePresenceSubscriptions = () => {
    const flags = useFlags(['presence'])
    const dispatch = useDispatch()

    const presence = useSelector(selectPresenceSlice)
    const user = useSelector(selectAuthenticatedUser)
    const lsStatus = useSelector(selectLiveServicesStatus)

    const subscriptions = useSelector(selectPresenceSubscription)

    const userID = (flags.presence.enabled && user?.userID) || skipToken
    const { data: userPresence } = useGetUserPresenceQuery(userID, {
        pollingInterval: 60_000
    })

    useEffect(() => {
        if (!userPresence || !user?.userID) return
        const status = {
            userID: user.userID,
            status: userPresence
        }
        dispatch(addLastKnownStatus([status]))
    }, [userPresence, dispatch, user?.userID])

    const presenceIDs = useMemo(() => {
        const recentIDs = [...presence.presenceIDs]
            .sort((a, b) => {
                if (a.persist || b.persist) return 1
                return numericSort(b.timestamp, a.timestamp)
            })
            .map(({ userID }) => userID)

        if (user?.userID) {
            recentIDs.unshift(user.userID)
        }

        recentIDs.length = 30

        return new Set(recentIDs)
    }, [presence.presenceIDs, user?.userID])

    const _registerPresenceSubscriptions = useCallback(
        (userIDs: string[]) => {
            if (!flags.presence.enabled || lsStatus !== 'CONNECTED') return
            dispatch(sendSubscriptionRequest({ userIDs, eventGroup: 'presence_changed' }))
        },
        [dispatch, flags.presence, lsStatus]
    )

    const registerPresenceSubscriptions = useDebouncedFunction(_registerPresenceSubscriptions, 500)

    // Re-register subscriptions when IDs change.
    useEffect(() => {
        registerPresenceSubscriptions(Array.from(presenceIDs))
    }, [presenceIDs, registerPresenceSubscriptions])

    useEffect(() => {
        const statuses =
            subscriptions?.map(subscription => ({
                userID: subscription.userID,
                status: subscription.detailedState,
                timestamp: Date.now()
            })) ?? []

        dispatch(addLastKnownStatus(statuses))
    }, [dispatch, subscriptions])

    // // Re-try subscriptions until they have loaded. This fixes the timing issue steming from requesting subscriptions before websockets have been initialised.
    useEffect(() => {
        if (lsStatus === 'CONNECTED') registerPresenceSubscriptions(Array.from(presenceIDs))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lsStatus])
}
