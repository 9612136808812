import { buttonAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle } = createMultiStyleConfigHelpers([...buttonAnatomy.keys])

const defaultKeyStyles = {
    width: '78px',
    height: '78px',
    borderRadius: '4px',
    textAlign: 'center',
    cursor: 'pointer'
}

export const DiallerKeyStyles: ComponentStyleConfig = {
    baseStyle: definePartsStyle(({ colorMode }) => ({
        container: {
            ...defaultKeyStyles,
            bg: `${colorMode}.tones.midnightExpress`,
            border: '1px solid',
            borderColor: `${colorMode}.tones.stormGrey`,
            _hover: { borderColor: `${colorMode}.tones.periwinkle`, bg: `${colorMode}.tones.navy` },
            _focus: { borderColor: `${colorMode}.tones.whiteLilac` }
        },
        label: {
            color: `${colorMode}.tones.periwinkle`,
            fontFamily: 'heading',
            fontSize: '24px',
            lineHeight: '168%',
            letterSpacing: '-0.48px',
            fontWeight: 400
        },
        callKey: {
            ...defaultKeyStyles,
            bg: `${colorMode}.alerts.aqua`,
            _hover: { bg: `${colorMode}.alerts.turquoise` },
            _focus: { bg: `${colorMode}.alerts.turquoise` },
            _disabled: {
                bg: `${colorMode}.tones.stormGrey`,
                _hover: { bg: `${colorMode}.tones.stormGrey` }
            }
        }
    })),
    sizes: {
        sm: definePartsStyle({
            container: {
                width: '64px',
                height: '64px'
            },
            callKey: {
                width: '64px',
                height: '64px'
            }
        })
    }
}

export const DialPadStyles: ComponentStyleConfig = {
    baseStyle: definePartsStyle({
        container: {
            width: 'full',
            maxW: 'fit-content',
            gap: '8px',
            gridTemplate: 'repeat(4, 1fr) / repeat(4, 1fr)'
        }
    }),
    sizes: {
        sm: definePartsStyle({
            container: { width: '288px' }
        })
    }
}
