import { Box, Flex, HStack, useMultiStyleConfig } from '@chakra-ui/react'
import { Body, IconButton } from 'atoms'
import {
    AddCallIconRegular,
    HangupIconSolid,
    MessageIconSolid,
    MissedCallIconRegular,
    NotificationOffIconSolid,
    PhoneIconSolid,
    TransferIconSolid,
    VideoIconSolid,
    VoicemailIconSolid,
    XIconRegular
} from 'atoms/Icons/zeta'
import { ContactDetail, ContactDetailProps } from 'molecules'
import { cloneElement, FC, MouseEventHandler, ReactNode } from 'react'

export interface NotificationProps extends Omit<ContactDetailProps, 'variant'> {
    variant: 'call' | 'chat' | 'meetings' | 'reminder' | 'voicemail'
    header: string
    messageBox?: ReactNode
    onAnswer?: MouseEventHandler<HTMLButtonElement>
    onHangup?: MouseEventHandler<HTMLButtonElement>
    onMessage?: MouseEventHandler<HTMLButtonElement>
    onTransfer?: MouseEventHandler<HTMLButtonElement>
    onVoicemail?: MouseEventHandler<HTMLButtonElement>
    onDismiss?: MouseEventHandler<HTMLButtonElement>
    onDisableNotifications?: MouseEventHandler<HTMLButtonElement>
}

const headerIcons = {
    call: <AddCallIconRegular boxSize="12px" />,
    chat: <MessageIconSolid boxSize="12px" />,
    meetings: <VideoIconSolid boxSize="16px" />,
    reminder: <MissedCallIconRegular boxSize="16px" />,
    voicemail: <VoicemailIconSolid boxSize="16px" />
}

export const Notification: FC<NotificationProps> = ({
    variant,
    header,
    messageBox,
    onDismiss,
    onAnswer,
    onHangup,
    onMessage,
    onTransfer,
    onVoicemail,
    onDisableNotifications,
    onClick,
    ...props
}) => {
    const styles = useMultiStyleConfig('Notification', { variant })
    return (
        <Box
            onClick={onClick}
            cursor={onClick ? 'pointer' : undefined}
            w="375px"
            sx={{ ...styles.container, ...(props.sx ?? {}) }}
        >
            <HStack as="header" sx={{ ...styles.header, '--webkit-drag-region': 'drag' }}>
                <IconButton
                    variant="transparent"
                    icon={<XIconRegular />}
                    onClick={onDismiss}
                    aria-label="Close"
                />
                <HStack gap="4px">
                    {/* @ts-ignore */}
                    {cloneElement(headerIcons[variant], { ...styles.header.icon })}
                    <Body size="sm" sx={{ color: 'inherit' }}>
                        {header}
                    </Body>
                </HStack>
            </HStack>
            <Flex as="main" sx={{ ...styles.main }} wrap="wrap">
                <ContactDetail {...props} sx={{ flex: 1 }} variant="whiteLilac" />
                <Flex sx={{ ...styles.actions }}>
                    {onHangup && (
                        <IconButton
                            variant="negatory"
                            size="sm"
                            icon={<HangupIconSolid />}
                            isRounded
                            onClick={onHangup}
                            aria-label="Decline"
                        />
                    )}
                    {onAnswer && (
                        <IconButton
                            variant="creationary"
                            size="sm"
                            icon={variant === 'meetings' ? <VideoIconSolid /> : <PhoneIconSolid />}
                            isRounded
                            onClick={onAnswer}
                            aria-label="Answer"
                        />
                    )}
                    {onMessage && (
                        <IconButton
                            variant="tertiary"
                            size="sm"
                            icon={<MessageIconSolid />}
                            isRounded
                            onClick={onMessage}
                            aria-label="Message"
                        />
                    )}
                    {onTransfer && (
                        <IconButton
                            variant="tertiary"
                            size="sm"
                            icon={<TransferIconSolid />}
                            isRounded
                            onClick={onTransfer}
                            aria-label="Transfer"
                        />
                    )}
                    {onVoicemail && (
                        <IconButton
                            variant="tertiary"
                            size="sm"
                            icon={<VoicemailIconSolid />}
                            isRounded
                            onClick={onVoicemail}
                            aria-label="Voicemail"
                        />
                    )}
                    {onDisableNotifications && (
                        <IconButton
                            variant="tertiary"
                            size="sm"
                            icon={<NotificationOffIconSolid />}
                            isRounded
                            onClick={onDisableNotifications}
                            aria-label="Voicemail"
                        />
                    )}
                </Flex>
                {messageBox && <div style={{ width: '100%' }}>{messageBox}</div>}
            </Flex>
        </Box>
    )
}
