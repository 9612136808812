import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const Filter2Icon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'Filter2Icon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <g clipPath="url(#clip0_645_73)">
                <path
                    d="M5.18605 6.41556C7.22587 9.03568 10.2416 12.9131 10.2416 12.9131V17.3093C10.2416 18.2764 11.0329 19.0677 12.0001 19.0677C12.9673 19.0677 13.7586 18.2764 13.7586 17.3093V12.9131C13.7586 12.9131 16.7743 9.03568 18.8142 6.41556C19.2626 5.83527 18.8493 5 18.1108 5H5.88064C5.15088 5 4.73764 5.83527 5.18605 6.41556Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_645_73">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </g>
    )
})

export const FilterCircleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'FilterCircleIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18 9C18 9.55228 17.5523 10 17 10H7C6.44772 10 6 9.55228 6 9C6 8.44771 6.44772 8 7 8H17C17.5523 8 18 8.44771 18 9ZM15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H9C8.44771 11 8 11.4477 8 12C8 12.5523 8.44771 13 9 13H15ZM14 15C14 15.5523 13.5523 16 13 16H11C10.4477 16 10 15.5523 10 15C10 14.4477 10.4477 14 11 14H13C13.5523 14 14 14.4477 14 15Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_177"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18 9C18 9.55229 17.5523 10 17 10H7C6.44772 10 6 9.55229 6 9C6 8.44771 6.44772 8 7 8H17C17.5523 8 18 8.44771 18 9ZM15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H9C8.44771 11 8 11.4477 8 12C8 12.5523 8.44771 13 9 13H15ZM14 15C14 15.5523 13.5523 16 13 16H11C10.4477 16 10 15.5523 10 15C10 14.4477 10.4477 14 11 14H13C13.5523 14 14 14.4477 14 15Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_177)"></g>
        </g>
    )
})

export const FilterIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'FilterIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M13.3333 15C14.0697 15 14.6667 15.5969 14.6667 16.3333C14.6667 17.0697 14.0697 17.6667 13.3333 17.6667H10.6667C9.93027 17.6667 9.33333 17.0697 9.33333 16.3333C9.33333 15.5969 9.93027 15 10.6667 15H13.3333ZM16 11C16.7364 11 17.3333 11.5969 17.3333 12.3333C17.3333 13.0697 16.7364 13.6667 16 13.6667H8C7.26363 13.6667 6.66667 13.0697 6.66667 12.3333C6.66667 11.5969 7.26363 11 8 11H16ZM18.6667 7C19.4031 7 20 7.59696 20 8.33333C20 9.06971 19.4031 9.66667 18.6667 9.66667H5.33333C4.59696 9.66667 4 9.06971 4 8.33333C4 7.59696 4.59696 7 5.33333 7H18.6667Z"
            fill="currentColor"
        />
    )
})
