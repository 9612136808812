import { Button } from '@missionlabs/react'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetReportsQuery } from 'shared/store'

import { AnalyticsReportsTable } from './AnalyticsReportsTable/AnalyticsReportsTable'
import { CreateReportSidebar } from './CreateReportSidebar'

const Reports: FC = () => {
    const { t } = useTranslation()
    const [shouldPoll, setShouldPoll] = useState<boolean>(false)

    const { setCreate } = useAnalytics().report

    const {
        data: reports = [],
        isLoading,
        isFetching
    } = useGetReportsQuery(undefined, {
        // programmatically set polling based on whether we have incomplete reports or not
        pollingInterval: shouldPoll ? 1000 : undefined
    })

    useEffect(() => {
        const hasUnconfirmedReports = reports.some(item => item.status === 'unconfirmed')
        setShouldPoll(hasUnconfirmedReports)

        return () => {
            setShouldPoll(false)
        }
    }, [reports])

    return (
        <SettingsPage
            title={t('analytics.reports.title')}
            subtitle={t('analytics.reportsSubtitle')}
            isLoading={isLoading || isFetching}
            actions={
                <>
                    <Button onClick={() => setCreate(true)} variant="creationary">
                        {t('analytics.reports.create')}
                    </Button>
                </>
            }
            contentProps={{ maxW: 'unset' }}
        >
            <AnalyticsReportsTable isLoading={isLoading || isFetching} reportsData={reports} />

            <CreateReportSidebar />
        </SettingsPage>
    )
}

export default Reports
