import { Center, useColorMode, VStack } from '@chakra-ui/react'
import { Heading } from '@missionlabs/react'
import { InboxEmptyIconRegular } from '@missionlabs/react/zeta'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {}

const ChatListEmpty: React.FC<Props> = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    return (
        <Center w="full" h="full" bg={`${colorMode}.tones.ghostWhite`}>
            <VStack spacing="4px">
                <InboxEmptyIconRegular boxSize="48px" p="7px" />
                <VStack spacing={0}>
                    <Heading size="h3">{t('chat.nothingToSee')}</Heading>
                    <Heading
                        size="h3"
                        sx={{
                            fontSize: '16px',
                            color: `${colorMode}.tones.stormGrey`
                        }}
                    >
                        {t('chat.noItems')}
                    </Heading>
                </VStack>
            </VStack>
        </Center>
    )
}

export default ChatListEmpty
