import { Dispatch, SetStateAction, useEffect, useState } from 'react'

/**
 * `useSyncedState` behaves the same as `useState`
 * except that it gets updated when the state from props is updated.
 */
export function useSyncedState<T>(state: T): [T, Dispatch<SetStateAction<T>>] {
    const [value, setValue] = useState<T>(state)

    useEffect(() => setValue(state), [state])

    return [value, setValue]
}
