import { Table } from '@missionlabs/react'
import { PaymentMethod } from '@missionlabs/types'
import { titleCase } from '@missionlabs/utils'
import { createColumnHelper } from '@tanstack/react-table'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const columnHelper = createColumnHelper<PaymentMethod>()

export interface PaymentMethodsTableProps {
    paymentMethods: PaymentMethod[]
}

export const PaymentMethodsTable: FC<PaymentMethodsTableProps> = ({ paymentMethods }) => {
    const { t } = useTranslation()

    const cards = paymentMethods.filter(payment => !!payment.card)
    const directDebits = paymentMethods.filter(payment => !!payment.directDebit)

    const cardColumns = [
        columnHelper.accessor('card.type', {
            cell: info => titleCase(info.getValue()),
            header: t('Type')
        }),
        columnHelper.accessor('card.maskedNumber', {
            cell: info => info.getValue(),
            header: t('Card')
        })
    ]

    const ddColumns = [
        columnHelper.accessor('directDebit.name_on_account', {
            cell: info => info.getValue(),
            header: t('directDebit.nameOnAccount')
        }),
        columnHelper.accessor('directDebit.bank_name', {
            cell: info => info.getValue(),
            header: t('directDebit.bankName')
        }),
        columnHelper.accessor('directDebit.last4', {
            cell: info => '******' + info.getValue(),
            header: t('directDebit.last4AccountNumber')
        })
    ]

    return (
        <>
            {!!cards.length && <Table data={cards} columns={cardColumns} />}
            {!!directDebits.length && <Table data={directDebits} columns={ddColumns} />}
        </>
    )
}
