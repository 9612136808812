import { HStack, VStack } from '@chakra-ui/react'
import {
    Button,
    FormProvider,
    Scrollable,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useYupForm,
    yup
} from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { APP_BRAND, AppConfig } from 'shared/utils/env'

import { AddUserToTeams } from './AddUserToTeams'
import { UserDetailsForm } from './UserDetailsForm'

type AddUserFormProps = {
    onSubmit: (data: AddUserFormFields) => Promise<void>
    onClose: () => void
    isLoading: boolean
}

export type AddUserFormFields = {
    firstName: string
    lastName: string
    email: string
    type: 'admin' | 'standard'
    subscriptionType: 'standard' | 'pro'
    teams: { ID: string; name: string; object: 'team' }[]
}

export const AddUserForm: FC<AddUserFormProps> = ({ onSubmit, onClose, isLoading }) => {
    const { t } = useTranslation()

    const userSchema = yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        email: yup.string().email().required(),
        type: yup.string().required(),
        subscriptionType: yup.string(),
        teams: yup.array()
    })

    const methods = useYupForm<AddUserFormFields>(userSchema, {
        defaultValues: {
            teams: [],
            type: 'standard',
            subscriptionType: AppConfig.brand === APP_BRAND.PHONELINEPLUS ? undefined : 'pro'
        }
    })

    const {
        formState: { isDirty }
    } = methods

    return (
        <FormProvider {...methods}>
            <VStack
                as="form"
                h="full"
                align="stretch"
                spacing="16px"
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <Tabs size="md" h="full" lazyBehavior="unmount" isLazy isFitted>
                    <TabList mx="24px">
                        <Tab>{t('Details')}</Tab>
                        <Tab>{t('admin.users.assignTeams')}</Tab>
                    </TabList>
                    <Scrollable h="calc(100dvh - 215px)" w="full">
                        <TabPanels>
                            <TabPanel p={0} h="full">
                                <UserDetailsForm />
                            </TabPanel>
                            <TabPanel p={0} h="full">
                                <AddUserToTeams />
                            </TabPanel>
                        </TabPanels>
                    </Scrollable>
                </Tabs>

                <HStack w="full" spacing="16px" align="inherit" padding="24px">
                    <Button
                        w="full"
                        size="lg"
                        variant="secondary"
                        isDisabled={isLoading}
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        type="submit"
                        w="full"
                        size="lg"
                        variant="creationary"
                        isDisabled={!isDirty}
                        isLoading={isLoading}
                    >
                        {t('Create')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}
