import { BoxProps, Center } from '@chakra-ui/react'
import { Children, cloneElement, FC, isValidElement } from 'react'

import { useStyles } from './context'

export interface NavbarItemProps extends BoxProps {
    isDisabled?: boolean
    noStyles?: boolean
}

export const NavbarItem: FC<NavbarItemProps> = ({
    isDisabled = false,
    noStyles = false,
    children,
    ...props
}) => {
    const styles = useStyles()

    return (
        <Center
            sx={styles.itemContainer}
            {...props}
            // role="button"
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
            onClick={isDisabled ? undefined : props.onClick}
        >
            {Children.map(children, child => {
                if (!isValidElement(child)) return null
                if (noStyles) return cloneElement(child)
                return cloneElement(child, { sx: styles.item, ...child.props })
            })}
        </Center>
    )
}
