import { useColorMode } from '@chakra-ui/react'
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps
} from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { SpeedDialForm } from './SpeedDialForm'

export interface AddNumberDrawerProps extends Omit<DrawerProps, 'children'> {}

export const AddNumberDrawer: FC<AddNumberDrawerProps> = ({ isOpen, onClose, ...props }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { colorMode } = useColorMode()

    function handleTransition() {
        return navigate(-1)
    }

    return (
        <Drawer
            {...props}
            size="sm"
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={handleTransition}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>{t('directories.speedDial.createTitle')}</DrawerHeader>
                <DrawerBody bgColor={`${colorMode}.tones.ghostWhite`}>
                    <SpeedDialForm onClose={onClose}></SpeedDialForm>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
