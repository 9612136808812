import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { emptyPageAnatomy } from '../anatomy'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(emptyPageAnatomy.keys)

export const EmptyPageStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => ({
        logo: { boxSize: '40px', color: `${colorMode}.tones.navy` },
        title: { color: `${colorMode}.tones.periwinkle` },
        subtitle: { color: `${colorMode}.tones.periwinkle` },
        link: {}
    })
})
