import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ViewNotVisibleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ViewNotVisibleIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.75965 18.7536C8.5 19.5712 10.2929 20.027 12.0107 19.9981C15.8947 20.0639 20.1931 17.6197 23.3772 14.1155C24.2062 13.1952 24.2062 11.8011 23.3794 10.8832C21.9527 9.31471 20.3012 7.96085 18.5647 6.94864L17.4626 8.05076C19.1834 8.99794 20.8457 10.3268 22.2673 11.8898C22.5777 12.2344 22.5777 12.7619 22.2648 13.1092C19.3458 16.3217 15.4172 18.5556 12.0107 18.4983C10.6852 18.5204 9.28554 18.2007 7.89867 17.6146L6.75965 18.7536ZM9.3647 16.1486C10.1051 16.6838 11.0147 16.9992 11.9981 16.9992C14.4834 16.9992 16.4981 14.9845 16.4981 12.4958C16.4976 11.5136 16.1823 10.605 15.6478 9.86551L14.566 10.9473C14.84 11.3999 14.9978 11.931 14.9981 12.4992L14.993 12.6755C14.9018 14.2503 13.5958 15.4992 11.9981 15.4992C11.4301 15.4992 10.899 15.3413 10.4462 15.0671L9.3647 16.1486ZM9.12417 13.3625L7.97305 14.5136C7.66915 13.9075 7.4981 13.2233 7.4981 12.4992C7.4981 10.0144 9.51208 7.99984 11.9967 7.99918C12.7214 7.99879 13.4062 8.16979 14.0128 8.47388L12.8616 9.62509C12.5879 9.54303 12.2977 9.49902 11.9973 9.49918C10.3408 9.49962 8.99809 10.8426 8.99809 12.4992C8.99809 12.7993 9.04216 13.0891 9.12417 13.3625ZM15.3802 7.10645C14.2334 6.69874 13.0928 6.48282 12.0107 6.50107H11.9854C8.60633 6.44407 4.65609 8.67112 1.73436 11.8871C1.42152 12.2344 1.42152 12.7619 1.73237 13.107C2.97757 14.4785 4.40656 15.6715 5.90238 16.5843L4.81124 17.6754C3.30231 16.712 1.87449 15.495 0.619834 14.1131C-0.206968 13.1952 -0.206968 11.8011 0.621975 10.8809C3.80565 7.37651 8.12209 4.94114 11.9981 5.00108C13.4778 4.9782 15.0216 5.31893 16.5425 5.94418L15.3802 7.10645Z"
                fill="currentColor"
            />
            <path
                d="M1.47089 23C1.35043 23 1.22988 22.954 1.13795 22.862C0.954015 22.6781 0.954015 22.3801 1.13795 22.1962L22.1965 1.13795C22.3805 0.954015 22.6785 0.954015 22.8624 1.13795C23.0463 1.32189 23.0463 1.61989 22.8624 1.80383L1.80383 22.862C1.71181 22.9539 1.59135 23 1.47089 23Z"
                fill="currentColor"
            />
        </g>
    )
})

export const ViewVisibleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ViewVisible',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M11.9981 5.00108C15.8735 4.94115 20.1889 7.37543 23.3794 10.8832C24.2062 11.8011 24.2062 13.1952 23.3772 14.1155C20.1931 17.6197 15.8947 20.0639 12.0107 19.9981C8.1012 20.0639 3.80318 17.6193 0.619834 14.1131C-0.206968 13.1952 -0.206968 11.8011 0.621975 10.8809C3.80565 7.37651 8.12209 4.94114 11.9981 5.00108ZM12.0107 6.50107H11.9854C8.60633 6.44407 4.65609 8.67112 1.73436 11.8871C1.42152 12.2344 1.42152 12.7619 1.73237 13.107C4.65085 16.3215 8.57893 18.5556 12.0107 18.4983C15.4172 18.5556 19.3458 16.3217 22.2648 13.1092C22.5777 12.7619 22.5777 12.2344 22.2673 11.8898C19.339 8.67029 15.3895 6.44408 12.0107 6.50107ZM11.9967 7.99918C14.4814 7.99785 16.4968 10.011 16.4981 12.4958C16.4981 14.9845 14.4834 16.9992 11.9981 16.9992C9.51281 16.9992 7.4981 14.9845 7.4981 12.4992C7.4981 10.0144 9.51208 7.99984 11.9967 7.99918ZM11.9973 9.49918C10.3408 9.49962 8.99809 10.8426 8.99809 12.4992C8.99809 14.156 10.3412 15.4992 11.9981 15.4992C13.5958 15.4992 14.9018 14.2503 14.993 12.6755L14.9981 12.4992C14.9972 10.8403 13.6538 9.4983 11.9973 9.49918Z"
            fill="currentColor"
        />
    )
})
