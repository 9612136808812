import { FC, ReactNode } from 'react'
import { Features, useHasClientFeature } from 'shared/hooks/useHasClientFeature'

export interface FeatureComponentProps {
    feature: Features
    children: ReactNode
}

export const FeatureComponent: FC<FeatureComponentProps> = ({ feature, children }) => {
    const hasFeature = useHasClientFeature(feature)

    if (!hasFeature) return null

    return <>{children}</>
}

export { Features }
