// https://github.com/streamich/react-use/blob/master/src/useMeasure.ts
// Modified from 'react-use/useMeasure' to add `blockSize` and `inlineSize`
// blockSize and inlineSize give us the full height/width (depending on content flow direction)
// of the component, INCLUDING padding, margin and borders

import { useLayoutEffect, useMemo, useState } from 'react'
import { flushSync } from 'react-dom'

export type UseMeasureRect = Omit<DOMRectReadOnly, 'toJSON'> & {
    blockSize: number
    inlineSize: number
}
export type UseMeasureRef<E extends Element = Element> = (element: E) => void
export type UseMeasureResult<E extends Element = Element> = [UseMeasureRef<E>, UseMeasureRect]

const defaultState: UseMeasureRect = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    blockSize: 0,
    inlineSize: 0
}

export const useMeasure = <E extends Element = Element>(): UseMeasureResult<E> => {
    const [element, ref] = useState<E | null>(null)
    const [rect, setRect] = useState<UseMeasureRect>(defaultState)

    const observer = useMemo(
        () =>
            new (window as any).ResizeObserver((entries: ResizeObserverEntry[]) => {
                if (!entries[0]) return

                const { x, y, width, height, top, left, bottom, right } = entries[0].contentRect
                const { blockSize, inlineSize } = entries[0].borderBoxSize[0]

                /**
                 * we're using flushSync here to prevent updates from being batched,
                 * which would cause the update to look "jumpy" when <Scrollable /> is
                 * re-rendered; references:
                 * https://github.com/facebook/react/issues/24331
                 * https://react.dev/reference/react-dom/flushSync
                 */
                flushSync(() => {
                    setRect({
                        x,
                        y,
                        width,
                        height,
                        top,
                        left,
                        bottom,
                        right,
                        blockSize,
                        inlineSize
                    })
                })
            }),
        []
    )

    useLayoutEffect(() => {
        if (!element) return
        observer.observe(element)
        return () => {
            observer.disconnect()
        }
    }, [element, observer])

    return [ref, rect]
}
