import isElectron from 'is-electron'
import { useCallback, useEffect } from 'react'

export const useClickToDial = (_callHandler: (number: string) => void) => {
    const callHandler = useCallback(
        (_event, number: string) => _callHandler(number),
        [_callHandler]
    )

    useEffect(() => {
        if (!isElectron()) return

        window.electron.addListener('numberToCall', callHandler)

        return () => {
            window.electron.removeListener('numberToCall', callHandler)
        }
    }, [callHandler])
}
