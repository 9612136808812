import { addCall, focusCall, updateCallDetails } from '@missionlabs/api'
import { addListener } from '@reduxjs/toolkit'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useLazyContactsSearchQuery } from 'shared/store'

export const useStoreListeners = () => {
    const { formatToLocalNumber } = useFormatToNumberE164()

    const [search] = useLazyContactsSearchQuery()

    const addCallListener = addListener({
        actionCreator: addCall,
        // update incoming call with contact details from ES
        effect: async (action, { dispatch }) => {
            try {
                const { callTraceID, remoteNumber, direction } = action.payload
                if (callTraceID && direction === 'outbound') {
                    dispatch(focusCall({ callTraceID: callTraceID }))
                }
                const contact = await search({
                    searchTerm: remoteNumber,
                    maxResults: 1
                }).unwrap()

                if (!contact.length) return

                const isExactExtension = contact[0].extension === remoteNumber

                const isExactNumber = contact[0].phoneNumbers?.some(
                    number =>
                        formatToLocalNumber(number.numberE164) === formatToLocalNumber(remoteNumber)
                )

                if (isExactNumber || isExactExtension) {
                    dispatch(
                        updateCallDetails({
                            callTraceID: callTraceID,
                            callDetails: {
                                callTraceID: callTraceID,
                                remoteNumber: remoteNumber,
                                companyName: contact[0].companyName,
                                contactName: contact[0].fullName
                            }
                        })
                    )
                }
            } catch (e) {
                console.error(e)
            }
        }
    })

    return { addCall: addCallListener }
}
