import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const TransferCallIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TransferCallIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M16.0666 0.183399L19.8166 3.9334C20.0588 4.17559 20.0588 4.57402 19.8166 4.81621L16.0666 8.56621C15.8245 8.8084 15.426 8.8084 15.1838 8.56621C14.9417 8.32402 14.9417 7.92559 15.1838 7.6834L17.8674 4.99981H11.8752C11.5315 4.99981 11.2502 4.71856 11.2502 4.37481C11.2502 4.03106 11.5315 3.74981 11.8752 3.74981H17.8674L15.1838 1.06621C14.9417 0.824024 14.9417 0.425586 15.1838 0.183399C15.426 -0.058789 15.8245 -0.058789 16.0666 0.183399V0.183399ZM12.8518 11.199C13.2932 10.66 14.0354 10.4764 14.676 10.7537L19.051 12.6287C19.7385 12.9217 20.1213 13.6639 19.9651 14.3904L19.0276 18.7654C18.8713 19.4842 18.2346 19.9998 17.5002 19.9998C17.262 19.9998 17.0237 19.9959 16.7854 19.9842H16.7815C16.6018 19.9764 16.426 19.9686 16.2463 19.9529C7.16821 19.3162 0.000244141 11.7459 0.000244141 2.4998C0.000244141 1.76152 0.515869 1.1248 1.23462 0.972461L5.60962 0.034961C6.34009 -0.121289 7.07837 0.261524 7.37134 0.949024L9.24634 5.32402C9.51978 5.96465 9.34009 6.70684 8.80103 7.14824L7.21509 8.44512C8.25806 10.242 9.75806 11.742 11.5549 12.785L12.8518 11.199ZM17.5002 18.7498C17.6487 18.7498 17.7737 18.6482 17.8049 18.5037L18.7424 14.1287C18.7737 13.9842 18.6956 13.8357 18.5588 13.7771L14.1838 11.9021C14.0549 11.8475 13.9065 11.8826 13.8206 11.992L12.5237 13.5779C12.137 14.0506 11.4612 14.1756 10.9299 13.867C8.94165 12.7146 7.2854 11.0584 6.13696 9.07402C5.82837 8.54277 5.95337 7.86699 6.42603 7.48027L8.01196 6.1834C8.12134 6.09356 8.15649 5.94512 8.10181 5.82012L6.22681 1.44512C6.16821 1.3084 6.01978 1.23027 5.87524 1.26152L1.50024 2.19902C1.35181 2.22637 1.25024 2.35137 1.25024 2.4998C1.25024 11.4686 8.51978 18.742 17.4885 18.7498H17.5002Z"
            fill="currentColor"
        />
    )
})

export const TransferCallIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TransferCallIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M12.8506 11.199C13.292 10.66 14.0342 10.4764 14.6748 10.7537L19.0498 12.6287C19.7373 12.9217 20.1201 13.6639 19.9639 14.3904L19.0264 18.7654C18.8701 19.4842 18.2334 19.9998 17.499 19.9998C17.2607 19.9998 17.0225 19.9959 16.7881 19.9842H16.7842C16.3936 19.9686 16.0107 19.9412 15.6279 19.8982C6.84277 18.9686 -0.000976562 11.5311 -0.000976562 2.4998C-0.000976562 1.76152 0.514648 1.1248 1.2334 0.972461L5.6084 0.034961C6.33887 -0.121289 7.07715 0.261523 7.37012 0.949024L9.24512 5.32402C9.51855 5.96465 9.33887 6.70684 8.7998 7.14824L7.21387 8.44512C8.25684 10.242 9.75684 11.742 11.5537 12.785L12.8506 11.199ZM18.0771 14.2498L14.1553 12.5701L13.0068 13.9686C12.4248 14.6795 11.4131 14.8631 10.6162 14.4021C8.53418 13.1951 6.7998 11.4607 5.59277 9.37871C5.13184 8.58184 5.31543 7.57012 6.02637 6.98809L7.42871 5.83965L5.74902 1.91777L1.87793 2.75371C2.01074 11.1795 8.81934 17.9881 17.2451 18.1248L18.0771 14.2498V14.2498ZM16.6006 0.273242L19.7256 3.39824C20.0928 3.76543 20.0928 4.35918 19.7256 4.72246L16.6006 7.84746C16.2334 8.21465 15.6396 8.21465 15.2764 7.84746C14.9131 7.48027 14.9092 6.88652 15.2764 6.52324L16.7998 4.9998H12.1865C11.667 4.9998 11.249 4.58184 11.249 4.0623C11.249 3.54277 11.667 3.1248 12.1865 3.1248H16.7998L15.2725 1.60137C14.9053 1.23418 14.9053 0.64043 15.2725 0.277148C15.6396 -0.0861328 16.2334 -0.090039 16.5967 0.277148L16.6006 0.273242Z"
            fill="currentColor"
        />
    )
})

export const TransferCallIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TransferCallIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M4.58887 0.0545917C5.34668 -0.15244 6.13965 0.234279 6.44043 0.960842L8.00293 4.71084C8.26855 5.34756 8.08496 6.08584 7.5498 6.51944L5.62402 8.09756C6.9248 10.8476 9.15137 13.0741 11.9014 14.3749L13.4756 12.4491C13.9131 11.914 14.6475 11.7304 15.2842 11.996L19.0342 13.5585C19.7607 13.8593 20.1475 14.6522 19.9404 15.4101L19.0029 18.8476C18.8193 19.5272 18.2021 19.9999 17.499 19.9999C7.83496 19.9999 -0.000976562 12.164 -0.000976562 2.4999C-0.000976562 1.79678 0.47168 1.17959 1.15137 0.992092L4.58887 0.0545917V0.0545917ZM16.6006 0.273342L19.7256 3.39834C20.0928 3.76553 20.0928 4.35928 19.7256 4.72256L16.6006 7.84756C16.2334 8.21475 15.6396 8.21475 15.2764 7.84756C14.9131 7.48037 14.9092 6.88662 15.2764 6.52334L16.7998 4.9999H12.1865C11.667 4.9999 11.249 4.58194 11.249 4.0624C11.249 3.54287 11.667 3.1249 12.1865 3.1249H16.7998L15.2725 1.60147C14.9053 1.23428 14.9053 0.640529 15.2725 0.277248C15.6396 -0.0860333 16.2334 -0.0899395 16.5967 0.277248L16.6006 0.273342Z"
            fill="currentColor"
        />
    )
})
