import { Drawer, DrawerOverlay, DrawerProps } from '@missionlabs/react'
import { DirectoryEntry } from '@missionlabs/types'
import {
    getDateFromUnixTimestamp,
    getMillisecondsUnix,
    getUnixTimestampForChosenDateAndTime,
    isValidEmail
} from '@missionlabs/utils'
import { format } from 'date-fns'
import { FC, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuthenticatedUser } from 'shared/hooks/useAuthenticatedUser'
import {
    useCreateScheduledMeetingMutation,
    useGetScheduledMeetingQuery,
    useUpdateScheduledMeetingMutation,
    useUpdateScheduledMeetingParticipantsMutation
} from 'shared/store'

import { useGetMeetingData } from '../useGetMeetingData'
import { CreateMeetingDrawerContent } from './AddMeetingDrawerContent'
import { EditMeetingDrawerContent } from './EditMeetingDrawerContent'
import { ShareMeetingDrawerContent } from './ShareMeetingDrawerContent'
import { ViewSharedMeetingDrawerContent } from './ViewSharedMeetingDrawerContent'

export type AddEditDrawerType = 'CREATE' | 'EDIT' | 'SHARE' | undefined

type AddEditMeetingDrawerProps = Omit<DrawerProps, 'children'> & {
    type: AddEditDrawerType
}

export type AddEditMeetingFormFields = {
    meetingName: string
    description: string
    date: Date
    startTime: string
    endTime: string
    allowGuestUsers: 'ALLOW' | 'DISALLOW'
    microphoneInitialState: 'MUTED' | 'UNMUTED'
    invitedUsers: DirectoryEntry[]
}

export const getUserIDFromInvitedUsers = (users: DirectoryEntry[]) => {
    return users.map(user => {
        if (isValidEmail(user.userID ?? ''))
            return { userID: user.userID ?? '', attendeeType: 'GUEST' }
        return { userID: user.externalID ?? '' }
    })
}

const sanitizeData = (data: AddEditMeetingFormFields) => {
    const {
        meetingName,
        description,
        startTime,
        endTime,
        date,
        allowGuestUsers,
        microphoneInitialState
    } = data
    return {
        meetingName,
        description,
        startTime: getUnixTimestampForChosenDateAndTime(date, startTime),
        endTime: getUnixTimestampForChosenDateAndTime(date, endTime),
        meetingSettings: {
            allowGuestUsers,
            microphoneInitialState
        }
    }
}

const noop = () => {}

export const AddEditMeetingDrawer: FC<AddEditMeetingDrawerProps> = ({
    isOpen,
    onClose,
    type,
    ...props
}) => {
    const { id = '' } = useParams()
    const { data: user } = useAuthenticatedUser()
    const navigate = useNavigate()
    const { data: meeting } = useGetScheduledMeetingQuery(
        {
            userID: user?.ID ?? '',
            meetingID: id
        },
        {
            skip: !user?.ID || !id
        }
    )
    const { hasStarted } = useGetMeetingData(meeting)
    const [createMeeting, { isLoading: createIsLoading }] = useCreateScheduledMeetingMutation()
    const [updateMeeting, { isLoading: updateIsLoading }] = useUpdateScheduledMeetingMutation()
    const [updateAttendeesMeeting, { isLoading: updateAttendeesIsLoading }] =
        useUpdateScheduledMeetingParticipantsMutation()

    const isLoading = createIsLoading || updateIsLoading || updateAttendeesIsLoading

    function handleTransition() {
        navigate(-1)
    }

    const organiserUserID = meeting?.organiser.userID,
        userID = user?.ID

    const isOrganiser = useMemo(() => {
        return organiserUserID === userID
    }, [organiserUserID, userID])

    const handleCreateMeeting = useCallback(
        async (data: AddEditMeetingFormFields) => {
            await createMeeting({
                ...sanitizeData(data),
                invitedUsers: getUserIDFromInvitedUsers(data.invitedUsers),
                userID: user?.ID ?? ''
            }).unwrap()
        },
        [user, createMeeting]
    )

    const handleUpdateMeeting = useCallback(
        async (data: AddEditMeetingFormFields) => {
            await updateMeeting({
                ...sanitizeData(data),
                meetingID: meeting?.ID ?? '',
                userID: user?.ID ?? ''
            }).unwrap()
        },
        [user, meeting, updateMeeting]
    )

    const handleUpdateMeetingParticipants = useCallback(
        async (data: Pick<AddEditMeetingFormFields, 'invitedUsers'>) => {
            await updateAttendeesMeeting({
                meetingID: meeting?.ID ?? '',
                userID: user?.ID ?? '',
                invitedUsers: getUserIDFromInvitedUsers(data.invitedUsers)
            }).unwrap()
        },
        [user, meeting, updateAttendeesMeeting]
    )

    const onSubmit = useCallback(
        async (data: AddEditMeetingFormFields) => {
            try {
                switch (type) {
                    case 'CREATE': {
                        await handleCreateMeeting(data)
                        break
                    }
                    case 'EDIT': {
                        await handleUpdateMeeting(data)
                        break
                    }
                    case 'SHARE': {
                        await handleUpdateMeetingParticipants(
                            data as Pick<AddEditMeetingFormFields, 'invitedUsers'>
                        )
                        break
                    }
                }
            } catch (err) {
                console.error(err)
            } finally {
                onClose()
            }
        },
        [onClose, handleCreateMeeting, handleUpdateMeeting, handleUpdateMeetingParticipants, type]
    )

    const defaultEditValues = useMemo(() => {
        if (!meeting) return
        const {
            meetingName,
            description,
            startTime,
            endTime,
            meetingSettings: { allowGuestUsers, microphoneInitialState }
        } = meeting

        return {
            meetingName,
            description,
            date: getDateFromUnixTimestamp(startTime),
            startTime: format(getMillisecondsUnix(startTime), 'HH:mm'),
            endTime: format(getMillisecondsUnix(endTime), 'HH:mm'),
            allowGuestUsers,
            microphoneInitialState
        } as AddEditMeetingFormFields
    }, [meeting])

    const drawerContent = useMemo(() => {
        switch (type) {
            case 'CREATE': {
                return (
                    <CreateMeetingDrawerContent
                        onClose={onClose}
                        onSubmit={onSubmit}
                        isLoading={isLoading}
                    />
                )
            }
            case 'EDIT': {
                return (
                    <EditMeetingDrawerContent
                        onClose={onClose}
                        onSubmit={onSubmit}
                        isLoading={isLoading}
                        values={defaultEditValues!}
                    />
                )
            }
            case 'SHARE': {
                if (hasStarted || !isOrganiser) {
                    return (
                        <ViewSharedMeetingDrawerContent
                            onClose={onClose}
                            isLoading={isLoading}
                            values={{
                                alreadyInvitedUsers: meeting?.invitedUsers ?? [],
                                invitedUsers: []
                            }}
                        />
                    )
                }
                return (
                    <ShareMeetingDrawerContent
                        onClose={onClose}
                        onSubmit={
                            onSubmit as (
                                data: Pick<AddEditMeetingFormFields, 'invitedUsers'>
                            ) => Promise<void>
                        }
                        isLoading={isLoading}
                        values={{
                            alreadyInvitedUsers: meeting?.invitedUsers ?? [],
                            invitedUsers: []
                        }}
                    />
                )
            }
            default: {
                return <>Error</>
            }
        }
    }, [onClose, onSubmit, isLoading, type, defaultEditValues, meeting, hasStarted, isOrganiser])

    return (
        <Drawer
            {...props}
            size="lg"
            isOpen={isOpen}
            onClose={!isLoading ? onClose : noop}
            onCloseComplete={handleTransition}
        >
            <DrawerOverlay />
            {drawerContent}
        </Drawer>
    )
}
