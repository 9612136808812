import { Box, Link, Spinner, useColorMode } from '@chakra-ui/react'
import { Body, Button, Checkbox, ErrorMessage, Input, ValidatedInput } from '@missionlabs/react'
import { ResetToken } from '@missionlabs/types'
import backgroundImage from 'assets/images/features-numbers-instantly.png'
import { useFlags } from 'flagsmith/react'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Address } from 'shared/components/Address'
import { AuthLayout } from 'shared/components/AuthLayout'
import { AppConfig } from 'shared/utils/env'

export interface WelcomeFormProps {
    token?: ResetToken
    errorMessage: string | null
    isSubmitting: boolean
    isValid: boolean
}

const containerStyle = { maxH: 'initial' }

export const WelcomeForm: FC<WelcomeFormProps> = ({
    errorMessage,
    isValid,
    isSubmitting,
    token
}) => {
    const { t } = useTranslation()

    const { colorMode } = useColorMode()
    const textStyle = { color: `${colorMode}.tones.navy` }
    const showTerms = useFlags(['explicit_accept_terms']).explicit_accept_terms.enabled
    const termsLink = (
        <Link href={AppConfig.termsLink} rel="noopener" color="blue" textDecoration="underline" />
    )

    return (
        <AuthLayout
            label={t('welcome.title', { appName: AppConfig.name })}
            contentWidth="512px"
            image={backgroundImage}
        >
            {!token && <Spinner alignSelf="center" size="sm" />}
            <Body size="sm" alignSelf="start" sx={textStyle}>
                {t('welcome.intro', {
                    appName: AppConfig.name,
                    companyName: token?.companyName || ''
                })}
            </Body>
            {/* https://www.chromium.org/developers/design-documents/create-amazing-password-forms/#use-hidden-fields-for-implicit-information */}
            <input
                readOnly
                name="username"
                autoComplete="username"
                type="hidden"
                value={token?.email}
            />
            <ValidatedInput
                isDisabled={!token}
                name="password"
                label={t('welcome.password')}
                maxHeight="initial"
                containerSx={containerStyle}
                helperText={t('welcome.passwordHelp')}
            >
                <Input type="password" autoComplete="new-password" />
            </ValidatedInput>
            <Box alignSelf="stretch">
                <Address isDisabled={!token} />
            </Box>
            {showTerms && (
                <ValidatedInput name="acceptTerms">
                    <Checkbox isRequired>
                        <Trans i18nKey="welcome.iAccept" components={{ TermsLink: termsLink }} />
                    </Checkbox>
                </ValidatedInput>
            )}
            {errorMessage && <ErrorMessage error={errorMessage} />}
            <Button
                isDisabled={!isValid}
                type="submit"
                size="lg"
                alignSelf="start"
                paddingX={8}
                isLoading={isSubmitting}
            >
                {t('welcome.activateYourAccount')}
            </Button>
            <Body size="xs" alignSelf="start" sx={textStyle}>
                <Trans
                    i18nKey={showTerms ? 'welcome.finePrintWithoutTerms' : 'welcome.finePrint'}
                    components={{
                        TermsLink: termsLink,
                        PrivacyLink: (
                            <Link
                                href={AppConfig.privacyLink}
                                rel="noopener"
                                color="blue"
                                textDecoration="underline"
                            />
                        )
                    }}
                />
            </Body>
        </AuthLayout>
    )
}
