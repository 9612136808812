import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { AnalyticsUser, UserRole } from '@missionlabs/types'
import { AnalyticsTable } from 'features/analytics/components/AnalyticsTable/AnalyticsTable'
import AnalyticsTabsView from 'features/analytics/components/AnalyticsTabView'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useUserRole } from 'shared/hooks/useUserRole'
import { useGetSingleUserTotalsAnalyticsQuery, useGetUsersAnalyticsQuery } from 'shared/store'

interface Props {}

const Users: React.FC<Props> = () => {
    const { t } = useTranslation()
    const { dateScroller, group } = useAnalytics()

    const userRole = useUserRole()
    const user = useSelector(selectAuthenticatedUser)

    const apiArgs = {
        from: dateScroller.start?.getTime().toString(),
        to: dateScroller.end?.getTime().toString(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        userID: user?.userID,
        group
    }

    const {
        data: userData,
        isLoading: isUserLoading,
        isFetching: isUserFetching
    } = useGetSingleUserTotalsAnalyticsQuery(apiArgs, { skip: userRole !== UserRole.END_USER })

    const {
        data: allUsersData,
        isFetching,
        isLoading
    } = useGetUsersAnalyticsQuery(apiArgs, {
        skip: userRole !== UserRole.ADMIN || !dateScroller.start || !dateScroller.end
    })

    const isNoResults = !userData?.data && !allUsersData?.data.length
    const isQueryLoading = isUserLoading || isUserFetching || isLoading || isFetching
    const usersData = userData?.data
        ? ([{ ...userData.data, ...userData.total }] as AnalyticsUser[])
        : allUsersData?.data

    return (
        <div>
            <SettingsPage
                title={t('analytics.users')}
                subtitle={t('analytics.usersSubtitle')}
                isLoading={isQueryLoading}
                contentProps={{ padding: 0, maxW: 'unset' }}
            >
                <AnalyticsTabsView noResults={isNoResults} isLoading={isQueryLoading} header={null}>
                    <AnalyticsTable usersData={usersData} />
                </AnalyticsTabsView>
            </SettingsPage>
        </div>
    )
}

export default Users
