import { Box, Flex, Skeleton, SkeletonCircle, useMultiStyleConfig } from '@chakra-ui/react'
import { AvatarProps } from 'molecules/Avatar/Avatar'
import { FC } from 'react'

export type ContactSkeletonProps = Omit<AvatarProps, 'role'> & {
    direction?: 'horizontal' | 'vertical'
}

export const ContactSkeleton: FC<ContactSkeletonProps> = ({
    size = 'sm',
    variant,
    direction = 'horizontal',
    ...props
}) => {
    const styles = useMultiStyleConfig('ContactDetail', { size, variant: variant ?? direction })

    return (
        <Flex
            sx={{ ...styles.wrapper, width: '100%', ...(props.sx ?? {}) }}
            data-testid="contact-skeleton"
        >
            <SkeletonCircle size={'8'} />
            <Box sx={styles.container} w="100%">
                <Skeleton h="20px" w="100%" />
                <Skeleton h="20px" w="100%" mt={1} />
            </Box>
        </Flex>
    )
}
