import styled, { css } from 'styled-components'

import { baseSpacing, baseStyles } from '../Base'

export const ellipsis = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const StyledVideoTile = styled.div<any>`
    height: 100%;
    width: 100%;
    position: relative;
    background: ${props => props.background};

    video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: ${props => props.objectFit || 'cover'};
    }

    .placeholder {
        place-content: center;
        display: inline-flex;
        position: absolute;
        width: 100%;
        height: 100%;
        align-items: center;
        object-fit: ${props => props.objectFit || 'cover'};
    }

    .ch-icon {
        width: 1.5rem;
        height: 1.5rem;
        display: inline-block;
        background-color: papayawhip; /* TODO: figure out what this is supposed to be */
        margin-right: 0.5rem;
        flex: 0 0 1.5rem;
    }

    .ch-nameplate {
        backdrop-filter: blur(20px);
        background-color: #2e3667;
        bottom: 0;
        color: ${props => props.theme.colors.greys.white};
        left: 0;
        max-width: calc(100% - 2rem);
        padding: 8px 16px;
        position: absolute;
        line-height: normal;
        display: flex;

        div {
            ${ellipsis};
            display: flex;
            align-items: center;
        }

        .ch-text {
            color: white;
            font-size: ${props => props.theme.fontSizes.text.fontSize};
            ${ellipsis};
            margin: 0;
        }
    }

    .active {
        background-color: #c9341f !important;
    }

    .speaker-icon {
        visibility: hidden;
        width: 0px;

        &.speaker-active {
            visibility: visible;
            width: auto;
            margin-left: 0.5rem;
        }
    }

    ${baseSpacing}
    ${baseStyles}
`
