import { Emoji } from '@missionlabs/api'
import { Button } from '@missionlabs/react'
import { FC } from 'react'

export interface EmojiPickerItemProps {
    item: Emoji | undefined
    onClick: (unicode: string) => void
}

export const EmojiPickerItem: FC<EmojiPickerItemProps> = ({ item, onClick }) => {
    if (!item) return null

    return (
        <Button
            size="sm"
            variant="ghost"
            sx={{
                padding: '6px',
                boxSize: '36px',
                fontSize: '24px'
            }}
            onClick={() => onClick(item?.native ?? '')}
            aria-label={item.name}
        >
            {item.native}
        </Button>
    )
}
