import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ShareIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ShareIcon',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M17.75 5C17.75 7.07031 16.0703 8.75 14 8.75C12.8672 8.75 11.8516 8.28125 11.1484 7.46094L7.63281 9.21875C7.71094 9.49219 7.71094 9.76562 7.71094 10C7.71094 10.2734 7.71094 10.5469 7.63281 10.8203L11.1484 12.5391C11.8516 11.7578 12.8672 11.25 14 11.25C16.0703 11.25 17.75 12.9297 17.75 15C17.75 17.0703 16.0703 18.75 14 18.75C11.8906 18.75 10.25 17.0703 10.25 15C10.25 14.7656 10.25 14.4922 10.3281 14.2188L6.8125 12.4609C6.10938 13.2812 5.09375 13.75 4 13.75C1.89062 13.75 0.25 12.0703 0.25 10C0.25 7.92969 1.89062 6.25 4 6.25C5.09375 6.25 6.10938 6.75781 6.8125 7.53906L10.3281 5.82031C10.25 5.54688 10.25 5.27344 10.25 5C10.25 2.92969 11.8906 1.25 14 1.25C16.0703 1.25 17.75 2.92969 17.75 5ZM3.96094 11.875C5.01562 11.875 5.83594 11.0547 5.83594 10C5.83594 8.98438 5.01562 8.125 3.96094 8.125C2.94531 8.125 2.08594 8.98438 2.08594 10C2.08594 11.0547 2.94531 11.875 3.96094 11.875ZM14 3.125C12.9453 3.125 12.125 3.98438 12.125 5C12.125 6.05469 12.9453 6.875 14 6.875C15.0156 6.875 15.875 6.05469 15.875 5C15.875 3.98438 15.0156 3.125 14 3.125ZM14 16.875C15.0156 16.875 15.875 16.0547 15.875 15C15.875 13.9844 15.0156 13.125 14 13.125C12.9453 13.125 12.125 13.9844 12.125 15C12.125 16.0547 12.9453 16.875 14 16.875Z"
            fill="currentColor"
        />
    )
})
