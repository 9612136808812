import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const RecentIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'RecentIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.51472 20.4853C8.20107 25.1716 15.799 25.1716 20.4853 20.4853C25.1716 15.7989 25.1716 8.20101 20.4853 3.51471C15.7989 -1.17157 8.201 -1.17157 3.51472 3.51472C-1.17157 8.20107 -1.17157 15.799 3.51472 20.4853ZM17.6459 11.1406L12.3562 13.3145V4.44116C12.3562 4.16502 12.1323 3.94116 11.8562 3.94116C11.5801 3.94116 11.3562 4.16502 11.3562 4.44116V14.0605C11.3562 14.4163 11.7172 14.6582 12.0463 14.523L18.026 12.0655C18.2814 11.9605 18.4034 11.6684 18.2984 11.413C18.1934 11.1576 17.9013 11.0356 17.6459 11.1406Z"
            fill="currentColor"
        />
    )
})
