import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const SearchIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SearchIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M9.6564 1C14.4284 1 18.3119 4.88307 18.3119 9.65552C18.3119 11.4681 17.7489 13.1502 16.7928 14.5424L22.535 20.284C23.1552 20.9051 23.1552 21.9136 22.534 22.5337C22.2347 22.8339 21.836 22.9991 21.41 23C20.9859 23 20.5862 22.8339 20.286 22.5337L14.5438 16.7922C13.1524 17.7481 11.4691 18.311 9.6564 18.311C4.88346 18.311 1 14.428 1 9.65552C1 4.88307 4.88346 1 9.6564 1ZM9.5991 3C5.96021 3 3 5.95975 3 9.59808C3 13.2364 5.96021 16.1962 9.5991 16.1962C13.238 16.1962 16.1974 13.2356 16.1974 9.59808C16.1974 5.95975 13.238 3 9.5991 3Z"
            fill="currentColor"
        />
    )
})
