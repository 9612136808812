import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const InboxEmptyIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'InboxEmptyIconLight',
    viewBox: '0 0 20 20',
    path: (
        <g>
            <path
                d="M10 0C10.3127 0 10.5661 0.253424 10.5661 0.566038V4.33962C10.5661 4.65224 10.3127 4.90566 10 4.90566C9.68743 4.90566 9.434 4.65224 9.434 4.33962V0.566038C9.434 0.253424 9.68743 0 10 0Z"
                fill="currentColor"
            />
            <path
                d="M2.59557 1.52053C2.84995 1.33882 3.20347 1.39774 3.38518 1.65213L5.74367 4.95402C5.92537 5.2084 5.86645 5.56192 5.61206 5.74362C5.35768 5.92533 5.00416 5.86641 4.82246 5.61202L2.46397 2.31013C2.28226 2.05575 2.34118 1.70223 2.59557 1.52053Z"
                fill="currentColor"
            />
            <path
                d="M17.4045 1.52053C17.6589 1.70223 17.7178 2.05575 17.5361 2.31013L15.1776 5.61202C14.9959 5.86641 14.6424 5.92533 14.388 5.74362C14.1336 5.56192 14.0747 5.2084 14.2564 4.95402L16.6149 1.65213C16.7966 1.39774 17.1501 1.33882 17.4045 1.52053Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5388 7.78028C3.64531 7.63382 3.81547 7.54717 3.99657 7.54717H16.0034C16.1845 7.54717 16.3547 7.63382 16.4612 7.78028L19.8881 12.4922C19.956 12.5834 19.9972 12.6956 19.9999 12.8174C20 12.8224 20 12.8274 20 12.8324V18.4906C20 19.3242 19.3242 20 18.4906 20H1.50943C0.675797 20 1.25017e-07 19.3242 1.25017e-07 18.4906V12.8303C-1.56271e-07 12.8305 1.25017e-07 12.8301 1.25017e-07 12.8303C0.000152925 12.7038 0.041796 12.5862 0.112051 12.4921L3.5388 7.78028ZM1.67761 12.2642H7.16981C7.48243 12.2642 7.73585 12.5176 7.73585 12.8302C7.73585 14.0806 8.74954 15.0943 10 15.0943C11.2505 15.0943 12.2642 14.0806 12.2642 12.8302C12.2642 12.5176 12.5176 12.2642 12.8302 12.2642H18.3224L15.7152 8.67924H4.28481L1.67761 12.2642ZM1.13208 13.3962V18.4906C1.13208 18.699 1.30102 18.8679 1.50943 18.8679H18.4906C18.699 18.8679 18.8679 18.699 18.8679 18.4906V13.3962H13.3493C13.3156 13.597 13.2643 13.7918 13.197 13.979C12.7261 15.2893 11.4725 16.2264 10 16.2264C9.89482 16.2264 9.79076 16.2216 9.68803 16.2123C8.14696 16.0719 6.90337 14.9021 6.65073 13.3962H1.13208Z"
                fill="currentColor"
            />
        </g>
    )
})

export const InboxEmptyIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'InboxEmptyIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <g>
            <path
                d="M10 0C10.4734 0 10.8571 0.355535 10.8571 0.794109V4.32349C10.8571 4.76206 10.4734 5.11759 10 5.11759C9.52662 5.11759 9.14286 4.76206 9.14286 4.32349V0.794109C9.14286 0.355535 9.52662 0 10 0Z"
                fill="currentColor"
            />
            <path
                d="M2.359 1.47143C2.74421 1.21652 3.27953 1.29918 3.55468 1.65606L5.93561 4.74426C6.21076 5.10115 6.12154 5.59711 5.73633 5.85202C5.35113 6.10694 4.8158 6.02428 4.54065 5.6674L2.15972 2.57919C1.88457 2.22231 1.97379 1.72635 2.359 1.47143Z"
                fill="currentColor"
            />
            <path
                d="M17.641 1.47143C18.0262 1.72635 18.1154 2.22231 17.8403 2.57919L15.4593 5.6674C15.1842 6.02428 14.6489 6.10694 14.2637 5.85202C13.8785 5.59711 13.7892 5.10115 14.0644 4.74426L16.4453 1.65606C16.7205 1.29918 17.2558 1.21652 17.641 1.47143Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.49404 7.34039C3.64942 7.12653 3.89764 7 4.1618 7H15.8382C16.1024 7 16.3506 7.12653 16.506 7.34039L19.8421 11.9322C19.9876 12.1326 20.0339 12.3846 19.9753 12.6189C19.9914 12.6827 20 12.7495 20 12.8182V18.2727C20 19.2267 19.2196 20 18.2569 20H1.74312C0.780422 20 2.22735e-09 19.2267 2.22735e-09 18.2727V12.8182C2.22735e-09 12.7495 0.00855114 12.6827 0.024656 12.6189C-0.0338976 12.3846 0.0123529 12.1326 0.157928 11.9322L3.49404 7.34039ZM4.58226 8.65306L2.44715 11.5918H7.24771C7.70372 11.5918 8.0734 11.9619 8.0734 12.4184C8.0734 13.4835 8.93597 14.3469 10 14.3469C11.064 14.3469 11.9266 13.4835 11.9266 12.4184C11.9266 11.9619 12.2963 11.5918 12.7523 11.5918H17.5528L15.4177 8.65306H4.58226ZM1.65138 18.2727V13.6364H6.51776C6.89051 15.2 8.30812 16.3636 10 16.3636C11.6919 16.3636 13.1095 15.2 13.4822 13.6364H18.3486V18.2727C18.3486 18.3229 18.3076 18.3636 18.2569 18.3636H1.74312C1.69245 18.3636 1.65138 18.3229 1.65138 18.2727Z"
                fill="currentColor"
            />
        </g>
    )
})

export const InboxEmptyIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'InboxEmptyIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <g>
            <path
                d="M10.8571 0.794109C10.8571 0.355535 10.4734 0 10 0C9.52662 0 9.14286 0.355535 9.14286 0.794109V4.32349C9.14286 4.76206 9.52662 5.11759 10 5.11759C10.4734 5.11759 10.8571 4.76206 10.8571 4.32349V0.794109Z"
                fill="currentColor"
            />
            <path
                d="M3.55468 1.65606C3.27953 1.29918 2.74421 1.21652 2.359 1.47143C1.97379 1.72635 1.88457 2.22231 2.15972 2.57919L4.54065 5.6674C4.8158 6.02428 5.35113 6.10694 5.73633 5.85202C6.12154 5.59711 6.21076 5.10115 5.93561 4.74426L3.55468 1.65606Z"
                fill="currentColor"
            />
            <path
                d="M17.641 1.47143C18.0262 1.72635 18.1154 2.22231 17.8403 2.57919L15.4593 5.6674C15.1842 6.02428 14.6489 6.10694 14.2637 5.85202C13.8785 5.59711 13.7892 5.10115 14.0644 4.74426L16.4453 1.65606C16.7205 1.29918 17.2558 1.21652 17.641 1.47143Z"
                fill="currentColor"
            />
            <path
                d="M4.1618 7C3.89764 7 3.64942 7.12653 3.49404 7.34039L0.326506 11.7002H7.3C7.85228 11.7002 8.3 12.1479 8.3 12.7002C8.3 13.6391 9.06112 14.4002 10 14.4002C10.9389 14.4002 11.7 13.6391 11.7 12.7002C11.7 12.1479 12.1477 11.7002 12.7 11.7002H19.6735L16.506 7.34039C16.3506 7.12653 16.1024 7 15.8382 7H4.1618Z"
                fill="currentColor"
            />
            <path
                d="M20 13.7002H13.5633C13.127 15.2578 11.6969 16.4002 10 16.4002C8.30307 16.4002 6.87295 15.2578 6.43672 13.7002H0V18.2727C0 19.2267 0.780422 20 1.74312 20H18.2569C19.2196 20 20 19.2267 20 18.2727V13.7002Z"
                fill="currentColor"
            />
        </g>
    )
})
