import { createIcon } from '@chakra-ui/react'

export const ClipboardIconLight = createIcon({
    displayName: 'ClipboardIconLight',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M 18.975 1.844 l 3.281 3.406 c 0.313 0.344 0.594 1.063 0.594 1.531 v 10.094 c 0 0.469 -0.375 0.906 -0.875 0.906 h -5.844 v -1.531 h 4.906 c 0.156 0 0.281 -0.125 0.281 -0.281 v -8.5 c 0 -0.156 -0.125 -0.281 -0.281 -0.281 h -3.625 c -0.156 0 -0.313 -0.125 -0.313 -0.281 v -3.875 c 0 -0.156 -0.125 -0.281 -0.281 -0.281 h -5.938 c -0.156 0 -0.313 0.125 -0.313 0.281 v 2.156 c -0.313 -0.125 -0.719 -0.188 -1.031 -0.188 h -0.469 v -2.906 c 0 -0.5 0.406 -0.875 0.875 -0.875 h 7.563 c 0.469 0 1.156 0.281 1.469 0.625 z M 18.913 5.656 h 1.313 c 0.156 0 0.188 -0.094 0.094 -0.219 l -1.5 -1.531 c -0.094 -0.125 -0.188 -0.094 -0.188 0.063 v 1.406 c 0 0.156 0.125 0.281 0.281 0.281 z M 11.038 6.844 l 3.25 3.438 c 0.344 0.344 0.594 1.031 0.594 1.5 v 10.094 c 0 0.5 -0.375 0.906 -0.844 0.906 h -12.031 c -0.5 0 -0.906 -0.406 -0.906 -0.906 v -14.781 c 0 -0.469 0.406 -0.906 0.906 -0.906 h 7.531 c 0.469 0 1.156 0.313 1.5 0.656 z M 2.913 21.25 h 10.156 c 0.156 0 0.313 -0.125 0.313 -0.281 v -8.5 c 0 -0.156 -0.156 -0.281 -0.313 -0.281 h -3.625 c -0.156 0 -0.281 -0.125 -0.281 -0.281 v -3.875 c 0 -0.156 -0.125 -0.281 -0.281 -0.281 h -5.969 c -0.156 0 -0.281 0.125 -0.281 0.281 v 12.938 c 0 0.156 0.125 0.281 0.281 0.281 z M 12.35 10.469 l -1.469 -1.594 c -0.125 -0.125 -0.219 -0.031 -0.219 0.125 v 1.375 c 0 0.156 0.156 0.281 0.313 0.281 h 1.281 c 0.156 0 0.219 -0.094 0.094 -0.188 z"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            fill="currentColor"
        ></path>
    )
})
