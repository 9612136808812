import { useDisclosure } from '@chakra-ui/react'
import { useUpdateParams } from '@missionlabs/react'
import { DateFilterParam } from '@missionlabs/types'
import {
    createContext,
    Dispatch,
    PropsWithChildren,
    useCallback,
    useContext,
    useMemo,
    useReducer,
    useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'

import {
    ActivityFilters,
    AppliedFilterLabel,
    SelectedDateOption,
    SelectedDatePayload
} from '../../types'
import {
    generateDateLabel,
    generateFilterLabels,
    getDateRange,
    getDateRangeLabel,
    getInitialFilters,
    hasAppliedFilters,
    initialFilterState
} from '../../utils/filters'

type IActivityFilterContext = {
    filterMenu: ReturnType<typeof useDisclosure>

    filters: ActivityFilters
    appliedFilterLabels: AppliedFilterLabel[]
    hasAppliedFilters: boolean
    resetFilters: () => void
    removeFilterValue: (filter: AppliedFilterLabel) => void

    dispatch: Dispatch<FiltersReducerAction>
    selectDateFilter: (filter: SelectedDatePayload) => void
    dateFilterOption?: SelectedDateOption
}

type FiltersReducerAction =
    | { type: 'SET_FILTERS'; payload: Partial<ActivityFilters> }
    | { type: 'SELECT_DATE_FILTER'; payload: SelectedDatePayload }
    | { type: 'RESET_FILTERS' }

export function filtersReducer(state: ActivityFilters, action: FiltersReducerAction) {
    switch (action.type) {
        case 'SET_FILTERS':
            return { ...state, ...action.payload }
        case 'SELECT_DATE_FILTER':
            return { ...state, ...getDateRange(state, action.payload) }
        case 'RESET_FILTERS':
            return initialFilterState
        default:
            return state
    }
}

const ActivityFilterContext = createContext<IActivityFilterContext | null>(null)

export const ActivityFilterProvider = ({ children }: PropsWithChildren) => {
    const { t } = useTranslation()
    const filterMenu = useDisclosure()
    const [params] = useUpdateParams()

    const [filters, dispatch] = useReducer(filtersReducer, initialFilterState, getInitialFilters)

    const [dateFilterOption, setDateFilterOption] = useState<SelectedDateOption | undefined>()
    useEffectOnce(() => {
        // When first mounted, check if there's "from" and "to" params
        // If so, then try to set the date filter option to match
        const from = params.get(DateFilterParam.FROM) ?? undefined
        const to = params.get(DateFilterParam.TO) ?? undefined
        const dateRangeLabel = getDateRangeLabel(from, to)
        if (dateRangeLabel) setDateFilterOption(dateRangeLabel)
    })

    const selectDateFilter = useCallback((payload: SelectedDatePayload) => {
        dispatch({ type: 'SELECT_DATE_FILTER', payload })
        setDateFilterOption(payload.selectedOption)
    }, [])

    const resetFilters = useCallback(() => {
        dispatch({ type: 'RESET_FILTERS' })
        setDateFilterOption(undefined)
    }, [])

    const removeDateFilter = useCallback(() => {
        dispatch({
            type: 'SET_FILTERS',
            payload: { [DateFilterParam.FROM]: undefined, [DateFilterParam.TO]: undefined }
        })
        setDateFilterOption(undefined)
    }, [])

    const removeFilterValue = useCallback(
        ({ filterName, value }: AppliedFilterLabel) => {
            if (filterName === 'date') return removeDateFilter()
            const filter = filters[filterName]
            if (Array.isArray(filter)) {
                // Remove the value from the array
                const withValueRemoved = filter.filter(val => val !== value)
                dispatch({ type: 'SET_FILTERS', payload: { [filterName]: withValueRemoved } })
            } else {
                const initialValue = initialFilterState[filterName]
                dispatch({ type: 'SET_FILTERS', payload: { [filterName]: initialValue } })
            }
        },
        [filters, removeDateFilter]
    )

    /** Give each of the filters that have been applied a user-facing label */
    const appliedFilterLabels = useMemo(() => {
        const labels = generateFilterLabels(filters, t)
        const dateLabel = generateDateLabel(dateFilterOption, filters, t)
        if (dateLabel) labels.push(dateLabel)
        return labels
    }, [filters, dateFilterOption, t])

    // Memoize the context value to avoid unnecessary re-renders
    const value = useMemo(
        () => ({
            filterMenu,
            appliedFilterLabels,
            hasAppliedFilters: hasAppliedFilters(filters),
            filters,
            dispatch,
            dateFilterOption,
            selectDateFilter,
            resetFilters,
            removeFilterValue
        }),
        [
            filterMenu,
            appliedFilterLabels,
            filters,
            dateFilterOption,
            selectDateFilter,
            resetFilters,
            removeFilterValue
        ]
    )

    return <ActivityFilterContext.Provider value={value}>{children}</ActivityFilterContext.Provider>
}

export const useActivityFilters = () => {
    const ctx = useContext(ActivityFilterContext)
    if (!ctx) {
        throw Error('useActivityFeedFilters used outside of <ActivityFilterContextProvider />')
    }
    return ctx
}
