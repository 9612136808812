import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const BoldFormatIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BoldFormatIconLight',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M12.7656 8.84375C14.0156 8.02344 14.875 6.57812 14.875 4.9375C14.875 2.35938 12.7656 0.25 10.1875 0.25H1.125C0.773438 0.25 0.5 0.5625 0.5 0.875C0.5 1.22656 0.773438 1.5 1.125 1.5H1.75V16.5H1.125C0.773438 16.5 0.5 16.8125 0.5 17.125C0.5 17.4766 0.773438 17.75 1.125 17.75H10.8125C13.3906 17.75 15.5 15.6797 15.5 13.0625C15.5 11.1875 14.3672 9.58594 12.7656 8.84375ZM3 1.5H10.1875C12.0625 1.5 13.625 3.0625 13.625 4.9375C13.625 6.85156 12.0625 8.375 10.1875 8.375H3V1.5ZM10.8125 16.5H3V9.625H10.8125C12.6875 9.625 14.25 11.1875 14.25 13.0625C14.25 14.9766 12.6875 16.5 10.8125 16.5Z"
            fill="currentColor"
        />
    )
})

export const BoldFormatIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BoldFormatIconRegular',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M12.6484 8.45312C12.7266 8.375 12.8438 8.33594 12.9219 8.25781C13.7812 7.35938 14.2891 6.1875 14.25 4.89844C14.25 2.35938 12.1406 0.25 9.5625 0.25H1.75C1.20312 0.25 0.8125 0.679688 0.8125 1.1875C0.8125 1.73438 1.20312 2.125 1.75 2.125H2.6875V15.875H1.75C1.20312 15.875 0.8125 16.3047 0.8125 16.8125C0.8125 17.3594 1.20312 17.75 1.75 17.75H10.1875C12.9219 17.75 15.1875 15.5234 15.1875 12.75C15.1875 10.9141 14.1328 9.3125 12.6484 8.45312ZM9.5625 2.125C11.125 2.125 12.375 3.375 12.375 4.9375C12.4141 5.67969 12.1016 6.38281 11.5938 6.92969C11.0469 7.47656 10.3438 7.75 9.5625 7.75H4.5625V2.125H9.5625ZM10.1875 15.875H4.5625V9.625H10.1875C11.9062 9.625 13.3125 11.0312 13.3125 12.7891C13.3125 14.5078 11.9062 15.875 10.1875 15.875Z"
            fill="currentColor"
        />
    )
})

export const BoldFormatIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BoldFormatIconSolid',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M13.0391 8.47266C13.7812 7.61328 14.25 6.48047 14.25 5.23047C14.25 2.49609 11.9844 0.230469 9.25 0.230469L1.75 0.269531C1.04688 0.269531 0.5 0.816406 0.5 1.51953C0.5 2.18359 1.04688 2.76953 1.75 2.76953H2.375V15.2695H1.75C1.04688 15.2695 0.5 15.8164 0.5 16.5195C0.5 17.1836 1.04688 17.7695 1.75 17.7695H10.5C13.2344 17.7695 15.5 15.5039 15.5 12.7695C15.5 10.9336 14.4844 9.33203 13.0391 8.47266ZM4.875 2.76953H9.25C10.6172 2.76953 11.75 3.86328 11.75 5.26953C11.75 6.63672 10.6172 7.76953 9.25 7.76953H4.875V2.76953ZM10.5 15.2305H4.875V10.2305H10.5C11.8672 10.2305 13 11.3633 13 12.7305C13 14.0586 11.8672 15.2305 10.5 15.2305Z"
            fill="currentColor"
        />
    )
})
