import { Box, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { useDispatch } from '@missionlabs/api'
import {
    Body,
    Button,
    ContactDetail,
    Dropdown,
    Heading,
    SelectOption,
    Textarea,
    useToast
} from '@missionlabs/react'
import { OutboundWhatsApp, WhatsAppContentType } from '@missionlabs/types'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWhatsAppSendMessage } from 'shared/hooks/useWhatsAppSendMessage'
import { sentMessage } from 'shared/slices/messagingSlice'

interface WhatsAppPanelProps {
    message?: OutboundWhatsApp
}

export const WhatsAppPanel: FC<WhatsAppPanelProps> = ({ message }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { toast } = useToast()
    const [toNumbers, setToNumbers] = useState<SelectOption[]>([])
    const { draftMessage, dispatch, myWhatsAppNumberOptions, templateOptions, send, isSending } =
        useWhatsAppSendMessage()
    const dispatchMessage = useDispatch()

    const { contentType, numberID, toNumber, templateName, text } = draftMessage

    useEffect(() => {
        if (message?.contact) {
            setToNumbers(
                message.contact.phoneNumbers?.map(num => ({
                    label: num.label ?? message.contact?.fullName ?? '',
                    value: num.numberE164
                })) ?? []
            )
        } else {
            setToNumbers([{ label: toNumber, value: toNumber }])
        }
    }, [message, toNumber])

    const handleChangeMessage = useCallback(
        (field: keyof OutboundWhatsApp, value: string) => {
            if (!message) return
            dispatch({ type: 'SET_MESSAGE', draftMessage: { [field]: value } })
        },
        [dispatch, message]
    )

    useEffect(() => {
        if (toNumbers.length > 0 && !toNumber) {
            handleChangeMessage('toNumber', toNumbers[0].value)
        }
    }, [toNumbers, toNumber, handleChangeMessage])

    const handleSendWhatsApp = async () => {
        if (!message) return
        try {
            await send()
            toast({
                title: t('whatsapp.success'),
                status: 'success'
            })
            dispatchMessage(sentMessage(message?.ID))
        } catch (e) {
            toast({
                title: t('whatsapp.error'),
                status: 'error'
            })
        }
    }

    return (
        <Box w="full" py="16px">
            <Box
                w="full"
                border="1px solid"
                borderColor="transparent"
                borderBottomColor={`${colorMode}.tones.stormGrey`}
                pb="16px"
                mb="16px"
                px="24px"
            >
                <ContactDetail
                    name={message?.contact?.fullName ?? message?.toNumber}
                    subText={message?.contact ? message.contact.companyName : 'Unknown'}
                    variant="horizontal-dark"
                    sx={{ width: '100%' }}
                />
            </Box>
            <VStack px="24px" w="full" spacing="16px" align="flex-start">
                <Heading size="h3" variant="bold" color="white">
                    {t('whatsapp.new')}
                </Heading>

                <VStack spacing="8px" w="full" align="flex-start">
                    <Body size="sm" sx={{ color: 'white' }}>
                        {t('whatsapp.sendFrom')}
                    </Body>
                    <Dropdown
                        width="full"
                        variant="dark"
                        options={myWhatsAppNumberOptions}
                        value={numberID}
                        onChange={value => handleChangeMessage('numberID', value)}
                    />
                </VStack>

                {message?.contact && (
                    <VStack spacing="8px" w="full" align="flex-start">
                        <Body size="sm" sx={{ color: 'white' }}>
                            {t('whatsapp.sendTo')}
                        </Body>
                        <Dropdown
                            width="full"
                            variant="dark"
                            options={toNumbers}
                            value={toNumber}
                            onChange={value => handleChangeMessage('toNumber', value)}
                        />
                    </VStack>
                )}

                {contentType === WhatsAppContentType.TEMPLATE && templateOptions && (
                    <VStack spacing="8px" w="full" align="flex-start">
                        <Body size="sm" sx={{ color: 'white' }}>
                            {t('whatsapp.template')}
                        </Body>
                        <Dropdown
                            width="full"
                            variant="dark"
                            options={templateOptions}
                            value={templateName}
                            onChange={value => handleChangeMessage('templateName', value)}
                        />
                    </VStack>
                )}

                {contentType === WhatsAppContentType.TEXT && (
                    <VStack spacing="8px" w="full" align="flex-start">
                        <Body size="sm" color="white">
                            {t('whatsapp.content')}
                        </Body>
                        <Textarea
                            aria-label="whatsapp content"
                            h="168px"
                            placeholder={t('whatsapp.enterMessage')}
                            variant="dark"
                            value={text}
                            onChange={e => handleChangeMessage('text', e.target.value)}
                        />
                    </VStack>
                )}
                <HStack pt="8px" w="full">
                    <Button
                        isLoading={isSending}
                        w="full"
                        isDisabled={!text && !templateName}
                        onClick={handleSendWhatsApp}
                    >
                        {t('whatsapp.sendWhatsApp')}
                    </Button>
                </HStack>
            </VStack>
        </Box>
    )
}
