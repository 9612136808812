import { UserActivity } from '@missionlabs/types'
import { RefObject, useEffect, useRef } from 'react'
import { useIntersection } from 'react-use'
import { isUnread } from 'shared/utils/activities'

import { useHandleReadContext } from '../../ActivitySidebar/ActivitySidebarContext'

/** Maximum amount of times we can retry marking an activity as read before giving up */
const READ_ACTIVITY_MAX_RETRIES = 2

/** How long the activity should be in view before the activity is considered "read" */
const READ_ACTIVITY_TIMEOUT_MS = 1000

/** The activity must be 75% visible to count as "seen" */
const READ_ACTIVITY_VISIBILITY_THRESHOLD = 0.75

const nullRef: RefObject<HTMLDivElement> = { current: null }

/**
 * Waits for the activity to be visible on screen for a certain amount of time, then marks
 * the activity as read (if it isn't already).
 *
 * @param activity The activity. If the activity is already read, this hook will do nothing.
 * @param ref A ref to the activity's element.
 */
export function useReadActivityTimer(activity: UserActivity, ref: RefObject<HTMLDivElement>) {
    const unread = isUnread(activity)
    const handleRead = useHandleReadContext()

    // Performance optimisation: if the activity is already read, disable the intersection observer by providing an empty ref.
    const isInView = useIntersection(unread ? ref : nullRef, {
        root: document.getElementById('activity-feed-content'),
        rootMargin: '0px',
        threshold: READ_ACTIVITY_VISIBILITY_THRESHOLD
    })?.isIntersecting

    const sent = useRef(false) // Avoid sending duplicate requests
    const failures = useRef(0) // Don't retry too many times
    const canMarkAsRead = isInView && isUnread(activity)

    useEffect(() => {
        if (!canMarkAsRead || sent.current || failures.current > READ_ACTIVITY_MAX_RETRIES) return

        const timeout = window.setTimeout(async () => {
            try {
                sent.current = true
                await handleRead(activity.ID)
            } catch (e) {
                console.error('Error marking activity as read', e)

                // Error - reset to allow trying again
                sent.current = false
                failures.current++
            }
        }, READ_ACTIVITY_TIMEOUT_MS)

        return () => {
            clearTimeout(timeout)
        }
    }, [activity.ID, canMarkAsRead, handleRead])
}
