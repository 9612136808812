import { AddressLookup } from '.'
import { HuntGroup } from './hunt-groups'
import { IVRMenu } from './ivr-menus'
import { InheritedNumber } from './numbers'
import { Pod } from './pod'
import { GroupType } from './userGroups'

export enum UserRole {
    END_USER = 'END_USER',
    ADMIN = 'ADMIN',
    NONE = 'NONE'
}

export interface OutboundBarring {
    international_calling: boolean
    premium_calling: boolean
    all_calls: boolean
}

export interface InboundBarring {
    all_calls: boolean
}

export type User = {
    ID: string
    enabled: boolean
    teams: (HuntGroup | IVRMenu)[]
    searchFields: string[]
    callRecording: boolean
    locale: string
    language: string
    timezone: string
    blockSignUpEmails: boolean
    blockedFeatures?: string[] | null
    conferenceNumber: string
    conferenceNumberE164: string
    altConferenceNumberE164: string
    distinctiveRingExternal?: boolean
    destinationsCount: number
    autoLogin?: boolean
    lastLogin: number
    altConferenceNumber: string
    extension: string
    conferenceRoom: string
    email: string
    clientID: string
    subscriptionType: string
    created: string
    brand: string
    firstName?: string
    lastName?: string
    fullName: string
    username?: string
    photoURL?: string | null
    updated: string
    type: string
    subscriptionTypeDisplayName: string
    subscriptionTypes: SubscriptionTypes
    object: string
    // Zeta Specific
    location: string
    podID?: string | null
    pod?: Pod
    tags: string[] | undefined
    isVirtual: boolean
    voicemailPin?: string
    notifications?: {
        channels?: Channels
        events?: Events
        mutedChatChannels?: string[]
    }
    recordingAnnouncement: boolean
    recordVoicemail: boolean
    recordingMode: RecordingMode
    ddiNumber: string | undefined
    subscriptions: Subscriptions
    permissions: Permission
    withholdNumber: boolean | null
    inheritedSettings?: UserInheritedSettings
    horizonCollaborate?: boolean
    blockedNumbers?: string[] // temporarily adding this in here so I can remove it from userSettings
    companyLevelBlockedNumbersListID?: string // temporarily adding this in here so I can remove it from userSettings
    displayName?: string | null
    voicemailNotificationEmail: string | null
    presentationNumber?: string | null
    outgoingNumber?: string
    outboundBarring?: OutboundBarring
    inboundBarring?: InboundBarring
    voicemailPinSet: boolean
    // callRoute?: UserCallRoute
    cliPrivacy?: boolean
    // addresses
    addressID: string
    address: AddressLookup | undefined
    callBarringList?: string
    number: InheritedNumber
    favouriteContacts?: string[]
    subscriptionGroups?: SubscriptionGroup[]
    userGroups?: { groupID: string; type: GroupType }[]
    updateChannel?: string
}

export type NewUser = {
    fullName: string
    email: string
    tags: string[]
    type: 'standard' | 'admin' | 'portal'
    addressID?: string
    extension?: string
    podID?: string
    isVirtual: boolean
    ddiNumber: string
    teams?: { ID: string; name: string; object: 'team' }[]
    subscriptionType: 'standard' | 'pro'
}

export const isUser = (x?: any): x is User => typeof x === 'object' && 'fullName' in x

export type Subscriptions = {
    level: string | 'G1' | 'G2' | 'G3' | 'G4' | 'M'
    features: string[]
}

export interface Permission {
    anonymousCallRejection: boolean
    callBarring: boolean
    callRecording: boolean
    callWaiting: boolean
    cliPresentation: boolean
    clientBlockedNumbers: boolean
    doNotDisturb: boolean
    forwardAlways: boolean
    forwardBusy: boolean
    forwardUnanswered: boolean
    holdMusic: boolean
    lineKeys: boolean
    softKeys: boolean
    speedDial: boolean
    userBlockedNumbers: boolean
    voicemail: boolean
    withholdNumber: boolean
    sendCallsAnywhere: boolean
}

export type UserInheritedSettings = {
    pod: {
        withholdNumber: boolean
        presentationNumber: boolean
        callBarringList?: boolean
    }
    client: Record<string, never>
}

export type RecordingMode =
    | 'always'
    | 'never'
    | 'always-pause-resume'
    | 'on-demand'
    | 'on-demand-user-start'
export enum RecordingModeEnum {
    ALWAYS = 'always',
    NEVER = 'never',
    PAUSE_RESUME = 'always-pause-resume',
    ON_DEMAND = 'on-demand',
    ON_DEMAND_USER_START = 'on-demand-user-start'
}
export type MediaDevice = {
    deviceId: string
    groupId: string
    kind: MediaDeviceKind
    label: string
}
export type Channels = {
    desktopApp: boolean
    android: boolean
    email: boolean
    web: boolean
    webApp: boolean
    sms: boolean
    ios: boolean
}
export type Events = string[]
export type SubscriptionGroup = {
    ID: string
    created: string
    eventTypes: string[]
    integrationSettings: { method?: string; url?: string; webhookURL?: string }
    integrationType: IntegrationType
    object: string
    updated: string
    userID: string
    versionNumber: boolean
}
export type NewSubscriptionGroup = Omit<
    Partial<SubscriptionGroup>,
    'ID' | 'created' | 'object' | 'updated' | 'versionNumber'
>
export type UpdateSubscriptionGroup = Omit<
    Partial<SubscriptionGroup>,
    'created' | 'object' | 'updated' | 'versionNumber'
>
export type IntegrationType = 'http' | 'slack'
export enum IntegrationTypeEnum {
    HTTP = 'http',
    SLACK = 'slack'
}
export type SubscriptionTypes = {
    current: SubscriptionType
    upgrade: SubscriptionType | null
    downgrade: SubscriptionType | null
    upgrades: SubscriptionType[]
    downgrades: SubscriptionType[]
}

export type SubscriptionType = {
    subscriptionType: string
    displayName: string
    description: string
    isDefault: boolean
    price: number
}

export type ResetToken = {
    object: 'resettoken'
    type: string
    ID: string
    companyName: string
    created: string
    email: string
    expires: number
    updated: string
    userID: string
}
