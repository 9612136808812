import { defineStyle, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { ActivityIcon, Body } from '@missionlabs/react'
import { IncomingChannelIcon } from '@missionlabs/react/circleloop'
import { format, isToday, isYesterday } from 'date-fns'
import { cloneElement, Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useActivityInfo } from 'shared/hooks/useActivityInfo'

import { UserActivityItem } from './RecentActivities'

type RecentActivityRowProps = {
    item: UserActivityItem
    index: number
    hoveredIndex: number
    setHoveredIndex: Dispatch<SetStateAction<number>>
}

export const RecentActivityRow: FC<RecentActivityRowProps> = ({
    item,
    index,
    hoveredIndex,
    setHoveredIndex
}) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { activity, onClick } = item
    const { name, direction } = useActivityInfo(activity)

    const activityDetails = useMemo(() => {
        const isInbound = direction === 'inbound'

        return {
            direction: isInbound ? t('activity.inboundCall') : t('activity.outboundCall'),
            icon: (
                <ActivityIcon
                    activityType={activity.activityType}
                    callLength={activity.callLength}
                />
            )
        }
    }, [activity, direction, t])

    const formattedDate = useMemo(() => {
        const parsedDate = new Date(activity.created)

        if (isToday(parsedDate)) {
            return format(parsedDate, 'HH:mm')
        } else if (isYesterday(parsedDate)) {
            return t('Yesterday')
        } else {
            return format(parsedDate, 'dd/MM/yy')
        }
    }, [activity, t])

    const formatDuration = useMemo(() => {
        const callLength = activity.callLength
        if (!callLength) return

        const hours = Math.floor(callLength / 3600)
        const minutes = Math.floor((callLength % 3600) / 60)
        const seconds = callLength % 60

        const hoursStr = hours > 0 ? `${hours}h ` : ''
        const minutesStr = minutes > 0 ? `${minutes}m ` : ''
        return hoursStr + minutesStr + `${seconds}s`
    }, [activity])

    const handleClick = useCallback(() => {
        onClick(
            direction === 'inbound' ? activity.originatingNumber : activity.dialledNumber,
            activity.contact
        )
    }, [activity, direction, onClick])

    const styles = defineStyle({
        h: '78px',
        w: 'full',
        p: '16px',
        my: '-1px',
        _last: { mb: '24px' },
        cursor: 'pointer',
        border: '1px solid transparent',
        borderTopColor: `${colorMode}.tones.stormGrey`,
        borderBottomColor: `${colorMode}.tones.stormGrey`,
        _hover: {
            borderRadius: '4px',
            borderLeftColor: `${colorMode}.tones.stormGrey`,
            borderRightColor: `${colorMode}.tones.stormGrey`,
            // Prevent the next row's border causing the radius to go weird
            '& + .activity-row': { borderTopColor: 'transparent' }
        },
        // Prevent the previous row's border causing the radius to go weird
        [`:nth-of-type(${hoveredIndex})`]: { borderBottomColor: 'transparent' },
        _focus: { bg: `${colorMode}.tones.navy` }
    })

    return (
        <HStack
            spacing="16px"
            sx={styles}
            onClick={handleClick}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(-1)}
            data-testid="activity-row"
            className="activity-row"
        >
            <VStack spacing="4px" flex={1} align="start">
                <Body size="sm" variant="bold" sx={{ color: 'white' }}>
                    {name}
                </Body>
                <HStack spacing="4px">
                    <IncomingChannelIcon color={`${colorMode}.tones.stormGrey`} />
                    {cloneElement(activityDetails.icon, { boxSize: '12px' })}
                    <Body size="sm" sx={{ color: `${colorMode}.tones.periwinkle` }}>
                        {activityDetails.direction}
                    </Body>
                </HStack>
            </VStack>
            <VStack spacing="4px" align="end">
                <Body size="sm" sx={{ color: 'white' }}>
                    {formattedDate}
                </Body>
                <Body size="sm" sx={{ color: `${colorMode}.tones.periwinkle` }}>
                    {formatDuration}
                </Body>
            </VStack>
        </HStack>
    )
}
