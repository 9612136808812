import { IntegrationApp } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

type KVPIntegrations = {
    kvps: {
        integrations: Record<string, { image: string }>
    }
}

interface PutIntegrationQueryArgs {
    userID: string
    appInstallationID: string
    app: Partial<IntegrationApp>
}

function getIntegrationName(name: string) {
    if (name.endsWith('Dev')) return name.split('Dev')[0]
    return name
}

export const buildIntegrationsAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'integrationsAPI',
        tagTypes: ['Integrations'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getIntegrations: builder.query<IntegrationApp[], string>({
                async queryFn(userID, _a, _e, baseQuery) {
                    const apps = await baseQuery(`/users/${userID}/apps`)
                    if (apps.error) return { error: apps.error }

                    const kvps = await baseQuery('/kvpgroups/images')
                    if (kvps.error) return { error: kvps.error }

                    const integrations = (kvps.data as KVPIntegrations).kvps.integrations
                    const response = apps.data as { data: IntegrationApp[] }

                    const data = response.data.map(item => ({
                        ...item,
                        logo: integrations[getIntegrationName(item.source)].image
                    }))
                    return { data }
                },
                providesTags: ['Integrations']
            }),
            putIntegration: builder.mutation<IntegrationApp, PutIntegrationQueryArgs>({
                query: ({ userID, appInstallationID, app }) => ({
                    url: `/users/${userID}/apps/${appInstallationID}`,
                    method: 'PUT',
                    body: app
                }),
                invalidatesTags: ['Integrations']
            })
        })
    })

    return { api, ...api }
}
