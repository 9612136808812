import { useEffect, useMemo, useRef, useState } from 'react'

export const useInputMonitor = (deviceID?: string, label?: string) => {
    const [average, setAverage] = useState<number>(0)
    const volumeInterval = useRef<ReturnType<typeof setInterval>>()
    const getMediaStream = async (deviceID: string) => {
        try {
            clearInterval(volumeInterval.current)

            const constraints: MediaStreamConstraints = { audio: true }
            constraints.audio = { deviceId: { exact: deviceID } }

            return await navigator.mediaDevices.getUserMedia(constraints)
        } catch (e) {
            console.error('Failed to get media stream:', e)
            return null
        }
    }

    const stream = useMemo(() => {
        if (deviceID) return getMediaStream(deviceID)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceID, label])

    useEffect(() => {
        const monitor = async () => {
            const mediaStream = await stream
            if (!mediaStream) return
            monitorStream(mediaStream, setAverage, volumeInterval)
        }

        if (deviceID && stream) {
            monitor()
        }

        const intervalId = volumeInterval.current
        return () => {
            clearInterval(intervalId)
        }
    }, [deviceID, label, stream])

    return average
}

const monitorStream = (
    stream: MediaStream,
    setAverage: (average: number) => void,
    volumeInterval: React.MutableRefObject<ReturnType<typeof setInterval> | undefined>
) => {
    try {
        if (volumeInterval.current) {
            clearInterval(volumeInterval.current)
        }
        const audioContext = new AudioContext()
        const audioSource = audioContext.createMediaStreamSource(stream)
        const analyser = audioContext.createAnalyser()
        analyser.fftSize = 512
        analyser.minDecibels = -127
        analyser.maxDecibels = 0
        analyser.smoothingTimeConstant = 0.4
        audioSource.connect(analyser)
        const volumes = new Uint8Array(analyser.frequencyBinCount)
        const volumeCallback = () => {
            analyser.getByteFrequencyData(volumes)
            let volumeSum = 0
            for (const volume of volumes) volumeSum += volume
            const averageVolume = volumeSum / volumes.length

            // Apply VAD here
            const vadThreshold = 20 // Adjust this threshold as needed
            const isSpeech = averageVolume > vadThreshold

            if (isSpeech) {
                // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
                setAverage(averageVolume)
            } else {
                setAverage(0) // Set average to 0 when there is no speech
            }
        }
        volumeInterval.current = setInterval(volumeCallback, 50)
    } catch (e) {
        console.error('Failed to initialize volume visualizer, simulating instead...', e)
        clearInterval(volumeInterval.current)
    }
}
