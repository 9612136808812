import { selectLiveServicesStatus, useSelector } from '@missionlabs/api'
import { useToast } from '@missionlabs/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useOnLogout } from './useOnLogout'

export const useCallConnectionStatus = () => {
    const { t } = useTranslation()
    const connectionStatus = useSelector(selectLiveServicesStatus)
    const { handleLogout } = useOnLogout()
    const defaultValues = useMemo(() => {
        return { down: false, value: t('callWarning.tryingToReconnect') }
    }, [t])

    const [connectionDown, setConnectionDown] = useState<{ down: boolean; value: string }>(
        defaultValues
    )

    const connectionToast = useRef<string>('')

    const { toast, remove } = useToast()

    useEffect(() => {
        if (connectionStatus === 'FAILED') {
            handleLogout()
        }
        if (connectionStatus === 'DISCONNECTED') {
            if (connectionToast.current) {
                remove(connectionToast.current)
            }

            connectionToast.current = toast({
                status: 'error',
                title: t('callWarning.disconnected'),
                duration: Infinity
            })

            setConnectionDown({ down: true, value: t('callWarning.tryingToReconnect') })
        }

        if (connectionStatus === 'RECONNECTING') {
            if (connectionToast.current) {
                remove(connectionToast.current)
                connectionToast.current = toast({
                    status: 'warning',
                    title: t('callWarning.reconnecting')
                })
            }
            setConnectionDown({ down: true, value: t('callWarning.reconnecting') })
        }

        if (connectionStatus === 'CONNECTED') {
            if (connectionToast.current) {
                remove(connectionToast.current)
                toast({ status: 'success', title: t('callWarning.connectionRestored') })
                connectionToast.current = ''
            }
            setConnectionDown(defaultValues)
        }
    }, [connectionStatus, setConnectionDown, t, handleLogout, defaultValues, toast, remove])

    return { connectionDown }
}
