import { ComponentStyleConfig } from '@chakra-ui/react'

export const SpinnerStyles: ComponentStyleConfig = {
    defaultProps: {
        size: 'lg',
        variant: 'brand'
    },
    variants: {
        brand: ({ colorMode }) => ({
            color: `${colorMode}.alerts.red`
        }),
        white: ({ colorMode }) => ({
            color: `${colorMode}.tones.white`
        })
    },
    sizes: {
        sm: { boxSize: '16px' },
        md: { boxSize: '40px' },
        lg: { boxSize: '64px' }
    }
}
