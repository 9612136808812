import { UseDisclosureReturn } from '@chakra-ui/react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from 'lexical'
import { FC, useEffect } from 'react'

export const TOGGLE_EMOJI_PICKER_COMMAND: LexicalCommand<void> = createCommand(
    'TOGGLE_EMOJI_PICKER_COMMAND'
)

export interface EmojiPickerPluginProps {
    disclosure: UseDisclosureReturn
}

export const EmojiPickerPlugin: FC<EmojiPickerPluginProps> = ({ disclosure }) => {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                TOGGLE_EMOJI_PICKER_COMMAND,
                () => {
                    disclosure.onToggle()
                    return true
                },
                COMMAND_PRIORITY_EDITOR
            )
        )
    }, [editor, disclosure])

    return null
}
