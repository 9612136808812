import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const AvatarPlaceholderIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AvatarPlaceholderIcon',
    viewBox: '0 0 104 112',
    path: (
        <path
            d="M52 59.5C68.25 59.5 81.7143 46.252 81.7143 29.75C81.7143 13.4805 68.25 0 52 0C35.5179 0 22.2857 13.4805 22.2857 29.75C22.2857 46.252 35.5179 59.5 52 59.5ZM63.6071 70.6562H40.1607C17.875 70.6562 0 88.7852 0 111.098C0 115.514 3.48214 119 7.89286 119H95.875C100.286 119 104 115.514 104 111.098C104 88.7852 85.8929 70.6562 63.6071 70.6562Z"
            fill="currentColor"
        />
    )
})
