import { selectPreferences, updatePreferences, useDispatch, useSelector } from '@missionlabs/api'
import { PreviewVideo, useBackgroundFilter, useMeetingContext } from '@missionlabs/meetings'
import { Dropdown, FormControl } from '@missionlabs/react'
import { Grid9IconSolid, VideoIconSolid } from '@missionlabs/react/zeta'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'
import { useDevicesContext } from 'shared/components/DevicesContext'

import { useBackgroundFilterOptions } from '../hooks/useBackgroundFilterOptions'
import { SetupSection } from './SetupSection'

export interface VideoSettingsProps {
    preview?: boolean
}

export const VideoSettings: FC<VideoSettingsProps> = ({ preview = false }) => {
    const dispatch = useDispatch()
    const ctx = useDevicesContext()
    const meeting = useMeetingContext()
    const { t } = useTranslation()

    const preferences = useSelector(selectPreferences)

    const videoRef = useRef<HTMLVideoElement>(null)

    const { isBackgroundBlurSupported } = useBackgroundFilter(
        handleBackgroundFilterChange,
        preview ? videoRef : undefined,
        !!ctx.cameraDeviceID
    )
    const backgroundFilterOptions = useBackgroundFilterOptions()

    function handleBackgroundFilterChange(newFilter: string) {
        dispatch(updatePreferences({ videoBackgroundFilter: newFilter }))
    }

    function handleCameraChange(value: string) {
        dispatch(updatePreferences({ cameraDeviceID: value }))
    }

    useEffectOnce(() => {
        if (preview) meeting.preview()
    })

    return (
        <SetupSection title={t('Video Settings')}>
            <FormControl name="camera" label={t('Camera')} variant="dark">
                <Dropdown
                    value={ctx.cameraDeviceID}
                    options={ctx.cameraDevices}
                    placeholder={t('No device selected')}
                    isDisabled={ctx.cameraDevices.length <= 0}
                    leftIcon={<VideoIconSolid boxSize="20px" p="2px" />}
                    onChange={handleCameraChange}
                />
            </FormControl>
            <FormControl name="background" label={t('Background')} variant="dark">
                <Dropdown
                    layout="grid"
                    value={preferences?.videoBackgroundFilter}
                    options={backgroundFilterOptions}
                    placeholder={t('None')}
                    isDisabled={ctx.cameraDevices.length <= 0 || !isBackgroundBlurSupported}
                    leftIcon={<Grid9IconSolid boxSize="20px" p="3.33px" />}
                    onChange={handleBackgroundFilterChange}
                />
            </FormControl>
            {!!ctx.cameraDeviceID && preview && <PreviewVideo ref={videoRef} />}
        </SetupSection>
    )
}
