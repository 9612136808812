import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const RefreshIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'RefreshIconLight',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M8.71094 0.289062C11.8359 0.289062 14.6484 1.89062 16.25 4.54688V0.875C16.25 0.5625 16.5234 0.25 16.875 0.25C17.1875 0.25 17.5 0.5625 17.5 0.875V5.875C17.5 6.22656 17.1875 6.5 16.875 6.5H11.875C11.5234 6.5 11.25 6.22656 11.25 5.875C11.25 5.5625 11.5234 5.25 11.875 5.25H15.1953C13.8672 2.94531 11.4062 1.5 8.75 1.5C4.60938 1.5 1.25 4.89844 1.25 9C1.25 13.1406 4.60938 16.5 8.75 16.5C11.0547 16.5 13.2031 15.4844 14.6484 13.6484C14.8438 13.375 15.2344 13.2969 15.5078 13.5312C15.7812 13.7266 15.8203 14.1172 15.625 14.3906C13.9453 16.5391 11.4453 17.75 8.71094 17.75C3.90625 17.75 0 13.8438 0 9C0 4.19531 3.90625 0.289062 8.71094 0.289062Z"
            fill="currentColor"
        />
    )
})

export const RefreshIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'RefreshIconRegular',
    viewBox: '0 0 19 20',
    path: (
        <path
            d="M18.125 1.875V8.125C18.125 8.67188 17.6953 9.0625 17.1875 9.0625H10.9375C10.3906 9.0625 10 8.67188 10 8.125C10 7.61719 10.3906 7.1875 10.9375 7.1875H14.8438C13.6719 4.96094 11.3281 3.4375 8.75 3.4375C4.92188 3.4375 1.875 6.52344 1.875 10.3125C1.875 14.1406 4.92188 17.1875 8.75 17.1875C10.3516 17.1875 11.9141 16.6406 13.125 15.625C13.5156 15.2734 14.1016 15.3516 14.4531 15.7422C14.7656 16.1328 14.7266 16.7188 14.3359 17.0703C12.7734 18.3594 10.7812 19.0625 8.71094 19.0625C3.90625 19.0625 0 15.1562 0 10.3125C0 5.50781 3.90625 1.60156 8.71094 1.60156C11.8359 1.60156 14.6875 3.28125 16.25 5.85938V1.875C16.25 1.36719 16.6406 0.9375 17.1875 0.9375C17.6953 0.9375 18.125 1.36719 18.125 1.875Z"
            fill="currentColor"
        />
    )
})

export const RefreshIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'RefreshIconSolid',
    viewBox: '0 0 19 20',
    path: (
        <path
            d="M18.125 2.1875V7.8125C18.125 8.51562 17.5391 9.0625 16.875 9.0625H11.25C10.5469 9.0625 10 8.51562 10 7.8125C10 7.14844 10.5469 6.5625 11.25 6.5625H13.7109C12.5391 5.03906 10.7031 4.10156 8.71094 4.10156C5.27344 4.10156 2.5 6.875 2.5 10.3125C2.5 13.7891 5.27344 16.5625 8.71094 16.5625C10.0781 16.5625 11.3672 16.1328 12.4609 15.3125C13.0078 14.9219 13.7891 15.0391 14.2188 15.5859C14.6484 16.1328 14.5312 16.9141 13.9844 17.3438C12.4609 18.4766 10.625 19.0625 8.71094 19.0625C3.90625 19.0625 0 15.1562 0 10.3125C0 5.50781 3.90625 1.60156 8.71094 1.60156C11.4453 1.60156 13.9453 2.85156 15.625 4.96094V2.1875C15.625 1.52344 16.1719 0.9375 16.875 0.9375C17.5391 0.9375 18.125 1.52344 18.125 2.1875Z"
            fill="currentColor"
        />
    )
})
