import { Box, useColorMode } from '@chakra-ui/react'
import { useDispatch } from '@missionlabs/api'
import { Channel, ChatMessage, UnreadThread } from '@missionlabs/types'
import { forwardRef, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isSection } from 'shared/hooks/useSectionTitle'
import { chatAPI, useGetChannelByIDMutation, useGetMessageMutation } from 'shared/store'

import { ChatThreadRow } from './ChatThreadRow'
import { ChatThreadRowHeader } from './ChatThreadRowHeader'
import { UnreadThreadsLabel } from './UnreadThreadsLable'
import { UnreadThreadsSkeleton } from './UnreadThreadsSkeleton'

export interface ChatHistoryUnreadThreadsRendererProps {
    data: UnreadThread[]
    index: number
    isThread?: boolean
    unreadCount: number
}

export const ChatHistoryUnreadThreadsRenderer = forwardRef<
    HTMLDivElement,
    ChatHistoryUnreadThreadsRendererProps
>(({ data, index, isThread }, ref) => {
    const item = data[index]
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { colorMode } = useColorMode()
    const [getMessage, { isLoading: messageLoading }] = useGetMessageMutation()
    const [getChannel, { isLoading: channelLoading }] = useGetChannelByIDMutation()
    const [parentMessageFallback, setParentMessageFallback] = useState<ChatMessage>()
    const [channelFallBack, setChannelFallback] = useState<Channel>()
    const isLoading = messageLoading || channelLoading
    const gotParentMessage = item?.parentMessage || parentMessageFallback
    const gotChannel = item?.channel || channelFallBack

    useMemo(() => {
        if (!item.parentMessage) {
            getMessage(item.parentMessageID)
                .unwrap()
                .then(parentMessage => setParentMessageFallback(parentMessage))
        }
    }, [getMessage, item])

    useMemo(() => {
        if (!item.channel && item.channelID) {
            getChannel(item.channelID)
                .unwrap()
                .then(channel => setChannelFallback(channel))
        }
    }, [getChannel, item.channel, item.channelID])

    if (isSection(item) || isThread) {
        return null
    }

    if (isLoading || !gotParentMessage || !gotChannel) return <UnreadThreadsSkeleton />

    return (
        <Box
            ref={ref}
            minWidth={'1000px'}
            data-index={index}
            style={{ position: 'relative' }}
            borderBottom={'1px solid'}
            borderColor={`${colorMode}.tones.periwinkle`}
            pt="24px"
            pb="30px"
            _hover={{ bg: `${colorMode}.tones.ghostWhite`, cursor: 'pointer' }}
            onClick={() => {
                dispatch(
                    chatAPI.util.updateQueryData('getUnreadThreads', undefined, draft => {
                        draft.unreadCount -= item.messages.length
                        draft.threads = draft.threads.filter(
                            thread => item.parentMessageID !== thread.parentMessageID
                        )
                        return draft
                    }) as any
                )
                navigate(
                    `/chat/${item?.channel?.ID || item?.channelID}/thread/${item.parentMessageID}`
                )
            }}
        >
            <ChatThreadRowHeader
                channel={item?.channel || channelFallBack}
                unreadCount={item.messages.length}
            />
            <ChatThreadRow
                item={item?.parentMessage || parentMessageFallback!}
                unreadCount={item.messages.length - 1}
            />
            <ChatThreadRow
                item={item.messages[item.messages.length - 1]}
                newTag
                unreadCount={item.messages.length - 1}
            />
            <UnreadThreadsLabel unreadCount={item.messages.length - 1} />
        </Box>
    )
})
