import {
    NavbarItem as MLNavbarItem,
    NavbarItemProps as MLNavbarItemProps
} from '@missionlabs/react'
import { FC, PropsWithChildren } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

export interface NavbarItemProps extends Omit<NavLinkProps, 'to'> {
    to?: NavLinkProps['to']
    noStyles?: MLNavbarItemProps['noStyles']
}

export const NavbarItem: FC<PropsWithChildren<NavbarItemProps>> = ({
    to,
    noStyles,
    children,
    ...props
}) => {
    if (!to) return <MLNavbarItem noStyles={noStyles}>{children}</MLNavbarItem>

    return (
        <NavLink to={to} {...props}>
            {({ isActive }) => (
                // remove data-active prop if isActive is false
                <MLNavbarItem noStyles={noStyles} data-active={isActive || undefined}>
                    {children}
                </MLNavbarItem>
            )}
        </NavLink>
    )
}
