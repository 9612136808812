import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const WavyLines: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'WavyLines',
    viewBox: '0 0 72 14',
    path: (
        <g>
            <path
                d="M-8 0L-5.99383 1.33912C-3.98765 2.65818 0.0246906 5.36651 4.03704 5.8831C8.04938 6.41975 12.0617 4.81481 16.0741 3.47569C20.0864 2.15664 24.0988 1.05324 28.1111 1.33912C32.1235 1.60494 36.1358 3.20988 40.1481 4.81482C44.1605 6.41975 48.1728 8.02469 52.1852 8.56134C56.1975 9.07793 60.2099 8.57639 62.216 8.29051L64.2222 8.02469V14.4444H62.216C60.2099 14.4444 56.1975 14.4444 52.1852 14.4444C48.1728 14.4444 44.1605 14.4444 40.1481 14.4444C36.1358 14.4444 32.1235 14.4444 28.1111 14.4444C24.0988 14.4444 20.0864 14.4444 16.0741 14.4444C12.0617 14.4444 8.04938 14.4444 4.03704 14.4444C0.0246906 14.4444 -3.98765 14.4444 -5.99383 14.4444H-8V0Z"
                fill="currentColor"
            />
        </g>
    )
})
