import { HStack, useColorMode, VStack } from '@chakra-ui/react'
import {
    Button,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    ErrorMessage,
    FormProvider,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useMeasure,
    useYupForm
} from '@missionlabs/react'
import { AttendeeDetails } from '@missionlabs/types'
import { isValidEmail } from '@missionlabs/utils'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAddEditMeetingSchema } from '../hooks/useAddEditMeetingSchema'
import { AddEditMeetingFormFields } from './AddEditMeetingDrawer'
import { SelectedForm } from './SelectedForm'
import { SharedForm } from './SharedForm'
import { ShareDirectoryForm } from './ShareDirectoryForm'

type MeetingShareFormFields = Pick<AddEditMeetingFormFields, 'invitedUsers'> & {
    alreadyInvitedUsers: AttendeeDetails[]
}

type EditMeetingDrawerContentProps = {
    onSubmit: (data: MeetingShareFormFields) => void
    onClose: () => void
    isLoading?: boolean
    values: MeetingShareFormFields
}

export const ShareMeetingDrawerContent: FC<EditMeetingDrawerContentProps> = ({
    values,
    onClose,
    onSubmit,
    isLoading
}) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()
    const [tabIndex, setTabIndex] = useState(0)
    const [headerRef, { blockSize: headerHeight }] = useMeasure<HTMLDivElement>()
    const [footerRef, { blockSize: footerHeight }] = useMeasure<HTMLDivElement>()

    const { meetingShareSchema } = useAddEditMeetingSchema()

    const methods = useYupForm<MeetingShareFormFields>(meetingShareSchema, {
        values: { invitedUsers: [], alreadyInvitedUsers: values?.alreadyInvitedUsers }
    })

    const {
        formState: { isValid },
        watch
    } = methods

    const { invitedUsers } = watch()

    const isExternalSelected = useMemo(() => {
        return (invitedUsers ?? []).some(({ userID }) => isValidEmail(userID ?? ''))
    }, [invitedUsers])

    return (
        <DrawerContent>
            <DrawerHeader ref={headerRef}>{t('scheduledMeeting.share.title')}</DrawerHeader>
            <DrawerBody>
                <FormProvider {...methods}>
                    <VStack
                        as="form"
                        h="100%"
                        spacing="16px"
                        align="stretch"
                        justify="space-between"
                        onSubmit={methods.handleSubmit(onSubmit)}
                        bg={`${colorMode}.tones.ghostWhite`}
                    >
                        <Tabs
                            index={tabIndex}
                            onChange={index => {
                                setTabIndex(index)
                            }}
                            variant="pill"
                            isLazy
                            height={`calc(100% - ${headerHeight}px - ${footerHeight}px)`}
                            isFitted
                        >
                            <TabList justifyContent={'center'} p="24px 24px 0px 24px" mx="24px">
                                <Tab>{t('Directory')}</Tab>
                                <Tab>{t('Selected')}</Tab>
                                <Tab>{t('Shared')}</Tab>
                            </TabList>

                            <TabPanels h="100%">
                                <TabPanel h="100%">
                                    <ShareDirectoryForm />
                                </TabPanel>
                                <TabPanel h="100%">
                                    <SelectedForm />
                                </TabPanel>
                                <TabPanel h="100%">
                                    <SharedForm />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>

                        <VStack ref={footerRef} w={'100%'}>
                            {isExternalSelected && (
                                <ErrorMessage error={t('scheduledMeeting.externalShareWarning')} />
                            )}
                            <HStack px="24px" pb="24px" spacing="24px" align="inherit" w={'100%'}>
                                <Button
                                    w="full"
                                    size="lg"
                                    variant="secondary"
                                    isDisabled={isLoading}
                                    onClick={onClose}
                                >
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    w="full"
                                    size="lg"
                                    variant="primary"
                                    isDisabled={!isValid}
                                    isLoading={isLoading}
                                    data-testid="submit-form"
                                >
                                    {t('Share')}
                                </Button>
                            </HStack>
                        </VStack>
                    </VStack>
                </FormProvider>
            </DrawerBody>
        </DrawerContent>
    )
}
