import { ChatMessage } from '@missionlabs/types'
import { createContext } from 'react'

interface IChatContext {
    enabledFeatures: {
        threads: boolean
        markUnread: boolean
    }
    isGuest: boolean
    messageForwarding?: {
        forwardMessage: ChatMessage | null
        setForwardMessage(message: ChatMessage | null): void
    }
    search: {
        searchOpen: boolean
        setSearchOpen(open: boolean): void
        searchTerm: string
        setSearchTerm(searchTerm: string): void
    }
}

export const ChatContext = createContext<IChatContext>({
    enabledFeatures: { threads: true, markUnread: true },
    isGuest: false,
    search: {
        searchOpen: false,
        setSearchOpen: () => {},
        searchTerm: '',
        setSearchTerm: () => {}
    }
})
