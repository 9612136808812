import {
    CallMonitoringEvent,
    CallParkNotification,
    CallUnParkNotification,
    DirectoryEntry,
    EventGroup,
    SingleState
} from '@missionlabs/types'

import { CallStatus } from './CallController'
import { CallSocket } from './CallSocket'
import {
    LiveServices,
    LiveServicesConfig,
    LiveServicesMessageCallback,
    LiveServicesWebSocketMessage
} from './LiveServices'
import Logger from './Logger'

enum BrainJSONTypes {
    CLI_UPDATE = 'CLIUpdate',
    DISALLOW_HANGUP = 'DisallowHangup',
    NWAY = 'nway'
}

type DisallowHangupData = {
    disallowHangup?: boolean
}

type CLIUpdateData = {
    phoneNumber?: string
    contactName?: string
}

type CLIUpdate = {
    type: BrainJSONTypes.CLI_UPDATE
    data?: CLIUpdateData
}

type DisallowHangup = {
    type: BrainJSONTypes.DISALLOW_HANGUP
    data?: DisallowHangupData
}

type NWayUpdate = {
    type: BrainJSONTypes.NWAY
    data?: NWayMemberEvent
}

type BrainJSON = CLIUpdate | DisallowHangup | NWayUpdate

type WebRTCData = {
    event:
        | 'accepted'
        | 'info'
        | 'calling'
        | 'progress'
        | 'hangup'
        | 'registration_failed'
        | 'incomingcall'
    content?: string | 'BRAIN:ANSWER'
    username?: string
    code?: number
    reason?: string
    brainJSON?: BrainJSON
}

export type RecordingState = 'ON' | 'OFF' | 'PAUSED'
export type Network = 'OFF_NET' | 'ON_NET'

export type NWayMember = {
    legID: string
    numberE164?: string
    displayName?: string
    companyName?: string
    replacementLegID?: string
}

export interface NWayMemberEvent extends NWayMember {
    event: 'answer' | 'hangup'
}

export type CallDetails = {
    alphaTag?: string
    autoAnswer?: boolean
    contactName?: string
    callTraceID: string
    companyName?: string
    direction?: 'inbound' | 'outbound'
    disallowHangup?: boolean
    displayName?: string
    distinctiveRinging?: string
    isTransfer?: boolean
    localNumber?: string
    muted?: boolean
    network?: Network
    nWayMembers?: NWayMember[]
    pageGroupName?: string
    recordingState?: RecordingState
    remoteNumber: string
    teamID?: string
    teamName?: string
    transfereeContact?: DirectoryEntry
    transfereeNumber?: string
    transfererCompanyName?: string
    transfererContactName?: string
    transfererNumber?: string
    transferToContact?: DirectoryEntry
    transferType?: 'attended' | 'blind'
    userGroupName?: string
}

export default class LiveServicesSignaller {
    config: LiveServicesConfig
    pollErrors: number
    ls: LiveServices
    ready: boolean
    keepAlive: any
    callTimer: any
    queue: any[]
    external: {
        webSocketID: string
        source: any
    }[]
    cs?: CallSocket[]
    transfersEnabled?: any
    recordingEnabled?: any
    keepCallAlive?: ReturnType<typeof setTimeout>
    callTimeout?: ReturnType<typeof setTimeout>

    //Events
    onready = (_callTraceID: string) => {}
    onerror = (..._args: any) => {}
    onhangup = (_error: any, _calldetails?: CallDetails) => {}
    onwebrtcup = (_callTraceID: string) => {}
    onremoteanswer = (_callTraceID: string, _offer: any) => {}
    onmessage = (_args: any) => {}
    onevent = (..._args: any) => {}
    oncalling = (_callTraceID: string) => {}
    onproceeding = (..._args: any) => {}
    ondisconnect = (..._args: any) => {}
    onreconnect = (_callTraceID: string) => {}
    onincomingcall = (_calldetails?: CallDetails) => {}
    ontransferenabled = (..._args: any) => {}
    onrecordingenabled = (..._args: any) => {}
    onexternalmessage = (..._args: any) => {}
    onremoteoffer = (_callTraceID: string, _offer: any) => {}
    oncallparkevent = (_json: CallParkNotification | CallUnParkNotification, _txID: string) => {}
    onrecordingmodechanged = (..._args: any) => {}
    oncallgroupvoicemail = (..._args: any) => {}
    onsubscriptionupdated = (..._args: any) => {}
    oncallstatuschange = (_callTraceID: string, _status: CallStatus) => {}
    onpresencesubscriptionchanged = (_users: { users: SingleState[] }) => {}
    oncallmonitoringevent = (_event: CallMonitoringEvent) => {}
    onstatuschange = (_callTraceID: string, _callDetails: CallDetails) => {}
    onnwaymemberupdate = (_callTraceID: string, _data: NWayMemberEvent) => {}

    constructor(ls: LiveServices, config?: any) {
        this.config = config || {}
        this.ls = ls
        this.pollErrors = 0
        this.ready = false
        this.keepAlive = this.config.keepAlive || 10000
        this.callTimer = this.config.callTimer || 8000
        this.queue = []
        this.external = []
        this.initliveservices()
    }

    getCall(callTraceID: string) {
        return this.cs?.find(call => call.callTraceID === callTraceID)
    }

    getCallBySocketID(socketID: string) {
        return this.cs?.find(call => call.webSocketID === socketID)
    }

    getCallBySessionID(sessionID: string) {
        return this.cs?.find(call => call.sessionID === sessionID)
    }

    getCallDetails(callTraceID: string) {
        return this.getCall(callTraceID)?.calldetails
    }

    updateCallDetails(callTraceID: string, callDetails: CallDetails) {
        const index = this.cs?.findIndex(call => call.callTraceID === callTraceID)
        if (index === -1 || index === undefined || !this.cs?.length || !this.cs[index]) {
            return
        }
        this.cs[index].calldetails = callDetails
        return this.onstatuschange(callTraceID, callDetails)
    }

    initliveservices() {
        this.ls.on('call', (args, txID) => this.incomingCall(args, txID))
        this.ls.on('call_parked', (args, txID) => this.callParkEvent(args, txID))
        this.ls.on('call_unparked', (args, txID) => this.callParkEvent(args, txID))
        this.ls.on('call_missed', args => this.missedCall(args))
        this.ls.on('call_recording_mode_changed', args => this.onrecordingmodechanged(args))
        this.ls.on('team_internal_message_received', args => this.oncallgroupvoicemail(args))
        this.ls.on('team_message_received', args => this.oncallgroupvoicemail(args))
        this.ls.on('team_message_transcribed', args => this.oncallgroupvoicemail(args))
        this.ls.on('subscription_presence_changed', args =>
            this.onpresencesubscriptionchanged(args)
        )
        this.ls.on('subscription_presence_changed', this.onpresencesubscriptionchanged)
        this.ls.on('subscription_presence_changed_updated', event => {
            this.onsubscriptionupdated(event)
        })
        this.ls.on('subscription_user_call_monitoring_updated', event => {
            event.type = EventGroup.USER_CALL
            this.oncallmonitoringevent(event)
        })
        this.ls.on('subscription_usergroup_call_monitoring_updated', event => {
            event.type = EventGroup.USER_GROUP_CALL
            this.oncallmonitoringevent(event)
        })
        this.ls.on('subscription_user_call_monitoring', event => {
            event.type = EventGroup.USER_CALL
            this.oncallmonitoringevent(event)
        })
        this.ls.on('subscription_usergroup_call_monitoring', event => {
            event.type = EventGroup.USER_GROUP_CALL
            this.oncallmonitoringevent(event)
        })
        this.ls.on('disconnect', (...args) => this.ondisconnect(args))
        this.ls.on('reconnected', (..._args) => this.onreconnected())
        this.ls.on('fwd', args => this.fwd(args))
    }

    callParkEvent(json: CallParkNotification, txID) {
        this.acknowledgeCall(txID, null)
        this.oncallparkevent(json, txID)
    }

    incomingCall(json, txID?) {
        if (json.eventType === 'call_hangup') {
            const call = this.getCall(json.callTraceID)
            if (call?.calldetails) {
                this.onhangup({}, call.calldetails)
                this.hangup(call?.callTraceID)
            }
            return
        }
        Logger.log('trace', 'incoming call', json, 'WEBSOCKET_INCOMING')
        Logger.putEvent(
            'call/incomingCall/receive',
            {
                dialledNumberE164: json.dialledNumber,
                originatingNumberE164: json.originatingNumber,
                callTraceID: json.callTraceID,
                contactName: json.contactName,
                teamName: json.teamName,
                teamID: json.teamID
            },
            'Received an incoming call notif down the websocket',
            {
                callID: json.callTraceID
            }
        )
        if (json.eventType !== 'incoming_call') return
        this.acknowledgeCall(txID, json.callTraceID)
        this.initinbound(json)
    }

    initinbound(json) {
        const calldetails: CallDetails = {
            alphaTag: json.alphaTag,
            distinctiveRinging: json.distinctiveRinging,
            autoAnswer: json.autoAnswer,
            localNumber: json.dialledNumber,
            remoteNumber: json.originatingNumber,
            callTraceID: json.callTraceID,
            contactName: json.contactName,
            teamName: json.teamName,
            teamID: json.teamID,
            displayName: json.displayName,
            companyName: json.companyName,
            network: json.network,
            pageGroupName: json.pageGroupName,
            isTransfer: json.isTransfer,
            transfereeContact: json.transfereeContact,
            transferType: json.transferType,
            transfererCompanyName: json.transfererCompanyName,
            transfererContactName: json.transfererContactName,
            transfereeNumber: json.transfereeNumber,
            transfererNumber: json.transfererNumber,
            recordingState: 'OFF',
            userGroupName: json.userGroupName,
            disallowHangup: json?.disallowHangup
        }
        const callSocket = new CallSocket(
            {
                ...this.config,
                url: json.webSocketHost || this.ls.getURL(),
                webSocketID: json.webSocketID,
                connectToHost: true
            },
            json.callTraceID
        )
        callSocket.calldetails = { ...calldetails, direction: 'inbound' }
        this.cs = [...(this.cs ?? []), callSocket]
        callSocket.on('webrtc', args => this.messageReceived(args, callSocket.callTraceID))
        callSocket.on('initwebrtc', args => this.initwebrtc(args, callSocket.callTraceID))
        callSocket.authenticate(this.ls.getToken()!, this.ls.getDestinationID()!, [], err => {
            if (err) {
                return this.onerror({
                    type: 'Registration Failed'
                })
            }
        })
    }

    initwebrtc(session: any, callTraceID: string) {
        Logger.log('trace', 'initwebrtc', null, 'WEBSOCKET_INITWEBRTC')
        Logger.putEvent(
            'call/initWebRTC/receive',
            {
                handleID: session.handleID,
                sessionID: session.sessionID
            },
            'received initWebRTCMessage'
        )
        const call = this.getCall(callTraceID)
        if (call) {
            call.sessionID = session.sessionID
            call.handleID = session.handleID
            call.transfersEnabled = session.transfersEnabled
            call.recordingEnabled = session.recordingEnabled
        }
        this.keepCallAlive = setInterval(() => this._keepCallAlive(), this.keepAlive)
        //Set a calltimer in case we don't receive an offer
        if (call?.calldetails?.direction === 'inbound') {
            //
            this.callTimeout = setTimeout(() => {
                if (this.ready) return
                Logger.log(
                    'trace',
                    'timed out waiting for offer on incoming call',
                    null,
                    'WEBSOCKET_TIMEOUT_AWAITING_OFFER'
                )
                this.hangup(callTraceID)
                this.onhangup({ reason: 'timeout' }, call.calldetails)
            }, this.callTimer)
            //
        }
    }

    fwd(json: any) {
        switch (json.event) {
            case 'register':
                return this.registerExternal(json)
            case 'signout':
                return this.removeExternal(json.iFrameWebSocketID)
            default:
                return this.onexternalmessage(json)
        }
    }

    removeExternal(webSocketID: string) {
        const index = this.external.findIndex(function (ex) {
            return ex.webSocketID === webSocketID
        })
        if (index > -1) {
            this.external.splice(index, 1)
        }
    }

    getExternal() {
        return this.external
    }

    registerExternal(json) {
        const registered = !!this.external.find(function (ex) {
            return ex.webSocketID === json.iFrameWebSocketID
        })
        if (!registered) {
            this.external.push({
                webSocketID: json.iFrameWebSocketID,
                source: json.source
            })
        }
        this.sendExternalMessage(
            { event: 'register', heartbeatID: json.heartbeatID },
            json.iFrameWebSocketID
        )
    }

    sendExternalMessage(body, webSocketID?) {
        if (!this.external.length) return
        const external = webSocketID
            ? [webSocketID]
            : this.external.map(function (ex) {
                  return ex.webSocketID
              })
        external.forEach(ex => {
            body.webSocketID = ex
            const message = {
                type: 'fwd',
                txID: this.ls.generateID(),
                body: body
            }
            this.ls.send(message, response => {
                if (response.type === 'error') {
                    this.removeExternal(ex)
                }
            })
        })
    }

    setReady(callTraceID: string) {
        this.ready = true
        this.onready(callTraceID)
    }

    sendOffer(
        offer: any,
        number: string,
        origNumber: string,
        callTraceID: string,
        contact?: { fullName?: string; companyName?: string },
        callPickupID?: string
    ) {
        const body = {
            numberE164: number,
            originatingNumberE164: origNumber,
            offer: offer.sdp,
            callTraceID,
            appAPIVersion: this.config.appAPIVersion || 2,
            callPickupID,
            earlyMediaSupported: true
        }

        Logger.log('debug', 'call trace ID generated ' + body.callTraceID)
        const calldetails: CallDetails = {
            remoteNumber: number,
            localNumber: origNumber,
            callTraceID: body.callTraceID,
            recordingState: 'OFF',
            direction: 'outbound',
            contactName: contact?.fullName,
            companyName: contact?.companyName
        }

        Logger.log('trace', 'sending offer', body.offer, 'WEBSOCKET_OFFER')
        Logger.putEvent(
            'call/webRTCOffer/send',
            { dialledNumberE164: number, originatingNumberE164: origNumber },
            'Sending webRTC Offer to remote'
        )
        this.register(body, callTraceID, calldetails)
    }

    register(body, callTraceID: string, calldetails: CallDetails) {
        const data = {
            type: 'initwebrtc',
            body: body
        }
        const callSocket = new CallSocket(
            {
                ...this.config,
                url: this.ls.getURL(),
                connectToHost: true
            },
            callTraceID
        )

        callSocket.calldetails = calldetails
        this.cs = [...(this.cs ?? []), callSocket]
        callSocket.authenticate(this.ls.getToken()!, this.ls.getDestinationID()!, [], err => {
            if (err)
                return this.onerror({
                    type: 'Registration Failed'
                })
            callSocket?.send(data, response => {
                if (response.type === 'error') {
                    return this.onerror({
                        type: 'Registration Failed'
                    })
                }
            })
            callSocket?.on('webrtc', session =>
                this.messageReceived(session, callSocket.callTraceID)
            )
            callSocket?.on('initwebrtc', session =>
                this.initwebrtc(session, callSocket.callTraceID)
            )
        })
    }

    sendAnswer(callTraceID: string, jsep) {
        const call = this.getCall(callTraceID)
        const request = {
            webrtc: 'message',
            jsep: jsep,
            session_id: call?.sessionID,
            handle_id: call?.handleID,
            body: {
                request: 'accept'
            }
        }
        Logger.log('trace', 'send answer', jsep, 'WEBSOCKET_ANSWER')
        Logger.putEvent('call/webRTCAnswer/send', null, 'sending webrtc answer')
        this.send(callTraceID, request)
    }

    missedCall(json) {
        if (json?.eventType !== 'call_missed') return

        const traceID = json.payload?.callTraceID ?? json.callTraceID
        const call = this.getCall(traceID)
        if (call) {
            this.hangup(call.callTraceID)
        }
    }

    onwebrtcevent(callTraceID: string, jsep, data: WebRTCData) {
        if (!data) {
            return //e.g. DTMF Acks, no need to do anything.
        }

        const call = this.getCall(callTraceID)
        switch (data.event) {
            case 'accepted':
                console.log('📞 Got an offer answer back from the janus server')
                this.setReady(callTraceID)
                if (jsep) this.onremoteanswer(callTraceID, jsep)
                break
            case 'calling':
                this.oncalling(callTraceID)
                break
            case 'progress':
                console.log('📞 Got an offer answer back from the janus server')
                if (jsep) this.onremoteanswer(callTraceID, jsep)
                break
            case 'hangup':
                console.log('hangup message', data.code, data.reason)
                Logger.log(
                    'trace',
                    'received hangup with code ' + data.code,
                    null,
                    'WEBSOCKET_HANGUP'
                )
                this.onhangup({ code: data.code, reason: data.reason }, call?.calldetails)
                this.hangup(callTraceID)
                break
            case 'registration_failed':
                this.destroy(callTraceID)
                this.onerror({ code: data.code, reason: data.reason })
                break
            case 'incomingcall':
                console.log('got an remote offer')
                this.clearCallTimeout()
                this.setReady(callTraceID)
                this.onincomingcall(call?.calldetails)
                this.onremoteoffer(callTraceID, jsep)
                if (this.transfersEnabled) {
                    Logger.log('info', 'transfers enabled')
                    this.ontransferenabled()
                }
                if (this.recordingEnabled) {
                    Logger.log('info', 'recording enabled')
                    this.onrecordingenabled()
                }
                break
            case 'info':
                console.log(`🧠 ${data.content}`)
                if (data?.content?.includes('BRAIN:JSON') && data.brainJSON) {
                    if (data.brainJSON.type === BrainJSONTypes.CLI_UPDATE) {
                        const call = this.getCall(callTraceID)
                        if (call?.calldetails) {
                            const callDetails = {
                                ...call?.calldetails,
                                contactName: data.brainJSON?.data?.contactName,
                                displayName: data.brainJSON?.data?.contactName,
                                remoteNumber:
                                    data.brainJSON?.data?.phoneNumber ||
                                    call?.calldetails.remoteNumber
                            }
                            return this.updateCallDetails(callTraceID, callDetails)
                        }
                    } else if (data.brainJSON.type === BrainJSONTypes.DISALLOW_HANGUP) {
                        const call = this.getCall(callTraceID)
                        if (call?.calldetails) {
                            const callDetails = {
                                ...call?.calldetails,
                                disallowHangup: data.brainJSON?.data?.disallowHangup
                            }
                            return this.updateCallDetails(callTraceID, callDetails)
                        }
                    } else if (data.brainJSON.type === BrainJSONTypes.NWAY && data.brainJSON.data) {
                        return this.onnwaymemberupdate(callTraceID, data.brainJSON.data)
                    }
                }

                if (data.content === 'BRAIN:ANSWER') {
                    return this.onwebrtcup(callTraceID)
                }
                if (data.content === 'TRANSFER_CANCELLED') {
                    const call = this.getCall(callTraceID)
                    if (call?.calldetails) {
                        this.updateCallDetails(callTraceID, {
                            ...call?.calldetails,
                            isTransfer: false
                        })
                    }
                    return this.oncallstatuschange(callTraceID, 'CONNECTED')
                }
                if (data.content === 'TRANSFER_COMPLETED') {
                    const call = this.getCall(callTraceID)
                    if (call?.calldetails) {
                        this.updateCallDetails(callTraceID, {
                            ...call?.calldetails,
                            isTransfer: false
                        })
                        return this.oncallstatuschange(callTraceID, 'CONNECTED')
                    }
                }

                if (data.content) {
                    const state = this.extractRecordingState(data.content)
                    const call = this.getCall(callTraceID)
                    if (call?.calldetails) {
                        call.calldetails.recordingState = state
                        this.updateCallDetails(callTraceID, {
                            ...call?.calldetails
                        })
                    }
                }
                break
        }
        this.onevent(data.event)
    }

    extractRecordingState(content: string): RecordingState | undefined {
        const regex = /^BRAIN:CALL_RECORDING_(\D*)/
        const allowedStates = /^(ON|OFF|PAUSED)$/
        const state = regex.exec(content)

        if (state && allowedStates.test(state[1])) return state[1] as RecordingState
        return undefined
    }

    checkForUserRegex(regex: 'left' | 'joined', content: string) {
        return regex === 'left' ? /^USER_LEFT.*/.test(content) : /^USER_JOINED.*/.test(content)
    }

    messageReceived(
        json: {
            session_id: string
            plugindata: any
            jsep: any
            type: string
            receiving: any
            webrtc: string
            code: any
        },
        callTraceID: string
    ) {
        let plugindata

        Logger.log('debug', 'webrtc message', json)
        if (json?.plugindata?.data?.result) {
            plugindata = json.plugindata.data.result
        }
        const call = this.getCall(callTraceID)
        switch (json.webrtc) {
            case 'event':
                this.onwebrtcevent(callTraceID, json.jsep, plugindata)
                break
            case 'webrtcup':
                console.log('📞 Janus: Webrtc connection established')
                if (call?.calldetails?.direction === 'inbound') {
                    this.onwebrtcup(callTraceID)
                }
                break
            case 'media':
                console.log(`🔈 Media: ${json.type} - Receiving: ${json.receiving}`)
                break
            case 'timeout':
            case 'hangup':
                //First check session id's match up
                if (call?.sessionID !== json.session_id) return
                Logger.log(
                    'trace',
                    'received hangup message, code:' + json.code,
                    null,
                    'WEBSOCKET_HANGUP'
                ) //<-FYI I think we are receiving this hangup after the first one, by which point calldetails is clear so this does'nt show in logs (not an issue)
                this.hangup(call.callTraceID)
                this.onhangup({ reason: 'hangup' })
                break
        }
        this.onmessage(json)
    }

    acknowledgeCall(txID: string, traceID) {
        Logger.log('info', 'acknowledgeCall')
        Logger.log(
            'trace',
            'acknowledge incoming call',
            { callTraceID: traceID, txID: txID },
            'WEBSOCKET_POST_ACK'
        )
        this.ls.send({
            type: 'ack',
            txID: txID,
            body: {
                transaction: txID
            }
        })
    }

    hold(callTraceID: string, callback: LiveServicesMessageCallback) {
        const message = {
            type: 'brain',
            body: {
                method: 'hold'
            }
        }
        this.getCall(callTraceID)?.send(message, callback)
    }

    unhold(callTraceID: string, callback: LiveServicesMessageCallback) {
        const message = {
            type: 'brain',
            body: {
                method: 'unhold'
            }
        }
        this.getCall(callTraceID)?.send(message, callback)
    }

    sendICECandidate(callTraceID: string, candidate) {
        const call = this.getCall(callTraceID)
        const theICECandidate = {
            webrtc: 'trickle',
            candidate: candidate,
            session_id: call?.sessionID,
            handle_id: call?.handleID
        }
        Logger.log('trace', 'send ice candidate', theICECandidate, 'WEBSOCKET_ICE')
        this.send(callTraceID, theICECandidate)
    }

    sendICEComplete(callTraceID: string) {
        const call = this.getCall(callTraceID)
        const ICEComplete = {
            webrtc: 'trickle',
            candidate: {
                completed: true
            },
            session_id: call?.sessionID,
            handle_id: call?.handleID
        }
        this.send(callTraceID, ICEComplete)
    }

    sendDTMF(callTraceID: string, digit) {
        const call = this.getCall(callTraceID)
        if (!digit.toString) {
            console.warn(
                'sendDTMF : invalid arg, expects an arg that is a string or can be converted to a string'
            )
            return
        }
        const digitString = digit.toString()
        const allowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '#', '*']
        if (allowed.indexOf(digitString) === -1) {
            console.warn('sendDTMF : invalid arg, expects 0-9, *, #')
            return
        }
        const msg = {
            janus: 'message',
            session_id: call?.sessionID,
            handle_id: call?.handleID,
            body: {
                request: 'dtmf_info',
                digit: digitString
            }
        }
        this.send(callTraceID, msg)
    }

    sendPresenceSubscription(eventGroup: string, userIDs?: string[], userGroupIDs?: string[]) {
        if (!userIDs && !userGroupIDs) return

        const message = {
            type: 'update_subscription',
            body: {
                event_group: eventGroup,
                userIDs,
                userGroupIDs
            }
        }
        this.ls.send(message, () => {})
    }

    declineCall(callTraceID: string) {
        const call = this.getCall(callTraceID)
        if (call?.sessionID) {
            const request = {
                webrtc: 'message',
                session_id: call?.sessionID,
                handle_id: call?.handleID,
                body: {
                    request: 'decline'
                }
            }
            this.send(callTraceID, request)
        }
        this.destroy(callTraceID)
        this.checkQueue()
    }

    hangup(callTraceID: string) {
        const call = this.getCall(callTraceID)
        if (call?.sessionID) {
            const request = {
                webrtc: 'message',
                session_id: call?.sessionID,
                handle_id: call?.handleID,
                body: {
                    request: 'hangup'
                }
            }
            this.send(callTraceID, request)
        }
        this.destroy(callTraceID)
        this.checkQueue()
    }

    clearCallTimeout() {
        clearTimeout(this.callTimeout)
    }

    destroy(callTraceID: string) {
        this.recordingEnabled = null
        this.transfersEnabled = null
        this.ready = false
        clearInterval(this.keepCallAlive)
        this.clearCallTimeout()

        const call = this.getCall(callTraceID)
        if (this.cs) {
            const request = {
                type: 'closewebrtc',
                txID: call?.generateID(),
                body: {}
            }
            Logger.log('trace', 'destroy call', null, 'WEBSOCKET_CLOSE')
            Logger.putEvent('call/closeWebRTC/send', null, 'sending closewebrtc to end the call')
            call?.send(request)
            call?.close()
            this.cs = this.cs.filter(call => call.callTraceID !== callTraceID)
        }
    }

    destroyAllCallSockets() {
        if (this.cs) {
            this.cs.forEach(callSocket => {
                this.hangup(callSocket.callTraceID)
            })
        }
    }

    send(callTraceID: string, data, callback?) {
        const call = this.getCall(callTraceID)
        data.transaction = call?.generateID()
        const message = {
            type: 'webrtc',
            body: data
        }
        call?.send(message, callback)
    }

    _keepCallAlive(callback?) {
        if (!this.cs?.length) {
            if (callback) callback(true)
            return clearInterval(this.keepCallAlive)
        }

        this.cs?.forEach(call => {
            const body = {
                webrtc: 'keepalive',
                session_id: call.sessionID
            }

            this.send(call.callTraceID, body, data => {
                if (data && data.type === 'error') {
                    clearInterval(this.keepCallAlive)
                    if (callback) callback(true)
                }
                if (callback) callback(false)
            })
        })
    }

    onreconnected() {
        this._keepCallAlive()
        this.cs?.forEach(call => {
            this.onreconnect(call.callTraceID)
        })
    }

    nWayInitiate(
        callTraceID: string,
        userID?: string | undefined,
        teamID?: string | undefined,
        numberE164?: string | undefined,
        callback?: LiveServicesMessageCallback
    ) {
        const message = {
            type: 'brain',
            body: {
                callTraceID,
                method: 'init_nway',
                userID,
                teamID,
                numberE164
            }
        }
        this.getCall(callTraceID)?.send(message, callback)
    }

    nWayHangup(
        callTraceID: string,
        legID: string,
        userID?: string | undefined,
        teamID?: string | undefined,
        numberE164?: string | undefined,
        callback?: LiveServicesMessageCallback
    ) {
        const message = {
            type: 'brain',
            body: {
                callTraceID,
                method: 'hangup_nway',
                legID,
                userID,
                teamID,
                numberE164
            }
        }
        this.getCall(callTraceID)?.send(message, callback)
    }

    transfer(
        callTraceID: string,
        userID?: string | undefined,
        teamID?: string | undefined,
        numberE164?: string | undefined,
        isUnattended?: boolean,
        callback?: LiveServicesMessageCallback
    ) {
        const message = {
            type: 'brain',
            body: {
                callTraceID,
                method: isUnattended ? 'blind_transfer' : 'init_transfer',
                userID,
                teamID,
                numberE164,
                unattended: isUnattended
            }
        }
        this.sendTransferMessage(message, callback)
    }

    confirmTransfer(callTraceID: string, callback: LiveServicesMessageCallback) {
        const message = {
            type: 'brain',
            body: {
                callTraceID,
                method: 'complete_transfer'
            }
        }
        this.sendTransferMessage(message, callback)
    }

    cancelTransfer(callTraceID: string, callback: LiveServicesMessageCallback) {
        const message = {
            type: 'brain',
            body: {
                callTraceID,
                method: 'cancel_transfer'
            }
        }
        this.sendTransferMessage(message, callback)
    }

    blindTransfer(
        callTraceID: string,
        userID?: string | undefined,
        teamID?: string | undefined,
        numberE164?: string | undefined,
        callback?: LiveServicesMessageCallback
    ) {
        const message = {
            type: 'brain',
            body: {
                callTraceID,
                method: 'blind_transfer',
                userID,
                teamID,
                numberE164
            }
        }
        this.sendTransferMessage(message, callback)
    }

    sendTransferMessage(
        message: LiveServicesWebSocketMessage,
        callback?: LiveServicesMessageCallback
    ) {
        const call = this.getCall(message.body.callTraceID)
        if (call) {
            call.send(message, callback)
        } else {
            // When trying to transfer a call that is happening on another device, we don't
            // have access to the call socket, so we send the message directly to liveservices
            this.ls.send(message, callback)
        }
    }

    saveRecording(callTraceID: string, callback: LiveServicesMessageCallback) {
        const message = {
            type: 'brain',
            body: {
                method: 'save_recording'
            }
        }
        this.getCall(callTraceID)?.send(message, callback)
    }

    stopRecording(callTraceID: string, callback: LiveServicesMessageCallback) {
        const message = {
            type: 'brain',
            body: {
                method: 'stop_recording'
            }
        }
        this.getCall(callTraceID)?.send(message, callback)
    }

    pauseRecording(callTraceID: string, callback: LiveServicesMessageCallback) {
        const message = {
            type: 'brain',
            body: {
                method: 'pause_recording'
            }
        }
        this.getCall(callTraceID)?.send(message, callback)
    }

    startRecording(callTraceID: string, callback) {
        const message = {
            type: 'brain',
            body: {
                method: 'start_recording'
            }
        }
        this.getCall(callTraceID)?.send(message, callback)
    }

    resumeRecording(callTraceID: string, callback: LiveServicesMessageCallback) {
        const message = {
            type: 'brain',
            body: {
                method: 'resume_recording'
            }
        }
        this.getCall(callTraceID)?.send(message, callback)
    }

    checkQueue() {
        if (!this.queue.length) return
        const call = this.queue.shift()
        this.initinbound(call)
    }

    parkCall(callTraceID: string, extension: string, callback: LiveServicesMessageCallback) {
        const message = {
            type: 'brain',
            body: {
                method: 'park_call',
                parkType: 'DIRECTED',
                extension
            }
        }
        this.getCall(callTraceID)?.send(message, callback)
    }

    sendConferenceOffer(offer: any, callTraceID: string, origNumber: string) {
        const body = {
            originatingNumberE164: origNumber,
            offer: offer.sdp,
            callTraceID,
            appAPIVersion: this.config.appAPIVersion || 2,
            createConference: true
        }

        const calldetails: CallDetails = {
            callTraceID: body.callTraceID,
            localNumber: origNumber,
            recordingState: 'OFF',
            remoteNumber: '',
            direction: 'outbound'
        }
        Logger.log('trace', 'sending conference offer', body.offer, 'WEBSOCKET_OFFER')

        this.register(body, callTraceID, calldetails)
    }

    parkCallGroup(callTraceID: string, callback: LiveServicesMessageCallback) {
        const message = {
            type: 'brain',
            body: {
                method: 'park_call',
                parkType: 'GROUP'
            }
        }
        this.getCall(callTraceID)?.send(message, callback)
    }

    getTurnConfig(
        callback: LiveServicesMessageCallback<{ uri: string; username: string; password: string }>
    ) {
        const message = {
            type: 'turn_config',
            body: {}
        }
        this.ls.send(message, callback)
    }
}
