import {
    Body,
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay
} from '@missionlabs/react'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthenticatedUser } from 'shared/hooks/useAuthenticatedUser'
import { useMarkAllActivitiesAsReadMutation } from 'shared/store'

import { useActivitySidebarContext } from '../ActivitySidebarContext'

interface MarkAllAsReadDialogProps {}

export const MarkAllAsReadDialog: FC<MarkAllAsReadDialogProps> = () => {
    const { t } = useTranslation()
    const {
        markAllAsReadDialog: { isOpen, onClose }
    } = useActivitySidebarContext()

    const ref = useRef<HTMLButtonElement>(null)

    const [markAllActivitiesAsRead, { isLoading }] = useMarkAllActivitiesAsReadMutation()
    const { data: user } = useAuthenticatedUser()

    const userID = user?.ID

    const handleConfirm = async () => {
        if (!userID) {
            return
        }
        await markAllActivitiesAsRead(userID)
            .unwrap()
            .then(() => onClose())
    }

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>
                    <Body>{t('activities.markAllAsReadDialog.heading')}</Body>
                </DialogHeader>
                <DialogBody>
                    <Body size="sm">{t('activities.markAllAsReadDialog.body')}</Body>
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('Confirm'),
                        variant: 'primary',
                        onClick: handleConfirm
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
