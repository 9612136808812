import { Divider, HStack, VStack } from '@chakra-ui/react'
import { UpdateCardDetailsArgs } from '@missionlabs/api'
import { Button, ErrorMessage, FormProvider, useYupForm, yup } from '@missionlabs/react'
import { Address } from '@missionlabs/types'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentMethodCreateParams } from '@stripe/stripe-js'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { AddPaymentMethodAddress } from './AddPaymentMethodAddress'
import { AddPaymentMethodCard } from './AddPaymentMethodCard'

export type AddPaymentMethodFields = Address & {
    _cardCvc?: string
    _cardExpiry?: string
    _cardNumber?: string
    name?: string
}

export const defaultValues: AddPaymentMethodFields = {
    _cardCvc: '',
    _cardExpiry: '',
    _cardNumber: '',
    name: '',
    line1: '',
    town: '',
    postcode: ''
}

export const acceptedPaymentCountryCodes = [
    'GB',
    'AU',
    'BE',
    'CA',
    'CY',
    'FR',
    'DK',
    'GE',
    'DE',
    'HK',
    'IE',
    'IL',
    'IT',
    'LT',
    'MT',
    'NL',
    'NZ',
    'PL',
    'PT',
    'ES',
    'UA',
    'US'
]

const addPaymentMethodSchema = yup.object({
    _cardCvc: yup.string().required(),
    _cardExpiry: yup.string().required(),
    _cardNumber: yup.string().required(),
    name: yup.string().required(),
    line1: yup.string().required(),
    line2: yup.string(),
    town: yup.string().required(),
    postcode: yup.string().required(),
    county: yup.string(),
    country: yup
        .string()
        .test(
            'is-accepted-country',
            () => `Country not accepted`,
            value => acceptedPaymentCountryCodes.includes((value ?? '').toUpperCase())
        )
        .required()
})

export interface AddPaymentMethodFormProps {
    values?: AddPaymentMethodFields
    error?: unknown
    onClose: () => void
    updateCard: (data: UpdateCardDetailsArgs) => Promise<void>
}

function fieldsToBillingDetails(
    data: AddPaymentMethodFields
): PaymentMethodCreateParams.BillingDetails {
    return {
        name: data.name,
        address: {
            line1: data.line1,
            line2: data.line2,
            city: data.town,
            state: data.county,
            country: data.country,
            postal_code: data.postcode
        }
    }
}

export const AddCardPaymentMethodForm: FC<AddPaymentMethodFormProps> = ({
    values,
    error,
    onClose,
    updateCard
}) => {
    const { t } = useTranslation()

    const methods = useYupForm<AddPaymentMethodFields>(addPaymentMethodSchema, {
        defaultValues: defaultValues,
        values: values
    })
    const {
        formState: { isDirty, isSubmitting }
    } = methods

    const elements = useElements()
    const stripe = useStripe()

    async function handleSubmit(data: AddPaymentMethodFields) {
        try {
            if (elements == null || stripe == null) return
            await updateCard({
                elements,
                stripe,
                billing_details: fieldsToBillingDetails(data)
            })
            return onClose()
        } catch {
            // noop
        }
    }

    return (
        <FormProvider {...methods}>
            <VStack
                m="24px"
                as="form"
                spacing="32px"
                align="stretch"
                justify="space-between"
                onSubmit={methods.handleSubmit(handleSubmit)}
                data-testid="add-payment-method-form"
            >
                <VStack spacing="24px" align="inherit">
                    <AddPaymentMethodCard />
                    <Divider />
                    <AddPaymentMethodAddress />
                    {!!error && (
                        <ErrorMessage error={error as string} sx={{ mt: '12px !important' }} />
                    )}
                </VStack>

                <HStack px="24px" pb="24px" spacing="16px" align="inherit">
                    <Button
                        w="full"
                        size="lg"
                        variant="secondary"
                        isDisabled={isSubmitting}
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        type="submit"
                        w="full"
                        size="lg"
                        variant="creationary"
                        isDisabled={!isDirty}
                        isLoading={isSubmitting}
                    >
                        {t('Save')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}
