import { CSSProperties } from 'react'
import { isActivitySection, VirtualisedActivityItem } from 'shared/types/feed'

import { ActivitySidebarRow } from './ActivitySidebarRow'
import { ActivitySidebarSection } from './ActivitySidebarSection'

interface ActivitySidebarRendererProps {
    data: VirtualisedActivityItem[]
    index: number
    style: CSSProperties
}

export const ActivitySidebarRenderer = ({ data, index, style }: ActivitySidebarRendererProps) => {
    const activity = data[index]

    if (isActivitySection(activity)) {
        return (
            <div style={style}>
                <span style={{ position: 'relative', width: '100%' }}>
                    <ActivitySidebarSection
                        key={activity.startOfDay}
                        section={activity}
                        index={index}
                    />
                </span>
            </div>
        )
    }

    return (
        <div style={style}>
            <span style={{ position: 'relative', width: '100%' }}>
                <ActivitySidebarRow activity={activity} />
            </span>
        </div>
    )
}
