import { Center, useMediaQuery, VStack } from '@chakra-ui/react'
import { Dialler } from '@missionlabs/react'
import { FC } from 'react'
import { Features } from 'shared/hooks/useHasClientFeature'
import { useHasFeature } from 'shared/hooks/useHasFeature'

type DialpadProps = {
    handleDialpadChange: (key: string) => void
    hideCall?: boolean
}

export const Dialpad: FC<DialpadProps> = ({ handleDialpadChange, hideCall }) => {
    const [isSmallerThan750] = useMediaQuery('(max-height: 750px)')

    const hasCallPullPermission = useHasFeature(Features.call_pull)

    return (
        <VStack spacing={0} w="full" align="stretch">
            <Center p="24px">
                <Dialler
                    size={isSmallerThan750 ? 'sm' : undefined}
                    hideCall={hideCall}
                    onClick={handleDialpadChange}
                    hideRetrieveCall={!hasCallPullPermission}
                />
            </Center>
        </VStack>
    )
}
