import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const EditOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EditOutlineIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M18.3267 2L18.5214 2.00387C19.4266 2.04599 20.2868 2.42316 20.9337 3.06741C21.6225 3.75865 22.0064 4.69661 21.9999 5.67257C21.9931 6.64842 21.5966 7.58109 20.9038 8.25778L8.82126 20.3406C8.74208 20.4198 8.64308 20.4762 8.53461 20.504L2.78082 21.9798C2.32061 22.0978 1.9022 21.6795 2.02017 21.2192L3.49508 15.4653C3.52289 15.3568 3.57935 15.2578 3.65855 15.1786L15.7361 3.10086C16.4182 2.40302 17.3508 2.00669 18.3267 2ZM18.3351 3.24986L18.1608 3.25744C17.5826 3.30344 17.0376 3.55743 16.6249 3.97963L4.66293 15.9413L3.493 20.5061L8.0577 19.3353L20.0252 7.36876C20.4428 6.96083 20.6965 6.41647 20.7424 5.83842L20.75 5.6641C20.7542 5.02187 20.5016 4.40459 20.0501 3.95141C19.5949 3.49818 18.9775 3.24562 18.3351 3.24986ZM3.65855 15.1786C3.88023 14.9565 4.22785 14.9356 4.47267 15.1169L4.54282 15.1774L8.82504 19.4522C9.06931 19.696 9.06966 20.0917 8.82581 20.336C8.60413 20.5581 8.25695 20.5786 8.01214 20.3973L7.94199 20.3368L3.65977 16.062C3.4155 15.8181 3.4147 15.4229 3.65855 15.1786ZM15.3961 3.44161C15.618 3.21973 15.9652 3.19956 16.2098 3.38109L16.2799 3.44161L20.558 7.71975C20.802 7.96382 20.802 8.35953 20.558 8.6036C20.3361 8.82547 19.9889 8.84564 19.7443 8.66411L19.6742 8.6036L15.3961 4.32545C15.152 4.08138 15.152 3.68567 15.3961 3.44161Z"
            fill="currentColor"
        />
    )
})

export const EditIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EditIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M17.694 3C16.8158 3.00602 15.9763 3.36272 15.3625 3.99077L4.4927 14.8607C4.42141 14.932 4.3706 15.0211 4.34557 15.1188L3.01816 20.2973C2.91198 20.7115 3.28855 21.0881 3.70274 20.9818L8.88115 19.6536C8.97877 19.6286 9.06787 19.5778 9.13913 19.5065L20.0134 8.632C20.637 8.02298 20.9938 7.18358 20.9999 6.30531C21.0057 5.42695 20.6603 4.58279 20.0404 3.96067C19.4581 3.38084 18.6839 3.0414 17.8692 3.00349L17.694 3ZM4.4931 14.8603C4.57872 14.7746 4.70789 14.6456 4.88059 14.4734L5.28854 14.8596L9.14253 18.707L9.54054 19.1043C9.34886 19.2964 9.21642 19.4291 9.14323 19.5024C9.0862 19.5596 8.99286 19.624 8.9022 19.65C8.89923 19.6509 8.7931 19.6772 8.5838 19.7289L8.34779 19.5031L4.49379 15.6558C4.46318 15.6252 4.38989 15.5396 4.27391 15.3987C4.28662 15.3508 4.29636 15.3115 4.30314 15.2809C4.37006 14.9779 4.44149 14.912 4.4931 14.8603ZM15.0565 4.29744C15.1346 4.21937 15.2665 4.0889 15.4523 3.90601L15.8519 4.29744L19.7022 8.14778C19.7465 8.19206 19.8809 8.32131 20.1053 8.53551L19.3185 9.32837L18.9067 8.94324L15.0565 5.09291C15.0054 5.04177 14.8748 4.90777 14.6649 4.69093C14.8307 4.52391 14.9612 4.39275 15.0565 4.29744Z"
            fill="currentColor"
        />
    )
})
