import { HStack, useDisclosure } from '@chakra-ui/react'
import { Button, Dropdown } from '@missionlabs/react'
import { UserOrTeam } from '@missionlabs/types'
import { stringToOption } from '@missionlabs/utils'
import { UTMDrawer } from 'features/settings/components/UTMDrawer'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface NewIVRMenuOptionProps {
    availableKeys: string[]
    onSubmit: (position: string, data: UserOrTeam) => void
}

/**
 * Lets the user pick an available key and set up a destination for that menu option
 * using the users, teams and menus drawer.
 */
export function NewIVRMenuOption({ availableKeys, onSubmit }: NewIVRMenuOptionProps) {
    const { t } = useTranslation()
    const newRow = useDisclosure()
    const utmDrawer = useDisclosure()
    const [newRowIndex, setNewRowIndex] = useState<string>('')

    const handleSubmit = (data: UserOrTeam) => {
        onSubmit(newRowIndex, data)
        setNewRowIndex('')
        newRow.onClose()
        utmDrawer.onClose()
    }

    // Make sure there's at least 1 empty menu option
    if (!availableKeys.length) {
        return null
    }

    // Show an "Add New" button before showing the new empty row
    // otherwise it might look a bit odd
    if (!newRow.isOpen) {
        return (
            <Button variant="secondary" onClick={newRow.onOpen}>
                {t('Add new')}
            </Button>
        )
    }

    return (
        <>
            <HStack alignSelf="stretch" gap={4} data-testid="new-ivr-option">
                <Dropdown
                    w="115px"
                    value={newRowIndex}
                    options={availableKeys.map(stringToOption)}
                    onChange={(newIndex: string) => setNewRowIndex(newIndex)}
                />
                <Button isDisabled={newRow.isOpen && !newRowIndex} onClick={utmDrawer.onOpen}>
                    {t('calls.selectUTM')}
                </Button>
            </HStack>
            <UTMDrawer
                isOpen={utmDrawer.isOpen}
                onClose={utmDrawer.onClose}
                onSubmit={handleSubmit}
            />
        </>
    )
}
