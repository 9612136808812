import { Divider, Grid, useColorMode } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { FC, PropsWithChildren, ReactNode } from 'react'

interface SettingsRowProps {
    label: ReactNode
    divider?: boolean
    size?: 'xs' | 'sm' | 'md' | 'lg'
}

export const SettingsRow: FC<PropsWithChildren<SettingsRowProps>> = ({
    label,
    divider = true,
    size = 'sm',
    children
}) => {
    const { colorMode } = useColorMode()
    return (
        <>
            <Grid w="full" templateColumns="160px 1fr" gap="16px" alignItems="start">
                <Body size={size} variant="bold" sx={{ color: `${colorMode}.tones.navy` }}>
                    {label}
                </Body>
                {children}
            </Grid>
            {divider && <Divider />}
        </>
    )
}
