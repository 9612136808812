import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { Body, Button } from '@missionlabs/react'
import { AddCircleIcon } from '@missionlabs/react/circleloop'
import { UserRole } from '@missionlabs/types'
import { SettingsTitle } from 'features/settings/components/SettingsTitle'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useUserRole } from 'shared/hooks/useUserRole'
import { useGetSpeedDialListForUserQuery, useGetSpeedDialListsForClientQuery } from 'shared/store'

import { SpeedDialTable } from './SpeedDialTable'

export const Directories: FC = () => {
    const { t } = useTranslation()
    const { pathname } = useLocation()

    const userRole = useUserRole()
    const navigate = useNavigate()
    const user = useSelector(selectAuthenticatedUser)

    const isAdmin = pathname.includes('/admin/') && userRole === UserRole.ADMIN

    const getRequest = isAdmin
        ? useGetSpeedDialListsForClientQuery
        : useGetSpeedDialListForUserQuery
    const getParams: any = isAdmin ? undefined : { userID: user?.userID ?? '' }

    const { data = [], isLoading } = getRequest(getParams, { skip: !user })

    const speedDialLists = Array.isArray(data) ? data : [data]

    const onAddClick = () => {
        navigate(`/${isAdmin ? 'admin' : 'settings'}/directories/speed-dial/add`)
    }

    return (
        <SettingsPage title={t('settings.directories')}>
            <SettingsTitle label={t('directories.speedDial.title')} divider={false} />

            <Body size="sm">{t('directories.speedDial.subTitle')}</Body>

            <Button variant="creationary" leftIcon={<AddCircleIcon />} onClick={onAddClick}>
                {t('directories.speedDial.createTitle')}
            </Button>
            <SpeedDialTable speedDialLists={speedDialLists} isLoading={isLoading} />
        </SettingsPage>
    )
}
