import { InfoOutlineIcon } from '@chakra-ui/icons'
import { HStack, useColorMode, useMultiStyleConfig, VStack } from '@chakra-ui/react'
import { Label, Tooltip, useFieldArray, useFormContext } from '@missionlabs/react'
import { TeamMember } from '@missionlabs/types'
import { InterstitialRadio } from 'features/settings/components/InterstitialRadio'
import { Reorder } from 'framer-motion'
import { FC, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'

import { ReorderItem } from './ReorderItem'

interface ReorderRadioProps {
    value: string
    label: string
    tooltipLabel: string
}

export const ReorderRadio: FC<ReorderRadioProps> = ({ value, label, tooltipLabel }) => {
    const { colorMode } = useColorMode()
    const styles = useMultiStyleConfig('Radio', { variant: colorMode })

    const { watch, control } = useFormContext()
    const [items, setItems] = useState<Array<TeamMember>>([])

    const { replace, update } = useFieldArray({
        control,
        name: 'callRoute.teamMembers'
    })

    const teamMembers = watch('callRoute.teamMembers')

    /** dependency is the length of the teamMember array as it'll end up setting the items when a
     *  reorder happens which isn't required. Additionally watch might not provide the value before
     *  the useEffect so we need to ensure it is setting the items initially. */
    useEffect(
        function setInitialTeamMembers() {
            setItems(
                teamMembers.sort(function (a, b) {
                    return a.order - b.order
                })
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [teamMembers?.length]
    )

    const handleTeamMemberOrderChange = members => {
        members.forEach((member, idx) => {
            member.order = idx + 1 // minimum value is 1
        })
        replace(members)
    }

    useDebounce(
        function updateFormTeamMembers() {
            handleTeamMemberOrderChange([...items])
        },
        1000,
        [items]
    )

    const handleTimeoutChange = (index: number, timeout: number) => {
        let item
        setItems(prev => {
            item = prev.at(index)
            if (item) item.timeout = timeout
            return prev
        })
        if (item) update(index, { ...item, timeout })
    }

    return (
        <InterstitialRadio
            value={value}
            current={watch('callRoute.callSequence')}
            label={
                <HStack>
                    <Label textProps={{ color: styles.label.color as string }} text={label} />
                    <Tooltip label={tooltipLabel}>
                        <InfoOutlineIcon />
                    </Tooltip>
                </HStack>
            }
            alignItems="center"
        >
            <VStack align="flex-start" w="full" px={6}>
                <Reorder.Group
                    axis="y"
                    onReorder={setItems}
                    values={items ?? []}
                    style={{ width: '100%' }}
                >
                    {items.map((item, idx) => (
                        <ReorderItem
                            key={item.userID}
                            item={item}
                            idx={idx}
                            update={handleTimeoutChange}
                        />
                    ))}
                </Reorder.Group>
            </VStack>
        </InterstitialRadio>
    )
}
