import { DetailedState } from '@missionlabs/types'
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'

export type PresenceID = {
    userID: string
    timestamp: number
    persist?: boolean
}

export type PresenceState = {
    presenceIDs: PresenceID[]
    lastKnownStatus: {
        [userID: string]: { status: DetailedState; lastUpdated: number } | undefined
    }
}

type PresenceAction<T> = (state: PresenceState, action: PayloadAction<T>) => void

export type PresenceActions = {
    addPresenceID: PresenceAction<Pick<PresenceID, 'userID' | 'persist'>>
    removePresenceID: PresenceAction<string>
    addLastKnownStatus: PresenceAction<{ userID: string; status: DetailedState }[]>
    clearPresenceIDs: PresenceAction<void>
}

const initialState: PresenceState = {
    presenceIDs: [],
    lastKnownStatus: {}
}

export const presenceSlice: Slice<PresenceState, PresenceActions, 'presenceSlice'> = createSlice({
    name: 'presenceSlice',
    initialState,
    reducers: {
        addPresenceID: (state, action) => {
            let update = false
            const timestamp = Date.now()
            state.presenceIDs = state.presenceIDs.map(item => {
                if (item.userID === action.payload.userID) {
                    update = true
                    return { ...item, timestamp }
                }

                return item
            })

            if (!update) {
                state.presenceIDs = [{ ...action.payload, timestamp }, ...state.presenceIDs]
            }
        },
        removePresenceID: (state, action) => {
            state.presenceIDs = state.presenceIDs.filter(item => item.userID !== action.payload)
        },
        addLastKnownStatus: (state, action) => {
            action.payload.forEach(item => {
                if (item.status === DetailedState.UNKNOWN) {
                    state.lastKnownStatus[item.userID] = undefined
                    return
                }

                state.lastKnownStatus[item.userID] = {
                    ...item,
                    lastUpdated: Date.now()
                }
            })

            state.lastKnownStatus = { ...state.lastKnownStatus }
        },
        clearPresenceIDs: state => {
            state.presenceIDs = []
        }
    }
})

export type PresenceSliceRootState = {
    presenceSlice: ReturnType<typeof presenceSlice.getInitialState>
}

export const selectPresenceSlice = (state: PresenceSliceRootState) => {
    return state.presenceSlice
}

export const { addPresenceID, removePresenceID, addLastKnownStatus, clearPresenceIDs } =
    presenceSlice.actions
