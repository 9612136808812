import { useCallback, useRef } from 'react'

type Fn = (...args: any[]) => void

/**
 * Creates a throttled callback from the function provided
 * @param fn The function to be called
 * @param delay The number of seconds between calls of the function.
 */
export const useThrottledFunction = <T extends Fn>(fn: T, delay: number) => {
    const wait = useRef<boolean>(false)

    return useCallback(
        (...args: Parameters<typeof fn>) => {
            if (wait.current) return

            fn(...args)
            wait.current = true

            setTimeout(() => (wait.current = false), delay)
        },
        [fn, delay]
    )
}
