import { Box, useColorMode } from '@chakra-ui/react'
import { useMeasure, VirtualPaginationContextProvider } from '@missionlabs/react'
import { useFlags } from 'flagsmith/react'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { DocumentTitle } from 'shared/components/DocumentTitle'
import { MainLayout } from 'shared/components/MainLayout'

import { ActivitySidebar } from '../activity/ActivitySidebar/ActivitySidebar'
import { ActivitySidebarContextProvider } from '../activity/ActivitySidebar/ActivitySidebarContext'
import { ActivitySearch } from '../activity/ActivitySidebar/components/ActivitySearch'
import { ActivitySidebarFilters } from '../activity/ActivitySidebar/components/ActivitySidebarFilters'
import { UnreadActivityCounts } from '../activity/ActivitySidebar/components/UnreadActivityCounts'

export interface VoiceDashboardProps {}

export const VoiceDashboard: FC<VoiceDashboardProps> = () => {
    const flags = useFlags(['activity_sidebar'])
    const { colorMode } = useColorMode()

    const [headerRef, { blockSize: headerHeight }] = useMeasure<HTMLDivElement>()

    return (
        <>
            <DocumentTitle breadcrumbs={['Activities']} />
            <VirtualPaginationContextProvider pageSize={10} direction="down">
                <ActivitySidebarContextProvider>
                    <MainLayout
                        left={
                            <Box bg={`${colorMode}.tones.ghostWhite`} id="activity-sidebar">
                                {flags.activity_sidebar.enabled && (
                                    <>
                                        <div id="activity-sidebar-header" ref={headerRef}>
                                            <ActivitySearch />
                                            <UnreadActivityCounts />
                                        </div>
                                        <ActivitySidebarFilters siblingHeight={headerHeight} />

                                        <ActivitySidebar siblingHeight={headerHeight} />
                                    </>
                                )}
                            </Box>
                        }
                        right={<Outlet />}
                    />
                </ActivitySidebarContextProvider>
            </VirtualPaginationContextProvider>
        </>
    )
}
