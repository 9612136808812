import { AuthenticatedUser, selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { useFlagsmith } from 'flagsmith/react'
import { useCallback, useEffect } from 'react'

export const useFlagsmithIdentify = () => {
    const flagsmith = useFlagsmith()

    const user = useSelector(selectAuthenticatedUser)

    // memorized wrapper around identify to prevent re-renders
    const identifyUser = useCallback(
        (_user: AuthenticatedUser) => {
            // Just some metadata about the user.
            // We can use these in flagsmith to enable/disable features by traits and segments
            const traits = { clientID: _user.clientID }
            return flagsmith.identify(_user.userID, traits)
        },
        [flagsmith]
    )

    useEffect(() => {
        // If the user is logged in but not yet identified to flagsmith
        // then identify them with user.id and traits for dataCentre and environments
        if (!flagsmith.identity && user) identifyUser(user)
    }, [flagsmith.identity, identifyUser, user])
}
