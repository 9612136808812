import { ButtonGroup, Divider, HStack, Text } from '@chakra-ui/react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { IconButton } from '@missionlabs/react'
import { ChatIconSolid, SendIconSolid, SmileEmojiPlusIconRegular } from '@missionlabs/react/zeta'
import { TextContentListener } from 'lexical/LexicalEditor'
import { FC, useEffect, useState } from 'react'
import { TOGGLE_EMOJI_PICKER_COMMAND } from 'shared/components/Lexical/plugins/EmojiPickerPlugin'

import { SEND_MESSAGE_COMMAND } from './SMSSendMessagePlugin'

export const SMSMessageToolbar: FC<{ showCounter: boolean }> = ({ showCounter }) => {
    const [editor] = useLexicalComposerContext()
    const [canSend, setCanSend] = useState(false)
    const maxMessageLength = 160
    const [totalCharCount, setTotalCharacterCount] = useState(0)
    const charCount = totalCharCount % maxMessageLength
    const messageCount = Math.ceil(totalCharCount / maxMessageLength)

    useEffect(() => {
        const handleContectChange: TextContentListener = content => {
            setTotalCharacterCount(content.length)
            setCanSend(content.length > 0)
        }
        const teardown = editor.registerTextContentListener(handleContectChange)
        return () => teardown()
    }, [editor, setTotalCharacterCount, setCanSend])

    return (
        <HStack
            spacing={0}
            py="8px"
            w="full"
            h="full"
            justify="space-between"
            data-testid="chat-toolbar"
        >
            <HStack spacing="8px">
                <ButtonGroup spacing={0}>
                    <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Add emoji"
                        onClick={() => {
                            editor.dispatchCommand(TOGGLE_EMOJI_PICKER_COMMAND, undefined)
                        }}
                    >
                        <SmileEmojiPlusIconRegular boxSize="24px" p="4px" />
                    </IconButton>
                </ButtonGroup>
                <Divider orientation="vertical" h="32px" />
            </HStack>
            <HStack justify="end" gap="8px" alignItems="center">
                {showCounter && (
                    <>
                        <Text fontSize="xs">
                            {charCount} / {maxMessageLength}
                        </Text>
                        <HStack alignItems="center" gap="1px">
                            <ChatIconSolid boxSize="24px" p="4px" />
                            <Text fontSize="xs">{messageCount}</Text>
                        </HStack>
                    </>
                )}
                <IconButton
                    size="sm"
                    variant="ghost"
                    isActive={canSend}
                    isDisabled={!canSend}
                    aria-label="Send message"
                    onClick={() => editor.dispatchCommand(SEND_MESSAGE_COMMAND, undefined)}
                    pl="4px"
                >
                    <SendIconSolid boxSize="24px" p="4px" />
                </IconButton>
            </HStack>
        </HStack>
    )
}
