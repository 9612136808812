import { Box, Button, Text } from '@chakra-ui/react'
import { Body, BodyProps } from 'atoms'
import { useRef, useState } from 'react'

interface ActivityDetailTextOverflowProps extends Omit<BodyProps, 'size' | 'variant'> {
    label?: string
    message?: string
    size?: 'xs' | 'sm' | 'md' | 'lg'
    variant?: 'bold' | 'extra-bold' | 'link' | 'link-bold' | 'none'
}
//Need to fix dynamic height in ActivityFeedContent. Hence the maxChars is set to 10000
const maxChars = 300000
export const ActivityDetailTextOverflow = ({
    label,
    message = '',
    ...props
}: ActivityDetailTextOverflowProps) => {
    const textElementRef = useRef<HTMLDivElement>(null)
    const [showFullText, setShowFullText] = useState(false)

    const handleToggleText = () => {
        setShowFullText(!showFullText)
    }
    const truncatedMessage = message.length > maxChars ? message.slice(0, maxChars) : message

    return (
        <Box>
            <Text as="span" ref={textElementRef} {...props}>
                {label && (
                    <Body as="span" size="sm" variant="bold">
                        {label}:&nbsp;
                    </Body>
                )}
                {showFullText ? message : truncatedMessage}&nbsp;
                {message.length > maxChars && (
                    <Button as="span" variant="link-underline" onClick={handleToggleText}>
                        {showFullText ? '...read less' : '...read more'}
                    </Button>
                )}
            </Text>
        </Box>
    )
}
