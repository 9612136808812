import { Button as ChakraButton, ButtonProps, useMultiStyleConfig, VStack } from '@chakra-ui/react'
import { Body } from 'atoms/Typography/Body'
import { forwardRef, ReactNode } from 'react'
import { Themes, useTheme } from 'theme'

type DiallerKeyProps = ButtonProps & {
    label: string
    subLabel?: string
    icon?: ReactNode
    onKeyClick: (key: string) => void
    size?: 'xs' | 'sm' | 'md' | 'lg'
}

export const DiallerKey = forwardRef<HTMLButtonElement, DiallerKeyProps>(
    ({ label, subLabel, icon, onKeyClick, size, ...props }, ref) => {
        const styles = useMultiStyleConfig('DiallerKey', { size })
        const { variant: theme } = useTheme()
        const bg = props.bg ?? styles.container.bg

        return (
            <ChakraButton
                sx={{ ...styles.container, bg }}
                onClick={() => onKeyClick(label)}
                visibility={label === 'hidden' ? 'hidden' : 'visible'}
                {...props}
                ref={ref}
                data-testid={`key-${label}`}
            >
                <VStack p={0} spacing={0} as="span" align="center" justify="center">
                    {!icon ? (
                        <>
                            <Body sx={{ ...styles.label }}>{label}</Body>
                            {subLabel ? (
                                <Body sx={{ ...styles.subLabel }}>{subLabel}</Body>
                            ) : (
                                theme === Themes.ZETA && <Body>&nbsp;</Body>
                            )}
                        </>
                    ) : (
                        icon
                    )}
                </VStack>
            </ChakraButton>
        )
    }
)

DiallerKey.displayName = 'DiallerKey'
