import { useColorMode } from '@chakra-ui/react'
import { IconButton, Tooltip } from '@missionlabs/react'
import { AddContactIcon } from '@missionlabs/react/circleloop'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface AddPersonButtonProps {
    onToggle: () => void
    isOpen: boolean
}

export const AddPersonButton: FC<AddPersonButtonProps> = ({ onToggle, isOpen }) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    return (
        <Tooltip label={t('Add Person')}>
            <IconButton
                icon={<AddContactIcon boxSize="16px" color={`${colorMode}.tones.periwinkle`} />}
                variant="dialler"
                boxSize="48px"
                isActive={isOpen}
                flex={1}
                aria-label="add-person"
                onClick={onToggle}
            />
        </Tooltip>
    )
}
