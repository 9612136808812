import { addListener } from '@reduxjs/toolkit'
import { useEffect } from 'react'

import { poll, refetch, reset } from '../slices/paginationSlice'
import { store } from '../store'

interface UsePaginationRTKListenersProps {
    // The key to use for the pagination slice
    paginationKey: string
    // The function to call when a "poll" action is dispatched
    onPoll?: () => Promise<void>
    // The function to call when a "refetch" action is dispatched
    onRefetch?: () => Promise<void>
    // The function to call when a "reset" action is dispatched
    onReset?: () => Promise<void>
}

/**
 * This hook sets up listeners for the pagination slice actions
 * so that we can perform the relevant searches when these actions happen.
 */
export function usePaginationListeners({
    paginationKey,
    onPoll,
    onRefetch,
    onReset
}: UsePaginationRTKListenersProps) {
    useEffect(() => {
        // If there's no pagination key, there's nothing to listen for
        if (!paginationKey) return

        const refetchListener = addListener({
            actionCreator: refetch,
            effect: async action => {
                if (action.payload !== paginationKey) return
                await onRefetch?.()
            }
        })

        const pollListener = addListener({
            actionCreator: poll,
            effect: async action => {
                if (action.payload !== paginationKey) return
                await onPoll?.()
            }
        })

        const resetListener = addListener({
            actionCreator: reset,
            effect: async action => {
                if (action.payload !== paginationKey) return
                await onReset?.()
            }
        })

        const refetchUnsubscribe = store.dispatch(refetchListener)
        const pollUnsubscribe = store.dispatch(pollListener)
        const resetUnsubscribe = store.dispatch(resetListener)

        return () => {
            refetchUnsubscribe()
            pollUnsubscribe()
            resetUnsubscribe()
        }
    }, [onPoll, onRefetch, onReset, paginationKey])
}
