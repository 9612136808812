import { HStack, useColorMode } from '@chakra-ui/react'
import { Tag } from '@missionlabs/react'

import { useActivityFilters } from '../hooks/useActivityFeedFilters'

const MAX_VISIBLE = 5

/**
 * Show which filters have been applied when the main filter menu is not open.
 * Will show "X more filters..." when more than 5 filters applied
 * @example "New", "Voicemails", "Missed Calls"
 */
export const ActivityFeedFilterDisplay = () => {
    const { colorMode } = useColorMode()
    const { appliedFilterLabels, filterMenu, removeFilterValue } = useActivityFilters()
    const nonSearchFilters = appliedFilterLabels.filter(f => f.filterName !== 'searchTerm')
    const moreFilters = nonSearchFilters.length - MAX_VISIBLE
    return (
        <HStack
            id="activity-feed-applied-filters"
            style={{ height: '57px' }}
            borderBottom="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
            pl={6}
        >
            {nonSearchFilters.slice(0, MAX_VISIBLE).map(filter => (
                <Tag
                    key={`${filter.filterName}-${filter.value}`}
                    label={filter.label}
                    variant="stormGrey"
                    color="white"
                    h="32px"
                    onClick={filterMenu.onOpen}
                    onClose={() => {
                        removeFilterValue(filter)
                    }}
                />
            ))}
            {moreFilters > 0 && (
                <Tag
                    label={`${moreFilters} more filters...`}
                    variant="stormGrey"
                    color="white"
                    onClick={filterMenu.onOpen}
                />
            )}
        </HStack>
    )
}
