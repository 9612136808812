import {
    useLocalVideo,
    useLogger,
    useMeetingManager,
    useVideoInputs
} from 'amazon-chime-sdk-component-library-react'
import { useCallback, useEffect } from 'react'

import useRealDevice from './useRealDevice'

const useVideoInputSelection = (
    preferenceDeviceID,
    controlCameraState = false,
    handlePreview = false
) => {
    const meetingManager = useMeetingManager()
    const { selectedDevice, devices } = useVideoInputs()
    const { isVideoEnabled, toggleVideo, setIsVideoEnabled } = useLocalVideo()
    const { getRealVideoInputDevice } = useRealDevice()

    const logger = useLogger()

    const handleChangeVideoInput = useCallback(
        async (value: string) => {
            if (controlCameraState && !!value !== isVideoEnabled) {
                toggleVideo()

                if (handlePreview) {
                    setIsVideoEnabled(!!value)
                }

                if (!value) {
                    return
                }
            }

            if (!devices.length) return

            let foundDevice = getRealVideoInputDevice(value)

            if (!foundDevice) {
                logger.warn(
                    `VideoInputSelection | failed finding video input device ${value}${
                        devices.length ? ', finding default' : ''
                    }`
                )
                if (!devices.length) {
                    return
                }

                foundDevice = getRealVideoInputDevice('default')

                if (!foundDevice) {
                    logger.warn(
                        'VideoInputSelection | failed to find default video input device, finding any video input device'
                    )
                    foundDevice = devices[0]
                }
            }

            try {
                if (isVideoEnabled) {
                    await meetingManager.startVideoInputDevice(foundDevice.deviceId)
                } else {
                    meetingManager.selectVideoInputDevice(foundDevice.deviceId)
                }
            } catch (error) {
                logger.error('VideoInputSelection | failed to select video input device')
            }
        },
        [
            devices,
            isVideoEnabled,
            logger,
            meetingManager,
            getRealVideoInputDevice,
            toggleVideo,
            setIsVideoEnabled,
            handlePreview,
            controlCameraState
        ]
    )

    useEffect(() => {
        if (preferenceDeviceID) {
            handleChangeVideoInput(preferenceDeviceID)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices, preferenceDeviceID])

    return {
        selectedDevice,
        devices,
        handleChangeVideoInput
    }
}

export default useVideoInputSelection
