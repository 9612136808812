import { HStack, StackProps } from '@chakra-ui/react'
import { Body, Toggle } from '@missionlabs/react'
import { FC, ReactNode, useId } from 'react'

type SettingSwitchProps = StackProps & {
    label: string | ReactNode
    isChecked: boolean
    onUpdate: (value: boolean) => void
    name?: string
}

export const SettingSwitch: FC<SettingSwitchProps> = ({
    label,
    isChecked,
    onUpdate,
    name,
    ...props
}) => {
    const accessibilityId = useId()
    const toggleName = name ? `-${name}` : ''
    return (
        <HStack w="full" justify="space-between" align="center" {...props}>
            <Body as="label" htmlFor={accessibilityId} size="sm" variant="bold">
                {label}
            </Body>
            <Toggle
                id={accessibilityId}
                isChecked={isChecked}
                onChange={e => onUpdate(e.target.checked)}
                data-testid={`setting-toggle${toggleName}`}
            />
        </HStack>
    )
}
