import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const TimeIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TimeIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM12.0012 2.44889C6.72701 2.44889 2.45139 6.72451 2.45139 11.9988C2.45139 17.273 6.72701 21.5486 12.0012 21.5486C17.2755 21.5486 21.5511 17.273 21.5511 11.9988C21.5511 6.72451 17.2755 2.44889 12.0012 2.44889ZM11.5427 4.80205C11.8978 4.80205 12.1857 5.08995 12.1857 5.44508V12.1196H16.1299C16.485 12.1196 16.7729 12.4075 16.7729 12.7626C16.7729 13.1177 16.485 13.4056 16.1299 13.4056H11.5427C11.1876 13.4056 10.8997 13.1177 10.8997 12.7626V5.44508C10.8997 5.08995 11.1876 4.80205 11.5427 4.80205Z"
            fill="currentColor"
        />
    )
})
