import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PinOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PinOutlineIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M12.3641 6.26592L12.4476 6.18245L12.4849 6.07047L13.5439 2.89296C13.6071 2.70402 13.7635 2.56116 13.9572 2.5154C14.1508 2.46967 14.3542 2.52741 14.4951 2.66823L21.3318 9.50494C21.4726 9.64577 21.5303 9.84924 21.4846 10.0428C21.4388 10.2365 21.296 10.3929 21.107 10.4561L17.9295 11.5151L17.8175 11.5524L17.7341 11.6359L14.5936 14.7763L14.3882 14.9818L14.465 15.2621C14.5026 15.3993 14.5396 15.5561 14.5713 15.7303L14.5713 15.7307C14.6252 16.025 14.6634 16.3677 14.6632 16.7474V16.7476C14.6632 18.0292 14.2299 19.6979 12.596 21.3318C12.3717 21.5561 12.008 21.5561 11.7838 21.3318L7.60581 17.1538L7.25226 16.8003L6.8987 17.1538L2.72071 21.3318C2.70611 21.3464 2.68275 21.3464 2.66818 21.3318C2.65362 21.3173 2.65358 21.2939 2.66818 21.2793L6.84618 17.1013L7.19973 16.7477L6.84618 16.3942L2.66818 12.2162C2.44394 11.992 2.44394 11.6283 2.66818 11.404C4.30214 9.77007 5.9708 9.33685 7.25238 9.33676H7.25242C7.85774 9.33668 8.36743 9.43303 8.73738 9.53488L9.01791 9.61212L9.22365 9.40637L12.3641 6.26592Z"
            stroke="currentColor"
        />
    )
})

export const PinIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PinIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8486 2.31467C14.5853 2.05132 14.2045 1.9432 13.8422 2.02879C13.4799 2.11438 13.1876 2.38144 13.0697 2.73455L12.0105 5.91237L8.8701 9.05282C8.46524 8.94136 7.91045 8.83667 7.25235 8.83676C5.84723 8.83685 4.04857 9.31654 2.31463 11.0505C1.89512 11.47 1.89512 12.1502 2.31463 12.5697L6.49262 16.7477L2.31463 20.9257C2.10488 21.1355 2.10469 21.4754 2.31463 21.6854C2.52457 21.8953 2.86451 21.8951 3.07426 21.6854L7.25226 17.5074L11.4303 21.6854C11.8498 22.1049 12.53 22.1049 12.9495 21.6854C14.6835 19.9514 15.1631 18.1528 15.1632 16.7476C15.1634 16.3343 15.1218 15.9612 15.0632 15.6407C15.0287 15.4512 14.9884 15.2801 14.9472 15.1299L18.0876 11.9895L21.2654 10.9303C21.6186 10.8124 21.8856 10.5201 21.9712 10.1578C22.0568 9.79549 21.9487 9.41474 21.6853 9.15139L14.8486 2.31467Z"
            fill="currentColor"
        />
    )
})
