import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { CallNote } from '@missionlabs/types'
import { getDestinationOSName } from '@missionlabs/utils'
import isElectron from 'is-electron'
import {
    $createParagraphNode,
    $createTextNode,
    $getRoot,
    CLEAR_EDITOR_COMMAND,
    COMMAND_PRIORITY_EDITOR,
    COMMAND_PRIORITY_HIGH,
    createCommand,
    KEY_ENTER_COMMAND,
    LexicalCommand
} from 'lexical'
import { FC, useCallback, useEffect } from 'react'

import { useActivityFooterState } from '../../hooks/useActivityFooterState'

// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/getModifierState#modifier_keys_on_gecko
function getPlatformModifier() {
    const platform = isElectron() ? getDestinationOSName(window.platform) : window.platform
    if (/Mac|iPod|iPhone|iPad/i.test(platform)) return 'Meta'
    return 'Control'
}

export const SAVE_CALL_NOTES_COMMAND: LexicalCommand<void> =
    createCommand('SAVE_CALL_NOTES_COMMAND')

export interface ActivityFeedNotesPluginProps {
    onSaveCallNotes: (callTraceID: string, callNoteContent: string, callNote?: CallNote) => void
    callNote: CallNote | { content: string } | undefined
    callTraceID: string
}

export const ActivityFeedNotesPlugin: FC<ActivityFeedNotesPluginProps> = ({
    onSaveCallNotes,
    callNote,
    callTraceID
}) => {
    const [editor] = useLexicalComposerContext()
    const { onClose } = useActivityFooterState()

    // If activity already contains call notes, set them as the text within the text box
    useEffect(() => {
        editor.update(() => {
            const root = $getRoot()
            root.clear()

            if (callNote?.content) {
                const p = $createParagraphNode()
                p.append($createTextNode(callNote.content))
                root.append(p)
            }
        })
    }, [editor, callNote?.content])

    const handleSaveCallNotes = useCallback(() => {
        const callNoteContents = editor.getRootElement()?.textContent ?? ''
        onSaveCallNotes(callTraceID, callNoteContents, callNote)
        onClose()
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
        return true
    }, [editor, callNote, callTraceID, onSaveCallNotes, onClose])

    useEffect(() => {
        return (
            mergeRegister(
                editor.registerCommand(
                    SAVE_CALL_NOTES_COMMAND,
                    handleSaveCallNotes,
                    COMMAND_PRIORITY_EDITOR
                )
            ),
            editor.registerCommand(
                KEY_ENTER_COMMAND,
                event => {
                    // user must press platform modifier + enter in order to send a message
                    const modifier = event?.getModifierState(getPlatformModifier())
                    if (!modifier) return false
                    event?.preventDefault()
                    return handleSaveCallNotes()
                },
                COMMAND_PRIORITY_HIGH
            )
        )
    })

    return null
}
