import { useLocalVideo, useToggleLocalMute } from 'amazon-chime-sdk-component-library-react'
import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base/index'
import { useActiveSpeakerIndicator } from 'hooks/useActiveSpeakerIndicator'
import { useBindVideoElement } from 'hooks/useBindVideoElement'
import { FC, HTMLAttributes, PropsWithChildren, ReactNode } from 'react'

import PlaceholderVideoTile from '../PlaceholderVideoTile/index'

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'css'>, BaseSdkProps {
    /** The name to show on the video's nameplate */
    name?: string
    chimeAttendeeId: string
    placeholder?: string
    placeholderElement?: ReactNode
}

export const LocalVideoTileExtended: FC<PropsWithChildren<Props>> = ({
    name,
    chimeAttendeeId,
    ...rest
}) => {
    const { tileId, isVideoEnabled } = useLocalVideo()

    const { muted } = useToggleLocalMute()

    const videoRef = useBindVideoElement(tileId, isVideoEnabled)

    const { nameplateRef, speakerIconRef } = useActiveSpeakerIndicator(chimeAttendeeId)

    return (
        <PlaceholderVideoTile
            {...rest}
            ref={videoRef}
            nameplate={name}
            muted={muted}
            nameplateRef={nameplateRef}
            speakerIconRef={speakerIconRef}
            tag={'div'}
        />
    )
}

export default LocalVideoTileExtended
