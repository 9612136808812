import { Heading as ChakraHeading, HeadingProps as ChakraHeadingProps } from '@chakra-ui/react'
import { FC, PropsWithChildren } from 'react'

export interface HeadingProps extends Omit<ChakraHeadingProps, 'size' | 'variant'> {
    size?: 'h1+' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
    variant?: 'bold' | 'none'
}

export const Heading: FC<PropsWithChildren<HeadingProps>> = ({ size, children, ...props }) => {
    return (
        <ChakraHeading as={size === 'h1+' ? 'h1' : size} {...props} size={size}>
            {children}
        </ChakraHeading>
    )
}
