import { useInterval } from '@chakra-ui/react'
import { Call } from '@missionlabs/api'
import { useMemo, useState } from 'react'

export const usePollCallTime = (calls: Call[], pollingInterval: number | null = null) => {
    const [currentTime, setCurrentTime] = useState<number | undefined>()

    const callStartTime = useMemo(() => {
        const timestamps = calls
            .map(call => call.startTimestamp)
            .filter((timestamp): timestamp is number => !!timestamp)

        // Sort from largest to smallest number, the first call accepted will be the smallest number so we should use that.
        return timestamps.sort((a, b) => b - a).pop()
    }, [calls])

    // Polling will disable itself when `pollingInterval` is set to `null`.
    useInterval(() => setCurrentTime(Date.now()), pollingInterval)

    return { currentTime, callStartTime }
}
