import {
    MenuList as ChakraMenuList,
    MenuListProps as ChakraMenuListProps,
    Portal
} from '@chakra-ui/react'
import { FC, PropsWithChildren } from 'react'

export type MenuListProps = ChakraMenuListProps

export const MenuList: FC<PropsWithChildren<MenuListProps>> = ({ children, ...props }) => {
    return (
        <Portal>
            <ChakraMenuList {...props}>{children}</ChakraMenuList>
        </Portal>
    )
}
