import { AddressLookup } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildAddressesAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'addresses',
        tagTypes: ['Addresses'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getAddressesByPostcode: builder.query<Array<Partial<AddressLookup>>, string>({
                query: (postcode: string) => {
                    postcode = postcode.toUpperCase().replace(/\s/g, '')
                    return `/address/lookup?postcode=${postcode}`
                },
                transformResponse: (addresses: Array<Partial<AddressLookup>>) => {
                    /**  Replaces null values with empty string in the address data */
                    return addresses.map(address => {
                        const newAddress = {}
                        Object.keys(address).forEach(key => {
                            const keyValue = address[key]
                            if (keyValue === null) {
                                newAddress[key] = ''
                                return
                            }
                            newAddress[key] = keyValue
                        })
                        return newAddress
                    })
                },
                providesTags: (result, error, postcode) => [{ type: 'Addresses', id: postcode }]
            })
        })
    })

    return { api, ...api }
}
