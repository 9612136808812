import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface StepProps extends BoxProps {
    variant?: 'active'
}

export const Step = forwardRef<HTMLDivElement, StepProps>(({ variant, ...props }, ref) => {
    const styles = useStyleConfig('Step', { variant })
    return <Box variant={variant} {...props} ref={ref} sx={{ ...styles }} />
})
