import { HStack } from '@chakra-ui/react'
import { Body, Toggle } from '@missionlabs/react'
import { User } from '@missionlabs/types'
import { useSettingsContext } from 'features/settings/components/settingsContext'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'

export const CallRecordingSetting: FC = () => {
    const { t } = useTranslation()
    const { user, onUpdateUser } = useSettingsContext()

    function handleUpdateCallRecording(e: ChangeEvent<HTMLInputElement>) {
        if (!user) return
        const updatedUser: Partial<User> = { ID: user.ID, callRecording: e.target.checked }
        onUpdateUser(updatedUser)
    }

    return (
        <SettingsRow label={t('calls.callRecording')}>
            <HStack w="full" justify="space-between">
                <Body size="sm" variant="bold">
                    {t('calls.recordAllCalls')}
                </Body>
                <Toggle
                    isChecked={user?.callRecording ?? false}
                    onChange={handleUpdateCallRecording}
                    data-testid="callRecording-switch"
                />
            </HStack>
        </SettingsRow>
    )
}
