import { CallNote } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildCallNoteAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'callNotesAPI',
        baseQuery: baseQuery,
        tagTypes: ['CallNotes'],
        endpoints: builder => ({
            createCallNote: builder.mutation<CallNote, Partial<CallNote>>({
                query: payload => ({
                    url: `/callnotes`,
                    method: 'POST',
                    body: payload
                }),
                onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                    await queryFulfilled
                    dispatch({ type: 'pagination/refetch', payload: 'searchActivities' })
                },
                invalidatesTags: ['CallNotes']
            }),
            updateCallNote: builder.mutation<
                CallNote,
                { callNoteID: string } & Pick<CallNote, 'content'>
            >({
                query: ({ content, callNoteID }) => ({
                    url: `/callnotes/${callNoteID}`,
                    method: 'PUT',
                    body: { content }
                }),
                onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                    await queryFulfilled
                    dispatch({ type: 'pagination/refetch', payload: 'searchActivities' })
                },
                invalidatesTags: ['CallNotes']
            }),
            deleteCallNote: builder.mutation<CallNote, { callNoteID: string }>({
                query: ({ callNoteID }) => ({
                    url: `/callnotes/${callNoteID}`,
                    method: 'DELETE'
                }),
                onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                    await queryFulfilled
                    dispatch({ type: 'pagination/refetch', payload: 'searchActivities' })
                },
                invalidatesTags: ['CallNotes']
            })
        })
    })

    const { useCreateCallNoteMutation, useUpdateCallNoteMutation, useDeleteCallNoteMutation } = api
    return {
        api,
        useCreateCallNoteMutation,
        useUpdateCallNoteMutation,
        useDeleteCallNoteMutation
    }
}
