import { MenuButtonProps as ChakraMenuButtonProps, useMenuButton } from '@chakra-ui/react'
import { cx } from '@chakra-ui/shared-utils'
import { Button, ButtonProps } from 'atoms'
import { forwardRef, PropsWithChildren } from 'react'

export type MenuButtonProps = ButtonProps & ChakraMenuButtonProps

export const MenuButton = forwardRef<HTMLButtonElement, PropsWithChildren<MenuButtonProps>>(
    ({ as, children, ...props }, ref) => {
        const buttonProps = useMenuButton(props, ref)

        const Element = as || Button

        return (
            <Element {...buttonProps} className={cx('chakra-menu__menu-button', props.className)}>
                {children}
            </Element>
        )
    }
)

MenuButton.displayName = 'MenuButton'
