import { DrawerFooter, HStack } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    Body,
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerTitle,
    FormControl,
    Input,
    useToast
} from '@missionlabs/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSet } from 'react-use'
import {
    useCreateChannelMutation,
    useCreateChatGroupMutation,
    useEditChatGroupMutation,
    useGetChatGroupQuery
} from 'shared/store'

import { ChatContactsList } from '../ChatContactsList'

interface AddEditChatGroupDrawerProps {}

export const AddEditChatGroupDrawer = (_props: AddEditChatGroupDrawerProps) => {
    const { id: groupID = '' } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { toast } = useToast()
    const user = useSelector(selectAuthenticatedUser)

    const [view, setView] = useState<'details' | 'users'>('details')
    const [name, setName] = useState('')
    const [userIDs, { toggle, reset, add }] = useSet<string>(new Set([]))

    const { data: group } = useGetChatGroupQuery(groupID, { skip: !groupID })
    const [createChatGroup, { isLoading }] = useCreateChatGroupMutation()
    const [editChatGroup, { isLoading: isUpdating }] = useEditChatGroupMutation()
    const [findOrCreateChannel] = useCreateChannelMutation()

    // Populate the form with the group data if editing
    useEffect(() => {
        if (!group) return
        setName(group.name)
        reset() // empty the userIDs set
        for (const userID of group.userIDs) {
            add(userID) // then re-add the userIDs from the group
        }
    }, [group, reset, add])

    const handleEditChatGroup = async () => {
        if (!user) return
        try {
            await editChatGroup({ groupID, name, userIDs: [...userIDs] }).unwrap()
            const channel = await findOrCreateChannel({ groupID, type: 'GROUP' }).unwrap()
            navigate(`/chat/${channel.ID}`)
        } catch (error) {
            console.error(error)
            toast({ status: 'error', title: t('chatGroups.updateError') })
        }
    }

    const handleCreateChatGroup = async () => {
        if (!user) return
        try {
            const newGroup = { name, userIDs: [user.userID, ...userIDs] }
            const { ID: groupID } = await createChatGroup(newGroup).unwrap()
            const channel = await findOrCreateChannel({ groupID, type: 'GROUP' }).unwrap()
            navigate(`/chat/${channel.ID}`)
        } catch (error) {
            console.error(error)
            toast({ status: 'error', title: t('chatGroups.createError') })
        }
    }

    const onSave = async () => {
        if (!user) return
        // Need to have a name and at least one other user to create/edit a chat group
        if (!name.trim() || userIDs.size === 0) return
        if (groupID) await handleEditChatGroup()
        else await handleCreateChatGroup()
    }

    return (
        <Drawer isOpen={true} onClose={() => navigate(-1)}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>
                    <DrawerTitle>
                        {groupID ? t('chatGroups.edit') : t('chatGroups.create')}
                    </DrawerTitle>
                </DrawerHeader>

                <DrawerBody p={6}>
                    {view === 'details' && (
                        <>
                            <Body>{t('chatGroups.groupDetails')}</Body>
                            <FormControl name="name" label={t('chatGroups.groupName')} pt={4}>
                                <Input
                                    value={name}
                                    onChange={e => setName(e.currentTarget.value)}
                                    onKeyUp={e => {
                                        if (e.key === 'Enter') setView('users')
                                    }}
                                />
                            </FormControl>
                        </>
                    )}
                    {view === 'users' && (
                        <ChatContactsList selectedUserIDs={userIDs} onClickContact={toggle} />
                    )}
                </DrawerBody>
                <DrawerFooter>
                    <HStack w="full" spacing={4}>
                        <Button variant="secondary" w="full" onClick={() => navigate(-1)}>
                            {t('Cancel')}
                        </Button>
                        {view === 'details' && (
                            <Button
                                variant="creationary"
                                w="full"
                                isDisabled={!name.trim()}
                                onClick={() => setView('users')}
                            >
                                {t('Continue')}
                            </Button>
                        )}
                        {view === 'users' && (
                            <Button
                                variant="creationary"
                                w="full"
                                isDisabled={userIDs.size === 0}
                                isLoading={isLoading || isUpdating}
                                onClick={onSave}
                            >
                                {t('Save')}
                            </Button>
                        )}
                    </HStack>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}
