import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const paginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        poll(_state, _action) {},
        refetch(_state, _action) {},
        reset(_state, _action) {}
    }
})

export const { poll, refetch, reset } = paginationSlice.actions
