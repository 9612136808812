import { useColorMode, VStack } from '@chakra-ui/react'
import { Body, Input, InputProps } from '@missionlabs/react'
import { UploadFileIcon } from '@missionlabs/react/circleloop'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'

interface FileUploadProps {
    onChange?: InputProps['onChange']
    accept?: InputProps['accept']
}

export const FileUpload: FC<FileUploadProps> = ({ accept, onChange }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const ref = useRef<HTMLInputElement>(null)

    return (
        <VStack
            w="full"
            minH="112px"
            p="16px"
            spacing="10px"
            justify="center"
            borderRadius="4px"
            border="1px dashed"
            borderColor={`${colorMode}.coolGrey.30`}
            cursor="pointer"
            onClick={() => ref.current?.click()}
        >
            <UploadFileIcon boxSize="24px" color={`${colorMode}.coolGrey.40`} />
            <Body>{t('fileUpload')}</Body>
            <Input ref={ref} type="file" accept={accept} onChange={onChange} hidden />
        </VStack>
    )
}
