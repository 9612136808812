export interface WhatsAppBusiness {
    name: string
    wabaid: string
    timezone: string
}

export interface WhatsAppNumber {
    name: string
    numberID: string
    inCL: boolean
    inFB: boolean
    verified: boolean
    numberE164: string
    status: string
    provisioningStatus: string
}

export interface WhatsAppBusinessTemplateComponent {
    type: 'HEADER' | 'FOOTER' | 'BUTTONS'
    text: string
    example: any
}

export interface WhatsAppBusinessTemplate {
    name: string
    components: WhatsAppBusinessTemplateComponent[]
    language: string
    status: string
    category: string
    id: string
}

export interface PostWhatsAppBusinessRequest {
    code: string
    wabaID: string
    clientID: string
}

export interface PostWhatsAppRequestCodeRequest {
    ID: string
    codeType: string
    language: string
}

export interface PostWhatsAppVerifyCodeRequest {
    ID: string
    code: string
}

export interface PostWhatsAppRegisterNumberRequest {
    ID: string
    pin: string
}

export interface PostWhatsAppSendMessageRequest {
    to: string
    type: 'text' | 'image' | 'document' | 'audio' | 'video' | 'location' | 'template'
    text?: {
        body: string
    }
    template?: {
        name: string
        language: {
            code: string
        }
    }
}

export interface PostWhatsAppSendMessageResponse {
    messaging_product: string
    contacts: {
        input: string
        wa_id: string
    }[]
    messages: {
        id: string
    }[]
}
export interface PostWhatsAppNumberRequest {
    name: string
    pin: string
    numberID: string
}

export interface FacebookSuccessResponse {
    success: boolean
}

export enum FacebookLoginStatus {
    NO_ACCOUNT = 'NO_ACCOUNT',
    SIGNUP_IN_PROGRESS = 'SIGNUP_IN_PROGRESS',
    SIGNUP_COMPLETE = 'SIGNUP_COMPLETE',
    SAVING_WABA_ID = 'SAVING_WABA_ID',
    BUSINESS_CREATED = 'BUSINESS_CREATED'
}

export interface PostNumberCheckRequest {
    fromNumber: string | undefined
    toNumbers: string[]
}

export enum NumberCheckCapability {
    SMS = 'SMS',
    WHATSAPP_TEMPLATE = 'WHATSAPP_TEMPLATE',
    WHATSAPP_TEXT = 'WHATSAPP_TEXT'
}

export interface NumberCheck {
    capabilities: NumberCheckCapability[]
    number: string
    isValid: boolean
    isPossible: boolean
    region: 'GB'
    type: number
}

export type PostNumberCheckResponse = NumberCheck[]
