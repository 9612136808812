import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@missionlabs/react'
import { Channel, ChatMessage } from '@missionlabs/types'
import { FC, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetUserFavouriteContactsQuery } from 'shared/store'

import { ChatList } from './ChatList'
import { SearchChatView } from './SearchChatView'

interface ChatSearchTabProps {
    channels: Channel[]
    messages: ChatMessage[]
    setSearchOnFocus: (value: SetStateAction<boolean>) => void
    messagesLoading: boolean
    channelsLoading: boolean
}

export const ChatSearchTab: FC<ChatSearchTabProps> = ({
    messages,
    messagesLoading,
    channels,
    channelsLoading
}) => {
    const { t } = useTranslation()
    const [index, setIndex] = useState<number>(0)

    const user = useSelector(selectAuthenticatedUser)
    const { data: favourites, isLoading: favouritesLoading } = useGetUserFavouriteContactsQuery(
        user?.userID ?? '',
        { skip: !user }
    )

    return (
        <Tabs
            h="full"
            mt="12px"
            isFitted
            index={index}
            onChange={setIndex}
            data-testid="searchChat-tabs"
        >
            <TabList>
                <Tab>{t('Chats')}</Tab>
                <Tab>{t('Messages')}</Tab>
            </TabList>
            <TabPanels h="full" overflow={'hidden'}>
                <TabPanel h="calc(100dvh - 200.5px)">
                    <ChatList channelsIn={channels} />
                </TabPanel>
                <TabPanel h="calc(100dvh - 200.5px)">
                    <SearchChatView
                        favourites={favourites}
                        messages={messages}
                        isLoading={messagesLoading || favouritesLoading || channelsLoading}
                    />
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}
