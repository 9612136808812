import { MeetingParticipant } from '@missionlabs/api'
import { useMemo } from 'react'

export const useAttendeeIDToName = (otherParticipants: Array<MeetingParticipant> | undefined) => {
    const attendeeIdToName = useMemo(() => {
        const mapping = {}
        for (const participant of [...(otherParticipants ?? [])]) {
            mapping[participant.userID] =
                participant.fullName ?? participant.displayName ?? 'Unknown'
        }
        return mapping
    }, [otherParticipants])

    return attendeeIdToName
}
