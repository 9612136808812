import { Channel } from '@missionlabs/types'
import { FC } from 'react'

import { ChatListRow } from './ChatListRow'
export interface ChatListThreadsRowProps {
    item: Channel
}

export const ChatListThreadsRow: FC<ChatListThreadsRowProps> = ({ item }) => {
    return <ChatListRow item={item} />
}
