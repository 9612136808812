import {
    ComponentWithAs,
    HStack,
    IconProps,
    MenuItem as ChakraMenuItem,
    MenuItemProps as ChakraMenuItemProps,
    useMultiStyleConfig
} from '@chakra-ui/react'
import { Body } from 'atoms'
import { cloneElement, forwardRef, isValidElement, ReactElement, useCallback } from 'react'

export type MenuItemProps = Omit<ChakraMenuItemProps, 'iconSpacing'> & {
    iconPlacement?: 'left' | 'right'
    leftElement?: ReactElement
    rightElement?: ReactElement
    spacing?: string | number
    variant?: 'destructive' | 'dark'
}

export const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>(
    (
        {
            icon,
            iconPlacement = 'left',
            leftElement,
            rightElement,
            spacing = '8px',
            variant,
            children,
            ...props
        },
        ref
    ) => {
        const styles = useMultiStyleConfig('Menu', { variant })

        const renderIcon = useCallback(() => {
            if (!isValidElement(icon)) return undefined
            const props: IconProps = {
                ...(icon.props as IconProps),
                boxSize: '20px',
                py: '3px'
            }
            return cloneElement(icon, props)
        }, [icon])

        const isClickable = !leftElement && !rightElement

        // TODO: Need to figure out how to disable keyboard interactions when !isClickable
        return (
            <ChakraMenuItem
                {...props}
                _active={!isClickable ? {} : props._active}
                _hover={!isClickable ? {} : props._hover}
                _focus={!isClickable ? {} : props._focus}
                aria-disabled={!isClickable || props.isDisabled}
                onClick={!isClickable ? undefined : props.onClick}
                ref={ref}
            >
                <HStack align="center" spacing={spacing} sx={{ color: styles.item.color }}>
                    {leftElement || (iconPlacement === 'left' && renderIcon())}
                    <Body size="sm" sx={{ color: styles.item.color }}>
                        {children}
                    </Body>
                    {rightElement || (iconPlacement === 'right' && renderIcon())}
                </HStack>
            </ChakraMenuItem>
        )
    }
) as ComponentWithAs<'button', MenuItemProps>
