import {
    FacebookSuccessResponse,
    PostNumberCheckRequest,
    PostNumberCheckResponse,
    PostWhatsAppBusinessRequest,
    PostWhatsAppNumberRequest,
    PostWhatsAppRegisterNumberRequest,
    PostWhatsAppRequestCodeRequest,
    PostWhatsAppSendMessageRequest,
    PostWhatsAppSendMessageResponse,
    PostWhatsAppVerifyCodeRequest,
    WhatsAppBusiness,
    WhatsAppBusinessTemplate,
    WhatsAppNumber
} from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildWhatsAppAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'whatsappAPI',
        baseQuery: baseQuery,
        tagTypes: ['WhatsAppBusiness', 'WhatsAppNumbers'],
        endpoints: builder => ({
            getWhatsAppBusiness: builder.query<WhatsAppBusiness | undefined, void>({
                query: () => {
                    return `/messaging/whatsapp/clients/:clientID`
                },
                providesTags: ['WhatsAppBusiness']
            }),
            postWhatsAppBusiness: builder.mutation<WhatsAppBusiness, PostWhatsAppBusinessRequest>({
                query: (body: PostWhatsAppBusinessRequest) => {
                    return {
                        url: `/messaging/whatsapp/clients`,
                        method: 'POST',
                        body
                    }
                },
                invalidatesTags: ['WhatsAppBusiness']
            }),
            deleteWhatsAppBusiness: builder.mutation<WhatsAppBusiness | undefined, void>({
                query: () => {
                    return {
                        url: `/messaging/whatsapp/clients/:clientID`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: ['WhatsAppBusiness']
            }),
            getWhatsAppNumbers: builder.query<WhatsAppNumber[], void>({
                query: () => {
                    return `/messaging/whatsapp/clients/:clientID/numbers`
                },
                providesTags: ['WhatsAppNumbers']
            }),
            postWhatsAppNumber: builder.mutation<WhatsAppNumber, PostWhatsAppNumberRequest>({
                query: (number: PostWhatsAppNumberRequest) => {
                    return {
                        url: `/messaging/whatsapp/clients/:clientID/numbers`,
                        method: 'POST',
                        body: number
                    }
                },
                invalidatesTags: ['WhatsAppNumbers']
            }),
            deleteWhatsAppNumber: builder.mutation<WhatsAppBusiness | undefined, string>({
                query: (ID: string) => {
                    return {
                        url: `/messaging/whatsapp/clients/:clientID/numbers/${ID}`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: ['WhatsAppNumbers']
            }),
            postWhatsAppRequestCode: builder.mutation<
                FacebookSuccessResponse,
                PostWhatsAppRequestCodeRequest
            >({
                query: (request: PostWhatsAppRequestCodeRequest) => {
                    const { ID, codeType, language } = request
                    return {
                        url: `/messaging/whatsapp/clients/:clientID/numbers/${ID}/request_code`,
                        method: 'POST',
                        body: {
                            codeType,
                            language
                        }
                    }
                },
                invalidatesTags: ['WhatsAppNumbers']
            }),
            postWhatsAppVerifyCode: builder.mutation<
                FacebookSuccessResponse,
                PostWhatsAppVerifyCodeRequest
            >({
                query: ({ ID, code }) => {
                    return {
                        url: `/messaging/whatsapp/clients/:clientID/numbers/${ID}/verify_code`,
                        method: 'POST',
                        body: { code }
                    }
                },
                invalidatesTags: ['WhatsAppNumbers']
            }),
            postWhatsAppRegisterNumber: builder.mutation<
                FacebookSuccessResponse,
                PostWhatsAppRegisterNumberRequest
            >({
                query: ({ ID, pin }) => {
                    return {
                        url: `/messaging/whatsapp/clients/:clientID/numbers/${ID}/register`,
                        method: 'POST',
                        body: { pin }
                    }
                },
                invalidatesTags: ['WhatsAppNumbers']
            }),
            getWhatsAppTemplates: builder.query<WhatsAppBusinessTemplate[], void>({
                query: () => {
                    return `/messaging/whatsapp/clients/:clientID/templates`
                },
                transformResponse: (response: { data: WhatsAppBusinessTemplate[] }) => response.data
            }),
            postNumberCheck: builder.mutation<PostNumberCheckResponse, PostNumberCheckRequest>({
                query: body => {
                    return {
                        url: `/messaging/clients/:clientID/numbercheck`,
                        method: 'POST',
                        body: {
                            fromNumberE164: body.fromNumber,
                            numbersE164: body.toNumbers
                        }
                    }
                }
            }),
            postWhatsAppMessage: builder.mutation<
                PostWhatsAppSendMessageResponse,
                PostWhatsAppSendMessageRequest & { numberID: string }
            >({
                query: payload => {
                    const { numberID, ...body } = payload
                    return {
                        url: `/messaging/whatsapp/clients/:clientID/numbers/${numberID}/messages`,
                        method: 'POST',
                        body
                    }
                }
            })
        })
    })

    return { api, ...api }
}
