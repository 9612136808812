import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const MoreHorizontalIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MoreHorizontalIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M6 14C5.45 14 4.97933 13.804 4.588 13.412C4.196 13.0207 4 12.55 4 12C4 11.45 4.196 10.979 4.588 10.587C4.97933 10.1957 5.45 10 6 10C6.55 10 7.02067 10.1957 7.412 10.587C7.804 10.979 8 11.45 8 12C8 12.55 7.804 13.0207 7.412 13.412C7.02067 13.804 6.55 14 6 14ZM12 14C11.45 14 10.9793 13.804 10.588 13.412C10.196 13.0207 10 12.55 10 12C10 11.45 10.196 10.979 10.588 10.587C10.9793 10.1957 11.45 10 12 10C12.55 10 13.021 10.1957 13.413 10.587C13.8043 10.979 14 11.45 14 12C14 12.55 13.8043 13.0207 13.413 13.412C13.021 13.804 12.55 14 12 14ZM18 14C17.45 14 16.979 13.804 16.587 13.412C16.1957 13.0207 16 12.55 16 12C16 11.45 16.1957 10.979 16.587 10.587C16.979 10.1957 17.45 10 18 10C18.55 10 19.021 10.1957 19.413 10.587C19.8043 10.979 20 11.45 20 12C20 12.55 19.8043 13.0207 19.413 13.412C19.021 13.804 18.55 14 18 14Z"
            fill="currentColor"
        />
    )
})

export const MoreVerticalIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MoreVerticalIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M12 20C11.45 20 10.9793 19.8043 10.588 19.413C10.196 19.021 10 18.55 10 18C10 17.45 10.196 16.979 10.588 16.587C10.9793 16.1957 11.45 16 12 16C12.55 16 13.021 16.1957 13.413 16.587C13.8043 16.979 14 17.45 14 18C14 18.55 13.8043 19.021 13.413 19.413C13.021 19.8043 12.55 20 12 20ZM12 14C11.45 14 10.9793 13.804 10.588 13.412C10.196 13.0207 10 12.55 10 12C10 11.45 10.196 10.979 10.588 10.587C10.9793 10.1957 11.45 10 12 10C12.55 10 13.021 10.1957 13.413 10.587C13.8043 10.979 14 11.45 14 12C14 12.55 13.8043 13.0207 13.413 13.412C13.021 13.804 12.55 14 12 14ZM12 8C11.45 8 10.9793 7.804 10.588 7.412C10.196 7.02067 10 6.55 10 6C10 5.45 10.196 4.97933 10.588 4.588C10.9793 4.196 11.45 4 12 4C12.55 4 13.021 4.196 13.413 4.588C13.8043 4.97933 14 5.45 14 6C14 6.55 13.8043 7.02067 13.413 7.412C13.021 7.804 12.55 8 12 8Z"
            fill="#616E7C"
        />
    )
})
