import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ExitIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ExitIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M19.7778 22L4.22222 22C2.99492 22 2 21.0051 2 19.7778L2 14.4459L4.22222 16.2237L4.22222 19.7778L19.7778 19.7778L19.7778 4.22223L4.22223 4.22222L4.22223 7.77636L2 9.55414L2 4.22222C2 2.99492 2.99493 2 4.22223 2L19.7778 2C21.0051 2 22 2.99493 22 4.22223L22 19.7778C22 21.0051 21.0051 22 19.7778 22Z"
                fill="currentColor"
            />
            <path
                d="M7.55556 10.8889V7.55555L2 12L7.55555 16.4444L7.55556 13.1111H16.4444V10.8889H7.55556Z"
                fill="currentColor"
            />
        </g>
    )
})
