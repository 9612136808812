import { Box, Collapse, useDisclosure, VStack } from '@chakra-ui/react'
import { Call, TransferType } from '@missionlabs/api'
import { VirtualPaginationContextProvider } from '@missionlabs/react'
import ContactsProvider from 'features/contacts/context/contactsContext'
import { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AddPersonList } from './AddPerson/AddPersonList'
import { CallButtons } from './CallButtons/CallButtons'
import { CallDetails } from './CallDetails'
import { CallNotes } from './CallNotes'
import { TransferList } from './TransferList/TransferList'

interface ActiveCallProps {
    call: Call
    dialpadElement: ReactNode
    onHangup: (callTraceID: string) => void
}

export const ActiveCall: FC<ActiveCallProps> = ({ call, dialpadElement, onHangup }) => {
    const { t: _t } = useTranslation()
    const dialpad = useDisclosure()
    const transfer = useDisclosure()
    const addPerson = useDisclosure()
    const [transferType, setTransferType] = useState<TransferType | undefined>()

    const closeTransferList = () => {
        setTransferType(undefined)
        transfer.onClose()
    }

    return (
        <VStack h="full" w="full" spacing="24px" mt="14px">
            <VStack spacing="16px" w="full">
                <CallDetails call={call} onHangup={onHangup} />
                <CallButtons
                    call={call}
                    dialpad={dialpad}
                    addPerson={addPerson}
                    transfer={transfer}
                    transferType={transferType}
                    setTransferType={setTransferType}
                />
                <VStack
                    as={Collapse}
                    in={!transfer.isOpen}
                    w="full"
                    startingHeight="0px"
                    unmountOnExit
                    spacing="24px"
                >
                    <VStack spacing="24px" h="full" w="full">
                        {/** Starting height is 0px to prevent layout jitter after collapse.  */}
                        <Collapse in={dialpad.isOpen} startingHeight="0px">
                            {dialpadElement}
                        </Collapse>
                        {/** (ZETA-7522) Hiding call notes
                      <CallNotes />
                    */}
                    </VStack>

                    {/** (ZETA-7522) Hiding call notes
                <Button w="full" variant="secondary">
                    {t('Update Note')}
                </Button>
                */}
                </VStack>
                <CallNotes call={call} />
            </VStack>

            <Box as={Collapse} in={transfer.isOpen} w="full">
                <VirtualPaginationContextProvider>
                    <ContactsProvider>
                        {transferType && (
                            <TransferList
                                call={call}
                                onClose={closeTransferList}
                                transferType={transferType}
                            />
                        )}
                    </ContactsProvider>
                </VirtualPaginationContextProvider>
            </Box>

            <Box as={Collapse} in={addPerson.isOpen} w="full">
                <VirtualPaginationContextProvider>
                    <ContactsProvider>
                        <AddPersonList
                            call={call}
                            onClose={addPerson.onClose}
                            isOpen={addPerson.isOpen}
                        />
                    </ContactsProvider>
                </VirtualPaginationContextProvider>
            </Box>
        </VStack>
    )
}
