import { Scrollable } from '@missionlabs/react'
import { NumberE164 } from '@missionlabs/types'
import { FC } from 'react'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetNumbersQuery } from 'shared/store'

import CreateReportDataList from '../components/CreateReportDataList'
import CreateReportListItem from '../components/CreateReportListItem'
import CreateReportSearch from '../components/CreateReportSearch'
import { useCreateReportSearch } from '../hooks/useCreateReportSearch'

interface ReportFormNumbersProps {
    onSelect(ID: string): void
    isSelected(ID: string): boolean
}

export const ReportFormNumbers: FC<ReportFormNumbersProps> = ({ onSelect, isSelected }) => {
    const { formatToLocalNumber } = useFormatToNumberE164()

    const { data: numbers = [] } = useGetNumbersQuery()

    const { searchValue, setSearchValue, results } = useCreateReportSearch<NumberE164>(
        numbers,
        'numberE164'
    )

    return (
        <CreateReportDataList>
            <CreateReportSearch search={searchValue} setSearch={setSearchValue} />

            <Scrollable height="container.sm">
                {results.map(number => (
                    <CreateReportListItem
                        isSelected={isSelected(number.ID)}
                        onClick={() => onSelect(number.ID)}
                        key={number.ID}
                    >
                        {formatToLocalNumber(number.numberE164)}
                    </CreateReportListItem>
                ))}
            </Scrollable>
        </CreateReportDataList>
    )
}
