import { OutboundSMS } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildSMSAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'messagingApi',
        baseQuery,
        endpoints: builder => ({
            sendMessage: builder.mutation<void, OutboundSMS>({
                query: body => {
                    return {
                        url: '/sendMessage',
                        body: {
                            ...body,
                            type: 'text'
                        },
                        method: 'POST'
                    }
                }
            })
        })
    })

    return { api, ...api }
}
