import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { Teams } from './Teams'

export interface TeamsDashboardProps {}

export const TeamsDashboard: FC<TeamsDashboardProps> = () => {
    return (
        <>
            <Teams />
            <Outlet />
        </>
    )
}
