import { BoxProps, Center, useMultiStyleConfig } from '@chakra-ui/react'
import { ChevronDownIconRegular } from 'atoms/Icons/zeta'
import { SelectProps } from 'molecules/Select/Select'
import { forwardRef, KeyboardEvent, MouseEvent, ReactNode } from 'react'

interface SelectButtonProps
    extends Omit<BoxProps, 'defaultValue' | 'value' | 'children'>,
        Omit<SelectProps, 'options' | 'onChange'> {
    children?: ReactNode
}

export const SelectButton = forwardRef<HTMLDivElement, SelectButtonProps>(
    ({ isDisabled, variant, onClick, onKeyUp, onKeyDown, children, isReadOnly, ...props }, ref) => {
        const { button, icon } = useMultiStyleConfig('CustomSelect', { variant })

        const handleClick = (e: MouseEvent<HTMLDivElement>) => {
            if (isDisabled) return
            return onClick?.(e)
        }

        const handleKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
            if (isDisabled) return

            // always suppress enter key events
            if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
                return false
            }

            return onKeyUp?.(e)
        }

        const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
            if (isDisabled) return
            return onKeyDown?.(e)
        }

        return (
            <Center
                {...props}
                ref={ref}
                role="button"
                tabIndex={0}
                sx={{
                    ...button,
                    w: 'full',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    justifyContent: 'space-between'
                }}
                onClick={handleClick}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                pointerEvents={isReadOnly ? 'none' : 'auto'}
            >
                {children}
                <Center boxSize="40px">
                    <ChevronDownIconRegular sx={{ ...icon }} />
                </Center>
            </Center>
        )
    }
)

SelectButton.displayName = 'SelectButton'
