import { HStack, IconButton } from '@chakra-ui/react'
import { Dropdown, Input } from '@missionlabs/react'
import { DeleteIcon } from '@missionlabs/react/circleloop'
import { stringToOption } from '@missionlabs/utils'

interface IVRMenuOptionProps {
    index: string
    display: string
    availableKeys: string[]
    onSwap: (newIndex: string) => void
    onEdit: () => void
    onDelete: () => void
}

export function IVRMenuOption(props: IVRMenuOptionProps) {
    const { index, display, availableKeys, onSwap, onEdit, onDelete } = props
    return (
        <HStack alignSelf="stretch" gap={4}>
            <Dropdown
                maxW="115px"
                value={index}
                options={availableKeys.map(stringToOption)}
                onChange={onSwap}
            />
            <Input value={display} isReadOnly onClick={onEdit} />
            <IconButton
                w="40px"
                h="40px"
                variant="negatory"
                icon={<DeleteIcon />}
                aria-label={`Delete menu option ${index}`}
                onClick={onDelete}
            />
        </HStack>
    )
}
