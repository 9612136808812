import { Tooltip, TooltipProps, useMultiStyleConfig } from '@chakra-ui/react'
import { LabelledPresenceStatus, UserStatus } from '@missionlabs/types'
import { FC, PropsWithChildren, useMemo } from 'react'

type StatusTooltipProps = TooltipProps & {
    status?: LabelledPresenceStatus
}

export const StatusTooltip: FC<PropsWithChildren<StatusTooltipProps>> = ({
    status: labelledStatus = {},
    children,
    ...props
}) => {
    const { status, label } = labelledStatus
    const styles = useMultiStyleConfig('Avatar')

    const showToolTip = useMemo(() => {
        if (!status) return false
        const statuses = [UserStatus.Active, UserStatus.Offline]

        return !statuses.includes(status as UserStatus)
    }, [status])

    return (
        <Tooltip
            label={showToolTip ? label : ''}
            openDelay={300}
            placement="left"
            sx={{ ...styles.tooltip }}
            {...props}
        >
            {children}
        </Tooltip>
    )
}
