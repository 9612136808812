import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { emptyPageAnatomy } from '../anatomy'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(emptyPageAnatomy.keys)

export const EmptyPageStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => ({
        logo: {
            height: '128px',
            width: 'auto',
            color: `${colorMode}.coolGrey.30`
        },
        title: {
            color: `${colorMode}.coolGrey.40`
        },
        subtitle: {
            color: `${colorMode}.coolGrey.40`
        },
        link: {
            mt: 4,
            fontSize: 'md'
        }
    })
})
