import { VStack } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import UserTeamMenuVisabilityInputs from './UserTeamMenuVisabilityInputs'

interface Props {}

const SmsForm: React.FC<Props> = () => {
    const { t } = useTranslation()

    return (
        <VStack mt={4} w="full" alignItems="left">
            <Body>{t('analytics.reports.form.fieldsToInclude')}</Body>

            <UserTeamMenuVisabilityInputs />
        </VStack>
    )
}

export default SmsForm
