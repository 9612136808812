import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ChatIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChatIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 17.85V23L13 18H15.5C19.6421 18 23 14.6421 23 10.5C23 6.35786 19.6421 3 15.5 3H8.5C4.35786 3 1 6.35786 1 10.5C1 14.1284 3.57664 17.1551 7 17.85ZM6 8.75C6 9.16421 6.33579 9.5 6.75 9.5H17.75C18.1642 9.5 18.5 9.16421 18.5 8.75C18.5 8.33579 18.1642 8 17.75 8H6.75C6.33579 8 6 8.33579 6 8.75ZM6.75 13.5C6.33579 13.5 6 13.1642 6 12.75C6 12.3358 6.33579 12 6.75 12H12.75C13.1642 12 13.5 12.3358 13.5 12.75C13.5 13.1642 13.1642 13.5 12.75 13.5H6.75Z"
            fill="currentColor"
        />
    )
})

export const Chat2Icon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'Chat2Icon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 2C1.89543 2 1 2.89543 1 4V16C1 17.1046 1.89543 18 3 18H5V22L9 18H21C22.1046 18 23 17.1046 23 16V4C23 2.89543 22.1046 2 21 2H3ZM5.5 6C5.22386 6 5 6.22386 5 6.5C5 6.77614 5.22386 7 5.5 7H18.5C18.7761 7 19 6.77614 19 6.5C19 6.22386 18.7761 6 18.5 6H5.5ZM5 9.5C5 9.22386 5.22386 9 5.5 9H18.5C18.7761 9 19 9.22386 19 9.5C19 9.77614 18.7761 10 18.5 10H5.5C5.22386 10 5 9.77614 5 9.5ZM5.5 12C5.22386 12 5 12.2239 5 12.5C5 12.7761 5.22386 13 5.5 13H12.5C12.7761 13 13 12.7761 13 12.5C13 12.2239 12.7761 12 12.5 12H5.5Z"
            fill="currentColor"
        />
    )
})
