import { Children, cloneElement, forwardRef, isValidElement, PropsWithChildren } from 'react'
import { get, useFormContext } from 'react-hook-form'

import { FormControl, FormControlProps } from './FormControl'

export interface ValidatedInputProps extends FormControlProps {
    shouldDisable?: boolean
    shouldRegister?: boolean
}

export const ValidatedInput = forwardRef<HTMLDivElement, PropsWithChildren<ValidatedInputProps>>(
    (
        {
            name = '',
            error: _error,
            shouldDisable = false,
            shouldRegister = true,
            children,
            ...props
        },
        ref
    ) => {
        const {
            register,
            formState: { errors, isSubmitting }
        } = useFormContext()

        const error = _error ?? get(errors, name)

        return (
            <FormControl ref={ref} name={name} error={error} isInvalid={!!error} {...props}>
                {Children.map(children, child =>
                    isValidElement(child)
                        ? cloneElement(child, {
                              ...(shouldRegister ? register(name) : {}),
                              ...child.props,
                              isDisabled: (shouldDisable && isSubmitting) || child.props.isDisabled
                          })
                        : null
                )}
            </FormControl>
        )
    }
)

ValidatedInput.displayName = 'ValidatedInput'
