import { useDisclosure } from '@chakra-ui/react'
import { selectAuthenticatedUser, useDispatch, useSelector } from '@missionlabs/api'
import {
    Body,
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    useToast
} from '@missionlabs/react'
import { FacebookLoginStatus } from '@missionlabs/types'
import { useFacebookLogin } from 'features/admin/pages/WhatsApp/hooks/useFacebookLogin'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
    selectSignupCode,
    selectSignupStatus,
    selectSignupWabaid,
    setFacebookLoginStatus,
    setSignupStatus
} from 'shared/slices/whatsappSlice'
import { usePostWhatsAppBusinessMutation } from 'shared/store'

export const FacebookSignupDialog = () => {
    const { t } = useTranslation()
    const ref = useRef<HTMLButtonElement>(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(selectAuthenticatedUser)
    const status = useSelector(selectSignupStatus)
    const wabaid = useSelector(selectSignupWabaid)
    const code = useSelector(selectSignupCode)
    const { toast } = useToast()
    const { fbSignup, fbLogout } = useFacebookLogin()
    const [createBusiness, { isLoading }] = usePostWhatsAppBusinessMutation()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    useEffect(() => {
        const handleSaveBusiness = async () => {
            if (user?.clientID === undefined) {
                return
            }
            dispatch(setSignupStatus(FacebookLoginStatus.SAVING_WABA_ID))
            await createBusiness({
                wabaID: wabaid,
                code,
                clientID: user.clientID
            }).unwrap()
            dispatch(setFacebookLoginStatus(FacebookLoginStatus.BUSINESS_CREATED))
            fbLogout()
            navigate('/admin/whatsapp')
        }
        if (status === FacebookLoginStatus.SIGNUP_COMPLETE && code && wabaid) {
            handleSaveBusiness()
        }
    }, [wabaid, code, status, user, dispatch, createBusiness, fbLogout, navigate])

    const handleSignup = async () => {
        dispatch(setSignupStatus(FacebookLoginStatus.SIGNUP_IN_PROGRESS))
        await fbSignup()
        dispatch(setSignupStatus(FacebookLoginStatus.SIGNUP_COMPLETE))
        toast({
            title: t('admin.whatsapp.connect.success'),
            status: 'success'
        })
        handleLeave()
    }

    const handleLeave = () => {
        dispatch(setFacebookLoginStatus(FacebookLoginStatus.NO_ACCOUNT))
        navigate('/admin/whatsapp')
    }

    const renderDialogText = (loginStatus: FacebookLoginStatus) => {
        switch (loginStatus) {
            case FacebookLoginStatus.SIGNUP_COMPLETE:
                return t('admin.whatsapp.login.signupComplete')
            case FacebookLoginStatus.SIGNUP_IN_PROGRESS:
                return t('admin.whatsapp.login.continueSignup')
            case FacebookLoginStatus.SAVING_WABA_ID:
                return t('admin.whatsapp.login.savingWabaID')
            case FacebookLoginStatus.BUSINESS_CREATED:
                return t('admin.whatsapp.login.businessCreated')
            case FacebookLoginStatus.NO_ACCOUNT:
            default:
                return t('admin.whatsapp.login.needLogin')
        }
    }

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton onClick={handleLeave} />
                <DialogHeader>{t('admin.whatsapp.login.title')}</DialogHeader>
                <DialogBody gap="12px">
                    <Body>{renderDialogText(status)}</Body>
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel'), onClick: handleLeave }}
                    confirm={{
                        label: t('admin.whatsapp.login.loginnFB'),
                        isDestructive: false,
                        variant: 'facebook',
                        onClick: () => handleSignup()
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
