import { HStack, VStack } from '@chakra-ui/react'
import { selectInData } from '@missionlabs/api'
import { Dropdown, Input, useFormContext, ValidatedInput } from '@missionlabs/react'
import { Country, Option } from '@missionlabs/types'
import { localeToOption } from '@missionlabs/utils'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetLocalesQuery } from 'shared/store'

import { acceptedPaymentCountryCodes, AddPaymentMethodFields } from './AddCardPaymentMethodForm'

const selectLocaleOptions = selectInData<Country[], Option[]>(
    data => data?.map(localeToOption).sort((a, b) => a.label.localeCompare(b.label)) ?? []
)

export interface AddPaymentMethodAddressProps {}

export const AddPaymentMethodAddress: FC<AddPaymentMethodAddressProps> = () => {
    const { t } = useTranslation()
    const { watch, setValue } = useFormContext<AddPaymentMethodFields>()

    const { locales = [] } = useGetLocalesQuery(undefined, {
        selectFromResult: result => ({ ...result, locales: selectLocaleOptions(result) })
    })

    const acceptedLocales = useMemo(
        () =>
            locales.filter(({ value }) =>
                acceptedPaymentCountryCodes.includes(value.toUpperCase())
            ),
        [locales]
    )

    return (
        <VStack h="full" spacing="16px" align="inherit" data-testid="add-payment-method-address">
            <ValidatedInput name="line1" label={t('admin.subscription.paymentMethod.form.line1')}>
                <Input autoComplete="billing address-line1" />
            </ValidatedInput>
            <ValidatedInput name="line2" label={t('admin.subscription.paymentMethod.form.line2')}>
                <Input autoComplete="billing address-line2" />
            </ValidatedInput>
            <HStack spacing="16px" align="inherit">
                <ValidatedInput name="town" label={t('admin.subscription.paymentMethod.form.town')}>
                    <Input autoComplete="billing address-level2" />
                </ValidatedInput>
                <ValidatedInput
                    name="postcode"
                    label={t('admin.subscription.paymentMethod.form.postcode')}
                >
                    <Input autoComplete="billing postal-code" />
                </ValidatedInput>
            </HStack>
            <ValidatedInput name="county" label={t('admin.subscription.paymentMethod.form.county')}>
                <Input autoComplete="billing address-level1" />
            </ValidatedInput>
            <ValidatedInput
                name="country"
                label={t('admin.subscription.paymentMethod.form.country')}
                shouldRegister={false}
            >
                <Dropdown
                    options={acceptedLocales}
                    value={watch('country')}
                    onChange={(value: string) => {
                        setValue('country', value, { shouldDirty: true })
                    }}
                />
            </ValidatedInput>
        </VStack>
    )
}
