import { DeploymentEnvironments } from '@missionlabs/types'
import { getDeploymentEnvironment, populateUrl } from '@missionlabs/utils'
import { createSlice } from '@reduxjs/toolkit'

import { BaseURL } from './authSlice'

export type GuestAppState = {
    baseUrl: string
}

const initialState: GuestAppState = {
    baseUrl: populateUrl(
        BaseURL.GUEST_APP,
        {
            environment: getDeploymentEnvironment() ?? DeploymentEnvironments.prod,
            dataCentre: '',
            country: '',
            product: ''
        },
        true
    )
}

export const guestLinkSlice = createSlice({
    name: 'guestLinkSlice',
    initialState,
    reducers: {}
})

export type GuestAppStateRootState = {
    guestLinkSlice: ReturnType<typeof guestLinkSlice.getInitialState>
}

export const guestAppBaseUrl = (state: GuestAppStateRootState) => state.guestLinkSlice.baseUrl
