import { Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@missionlabs/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DatePeriod } from '../context/index'
import { useAnalytics } from '../hooks/useAnalytics'
import AnalyticsDatePicker from './AnalyticsDatePicker'
import { AnalyticsLoading } from './AnalyticsLoading'
import AnalyticsNoData from './AnalyticsNoData'

interface Props {
    children: React.ReactNode
    header: React.ReactNode
    isLoading?: boolean
    noResults?: boolean
}

type TabMap = Record<number, DatePeriod> & Record<DatePeriod, number>

const tabMap: TabMap = {
    0: 'days',
    1: 'weeks',
    2: 'months',
    3: 'custom',
    days: 0,
    weeks: 1,
    months: 2,
    custom: 3
}

const AnalyticsTabsView: React.FC<Props> = ({ children, header, isLoading, noResults }) => {
    const { setPeriod, period } = useAnalytics()

    const { t } = useTranslation()

    const renderContent = () => {
        if (isLoading) return <AnalyticsLoading />
        if (noResults) return <AnalyticsNoData />

        return children
    }

    return (
        <>
            {typeof header === 'string' ? (
                <Heading size="h3" fontSize="20px" lineHeight="32px" fontWeight={400}>
                    {header}
                </Heading>
            ) : (
                header
            )}
            <Tabs
                index={tabMap[period]}
                isLazy
                onChange={index => {
                    setPeriod(tabMap[index])
                }}
                width="100%"
            >
                <TabList px="8px" pt="8px" w="100%">
                    <Tab fontWeight="500">{t('Daily')}</Tab>
                    <Tab fontWeight="500">{t('Weekly')}</Tab>
                    <Tab fontWeight="500">{t('Monthly')}</Tab>
                    <Tab fontWeight="500">{t('Custom')}</Tab>
                </TabList>

                <TabPanels px="24px">
                    {Object.values(tabMap).map(tab =>
                        typeof tab === 'string' ? (
                            <TabPanel key={tab}>
                                <AnalyticsDatePicker />
                                {renderContent()}
                            </TabPanel>
                        ) : null
                    )}
                </TabPanels>
            </Tabs>
        </>
    )
}

export default AnalyticsTabsView
