import { useAudioVideo } from 'amazon-chime-sdk-component-library-react'
import { DefaultActiveSpeakerPolicy } from 'amazon-chime-sdk-js'
import { useCallback, useEffect, useRef } from 'react'

export const usePrioritisedAttendees = () => {
    const audioVideo = useAudioVideo()

    const attendeesSortedByScore = useRef<string[]>([])
    const attendeeToActivityTime = useRef<Map<string, number>>(new Map())

    const isVideoEnabledForAttendee = useCallback(
        (attendeeId: string): boolean => {
            if (!audioVideo) return false

            return !!audioVideo
                .getRemoteVideoSources()
                .find(({ attendee }) => attendee.attendeeId === attendeeId)
        },
        [audioVideo]
    )

    const getRecentSpeakerScore = useCallback(
        (attendeeID: string, scoreBoost: number, timestamp, milliseconds = 30000): number => {
            const attendeeActiveTime = attendeeToActivityTime.current.get(attendeeID)
            if (!attendeeActiveTime) return 0

            const delta = timestamp - attendeeActiveTime

            if (delta > milliseconds) {
                return 0
            }

            // Linear taper
            return scoreBoost + (1 - delta / milliseconds) * 0.02
        },
        []
    )

    const setRecentSpeakerTime = useCallback(
        (attendeeID: string, score: number, timestamp: number): void => {
            if (score > 0) {
                attendeeToActivityTime.current.set(attendeeID, timestamp)
            }
        },
        []
    )

    const getVideoEnabledScore = useCallback(
        (attendeeID: string, scoreBoost: number): number => {
            return isVideoEnabledForAttendee(attendeeID) ? scoreBoost : 0
        },
        [isVideoEnabledForAttendee]
    )

    const modifyActivityScore = useCallback(
        (attendeeID: string, score: number, timestamp: number): number => {
            setRecentSpeakerTime(attendeeID, score, timestamp)

            const SCORE_BOOST = 0.1

            const recentSpeakerScore = getRecentSpeakerScore(attendeeID, SCORE_BOOST, timestamp)
            const videoEnabledScore = getVideoEnabledScore(attendeeID, SCORE_BOOST)

            return score + Math.max(videoEnabledScore, recentSpeakerScore)
        },
        [getRecentSpeakerScore, getVideoEnabledScore, setRecentSpeakerTime]
    )

    useEffect(() => {
        if (!audioVideo) return

        const onActiveSpeakers = (_activeSpeakers: string[]) => {
            return
        }

        audioVideo.subscribeToActiveSpeakerDetector(
            new DefaultActiveSpeakerPolicy(undefined, -1, 0.1, 0.5),
            onActiveSpeakers,
            scores => {
                const dateNow = Date.now()

                const modifiedScores = new Map<string, number>()
                const scoreToAttendees = new Map<number, string[]>()

                for (const [key, value] of Object.entries(scores)) {
                    if (key.includes('#content')) {
                        continue
                    }

                    const modifiedScore = modifyActivityScore(key, value, dateNow)

                    modifiedScores.set(key, modifiedScore)
                    const scoreGroup = [...(scoreToAttendees.get(modifiedScore) ?? []), key]
                    scoreToAttendees.set(modifiedScore, scoreGroup)
                }

                const sortedKeys = [...scoreToAttendees.keys()].sort((a, b) => b - a)

                const sortedIds = sortedKeys
                    .map(key => scoreToAttendees.get(key))
                    .flat() as unknown as string[]

                attendeesSortedByScore.current = [...sortedIds]
            },
            500
        )

        return () => {
            audioVideo.unsubscribeFromActiveSpeakerDetector(onActiveSpeakers)
        }
    }, [audioVideo, modifyActivityScore])

    const displayedAttendees = useRef<string[]>([])

    const reorderAttendeesFn = useCallback(
        (attendeeIds: string[], maxInView: number, setAttendeeOrder: (arr: string[]) => void) => {
            /*
             * If the amount of attendees is greater then the amount of visible tiles then
             * one tile is spared for showing the overflow
             */
            const maxInViewProper =
                attendeeIds.length > maxInView ? maxInView - 1 : attendeeIds.length

            // Get the current attendees who are currently visible given the grid size
            const toBeDisplayedAttendees: Array<string> = [
                ...displayedAttendees.current.slice(0, maxInViewProper)
            ]

            // Get the attendees who should be visible
            const attendeesWhoShouldBeInView = attendeesSortedByScore.current
                .filter(id => !!attendeeIds.includes(id))
                .slice(0, maxInViewProper)

            const remainingAttendees =
                attendeeIds.length > maxInView
                    ? attendeesSortedByScore.current
                          .filter(id => !!attendeeIds.includes(id))
                          .slice(maxInViewProper)
                    : []

            /*
             * Loop through the current attendees in view if they shouldn't be visible
             * and the current amount of display attendees is greater then what's allowed. Then
             * loop through the attendees who should be visible and if they aren't in visible
             * then switch their position with the attendee who shouldn't be in view.
             * (This only does swapping when the amount of people is greater then what can be displayed)
             */
            for (let i = 0; i < displayedAttendees.current.length; i++) {
                if (
                    !attendeesWhoShouldBeInView.includes(toBeDisplayedAttendees[i] ?? '') &&
                    displayedAttendees.current.length > maxInViewProper
                ) {
                    for (let k = 0; k < attendeesWhoShouldBeInView.length; k++) {
                        const id = attendeesWhoShouldBeInView[k]

                        if (!toBeDisplayedAttendees.includes(id)) {
                            toBeDisplayedAttendees[i] = id
                        }
                    }
                }
            }

            /*
             * Loop through the attendees who should be in view and just push them into
             * the array of attendees to be displayed if they aren't currently visible.
             */
            for (let i = 0; i < attendeesWhoShouldBeInView.length; i++) {
                const id = attendeesWhoShouldBeInView[i]

                if (!toBeDisplayedAttendees.includes(id)) {
                    toBeDisplayedAttendees.push(id)
                }
            }

            // Set the reference array to the array of attendees that will next be displayed
            displayedAttendees.current = toBeDisplayedAttendees

            setAttendeeOrder([...toBeDisplayedAttendees, ...remainingAttendees])
        },
        []
    )

    return { attendeesSortedByScore, reorderAttendeesFn }
}
