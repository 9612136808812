export enum EventType {
    CALL_PARKED = 'call_parked',
    CALL_UNPARKED = 'call_unparked'
}

type Notification<T, E = EventType> = {
    clientID: string
    eventType: E
    websocketID: string
    payload: T
    priority: 'low' | 'normal' | 'high'
}

type CallParkPayload = {
    callTraceID: string
    dialledNumber: string
    originatingNumber: string
    userID: string
    parkedContactName?: string
    parkedContactCompany?: string
    parkerContactName?: string
    parkerContactCompany?: string
}

type CallUnParkPayload = {
    callTraceID: string
}
export interface TeamVmNotificationPayload {
    userID: string
    contactID: string
    contactFullName: string
    companyName: string
    originatingNumber: string
    dialledNumber: string
    messageAttachmentURL: string
    callFrom: string
    teamName: string
    activityID: string
    callTraceID: string
}
export type TeamVmNotificationType = 'team_internal_message_received' | 'team_message_received'

export interface TeamVmNotification {
    txID: string
    type: TeamVMeventType
    body: {
        webSocketID: string
        eventType: TeamVMeventType
        clientID: string
        priority: string
        expiry: number
        payload: TeamVmNotificationPayload
        alert: {
            title: string
            subtitle: string
            body: null
        }
    }
}
type TeamVMeventType =
    | 'team_internal_message_received'
    | 'team_message_received'
    | 'team_message_transcribed'

export type CallParkNotification = Notification<CallParkPayload, EventType.CALL_PARKED>
export type CallUnParkNotification = Notification<CallUnParkPayload, EventType.CALL_UNPARKED>
