import {
    Button,
    DrawerBody,
    DrawerFooter,
    get,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useFormContext,
    yup
} from '@missionlabs/react'
import { HuntGroup, UserCallRoute } from '@missionlabs/types'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { notAvailableSchema } from 'shared/schemas/notAvailable'
import { useGetUsersQuery } from 'shared/store'

import { AddEditTeamDetails } from './AddEditTeamDetails'
import { AddEditTeamMembers } from './AddEditTeamMembers'

export type AddEditTeamFields = Pick<
    HuntGroup,
    | 'name'
    | 'IVR'
    | 'waitMusic'
    | 'customWaitMusicURL'
    | 'holdMusic'
    | 'customHoldMusicURL'
    | 'callRoute'
    | 'callTimeout'
    | 'notAvailable'
    | 'blockAnonymousCalls'
    | 'voicemail'
    | 'unansweredRedirect'
    | 'unansweredRedirectInternal'
    | 'busyRedirect'
    | 'busyRedirectInternal'
    | 'outOfHoursRedirectInternal'
    | 'outOfHoursVoicemail'
    | 'phoneNumbers'
> &
    Pick<UserCallRoute, 'outOfHoursRedirect'>

export const defaultValues: AddEditTeamFields = {
    name: '',
    IVR: { greeting: undefined, greetingEnabled: false },
    waitMusic: false,
    customWaitMusicURL: undefined,
    holdMusic: false,
    customHoldMusicURL: undefined,
    callRoute: {
        callSequence: 'group',
        teamMembers: [],
        inHoursUnanswered: 'ring_out',
        outOfHours: 'voicemail',
        busy: 'inactive'
    },
    notAvailable: { enabled: false },
    blockAnonymousCalls: false,
    callTimeout: undefined,
    unansweredRedirect: undefined,
    unansweredRedirectInternal: undefined,
    busyRedirect: undefined,
    busyRedirectInternal: undefined,
    voicemail: {
        greeting: 'default',
        customGreetingURL: '',
        greetingNoMessage: 'default',
        customGreetingNoMessageURL: '',
        transcribe: true
    },
    outOfHoursVoicemail: {
        greeting: 'default',
        customGreetingURL: '',
        greetingNoMessage: 'default',
        customGreetingNoMessageURL: '',
        transcribe: true
    },
    phoneNumbers: []
}

export const addEditTeamSchema = yup.object({
    name: yup.string().required(),
    IVR: yup.object({
        greeting: yup.string().when('greetingEnabled', {
            is: v => v === true,
            then: () => yup.string().required()
        }),
        greetingEnabled: yup.boolean()
    }),
    waitMusic: yup.boolean(),
    customWaitMusicURL: yup.string().when('waitMusic', {
        is: v => v === true,
        then: () => yup.string()
    }),
    holdMusic: yup.boolean(),
    customHoldMusicURL: yup.string().when('holdMusic', {
        is: v => v === true,
        then: () => yup.string()
    }),
    callRoute: yup.object({
        teamMembers: yup.array().required(),
        inHoursUnanswered: yup.string().required(),
        outOfHours: yup.string()
    }),
    notAvailable: notAvailableSchema().default(undefined),
    blockAnonymousCalls: yup.boolean(),
    callTimeout: yup.number().when('callRoute.inHoursUnanswered', {
        is: v => v !== 'ring_out',
        then: () => yup.number().required()
    }),
    voicemail: yup.object({
        greeting: yup.string(),
        customGreetingURL: yup.string().when('greeting', {
            is: v => v === 'custom',
            then: () => yup.string().required()
        }),
        greetingNoMessage: yup.string().nullable(),
        customGreetingNoMessageURL: yup.string().when('greetingNoMessage', {
            is: v => v === 'custom',
            then: () => yup.string().required()
        })
    }),
    unansweredRedirect: yup.object().when('callRoute.inHoursUnanswered', {
        is: v => v === 'redirect',
        then: () => yup.object({ numberE164: yup.string().required() })
    }),
    unansweredRedirectInternal: yup.object().when('callRoute.inHoursUnanswered', {
        is: v => v === 'redirect_internal',
        then: () => yup.object({ display: yup.string().required() })
    })
})

export interface AddEditTeamFormProps {
    canDelete?: boolean
    onClose: () => void
    onDelete: () => void
    onSubmit: (data: AddEditTeamFields) => Promise<void>
}

export const AddEditTeamForm: FC<AddEditTeamFormProps> = ({
    canDelete = false,
    onClose,
    onDelete,
    onSubmit
}) => {
    const { t } = useTranslation()
    const { formatToNumberE164 } = useFormatToNumberE164()

    const formRef = useRef<HTMLFormElement>(null)

    const [index, setIndex] = useState<number>(0)

    const { data: users = [] } = useGetUsersQuery()

    const methods = useFormContext<AddEditTeamFields>()
    const {
        formState: { errors, isDirty, isSubmitting },
        watch,
        setValue
    } = methods

    const { waitMusic, holdMusic, customWaitMusicURL, customHoldMusicURL } = watch()
    const { customGreetingNoMessageURL, customGreetingURL } = watch('voicemail')

    const handleSubmit = useCallback(
        async (data: AddEditTeamFields) => {
            /** Had issues trying to modify `data` as a workaround it creates a new object with all the properties from `data` */
            const mutableData = JSON.parse(JSON.stringify(data))

            if (mutableData.voicemail.customGreetingURL === '') {
                mutableData.voicemail.greeting = 'default'
            }

            if (mutableData.voicemail.customGreetingNoMessageURL === '') {
                mutableData.voicemail.greetingNoMessage = 'default'
            }

            const numberE164 = mutableData.unansweredRedirect?.numberE164
            if (numberE164) {
                mutableData.unansweredRedirect!.numberE164 = formatToNumberE164(numberE164)
            }

            await onSubmit(mutableData)
        },
        [formatToNumberE164, onSubmit]
    )

    useEffect(
        function setDefaultValuesForTeamOnSwitch() {
            if (waitMusic && !customWaitMusicURL) {
                setValue('customWaitMusicURL', '')
            }

            if (holdMusic && !customHoldMusicURL) {
                setValue('customHoldMusicURL', '')
            }

            // Schema complains if greeting is custom with an empty url value
            setValue('voicemail.greeting', customGreetingURL ? 'custom' : 'default')
            setValue(
                'voicemail.greetingNoMessage',
                customGreetingNoMessageURL ? 'custom' : 'default'
            )
        },
        [
            waitMusic,
            customWaitMusicURL,
            holdMusic,
            customHoldMusicURL,
            customGreetingNoMessageURL,
            customGreetingURL,
            setValue
        ]
    )

    useEffect(() => {
        const count = Object.keys(errors).length
        if (count === 1 && get(errors, 'callRoute.teamMembers')) setIndex(1)
        else if (count > 0) setIndex(0)
    }, [errors])

    return (
        <>
            <DrawerBody>
                <form
                    ref={formRef}
                    id="add-edit-team-form"
                    onSubmit={methods.handleSubmit(handleSubmit)}
                >
                    <Tabs size="md" index={index} isFitted onChange={setIndex}>
                        <TabList mx="24px">
                            <Tab isDisabled={isSubmitting}>{t('admin.teams.form.details_tab')}</Tab>
                            <Tab isDisabled={isSubmitting}>
                                {t('admin.teams.form.members_tab', {
                                    count: watch('callRoute.teamMembers').length
                                })}
                            </Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel p={0}>
                                <AddEditTeamDetails />
                            </TabPanel>
                            <TabPanel p={0}>
                                <AddEditTeamMembers users={users} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </form>
            </DrawerBody>

            <DrawerFooter p="32px 24px 24px" gap="16px">
                {canDelete && (
                    <Button
                        w="full"
                        size="lg"
                        variant="negatory-outline"
                        isDisabled={isSubmitting}
                        onClick={onDelete}
                    >
                        {t('Remove')}
                    </Button>
                )}
                <Button
                    w="full"
                    size="lg"
                    variant="secondary"
                    isDisabled={isSubmitting}
                    onClick={onClose}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    w="full"
                    size="lg"
                    variant="creationary"
                    isDisabled={!isDirty}
                    isLoading={isSubmitting}
                    type="submit"
                    form="add-edit-team-form"
                >
                    {t('Save')}
                </Button>
            </DrawerFooter>
        </>
    )
}
