import { RadioGroup, Stack, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Radio, SelectOption } from '@missionlabs/react'
import { HuntGroupCallRouteSteering } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { UnansweredOptions } from './UnansweredOptions'

interface Props {
    busy?: HuntGroupCallRouteSteering | 'busy_tone'
    audioClipsWithDefault: SelectOption[]
    handleChange(name: string, value: string): void
}

export const TeamLineBusy: FC<Props> = ({ busy, audioClipsWithDefault, handleChange }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    return (
        <VStack spacing="10px" align="inherit">
            <Body sx={{ color: `${colorMode}.coolGrey.80` }}>
                {t('admin.teams.form.lineBusy_text')}
            </Body>
            <RadioGroup
                minH="unset"
                value={busy}
                onChange={value => handleChange('callRoute.busy', value)}
            >
                <Stack spacing={3}>
                    <Radio value="busy_tone">{t('admin.teams.form.lineBusy_busy_tone')}</Radio>
                    <Radio value="voicemail">
                        {t('admin.teams.form.inHoursUnanswered_voicemail')}
                    </Radio>
                    {busy === 'voicemail' && (
                        <UnansweredOptions
                            mode="audioclip"
                            name="busyVoicemail.customGreetingURL"
                            options={audioClipsWithDefault}
                        />
                    )}
                    <Radio value="voicemail_no_message">
                        {t('admin.teams.form.inHoursUnanswered_voicemail_no_message')}
                    </Radio>
                    {busy === 'voicemail_no_message' && (
                        <UnansweredOptions
                            mode="audioclip"
                            name="busyVoicemail.customGreetingNoMessageURL"
                            options={audioClipsWithDefault}
                        />
                    )}
                    <Radio value="redirect_internal">
                        {t('admin.teams.form.inHoursUnanswered_redirect_internal')}
                    </Radio>
                    {busy === 'redirect_internal' && (
                        <UnansweredOptions mode="internal" name="busyRedirectInternal" />
                    )}
                    <Radio value="redirect">
                        {t('admin.teams.form.inHoursUnanswered_redirect')}
                    </Radio>
                    {busy === 'redirect' && (
                        <UnansweredOptions mode="numberE164" name="busyRedirect" />
                    )}
                </Stack>
            </RadioGroup>
        </VStack>
    )
}
