import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const RedirectIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'RedirectIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M14.9999 19C13.8999 19 12.9582 18.6083 12.1749 17.825C11.3916 17.0417 10.9999 16.1 10.9999 15V8C10.9999 7.45 10.8042 6.97933 10.4129 6.588C10.0209 6.196 9.5499 6 8.9999 6C8.4499 6 7.97924 6.196 7.5879 6.588C7.1959 6.97933 6.9999 7.45 6.9999 8V15.15L7.8999 14.25C8.08324 14.0667 8.31257 13.975 8.5879 13.975C8.86257 13.975 9.0999 14.075 9.2999 14.275C9.4999 14.475 9.5959 14.7167 9.5879 15C9.57924 15.2833 9.4749 15.525 9.2749 15.725L6.6999 18.3C6.5999 18.4 6.49157 18.4707 6.3749 18.512C6.25824 18.554 6.13324 18.575 5.9999 18.575C5.86657 18.575 5.74157 18.554 5.6249 18.512C5.50824 18.4707 5.3999 18.4 5.2999 18.3L2.6999 15.7C2.4999 15.5 2.3999 15.2627 2.3999 14.988C2.3999 14.7127 2.4999 14.475 2.6999 14.275C2.8999 14.075 3.13757 13.975 3.4129 13.975C3.68757 13.975 3.9249 14.075 4.1249 14.275L4.9999 15.15V8C4.9999 6.9 5.39157 5.95833 6.1749 5.175C6.95824 4.39167 7.8999 4 8.9999 4C10.0999 4 11.0416 4.39167 11.8249 5.175C12.6082 5.95833 12.9999 6.9 12.9999 8V15C12.9999 15.55 13.1959 16.021 13.5879 16.413C13.9792 16.8043 14.4499 17 14.9999 17C15.5499 17 16.0209 16.8043 16.4129 16.413C16.8042 16.021 16.9999 15.55 16.9999 15V7.85L16.0999 8.75C15.9166 8.93333 15.6876 9.025 15.4129 9.025C15.1376 9.025 14.8999 8.925 14.6999 8.725C14.4999 8.525 14.4042 8.28333 14.4129 8C14.4209 7.71667 14.5249 7.475 14.7249 7.275L17.2999 4.7C17.3999 4.6 17.5082 4.529 17.6249 4.487C17.7416 4.44567 17.8666 4.425 17.9999 4.425C18.1332 4.425 18.2582 4.44567 18.3749 4.487C18.4916 4.529 18.5999 4.6 18.6999 4.7L21.2999 7.3C21.4999 7.5 21.5999 7.73733 21.5999 8.012C21.5999 8.28733 21.4999 8.525 21.2999 8.725C21.0999 8.925 20.8622 9.025 20.5869 9.025C20.3122 9.025 20.0749 8.925 19.8749 8.725L18.9999 7.85V15C18.9999 16.1 18.6082 17.0417 17.8249 17.825C17.0416 18.6083 16.0999 19 14.9999 19Z"
            fill="currentColor"
        />
    )
})
