import { useToast } from '@chakra-ui/react'
import {
    declineMeetingRequest,
    MeetingEventType,
    MeetingRequest,
    openMeetingWindow,
    selectAuthenticatedUser,
    selectMeetingRequests,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import isElectron from 'is-electron'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IncomingMeetingNotification } from 'shared/components/Notifications/IncomingMeetingNotification'
import { createNotification } from 'shared/utils/notifications/browserNotifications'
import { ElectronNotification } from 'shared/utils/notifications/electronNotifications'

import { useNotificationRefs } from './useNotificationRefs'

export const useMeetingNotificationListener = (
    isEnabled: boolean,
    browserNotificationsEnabled: boolean
) => {
    const toast = useToast()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const meetings = useSelector(selectMeetingRequests)
    const user = useSelector(selectAuthenticatedUser)

    const { has: isRecentlyDismissed, add: addRecentlyDismissed } = useNotificationRefs()
    const { has, add, remove, dismissOthers } = useNotificationRefs()

    const presentNotification = useCallback(
        (item: MeetingRequest) => {
            toast({
                position: 'top-right',
                duration: null,
                render: ({ onClose: closeToast }) => {
                    const { meetingID } = item

                    add(meetingID, () => {
                        addRecentlyDismissed(meetingID)
                        closeToast()
                    })

                    return (
                        <IncomingMeetingNotification
                            call={item}
                            navigate={navigate}
                            dispatch={dispatch}
                            onClose={remove}
                        />
                    )
                }
            })
        },
        [add, addRecentlyDismissed, dispatch, navigate, remove, toast]
    )

    useEffect(() => {
        const actionNotificationHandle = (_: any, type: string, meeting: MeetingRequest) => {
            const { meetingID } = meeting

            const decline = () => {
                dispatch(declineMeetingRequest({ ...meeting }))
                remove(meetingID)
            }

            switch (type) {
                case 'accept_video_call':
                    if (!user) return
                    dispatch(
                        // @ts-ignore
                        openMeetingWindow({
                            ...meeting,
                            event: MeetingEventType.ACCEPT_VIDEO_CALL,
                            ...user
                        })
                    )
                    remove(meetingID)
                    break
                case 'decline_video_call':
                case 'reject_video_call':
                case 'hangup_from_video_call':
                    decline()
                    break
                case 'view':
                case 'message':
                    navigate(`/contacts/${meeting.caller.contactID}`)
                    break
                case 'dismiss': // this event tells electron to destroy popup window. keeping it here for reference.
                    remove(meetingID)
                    break
                default:
                    break
            }
        }
        ElectronNotification.onAction(actionNotificationHandle)

        return () => {
            ElectronNotification.removeOnAction(actionNotificationHandle)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // Listen for incoming video calls.
    useEffect(() => {
        if (!isEnabled || !meetings) return

        // Dismiss all stale notifications (any notification that no longer has an associated call).
        dismissOthers(meetings.map(call => call.meetingID))

        if (meetings.length < 1) return

        meetings.forEach(item => {
            const { meetingID, caller } = item

            // Ignore notifications already being displayed to prevent spam.
            if (has(meetingID) || isRecentlyDismissed(meetingID)) return

            if (!isElectron()) {
                // In-app notification
                presentNotification(item)
            } else {
                // Electron API notification
                const handle = ElectronNotification.create('/notification/meeting/incoming', item, {
                    user
                })
                add(meetingID, async () => {
                    const id = await handle
                    if (!id) return
                    addRecentlyDismissed(meetingID)
                    ElectronNotification.action(id, 'dismiss', item)
                })
            }

            // Browser API notification
            if (browserNotificationsEnabled) {
                const { displayName, fullName = 'Unknown' } = caller
                createNotification(
                    'Incoming Meeting',
                    t('details.from', { value: displayName || fullName }),
                    { click: window.focus }
                )
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnabled, meetings, user])
}
