import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ParticipantsIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ParticipantsIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M9.92112 5.99428C9.91904 6.05412 9.91638 6.11341 9.91335 6.17216C10.1395 6.33642 10.2521 6.75094 10.1695 7.19212C10.0244 7.96725 9.76776 8.3836 9.50672 8.46712C9.31374 9.0299 9.06162 9.49181 8.76475 9.85296V10.7011C11.6572 11.9145 12.6471 12.713 12.6471 15.5883C12.6471 16.3679 12.1256 17 11.4824 17H2.16471C1.5215 17 1 16.3679 1 15.5883C1 12.713 1.98993 11.9145 4.88238 10.7011V9.85296C4.58342 9.48918 4.32978 9.02312 4.13623 8.45479C3.88676 8.34794 3.64544 7.93466 3.50648 7.19212C3.42743 6.76931 3.52734 6.37089 3.73491 6.19392C3.72354 5.97985 3.71766 5.75835 3.71766 5.52941C3.71766 3.58015 5.10815 2 6.82356 2C8.53897 2 9.92946 3.58015 9.92946 5.52941C9.92946 5.68796 9.92662 5.8429 9.92112 5.99428Z"
                fill="currentColor"
            />
            <path
                d="M18.0471 17H13.3099C13.5682 16.5741 13.7059 16.0712 13.7059 15.5882C13.7059 14.0348 13.4399 12.8107 12.5905 11.8195C12.5501 11.7724 12.5088 11.7263 12.4664 11.681C12.5256 11.6545 12.5858 11.6278 12.647 11.6009V10.8739C12.4024 10.5621 12.1949 10.1626 12.0365 9.67551C11.8324 9.58393 11.635 9.22969 11.5213 8.59322C11.4566 8.23081 11.5384 7.88931 11.7082 7.73762C11.6989 7.55413 11.6941 7.36427 11.6941 7.16804C11.6941 5.49724 12.8317 4.14283 14.2353 4.14283C15.6388 4.14283 16.7765 5.49724 16.7765 7.16804C16.7765 7.30394 16.7741 7.43675 16.7696 7.56651C16.7679 7.61779 16.7658 7.66861 16.7633 7.71898C16.9483 7.85977 17.0404 8.21507 16.9728 8.59322C16.8542 9.25762 16.6442 9.61449 16.4306 9.68608C16.2727 10.1685 16.0664 10.5644 15.8235 10.8739V11.6009C18.1901 12.6409 19 13.3254 19 15.7899C19 16.4582 18.5733 17 18.0471 17Z"
                fill="currentColor"
            />
        </g>
    )
})
