import { AddressLookup, ResetToken, User } from '@missionlabs/types'
import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

interface TempToken {
    object: 'token'
    deleteFlag: boolean
    extendable: boolean
    type: 'temp'
    brand: string
    expires: number
    ID: string
    ttl: number
    created: string
    updated: string
}

/**
 * Separate API handlers for managing reset tokens on users.
 * Seperated from other API's as it needs to create a temporary token
 * instead of using the users token.
 */
export const buildResetTokenAPI = (baseQuery: BaseQueryFn) => {
    const TokenCache = new Map<string, TempToken>()

    // retrieve a cached temp token or fetch a new one when not appropriate
    const fetchTempToken = async (url): Promise<TempToken | undefined> => {
        const cachedToken = [...TokenCache.values()].pop()

        if (cachedToken && cachedToken.expires > Date.now()) return cachedToken

        const response = await fetch(`${url}/admin/tokens/temp`)
        if (response.ok) {
            const data = await response.json()
            TokenCache.clear()
            TokenCache.set(data.ID, data)
            return data
        }

        return undefined
    }

    const api = customCreateApi({
        baseQuery,
        reducerPath: 'resetTokenApi',
        endpoints: builder => ({
            getResetToken: builder.query<ResetToken, string>({
                query: tokenID => `resettokens/${tokenID}`
            }),

            // Would be nice to use baseQuery here because pbxTempTokenBaseQuery already handles fetching temp tokens, but probably can't
            // because of the `endpoint` parameter which overrides the API endpoint at runtime
            updateResetToken: builder.mutation<
                User & { token: string | null | undefined },
                { ID: string; endpoint: string; password: string; address?: AddressLookup }
            >({
                queryFn: async ({ ID, password, endpoint, address }, queryApi, extraOptions) => {
                    const tempToken = await fetchTempToken(endpoint)

                    const res = (await fetchBaseQuery({ baseUrl: `${endpoint}/admin` })(
                        {
                            url: `/resettokens/${ID}`,
                            method: 'PUT',
                            body: { password, address },
                            headers: { 'X-TOKEN': tempToken?.ID }
                        },
                        queryApi,
                        extraOptions
                    )) as { data: User; meta: { response: { headers: Headers } } }

                    return {
                        ...res,
                        data: { ...res.data, token: res.meta?.response.headers.get('X-TOKEN') }
                    }
                }
            })
        })
    })

    const { useGetResetTokenQuery, useUpdateResetTokenMutation } = api
    return { api, useGetResetTokenQuery, useUpdateResetTokenMutation }
}
