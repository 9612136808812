import { Styles } from '@mantine/core'
import { CalendarStylesNames } from '@mantine/dates'

import { colors } from './colors/colors'

const selectedStyles = {
    backgroundColor: `${colors.light.primary.gamma16} !important`,
    color: `${colors.light.tones.navy} !important`,
    marginTop: '-1px',
    height: '39px',
    width: '39px'
}

const inRangeStyles = {
    ...selectedStyles,
    color: `${colors.light.tones.navy}  !important`,
    backgroundColor: `${colors.light.primary.gamma16}  !important`
}

const dataFocusStyles = {
    outline: '2px solid',
    outlineColor: colors.light.tones.stormGrey,
    outlineOffset: '-2px',
    borderRadius: '3px'
}

const styles: Styles<CalendarStylesNames, Record<string, any>> | undefined = () => ({
    calendarBase: {
        maxWidth: 'none',
        display: 'flex',
        backgroundColor: colors.light.tones.vantaBlue
    },
    calendarHeader: {
        margin: '0 auto',
        marginTop: '14px',
        marginBottom: '16px'
    },
    calendarHeaderLevel: {
        fontFamily: 'Mulish',
        fontWeight: 'bold',
        fontSize: '13px',
        lineHeight: '31px',
        color: colors.light.tones.navy,
        '&[data-focus-visible-added]': dataFocusStyles
    },
    calendarHeaderControl: {
        color: colors.light.tones.navy,
        '&[data-focus-visible-added]': dataFocusStyles
    },
    pickerControl: {
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        fontSize: '13px',
        color: colors.light.tones.navy,
        '&[data-focus-visible-added]': dataFocusStyles
    },
    day: {
        fontFamily: 'Lato',
        fontWeight: 'normal',
        fontSize: '16px',
        color: colors.light.tones.navy,
        '&[data-outside]': {
            opacity: 0
        },
        '&[data-first-in-range]': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        },
        '&[data-last-in-range]': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
        },
        '&[data-in-range]': inRangeStyles,
        '&[data-selected]': selectedStyles,
        '&[data-weekend]': {
            color: `${colors.light.tones.navy} !important`
        },
        '&[data-focus-visible-added]': dataFocusStyles
    },
    month: {
        width: '273px',
        margin: '0 13px'
    },
    weekdaysRow: {
        '& th:nth-child(6), & th:nth-child(7)': {
            /* Styles for "Sa" and "Su" */
            color: colors.light.primary.gamma
        }
    },

    weekday: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        border: 'none',
        color: colors.light.tones.navy
    },
    weekdayCell: {
        backgroundColor: colors.light.primary.gamma,
        paddingBottom: '22px'
    }
})

export default styles
