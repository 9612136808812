import { VStack } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import * as React from 'react'
import { ComponentProps } from 'react'

type FilterSectionProps = ComponentProps<typeof VStack> & { label: string }
export const FilterSection = ({ children, label, ...props }: FilterSectionProps) => {
    return (
        <VStack as="fieldset" align="start" {...props}>
            <Body as="legend" size="md" variant="bold" mb="10px">
                {label}
            </Body>
            {children}
        </VStack>
    )
}
