// FIXME: Fix eqeqeq issues
/* eslint-disable eqeqeq */
export function preferCodec(sdp: string, codecName: string, forbiddenCodecs?: string[]) {
    //Find all the a=rtpmap lines
    //Find the line with the codec we want.
    //Read the <payload> number
    //Find the line m=audio, and grab the numbers on that line
    //Remove the <payload> number
    //Move it to the front of the list
    //Put everything back together and return

    let lines = sdp.split('\n')

    lines = lines.reduce((prev, curr) => {
        if (!forbiddenCodecs) return prev

        const forbiddenLine = forbiddenCodecs?.find(codec => curr.includes(codec))
        return forbiddenLine ? prev : [...prev, curr]
    }, [] as string[])

    const mLines = lines.filter(function (x) {
        return x.indexOf('m=audio') == 0
    })

    if (mLines.length != 1) {
        console.warn('SDP looks invalid (not exactly one m=audioLine), returning SDP unedited')
        return sdp
    }
    const mLine = mLines[0]
    const mLineSplit = mLine.split(' ')
    if (mLineSplit.length < 4) {
        //3 bits of data split by spaces, then a load of numbers split by spaces. we're interested in the numbers.
        console.warn('Error : m=audio line on SDP malformed')
        return sdp
    }
    let mLineNumbers = mLineSplit.slice(3)
    const aLines = lines.filter(function (x) {
        return x.indexOf('a=rtpmap:') == 0
    })
    const codecLines = aLines.filter(function (x) {
        return x.indexOf(codecName) != -1
    })

    if (codecLines.length == 0) {
        console.log(
            'Requested to limit codecs to ' +
                codecName +
                'but no rtpmap line for that codec. Returning sdp unedited'
        )
        return sdp
    }
    // eslint-disable-next-line
    const reg = /:(.*?)\s/
    codecLines.forEach(function (line) {
        const matches = reg.exec(line) //between first : and space is the number we're interested in
        const foundMatch = matches?.length == 2
        if (foundMatch) {
            const payload = matches[1] //I can't write regexs so its the second match for some reason.
            const newMLineNumbers = mLineNumbers.filter(function (x) {
                return x != payload
            }) //remove that number from the mLine.
            newMLineNumbers.unshift(payload) //...and put it in at the front
            mLineNumbers = newMLineNumbers
        } else {
            console.warn(
                'There is a bug in regex or invalid SDP. Error finding the payload match in line: ',
                line
            )
        }
    })
    //Turn the doctored list of numbers back into a string, and put it after the start of mLine to re-create a valid mline.
    const mLineNumbersString = mLineNumbers.join(' ')
    const mLineStart = mLineSplit.slice(0, 3).join(' ')
    const mLineAltered = mLineStart + ' ' + mLineNumbersString
    //Replace the old mLine with the new one, turn the lines back into a big string forming the SDP and return.
    const index = lines.indexOf(mLine)
    lines[index] = mLineAltered
    const returnSDP = lines.join('\n')

    return returnSDP
}

export function updateBandwidthRestriction(sdp, bandwidth) {
    let modifier = 'AS'
    // @ts-ignore
    if (adapter && adapter.browserDetails.browser === 'firefox') {
        bandwidth = (bandwidth >>> 0) * 1000
        modifier = 'TIAS'
    }
    if (sdp.indexOf('b=' + modifier + ':') === -1) {
        // insert b= after c= line.
        sdp = sdp.replace(/c=IN (.*)\r\n/, 'c=IN $1\r\nb=' + modifier + ':' + bandwidth + '\r\n')
    } else {
        sdp = sdp.replace(
            new RegExp('b=' + modifier + ':.*\r\n'),
            'b=' + modifier + ':' + bandwidth + '\r\n'
        )
    }
    return sdp
}

export function removeBandwidthRestriction(sdp) {
    return sdp.replace(/b=AS:.*\r\n/, '').replace(/b=TIAS:.*\r\n/, '')
}
