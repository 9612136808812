import { ChatMessageNotification, removeMessage } from '@missionlabs/api'
import { Notification } from '@missionlabs/react'
import { FC, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationProps } from 'shared/components/Notifications/types'
import { useGetPresence } from 'shared/hooks/useGetPresence'
import { useMuteChatChannelNotifications } from 'shared/hooks/useMuteChatChannelNotifications'

export const IncomingChatMessageNotification: FC<NotificationProps<ChatMessageNotification>> = ({
    call: message,
    dispatch,
    navigate,
    onClose
}) => {
    const { t } = useTranslation()

    const { status } = useGetPresence(message?.userID)

    const channelTeamName = message?.channelName !== 'USER_CHAT' ? message?.channelName : undefined

    const { onMuteChatChannelNotifications } = useMuteChatChannelNotifications(message?.channelID)

    const onMessage = (event?: MouseEvent) => {
        if (!message) return
        event?.stopPropagation()
        navigate(`/chat/${message.channelID}`)
        onDismiss()
    }

    const onDismiss = (event?: MouseEvent) => {
        if (!message) return
        event?.stopPropagation()
        dispatch(removeMessage(message.messageID))
        onClose(message.messageID)
    }

    const onDisableNotifications = (event?: MouseEvent) => {
        if (!message) return
        event?.stopPropagation()
        onMuteChatChannelNotifications()
        onDismiss()
    }

    if (!message) return null

    return (
        <Notification
            variant="chat"
            header={t('notifications.incomingChat')}
            status={status}
            group={false}
            name={channelTeamName || message.sender.name || 'Unknown'}
            subText={message.messageBody}
            onClick={onMessage}
            onDismiss={onDismiss}
            onDisableNotifications={onDisableNotifications}
        />
    )
}
