import { radioAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys)

export const RadioStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => {
        const hoverStyles = {
            bg: `${colorMode}.tones.stormGrey`
        }
        const disabledStyles = {
            bg: `${colorMode}.tones.periwinkle`,
            color: `${colorMode}.tones.stormGrey`
        }

        return {
            container: {
                lineHeight: 'initial'
            },
            control: {
                bg: `${colorMode}.tones.periwinkle`,
                border: 'none',
                h: '16px',
                w: '16px',
                _checked: {
                    bg: `${colorMode}.alerts.aqua`,
                    _disabled: disabledStyles,
                    _hover: hoverStyles
                },
                _disabled: {
                    ...disabledStyles,
                    _hover: {
                        bg: `${colorMode}.tones.periwinkle`
                    }
                },
                _hover: hoverStyles
            },
            label: {
                color: `${colorMode}.tones.navy`,
                marginInlineStart: '10px'
            }
        }
    },
    variants: {
        destructive: ({ colorMode }) => ({
            control: {
                _checked: {
                    bg: `${colorMode}.alerts.rose`
                }
            }
        }),
        warning: ({ colorMode }) => ({
            control: {
                _checked: {
                    bg: `${colorMode}.avatar.amber`
                }
            }
        }),
        creationary: ({ colorMode }) => ({
            control: {
                _checked: {
                    bg: `${colorMode}.alerts.aqua`
                }
            }
        })
    }
})
