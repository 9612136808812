import { AlertDialogFooter } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    BoldTranslation,
    Button,
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogHeader,
    DialogOverlay,
    FormProvider,
    useYupForm,
    yup
} from '@missionlabs/react'
import { format } from 'date-fns'
import { FC, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCancelClientSubscriptionMutation, useGetSummaryQuery } from 'shared/store'

import { CancelSubscriptionForm } from './CancelReasonForm'

export interface CancelSubscriptionDialogProps {
    isOpen: boolean
    onClose: () => void
}

export interface CancelSubscriptionFields {
    cancelCategory: string
    cancelReason: string
}

const cancelSubscriptionSchema = yup.object({
    cancelCategory: yup.string().required(),
    cancelReason: yup.string().required()
})

export const CancelSubscriptionDialog: FC<CancelSubscriptionDialogProps> = ({
    isOpen,
    onClose
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const ref = useRef(null)

    const user = useSelector(selectAuthenticatedUser)
    const { data, isLoading: isLoadingSummary } = useGetSummaryQuery()
    const [cancelClientSubscription, { isLoading: isLoadingCancelling, isError, isSuccess }] =
        useCancelClientSubscriptionMutation()

    const methods = useYupForm<CancelSubscriptionFields>(cancelSubscriptionSchema, {
        defaultValues: {}
    })

    const {
        formState: { isValid }
    } = methods

    const termEndData = useMemo(() => {
        if (!data) return
        const { currentTerm } = data
        const usageEnd = format(currentTerm.termEnd * 1000, 'kk:mm MMMM do yyyy')
        return usageEnd
    }, [data])

    const handleSubmit = async (data: CancelSubscriptionFields) => {
        if (!user) return

        const { cancelReason, cancelCategory } = data
        const { clientID } = user
        try {
            await cancelClientSubscription({
                ID: clientID,
                cancelReason,
                cancelCategory
            }).unwrap()
        } catch (e) {
            console.error('Failed to cancel client subscription')
        }
    }

    const ErrorBody = <BoldTranslation i18nKey="admin.subscription.cancel.error" fontWeight={500} />

    const SuccessBody = (
        <BoldTranslation i18nKey="admin.subscription.cancel.success" fontWeight={500} />
    )

    const hasCancellationBeenRequested = useMemo(() => isSuccess || isError, [isSuccess, isError])

    return (
        <Dialog
            leastDestructiveRef={ref}
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={() => navigate(-1)}
        >
            <DialogOverlay />
            <DialogContent>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleSubmit)}>
                        <DialogCloseButton />
                        <DialogHeader>{t('admin.subscription.cancel.title')}</DialogHeader>
                        <DialogBody mb="32px">
                            {isSuccess && SuccessBody}
                            {isError && ErrorBody}
                            {!hasCancellationBeenRequested && (
                                <>
                                    <BoldTranslation
                                        i18nKey="admin.subscription.cancel.bodynew"
                                        fontWeight={500}
                                        values={{ cancelledAt: termEndData }}
                                    />
                                    <CancelSubscriptionForm />
                                </>
                            )}
                        </DialogBody>

                        {!hasCancellationBeenRequested && (
                            <AlertDialogFooter>
                                <Button
                                    ref={ref}
                                    size="lg"
                                    width="full"
                                    variant="secondary"
                                    onClick={onClose}
                                >
                                    {t('Cancel')}
                                </Button>

                                <Button
                                    size="lg"
                                    width="full"
                                    variant={'negatory'}
                                    isDisabled={!isValid}
                                    isLoading={isLoadingCancelling || isLoadingSummary}
                                    type="submit"
                                >
                                    {t('Continue')}
                                </Button>
                            </AlertDialogFooter>
                        )}
                    </form>
                </FormProvider>
            </DialogContent>
        </Dialog>
    )
}
