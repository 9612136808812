import { selectActiveMeeting, useSelector } from '@missionlabs/api'
import {
    ContentShare,
    useContentShareState,
    useMeetingManager,
    useRemoteVideoTileState,
    useRosterState
} from 'amazon-chime-sdk-component-library-react'
import { FC, RefObject, useEffect, useState } from 'react'

import { useMeetingContext } from '../provider'
import VideoGrid from './TileGrid'
import LocalVideoTileExtended from './VideoTiles/LocalVideoTile'
import PlaceholderVideoTile from './VideoTiles/PlaceholderVideoTile'
import VideoTileExtended from './VideoTiles/RemoteVideoTile'
import { SelfTile } from './VideoTiles/SelfTile'
import { WaitingTile } from './VideoTiles/WaitingTile'

const MAX_PARTICIPANTS_IN_VIEW = 14
const MAX_PARTICIPANTS_IN_VIEW_WITH_SCREEN_SHARE = 6
const TILES_TILL_SELF = 3

export interface VideoTilesProps {
    constraintsRef: RefObject<HTMLDivElement>
}

const VideoTiles: FC<VideoTilesProps> = ({ constraintsRef }) => {
    const { reorderAttendees } = useMeetingContext()
    const { meetingSession } = useMeetingManager()

    const { tileId, sharingAttendeeId } = useContentShareState()
    const { roster } = useRosterState()
    const { attendeeIdToTileId } = useRemoteVideoTileState()

    const meeting = useSelector(selectActiveMeeting)

    const [orderedAttendeeIds, setOrderedAttendeeIds] = useState<Array<string>>([])

    const attendeeId = meetingSession?.configuration.credentials?.attendeeId || ''
    const numberOfTiles = meeting?.numberOfTiles ?? 0
    const shouldShowSelf =
        (numberOfTiles ? numberOfTiles : Object.keys(roster).length > TILES_TILL_SELF) ||
        tileId != null

    useEffect(() => {
        const isScreenBeingShared = tileId && sharingAttendeeId
        const maxInView = isScreenBeingShared
            ? MAX_PARTICIPANTS_IN_VIEW_WITH_SCREEN_SHARE
            : numberOfTiles
            ? numberOfTiles
            : MAX_PARTICIPANTS_IN_VIEW

        const int = setInterval(() => {
            reorderAttendees(Object.keys(roster), maxInView, setOrderedAttendeeIds)
        }, 1000)

        return () => {
            clearInterval(int)
        }
    }, [reorderAttendees, sharingAttendeeId, numberOfTiles, roster, tileId])

    function renderParticipantTile(id: string, index: number) {
        const defaultProps = {
            key: id,
            chimeAttendeeId: id,
            css: `grid-area: ${String.fromCharCode(index + 97)};`,
            placeholder: roster[id]?.name
        }

        if (id === attendeeId) return <LocalVideoTileExtended {...defaultProps} name="Me" />
        return (
            <VideoTileExtended
                {...defaultProps}
                name={roster[id]?.name}
                tileId={attendeeIdToTileId[id]}
            />
        )
    }

    function renderVideoTiles() {
        const isScreenBeingShared = tileId && sharingAttendeeId
        const maxTiles = isScreenBeingShared
            ? MAX_PARTICIPANTS_IN_VIEW_WITH_SCREEN_SHARE
            : numberOfTiles
            ? numberOfTiles
            : MAX_PARTICIPANTS_IN_VIEW

        const actualSpeakers = !shouldShowSelf
            ? orderedAttendeeIds.filter(speaker => speaker !== attendeeId)
            : orderedAttendeeIds
        const additionalParticipants = actualSpeakers.slice(maxTiles - 1)
        const visibleParticipants = actualSpeakers.slice(0, maxTiles - 1)

        return [
            // Show the screen share tile if present...
            isScreenBeingShared ? <ContentShare key={tileId} css="grid-area: ft;" /> : undefined,
            // Show the visible participants...
            ...visibleParticipants.map((id, idx) => renderParticipantTile(id, idx)),
            /* Show any additional participants as either...
             * - A single "multi" tile if there is more than one
             * - A single tile for the remaining participant if there is only one
             */
            additionalParticipants.length > 1 ? (
                <PlaceholderVideoTile
                    muted={false}
                    placeholder={`${additionalParticipants.length}`}
                    css="display: flex;"
                />
            ) : additionalParticipants.length === 1 ? (
                renderParticipantTile(additionalParticipants[0], visibleParticipants.length)
            ) : undefined,
            orderedAttendeeIds.length <= 0 ? <WaitingTile /> : undefined
        ].filter(val => val !== undefined)
    }

    return (
        <>
            <VideoGrid layout={tileId != null ? 'featured' : 'standard'}>
                {renderVideoTiles()}
            </VideoGrid>
            {!shouldShowSelf && !!attendeeId && (
                <SelfTile
                    chimeAttendeeId={attendeeId}
                    constraintsRef={constraintsRef}
                    roster={roster}
                />
            )}
        </>
    )
}

export default VideoTiles
