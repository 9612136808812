import { selectPreferences, updatePreferences, useDispatch, useSelector } from '@missionlabs/api'
import { useNoiseSuppression } from '@missionlabs/meetings'
import { Dropdown, FormControl, Slider, useInputMonitor } from '@missionlabs/react'
import { FileAudioIconSolid, MicrophoneIconSolid, SpeakerIconSolid } from '@missionlabs/react/zeta'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { isChime, useDevicesContext } from 'shared/components/DevicesContext'

import { SetupSection } from './SetupSection'

export interface AudioSettingsProps {
    isPreview?: boolean
}

export const AudioSettings: FC<AudioSettingsProps> = ({ isPreview = false }) => {
    const dispatch = useDispatch()
    const ctx = useDevicesContext()
    const { t } = useTranslation()

    const preferences = useSelector(selectPreferences)

    const inputVolume = useInputMonitor(
        ctx.inputDeviceID,
        ctx.inputDevices.find(device => device.value === ctx.inputDeviceID)?.label
    )

    const { isVoiceFocusEnabled, isVoiceFocusSupported, onChangeNoiseSuppression } =
        useNoiseSuppression(preferences?.videoNoiseSuppression)

    function handleMicrophoneChange(value: string) {
        if (isChime(ctx)) ctx.handleChangeAudioInput(value)
        dispatch(updatePreferences({ inputDeviceID: value }))
    }

    function handleNoiseCancellationChange(value: boolean) {
        if (!isPreview) onChangeNoiseSuppression(value)
        dispatch(updatePreferences({ videoNoiseSuppression: value }))
    }

    function handleOutputChange(value: string) {
        if (isChime(ctx)) ctx.handleChangeAudioOutput(value, preferences?.audioVolume)
        dispatch(updatePreferences({ outputDeviceID: value }))
    }

    function handleOutputVolumeChange(value: number) {
        if (isChime(ctx) && ctx.outputDeviceID) {
            ctx.handleChangeAudioOutput(ctx.outputDeviceID, value)
        }
        dispatch(updatePreferences({ audioVolume: value }))
    }

    return (
        <SetupSection title={t('Audio Settings')}>
            <FormControl name="microphone" label={t('Microphone')} variant="dark">
                <Dropdown
                    value={ctx.inputDeviceID}
                    options={ctx.inputDevices}
                    placeholder={t('No device selected')}
                    isDisabled={ctx.inputDevices.length <= 0}
                    leftIcon={<MicrophoneIconSolid boxSize="20px" p="3.33px" />}
                    onChange={handleMicrophoneChange}
                />
            </FormControl>
            <Slider
                value={inputVolume}
                min={1}
                step={10}
                max={100}
                isDisabled={ctx.inputDevices.length <= 0}
                isReadOnly
                aria-label="Microphone level"
            />
            <FormControl
                name="background-noise-cancellation"
                label={t('Background noise cancellation')}
                variant="dark"
            >
                <Dropdown
                    value={isPreview ? preferences?.videoNoiseSuppression : isVoiceFocusEnabled}
                    options={[
                        { label: t('Disabled'), value: false },
                        { label: t('Enabled'), value: true }
                    ]}
                    isDisabled={!isVoiceFocusSupported}
                    leftIcon={<FileAudioIconSolid boxSize="20px" p="3.33px" />}
                    onChange={handleNoiseCancellationChange}
                />
            </FormControl>
            <FormControl name="audio-output" label={t('Audio output')} variant="dark">
                <Dropdown
                    value={ctx.outputDeviceID}
                    options={ctx.outputDevices}
                    placeholder={t('No device selected')}
                    isDisabled={ctx.outputDevices.length <= 0}
                    leftIcon={<SpeakerIconSolid boxSize="20px" p="3.33px" />}
                    onChange={handleOutputChange}
                />
            </FormControl>
            <Slider
                value={preferences?.audioVolume}
                min={0}
                step={0.1}
                max={1}
                isDisabled={ctx.outputDevices.length <= 0}
                aria-label="Output volume"
                sx={{ padding: 'none' }}
                onChange={handleOutputVolumeChange}
            />
        </SetupSection>
    )
}
