import { Divider, HStack, VStack } from '@chakra-ui/react'
import {
    Button,
    FormProvider,
    Input,
    Toggle,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { Destination, DestinationTypes } from '@missionlabs/types'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export type EditDeviceDefaultFields<T = any> = T & {
    type?: DestinationTypes
    label: string
    nonRinging: boolean
}

export const defaultValues: EditDeviceDefaultFields = {
    label: '',
    nonRinging: false
}

export interface EditDeviceDefaultFormProps<T> {
    values?: Destination
    schema?: yup.ObjectSchema<object>
    showRingingField?: boolean
    getValues?: (data: Destination | undefined) => EditDeviceDefaultFields<T>
    onClose: () => void
    onDelete: () => void
    onSubmit: (data: EditDeviceDefaultFields<T>) => void
    children?: ReactNode
}

export function EditDeviceDefaultForm<T>({
    values,
    schema,
    showRingingField = true,
    getValues,
    onClose,
    onDelete,
    onSubmit,
    children,
    ...props
}: EditDeviceDefaultFormProps<T>): JSX.Element {
    const { t } = useTranslation()

    const editDeviceDefaultSchema = yup
        .object({ label: yup.string(), nonRinging: yup.boolean() })
        .concat(schema as any)

    function defaultGetValues<T>(data: Destination | undefined): EditDeviceDefaultFields<T> {
        if (!data) return defaultValues as EditDeviceDefaultFields<T>
        return {
            type: data.type,
            label: (data.label || data.name) ?? defaultValues.label,
            nonRinging: data.nonRinging ?? defaultValues.nonRinging
        } as EditDeviceDefaultFields<T>
    }

    function handleSubmit(data: EditDeviceDefaultFields<T>) {
        return onSubmit(data)
    }

    const methods = useYupForm<EditDeviceDefaultFields>(editDeviceDefaultSchema, {
        defaultValues: defaultValues,
        values: getValues ? getValues(values) : defaultGetValues<T>(values)
    })
    const {
        formState: { isDirty, isSubmitting },
        setValue,
        watch
    } = methods

    return (
        <FormProvider {...methods}>
            <VStack
                as="form"
                h="full"
                spacing="32px"
                align="stretch"
                justify="space-between"
                onSubmit={methods.handleSubmit(handleSubmit)}
                data-testid="edit-device-default-form"
                {...props}
            >
                <VStack p="24px" spacing="24px" align="inherit">
                    <ValidatedInput name="label" label={t('devices.edit.default.label')}>
                        <Input placeholder={t('devices.edit.default.label_placeholder')} />
                    </ValidatedInput>
                    {showRingingField && (
                        <>
                            <Divider />
                            <ValidatedInput name="nonRinging" shouldRegister={false}>
                                <Toggle
                                    side="left"
                                    size="lg"
                                    label={t('devices.edit.default.nonRinging')}
                                    subtext={t('devices.edit.default.nonRinging_subtext')}
                                    isChecked={!watch('nonRinging')}
                                    onChange={e => {
                                        setValue('nonRinging', !e.target.checked, {
                                            shouldDirty: true
                                        })
                                    }}
                                />
                            </ValidatedInput>
                        </>
                    )}
                    {children}
                </VStack>

                <HStack px="24px" pb="24px" spacing="16px" align="inherit">
                    <Button
                        w="full"
                        size="lg"
                        variant="negatory-outline"
                        isDisabled={isSubmitting}
                        onClick={onDelete}
                    >
                        {t('Remove')}
                    </Button>
                    <Button
                        w="full"
                        size="lg"
                        variant="secondary"
                        isDisabled={isSubmitting}
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        type="submit"
                        w="full"
                        size="lg"
                        variant="creationary"
                        isDisabled={!isDirty}
                        isLoading={isSubmitting}
                    >
                        {t('Save Changes')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}
