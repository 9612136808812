import { useMultiStyleConfig } from '@chakra-ui/react'
import { Body, BodyProps } from 'atoms'
import { FC, PropsWithChildren } from 'react'

export type DrawerTitleProps = BodyProps

export const DrawerTitle: FC<PropsWithChildren<DrawerTitleProps>> = ({ children, ...props }) => {
    const styles = useMultiStyleConfig('Drawer')
    return (
        <Body {...props} sx={{ ...styles.title, ...(props.sx ?? {}) }}>
            {children}
        </Body>
    )
}
