import { useFlags } from 'flagsmith/react'

import { Features, useHasClientFeature } from './useHasClientFeature'

export const useHasFeature = (feature: Features) => {
    const flags = useFlags([feature])

    const isFeatureOnClient = useHasClientFeature(feature)
    const isEnabledOnFlagsmith = flags[`${feature}`]?.enabled ?? false

    return isEnabledOnFlagsmith && isFeatureOnClient
}
