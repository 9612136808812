import { UserRole } from '@missionlabs/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { RequireRole } from 'routes/RequireRole'
import { DocumentTitle } from 'shared/components/DocumentTitle'
import { MainLayout } from 'shared/components/MainLayout'
import { SubNavLink, SubNavMenu } from 'shared/components/SubNav'

import AnalyticsProvider from './context/Provider'

interface Props {}

const Analytics: React.FC<Props> = () => {
    const { t } = useTranslation()

    return (
        <AnalyticsProvider>
            <DocumentTitle breadcrumbs={['Analytics']} />
            <MainLayout
                leftWidth={320}
                left={
                    <SubNavMenu title={t('analytics.title')}>
                        <SubNavLink label={t('analytics.summary')} page="summary" />
                        <SubNavLink label={t('analytics.users')} page="users" />
                        <SubNavLink label={t('analytics.numbers')} page="numbers" />
                        <SubNavLink label={t('analytics.teams')} page="teams" />
                        <RequireRole role={UserRole.ADMIN} redirect={false}>
                            <SubNavLink label={t('analytics.search-number')} page="search-number" />
                            <SubNavLink label={t('analytics.reports.title')} page="reports" />
                        </RequireRole>
                    </SubNavMenu>
                }
                right={<Outlet />}
            />
        </AnalyticsProvider>
    )
}

export default Analytics
