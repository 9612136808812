import {
    Breadcrumb as ChakraBreadcrumb,
    BreadcrumbProps as ChakraBreadcrumbProps
} from '@chakra-ui/react'
import { forwardRef } from 'react'

import { BreadcrumbLink, BreadcrumbLinkProps } from './BreadcrumbLink'

export interface BreadcrumbProps extends ChakraBreadcrumbProps {
    variant?: 'title'
    items: BreadcrumbLinkProps[]
}

const titleStyles = {
    p: {
        fontSize: '20px',
        lineHeight: '32px',
        fontWeight: 400,
        mr: '4px',
        letterSpacing: '-0.36px',
        textDecoration: 'none'
    }
}

export const Breadcrumb = forwardRef<HTMLElement, BreadcrumbProps>(
    ({ variant: _variant, items, ...props }, ref) => {
        const variantStyles = _variant === 'title' ? titleStyles : {}

        return (
            <ChakraBreadcrumb {...props} {...variantStyles} sx={{}} ref={ref}>
                {items.map((item, index) => {
                    const isLast = items.length - 1 === index
                    const { title, isDisabled, isCurrentPage, ...props } = item

                    const variant = (function () {
                        if (isDisabled) return 'inactive'
                        if (isCurrentPage) return 'active'
                        return 'link'
                    })()

                    return (
                        <BreadcrumbLink
                            key={`breadcrumb-${title}`}
                            title={title}
                            variant={variant}
                            isLast={isLast}
                            isDisabled={isDisabled}
                            {...props}
                        />
                    )
                })}
            </ChakraBreadcrumb>
        )
    }
)
