import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { User } from '@missionlabs/types'
import { useGetUserQuery } from 'shared/store'

export interface UseAuthenticatedUserReturn {
    data: User | undefined
    isLoading: boolean
    isError: boolean
}

export const useAuthenticatedUser = (): UseAuthenticatedUserReturn => {
    const user = useSelector(selectAuthenticatedUser)

    const { data, isLoading, isError } = useGetUserQuery(user?.userID ?? '', {
        skip: !user,
        pollingInterval: 30000
    })

    return { data, isLoading, isError }
}
