import { useDisclosure } from '@chakra-ui/react'
import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from '@missionlabs/react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AddNumberDialog } from './AddNumberDialog'
import { AddNumberFields, AddNumberForm } from './AddNumberForm'

export interface AddNumberDrawerProps {}

export const AddNumberDrawer: FC<AddNumberDrawerProps> = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    const [selected, setSelected] = useState<AddNumberFields>()

    function handleClose(parent?: boolean) {
        setSelected(undefined)
        if (parent === true) onClose()
    }

    function handleSubmit(data: AddNumberFields) {
        if (!data) return
        setSelected(data)
    }

    function handleTransition() {
        return navigate('/admin/numbers')
    }

    return (
        <>
            <AddNumberDialog selected={selected} onClose={handleClose} />
            <Drawer size="lg" isOpen={isOpen} onClose={onClose} onCloseComplete={handleTransition}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader pb="0 !important">{t('admin.numbers.add.header')}</DrawerHeader>
                    <DrawerBody>
                        <AddNumberForm onClose={onClose} onSubmit={handleSubmit} />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}
