import { AnimatePresence, domAnimation, LazyMotion } from 'framer-motion'
import { FC, useCallback, useEffect, useState } from 'react'

import { useDataMessages } from '../DataMessagesProvider'
import { ChatDataMessage } from '../DataMessagesProvider/state'
import EmojiReaction from '../EmojiReaction'

function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const ReactionAnimations: FC = () => {
    const { reactions, removeReaction } = useDataMessages()

    const [animations, setAnimations] = useState<{ key: string; element: any }[]>([])

    const removeAnimation = useCallback(
        (message: ChatDataMessage) => {
            removeReaction(message)
            setAnimations(prev => [...(prev ?? []).filter(({ key }) => key !== message.id)])
        },
        [removeReaction]
    )

    useEffect(() => {
        for (const reaction of reactions) {
            if (animations.some(({ key }) => key === reaction.id)) continue

            setAnimations(prev => [
                ...prev,
                {
                    key: reaction.id,
                    element: (
                        <EmojiReaction
                            key={`reaction-motion-${reaction.id}`}
                            message={reaction}
                            removeMessage={removeAnimation}
                            x={randomIntFromInterval(-250, 250)}
                            lastY={`${randomIntFromInterval(-750, -400)}%`}
                            duration={randomIntFromInterval(4, 7)}
                        />
                    )
                }
            ])
        }
    }, [animations, reactions, removeAnimation])

    return (
        <LazyMotion features={domAnimation}>
            {animations.map(({ element, key }) => (
                <AnimatePresence mode="sync" key={`presence-antimation-${key}`}>
                    {element}
                </AnimatePresence>
            ))}
        </LazyMotion>
    )
}

export default ReactionAnimations
