import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PlayCircleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PlayCircleIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 0C5.37257 0 0 5.37257 0 12C0 18.6274 5.37257 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37257 18.6274 0 12 0ZM17.8299 12.3188L17.834 12.3237L9.73395 16.9071L9.7299 16.9021C9.65205 16.96 9.55755 17 9.45 17C9.2016 17 9 16.8137 9 16.5833V7.41667C9 7.18625 9.2016 7 9.45 7C9.55755 7 9.6525 7.04 9.7299 7.09792L9.73395 7.09333L17.834 11.6767L17.8299 11.6812C17.9311 11.7579 18 11.8696 18 12C18 12.1304 17.9311 12.2425 17.8299 12.3188Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_190"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 0C5.37257 0 0 5.37257 0 12C0 18.6274 5.37257 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37257 18.6274 0 12 0ZM17.8299 12.3188L17.834 12.3237L9.73395 16.9071L9.7299 16.9021C9.65205 16.96 9.55755 17 9.45 17C9.2016 17 9 16.8137 9 16.5833V7.41667C9 7.18625 9.2016 7 9.45 7C9.55755 7 9.6525 7.04 9.7299 7.09792L9.73395 7.09333L17.834 11.6767L17.8299 11.6812C17.9312 11.7579 18 11.8696 18 12C18 12.1304 17.9312 12.2425 17.8299 12.3188Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_190)"></g>
        </g>
    )
})

export const PlayIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PlayIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7543 12.4462L19.7601 12.4532L8.06015 18.8699L8.0543 18.8629C7.94185 18.944 7.80535 19 7.65 19C7.2912 19 7 18.7392 7 18.4167V5.58333C7 5.26075 7.2912 5 7.65 5C7.80535 5 7.9425 5.056 8.0543 5.13708L8.06015 5.13067L19.7601 11.5473L19.7543 11.5538C19.9005 11.6611 20 11.8174 20 12C20 12.1826 19.9005 12.3395 19.7543 12.4462Z"
            fill="currentColor"
        />
    )
})
