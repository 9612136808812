import { Box, Collapse, HStack, RadioGroup, Stack, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Toggle, useSyncedState } from '@missionlabs/react'
import { NotAvailable, UserCallRoute } from '@missionlabs/types'
import { Schedule } from 'features/settings/pages/Calls/OutOfHoursSetting/Schedule/Schedule'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RedirectExternalRadio } from 'shared/components/OutOfHours/RedirectExternalRadio'
import { RedirectInternalRadio } from 'shared/components/OutOfHours/RedirectInternalRadio'
import { VoicemailRadio } from 'shared/components/OutOfHours/VoicemailRadio'

type OutOfHoursProps = {
    callRoute: UserCallRoute
    onUpdate: (payload: Partial<UserCallRoute>) => void
    /**
     * Defines which days of the week are unavailable
     * @example For full days: `{ monday: {} }`
     * @example For partial days: `{ monday: { from: '13:00', to: '15:00' }}`
     */
    notAvailable?: NotAvailable
    onUpdateNotAvailable: (setting?: NotAvailable) => void
    minW?: string
}

export const OutOfHours: FC<OutOfHoursProps> = ({
    callRoute,
    onUpdate,
    notAvailable,
    onUpdateNotAvailable,
    minW
}) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    /**
     * The behaviour of what happens when a call is made out of hours, i.e.
     * voicemail, redirect_internal, redirect_external
     */
    const [forwardType, setForwardType] = useSyncedState<string>(callRoute?.outOfHours ?? '')

    return (
        <VStack w="full" spacing="0px" align="flex-start">
            <HStack w="full" justify="space-between">
                <Body size="sm" variant="bold">
                    {t('calls.certainTimes')}
                </Body>
                <Toggle
                    aria-label="toggle schedule"
                    isChecked={notAvailable?.enabled}
                    onChange={e => onUpdateNotAvailable({ enabled: e.target.checked })}
                />
            </HStack>
            <Box as={Collapse} in={notAvailable?.enabled} w="full">
                <VStack pt="16px" w="full" spacing="16px" alignItems="flex-start">
                    <Schedule notAvailable={notAvailable} onUpdate={onUpdateNotAvailable} />

                    <RadioGroup value={forwardType} onChange={setForwardType} alignSelf="start">
                        <Body
                            w="full"
                            textAlign="start"
                            py="12px"
                            size="sm"
                            variant="bold"
                            sx={{
                                color: `${colorMode}.coolGrey.80`
                            }}
                        >
                            {t('calls.whatHappens')}
                        </Body>
                        <Stack spacing={1}>
                            <VoicemailRadio
                                type="outOfHours"
                                forwardType={forwardType}
                                callRoute={callRoute}
                                onUpdate={onUpdate}
                                minW={minW}
                            />
                            <RedirectInternalRadio
                                type="outOfHours"
                                forwardType={forwardType}
                                callRoute={callRoute}
                                onUpdate={onUpdate}
                                minW={minW}
                            />
                            <RedirectExternalRadio
                                type="outOfHours"
                                forwardType={forwardType}
                                callRoute={callRoute}
                                onUpdate={onUpdate}
                                minW={minW}
                            />
                        </Stack>
                    </RadioGroup>
                </VStack>
            </Box>
        </VStack>
    )
}
