import { IconButton, IconButtonProps } from '@missionlabs/react'
import { MicrophoneIconSolid, MicrophoneSlashIconSolid } from '@missionlabs/react/zeta'
import { useToggleLocalMute } from 'amazon-chime-sdk-component-library-react'
import { FC } from 'react'

export interface AudioInputControlProps extends Omit<IconButtonProps, 'aria-label' | 'onClick'> {
    /** The label that will be shown when microphone is unmuted , it defaults to `Mute`. */
    muteLabel?: string
    /** The label that will be shown when microphone is muted, it defaults to `Unmute`. */
    unmuteLabel?: string
}

const AudioInputControl: FC<AudioInputControlProps> = ({
    muteLabel = 'Mute',
    unmuteLabel = 'Unmute',
    ...props
}) => {
    const { muted, toggleMute } = useToggleLocalMute()
    return (
        <IconButton
            {...props}
            variant="negatory"
            isRounded
            title={muted ? unmuteLabel : muteLabel}
            onClick={toggleMute}
            aria-label={muted ? unmuteLabel : muteLabel}
            data-testid="audio-input-control"
        >
            {muted ? <MicrophoneSlashIconSolid /> : <MicrophoneIconSolid />}
        </IconButton>
    )
}

export default AudioInputControl
