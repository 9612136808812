import { HStack, VStack } from '@chakra-ui/react'
import {
    Button,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    FormProvider,
    useYupForm
} from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useAddEditMeetingSchema } from '../hooks/useAddEditMeetingSchema'
import { AddEditMeetingFormFields } from './AddEditMeetingDrawer'
import { MeetingDetailsForm } from './MeetingDetailsForm'

type EditMeetingDrawerContentProps = {
    onSubmit: (data: AddEditMeetingFormFields) => void
    onClose: () => void
    isLoading?: boolean
    values: AddEditMeetingFormFields
}

export const EditMeetingDrawerContent: FC<EditMeetingDrawerContentProps> = ({
    values,
    onClose,
    onSubmit,
    isLoading
}) => {
    const { t } = useTranslation()

    const { meetingDetailsSchema } = useAddEditMeetingSchema()

    const methods = useYupForm<AddEditMeetingFormFields>(meetingDetailsSchema, {
        values
    })

    const {
        formState: { isValid }
    } = methods

    return (
        <DrawerContent>
            <DrawerHeader>{t('scheduledMeeting.update.title')}</DrawerHeader>
            <DrawerBody>
                <FormProvider {...methods}>
                    <VStack
                        as="form"
                        h="100%"
                        spacing="16px"
                        align="stretch"
                        justify="space-between"
                        onSubmit={methods.handleSubmit(onSubmit)}
                    >
                        <MeetingDetailsForm />
                        <HStack px="24px" pb="24px" spacing="24px" align="inherit">
                            <Button
                                w="full"
                                size="lg"
                                variant="secondary"
                                isDisabled={isLoading}
                                onClick={onClose}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                type="submit"
                                w="full"
                                size="lg"
                                variant="primary"
                                isDisabled={!isValid}
                                isLoading={isLoading}
                                data-testid="submit-form"
                            >
                                {t('Update')}
                            </Button>
                        </HStack>
                    </VStack>
                </FormProvider>
            </DrawerBody>
        </DrawerContent>
    )
}
