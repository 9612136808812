import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const MixerIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MixerIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 12.5502V4.5C11.5 4.22386 11.7239 4 12 4C12.2761 4 12.5 4.22386 12.5 4.5V12.5502C12.9645 12.6446 13.4075 12.872 13.7678 13.2322C14.7441 14.2086 14.7441 15.7915 13.7678 16.7678C13.4075 17.1281 12.9645 17.3554 12.5 17.4498V19.5C12.5 19.7761 12.2761 20 12 20C11.7239 20 11.5 19.7761 11.5 19.5V17.4498C11.0354 17.3554 10.5925 17.1281 10.2322 16.7678C9.25591 15.7915 9.25591 14.2086 10.2322 13.2322C10.5925 12.8719 11.0354 12.6446 11.5 12.5502ZM12.0036 16.5L12 16.5L11.9964 16.5C11.6137 16.4991 11.2313 16.3527 10.9393 16.0607C10.3535 15.4749 10.3535 14.5251 10.9393 13.9394C11.5251 13.3536 12.4749 13.3536 13.0606 13.9394C13.6464 14.5251 13.6464 15.4749 13.0606 16.0607C12.7687 16.3527 12.3863 16.4991 12.0036 16.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.73223 11.7678C5.09253 12.1281 5.53545 12.3554 6 12.4498V19.5C6 19.7761 6.22386 20 6.5 20C6.77614 20 7 19.7761 7 19.5V12.4498C7.46455 12.3554 7.90746 12.1281 8.26776 11.7678C9.24407 10.7915 9.24407 9.20855 8.26776 8.23224C7.90746 7.87194 7.46455 7.6446 7 7.55024V4.5C7 4.22386 6.77614 4 6.5 4C6.22386 4 6 4.22386 6 4.5V7.55023C5.53545 7.6446 5.09253 7.87194 4.73223 8.23224C3.75592 9.20855 3.75592 10.7915 4.73223 11.7678ZM7.56066 11.0607C7.26836 11.353 6.88544 11.4994 6.50234 11.5L6.5 11.5L6.49676 11.5C6.11396 11.4992 5.73141 11.3527 5.43933 11.0607C4.85355 10.4749 4.85355 9.52513 5.43933 8.93934C6.02512 8.35356 6.97487 8.35356 7.56066 8.93934C8.14644 9.52513 8.14644 10.4749 7.56066 11.0607Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 12.4498C16.5354 12.3554 16.0925 12.1281 15.7322 11.7678C14.7559 10.7915 14.7559 9.20855 15.7322 8.23224C16.0925 7.87193 16.5354 7.6446 17 7.55023V4.5C17 4.22386 17.2239 4 17.5 4C17.7761 4 18 4.22386 18 4.5V7.55024C18.4645 7.64461 18.9075 7.87194 19.2678 8.23224C20.2441 9.20855 20.2441 10.7915 19.2678 11.7678C18.9075 12.1281 18.4645 12.3554 18 12.4498V19.5C18 19.7761 17.7761 20 17.5 20C17.2239 20 17 19.7761 17 19.5V12.4498ZM17.5023 11.5C17.8854 11.4994 18.2684 11.353 18.5606 11.0607C19.1464 10.4749 19.1464 9.52513 18.5606 8.93934C17.9749 8.35356 17.0251 8.35356 16.4393 8.93934C15.8535 9.52513 15.8535 10.4749 16.4393 11.0607C16.7314 11.3527 17.114 11.4992 17.4968 11.5L17.5 11.5L17.5023 11.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.5 24C0.671578 24 0 23.3284 0 22.5V1.5C0 0.671578 0.671578 0 1.5 0H22.5C23.3284 0 24 0.671578 24 1.5V22.5C24 23.3284 23.3284 24 22.5 24H1.5ZM1 22.5C1 22.7761 1.22386 23 1.5 23H22.5C22.7761 23 23 22.7761 23 22.5V1.5C23 1.22386 22.7761 1 22.5 1H1.5C1.22386 1 1 1.22386 1 1.5V22.5Z"
                fill="currentColor"
            />
        </g>
    )
})
