import { useColorMode } from '@chakra-ui/react'
import { IconButton, IconButtonProps, Tooltip } from '@missionlabs/react'
import { ArrowForwardIcon } from '@missionlabs/react/circleloop'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface BlindTransferButtonProps extends Omit<IconButtonProps, 'aria-label'> {
    transferring?: boolean
    onToggle?: () => void
    isDisabled: boolean
}

export const BlindTransferButton: FC<BlindTransferButtonProps> = ({
    transferring,
    onToggle,
    isDisabled,
    ...props
}) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    return (
        <Tooltip label={t('Blind Transfer')}>
            <IconButton
                icon={<ArrowForwardIcon boxSize="16px" color={`${colorMode}.tones.periwinkle`} />}
                variant="dialler"
                isActive={transferring}
                boxSize="48px"
                flex={1}
                isDisabled={isDisabled}
                onClick={onToggle}
                aria-label="blind-transfer"
                {...props}
            />
        </Tooltip>
    )
}
