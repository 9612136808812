import * as yup from 'yup'

export type ExtensionArgs = {
    invalidValues: string[]
    min?: number
    max?: number
}

/** Replace X with single digit and # with 1 or more digits */
export function sanitizeExtensionRegex(ext: string) {
    return ext.replace(/X/g, '[0-9]').replace(/#/g, '[0-9]+')
}

export function extensionIsAllowed(invalidValues: string[], value?: string) {
    const numberRegex = /^[0-9]+$/
    if (!value || !numberRegex.test(value)) return false
    return !invalidValues.some(ext => {
        const extRegex = new RegExp(`^${sanitizeExtensionRegex(ext)}$`)
        return extRegex.test(value.toString())
    })
}

/**
 * Adds the .extension method to yup.string
 *
 * To use import this file before initialising a yup schema
 * and call .extension(args) on a yup.string instance
 */
// @ts-ignore
yup.addMethod(yup.string, 'extension', function ({ invalidValues, min, max }: ExtensionArgs) {
    return this.test('extension', { key: 'validations.extensionUsed' }, value => {
        if (value) {
            return extensionIsAllowed(invalidValues, value)
        }

        return true
    })
        .test('extension', { key: 'validations.stringMin', values: { min } }, (value: string) => {
            if (value && min) {
                return value.length >= min
            }

            return true
        })
        .test('extension', { key: 'validations.stringMax', values: { max } }, (value: string) => {
            if (value && max) {
                return value.length <= max
            }

            return true
        })
})
