import { ComponentStyleConfig } from '@chakra-ui/react'

export const BodyStyles: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        color: `${colorMode}.tones.navy`,
        fontFamily: 'body'
    }),
    defaultProps: {
        size: 'md'
    },
    variants: {
        bold: ({ colorMode }) => ({
            color: `${colorMode}.tones.midnightExpress`,
            fontWeight: 700
        }),
        link: {
            fontWeight: 700,
            textDecoration: 'underline'
        },
        'link-bold': ({ colorMode }) => ({
            color: `${colorMode}.tones.midnightExpress`,
            fontWeight: 800,
            textDecoration: 'underline'
        })
    },
    sizes: {
        lg: {
            fontFamily: 'heading',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '168%',
            letterSpacing: '-0.02em'
        },
        md: { fontSize: '16px', fontWeight: 500, lineHeight: '150%' },
        sm: ({ colorMode }) => ({
            color: `${colorMode}.tones.stormGrey`,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '150%'
        }),
        xs: ({ colorMode }) => ({
            color: `${colorMode}.tones.stormGrey`,
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '150%'
        })
    }
}

export const HeadingStyles: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        color: `${colorMode}.tones.midnightExpress`,
        fontFamily: 'heading',
        letterSpacing: '-0.02em'
    }),
    sizes: {
        h1: { fontSize: '32px', fontWeight: 400, lineHeight: '160%' },
        h2: { fontSize: '24px', fontWeight: 800, lineHeight: '233%' },
        h3: { fontSize: '18px', fontWeight: 800, lineHeight: '176%' },
        h4: {
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: 'normal',
            letterSpacing: '0.02em',
            textTransform: 'uppercase'
        }
    }
}
