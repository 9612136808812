import { AspectRatio, Box, Center, Image, useMultiStyleConfig } from '@chakra-ui/react'
import { CheckIconSolid } from 'atoms/Icons/zeta'
import { SelectProps } from 'molecules/Select/Select'
import { SelectOption } from 'molecules/Select/types'
import { cloneElement, FC, isValidElement } from 'react'

interface SelectItemProps extends Pick<SelectProps, 'variant'> {
    item: SelectOption
    isSelected: boolean
    onClick: (value: SelectOption['value']) => void
}

export const SelectItem: FC<SelectItemProps> = ({ item, variant, isSelected, onClick }) => {
    const styles = useMultiStyleConfig('CustomSelect', { variant })
    const { label, value, leftElement } = item

    function handleClick() {
        onClick(value)
    }

    if (item.image) {
        return (
            <AspectRatio
                role="button"
                ratio={100 / 60}
                sx={{ ...styles['grid-item'] }}
                aria-label={label}
                data-active={isSelected || undefined}
                data-value={value}
                onClick={handleClick}
            >
                {isValidElement(item.src) ? (
                    <Box>{cloneElement(item.src, { boxSize: '32px' } as any)}</Box>
                ) : (
                    <Image src={item.src} objectFit="cover" />
                )}
            </AspectRatio>
        )
    }

    return (
        <Center
            role="button"
            sx={{ ...styles.item, w: 'full', justifyContent: 'space-between' }}
            aria-label={label}
            data-active={isSelected || undefined}
            data-value={value}
            onClick={handleClick}
        >
            {leftElement}
            <Box w="full" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                {label}
            </Box>
            {isSelected ? <CheckIconSolid boxSize="11px" /> : null}
        </Center>
    )
}
