import { HStack, useMultiStyleConfig, VStack } from '@chakra-ui/react'
import { RecordingModeEnum } from '@missionlabs/types'
import { Body, Checkbox, Heading, IconButton } from 'atoms'
import { HangupIconSolid, SubItemIconRegular } from 'atoms/Icons/zeta'
import { format } from 'date-fns'
import { ContactDetail } from 'molecules/ContactDetail/ContactDetail'
import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CallState, Member } from '../types'
import { CallButtons, CallButtonsProps } from './CallButtons'
import { CallStatus } from './CallStatus'
import { CallWarning } from './CallWarning'

type ActiveCallProps = CallButtonsProps & {
    state: CallState
    name: string
    company: string
    callDuration: string // could be a number and we will need to convert
    startTimestamp?: number
    via?: string
    dualCall?: {
        name?: string
        company?: string
    }
    members?: Partial<Member>[]
    onConfirmTransfer: () => void
    onToggleRecording: () => void
    onStartStopRecording: (save: boolean) => void
}

// TODO: We can probably move these into zeta-hybrid as the call components are quite unique between the apps.
export const ActiveCall = forwardRef<HTMLDivElement, ActiveCallProps>(
    (
        {
            name,
            company,
            callDuration,
            startTimestamp,
            via,
            dualCall,
            members = [],
            onHangCall,
            onMuteCall,
            onHoldCall,
            onTransferCall,
            onConfirmTransfer,
            onToggleRecording,
            onAddParticipant,
            onStartStopRecording,
            state,
            ...props
        },
        ref
    ) => {
        const { container, details, timeContainer, duration, time, dualCallContainer } =
            useMultiStyleConfig('ActiveCall')
        const { t } = useTranslation()
        const [saveRecording, setSaveRecording] = useState(false)

        const renderMember = (member: Partial<Member>) => {
            return (
                <HStack spacing={0} sx={dualCallContainer}>
                    <HStack spacing={0} align="start">
                        <SubItemIconRegular boxSize="32px" py="10px" color="light.tones.navy" />
                        <ContactDetail name={member?.name} role={member?.company} size="sm" />
                    </HStack>
                </HStack>
            )
        }

        return (
            <VStack spacing="16px" sx={container} {...props} ref={ref}>
                <HStack sx={details}>
                    <ContactDetail name={name} role={company} subText={via} size="sm" />
                    <VStack spacing={0} sx={timeContainer}>
                        <Heading sx={duration}>{callDuration}</Heading>
                        <Body sx={time}>
                            {startTimestamp ? format(startTimestamp, 'HH:mm') : ''}
                        </Body>
                    </VStack>
                </HStack>
                {dualCall && (
                    <HStack spacing={0} sx={dualCallContainer}>
                        <HStack spacing={0} align="start">
                            <SubItemIconRegular boxSize="32px" py="10px" color="light.tones.navy" />
                            <ContactDetail name={dualCall.name} role={dualCall.company} size="sm" />
                        </HStack>
                        <IconButton
                            variant="negatory-outline"
                            icon={<HangupIconSolid />}
                            aria-label="Confirm transfer button"
                            size="sm"
                            onClick={onConfirmTransfer}
                        />
                    </HStack>
                )}
                {members.length && <VStack w="full">{members.map(renderMember)}</VStack>}
                {state.connectionDown ? (
                    <CallWarning />
                ) : (
                    <>
                        <CallButtons
                            state={state}
                            onHangCall={onHangCall}
                            onMuteCall={onMuteCall}
                            onHoldCall={onHoldCall}
                            onTransferCall={onTransferCall}
                            onAddParticipant={onAddParticipant}
                        />
                        {/* TODO: add functionality , not showing these 2 buttons in the recording design*/}
                        <HStack spacing="8px" w="100%" align="flex-start">
                            <CallStatus
                                state={state}
                                onStartStopRecording={onStartStopRecording}
                                onToggleRecording={onToggleRecording}
                            />
                            {/* <IconButton
                        variant="secondary"
                        icon={<MessageIconSolid />}
                        aria-label="Message button"
                    />
                    <IconButton
                        variant="secondary"
                        icon={<VideoIconSolid />}
                        aria-label="Video button"
                    /> */}
                        </HStack>
                        {state.recordingMode === RecordingModeEnum.ON_DEMAND && (
                            <Checkbox
                                name="rememberDetails"
                                onChange={e => {
                                    setSaveRecording(true)
                                    onStartStopRecording(e.target.checked)
                                }}
                                isDisabled={saveRecording}
                                w="100%"
                            >
                                {t('save-recording')}
                            </Checkbox>
                        )}
                    </>
                )}
            </VStack>
        )
    }
)
