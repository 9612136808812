import { useDispatch } from '@missionlabs/api'
import { DirectoryEntry } from '@missionlabs/types'
import { useCallback } from 'react'
import { ListOnItemsRenderedProps } from 'react-window'
import { addPresenceID, clearPresenceIDs } from 'shared/slices/presenceSlice'
import { isPresenceAllowed } from 'shared/utils/presenceToStatus'

interface UseContactListPresenceArgs {
    groupedContacts: (string | DirectoryEntry)[]
}

export const useContactListPresence = ({ groupedContacts }: UseContactListPresenceArgs) => {
    const dispatch = useDispatch()

    const getPresenceId = (contact: DirectoryEntry | string) => {
        if (typeof contact === 'string') return undefined
        return isPresenceAllowed(contact) ? contact.externalID : undefined
    }

    const updatePresenceSubscriptions = useCallback(
        ({ overscanStartIndex, overscanStopIndex }: ListOnItemsRenderedProps) => {
            dispatch(clearPresenceIDs())
            groupedContacts
                .slice(overscanStartIndex, overscanStopIndex)
                .map(contact => getPresenceId(contact as DirectoryEntry))
                .forEach(id => {
                    if (id) {
                        dispatch(addPresenceID({ userID: id, persist: true }))
                    }
                })
        },
        [groupedContacts, dispatch]
    )

    return { updatePresenceSubscriptions }
}
