import { Call } from '@missionlabs/api'
import { UseDialStateReturn } from 'shared/hooks/useDialState'
import { useDtmf } from 'shared/hooks/useDtmf'

import { ActiveCallOtherDevice } from '../ActiveCall/ActiveCallOtherDevice'
import { CancelledCall } from '../CancelledCall/CancelledCall'
import { Dialpad } from '../Dialpad/Dialpad'
import { VoiceCallView } from '../VoiceCallView'

interface VoicePanelProps {
    isOpen: boolean
    dialState: UseDialStateReturn
    call: Call | undefined
}

export const VoicePanel = ({ isOpen, dialState, call }: VoicePanelProps) => {
    const { onDtmfPress } = useDtmf()

    function afterHangUp(call: Call) {
        // Show the cancelled call screen for 2 seconds, then return to dialpad
        dialState.showCancelledCall(call)
        setTimeout(() => dialState.showDialpad(), 2000)
    }

    if (!isOpen) return null

    if (dialState.view === 'inCallOnOtherDevice') {
        const { callDetails } = dialState
        return <ActiveCallOtherDevice callDetails={callDetails} />
    }

    if (dialState.view === 'cancelledCall') {
        return <CancelledCall call={dialState.call} />
    }

    if (dialState.view === 'calls') {
        if (!call) return <></>
        return (
            <VoiceCallView
                key={call.callTraceID}
                call={call}
                afterHangUp={afterHangUp}
                dialpadElement={<Dialpad handleDialpadChange={onDtmfPress} hideCall />}
            />
        )
    }

    return null
}
