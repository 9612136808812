import { Box } from '@chakra-ui/react'
import React from 'react'
import { NoResultsState } from 'shared/components/NoResultsState'

interface Props {}

const AnalyticsNoData: React.FC<Props> = () => {
    return (
        <Box
            w="full"
            h="full"
            mt={'64px'}
            bg="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={100}
        >
            <NoResultsState />
        </Box>
    )
}

export default AnalyticsNoData
