import { menuAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(menuAnatomy.keys)

export const MenuStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => ({
        list: {
            bg: 'white',
            border: '1px solid',
            borderColor: `${colorMode}.tones.periwinkle`,
            borderRadius: '8px',
            boxShadow: 'md',
            padding: '12px',
            zIndex: 'var(--chakra-zIndices-popover)'
        },
        item: {
            borderRadius: '8px',
            padding: '8px 16px 8px 12px',
            minHeight: '40px',
            _active: {
                bg: `${colorMode}.tones.whiteLilac`,
                outline: '1px solid',
                outlineColor: `${colorMode}.tones.periwinkle`,
                outlineOffset: -1
            },
            _focus: {
                bg: `${colorMode}.tones.whiteLilac`,
                outline: '1px solid',
                outlineColor: `${colorMode}.tones.periwinkle`,
                outlineOffset: -1
            },
            _hover: {
                bg: `${colorMode}.tones.whiteLilac`
            }
        },
        divider: {
            borderColor: `${colorMode}.tones.periwinkle`,
            borderWidth: '1px',
            my: '8px',
            opacity: 1
        },
        groupTitle: {
            mt: '16px',
            ml: '8px',
            fontFamily: 'heading',
            _first: {
                mt: '8px'
            }
        }
    }),
    variants: {
        destructive: ({ colorMode }) => ({
            item: {
                color: `${colorMode}.alerts.pink`
            }
        }),
        dark: ({ colorMode }) => {
            return definePartsStyle({
                list: defineStyle({
                    bg: `${colorMode}.tones.midnightExpress`,
                    borderColor: `${colorMode}.tones.stormGrey`,
                    color: `${colorMode}.tones.white`
                }),
                item: defineStyle({
                    color: `${colorMode}.tones.white`,
                    bg: `${colorMode}.tones.midnightExpress`,
                    _active: { bg: `${colorMode}.tones.navy`, color: `${colorMode}.tones.white` },
                    _focus: { bg: `${colorMode}.tones.navy`, color: `${colorMode}.tones.white` },
                    _hover: { bg: `${colorMode}.tones.navy`, color: `${colorMode}.tones.white` }
                })
            })
        }
    }
})
