import { useMultiStyleConfig } from '@chakra-ui/react'
import { PlaceholderIcon } from 'atoms/Icons/zeta'
import { cloneElement, FC, isValidElement } from 'react'

import { AvatarProps } from './Avatar'

export interface AvatarIconProps extends Pick<AvatarProps, 'icon' | 'size' | 'variant'> {}

export const AvatarIcon: FC<AvatarIconProps> = ({ icon, size, variant }) => {
    const styles = useMultiStyleConfig('Avatar', { size, variant })

    if (!isValidElement(icon)) return <PlaceholderIcon sx={{ ...styles.icon }} />
    return cloneElement(icon, { sx: styles.icon, ...(icon.props as any) })
}
