import { Box, CheckboxGroup, HStack, useColorMode, VStack } from '@chakra-ui/react'
import {
    Body,
    Checkbox,
    Dropdown,
    Input,
    useDebouncedFunction,
    useSyncedState
} from '@missionlabs/react'
import { UserCallRoute } from '@missionlabs/types'
import { InterstitialRadio } from 'features/settings/components/InterstitialRadio'
import { useFlags } from 'flagsmith/react'
import { ChangeEvent, FC, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'

import { getTimeoutOptions } from './timeoutOptions'

type RadioType = 'inHoursUnanswered' | 'outOfHours' | 'busy'

const propNames = {
    busy: { forward: 'busy', redirect: 'busyRedirect' },
    inHoursUnanswered: {
        forward: 'inHoursUnanswered',
        redirect: 'unansweredRedirect'
    },
    outOfHours: {
        forward: 'outOfHours',
        redirect: 'outOfHoursRedirect'
    }
} as const

type InHoursProps = {
    type: 'inHoursUnanswered'
    callRoute: Pick<
        UserCallRoute,
        'inHoursUnanswered' | 'unansweredRedirect' | 'callTimeout' | 'always'
    >
}

type OutOfHoursProps = {
    type: 'outOfHours'
    callRoute: Pick<UserCallRoute, 'outOfHours' | 'outOfHoursRedirect' | 'always'>
}

type BusyProps = {
    type: 'busy'
    callRoute: Pick<UserCallRoute, 'busy' | 'busyRedirect' | 'always'>
}

type RedirectExternalRadioProps = (InHoursProps | OutOfHoursProps | BusyProps) & {
    type: RadioType
    forwardType: string
    onUpdate: (payload: Partial<UserCallRoute>) => void
    minW?: string
}

export const RedirectExternalRadio: FC<RedirectExternalRadioProps> = ({
    type,
    forwardType,
    callRoute,
    onUpdate,
    minW
}) => {
    const { call_forwarding_always } = useFlags(['call_forwarding_always'])

    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { formatToNumberE164, isValidPhoneNumber } = useFormatToNumberE164()
    const ref = useRef<HTMLInputElement>(null)

    const isActive = useMemo(() => forwardType === 'redirect', [forwardType])

    const [callTimeout, setCallTimeout] = useSyncedState(
        type === 'inHoursUnanswered' ? callRoute.callTimeout : undefined
    )
    const [number, setNumber] = useSyncedState(
        callRoute[propNames[type].redirect]?.numberE164 ?? ''
    )
    const [announce, setAnnounce] = useSyncedState(callRoute[propNames[type].redirect]?.announce)
    const [flipCLI, setFlipCLI] = useSyncedState(callRoute[propNames[type].redirect]?.flipCLI)
    const [always, setAlways] = useSyncedState(callRoute.always === 'redirect')

    const isValidNumber = useMemo(() => isValidPhoneNumber(number), [isValidPhoneNumber, number])

    const checked = useMemo(() => {
        const checked: string[] = []
        if (announce) checked.push('announceCalls')
        if (flipCLI) checked.push('presentDialledNumber')
        if (always) checked.push('always')
        return checked
    }, [announce, flipCLI, always])

    const debouncedUpdate = useDebouncedFunction(
        (payload: Partial<UserCallRoute>) => onUpdate(payload),
        500
    )

    const handleFocus = () => {
        if (!ref.current) return
        ref.current.focus()
    }

    const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => setNumber(e.target.value)
    const handleFlipCLI = (e: ChangeEvent<HTMLInputElement>) => setFlipCLI(e.target.checked)
    const handleAnnounce = (e: ChangeEvent<HTMLInputElement>) => setAnnounce(e.target.checked)
    const handleAlways = (e: ChangeEvent<HTMLInputElement>) => setAlways(e.target.checked)

    useEffect(() => {
        if (!isActive || !isValidNumber) return

        debouncedUpdate({
            [propNames[type].forward]: 'redirect',
            callTimeout,
            always: always ? 'redirect' : 'inactive',
            [propNames[type].redirect]: {
                flipCLI,
                announce,
                numberE164: formatToNumberE164(number)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [announce, callTimeout, flipCLI, number, always])

    return (
        <InterstitialRadio
            value="redirect"
            current={forwardType}
            onClick={() => onUpdate({ [propNames[type].forward]: 'redirect' })}
            label={t('calls.redirectExternal')}
            minW={minW}
            alignItems="center"
        >
            <VStack align="flex-start" spacing="8px">
                <HStack spacing="16px" w="full">
                    {type === 'inHoursUnanswered' && (
                        <Dropdown
                            w="full"
                            maxW="196px"
                            options={getTimeoutOptions(t)}
                            value={callTimeout}
                            onChange={setCallTimeout}
                        />
                    )}
                    <Box>
                        <Input
                            ref={ref}
                            aria-label="redirect number"
                            maxW="296px"
                            value={number}
                            onChange={handleNumberChange}
                            onClick={handleFocus}
                        />
                        {!isValidNumber && (
                            <Body sx={{ color: `${colorMode}.alerts.red`, py: '8px' }} size="sm">
                                {t('validations.number')}
                            </Body>
                        )}
                    </Box>
                </HStack>
                <CheckboxGroup value={checked}>
                    <Checkbox key="announceCalls" value="announceCalls" onChange={handleAnnounce}>
                        <Body as="span" size="sm" sx={{ color: `${colorMode}.coolGrey.80` }}>
                            {t('calls.announceCalls')}
                        </Body>
                    </Checkbox>
                    <Checkbox
                        key="presentDialledNumber"
                        value="presentDialledNumber"
                        onChange={handleFlipCLI}
                    >
                        <Body as="span" size="sm" sx={{ color: `${colorMode}.coolGrey.80` }}>
                            {t('calls.presentDialledNumber')}
                        </Body>
                    </Checkbox>
                    {call_forwarding_always.enabled && (
                        <Checkbox key="always" value="always" onChange={handleAlways}>
                            <Body as="span" size="sm" sx={{ color: `${colorMode}.coolGrey.80` }}>
                                {t('calls.always')}
                            </Body>
                        </Checkbox>
                    )}
                </CheckboxGroup>
            </VStack>
        </InterstitialRadio>
    )
}
