import { Box, Flex, HStack, Image, useColorMode, useToast, VStack } from '@chakra-ui/react'
import {
    BaseURL,
    selectAuthenticatedUser,
    selectAuthEnvironment,
    useSelector
} from '@missionlabs/api'
import { Body, Button, Heading, Tag } from '@missionlabs/react'
import { WarningIconSolid } from '@missionlabs/react/zeta'
import { IntegrationApp } from '@missionlabs/types'
import { openURL, populateUrl } from '@missionlabs/utils'
import { FC, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { usePutIntegrationMutation } from 'shared/store'

import { ImagePlaceholder } from './ImagePlaceholder'

export interface IntegrationsCardProps {
    app: IntegrationApp
}

export const IntegrationsCard: FC<IntegrationsCardProps> = ({ app }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const env = useSelector(selectAuthEnvironment)

    const hasConfiguration = Object.keys(app.configuration ?? {}).length > 0
    const isInstalled = !!app.appInstallationID
    const requiresReset = !!app.tokenInvalid

    function handleClick(e: MouseEvent<HTMLButtonElement>) {
        const intent = e.currentTarget.dataset.intent
        if (!intent) return

        const url = new URL(populateUrl(BaseURL.INTEGRATIONS, env, true))
        url.searchParams.set('intent', intent)
        url.searchParams.set('app', app.source)

        openURL(url.toString())
    }

    const [putIntegration] = usePutIntegrationMutation()

    const user = useSelector(selectAuthenticatedUser)

    const toast = useToast()

    const handleReset = async () => {
        if (!user?.userID) return
        if (!app.appInstallationID || !app.oauthURL) return

        try {
            await putIntegration({
                userID: user.userID,
                appInstallationID: app.appInstallationID,
                app: {
                    tokenInvalid: false
                }
            }).unwrap()

            openURL(app.oauthURL)
        } catch (error) {
            console.error(error)
            toast({
                status: 'error',
                title: 'Could not reset integration.'
            })
        }
    }

    return (
        <VStack
            spacing="24px"
            w="full"
            p="24px"
            align="start"
            border="1px solid"
            borderColor={`${colorMode}.coolGrey.30`}
            borderRadius="4px"
            data-testid="integrations-card"
        >
            <HStack w="full" justifyContent="space-between">
                <Image
                    src={app.logo}
                    boxSize="64px"
                    fit="contain"
                    fallback={<ImagePlaceholder />}
                />
                {requiresReset && (
                    <Box alignSelf="start">
                        <WarningIconSolid color={`${colorMode}.avatar.orange`} />
                    </Box>
                )}
            </HStack>
            <Flex flex={1} w="full" gap="8px" align="inherit" wrap="wrap">
                <Heading size="h4" variant="bold">
                    {app.name}
                </Heading>
                {app.beta && <Tag label={t('Beta')} variant="grey" />}
                <Body h="full">{app.description}</Body>
            </Flex>
            <HStack justify="space-between">
                {requiresReset && (
                    <Button variant="secondary" data-indent="reset" onClick={handleReset}>
                        {t('Reset')}
                    </Button>
                )}
                {isInstalled && hasConfiguration && !requiresReset && (
                    <Button variant="secondary" data-intent="configure" onClick={handleClick}>
                        {t('Configure')}
                    </Button>
                )}
                <Button
                    variant={isInstalled ? 'negatory' : 'creationary'}
                    data-intent={isInstalled ? 'delete' : 'install'}
                    onClick={handleClick}
                >
                    {isInstalled ? t('Uninstall') : t('Install')}
                </Button>
            </HStack>
        </VStack>
    )
}
