import { Grid, HStack, useColorMode } from '@chakra-ui/react'
import {
    leaveActiveMeeting,
    MeetingRecordingStatus,
    ParticipantVideoStatus,
    selectActiveMeeting,
    selectRecordings,
    stopMeeting,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { useMeetingManager } from '@missionlabs/meetings'
import { Button, Heading, IconButton } from '@missionlabs/react'
import { SettingsIconRegular, UserCircleIconRegular } from '@missionlabs/react/zeta'
import { useMeetingDuration } from 'features/meetings/hooks/useMeetingDuration'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

export const InVideoCallHeader: FC = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const manager = useMeetingManager()
    const duration = useMeetingDuration()
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const meeting = useSelector(selectActiveMeeting)
    const recordings = useSelector(selectRecordings)

    const notifyRecording = useMemo(() => {
        const activeRecording = [...(Array.isArray(recordings) ? recordings : [])]
            .filter(({ status }) => status !== MeetingRecordingStatus.ENDED)
            .at(0)
        if (!activeRecording || activeRecording.owner === true) return false
        return activeRecording.status !== MeetingRecordingStatus.PAUSED
    }, [recordings])

    function handleLeave() {
        if (!meeting) {
            return dispatch(stopMeeting({ error: 'ended', force: true }))
        }
        return manager.leave().then(() => {
            const acceptedParticipantsLength = (meeting?.otherParticipants ?? []).filter(
                ({ status }) => status === ParticipantVideoStatus.ACCEPTED
            ).length

            /**  Handle sending the leave event to video-services
             *   in-order to send a notification to the callee/callee's
             */
            dispatch(leaveActiveMeeting({ ...meeting }))

            if (acceptedParticipantsLength <= 1) {
                /*  Replaces in-video call screen with the close window page
                 *   because we can't rely on useVideoCall meetingStatus as we
                 *   may not be connected to Chime
                 */
                dispatch(stopMeeting({ error: 'ended', force: true }))
            }
        })
    }

    return (
        <Grid
            as="header"
            boxSize="full"
            bg={`${colorMode}.tones.midnightExpress`}
            alignItems="center"
            justifyItems="center"
            templateColumns="104px 1fr 112px"
        >
            <Button variant="negatory" onClick={handleLeave}>
                {t('Leave')}
            </Button>
            <HStack spacing="32px">
                {typeof meeting?.startTime !== 'undefined' && (
                    <Heading size="h3" color={`${colorMode}.tones.white`} fontSize="16px">
                        {t('meeting.duration', { duration })}
                    </Heading>
                )}
                {notifyRecording && (
                    <Heading size="h3" color={`${colorMode}.alerts.red`} fontSize="16px">
                        {t('meeting.recording')}
                    </Heading>
                )}
            </HStack>
            <HStack spacing={0}>
                <IconButton
                    size="lg"
                    variant="transparent"
                    aria-label="Settings"
                    onClick={() => navigate(`${location.pathname}/settings`)}
                >
                    <SettingsIconRegular color={`${colorMode}.tones.whiteLilac`} />
                </IconButton>
                <IconButton
                    size="lg"
                    variant="transparent"
                    aria-label="Participants"
                    onClick={() => navigate(`${location.pathname}/participants`)}
                >
                    <UserCircleIconRegular color={`${colorMode}.tones.whiteLilac`} />
                </IconButton>
            </HStack>
        </Grid>
    )
}
