import { Flex } from '@chakra-ui/react'
import {
    Button,
    Dropdown,
    SelectOption,
    Toggle,
    useFormContext,
    ValidatedInput
} from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { AddEditTeamFields } from './AddEditTeamForm'

export interface MusicToggleProps {
    name: any
    dropdownName: any
    label: string
    subtext: string
    options: SelectOption[]
}

export const MusicToggle: FC<MusicToggleProps> = ({ name, dropdownName, options, ...props }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { setValue, watch } = useFormContext<AddEditTeamFields>()

    const isChecked = watch(name)

    function handleChange(name: any, value: any) {
        if (value === false) setValue(dropdownName, undefined)
        setValue(name, value, { shouldDirty: true })
    }

    function handleClick() {
        navigate(`${location.pathname}/upload`, { state: dropdownName })
    }

    return (
        <>
            <ValidatedInput name={name} shouldRegister={false}>
                <Toggle
                    {...props}
                    side="left"
                    size="lg"
                    isChecked={isChecked}
                    onChange={e => handleChange(name, e.target.checked)}
                />
            </ValidatedInput>
            {isChecked && (
                <Flex pt="8px" gap="16px" align="start">
                    <ValidatedInput flex={1} name={dropdownName} shouldRegister={false}>
                        <Dropdown
                            options={options}
                            value={watch(dropdownName)}
                            onChange={value => handleChange(dropdownName, value)}
                        />
                    </ValidatedInput>
                    <Button variant="creationary" onClick={handleClick}>
                        {t('Upload New')}
                    </Button>
                </Flex>
            )}
        </>
    )
}
