import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const CloseCircleOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CloseCircleOutlineIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M7.24401 15.5778L10.8231 11.9987L7.24666 8.42221C6.92131 8.09686 6.92131 7.56936 7.24666 7.24401C7.57201 6.91866 8.09951 6.91866 8.42486 7.24401L12.0013 10.8205L15.5751 7.24666C15.9005 6.92131 16.428 6.92131 16.7533 7.24666C17.0787 7.57201 17.0787 8.09951 16.7533 8.42486L13.1795 11.9987L16.756 15.5751C17.0813 15.9005 17.0813 16.428 16.756 16.7533C16.4306 17.0787 15.9031 17.0787 15.5778 16.7533L12.0013 13.1769L8.42221 16.756C8.09686 17.0813 7.56936 17.0813 7.24401 16.756C6.91866 16.4306 6.91866 15.9031 7.24401 15.5778Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
                fill="currentColor"
            />
        </g>
    )
})

export const CloseCircleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CloseCircleIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM7.24401 15.5778L10.8231 11.9987L7.24666 8.42221C6.92131 8.09686 6.92131 7.56936 7.24666 7.24401C7.57201 6.91866 8.09951 6.91866 8.42486 7.24401L12.0013 10.8205L15.5751 7.24666C15.9005 6.92131 16.428 6.92131 16.7533 7.24666C17.0787 7.57201 17.0787 8.09951 16.7533 8.42486L13.1795 11.9987L16.756 15.5751C17.0813 15.9005 17.0813 16.428 16.756 16.7533C16.4306 17.0787 15.9031 17.0787 15.5778 16.7533L12.0013 13.1769L8.42221 16.756C8.09686 17.0813 7.56936 17.0813 7.24401 16.756C6.91866 16.4306 6.91866 15.9031 7.24401 15.5778Z"
            fill="currentColor"
        />
    )
})

export const CloseIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CloseIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.97006 12.4983L5.31722 17.1511C4.89426 17.5741 4.89426 18.2598 5.31722 18.6828C5.74017 19.1057 6.42592 19.1057 6.84888 18.6828L11.5017 14.0299L16.1511 18.6793C16.5741 19.1023 17.2598 19.1023 17.6828 18.6793C18.1057 18.2564 18.1057 17.5706 17.6828 17.1477L13.0334 12.4983L17.6793 7.85231C18.1023 7.42936 18.1023 6.74361 17.6793 6.32065C17.2564 5.8977 16.5706 5.8977 16.1477 6.32066L11.5017 10.9666L6.85231 6.31722C6.42936 5.89426 5.74361 5.89426 5.32065 6.31722C4.8977 6.74017 4.8977 7.42592 5.32065 7.84888L9.97006 12.4983Z"
            fill="currentColor"
        />
    )
})
