import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ImportIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ImportIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.5147 7.94424C9.15164 8.28372 8.58211 8.26461 8.24262 7.90155C7.90314 7.53849 7.92225 6.96896 8.28531 6.62948L12.9768 2.24262C13.3228 1.91913 13.8602 1.91913 14.2062 2.24262L18.8977 6.62948C19.2608 6.96896 19.2799 7.53849 18.9404 7.90155C18.6009 8.26461 18.0314 8.28372 17.6683 7.94424L14.4897 4.972V13.5017C14.4897 13.9988 14.0867 14.4017 13.5897 14.4017C13.0926 14.4017 12.6897 13.9988 12.6897 13.5017V4.97543L9.5147 7.94424ZM8.94114 21.3143C9.10488 21.4844 9.33491 21.5903 9.58967 21.5903C9.5899 21.5903 9.59013 21.5903 9.59035 21.5903C9.81159 21.5906 10.0329 21.5097 10.2062 21.3477L14.8977 16.9608C15.2608 16.6213 15.2799 16.0518 14.9404 15.6887C14.6009 15.3257 14.0314 15.3066 13.6683 15.646L10.4897 18.6183V10.696C10.4897 10.1989 10.0867 9.796 9.58967 9.796C9.09261 9.796 8.68967 10.1989 8.68967 10.696V18.6148L5.5147 15.646C5.15164 15.3066 4.58211 15.3257 4.24262 15.6887C3.90314 16.0518 3.92225 16.6213 4.28531 16.9608L8.94114 21.3143Z"
            fill="currentColor"
        />
    )
})
