import { Drawer, DrawerBody, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    DrawerHeader,
    DrawerProps,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from '@missionlabs/react'
import { Destination, DestinationTypes } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePostDestinationForUserMutation } from 'shared/store'

import { AddDeviceNumberFields, AddDeviceNumberForm } from './AddDeviceNumberForm'
import { AddDeviceSipEndpointFields, AddDeviceSipEndpointForm } from './AddDeviceSipEndpointForm'

const noop = () => {}

export interface AddDeviceDrawerProps extends Omit<DrawerProps, 'children'> {}

export const AddDeviceDrawer: FC<AddDeviceDrawerProps> = ({ isOpen, onClose, ...props }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const user = useSelector(selectAuthenticatedUser)

    const [createDestination, { data, isLoading }] = usePostDestinationForUserMutation()

    async function handleSubmit(data: AddDeviceSipEndpointFields | AddDeviceNumberFields) {
        if (!user) return
        await createDestination({
            userID: user.userID,
            payload: data as Partial<Destination>
        }).unwrap()
        return onClose()
    }

    function handleTransition() {
        if (data?.type === DestinationTypes.sipEndpoint) {
            navigate(`/settings/devices/${data.ID}/edit`)
        } else {
            navigate('/settings/devices')
        }
    }

    return (
        <Drawer
            {...props}
            size="lg"
            isOpen={isOpen}
            onClose={!isLoading ? onClose : noop}
            onCloseComplete={handleTransition}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>{t('devices.add.header')}</DrawerHeader>
                <DrawerBody>
                    <Tabs size="md" h="full" lazyBehavior="unmount" isLazy isFitted>
                        <TabList mx="24px">
                            <Tab isDisabled={isLoading}>{t('devices.add.number.tab')}</Tab>
                            <Tab isDisabled={isLoading}>{t('devices.add.sipEndpoint.tab')}</Tab>
                        </TabList>
                        <TabPanels h="calc(100% - 48px)">
                            <TabPanel p={0} h="full">
                                <AddDeviceNumberForm onClose={onClose} onSubmit={handleSubmit} />
                            </TabPanel>
                            <TabPanel p={0} h="full">
                                <AddDeviceSipEndpointForm
                                    onClose={onClose}
                                    onSubmit={handleSubmit}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
