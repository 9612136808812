import { useAudioVideo } from 'amazon-chime-sdk-component-library-react'
import { useCallback, useEffect, useRef } from 'react'

export const useActiveSpeakerIndicator = (chimeAttendeeId: string) => {
    const audioVideo = useAudioVideo()

    const activityIndicatorTimeout = useRef<any>()
    const nameplateRef = useRef<HTMLHeadElement>(null)
    const speakerIconRef = useRef<HTMLDivElement>(null)

    const removeActivityIndicator = useCallback(() => {
        nameplateRef.current?.classList.remove('active')
        speakerIconRef.current?.classList.remove('speaker-active', 'active')
    }, [])

    useEffect(() => {
        if (!audioVideo || !chimeAttendeeId) return

        const callback = (
            _: string,
            volume: number | null,
            __: boolean | null,
            ___: number | null
        ) => {
            if (volume !== null && volume !== undefined && volume > 0.1) {
                clearTimeout(activityIndicatorTimeout.current)
                nameplateRef.current?.classList.add('active')
                speakerIconRef.current?.classList.add('speaker-active', 'active')
                activityIndicatorTimeout.current = setTimeout(removeActivityIndicator, 1500)
            }
        }

        audioVideo.realtimeSubscribeToVolumeIndicator(chimeAttendeeId, callback)

        return () => audioVideo.realtimeUnsubscribeFromVolumeIndicator(chimeAttendeeId, callback)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chimeAttendeeId])

    return { nameplateRef, speakerIconRef }
}
