import {
    answer,
    Call,
    declineCall,
    hangup,
    retrieveParkedCall,
    selectAuthenticatedUser,
    selectCallGroupMessages,
    selectCalls,
    selectConnectedCalls,
    selectIncomingCalls,
    selectInData,
    selectParkedCalls,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { Activity, DestinationTypes, UserActivity } from '@missionlabs/types'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { useGetDestinationsForUserQuery, useGetUserActivitiesQuery } from 'shared/store'
import { selectDestinationsByTypes } from 'shared/utils/deviceDestination'
import { ElectronNotification } from 'shared/utils/notifications/electronNotifications'

import { useCallNotificationListenerBuilder } from './useCallNotificationListenerBuilder'
import { useNotificationRefs } from './useNotificationRefs'
import { usePollCallTime } from './usePollCallTime'

const allowedDestinationsTypes = [DestinationTypes.android, DestinationTypes.ios]
const selectMissedCalls = selectInData<UserActivity[]>(data =>
    (data ?? []).filter(item => {
        const isUnread = item.isUnread || item.isUnread === undefined
        return (
            isUnread &&
            (item.activityType === 'call_missed' || item.activityType === 'internal_call_missed')
        )
    })
)

export const useCallNotificationListener = (
    isEnabled: boolean,
    browserNotificationsEnabled: boolean
) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const calls = useSelector(selectCalls)
    const incomingCalls = useSelector(selectIncomingCalls)
    const connectedCalls = useSelector(selectConnectedCalls)
    const parkedCalls = useSelector(selectParkedCalls)
    const callGroupMessages = useSelector(selectCallGroupMessages)
    const user = useSelector(selectAuthenticatedUser)

    const { currentTime, callStartTime } = usePollCallTime(connectedCalls, 5000)

    const { destinations = [] } = useGetDestinationsForUserQuery(user?.userID ?? '', {
        skip: !user,
        selectFromResult: result => ({
            ...result,
            destinations: selectDestinationsByTypes(allowedDestinationsTypes)(result)
        })
    })
    const currentDevice = destinations?.find(device => device.ID === user?.destinationID)
    const isMissedCallEnabled = currentDevice?.notifications?.events?.includes(Activity.CALL_MISSED)

    const { missedCalls } = useGetUserActivitiesQuery(
        { from: String(callStartTime), to: String(currentTime), userID: user?.userID ?? '' },
        {
            skip: !callStartTime || !currentTime || !isMissedCallEnabled,
            selectFromResult: result => ({ ...result, missedCalls: selectMissedCalls(result) })
        }
    )

    const recentlyDismissed = useNotificationRefs()

    // Listen for incoming calls.
    const { has: hasIncomingCall, remove: removeIncomingCall } = useCallNotificationListenerBuilder(
        'incoming',
        incomingCalls,
        recentlyDismissed,
        isEnabled,
        browserNotificationsEnabled,
        calls
    )

    // Listen for missed calls.
    const { has: hasMissedCall, remove: removeMissedCall } = useCallNotificationListenerBuilder(
        'missed',
        missedCalls,
        recentlyDismissed,
        isEnabled,
        browserNotificationsEnabled
    )

    // Listen for parked calls.
    const { has: hasParkedCall, remove: removeParkedCall } = useCallNotificationListenerBuilder(
        'parked',
        parkedCalls,
        recentlyDismissed,
        isEnabled,
        browserNotificationsEnabled
    )

    // This works for menu voicemails, however personal voicemails aren't implemented
    // so removing this until we do work to get both working.
    //
    // // Listen for voicemails.
    // const { has: hasVoicemail, remove: removeVoicemail } = useCallNotificationListenerBuilder(
    //     'voicemail',
    //     callGroupMessages,
    //     recentlyDismissed,
    //     isEnabled,
    //     browserNotificationsEnabled
    // )

    const removeCall = useCallback(
        (callTraceID: string) => {
            if (hasIncomingCall(callTraceID)) return removeIncomingCall(callTraceID)
            if (hasMissedCall(callTraceID)) return removeMissedCall(callTraceID)
            if (hasParkedCall(callTraceID)) return removeParkedCall(callTraceID)
        },
        [
            hasIncomingCall,
            hasMissedCall,
            removeIncomingCall,
            removeMissedCall,
            hasParkedCall,
            removeParkedCall
            // hasVoicemail,
            // removeVoicemail
        ]
    )

    const removeOtherRecentlyDismissed = recentlyDismissed.dismissOthers
    useEffect(() => {
        const callTraceIDs = [
            ...calls,
            ...parkedCalls,
            ...(missedCalls ?? []),
            ...(callGroupMessages ?? [])
        ].map(call => call.callTraceID)
        // Remove all recently dismissed notifications from list when they are no longer tracked by call slice.
        removeOtherRecentlyDismissed(callTraceIDs)
    }, [calls, parkedCalls, missedCalls, callGroupMessages, removeOtherRecentlyDismissed])

    // On mount, set up electron handlers.
    useEffectOnce(() => {
        const actionNotificationHandle = (_: any, type: string, call: Call) => {
            const { callTraceID, userID = '' } = call

            switch (type) {
                case 'answer':
                    dispatch(answer({ callTraceID }))
                    removeCall(callTraceID)
                    break
                case 'retrieve-parked':
                    dispatch(retrieveParkedCall())
                    removeCall(callTraceID)
                    break
                case 'decline':
                    dispatch(declineCall({ callTraceID }))
                    removeCall(callTraceID)
                    break
                case 'hangup':
                    dispatch(hangup({ callTraceID }))
                    // removeCall(callTraceID)
                    break
                case 'view':
                case 'message':
                    navigate(`/contacts/${userID}`)
                    break
                case 'transfer':
                    navigate('/home/transfer', { state: { call }, replace: true })
                    break
                case 'dismiss': // this event tells electron to destroy popup window. keeping it here for reference.
                    removeCall(callTraceID)
                    break
                default:
                    break
            }
        }

        ElectronNotification.onAction(actionNotificationHandle)

        return () => {
            ElectronNotification.removeOnAction(actionNotificationHandle)
        }
    })
}
