import { VStack } from '@chakra-ui/react'
import { Heading } from '@missionlabs/react'
import { BusinessInfo } from 'features/admin/pages/WhatsApp/BusinessInfo'
import { NumbersTable } from 'features/admin/pages/WhatsApp/NumbersTable'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const WhatsAppSettings: FC = () => {
    const { t } = useTranslation()
    return (
        <VStack maxW="100%" align="start">
            <BusinessInfo />
            <Heading size="h3" py="1">
                {t('admin.whatsapp.numbers')}
            </Heading>
            <NumbersTable searchTerm="" />
        </VStack>
    )
}
