import {
    useAudioOutputs,
    useLogger,
    useMeetingManager
} from 'amazon-chime-sdk-component-library-react'
import { useCallback, useEffect } from 'react'

import useRealDevice from './useRealDevice'

const useAudioOutputSelection = (preferenceDeviceID, preferenceAudioOutputVolume) => {
    const meetingManager = useMeetingManager()
    const { selectedDevice, devices } = useAudioOutputs()
    const { getRealAudioOutputDevice } = useRealDevice()

    const logger = useLogger()

    const handleChangeAudioOutput = useCallback(
        async (value: string, volume: number = 1) => {
            if (!devices.length) return

            let foundDevice = getRealAudioOutputDevice(value)

            if (!foundDevice) {
                logger.warn(
                    `AudioOutputSelection | failed finding audio output device ${value}${
                        devices.length ? ', finding default' : ''
                    }`
                )
                if (!devices.length) {
                    return
                }

                foundDevice = getRealAudioOutputDevice('default')

                if (!foundDevice) {
                    logger.warn(
                        'AudioOutputSelection | failed to find default audio output device, finding any output device'
                    )
                    foundDevice = devices[0]
                }
            }

            if (!foundDevice) {
                logger.error(
                    `AudioOutputSelection | failed to find output device ${value}/default/any`
                )
                return
            }

            try {
                if (selectedDevice !== foundDevice?.deviceId) {
                    await meetingManager.startAudioOutputDevice(foundDevice?.deviceId)
                }
                const [element] = document.querySelectorAll('audio')
                element.volume = volume
            } catch (error) {
                logger.error('AudioOutputSelection | failed to select audio output device')
            }
        },
        [devices, logger, meetingManager, selectedDevice, getRealAudioOutputDevice]
    )

    useEffect(() => {
        if (preferenceDeviceID) {
            handleChangeAudioOutput(preferenceDeviceID, preferenceAudioOutputVolume)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices])

    return {
        selectedDevice,
        devices,
        handleChangeAudioOutput
    }
}

export default useAudioOutputSelection
