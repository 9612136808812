import { Flex, HStack, Switch, SwitchProps, useColorMode } from '@chakra-ui/react'
import { Body, Tooltip } from 'atoms'
import { InformationIconRegular } from 'atoms/Icons/zeta'
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

interface ToggleProps extends SwitchProps {
    side?: 'left' | 'right'
    size?: 'sm' | 'lg'
    label?: string
    subtext?: string
    tooltip?: string
    variant?: 'light' | 'dark'
}

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
    (
        {
            side = 'right',
            label,
            subtext,
            tooltip,
            isDisabled,
            size = 'lg',
            variant = 'light',
            ...props
        },
        ref
    ) => {
        const { colorMode } = useColorMode()

        const innerRef = useRef<HTMLInputElement>(null)

        const color = variant === 'light' ? `${colorMode}.tones.navy` : `${colorMode}.tones.white`
        const subtextColor =
            variant === 'light' ? `${colorMode}.tones.stormGrey` : `${colorMode}.tones.white`

        function renderLabel() {
            if (!label) return null
            return (
                <HStack flex={subtext ? 1 : undefined} spacing="8px">
                    {!!label && (
                        <Body size="md" variant={subtext ? 'bold' : undefined} sx={{ color }}>
                            {label}
                        </Body>
                    )}
                    {!!tooltip && (
                        <Tooltip isDisabled={isDisabled} label={tooltip}>
                            <InformationIconRegular boxSize="12px" alignSelf="flex-start" />
                        </Tooltip>
                    )}
                </HStack>
            )
        }

        // merge forwarded ref and inner ref
        useImperativeHandle(ref, () => innerRef.current as HTMLInputElement)

        // Chakra doesn't set the `data-disabled` attribute on the "thumb" part of a `Switch`.
        // This is not easily accessible by other means so we manually toggle the attribute on all nested children.
        useEffect(() => {
            if (innerRef.current) {
                // ref gives us the input element, we want the root of the switch.
                const parent = innerRef.current.parentElement
                if (!parent) return

                // gets all children of switch's root and toggle attribute on their children (only depth 1 is enough for our means).
                const elements = Array.from(parent.children)
                elements.forEach(element => {
                    const children = Array.from(element.children)
                    if (isDisabled) {
                        children.forEach(child => child.setAttribute('data-disabled', ''))
                    } else {
                        children.forEach(child => child.removeAttribute('data-disabled'))
                    }
                })
            }
        }, [isDisabled, innerRef])

        return (
            <Flex gap="8px 10px" wrap="wrap">
                {side === 'left' && renderLabel()}
                <Switch
                    isDisabled={isDisabled}
                    size={size}
                    {...props}
                    ref={innerRef}
                    variant={variant}
                />
                {!side || (side === 'right' && renderLabel())}
                {!!subtext && (
                    <Body w="full" size="sm" variant="bold" sx={{ color: subtextColor }}>
                        {subtext}
                    </Body>
                )}
            </Flex>
        )
    }
)

Toggle.displayName = 'Toggle'
