import { NewSequentialRingingList, SequentialRingingList } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildSequentialRingingListsAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'sequentialRingingListsAPI',
        tagTypes: ['SequentialRingingList'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getSequentialRingingLists: builder.query<SequentialRingingList[], string>({
                query: userID => `/users/${userID}/sequentialringinglists`,
                transformResponse: (response: { data: SequentialRingingList[] }) => response.data,
                providesTags: (result, error, userID) => [
                    { type: 'SequentialRingingList', id: userID }
                ]
            }),
            getSequentialRingingList: builder.query<
                SequentialRingingList,
                { userID: string; listID: string | undefined }
            >({
                query: list => `/users/${list.userID}/sequentialringinglists/${list.listID}`,
                transformResponse: (response: { data: SequentialRingingList }) => response.data,
                providesTags: (result, error, list) => [
                    { type: 'SequentialRingingList', id: list.listID }
                ]
            }),
            createSequentialRingingList: builder.mutation<
                SequentialRingingList,
                { list: NewSequentialRingingList; userID: string }
            >({
                query: list => ({
                    url: `/users/${list.userID}/sequentialringinglists`,
                    method: 'POST',
                    body: list.list
                }),
                invalidatesTags: ['SequentialRingingList']
            }),
            updateSequentialRingingList: builder.mutation<
                SequentialRingingList,
                { list: Partial<SequentialRingingList>; userID: string }
            >({
                query: ({ list, userID }) => ({
                    url: `/users/${userID}/sequentialringinglists/${list.ID}`,
                    method: 'PUT',
                    body: list
                }),
                invalidatesTags: ['SequentialRingingList']
            }),
            deleteSequentialRingingList: builder.mutation<
                SequentialRingingList,
                Pick<SequentialRingingList, 'ID' | 'userID'>
            >({
                query: list => ({
                    url: `/users/${list.userID}/sequentialringinglists/${list.ID}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['SequentialRingingList']
            })
        })
    })

    const {
        useGetSequentialRingingListsQuery,
        useGetSequentialRingingListQuery,
        useCreateSequentialRingingListMutation,
        useUpdateSequentialRingingListMutation,
        useDeleteSequentialRingingListMutation
    } = api
    return {
        api,
        useGetSequentialRingingListsQuery,
        useGetSequentialRingingListQuery,
        useCreateSequentialRingingListMutation,
        useUpdateSequentialRingingListMutation,
        useDeleteSequentialRingingListMutation
    }
}
