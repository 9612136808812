import { useColorMode } from '@chakra-ui/react'
import { IconButton, IconButtonProps, Tooltip } from '@missionlabs/react'
import { TickIcon } from '@missionlabs/react/circleloop'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface ConfirmTransferButtonProps extends Omit<IconButtonProps, 'aria-label'> {
    onConfirm?: () => void
}

export const ConfirmTransferButton: FC<ConfirmTransferButtonProps> = ({ onConfirm, ...props }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    return (
        <Tooltip label={t('Complete transfer')}>
            <IconButton
                icon={<TickIcon boxSize="16px" color={`${colorMode}.tones.white`} />}
                variant="primary"
                boxSize="48px"
                flex={1}
                onClick={onConfirm}
                aria-label="complete transfer"
                isRounded
                {...props}
            />
        </Tooltip>
    )
}
