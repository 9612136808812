import { ComponentStyleConfig } from '@chakra-ui/react'

const disabledStyles = {
    bg: 'light.tones.stormGrey',
    opacity: 0.5
}

export const ButtonStyles: ComponentStyleConfig = {
    baseStyle: {
        borderRadius: '4px',
        color: 'light.tones.white',
        fontFamily: 'body',
        textAlign: 'center',
        _disabled: disabledStyles,
        transition: 'all 200ms ease'
    },
    defaultProps: {
        variant: 'primary'
    },
    variants: {
        dark: {
            bg: 'light.tones.vantaBlue',
            borderColor: 'light.tones.stormGrey',
            border: 'solid 1px',
            color: 'light.tones.periwinkle',
            _hover: {
                bg: 'light.tones.navy'
            },
            _active: {
                bg: 'light.tones.navy'
            }
        },
        dialler: {
            bg: 'light.tones.midnightExpress',
            borderColor: 'light.tones.stormGrey',
            borderStyle: 'solid',
            borderWidth: '1px',
            color: 'light.tones.periwinkle',
            _disabled: {
                borderColor: 'light.tones.navy',
                color: 'light.tones.navy'
            },
            _hover: {
                borderColor: 'light.tones.periwinkle',
                color: 'light.tones.white'
            },
            _active: {
                bg: 'light.tones.navy',
                borderColor: 'light.tones.whiteLilac',
                color: 'light.tones.white'
            }
        },
        creationary: {
            bg: 'light.alerts.aqua',
            _hover: {
                bg: 'light.alerts.algae',
                _disabled: disabledStyles
            }
        },
        primary: {
            bg: 'light.alerts.aqua',
            _hover: {
                bg: 'light.alerts.algae',
                _disabled: disabledStyles
            }
        },
        secondary: {
            bg: 'light.tones.navy',
            _hover: {
                bg: 'light.tones.stormGrey',
                _disabled: disabledStyles
            }
        },
        negatory: {
            bg: 'light.alerts.red',
            _hover: {
                bg: 'light.alerts.redDark',
                _disabled: disabledStyles
            }
        },
        'negatory-outline': {
            bg: 'transparent',
            border: '1px solid',
            borderColor: 'light.alerts.red',
            color: 'light.alerts.red',
            _hover: {
                bg: 'light.alerts.redDark',
                border: 'none',
                color: 'light.tones.white',
                _disabled: {
                    bg: 'transparent',
                    border: '1px solid',
                    borderColor: 'light.tones.periwinkle',
                    color: 'light.tones.stormGrey'
                }
            },
            _disabled: {
                bg: 'transparent',
                borderColor: 'light.tones.periwinkle',
                color: 'light.tones.stormGrey'
            }
        },
        'negatory-light': {
            bg: 'light.alerts.rose24',
            border: 'none',
            color: 'light.alerts.rose',
            _hover: {
                bg: 'light.alerts.rose',
                border: 'none',
                color: 'light.tones.white',
                _disabled: {
                    bg: 'transparent',
                    border: '1px solid',
                    borderColor: 'light.tones.periwinkle',
                    color: 'light.tones.stormGrey'
                }
            },
            _disabled: {
                bg: 'transparent',
                borderColor: 'light.tones.periwinkle',
                color: 'light.tones.stormGrey'
            }
        },
        tertiary: {
            bg: 'light.tones.whiteLilac',
            border: '1px solid',
            borderColor: 'light.tones.periwinkle',
            color: 'light.tones.navy',
            _hover: {
                bg: 'light.tones.periwinkle',
                color: 'light.tones.midnightExpress',
                _disabled: {
                    bg: 'light.tones.whiteLilac',
                    border: '1px solid',
                    borderColor: 'light.tones.periwinkle',
                    color: 'light.tones.stormGrey',
                    opacity: 1
                }
            },
            _disabled: {
                bg: 'light.tones.whiteLilac',
                border: '1px solid',
                borderColor: 'light.tones.periwinkle',
                color: 'light.tones.stormGrey',
                opacity: 1
            }
        },
        ghost: {
            bg: 'transparent',
            color: 'light.tones.navy',
            _active: {
                bg: 'transparent',
                color: 'light.primary.gamma',
                _hover: {
                    bg: 'light.tones.whiteLilac'
                }
            },
            _hover: {
                bg: 'light.tones.whiteLilac',
                _disabled: {
                    bg: 'transparent',
                    color: 'light.tones.navy',
                    opacity: 1
                }
            },
            _disabled: {
                bg: 'transparent',
                color: 'light.tones.navy',
                opacity: 1
            }
        },
        transparent: {
            bg: 'transparent',
            color: 'light.tones.navy',
            border: '1px solid transparent',
            _hover: {
                bg: 'transparent',
                color: 'light.tones.midnightExpress',
                border: '1px solid',
                borderColor: 'light.tones.periwinkle',
                _disabled: {
                    bg: 'transparent',
                    color: 'light.tones.navy',
                    opacity: 0.7
                }
            },
            _disabled: {
                bg: 'transparent',
                color: 'light.tones.navy',
                opacity: 0.7
            }
        },
        link: {
            color: 'light.alerts.red',
            textDecoration: 'none',
            fontWeight: 700,
            verticalAlign: 'baseline',
            lineHeight: 'normal',
            height: 'auto',
            padding: 0,
            justifyContent: 'start',
            _disabled: {
                bg: 'transparent',
                color: 'light.alerts.red',
                _hover: {
                    color: 'light.alerts.red'
                }
            },
            _hover: {
                textDecoration: 'none',
                color: 'light.alerts.redDark'
            }
        },
        'link-underline': {
            color: 'light.alerts.red',
            textDecoration: 'underline',
            fontWeight: 700,
            verticalAlign: 'baseline',
            lineHeight: 'normal',
            height: 'auto',
            padding: 0,
            justifyContent: 'start',
            _disabled: {
                bg: 'transparent',
                color: 'light.alerts.red',
                _hover: {
                    color: 'light.alerts.red'
                }
            },
            _hover: {
                textDecoration: 'underline',
                color: 'light.alerts.redDark'
            }
        },
        facebook: {
            bg: 'light.tones.facebook',
            color: 'light.tones.white',
            _hover: {
                bg: 'light.tones.facebookDark'
            }
        }
    },
    sizes: {
        sm: { py: '7px', px: '12px', fontWeight: 400, fontSize: '12px', lineHeight: '150%' },
        md: { py: '9.5px', px: '16px', fontWeight: 500, fontSize: '14px', lineHeight: '150%' },
        lg: { py: '13.5px', px: '16px', fontWeight: 500, fontSize: '14px', lineHeight: '150%' },
        xl: { py: '20px', px: '24px', fontWeight: 700, fontSize: '16px', lineHeight: '150%' }
    }
}
