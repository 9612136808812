import { DetailedState, SingleState, UserStatus } from '@missionlabs/types'
import { useTranslation } from 'react-i18next'
import { PresenceToStatus } from 'shared/utils/presenceToStatus'

const isDefault = (option: string) => {
    const options = [UserStatus.Active, UserStatus.Away, UserStatus.Busy, UserStatus.DND]

    return options.includes(option as UserStatus)
}

const isAvailable = (status?: DetailedState) => {
    return status === DetailedState.AVAILABLE
}

const isInAMeeting = (status?: DetailedState) => {
    return status === DetailedState.INMEETING
}

const isInCallOnOtherDevice = (
    currentDestinationID?: string,
    presenceState?: SingleState
): boolean => {
    if (!currentDestinationID || !presenceState?.metadata?.displayDestinationID) return false
    // If the user is in a call, check if the call is on a different device
    return (
        presenceState.detailedState === 'INCALL' &&
        currentDestinationID !== presenceState.metadata?.displayDestinationID
    )
}

export const useGetPresenceHelpers = (subscription: SingleState | undefined) => {
    const { t } = useTranslation()

    const UserStatuses = new Map([
        [UserStatus.Active, t('nav.presence.available')],
        [UserStatus.Away, t('nav.presence.away')],
        [UserStatus.Busy, t('nav.presence.busy')],
        [UserStatus.DND, t('nav.presence.doNotDisturb')],
        [UserStatus.InMeeting, t('meetings.inAMeeting')]
    ])

    const snoozeStatusName =
        (subscription?.metadata?.snoozeStatusName?.toLowerCase() as UserStatus) ?? UserStatus.Active

    const isDefaultVanityStatus = isDefault(snoozeStatusName)

    function getStatus(status: DetailedState) {
        if (isAvailable(status)) {
            if (isDefaultVanityStatus)
                return { status: snoozeStatusName, label: UserStatuses.get(snoozeStatusName) }
            return { status: UserStatus.Custom, label: snoozeStatusName }
        }

        if (isInAMeeting(status)) {
            return { status: UserStatuses.get(UserStatus.Busy), label: t('meetings.inAMeeting') }
        }

        return {
            status: PresenceToStatus.get(status),
            label: UserStatuses.get(PresenceToStatus.get(status) ?? UserStatus.Offline) ?? 'sss'
        }
    }

    function getCachedStatus(status: DetailedState) {
        if (isAvailable(status)) {
            return { status: UserStatus.Active, label: t('nav.presence.available') }
        }

        return {
            status: PresenceToStatus.get(status),
            label: UserStatuses.get(PresenceToStatus.get(status) ?? UserStatus.Active)
        }
    }

    function getCachedVanityStatus(status: DetailedState) {
        if (isAvailable(status)) {
            return UserStatus.Active
        }

        return PresenceToStatus.get(status)
    }

    function getLastKnownVanityStatus(status: DetailedState) {
        if (isAvailable(status)) {
            return snoozeStatusName
        }

        if (isInAMeeting(status)) {
            return UserStatus.InMeeting
        }

        return PresenceToStatus.get(status)
    }

    return {
        isAvailable,
        getStatus,
        getCachedStatus,
        getCachedVanityStatus,
        getLastKnownVanityStatus,
        isInAMeeting,
        isInCallOnOtherDevice
    }
}
