import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const SupportIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SupportIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M19.6801 10.3333H20.0425C21.1055 10.3333 22 11.1719 22 12.2613V16.6544C22 17.7438 21.1053 18.5833 20.0425 18.5833H19.6241C19.593 18.6561 19.5596 18.7276 19.5237 18.7979C19.214 19.4043 18.7422 19.8707 18.1266 20.2156C17.0458 20.821 15.5024 21.0601 13.5167 21.0985C13.3557 21.2445 13.1427 21.3333 12.9091 21.3333H11.0909C10.5888 21.3333 10.1818 20.9229 10.1818 20.4167C10.1818 19.9104 10.5888 19.5 11.0909 19.5H12.9091C13.1365 19.5 13.3444 19.5842 13.5038 19.7234C15.4287 19.6845 16.685 19.4502 17.4643 19.0136C17.8714 18.7856 18.1383 18.5072 18.3115 18.1682C18.3297 18.1326 18.3471 18.0958 18.3637 18.0577L18.3636 18.0467V11.25C18.3636 7.41307 15.5145 4.30263 12 4.30263C8.48546 4.30263 5.63636 7.41307 5.63636 11.25V18.0467C5.63636 18.3431 5.42421 18.5833 5.1625 18.5833H3.9575C2.89371 18.5833 2 17.7447 2 16.6544V12.2623C2 11.1727 2.89405 10.3333 3.9575 10.3333H4.31989C4.74699 6.20838 8.02255 3 12 3C15.9774 3 19.253 6.20838 19.6801 10.3333Z"
            fill="currentColor"
        />
    )
})
