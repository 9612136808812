import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const HeadphonesIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HeadphonesIconLight',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M14.9609 8.96674L13.75 9.0058C13.0469 9.0058 12.5 9.55268 12.5 10.2558V16.4667C12.5 17.1699 13.0469 17.7558 13.75 17.7558H14.9609C16.3672 17.7167 17.5 16.623 17.5 15.2167V11.5058C17.5 10.0996 16.3672 8.96674 14.9609 8.96674ZM16.25 15.2167C16.25 15.9199 15.6641 16.5058 15 16.5058H13.75V10.2167L15 10.2558C15.6641 10.2558 16.25 10.8027 16.25 11.5058V15.2167ZM6.25 9.0058L4.96094 8.96674C3.59375 8.96674 2.5 10.0996 2.5 11.5058V15.2167C2.5 16.623 3.59375 17.7558 4.96094 17.7558L6.25 17.7167C6.91406 17.7167 7.5 17.1699 7.5 16.4667V10.2558C7.5 9.55268 6.91406 9.0058 6.25 9.0058ZM6.25 16.5058H5C4.29688 16.5058 3.75 15.9199 3.75 15.2167V11.5058C3.75 10.8027 4.29688 10.2558 5 10.2558H6.25V16.5058ZM9.49219 0.255803C4.10156 0.529241 0 5.13862 0 10.5292V14.5917C0 14.9433 0.273438 15.2167 0.625 15.2167C0.9375 15.2167 1.25 14.9433 1.25 14.6308V10.4902C1.25 5.80268 4.84375 1.74018 9.53125 1.5058C14.5703 1.23237 18.75 5.2558 18.75 10.2558V14.6308C18.75 14.9433 19.0234 15.2558 19.375 15.2558C19.6875 15.2558 20 14.9433 20 14.6308V10.2167C20 4.55268 15.2344 -0.0176342 9.49219 0.255803Z"
            fill="currentColor"
        />
    )
})

export const HeadphonesIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HeadphonesIconRegular',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M6.25 9.60547H5.625C4.21875 9.60547 3.125 10.7383 3.125 12.1445V15.2305C3.125 16.6367 4.21875 17.7305 5.625 17.7305H6.25C6.91406 17.7305 7.5 17.1836 7.5 16.4805V10.8555C7.5 10.1914 6.91406 9.60547 6.25 9.60547ZM5.625 15.8555C5.27344 15.8555 5 15.582 5 15.2305V12.1055C5 11.793 5.27344 11.4805 5.625 11.4805V15.8555ZM10 0.230469C4.375 0.230469 0.15625 4.91797 0 10.2305V14.6055C0 14.957 0.273438 15.2305 0.625 15.2305H1.25C1.5625 15.2305 1.83594 14.957 1.83594 14.6055L1.875 10.2305C1.875 5.77734 5.50781 2.14453 10 2.14453C14.4531 2.14453 18.125 5.77734 18.125 10.2305L18.0859 14.6055C18.0859 14.957 18.3984 15.2305 18.75 15.2305L19.375 15.2695C19.6875 15.2695 20 14.957 20 14.6445V10.2305C19.8047 4.91797 15.5859 0.230469 10 0.230469ZM14.375 9.60547H13.75C13.0469 9.60547 12.5 10.1914 12.5 10.8555V16.4805C12.5 17.1836 13.0469 17.7695 13.75 17.7695L14.375 17.7305C15.7422 17.7305 16.875 16.6367 16.875 15.2305V12.1055C16.875 10.7383 15.7422 9.60547 14.375 9.60547ZM15 15.2305C15 15.582 14.6875 15.8555 14.375 15.8555V11.4805C14.6875 11.4805 15 11.793 15 12.1445V15.2305Z"
            fill="currentColor"
        />
    )
})

export const HeadphonesIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'HeadphonesIconSolid',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M10 0.25C4.375 0.25 0.15625 4.9375 0 10.25V14.3125C0 14.8594 0.390625 15.25 0.898438 15.25C1.36719 15.25 1.875 14.8594 1.875 14.3125V10.25C1.875 5.79688 5.50781 2.16406 10 2.16406C14.4531 2.16406 18.125 5.79688 18.125 10.25V14.3125C18.125 14.8594 18.5156 15.25 19.0625 15.25C19.5703 15.25 20 14.8594 20 14.3125V10.25C19.8047 4.9375 15.5859 0.25 10 0.25ZM6.25 10.25H5.625C4.21875 10.25 3.125 11.3828 3.125 12.7891V15.25C3.125 16.6562 4.21875 17.75 5.625 17.75H6.25C6.91406 17.75 7.5 17.2031 7.5 16.5V11.5391C7.5 10.8359 6.91406 10.25 6.25 10.25ZM14.375 10.25H13.75C13.0469 10.25 12.5 10.8359 12.5 11.5391V16.5C12.5 17.2031 13.0469 17.75 13.75 17.75H14.375C15.7422 17.75 16.875 16.6562 16.875 15.25V12.7891C16.875 11.3828 15.7422 10.25 14.375 10.25Z"
            fill="currentColor"
        />
    )
})
