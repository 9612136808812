import { FC, useMemo } from 'react'
import { Title } from 'react-head'

export interface DocumentTitleProps {
    base: string
    separator?: string
    breadcrumbs?: string[]
}

const DEFAULT_SEPARATOR = ' | '

export const DocumentTitle: FC<DocumentTitleProps> = ({
    base,
    separator = DEFAULT_SEPARATOR,
    breadcrumbs = []
}) => {
    const title = useMemo(() => {
        return [base, ...breadcrumbs].join(separator)
    }, [base, breadcrumbs, separator])

    return <Title>{title}</Title>
}
