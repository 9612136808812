import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { Scrollable } from '@missionlabs/react'
import { Channel } from '@missionlabs/types'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList } from 'react-window'
import {
    useGetChannelsQuery,
    useGetUnreadThreadsQuery,
    useGetUserFavouriteContactsQuery
} from 'shared/store'

import ChatListEmpty from './ChatListEmpty'
import { ChatListRenderer } from './ChatListRenderer'
import { ChatListSkeleton } from './ChatListSkeleton'

export type Section = {
    object: string
    title: string
}

export const isSection = (x: any): x is Section =>
    typeof x === 'object' && 'object' in x && x.object === 'section'

export type VirtualisedChatListItem = Channel | Section

export interface ChatListProps {
    channelsIn?: Channel[]
}

export const ChatList: FC<ChatListProps> = ({ channelsIn }) => {
    const { t } = useTranslation()
    const threadsTranslation = t('chat.threads')
    const conversationsTranslation = t('chat.conversations')

    const user = useSelector(selectAuthenticatedUser)

    const { data: _channels = [], isLoading: channelsLoading } = useGetChannelsQuery(undefined, {
        skip: !!channelsIn?.length
    })

    const { data: unreadThreads, isLoading: threadsLoading } = useGetUnreadThreadsQuery()
    const { data: favourites, isLoading: favouritesLoading } = useGetUserFavouriteContactsQuery(
        user?.userID ?? '',
        { skip: !user }
    )

    const channels = useMemo(() => channelsIn ?? _channels, [channelsIn, _channels])

    const isLoading = channelsLoading || favouritesLoading || threadsLoading

    // todo: sort by recent message ascending
    const grouped = useMemo(() => {
        const items: VirtualisedChatListItem[] = []

        if (unreadThreads && unreadThreads?.unreadCount > 0) {
            const messageSentTime = unreadThreads.threads[0]?.messages[0]?.sentTimestamp
            items.push({
                ID: 'unread-threads',
                channelName: threadsTranslation,
                type: 'GROUP',
                lastMessagePreview: `${unreadThreads.threads.length} ${conversationsTranslation}`,
                lastMessageSent: typeof messageSentTime === 'number' ? messageSentTime : Date.now(), // find latest message
                unreadThreadsNumber: unreadThreads.threads.length,
                unreadCount: unreadThreads.unreadCount,
                members: []
            })
        }

        const sortedPinned = channels.filter(item => item.isPinned)
        if (sortedPinned.length) {
            // add group section
            items.push({ object: 'section', title: t('Pinned') })
            items.push(...sortedPinned)
        }
        const filterPinned = channels.filter(item => !item.isPinned)

        const sortedGroups = filterPinned.filter(item => item.team || item.group)
        if (sortedGroups.length) {
            // add group section
            items.push({ object: 'section', title: t('Groups') })
            items.push(...sortedGroups)
        }

        const sortedChannels = filterPinned.filter(
            item => !favourites?.contacts.some(f => f.ID === item.ID) && item.user
        )

        if (sortedChannels.length) {
            // add users section
            items.push({ object: 'section', title: t('chat.directMessages') })
            items.push(...sortedChannels)
        }

        return items
    }, [
        channels,
        conversationsTranslation,
        favourites?.contacts,
        t,
        threadsTranslation,
        unreadThreads
    ])

    const rowHeights = useMemo(() => grouped.map(item => (isSection(item) ? 72 : 76)), [grouped])

    const getRowSize = (index: number) => rowHeights[index]

    if (isLoading) return <ChatListSkeleton />
    if (!grouped.length) return <ChatListEmpty />

    return (
        <AutoSizer style={{ marginTop: '8px' }}>
            {({ width, height }) => (
                <VariableSizeList
                    height={height}
                    width={width}
                    itemCount={grouped.length}
                    itemData={grouped}
                    itemSize={getRowSize}
                    outerElementType={Scrollable}
                >
                    {ChatListRenderer}
                </VariableSizeList>
            )}
        </AutoSizer>
    )
}
