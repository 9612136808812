import { Input, ValidatedInput } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SetupSection } from './SetupSection'

export const GuestForm: FC = () => {
    const { t } = useTranslation()
    return (
        <SetupSection title={t('Your Details')}>
            <ValidatedInput name="name" label={t('Name')} variant="dark">
                <Input placeholder={t('Enter full name')} />
            </ValidatedInput>
            <ValidatedInput name="email" label={t('Email address')} variant="dark">
                <Input placeholder={t('Enter email address')} />
            </ValidatedInput>
        </SetupSection>
    )
}
