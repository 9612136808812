import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base/index'
import { SpaceProps } from 'styled-system'
import { space } from 'styled-system'

export interface BaseProps extends SpaceProps, BaseSdkProps {
    /** Optional tag to render the component as a different HTML tag */
    tag?: any
    id?: string
}

export interface FocusableProps {
    /** Optional tab index for keyboard accessibility */
    tabIndex?: number
    /** onFocus event handler */
    onFocus?: (event: React.FocusEvent<any>) => void
    /** onBlur event handler */
    onBlur?: (event: React.FocusEvent<any>) => void
}

export const baseStyles = ({ css }: BaseProps) => (css ? `@media { ${css} };` : '')
export const baseSpacing = (props: BaseProps) => space(props)
