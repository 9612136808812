import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { navbarAnatomy } from '../anatomy'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(navbarAnatomy.keys)

export const NavbarStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => ({
        bar: {
            bg: `${colorMode}.tones.whiteLilac`,
            w: '64px'
        },
        itemContainer: {
            boxSize: '64px',
            _active: {
                bg: `${colorMode}.primary.gamma !important`,
                color: `${colorMode}.tones.white`
            },
            _hover: {
                bg: `${colorMode}.tones.periwinkle`
            }
        },
        item: {
            boxSize: '32px'
        }
    })
})
