import { inspect } from 'util'

import { createTemplate, format, Info } from '../format'
import { isAllowed, Level } from '../levels'

export type Log = { level: string; message: string }

export enum Transports {
    console,
    file,
    http,
    indexeddb
}

export interface TransportConfig {
    template?: (info: Info) => string
    level: Level
}

const defaultConfig: Partial<TransportConfig> = {
    template: createTemplate(
        format.date('DD/MM/YYYY HH:mm:ss', true),
        format.level(true),
        format.message()
    ),
    level: 'info'
}

abstract class Transport<T extends TransportConfig = TransportConfig> {
    public abstract readonly name: Transports

    protected config: T

    protected constructor(config: T) {
        this.config = { ...defaultConfig, ...config }
    }

    public get level() {
        return this.config.level
    }

    public set level(level: Level) {
        this.config.level = level
    }

    public abstract log(value: Log): string

    public postLog(_value: Log): void {}

    public format(value: any): string {
        if (typeof value === 'object') {
            return inspect(value, { colors: true, showHidden: false, depth: null, compact: false })
        }
        return String(value)
    }

    public isAllowed(level: Level): boolean {
        return isAllowed(this.config.level, level)
    }

    public getMessage(info: Info): string {
        if (!this.config.template) return ''
        return this.config.template(info)
    }
}

export default Transport
