import { modalAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(modalAnatomy.keys)

export const ModalStyles: ComponentStyleConfig = defineMultiStyleConfig({
    variants: {
        dialog: ({ colorMode }) => ({
            dialog: {
                bg: 'white',
                borderRadius: '8px',
                boxShadow: 'md',
                margin: 0
            },
            overlay: { bg: `${colorMode}.tones.navy.alpha-20` },
            closeButton: {
                top: '12px',
                right: '16px'
            },
            header: {
                display: 'flex',
                alignItems: 'center',
                padding: '12px 16px 12px 24px',
                minHeight: '64px',
                color: `${colorMode}.tones.midnightExpress`,
                fontSize: '16px',
                lineHeight: '28.16px'
            },
            body: {
                padding: '0px 24px 8px',
                color: `${colorMode}.tones.navy`
            },
            footer: { padding: '24px', gap: '24px' }
        })
    },
    defaultProps: {
        size: 'sm'
    },
    sizes: {
        sm: { dialog: { maxWidth: '480px' } },
        md: { dialog: { maxWidth: '530px' } },
        lg: { dialog: { maxWidth: '790px' } }
    }
})
