import { Center } from '@chakra-ui/react'
import { EmptyPage } from '@missionlabs/react'
import { useTranslation } from 'react-i18next'

import { ActivityWelcomePage } from './ActivityWelcomePage'

export const ActivityEmptyPage = () => {
    const { t } = useTranslation()
    const seenWelcome = localStorage.getItem('seenWelcome')

    if (!seenWelcome) {
        return <ActivityWelcomePage />
    }

    return (
        <Center>
            <EmptyPage
                title={t('emptyState.nothingSelected')}
                subtitle={t('emptyState.pleaseSelectAnItem')}
            />
        </Center>
    )
}
