import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const TickCircleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TickCircleIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M 10.32 14.16 L 7.71 11.55 C 7.49 11.33 7.221 11.218 6.898 11.218 C 6.579 11.218 6.298 11.338 6.058 11.578 C 5.84 11.8 5.73 12.08 5.73 12.418 C 5.73 12.76 5.84 13.04 6.058 13.258 L 9.48 16.68 C 9.699 16.899 9.981 17.01 10.32 17.01 C 10.659 17.01 10.941 16.899 11.16 16.68 L 17.97 9.87 C 18.19 9.65 18.298 9.381 18.298 9.058 C 18.298 8.739 18.178 8.458 17.938 8.218 C 17.72 8 17.44 7.89 17.098 7.89 C 16.76 7.89 16.48 8 16.258 8.218 L 10.32 14.16 Z M 12 24 C 10.341 24 8.781 23.685 7.32 23.055 C 5.859 22.424 4.59 21.57 3.51 20.49 C 2.43 19.41 1.576 18.141 0.944 16.68 C 0.314 15.219 0 13.659 0 12 C 0 10.341 0.314 8.781 0.944 7.32 C 1.576 5.859 2.43 4.59 3.51 3.51 C 4.59 2.43 5.859 1.575 7.32 0.944 C 8.781 0.314 10.341 0 12 0 C 13.659 0 15.219 0.314 16.68 0.944 C 18.141 1.575 19.41 2.43 20.49 3.51 C 21.57 4.59 22.424 5.859 23.055 7.32 C 23.685 8.781 24 10.341 24 12 C 24 13.659 23.685 15.219 23.055 16.68 C 22.424 18.141 21.57 19.41 20.49 20.49 C 19.41 21.57 18.141 22.424 16.68 23.055 C 15.219 23.685 13.659 24 12 24 Z"
            fill="currentColor"
        />
    )
})

export const TickIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TickIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.96974 11.5714C6.51913 11.1082 5.78856 11.1082 5.33795 11.5714C4.88735 12.0347 4.88735 12.7857 5.33795 13.249L9.95931 18L19.662 8.02501C20.1127 7.56176 20.1127 6.81069 19.662 6.34744C19.2114 5.88419 18.4809 5.88419 18.0303 6.34744L9.95931 14.6449L6.96974 11.5714Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.96974 11.5714C6.51913 11.1082 5.78856 11.1082 5.33795 11.5714C4.88735 12.0347 4.88735 12.7857 5.33795 13.249L9.95931 18L19.662 8.02501C20.1127 7.56176 20.1127 6.81069 19.662 6.34744C19.2114 5.88419 18.4809 5.88419 18.0303 6.34744L9.95931 14.6449L6.96974 11.5714Z"
                fill="currentColor"
            />
        </g>
    )
})

export const DoubleTickIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DoubleTickIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M6.94649 17.654C6.81966 17.654 6.70075 17.6302 6.58977 17.5826C6.4788 17.5351 6.37574 17.4637 6.28062 17.3686L2.26159 13.3496C2.0872 13.1752 2 12.9493 2 12.6718C2 12.3944 2.0872 12.1685 2.26159 11.9941C2.43599 11.8197 2.65795 11.7325 2.92747 11.7325C3.19699 11.7325 3.41895 11.8197 3.59334 11.9941L8.30202 16.7027L7.61237 17.3686C7.51724 17.4637 7.41419 17.5351 7.30321 17.5826C7.19223 17.6302 7.07333 17.654 6.94649 17.654ZM12.321 17.6302C12.1942 17.6302 12.0753 17.6104 11.9643 17.5707C11.8533 17.5311 11.7503 17.4637 11.6552 17.3686L7.61237 13.3258C7.43797 13.1514 7.35077 12.9255 7.35077 12.648C7.35077 12.3706 7.43797 12.1447 7.61237 11.9703C7.78676 11.7959 8.00872 11.7087 8.27824 11.7087C8.54776 11.7087 8.76972 11.7959 8.94411 11.9703L12.321 15.3472L20.4067 7.26159C20.5811 7.0872 20.803 7 21.0725 7C21.3421 7 21.564 7.0872 21.7384 7.26159C21.9128 7.43599 22 7.66191 22 7.93936C22 8.21681 21.9128 8.44273 21.7384 8.61712L12.9869 17.3686C12.8918 17.4637 12.7887 17.5311 12.6778 17.5707C12.5668 17.6104 12.4479 17.6302 12.321 17.6302ZM12.321 12.6599L10.9655 11.3282L15.0083 7.28537C15.1827 7.11098 15.4086 7.02378 15.6861 7.02378C15.9635 7.02378 16.1895 7.11098 16.3639 7.28537C16.5382 7.45977 16.6254 7.68173 16.6254 7.95125C16.6254 8.22077 16.5382 8.44273 16.3639 8.61712L12.321 12.6599Z"
            fill="currentColor"
        />
    )
})
