import { Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps } from '@chakra-ui/react'
import { FC, PropsWithChildren } from 'react'

export interface TooltipProps extends ChakraTooltipProps {}

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({
    children,
    hasArrow = true,
    ...props
}) => {
    return (
        <ChakraTooltip openDelay={300} {...props} hasArrow={hasArrow} arrowSize={8}>
            {children}
        </ChakraTooltip>
    )
}
