import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const BarChartIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BarChartIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.1284 23.1672H17.5405C16.8647 23.1672 16.3354 22.6516 16.3354 21.9924V2.38695C16.3354 1.72535 16.8872 1.16675 17.5405 1.16675H22.1284C22.7827 1.16675 23.3354 1.72535 23.3354 2.38695V21.9924C23.3355 22.6399 22.7945 23.1672 22.1284 23.1672ZM14.1275 23.1672H9.5401C8.8755 23.1672 8.3345 22.6399 8.3345 21.9924V12.3879C8.3345 11.7268 8.8867 11.1682 9.5401 11.1682H14.1275C14.7818 11.1682 15.3345 11.7268 15.3345 12.3879V21.9924C15.3345 22.6399 14.7935 23.1672 14.1275 23.1672ZM6.127 23.1672H1.5391C0.874496 23.1672 0.333496 22.6399 0.333496 21.9924V7.38745C0.333496 6.72585 0.885796 6.16725 1.5391 6.16725H6.127C6.7808 6.16725 7.3335 6.72585 7.3335 7.38745V21.9924C7.3335 22.6399 6.7925 23.1672 6.127 23.1672Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_184"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="1"
                width="24"
                height="23"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.1284 23.1672H17.5405C16.8647 23.1672 16.3354 22.6516 16.3354 21.9924V2.38695C16.3354 1.72535 16.8872 1.16675 17.5405 1.16675H22.1284C22.7827 1.16675 23.3354 1.72535 23.3354 2.38695V21.9924C23.3355 22.6399 22.7945 23.1672 22.1284 23.1672ZM14.1275 23.1672H9.5401C8.8755 23.1672 8.3345 22.6399 8.3345 21.9924V12.3879C8.3345 11.7268 8.8867 11.1682 9.5401 11.1682H14.1275C14.7818 11.1682 15.3345 11.7268 15.3345 12.3879V21.9924C15.3345 22.6399 14.7935 23.1672 14.1275 23.1672ZM6.127 23.1672H1.5391C0.874496 23.1672 0.333496 22.6399 0.333496 21.9924V7.38745C0.333496 6.72585 0.885796 6.16725 1.5391 6.16725H6.127C6.7808 6.16725 7.3335 6.72585 7.3335 7.38745V21.9924C7.3335 22.6399 6.7925 23.1672 6.127 23.1672Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_184)"></g>
        </g>
    )
})

export const ChartIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChartIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.3323 7.14351L14.9814 9.53941C14.6114 9.64556 14.2653 9.31141 14.3583 8.93794L16.4586 0.506939C16.5253 0.23903 16.7966 0.0759249 17.0645 0.142603C20.2774 0.942182 22.7635 3.38348 23.6698 6.50804C23.757 6.77424 23.6068 7.06475 23.3323 7.14351ZM12.1142 12.5028C13.2695 12.153 14.4252 11.8043 15.5043 11.4803C16.8922 11.0637 18.0497 10.7192 18.9004 10.4705C19.385 10.3288 19.7639 10.22 20.0283 10.1468C20.1706 10.1074 20.2803 10.0782 20.3579 10.0591C20.4164 10.045 20.4164 10.045 20.47 10.0341C20.4989 10.0288 20.4989 10.0288 20.5478 10.0237C20.6066 9.9672 20.6066 9.9672 21.0747 10.3947C21.5322 12.1102 21.556 13.9142 21.1227 15.6728C19.7156 21.375 13.9532 24.8566 8.25158 23.4495C2.54932 22.0424 -0.932338 16.2802 0.474835 10.5777C1.882 4.87591 7.64425 1.39433 13.3461 2.80086C13.6142 2.86699 13.778 3.13795 13.7118 3.40606L11.4154 12.7146C11.6364 12.6476 11.87 12.5768 12.1142 12.5028Z"
            fill="currentColor"
        />
    )
})
