import { Grid } from '@chakra-ui/react'
import {
    Dropdown,
    IconButton,
    Input,
    SelectOption,
    useFormContext,
    ValidatedInput
} from '@missionlabs/react'
import { DeleteIcon } from '@missionlabs/react/circleloop'
import { FC, useMemo } from 'react'
import { UseFieldArrayReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useBlockContact } from 'shared/hooks/useBlockContact'

import { AddEditContactFormFields } from './AddEditContactForm'

type FieldArray = UseFieldArrayReturn<AddEditContactFormFields, 'phoneNumbers'>

export interface AddEditContactPhoneNumbersFieldProps {
    item: FieldArray['fields'][number]
    index: number
    update: (item: FieldArray['fields'][number], index: number, value: string) => void
    remove: FieldArray['remove']
}

export const AddEditContactPhoneNumbersField: FC<AddEditContactPhoneNumbersFieldProps> = ({
    item,
    index,
    update,
    remove
}) => {
    const { t } = useTranslation()
    const { isNumberBlocked } = useBlockContact(item.numberE164)
    const { watch } = useFormContext()

    const isBlocked = isNumberBlocked.inbound || isNumberBlocked.outbound

    const options = useMemo<SelectOption[]>(
        () => [
            { label: t('Home'), value: 'home' },
            { label: t('Mobile'), value: 'mobile' },
            { label: t('Work'), value: 'work' },
            { label: t('Other'), value: '' }
        ],
        [t]
    )

    return (
        <Grid key={item.id} gap="8px" templateColumns="112px 154px 1fr" alignItems="flex-start">
            <ValidatedInput
                name={`phoneNumbers[${index}].label`}
                shouldRegister={false}
                isDisabled={isBlocked}
            >
                <Dropdown
                    options={options}
                    value={options.find(option => option.value === item.label)?.value}
                    placeholder={t('Type')}
                    isDisabled={isBlocked}
                    onChange={update.bind(this, watch(`phoneNumbers[${index}]`), index)}
                />
            </ValidatedInput>
            <ValidatedInput name={`phoneNumbers[${index}].numberE164`} isDisabled={isBlocked}>
                <Input
                    placeholder={t('Enter number')}
                    value={isBlocked ? `${item.numberE164} (blocked)` : undefined}
                />
            </ValidatedInput>
            <IconButton
                aria-label="Remove"
                variant="negatory-outline"
                isDisabled={isBlocked}
                onClick={() => remove(index)}
            >
                <DeleteIcon boxSize="16px" />
            </IconButton>
        </Grid>
    )
}
