import { Box, HStack, useColorMode } from '@chakra-ui/react'
import { Button, Input, useUpdateParams } from '@missionlabs/react'
import { AddCircleIcon, SearchIcon } from '@missionlabs/react/circleloop'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetNumbersQuery } from 'shared/store'
import { APP_BRAND, AppConfig } from 'shared/utils/env'

import { NumbersTable } from './NumbersTable'

export const Numbers: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const { data: numbers = [], isLoading } = useGetNumbersQuery({
        loadAssociations: true
    })

    const [searchParams, updateParams] = useUpdateParams()
    const search = searchParams.get('search') || ''
    const setSearch = (value: string) => updateParams({ search: value })

    // Redirect to number edit page if numberE164 is in the URL.
    // This is useful for deep linking to a specific number when we don't have the number ID,
    // i.e. from the edit user/team/menu drawer, where phoneNumbers is just a string[]
    if (searchParams.get('numberE164') && numbers.length > 0) {
        const number = numbers.find(n => n.numberE164 === searchParams.get('numberE164'))
        if (number) {
            return <Navigate to={`/admin/numbers/${number.ID}/edit`} replace={true} />
        }
    }

    return (
        <SettingsPage
            maxW="100%"
            title={t('admin.numbers.title')}
            subtitle={t('admin.numbers.subheading')}
            isLoading={isLoading}
            isEmpty={!numbers.length}
            actions={
                AppConfig.brand === APP_BRAND.CIRCLELOOP && (
                    <HStack spacing="8px">
                        <Button
                            variant="secondary"
                            leftIcon={<AddCircleIcon boxSize="16px" />}
                            onClick={() => navigate('/admin/numbers/existing/port')}
                        >
                            {t('admin.numbers.addExistingNumber')}
                        </Button>
                        <Button
                            variant="creationary"
                            leftIcon={<AddCircleIcon boxSize="16px" />}
                            onClick={() => navigate('/admin/numbers/add')}
                        >
                            {t('admin.numbers.addNumber')}
                        </Button>
                    </HStack>
                )
            }
        >
            <Box w="50%">
                <Input
                    value={search}
                    onChange={e => setSearch(e.currentTarget.value)}
                    placeholder={t('admin.numbers.search_placeholder')}
                    rightIcon={<SearchIcon boxSize="20px" color={`${colorMode}.coolGrey.60`} />}
                />
            </Box>
            <NumbersTable numbers={numbers} searchTerm={search} />
        </SettingsPage>
    )
}
