import { ComponentStyleConfig } from '@chakra-ui/react'

export const TableStyles: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        sortIcon: {
            color: `${colorMode}.alerts.rose`,
            w: '10px',
            h: '10px',
            verticalAlign: 'initial'
        },
        pageCount: {
            color: `${colorMode}.tones.navy`
        }
    }),
    defaultProps: {
        variant: 'table'
    },
    variants: {
        table: ({ colorMode }) => ({
            table: {
                border: `1px solid`,
                borderColor: `${colorMode}.tones.periwinkle`
            },
            td: {
                border: '1px solid',
                borderColor: `${colorMode}.tones.periwinkle`,
                px: '8px',
                py: '12px',
                fontFamily: 'body',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '21px',
                color: `${colorMode}.tones.midnightExpress`
            },
            tr: {
                _even: {
                    bg: `${colorMode}.tones.ghostWhite`,
                    _hover: { bg: `${colorMode}.primary.gamma16` }
                },
                _odd: {
                    bg: `${colorMode}.tones.white`,
                    _hover: { bg: `${colorMode}.primary.gamma16` }
                }
            },
            th: {
                bg: `${colorMode}.tones.whiteLilac`,
                border: '1px solid',
                borderColor: `${colorMode}.tones.periwinkle`,
                borderBottomColor: `${colorMode}.tones.stormGrey`,
                px: '8px',
                py: '12px',
                textTransform: 'none',
                letterSpacing: 'normal',
                fontFamily: 'body',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '21px',
                color: `${colorMode}.tones.midnightExpress`
            }
        })
    }
}
