import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ActivityFeedIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ActivityFeedIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 0H2C0.89543 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.89543 23.1046 0 22 0ZM4.75 6.5C4.75 6.91421 5.08579 7.25 5.5 7.25H18.5C18.9142 7.25 19.25 6.91421 19.25 6.5C19.25 6.08579 18.9142 5.75 18.5 5.75H5.5C5.08579 5.75 4.75 6.08579 4.75 6.5ZM5.5 13.25C5.08579 13.25 4.75 12.9142 4.75 12.5C4.75 12.0858 5.08579 11.75 5.5 11.75H18.5C18.9142 11.75 19.25 12.0858 19.25 12.5C19.25 12.9142 18.9142 13.25 18.5 13.25H5.5ZM4.75 18.5C4.75 18.9142 5.08579 19.25 5.5 19.25H18.5C18.9142 19.25 19.25 18.9142 19.25 18.5C19.25 18.0858 18.9142 17.75 18.5 17.75H5.5C5.08579 17.75 4.75 18.0858 4.75 18.5Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_181"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 0H2C0.89543 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.89543 23.1046 0 22 0ZM4.75 6.5C4.75 6.91421 5.08579 7.25 5.5 7.25H18.5C18.9142 7.25 19.25 6.91421 19.25 6.5C19.25 6.08579 18.9142 5.75 18.5 5.75H5.5C5.08579 5.75 4.75 6.08579 4.75 6.5ZM5.5 13.25C5.08579 13.25 4.75 12.9142 4.75 12.5C4.75 12.0858 5.08579 11.75 5.5 11.75H18.5C18.9142 11.75 19.25 12.0858 19.25 12.5C19.25 12.9142 18.9142 13.25 18.5 13.25H5.5ZM4.75 18.5C4.75 18.9142 5.08579 19.25 5.5 19.25H18.5C18.9142 19.25 19.25 18.9142 19.25 18.5C19.25 18.0858 18.9142 17.75 18.5 17.75H5.5C5.08579 17.75 4.75 18.0858 4.75 18.5Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_181)"></g>
        </g>
    )
})

export const ActivityFeed2Icon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ActivityFeed2Icon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 2C2.44772 2 2 2.44772 2 3V7C2 7.55228 2.44772 8 3 8H21C21.5523 8 22 7.55228 22 7V3C22 2.44772 21.5523 2 21 2H3ZM6 4H4V6H6V4Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 9C2.44772 9 2 9.44772 2 10V14C2 14.5523 2.44772 15 3 15H21C21.5523 15 22 14.5523 22 14V10C22 9.44772 21.5523 9 21 9H3ZM6 11H4V13H6V11Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 17C2 16.4477 2.44772 16 3 16H21C21.5523 16 22 16.4477 22 17V21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V17ZM4 18H6V20H4V18Z"
                fill="currentColor"
            />
        </g>
    )
})
