import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ChatIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: '',
    viewBox: '0 0 20 22',
    path: (
        <path
            d="M17.5 1.797H2.5C1.09375 1.797 0 2.92981 0 4.297V15.5079C0 16.8751 1.09375 17.9689 2.5 17.9689H6.25V21.2501C6.25 21.6407 6.67969 21.8361 6.99219 21.6017L11.875 17.9689H17.5C18.8672 17.9689 20 16.8361 20 15.5079V4.297C20 2.92981 18.9062 1.797 17.5 1.797ZM18.7891 15.547C18.7891 16.2501 18.2031 16.797 17.5391 16.797H11.875C11.6016 16.797 11.3672 16.9142 11.1328 17.0704L7.53906 19.7657V17.422C7.53906 17.1095 7.22656 16.797 6.91406 16.797H2.53906C1.83594 16.797 1.28906 16.2501 1.28906 15.547V4.297C1.28906 3.63293 1.83594 3.047 2.53906 3.047H17.5391C18.2031 3.047 18.7891 3.63293 18.7891 4.297V15.547Z"
            fill="currentColor"
        />
    )
})

export const ChatIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: '',
    viewBox: '0 0 20 22',
    path: (
        <path
            d="M17.5 1.797H2.5C1.09375 1.797 0 2.92981 0 4.297V15.5079C0 16.8751 1.09375 17.9689 2.5 17.9689H6.25V21.2501C6.25 21.6407 6.67969 21.8361 6.99219 21.6017L11.875 17.9689H17.5C18.8672 17.9689 20 16.8361 20 15.5079V4.297C20 2.92981 18.9062 1.797 17.5 1.797ZM18.1641 15.547C18.1641 15.8986 17.8516 16.172 17.5391 16.172H11.2891L8.16406 18.5157V16.172H2.53906C2.1875 16.172 1.91406 15.8986 1.91406 15.547V4.297C1.91406 3.9845 2.1875 3.672 2.53906 3.672H17.5391C17.8516 3.672 18.1641 3.9845 18.1641 4.297V15.547Z"
            fill="currentColor"
        />
    )
})

export const ChatIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ChatIconSolid',
    viewBox: '0 0 20 22',
    path: (
        <path
            d="M20 4.29712V15.5081C20 16.8752 18.8672 17.969 17.5 17.969H11.875L6.99219 21.6018C6.67969 21.8362 6.25 21.6409 6.25 21.2502V17.969H2.5C1.09375 17.969 0 16.8362 0 15.5081V4.29712C0 2.89087 1.09375 1.79712 2.5 1.79712H17.5C18.9062 1.79712 20 2.92993 20 4.29712Z"
            fill="currentColor"
        />
    )
})
