export enum OverallState {
    AVAILABLE = 'AVAILABLE',
    UNAVAILABLE = 'UNAVAILABLE'
}

export enum DetailedState {
    UNKNOWN = 'UNKNOWN',
    AVAILABLE = 'AVAILABLE',
    OFFHOOK = 'OFFHOOK',
    RINGING = 'RINGING',
    DIALLING = 'DIALLING',
    INCALL = 'INCALL',
    DND = 'DND',
    PARKED = 'PARKED',
    INMEETING = 'INMEETING'
}

export enum Direction {
    NONE = 'NONE',
    INBOUND = 'INBOUND',
    OUTBOUND = 'OUTBOUND',
    BOTH = 'BOTH'
}

export enum StateType {
    OVERALL = 'OVERALL',
    DETAIL = 'DETAIL'
}

export type UserDetails = {
    fullName: string
    username?: string
    email: string
    externalID?: string
    extension?: string
    number?: string
}

export type SingleState = {
    type: StateType
    userID: string
    userDetails?: UserDetails
    lastUpdated: number
    currentMeetingIDs?: string[]
    currentCallIDs?: string[]
    callID?: string
    direction: Direction
    overallState: OverallState
    detailedState: DetailedState
    metadata: {
        [key: string]: string
    }
}

export type CallMonitoringEvent = UserGroupCallEvent | UserCallEvent

export type UserGroupCallEvent = {
    type: EventGroup.USER_GROUP_CALL
    userGroups: userSubscriptionCalls[]
}

export type UserCallEvent = {
    type: EventGroup.USER_CALL
    user: userSubscriptionCalls[]
}
export type userSubscriptionCalls = {
    type: StateType
    userID: string
    userDetails?: UserDetails
    lastUpdated: number
    currentCallIDs?: string[]
    callID?: string
    direction: Direction
    overallState: OverallState
    detailedState: DetailedState
    metadata: {
        [key: string]: string
    }
    currentCalls?: DecoratedCall[]
}
export enum EventGroup {
    USER_GROUP_CALL = 'subscription_usergroup_call_monitoring',
    USER_CALL = 'subscription_user_call_monitoring',
    PRESENCE_CHANGED = 'presence_changed'
}
export type DecoratedCall = {
    dialledNumber?: string
    originatingNumber?: string
    displayName?: string
    companyName?: string
    callTraceID?: string
    clientID?: string
    direction?: string
    teamName?: string
}

export enum UserStatus { // For avatar
    Active = 'active',
    Away = 'away',
    Busy = 'busy',
    Offline = 'offline',
    Other = 'other',
    DND = 'do not disturb',
    Custom = 'custom',
    InMeeting = 'inMeeting'
}

export type LabelledPresenceStatus = { status?: UserStatus | string; label?: string }
