import { Link, SimpleGrid, useColorMode, useDisclosure, VStack } from '@chakra-ui/react'
import { call, selectPresentationNumber, useDispatch, useSelector } from '@missionlabs/api'
import { IconButton } from '@missionlabs/react'
import { ContactGroup, ContactType, DirectoryEntry, isContactGroup } from '@missionlabs/types'
import {
    CategorisedPhoneNumbers,
    categorisePhoneNumbers,
    openURL,
    titleCase
} from '@missionlabs/utils'
import { BlockContactDialog } from 'features/contacts/BlockContactDialog'
import { FC, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { prettifySource } from 'shared/utils/contactSource'

import { ContactDetailsEmailRow as EmailRow } from '../ContactDetails/ContactDetailsEmailRow'
import { ContactDetailsNumberRow as NumberRow } from '../ContactDetails/ContactDetailsNumberRow'
import { ContactDetailsRow as Row } from '../ContactDetails/ContactDetailsRow'
import { useGetIntegrations } from '../hooks/useGetIntegrations'

interface ContactDetailsProps {
    contact: ContactGroup | DirectoryEntry
    type: ContactType
}

export const ContactDetails: FC<ContactDetailsProps> = ({ contact, type }) => {
    const { t } = useTranslation()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { colorMode } = useColorMode()
    const dispatch = useDispatch()
    const presentationNumber = useSelector(selectPresentationNumber) ?? ''

    const pendingNumberE164 = useRef<string>('')

    const numbers: CategorisedPhoneNumbers | undefined = useMemo(() => {
        if (isContactGroup(contact)) return
        return categorisePhoneNumbers(contact.phoneNumbers)
    }, [contact])

    function handleBlock(numberE164: string) {
        pendingNumberE164.current = numberE164
        onOpen()
    }

    const { sourceName, sourceLogo } = useGetIntegrations(
        isContactGroup(contact) ? undefined : contact.source
    )

    const handleCall = useCallback(
        (numberOrExt: string) => {
            dispatch(
                call({
                    to: numberOrExt,
                    from: presentationNumber,
                    contact: isContactGroup(contact) ? undefined : contact
                })
            )
        },
        [dispatch, presentationNumber, contact]
    )

    if (isContactGroup(contact)) {
        return (
            <>
                <BlockContactDialog
                    numberE164={pendingNumberE164.current}
                    isOpen={isOpen}
                    onClose={onClose}
                />
                <SimpleGrid w="full" h="full" columns={{ sm: 2, md: 1 }} spacing={4} p={6}>
                    <Row field={t('Name')} value={contact.name} />
                    <Row field={t('Type')} value={titleCase(type)} />
                    <Row field={t('Contact')} alignItems="start">
                        <VStack spacing={6}>
                            {numbers && (
                                <ContactDetailNumbers
                                    numbers={numbers}
                                    type={type}
                                    onBlock={handleBlock}
                                    onCall={handleCall}
                                    labelFormat={() => t('Group Number')}
                                />
                            )}
                        </VStack>
                    </Row>
                </SimpleGrid>
            </>
        )
    }

    return (
        <>
            <BlockContactDialog
                numberE164={pendingNumberE164.current}
                isOpen={isOpen}
                onClose={onClose}
            />

            <SimpleGrid w="full" h="full" columns={{ sm: 2, md: 1 }} spacing={4} p={6}>
                <Row field={t('Name')} value={contact.fullName} />
                <Row field={t('Type')} value={titleCase(type)} />
                {type === 'external' && sourceName ? (
                    <Row
                        field={t('Source')}
                        value={`View in ${titleCase(sourceName)}`}
                        icon={
                            contact.externalURL ? (
                                <IconButton
                                    aria-label={`View in ${titleCase(sourceName)}`}
                                    variant="transparent"
                                    size="sm"
                                >
                                    <Link
                                        rel="noopener"
                                        referrerPolicy="no-referrer"
                                        onClick={e => {
                                            e.preventDefault()
                                            if (contact.externalURL) openURL(contact.externalURL)
                                        }}
                                    >
                                        <img
                                            src={sourceLogo}
                                            style={{ minWidth: '24px' }}
                                            alt={`View in ${titleCase(sourceName)}`}
                                        />
                                    </Link>
                                </IconButton>
                            ) : undefined
                        }
                    />
                ) : (
                    <Row
                        field={t('Source')}
                        value={prettifySource(contact.source) ?? t('Unknown')}
                    />
                )}
                {contact.companyName && <Row field={t('Company')} value={contact.companyName} />}
                {contact.jobTitle && <Row field={t('Position')} value={contact.jobTitle} />}
                {contact.tags && (
                    <Row field={t('contacts.form.tags')} value={contact.tags.join(', ')} />
                )}
                <VStack
                    spacing={0}
                    mt={2}
                    maxW="40rem"
                    sx={{
                        // Give every child of the list a border bottom, except the last one
                        '& > *': {
                            borderBottom: '1px solid',
                            borderBottomColor: `${colorMode}.tones.periwinkle`,
                            py: 4,
                            _last: { borderBottomColor: 'transparent' }
                        }
                    }}
                >
                    {contact.emails?.map(({ email }) => (
                        <EmailRow key={email} field={t('Email')} email={email} />
                    ))}

                    {numbers && (
                        <ContactDetailNumbers
                            contact={contact}
                            numbers={numbers}
                            type={type}
                            onBlock={handleBlock}
                            onCall={handleCall}
                        />
                    )}
                </VStack>
            </SimpleGrid>
        </>
    )
}

interface ContactDetailsNumbersProps {
    contact?: DirectoryEntry
    numbers: CategorisedPhoneNumbers
    type: ContactType
    onBlock: (numberE164: string) => void
    onCall: (numberOrExt: string) => void
    labelFormat?: (category: string) => string
}

function ContactDetailNumbers({
    contact,
    numbers,
    type,
    onBlock,
    onCall,
    labelFormat = titleCase
}: ContactDetailsNumbersProps) {
    const categories = Object.entries(numbers)
    if (!categories.length) return null

    return (
        <>
            {categories.map(([category, nums]) =>
                nums.map(number => (
                    <NumberRow
                        key={`${category}-${number}`}
                        contact={contact}
                        field={labelFormat(category)}
                        value={number}
                        type={type}
                        onBlock={onBlock}
                        onCall={onCall}
                    />
                ))
            )}
        </>
    )
}
