import { useColorMode, useDisclosure } from '@chakra-ui/react'
import {
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    IconButton,
    IconButtonProps
} from '@missionlabs/react'
import { ShareScreenIconSolid } from '@missionlabs/react/zeta'
import {
    useContentShareControls,
    useContentShareState
} from 'amazon-chime-sdk-component-library-react'
import isElectron from 'is-electron'
import { FC, useEffect, useRef, useState } from 'react'

export interface ScreenShareControlProps extends Omit<IconButtonProps, 'aria-label' | 'onClick'> {
    /** The label shown when video is enabled. Defaults to `Turn video off` */
    labelScreenShare?: string
    /** The label shown when video is disabled (voice only). Defaults to `Turn video on` */
    labelNoScreenShare?: string

    electronWindowPicker?: FC<{
        isOpen: boolean
        onClose: () => void
        onSelect: (sourceId: string) => void
    }>
}

// todo: refactor to match designs
const ScreenShareControl: FC<ScreenShareControlProps> = ({
    labelScreenShare = 'Turn screen share off',
    labelNoScreenShare = 'Turn screen share on',
    electronWindowPicker: ElectronWindowPicker,
    ...props
}) => {
    const { colorMode } = useColorMode()
    const { isOpen, onToggle } = useDisclosure()

    const ref = useRef<HTMLButtonElement>(null)

    const [toggleSelectSource, setToggleSelectSource] = useState(false)

    const { toggleContentShare } = useContentShareControls()
    const { isLocalUserSharing, sharingAttendeeId } = useContentShareState()

    function handleConfirm() {
        if (!isElectron()) toggleContentShare()
        else setToggleSelectSource(true)
        onToggle()
    }

    function handleContentShare(sourceId: string) {
        toggleContentShare(sourceId)
        setToggleSelectSource(false)
    }

    function handleClick() {
        if (isLocalUserSharing) return toggleContentShare()
        if (!sharingAttendeeId) return onToggle()
    }

    useEffect(() => {
        // todo: test if this is right
        if (sharingAttendeeId && isOpen) onToggle()
    }, [sharingAttendeeId, isOpen, onToggle])

    return (
        <>
            <IconButton
                {...props}
                variant={isOpen ? 'negatory' : 'tertiary'}
                isRounded
                title={isLocalUserSharing ? labelNoScreenShare : labelScreenShare}
                sx={!isOpen ? { color: `${colorMode}.alerts.red` } : undefined}
                onClick={handleClick}
                aria-label="Screen Share"
                data-testid="video-menu_screen_share_btn"
            >
                <ShareScreenIconSolid />
            </IconButton>

            {isOpen && (
                <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onToggle}>
                    <DialogOverlay />
                    <DialogContent>
                        <DialogCloseButton />
                        <DialogHeader>Screen Sharing</DialogHeader>
                        <DialogBody>
                            You are about to share your screen. Are you sure you want to continue?
                        </DialogBody>
                        <DialogFooter
                            cancel={{ ref, label: 'Cancel' }}
                            confirm={{ label: 'Start screen share', onClick: handleConfirm }}
                        />
                    </DialogContent>
                </Dialog>
            )}

            {!!ElectronWindowPicker && toggleSelectSource && (
                <ElectronWindowPicker
                    isOpen={toggleSelectSource}
                    onClose={() => setToggleSelectSource(false)}
                    onSelect={handleContentShare}
                />
            )}
        </>
    )
}

export default ScreenShareControl
