import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PlayIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PlayIconLight',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M1.4375 0.510935C2.02344 0.159373 2.76562 0.159373 3.35156 0.549998L14.6016 7.38593C15.1484 7.7375 15.5 8.3625 15.5 8.9875C15.5 9.65156 15.1484 10.2766 14.6016 10.5891L3.35156 17.4641C2.76562 17.8156 2.02344 17.8547 1.4375 17.5031C0.851562 17.1906 0.5 16.5656 0.5 15.8625V2.1125C0.5 1.44844 0.851562 0.823435 1.4375 0.510935ZM2.6875 1.60469C2.49219 1.4875 2.25781 1.4875 2.0625 1.60469C1.86719 1.68281 1.75 1.91719 1.75 2.1125V15.8625C1.75 16.0969 1.86719 16.3312 2.0625 16.4094C2.25781 16.5266 2.49219 16.5266 2.6875 16.4094L13.9375 9.53437C14.1328 9.41718 14.25 9.22187 14.25 8.9875C14.25 8.79218 14.1328 8.59687 13.9375 8.47968L2.6875 1.60469Z"
            fill="currentColor"
        />
    )
})

export const PlayIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PlayIconRegular',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M1.4375 0.510935C2.02344 0.159373 2.76562 0.159373 3.35156 0.549998L14.6016 7.38593C15.1484 7.7375 15.5 8.3625 15.5 8.9875C15.5 9.65156 15.1484 10.2766 14.6016 10.5891L3.35156 17.4641C2.76562 17.8156 2.02344 17.8547 1.4375 17.5031C0.851562 17.1906 0.5 16.5656 0.5 15.8625V2.1125C0.5 1.44844 0.851562 0.823435 1.4375 0.510935ZM2.375 15.8625L13.625 8.9875L2.375 2.1125V15.8625Z"
            fill="currentColor"
        />
    )
})

export const PlayIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PlayIconSolid',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M14.6016 7.38593C15.1484 7.7375 15.5 8.3625 15.5 8.9875C15.5 9.65156 15.1484 10.2766 14.6016 10.5891L3.35156 17.4641C2.76562 17.8156 2.02344 17.8547 1.4375 17.5031C0.851562 17.1906 0.5 16.5656 0.5 15.8625V2.1125C0.5 1.44844 0.851562 0.823435 1.4375 0.510935C2.02344 0.159373 2.76562 0.159373 3.35156 0.549998L14.6016 7.38593Z"
            fill="currentColor"
        />
    )
})

export const PlayCircleIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PlayCircleIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M13.9062 9.21875C14.1797 9.375 14.375 9.6875 14.375 10C14.375 10.3516 14.1797 10.6641 13.9062 10.8203L8.28125 14.2578C8.00781 14.4141 7.61719 14.4531 7.34375 14.2578C7.03125 14.1016 6.875 13.7891 6.875 13.4375V6.5625C6.875 6.25 7.03125 5.9375 7.34375 5.78125C7.61719 5.58594 8.00781 5.58594 8.28125 5.78125L13.9062 9.21875ZM8.125 7.14844V12.8906L12.8125 10L8.125 7.14844ZM0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10ZM10 18.75C14.8047 18.75 18.75 14.8438 18.75 10C18.75 5.19531 14.8047 1.25 10 1.25C5.15625 1.25 1.25 5.19531 1.25 10C1.25 14.8438 5.15625 18.75 10 18.75Z"
            fill="currentColor"
        />
    )
})

export const PlayCircleIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PlayCircleIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M7.34375 5.78125C7.61719 5.58594 8.00781 5.58594 8.28125 5.78125L13.9062 9.21875C14.1797 9.375 14.375 9.6875 14.375 10C14.375 10.3516 14.1797 10.6641 13.9062 10.8203L8.28125 14.2578C8.00781 14.4141 7.61719 14.4531 7.34375 14.2578C7.03125 14.1016 6.875 13.7891 6.875 13.4375V6.5625C6.875 6.25 7.03125 5.9375 7.34375 5.78125ZM20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10ZM10 1.875C5.50781 1.875 1.875 5.54688 1.875 10C1.875 14.4922 5.50781 18.125 10 18.125C14.4531 18.125 18.125 14.4922 18.125 10C18.125 5.54688 14.4531 1.875 10 1.875Z"
            fill="currentColor"
        />
    )
})

export const PlayCircleIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PlayCircleIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10ZM6.875 6.5625V13.4375C6.875 13.7891 7.03125 14.1016 7.34375 14.2578C7.61719 14.4531 8.00781 14.4141 8.28125 14.2578L13.9062 10.8203C14.1797 10.6641 14.375 10.3516 14.375 10C14.375 9.6875 14.1797 9.375 13.9062 9.21875L8.28125 5.78125C8.00781 5.58594 7.61719 5.58594 7.34375 5.78125C7.03125 5.9375 6.875 6.25 6.875 6.5625Z"
            fill="currentColor"
        />
    )
})

export const PauseIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PauseIconLight',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M0.625 0.5C0.273438 0.5 0 0.8125 0 1.125V14.875C0 15.2266 0.273438 15.5 0.625 15.5C0.9375 15.5 1.25 15.2266 1.25 14.875V1.125C1.25 0.8125 0.9375 0.5 0.625 0.5ZM9.375 0.5C9.02344 0.5 8.75 0.773438 8.75 1.125V14.875C8.75 15.1875 9.02344 15.5 9.375 15.5C9.6875 15.5 10 15.1875 10 14.875V1.125C10 0.8125 9.6875 0.5 9.375 0.5Z"
            fill="currentColor"
        />
    )
})

export const PauseIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PauseIconRegular',
    viewBox: '0 0 10 16',
    path: (
        <path
            d="M0.9375 0.5C0.390625 0.5 0 0.929688 0 1.4375V14.5625C0 15.1094 0.390625 15.5 0.9375 15.5C1.44531 15.5 1.875 15.1094 1.875 14.5625V1.4375C1.875 0.929688 1.44531 0.5 0.9375 0.5ZM9.0625 0.5C8.51562 0.5 8.125 0.929688 8.125 1.4375V14.5625C8.125 15.1094 8.51562 15.5 9.0625 15.5C9.57031 15.5 10 15.1094 10 14.5625V1.4375C10 0.929688 9.57031 0.5 9.0625 0.5Z"
            fill="currentColor"
        />
    )
})

export const PauseIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PauseIconSolid',
    viewBox: '0 0 14 16',
    path: (
        <path
            d="M11.375 0.5H10.125C9.07031 0.5 8.25 1.32031 8.25 2.33594V13.5859C8.25 14.6016 9.07031 15.4609 10.125 15.4609L11.375 15.5C12.3906 15.5 13.25 14.6797 13.25 13.625V2.375C13.25 1.35938 12.3906 0.5 11.375 0.5ZM3.875 0.5H2.625C1.57031 0.5 0.75 1.32031 0.75 2.375V13.625C0.75 14.6797 1.57031 15.5 2.625 15.5H3.875C4.89062 15.5 5.75 14.6797 5.75 13.625V2.375C5.75 1.35938 4.89062 0.5 3.875 0.5Z"
            fill="currentColor"
        />
    )
})

export const PauseCircleIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PauseCircleIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M8.125 6.25C7.77344 6.25 7.5 6.5625 7.5 6.875V13.125C7.5 13.4766 7.77344 13.75 8.125 13.75C8.4375 13.75 8.75 13.4766 8.75 13.125V6.875C8.75 6.5625 8.4375 6.25 8.125 6.25ZM11.875 6.25C11.5234 6.25 11.25 6.5625 11.25 6.875V13.125C11.25 13.4766 11.5234 13.75 11.875 13.75C12.1875 13.75 12.5 13.4766 12.5 13.125V6.875C12.5 6.5625 12.1875 6.25 11.875 6.25ZM10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 18.75C5.15625 18.75 1.25 14.8438 1.25 10C1.25 5.19531 5.15625 1.25 10 1.25C14.8047 1.25 18.75 5.19531 18.75 10C18.75 14.8438 14.8047 18.75 10 18.75Z"
            fill="currentColor"
        />
    )
})

export const PauseCircleIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PauseCircleIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M7.8125 6.25C7.26562 6.25 6.875 6.67969 6.875 7.1875V12.8125C6.875 13.3594 7.26562 13.75 7.8125 13.75C8.32031 13.75 8.75 13.3594 8.75 12.8125V7.1875C8.75 6.67969 8.32031 6.25 7.8125 6.25ZM12.1875 6.25C11.6406 6.25 11.25 6.67969 11.25 7.1875V12.8125C11.25 13.3594 11.6406 13.75 12.1875 13.75C12.6953 13.75 13.125 13.3594 13.125 12.8125V7.1875C13.125 6.67969 12.6953 6.25 12.1875 6.25ZM10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 18.125C5.50781 18.125 1.875 14.4922 1.875 10C1.875 5.54688 5.50781 1.875 10 1.875C14.4531 1.875 18.125 5.54688 18.125 10C18.125 14.4922 14.4531 18.125 10 18.125Z"
            fill="currentColor"
        />
    )
})

export const PauseCircleIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PauseCircleIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM8.75 7.5V12.5C8.75 13.2031 8.16406 13.75 7.5 13.75C6.79688 13.75 6.25 13.2031 6.25 12.5V7.5C6.25 6.83594 6.79688 6.25 7.46094 6.25C8.08594 6.25 8.75 6.83594 8.75 7.5ZM13.75 7.5V12.5C13.75 13.2031 13.1641 13.75 12.5 13.75C11.7969 13.75 11.25 13.2031 11.25 12.5V7.5C11.25 6.83594 11.7969 6.25 12.4609 6.25C13.0859 6.25 13.75 6.83594 13.75 7.5Z"
            fill="currentColor"
        />
    )
})

export const StopIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'StopIconLight',
    viewBox: '0 0 16 16',
    path: (
        <path
            d="M13 0.5H3C1.59375 0.5 0.5 1.63281 0.5 3V13C0.5 14.3672 1.59375 15.5 3 15.5H13C14.3672 15.5 15.5 14.3672 15.5 13V3C15.5 1.63281 14.3672 0.5 13 0.5ZM14.25 13C14.25 13.7031 13.6641 14.25 13 14.25H3C2.29688 14.25 1.75 13.7031 1.75 13V3C1.75 2.33594 2.29688 1.75 3 1.75H13C13.6641 1.75 14.25 2.33594 14.25 3V13Z"
            fill="currentColor"
        />
    )
})

export const StopIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'StopIconRegular',
    viewBox: '0 0 16 16',
    path: (
        <path
            d="M13 0.5H3C1.59375 0.5 0.5 1.63281 0.5 3V13C0.5 14.3672 1.59375 15.5 3 15.5H13C14.3672 15.5 15.5 14.3672 15.5 13V3C15.5 1.63281 14.3672 0.5 13 0.5ZM13.625 13C13.625 13.3516 13.3125 13.625 13 13.625H3C2.64844 13.625 2.375 13.3516 2.375 13V3C2.375 2.6875 2.64844 2.375 3 2.375H13C13.3125 2.375 13.625 2.6875 13.625 3V13Z"
            fill="currentColor"
        />
    )
})

export const StopIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'StopIconSolid',
    viewBox: '0 0 16 16',
    path: (
        <path
            d="M15.5 3V13C15.5 14.3672 14.3672 15.5 13 15.5H3C1.59375 15.5 0.5 14.3672 0.5 13V3C0.5 1.63281 1.59375 0.5 3 0.5H13C14.3672 0.5 15.5 1.63281 15.5 3Z"
            fill="currentColor"
        />
    )
})

export const StopCircleIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'StopCircleIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M12.5 6.25H7.5C6.79688 6.25 6.25 6.83594 6.25 7.5V12.5C6.25 13.2031 6.79688 13.75 7.5 13.75H12.5C13.1641 13.75 13.75 13.2031 13.75 12.5V7.5C13.75 6.83594 13.1641 6.25 12.5 6.25ZM12.5 12.5H7.5V7.5H12.5V12.5ZM10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 18.75C5.15625 18.75 1.25 14.8438 1.25 10C1.25 5.19531 5.15625 1.25 10 1.25C14.8047 1.25 18.75 5.19531 18.75 10C18.75 14.8438 14.8047 18.75 10 18.75Z"
            fill="currentColor"
        />
    )
})

export const StopCircleIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'StopCircleIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M12.8125 6.25H7.1875C6.64062 6.25 6.25 6.67969 6.25 7.1875V12.8125C6.25 13.3594 6.64062 13.75 7.1875 13.75H12.8125C13.3203 13.75 13.75 13.3594 13.75 12.8125V7.1875C13.75 6.67969 13.3203 6.25 12.8125 6.25ZM10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 18.125C5.50781 18.125 1.875 14.4922 1.875 10C1.875 5.54688 5.50781 1.875 10 1.875C14.4531 1.875 18.125 5.54688 18.125 10C18.125 14.4922 14.4531 18.125 10 18.125Z"
            fill="currentColor"
        />
    )
})

export const StopCircleIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'StopCircleIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM13.75 12.8125C13.75 13.3594 13.3203 13.75 12.8125 13.75H7.1875C6.64062 13.75 6.25 13.3594 6.25 12.8125V7.1875C6.25 6.67969 6.64062 6.25 7.1875 6.25H12.8125C13.3203 6.25 13.75 6.67969 13.75 7.1875V12.8125Z"
            fill="currentColor"
        />
    )
})

export const MusicIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MusicIconLight',
    viewBox: '0 0 16 20',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.01167 14.3233C6.01167 15.8729 4.75543 17.1291 3.20578 17.1291C1.65614 17.1291 0.399902 15.8729 0.399902 14.3233C0.399902 12.7736 1.65614 11.5174 3.20578 11.5174C3.80308 11.5174 4.35679 11.704 4.81177 12.0221V0.653809L15.0118 2.35382L15.0118 16.3233C15.0118 17.8729 13.7554 19.1291 12.2058 19.1291C10.6561 19.1291 9.3999 17.8729 9.3999 16.3233C9.3999 14.7736 10.6561 13.5174 12.2058 13.5174C12.8031 13.5174 13.3568 13.704 13.8118 14.0221L13.8118 6.90015L6.01177 5.60015V13.9998C6.01177 13.9998 6.01167 14.2904 6.01167 14.3233ZM13.8118 5.6836L13.8118 3.37037L6.01177 2.07036V4.38359L13.8118 5.6836ZM1.5999 14.3233C1.5999 13.4364 2.31888 12.7174 3.20578 12.7174C4.09269 12.7174 4.81167 13.4364 4.81167 14.3233C4.81167 15.2102 4.09269 15.9291 3.20578 15.9291C2.31888 15.9291 1.5999 15.2102 1.5999 14.3233ZM12.2058 14.7174C11.3189 14.7174 10.5999 15.4364 10.5999 16.3233C10.5999 17.2102 11.3189 17.9291 12.2058 17.9291C13.0927 17.9291 13.8117 17.2102 13.8117 16.3233C13.8117 15.4364 13.0927 14.7174 12.2058 14.7174Z"
            fill="currentColor"
        />
    )
})

export const MusicIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MusicIconRegular',
    viewBox: '0 0 16 20',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.35182 14.4153C6.35182 14.306 6.34625 14.1981 6.33538 14.0917H6.35181V5.74856L13.4718 6.93523V13.5345C13.0845 13.364 12.6563 13.2694 12.2059 13.2694C10.4685 13.2694 9.06006 14.6779 9.06006 16.4153C9.06006 18.1527 10.4685 19.5612 12.2059 19.5612C13.9434 19.5612 15.3518 18.1527 15.3518 16.4153L15.3518 16.4048V1.81333L4.47181 0V11.5345C4.08448 11.364 3.65626 11.2694 3.20594 11.2694C1.46852 11.2694 0.0600586 12.6779 0.0600586 14.4153C0.0600586 16.1527 1.46852 17.5612 3.20594 17.5612C4.94336 17.5612 6.35182 16.1527 6.35182 14.4153ZM6.35181 2.21927V3.84263L13.4718 5.0293V3.40593L6.35181 2.21927ZM1.94006 14.4153C1.94006 13.7162 2.50681 13.1494 3.20594 13.1494C3.90507 13.1494 4.47182 13.7162 4.47182 14.4153C4.47182 15.1144 3.90507 15.6812 3.20594 15.6812C2.50681 15.6812 1.94006 15.1144 1.94006 14.4153ZM10.9401 16.4153C10.9401 15.7162 11.5068 15.1494 12.2059 15.1494C12.9029 15.1494 13.4683 15.7127 13.4718 16.4088V16.4154C13.4718 17.1145 12.905 17.6812 12.2059 17.6812C11.5068 17.6812 10.9401 17.1144 10.9401 16.4153Z"
            fill="currentColor"
        />
    )
})

export const MusicIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'StopCircleIconSolid',
    viewBox: '0 0 16 20',
    path: (
        <path
            d="M6.35182 14.5071C6.35182 14.3978 6.34625 14.2899 6.33538 14.1835H6.35181V5.84036L13.4718 7.02703V13.6263C13.0845 13.4558 12.6563 13.3612 12.2059 13.3612C10.4685 13.3612 9.06006 14.7697 9.06006 16.5071C9.06006 18.2445 10.4685 19.653 12.2059 19.653C13.9434 19.653 15.3518 18.2445 15.3518 16.5071L15.3518 16.4966V1.90513L4.47181 0.0917969V11.6263C4.08448 11.4558 3.65626 11.3612 3.20594 11.3612C1.46852 11.3612 0.0600586 12.7697 0.0600586 14.5071C0.0600586 16.2445 1.46852 17.653 3.20594 17.653C4.94336 17.653 6.35182 16.2445 6.35182 14.5071Z"
            fill="currentColor"
        />
    )
})
