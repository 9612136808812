import 'dayjs/locale/es'

import { useColorMode } from '@chakra-ui/react'
import {
    DatePicker as MantineDatePicker,
    DatePickerProps as MantineDatePickerProps
} from '@mantine/dates'
import { Maybe } from '@missionlabs/types/src/util'
import { Themes } from 'index'
import { FC } from 'react'
import { colors } from 'theme/zeta/colors/colors'

import clStyles from '../../theme/circleloop/datePicker'
import zetaStyles from '../../theme/zeta/datePicker'

const styles = {
    [Themes.CIRCLELOOP]: clStyles,
    [Themes.ZETA]: zetaStyles
}

export type DatePickerDate = Maybe<number>

export type DatePickerProps = MantineDatePickerProps & {
    numberOfMonths?: number
    existingValues?: Date[]
    theme: Themes
    disablePastDays?: boolean
}

export const DatePicker: FC<DatePickerProps> = ({
    numberOfMonths = 1,
    locale,
    theme,
    value,
    existingValues,
    onChange,
    disablePastDays = false,
    ...props
}) => {
    const { colorMode } = useColorMode()

    const getDayProps: DatePickerProps['getDayProps'] = date => {
        const formattedValues = existingValues?.map(date => date.getTime())

        if (formattedValues?.includes(date.getTime())) {
            return {
                style: {
                    backgroundColor: colors[colorMode].tones.whiteLilac, // Will need to add in CL colors if needed
                    color: colors[colorMode].tones.navy
                }
            }
        }

        return {}
    }

    const excludeDates = (date: Date) => {
        if (!disablePastDays) return false
        const today = new Date()
        today.setHours(0, 0, 0, 0)

        return date < today
    }

    return (
        <MantineDatePicker
            type="default"
            value={value}
            styles={styles[theme]}
            allowDeselect
            numberOfColumns={numberOfMonths}
            onChange={onChange}
            getDayProps={getDayProps}
            locale={locale}
            excludeDate={excludeDates}
            ariaLabels={{
                previousMonth: 'Previous month',
                nextMonth: 'Next month'
            }}
            {...props}
        />
    )
}
