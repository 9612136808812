import { createIcon } from '@chakra-ui/react'

export const FileAudioIconLight = createIcon({
    displayName: 'FileAudioIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M16.5999 1.9999C16.5999 1.66853 16.3313 1.3999 15.9999 1.3999C15.6685 1.3999 15.3999 1.66853 15.3999 1.9999V17.9999C15.3999 18.3313 15.6685 18.5999 15.9999 18.5999C16.3313 18.5999 16.5999 18.3313 16.5999 17.9999V1.9999ZM9.9999 3.68604C10.3313 3.68604 10.5999 3.95466 10.5999 4.28604V15.7146C10.5999 16.046 10.3313 16.3146 9.9999 16.3146C9.66853 16.3146 9.3999 16.046 9.3999 15.7146V4.28604C9.3999 3.95466 9.66853 3.68604 9.9999 3.68604ZM0.999902 7.11377C1.33127 7.11377 1.5999 7.3824 1.5999 7.71377V12.2852C1.5999 12.6166 1.33127 12.8852 0.999902 12.8852C0.668532 12.8852 0.399902 12.6166 0.399902 12.2852V7.71377C0.399902 7.3824 0.668532 7.11377 0.999902 7.11377ZM3.9999 8.25732C4.33127 8.25732 4.5999 8.52595 4.5999 8.85732V11.143C4.5999 11.4744 4.33127 11.743 3.9999 11.743C3.66853 11.743 3.3999 11.4744 3.3999 11.143V8.85732C3.3999 8.52595 3.66853 8.25732 3.9999 8.25732ZM7.5999 7.71377C7.5999 7.3824 7.33127 7.11377 6.9999 7.11377C6.66853 7.11377 6.3999 7.3824 6.3999 7.71377V12.2852C6.3999 12.6166 6.66853 12.8852 6.9999 12.8852C7.33127 12.8852 7.5999 12.6166 7.5999 12.2852V7.71377ZM12.9999 7.11377C13.3313 7.11377 13.5999 7.3824 13.5999 7.71377V12.2852C13.5999 12.6166 13.3313 12.8852 12.9999 12.8852C12.6685 12.8852 12.3999 12.6166 12.3999 12.2852V7.71377C12.3999 7.3824 12.6685 7.11377 12.9999 7.11377ZM19.5999 8.85732C19.5999 8.52595 19.3313 8.25732 18.9999 8.25732C18.6685 8.25732 18.3999 8.52595 18.3999 8.85732V11.143C18.3999 11.4744 18.6685 11.743 18.9999 11.743C19.3313 11.743 19.5999 11.4744 19.5999 11.143V8.85732Z"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
        />
    )
})

export const FileAudioIconRegular = createIcon({
    displayName: 'FileAudioIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M16.9001 2.0001C16.9001 1.50304 16.4972 1.1001 16.0001 1.1001C15.503 1.1001 15.1001 1.50304 15.1001 2.0001V18.0001C15.1001 18.4972 15.503 18.9001 16.0001 18.9001C16.4972 18.9001 16.9001 18.4972 16.9001 18.0001V2.0001ZM10.0001 3.38623C10.4972 3.38623 10.9001 3.78917 10.9001 4.28623V15.7148C10.9001 16.2119 10.4972 16.6148 10.0001 16.6148C9.50304 16.6148 9.1001 16.2119 9.1001 15.7148V4.28623C9.1001 3.78917 9.50304 3.38623 10.0001 3.38623ZM1.0001 6.81396C1.49715 6.81396 1.9001 7.21691 1.9001 7.71396V12.2854C1.9001 12.7824 1.49715 13.1854 1.0001 13.1854C0.503041 13.1854 0.100098 12.7824 0.100098 12.2854V7.71396C0.100098 7.21691 0.503041 6.81396 1.0001 6.81396ZM4.0001 7.95752C4.49715 7.95752 4.9001 8.36046 4.9001 8.85752V11.1432C4.9001 11.6403 4.49715 12.0432 4.0001 12.0432C3.50304 12.0432 3.1001 11.6403 3.1001 11.1432V8.85752C3.1001 8.36046 3.50304 7.95752 4.0001 7.95752ZM7.9001 7.71396C7.9001 7.21691 7.49715 6.81396 7.0001 6.81396C6.50304 6.81396 6.1001 7.21691 6.1001 7.71396V12.2854C6.1001 12.7824 6.50304 13.1854 7.0001 13.1854C7.49715 13.1854 7.9001 12.7824 7.9001 12.2854V7.71396ZM13.0001 6.81396C13.4972 6.81396 13.9001 7.21691 13.9001 7.71396V12.2854C13.9001 12.7824 13.4972 13.1854 13.0001 13.1854C12.503 13.1854 12.1001 12.7824 12.1001 12.2854V7.71396C12.1001 7.21691 12.503 6.81396 13.0001 6.81396ZM19.9001 8.85752C19.9001 8.36046 19.4972 7.95752 19.0001 7.95752C18.503 7.95752 18.1001 8.36046 18.1001 8.85752V11.1432C18.1001 11.6403 18.503 12.0432 19.0001 12.0432C19.4972 12.0432 19.9001 11.6403 19.9001 11.1432V8.85752Z"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
        />
    )
})

export const FileAudioIconSolid = createIcon({
    displayName: 'FileAudioIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <g>
            <path
                d="M18.2 2.00029C18.2 1.33755 17.6628 0.800293 17 0.800293C16.3373 0.800293 15.8 1.33755 15.8 2.00029V18.0003C15.8 18.663 16.3373 19.2003 17 19.2003C17.6628 19.2003 18.2 18.663 18.2 18.0003V2.00029Z"
                fill="currentColor"
            />
            <path
                d="M11 3.08643C11.6628 3.08643 12.2 3.62368 12.2 4.28643V15.715C12.2 16.3777 11.6628 16.915 11 16.915C10.3373 16.915 9.80005 16.3777 9.80005 15.715V4.28643C9.80005 3.62368 10.3373 3.08643 11 3.08643Z"
                fill="currentColor"
            />
            <path
                d="M2.00005 6.51416C2.66279 6.51416 3.20005 7.05142 3.20005 7.71416V12.2856C3.20005 12.9483 2.66279 13.4856 2.00005 13.4856C1.33731 13.4856 0.800049 12.9483 0.800049 12.2856V7.71416C0.800049 7.05142 1.33731 6.51416 2.00005 6.51416Z"
                fill="currentColor"
            />
            <path
                d="M5.00005 7.65771C5.66279 7.65771 6.20005 8.19497 6.20005 8.85771V11.1434C6.20005 11.8062 5.66279 12.3434 5.00005 12.3434C4.33731 12.3434 3.80005 11.8062 3.80005 11.1434V8.85771C3.80005 8.19497 4.33731 7.65771 5.00005 7.65771Z"
                fill="currentColor"
            />
            <path
                d="M9.20005 7.71416C9.20005 7.05142 8.66279 6.51416 8.00005 6.51416C7.33731 6.51416 6.80005 7.05142 6.80005 7.71416V12.2856C6.80005 12.9483 7.33731 13.4856 8.00005 13.4856C8.66279 13.4856 9.20005 12.9483 9.20005 12.2856V7.71416Z"
                fill="currentColor"
            />
            <path
                d="M14 6.51416C14.6628 6.51416 15.2 7.05142 15.2 7.71416V12.2856C15.2 12.9483 14.6628 13.4856 14 13.4856C13.3373 13.4856 12.8 12.9483 12.8 12.2856V7.71416C12.8 7.05142 13.3373 6.51416 14 6.51416Z"
                fill="currentColor"
            />
            <path
                d="M21.2 8.85771C21.2 8.19497 20.6628 7.65771 20 7.65771C19.3373 7.65771 18.8 8.19497 18.8 8.85771V11.1434C18.8 11.8062 19.3373 12.3434 20 12.3434C20.6628 12.3434 21.2 11.8062 21.2 11.1434V8.85771Z"
                fill="currentColor"
            />
        </g>
    )
})
