import { HStack, useColorMode } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

export interface SubNavLinkProps {
    label: string
    page: string
}

const SubNavListItem = ({ isActive, children, ...props }: any) => {
    const { colorMode } = useColorMode()
    const red = `${colorMode}.alerts.redDark`
    const white = `${colorMode}.tones.white`
    return (
        <HStack
            role="listitem"
            data-active={isActive || undefined}
            p="6px 8px"
            rounded="4px"
            bg={isActive ? red : undefined}
            color={isActive ? white : `${colorMode}.tones.navy`}
            _hover={{ color: red }}
            _active={{ color: white }}
            transition="all 200ms ease"
            {...props}
        >
            <Body color="inherit">{children}</Body>
        </HStack>
    )
}

export const SubNavLink: FC<SubNavLinkProps> = ({ label, page }) => {
    return (
        <NavLink to={page}>
            {({ isActive }) => <SubNavListItem isActive={isActive}>{label}</SubNavListItem>}
        </NavLink>
    )
}
