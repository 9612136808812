import { Box, HStack, IconButton, useColorMode, useDisclosure, VStack } from '@chakra-ui/react'
import { selectInData } from '@missionlabs/api'
import {
    Button,
    Dropdown,
    HeaderAvatar,
    Input,
    useFormContext,
    useToast,
    ValidatedInput
} from '@missionlabs/react'
import { DeleteIcon } from '@missionlabs/react/circleloop'
import { Country, Option, Timezone } from '@missionlabs/types'
import { localeToOption, timezoneToOption } from '@missionlabs/utils'
import { useSettingsContext } from 'features/settings/components/settingsContext'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Address } from 'shared/components/Address'
import { useGetLocalesQuery, useGetTimezonesQuery } from 'shared/store'

import { AppVersion } from './AppVersion'
import { ChangePassword } from './ChangePassword'
import { MyDetailsFormFields } from './MyDetails'
import { UploadImageModal } from './UploadImageModal'

const selectLocaleOptions = selectInData<Country[], Option[]>(
    data => data?.map(localeToOption).sort((a, b) => a.label.localeCompare(b.label)) ?? []
)

const selectTimezoneOptions = selectInData<Timezone[], Option[]>(
    data => data?.map(timezoneToOption) ?? []
)

type MyDetailsFormProps = {}

export const MyDetailsForm: FC<MyDetailsFormProps> = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { toast } = useToast()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [showProfileDelete, setShowProfileDelete] = useState(false)
    const { user, onUpdateUser: updateUser } = useSettingsContext()

    const { locales } = useGetLocalesQuery(undefined, {
        selectFromResult: result => ({ ...result, locales: selectLocaleOptions(result) })
    })
    const { timezones } = useGetTimezonesQuery(undefined, {
        selectFromResult: result => ({ ...result, timezones: selectTimezoneOptions(result) })
    })

    const handleProfileDelete = async () => {
        if (!user) return

        try {
            await updateUser({ ID: user.ID, photoURL: null })
        } catch (e) {
            console.log(e)
            toast({ status: 'error', title: 'Failed to delete profile picture' })
        }
    }

    const { watch, setValue } = useFormContext<MyDetailsFormFields>()

    const { locale, timezone } = watch()

    return (
        <>
            {/* Profile Picture */}
            <SettingsRow label={t('myDetails.profile.header')} size="md"></SettingsRow>
            <SettingsRow label={t('myDetails.profile.title')}>
                <VStack w="full" align="start" spacing={4}>
                    <Box
                        border="1px solid"
                        borderRadius="8px"
                        borderColor={`${colorMode}.tones.periwinkle`}
                        onMouseEnter={() => setShowProfileDelete(true)}
                        onMouseLeave={() => setShowProfileDelete(false)}
                    >
                        <HeaderAvatar
                            size="lg"
                            name={user?.fullName}
                            src={user?.photoURL ?? undefined}
                            borderRadius={0}
                        >
                            {showProfileDelete && user?.photoURL && (
                                <IconButton
                                    aria-label="Close filters"
                                    icon={<DeleteIcon boxSize="20px" />}
                                    variant="negatory"
                                    position={'absolute'}
                                    size="md"
                                    onClick={handleProfileDelete}
                                />
                            )}
                        </HeaderAvatar>
                    </Box>

                    <Button onClick={onOpen} w="250px">
                        {t('myDetails.profile.uploadNew')}
                    </Button>

                    <UploadImageModal isOpen={isOpen} onClose={onClose} />
                </VStack>
            </SettingsRow>

            {/* Email Address */}
            <SettingsRow label={t('myDetails.email')} divider={false}>
                <ValidatedInput name="email">
                    <Input w="300px" data-testid="email-input" />
                </ValidatedInput>
            </SettingsRow>

            {/* Password */}
            <SettingsRow label={t('myDetails.security')} size="md"></SettingsRow>
            <ChangePassword />

            {/*Address */}
            <SettingsRow label={t('myDetails.address.title')}>
                <Address />
            </SettingsRow>

            {/*Localisation */}
            <SettingsRow label={t('myDetails.localisation')} divider={false}>
                <HStack align="inherit" spacing="24px" w="full">
                    <ValidatedInput
                        name="locale"
                        label={t('myDetails.myLocale')}
                        shouldDisable
                        shouldRegister={false}
                    >
                        <Dropdown
                            width="full"
                            options={locales}
                            value={locale}
                            onChange={value => setValue('locale', value, { shouldDirty: true })}
                        />
                    </ValidatedInput>

                    <ValidatedInput
                        name="timezone"
                        label={t('myDetails.myTimezone')}
                        shouldDisable
                        shouldRegister={false}
                    >
                        <Dropdown
                            width="full"
                            options={timezones}
                            value={timezone}
                            onChange={value => setValue('timezone', value, { shouldDirty: true })}
                        />
                    </ValidatedInput>
                </HStack>
            </SettingsRow>
            {/*Application*/}
            <SettingsRow label={t('myDetails.application')} size="md"></SettingsRow>
            <AppVersion />
        </>
    )
}
