import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ColleagueIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ColleagueIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0002 24C5.38328 24 0 18.6168 0 11.9995C0 5.38327 5.38328 0 12.0002 0C18.6166 0 24 5.38317 24 11.9995C24 18.6168 18.6167 24 12.0002 24ZM19.5449 19.9976C15.7553 18.3661 15.1701 17.6894 15.048 17.5038V16.762C15.7093 16.0191 16.2271 15.0529 16.5557 13.9465C16.9715 13.5893 17.2482 13.0813 17.3241 12.5261C17.4009 11.9602 17.271 11.4017 16.9549 10.9318V8.71114C16.9549 5.71702 15.1312 4 11.9505 4C8.81602 4 6.94497 5.76125 6.94497 8.71114V10.9328C6.63044 11.4017 6.49991 11.9592 6.57742 12.5239C6.65334 13.0802 6.92942 13.5881 7.34424 13.9454C7.67335 15.0529 8.19113 16.0191 8.8535 16.762V17.5112C8.72807 17.729 8.13544 18.4163 4.45555 19.9979C2.32907 17.9908 1 15.1474 1 11.9995C1 5.93446 5.93448 1 12.0002 1C18.0659 1 23 5.93446 23 11.9995C23 15.1473 21.6711 17.9905 19.5449 19.9976Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_187"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.0002 24C5.38328 24 0 18.6168 0 11.9995C0 5.38327 5.38328 0 12.0002 0C18.6166 0 24 5.38317 24 11.9995C24 18.6168 18.6167 24 12.0002 24ZM19.5449 19.9976C15.7553 18.3661 15.1701 17.6894 15.048 17.5038V16.762C15.7093 16.0191 16.2271 15.0529 16.5557 13.9465C16.9715 13.5893 17.2482 13.0813 17.3241 12.5261C17.4009 11.9602 17.271 11.4017 16.9549 10.9318V8.71114C16.9549 5.71702 15.1312 4 11.9505 4C8.81602 4 6.94497 5.76125 6.94497 8.71114V10.9328C6.63044 11.4017 6.49991 11.9592 6.57742 12.5239C6.65334 13.0802 6.92942 13.5881 7.34424 13.9454C7.67335 15.0529 8.19113 16.0191 8.8535 16.762V17.5112C8.72807 17.729 8.13544 18.4163 4.45555 19.9979C2.32907 17.9908 1 15.1474 1 11.9995C1 5.93446 5.93448 1 12.0002 1C18.0659 1 23 5.93446 23 11.9995C23 15.1473 21.6711 17.9905 19.5449 19.9976Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_187)"></g>
        </g>
    )
})
