import { HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Button, ButtonLink } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetPortingFormURLsQuery } from 'shared/store'

interface YesNonMobilePortableComponentProps {
    onReset: () => void
}

const YesNonMobilePortableComponent: FC<YesNonMobilePortableComponentProps> = ({ onReset }) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    const { data: { PORTING_FORM = '', PORTING_HELP = '' } = {} } = useGetPortingFormURLsQuery()

    return (
        <VStack
            h="full"
            p={4}
            m={4}
            bg={`${colorMode}.tones.ghostWhite`}
            sx={{ borderRadius: '10px' }}
        >
            <VStack align="flex-start">
                <VStack align="flex-start" p={1}>
                    <Body size="sm">{t('admin.numbers.porting.yes')}</Body>
                </VStack>
                <HStack p={1}>
                    <ButtonLink to={PORTING_FORM} target="_blank" rel="noopener noreferrer">
                        {t('admin.numbers.porting.requestForm')}
                    </ButtonLink>
                    <ButtonLink
                        to={PORTING_HELP}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="secondary"
                    >
                        {t('admin.numbers.porting.help')}
                    </ButtonLink>
                </HStack>
                <Button p={1} onClick={onReset} variant="link-underline" size={'md'}>
                    {t('admin.numbers.porting.checkDifferent')}
                </Button>
            </VStack>
        </VStack>
    )
}

export default YesNonMobilePortableComponent
