import { Divider, SimpleGrid, StackProps, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Input, useFormContext, ValidatedInput } from '@missionlabs/react'
import { SettingSwitch } from 'features/settings/components/SettingsSwitch'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { APP_BRAND, AppConfig } from 'shared/utils/env'

import { SubscriptionOption } from './SubscriptionOption'

export const UserDetailsForm: FC<StackProps> = ({ ...props }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { watch, setValue } = useFormContext()

    const { type, subscriptionType } = watch()

    const onChangeAdminSetting = (value: boolean) => {
        const setting = value ? 'admin' : 'standard'
        setValue('type', setting)
    }

    return (
        <VStack spacing="16px" p="24px" {...props}>
            <SimpleGrid w="100%" columns={2} gap="16px">
                <ValidatedInput name="firstName" label={t('First name')}>
                    <Input />
                </ValidatedInput>
                <ValidatedInput name="lastName" label={t('Last name')}>
                    <Input />
                </ValidatedInput>
            </SimpleGrid>
            <ValidatedInput name="email" label={t('Email')}>
                <Input />
            </ValidatedInput>
            <Divider />
            <ValidatedInput name="type">
                <SettingSwitch
                    label={t('admin.users.setAsAdmin')}
                    isChecked={type === 'admin'}
                    onUpdate={onChangeAdminSetting}
                    py="8px"
                />
            </ValidatedInput>
            <Divider />
            {AppConfig.brand === APP_BRAND.CIRCLELOOP && (
                <VStack w="full" alignItems="start">
                    <Body
                        size="md"
                        sx={{ color: `${colorMode}.coolGrey.80`, pt: '8px', pb: '16px' }}
                    >
                        {t('admin.users.subscription')}
                    </Body>
                    <Divider />
                    <ValidatedInput name="subscriptionType">
                        <SubscriptionOption
                            isChecked={subscriptionType === 'pro'}
                            onChange={() => setValue('subscriptionType', 'pro')}
                            type={t('admin.users.unlimitedSubscription')}
                            description={t('admin.users.unlimitedSubscriptionDescription')}
                        />
                    </ValidatedInput>

                    <Divider />

                    <ValidatedInput name="subscriptionType">
                        <SubscriptionOption
                            isChecked={subscriptionType === 'standard'}
                            onChange={() => setValue('subscriptionType', 'standard')}
                            type={t('admin.users.paygSubscription')}
                            description={t('admin.users.paygSubscriptionDescription')}
                        />
                    </ValidatedInput>
                </VStack>
            )}
        </VStack>
    )
}
