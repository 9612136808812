import { Box, HStack, useDisclosure, VStack } from '@chakra-ui/react'
import {
    Body,
    Button,
    Dropdown,
    FormProvider,
    IconButton,
    Input,
    useFieldArray,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { DeleteIcon } from '@missionlabs/react/circleloop'
import {
    IntegrationType,
    IntegrationTypeEnum,
    NewSubscriptionGroup,
    SubscriptionGroup as SubscriptionGroupType,
    UpdateSubscriptionGroup
} from '@missionlabs/types'
import { format } from 'date-fns'
import { SettingsCheckboxList } from 'features/settings/components/SettingsCheckboxList'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { DeleteSubscriptionGroupDialog } from './DeleteSubscriptionGroupDialog'

export interface SubscriptionGroupProps {
    label: string
    integrationType: IntegrationType
    subscriptionGroup: SubscriptionGroupType | undefined
    isLoading: boolean
    handleCreate: (subscriptionGroup: NewSubscriptionGroup) => void
    handleUpdate: (subscriptionGroup: UpdateSubscriptionGroup) => void
    handleDelete: (subscriptionGroupID: string) => void
}

export type SubscriptionGroupFields = {
    url: string
    method?: string
    eventTypes: { eventType: string }[]
}

const methodOptions = [
    { label: 'GET', value: 'GET' },
    { label: 'PUT', value: 'PUT' },
    { label: 'POST', value: 'POST' },
    { label: 'DELETE', value: 'DELETE' }
]

export const SubscriptionGroup: FC<SubscriptionGroupProps> = ({
    label,
    integrationType,
    subscriptionGroup,
    isLoading,
    handleCreate,
    handleUpdate,
    handleDelete
}) => {
    const { t } = useTranslation()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const eventTypeOptions = useMemo(
        () => [
            { ID: 'call_missed', label: t('notifications.calls') },
            { ID: 'message_received', label: t('notifications.voicemails') },
            { ID: 'chat_message_received', label: t('notifications.sms') }
        ],
        [t]
    )

    const isHttp = useMemo(() => {
        return integrationType === IntegrationTypeEnum.HTTP
    }, [integrationType])

    const isSlack = useMemo(() => {
        return integrationType === IntegrationTypeEnum.SLACK
    }, [integrationType])

    const values = useMemo(() => {
        const url =
            subscriptionGroup?.integrationSettings.url ??
            subscriptionGroup?.integrationSettings.webhookURL ??
            ''
        const method = subscriptionGroup?.integrationSettings.method ?? methodOptions[0].value
        const eventTypes =
            subscriptionGroup?.eventTypes.map(event => {
                return { eventType: event }
            }) ?? []

        if (isHttp) return { url, method, eventTypes }
        if (isSlack) return { url, eventTypes }
    }, [isHttp, isSlack, subscriptionGroup])

    const subscriptionGroupSchema = yup.object({
        url: yup.string().test((value = '') => {
            const validURLRegex = isSlack
                ? /^https:\/\/hooks\.slack\.com\/services\/\S+$/
                : /^\w+:\/\S*$/
            return validURLRegex.test(value)
        }),
        method: yup.string(),
        eventTypes: yup.array(yup.object({ eventTypes: yup.string() })).min(1)
    })

    const methods = useYupForm<SubscriptionGroupFields>(subscriptionGroupSchema, { values })

    const {
        formState: { isValid },
        setValue,
        handleSubmit,
        watch,
        reset,
        control
    } = methods

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'eventTypes'
    })

    const eventTypes = watch('eventTypes')

    const onCheck = useCallback(
        (eventType: string) => {
            const eventTypes = fields.map(({ eventType }) => eventType)
            if (eventTypes.includes(eventType)) {
                const eventTypeIndex = eventTypes.indexOf(eventType)
                return remove(eventTypeIndex)
            } else return append({ eventType })
        },
        [append, fields, remove]
    )

    const onSubmit = (data: SubscriptionGroupFields) => {
        const { url, method } = data
        const integrationSettings = isHttp ? { url, method } : { webhookURL: url }
        const eventTypes = data.eventTypes.map(({ eventType }) => eventType)

        const updatedSubscriptionGroup = {
            integrationSettings,
            eventTypes,
            integrationType
        }

        if (subscriptionGroup) {
            handleUpdate({ ...updatedSubscriptionGroup, ID: subscriptionGroup.ID })
        } else handleCreate(updatedSubscriptionGroup)
    }

    const onDelete = () => {
        if (subscriptionGroup) handleDelete(subscriptionGroup.ID)
        reset()
        onClose()
    }

    const formatDate = (value: string) => {
        if (!value) return ''
        const date = new Date(value)
        return format(date, 'dd MMMM yyyy')
    }

    return (
        <SettingsRow
            label={
                <VStack spacing="4px" align="inherit">
                    <Body size="sm" variant="bold">
                        {label}
                    </Body>
                    {subscriptionGroup?.created && (
                        <Body size="sm">
                            <Trans
                                i18nKey="notifications.connected"
                                components={{ div: <Body sx={{ color: 'inherit' }} /> }}
                                values={{ date: formatDate(subscriptionGroup.created) }}
                            />
                        </Body>
                    )}
                </VStack>
            }
        >
            <FormProvider {...methods}>
                <Box as="form" onSubmit={handleSubmit(onSubmit)} w="full">
                    <HStack spacing={4} align="flex-end" mb={6}>
                        <ValidatedInput name="url" label={t('notifications.webhookURL')}>
                            <Input placeholder={t('notifications.enterURL')} />
                        </ValidatedInput>
                        {isHttp && (
                            <ValidatedInput
                                name="method"
                                label={t('notifications.method')}
                                width="110px"
                            >
                                <Dropdown
                                    width="110px"
                                    options={methodOptions}
                                    value={watch('method')}
                                    onChange={val => setValue('method', val)}
                                />
                            </ValidatedInput>
                        )}
                        <Button
                            variant="creationary"
                            type="submit"
                            isDisabled={!isValid}
                            isLoading={isLoading}
                        >
                            {t('Save')}
                        </Button>
                        {isValid && (
                            <IconButton
                                variant="negatory"
                                aria-label="Delete integration"
                                onClick={onOpen}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </HStack>
                    <SettingsCheckboxList
                        options={eventTypeOptions.map(({ ID, label }) => {
                            return {
                                ID,
                                label,
                                isChecked: eventTypes.map(({ eventType }) => eventType).includes(ID)
                            }
                        })}
                        onClick={onCheck}
                    />
                </Box>
            </FormProvider>
            <DeleteSubscriptionGroupDialog
                isOpen={isOpen}
                onClose={onClose}
                handleDelete={onDelete}
            />
        </SettingsRow>
    )
}
