import { buildRootAPI, buildZetaIDAPI, SuccessfulAuthResponse, useDispatch } from '../apis'
import { AuthenticatedUser, setAPIBaseURL, setUser, updateAutoLogin } from '../slices'

type ZetaIDArgs = {
    pbxAuth: ReturnType<typeof buildRootAPI>['useLoginWithJWTMutation']
    zetaAuth: ReturnType<typeof buildZetaIDAPI>['useAuthenticateMutation']
}

export const useZetaIDLogin = (args: ZetaIDArgs) => {
    const dispatch = useDispatch()

    const [authenticateWithZetaID, { isLoading: authIsLoading }] = args.zetaAuth()
    const [loginWithJWT, { isLoading: jwtLoginIsLoading }] = args.pbxAuth()

    const exchangeToken = async (
        authResponse: SuccessfulAuthResponse & { username: string },
        rememberDetails?: boolean
    ): Promise<AuthenticatedUser> => {
        const { access_token, config, username } = authResponse
        dispatch(setAPIBaseURL(`https://${config.web}/admin`))
        const { clientID, userID, ID } = await loginWithJWT(access_token).unwrap()
        const user: AuthenticatedUser = {
            token: ID,
            userID,
            clientID,
            username
            // role: UserRole.NONE,
            // loginType: LoginType.USER
        }

        dispatch(setUser(user))
        if (rememberDetails) dispatch(updateAutoLogin(true))

        return user
    }

    const loginWithZetaID = async (
        userName: string,
        password: string,
        rememberDetails?: boolean
    ) => {
        const authResponse = await authenticateWithZetaID({ userName, password }).unwrap()
        return await exchangeToken({ ...authResponse, username: userName }, rememberDetails)
    }

    return { loginWithZetaID, isLoading: authIsLoading || jwtLoginIsLoading }
}
