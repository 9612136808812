import consoleTransport from './console'
import fileTransport from './file'
import httpTransport from './http'
import indexedDBTransport from './indexeddb'
import Transport, { Log, TransportConfig } from './transport'

const transports = {
    console: consoleTransport,
    file: fileTransport,
    http: httpTransport,
    indexeddb: indexedDBTransport
}

export { type Log, type TransportConfig, Transport }

export default transports
