import { Grid, GridProps, useColorMode } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { FC, PropsWithChildren } from 'react'

interface ContactDetailsRowProps extends GridProps {
    field: string
    value?: string
    icon?: JSX.Element
}

export const ContactDetailsRow: FC<PropsWithChildren<ContactDetailsRowProps>> = ({
    field,
    value,
    icon,
    children,
    ...props
}) => {
    const { colorMode } = useColorMode()
    return (
        <Grid gap="24px" w="full" alignItems="center" templateColumns="128px 1fr" {...props}>
            <Body size="sm" variant="bold">
                {field}
            </Body>
            {value && (
                <Body size="sm" isTruncated sx={{ color: `${colorMode}.tones.navy` }}>
                    {value} {icon}
                </Body>
            )}
            {children}
        </Grid>
    )
}
