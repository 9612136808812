import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const MissedCallIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MissedCallIconLight',
    viewBox: '0 0 26 20',
    path: (
        <path
            d="M24.9484 13.8211C21.7469 10.5415 17.4912 8.74557 13.0013 8.74557C8.43331 8.74557 4.17765 10.5415 1.01519 13.8211C0.468594 14.4068 0.351466 15.3047 0.780936 15.9685L2.73307 19.17C3.20159 19.9118 4.06053 20.1851 4.88042 19.8727L8.7847 18.272C9.48746 17.9987 9.95598 17.2569 9.87789 16.476L9.68268 14.4458C11.83 13.8211 14.1726 13.8211 16.359 14.4458L16.1638 16.476C16.0857 17.2569 16.5542 17.9597 17.257 18.272L21.1612 19.8727C21.3565 19.9508 21.5517 19.9899 21.7859 19.9899C22.3716 19.9899 22.9182 19.7166 23.2695 19.17L25.2217 15.9685C25.6512 15.3047 25.534 14.4068 24.9484 13.8211ZM24.1285 15.3438L22.1373 18.5063C22.0202 18.7405 21.7859 18.8186 21.5517 18.7405L17.6474 17.1398C17.4522 17.0617 17.3351 16.8274 17.3351 16.5932L17.6084 14.0944C17.6084 13.7821 17.4522 13.5088 17.1789 13.4307C15.8124 12.9622 14.3678 12.7279 12.9623 12.7279C11.5177 12.7279 10.0731 12.9622 8.70661 13.4307C8.43331 13.5088 8.27714 13.7821 8.31618 14.0944L8.55044 16.5932C8.55044 16.8274 8.43331 17.0617 8.2381 17.1398L4.33382 18.7405C4.09957 18.8186 3.86531 18.7405 3.74818 18.5063L1.79605 15.3438C1.63988 15.1095 1.67892 14.9143 1.83509 14.6801C4.80234 11.6738 8.74565 9.99494 12.9623 9.99494C17.1398 9.99494 21.0832 11.6738 24.0504 14.6801C24.2456 14.8753 24.2847 15.1095 24.1285 15.3438ZM4.88042 6.24684C5.19276 6.24684 5.46606 5.93449 5.46606 5.62215V2.14735L9.29225 6.01258C10.2683 6.94961 11.5958 7.4962 12.9623 7.4962C14.2897 7.4962 15.6172 6.94961 16.5932 6.01258L20.8879 1.71788C21.1222 1.48362 21.1222 1.05415 20.8879 0.819897C20.6537 0.585641 20.2633 0.585641 19.99 0.819897L15.6953 5.1146C14.2507 6.55918 11.6348 6.55918 10.1902 5.1146L6.36405 1.24937H9.87789C10.1902 1.24937 10.4635 0.937025 10.4635 0.624684C10.4635 0.351385 10.1902 0 9.87789 0H4.88042C4.52904 0 4.25574 0.312342 4.25574 0.624684V5.58311C4.25574 5.97354 4.52904 6.24684 4.88042 6.24684Z"
            fill="currentColor"
        />
    )
})

export const MissedCallIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MissedCallIconRegular',
    viewBox: '0 0 26 20',
    path: (
        <path
            d="M24.9057 13.4375C21.7417 10.2344 17.5229 8.4375 12.9917 8.4375C8.4604 8.4375 4.24165 10.1953 1.07759 13.3984C0.413526 14.1016 0.335401 15.1172 0.765088 15.9375L2.67915 19.0234C3.18696 19.8438 4.24165 20.1953 5.17915 19.8438L8.96821 18.2812C9.82759 17.9688 10.3354 17.1094 10.2573 16.25L10.101 14.5703C12.0151 14.0234 14.0073 14.0234 15.9213 14.5703L15.7651 16.2109C15.6479 17.1094 16.1948 18.0078 17.0151 18.3203L20.8432 19.8828C21.0776 19.9609 21.312 20 21.5854 20C22.2495 20 22.9135 19.6875 23.3042 19.0625L25.2182 15.9766C25.687 15.1562 25.5698 14.1406 24.9057 13.4375ZM23.6167 14.9609L21.7026 18.0469C21.6635 18.0859 21.5854 18.125 21.5463 18.0859L17.7182 16.5625C17.6401 16.5234 17.601 16.4453 17.6401 16.3672L17.8745 13.9453C17.9135 13.5156 17.6401 13.125 17.2495 12.9688C14.5151 12.0312 11.5073 12.0312 8.7729 12.9688C8.38228 13.125 8.10884 13.5156 8.1479 13.9453L8.38228 16.3672C8.42134 16.4453 8.38228 16.5234 8.30415 16.5625L4.51509 18.0859C4.43696 18.125 4.35884 18.0859 4.31978 18.0469L2.40571 14.9609C2.36665 14.9219 2.36665 14.8047 2.44478 14.7656C5.25728 11.9141 9.00728 10.3125 12.9917 10.3125C16.976 10.3125 20.726 11.875 23.5385 14.7266C23.6557 14.8047 23.6557 14.9219 23.6167 14.9609ZM5.21821 6.25C5.72603 6.25 6.11665 5.85938 6.11665 5.3125V3.20312L8.26509 5.39062C9.47603 6.52344 11.0776 7.1875 12.7182 7.1875C14.3198 7.1875 15.9213 6.52344 17.0932 5.39062L20.5698 1.91406C20.9213 1.5625 20.9213 0.976562 20.5698 0.625C20.1792 0.234375 19.5932 0.234375 19.2417 0.625L15.7651 4.0625C14.1635 5.66406 11.2338 5.66406 9.63228 4.0625L7.44478 1.875H9.55415C10.062 1.875 10.4526 1.48438 10.4526 0.9375C10.4526 0.429688 10.101 0 9.59321 0H5.21821C4.67134 0 4.28071 0.429688 4.28071 0.9375V5.3125C4.28071 5.85938 4.67134 6.25 5.21821 6.25Z"
            fill="currentColor"
        />
    )
})

export const MissedCallIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MissedCallIconSolid',
    viewBox: '0 0 26 20',
    path: (
        <path
            d="M25.1591 14.7116C18.4472 7.9997 7.55978 7.9997 0.847838 14.7116C0.457608 15.1019 0.418585 15.6872 0.691746 16.1555L2.75996 19.4334C3.03312 19.9407 3.65749 20.1358 4.20381 19.9017L8.30122 18.2627C8.76949 18.0676 9.08168 17.5993 9.04265 17.053L8.76949 14.4775C11.5011 13.541 14.5059 13.541 17.1984 14.4775L16.9643 17.053C16.8863 17.5993 17.1984 18.0676 17.6667 18.2627L21.8032 19.9017C22.3105 20.1358 22.8958 19.9407 23.208 19.4334L25.2762 16.1555C25.5884 15.6872 25.5494 15.1019 25.1591 14.7116ZM4.90622 6.16562C5.3745 6.36074 5.80375 6.16562 6.03789 5.93149L7.2476 4.7608L9.31581 6.86804C10.1353 7.68752 11.1889 8.27286 12.3596 8.42895C13.9205 8.62407 15.4424 8.07775 16.5351 6.98511L21.569 1.95115C21.8032 1.71701 21.8032 1.32678 21.569 1.09264L20.6715 0.195115C20.4374 -0.0390229 20.0471 -0.0390229 19.813 0.195115L14.779 5.22907C13.8035 6.20465 12.2035 6.20465 11.2279 5.22907L9.00363 3.00477L10.2133 1.79506C10.4865 1.48287 10.6036 1.05362 10.4085 0.66339C10.2524 0.273161 9.90116 0 9.47191 0H5.02329C4.59404 0 4.28186 0.351206 4.28186 0.780459V5.19005C4.28186 5.65833 4.51599 6.00953 4.90622 6.16562Z"
            fill="currentColor"
        />
    )
})
