import {
    Channels,
    RecordingMode,
    ServicePackFeatureOrBoltOn,
    ServicePackFeatures
} from '@missionlabs/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Call } from './callSlice'

export type AuthenticatedUser = {
    token: string
    userID: string
    clientID: string
    username?: string
    extension?: string
    installationID?: string
    destinationID?: string
    numberToCall?: string
    recordingMode?: RecordingMode
    // role: UserRole
    // loginType: LoginType
    isGuest?: boolean
    notifications?: UserNotifications
    updateChannel?: string
}

export type UserNotifications = {
    channels?: Channels
    events?: string[]
    mutedChatChannels?: string[]
}

export interface UserState {
    user: AuthenticatedUser | undefined
    callHistory: Call[]
    features: Array<string | ServicePackFeatureOrBoltOn>
}

const initialState: UserState = {
    user: undefined,
    callHistory: [],
    features: []
}

type UpdatedCall = Omit<Call, 'callTraceID' | 'webSocket'>

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<AuthenticatedUser | undefined>) => {
            if (action.payload) {
                state.user = action.payload
            } else {
                state.user = undefined
            }
        },
        setToken: (state, action: PayloadAction<string>) => {
            if (state.user) state.user.token = action.payload
        },
        addNumberToCall: (state, action: PayloadAction<string>) => {
            if (state.user) {
                state.user.numberToCall = action.payload
            }
        },
        updateRecordingMode: (state, action: PayloadAction<RecordingMode>) => {
            if (state.user) {
                state.user.recordingMode = action.payload
            }
        },
        addToCallHistory: (state, action: PayloadAction<Call>) => {
            state.callHistory.push(action.payload)
        },
        updateCallHistory(
            state,
            action: PayloadAction<{ updatedCall: UpdatedCall; callTraceID: string }>
        ) {
            const { updatedCall, callTraceID } = action.payload
            state.callHistory = state.callHistory.map(call =>
                call.callTraceID === callTraceID
                    ? {
                          ...call,
                          ...updatedCall,
                          startTimestamp: call.startTimestamp ?? updatedCall.startTimestamp
                      }
                    : call
            )
        },
        updateAutoLogin: (state, action: PayloadAction<boolean>) => {
            if (!state.user) return

            if (action.payload) {
                const session = {
                    token: state.user.token,
                    clientID: state.user.clientID,
                    userID: state.user.userID,
                    username: state.user.username
                }
                localStorage.setItem('auth_session', JSON.stringify(session))
            } else {
                localStorage.removeItem('auth_session')
            }
            //_updatePreferences(state, { autoLogin: action.payload })
        },
        setUserFeatures: (state, action: PayloadAction<Array<string>>) => {
            //state.features = [...action.payload, ServicePackFeatures.MEETINGS]
            state.features = action.payload
        },
        updateNotifications: (state, action: PayloadAction<UserNotifications>) => {
            if (!state.user) return
            state.user.notifications = action.payload
        }
    }
})

export type UserSliceRootState = {
    userSlice: ReturnType<typeof userSlice.getInitialState>
}

export const selectAuthenticatedUser = (state: UserSliceRootState) => state.userSlice.user
export const selectCallHistory = (state: UserSliceRootState) => state.userSlice.callHistory
export const selectLastCall = (state: UserSliceRootState) => {
    // copy callHistory instead of mutating directly
    const callHistory = [...state.userSlice.callHistory]
    return callHistory.pop()
}
export const selectIsVideoCallEnabled = (state: UserSliceRootState) => {
    return haveAnyOfTheseFeatures(state, [ServicePackFeatures.MEETINGS, ServicePackFeatures.VIDEO])
}

export const haveAnyOfTheseFeatures = (
    state: UserSliceRootState,
    features: Array<string | ServicePackFeatureOrBoltOn>
) => {
    return state.userSlice.features.some(f => features.includes(f))
}

export const {
    setUser,
    setToken,
    addNumberToCall,
    updateAutoLogin,
    updateRecordingMode,
    addToCallHistory,
    updateCallHistory,
    setUserFeatures,
    updateNotifications
} = userSlice.actions
