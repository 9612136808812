import { HStack, VStack } from '@chakra-ui/react'
import {
    Body,
    Button,
    Checkbox,
    FormProvider,
    Input,
    useYupForm,
    ValidatedInput,
    yup
} from '@missionlabs/react'
import { DestinationTypes } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetClientQuery } from 'shared/store'

export interface AddDeviceNumberFields {
    type: DestinationTypes.number
    announce: boolean
    flipCLI: boolean
    numberE164: string
}

export const defaultValues: AddDeviceNumberFields = {
    type: DestinationTypes.number,
    announce: false,
    flipCLI: false,
    numberE164: ''
}

export interface AddDeviceNumberFormProps {
    onClose: () => void
    onSubmit: (data: AddDeviceNumberFields) => Promise<void>
}

export const AddDeviceNumberForm: FC<AddDeviceNumberFormProps> = ({ onClose, onSubmit }) => {
    const { t } = useTranslation()
    const { formatToNumberE164 } = useFormatToNumberE164()

    const { data: client } = useGetClientQuery()

    const addDeviceNumberSchema = yup.object({
        announce: yup.boolean(),
        flipCLI: yup.boolean(),
        numberE164: yup
            .string()
            .phone(client?.locale ?? 'GB')
            .required()
    })

    const methods = useYupForm<AddDeviceNumberFields>(addDeviceNumberSchema, {
        defaultValues: defaultValues
    })
    const {
        formState: { isDirty, isSubmitting }
    } = methods

    function handleSubmit(data: AddDeviceNumberFields) {
        return onSubmit({ ...data, numberE164: formatToNumberE164(data.numberE164) })
    }

    return (
        <FormProvider {...methods}>
            <VStack
                as="form"
                h="full"
                spacing="32px"
                align="stretch"
                justify="space-between"
                onSubmit={methods.handleSubmit(handleSubmit)}
                data-testid="add-device-number-form"
            >
                <VStack p="24px" spacing="10px" align="inherit">
                    <ValidatedInput name="numberE164">
                        <Input placeholder={t('devices.add.number.numberE164_placeholder')} />
                    </ValidatedInput>
                    <Body>{t('devices.add.number.announce_text')}</Body>
                    <div>
                        <ValidatedInput name="announce">
                            <Checkbox>{t('devices.add.number.announce_checkbox')}</Checkbox>
                        </ValidatedInput>
                        <ValidatedInput name="flipCLI">
                            <Checkbox>{t('devices.add.number.flipCLI_checkbox')}</Checkbox>
                        </ValidatedInput>
                    </div>
                </VStack>

                <HStack px="24px" pb="24px" spacing="16px" align="inherit">
                    <Button
                        w="full"
                        size="lg"
                        variant="secondary"
                        isDisabled={isSubmitting}
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        type="submit"
                        w="full"
                        size="lg"
                        variant="creationary"
                        isDisabled={!isDirty}
                        isLoading={isSubmitting}
                    >
                        {t('devices.add.number.submit')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}
