import { useSearchParams } from 'react-router-dom'

import { ContactTypeFilterType } from '../ContactTypeFilters'

export const useContactsSearchParams = () => {
    const [params, _updateParams] = useSearchParams()

    const type = (params.get('type') ?? 'all') as ContactTypeFilterType
    const searchTerm = params.get('search') ?? undefined
    const sortBy = params.get('sort') ?? undefined

    const hasSearchedContacts = !!searchTerm || type !== 'all'

    const updateParams = (type: string, value: string) => {
        params.set(type, value)
        _updateParams(params)
    }

    return { type, searchTerm, sortBy, hasSearchedContacts, updateParams }
}
