import { OutOfHoursForwardType, UserCallRoute, Voicemail } from '@missionlabs/types'
import { useSettingsContext } from 'features/settings/components/settingsContext'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { OutOfHours } from 'shared/components/OutOfHours/OutOfHours'

const getVoicemail = (
    route: UserCallRoute,
    outOfHours?: OutOfHoursForwardType,
    voicemail?: Voicemail
) => {
    return {
        ...route.voicemail, // Inherit any missing props set on voicemail like CustomGreetingURL
        ...route.outOfHoursVoicemail,
        ...voicemail,
        status: outOfHours === 'voicemail' ? 'active' : 'inactive',
        transcribe: true
    }
}

export const OutOfHoursSetting: FC = () => {
    const { t } = useTranslation()
    const { userCallRoute, onUpdateUserCallRoute, onUpdateNotAvailable } = useSettingsContext()

    const onUpdate = (payload: Partial<UserCallRoute>) => {
        if (!userCallRoute) return

        return onUpdateUserCallRoute?.({
            callTimeout: payload.callTimeout,
            outOfHours: payload.outOfHours,
            outOfHoursRedirect: payload.outOfHoursRedirect,
            outOfHoursRedirectInternal: payload.outOfHoursRedirectInternal,
            outOfHoursVoicemail: getVoicemail(
                userCallRoute,
                payload.outOfHours,
                payload.outOfHoursVoicemail
            )
        })
    }

    if (!userCallRoute) return null

    return (
        <SettingsRow label={t('calls.outOfHours')}>
            <OutOfHours
                callRoute={userCallRoute}
                notAvailable={userCallRoute?.notAvailable}
                onUpdateNotAvailable={onUpdateNotAvailable}
                onUpdate={onUpdate}
            />
        </SettingsRow>
    )
}
