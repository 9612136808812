import { selectAuthenticatedUser, selectInData, useSelector } from '@missionlabs/api'
import { SelectOption } from '@missionlabs/react'
import { NumbersPhoneNumber, NumbersResponse } from '@missionlabs/types'
import { useMemo } from 'react'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetNumbersForUserQuery } from 'shared/store'

export interface GetMyNumbers {
    mobileOnly?: boolean
    preferDefault?: boolean
}

export const useGetMyNumbers = ({ mobileOnly, preferDefault }: GetMyNumbers = {}) => {
    const { formatToLocalNumber, isUKMobileNumber } = useFormatToNumberE164()
    const user = useSelector(selectAuthenticatedUser)

    const selectNumberOptions = useMemo(
        () =>
            selectInData<NumbersResponse, SelectOption[]>(data => {
                if (!data) return []

                const predicate = (item: NumbersPhoneNumber, team?: string) => ({
                    number: item.numberE164,
                    isDefault: item.isDefault,
                    team
                })

                const { phoneNumbers = [], teams = [] } = data

                const userNumbers = phoneNumbers.map(num => predicate(num))
                const teamNumbers = teams
                    .filter(t => t.phoneNumbers && !!t.phoneNumbers.length)
                    .flatMap(team => team.phoneNumbers!.map(num => predicate(num, team.name)))

                const combined = [...userNumbers, ...teamNumbers].filter(({ number }) => {
                    return mobileOnly ? isUKMobileNumber(number) : true
                })

                if (preferDefault) {
                    combined.sort((n1, n2) => (n1.isDefault && !n2.isDefault ? -1 : 1))
                }

                return combined.map<SelectOption>(({ number, team }) => {
                    const formattedNumber = formatToLocalNumber(number)
                    const label = team ? `${formattedNumber} - ${team}` : formattedNumber
                    return { label, value: number }
                })
            }),
        [formatToLocalNumber, isUKMobileNumber, mobileOnly, preferDefault]
    )

    const { numbers } = useGetNumbersForUserQuery(user?.userID ?? '', {
        skip: !user,
        selectFromResult: result => ({ ...result, numbers: selectNumberOptions(result) })
    })

    return numbers ?? []
}
