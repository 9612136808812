import { CSSProperties, FC } from 'react'

import { VirtualisedListItem } from '../SearchChatView'
import { isSection } from './ChatList'
import { ChatListSection } from './ChatListSection'
import { ChatMessageListRow } from './ChatMessageListRow'
interface ChatMessageListRendererProps {
    data: VirtualisedListItem[]
    index: number
    style: CSSProperties
}

export const ChatMessageListRenderer: FC<ChatMessageListRendererProps> = ({
    data,
    index,
    style
}) => {
    const item = data[index]

    if (isSection(item)) {
        return (
            <div style={style}>
                <ChatListSection item={item} />
            </div>
        )
    }

    return (
        <div style={style}>
            <ChatMessageListRow item={item} />
        </div>
    )
}
