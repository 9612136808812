import { ChatMessage } from '@missionlabs/types'
import { FC, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { DocumentTitle } from 'shared/components/DocumentTitle'
import { MainLayout } from 'shared/components/MainLayout'
import { useGetMessagesQuery, useGetSessionQuery } from 'shared/store'

import { ChatSidebar } from './ChatSidebar'
import { ChatContext } from './context/chatContext'

export interface ChatDashboardProps {}

export const ChatDashboard: FC<ChatDashboardProps> = () => {
    const { data: sessionData } = useGetSessionQuery()
    const { data: _messages } = useGetMessagesQuery(
        { url: sessionData?.url || '' },
        { skip: !sessionData?.url }
    )

    const [forwardMessage, setForwardMessage] = useState<ChatMessage | null>(null)

    const [searchTerm, setSearchTerm] = useState('')
    const [searchOpen, setSearchOpen] = useState(false)

    const value = useMemo(() => {
        return {
            messageForwarding: {
                forwardMessage,
                setForwardMessage
            },
            search: {
                searchTerm,
                setSearchTerm,
                searchOpen,
                setSearchOpen
            },
            enabledFeatures: { threads: true, markUnread: true },
            isGuest: false
        }
    }, [forwardMessage, searchTerm, searchOpen])

    return (
        <>
            <ChatContext.Provider value={value}>
                <DocumentTitle breadcrumbs={['Chat']} />
                <MainLayout left={<ChatSidebar />} right={<Outlet />} />
            </ChatContext.Provider>
        </>
    )
}
