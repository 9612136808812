import { SimpleGrid, useColorMode } from '@chakra-ui/react'
import { Body, Dropdown, SelectOption, Slider } from '@missionlabs/react'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { FC } from 'react'

interface SoundDeviceSettingProps {
    label: string
    dropdown: {
        label: string
        options: SelectOption[]
        value: string | undefined
        onChange: (value: string) => void
    }
    slider?: {
        label: string
        value: number | undefined
        onChange: (value: number) => void
        onChangeEnd: (value: number) => void
    }
}

export const SoundDeviceSetting: FC<SoundDeviceSettingProps> = ({ label, dropdown, slider }) => {
    const { colorMode } = useColorMode()
    return (
        <SettingsRow label={label}>
            <SimpleGrid columns={slider ? 2 : 1} gap="8px 24px">
                <Body size="sm" variant="bold" sx={{ color: `${colorMode}.tones.navy` }}>
                    {dropdown.label}
                </Body>
                {slider && (
                    <Body size="sm" variant="bold" sx={{ color: `${colorMode}.tones.navy` }}>
                        {slider.label}
                    </Body>
                )}
                <Dropdown
                    width="full"
                    options={dropdown.options}
                    value={dropdown.value}
                    onChange={dropdown.onChange}
                />
                {slider && (
                    <Slider
                        variant="volume-control"
                        min={0}
                        step={0.01}
                        max={1}
                        aria-label={slider.label}
                        value={slider.value}
                        onChange={slider.onChange}
                        onChangeEnd={slider.onChangeEnd}
                        size="lg"
                    />
                )}
            </SimpleGrid>
        </SettingsRow>
    )
}
