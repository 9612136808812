import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { useMemo } from 'react'
import { useGetSubscriptionGroupsQuery, useGetUserQuery } from 'shared/store'

export function useGetNotificationsData() {
    const auth = useSelector(selectAuthenticatedUser)
    const userID = auth?.userID ?? ''
    const { data: user } = useGetUserQuery(userID)
    const { data: subscriptionGroups = [], isLoading: subscriptionGroupsLoading } =
        useGetSubscriptionGroupsQuery(userID, {
            skip: !user
        })

    const channels = useMemo(() => {
        const {
            desktopApp = false,
            android = false,
            email = false,
            web = false,
            sms = false,
            ios = false
        } = user?.notifications?.channels || {}
        return { desktopApp, android, email, web, sms, ios }
    }, [user?.notifications?.channels])

    const events = useMemo(() => {
        return user?.notifications?.events ?? []
    }, [user?.notifications?.events])

    return { channels, events, subscriptionGroups, subscriptionGroupsLoading }
}
