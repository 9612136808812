import {
    Box,
    Divider,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerOverlay,
    HStack,
    useDisclosure,
    VStack
} from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    Body,
    Button,
    DrawerHeader,
    FormProvider,
    Input,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useToast,
    useYupForm,
    ValidatedInput
} from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FeatureComponent, Features } from 'shared/components/FeatureComponent'
import { AdminAnonymousCallSetting } from 'shared/components/settings/AdminAnonymousCallSetting'
import { useCreateIVRMenuMutation, useGetUsersQuery } from 'shared/store'
import { buildNotAvailable } from 'shared/utils/notAvailable'

import { AddEditTeamMembers } from '../../Teams/AddEditTeam/AddEditTeamMembers'
import { IVRMenuOptions } from '../IVRMenuOptions'
import { defaultMenuValues, MenuFormFields, menuSchema } from '../menu.schema'
import { MenuOutOfHours } from '../MenuOutOfHours'
import { UserExtensions } from '../UserExtensions'
import { WaitMusic } from '../WaitMusic'
import { WelcomeGreeting } from '../WelcomeGreeting'

export interface AddMenuDrawerProps {}

const noop = () => {}

export const AddMenuDrawer: FC<AddMenuDrawerProps> = () => {
    const navigate = useNavigate()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const { t } = useTranslation()
    const { toast } = useToast()

    const { data: users = [] } = useGetUsersQuery()

    const methods = useYupForm<MenuFormFields>(menuSchema, {
        shouldFocusError: true,
        defaultValues: defaultMenuValues
    })

    const {
        formState: { isDirty, isSubmitting },
        watch,
        setValue
    } = methods

    const user = useSelector(selectAuthenticatedUser)

    const [createMenu, { isLoading }] = useCreateIVRMenuMutation()

    function handleTransition() {
        navigate('/admin/menus')
    }
    const handleSubmit = async (data: MenuFormFields) => {
        if (!user) return
        try {
            await createMenu({
                ...data,
                notAvailable: buildNotAvailable({ ...data?.notAvailable }),
                phoneNumbers: [],
                clientID: user.clientID,
                callRoute: {
                    callSequence: 'group',
                    inHours: 'ring_through',
                    inHoursUnanswered: 'ring_out',
                    ...data.callRoute
                },
                outOfHoursVoicemail: {
                    greeting: 'default',
                    transcribe: true,
                    greetingNoMessage: 'default',
                    ...data.outOfHoursVoicemail
                }
            }).unwrap()
            toast({ status: 'success', title: `${data.name} created` })
            return onClose()
        } catch (e) {
            toast({
                title: t('menu.menuError'),
                status: 'error'
            })
        }
    }

    return (
        <Drawer
            size="lg"
            isOpen={isOpen}
            onClose={!isLoading ? onClose : noop}
            onCloseComplete={handleTransition}
        >
            <DrawerOverlay />
            <DrawerContent>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleSubmit)}>
                        <DrawerHeader>{t('menus.addMenu')}</DrawerHeader>
                        <DrawerBody>
                            <Box maxH="calc(100vh - 159px)">
                                <Tabs size="md" h="full" isLazy isFitted>
                                    <TabList mx="24px">
                                        <Tab>{t('Details')}</Tab>
                                        <Tab>
                                            {t('Users')} ({watch('callRoute.teamMembers').length})
                                        </Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            <VStack
                                                p={6}
                                                align="stretch"
                                                spacing={6}
                                                id="edit-menu-drawer"
                                            >
                                                <VStack align="flex-start">
                                                    <Body id="menu-name">{t('Name')}</Body>
                                                    <ValidatedInput
                                                        name="name"
                                                        aria-labelledby="menu-name"
                                                    >
                                                        <Input autoComplete="off" />
                                                    </ValidatedInput>
                                                </VStack>

                                                <Divider />
                                                <IVRMenuOptions
                                                    extensions={watch('IVR.extensions')}
                                                    onUpdateExtensions={extensions => {
                                                        setValue('IVR.extensions', extensions, {
                                                            shouldDirty: true
                                                        })
                                                    }}
                                                />

                                                <FeatureComponent
                                                    feature={Features.user_extensions}
                                                >
                                                    <Divider />
                                                    <UserExtensions
                                                        extensions={watch('IVR.extensions')}
                                                        onUpdateExtensions={extensions => {
                                                            setValue('IVR.extensions', extensions, {
                                                                shouldDirty: true
                                                            })
                                                        }}
                                                    />
                                                </FeatureComponent>

                                                <Divider />
                                                <WelcomeGreeting
                                                    greetingUrl={watch('IVR.greeting')}
                                                    onChangeGreetingUrl={url => {
                                                        setValue('IVR.greeting', url, {
                                                            shouldDirty: true
                                                        })
                                                    }}
                                                />

                                                <Divider />
                                                <WaitMusic
                                                    enabled={watch('waitMusic')}
                                                    waitMusicUrl={watch('customWaitMusicURL')}
                                                    onToggle={isChecked => {
                                                        setValue('waitMusic', isChecked, {
                                                            shouldDirty: true
                                                        })
                                                    }}
                                                    onChangeWaitMusicUrl={url => {
                                                        setValue('customWaitMusicURL', url, {
                                                            shouldDirty: true
                                                        })
                                                    }}
                                                />

                                                <Divider />
                                                <MenuOutOfHours watch={watch} setValue={setValue} />

                                                <Divider />
                                                <AdminAnonymousCallSetting
                                                    anonymousCallSetting={watch(
                                                        'blockAnonymousCalls'
                                                    )}
                                                    onChange={setValue}
                                                />
                                            </VStack>
                                        </TabPanel>
                                        <TabPanel>
                                            <Body p="24px" size="sm">
                                                {t('menus.notificationUsers')}
                                            </Body>
                                            <AddEditTeamMembers users={users} pt="0" />
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Box>
                        </DrawerBody>
                        <DrawerFooter p={6}>
                            <HStack w="full" spacing={4}>
                                <Button variant="secondary" w="full" onClick={onClose}>
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    variant="creationary"
                                    w="full"
                                    isDisabled={!isDirty}
                                    isLoading={isSubmitting}
                                >
                                    {t('Save')}
                                </Button>
                            </HStack>
                        </DrawerFooter>
                    </form>
                </FormProvider>
            </DrawerContent>
        </Drawer>
    )
}
