import { yup } from '@missionlabs/react'
import { CreateReportParams, CreateReportVisibility } from '@missionlabs/types'
import { differenceInCalendarDays } from 'date-fns'

export type ReportFormType = 'call' | 'sms' | 'itemized_bill'

export type ReportFormFields = {
    name: string
    params: CreateReportParams
    visibility: CreateReportVisibility
    type: ReportFormType
}

export const defaultValues: ReportFormFields = {
    name: '',
    params: {
        fromDate: new Date().getTime(),
        toDate: new Date().getTime(),
        reportMenus: [],
        reportNumbers: [],
        reportTeams: [],
        reportPods: [],
        reportUsers: []
    },
    visibility: {
        showAdminRecordingURL: false,
        showDuration: false,
        showMenuName: false,
        showTeamName: false,
        showUserName: false
    },
    type: 'call'
}

export enum ParamModels {
    MENU = 'params.reportMenus',
    NUMBER = 'params.reportNumbers',
    TEAM = 'params.reportTeams',
    USER = 'params.reportUsers',
    POD = 'params.reportPods'
}

export type ParamFormKey = `${ParamModels}`

export enum VisibilityModels {
    URL = 'visibility.showAdminRecordingURL',
    DURATION = 'visibility.showDuration',
    MENU = 'visibility.showMenuName',
    USER = 'visibility.showUserName',
    POD = 'visibility.showPod'
}

export type VisibilityFormKey = `${VisibilityModels}`

export const callsParamSchema = yup.object().shape({
    fromDate: yup.number(),
    toDate: yup
        .number()
        .test(
            'required',
            { key: 'reports.dateRequired' },
            (value, context) => !!value && !!context.parent.fromDate
        )
        // makes sure the dates aren't more than 30 days apart
        .test(
            'dates',
            { key: 'reports.max30Days' },
            (value, context) =>
                !!value && differenceInCalendarDays(value, context.parent.fromDate) <= 30
        ),
    reportMenus: yup.array(yup.string()),
    reportNumbers: yup.array(yup.string()),
    reportTeams: yup.array(yup.string()),
    reportUsers: yup.array(yup.string())
})

export const callsVisibilitySchema = yup.object().shape({
    showAdminRecordingURL: yup.boolean(),
    showDuration: yup.boolean(),
    showMenuName: yup.boolean(),
    showUserName: yup.boolean(),
    showTeamName: yup.boolean()
})

export const callsDefaultValues = {
    params: {
        fromDate: new Date().getTime(),
        toDate: new Date().getTime(),
        reportMenus: [],
        reportNumbers: [],
        reportTeams: [],
        reportPods: [],
        reportUsers: []
    },
    visibility: {
        showAdminRecordingURL: true,
        showCreated: true,
        showFromNumber: true,
        showToNumber: true,
        showDuration: false,
        showMenuName: false,
        showTeamName: false,
        showUserName: false
    }
}

export const smsParamSchema = yup.object().shape({
    fromDate: yup.number(),
    toDate: yup
        .number()
        .test(
            'required',
            { key: 'reports.dateRequired' },
            (value, context) => !!value && !!context.parent.fromDate
        )
        // makes sure the dates aren't more than 30 days apart
        .test(
            'dates',
            { key: 'reports.max30Days' },
            (value, context) =>
                !!value && differenceInCalendarDays(value, context.parent.fromDate) <= 30
        ),
    reportMenus: yup.array(yup.string()),
    reportNumbers: yup.array(yup.string()),
    reportTeams: yup.array(yup.string()),
    reportUsers: yup.array(yup.string())
})

export const smsVisibilitySchema = yup.object().shape({
    showFromNumber: yup.boolean(),
    showToNumber: yup.boolean(),
    showSmsTime: yup.boolean(),
    showAdminSmsData: yup.boolean(),
    showMenuName: yup.boolean(),
    showUserName: yup.boolean(),
    showTeamName: yup.boolean()
})
export const smsDefaultValues = {
    params: {
        fromDate: new Date().getTime(),
        toDate: new Date().getTime(),
        reportMenus: [],
        reportNumbers: [],
        reportTeams: [],
        reportPods: [],
        reportUsers: []
    },
    visibility: {
        showFromNumber: true,
        showToNumber: true,
        showSmsTime: true,
        showAdminSmsData: false,
        showMenuName: false,
        showTeamName: false,
        showUserName: false
    }
}

export const itemisedBillingParamSchema = yup.object().shape({
    fromDate: yup.number(),
    toDate: yup
        .number()
        .test(
            'required',
            { key: 'reports.dateRequired' },
            (value, context) => !!value && !!context.parent.fromDate
        )
        // makes sure the dates aren't more than 30 days apart
        .test(
            'dates',
            { key: 'reports.max30Days' },
            (value, context) =>
                !!value && differenceInCalendarDays(value, context.parent.fromDate) <= 30
        ),
    numberDisplay: yup.string()
})

export const itemisedBillingVisibilitySchema = yup.object().shape({})

export const itemisedBillingDefaultValues = {
    params: {
        fromDate: new Date().getTime(),
        toDate: new Date().getTime(),
        numberDisplay: 'partial'
    },
    visibility: {}
}

//62 days to cover 2 months
export const MAX_DAYS = 62
