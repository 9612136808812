import { CheckboxGroup, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body, IconButton, Input } from '@missionlabs/react'
import { CloseIcon, RefreshIcon } from '@missionlabs/react/circleloop'
import { DateFilterParam } from '@missionlabs/types'
import { endOfToday, startOfDay } from 'date-fns'
import * as React from 'react'
import { ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { UseGetUserActivitiesOptions } from 'shared/hooks/useGetUserActivities'

import {
    CallsFilterOptions,
    DateFilterOptions,
    DirectionFilterOptions,
    MessagesFilterOptions,
    StatusFilterOptions
} from '../../components/FilterOptions'
import { FilterSection } from '../../components/FilterSection'
import { formatDate, getFrom, getTo } from '../../utils/filters'
import { useActivityFilters } from '../hooks/useActivityFeedFilters'

export const ActivityFeedFilterMenu = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const {
        filters,
        dispatch,
        selectDateFilter,
        dateFilterOption,
        resetFilters,
        hasAppliedFilters,
        filterMenu
    } = useActivityFilters()

    const fromValue = filters[DateFilterParam.FROM] as string
    const toValue = filters[DateFilterParam.TO] as string

    const handleFromChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const from = getFrom(e.currentTarget.value)
            selectDateFilter({ selectedOption: 'custom', from })
        },
        [selectDateFilter]
    )

    const handleToChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const to = getTo(e.currentTarget.value)
            selectDateFilter({ selectedOption: 'custom', to })
        },
        [selectDateFilter]
    )

    const setFilters: any = (newFilters: Partial<UseGetUserActivitiesOptions>) => {
        dispatch({ type: 'SET_FILTERS', payload: newFilters })
    }

    return (
        <VStack
            align="flex-start"
            px={6}
            borderBottom="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
        >
            <HStack
                justify="space-between"
                w="100%"
                py={3}
                borderBottom="1px solid"
                borderColor={`${colorMode}.tones.periwinkle`}
            >
                <Body size="md" variant="bold" color={`${colorMode}.tones.navy`}>
                    {t('activities.Filters')}
                </Body>
                <HStack>
                    {hasAppliedFilters && (
                        <IconButton
                            aria-label="Reset filters"
                            icon={<RefreshIcon boxSize="16px" />}
                            variant="transparent"
                            size="sm"
                            onClick={resetFilters}
                        />
                    )}
                    <IconButton
                        aria-label="Close filters"
                        icon={<CloseIcon boxSize="16px" />}
                        variant="transparent"
                        size="sm"
                        onClick={filterMenu.onClose}
                    />
                </HStack>
            </HStack>

            <HStack align="start" spacing="24px" w="100%" pt={4} pb={6}>
                <FilterSection label={t('Date')} mt={0} flex="1 0 0">
                    <CheckboxGroup
                        value={dateFilterOption ? [dateFilterOption] : []}
                        onChange={(values: string[]) => {
                            if (!values.length) {
                                selectDateFilter({ selectedOption: undefined })
                            } else {
                                // Date filter should only have one value,
                                // so take the most recent value from the end of the array
                                const lastOption = [...values].pop()
                                selectDateFilter({ selectedOption: lastOption as any })
                            }
                        }}
                    >
                        <DateFilterOptions />
                    </CheckboxGroup>

                    {dateFilterOption === 'custom' && (
                        <VStack mt="8px">
                            <Input
                                title={t('From')}
                                type="date"
                                value={formatDate(fromValue)}
                                onChange={handleFromChange}
                                max={
                                    toValue
                                        ? formatDate(startOfDay(new Date(toValue)))
                                        : formatDate(endOfToday())
                                }
                            />
                            <Input
                                title={t('To')}
                                type="date"
                                value={formatDate(toValue)}
                                onChange={handleToChange}
                                min={
                                    // Can't go any earlier than the "from" date if it exists
                                    fromValue
                                        ? formatDate(startOfDay(new Date(fromValue)))
                                        : undefined
                                }
                                max={formatDate(endOfToday())}
                            />
                        </VStack>
                    )}
                </FilterSection>

                <FilterSection label={t('Messages')} flex="1 0 0">
                    <CheckboxGroup
                        value={filters.type}
                        onChange={(values: string[]) => setFilters({ type: values })}
                    >
                        <MessagesFilterOptions />
                    </CheckboxGroup>
                </FilterSection>

                <FilterSection label={t('Calls')} flex="1 0 0">
                    <CheckboxGroup
                        value={filters.type}
                        onChange={(values: string[]) => {
                            // Voicemail type and outgoing direction are mutually exclusive
                            // so remove the outgoing direction if voicemail is selected
                            const directions = values.includes('voicemail')
                                ? filters.directions?.filter(dir => dir !== 'outgoing')
                                : filters.directions
                            setFilters({ type: values, directions })
                        }}
                    >
                        <CallsFilterOptions
                            disableVoicemail={filters.directions?.includes('outgoing')}
                        />
                    </CheckboxGroup>
                </FilterSection>

                <FilterSection label={t('Direction')} flex="1 0 0">
                    <CheckboxGroup
                        value={filters.directions}
                        onChange={(values: string[]) => {
                            // Voicemail type and outgoing direction are mutually exclusive
                            // so remove the voicemail type if outgoing is selected
                            const type = values.includes('outgoing')
                                ? filters.type?.filter(t => t !== 'voicemail')
                                : filters.type
                            setFilters({ directions: values, type })
                        }}
                    >
                        <DirectionFilterOptions
                            disabledOutbound={filters.type?.includes('voicemail')}
                        />
                    </CheckboxGroup>
                </FilterSection>

                <FilterSection label={t('Status')} flex="1 0 0">
                    <CheckboxGroup
                        value={filters.status}
                        onChange={values => setFilters({ status: values as string[] })}
                    >
                        <StatusFilterOptions />
                    </CheckboxGroup>
                </FilterSection>
            </HStack>
        </VStack>
    )
}
