import {
    AuthenticatedUser,
    BaseURL,
    liveServicesAuth,
    PreferencesState,
    selectAuthEnvironment,
    setUser,
    TelecomConfig,
    updatePreferences,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { createInstallationIdPayload, populateUrl } from '@missionlabs/utils'
import {
    useLazyGetServerDataQuery,
    useLazyGetUserQuery,
    usePostInstallationIdMutation
} from 'shared/store'

const appDisplayName = __NAME__ === 'cl-hybrid' ? 'CircleLoop' : 'PhoneLine+'

export const useSetUpDestination = () => {
    const dispatch = useDispatch()
    const [getUser] = useLazyGetUserQuery()
    const [getServerData] = useLazyGetServerDataQuery()
    const [postDestination] = usePostInstallationIdMutation()
    const { country, dataCentre, environment, product } = useSelector(selectAuthEnvironment)

    const setUpDestination = async (user: AuthenticatedUser | undefined) => {
        if (!user) return
        const { clientID, userID, token } = user
        const data = createInstallationIdPayload(userID, __NAME__, appDisplayName, __VERSION__)
        const destination = await postDestination({ clientID, userID, payload: data }).unwrap()
        const res = await getServerData().unwrap().catch(console.error)
        const fetchedUser = await getUser(userID).unwrap()

        const telecomConfig: TelecomConfig = {
            liveServicesConfig: {
                // TODO: Might want ZetaID to return this as part of its config response
                url: populateUrl(BaseURL.LS, { country, dataCentre, environment, product }),
                connectToHost: true
            },
            callControllerConfig: {
                // TODO: ZetaID returns this in the config response, we should probably take it from there
                turn: populateUrl(BaseURL.TURN, {
                    country,
                    dataCentre,
                    environment,
                    product
                }),
                turnConfig: res?.kvps.coturn,
                preferCodec: 'opus',
                forbiddenCodecs: ['PCMU', 'PCMA'],
                sounds: {
                    ringTone: new URL('../../assets/sounds/ringtone-1.mp3', import.meta.url).href,
                    ringTone2: new URL('../../assets/sounds/ringtone-2.mp3', import.meta.url).href,
                    callWaitingTone: new URL('../../assets/sounds/call_wait.mp3', import.meta.url)
                        .href,
                    dtmf0: new URL('../../assets/sounds/dtmf-0.wav', import.meta.url).href,
                    dtmf1: new URL('../../assets/sounds/dtmf-1.wav', import.meta.url).href,
                    dtmf2: new URL('../../assets/sounds/dtmf-2.wav', import.meta.url).href,
                    dtmf3: new URL('../../assets/sounds/dtmf-3.wav', import.meta.url).href,
                    dtmf4: new URL('../../assets/sounds/dtmf-4.wav', import.meta.url).href,
                    dtmf5: new URL('../../assets/sounds/dtmf-5.wav', import.meta.url).href,
                    dtmf6: new URL('../../assets/sounds/dtmf-6.wav', import.meta.url).href,
                    dtmf7: new URL('../../assets/sounds/dtmf-7.wav', import.meta.url).href,
                    dtmf8: new URL('../../assets/sounds/dtmf-8.wav', import.meta.url).href,
                    dtmf9: new URL('../../assets/sounds/dtmf-9.wav', import.meta.url).href,
                    dtmfStar: new URL('../../assets/sounds/dtmf-star.wav', import.meta.url).href,
                    dtmfHash: new URL('../../assets/sounds/dtmf-hash.wav', import.meta.url).href,
                    videoRingTone: new URL(
                        '../../assets/sounds/video-ringtone.mp3',
                        import.meta.url
                    ).href
                }
            }
        }

        let userPreferences: PreferencesState = {}
        const preferences = localStorage.getItem(`userPreferencesID=${userID}`)
        if (preferences) {
            userPreferences = {
                ...JSON.parse(preferences),
                autoAnswer: destination.autoAnswer,
                autoAnswerDuration: destination.autoAnswerDuration,
                distinctiveRingExternal: fetchedUser.distinctiveRingExternal,
                userID
            }
        } else {
            userPreferences = {
                autoAnswer: destination.autoAnswer,
                autoAnswerDuration: destination.autoAnswerDuration,
                audioVolume: 1,
                ringingVolume: 1,
                distinctiveRingExternal: fetchedUser.distinctiveRingExternal,
                noiseCancellation: true,
                videoBackgroundFilter: 'none',
                videoNoiseSuppression: false,
                userID
            }
        }
        dispatch(
            setUser({
                ...user,
                destinationID: destination.ID,
                extension: fetchedUser.extension,
                updateChannel: fetchedUser.updateChannel
            })
        )
        dispatch(liveServicesAuth({ token, destinationID: destination.ID, config: telecomConfig }))
        dispatch(updatePreferences(userPreferences))
    }

    return setUpDestination
}
