import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay
} from '@missionlabs/react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDeleteChatGroupMutation, useGetChatGroupQuery } from 'shared/store'

interface DeleteChatGroupDialogProps {
    groupID: string
    isOpen: boolean
    onClose?: () => void
}

export const DeleteChatGroupDialog = ({
    groupID,
    isOpen,
    onClose = () => {}
}: DeleteChatGroupDialogProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const user = useSelector(selectAuthenticatedUser)

    const [deleteGroup, { isLoading }] = useDeleteChatGroupMutation()
    const { data: group } = useGetChatGroupQuery(groupID)

    const isOwner = group?.ownerID === user?.userID

    const ref = useRef<HTMLButtonElement>(null)
    const onDeleteGroup = async () => {
        if (!groupID || !user || !isOwner) return
        await deleteGroup({ groupID })
        navigate('/chat')
    }

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>{t('chatGroups.delete.header')}</DialogHeader>
                <DialogBody gap="12px">{t('chatGroups.delete.body')}</DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('chatGroups.delete.confirm'),
                        isDestructive: true,
                        onClick: () => {
                            onDeleteGroup()
                        }
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
