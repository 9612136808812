import { Center, useColorMode } from '@chakra-ui/react'
import { ContactDetail, ListItem } from '@missionlabs/react'
import { CheckCircleIconSolid } from '@missionlabs/react/zeta'
import { DirectoryEntry, HuntGroup, IVRMenu, UserStatus } from '@missionlabs/types'
import { isTeamOrMenu } from '@missionlabs/utils'
import { FC } from 'react'
import { useGetPresence, useGetPresenceID } from 'shared/hooks/useGetPresence'

export interface ContactsRowProps {
    data: DirectoryEntry | HuntGroup | IVRMenu
    isActive?: boolean
    renderTick?: boolean
    onClick: (id: (DirectoryEntry | HuntGroup | IVRMenu)['ID']) => void
}

export const ContactsRow: FC<ContactsRowProps> = ({
    data,
    isActive = false,
    renderTick = false,
    onClick
}) => {
    const { colorMode } = useColorMode()

    const presenceID = useGetPresenceID(data as DirectoryEntry)

    const { status } = useGetPresence(presenceID)

    return (
        <ListItem
            variant="interactive"
            sx={{ minHeight: '79px' }}
            data-active={isActive || undefined}
            onClick={() => onClick(data.ID)}
        >
            <ContactDetail
                name={isTeamOrMenu(data) ? data.name : data.fullName}
                subText={isTeamOrMenu(data) ? '1' : data.companyName}
                status={status as { status: UserStatus | undefined; label: string | undefined }}
                src={isTeamOrMenu(data) ? undefined : data.photoURL}
                team={isTeamOrMenu(data) || data?.source === 'teamprofile'}
            />
            {isActive && renderTick && (
                <Center boxSize="32px">
                    <CheckCircleIconSolid color={`${colorMode}.alerts.aqua`} />
                </Center>
            )}
        </ListItem>
    )
}
