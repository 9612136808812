import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const RecordGreetingIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'RecordGreetingIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <circle cx="12" cy="12" r="12" fill="currentColor" />
            <path
                d="M14.4 8.31579L14.4 11.8246C14.4 13.1035 13.3255 14.1404 12 14.1404C10.6745 14.1404 9.6 13.1035 9.6 11.8246V8.31579C9.6 7.03681 10.6745 6 12 6C13.3255 6 14.4 7.03681 14.4 8.31579Z"
                fill="white"
            />
            <path
                d="M8.8 11.8839V10.3265L8 10.3282V11.8246C8 13.6918 9.37421 15.2494 11.2 15.607V17.2281H9.6V18H14.4V17.2281H12.8V15.607C14.6258 15.2494 16 13.6918 16 11.8246V10.3116L15.2 10.3133L15.2 11.8839C15.2 13.5564 13.7673 14.9123 12 14.9123C10.2327 14.9123 8.8 13.5564 8.8 11.8839Z"
                fill="white"
            />
        </g>
    )
})

export const RecordPausedIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'RecordPausedIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path d="M8 8H11V16H8V8Z" fill="currentColor" />
            <path d="M16 8H13V16H16V8Z" fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
                fill="currentColor"
            />
        </g>
    )
})

export const RecordIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'RecordIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
                fill="currentColor"
            />
        </g>
    )
})
