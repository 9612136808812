import { Box, Center, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { AdminSettingsIcon, ChartIcon } from '@missionlabs/react/circleloop'
import {
    ContactBookIconRegular,
    DialpadIconSolid,
    ListIconSolid,
    SettingsIconRegular
} from '@missionlabs/react/zeta'
import { UserRole } from '@missionlabs/types'
import { ReactNode, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { RequireRole } from 'routes/RequireRole'

interface WelcomeItemProps {
    title: string
    subtitle: string
    icon: ReactNode
    variant?: 'red' | 'blue'
}

const WelcomeItem = ({ title, subtitle, icon, variant = 'red' }: WelcomeItemProps) => {
    const { colorMode } = useColorMode()
    const bg = variant === 'blue' ? `${colorMode}.tones.navy` : `${colorMode}.alerts.red`
    return (
        <HStack
            as="li"
            align="center"
            spacing={6}
            color={`${colorMode}.tones.ghostWhite`}
            _hover={{ svg: { transform: 'scale(1.25)' } }}
        >
            <Box
                className="boxImage"
                w="64px"
                h="64px"
                borderRadius="8px"
                bg={bg}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ '& svg': { boxSize: '20px', transition: 'all 200ms ease' } }}
            >
                {icon}
            </Box>
            <VStack align="start" spacing={0}>
                <Body size="md">{title}</Body>
                <Body size="sm">{subtitle}</Body>
            </VStack>
        </HStack>
    )
}

export const ActivityWelcomePage = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    useEffect(() => {
        // We only want to show the welcome page on first login to a device
        // so we store a flag in local storage to check against
        localStorage.setItem('seenWelcome', 'true')
    }, [])

    return (
        <Center>
            <VStack align="start">
                <Body as="h1" size="lg">
                    <Trans
                        i18nKey="activities.welcome.title"
                        components={{
                            bold: (
                                <Body
                                    as="span"
                                    size="lg"
                                    sx={{ color: `${colorMode}.alerts.red` }}
                                />
                            )
                        }}
                    />
                </Body>
                <Body as="h2" size="md">
                    {t('activities.welcome.subtitle')}
                </Body>
                <VStack as="ul" align="start" spacing={6} mt={8}>
                    <WelcomeItem
                        title={t('activities.welcome.dialpad.title')}
                        subtitle={t('activities.welcome.dialpad.subtitle')}
                        variant="blue"
                        icon={<DialpadIconSolid aria-label="Dialpad" />}
                    />
                    <WelcomeItem
                        title={t('activities.welcome.activities.title')}
                        subtitle={t('activities.welcome.activities.subtitle')}
                        icon={<ListIconSolid aria-label="Home" />}
                    />
                    <WelcomeItem
                        title={t('activities.welcome.contacts.title')}
                        subtitle={t('activities.welcome.contacts.subtitle')}
                        icon={<ContactBookIconRegular aria-label="Contacts" />}
                    />
                    <WelcomeItem
                        title={t('activities.welcome.analytics.title')}
                        subtitle={t('activities.welcome.analytics.subtitle')}
                        icon={<ChartIcon aria-label="Analytics" />}
                    />
                    <WelcomeItem
                        title={t('activities.welcome.settings.title')}
                        subtitle={t('activities.welcome.settings.subtitle')}
                        icon={<SettingsIconRegular aria-label="Settings" />}
                    />
                    <RequireRole role={UserRole.ADMIN} redirect={false}>
                        <WelcomeItem
                            title={t('activities.welcome.adminSettings.title')}
                            subtitle={t('activities.welcome.adminSettings.subtitle')}
                            icon={<AdminSettingsIcon aria-label="Settings" />}
                        />
                    </RequireRole>
                </VStack>
            </VStack>
        </Center>
    )
}
