import { FC, useEffect, useRef } from 'react'

interface AudioVisualiserProps {
    analyser?: AnalyserNode
    height: number
    width: number
}

export const AudioVisualiser: FC<AudioVisualiserProps> = ({ analyser, height, width }) => {
    const ref = useRef<HTMLCanvasElement>(null)
    const analyserRef = useRef<CanvasRenderingContext2D | null>(null)
    const sample = useRef<number[]>([])

    useEffect(() => {
        const canvas = ref.current
        if (!canvas) return console.error('no canvas')

        if (!analyserRef.current) {
            canvas.width = width
            canvas.height = height

            analyserRef.current = canvas.getContext('2d')
        }

        const timeout = setInterval(draw, 1000)

        return () => {
            if (timeout) clearInterval(timeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const draw = () => {
        const canvas = ref.current
        const analyserContext = analyserRef.current
        if (!analyser || !canvas || !analyserContext) return

        const SPACING = 6

        const numberOfBars = Math.round(canvas.width / SPACING)
        const dataPoints = new Uint8Array(analyser.frequencyBinCount)

        analyser.getByteFrequencyData(dataPoints)

        analyserContext.clearRect(0, 0, canvas.width, canvas.height)

        analyserContext.lineCap = 'square'
        const multiplier = analyser.frequencyBinCount / numberOfBars

        console.log({ multiplier })

        let averageMagnitude = 0

        // Draw rectangle for each frequency bin.
        for (let i = 0; i < numberOfBars; ++i) {
            let magnitude = 0
            const offset = Math.floor(i * multiplier)
            // gotta sum/average the block, or we miss narrow-bandwidth spikes
            for (let j = 0; j < multiplier; j++) {
                magnitude += dataPoints[offset + j]
            }

            averageMagnitude += magnitude / multiplier
        }

        // Store average magnitude of audio over sample rate (i.e, every second).

        // After canvas length has been reached, start scrolling through the data points (i.e, remove one, shift the reset down an index, and add one)
        if (sample.current.length >= numberOfBars) {
            sample.current.shift()
        }

        sample.current.push(averageMagnitude / numberOfBars)

        sample.current.forEach((magnitude, i) => {
            const x = i * SPACING // Spaced out evenly.
            const y = canvas.height / 2 + magnitude / 2 // Centered on the Y axis.
            const w = 3 // Uniform width.
            const h = -magnitude // Variable height based on volume.

            analyserContext.fillStyle = '#7B8794'
            analyserContext.fillRect(x, y, w, h)
        })
    }

    return <canvas ref={ref} style={{ width: '100%', height: '100%', background: '#F5F7FA' }} />
}
