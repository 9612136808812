import { Center, HStack, SimpleGrid, useColorMode, VStack } from '@chakra-ui/react'
import { guestRequestToJoinMeeting, useDispatch } from '@missionlabs/api'
import { Body, Button, FormProvider, Heading, useYupForm, yup } from '@missionlabs/react'
import { Logo } from '@missionlabs/react/circleloop'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'

import { AudioSettings } from './components/AudioSettings'
import { GuestForm } from './components/GuestForm'
import { VideoSettings } from './components/VideoSettings'
import { useMeetingTitle } from './hooks/useMeetingTitle'
import { MeetingCallRootContext } from './MeetingsCallRoot'

export interface MeetingsCallSetupFields {
    isGuest?: boolean
    name: string
    email: string
}

const meetingsCallSetupSchema = yup.object({
    isGuest: yup.boolean(),
    name: yup.string().when('isGuest', {
        is: val => val === true,
        then: () => yup.string().required()
    }),
    email: yup.string().when('isGuest', {
        is: val => val === true,
        then: () => yup.string().email().required()
    })
})

export const MeetingsCallSetup: FC = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const title = useMeetingTitle()
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    const {
        meeting,
        isGuest,
        setSetupComplete,
        error: meetingError
    } = useOutletContext<MeetingCallRootContext>()

    const methods = useYupForm<MeetingsCallSetupFields>(meetingsCallSetupSchema, {
        defaultValues: { isGuest }
    })

    function handleSubmit(data: MeetingsCallSetupFields) {
        const meetingID = location.state?.id ?? meeting?.meetingID

        if (isGuest) {
            const payload = {
                meetingID,
                name: data.name,
                email: data.email
            }
            dispatch(guestRequestToJoinMeeting(payload))
        }

        setSetupComplete(true)
        return navigate(isGuest ? `/${meetingID}` : `/meetings/${meetingID}`)
    }

    const meetingID = location.state?.id ?? meeting?.meetingID

    /** If the app receives a `video_call_hangup` whilst on the setup screen
     *  navigate to the path that displays the hangup page.
     */
    useEffect(
        function handleCallHangUp() {
            if (meetingError) {
                navigate(isGuest ? `/${meetingID}` : `/meetings/${meetingID}`)
            }
        },
        [navigate, meetingID, isGuest, meetingError]
    )

    // todo
    // if (!isAcceptMeetingPayload(payload))

    return (
        <FormProvider {...methods}>
            <Center w="100dvw" h="100dvh" bg={`${colorMode}.tones.midnightExpress`}>
                <VStack
                    as="form"
                    spacing={0}
                    w="864px"
                    align="stretch"
                    onSubmit={methods.handleSubmit(handleSubmit)}
                >
                    <HStack
                        spacing="24px"
                        p="24px"
                        borderBottom="1px solid"
                        borderColor={`${colorMode}.tones.stormGrey`}
                    >
                        <Logo boxSize="48px" />
                        <VStack spacing={0} align="start">
                            <Heading
                                size="h3"
                                variant="bold"
                                sx={{ color: `${colorMode}.tones.white` }}
                            >
                                {t('meeting.setup.title', { title })}
                            </Heading>
                            <Body size="sm" sx={{ color: `${colorMode}.tones.periwinkle` }}>
                                {t('meeting.setup.body')}
                            </Body>
                        </VStack>
                    </HStack>
                    <SimpleGrid columns={isGuest ? 3 : 2} gap="24px" px="24px">
                        {isGuest && <GuestForm />}
                        <AudioSettings />
                        <VideoSettings preview />
                    </SimpleGrid>
                    <Center px="24px" py="32px">
                        <Button
                            type="submit"
                            size="xl"
                            w="256px"
                            isDisabled={isGuest ? !methods.formState.isDirty : !meeting?.meetingID}
                            isLoading={!isGuest && !meeting?.meetingID}
                        >
                            {t('Join Video Chat')}
                        </Button>
                    </Center>
                </VStack>
            </Center>
        </FormProvider>
    )
}
