import apiMetric from './api'
import callMetric from './call'
import errorMetric from './error'
import Metric, { MetricData } from './metric'
import userMetric from './user'

const metrics = {
    api: apiMetric,
    call: callMetric,
    error: errorMetric,
    user: userMetric
}

export { type MetricData, Metric }

export default metrics
