import {
    selectAuthenticatedUser,
    selectUserCalls,
    sendSubscriptionRequest,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { userSubscriptionCalls } from '@missionlabs/types'
import { FC, useEffect, useMemo } from 'react'
import { useCallLookup } from 'shared/hooks/useCallLookup'

import { CallPull } from '../RetrieveCall/CallPull'

export type SanitizedUserSubscriptionCalls = userSubscriptionCalls &
    Required<Pick<userSubscriptionCalls, 'currentCalls'>>

export const RetrieveCallPanel: FC = () => {
    const dispatch = useDispatch()

    const user = useSelector(selectAuthenticatedUser)
    const userCalls = useSelector(selectUserCalls)
    const lookupCall = useCallLookup()

    const pullableCall = useMemo(() => {
        const subscription = userCalls?.find((call): call is SanitizedUserSubscriptionCalls => {
            return Boolean(call.currentCalls?.length)
        })
        const pullableCall = subscription?.currentCalls[0]

        // prevent pulling calls on current device.
        const call = lookupCall(pullableCall?.callTraceID)
        if (call && call.status === 'CONNECTED') return

        return pullableCall
    }, [lookupCall, userCalls])

    useEffect(() => {
        if (!user) return
        dispatch(
            sendSubscriptionRequest({ eventGroup: 'user_call_monitoring', userIDs: [user.userID] })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    if (!pullableCall) return null
    return <CallPull call={pullableCall} />
}
