import { Box, useColorMode } from '@chakra-ui/react'
import { Heading } from '@missionlabs/react'
import { FC } from 'react'
import { useSectionTitle } from 'shared/hooks/useSectionTitle'
import { Section } from 'shared/types/feed'

export interface ActivityFeedSectionProps {
    section: Section
}

export const ActivityFeedSection: FC<ActivityFeedSectionProps> = ({ section }) => {
    const { colorMode } = useColorMode()

    const title = useSectionTitle(section)

    return (
        <Box
            w="calc(100% - 48px)"
            pb="16px"
            ml="24px"
            borderBottom="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
        >
            <Heading size="h3" fontWeight={500}>
                {title}
            </Heading>
        </Box>
    )
}
