type UUID = string
type Email = string

type InternalAttendee = {
    userID: UUID
    attendeeType: 'internal'
    /** name can be provided when creating meeting to prevent having to fetch users' names when fetching the meeting */
    name?: string
}

type GuestAttendee = {
    userID: Email
    attendeeType: 'guest'
    name: string
    company: string
}

export enum AttendeeStatus {
    NO_RESPONSE = 'NO_RESPONSE',
    ATTENDING = 'ATTENDING',
    DECLINED = 'DECLINED'
}

export type AttendeeDetails = {
    companyName: string
    name: string
    userID: string
    attendanceStatus: AttendeeStatus
}

export enum MeetingStatus {
    ACTIVE = 'Active',
    CANCELLED = 'CANCELLED'
}

export type ScheduledMeetingAttendee = InternalAttendee | GuestAttendee

/**
 * Represents a single user's scheduled meeting.
 *
 * `attendanceStatus` is this user's attendance status for the meeting
 * `invitedUsers` are all the users invited to this meeting
 */
export type ScheduledMeeting = {
    ID: UUID
    meetingName: string
    description?: string
    startTime: number
    endTime: number
    attendanceStatus: AttendeeStatus
    invitedUsers: AttendeeDetails[]
    organiser: Omit<AttendeeDetails, 'attendanceStatus'>
    meetingSettings: {
        allowGuestUsers: 'ALLOW' | 'DISALLOW'
        microphoneInitialState: 'MUTED' | 'UNMUTED'
    }
    status: MeetingStatus
}

export type NewScheduledMeeting = Pick<
    ScheduledMeeting,
    'meetingName' | 'description' | 'startTime' | 'endTime' | 'meetingSettings'
> & { invitedUsers: { userID: string }[] }

export type UpdateScheduledMeeting = Omit<Partial<NewScheduledMeeting>, 'invitedUsers'> & {
    userID: string
    meetingID: string
    meetingStatus?: string
}
