import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ExternalLinkIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ExternalLinkIconLight',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M10.875 1.5C10.5234 1.5 10.25 1.22656 10.25 0.875C10.25 0.5625 10.5234 0.25 10.875 0.25H17.125C17.4375 0.25 17.75 0.5625 17.75 0.875V7.125C17.75 7.47656 17.4375 7.75 17.125 7.75C16.7734 7.75 16.5 7.47656 16.5 7.125V2.39844L7.55469 11.3438C7.32031 11.5781 6.89062 11.5781 6.65625 11.3438C6.42188 11.1094 6.42188 10.6797 6.65625 10.4453L15.6016 1.5H10.875ZM0.25 3.375C0.25 2.35938 1.07031 1.5 2.125 1.5H7.125C7.4375 1.5 7.75 1.8125 7.75 2.125C7.75 2.47656 7.4375 2.75 7.125 2.75H2.125C1.77344 2.75 1.5 3.0625 1.5 3.375V15.875C1.5 16.2266 1.77344 16.5 2.125 16.5H14.625C14.9375 16.5 15.25 16.2266 15.25 15.875V10.875C15.25 10.5625 15.5234 10.25 15.875 10.25C16.1875 10.25 16.5 10.5625 16.5 10.875V15.875C16.5 16.9297 15.6406 17.75 14.625 17.75H2.125C1.07031 17.75 0.25 16.9297 0.25 15.875V3.375Z"
            fill="currentColor"
        />
    )
})

export const ExternalLinkIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ExternalLinkIconRegular',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M11.1875 2.125C10.6406 2.125 10.25 1.73438 10.25 1.1875C10.25 0.679688 10.6406 0.25 11.1875 0.25H16.8125C17.3203 0.25 17.75 0.679688 17.75 1.1875V6.8125C17.75 7.35938 17.3203 7.75 16.8125 7.75C16.2656 7.75 15.875 7.35938 15.875 6.8125V3.45312L8.0625 11.2266C7.71094 11.6172 7.125 11.6172 6.77344 11.2266C6.38281 10.875 6.38281 10.2891 6.77344 9.89844L14.5469 2.125H11.1875ZM0.25 3.6875C0.25 2.51562 1.22656 1.5 2.4375 1.5H6.8125C7.32031 1.5 7.75 1.92969 7.75 2.4375C7.75 2.98438 7.32031 3.375 6.8125 3.375H2.4375C2.24219 3.375 2.125 3.53125 2.125 3.6875V15.5625C2.125 15.7578 2.24219 15.875 2.4375 15.875H14.3125C14.4688 15.875 14.625 15.7578 14.625 15.5625V11.1875C14.625 10.6797 15.0156 10.25 15.5625 10.25C16.0703 10.25 16.5 10.6797 16.5 11.1875V15.5625C16.5 16.7734 15.4844 17.75 14.3125 17.75H2.4375C1.22656 17.75 0.25 16.7734 0.25 15.5625V3.6875Z"
            fill="currentColor"
        />
    )
})

export const ExternalLinkIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ExternalLinkIconSolid',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M10.25 1.5C10.25 0.835938 10.7969 0.25 11.5 0.25H16.4609C16.6562 0.25 16.8125 0.289062 16.9688 0.367188C17.0859 0.40625 17.2422 0.523438 17.3594 0.640625C17.5938 0.875 17.7109 1.1875 17.75 1.5V6.5C17.75 7.20312 17.1641 7.75 16.5 7.75C15.7969 7.75 15.25 7.20312 15.25 6.5V4.54688L8.60938 11.1484C8.14062 11.6562 7.32031 11.6562 6.85156 11.1484C6.34375 10.6797 6.34375 9.85938 6.85156 9.39062L13.4531 2.75H11.5C10.7969 2.75 10.25 2.20312 10.25 1.5ZM0.25 4C0.25 2.63281 1.34375 1.5 2.75 1.5H6.5C7.16406 1.5 7.75 2.08594 7.75 2.75C7.75 3.45312 7.16406 4 6.5 4H2.75V15.25H14V11.5C14 10.8359 14.5469 10.25 15.25 10.25C15.9141 10.25 16.5 10.8359 16.5 11.5V15.25C16.5 16.6562 15.3672 17.75 14 17.75H2.75C1.34375 17.75 0.25 16.6562 0.25 15.25V4Z"
            fill="currentColor"
        />
    )
})
