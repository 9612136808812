import { useFlags } from 'flagsmith/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'

import { AfterCallSettings } from './AfterCallSettings'
import AnonymousCallSetting from './AnonymousCallSetting'
import { CallRecordingSetting } from './CallRecordingSetting'
import CallWaitingSetting from './CallWaitingSetting'
import { HoldMusicSetting } from './HoldMusicSetting'
import { LineBusySetting } from './LineBusySetting/LineBusySetting'
import { OutOfHoursSetting } from './OutOfHoursSetting/OutOfHoursSetting'
import { UnansweredCallSetting } from './UnansweredCallSetting/UnansweredCallSetting'
import { VoicemailPortalPinSetting } from './VoicePortalPinSetting'

export const Calls: FC = () => {
    const { t } = useTranslation()
    const flags = useFlags([
        'user_voice_portal',
        'call_settings_line_busy',
        'call_settings_anonymous_calls'
    ])

    return (
        <SettingsPage title={t('calls.title')}>
            <UnansweredCallSetting />
            {flags.call_settings_line_busy && <LineBusySetting />}
            {flags.call_settings_anonymous_calls && <AnonymousCallSetting />}
            <OutOfHoursSetting />
            <CallRecordingSetting />
            <HoldMusicSetting />
            {flags.user_voice_portal.enabled && <VoicemailPortalPinSetting />}
            <AfterCallSettings />
            <CallWaitingSetting />
        </SettingsPage>
    )
}
