import {
    closeSocket,
    logoutSuccess,
    selectActiveMeeting,
    selectPreferences,
    setMeetingAnswered,
    setNumberOfTiles,
    stopMeeting,
    updatePreferences,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import {
    MeetingStatus,
    useDataMessages,
    useMeetingContext,
    useMeetingManager,
    useMeetingStatus,
    useParticipantsData
} from '@missionlabs/meetings'
import { useOnTabClose } from '@missionlabs/react'
import { useEffect, useState } from 'react'
import { useGetUserData } from 'shared/hooks/useGetUserData'

const ONE_SECOND = 1000

export const CLOSE_TIMEOUT = 5 * ONE_SECOND
export const IDLE_TIMEOUT = 5 * ONE_SECOND
export const MAX_TILES = 6

export const useVideoCall = (isGuest = false) => {
    const dispatch = useDispatch()
    const context = useMeetingContext()
    const manager = useMeetingManager()
    const status = useMeetingStatus()

    const meeting = useSelector(selectActiveMeeting)
    const preferences = useSelector(selectPreferences)

    const { user } = useGetUserData()
    const { setFullName } = useDataMessages()
    const { joinedParticipants: participants } = useParticipantsData()

    const [init, setInit] = useState<boolean>(false)

    useOnTabClose(() => {
        if (isGuest) {
            dispatch(closeSocket())
            dispatch(logoutSuccess())
        }
        manager.leave()
    })

    useEffect(() => {
        if (!meeting?.joinInfo || init) return

        const { initialDeviceState } = meeting
        if (initialDeviceState) {
            const prefs = { ...preferences }
            prefs.videoNoiseSuppression = !!initialDeviceState.isNoiseSuppressionEnabled
            if (
                initialDeviceState.videoBackgroundFilter &&
                !initialDeviceState.videoBackgroundFilter.includes('image')
            ) {
                prefs.videoBackgroundFilter = initialDeviceState.videoBackgroundFilter
            }
            dispatch(updatePreferences(prefs))
        }

        if (user) setFullName(user.fullName)

        setInit(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meeting, init, user])

    useEffect(() => {
        if (!meeting?.joinInfo || !init || meeting.answered) return

        const { initialDeviceState, meetingSettings } = meeting
        const isMicrophoneEnabled = initialDeviceState?.isMicrophoneEnabled
        let microphoneInitialState = meetingSettings?.microphoneInitialState ?? 'UNMUTED'
        if (initialDeviceState) microphoneInitialState = isMicrophoneEnabled ? 'UNMUTED' : 'MUTED'

        context
            .join(meeting.joinInfo, { meetingSettings: { microphoneInitialState } })
            .then(() => dispatch(setMeetingAnswered(true)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meeting, init])

    useEffect(() => {
        if (!meeting) return

        const numberOfTiles = meeting.numberOfTiles ?? 0
        if (numberOfTiles > participants.length) dispatch(setNumberOfTiles(0))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meeting, participants.length])

    useEffect(() => {
        if (status === MeetingStatus.Ended) {
            dispatch(stopMeeting({ error: 'ended', meetingID: meeting?.meetingID }))
        }
        if (status === MeetingStatus.Left) {
            dispatch(stopMeeting({ error: 'disconnected', meetingID: meeting?.meetingID }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meeting, status])
}
