import { HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Spinner } from '@missionlabs/react'
import { ClipboardIconLight } from '@missionlabs/react/zeta'
import { FC } from 'react'
import { useCopyToClipboard } from 'react-use'

export interface ConferenceCallingItemProps {
    label: string
    value: string | undefined
    isLoading: boolean
    isIcon: boolean
}

export const ConferenceCallingItem: FC<ConferenceCallingItemProps> = ({
    label,
    value,
    isLoading,
    isIcon
}) => {
    const { colorMode } = useColorMode()

    const [, copyToClipboard] = useCopyToClipboard()

    const handleCopyToClipboard = () => {
        if (value) {
            copyToClipboard(value)
        }
    }

    return (
        <VStack spacing={0} align="start">
            <Body>{label}</Body>
            <HStack>
                {isLoading ? (
                    <Spinner size="sm" />
                ) : (
                    <Body color={`${colorMode}.alerts.red`} fontSize="28px" fontWeight={300}>
                        {value}
                    </Body>
                )}
                {isIcon && (
                    <ClipboardIconLight
                        boxSize="24px"
                        p="4px"
                        onClick={handleCopyToClipboard}
                        color={`${colorMode}.alerts.red`}
                    />
                )}
            </HStack>
        </VStack>
    )
}
