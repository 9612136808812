import { Button, HStack, VStack } from '@chakra-ui/react'
import { FormProvider, Input, useToast, useYupForm, ValidatedInput, yup } from '@missionlabs/react'
import { PostWhatsAppRegisterNumberRequest } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { usePostWhatsAppRegisterNumberMutation } from 'shared/store'

export interface RegisterWhatsAppNumberFormProps {
    onClose: () => void
}

export const RegisterWhatsAppNumberForm: FC<RegisterWhatsAppNumberFormProps> = ({ onClose }) => {
    const { t } = useTranslation()
    const { toast } = useToast()
    const { id: numberID = '' } = useParams()

    const [registerNumber, { isLoading }] = usePostWhatsAppRegisterNumberMutation()

    const values: PostWhatsAppRegisterNumberRequest = {
        ID: numberID,
        pin: ''
    }

    async function onSubmit(data: PostWhatsAppRegisterNumberRequest) {
        try {
            await registerNumber(data).unwrap()
            toast({
                title: t('admin.whatsapp.register.success'),
                status: 'success'
            })
            await new Promise(resolve => setTimeout(resolve, 2000))
            onClose()
        } catch (error) {
            console.error('Error registering number', error)
            toast({
                title: t('admin.whatsapp.register.error'),
                status: 'error'
            })
        }
    }

    const schema = yup.object({
        pin: yup
            .string()
            .required()
            .matches(/^\d{6}$/, 'Pin must be exactly 6 digits')
    })

    const methods = useYupForm<PostWhatsAppRegisterNumberRequest>(schema, {
        values
    })
    const {
        formState: { isDirty }
    } = methods

    return (
        <FormProvider {...methods}>
            <VStack
                as="form"
                align="stretch"
                h="full"
                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <VStack py={4}>
                    <ValidatedInput name="pin">
                        <Input width={312} type="password" />
                    </ValidatedInput>
                </VStack>
                <HStack pb={8}>
                    <Button
                        type="submit"
                        size="lg"
                        variant="creationary"
                        isDisabled={!isDirty}
                        isLoading={isLoading}
                    >
                        {t('admin.whatsapp.register.fields.submit')}
                    </Button>
                </HStack>
            </VStack>
        </FormProvider>
    )
}
