import { useDisclosure } from '@chakra-ui/react'
import { selectAuthenticatedUser, selectInCurrentData, useSelector } from '@missionlabs/api'
import {
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay
} from '@missionlabs/react'
import { AudioClip } from '@missionlabs/types'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { FC, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDeleteUserAudioClipMutation, useGetUserAudioClipsQuery } from 'shared/store'

export const DeleteRecordingDialog: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { id = '' } = useParams()

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    const ref = useRef<HTMLButtonElement>(null)

    const user = useSelector(selectAuthenticatedUser)

    const selectAudioClip = useMemo(() => {
        return selectInCurrentData<AudioClip[], AudioClip>(data =>
            data?.find(item => item.ID === id)
        )
    }, [id])

    const { audioClip } = useGetUserAudioClipsQuery(user?.userID ?? skipToken, {
        selectFromResult: result => ({ ...result, audioClip: selectAudioClip(result) })
    })
    const [deleteClip, { isLoading, isSuccess }] = useDeleteUserAudioClipMutation()

    async function handleDelete() {
        try {
            if (!audioClip || !user) return
            await deleteClip({ ID: audioClip.ID, userID: user.userID }).unwrap()
            return onClose()
        } catch (e) {
            console.error(e)
        }
    }

    function handleTransition() {
        if (isSuccess) navigate(`/settings/recordings`)
        else navigate(`/settings/recordings/${id}/edit`)
    }

    return (
        <Dialog
            leastDestructiveRef={ref}
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={handleTransition}
        >
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>{t('recordings.delete.header')}</DialogHeader>
                <DialogBody>{t('recordings.delete.body', { name: audioClip?.label })}</DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('recordings.delete.confirm'),
                        isDestructive: true,
                        onClick: handleDelete
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
