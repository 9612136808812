import { useDisclosure } from '@chakra-ui/react'
import {
    Drawer,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    FormProvider,
    useYupForm
} from '@missionlabs/react'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import {
    useCreateHuntGroupMutation,
    useGetHuntGroupQuery,
    useUpdateHuntGroupMutation
} from 'shared/store'
import { buildNotAvailable } from 'shared/utils/notAvailable'

import {
    AddEditTeamFields,
    AddEditTeamForm,
    addEditTeamSchema,
    defaultValues
} from './AddEditTeamForm'

const noop = () => {}

export const AddEditTeamDrawer: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { id = '' } = useParams()
    const { formatToLocalNumber } = useFormatToNumberE164()

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    const { currentData: team } = useGetHuntGroupQuery(id, { skip: !id || !isOpen })
    const [createTeam, { isLoading: createLoading }] = useCreateHuntGroupMutation()
    const [updateTeam, { isLoading: updateLoading }] = useUpdateHuntGroupMutation()

    const isLoading = createLoading || updateLoading

    const initialValues = useMemo(() => {
        const values = { ...defaultValues }

        if (team) {
            for (const key in values) {
                let value = team[key]
                if (!value) continue
                if (key === 'unansweredRedirect' && 'numberE164' in value) {
                    value = { ...value, numberE164: formatToLocalNumber(value.numberE164) }
                }
                if (key === 'busyRedirect' && 'numberE164' in value) {
                    value = { ...value, numberE164: formatToLocalNumber(value.numberE164) }
                }
                values[key] = value
            }
        }

        return values
    }, [team, formatToLocalNumber])

    const methods = useYupForm(addEditTeamSchema, {
        defaultValues: defaultValues,
        values: initialValues
    })

    function handleDelete() {
        navigate(`/admin/teams/${id}/delete`)
    }

    async function handleSubmit(data: AddEditTeamFields) {
        try {
            const notAvailable = buildNotAvailable({ ...data?.notAvailable })
            if (team)
                await updateTeam({
                    ID: team.ID,
                    ...data,
                    notAvailable
                }).unwrap()
            else
                await createTeam({
                    ...data,
                    notAvailable
                }).unwrap()
            return onClose()
        } catch (e) {
            console.error(e)
        }
    }

    function handleTransition() {
        navigate(-1)
    }

    return (
        <FormProvider {...methods}>
            <Drawer
                size="lg"
                isOpen={isOpen}
                onClose={!isLoading ? onClose : noop}
                onCloseComplete={handleTransition}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader>
                        {t(`admin.teams.form.header.${id ? 'edit' : 'add'}`)}
                    </DrawerHeader>
                    <AddEditTeamForm
                        canDelete={!!id}
                        onClose={onClose}
                        onDelete={handleDelete}
                        onSubmit={handleSubmit}
                    />
                </DrawerContent>
            </Drawer>
            <Outlet />
        </FormProvider>
    )
}
