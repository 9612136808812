import { UnansweredForwardType, UserCallRoute, Voicemail } from '@missionlabs/types'
import { useSettingsContext } from 'features/settings/components/settingsContext'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { UnansweredCallRadio } from 'features/settings/pages/Calls/UnansweredCallSetting/UnansweredCallRadio'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

function getVoicemail(
    route: UserCallRoute,
    unanswered?: UnansweredForwardType,
    voicemail?: Voicemail
) {
    return {
        ...route.voicemail,
        ...voicemail,
        status: unanswered === 'voicemail' ? 'active' : 'inactive',
        transcribe: true
    }
}

export const UnansweredCallSetting: FC = () => {
    const { t } = useTranslation()

    const { userCallRoute: callRoute, onUpdateUserCallRoute } = useSettingsContext()

    const onUpdate = (payload: Partial<UserCallRoute>) => {
        if (!callRoute) return

        return onUpdateUserCallRoute?.({
            callTimeout: payload.callTimeout,
            always: payload.always,
            alwaysRedirect: payload.unansweredRedirect,
            inHoursUnanswered: payload.inHoursUnanswered,
            unansweredRedirect: payload.unansweredRedirect,
            unansweredRedirectInternal: payload.unansweredRedirectInternal,
            voicemail: getVoicemail(callRoute, payload.inHoursUnanswered, payload.voicemail)
        })
    }

    if (!callRoute) return null

    return (
        <SettingsRow label={t('calls.callSettings')}>
            <UnansweredCallRadio callRoute={callRoute} onUpdate={onUpdate} />
        </SettingsRow>
    )
}
