import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ShareIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ShareIconLight',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M17.75 3.375C17.75 5.13281 16.3438 6.5 14.625 6.5C13.7266 6.5 12.9062 6.14844 12.3203 5.52344L6.42188 8.49219C6.46094 8.64844 6.5 8.84375 6.5 9C6.5 9.19531 6.46094 9.39062 6.42188 9.54688L12.3203 12.4766C12.9062 11.8906 13.7266 11.5 14.625 11.5C16.3438 11.5 17.75 12.9062 17.75 14.625C17.75 16.3828 16.3438 17.75 14.625 17.75C12.8672 17.75 11.5 16.3828 11.5 14.625C11.5 14.2734 11.5391 13.8828 11.6562 13.5703L5.99219 10.7188C5.40625 11.5781 4.46875 12.125 3.375 12.125C1.61719 12.125 0.25 10.7578 0.25 9C0.25 7.28125 1.61719 5.875 3.375 5.875C4.46875 5.875 5.40625 6.46094 5.99219 7.32031L11.6562 4.46875C11.5391 4.11719 11.5 3.76562 11.5 3.375C11.5 1.65625 12.8672 0.25 14.625 0.25C16.3438 0.25 17.75 1.65625 17.75 3.375ZM3.33594 10.875C4.39062 10.875 5.21094 10.0547 5.21094 9C5.21094 7.98438 4.39062 7.125 3.33594 7.125C2.32031 7.125 1.46094 7.98438 1.46094 9C1.46094 10.0547 2.32031 10.875 3.33594 10.875ZM14.625 1.5C13.5703 1.5 12.75 2.35938 12.75 3.375C12.75 4.42969 13.5703 5.25 14.625 5.25C15.6406 5.25 16.5 4.42969 16.5 3.375C16.5 2.35938 15.6406 1.5 14.625 1.5ZM14.625 16.5C15.6406 16.5 16.5 15.6797 16.5 14.625C16.5 13.6094 15.6406 12.75 14.625 12.75C13.5703 12.75 12.75 13.6094 12.75 14.625C12.75 15.6797 13.5703 16.5 14.625 16.5Z"
            fill="currentColor"
        />
    )
})

export const ShareIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ShareIconRegular',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M17.75 4C17.75 6.07031 16.0703 7.75 14 7.75C12.8672 7.75 11.8516 7.28125 11.1484 6.46094L7.63281 8.21875C7.71094 8.49219 7.71094 8.76562 7.71094 9C7.71094 9.27344 7.71094 9.54688 7.63281 9.82031L11.1484 11.5391C11.8516 10.7578 12.8672 10.25 14 10.25C16.0703 10.25 17.75 11.9297 17.75 14C17.75 16.0703 16.0703 17.75 14 17.75C11.8906 17.75 10.25 16.0703 10.25 14C10.25 13.7656 10.25 13.4922 10.3281 13.2188L6.8125 11.4609C6.10938 12.2812 5.09375 12.75 4 12.75C1.89062 12.75 0.25 11.0703 0.25 9C0.25 6.92969 1.89062 5.25 4 5.25C5.09375 5.25 6.10938 5.75781 6.8125 6.53906L10.3281 4.82031C10.25 4.54688 10.25 4.27344 10.25 4C10.25 1.92969 11.8906 0.25 14 0.25C16.0703 0.25 17.75 1.92969 17.75 4ZM3.96094 10.875C5.01562 10.875 5.83594 10.0547 5.83594 9C5.83594 7.98438 5.01562 7.125 3.96094 7.125C2.94531 7.125 2.08594 7.98438 2.08594 9C2.08594 10.0547 2.94531 10.875 3.96094 10.875ZM14 2.125C12.9453 2.125 12.125 2.98438 12.125 4C12.125 5.05469 12.9453 5.875 14 5.875C15.0156 5.875 15.875 5.05469 15.875 4C15.875 2.98438 15.0156 2.125 14 2.125ZM14 15.875C15.0156 15.875 15.875 15.0547 15.875 14C15.875 12.9844 15.0156 12.125 14 12.125C12.9453 12.125 12.125 12.9844 12.125 14C12.125 15.0547 12.9453 15.875 14 15.875Z"
            fill="currentColor"
        />
    )
})

export const ShareIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ShareIconSolid',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M11.3828 6.73438L7.71094 8.57031C7.71094 8.6875 7.71094 8.84375 7.71094 9C7.71094 9.15625 7.71094 9.3125 7.71094 9.46875L11.3828 11.3047C12.0469 10.6797 12.9844 10.25 14 10.25C16.0703 10.25 17.75 11.9297 17.75 14C17.75 16.0703 16.0703 17.75 14 17.75C11.8906 17.75 10.25 16.0703 10.25 14C10.25 13.8438 10.25 13.6875 10.25 13.5703L6.57812 11.7344C5.91406 12.3594 4.97656 12.75 4 12.75C1.89062 12.75 0.25 11.0703 0.25 9C0.25 6.92969 1.89062 5.25 4 5.25C4.97656 5.25 5.91406 5.67969 6.57812 6.30469L10.25 4.46875C10.25 4.3125 10.25 4.15625 10.25 4C10.25 1.92969 11.8906 0.25 14 0.25C16.0703 0.25 17.75 1.92969 17.75 4C17.75 6.07031 16.0703 7.75 14 7.75C12.9844 7.75 12.0469 7.35938 11.3828 6.73438Z"
            fill="currentColor"
        />
    )
})
