import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay
} from '@missionlabs/react'
import { DirectoryEntry, isContactGroup } from '@missionlabs/types'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDeleteUserContactMutation } from 'shared/store'

export interface DeleteContactDialogProps {
    contact: DirectoryEntry
    isOpen: boolean
    onClose: () => void
}

export const DeleteContactDialog: FC<DeleteContactDialogProps> = ({ contact, isOpen, onClose }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const ref = useRef<HTMLButtonElement>(null)

    const user = useSelector(selectAuthenticatedUser)

    const [deleteContact, { isLoading }] = useDeleteUserContactMutation()

    function handleDelete() {
        if (!user) return
        // optimistic update, don't await
        else deleteContact({ ID: contact.ID, userID: user.userID })
        onClose()
        return navigate('/contacts')
    }

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>{t('contacts.delete.header.contact')}</DialogHeader>
                <DialogBody>
                    {t('contacts.delete.body.contact', {
                        name: isContactGroup(contact) ? contact.name : contact.fullName
                    })}
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('Delete Contact'),
                        isDestructive: true,
                        onClick: handleDelete
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
