export interface ChatDataMessage {
    id: string
    message: string
    senderAttendeeId: string
    timestamp: number
    senderName: string
    isSelf: boolean
}

export interface State {
    messages: ChatDataMessage[]
    reactions: ChatDataMessage[]
}

export enum DataMessagesActionType {
    ADD,
    REMOVE,

    ADD_REACTION,
    REMOVE_REACTION
}

export interface Action {
    type: DataMessagesActionType
    payload: ChatDataMessage
}

export const initialState: State = {
    messages: [],
    reactions: []
}

export function reducer(state: State, action: Action): State {
    const { type, payload } = action
    switch (type) {
        case DataMessagesActionType.ADD:
            return {
                ...state,
                messages: [...state.messages, payload]
            }
        case DataMessagesActionType.REMOVE:
            return {
                ...state,
                messages: [...state.messages.filter(message => message.id !== payload.id)]
            }

        case DataMessagesActionType.ADD_REACTION:
            return {
                ...state,
                reactions: [...state.reactions, payload]
            }
        case DataMessagesActionType.REMOVE_REACTION:
            return {
                ...state,
                reactions: [...state.reactions.filter(message => message.id !== payload.id)]
            }

        default:
            throw new Error('Incorrect action in DataMessagesProvider reducer')
    }
}
