import { buildCreateApi, coreModule } from '@reduxjs/toolkit/dist/query'
import { reactHooksModule } from '@reduxjs/toolkit/dist/query/react'
import * as React from 'react'
import {
    createDispatchHook,
    createSelectorHook,
    createStoreHook,
    ReactReduxContextValue
} from 'react-redux'

export const SharedContext = React.createContext<ReactReduxContextValue>({} as any)

export const useStore = createStoreHook(SharedContext)
export const useDispatch = createDispatchHook(SharedContext)
export const useSelector = createSelectorHook(SharedContext)

export const customCreateApi = buildCreateApi(
    coreModule(),
    reactHooksModule({
        useStore,
        useDispatch,
        useSelector
    })
)
