import { Box, HStack, VStack } from '@chakra-ui/react'
import { Body, Button, Spinner, Table } from '@missionlabs/react'
import { formatCurrency } from '@missionlabs/utils'
import { createColumnHelper } from '@tanstack/react-table'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type PayInvoiceProps = {
    onSubmit: () => Promise<void>
    onClose: () => void
    isLoading: boolean
    invoice?: any
}

const columnHelper = createColumnHelper<any>()

export const PayInvoiceDetails: FC<PayInvoiceProps> = ({
    onSubmit,
    onClose,
    isLoading,
    invoice
}) => {
    const { t } = useTranslation()

    const columns = [
        columnHelper.accessor('description', {
            cell: info => `${info.getValue()} - x${info.row.original.quantity}`,
            header: t('Description'),
            footer: () => {
                return (
                    <>
                        <Body size="sm">{invoice?.taxDescription}</Body>
                        <Body>{t('Total')}</Body>
                    </>
                )
            }
        }),
        columnHelper.accessor('amount', {
            cell: info => {
                const amount = info.getValue() / 100
                const currency = info.row.original.currencyCode
                return formatCurrency({ currency, amount })
            },
            header: t('Amount'),
            footer: () => {
                return (
                    <>
                        <Body p={0} size="sm">
                            {formatCurrency({
                                currency: invoice?.currencyCode,
                                amount: invoice?.taxTotal / 100
                            })}
                        </Body>
                        <Body>
                            {formatCurrency({
                                currency: invoice?.currencyCode,
                                amount: invoice?.total / 100
                            })}
                        </Body>
                    </>
                )
            }
        })
    ]

    return (
        <VStack h="full" align="stretch" spacing="16px">
            <Box h="full" p="16px">
                {invoice ? (
                    <Table columns={columns} data={invoice?.lines ?? []} />
                ) : (
                    <HStack justifyContent="center" w="full" p={10}>
                        <Spinner size="md" />
                    </HStack>
                )}

                <Body p={4} size="sm">
                    Payment will be attempted using your default payment method
                </Body>
            </Box>
            <HStack w="full" spacing="16px" align="inherit" padding="24px">
                <Button
                    w="full"
                    size="lg"
                    variant="secondary"
                    isDisabled={isLoading || !invoice}
                    onClick={onClose}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    type="submit"
                    w="full"
                    size="lg"
                    variant="creationary"
                    isLoading={isLoading}
                    onClick={onSubmit}
                >
                    {t('admin.billing.invoice.payNow')}
                </Button>
            </HStack>
        </VStack>
    )
}
