import { Body, Checkbox } from '@missionlabs/react'
import { Option } from '@missionlabs/types'
import * as React from 'react'
import { ComponentProps } from 'react'

export const FilterCheckbox = ({
    label,
    value,
    ...props
}: Option & ComponentProps<typeof Checkbox>) => {
    return (
        <Checkbox {...props} key={value} value={value}>
            <Body as="span" size="sm">
                {label}
            </Body>
        </Checkbox>
    )
}
