import { Center, Divider, HStack, useColorMode } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface UnreadThreadsLabelProps {
    unreadCount: number
}

export const UnreadThreadsLabel: FC<UnreadThreadsLabelProps> = ({ unreadCount }) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()
    if (!unreadCount) return null

    return (
        <HStack spacing={0} alignItems="flex-end">
            <Divider ml="40px" h="36px" borderStyle="dotted" orientation="vertical" />
            <Divider h="10px" w="15px" borderStyle="dotted" orientation="horizontal" />
            <Center
                position="absolute"
                left="56px"
                bottom={'14px'}
                w="81px"
                h="32px"
                mt="8px"
                py="6px"
                px="12px"
                bg={`${colorMode}.tones.whiteLilac`}
                borderRadius="26px"
            >
                <Body
                    size="sm"
                    variant="bold"
                    sx={{
                        color: `${colorMode}.tones.navy`,
                        fontWeight: 800
                    }}
                >
                    + {unreadCount} {t('chat.more')}
                </Body>
            </Center>
        </HStack>
    )
}
