import { Flex } from '@chakra-ui/react'
import { Call } from '@missionlabs/api'
import { Body, IconButton } from '@missionlabs/react'
import { PhoneHangupIcon } from '@missionlabs/react/circleloop'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useCallDetails } from 'shared/hooks/useCallDetails'

type OutgoingCallProps = {
    call: Call
    onHangup: () => void
}

export const OutgoingCall: FC<OutgoingCallProps> = ({ call, onHangup }) => {
    const { t } = useTranslation()

    const { contactName, number } = useCallDetails(call)

    return (
        <Flex flexDir="column" gap={2} align="center" w="full">
            <Body color="white" mb={1}>
                {t('Calling')}
            </Body>
            <Body data-testid="outgoing-call-heading" size="lg" color="white">
                {contactName}
            </Body>

            <Body size="md" color="white">
                {number}
            </Body>

            <IconButton
                data-testid="outgoing-call-hangup-button"
                icon={<PhoneHangupIcon color="white" boxSize="32px" />}
                boxSize="80px"
                isRounded
                variant="negatory"
                mt={3}
                onClick={onHangup}
                aria-label="Hang up"
            />
        </Flex>
    )
}
