import { CheckboxGroup, HStack } from '@chakra-ui/react'
import { UpdateCardDetailsArgs } from '@missionlabs/api'
import {
    Checkbox,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps
} from '@missionlabs/react'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FeatureComponent, Features } from 'shared/components/FeatureComponent'
import { useGetClientQuery, useUpdateCardDetailsMutation } from 'shared/store'

import { AddCardPaymentMethodForm } from './AddCardPaymentMethodForm'
import AddDirectDebitPaymentMethodForm from './AddDirectDebitPaymentMethodForm'

const noop = () => {}

export interface AddPaymentMethodDrawerProps extends Omit<DrawerProps, 'children'> {}

export const AddPaymentMethodDrawer: FC<AddPaymentMethodDrawerProps> = ({
    isOpen,
    onClose,
    ...props
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { data: client } = useGetClientQuery()
    const [updatePaymentCard, { error, isLoading }] = useUpdateCardDetailsMutation()

    /**
     * [ZETA-7839]
     * Pre-populate the existing billing address if available
     * so that it's easier to update the card details
     */
    const currentAddress = useMemo(() => client?.currentPlan?.invoiceAddress, [client])

    const [paymentMethod, setPaymentMethod] = useState<'card' | 'dd'>('card')

    function handleTransition() {
        return navigate(-1)
    }

    async function updateCard(details: UpdateCardDetailsArgs) {
        await updatePaymentCard(details).unwrap()
    }

    return (
        <Drawer
            {...props}
            size="lg"
            isOpen={isOpen}
            onClose={!isLoading ? onClose : noop}
            onCloseComplete={handleTransition}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>{t('admin.subscription.paymentMethod.add')}</DrawerHeader>
                <DrawerBody>
                    <HStack spacing="16px" m="24px">
                        <CheckboxGroup>
                            <Checkbox
                                isChecked={paymentMethod === 'card'}
                                onChange={() => setPaymentMethod('card')}
                            >
                                {t('Card payment')}
                            </Checkbox>
                            <FeatureComponent feature={Features.direct_debit}>
                                <Checkbox
                                    isChecked={paymentMethod === 'dd'}
                                    onChange={() => setPaymentMethod('dd')}
                                >
                                    {t('Direct Debit')}
                                </Checkbox>
                            </FeatureComponent>
                        </CheckboxGroup>
                    </HStack>
                    {paymentMethod === 'card' && (
                        <AddCardPaymentMethodForm
                            updateCard={updateCard}
                            error={error}
                            onClose={onClose}
                            values={currentAddress}
                        />
                    )}

                    {paymentMethod === 'dd' && (
                        <AddDirectDebitPaymentMethodForm onClose={onClose} />
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
