import { Tabs as ChakraTabs, TabsProps as ChakraTabsProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface TabsProps extends ChakraTabsProps {
    size?: 'sm' | 'md' | 'lg'
    variant?: 'lineBottom' | 'lineTop' | 'fill' | 'pill'
}

export const Tabs = forwardRef<HTMLDivElement, TabsProps>(
    ({ variant = 'lineBottom', ...props }, ref) => {
        return <ChakraTabs variant={variant} {...props} ref={ref} />
    }
)
