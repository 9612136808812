import { useColorMode } from '@chakra-ui/react'
import { SearchInput } from 'shared/components/SearchInput'

import { useActivitySidebarContext } from '../ActivitySidebarContext'

export const ActivitySearch = () => {
    const { colorMode } = useColorMode()
    const { showFilters, setShowFilters, setFilters, filters, hasAppliedFilters } =
        useActivitySidebarContext()

    return (
        <SearchInput
            id="activity-sidebar-search"
            value={filters.searchTerm}
            onChange={e => setFilters({ searchTerm: e.target.value })}
            filtersActive={hasAppliedFilters}
            toggleFiltersActive={() => setShowFilters(!showFilters)}
            hideFavourites
            sx={{
                backgroundColor: `${colorMode}.tones.white`,
                _hover: {
                    backgroundColor: `${colorMode}.tones.white`
                },
                _active: {
                    color: `${colorMode}.tones.midnightExpress`
                }
            }}
        />
    )
}
