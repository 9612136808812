import { Tooltip } from '@missionlabs/react'
import { AnalyticsCallGroup, AnalyticsNumber, AnalyticsUser } from '@missionlabs/types'
import { createColumnHelper } from '@tanstack/react-table'
import { formatDuration } from 'features/analytics/utils/time'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'

export type AnalyticsTableDataType = AnalyticsCallGroup | AnalyticsUser | AnalyticsNumber

const columnHelper = createColumnHelper<AnalyticsTableDataType>()

interface UseAnalyticsTableColumnsArgs {
    usersData?: AnalyticsUser[]
    callGroupsData?: AnalyticsCallGroup[]
    numbersData?: AnalyticsNumber[]
}

export const useAnalyticsTable = ({
    usersData,
    callGroupsData,
    numbersData
}: UseAnalyticsTableColumnsArgs) => {
    const { t } = useTranslation()

    const { formatToLocalNumber } = useFormatToNumberE164()

    const subjectColumn = useMemo(() => {
        if (usersData)
            return columnHelper.accessor('fullName', {
                header: t('analytics.table.user')
            })

        if (callGroupsData)
            return columnHelper.accessor('name', {
                header: t('analytics.table.team')
            })

        if (numbersData)
            return columnHelper.accessor('numberE164', {
                header: t('analytics.table.number'),
                cell: data => formatToLocalNumber(data.getValue() as string)
            })

        return columnHelper.display({ id: 'title' })
    }, [t, formatToLocalNumber, usersData, callGroupsData, numbersData])

    const commonColumns = useMemo(
        () => [
            columnHelper.accessor('total', {
                header: t('analytics.table.total')
            }),
            columnHelper.accessor('inbound', {
                header: t('analytics.table.in')
            }),
            columnHelper.accessor('outbound', {
                header: t('analytics.table.out')
            }),
            columnHelper.accessor('missed', {
                header: t('analytics.table.missed')
            }),
            columnHelper.accessor('tcd', {
                cell: info => formatDuration(info.getValue()),
                header: () => (
                    <Tooltip label={t('analytics.table.tcd.text')}>
                        {`${t('analytics.table.tcd.acronym')}`}
                    </Tooltip>
                )
            }),
            columnHelper.accessor('acd', {
                cell: info => formatDuration(info.getValue()),
                header: () => (
                    <Tooltip label={t('analytics.table.acd.text')}>
                        {`${t('analytics.table.acd.acronym')}`}
                    </Tooltip>
                )
            }),
            columnHelper.accessor('tta', {
                header: () => (
                    <Tooltip label={t('analytics.table.tta.text')}>
                        {`${t('analytics.table.tta.acronym')} (${t('Seconds')})`}
                    </Tooltip>
                )
            })
        ],
        [t]
    )

    const columns = useMemo(() => {
        return [subjectColumn, ...commonColumns]
    }, [subjectColumn, commonColumns])

    const data = usersData ?? callGroupsData ?? numbersData ?? []

    return {
        columns,
        data
    }
}
