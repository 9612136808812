import { FormProvider, useYupForm, yup } from '@missionlabs/react'
import { Client } from '@missionlabs/types'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetClientQuery, useUpdateClientMutation } from 'shared/store'

import { CompanyDetailsForm } from './CompanyDetailsForm'
import { CompanyMusicForm } from './CompanyMusicForm'
import { ContactsExport } from './ContactsExport'
import { ContactsImport } from './ContactsImport'

const schema = yup.object().shape({
    name: yup.string().trim().required(),
    locale: yup.string(),
    timezone: yup.string(),
    customHoldMusicURL: yup.string(),
    customWaitMusicURL: yup.string()
})

export type GlobalSettingsFormFields = Pick<
    Client,
    'name' | 'locale' | 'timezone' | 'customHoldMusicURL' | 'customWaitMusicURL'
>

function getValues(client: Client | undefined): GlobalSettingsFormFields | undefined {
    if (!client) return
    return {
        name: client?.name ?? '',
        locale: client?.locale,
        timezone: client?.timezone ?? '',
        customHoldMusicURL: client?.customHoldMusicURL,
        customWaitMusicURL: client?.customWaitMusicURL
    }
}

export const GlobalSettings: FC = () => {
    const { t } = useTranslation()
    const { data: client } = useGetClientQuery()
    const [updateClient] = useUpdateClientMutation()

    const values = useMemo(() => getValues(client), [client])

    const methods = useYupForm<GlobalSettingsFormFields>(schema, {
        values,
        mode: 'onBlur'
    })

    function handleSubmit(data: GlobalSettingsFormFields) {
        if (!client) return
        updateClient({
            status: client.status,
            ...data
        })
    }

    return (
        <FormProvider {...methods}>
            <form onBlur={methods.handleSubmit(handleSubmit)}>
                <SettingsPage
                    maxW="100%"
                    title={t('globalSettings.heading')}
                    subtitle={t('globalSettings.subheading')}
                >
                    <CompanyDetailsForm />
                    <CompanyMusicForm />
                    <ContactsImport />
                    <ContactsExport />
                </SettingsPage>
            </form>
        </FormProvider>
    )
}
