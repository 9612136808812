import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { listAnatomy } from 'theme/anatomy'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([...listAnatomy.keys])

export const ListStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => ({
        title: {
            width: '100%',
            minHeight: '57px', // +1 for border
            spacing: 0,
            padding: '8px',
            justifyContent: 'space-between',
            borderBottom: '1px solid',
            borderColor: `${colorMode}.tones.periwinkle`
        },
        heading: {
            size: 'h3',
            fontSize: '16px',
            lineHeight: '28.16px'
        },
        item: {
            width: '100%',
            spacing: '8px',
            paddingX: '8px',
            paddingY: '12px',
            borderBottom: '1px solid',
            borderColor: `${colorMode}.tones.periwinkle`,
            justifyContent: 'space-between'
        }
    }),
    defaultProps: {
        variant: 'interactive'
    },
    variants: {
        interactive: ({ colorMode }) => ({
            item: {
                cursor: 'pointer',
                _active: {
                    bg: 'white'
                },
                _hover: {
                    bg: `${colorMode}.tones.whiteLilac`,
                    boxShadow: 'sm'
                }
            }
        }),
        'non-interactive': () => ({
            item: {
                cursor: 'default'
            }
        })
    }
})
