import { Center } from '@chakra-ui/react'
import { Heading } from '@missionlabs/react'
import PlaceholderVideoTile from 'components/VideoTiles/PlaceholderVideoTile'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const WaitingTile: FC = () => {
    const { t } = useTranslation()
    return (
        <PlaceholderVideoTile
            muted={false}
            placeholderElement={
                <Center w="full" h="full">
                    <Heading size="h3" variant="bold" color="white">
                        {t('Waiting for others to join')}
                    </Heading>
                </Center>
            }
        />
    )
}
