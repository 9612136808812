import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { listAnatomy } from 'theme/anatomy'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([...listAnatomy.keys])

export const ListStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => ({
        title: {
            width: '100%',
            minHeight: '72px', // +1 for border
            py: '16px',
            pl: '24px',
            pr: '16px',
            justifyContent: 'space-between'
        },
        heading: {
            size: 'h3',
            fontSize: '16px',
            lineHeight: '28.16px',
            fontWeight: '500'
        },
        item: {
            width: '100%',
            spacing: '8px',
            padding: '16px',
            borderY: '1px solid',
            borderColor: `${colorMode}.tones.periwinkle`,
            borderX: '1px solid transparent',
            justifyContent: 'space-between',
            transition: 'all 200ms ease'
        }
    }),
    defaultProps: {
        variant: 'interactive'
    },
    variants: {
        // fixme
        // interactive: ({ colorMode }) => {
        //     const border = {
        //         border: '1px solid',
        //         borderColor: `${colorMode}.coolGrey.30`,
        //         borderRadius: '4px',
        //         paddingLeft: '15px',
        //         paddingBottom: '11px'
        //     }
        //     return {
        //         item: {
        //             cursor: 'pointer',
        //             _active: {
        //                 bg: `${colorMode}.coolGrey.0`,
        //                 ...border
        //             },
        //             _focus: {
        //                 bg: `${colorMode}.coolGrey.10`,
        //                 ...border
        //             },
        //             _hover: {
        //                 ...border
        //             },
        //             sx: {
        //                 '&:active + &, &[data-active] + &': {
        //                     borderTop: 'none',
        //                     paddingTop: '13px'
        //                 },
        //                 '&:focus + &, &[data-focus] + &': {
        //                     borderTop: 'none',
        //                     paddingTop: '13px'
        //                 },
        //                 '&:hover + &': {
        //                     borderTop: 'none',
        //                     paddingTop: '13px'
        //                 }
        //             }
        //         }
        //     }
        // },
        interactive: ({ colorMode }) => ({
            item: {
                cursor: 'pointer',
                _hover: {
                    borderColor: `${colorMode}.tones.periwinkle`,
                    borderX: `solid 1px ${colorMode}.tones.periwinkle`,
                    boxShadow: 'sm',
                    borderRadius: '5px'
                },
                _active: {
                    bg: `${colorMode}.tones.white`,
                    borderX: '1px solid',
                    borderColor: `${colorMode}.tones.periwinkle`,
                    borderRadius: '5px'
                }
            }
        }),
        'non-interactive': () => ({
            item: {
                cursor: 'default'
            }
        })
    }
})
