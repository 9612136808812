import Metric, { MetricData } from './metric'

export interface APIMetricData extends MetricData {
    url: string
    method: string
    body: any
    status: number
    data: any
}

class APIMetric extends Metric<APIMetricData> {
    constructor(data: any, req: Request, res: Response, meta?: APIMetricData['meta']) {
        super({
            url: req.url,
            method: req.method,
            body: req.body ? req.json() : null,
            status: res.status,
            data: data,
            meta: meta
        })
    }
}

export default APIMetric
