import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const AttachmentIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AttachmentIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 15.5888C19 16.2685 18.4898 16.8195 17.8605 16.8195C17.231 16.8195 16.7209 16.2685 16.7209 15.5888V7.69231C16.7209 4.79782 14.6019 2.46154 11.9999 2.46154C9.39801 2.46154 7.27906 4.79782 7.27906 7.69231V18.4615C7.27906 20.1666 8.5234 21.5385 10.0465 21.5385C11.5697 21.5385 12.8139 20.1666 12.8139 18.4615V7.69231C12.8139 7.17689 12.4442 6.76923 11.9999 6.76923C11.5558 6.76923 11.186 7.17689 11.186 7.69231V17.0256C11.186 17.7054 10.6758 18.2564 10.0465 18.2564C9.41716 18.2564 8.90697 17.7054 8.90697 17.0256V7.69231C8.90697 5.82866 10.2864 4.30769 11.9999 4.30769C13.7135 4.30769 15.0929 5.82866 15.0929 7.69231V18.4615C15.0929 21.5147 12.8389 24 10.0465 24C7.25407 24 5 21.5147 5 18.4615V7.69231C5 3.44958 8.12868 0 11.9999 0C15.8713 0 19 3.44958 19 7.69231V15.5888Z"
            fill="currentColor"
        />
    )
})
