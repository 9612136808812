import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
    $createRangeSelection,
    $getRoot,
    $getSelection,
    $insertNodes,
    $setSelection,
    createCommand,
    LexicalCommand
} from 'lexical'
import { FC, useEffect } from 'react'

import { $createForwardMessageNode } from '../nodes/ForwardedNode'

export interface ReplyPluginProps {}

export const INSERT_FORWARD_COMMAND: LexicalCommand<{
    ID: string
    from: string
    message: string
}> = createCommand('INSERT_FORWARD_COMMAND')

export const FORWARD_CHAT_EVENT = 'FORWARD_CHAT_EVENT'

export const dispatchForwardEvent = (ID: string, from: string, message: string) => {
    return document.dispatchEvent(
        new CustomEvent(FORWARD_CHAT_EVENT, { detail: { ID, from, message } })
    )
}

export const ForwardPlugin: FC<ReplyPluginProps> = () => {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        const insertForward = e => {
            const { ID, from, message } = e.detail

            editor.update(() => {
                const currSelection = $getSelection()
                const rangeSelection = $createRangeSelection()
                const root = $getRoot()
                const nodes = root.getChildren()
                const existingForward = nodes.find(
                    n => n.__type === 'forward' || n.__type === 'forward_message'
                )

                if (existingForward) {
                    existingForward.remove()
                }

                $setSelection(rangeSelection)
                $insertNodes([$createForwardMessageNode(ID, from, message)])
                $setSelection(currSelection)
            })
        }

        document.addEventListener(FORWARD_CHAT_EVENT, insertForward)
        return () => document.removeEventListener(FORWARD_CHAT_EVENT, insertForward)
    }, [editor])

    return null
}
