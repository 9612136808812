import { useApplyVideoObjectFit, useAttendeeStatus } from 'amazon-chime-sdk-component-library-react'
import { BaseSdkProps } from 'amazon-chime-sdk-component-library-react/lib/components/sdk/Base/index'
import { useActiveSpeakerIndicator } from 'hooks/useActiveSpeakerIndicator'
import { useBindVideoElement } from 'hooks/useBindVideoElement'
import { FC, HTMLAttributes, PropsWithChildren, ReactNode, useRef } from 'react'

import PlaceholderVideoTile from '../PlaceholderVideoTile/index'

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'css'>, BaseSdkProps {
    /** The tile ID to bind the video element to */
    tileId: number
    /** The name to show on the video's nameplate */
    name?: string
    placeholder?: string
    placeholderElement?: ReactNode
    chimeAttendeeId: string
}

export const RemoteVideoTileExtended: FC<PropsWithChildren<Props>> = ({
    name,
    className,
    tileId,
    chimeAttendeeId,
    ...rest
}) => {
    const { videoEnabled, muted } = useAttendeeStatus(chimeAttendeeId)

    // dummyVideoEl doesn't actually get assigned to anything,
    // we just use it as a fallback so that we can still pass a HTMLVideoElement ref to useApplyVideoObjectFit
    // which doesn't allow it to be undefined, and we can't call hooks conditionally
    const dummyVideoEl = useRef<HTMLVideoElement>(null)
    const videoRef = useBindVideoElement(tileId, videoEnabled)
    useApplyVideoObjectFit(videoRef ?? dummyVideoEl)

    const { nameplateRef, speakerIconRef } = useActiveSpeakerIndicator(chimeAttendeeId)

    return (
        <PlaceholderVideoTile
            {...rest}
            ref={videoRef}
            nameplate={name}
            className={`${className || ''}`}
            muted={muted}
            nameplateRef={nameplateRef}
            speakerIconRef={speakerIconRef}
            tag={'div'}
        />
    )
}

export default RemoteVideoTileExtended
