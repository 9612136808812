import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { useGetNumbersForUserQuery, useGetWhatsAppNumbersQuery } from 'shared/store'

/**
 * Gets a list of WhatsApp numbers for the user
 * @returns boolean
 */
export const useGetMyWhatsAppNumbers = () => {
    const user = useSelector(selectAuthenticatedUser)
    const { data: userNumberQuery } = useGetNumbersForUserQuery(user?.userID ?? '')
    const { data: whatsappNumbers = [] } = useGetWhatsAppNumbersQuery()

    if (!userNumberQuery) return { myWhatsAppNumbers: [], myWhatsAppNumberOptions: [] }

    const { phoneNumbers = [] } = userNumberQuery

    const myWhatsAppNumbers = phoneNumbers.filter(number =>
        whatsappNumbers.some(whatsappNumber => whatsappNumber.numberID === number.ID)
    )
    const myWhatsAppNumberOptions = myWhatsAppNumbers.map(number => ({
        label: number.numberE164,
        value: number.ID
    }))

    return { myWhatsAppNumbers, myWhatsAppNumberOptions }
}
