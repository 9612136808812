import { Center } from '@chakra-ui/react'
import { Body, Scrollable } from '@missionlabs/react'
import { ChatMessage } from '@missionlabs/types'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList } from 'react-window'
import { useGetUserFavouriteContactsQuery } from 'shared/store'

import { isSection, Section } from './ChatList/ChatList'
import { ChatListSkeleton } from './ChatList/ChatListSkeleton'
import { ChatMessageListRenderer } from './ChatList/ChatMessageListRenderer'
interface SearchChatViewProps {
    messages: ChatMessage[]
    favourites: ReturnType<typeof useGetUserFavouriteContactsQuery>['data']
    isLoading: boolean
}

export type VirtualisedListItem = ChatMessage | Section

export const SearchChatView: FC<SearchChatViewProps> = ({ messages, isLoading, favourites }) => {
    const { t } = useTranslation()

    // todo: sort by recent message ascending
    const grouped = useMemo(() => {
        const items: VirtualisedListItem[] = []

        const sortedGroups = messages
            .filter(item => item.channel?.team)
            .sort((a, b) => b.updated - a.updated)
        if (sortedGroups.length) {
            // add group section
            items.push({ object: 'section', title: t('Groups') })
            items.push(...sortedGroups)
        }

        const sortedChannels = messages
            .filter(
                item =>
                    !favourites?.contacts.some(f => f.ID === item.channel?.ID) && item.channel?.user
            )
            .sort((a, b) => b.updated - a.updated)

        if (sortedChannels.length) {
            // add users section
            items.push({ object: 'section', title: t('Users') })
            items.push(...sortedChannels)
        }

        return items
    }, [favourites?.contacts, messages, t])

    const rowHeights = useMemo(() => grouped.map(item => (isSection(item) ? 57 : 70)), [grouped])

    const getRowSize = (index: number) => rowHeights[index]

    if (isLoading) return <ChatListSkeleton />
    if (!messages.length)
        return (
            <Center p={5}>
                <Body>{t('No results found')}</Body>
            </Center>
        )
    return (
        <AutoSizer>
            {({ width, height }) => (
                <VariableSizeList
                    height={height}
                    width={width}
                    itemCount={grouped.length}
                    itemData={grouped}
                    itemSize={getRowSize}
                    outerElementType={Scrollable}
                >
                    {ChatMessageListRenderer}
                </VariableSizeList>
            )}
        </AutoSizer>
    )
}
