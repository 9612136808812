export interface ContactEmail {
    email: string
}

export interface ContactPhoneNumber {
    label?: string
    numberE164: string
    _default?: boolean
}

export interface ContactCustomFields {
    intercomExternalID?: string
    portalIdStr?: string
}

export interface ContactGroup {
    ID: string
    name: string
    favourite?: boolean
    contacts: string[]
    _contacts: DirectoryEntry[]
    userID: string
    clientID: string
    tags?: string[]
    location?: string
    object?: string
}

export const isContactGroup = (x?: any): x is ContactGroup =>
    typeof x === 'object' && 'contacts' in x

export interface DirectoryEntry {
    ID: string
    clientID: string
    userID?: string
    activityCount?: number
    companyName?: string
    customFields?: ContactCustomFields
    deleteDate?: number
    deleteDateTTL?: number
    deleteFlag: boolean
    emails?: ContactEmail[]
    extension?: string
    externalID?: string
    externalURL?: string
    favourite?: any
    firstLetter?: string
    firstName?: string
    fullName?: string
    global?: boolean
    hash?: number
    importID?: string
    importType?: string
    initials?: string
    jobTitle?: string
    lastActivity?: number
    lastLetter?: string
    lastName?: string
    location?: string
    nameDiallingNumberFL?: string
    nameDiallingNumberLF?: string
    object?: string
    phoneNumbers?: ContactPhoneNumber[]
    photoURL?: string
    searchFields?: string[]
    social?: any
    source?: string
    tags?: string[]
    ttl?: number
}

export type ContactType = 'group' | 'internal' | 'external'
