import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ReplyIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ReplyIconLight',
    viewBox: '0 0 20 16',
    path: (
        <path
            d="M20 13.616V14.866C20 15.2175 19.6875 15.491 19.375 15.491C19.0234 15.491 18.75 15.2175 18.75 14.866V13.655C18.75 10.5691 16.2109 8.0691 13.125 8.0691H2.34375L8.51562 13.2253C8.78906 13.4597 8.78906 13.8503 8.59375 14.1238C8.47656 14.1628 8.28125 14.241 8.125 14.241C7.96875 14.241 7.8125 14.2019 7.69531 14.1238L0.195312 7.91285C0.078125 7.7566 0 7.56129 0 7.36597C0 7.17066 0.078125 6.97535 0.195312 6.85816L7.69531 0.647223C7.96875 0.45191 8.35938 0.45191 8.59375 0.725348C8.78906 0.998785 8.78906 1.38941 8.51562 1.62379L2.34375 6.74097H13.125C16.9141 6.74097 20 9.82691 20 13.616Z"
            fill="currentColor"
        />
    )
})

export const ReplyIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ReplyIconRegular',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M20 12.0926V16.7801C20 17.327 19.5703 17.7176 19.0625 17.7176C18.5156 17.7176 18.125 17.327 18.125 16.8192V12.1707C18.125 9.94417 16.2891 8.1473 14.0625 8.1473H3.4375L8.39844 12.4442C8.78906 12.7957 8.82812 13.3817 8.51562 13.7723C8.32031 13.8895 8.04688 13.9676 7.8125 13.9676C7.57812 13.9676 7.34375 13.9285 7.1875 13.7723L0.3125 7.87386C0.117188 7.63949 0 7.36605 0 7.09261C0 6.81917 0.117188 6.5848 0.3125 6.38949L7.1875 0.491048C7.53906 0.178548 8.16406 0.217611 8.51562 0.608236C8.82812 0.998861 8.78906 1.5848 8.39844 1.93636L3.4375 6.15511H14.0625C17.3047 6.15511 20 8.85042 20 12.0926Z"
            fill="currentColor"
        />
    )
})

export const ReplyIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ReplyIconSolid',
    viewBox: '0 0 22 18',
    path: (
        <path
            d="M1.31738 6.38526L8.19238 0.48682C8.77832 -0.0209923 9.75488 0.408695 9.75488 1.18995V4.35401C16.0049 4.35401 21.0049 5.64307 21.0049 11.5806C21.0049 13.9634 19.4424 16.3462 17.7236 17.5962C17.2158 17.9868 16.4346 17.479 16.6299 16.854C18.3877 11.1899 15.8096 9.70557 9.75488 9.58838V13.0259C9.75488 13.8462 8.77832 14.2759 8.19238 13.7681L1.31738 7.83057C0.887695 7.43995 0.887695 6.77588 1.31738 6.38526Z"
            fill="currentColor"
        />
    )
})

export const ReplyAllIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ReplyAllIconLight',
    viewBox: '0 0 24 16',
    path: (
        <path
            d="M16.375 6.74097H8.09375L14.2656 1.62379C14.5391 1.38941 14.5391 0.998785 14.3438 0.725348C14.1094 0.45191 13.7188 0.45191 13.4453 0.647223L5.94531 6.85816C5.82812 7.01441 5.75 7.20972 5.75 7.36597C5.75 7.52222 5.82812 7.71754 5.94531 7.83472L13.4453 14.0457C13.5625 14.1238 13.7188 14.2019 13.875 14.2019C14.0312 14.2019 14.2266 14.1238 14.3438 13.9675C14.5391 13.6941 14.5391 13.3035 14.2656 13.0691L8.09375 7.99097H16.375C19.4609 7.99097 22 10.53 22 13.616V14.8269C22 15.2175 22.2734 15.491 22.625 15.491C22.9375 15.491 23.25 15.2175 23.25 14.905V13.6941C23.25 9.82691 20.1641 6.74097 16.375 6.74097ZM2.35156 7.36597L9.26562 1.62379C9.53906 1.38941 9.53906 0.998785 9.34375 0.725348C9.10938 0.45191 8.71875 0.45191 8.44531 0.647223L0.945312 6.85816C0.828125 7.01441 0.75 7.20972 0.75 7.36597C0.75 7.52222 0.828125 7.71754 0.945312 7.83472L8.44531 14.0457C8.5625 14.1238 8.71875 14.2019 8.875 14.2019C9.03125 14.2019 9.22656 14.1238 9.34375 13.9675C9.53906 13.6941 9.53906 13.3035 9.26562 13.0691L2.35156 7.36597Z"
            fill="currentColor"
        />
    )
})

export const ReplyAllIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ReplyAllIconRegular',
    viewBox: '0 0 24 18',
    path: (
        <path
            d="M3.09375 7.11214L9.10938 1.91683C9.5 1.56527 9.53906 0.979329 9.22656 0.588704C8.875 0.198079 8.28906 0.159017 7.89844 0.471517L1.02344 6.36995C0.867188 6.60433 0.75 6.8387 0.75 7.11214C0.75 7.38558 0.867188 7.65902 1.0625 7.85433L7.9375 13.7528C8.09375 13.9481 8.32812 13.9871 8.5625 13.9871C8.79688 13.9871 9.07031 13.909 9.26562 13.6746C9.57812 13.284 9.53906 12.6981 9.14844 12.3465L3.09375 7.11214ZM17.3125 6.17464H9.1875L14.1484 1.91683C14.5391 1.56527 14.5781 0.979329 14.2656 0.588704C13.9141 0.198079 13.3281 0.159017 12.9375 0.471517L6.0625 6.40902C5.86719 6.60433 5.75 6.8387 5.75 7.11214C5.75 7.38558 5.86719 7.65902 6.0625 7.85433L12.9375 13.7528C13.0938 13.909 13.3281 13.9871 13.5625 13.9871C13.7969 13.9871 14.0703 13.87 14.2656 13.6356C14.5781 13.245 14.5391 12.659 14.1484 12.3075L9.1875 8.04964H17.3125C19.5391 8.04964 21.375 9.88558 21.375 12.1121V16.7606C21.375 17.3465 21.7656 17.7371 22.3125 17.7371C22.8203 17.7371 23.25 17.3465 23.25 16.7996V12.1512C23.25 8.86995 20.5547 6.17464 17.3125 6.17464Z"
            fill="currentColor"
        />
    )
})

export const ReplyAllIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ReplyAllIconSolid',
    viewBox: '0 0 24 18',
    path: (
        <path
            d="M6.08691 7.86616L12.9619 13.7646C13.5479 14.2724 14.4854 13.8427 14.4854 13.0615V9.78022C18.7432 10.249 20.4229 12.0458 18.8994 16.8505C18.7041 17.5146 19.4854 17.9833 19.9932 17.5927C21.7119 16.3427 23.2354 13.9599 23.2354 11.5771C23.2354 6.45991 19.5635 4.81929 14.4854 4.42866V1.22554C14.4854 0.405224 13.5479 -0.0244632 12.9619 0.483349L6.08691 6.42085C5.65723 6.81147 5.65723 7.47554 6.08691 7.86616ZM1.08691 7.86616L7.96191 13.7646C8.54785 14.2724 9.52441 13.8427 9.52441 13.0615V12.4365L3.35254 7.12397L9.52441 1.81147V1.22554C9.52441 0.405224 8.54785 -0.0244632 7.96191 0.483349L1.08691 6.38179C0.657227 6.81147 0.657227 7.47554 1.08691 7.86616Z"
            fill="currentColor"
        />
    )
})
