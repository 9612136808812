import { extendTheme } from '@chakra-ui/react'
import { AvatarBadgeStyles, AvatarStyles } from 'theme/zeta/avatar'
import { BannerStyles } from 'theme/zeta/banner'
import { borders } from 'theme/zeta/borders/borders'
import { ButtonStyles } from 'theme/zeta/button'
import { ActiveCallStyles, ParkedCallStyles, RingingCallStyles } from 'theme/zeta/calls'
import { CheckboxStyles } from 'theme/zeta/checkbox'
import { colors } from 'theme/zeta/colors/colors'
import { ContactDetailStyles } from 'theme/zeta/contactDetail'
import { ContainerStyles } from 'theme/zeta/container'
import { DiallerKeyStyles, DialPadStyles } from 'theme/zeta/dialler'
import { DividerStyles } from 'theme/zeta/divider'
import { DrawerStyles } from 'theme/zeta/drawer'
import { EmptyPageStyles } from 'theme/zeta/emptyPage'
import { HeaderAvatarStyles } from 'theme/zeta/headerAvatar'
import { IconButtonStyles } from 'theme/zeta/iconButton'
import { InputStyles } from 'theme/zeta/input'
import { LabelStyles } from 'theme/zeta/label'
import { ListStyles } from 'theme/zeta/list'
import { MenuStyles } from 'theme/zeta/menu'
import { ModalStyles } from 'theme/zeta/modal'
import { NavbarStyles } from 'theme/zeta/navbar'
import { NotificationStyles } from 'theme/zeta/notification'
import { PlayerStyles } from 'theme/zeta/player'
import { ProgressBarStyles } from 'theme/zeta/progressBar'
import { RadioStyles } from 'theme/zeta/radio'
import { ScrollableStyles } from 'theme/zeta/scrollable'
import { SearchInputStyles } from 'theme/zeta/searchInput'
import { SelectStyles } from 'theme/zeta/select'
import { shadows } from 'theme/zeta/shadows/shadows'
import { SliderStyles } from 'theme/zeta/slider'
import { SpinnerStyles } from 'theme/zeta/spinner'
import { StepsStyles, StepStyles } from 'theme/zeta/steps'
import { TableStyles } from 'theme/zeta/table'
import { TabsStyles } from 'theme/zeta/tabs'
import { TagStyles } from 'theme/zeta/tag'
import { TextareaStyles } from 'theme/zeta/textarea'
import { ToggleStyles } from 'theme/zeta/toggle'
import { TooltipStyles } from 'theme/zeta/tooltip'
import { BodyStyles, HeadingStyles } from 'theme/zeta/typography'

import { ImageSelectStyles } from './imageSelect'
import { ToastStyles } from './toast'

export const theme = {
    config: {
        initialColorMode: 'light',
        useSystemColorMode: false
    },
    fonts: {
        body: 'Lato',
        heading: 'Mulish'
    },
    colors,
    shadows,
    borders,
    radii: {},
    components: {
        ActiveCall: ActiveCallStyles,
        Text: BodyStyles,
        Button: ButtonStyles,
        IconButton: IconButtonStyles,
        Heading: HeadingStyles,
        Checkbox: CheckboxStyles,
        DiallerKey: DiallerKeyStyles,
        DialPad: DialPadStyles,
        Tooltip: TooltipStyles,
        Avatar: AvatarStyles,
        AvatarBadge: AvatarBadgeStyles,
        Tabs: TabsStyles,
        Switch: ToggleStyles,
        Spinner: SpinnerStyles,
        Step: StepStyles,
        Steps: StepsStyles,
        Label: LabelStyles,
        Menu: MenuStyles,
        Drawer: DrawerStyles,
        ContactDetail: ContactDetailStyles,
        Notification: NotificationStyles,
        Navbar: NavbarStyles,
        CustomInput: InputStyles,
        Divider: DividerStyles,
        Container: ContainerStyles,
        Textarea: TextareaStyles,
        RingingCall: RingingCallStyles,
        Radio: RadioStyles,
        Player: PlayerStyles,
        SearchInput: SearchInputStyles,
        List: ListStyles,
        HeaderAvatar: HeaderAvatarStyles,
        Modal: ModalStyles,
        Scrollable: ScrollableStyles,
        Slider: SliderStyles,
        Table: TableStyles,
        Banner: BannerStyles,
        ParkedCall: ParkedCallStyles,
        CustomSelect: SelectStyles,
        Tag: TagStyles,
        ImageSelect: ImageSelectStyles,
        EmptyPage: EmptyPageStyles,
        Toast: ToastStyles,
        ProgressBar: ProgressBarStyles
    }
}

/**
 * @see https://chakra-ui.com/docs/theming/customize-theme
 */
const zetaTheme = extendTheme(theme)

export default zetaTheme
