import { VStack } from '@chakra-ui/react'
import { ContactType } from '@missionlabs/types'
import { isInternalContact } from '@missionlabs/utils'
import { ContactsEmptyPage } from 'features/contacts/ContactsEmptyPage'
import { FC, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useGetContactQuery } from 'shared/store'

import { ContactHeader } from '../ContactsHeader/ContactHeader'
import { ContactTabs } from '../ContactTabs/ContactTabs'

export const ContactsView: FC = () => {
    const { id = '' } = useParams()
    const { pathname } = useLocation()

    const {
        data: contact,
        isLoading: contactLoading,
        isError: contactError
    } = useGetContactQuery(id, { skip: pathname.includes('/group') || !id })

    const type = useMemo<ContactType | undefined>(() => {
        if (!contact) return
        if (contact?.source === 'teamprofile') return 'group'
        return isInternalContact(contact) ? 'internal' : 'external'
    }, [contact])

    const data = contact
    const isLoading = contactLoading
    const isError = contactError

    // todo: skeleton ui
    if (isLoading) {
        return <ContactsEmptyPage />
    }

    // todo: handle no contact loaded
    if (isError || !data || !type) {
        return <ContactsEmptyPage />
    }

    return (
        <VStack as="main">
            <ContactHeader contact={data} type={type} />
            <ContactTabs contact={data} type={type} />
        </VStack>
    )
}
