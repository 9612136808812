import { FC } from 'react'
import { Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom'

import { InVideoCall } from './components/InVideoCall'
import { VideoCallEnded, VideoCallEndReason } from './components/VideoCallEnded'
import { WaitingRoom } from './components/WaitingRoom'
import { MeetingCallRootContext } from './MeetingsCallRoot'

type GetVideoCallEndReasonArgs = Pick<MeetingCallRootContext, 'meeting' | 'error'>

function getVideoCallEndReason({ meeting, error }: GetVideoCallEndReasonArgs) {
    if (error === 'disconnected') return VideoCallEndReason.disconnected
    if (error === 'ended' || meeting?.answered) return VideoCallEndReason.ended
    return VideoCallEndReason.rejected
}

export const MeetingsCallView: FC = () => {
    const { id = '' } = useParams()
    const { meeting, error, isGuest, setupComplete } = useOutletContext<MeetingCallRootContext>()

    if (error) {
        return <VideoCallEnded reason={getVideoCallEndReason({ meeting, error })} message={error} />
    }

    if (isGuest && !setupComplete) {
        return <Navigate to="/setup" replace state={{ id }} />
    }

    if (isGuest && !meeting?.joinInfo) {
        return <WaitingRoom meetingID={meeting?.meetingID ?? id} />
    }

    return (
        <>
            <InVideoCall />
            <Outlet />
        </>
    )
}
