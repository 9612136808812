import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ItalicsIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ItalicsIconLight',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M15.5 0.875C15.5 1.22656 15.1875 1.5 14.875 1.5H11.5938L5.65625 16.5H8.625C8.9375 16.5 9.25 16.8125 9.25 17.125C9.25 17.4766 8.9375 17.75 8.625 17.75H1.125C0.773438 17.75 0.5 17.4766 0.5 17.125C0.5 16.8125 0.773438 16.5 1.125 16.5H4.36719L10.3047 1.5H7.375C7.02344 1.5 6.75 1.22656 6.75 0.875C6.75 0.5625 7.02344 0.25 7.375 0.25H14.875C15.1875 0.25 15.5 0.5625 15.5 0.875Z"
            fill="currentColor"
        />
    )
})

export const ItalicsIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ItalicsIconRegular',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M15.5 1.1875C15.5 1.73438 15.0703 2.125 14.5625 2.125H11.9062L6.16406 15.875H9.5625C10.0703 15.875 10.5 16.3047 10.5 16.8125C10.5 17.3594 10.0703 17.75 9.5625 17.75H1.4375C0.890625 17.75 0.5 17.3594 0.5 16.8125C0.5 16.3047 0.890625 15.875 1.4375 15.875H4.17188L9.91406 2.125H6.4375C5.89062 2.125 5.5 1.73438 5.5 1.1875C5.5 0.679688 5.89062 0.25 6.4375 0.25H14.5625C15.0703 0.25 15.5 0.679688 15.5 1.1875Z"
            fill="currentColor"
        />
    )
})

export const ItalicsIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ItalicsIconSolid',
    viewBox: '0 0 16 18',
    path: (
        <path
            d="M15.5 1.5C15.5 2.16406 14.9141 2.75 14.25 2.75H11.9453L6.75 15.25H9.25C9.91406 15.25 10.5 15.7969 10.5 16.5C10.5 17.1641 9.91406 17.75 9.25 17.75H1.75C1.04688 17.75 0.5 17.1641 0.5 16.5C0.5 15.7969 1.04688 15.25 1.75 15.25H4.01562L9.21094 2.75H6.75C6.04688 2.75 5.5 2.16406 5.5 1.5C5.5 0.796875 6.04688 0.25 6.75 0.25H14.25C14.9141 0.25 15.5 0.796875 15.5 1.5Z"
            fill="currentColor"
        />
    )
})
