import { useColorMode, VStack } from '@chakra-ui/react'
import { Body, Button } from '@missionlabs/react'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface SoundJabraDeviceSettingProps {
    deviceName?: string
    onClick: () => void
}

export const SoundJabraDeviceSetting: FC<SoundJabraDeviceSettingProps> = ({
    deviceName,
    onClick
}) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    return (
        <SettingsRow divider={false} label={t('settings.sounds.jabraDevice')}>
            <VStack spacing="8px" align="inherit">
                <Body size="sm" variant="bold" sx={{ color: `${colorMode}.tones.navy` }}>
                    {t('settings.sounds.chooseDevice')}
                </Body>
                <Body size="sm">{deviceName}</Body>
                <div />
                <Button key="selectDevice" variant="creationary" onClick={onClick}>
                    {t('Pair device')}
                </Button>
            </VStack>
        </SettingsRow>
    )
}
