// @ts-ignore
import c from 'chalk'
import dayjs from 'dayjs'

import { Level } from './levels'

export interface Info {
    date: Date
    level: string
    message: string
}

type Formatter = (info: Info) => string

const colors: Record<Level, (str: string) => string> = {
    error: str => c.bold.bgRedBright.black(str),
    warn: str => c.bold.bgYellowBright.black(str),
    info: str => c.bold.bgCyanBright.black(str),
    debug: str => c.bold.bgBlueBright.black(str),
    trace: str => c.bold.bgWhiteBright.black(str),
    verbose: str => str
}

export const format = {
    date:
        (format: string, colorize = false): Formatter =>
        ({ date }) => {
            const text = `[${dayjs(date).format(format)}] `
            if (colorize) return c.grey.dim(text)
            return text
        },
    message:
        (): Formatter =>
        ({ message }) =>
            message,
    text:
        (message: string): Formatter =>
        () =>
            message,
    level:
        (colorize = false): Formatter =>
        ({ level }) => {
            const text = level.toUpperCase()
            if (colorize) return colors[level](` ${text} `)
            return text
        },
    newLine:
        (count = 1): Formatter =>
        () =>
            '\n'.repeat(count)
}

export const createTemplate = (...fns: Formatter[]) => {
    return (info: Info) => {
        return fns.reduce((prev, curr) => {
            return `${prev}${curr(info)}`
        }, '')
    }
}
