import { Box, Collapse, useDisclosure, VStack } from '@chakra-ui/react'
import { Call } from '@missionlabs/api'
import { Body, VirtualPaginationContextProvider } from '@missionlabs/react'
import ContactsProvider from 'features/contacts/context/contactsContext'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OtherDeviceCallDetails } from 'shared/hooks/useDialState'

import { CallDetails } from './CallDetails'
import { TransferCallTarget, TransferList } from './TransferList/TransferList'

interface ActiveCallOtherDeviceProps {
    callDetails?: OtherDeviceCallDetails
}

type TransferDetails = Pick<
    Call,
    'transferToContact' | 'transfererCompanyName' | 'transfereeNumber'
>

/**
 * Screen to show when there's an ongoing call on another device.
 * Prevents the user from making a new call.
 */
export const ActiveCallOtherDevice = ({ callDetails }: ActiveCallOtherDeviceProps) => {
    const { t } = useTranslation()
    const transfer = useDisclosure()

    // Normally, these fields would be set on the call when status changes in liveservices
    // but since we don't have the actual call, we have to keep track of them in state locally
    const [transferDetails, setTransferDetails] = useState<TransferDetails | undefined>()

    const call = useMemo((): Call | undefined => {
        if (!callDetails) return
        return {
            ...callDetails,
            ...(transferDetails || {}),
            startTimestamp: callDetails?.answeredTime,
            status: transferDetails ? 'TRANSFERRING' : 'CONNECTED'
        }
    }, [callDetails, transferDetails])

    const { onClose } = transfer
    const onTransferInitiated = useCallback(
        (target?: TransferCallTarget) => {
            onClose()
            setTransferDetails({
                transferToContact: target?.contact,
                transfererCompanyName: target?.contact?.companyName
            })
        },
        [setTransferDetails, onClose]
    )

    return (
        <Box m="24px">
            <Body color="white">{t('calls.activeOnOtherDevice')}</Body>
            {call && (
                <VStack h="full" w="full" spacing="24px" mt="14px">
                    <VStack spacing="16px" w="full">
                        <CallDetails
                            call={call}
                            primaryAction="transfer"
                            onTransfer={transfer.onToggle}
                            isSelectingTransfer={transfer.isOpen}
                            onTransferComplete={() => {
                                setTransferDetails(undefined)
                            }}
                        />
                    </VStack>

                    <Box as={Collapse} in={transfer.isOpen} w="full">
                        <VirtualPaginationContextProvider>
                            <ContactsProvider>
                                <TransferList call={call} onClose={onTransferInitiated} />
                            </ContactsProvider>
                        </VirtualPaginationContextProvider>
                    </Box>
                </VStack>
            )}
        </Box>
    )
}
