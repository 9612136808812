import { Badge as ChakraBadge, BadgeProps as ChakraBadgeProps } from '@chakra-ui/react'

export interface BadgeProps extends ChakraBadgeProps {
    variant?: 'white' | 'red' | 'teal' | 'blue' | 'gold' | 'purple' | 'darkGrey'
    size?: 'sm' | 'md' | 'lg'
}

export const Badge = (props: BadgeProps) => {
    return <ChakraBadge {...props} />
}
