import { useAudioVideo, useMeetingManager } from 'amazon-chime-sdk-component-library-react'
import { DataMessage } from 'amazon-chime-sdk-js'
import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState
} from 'react'
import * as uuid from 'uuid'

import { ChatDataMessage, DataMessagesActionType, initialState, reducer } from './state'

interface ReactionMessage {
    shortcodes: string
    unified: string
}

interface DataMessagesStateContextType {
    setFullName: (name: string) => void
    sendMessage: (message: string) => void
    removeMessage: (message: ChatDataMessage) => void
    messages: ChatDataMessage[]

    sendReaction: (message: ReactionMessage) => void
    removeReaction: (message: ChatDataMessage) => void
    reactions: ChatDataMessage[]
}

enum TopicEnum {
    REACTION = 'reactions',
    GENERIC = 'generic'
}

const DataMessagesStateContext = createContext<DataMessagesStateContextType | undefined>(undefined)

const messageAction = (type: TopicEnum): DataMessagesActionType => {
    switch (type) {
        case TopicEnum.GENERIC:
            return DataMessagesActionType.ADD
        case TopicEnum.REACTION:
            return DataMessagesActionType.ADD_REACTION
        default:
            return DataMessagesActionType.ADD
    }
}

export const DataMessagesProvider: FC<PropsWithChildren> = ({ children }) => {
    const meetingManager = useMeetingManager()
    const audioVideo = useAudioVideo()
    const [fullName, setFullName] = useState('')
    const [state, dispatch] = useReducer(reducer, initialState)

    const meetingId = useMemo(() => {
        return meetingManager?.meetingId
    }, [meetingManager?.meetingId])

    const handler = useCallback(
        (dataMessage: DataMessage) => {
            if (!dataMessage.throttled) {
                const isSelf =
                    dataMessage.senderAttendeeId ===
                    meetingManager.meetingSession?.configuration.credentials?.attendeeId
                if (isSelf) {
                    const data = JSON.parse(new TextDecoder().decode(dataMessage.data))

                    dispatch({
                        type: messageAction(dataMessage.topic as TopicEnum),
                        payload: {
                            id: uuid.v4(),
                            message: data,
                            senderAttendeeId: dataMessage.senderAttendeeId,
                            timestamp: dataMessage.timestampMs,
                            senderName: dataMessage.senderExternalUserId,
                            isSelf: true
                        }
                    })
                } else {
                    const data = dataMessage.json()
                    dispatch({
                        type: messageAction(dataMessage.topic as TopicEnum),
                        payload: {
                            id: uuid.v4(),
                            message: data,
                            senderAttendeeId: dataMessage.senderAttendeeId,
                            timestamp: dataMessage.timestampMs,
                            senderName: dataMessage.senderExternalUserId,
                            //        senderName: data.senderName,
                            isSelf: false
                        }
                    })
                }
            } else {
                console.warn('DataMessage is throttled. Please resend')
            }
        },
        [meetingManager]
    )

    useEffect(() => {
        if (!audioVideo || !meetingId) {
            return
        }
        audioVideo.realtimeSubscribeToReceiveDataMessage(meetingId, handler)
        return () => {
            audioVideo.realtimeUnsubscribeFromReceiveDataMessage(meetingId)
        }
    }, [audioVideo, meetingId, handler])

    useEffect(() => {
        if (!audioVideo || !meetingId) {
            return
        }
        audioVideo.realtimeSubscribeToReceiveDataMessage('reactions', handler)
        return () => {
            audioVideo.realtimeUnsubscribeFromReceiveDataMessage(meetingId)
        }
    }, [audioVideo, meetingId, handler])

    const sendMessage = useCallback(
        (message: string) => {
            if (
                !meetingManager ||
                !meetingManager.meetingSession ||
                !meetingManager.meetingSession.configuration.credentials ||
                !meetingManager.meetingSession.configuration.credentials.attendeeId ||
                !audioVideo ||
                !meetingId
            ) {
                return
            }
            const senderAttendeeId =
                meetingManager.meetingSession.configuration.credentials.attendeeId
            audioVideo.realtimeSendDataMessage(meetingId, message)
            handler(
                new DataMessage(
                    Date.now(),
                    meetingId,
                    new TextEncoder().encode(message),
                    senderAttendeeId,
                    fullName
                )
            )
        },
        [meetingManager, audioVideo, meetingId, fullName, handler]
    )

    const sendReaction = useCallback(
        (message: ReactionMessage) => {
            if (
                !meetingManager.meetingSession?.configuration.credentials?.attendeeId ||
                !audioVideo ||
                !meetingId
            ) {
                return
            }
            const senderAttendeeId =
                meetingManager.meetingSession.configuration.credentials.attendeeId
            audioVideo.realtimeSendDataMessage('reactions', message)
            handler(
                new DataMessage(
                    Date.now(),
                    'reactions',
                    new TextEncoder().encode(JSON.stringify(message)),
                    senderAttendeeId,
                    fullName
                )
            )
        },
        [meetingManager, audioVideo, meetingId, fullName, handler]
    )

    const removeMessage = useCallback(
        (message: ChatDataMessage) => {
            dispatch({
                type: DataMessagesActionType.REMOVE,
                payload: message
            })
        },
        [dispatch]
    )

    const removeReaction = useCallback(
        (message: ChatDataMessage) => {
            dispatch({
                type: DataMessagesActionType.REMOVE_REACTION,
                payload: message
            })
        },
        [dispatch]
    )

    const value = {
        sendMessage,
        removeMessage,
        sendReaction,
        removeReaction,
        setFullName,
        messages: state.messages,
        reactions: state.reactions
    }
    return (
        <DataMessagesStateContext.Provider value={value}>
            {children}
        </DataMessagesStateContext.Provider>
    )
}

export const useDataMessages = (): {
    setFullName: (name: string) => void
    sendMessage: (message: string) => void
    removeMessage: (message: ChatDataMessage) => void

    sendReaction: (message: ReactionMessage) => void
    removeReaction: (message: ChatDataMessage) => void
    messages: ChatDataMessage[]
    reactions: ChatDataMessage[]
} => {
    const meetingManager = useMeetingManager()
    const context = useContext(DataMessagesStateContext)
    if (!meetingManager || !context) {
        throw new Error(
            'Use useDataMessages hook inside DataMessagesProvider. Wrap DataMessagesProvider under MeetingProvider.'
        )
    }
    return context
}
