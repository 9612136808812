import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

type NewUpload = {
    body: string
    channelID: string
    fileName: string
    userID: string
}

type UploadData = {
    ID: string
    fileName: string
    downloadURL: string
    mime: string
}

export const buildUploadAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'uploadsAPI',
        baseQuery: baseQuery,
        tagTypes: ['Uploads'],
        endpoints: builder => ({
            uploadChatFile: builder.mutation<{ ID: string }, NewUpload>({
                query: ({ userID, channelID, fileName, body }) => ({
                    url: `/users/${userID}/channels/${channelID}/upload`,
                    params: { type: 'chat', fileName },
                    method: 'POST',
                    body,
                    headers: { 'Content-Type': 'application/json' }
                })
            }),
            getFileUpload: builder.query<
                UploadData,
                { userID: string; channelID: string; uploadID: string }
            >({
                query: ({ userID, channelID, uploadID }) => {
                    return `/users/${userID}/channels/${channelID}/uploads/${uploadID}`
                }
            }),
            uploadChatGroupFile: builder.mutation<
                { ID: string },
                Omit<NewUpload, 'channelID'> & { groupID: string }
            >({
                query: ({ userID, groupID, fileName, body }) => ({
                    url: `/users/${userID}/chatgroups/${groupID}/upload`,
                    params: { type: 'chat', fileName },
                    method: 'POST',
                    body,
                    headers: { 'Content-Type': 'application/json' }
                })
            }),
            getChatGroupFile: builder.query<
                UploadData,
                { userID: string; groupID: string; uploadID: string }
            >({
                query: ({ userID, groupID, uploadID }) => {
                    return `/users/${userID}/chatgroups/${groupID}/uploads/${uploadID}`
                }
            })
        })
    })

    return { api, ...api }
}
