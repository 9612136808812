import { Box, useColorMode } from '@chakra-ui/react'
import { Dropdown } from '@missionlabs/react'
import { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { useGetMyNumbers } from 'shared/hooks/useGetMyNumbers'
import { useGetUserData } from 'shared/hooks/useGetUserData'

export interface DiallerOptionsProps {
    mobileOnly?: boolean
    onChange?: (value: string) => void
    selected?: string
}

export const DiallerOptions: FC<DiallerOptionsProps> = ({ mobileOnly, onChange, selected }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { isUKMobileNumber } = useFormatToNumberE164()

    const { callRoute } = useGetUserData()
    const numbers = useGetMyNumbers({ preferDefault: true })

    const handleChange = useCallback(
        (value: string) => {
            onChange?.(value)
        },
        [onChange]
    )

    const getDefaultNumber = useCallback(() => {
        if (callRoute?.defaultPhoneNumber) {
            return callRoute.defaultPhoneNumber
        }

        return numbers?.[0]?.value ?? ''
    }, [callRoute, numbers])

    useEffect(() => {
        if (!selected) handleChange(getDefaultNumber())
    }, [selected, callRoute, handleChange, getDefaultNumber])

    useEffect(() => {
        // Changes presentation number to first available UK mobile number, if possible and in mobile only mode.
        if (numbers?.length && mobileOnly && !isUKMobileNumber(selected)) {
            handleChange(numbers[0].value)
        }
    }, [handleChange, isUKMobileNumber, mobileOnly, numbers, selected])

    return (
        <Box
            w="full"
            px="24px"
            py="16px"
            borderBottom="1px solid"
            borderColor={`${colorMode}.tones.stormGrey`}
        >
            <Dropdown
                width="full"
                variant="dark"
                options={numbers}
                value={selected ?? getDefaultNumber()}
                format={(value?: string) => (value ? t('My Number', { value }) : undefined)}
                onChange={handleChange}
            />
        </Box>
    )
}
