import { StackProps, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Themes, useTheme } from '@missionlabs/react'
import { CircleloopLogoIcon } from '@missionlabs/react/circleloop'
import { PhonelinePlusLogoIcon } from '@missionlabs/react/phonelineplus'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type NoResultsStateProps = StackProps & {
    heading?: string
    subHeading?: string
}

export const NoResultsState: FC<NoResultsStateProps> = ({ heading, subHeading, ...props }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const theme = useTheme().variant

    const LogoIcon = theme === Themes.PHONELINEPLUS ? PhonelinePlusLogoIcon : CircleloopLogoIcon

    return (
        <VStack alignItems="center" alignSelf="center" {...props}>
            <LogoIcon color={`${colorMode}.tones.periwinkle`} height="64px" width="64px" />
            <Body size="lg" mt="15px" color={`${colorMode}.tones.periwinkle`}>
                {heading ?? t('No results found')}
            </Body>
            <Body size="md" color={`${colorMode}.tones.periwinkle`} textAlign="center">
                {subHeading}
            </Body>
        </VStack>
    )
}
