import { formatDuration } from '@missionlabs/utils'
import { useEffect, useMemo, useRef, useState } from 'react'

type UseGetCallDurationReturn<T> = undefined extends T ? number : false extends T ? number : string

/**
 * Calculates the current duration of an ongoing call.
 * @param start Start time of the call in milliseconds
 * @param format Whether to format the duration as a HH:MM:SS string
 * @returns Call duration in milliseconds
 */
export const useGetCallDuration = <F extends boolean | undefined>(
    start: number | undefined,
    format?: F
    /** Stupid Typescript
     * if format === undefined return number
     * if format === false return number
     * if format === true return string
     */
): UseGetCallDurationReturn<F> => {
    const timeout = useRef<NodeJS.Timeout | undefined>(undefined)
    const [duration, setDuration] = useState<number>(0)
    const formattedDuration = useMemo(() => formatDuration(duration), [duration])

    useEffect(() => {
        if (!start && timeout.current) {
            clearInterval(timeout.current)
            return
        }
        if (start) {
            timeout.current = setInterval(() => {
                const now = Date.now()
                setDuration(now - start)
            }, 1000)
        }

        return () => clearInterval(timeout.current)
    }, [timeout, setDuration, start])

    if (format) return formattedDuration as any
    return duration as any
}
