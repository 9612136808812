import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import ifEmoji from 'if-emoji'

import { customCreateApi } from './customCreateAPI'

export interface Skin {
    unified: string
    native: string
    x?: number
    y?: number
}

export interface Emoji {
    id: string
    name: string
    keywords: string[]
    skins: Skin[]
    version: number
    emoticons?: string[]
    native?: string
}

export interface EmojiMartData {
    categories: { id: string; emojis: string[] }[]
    emojis: Record<string, Emoji>
    aliases: Record<string, string>
    sheet: { cols: number; rows: number }
}

export const buildEmojiAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'emojiApi',
        tagTypes: ['Emojis', 'Emoticons'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getEmojis: builder.query<Emoji[], void>({
                query: () => '/native.json',
                transformResponse: (data: EmojiMartData) => {
                    const emojis = data.categories
                        .flatMap(item => item.emojis)
                        .map(item => data.emojis[item])
                    return emojis.reduce((prev, cur) => {
                        for (const skin of cur.skins) {
                            // filter unsupported emojis
                            if (!ifEmoji(skin.native)) continue

                            prev.push({
                                ...cur,
                                id: `${cur.id}-${skin.unified}`,
                                native: skin.native
                            })
                        }
                        return prev
                    }, [] as Emoji[])
                },
                providesTags: ['Emojis']
            }),
            getEmoticons: builder.query<Record<string, string>, void>({
                query: () => '/native.json',
                transformResponse: (data: EmojiMartData) => {
                    const obj: Record<string, string> = {}
                    for (const key in data.emojis) {
                        const emoji = data.emojis[key]
                        if (!emoji.emoticons) continue
                        for (const emoticon of emoji.emoticons) {
                            obj[emoticon] = emoji.skins[0].native
                        }
                    }
                    return obj
                },
                providesTags: ['Emoticons']
            })
        })
    })
    return { api, ...api }
}
