import { SimpleGrid, VStack } from '@chakra-ui/react'
import { Input, TimePicker, Toggle, useFormContext, ValidatedInput } from '@missionlabs/react'
import { format } from 'date-fns'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePickerPopover } from 'shared/components/DatePickerPopover'

export const MeetingDetailsForm: FC = () => {
    const { t } = useTranslation()
    const { setValue, watch } = useFormContext()
    const { date, microphoneInitialState } = watch()

    const dateValue = useMemo(() => {
        if (!date) return ''
        return format(date, 'dd/MM/yy')
    }, [date])

    return (
        <VStack p="24px" spacing="16px" align="inherit">
            <ValidatedInput name="meetingName" label={t('scheduledMeeting.form.meetingName')}>
                <Input autoComplete="off" />
            </ValidatedInput>
            <ValidatedInput name="description" label={t('scheduledMeeting.form.description')}>
                <Input autoComplete="off" />
            </ValidatedInput>

            <ValidatedInput name="date" label={t('Date')}>
                <DatePickerPopover
                    value={date}
                    triggerValue={dateValue}
                    onChange={date => setValue('date', date)}
                    disablePastDays
                />
            </ValidatedInput>
            <SimpleGrid columns={2} gap="24px">
                <ValidatedInput name="startTime" label={t('scheduledMeeting.form.startTime')}>
                    <TimePicker />
                </ValidatedInput>
                <ValidatedInput name="endTime" label={t('scheduledMeeting.form.finishTime')}>
                    <TimePicker />
                </ValidatedInput>
            </SimpleGrid>
            <ValidatedInput
                name="microphoneInitialState"
                label={t('scheduledMeeting.form.muteAllAtStart')}
            >
                <Toggle
                    paddingRight={'16px'}
                    paddingLeft={'12px'}
                    value={microphoneInitialState}
                    isChecked={microphoneInitialState === 'MUTED'}
                    onChange={e => {
                        console.log(e)
                        setValue('microphoneInitialState', e.target.checked ? 'MUTED' : 'UNMUTED')
                    }}
                >
                    {t('scheduledMeeting.form.autoMuteUsers')}
                </Toggle>
            </ValidatedInput>
        </VStack>
    )
}
