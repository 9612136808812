import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { BlockedNumber, ContactGroup, DirectoryEntry, isContactGroup } from '@missionlabs/types'
import { useMemo } from 'react'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import {
    useBatchCreateDefaultBlockedNumbersMutation,
    useDeleteDefaultBlockedNumberListMutation,
    useGetDefaultBlockedNumberListQuery
} from 'shared/store'

interface UseBlockContactReturn {
    isBlocked: boolean
    isLoading: boolean
    block: (outbound?: boolean, inbound?: boolean) => Promise<BlockedNumber[]> | undefined
    unblock: () => Promise<BlockedNumber[]> | undefined
    isNumberBlocked: Partial<BlockedNumber>
    allNumbersBlocked: boolean
}

export const useBlockContact = (
    contact: ContactGroup | DirectoryEntry | string | undefined
): UseBlockContactReturn => {
    const user = useSelector(selectAuthenticatedUser)

    const { data: blockedNumbers } = useGetDefaultBlockedNumberListQuery()
    const [batchCreatedBlockedNumbers, { isLoading: updateLoading }] =
        useBatchCreateDefaultBlockedNumbersMutation()
    const [deleteBlockedNumbers, { isLoading: deleteLoading }] =
        useDeleteDefaultBlockedNumberListMutation()
    const { formatToNumberE164 } = useFormatToNumberE164()

    const isLoading = updateLoading || deleteLoading

    const contactNumberE164s = useMemo(() => {
        if (typeof contact === 'string') return [contact]
        if (isContactGroup(contact) || !contact?.phoneNumbers) return []
        return contact.phoneNumbers.map(item => item.numberE164)
    }, [contact])

    const isBlocked = useMemo(() => {
        if (!blockedNumbers) return false
        return blockedNumbers.some(item => contactNumberE164s.includes(item.numberE164))
    }, [blockedNumbers, contactNumberE164s])

    const allNumbersBlocked = useMemo(() => {
        if (!blockedNumbers || contactNumberE164s.length === 0) return false
        const blockedNumberE164s = blockedNumbers.map(n => n.numberE164)

        return contactNumberE164s.every(number => blockedNumberE164s.includes(number))
    }, [blockedNumbers, contactNumberE164s])

    const isNumberBlocked = useMemo(() => {
        if (!blockedNumbers || typeof contact !== 'string')
            return { inbound: false, outbound: false }

        const number = blockedNumbers.find(item => item.numberE164 === formatToNumberE164(contact))

        return {
            inbound: number?.inbound,
            outbound: number?.outbound
        }
    }, [blockedNumbers, contact, formatToNumberE164])

    function handleBlock(outbound = false, inbound = true) {
        if (!user) return
        return batchCreatedBlockedNumbers({
            clientID: user.clientID,
            numberE164: contactNumberE164s,
            outbound: outbound,
            inbound: inbound
        }).unwrap()
    }

    function handleUnblock() {
        if (!user) return
        return deleteBlockedNumbers({
            clientID: user.clientID,
            numberE164: contactNumberE164s
        }).unwrap()
    }

    return {
        isBlocked,
        isNumberBlocked,
        isLoading: isLoading,
        block: handleBlock,
        unblock: handleUnblock,
        allNumbersBlocked
    }
}
