import {
    Activity as ActivityType,
    ActivityDirection,
    ContactType,
    DirectoryEntry,
    isContactGroup,
    UserActivity
} from '@missionlabs/types'
import { titleCase } from '@missionlabs/utils'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'

import {
    getCompanyFromActivity,
    getDirectionOfActivity,
    getNameFromActivity,
    getTeamFromActivity
} from '../utils/activities'

type UseActivityInfoOptions = {
    format?: {
        date?: string
        time?: string
    }
}

type UseActivityInfoReturn = {
    name: string
    company: string | undefined
    team: string | undefined
    date: string
    time: string
    direction: ActivityDirection
    originatingNumber: string | undefined
    dialledNumber: string | undefined
    contact: DirectoryEntry | undefined
    contactNumber: string | undefined
    type: ContactType | 'unknown'
    descriptiveType: string | undefined
}

// Gets reusable information from an activity object.
export const useActivityInfo = (
    activity: UserActivity | undefined,
    options?: UseActivityInfoOptions
): UseActivityInfoReturn => {
    const { t } = useTranslation()
    const { formatToLocalNumber } = useFormatToNumberE164()

    const name = useMemo(() => {
        const activityWithoutExternalContact = {
            ...activity,
            originatingContact: undefined
        } as UserActivity

        return getNameFromActivity(activityWithoutExternalContact, formatToLocalNumber, t)
    }, [activity, formatToLocalNumber, t])

    const company = useMemo(() => getCompanyFromActivity(activity), [activity])

    const team = useMemo(() => getTeamFromActivity(activity, t), [activity, t])

    const direction = useMemo(() => getDirectionOfActivity(activity), [activity])

    const [date, time] = useMemo(() => {
        if (!activity) return ['', '']

        const date = format(new Date(activity.created), options?.format?.date ?? 'dd/MM')
        const time = format(new Date(activity.created), options?.format?.time ?? 'HH:mm')
        return [date, time]
    }, [activity, options])

    const type = useMemo(() => {
        if (!activity?.contact) return 'unknown'
        if (isContactGroup(activity.contact)) return 'group'
        if ('externalID' in activity.contact) return 'internal'
        return 'external'
    }, [activity])

    const contact = useMemo(() => {
        return activity?.contact || activity?.originatingContact
    }, [activity])

    // rendered underneath the activity detail heading
    const descriptiveType = useMemo(() => {
        if (!activity) return
        switch (activity.activityType) {
            case ActivityType.CHAT_MESSAGE_SENT:
            case ActivityType.CHAT_MESSAGE_RECEIVED:
                return t('SMS')
            case ActivityType.CALL_RECEIVED:
            case ActivityType.INTERNAL_CALL_RECEIVED:
                return t('activity.inboundCall')
            case ActivityType.CALL_MISSED:
            case ActivityType.INTERNAL_CALL_MISSED:
                return t('details.inbound-missed')
            case ActivityType.MESSAGE_RECEIVED:
            case ActivityType.INTERNAL_MESSAGE_RECEIVED:
                return t('details.voicemail')
            case ActivityType.CALL_MADE:
            case ActivityType.INTERNAL_CALL_MADE:
                return !('callLength' in activity)
                    ? t('details.outbound-missed')
                    : t('activity.outboundCall')
            default:
                return
        }
    }, [activity, t])

    /**
     * The contact can have multiple numbers, this uses the number dialled with for the particular activity.
     * For inbound calls, this will be the number that the customer dialled from
     * For outbound calls, this will be the number that the agent dialled from
     */
    const originatingNumber = useMemo(() => {
        return formatToLocalNumber(activity?.originatingNumber || activity?.originatingNumberE164)
    }, [activity?.originatingNumber, activity?.originatingNumberE164, formatToLocalNumber])

    const dialledNumber = useMemo(() => {
        return activity?.dialledNumber ? formatToLocalNumber(activity.dialledNumber) : undefined
    }, [activity?.dialledNumber, formatToLocalNumber])

    const contactNumber = useMemo(() => {
        const internalContact = activity?.contact || activity?.originatingContact
        if (internalContact?.extension) {
            return `Ext. ${internalContact.extension}`
        }
        if (!activity?.contact?.phoneNumbers) return

        let numberE164
        if (direction === 'inbound') {
            // voicemails "originatingNumber" is the formatted local number not E.164
            if (activity.activityClass === 'message') numberE164 = activity.originatingNumberE164
            else numberE164 = activity.originatingNumber
        }
        if (direction === 'outbound') {
            if (activity.activityClass === 'message') numberE164 = activity.dialledNumberE164
            else numberE164 = activity.dialledNumber
        }

        const obj = activity.contact.phoneNumbers.find(item => item.numberE164 === numberE164)
        if (!obj) return

        const formattedNumber = formatToLocalNumber(obj.numberE164)

        if (!obj.label) return formattedNumber
        return `${t(titleCase(obj.label))} - ${formatToLocalNumber(obj.numberE164)}`
    }, [activity, direction, formatToLocalNumber, t])

    return {
        name,
        company,
        team,
        date,
        time,
        direction,
        originatingNumber,
        dialledNumber,
        contact,
        contactNumber,
        type,
        descriptiveType
    }
}
