import { Center, Heading, HStack, IconButton, useColorMode, VStack } from '@chakra-ui/react'
import { ActivityIcon, Dropdown, useToast } from '@missionlabs/react'
import { CloseIcon, MobileIconOutline } from '@missionlabs/react/circleloop'
import {
    Activity as ActivityType,
    DirectoryEntry,
    OutboundWhatsApp,
    UserActivity,
    WhatsAppContentType
} from '@missionlabs/types'
import { useGetActivityContactNumberOptions } from 'features/activity/ActivityFeed/hooks/useGetActivityContactNumbers'
import { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useWhatsAppSendMessage } from 'shared/hooks/useWhatsAppSendMessage'

import { SMSMessageInput } from './components/SMSMessageInput/SMSMessageInput'
import { useActivityFooterState } from './hooks/useActivityFooterState'

export interface ActivityFeedFooterWhatsAppContentProps {
    activity: UserActivity
    contact?: DirectoryEntry
    contactNumber?: string
}

export const ActivityFeedFooterWhatsAppContent: FC<ActivityFeedFooterWhatsAppContentProps> = ({
    contact,
    contactNumber,
    activity
}) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { draftMessage, dispatch, myWhatsAppNumberOptions, templateOptions, template, send } =
        useWhatsAppSendMessage()

    const { numberID, templateName, toNumber, contentType } = draftMessage

    const setValueField = useCallback(
        (field: keyof OutboundWhatsApp, value: string) => {
            dispatch({ type: 'SET_MESSAGE', draftMessage: { [field]: value } })
        },
        [dispatch]
    )

    // Set To Numbers and select the first number
    const toNumbers = useGetActivityContactNumberOptions(contact, contactNumber)
    useEffect(() => {
        if (toNumbers.length > 0 && !toNumber) {
            setValueField('toNumber', toNumbers[0].value)
        }
    }, [toNumbers, toNumber, setValueField])

    const { toast } = useToast()
    const { onClose } = useActivityFooterState()

    const handleSendMsg = async ({ text }: { text: string }) => {
        if (!text) return
        try {
            await send(text)
            onClose()
            toast({
                title: t('whatsapp.success'),
                status: 'success'
            })
        } catch (e) {
            toast({
                title: t('whatsapp.error'),
                status: 'error'
            })
        }
    }

    return (
        <VStack
            w="full"
            pt="18px"
            pb="24px"
            px="24px"
            borderTop="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
        >
            <HStack w="full" justify="space-between">
                <HStack>
                    <Center
                        boxSize="32px"
                        bg={`${colorMode}.tones.ghostWhite`}
                        border="1px solid"
                        borderColor={`${colorMode}.tones.periwinkle`}
                        borderRadius="full"
                    >
                        <ActivityIcon activityType={ActivityType.WHATSAPP_MESSAGE_RECEIVED} />
                    </Center>
                    <Heading size="16px">{t('activity.replyToWhatsApp')}</Heading>
                </HStack>
                <IconButton
                    aria-label="Close filters"
                    icon={<CloseIcon boxSize="16px" />}
                    variant="transparent"
                    size="md"
                    onClick={onClose}
                />
            </HStack>
            <HStack w="full">
                <VStack w="50%" align="flex-start">
                    <Dropdown
                        format={val => `${t('sms.from')}: ${val}`}
                        onChange={(val: string) => setValueField('numberID', val)}
                        value={numberID}
                        w="full"
                        options={myWhatsAppNumberOptions}
                        leftIcon={<MobileIconOutline />}
                    />
                </VStack>
                <VStack w="50%" align="flex-start">
                    <Dropdown
                        format={val => `${t('sms.to')}: ${val}`}
                        onChange={(val: string) => setValueField('toNumber', val)}
                        value={toNumber}
                        w="full"
                        options={toNumbers}
                        leftIcon={<MobileIconOutline />}
                    />
                </VStack>
                {contentType === WhatsAppContentType.TEMPLATE && (
                    <VStack w="50%" align="flex-start">
                        <Dropdown
                            format={val => `${t('whatsapp.template')}: ${val}`}
                            onChange={(val: string) => setValueField('templateName', val)}
                            value={templateName ?? ''}
                            w="full"
                            options={templateOptions}
                        />
                    </VStack>
                )}
            </HStack>
            <SMSMessageInput
                onSendMessage={handleSendMsg}
                channelID={activity.ID}
                template={template}
                showCounter={false}
            />
        </VStack>
    )
}
