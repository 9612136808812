import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ResizeIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ResizeIcon',
    viewBox: '0 0 8 8',
    path: (
        <g>
            <path
                d="M0.240662 7.75933C-0.0802206 7.43844 -0.0802206 6.91817 0.240662 6.59728L6.59702 0.240669C6.9179 -0.0802232 7.43815 -0.0802232 7.75903 0.240669C8.07992 0.561562 8.07992 1.08183 7.75903 1.40272L1.40268 7.75933C1.0818 8.08022 0.561545 8.08022 0.240662 7.75933Z"
                fill="currentColor"
            />
            <path
                d="M4.88904 7.75933C4.56816 7.43844 4.56816 6.91817 4.88904 6.59728L6.59732 4.88889C6.9182 4.568 7.43846 4.568 7.75934 4.88889C8.08022 5.20978 8.08022 5.73005 7.75934 6.05094L6.05106 7.75933C5.73018 8.08022 5.20993 8.08022 4.88904 7.75933Z"
                fill="currentColor"
            />
        </g>
    )
})
