import {
    IconButton as ChakraIconButton,
    IconButtonProps as ChakraIconButtonProps,
    useStyleConfig
} from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface IconButtonProps extends Omit<ChakraIconButtonProps, 'size' | 'variant'> {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    variant?:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'creationary'
        | 'negatory'
        | 'negatory-outline'
        | 'negatory-light'
        | 'ghost'
        | 'transparent'
        | 'outline'
        | 'outline-active'
        | 'dialler'
    isRounded?: boolean
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
    ({ size, variant, isRounded, sx, ...props }, ref) => {
        const styles = useStyleConfig('IconButton', { size, variant })

        return (
            <ChakraIconButton
                sx={{ ...styles, borderRadius: isRounded ? '50%' : styles.borderRadius, ...sx }}
                {...props}
                variant={variant}
                ref={ref}
            />
        )
    }
)

IconButton.displayName = 'IconButton'
