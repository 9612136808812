import {
    ComponentStyleConfig,
    createMultiStyleConfigHelpers,
    defineStyle,
    defineStyleConfig
} from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
    'subtitle',
    'title',
    'container',
    'titleContainer',
    'stepsContainer'
])

const baseStyle = definePartsStyle({
    subtitle: defineStyle({ color: 'light.tones.midnightExpress', fontWeight: 700 }),
    title: defineStyle({ color: 'light.tones.stormGrey', fontWeight: 700 }),
    container: defineStyle({ alignItems: 'flex-start' }),
    titleContainer: defineStyle({ spacing: '8px' }),
    stepsContainer: defineStyle({ spacing: '4px' })
})

export const StepsStyles: ComponentStyleConfig = defineMultiStyleConfig({ baseStyle })

export const StepStyles: ComponentStyleConfig = defineStyleConfig({
    baseStyle: defineStyle({
        w: '88px',
        h: '4px',
        borderRadius: '8px',
        bg: 'light.tones.periwinkle',
        cursor: 'pointer'
    }),
    variants: {
        active: defineStyle({ bg: 'light.primary.gamma' })
    }
})
