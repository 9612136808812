import { Box, Divider, VStack } from '@chakra-ui/react'
import {
    Input,
    useMeasure,
    useUpdateParams,
    VirtualPaginationContextProvider
} from '@missionlabs/react'
import { useContactsSidebarData } from 'features/contacts/hooks/useContactsSidebarData'
import { FC, useEffect } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList } from 'react-window'
import { useContactsSearch } from 'shared/hooks/useContactsSearch'

import { MeetingsCallAddDirectoryRowRenderer } from './MeetingsCallAddDirectoryRowRenderer'

export interface MeetingsCallAddDirectoryProps {
    siblingHeight: number
    contactFiltersStorageKey?: string
}

export const MeetingsCallAddDirectory: FC<MeetingsCallAddDirectoryProps> = ({
    siblingHeight,
    contactFiltersStorageKey
}) => {
    const [searchContacts, { data: contacts }] = useContactsSearch()
    const { listRef, grouped, getRowSize, onItemsRendered } = useContactsSidebarData(contacts, {
        useContactsFilterParams: { storageKey: contactFiltersStorageKey }
    })
    const [searchParams, updateParams] = useUpdateParams()
    const search = searchParams.get('search') || ''

    const [searchRef, { blockSize: searchHeight }] = useMeasure<HTMLDivElement>()

    /** When search query string param changes, search contacts
     *  Only search for internal users as contacts */
    useEffect(() => {
        searchContacts({ searchTerm: search, filter: ['individual'] })
    }, [searchContacts, search])

    return (
        <>
            <VStack
                ref={searchRef}
                as="section"
                pt="24px"
                mx="24px"
                spacing="24px"
                aria-label="Search directory"
            >
                <Input
                    placeholder="Enter name or number"
                    onChange={e => updateParams({ search: e.currentTarget.value })}
                />
                <Divider />
            </VStack>
            <Box h={`calc(100% - ${siblingHeight + searchHeight}px)`} overflow="hidden auto">
                <VirtualPaginationContextProvider>
                    <AutoSizer>
                        {({ height, width }) => (
                            <VariableSizeList
                                ref={listRef}
                                height={height}
                                width={width}
                                itemSize={getRowSize}
                                itemCount={grouped.length}
                                itemData={grouped}
                                onItemsRendered={onItemsRendered}
                            >
                                {MeetingsCallAddDirectoryRowRenderer}
                            </VariableSizeList>
                        )}
                    </AutoSizer>
                </VirtualPaginationContextProvider>
            </Box>
        </>
    )
}
