import { Flex, HStack, Skeleton, SkeletonText, useColorMode, VStack } from '@chakra-ui/react'
import { Button } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ImagePlaceholder } from './ImagePlaceholder'

export const SkeletonIntegrationsCard: FC = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    return (
        <VStack
            spacing="24px"
            w="full"
            p="24px"
            align="start"
            border="1px solid"
            borderColor={`${colorMode}.coolGrey.30`}
            borderRadius="4px"
        >
            <ImagePlaceholder />
            <Flex flex={1} w="full" gap="8px" align="inherit" wrap="wrap">
                <Skeleton
                    w="100px"
                    h="24px"
                    startColor={`${colorMode}.coolGrey.40`}
                    endColor={`${colorMode}.coolGrey.10`}
                />
                <SkeletonText
                    w="full"
                    noOfLines={8}
                    startColor={`${colorMode}.coolGrey.40`}
                    endColor={`${colorMode}.coolGrey.10`}
                />
            </Flex>
            <HStack justify="space-between">
                <Button isDisabled>{t('Install')}</Button>
            </HStack>
        </VStack>
    )
}
