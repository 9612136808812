import { Box } from '@chakra-ui/react'
import { getSelectedOptionLabel } from '@missionlabs/utils'
import { Input, InputProps } from 'atoms'
import { ChevronDownIconRegular } from 'atoms/Icons/zeta'
import { Select, SelectProps } from 'molecules'
import { forwardRef } from 'react'

export type DropdownProps = Omit<InputProps, 'onChange'> &
    SelectProps & {
        format?: (label: string | undefined) => string | undefined
    }

export const Dropdown = forwardRef<HTMLInputElement, DropdownProps>(
    (
        {
            value,
            format,
            placeholder,
            isDisabled,
            isInvalid,
            leftIcon,
            rightIcon,
            rightSx,
            variant,
            ...props
        },
        ref
    ) => {
        const inputDisplayValue = getSelectedOptionLabel(props.options, value)

        return (
            <Box
                w={props.w || props.width}
                maxW={props.maxW || props.maxWidth}
                minW={props.minW || props.maxWidth}
            >
                <Select {...props} value={value} variant={variant} isDisabled={isDisabled}>
                    <Input
                        ref={ref}
                        isReadOnly
                        variant={variant}
                        value={format?.(inputDisplayValue) ?? inputDisplayValue ?? ''}
                        placeholder={placeholder}
                        isDisabled={isDisabled}
                        isInvalid={isInvalid}
                        leftIcon={leftIcon}
                        rightIcon={rightIcon ?? <ChevronDownIconRegular boxSize="8px" />}
                        rightSx={rightSx ?? { pointerEvents: 'none' }}
                        data-testid="dropdown-trigger"
                        aria-labelledby={props['aria-labelledby']}
                        // aria-labelledby takes precedence over aria-label
                        aria-label={props['aria-label']}
                    />
                </Select>
            </Box>
        )
    }
)

Dropdown.displayName = 'Dropdown'
