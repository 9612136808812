import { Box, Flex, HStack, Text, useColorMode } from '@chakra-ui/react'
import { DateInput, IconButton } from '@missionlabs/react'
import { ArrowLeftIconRegular, ArrowRightIconRegular } from '@missionlabs/react/zeta'
import { endOfDay } from 'date-fns'
import React from 'react'

import { useAnalytics } from '../hooks/useAnalytics'

interface Props {}

const AnalyticsDatePicker: React.FC<Props> = () => {
    const { previous, next, setStart, setEnd, formattedTime, isCurrentPeriod, period, start, end } =
        useAnalytics().dateScroller

    const { colorMode } = useColorMode()

    return (
        <Box borderBottom="1px solid" borderBottomColor={`${colorMode}.tones.periwinkle`}>
            {period === 'custom' ? (
                <HStack my="9px">
                    <DateInput
                        w={150}
                        placeholder="DD/MM/YY"
                        value={start}
                        onChange={date => setStart(date as Date)}
                        maxDate={new Date()}
                    />
                    <DateInput
                        w={150}
                        placeholder="DD/MM/YY"
                        value={end}
                        onChange={date => setEnd(endOfDay(date as Date))}
                        maxDate={new Date()}
                    />
                </HStack>
            ) : (
                <Flex align="center" my="9px" justify="space-between">
                    <IconButton
                        borderTopRightRadius={0}
                        borderBottomRightRadius={0}
                        borderRight={0}
                        color={`${colorMode}.tones.midnightExpress`}
                        onClick={previous}
                        variant="ghost"
                        icon={<ArrowLeftIconRegular />}
                        aria-label="Previous"
                    />
                    <Text
                        color={`${colorMode}.tones.midnightExpress`}
                        fontWeight={500}
                        fontSize={16}
                    >
                        {formattedTime}
                    </Text>
                    <IconButton
                        color={`${colorMode}.tones.midnightExpress`}
                        borderBottomLeftRadius={0}
                        borderTopLeftRadius={0}
                        onClick={next}
                        isDisabled={isCurrentPeriod}
                        variant="ghost"
                        icon={<ArrowRightIconRegular />}
                        aria-label="Next"
                    />
                </Flex>
            )}
        </Box>
    )
}

export default AnalyticsDatePicker
