import { Flex, ListItem, ListItemProps } from '@chakra-ui/react'
import { TickCircleIcon } from '@missionlabs/react/circleloop'
import React from 'react'

interface Props extends ListItemProps {
    isSelected: boolean
}

const CreateReportStackItem: React.FC<Props> = ({ children, isSelected, ...props }) => {
    return (
        <ListItem {...props}>
            <Flex align="center" justify="space-between">
                {children}
                {isSelected && <TickCircleIcon color="#19C4A6" />}
            </Flex>
        </ListItem>
    )
}

export default CreateReportStackItem
