import { Flex, VStack } from '@chakra-ui/react'
import { call, selectPresentationNumber, useDispatch, useSelector } from '@missionlabs/api'
import { Body, Button, Heading, Spinner } from '@missionlabs/react'
import { DrawerMode } from 'features/voice/ContactDrawer'
import { useContactDrawerState } from 'features/voice/hooks/useContactDrawerState'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetUserData } from 'shared/hooks/useGetUserData'
import { useGetClientQuery, useGetPaymentMethodsQuery } from 'shared/store'

import { LicensingTable } from './LicensingTable'
import { PaymentMethodsTable } from './PaymentMethodsTable'

export interface SubscriptionProps {}

export const Subscription: FC<SubscriptionProps> = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { callRoute, user } = useGetUserData()
    const { data: client } = useGetClientQuery()
    const { setMode } = useContactDrawerState()

    const canCancelSubscription = user?.type === 'owner' || user?.type === 'superuser'
    const clientScheduledForCancellation = !!client?.cancelCategory && !!client?.cancelReason

    const presentationNumber = useSelector(selectPresentationNumber)
    const fromNumber = useMemo(
        () => presentationNumber ?? callRoute?.defaultPhoneNumber ?? '',
        [presentationNumber, callRoute?.defaultPhoneNumber]
    )

    function handleSupportCall() {
        if (!fromNumber) return
        dispatch(call({ from: fromNumber, to: '03330506070' }))
        setMode(DrawerMode.VOICE)
    }

    const { data = [], isLoading } = useGetPaymentMethodsQuery()

    return (
        <SettingsPage
            maxW="full"
            title={t('admin.subscription.title')}
            subtitle={t('admin.subscription.subtitle')}
        >
            <Flex w="full" justify="space-between">
                <Heading size="h3" fontWeight={500} variant="bold">
                    {t('admin.subscription.paymentMethod.title')}
                </Heading>

                {!isLoading && (
                    <Button
                        variant="link-underline"
                        onClick={() => navigate('/admin/subscription/payment-method')}
                    >
                        {t(
                            data.length
                                ? 'admin.subscription.paymentMethod.change'
                                : 'admin.subscription.paymentMethod.add'
                        )}
                    </Button>
                )}
            </Flex>

            {isLoading ? <Spinner size="sm" /> : <PaymentMethodsTable paymentMethods={data} />}

            <VStack spacing="8px" align="inherit">
                <Heading size="h3" fontWeight={500} variant="bold">
                    {t('admin.subscription.licensing.title')}
                </Heading>
                <Body size="sm">
                    {t('admin.subscription.licensing.query')}
                    <Button variant="link-underline" onClick={handleSupportCall} ml="5px">
                        {t('Call us now')}
                    </Button>
                </Body>
            </VStack>
            <LicensingTable />
            {canCancelSubscription && (
                <Body size="sm">
                    <Button
                        variant="link-underline"
                        onClick={() => navigate('/admin/subscription/cancel')}
                        mr="5px"
                        isDisabled={!!clientScheduledForCancellation}
                    >
                        {t('Cancel subscription')}
                    </Button>
                    {t('admin.subscription.cancel.link')}
                </Body>
            )}
        </SettingsPage>
    )
}
