import { Collapse } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { Activity, Destination, DestinationTypes } from '@missionlabs/types'
import { SettingsCheckboxList } from 'features/settings/components/SettingsCheckboxList'
import { useSettingsContext } from 'features/settings/components/settingsContext'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { SettingSwitch } from 'features/settings/components/SettingsSwitch'
import { SettingsTitle } from 'features/settings/components/SettingsTitle'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Features } from 'shared/hooks/useHasClientFeature'
import { useHasFeature } from 'shared/hooks/useHasFeature'
import {
    useGetDestinationNotificationTypesQuery,
    useGetDestinationsForUserQuery
} from 'shared/store'
import { selectDestinationsByTypes } from 'shared/utils/deviceDestination'

export interface LoggedInDevicesProps {}
const allowedDestinationsTypes = [
    DestinationTypes.android,
    DestinationTypes.ios,
    DestinationTypes.webApp,
    DestinationTypes.desktopApp
]

type SettingsOption = { ID: string; label: string; isChecked: boolean }

export const LoggedInDevices: FC<LoggedInDevicesProps> = () => {
    const { t } = useTranslation()
    const user = useSelector(selectAuthenticatedUser)
    const { onUpdateUserDestination } = useSettingsContext()
    const hasChatPermission = useHasFeature(Features.chat)

    const { data: notificationOptions } = useGetDestinationNotificationTypesQuery(
        user?.userID ?? ''
    )
    const { destinations = [] } = useGetDestinationsForUserQuery(user?.userID ?? '', {
        skip: !user,
        selectFromResult: result => ({
            ...result,
            destinations: selectDestinationsByTypes(allowedDestinationsTypes)(result)
        })
    })

    const sortedDestinations = destinations.slice(0, 5).sort((a, b) => {
        if (a.ID === user?.destinationID) return -1
        if (b.ID === user?.destinationID) return 1
        if (a.lastAppActivity === undefined && b.lastAppActivity !== undefined) return 1
        if (a.lastAppActivity !== undefined && b.lastAppActivity === undefined) return -1
        if (a.lastAppActivity === undefined && b.lastAppActivity === undefined) return 0
        return b.lastAppActivity! - a.lastAppActivity!
    })
    const handleNotificationToggle = useCallback(
        (value: boolean, device: Destination) => {
            const updatedNotifications = {
                ...device.notifications,
                muteAllNotifications: !value
            }

            const { ID, type, installationID, userID, APNSToken, name } = device
            onUpdateUserDestination({
                ID,
                type,
                installationID,
                userID,
                APNSToken,
                name,
                notifications: updatedNotifications
            })
        },
        [onUpdateUserDestination]
    )

    const handleEvent = useCallback(
        (joinedEvent: string, device: Destination) => {
            const updatedEventsSet = new Set(device.notifications?.events ?? [])

            const events = joinedEvent.split(',') as Activity[]
            const isAdd = !Array.from(updatedEventsSet).some(e => events.includes(e))

            events.forEach(singleEvent => {
                if (isAdd) {
                    updatedEventsSet.add(singleEvent)
                } else {
                    updatedEventsSet.delete(singleEvent)
                }
            })
            let updatedEvents = Array.from(updatedEventsSet)
            updatedEvents = updatedEvents.filter(e => typeof e === 'string')

            const { ID, type, installationID, userID, APNSToken, name } = device
            onUpdateUserDestination({
                ID,
                type,
                installationID,
                userID,
                APNSToken,
                name,
                notifications: { ...device.notifications, events: updatedEvents }
            })
        },
        [onUpdateUserDestination]
    )
    if (destinations.length === 0) {
        return null
    }

    return (
        <>
            <SettingsTitle label={t('Circleloop')} />
            {sortedDestinations.map(device => (
                <SettingsRow
                    key={device.ID}
                    label={`${device.name} (${device.type})`}
                    divider={false}
                >
                    <SettingSwitch
                        label={t('notifications.sendToDevice')}
                        isChecked={!device.notifications?.muteAllNotifications}
                        onUpdate={(value: boolean) => handleNotificationToggle(value, device)}
                    />
                    <div />
                    <Collapse
                        in={!device.notifications?.muteAllNotifications}
                        startingHeight="0px"
                        unmountOnExit
                    >
                        <SettingsCheckboxList
                            options={(notificationOptions?.notificationTypes ?? [])
                                .map(type => {
                                    if (
                                        !hasChatPermission &&
                                        type.events.includes(Activity.CHAT_CHANNEL_MESSAGE_RECEIVED)
                                    ) {
                                        return null
                                    }
                                    return {
                                        ID: type.events.join(','),
                                        label: t(type.display),
                                        isChecked: device.notifications?.events?.includes(
                                            type.events[0]
                                        )
                                    }
                                })
                                .filter((data): data is SettingsOption => Boolean(data))}
                            onClick={(joinedEvent: string) => handleEvent(joinedEvent, device)}
                        />
                    </Collapse>
                </SettingsRow>
            ))}
        </>
    )
}
