import { HStack } from '@chakra-ui/react'
import { IconButton } from 'atoms'
import {
    AddUserIconRegular,
    HangupIconSolid,
    MicrophoneIconRegular,
    MusicIconRegular,
    SmartphoneOutIconRegular,
    TransferIconRegular
} from 'atoms/Icons/zeta'
import { FC } from 'react'

import { CallState } from '../types'

export type CallButtonsProps = {
    state: CallState
    onHangCall?: () => void
    onMuteCall?: () => void
    onHoldCall?: () => void
    onTransferCall?: () => void
    onAddParticipant?: () => void
}

export const CallButtons: FC<CallButtonsProps> = ({
    state: { muted, onHold, transferring, numberOfMembers = 0 },
    onHangCall,
    onMuteCall,
    onHoldCall,
    onTransferCall,
    onAddParticipant
}) => {
    return (
        <HStack w="100%" spacing="8px" justify="space-between">
            <IconButton
                variant={muted ? 'negatory-light' : 'tertiary'}
                size="lg"
                icon={<MicrophoneIconRegular />}
                aria-label="Microphone icon"
                onClick={onMuteCall}
                title={muted ? 'Unmute' : 'Mute'}
            />
            <IconButton
                variant={onHold ? 'negatory-light' : 'tertiary'}
                size="lg"
                icon={<MusicIconRegular />}
                aria-label="Music icon"
                onClick={onHoldCall}
                title={onHold ? 'Unhold' : 'Hold'}
            />
            <IconButton
                variant="tertiary"
                size="lg"
                icon={<TransferIconRegular />}
                aria-label="Transfer button"
                onClick={onTransferCall}
                title="Transfer"
            />
            <IconButton
                variant="tertiary"
                size="lg"
                icon={<AddUserIconRegular />}
                aria-label="Participant button"
                onClick={onAddParticipant}
                isDisabled={numberOfMembers === 4} // global limit on the platform.
                title="Add participant"
            />
            <IconButton
                variant="tertiary"
                size="lg"
                icon={<SmartphoneOutIconRegular />}
                aria-label="Microphone button"
                title="Send to device"
            />
            <IconButton
                variant={transferring ? 'negatory-outline' : 'negatory'}
                icon={<HangupIconSolid boxSize="30px" />}
                size="lg"
                aria-label="Hang up button"
                onClick={onHangCall}
                title="Hang up"
            />
        </HStack>
    )
}
