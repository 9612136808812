import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PlaceholderIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PlaceholderIcon',
    viewBox: '0 0 34 34',
    path: (
        <g>
            <path
                d="M20.3333 3.66667C20.3333 5.50762 18.8409 7 17 7C15.159 7 13.6666 5.50762 13.6666 3.66667C13.6666 1.82572 15.159 0.333336 17 0.333336C18.8409 0.333336 20.3333 1.82572 20.3333 3.66667Z"
                fill="currentColor"
                key="1"
            />
            <path
                d="M22 17C22 19.7614 19.7614 22 17 22C14.2386 22 12 19.7614 12 17C12 14.2386 14.2386 12 17 12C19.7614 12 22 14.2386 22 17Z"
                fill="currentColor"
                key="2"
            />
            <path
                d="M17 33.6667C18.8409 33.6667 20.3333 32.1743 20.3333 30.3333C20.3333 28.4924 18.8409 27 17 27C15.159 27 13.6666 28.4924 13.6666 30.3333C13.6666 32.1743 15.159 33.6667 17 33.6667Z"
                fill="currentColor"
                key="3"
            />
            <path
                d="M6.99998 17C6.99998 18.841 5.5076 20.3333 3.66665 20.3333C1.8257 20.3333 0.333313 18.841 0.333313 17C0.333313 15.1591 1.8257 13.6667 3.66665 13.6667C5.5076 13.6667 6.99998 15.1591 6.99998 17Z"
                fill="currentColor"
                key="4"
            />
            <path
                d="M30.3333 20.3333C32.1743 20.3333 33.6666 18.841 33.6666 17C33.6666 15.1591 32.1743 13.6667 30.3333 13.6667C28.4924 13.6667 27 15.1591 27 17C27 18.841 28.4924 20.3333 30.3333 20.3333Z"
                fill="currentColor"
                key="5"
            />
        </g>
    )
})
