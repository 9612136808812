import { useDisclosure } from '@chakra-ui/react'
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    useToast
} from '@missionlabs/react'
import { User } from '@missionlabs/types'
import { inferFirstLastName } from '@missionlabs/utils'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
    useGetUserCallRouteQuery,
    useGetUserQuery,
    useUpdateUserCallRouteMutation,
    useUpdateUserMutation
} from 'shared/store'
import { buildNotAvailable } from 'shared/utils/notAvailable'

import { EditUserForm, EditUserFormFields } from './EditUserForm'

const noop = () => {}

export const EditUserDrawer: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { id: ID = '' } = useParams()
    const { data: user } = useGetUserQuery(ID)
    const { data: userCallroute } = useGetUserCallRouteQuery(ID)
    const { toast } = useToast()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const [updateUser, { isLoading: userLoading }] = useUpdateUserMutation()
    const [updateUserCallRoute, { isLoading: callRouteLoading }] = useUpdateUserCallRouteMutation()

    function handleTransition() {
        navigate('/admin/users')
    }

    const onSubmit = async (data: EditUserFormFields) => {
        const { callRoute, ...user } = data

        try {
            const userResponse = await updateUser({ ID, ...user } as Partial<User>).unwrap()
            const callRouteResponse = await updateUserCallRoute({
                ID,
                userID: ID,
                ...callRoute,
                notAvailable: buildNotAvailable({ ...callRoute?.notAvailable })
            }).unwrap()

            if (userResponse && callRouteResponse) onClose()
        } catch (error) {
            toast({
                title: t('admin.users.errorUpdating'),
                status: 'error'
            })
        }
    }

    const values: EditUserFormFields = useMemo(() => {
        const { firstName, lastName } = inferFirstLastName(user)
        return {
            firstName: firstName,
            lastName: lastName,
            email: user?.email ?? '',
            type: user?.type ?? 'standard',
            subscriptionType: user?.subscriptionType ?? 'pro',
            teams: user?.teams ?? [],
            outboundBarring: user?.outboundBarring,
            inboundBarring: user?.inboundBarring,
            callRoute: {
                callTimeout: userCallroute?.callTimeout ?? 10,
                inHoursUnanswered: userCallroute?.inHoursUnanswered ?? 'ring_out',
                unansweredRedirect: userCallroute?.unansweredRedirect,
                unansweredRedirectInternal: userCallroute?.unansweredRedirectInternal,
                voicemail: userCallroute?.voicemail,
                outOfHours: userCallroute?.outOfHours,
                outOfHoursRedirect: userCallroute?.outOfHoursRedirect,
                outOfHoursRedirectInternal: userCallroute?.outOfHoursRedirectInternal,
                outOfHoursVoicemail: userCallroute?.outOfHoursVoicemail,
                notAvailable: userCallroute?.notAvailable,
                blockAnonymousCalls: userCallroute?.blockAnonymousCalls
            }
        }
    }, [user, userCallroute])

    if (!user || !userCallroute) return null

    const isLoading = userLoading || callRouteLoading

    return (
        <Drawer
            size="lg"
            isOpen={isOpen}
            onClose={!isLoading ? onClose : noop}
            onCloseComplete={handleTransition}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>{t('admin.users.edit')}</DrawerHeader>
                <DrawerBody>
                    <EditUserForm
                        onSubmit={onSubmit}
                        onClose={onClose}
                        user={user}
                        isLoading={isLoading}
                        values={values}
                        phoneNumbers={userCallroute.phoneNumbers ?? []}
                    />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
