import { SettingsIconRegular, UserIconRegular } from '@missionlabs/react/zeta'
import { UserStatus } from '@missionlabs/types'
import { useRegisterActions } from 'kbar'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetUserData } from 'shared/hooks/useGetUserData'
import { useOnLogout } from 'shared/hooks/useOnLogout'
import { useUpdateUserCallRouteMutation } from 'shared/store'

import { commandMenuShortcuts } from './shortcuts'

export function useSettingsActions() {
    const navigate = useNavigate()
    const { handleLogout } = useOnLogout()

    const { user, callRoute } = useGetUserData()
    const [updateUserCallRoute] = useUpdateUserCallRouteMutation()

    const changeStatus = useCallback(
        (newState: UserStatus) => {
            if (!user || !callRoute) {
                console.log('no user or callRoute so cannot update status')
                return
            }

            const dnd = newState === UserStatus.DND

            updateUserCallRoute({
                ID: callRoute.ID,
                userID: user.ID,
                snoozeStatusName: newState.toLowerCase(),
                dnd,
                snoozeExpiry: {
                    duration: Number(newState !== UserStatus.Active),
                    timeFormat: 'days',
                    statusName: newState.toLowerCase()
                }
            })
        },
        [user, callRoute, updateUserCallRoute]
    )

    const settingsActions = useMemo(
        () => [
            {
                id: 'change-status',
                name: 'Change Status...',
                keywords: 'status',
                icon: <UserIconRegular />,
                section: 'Settings'
            },
            {
                id: 'active',
                name: 'Active',
                parent: 'change-status',
                perform: () => changeStatus(UserStatus.Active),
                keywords: 'active online available'
            },
            {
                id: 'away',
                name: 'Away',
                parent: 'change-status',
                perform: () => changeStatus(UserStatus.Away)
            },
            {
                id: 'busy',
                name: 'Busy',
                parent: 'change-status',
                perform: () => changeStatus(UserStatus.Busy)
            },
            {
                id: 'do-not-disturb',
                name: 'Do not disturb',
                parent: 'change-status',
                perform: () => changeStatus(UserStatus.DND)
            },
            {
                id: 'settings',
                name: 'Settings...',
                keywords: 'settings',
                section: 'Settings',
                shortcut: commandMenuShortcuts.settings,
                icon: <SettingsIconRegular />
            },
            {
                id: 'settings-my-details',
                name: 'My Details',
                parent: 'settings',
                perform: () => navigate('/settings/my-details')
            },
            {
                id: 'settings-my-numbers',
                name: 'My Numbers',
                parent: 'settings',
                perform: () => navigate('/settings/my-numbers')
            },
            {
                id: 'settings-calls',
                name: 'Calls',
                parent: 'settings',
                perform: () => navigate('/settings/calls')
            },
            {
                id: 'settings-devices',
                name: 'Devices',
                parent: 'settings',
                perform: () => navigate('/settings/devices')
            },
            {
                id: 'settings-directories',
                name: 'Directories',
                parent: 'settings',
                perform: () => navigate('/settings/directories')
            },
            {
                id: 'settings-audio',
                name: 'Audio',
                parent: 'settings',
                keywords: 'audio sound ringtone jabra microphone headset',
                perform: () => navigate('/settings/sounds')
            },
            {
                id: 'settings-integrations',
                name: 'Integrations',
                parent: 'settings',
                perform: () => navigate('/settings/integrations')
            },
            {
                id: 'settings-audio-library',
                name: 'Audio Library',
                parent: 'settings',
                perform: () => navigate('/settings/recordings'),
                keywords: 'audio recordings music'
            },
            {
                id: 'settings-notifications',
                name: 'Notifications',
                parent: 'settings',
                perform: () => navigate('/settings/notifications')
            },
            {
                id: 'settings-sign-out',
                name: 'Sign out',
                parent: 'settings',
                keywords: 'sign out logout',
                perform: () => handleLogout()
            }
        ],
        [navigate, handleLogout, changeStatus]
    )

    useRegisterActions(settingsActions, [user, callRoute])
}
