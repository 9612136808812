import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const AddContactIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddContactIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1331 8.58365C12.1331 11.4184 10.3107 13.6838 8.10914 13.6838C5.90761 13.6838 4.08514 11.4184 4.08514 8.58365C4.08514 5.86607 5.79505 4.02148 8.10914 4.02148C10.4232 4.02148 12.1331 5.86607 12.1331 8.58365ZM8.10914 14.7305C6.06119 14.7305 4.31757 13.2439 3.53147 11.1212C3.12486 11.3289 2.58244 11.7228 2.04464 12.3409C1.07886 13.4508 0.5 14.9143 0.5 16.7541C0.5 18.6772 1.42266 19.9496 2.92391 20.6397C3.43662 20.8754 3.98041 21.0254 4.52327 21.1086C4.85018 21.1587 5.1083 21.1756 5.26549 21.1756H10.9528C11.11 21.1756 11.3681 21.1587 11.695 21.1086C12.2379 21.0254 12.7817 20.8754 13.2944 20.6397C14.7956 19.9496 15.7183 18.6772 15.7183 16.7541C15.7183 14.9143 15.1394 13.4508 14.1736 12.3409C13.6358 11.7228 13.0934 11.3289 12.6868 11.1212C11.9007 13.2439 10.1571 14.7305 8.10914 14.7305ZM19.5 6.99998C19.7761 6.99998 20 7.22384 20 7.49998V9.99998H22.5C22.7761 9.99998 23 10.2238 23 10.5C23 10.7761 22.7761 11 22.5 11H20V13.5C20 13.7761 19.7761 14 19.5 14C19.2239 14 19 13.7761 19 13.5V11H16.5C16.2239 11 16 10.7761 16 10.5C16 10.2238 16.2239 9.99998 16.5 9.99998H19V7.49998C19 7.22384 19.2239 6.99998 19.5 6.99998Z"
            fill="currentColor"
        />
    )
})

export const ContactsListIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ContactsListIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.10914 13.6838C10.3107 13.6838 12.1331 11.4184 12.1331 8.58365C12.1331 5.86607 10.4232 4.02148 8.10914 4.02148C5.79505 4.02148 4.08514 5.86607 4.08514 8.58365C4.08514 11.4184 5.90761 13.6838 8.10914 13.6838ZM3.53147 11.1212C4.31757 13.2439 6.06119 14.7305 8.10914 14.7305C10.1571 14.7305 11.9007 13.2439 12.6868 11.1212C13.0934 11.3289 13.6358 11.7228 14.1736 12.3409C15.1394 13.4508 15.7183 14.9143 15.7183 16.7541C15.7183 18.6772 14.7956 19.9496 13.2944 20.6397C12.7817 20.8754 12.2379 21.0254 11.695 21.1086C11.3681 21.1587 11.11 21.1756 10.9528 21.1756H5.26549C5.1083 21.1756 4.85018 21.1587 4.52327 21.1086C3.98041 21.0254 3.43662 20.8754 2.92391 20.6397C1.42266 19.9496 0.5 18.6772 0.5 16.7541C0.5 14.9143 1.07886 13.4508 2.04464 12.3409C2.58244 11.7228 3.12486 11.3289 3.53147 11.1212ZM16.5 9.99571C16.2239 9.99571 16 10.2196 16 10.4957C16 10.7719 16.2239 10.9957 16.5 10.9957H22.5C22.7761 10.9957 23 10.7719 23 10.4957C23 10.2196 22.7761 9.99571 22.5 9.99571H16.5ZM17 7.50059C17 7.22445 17.2239 7.00059 17.5 7.00059H22.5C22.7761 7.00059 23 7.22445 23 7.50059C23 7.77673 22.7761 8.00059 22.5 8.00059H17.5C17.2239 8.00059 17 7.77673 17 7.50059ZM18.5 12.9989C18.2239 12.9989 18 13.2228 18 13.4989C18 13.7751 18.2239 13.9989 18.5 13.9989H22.5C22.7761 13.9989 23 13.7751 23 13.4989C23 13.2228 22.7761 12.9989 22.5 12.9989H18.5Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_178"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="4"
                width="23"
                height="18"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.10914 13.6838C10.3107 13.6838 12.1331 11.4184 12.1331 8.58365C12.1331 5.86607 10.4232 4.02148 8.10914 4.02148C5.79505 4.02148 4.08514 5.86607 4.08514 8.58365C4.08514 11.4184 5.90761 13.6838 8.10914 13.6838ZM3.53147 11.1212C4.31757 13.2439 6.06119 14.7305 8.10914 14.7305C10.1571 14.7305 11.9007 13.2439 12.6868 11.1212C13.0934 11.3289 13.6358 11.7228 14.1736 12.3409C15.1394 13.4508 15.7183 14.9143 15.7183 16.7541C15.7183 18.6772 14.7956 19.9496 13.2944 20.6397C12.7817 20.8754 12.2379 21.0254 11.695 21.1086C11.3681 21.1587 11.11 21.1756 10.9528 21.1756H5.26549C5.1083 21.1756 4.85018 21.1587 4.52327 21.1086C3.98041 21.0254 3.43662 20.8754 2.92391 20.6397C1.42266 19.9496 0.5 18.6772 0.5 16.7541C0.5 14.9143 1.07886 13.4508 2.04464 12.3409C2.58244 11.7228 3.12486 11.3289 3.53147 11.1212ZM16.5 9.99571C16.2239 9.99571 16 10.2196 16 10.4957C16 10.7719 16.2239 10.9957 16.5 10.9957H22.5C22.7761 10.9957 23 10.7719 23 10.4957C23 10.2196 22.7761 9.99571 22.5 9.99571H16.5ZM17 7.50059C17 7.22445 17.2239 7.00059 17.5 7.00059H22.5C22.7761 7.00059 23 7.22445 23 7.50059C23 7.77673 22.7761 8.00059 22.5 8.00059H17.5C17.2239 8.00059 17 7.77673 17 7.50059ZM18.5 12.9989C18.2239 12.9989 18 13.2228 18 13.4989C18 13.7751 18.2239 13.9989 18.5 13.9989H22.5C22.7761 13.9989 23 13.7751 23 13.4989C23 13.2228 22.7761 12.9989 22.5 12.9989H18.5Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_178)"></g>
        </g>
    )
})

export const ContactsIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ContactsIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 1C3.89543 1 3 1.89543 3 3V5H4C5.10457 5 6 5.89543 6 7C6 8.10457 5.10457 9 4 9H3V10H4C5.10457 10 6 10.8954 6 12C6 13.1046 5.10457 14 4 14H3V15H4C5.10457 15 6 15.8954 6 17C6 18.1046 5.10457 19 4 19H3V21C3 22.1046 3.89543 23 5 23H19C20.1046 23 21 22.1046 21 21V3C21 1.89543 20.1046 1 19 1H5ZM14.3617 9.39657C14.3604 9.43247 14.3588 9.46805 14.357 9.5033C14.4929 9.60185 14.5606 9.85056 14.5109 10.1153C14.4237 10.5803 14.2695 10.8302 14.1126 10.8803C13.9966 11.2179 13.8451 11.4951 13.6667 11.7118V12.2206C15.405 12.9487 16 13.4278 16 15.153C16 15.6207 15.6866 16 15.3 16H9.7C9.31343 16 9 15.6207 9 15.153C9 13.4278 9.59495 12.9487 11.3333 12.2206V11.7118C11.1537 11.4935 11.0012 11.2139 10.8849 10.8729C10.735 10.8088 10.5899 10.5608 10.5064 10.1153C10.4589 9.86158 10.5189 9.62253 10.6437 9.51635C10.6369 9.38791 10.6333 9.25501 10.6333 9.11765C10.6333 7.94809 11.469 7 12.5 7C13.531 7 14.3667 7.94809 14.3667 9.11765C14.3667 9.21278 14.365 9.30574 14.3617 9.39657Z"
                fill="currentColor"
            />
            <path
                d="M1 7C1 6.44772 1.44772 6 2 6H4C4.55228 6 5 6.44772 5 7C5 7.55228 4.55228 8 4 8H2C1.44772 8 1 7.55228 1 7Z"
                fill="currentColor"
            />
            <path
                d="M2 11C1.44772 11 1 11.4477 1 12C1 12.5523 1.44772 13 2 13H4C4.55228 13 5 12.5523 5 12C5 11.4477 4.55228 11 4 11H2Z"
                fill="currentColor"
            />
            <path
                d="M2 16C1.44772 16 1 16.4477 1 17C1 17.5523 1.44772 18 2 18H4C4.55228 18 5 17.5523 5 17C5 16.4477 4.55228 16 4 16H2Z"
                fill="currentColor"
            />
        </g>
    )
})

export const InternalContactsIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'InternalContactsIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.04028 5.69998V5.27513C8.04028 4.47988 8.73466 3.9807 9.60413 3.9807H14.4301C15.2995 3.9807 15.9939 4.47988 15.9939 5.27513V5.69998C15.9939 5.96297 16.2071 6.17617 16.4701 6.17617C16.7331 6.17617 16.9463 5.96297 16.9463 5.69998V5.27513C16.9463 3.94143 15.8138 3.02832 14.4301 3.02832H9.60413C8.22037 3.02832 7.0879 3.94143 7.0879 5.27513V5.69998C7.0879 5.96297 7.3011 6.17617 7.56409 6.17617C7.82709 6.17617 8.04028 5.96297 8.04028 5.69998ZM21.0742 18.8561C21.0742 20.0435 20.1593 21.0057 19.0303 21.0057H5.11892C3.98978 21.0057 3.07422 20.0435 3.07422 18.8561V14.0001H8.99976V13.0001H3.07422V9.1817C3.07422 7.99491 3.99001 7.03209 5.11892 7.03209H19.0303C20.1591 7.03209 21.0742 7.99483 21.0742 9.1817V13.0001H14.9998V14.0001H21.0742V18.8561ZM11.0742 12.0001C10.5219 12.0001 10.0742 12.4478 10.0742 13.0001V14.0001C10.0742 14.5524 10.5219 15.0001 11.0742 15.0001H12.9998C13.552 15.0001 13.9998 14.5524 13.9998 14.0001V13.0001C13.9998 12.4478 13.552 12.0001 12.9998 12.0001H11.0742Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_180"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="3"
                y="3"
                width="19"
                height="19"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.04028 5.69998V5.27513C8.04028 4.47988 8.73466 3.9807 9.60413 3.9807H14.4301C15.2995 3.9807 15.9939 4.47988 15.9939 5.27513V5.69998C15.9939 5.96297 16.2071 6.17617 16.4701 6.17617C16.7331 6.17617 16.9463 5.96297 16.9463 5.69998V5.27513C16.9463 3.94143 15.8138 3.02832 14.4301 3.02832H9.60413C8.22037 3.02832 7.0879 3.94143 7.0879 5.27513V5.69998C7.0879 5.96297 7.3011 6.17617 7.56409 6.17617C7.82709 6.17617 8.04028 5.96297 8.04028 5.69998ZM21.0742 18.8561C21.0742 20.0435 20.1593 21.0057 19.0303 21.0057H5.11892C3.98978 21.0057 3.07422 20.0435 3.07422 18.8561V14.0001H8.99976V13.0001H3.07422V9.1817C3.07422 7.99491 3.99001 7.03209 5.11892 7.03209H19.0303C20.1591 7.03209 21.0742 7.99483 21.0742 9.1817V13.0001H14.9998V14.0001H21.0742V18.8561ZM11.0742 12.0001C10.5219 12.0001 10.0742 12.4478 10.0742 13.0001V14.0001C10.0742 14.5524 10.5219 15.0001 11.0742 15.0001H12.9998C13.552 15.0001 13.9998 14.5524 13.9998 14.0001V13.0001C13.9998 12.4478 13.552 12.0001 12.9998 12.0001H11.0742Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_180)"></g>
        </g>
    )
})

export const SharedContactIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SharedContactIcon',
    viewBox: '0 0 24 24',
    path: [
        <path
            key="0"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.7397 6.32498C6.85503 7.25826 5.43061 8.97543 4.89649 11.0482C4.82027 11.045 4.74366 11.0435 4.66667 11.0435C4.07331 11.0435 3.50189 11.1371 2.96649 11.3102C3.4957 8.24702 5.55712 5.70576 8.33656 4.49744C8.41796 2.55217 10.027 1 12 1C14.025 1 15.6667 2.63514 15.6667 4.65218C15.6667 6.66922 14.025 8.30436 12 8.30436C10.5802 8.30436 9.34892 7.5006 8.7397 6.32498ZM10.1667 4.65218C10.1667 3.64366 10.9875 2.82609 12 2.82609C13.0125 2.82609 13.8333 3.64366 13.8333 4.65218C13.8333 5.6607 13.0125 6.47827 12 6.47827C10.9875 6.47827 10.1667 5.6607 10.1667 4.65218Z"
            fill="currentColor"
        />,
        <path
            key="1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.66667 12.8696C2.64162 12.8696 1 14.5047 1 16.5218C1 18.5388 2.64162 20.1739 4.66667 20.1739C5.18343 20.1739 5.67523 20.0675 6.12115 19.8753C7.71257 21.2016 9.76268 22 12 22C13.5014 22 14.9184 21.6405 16.1691 21.0032C15.6344 20.6279 15.1698 20.16 14.7989 19.623C13.9365 19.978 12.9912 20.1739 12 20.1739C10.3491 20.1739 8.82558 19.6305 7.59993 18.7135C8.06044 18.103 8.33333 17.3441 8.33333 16.5218C8.33333 14.5047 6.69171 12.8696 4.66667 12.8696ZM2.83333 16.5218C2.83333 15.5132 3.65414 14.6957 4.66667 14.6957C5.67919 14.6957 6.5 15.5132 6.5 16.5218C6.5 17.5303 5.67919 18.3478 4.66667 18.3478C3.65414 18.3478 2.83333 17.5303 2.83333 16.5218Z"
            fill="currentColor"
        />,
        <path
            key="2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.6667 16.5218C15.6667 14.5047 17.3083 12.8696 19.3333 12.8696C19.3333 12.8696 19.3333 12.8696 19.3333 12.8696C19.3333 10.6605 18.3488 8.68051 16.793 7.3411C17.108 6.78523 17.3284 6.16908 17.4323 5.51435C19.6972 7.17675 21.1667 9.85223 21.1667 12.8696C21.1667 13.031 21.1625 13.1916 21.1541 13.351C22.257 13.9807 23 15.1648 23 16.5218C23 18.5388 21.3584 20.1739 19.3333 20.1739C17.3083 20.1739 15.6667 18.5388 15.6667 16.5218ZM19.3333 14.6957C18.3208 14.6957 17.5 15.5132 17.5 16.5218C17.5 17.5303 18.3208 18.3478 19.3333 18.3478C20.3459 18.3478 21.1667 17.5303 21.1667 16.5218C21.1667 15.5132 20.3459 14.6957 19.3333 14.6957Z"
            fill="currentColor"
        />
    ]
})
