import { HStack, Skeleton, StackDivider, useColorMode, VStack } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { FC, ReactNode } from 'react'

type BillingFiguresProps = {
    data: { title: string; value: number | string | undefined }[]
    title: ReactNode
    isLoading: boolean
}

export const BillingFigures: FC<BillingFiguresProps> = ({ data, title, isLoading }) => {
    const { colorMode } = useColorMode()

    return (
        <VStack spacing="16px" px="32px" align="start">
            <Body as={'h4'} variant="bold">
                {title}
            </Body>
            <HStack spacing="32px" divider={<StackDivider />}>
                {data.map(({ title, value }) => (
                    <VStack key={title} spacing={0} align="start" w="full">
                        <Body as="h5" whiteSpace="nowrap" color={`${colorMode}.tones.stormGrey`}>
                            {title}
                        </Body>
                        <Skeleton h="36px" w="full" isLoaded={!isLoading}>
                            <Body size="lg" color={`${colorMode}.tones.navy`}>
                                {value}
                            </Body>
                        </Skeleton>
                    </VStack>
                ))}
            </HStack>
        </VStack>
    )
}
