import { ComponentStyleConfig } from '@chakra-ui/react'

export const PlayerStyles: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        iconButton: {
            h: '56px',
            w: '56px',
            bg: 'none',
            border: 'none',
            borderLeftRadius: '8px',
            borderRightRadius: 0
        },
        container: {
            h: '56px',
            w: 'full',
            border: '1px solid',
            borderColor: `${colorMode}.tones.periwinkle`,
            borderRadius: '8px'
        },
        playerContainer: {
            h: 'full',
            flex: 1,
            align: 'center',
            padding: '0 16px',
            borderLeft: '1px solid',
            borderColor: `${colorMode}.tones.periwinkle`
        },
        sliderTrack: {
            bg: `${colorMode}.tones.periwinkle`,
            borderRadius: '4px'
        },
        filledTrack: {
            bg: `${colorMode}.primary.gamma`
        },
        sliderThumb: {
            h: '24px',
            w: '24px',
            boxShadow: 'sm',
            border: '1px solid',
            borderColor: `${colorMode}.tones.periwinkle`,
            borderRadius: '4px'
        },
        label: {
            color: `${colorMode}.tones.stormGrey`,
            fontFamily: 'body',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '21px'
        }
    }),
    variants: {
        mini: {
            iconButton: {
                h: '40px',
                w: '40px'
            },
            container: {
                h: '40px',
                w: '102px'
            },
            playerContainer: {
                padding: '0 12px',
                borderRight: 'none'
            }
        }
    }
}
