import { HuntGroup, User } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

export const buildHuntGroupsApi = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'huntGroupsAPI',
        tagTypes: ['HuntGroups', 'HuntGroupMembers'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getHuntGroup: builder.query<HuntGroup, string>({
                query: id => `/teams/${id}`,
                providesTags: (_r, _e, id) => [{ type: 'HuntGroups', id }]
            }),
            getHuntGroups: builder.query<HuntGroup[], void>({
                query: () => '/teams',
                transformResponse: (response: { data: HuntGroup[] }) => {
                    return response.data?.map(team => {
                        return {
                            ...team,
                            ID: team.ID,
                            name: team.name,
                            phoneNumbers: team.phoneNumbers ?? []
                        }
                    })
                },
                providesTags: result =>
                    result
                        ? [
                              ...result.map(({ ID }) => ({ type: 'HuntGroups' as const, id: ID })),
                              'HuntGroups'
                          ]
                        : ['HuntGroups']
            }),
            getHuntGroupMembers: builder.query<User[], { teamID?: string }>({
                query: ({ teamID }) => {
                    if (!teamID) {
                        throw new Error('getHuntGroupMembers | TeamID is required')
                    }

                    return `/teams/${teamID}/users`
                },
                transformResponse: (response: { data: User[] }) => {
                    return response.data?.map(user => user)
                },
                providesTags: (result, _error, { teamID }) =>
                    result && teamID
                        ? [{ type: 'HuntGroupMembers', id: teamID }, 'HuntGroupMembers']
                        : ['HuntGroupMembers']
            }),
            createHuntGroup: builder.mutation<HuntGroup, Partial<HuntGroup>>({
                query: body => ({
                    url: '/teams',
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['HuntGroups']
            }),
            updateHuntGroup: builder.mutation<HuntGroup, Partial<HuntGroup>>({
                query: body => ({
                    url: `/teams/${body.ID}`,
                    method: 'PUT',
                    body
                }),
                invalidatesTags: ['HuntGroups']
            }),
            deleteHuntGroup: builder.mutation<string, string>({
                query: id => ({
                    url: `/teams/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['HuntGroups']
            }),
            signCallQueueGroup: builder.mutation<
                HuntGroup,
                { teamID: string; userID: string; signedIn?: boolean; canEdit?: boolean }
            >({
                query: ({ teamID, userID, signedIn, canEdit }) => {
                    return {
                        url: `/teams/${teamID}/members/${userID}`,
                        method: 'PATCH',
                        body: { signedIn, canEdit }
                    }
                },
                invalidatesTags: (result, error, { teamID }) => [{ type: 'HuntGroups', id: teamID }]
            })
        })
    })

    return { api, ...api }
}
