import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useMemo } from 'react'
import { useGetIntegrationsQuery } from 'shared/store'

export const useIntegrationAppInvalid = () => {
    const user = useSelector(selectAuthenticatedUser)

    const { data: apps } = useGetIntegrationsQuery(user?.userID ?? skipToken)

    const hasInvalidIntegration = useMemo(() => {
        return !!apps?.some(app => app.tokenInvalid)
    }, [apps])

    return hasInvalidIntegration
}
