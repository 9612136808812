import { Box, useColorMode } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface PlaceholderProps {}

export const Placeholder: FC<PlaceholderProps> = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    return (
        <Box
            display="inline-block"
            position="absolute"
            top="12px"
            pointerEvents="none"
            userSelect="none"
        >
            <Body size="sm" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                {t('Enter messsage...')}
            </Body>
        </Box>
    )
}
