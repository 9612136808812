import { Box } from '@chakra-ui/react'
import { RouterBoundary } from '@missionlabs/react'
import { UserRole } from '@missionlabs/types'
import { ActivityEmptyPage } from 'features/activity/ActivityEmptyPage'
import { ActivityFeed } from 'features/activity/ActivityFeed/ActivityFeed'
import { AdminDashboard } from 'features/admin/components/AdminDashboard'
import { ActivityLog } from 'features/admin/pages/ActivityLog/ActivityLog'
import { Billing } from 'features/admin/pages/Billing/Billing'
import { PayInvoiceDrawer } from 'features/admin/pages/Billing/PayInvoiceDrawer'
import { BlockedNumbers } from 'features/admin/pages/BlockedNumbers/BlockedNumbers'
import { CallRecording } from 'features/admin/pages/CallRecording/CallRecording'
import { GlobalSettings } from 'features/admin/pages/GlobalSettings/GlobalSettings'
import { AddMenuDrawer } from 'features/admin/pages/Menus/AddMenu/AddMenuDrawer'
import { EditMenuDrawer } from 'features/admin/pages/Menus/EditMenu/EditMenuDrawer'
import { Menus } from 'features/admin/pages/Menus/Menus'
import { AddNumberDrawer } from 'features/admin/pages/Numbers/AddNumber/AddNumberDrawer'
import { EditNumbersDrawer } from 'features/admin/pages/Numbers/EditNumbersDrawer'
import { ExistingNumberDrawer } from 'features/admin/pages/Numbers/ExistingNumber/ExistingNumberDrawer'
import { NumbersDashboard } from 'features/admin/pages/Numbers/NumbersDashboard'
import { RemoveNumberDialog } from 'features/admin/pages/Numbers/RemoveNumberDialog'
import { UnassignNumberDialog } from 'features/admin/pages/Numbers/UnassignNumberDialog'
import { SubscriptionDashboard } from 'features/admin/pages/Subscription/SubscriptionDashboard'
import { AddEditTeamDrawer } from 'features/admin/pages/Teams/AddEditTeam/AddEditTeamDrawer'
import { AssignToTeamDrawer } from 'features/admin/pages/Teams/AssignToTeamDrawer'
import { DeleteTeamDialog } from 'features/admin/pages/Teams/DeleteTeamDialog'
import { TeamsDashboard } from 'features/admin/pages/Teams/TeamsDashboard'
import { UploadClipTeamDrawer } from 'features/admin/pages/Teams/UploadClipTeamDrawer'
import { AddUserDrawer } from 'features/admin/pages/Users/AddUserDrawer'
import { EditUserDrawer } from 'features/admin/pages/Users/EditUserDrawer'
import { Users } from 'features/admin/pages/Users/Users'
import { AddWhatsAppNumberDrawer } from 'features/admin/pages/WhatsApp/AddNumber/AddWhatsAppNumberDrawer'
import { DeleteWhatsAppNumberDialog } from 'features/admin/pages/WhatsApp/dialogs/DeleteWhatsAppNumberDialog'
import { FacebookDisconnectDialog } from 'features/admin/pages/WhatsApp/dialogs/FacebookDisconnectDialog'
import { FacebookSignupDialog } from 'features/admin/pages/WhatsApp/dialogs/FacebookSignupDialog'
import { SelectWhatsAppNumberDrawer } from 'features/admin/pages/WhatsApp/SelectNumber/SelectWhatsAppNumberDrawer'
import { VerifyWhatsAppNumberDrawer } from 'features/admin/pages/WhatsApp/VerifyNumber/VerifyWhatsAppNumberDrawer'
import { WhatsApp } from 'features/admin/pages/WhatsApp/WhatsApp'
import Analytics from 'features/analytics'
import Numbers from 'features/analytics/pages/Numbers'
import AnalyticsNumberExpanded from 'features/analytics/pages/Numbers/AnalyticsNumberExpanded'
import Reports from 'features/analytics/pages/Reports'
import SearchNumber from 'features/analytics/pages/SearchNumber/index'
import Summary from 'features/analytics/pages/Summary'
import AnalyticsTeamsExpanded from 'features/analytics/pages/TeamsNumbers/AnalyticsTeamsExpanded'
import TeamsNumbers from 'features/analytics/pages/TeamsNumbers/index'
import AnalyticsUsers from 'features/analytics/pages/Users'
import AnalyticsUserExpanded from 'features/analytics/pages/Users/AnalyticsUserExpanded'
import { ForgotPassword } from 'features/auth/ForgotPassword'
import { Login } from 'features/auth/Login'
import { Logout } from 'features/auth/Logout'
import { ResetPassword } from 'features/auth/ResetPassword'
import { Welcome } from 'features/auth/Welcome'
import { ChatDashboard } from 'features/chat/ChatDashboard'
import { AddEditChatGroupDrawer } from 'features/chat/ChatGroups/AddEditChatGroupDrawer'
import { ViewChatGroupDrawer } from 'features/chat/ChatGroups/ViewChatGroupDrawer'
import { ChatView } from 'features/chat/ChatView'
import { ChatViewUnreadThreads } from 'features/chat/ChatViewUnreadThreads'
import { NewChatDrawer } from 'features/chat/NewChatDrawer'
import { ContactsDashboard } from 'features/contacts/ContactsDashboard'
import { ContactsEmptyPage } from 'features/contacts/ContactsEmptyPage'
import { ContactsView } from 'features/contacts/ContactsView/ContactsView'
import { MeetingDay } from 'features/meetings/MeetingDay'
import { MeetingsCallAddDrawer } from 'features/meetings/MeetingsCallAddDrawer'
import { MeetingsCallParticipantsDrawer } from 'features/meetings/MeetingsCallParticipantsDrawer'
import { MeetingsCallRemoveParticipantDialog } from 'features/meetings/MeetingsCallRemoveParticipantDialog'
import { MeetingsCallRoot } from 'features/meetings/MeetingsCallRoot'
import { MeetingsCallSettingsDrawer } from 'features/meetings/MeetingsCallSettingsDrawer'
import { MeetingsCallSetup } from 'features/meetings/MeetingsCallSetup'
import { MeetingsCallView } from 'features/meetings/MeetingsCallView'
import { MeetingsDashboard } from 'features/meetings/MeetingsDashboard'
import { MeetingsGuestHome } from 'features/meetings/MeetingsGuestHome'
import { SettingsDashboard } from 'features/settings/components/SettingsDashboard'
import { Calls } from 'features/settings/pages/Calls/Calls'
import { ConferenceCalling } from 'features/settings/pages/ConferenceCalling/ConferenceCalling'
import { DevicesDashboard } from 'features/settings/pages/Devices/DevicesDashboard'
import { DirectoriesDashboard } from 'features/settings/pages/Directories/DirectoriesDashboard'
import { Integrations } from 'features/settings/pages/Integrations/Integrations'
import { MyDetails } from 'features/settings/pages/MyDetails/MyDetails'
import { MyNumbers } from 'features/settings/pages/MyNumbers/MyNumbers'
import { Notifications } from 'features/settings/pages/Notifications/Notifications'
import { AddRecordingDrawer } from 'features/settings/pages/Recordings/AddRecordingDrawer'
import { DeleteRecordingDialog } from 'features/settings/pages/Recordings/DeleteRecordingDialog'
import { EditRecordingDrawer } from 'features/settings/pages/Recordings/EditRecordingDrawer'
import { RecordingsDashboard } from 'features/settings/pages/Recordings/RecordingsDashboard'
import { Sounds } from 'features/settings/pages/Sounds/Sounds'
import { VoiceDashboard } from 'features/voice/VoiceDashboard'
import { useFlags } from 'flagsmith/react'
import { FC, Fragment, useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { Root } from 'Root'
import { RootGuest } from 'RootGuest'
import { DownloadPage } from 'shared/components/DownloadPage'
import { ForbiddenPage } from 'shared/components/ForbiddenPage'
import { NotFoundPage } from 'shared/components/NotFoundPage'
import { Features, useHasClientFeature } from 'shared/hooks/useHasClientFeature'
import { useHasFeature } from 'shared/hooks/useHasFeature'
import { monitoring } from 'shared/monitoring/monitoring'
import { useGetMonitoringConfigQuery } from 'shared/store'

import { RequireAuth } from './RequireAuth'
import { RequireExactRoles } from './RequireExactRoles'
import { RequireFeature } from './RequireFeature'
import { RequireRole } from './RequireRole'

export const AppRouter: FC = () => {
    const flags = useFlags(['billing', 'subscription', 'speed_dial'])
    const location = useLocation()

    const state = location.state as { backgroundLocation?: Location }
    const hasVideoPermission = useHasFeature(Features.meetings)
    const whatsappClientEnabled = useHasClientFeature(Features.whatsapp)

    useEffect(() => {
        monitoring.recordPageView(location.pathname)
    }, [location])

    const { data: monitoringConfig } = useGetMonitoringConfigQuery()
    if (monitoringConfig) monitoring.setupClient(monitoringConfig)

    if (process.env.REACT_APP_GUEST === 'true') {
        return (
            <Routes>
                <Route path="/" element={<RootGuest />} errorElement={<RouterBoundary />}>
                    <Route path="" element={<MeetingsGuestHome />} />

                    <Route element={<MeetingsCallRoot isGuest />}>
                        <Route path="setup" element={<MeetingsCallSetup />} />
                        <Route path=":id" element={<MeetingsCallView />}>
                            <Route path="add" element={<MeetingsCallAddDrawer />} />
                            <Route
                                path="participants"
                                element={<MeetingsCallParticipantsDrawer />}
                            />
                            <Route path="settings" element={<MeetingsCallSettingsDrawer />} />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        )
    }

    return (
        <Routes location={state?.backgroundLocation || location}>
            <Route path="/" element={<Root />} errorElement={<RouterBoundary />}>
                {/* auth */}
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Logout />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset" element={<ResetPassword />} />
                <Route path="welcome" element={<Welcome />} />
                <Route path="forbidden" element={<ForbiddenPage />} />
                <Route path="download" element={<DownloadPage />} />

                <Route element={<RequireAuth />}>
                    {/* voice */}
                    <Route path="home" element={<VoiceDashboard />}>
                        <Route path="" element={<ActivityEmptyPage />} />
                        <Route path="feed" element={<ActivityFeed />} />
                    </Route>

                    {/* contacts */}
                    <Route path="contacts" element={<ContactsDashboard />}>
                        <Route path="" element={<ContactsEmptyPage />} />
                        <Route path="add" element={<ContactsEmptyPage />} />
                        <Route path=":id" element={<ContactsView />} />
                        <Route path=":id/edit" element={<ContactsView />} />
                    </Route>

                    {/* analytics */}

                    <Route path="analytics" element={<Analytics />}>
                        <Route path="" element={<Navigate to="summary" replace />} />
                        <Route path="summary" element={<Summary />} />
                        <Route path="users" element={<AnalyticsUsers />} />
                        <Route path="users/:userID" element={<AnalyticsUserExpanded />} />
                        <Route path="numbers" element={<Numbers />} />
                        <Route path="numbers/:numberE164" element={<AnalyticsNumberExpanded />} />
                        <Route path="teams" element={<TeamsNumbers />} />
                        <Route path="teams/:teamID" element={<AnalyticsTeamsExpanded />} />
                        <Route path="search-number" element={<SearchNumber />} />
                        <Route
                            element={
                                <RequireRole role={UserRole.ADMIN} redirect>
                                    <Outlet />
                                </RequireRole>
                            }
                        >
                            <Route path="" element={<Navigate to="/admin/users" replace />} />
                            <Route path="users" element={<Users />}>
                                <Route path="add" element={<AddUserDrawer />} />
                                <Route path=":id" element={<EditUserDrawer />} />
                            </Route>
                            <Route path="teams" element={<TeamsDashboard />}>
                                <Route path="add" element={<AddEditTeamDrawer />}>
                                    <Route path="assign" element={<AssignToTeamDrawer />} />
                                    <Route path="upload" element={<UploadClipTeamDrawer />} />
                                </Route>
                                <Route path=":id/edit" element={<AddEditTeamDrawer />}>
                                    <Route path="assign" element={<AssignToTeamDrawer />} />
                                    <Route path="upload" element={<UploadClipTeamDrawer />} />
                                </Route>
                                <Route path=":id/delete" element={<DeleteTeamDialog />} />
                            </Route>
                            <Route path="numbers" element={<NumbersDashboard />}>
                                <Route path="add" element={<AddNumberDrawer />} />
                                <Route path="existing/:type" element={<ExistingNumberDrawer />} />
                                <Route path=":id/edit" element={<EditNumbersDrawer />} />
                                <Route path=":id/remove" element={<RemoveNumberDialog />} />
                                <Route path=":id/assign" element={<EditNumbersDrawer />} />
                                <Route path=":id/unassign" element={<UnassignNumberDialog />} />
                            </Route>
                            <Route path="call-recording" element={<CallRecording />} />
                            <Route path="blocked-numbers" element={<BlockedNumbers />} />
                            <Route path="menus" element={<Menus />}>
                                <Route path="add" element={<AddMenuDrawer />} />
                                <Route path=":id" element={<EditMenuDrawer />} />
                            </Route>
                            <Route path="global-settings" element={<GlobalSettings />} />
                            <Route path="activity-log" element={<ActivityLog />} />
                            <Route path="billing" element={<Billing />} />
                            <Route path="subscription" element={<SubscriptionDashboard />}>
                                <Route
                                    path="cancel"
                                    element={
                                        <RequireExactRoles
                                            roles={['superuser', 'owner']}
                                            redirect
                                        />
                                    }
                                />
                                <Route path="payment-method" element={<Fragment />} />
                            </Route>
                            {whatsappClientEnabled && (
                                <Route path="whatsapp" element={<WhatsApp />}>
                                    <Route path="signup" element={<FacebookSignupDialog />} />
                                    <Route
                                        path="disconnect"
                                        element={<FacebookDisconnectDialog />}
                                    />
                                    <Route path="select" element={<SelectWhatsAppNumberDrawer />} />
                                    <Route path=":id/add" element={<AddWhatsAppNumberDrawer />} />
                                    <Route
                                        path=":id/verify"
                                        element={<VerifyWhatsAppNumberDrawer />}
                                    />
                                    {/* <Route path="select" element={<AddWhatsAppNumberDrawer />} /> */}
                                    <Route
                                        path=":id/delete"
                                        element={<DeleteWhatsAppNumberDialog />}
                                    />
                                </Route>
                            )}
                            <Route path="reports" element={<Reports />} />
                        </Route>
                    </Route>

                    {/* meetings */}
                    {hasVideoPermission && (
                        <Route path="meetings" element={<MeetingsDashboard />}>
                            <Route path="" element={<Navigate to="/meetings/day" replace />} />
                            <Route element={<MeetingsCallRoot />}>
                                <Route path="setup" element={<MeetingsCallSetup />} />
                                <Route path=":id" element={<MeetingsCallView />}>
                                    <Route
                                        path="add"
                                        element={
                                            <MeetingsCallAddDrawer siblingPath="participants" />
                                        }
                                    />
                                    <Route
                                        path="participants"
                                        element={<MeetingsCallParticipantsDrawer />}
                                    />
                                    <Route
                                        path="remove/:attendeeID"
                                        element={<MeetingsCallRemoveParticipantDialog />}
                                    />
                                    <Route
                                        path="settings"
                                        element={<MeetingsCallSettingsDrawer />}
                                    />
                                </Route>
                            </Route>
                            {/* todo: handle default time ranges */}
                            <Route path="day" element={<MeetingDay />}>
                                <Route path="new" element={<Fragment />} />
                                <Route path=":id/edit" element={<Fragment />} />
                                <Route path=":id/participants" element={<Fragment />} />
                                <Route path=":id" element={<Fragment />} />
                            </Route>
                        </Route>
                    )}

                    {/* settings */}
                    <Route path="settings" element={<SettingsDashboard />}>
                        <Route path="" element={<Navigate to="/settings/my-details" replace />} />
                        <Route path="my-details" element={<MyDetails />} />
                        <Route path="my-numbers" element={<MyNumbers />} />
                        <Route path="devices" element={<DevicesDashboard />}>
                            <Route path="add" element={<Fragment />} />
                            <Route path=":id/edit" element={<Fragment />} />
                        </Route>
                        {flags.speed_dial.enabled && (
                            <Route path="directories" element={<DirectoriesDashboard />}>
                                <Route path="speed-dial" element={<Fragment />}>
                                    <Route path="add" element={<Fragment />} />
                                </Route>
                            </Route>
                        )}
                        <Route path="calls" element={<Calls />} />
                        <Route path="sounds" element={<Sounds />} />
                        <Route path="recordings" element={<RecordingsDashboard />}>
                            <Route path="add" element={<AddRecordingDrawer />} />
                            <Route path=":id/delete" element={<DeleteRecordingDialog />} />
                            <Route path=":id/edit" element={<EditRecordingDrawer />} />
                        </Route>
                        <Route path="notifications" element={<Notifications />} />
                        <Route path="conference-calling" element={<ConferenceCalling />} />
                        <Route path="integrations" element={<Integrations />} />
                    </Route>

                    {/* admin */}
                    <Route
                        path="admin"
                        element={
                            <RequireRole role={UserRole.ADMIN} redirect>
                                <AdminDashboard />
                            </RequireRole>
                        }
                    >
                        <Route path="" element={<Navigate to="/admin/users" replace />} />
                        <Route path="users" element={<Users />}>
                            <Route path="add" element={<AddUserDrawer />} />
                            <Route path=":id" element={<EditUserDrawer />} />
                        </Route>
                        <Route path="teams" element={<TeamsDashboard />}>
                            <Route path="add" element={<AddEditTeamDrawer />}>
                                <Route path="assign" element={<AssignToTeamDrawer />} />
                                <Route path="upload" element={<UploadClipTeamDrawer />} />
                            </Route>
                            <Route path=":id/edit" element={<AddEditTeamDrawer />}>
                                <Route path="assign" element={<AssignToTeamDrawer />} />
                                <Route path="upload" element={<UploadClipTeamDrawer />} />
                            </Route>
                            <Route path=":id/delete" element={<DeleteTeamDialog />} />
                        </Route>
                        <Route path="numbers" element={<NumbersDashboard />}>
                            <Route path="add" element={<AddNumberDrawer />} />
                            <Route path="existing/:type" element={<ExistingNumberDrawer />} />
                            <Route path=":id/edit" element={<EditNumbersDrawer />} />
                            <Route path=":id/remove" element={<RemoveNumberDialog />} />
                            <Route path=":id/assign" element={<EditNumbersDrawer />} />
                            <Route path=":id/unassign" element={<UnassignNumberDialog />} />
                        </Route>
                        <Route path="call-recording" element={<CallRecording />} />
                        <Route path="blocked-numbers" element={<BlockedNumbers />} />
                        <Route path="menus" element={<Menus />}>
                            <Route path="add" element={<AddMenuDrawer />} />
                            <Route path=":id" element={<EditMenuDrawer />} />
                        </Route>
                        <Route path="global-settings" element={<GlobalSettings />} />
                        <Route path="directories" element={<DirectoriesDashboard />}>
                            <Route path="speed-dial" element={<Fragment />}>
                                <Route path="add" element={<Fragment />} />
                            </Route>
                        </Route>
                        <Route path="activity-log" element={<ActivityLog />} />
                        {flags.billing.enabled && (
                            <Route path="billing" element={<Billing />}>
                                <Route path="invoice-details/:planId" element={<Fragment />} />
                                <Route path="invoices/:id/pay" element={<PayInvoiceDrawer />} />
                            </Route>
                        )}
                        {flags.subscription.enabled && (
                            <Route path="subscription" element={<SubscriptionDashboard />}>
                                <Route path="cancel" element={<Fragment />} />
                                <Route path="payment-method" element={<Fragment />} />
                            </Route>
                        )}
                        {whatsappClientEnabled && (
                            <Route path="whatsapp" element={<WhatsApp />}>
                                <Route path="signup" element={<FacebookSignupDialog />} />
                                <Route path="disconnect" element={<FacebookDisconnectDialog />} />
                                <Route path="select" element={<SelectWhatsAppNumberDrawer />} />
                                <Route path=":id/add" element={<AddWhatsAppNumberDrawer />} />
                                <Route path=":id/verify" element={<VerifyWhatsAppNumberDrawer />} />
                                {/* <Route path="select" element={<AddWhatsAppNumberDrawer />} /> */}
                                <Route path=":id/delete" element={<DeleteWhatsAppNumberDialog />} />
                            </Route>
                        )}
                    </Route>
                </Route>
                {/* chat */}

                <Route
                    path="chat"
                    element={
                        <RequireFeature feature="chat" redirect>
                            <ChatDashboard />
                        </RequireFeature>
                    }
                >
                    <Route path="" element={<div />} />
                    <Route path="unread-threads" element={<ChatViewUnreadThreads />} />
                    <Route path=":id" element={<ChatView />}>
                        <Route path=":messageID" element={<ChatView />} />
                        <Route path="thread/:threadMessageID" element={<ChatView />} />
                    </Route>
                    <Route
                        path="add"
                        element={
                            <>
                                <Box />
                                <NewChatDrawer />
                            </>
                        }
                    />
                    <Route path="group">
                        <Route path=":id" element={<ChatView />}>
                            <Route path="thread/:threadMessageID" element={<ChatView />} />
                        </Route>
                    </Route>
                    <Route path="groups">
                        <Route
                            path="add"
                            element={
                                <>
                                    <Box />
                                    <AddEditChatGroupDrawer />
                                </>
                            }
                        />
                        <Route
                            path=":id/edit"
                            element={
                                <>
                                    <Box />
                                    <AddEditChatGroupDrawer />
                                </>
                            }
                        />
                        <Route
                            path=":id"
                            element={
                                <>
                                    <Box />
                                    <ViewChatGroupDrawer />
                                </>
                            }
                        ></Route>
                    </Route>
                </Route>
            </Route>

            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    )
}
