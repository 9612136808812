import { Popover, PopoverAnchor, PopoverBody, PopoverContent, PopoverProps } from '@chakra-ui/react'
import { Emoji } from '@missionlabs/api'
import { Input, Scrollable, useFuzzySearch } from '@missionlabs/react'
import { SearchIconRegular } from '@missionlabs/react/zeta'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FixedSizeGrid, GridItemKeySelector } from 'react-window'
import { useGetEmojisQuery } from 'shared/store'

import { EmojiPickerItemProps } from './EmojiPickerItem'
import { EmojiPickerRenderer } from './EmojiPickerRenderer'

export const COLUMN_COUNT = 8

export interface EmojiPickerProps extends PopoverProps, Pick<EmojiPickerItemProps, 'onClick'> {}

export const EmojiPicker: FC<EmojiPickerProps> = ({ isOpen, onClick, onClose, ...props }) => {
    const { t } = useTranslation()

    const [search, setSearch] = useState<string>('')

    const { data: emojis = [] } = useGetEmojisQuery()

    const getItemKey: GridItemKeySelector<Emoji[] | undefined> = ({
        data,
        columnIndex,
        rowIndex
    }) => {
        const index = rowIndex * COLUMN_COUNT + columnIndex
        return data?.[index]?.id ?? `${columnIndex}:${rowIndex}`
    }

    function handleClose() {
        setSearch('')
        onClose?.()
    }

    const { results } = useFuzzySearch<Emoji>({
        items: emojis,
        searchValue: search,
        keys: ['name', 'keywords'],
        options: {
            minMatchCharLength: 1
        }
    })

    return (
        <Popover
            placement="top-start"
            offset={[-16, 0]}
            {...props}
            isLazy={true}
            returnFocusOnClose={false}
            isOpen={isOpen}
            onClose={handleClose}
        >
            <PopoverAnchor>
                {/* fake anchor to allow us to render the component wherever in the Chatbox tree */}
                <div style={{ visibility: 'hidden' }} />
            </PopoverAnchor>
            <PopoverContent>
                <PopoverBody
                    display="flex"
                    flexDir="column"
                    p="16px"
                    gap="16px"
                    alignItems="center"
                >
                    <Input
                        value={search}
                        placeholder={t('Search')}
                        rightIcon={<SearchIconRegular />}
                        onChange={e => setSearch(e.target.value)}
                    />
                    <FixedSizeGrid
                        columnCount={COLUMN_COUNT}
                        columnWidth={36}
                        rowCount={Math.ceil((results?.length ?? 1) / 8)}
                        rowHeight={36}
                        height={236}
                        width={288}
                        itemData={results}
                        itemKey={getItemKey}
                        outerElementType={Scrollable}
                    >
                        {props => <EmojiPickerRenderer {...props} onClick={onClick} />}
                    </FixedSizeGrid>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}
