import { Box, Center, Divider, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Avatar, Body, useMeasure } from '@missionlabs/react'
import { ChatMessage } from '@missionlabs/types'
import { format } from 'date-fns'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetPresence } from 'shared/hooks/useGetPresence'

function parseHTML(html: string) {
    const parser = new DOMParser()
    const dom = parser.parseFromString(html, 'text/html')
    return dom.body.innerHTML
}

export interface ChatThreadRowProps {
    item: ChatMessage
    newTag?: boolean
    unreadCount: number
}

export const ChatThreadRow: FC<ChatThreadRowProps> = ({ item, newTag, unreadCount }) => {
    const [ref, { height }] = useMeasure<HTMLDivElement>()

    const { colorMode } = useColorMode()
    const isUpdated = item?.updated > item?.created
    const { t } = useTranslation()

    const { status } = useGetPresence(item?.userID)
    const content = item.contentHTML || item.contentPlainText

    function renderAvatar() {
        if (item.parentMessageID) {
            return (
                <HStack spacing={0}>
                    <Center>
                        {unreadCount > 0 && (
                            <Divider h={height} borderStyle="dotted" orientation="vertical" />
                        )}
                        <Divider w="15px" borderStyle="dotted" orientation="horizontal" />
                    </Center>
                    <Avatar
                        size="sm"
                        variant="whiteLilac"
                        name={item?.user?.name || item?.channel?.user?.name}
                        status={status}
                    />
                </HStack>
            )
        }
        return (
            <VStack spacing={0}>
                <Avatar
                    size="sm"
                    variant="whiteLilac"
                    name={item?.user?.name || item?.channel?.user?.name}
                    status={status}
                />
                <VStack spacing={0} position="relative">
                    <Divider
                        position="absolute"
                        h={height}
                        borderStyle="dotted"
                        orientation="vertical"
                    />
                </VStack>
            </VStack>
        )
    }

    return (
        <Box
            position="inherit"
            ml={item.parentMessageID ? '40px' : undefined}
            mt={item.parentMessageID ? '12px' : undefined}
            px={item.parentMessageID ? '0' : '24.5px'}
            ref={ref}
        >
            <HStack spacing="10px" align="start">
                {renderAvatar()}
                <VStack spacing="4px" align="start">
                    <HStack spacing="8px">
                        {newTag && (
                            <Body
                                size="sm"
                                variant="bold"
                                sx={{
                                    color: `${colorMode}.primary.gamma`,
                                    fontWeight: 800
                                }}
                            >
                                {t('chat.new')}
                            </Body>
                        )}

                        <Body
                            size="sm"
                            variant="bold"
                            sx={{
                                color: `${colorMode}.tones.midnightExpress`,
                                fontWeight: 800
                            }}
                        >
                            {item?.user?.name || item?.channel?.user?.name}
                        </Body>

                        <Body size="sm" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                            {format(item.created, 'HH:mm')}
                        </Body>
                    </HStack>
                    <div dangerouslySetInnerHTML={{ __html: parseHTML(content) }} />
                    {isUpdated && (
                        <Body size="sm" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                            ({t('edited')})
                        </Body>
                    )}
                </VStack>
            </HStack>
        </Box>
    )
}
