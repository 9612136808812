import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import { DatePicker, DatePickerProps, Input, Themes } from '@missionlabs/react'
import { CalendarIconRegular } from '@missionlabs/react/zeta'
import { FC } from 'react'

type DatePickerPopoverProps = Omit<DatePickerProps, 'theme'> & {
    triggerValue: string
}

export const DatePickerPopover: FC<DatePickerPopoverProps> = ({ triggerValue, ...props }) => {
    return (
        <Popover>
            <PopoverTrigger>
                <Input
                    value={triggerValue}
                    cursor="pointer"
                    isReadOnly
                    rightIcon={<CalendarIconRegular />}
                />
            </PopoverTrigger>
            <PopoverContent p={1} alignItems="center" justifyContent="center">
                <DatePicker theme={Themes.ZETA} {...props} />
            </PopoverContent>
        </Popover>
    )
}
