import { tabsAnatomy } from '@chakra-ui/anatomy'
import { ComponentStyleConfig, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys)

const baseStyle = definePartsStyle({
    tab: defineStyle({
        color: 'light.tones.stormGrey'
    }),
    tabpanel: defineStyle({
        padding: '0'
    })
})

const variants = {
    lineBottom: definePartsStyle({
        tab: defineStyle({
            borderBottom: '1px solid',
            borderColor: 'light.tones.periwinkle',
            mb: '-1px',
            _hover: {
                borderColor: 'light.tones.stormGrey',
                color: 'light.tones.navy'
            },
            _selected: {
                borderColor: 'light.primary.gamma',
                color: 'light.tones.midnightExpress'
            }
        }),
        tablist: defineStyle({
            borderBottom: '1px solid',
            borderColor: 'light.tones.periwinkle'
        })
    }),
    lineTop: definePartsStyle({
        tab: defineStyle({
            borderTop: '1px solid',
            borderColor: 'light.tones.periwinkle',
            mt: '-1px',
            _hover: {
                borderColor: 'light.tones.stormGrey',
                color: 'light.tones.navy'
            },
            _selected: {
                borderColor: 'light.primary.gamma',
                color: 'light.tones.midnightExpress'
            }
        }),
        tablist: defineStyle({
            borderTop: '1px solid',
            borderColor: 'light.tones.periwinkle'
        })
    }),
    fill: definePartsStyle({
        tab: defineStyle({
            _hover: {
                bg: 'light.tones.whiteLilac',
                color: 'light.tones.navy'
            },
            _selected: {
                bg: 'light.primary.gamma',
                color: 'light.tones.white'
            }
        })
    })
}

const sizes = {
    sm: definePartsStyle({
        tab: defineStyle({
            padding: '10px 12px 9px',
            fontFamily: 'body',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '21px'
        })
    }),
    md: definePartsStyle({
        tab: defineStyle({
            padding: '10px 16px 9px',
            fontFamily: 'heading',
            fontWeight: '800',
            fontSize: '16px',
            lineHeight: '28px',
            letterSpacing: ' -0.02em'
        })
    }),
    lg: definePartsStyle({
        tab: defineStyle({
            padding: '16px 16px 15px',
            fontFamily: 'heading',
            fontWeight: '800',
            fontSize: '16px',
            lineHeight: '28px',
            letterSpacing: ' -0.02em'
        })
    })
}

export const TabsStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle,
    defaultProps: {
        size: 'md',
        variant: 'lineBottom'
    },
    sizes,
    variants
})
