import { Themes } from '@missionlabs/react'
import { AnalyticsGroup } from '@missionlabs/types'

import { DatePeriod } from '../context'

export const getTimeFormatForGroup = (group: AnalyticsGroup | undefined) => {
    if (group === 'month') return '%b %y'
    if (group === 'hour') return '%H:%M'
    return '%e %b'
}

export const getTimeFormatForPeriod = (period: DatePeriod | undefined) => {
    if (period === 'days') return '%H:%M'
    return '%e %b'
}

export const getTickValuesForPeriod = (period: DatePeriod | undefined) => {
    if (period === 'months' || period === 'custom') return 'every week'
    if (period === 'days') return 'every hour'
    return 'every day'
}

export const getPrecisionForPeriod = (period: DatePeriod | undefined) => {
    if (period === 'days') return 'hour'
    return 'day'
}

export const getAnalyticsColours = (theme: Themes) => {
    if (theme === Themes.PHONELINEPLUS)
        return {
            inbound: '#6B49F1',
            outbound: '#3EBC9E',
            missed: '#DA0075',
            remainder: '#CACEE9'
        }

    return {
        inbound: '#346DFF',
        outbound: '#3EBC9E',
        missed: '#DD4833',
        remainder: '#CACEE9'
    }
}
