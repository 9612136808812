import { createIcon } from '@chakra-ui/react'

export const SpeakerIconLight = createIcon({
    displayName: 'SpeakerIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M13.6039 19.948C13.3874 20.0346 13.1818 20.0078 12.987 19.8675C12.7922 19.7264 12.6948 19.526 12.6948 19.2662C12.6948 19.158 12.7329 19.0606 12.8091 18.974C12.8844 18.8874 12.9762 18.8225 13.0844 18.7792C14.7727 18.0866 16.1307 16.9935 17.1584 15.5C18.187 14.0065 18.7013 12.3398 18.7013 10.5C18.7013 8.66017 18.187 6.99351 17.1584 5.5C16.1307 4.00649 14.7727 2.91342 13.0844 2.22078C12.9545 2.17749 12.8571 2.10736 12.7922 2.01039C12.7273 1.91255 12.6948 1.7987 12.6948 1.66883C12.6948 1.43074 12.7922 1.24675 12.987 1.11688C13.1818 0.987013 13.3874 0.965368 13.6039 1.05195C15.5087 1.87446 17.0511 3.12987 18.2312 4.81818C19.4104 6.50649 20 8.40043 20 10.5C20 12.5996 19.4104 14.4935 18.2312 16.1818C17.0511 17.8701 15.5087 19.1255 13.6039 19.948ZM1.07143 13.1299C0.768398 13.1299 0.513853 13.0268 0.307792 12.8208C0.102597 12.6156 0 12.3723 0 12.0909V8.97403C0 8.69264 0.102597 8.44935 0.307792 8.24416C0.513853 8.03809 0.768398 7.93506 1.07143 7.93506H4.44805L7.30519 5.07792C7.65152 4.75325 8.0355 4.67749 8.45714 4.85065C8.87965 5.02381 9.09091 5.34848 9.09091 5.82468V15.2403C9.09091 15.7164 8.87965 16.0411 8.45714 16.2143C8.0355 16.3874 7.65152 16.3117 7.30519 15.987L4.44805 13.1299H1.07143ZM12.6948 14.8182V6.18182C13.4091 6.63636 13.961 7.25325 14.3506 8.03247C14.7403 8.81169 14.9351 9.64502 14.9351 10.5325C14.9351 11.4199 14.7403 12.2424 14.3506 13C13.961 13.7576 13.4091 14.3636 12.6948 14.8182ZM7.79221 6.44156L5 9.23377H1.2987V11.8312H5L7.79221 14.6234V6.44156Z"
            fill="currentColor"
        />
    )
})

export const SpeakerIconRegular = createIcon({
    displayName: 'SpeakerIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M13.7222 19.5847C13.3519 19.7328 13.0093 19.6865 12.6944 19.4458C12.3796 19.205 12.2222 18.8717 12.2222 18.4458C12.2222 18.2421 12.2826 18.0617 12.4033 17.9047C12.5233 17.7469 12.6759 17.631 12.8611 17.5569C14.3426 16.9643 15.5326 16.0198 16.4311 14.7235C17.3289 13.4272 17.7778 11.9643 17.7778 10.3347C17.7778 8.70503 17.3289 7.24206 16.4311 5.94577C15.5326 4.64947 14.3426 3.70503 12.8611 3.11243C12.6574 3.03836 12.5 2.91799 12.3889 2.75132C12.2778 2.58466 12.2222 2.39947 12.2222 2.19577C12.2222 1.78836 12.3796 1.46429 12.6944 1.22354C13.0093 0.982804 13.3519 0.936508 13.7222 1.08466C15.6111 1.84392 17.1296 3.05688 18.2778 4.72355C19.4259 6.39021 20 8.26058 20 10.3347C20 12.4087 19.4259 14.2791 18.2778 15.9458C17.1296 17.6124 15.6111 18.8254 13.7222 19.5847ZM1.11111 13.6958C0.796296 13.6958 0.532593 13.5891 0.32 13.3758C0.106667 13.1632 0 12.8995 0 12.5847V8.14021C0 7.8254 0.106667 7.56132 0.32 7.34799C0.532593 7.1354 0.796296 7.0291 1.11111 7.0291H4.44444L8.11111 3.36243C8.46296 3.01058 8.86556 2.93169 9.31889 3.12577C9.77296 3.32058 10 3.66799 10 4.16799V16.5569C10 17.0569 9.77296 17.4039 9.31889 17.598C8.86556 17.7928 8.46296 17.7143 8.11111 17.3624L4.44444 13.6958H1.11111ZM12.2222 14.8069V5.86243C13.0556 6.25132 13.7267 6.85317 14.2356 7.66799C14.7452 8.4828 15 9.38095 15 10.3624C15 11.3439 14.7452 12.2328 14.2356 13.0291C13.7267 13.8254 13.0556 14.418 12.2222 14.8069ZM7.77778 6.86243L5.38889 9.25132H2.22222V11.4735H5.38889L7.77778 13.8624V6.86243Z"
            fill="currentColor"
        />
    )
})

export const SpeakerIconSolid = createIcon({
    displayName: 'SpeakerIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M14.047 18.8465C13.6641 19.0032 13.3072 18.9551 12.9765 18.7024C12.6458 18.4503 12.4804 18.1067 12.4804 17.6716C12.4804 17.4627 12.5413 17.2712 12.6632 17.0972C12.785 16.9231 12.9504 16.8013 13.1593 16.7316C14.517 16.1572 15.6136 15.2608 16.4491 14.0423C17.2846 12.8239 17.7024 11.4662 17.7024 9.96924C17.7024 8.47228 17.2846 7.11458 16.4491 5.89613C15.6136 4.67768 14.517 3.78125 13.1593 3.20683C12.933 3.13721 12.7634 3.01084 12.6507 2.82772C12.5372 2.6453 12.4804 2.44965 12.4804 2.24078C12.4804 1.80562 12.6458 1.46619 12.9765 1.2225C13.3072 0.97881 13.6641 0.935293 14.047 1.09195C15.8399 1.82302 17.2804 2.98925 18.3687 4.59065C19.4562 6.19204 20 7.9849 20 9.96924C20 11.9536 19.4562 13.7464 18.3687 15.3478C17.2804 16.9492 15.8399 18.1154 14.047 18.8465ZM1.38381 13.6246C1.00087 13.6246 0.674326 13.4895 0.404178 13.2194C0.134726 12.9499 0 12.6237 0 12.2408V7.74991C0 7.36697 0.134726 7.04043 0.404178 6.77028C0.674326 6.50083 1.00087 6.3661 1.38381 6.3661H4.46475L8.04177 2.78908C8.47694 2.35392 8.9772 2.25818 9.54256 2.50187C10.1086 2.74556 10.3916 3.17202 10.3916 3.78125V16.2094C10.3916 16.8187 10.1086 17.2451 9.54256 17.4888C8.9772 17.7325 8.47694 17.6368 8.04177 17.2016L4.46475 13.6246H1.38381ZM12.4804 14.3557V5.58281C13.3333 5.98316 14.0164 6.58368 14.5295 7.38438C15.0433 8.18508 15.3003 9.0554 15.3003 9.99535C15.3003 10.9353 15.0433 11.7969 14.5295 12.5802C14.0164 13.3635 13.3333 13.9553 12.4804 14.3557Z"
            fill="currentColor"
        />
    )
})
