import Transport, { Log, TransportConfig, Transports } from './transport'

interface HttpTransportConfig extends TransportConfig {
    endpoint: string
    method?: string
}

const defaultConfig: Partial<HttpTransportConfig> = {
    template: ({ message }) => message,
    level: 'info'
}

class HttpTransport extends Transport<HttpTransportConfig> {
    public readonly name = Transports.http

    constructor(config: HttpTransportConfig) {
        super({ ...defaultConfig, ...config })
    }

    public static find(x: any): x is InstanceType<typeof HttpTransport> {
        return x.name === Transports.http
    }

    public format(value: any): string {
        return JSON.stringify(value)
    }

    public log(value: Log): string {
        try {
            fetch(this.config.endpoint, {
                method: this.config.method?.toUpperCase() ?? 'POST',
                body: JSON.stringify(value),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        } catch (e) {
            console.error('HttpTransport | Failed to post log!', e)
        }
        return value.message
    }
}

export default HttpTransport
