import { HStack, useColorMode, UseDisclosureReturn, VStack } from '@chakra-ui/react'
import {
    MeetingEventType,
    OpenCreateMeetingWindow,
    openMeetingWindow,
    selectAuthenticatedUser,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { Avatar, Body, IconButton } from '@missionlabs/react'
import { ChatIconRegular, FilterIconRegular, VideoIconSolid } from '@missionlabs/react/zeta'
import { ContactType, DirectoryEntry } from '@missionlabs/types'
import { isInternalContact } from '@missionlabs/utils'
import { useFlags } from 'flagsmith/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useBlockContact } from 'shared/hooks/useBlockContact'
import { useCanSendWhatsApp } from 'shared/hooks/useCanSendWhatsApp'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'
import { Features } from 'shared/hooks/useHasClientFeature'
import { useHasFeature } from 'shared/hooks/useHasFeature'
import { useIsSMSEnabled } from 'shared/hooks/useIsSMSEnabled'
import { useCreateChannelMutation } from 'shared/store'

import { ActivityFeedHeaderMenu } from './ActivityFeedHeaderMenu'
import { ActivityFeedMessagingMenu } from './ActivityFeedMessagingMenu'
import { ActivityFeedNumberMenu } from './ActivityFeedNumberMenu'
import { useAddNumberToContactDrawer } from './components/AddNumberToContact/useAddNumberToContactDrawer'
import { useActivityFilters } from './hooks/useActivityFeedFilters'

interface ActivityFeedHeaderProps {
    // For contacts:
    contact?: DirectoryEntry
    // For unsaved contacts:
    contactNumber?: string
    showFooter?: UseDisclosureReturn
}

export const ActivityFeedHeader = ({ contact, contactNumber }: ActivityFeedHeaderProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { colorMode } = useColorMode()
    const dispatch = useDispatch()
    const addNumberToContactDrawer = useAddNumberToContactDrawer()
    const { formatToLocalNumber, formatToNumberE164 } = useFormatToNumberE164()

    const user = useSelector(selectAuthenticatedUser)
    const hasVideoPermission = useHasFeature(Features.meetings)

    const [findOrCreateChat] = useCreateChannelMutation()

    const { filterMenu, hasAppliedFilters } = useActivityFilters()

    const type: ContactType | 'unsaved' = useMemo(() => {
        if (!contact) return 'unsaved'
        return isInternalContact(contact) ? 'internal' : 'external'
    }, [contact])

    const { allNumbersBlocked } = useBlockContact(
        type === 'unsaved' ? formatToNumberE164(contactNumber) : contact
    )
    const flags = useFlags(['chat'])

    const numbers = contact?.phoneNumbers?.map(pn => pn.numberE164) ?? [contactNumber ?? '']
    const isSmsEnabled = useIsSMSEnabled(numbers)
    const isWhatsappEnabled = useCanSendWhatsApp()
    const isMessagingEnabled = isSmsEnabled || isWhatsappEnabled

    async function handleChat() {
        if (type !== 'internal' || !user || !contact?.externalID) return

        try {
            const body =
                contact.source === 'teamprofile'
                    ? { type: 'GROUP' as const, teamID: contact.externalID }
                    : { type: 'USER' as const, members: [contact.externalID, user.userID] }

            const channel = await findOrCreateChat(body).unwrap()
            navigate(`/chat/${channel.ID}`)
        } catch (e) {
            console.error(`Couldn't findOrCreateChat for ${user.userID}`, e)
        }
    }

    function handleVideoCall() {
        if (!user || !contact?.externalID) return

        const payload: OpenCreateMeetingWindow = {
            event: MeetingEventType.CREATE_VIDEO_CALL,
            otherParticipants: [{ userID: contact?.externalID }],
            // todo
            // sources,
            userID: user.userID,
            clientID: user.clientID,
            token: user.token,
            username: user.username
        }
        dispatch(openMeetingWindow(payload))
    }

    return (
        <HStack
            as="header"
            h="81px"
            w="full"
            p="16px 24px"
            spacing="10px"
            bg={`${colorMode}.tones.ghostWhite`}
            borderBottom="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
            align="flex-start"
            justify="space-between"
        >
            <HStack spacing="8px" align="stretch">
                <Avatar
                    name={
                        type === 'unsaved' ? formatToNumberE164(contactNumber) : contact?.fullName
                    }
                    src={contact?.photoURL}
                />
                <VStack spacing={0} align="start" justify="space-between">
                    <VStack spacing={0} align="inherit">
                        <Body>
                            {type === 'unsaved'
                                ? formatToLocalNumber(contactNumber)
                                : contact?.fullName}
                        </Body>
                        {type !== 'unsaved' && contact?.companyName && (
                            <Body size="sm" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                                {contact?.companyName}
                            </Body>
                        )}
                        {type === 'unsaved' && (
                            <Body size="sm" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                                {t('activities.unsavedContact')}
                            </Body>
                        )}
                    </VStack>
                </VStack>
            </HStack>
            <HStack spacing={0}>
                {/* <IconButton variant="ghost" aria-label="History">
                    <HistoryIconRegular />
                </IconButton>*/}
                <IconButton
                    variant={hasAppliedFilters && !filterMenu.isOpen ? 'secondary' : 'ghost'}
                    aria-label="Filter"
                    onClick={filterMenu.onToggle}
                >
                    <FilterIconRegular />
                </IconButton>
                {type === 'internal' && (
                    <>
                        {flags.chat.enabled && (
                            <IconButton variant="ghost" aria-label="Chat" onClick={handleChat}>
                                <ChatIconRegular />
                            </IconButton>
                        )}
                        {hasVideoPermission && (
                            <IconButton
                                variant="ghost"
                                aria-label="Video Call"
                                onClick={handleVideoCall}
                            >
                                <VideoIconSolid />
                            </IconButton>
                        )}
                    </>
                )}

                {!allNumbersBlocked && (
                    <ActivityFeedNumberMenu
                        contact={contact}
                        contactNumber={
                            contactNumber ? formatToNumberE164(contactNumber) : undefined
                        }
                    />
                )}
                {isMessagingEnabled && (
                    <ActivityFeedMessagingMenu
                        smsEnabled={isSmsEnabled}
                        whatsappEnabled={isWhatsappEnabled}
                    />
                )}
                <ActivityFeedHeaderMenu
                    contact={contact}
                    contactNumber={contactNumber ? formatToNumberE164(contactNumber) : undefined}
                    onAddToContact={addNumberToContactDrawer.onOpen}
                />
            </HStack>
        </HStack>
    )
}
