import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const CheckIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CheckIconLight',
    viewBox: '0 0 18 12',
    path: (
        <path
            d="M17.5742 0.238281C17.8086 0.472656 17.8086 0.902344 17.5742 1.13672L6.94922 11.7617C6.71484 11.9961 6.28516 11.9961 6.05078 11.7617L0.425781 6.13672C0.191406 5.90234 0.191406 5.47266 0.425781 5.23828C0.660156 5.00391 1.08984 5.00391 1.32422 5.23828L6.51953 10.4336L16.6758 0.238281C16.9102 0.00390625 17.3398 0.00390625 17.5742 0.238281Z"
            fill="currentColor"
        />
    )
})

export const CheckIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CheckIconRegular',
    viewBox: '0 0 18 14',
    path: (
        <path
            d="M17.457 1.16504C17.8477 1.55566 17.8477 2.1416 17.457 2.49316L7.14453 12.8057C6.79297 13.1963 6.20703 13.1963 5.85547 12.8057L0.542969 7.49316C0.152344 7.1416 0.152344 6.55566 0.542969 6.16504C0.894531 5.81348 1.48047 5.81348 1.83203 6.16504L6.51953 10.8525L16.168 1.16504C16.5195 0.813477 17.1055 0.813477 17.457 1.16504Z"
            fill="currentColor"
        />
    )
})

export const CheckIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CheckIconSolid',
    viewBox: '0 0 18 14',
    path: (
        <path
            d="M17.3789 1.12109C17.8867 1.58984 17.8867 2.41016 17.3789 2.87891L7.37891 12.8789C6.91016 13.3867 6.08984 13.3867 5.62109 12.8789L0.621094 7.87891C0.113281 7.41016 0.113281 6.58984 0.621094 6.12109C1.08984 5.61328 1.91016 5.61328 2.37891 6.12109L6.48047 10.2227L15.6211 1.12109C16.0898 0.613281 16.9102 0.613281 17.3789 1.12109Z"
            fill="currentColor"
        />
    )
})
