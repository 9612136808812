import { Box } from '@chakra-ui/react'
import { Dialog, DialogContent, DialogOverlay } from '@missionlabs/react'
import { useRef } from 'react'

import { ChatSidebar } from '../ChatSidebar'
import { useChatContext } from '../context/useChatContext'

interface Props {}

const ForwardMessage: React.FC<Props> = () => {
    const { messageForwarding } = useChatContext()
    const ref = useRef<HTMLButtonElement>(null)

    return (
        <Dialog
            leastDestructiveRef={ref}
            isOpen={!!messageForwarding?.forwardMessage}
            onClose={() => messageForwarding?.setForwardMessage(null)}
        >
            <DialogOverlay />
            <DialogContent>
                <Box>
                    <ChatSidebar alwaysSearch />
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ForwardMessage
