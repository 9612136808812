import { CSSProperties } from 'react'

import { TransferContact, VirtualTransferContact } from './TransferContact'

interface TransferListRendererProps {
    data: VirtualTransferContact[]
    index: number
    style: CSSProperties
}

export const TransferListRenderer = ({ data, index, style }: TransferListRendererProps) => {
    const { contact, onClick } = data[index]

    return (
        <div style={style}>
            <TransferContact contact={contact} onClick={onClick} />
        </div>
    )
}
