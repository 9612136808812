import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const AddCallIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddCallIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M18.9297 12.9267L14.9531 11.2242C14.2305 10.9245 13.3828 11.1316 12.8926 11.7343L11.598 13.3123C9.53906 12.1936 7.82422 10.4818 6.70313 8.42689L8.28516 7.09724C8.89727 6.59345 9.09883 5.76524 8.78672 5.03685L7.0832 1.06737C6.74262 0.284 5.8875 -0.145702 5.05938 0.044973L1.37227 0.899308C0.565234 1.11884 0 1.82539 0 2.65282C0 12.217 7.79687 20 17.3789 20C18.2086 20 18.9184 19.4369 19.1043 18.6302L19.9559 14.9465C20.1445 14.116 19.7148 13.2659 18.9297 12.9267ZM17.8867 18.3506C17.832 18.5846 17.6172 18.7522 17.3789 18.7522C8.48437 18.7522 1.25 11.5307 1.25 2.65282C1.25 2.41153 1.41539 2.20518 1.65391 2.15059L5.3418 1.29626C5.38086 1.28712 5.41992 1.28285 5.45836 1.28285C5.66281 1.28285 5.85328 1.40349 5.93687 1.596L7.63922 5.56158C7.73047 5.77537 7.67188 6.02103 7.49219 6.1692L5.51953 7.78273C5.29492 7.96674 5.22656 8.28067 5.35473 8.54075C6.65863 11.1849 8.83559 13.3564 11.4836 14.6587C11.743 14.7879 12.059 14.7184 12.243 14.4942L13.8598 12.5251C14.0045 12.3472 14.2567 12.2887 14.4653 12.3752L18.434 14.0737C18.6647 14.1736 18.7917 14.4235 18.7355 14.6672L17.8867 18.3506ZM13.125 4.40282H15.625V6.89837C15.625 7.24151 15.9062 7.52226 16.25 7.52226C16.5938 7.52226 16.875 7.24323 16.875 6.89837V4.40282H19.375C19.7188 4.40282 20 4.12207 20 3.77894C20 3.4358 19.7205 3.15505 19.375 3.15505H16.875V0.659502C16.875 0.314648 16.5938 0.0356147 16.25 0.0356147C15.9062 0.0356147 15.625 0.314648 15.625 0.659502V3.15505H13.125C12.7812 3.15505 12.5 3.43424 12.5 3.77894C12.5 4.12363 12.7812 4.40282 13.125 4.40282Z"
            fill="currentColor"
        />
    )
})

export const AddCallIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddCallIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M18.7612 12.5118L14.9253 10.868C14.0894 10.5055 13.1034 10.7466 12.5347 11.452L11.4628 12.7606C9.71436 11.7539 8.24951 10.2891 7.2417 8.5391L8.55225 7.46566C9.25342 6.89456 9.49561 5.9141 9.10498 5.08206L7.48779 1.23167C7.09326 0.325424 6.10889 -0.168951 5.15186 0.0526115L1.58584 0.875424C0.651855 1.08831 -0.000488281 1.90746 -0.000488281 2.86683C-0.000488281 12.3164 7.68311 20 17.1323 20C18.0917 20 18.9108 19.3481 19.1245 18.4168L19.9472 14.8489C20.1714 13.8868 19.6714 12.9024 18.7612 12.5118ZM18.1206 14.4258L17.2968 17.9965C17.2769 18.0743 17.2144 18.125 17.1323 18.125C8.71826 18.125 1.83936 11.2813 1.83936 2.86683C1.83936 2.78503 1.89002 2.72097 1.96998 2.70265L5.57373 1.87933C5.58594 1.87628 5.59875 1.87506 5.61096 1.87506C5.6787 1.87506 5.74217 1.91596 5.76905 1.97697L7.41514 5.81799C7.44482 5.88675 7.42529 5.96878 7.3667 6.01566L5.46123 7.57699C5.12451 7.8516 5.01904 8.32425 5.21045 8.71488C6.4999 11.341 8.65811 13.5 11.2847 14.7891C11.6752 14.9807 12.1476 14.8782 12.4233 14.5413L13.9866 12.6319C14.0299 12.577 14.1111 12.5562 14.1788 12.588L18.0171 14.2321C18.0933 14.2657 18.1362 14.3477 18.1206 14.4258ZM13.4019 4.65238H15.312V6.52738C15.312 7.08206 15.7339 7.50003 16.2495 7.50003C16.7651 7.50003 17.1519 7.08011 17.1519 6.59769V4.65238H19.0269C19.5815 4.65238 19.9995 4.26956 19.9995 3.75003C19.9995 3.2305 19.5796 2.84769 19.0972 2.84769H17.1519V0.902377C17.1519 0.419955 16.769 3.33841e-05 16.2495 3.33841e-05C15.73 3.33841e-05 15.3472 0.419955 15.3472 0.902377V2.77738H13.4019C12.9175 2.81253 12.4995 3.23246 12.4995 3.75003C12.4995 4.26761 12.9175 4.65238 13.4019 4.65238Z"
            fill="currentColor"
        />
    )
})

export const AddCallIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AddCallIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M14.68 7.81289V5.31289H12.18C11.6605 5.31289 11.25 4.89492 11.25 4.37539C11.25 3.85586 11.6605 3.43789 12.18 3.43789H14.68V0.937892C14.68 0.418361 15.1005 0.000392481 15.62 0.000392481C16.1395 0.000392481 16.56 0.418361 16.56 0.937892V3.43789H19.06C19.5795 3.43789 20 3.85586 20 4.37539C20 4.89492 19.5795 5.31289 19.06 5.31289H16.56V7.81289C16.56 8.33242 16.1395 8.75039 15.62 8.75039C15.1005 8.75039 14.68 8.33242 14.68 7.81289ZM4.58984 0.05508C5.34766 -0.151951 6.14062 0.234768 6.44141 0.96133L8.00391 4.71133C8.26953 5.34805 8.08594 6.08633 7.55078 6.51992L5.625 8.09805C6.92578 10.848 9.15234 13.0746 11.9023 14.3754L13.4766 12.4496C13.9141 11.9145 14.6484 11.7309 15.2852 11.9965L19.0352 13.559C19.7617 13.8598 20.1484 14.6527 19.9414 15.4105L19.0039 18.848C18.8203 19.5277 18.2031 20.0004 17.5 20.0004C7.83594 20.0004 0 12.1645 0 2.50039C0 1.79727 0.472656 1.18008 1.15234 0.99258L4.58984 0.05508Z"
            fill="currentColor"
        />
    )
})
