import { DirectoryEntry } from '@missionlabs/types'
import { createContext, useContext } from 'react'

interface IAddNumberToContactContext {
    selectedContact: DirectoryEntry | undefined
    setSelectedContact: (contact?: DirectoryEntry) => void
}

export const AddNumberToContactContext = createContext<IAddNumberToContactContext | null>(null)

export const useAddNumberToContactContext = () => {
    const context = useContext(AddNumberToContactContext)
    if (!context) {
        throw new Error(
            'useAddNumberToContactContext must be used within a AddNumberToContactContext.Provider'
        )
    }
    return context
}
