import { CheckboxProps, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Checkbox } from '@missionlabs/react'
import { FC } from 'react'

type SubscriptionOptionProps = CheckboxProps & {
    type: string
    description: string
}

export const SubscriptionOption: FC<SubscriptionOptionProps> = ({
    type,
    description,
    ...props
}) => {
    const { colorMode } = useColorMode()

    return (
        <HStack p="16px" spacing="10px" w="full" alignItems="start">
            <Checkbox h="10px" {...props} alignSelf="center" />
            <VStack spacing={0} w="full" align="start">
                <Body size="md" sx={{ color: `${colorMode}.coolGrey.80` }}>
                    {type}
                </Body>
                <Body size="sm" sx={{ color: `${colorMode}.coolGrey.80` }}>
                    {description}
                </Body>
            </VStack>
        </HStack>
    )
}
