import { ComponentStyleConfig } from '@chakra-ui/react'

export const PlayerStyles: ComponentStyleConfig = {
    baseStyle: ({ colorMode }) => ({
        iconButton: {
            h: '40px',
            w: '40px',
            bg: 'none',
            border: 'none',
            borderLeftRadius: '8px',
            borderRightRadius: 0,
            color: `${colorMode}.tones.stormGrey`,
            transition: 'background-color,color 200ms ease',
            mr: '-1px',
            borderLeft: '1px solid',
            borderLeftColor: `${colorMode}.tones.periwinkle`,

            _hover: {
                bg: `${colorMode}.alerts.red`,
                color: 'white'
            }
        },
        container: {
            h: '40px',
            w: 'full',
            border: '1px solid',
            borderColor: `${colorMode}.tones.periwinkle`,
            borderRadius: '8px',
            bg: `${colorMode}.tones.white`
        },
        playerContainer: {
            h: 'full',
            flex: 1,
            align: 'center',
            padding: '0 16px',
            borderLeft: '1px solid',
            borderColor: `${colorMode}.tones.periwinkle`
        },
        sliderTrack: {
            bg: `${colorMode}.tones.periwinkle`,
            borderRadius: '4px'
        },
        filledTrack: {
            bg: `${colorMode}.avatar.red`
        },
        sliderThumb: {
            h: '24px',
            w: '24px',
            boxShadow: 'sm',
            border: '1px solid',
            borderColor: `${colorMode}.tones.periwinkle`,
            borderRadius: '4px'
        },
        icon: {
            _hover: {
                color: '#fff'
            }
        },
        label: {
            color: `${colorMode}.tones.stormGrey`,
            fontFamily: 'body',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '21px'
        }
    }),
    variants: {
        table: ({ colorMode }) => ({
            iconButton: {
                borderLeft: 'none',
                background: 'unset',
                _hover: {
                    bg: 'inherit'
                }
            },
            sliderTrack: {
                bg: `${colorMode}.tones.periwinkle`,
                borderRadius: '0px',
                height: '8px'
            },
            filledTrack: {
                bg: `#fff`,
                border: 'solid 1px #CACEE9'
            },
            sliderThumb: {
                display: 'none'
            },
            playerContainer: {
                padding: '0 12px',
                border: 'none'
            },
            icon: {
                color: `#939fbc`,
                _hover: {
                    color: '#F9644F'
                }
            },

            container: {
                border: 'none',
                bg: 'inherit'
            }
        }),
        mini: {
            iconButton: {
                h: '1px',
                w: '40px'
            },
            container: {
                h: '40px',
                w: '102px'
            },
            playerContainer: {
                padding: '0 12px',
                borderRight: 'none'
            }
        }
    }
}
