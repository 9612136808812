import { useColorMode } from '@chakra-ui/react'
import { IconButton, Tooltip } from '@missionlabs/react'
import { MicOffOutlineIcon, MicOutlineIcon } from '@missionlabs/react/circleloop'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface MuteButtonProps {
    muted?: boolean
    onToggle: () => void
}

export const MuteButton: FC<MuteButtonProps> = ({ muted, onToggle }) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    return (
        <Tooltip label={muted ? t('Unmute') : t('Mute')}>
            <IconButton
                icon={
                    muted ? (
                        <MicOffOutlineIcon boxSize="16px" color={`${colorMode}.tones.periwinkle`} />
                    ) : (
                        <MicOutlineIcon boxSize="16px" color={`${colorMode}.tones.periwinkle`} />
                    )
                }
                variant="dialler"
                isActive={muted}
                boxSize="48px"
                flex={1}
                aria-label="mute"
                onClick={onToggle}
            />
        </Tooltip>
    )
}
