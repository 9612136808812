import { Center, Collapse, Grid, HStack, useColorMode } from '@chakra-ui/react'
import { selectPreferences, updatePreferences, useDispatch, useSelector } from '@missionlabs/api'
import {
    AudioInputControl,
    ChatControl,
    ReactionAnimations,
    ReactionControl,
    RecordingControl,
    ScreenShareControl,
    VideoInputControl,
    VideoTiles
} from '@missionlabs/meetings'
import { useMeasure } from '@missionlabs/react'
import { IDLE_TIMEOUT, useVideoCall } from 'features/meetings/hooks/useVideoCall'
import { MeetingsCallRootProps } from 'features/meetings/MeetingsCallRoot'
import { FC, useRef } from 'react'
import { useIdle } from 'react-use'

import { ElectronScreenShareDialog } from '../ElectronScreenShareDialog'
import { InVideoCallHeader } from './InVideoCallHeader'

export interface InVideoCallProps extends MeetingsCallRootProps {}

export const InVideoCallRoot: FC<InVideoCallProps> = ({ isGuest }) => {
    const dispatch = useDispatch()
    const { colorMode } = useColorMode()

    const preferences = useSelector(selectPreferences)

    const [headerRef, { blockSize: headerHeight }] = useMeasure<HTMLDivElement>()
    const mainRef = useRef<HTMLDivElement>(null)

    const idle = useIdle(IDLE_TIMEOUT)
    useVideoCall(isGuest)

    function handleVideoControlClick(isVideoEnabled, defaultDevice) {
        if (!isVideoEnabled || !preferences?.cameraDeviceID) return
        dispatch(updatePreferences({ cameraDeviceID: defaultDevice }))
    }

    return (
        <Grid
            h="100dvh"
            w="100dvw"
            bg={`${colorMode}.tones.midnightExpress`}
            templateRows={`${!idle ? '64px' : ''} 1fr`}
        >
            <Collapse ref={headerRef} in={idle} startingHeight={64} endingHeight={0}>
                <InVideoCallHeader />
            </Collapse>
            <Center ref={mainRef} as="main" h={`calc(100dvh - ${headerHeight}px)`} w="full">
                <VideoTiles constraintsRef={mainRef} />
                <HStack
                    pos="absolute"
                    bottom="24px"
                    spacing="24px"
                    visibility={idle ? 'hidden' : 'visible'}
                    zIndex={5}
                >
                    <HStack spacing="8px">
                        <RecordingControl isDisabled={isGuest} onClick={() => {}} />
                        <VideoInputControl
                            cameraDeviceID={preferences?.cameraDeviceID ?? ''}
                            onClick={handleVideoControlClick}
                        />
                        <AudioInputControl />
                    </HStack>
                    <HStack spacing="8px">
                        <ReactionControl />
                        <ChatControl onClick={() => {}} />
                        <ScreenShareControl electronWindowPicker={ElectronScreenShareDialog} />
                    </HStack>
                    <div style={{ visibility: 'visible' }}>
                        {/* note: this isn't rendered as you'd expect and acts more like a container */}
                        <ReactionAnimations />
                    </div>
                </HStack>
            </Center>
        </Grid>
    )
}
