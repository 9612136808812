import { Box, HStack, useColorMode, useDisclosure } from '@chakra-ui/react'
import {
    Body,
    Button,
    ContactDetail,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerTitle,
    ListItem
} from '@missionlabs/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetChatGroupQuery } from 'shared/store'

import { LeaveChatGroupDialog } from './LeaveChatGroupDialog'

interface ViewChatGroupDrawerProps {}

export const ViewChatGroupDrawer = (_props: ViewChatGroupDrawerProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { colorMode } = useColorMode()
    const deleteModal = useDisclosure()

    const { id: groupID = '' } = useParams()
    const { data: group, isLoading } = useGetChatGroupQuery(groupID, { skip: !groupID })
    const users = useMemo(() => group?.metadata?.users ?? [], [group])

    return (
        <>
            <Drawer isOpen={true} onClose={() => navigate(-1)}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader>
                        <DrawerTitle>{t('chatGroups.view')}</DrawerTitle>
                    </DrawerHeader>
                    <DrawerBody>
                        {isLoading || !group ? (
                            <div>Loading...</div>
                        ) : (
                            <div>
                                <Box p={6}>
                                    <Body variant="extra-bold">{group.name}</Body>
                                </Box>
                                <Box
                                    mx={6}
                                    borderTop="1px solid"
                                    borderColor={`${colorMode}.tones.periwinkle`}
                                >
                                    {users.map(user => (
                                        <ListItem
                                            sx={{ minHeight: '79px', borderTop: 'none' }}
                                            key={user.id}
                                        >
                                            <ContactDetail
                                                key={user.id}
                                                name={user.name}
                                                subText={
                                                    group.ownerID === user?.id
                                                        ? t('chatGroups.groupOwner')
                                                        : ''
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </Box>
                            </div>
                        )}
                    </DrawerBody>
                    <DrawerFooter>
                        <HStack w="full" spacing={4}>
                            <Button
                                size="lg"
                                variant="negatory"
                                w="full"
                                onClick={deleteModal.onOpen}
                            >
                                {t('chatGroups.leave.cta')}
                            </Button>
                        </HStack>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            <LeaveChatGroupDialog
                groupID={groupID}
                isOpen={deleteModal.isOpen}
                onClose={deleteModal.onClose}
            />
        </>
    )
}
