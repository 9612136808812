const useImageUploader = (localStorageKey: string, cb: (dataURL: string) => void) => {
    const fileInput = document.createElement('input')
    fileInput.setAttribute('type', 'file')
    fileInput.setAttribute('accept', '.png,.jpg,.bmp,.jpeg')
    fileInput.setAttribute('hidden', 'true')
    fileInput.addEventListener('change', function () {
        try {
            const file = this.files?.[0]
            if (!file) {
                throw new Error('No file selected')
            }

            const reader = new FileReader()
            reader.addEventListener('load', () => {
                const bgImages = JSON.parse(localStorage.getItem(localStorageKey) || '[]')
                if (reader.result) {
                    localStorage.setItem(
                        localStorageKey,
                        JSON.stringify([...bgImages, reader.result])
                    )
                    cb((reader.result as string) || '')
                }
                this.remove()
            })
            reader.readAsDataURL(file)
        } catch (e) {
            console.log(e)
            this.remove()
        }
    })

    const uploadFile = () => {
        document.body.appendChild(fileInput)
        fileInput.click()
    }

    return uploadFile
}

export default useImageUploader
