import { Box, useColorMode, VStack } from '@chakra-ui/react'
import { IconButton } from '@missionlabs/react'
import { AddIcon } from '@missionlabs/react/circleloop'
import { DirectoryEntry } from '@missionlabs/types'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList } from 'react-window'

import { useContactListPresence } from '../hooks/useContactListPresence'
import { useContactsSidebarData } from '../hooks/useContactsSidebarData'
import { ContactsRowRenderer } from './ContactsRowRenderer'

type ContactsListProps = {
    searchedContacts: DirectoryEntry[]
}

export const ContactsList: FC<ContactsListProps> = ({ searchedContacts }) => {
    const { colorMode } = useColorMode()
    const navigate = useNavigate()
    const { listRef, grouped, getRowSize, onItemsRendered } =
        useContactsSidebarData(searchedContacts)

    const { updatePresenceSubscriptions } = useContactListPresence({ groupedContacts: grouped })

    return (
        <VStack spacing={0} w="full" h={`100%`} flex={1} bg={`${colorMode}.tones.ghostWhite`}>
            <Box w="full" flexGrow="1" role="list">
                <AutoSizer>
                    {({ height, width }) => (
                        <VariableSizeList
                            ref={listRef}
                            height={height}
                            width={width}
                            itemSize={getRowSize}
                            itemCount={grouped.length}
                            itemData={grouped}
                            onItemsRendered={renderedData => {
                                onItemsRendered(renderedData)
                                updatePresenceSubscriptions(renderedData)
                            }}
                        >
                            {ContactsRowRenderer}
                        </VariableSizeList>
                    )}
                </AutoSizer>
            </Box>
            <IconButton
                onClick={() => navigate('/contacts/add')}
                icon={<AddIcon rounded="full" boxSize="28px" />}
                aria-label="Create contact"
                sx={{
                    width: '48px',
                    height: '48px',
                    borderRadius: '100%',
                    position: 'absolute',
                    bottom: '16px',
                    right: '32px',
                    zIndex: 1
                }}
            />
        </VStack>
    )
}
