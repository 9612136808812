import { useDisclosure } from '@chakra-ui/react'
import { useFormContext } from '@missionlabs/react'
import { RecordingDrawer, RecordingDrawerProps } from 'features/settings/components/RecordingDrawer'
import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCreateAudioClipMutation } from 'shared/store'

import { AddEditTeamFields } from './AddEditTeam/AddEditTeamForm'

export const UploadClipTeamDrawer: FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const state = location.state as string | null

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const { setValue } = useFormContext<AddEditTeamFields>()

    const [createClip, { isLoading }] = useCreateAudioClipMutation()

    const handleSubmit: RecordingDrawerProps['onSubmit'] = async data => {
        if (!data || !state) return

        const fd = new FormData()
        fd.append('importMP3', data)
        fd.append('label', data.name)

        const { URL } = await createClip(fd).unwrap()
        setValue(state as any, URL)
        return onClose()
    }

    function handleTransition() {
        navigate(-1)
    }

    useEffect(() => {
        if (!state) navigate('/admin/teams')
    }, [navigate, onClose, state])

    return (
        <RecordingDrawer
            isOpen={isOpen}
            onClose={onClose}
            isLoading={isLoading}
            onCloseComplete={handleTransition}
            onSubmit={handleSubmit}
        />
    )
}
