import { HStack, VStack } from '@chakra-ui/react'
import { Input, ValidatedInput } from '@missionlabs/react'
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { StripeValidatedInput } from './StripeValidatedInput'

export interface AddPaymentMethodCardProps {}

export const AddPaymentMethodCard: FC<AddPaymentMethodCardProps> = () => {
    const { t } = useTranslation()
    return (
        <VStack h="full" spacing="16px" align="inherit" data-testid="add-payment-method-card">
            <StripeValidatedInput
                as={CardNumberElement}
                name="_cardNumber"
                label={t('admin.subscription.paymentMethod.form.cardNumber')}
                options={{ placeholder: '' }}
            />
            <HStack spacing="16px" align="inherit">
                <StripeValidatedInput
                    as={CardExpiryElement}
                    name="_cardExpiry"
                    label={t('admin.subscription.paymentMethod.form.cardExpiry')}
                />
                <StripeValidatedInput
                    as={CardCvcElement}
                    name="_cardCvc"
                    label={t('admin.subscription.paymentMethod.form.cardCVC')}
                />
            </HStack>
            <ValidatedInput name="name" label={t('admin.subscription.paymentMethod.form.name')}>
                <Input autoComplete="billing name" />
            </ValidatedInput>
        </VStack>
    )
}
