import { AnalyticsGroup } from '@missionlabs/types'
import React from 'react'

import { useDateScroll } from '../hooks/useDateScroll'

export type DatePeriod = 'days' | 'weeks' | 'months' | 'custom'

type AnalyticsGraph = 'bar' | 'line'

interface ReportState {
    create: boolean
    setCreate(create: boolean): void
}

interface GraphState {
    graphType: AnalyticsGraph
    setGraphType(type: AnalyticsGraph): void
}

export interface AnalyticsContextType {
    period: DatePeriod
    group: AnalyticsGroup
    setPeriod(period: DatePeriod): void
    setGroup(group: AnalyticsGroup): void
    dateScroller: ReturnType<typeof useDateScroll>
    report: ReportState
    graph: GraphState
    colours: {
        inbound: string
        outbound: string
        missed: string
        remainder: string
    }
}

export const AnalyticsContext = React.createContext<AnalyticsContextType>({} as never)
