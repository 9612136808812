import { ALL_CLIENT_ACTIVITIES, AudioClip, Client, UserActivity } from '@missionlabs/types'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { customCreateApi } from './customCreateAPI'

const pageCache = new Map<number, UserActivity[]>()
let pageTotal = 0

export const buildClientAPI = (baseQuery: BaseQueryFn) => {
    const api = customCreateApi({
        reducerPath: 'clientAPI',
        tagTypes: ['Client', 'ClientActivity'],
        baseQuery: baseQuery,
        endpoints: builder => ({
            getClient: builder.query<Client, void>({
                query: () => `/`,
                providesTags: data => ['Client', { type: 'Client', id: data?.ID }]
            }),
            updateClient: builder.mutation<Client, Partial<Client>>({
                query: body => ({
                    url: '/',
                    method: 'PUT',
                    body
                }),
                invalidatesTags: (_data, _err, arg) => [{ type: 'Client', id: arg.ID }, 'Client']
            }),
            getClientActivities: builder.query<
                { data: UserActivity[]; total: number },
                { fromTime?: number; max?: number; page?: number } | void
            >({
                // @ts-ignore
                queryFn: async (params, _a, _e, baseQuery) => {
                    const searchParams = new URLSearchParams({
                        activityClass: 'account',
                        activityType: ALL_CLIENT_ACTIVITIES.join(','),
                        max: '10'
                    })
                    if (params?.fromTime) searchParams.set('fromTime', String(params.fromTime))
                    if (params?.max) searchParams.set('max', String(params.max))

                    // return cached page if available
                    if (typeof params?.page === 'number' && pageCache.has(params.page)) {
                        return { data: { data: pageCache.get(params.page), total: pageTotal } }
                    }

                    // otherwise fetch new data and cache
                    try {
                        const { data } = (await baseQuery({
                            url: `/activities?${searchParams.toString()}`,
                            headers: { 'Cache-Control': 'no-cache' }
                        })) as { data: { data: UserActivity[]; total: number } }

                        if (typeof params?.page === 'number') pageCache.set(params.page, data.data)
                        if (!pageTotal) pageTotal = data.total

                        return { data: { ...data, total: pageTotal } }
                    } catch (error) {
                        return { error }
                    }
                }
            }),
            cancelClientSubscription: builder.mutation<
                Client,
                Pick<Client, 'ID' | 'cancelCategory' | 'cancelReason'>
            >({
                query: args => {
                    const { cancelReason, cancelCategory } = args
                    return {
                        url: '/cancel',
                        method: 'DELETE',
                        body: { cancelReason, cancelCategory }
                    }
                },
                invalidatesTags: (_data, _err, arg) => [{ type: 'Client', id: arg.ID }, 'Client']
            }),
            getClientAudioClips: builder.query<AudioClip[], string>({
                query: () => `/audioclips`,
                transformResponse: (response: { data: AudioClip[] }) =>
                    response.data.toSorted((a, b) => a.label.localeCompare(b.label))
            })
        })
    })

    return { api, ...api }
}
