import { useColorMode } from '@chakra-ui/react'
import {
    Body,
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay
} from '@missionlabs/react'
import { AttendeeStatus, ScheduledMeeting } from '@missionlabs/types'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthenticatedUser } from 'shared/hooks/useAuthenticatedUser'
import { useUpdateScheduledMeetingAttendanceMutation } from 'shared/store'

type RemoveMeetingDialogProps = {
    meeting: ScheduledMeeting
    isOpen: boolean
    onClose: () => void
}

export const RemoveMeetingDialog: FC<RemoveMeetingDialogProps> = ({ meeting, isOpen, onClose }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { data: user } = useAuthenticatedUser()
    const [updateMeetingStatus, { isLoading }] = useUpdateScheduledMeetingAttendanceMutation()

    const ref = useRef<HTMLButtonElement>(null)

    const handleRemove = () => {
        updateMeetingStatus({
            attendanceStatus: AttendeeStatus.DECLINED,
            meetingID: meeting.ID,
            userID: user?.ID ?? ''
        })
    }

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>{t('meetings.removeFromCalendar')}</DialogHeader>
                <DialogBody gap="12px">
                    <Body size="sm" sx={{ color: `${colorMode}.tones.navy` }}>
                        {t('meetings.removeMeetingDescription')}
                    </Body>
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('meetings.removeMeeting'),
                        isDestructive: true,
                        onClick: () => {
                            handleRemove()
                        }
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
