import { HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Call } from '@missionlabs/api'
import { Avatar, Body, IconButton } from '@missionlabs/react'
import { PhoneHangupIcon, PhoneUpIcon } from '@missionlabs/react/circleloop'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useCallDetails } from 'shared/hooks/useCallDetails'

interface IncomingCallProps {
    call: Call
    onHangup: () => void
    onAnswer: () => void
}

export const IncomingCall: FC<IncomingCallProps> = ({ call, onHangup, onAnswer }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const periwinkle = `${colorMode}.tones.periwinkle`

    const { contactName, number, isWithheld, isUnknown, company, team, localNumber } =
        useCallDetails(call)

    return (
        <VStack h="full" w="full" spacing="8px">
            <Body
                data-testid="incoming-call-heading"
                size="sm"
                variant="bold"
                color={periwinkle}
                as="h2"
            >
                {call.isTransfer ? t('Incoming Transfer') : t('Incoming Call')}
            </Body>

            {call.alphaTag && (
                <Body size="sm" color={periwinkle} as="h4">
                    {call.alphaTag}
                </Body>
            )}

            {contactName && !isWithheld && !isUnknown && <Avatar name={contactName} size="lg" />}

            <VStack w="full" spacing={0}>
                {contactName && (
                    <Body size="lg" color="white" as="h1" lineHeight="40px">
                        {contactName}
                    </Body>
                )}

                {company && (
                    <Body size="sm" color={periwinkle} as="h4">
                        {company}
                    </Body>
                )}

                {number && contactName !== number && (
                    <Body size="sm" color={periwinkle} as="h4">
                        {number}
                    </Body>
                )}

                <Body size="sm" color={periwinkle} as="h4">
                    {team
                        ? t('notifications.via', { value: team })
                        : t('notifications.directCall', { value: localNumber })}
                </Body>
            </VStack>

            {call.isTransfer && (
                <VStack mt="24px" spacing={0}>
                    <Body size="md" color="white">
                        <span>{t('Transferring from')} </span>
                        <span>{call.transfererContactName}</span>
                    </Body>
                    {call.transfererCompanyName && (
                        <Body size="sm" color={periwinkle}>
                            {call.transfererCompanyName}
                        </Body>
                    )}
                    <Body size="sm" color={periwinkle}>
                        {call.transfereeNumber}
                    </Body>
                </VStack>
            )}

            <HStack spacing="16px" pt="16px">
                <IconButton
                    data-testid="incoming-call-hangup-button"
                    icon={<PhoneHangupIcon color="white" boxSize="32px" opacity="0.75" />}
                    isRounded
                    boxSize="64px"
                    variant="negatory"
                    borderRadius="100%"
                    aria-label="Hangup"
                    onClick={onHangup}
                />
                <IconButton
                    icon={<PhoneUpIcon boxSize="32px" opacity="0.75" />}
                    isRounded
                    boxSize="64px"
                    variant="creationary"
                    borderRadius="100%"
                    aria-label="Answer"
                    onClick={onAnswer}
                />
            </HStack>
        </VStack>
    )
}
