export enum Levels {
    error = 0,
    warn = 1,
    info = 2,
    debug = 3,
    trace = 4,
    verbose = 999
}

export type Level = keyof typeof Levels

const isNil = (val: any) => typeof val === 'undefined' || val == null

export const getLevelNumber = (lvl: Level): Levels => {
    const result = Levels[lvl]
    return isNil(result) ? Levels.verbose : result
}

export const isAllowed = (expectedLevel: Level, level: Level): boolean => {
    return getLevelNumber(level) <= getLevelNumber(expectedLevel)
}
