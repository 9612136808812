import { Center, useColorMode, VStack } from '@chakra-ui/react'
import { Heading } from '@missionlabs/react'
import { MessageIconLight } from '@missionlabs/react/zeta'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface ChatHistoryEmptyProps {
    siblingHeight: number
}

export const ChatHistoryEmpty: FC<ChatHistoryEmptyProps> = ({ siblingHeight }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    return (
        <Center w="full" h={`calc(100dvh - ${siblingHeight}px)`} mb="8px">
            <VStack spacing="4px">
                <MessageIconLight boxSize="48px" p="7px" color={`${colorMode}.tones.stormGrey`} />
                <Heading
                    size="h3"
                    sx={{
                        fontSize: '16px',
                        color: `${colorMode}.tones.stormGrey`
                    }}
                >
                    {t('chat.noMessagesYet')}
                </Heading>
            </VStack>
        </Center>
    )
}
