import { usePromisedDebouncedFunction, useVirtualPagination } from '@missionlabs/react'
import { SearchActivitiesRequest, UserActivity } from '@missionlabs/types'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useLazySearchUserActivitiesQuery } from '../store'
import { usePaginationListeners } from './usePaginationListeners'

export function usePaginatedActivitySearch(
    filters: Partial<SearchActivitiesRequest> = {},
    options?: { skip?: boolean }
) {
    const {
        page,
        pageSize,
        reset: resetPages,
        setItemCount,
        setIsLoading,
        setIsEndOfList,
        onItemsRendered
    } = useVirtualPagination()

    //As its debounced we need to keep track of when the initial search completes
    const [initialSearch, setInitialSearch] = useState(false)
    const [activities, setActivities] = useState<UserActivity[]>([])
    const [searchActivities, { isLoading, isFetching, isError }] =
        useLazySearchUserActivitiesQuery()

    const searchActivitiesDebounced = usePromisedDebouncedFunction(searchActivities, 300)

    // Notify the pagination that we're loading when a fetch is initiated
    useEffect(() => {
        setIsLoading(isFetching)
    }, [setIsLoading, isFetching])

    /** Fetch the first page of activities, from 0 to `pageSize` */
    const getInitialActivities = useCallback(async () => {
        try {
            const response = await searchActivities({ ...filters, from: '0', max: pageSize })
            setActivities(response.data ?? [])
            setInitialSearch(true)
        } catch (e) {
            console.error('Failed to get initial activities, resetting pagination.')
        } finally {
            // Since we've just started from 0 again, we should reset the page back to 0 too
            resetPages()
        }
    }, [filters, pageSize, searchActivities, resetPages])

    /** Check for new activities since the latest loaded activity. */
    const getNewActivities = useCallback(async () => {
        try {
            const params: SearchActivitiesRequest = { ...filters, max: pageSize }

            if (activities.length) {
                /*
                 * If we have activities already, we want to find new activities since the latest activity
                 * We need to +1 ms so that the most recent activity itself is not returned
                 * this seems to be a quirk of the searchActivities endpoint
                 * as the normal activities endpoint excludes the activity if `created === sinceTime`
                 */
                const sinceTime = new Date(activities[0].created)
                params.sinceTime = new Date(sinceTime.getTime() + 1).toISOString()
            } else {
                // otherwise, we just want to find any activities, so start "from 0".
                params.from = '0'
            }

            const response = await searchActivities(params)
            const update = response.data ?? []
            setActivities(activities => [...update, ...activities])
        } catch (e) {
            console.error('Failed to poll activities, resetting pagination.')
            resetPages()
        }
    }, [filters, pageSize, searchActivities, resetPages, activities])

    /**
     * Refetch all of the currently loaded activities.
     * i.e. when an activity is deleted or updated, refetch all.
     */
    const refetchActivities = useCallback(async () => {
        try {
            const response = await searchActivities({
                ...filters,
                from: '0',
                max: activities.length
            })
            setActivities(response.data ?? [])
        } catch (e) {
            console.error('Failed to refetch activities, resetting pagination.')
            resetPages()
        }
    }, [searchActivities, resetPages, activities.length, filters])

    // Memoize the listeners since we're passing in an object
    // otherwise, it'll get recreated on each render
    const paginationListeners = useMemo(() => {
        return {
            paginationKey: 'searchActivities',
            onPoll: getNewActivities,
            onRefetch: refetchActivities,
            onReset: getInitialActivities
        }
    }, [getInitialActivities, getNewActivities, refetchActivities])

    /**
     * We listen for "refetch", "poll" and "reset" actions from pagination slice
     * and perform the relevant searches when these actions happen.
     *
     * Using the listeners allows us to:
     * - Use `dispatch(refetch('searchActivities'))` from any part of the app
     * - Invalidate the local cache of activities from within other RTK Query functions
     * 		for example, batchDeleteUserActivities - when activities are deleted, we should re-fetch
     */
    usePaginationListeners(paginationListeners)

    useEffect(
        // Whenever the "page" changes, fetch the activities for that page
        function fetchNextPage() {
            if (options?.skip) return
            searchActivitiesDebounced({
                ...filters,
                from: `${activities.length}`,
                max: pageSize
            }).then(response => {
                if (!response.data) return
                setActivities(activities => [...activities, ...response.data!])
                setInitialSearch(true)
                if (response.data.length === 0) setIsEndOfList(true)
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [page]
    )

    return {
        activities,
        isLoading: isLoading || !initialSearch,
        isFetching,
        isError,
        onItemsRendered,
        setItemCount
    }
}
