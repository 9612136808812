import { useGetClientQuery } from 'shared/store'

import { Features, useHasClientFeature } from './useHasClientFeature'

export const useCanMakeCalls = () => {
    const { data: client } = useGetClientQuery()

    const hasCalling = useHasClientFeature([Features.outbound_calling, Features.inbound_calling])
    // assume true until the client object has come from the API
    if (!client) return true

    return hasCalling
}
