import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import isElectron from 'is-electron'

export type ChatInfo = {
    url: string
    channelID: string
}

export type JoinInfo = {
    Attendee: {
        AttendeeId: string
        ExternalUserId: string
        JoinToken: string
    }
    Errors: Array<string>
    Meeting: {
        ExternalMeetingId: string
        MeetingFeatures?: {
            Audio?: {
                EchoReduction?: 'AVAILABLE'
            }
        }
        MediaPlacement: {
            AudioFallbackUrl: string
            AudioHostUrl: string
            EventIngestionUrl: string
            ScreenDataUrl: string
            ScreenSharingUrl: string
            ScreenViewingUrl: string
            SignalingUrl: string
            TurnControlUrl: string
        }
        MediaRegion: string
        MeetingId: string
    }
}
export enum ParticipantType {
    INTERNAL = 'INTERNAL',
    GUEST = 'GUEST'
}
export enum ParticipantVideoStatus {
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    ACCEPTED = 'ACCEPTED',
    GONE = 'GONE',
    WAITING = 'WAITING'
}
export type MeetingParticipant = {
    userID: string
    fullName?: string
    displayName?: string
    company?: string
    contactID?: string
    type: ParticipantType
    status: ParticipantVideoStatus
}

export type MeetingCaller = MeetingParticipant & {
    sources?: string[]
}

export type MeetingRequest = {
    meetingID: string
    caller: MeetingCaller
    otherParticipants: any[]
}

export type GuestMeetingRequest = {
    meetingID: string
    name: string
    email: string
    company?: string
}

export enum MeetingEventType {
    CREATE_VIDEO_CALL = 'create_video_call',
    ACCEPT_VIDEO_CALL = 'accept_video_call',
    JOIN_VIDEO_CALL = 'join_scheduled_video_call',
    REQUEST_TO_PULL_VIDEO_CALL = 'request_to_pull_video_call'
}

export enum MeetingTypes {
    ONE_TO_ONE = 'ONE_TO_ONE',
    ONE_TO_MANY = 'ONE_TO_MANY'
}

type OpenWindowUserAttributes = {
    userID: string
    clientID: string
    token: string
    username: string | undefined
}

export type OpenCreateMeetingWindow = OpenWindowUserAttributes &
    Pick<MeetingCaller, 'sources'> & {
        event: MeetingEventType.CREATE_VIDEO_CALL
        otherParticipants: Array<Pick<MeetingParticipant, 'userID'>>
    }

export type OpenAcceptMeetingWindow = OpenWindowUserAttributes &
    MeetingRequest & {
        event: MeetingEventType.ACCEPT_VIDEO_CALL
    }

export type OpenJoinMeetingWindow = OpenWindowUserAttributes & {
    meetingID: string
    meetingTitle: string
    event: MeetingEventType.JOIN_VIDEO_CALL
}

export type OpenRequestToPullMeetingWindow = OpenWindowUserAttributes & {
    meetingID: string
    event: MeetingEventType.REQUEST_TO_PULL_VIDEO_CALL
}

export type OpenMeetingWindow =
    | OpenCreateMeetingWindow
    | OpenAcceptMeetingWindow
    | OpenJoinMeetingWindow
    | OpenRequestToPullMeetingWindow

export enum MeetingRecordingStatus {
    STARTED = 'STARTED',
    RECORDING = 'RECORDING',
    PAUSED = 'PAUSED',
    ENDED = 'ENDED'
}

export type MeetingSettings = {
    microphoneInitialState: 'UNMUTED' | 'MUTED'
}

export type MeetingRecording = {
    recordingID: string
    status: MeetingRecordingStatus
    owner: boolean
}

export type MeetingDeviceState = {
    isMicrophoneEnabled?: boolean
    isVideoEnabled?: boolean
    videoBackgroundFilter?: string
    isNoiseSuppressionEnabled?: boolean
}

export type Meeting = {
    otherParticipants: Array<MeetingParticipant & { accepted: boolean }>
    joinInfo?: JoinInfo
    chatInfo?: ChatInfo
    meetingSettings?: MeetingSettings
    initialDeviceState?: MeetingDeviceState // Initial device state provided by video call pull (transfer)
    transferDeviceState?: MeetingDeviceState // Device state to be transferred if a video call pull is requested
    recordings: MeetingRecording[]
    startTime?: number
    toWebsocketID?: string
    numberOfTiles?: number
    answered?: boolean
} & Pick<MeetingRequest, 'caller' | 'meetingID'>

export type MeetingState = {
    requests: Array<MeetingRequest>
    active?: Partial<Meeting>
    error?: string
}

type Identity<T> = { [P in keyof T]: T[P] }
type Replace<T, K extends keyof T, A> = Identity<Pick<T, Exclude<keyof T, K>> & { [P in K]: A }>

type ReplaceOtherParticpants<T> = Replace<
    T & { otherParticipants: any },
    'otherParticipants',
    Array<MeetingParticipant>
>

export type CreateMeeting = ReplaceOtherParticpants<Meeting>

export type AddMeetingParticipants = ReplaceOtherParticpants<
    Pick<Meeting, 'meetingID' | 'otherParticipants'>
>

const reducers = {
    /* Store any incoming meeting requests. We don't yet set the `.active`
     * meeting because the user may choose to decline the request. We also
     * de-dupe the `.requests[]` array by making the assumption that no more
     * than one meeting request can come from a single user at a time...
     */
    addMeetingRequest: (state: MeetingState, action: PayloadAction<MeetingRequest>) => {
        state.requests = [
            ...state.requests.filter(req => req.caller.userID !== action.payload.caller.userID),
            action.payload
        ]
    },

    /* If user declines a meeting request then remove it
     * from the current list of requests...
     */
    declineMeetingRequest: (state: MeetingState, action: PayloadAction<MeetingRequest>) => {
        state.requests = [
            ...state.requests.filter(req => req.caller.userID !== action.payload.caller.userID)
        ]
    },

    leaveActiveMeeting: (
        state: MeetingState,
        action: PayloadAction<{ meetingID?: string; endMeeting?: boolean }>
    ) => {
        state.active =
            state.active && isActionForActiveMeeting(state, action) ? undefined : state.active
    },

    /* If user accepts a meeting request then "promote" it
     * to the `.active` meeting property, transforming as
     * necessary...
     */
    acceptMeetingRequest: (state: MeetingState, action: PayloadAction<MeetingRequest>) => {
        const {
            meetingID,
            caller: { userID, fullName },
            otherParticipants
        } = action.payload

        state.active = {
            meetingID,
            caller: action.payload.caller,
            otherParticipants: [...(otherParticipants ?? []), { userID, fullName, accepted: true }]
        }
    },

    guestRequestToJoinMeeting: (
        state: MeetingState,
        action: PayloadAction<GuestMeetingRequest>
    ) => {
        const { meetingID } = action.payload

        state.active = {
            meetingID,
            otherParticipants: []
        }
    },

    admitGuest: (
        _state: MeetingState,
        _action: PayloadAction<Pick<GuestMeetingRequest, 'meetingID'> & { guestUserID: string }>
    ) => {
        return
    },

    rejectGuest: (
        _state: MeetingState,
        _action: PayloadAction<Pick<GuestMeetingRequest, 'meetingID'> & { guestUserID: string }>
    ) => {
        return
    },

    /* User can create a meeting (i.e. inviting one or more others to join).
     * We immediately set the `.active` meeting but without a `meetingID`,
     * which will be returned from LiveServices when the meeting has been
     * set up...
     */
    createMeeting: (state: MeetingState, action: PayloadAction<CreateMeeting>) => {
        state.requests = []
        state.active = {
            ...action.payload,
            otherParticipants: [
                ...action.payload.otherParticipants.map(participant => ({
                    ...participant,
                    accepted: false
                }))
            ]
        }
    },

    joinMeeting: (state: MeetingState, action: PayloadAction<{ meetingID: string }>) => {
        state.requests = []
        state.active = {
            meetingID: action.payload.meetingID
        }
        state.error = undefined
    },

    removeMeetingParticipants: (
        state: MeetingState,
        action: PayloadAction<AddMeetingParticipants>
    ) => {
        if (!state.active || !isActionForActiveMeeting(state, action)) return

        if (state.active) {
            state.active = {
                ...state.active,
                otherParticipants: [
                    ...(state.active.otherParticipants ?? []).filter(
                        existing =>
                            !action.payload.otherParticipants.some(
                                removed => removed.userID === existing.userID
                            )
                    )
                ]
            }
        }
    },

    addMeetingParticipants: (
        state: MeetingState,
        action: PayloadAction<AddMeetingParticipants>
    ) => {
        if (!state.active || !isActionForActiveMeeting(state, action)) return

        state.active = {
            ...state.active,
            ...action.payload,
            otherParticipants: [
                ...(action.payload.otherParticipants.map(participant => ({
                    ...participant,
                    accepted: false
                })) ?? []),
                ...(state.active?.otherParticipants ?? [])
            ].reduce(
                /* Dedupe the participants by `userID`. NOTE: we
                 * spread the action payload's participants first (above)
                 * so that we take the action's payload as the updated
                 * values...
                 */
                (acc: Meeting['otherParticipants'], item) =>
                    acc.find(({ userID }) => userID === item.userID) ? acc : [...acc, item],
                []
            )
        }
    },

    /* When a meeting has been set up by LiveServices, we use this action
     * to set the `meetingID` (and any other information returned by LS)...
     */
    updateMeeting: (state: MeetingState, action: PayloadAction<Partial<Meeting>>) => {
        // Skip update if there are no active meetings or the incoming action is not for the currently active meeting
        if (!state.active || !isActionForActiveMeeting(state, action)) return

        state.active = {
            ...state.active,
            ...action.payload,
            otherParticipants: [
                ...(action.payload.otherParticipants ?? []),
                ...(state.active?.otherParticipants ?? [])
            ].reduce(
                /* Dedupe the participants by `userID`. NOTE: we
                 * spread the action payload's participants first (above)
                 * so that we take the action's payload as the updated
                 * values...
                 */
                (acc: Meeting['otherParticipants'], item) =>
                    acc.find(({ userID }) => userID === item.userID) ? acc : [...acc, item],
                []
            )
        }
    },

    /* Hanging up will just cause the `.active` meeting to go away...
     */
    stopMeeting: (
        state: MeetingState,
        action: PayloadAction<
            | {
                  error?: string
                  meetingID?: string
                  force?: boolean
              }
            | undefined
        >
    ) => {
        state.requests = []
        state.error = action.payload?.error
        if (!action.payload || isActionForActiveMeeting(state, action) || action.payload.force) {
            state.active = undefined
        }
    },

    /* Set a handle for the meeting window
     */
    openMeetingWindow: (state: MeetingState, action: PayloadAction<OpenMeetingWindow>) => {
        const { event, userID, clientID, token, username } = action.payload
        let path = '/meetings/setup'

        if (event === MeetingEventType.ACCEPT_VIDEO_CALL) {
            const { caller } = action.payload
            const n = state.requests.findIndex(req => req.caller.userID === caller?.userID)

            if (n < 0) {
                state.active = undefined
                return
            }

            /* Remove the accepted meeting from the pending requests...
             */
            state.requests = [...state.requests.filter((_, i) => i !== n)]
        }

        // todo
        if (event === MeetingEventType.REQUEST_TO_PULL_VIDEO_CALL) {
            path = '/video-call'
        }

        localStorage.setItem('meetingPayload', JSON.stringify(action.payload))

        if (isElectron()) {
            ;(window as any).videoMeetingsAPI.openMeetingWindow(path, {
                token,
                userID,
                clientID,
                username
            })
        } else {
            window.open(path)
        }
    },
    /* User can create a meeting (i.e. inviting one or more others to join).
     * We immediately set the `.active` meeting but without a `meetingID`,
     * which will be returned from LiveServices when the meeting has been
     * set up...
     */
    startRecording: (_state: MeetingState, _action: PayloadAction) => {
        return
    },
    pauseRecording: (_state: MeetingState, _action: PayloadAction) => {
        return
    },
    resumeRecording: (_state: MeetingState, _action: PayloadAction) => {
        return
    },
    endRecording: (_state: MeetingState, _action: PayloadAction) => {
        return
    },

    addRecording: (
        state: MeetingState,
        action: PayloadAction<{ recordingID: string; owner: boolean; meetingID: string }>
    ) => {
        if (!isActionForActiveMeeting(state, action)) return

        const { recordingID, owner } = action.payload
        state.active = {
            ...state.active,
            recordings: [
                ...(state.active?.recordings ?? []),
                { recordingID, status: MeetingRecordingStatus.STARTED, owner }
            ]
        }
    },
    updateRecording: (
        state: MeetingState,
        action: PayloadAction<
            Omit<MeetingRecording, 'owner'> & {
                meetingID: string
            }
        >
    ) => {
        if (!isActionForActiveMeeting(state, action)) return

        const { recordingID, status } = action.payload
        state.active = {
            ...state.active,
            recordings: [...(state.active?.recordings ?? [])].map(recording => {
                if (recording.recordingID === recordingID) {
                    return { recordingID, status, owner: recording.owner }
                }
                return recording
            })
        }
    },
    removeRecording: (
        state: MeetingState,
        action: PayloadAction<{ recordingID: string; meetingID: string }>
    ) => {
        if (!isActionForActiveMeeting(state, action)) return

        const recording = [...(state.active?.recordings ?? [])].find(
            ({ recordingID }) => recordingID === action.payload.recordingID
        )

        if (recording) {
            recording.status = MeetingRecordingStatus.ENDED

            state.active = {
                ...state.active,
                recordings: [
                    ...[...(state.active?.recordings ?? [])].filter(
                        ({ recordingID }) => recordingID !== action.payload.recordingID
                    ),
                    recording
                ]
            }
        }
    },

    requestToPullMeeting: (state: MeetingState, action: PayloadAction<{ meetingID: string }>) => {
        state.requests = []
        state.active = {
            meetingID: action.payload.meetingID
        }
        state.error = undefined
    },

    acceptPullMeeting: (
        _state: MeetingState,
        _action: PayloadAction<{ meetingID: string; toWebsocketID: string }>
    ) => {
        return
    },

    setNumberOfTiles: (state: MeetingState, action: PayloadAction<number>) => {
        state.active = {
            ...state.active,
            numberOfTiles: action.payload
        }
    },

    setMeetingAnswered: (state: MeetingState, action: PayloadAction<boolean>) => {
        if (state.active) state.active.answered = action.payload
    }
}

const isActionForActiveMeeting = (
    state: MeetingState,
    action: PayloadAction<{ meetingID?: string } | undefined>
) => {
    return (
        !action.payload?.meetingID ||
        !state.active?.meetingID ||
        state.active.meetingID === action.payload?.meetingID
    )
}

export const meetingSlice = createSlice<MeetingState, typeof reducers, 'meeting'>({
    initialState: { requests: [] },
    name: 'meeting',
    reducers
})

export type MeetingRootState = {
    meetingSlice: ReturnType<typeof meetingSlice.getInitialState>
}

export const selectActiveMeeting = (state: MeetingRootState) => state.meetingSlice.active
export const selectMeetingRequests = (state: MeetingRootState) => state.meetingSlice.requests
export const selectMeetingError = (state: MeetingRootState) => state.meetingSlice.error
export const selectMyRecording = (state: MeetingRootState) =>
    state.meetingSlice.active?.recordings?.find(
        ({ owner, status }) => owner && status !== MeetingRecordingStatus.ENDED
    )
export const selectRecordings = (state: MeetingRootState) => state.meetingSlice.active?.recordings

export const selectMeetingType = (state: MeetingRootState) =>
    (state.meetingSlice.active?.otherParticipants?.length || 0) > 2
        ? MeetingTypes.ONE_TO_MANY
        : MeetingTypes.ONE_TO_ONE

export const selectHostParticipant = (state: MeetingRootState) => {
    const { otherParticipants, caller } = state.meetingSlice.active || {}
    return otherParticipants?.find(({ userID }) => userID === caller?.userID)
}

export const {
    createMeeting,
    joinMeeting,
    requestToPullMeeting,
    addMeetingRequest,
    addMeetingParticipants,
    removeMeetingParticipants,
    declineMeetingRequest,
    acceptMeetingRequest,
    guestRequestToJoinMeeting,
    admitGuest,
    rejectGuest,
    updateMeeting,
    leaveActiveMeeting,
    stopMeeting,
    openMeetingWindow,
    startRecording,
    pauseRecording,
    endRecording,
    resumeRecording,
    addRecording,
    updateRecording,
    removeRecording,
    acceptPullMeeting,
    setNumberOfTiles,
    setMeetingAnswered
} = meetingSlice.actions
