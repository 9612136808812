import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const TrendUpIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TrendUpIconLight',
    viewBox: '0 0 24 14',
    path: (
        <path
            d="M15.1348 1.36523C15.1348 1.05273 15.4082 0.740234 15.7598 0.740234H22.6348C22.9473 0.740234 23.2598 1.05273 23.2598 1.36523V8.24023C23.2598 8.5918 22.9473 8.86523 22.6348 8.86523C22.2832 8.86523 22.0098 8.5918 22.0098 8.24023V2.88867L13.6895 11.209C13.4551 11.4434 13.0254 11.4434 12.791 11.209L8.25977 6.63867L1.81445 13.084C1.58008 13.3184 1.15039 13.3184 0.916016 13.084C0.681641 12.8496 0.681641 12.4199 0.916016 12.1855L7.79102 5.31055C8.02539 5.07617 8.45508 5.07617 8.68945 5.31055L13.2598 9.88086L21.1113 1.99023H15.7598C15.4082 1.99023 15.1348 1.7168 15.1348 1.36523Z"
            fill="currentColor"
        />
    )
})

export const TrendUpIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TrendUpIconRegular',
    viewBox: '0 0 24 14',
    path: (
        <path
            d="M14.5098 1.67773C14.5098 1.16992 14.9004 0.740234 15.4473 0.740234H22.3223C22.8301 0.740234 23.2598 1.16992 23.2598 1.67773V8.55273C23.2598 9.09961 22.8301 9.49023 22.3223 9.49023C21.7754 9.49023 21.3848 9.09961 21.3848 8.55273V3.94336L13.8848 11.4043C13.5332 11.7949 12.9473 11.7949 12.5957 11.4043L8.25977 7.06836L2.32227 12.9668C1.9707 13.3574 1.38477 13.3574 1.0332 12.9668C0.642578 12.6152 0.642578 12.0293 1.0332 11.6387L7.5957 5.07617C7.94727 4.72461 8.5332 4.72461 8.88477 5.07617L13.2598 9.45117L20.0566 2.61523H15.4473C14.9004 2.61523 14.5098 2.22461 14.5098 1.67773Z"
            fill="currentColor"
        />
    )
})

export const TrendUpIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TrendUpIconSolid',
    viewBox: '0 0 24 14',
    path: (
        <path
            d="M15.7646 3.23535C15.0615 3.23535 14.5146 2.68848 14.5146 1.98535C14.5146 1.32129 15.0615 0.735352 15.7646 0.735352H22.0146C22.6787 0.735352 23.2646 1.32129 23.2646 1.98535V8.23535C23.2646 8.93848 22.6787 9.48535 22.0146 9.48535C21.3115 9.48535 20.7646 8.93848 20.7646 8.23535V5.03223L14.124 11.6338C13.6553 12.1416 12.835 12.1416 12.3662 11.6338L8.22559 7.53223L2.87402 12.8838C2.40527 13.3916 1.58496 13.3916 1.11621 12.8838C0.608398 12.415 0.608398 11.5947 1.11621 11.126L7.36621 4.87598C7.83496 4.36816 8.65527 4.36816 9.12402 4.87598L13.2646 8.97754L18.9678 3.23535H15.7646Z"
            fill="currentColor"
        />
    )
})

export const TrendDownIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TrendDownIconLight',
    viewBox: '0 0 24 14',
    path: (
        <path
            d="M15.7598 11.9902H21.1113L13.2598 4.13867L8.68945 8.70898C8.45508 8.94336 8.02539 8.94336 7.79102 8.70898L0.916016 1.83398C0.681641 1.59961 0.681641 1.16992 0.916016 0.935547C1.15039 0.701172 1.58008 0.701172 1.81445 0.935547L8.25977 7.38086L12.791 2.81055C13.0254 2.57617 13.4551 2.57617 13.6895 2.81055L22.0098 11.1309V5.74023C22.0098 5.42773 22.2832 5.11523 22.6348 5.11523C22.9473 5.11523 23.2598 5.42773 23.2598 5.74023V12.6152C23.2598 12.9668 22.9473 13.2402 22.6348 13.2402H15.7598C15.4082 13.2402 15.1348 12.9668 15.1348 12.6152C15.1348 12.3027 15.4082 11.9902 15.7598 11.9902Z"
            fill="currentColor"
        />
    )
})

export const TrendDownIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TrendDownIconRegular',
    viewBox: '0 0 24 14',
    path: (
        <path
            d="M15.4473 11.3701H20.0566L13.2598 4.57324L8.88477 8.90918C8.5332 9.2998 7.94727 9.2998 7.5957 8.90918L1.0332 2.34668C0.642578 1.99512 0.642578 1.40918 1.0332 1.01855C1.38477 0.666992 1.9707 0.666992 2.32227 1.01855L8.25977 6.95605L12.5957 2.58105C12.9473 2.22949 13.5332 2.22949 13.8848 2.58105L21.3848 10.0811V5.43262C21.3848 4.9248 21.7754 4.49512 22.3223 4.49512C22.8301 4.49512 23.2598 4.9248 23.2598 5.43262V12.3076C23.2598 12.8545 22.8301 13.2451 22.3223 13.2451H15.4473C14.9004 13.2451 14.5098 12.8545 14.5098 12.3076C14.5098 11.7998 14.9004 11.3701 15.4473 11.3701Z"
            fill="currentColor"
        />
    )
})

export const TrendDownIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TrendDownIconSolid',
    viewBox: '0 0 24 14',
    path: (
        <path
            d="M18.9678 10.7451L13.2646 5.04199L9.12402 9.14355C8.65527 9.65137 7.83496 9.65137 7.36621 9.14355L1.11621 2.89355C0.608398 2.4248 0.608398 1.60449 1.11621 1.13574C1.58496 0.62793 2.40527 0.62793 2.87402 1.13574L8.22559 6.4873L12.3662 2.38574C12.835 1.87793 13.6553 1.87793 14.124 2.38574L20.7646 8.9873V5.74512C20.7646 5.08105 21.3115 4.49512 22.0146 4.49512C22.6787 4.49512 23.2646 5.08105 23.2646 5.74512V11.9951C23.2646 12.6982 22.6787 13.2451 22.0146 13.2451H15.7646C15.0615 13.2451 14.5146 12.6982 14.5146 11.9951C14.5146 11.3311 15.0615 10.7451 15.7646 10.7451H18.9678Z"
            fill="currentColor"
        />
    )
})
