import { PropsWithChildren, useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthenticatedUser } from 'shared/hooks/useAuthenticatedUser'

export interface RequireExactRolesProps {
    roles: string[]
    /** If true will redirect to /forbidden, otherwise will render nothing. */
    redirect?: boolean
}

export const RequireExactRoles = ({
    roles = [],
    redirect = false,
    children
}: PropsWithChildren<RequireExactRolesProps>) => {
    const { data: user } = useAuthenticatedUser()
    const location = useLocation()

    const userRole = user?.type

    const hasRequiredRole = useMemo(() => {
        if (!userRole) return false
        return roles.includes(userRole)
    }, [roles, userRole])

    if (!user) return null
    if (hasRequiredRole) return <>{children}</>
    if (redirect) return <Navigate to="/forbidden" replace state={{ path: location.pathname }} />
    return null
}
