import { GridItem } from '@chakra-ui/react'
import { Body, Dropdown, TimePicker } from '@missionlabs/react'
import { NotAvailable, NotAvailableDayTimes } from '@missionlabs/types'
import { format, parse } from 'date-fns'
import { ChangeEvent, FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Availability, getAvailabilityOptions, getTimeOptions, TimeSpan } from './scheduleOptions'

interface ScheduleDataRowProps {
    data: NotAvailableDayTimes | undefined
    day: string
    availability?: string
    time?: string
    from?: string
    to?: string
    onUpdate: (payload?: NotAvailable) => void
}

export const ScheduleDataRow: FC<ScheduleDataRowProps> = ({
    data,
    day,
    availability = Availability.AVAILABLE,
    time = TimeSpan.ALL_DAY,
    from,
    to,
    onUpdate
}) => {
    const { t } = useTranslation()

    const isAlwaysOn = useMemo(() => time === TimeSpan.ALL_DAY, [time])
    const isUnavailable = useMemo(() => {
        return availability === Availability.UNAVAILABLE
    }, [availability])

    const onUpdateAvailability = (availability: Availability) => {
        const payload = data && Object.keys(data).length ? { ...data } : undefined
        onUpdate({ [day]: availability === Availability.AVAILABLE ? payload : {} })
    }

    const onUpdateTime = (timeSpan: TimeSpan) => {
        let updateData: NotAvailableDayTimes | undefined = { ...data }
        if (timeSpan === TimeSpan.ALL_DAY) {
            // If they change to "All day" then we want to remove this day from the "notAvailable"
            // otherwise the availability will change to "Unavailable"
            updateData = undefined
        } else {
            updateData.before = data?.before ?? '00:00:00'
            updateData.after = data?.after ?? '00:00:00'
        }
        onUpdate({ [day]: updateData })
    }

    const onUpdateFrom = (e: ChangeEvent<HTMLInputElement>) => {
        const parsed = parse(e.target.value, 'HH:mm', new Date())
        const before = format(parsed, 'HH:mm:ss')

        onUpdate({ [day]: { ...data, before } })
    }

    const onUpdateTo = (e: ChangeEvent<HTMLInputElement>) => {
        const parsed = parse(e.target.value, 'HH:mm', new Date())
        const after = format(parsed, 'HH:mm:ss')

        onUpdate({ [day]: { ...data, after } })
    }

    return (
        <>
            <GridItem display="flex" alignItems="center">
                <Body size="xs" variant="bold">
                    {t(`calls.${day}`)}
                </Body>
            </GridItem>
            <GridItem>
                <Dropdown
                    aria-label="availability"
                    value={availability}
                    options={getAvailabilityOptions(t)}
                    onChange={onUpdateAvailability}
                />
            </GridItem>
            <GridItem>
                <Dropdown
                    aria-label="time"
                    value={time}
                    options={getTimeOptions(t)}
                    onChange={onUpdateTime}
                    isDisabled={isUnavailable}
                />
            </GridItem>
            <GridItem>
                <TimePicker
                    aria-label="from"
                    value={!isAlwaysOn ? from : '00:00'}
                    onChange={onUpdateFrom}
                    isDisabled={isAlwaysOn}
                />
            </GridItem>
            <GridItem>
                <TimePicker
                    aria-label="to"
                    value={!isAlwaysOn ? to : '00:00'}
                    onChange={onUpdateTo}
                    isDisabled={isAlwaysOn}
                />
            </GridItem>
        </>
    )
}
