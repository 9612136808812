import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const AvatarIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'AvatarIcon',
    viewBox: '0 0 16 17',
    path: (
        <path
            d="M8 1.5C4.11719 1.5 1 4.64453 1 8.5C1 12.3828 4.11719 15.5 8 15.5C11.8555 15.5 15 12.3828 15 8.5C15 4.64453 11.8555 1.5 8 1.5ZM8 5C9.06641 5 9.96875 5.90234 9.96875 6.96875C9.96875 8.0625 9.06641 8.9375 8 8.9375C6.90625 8.9375 6.03125 8.0625 6.03125 6.96875C6.03125 5.90234 6.90625 5 8 5ZM8 13.75C6.55078 13.75 5.23828 13.1758 4.28125 12.2188C4.71875 11.0703 5.8125 10.25 7.125 10.25H8.875C10.1602 10.25 11.2539 11.0703 11.6914 12.2188C10.7344 13.1758 9.42188 13.75 8 13.75Z"
            fill="#6E7090"
        />
    )
})
