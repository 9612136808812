import { AspectRatio } from 'amazon-chime-sdk-component-library-react/lib/hooks/useElementAspectRatio/index'
import styled from 'styled-components'

import { VideoGridProps } from './'

interface StyledGridProps extends VideoGridProps {
    ratio?: AspectRatio | null
    size: number
    featured: boolean
}

const sortedRatios: AspectRatio[] = ['slim', 'r1by2', 'r2by3', 'r1by1', 'r4by3', 'r3by2', 'r16by9']

const ratioStyles = {
    '1': `grid-template: 'a' 1fr / 1fr;`,

    '1.featured': `grid-template: "ft" 1fr / 1fr;`,

    '2': `grid-template: 1fr / repeat(2,1fr); grid-template-areas: 'a b';`,

    '2.featured': `grid-template: repeat(4,1fr) / repeat(3,1fr) 128px;
                   grid-template-areas: 'ft ft ft a' 'ft ft ft a' 'ft ft ft b' 'ft ft ft b';`,

    '3': `grid-template: 1fr / repeat(3,1fr); grid-template-areas: 'a b c';`,

    '3.featured': `grid-template: repeat(4,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft a' 'ft ft ft b' 'ft ft ft b';`,

    '4': `grid-template: repeat(2,1fr) / repeat(2,1fr);  grid-template-areas: 'a b' 'c d';`,

    '4.featured': `grid-template: repeat(3,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c';`,

    '5': `grid-template: repeat(2,1fr) / repeat(6,1fr); grid-template-areas: 'a a b b c c' 'd d d e e e' `,

    '5.featured': `grid-template: repeat(4,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c' 'ft ft ft d' ;`,

    '6': `grid-template: repeat(2,1fr) / repeat(3,1fr); grid-template-areas: 'a b c' 'd e f';`,

    '6.featured': `grid-template: repeat(5,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c' 'ft ft ft d' 'ft ft ft e';`,

    '7': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px; grid-template-areas: 'a b c g' 'a b c g' 'a b c h' 'a b c h' 'd e f i' 'd e f i' 'd e f j' 'd e f k';`,

    '7.featured': `grid-template: repeat(6,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c' 'ft ft ft d' 'ft ft ft e' 'ft ft ft f';`,

    '8': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px; grid-template-areas: 'a b c g' 'a b c g' 'a b c h' 'a b c h' 'd e f i' 'd e f i' 'd e f j' 'd e f k';`,

    '8.featured': `grid-template: repeat(6,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c' 'ft ft ft d' 'ft ft ft e' 'ft ft ft f';`,

    '9': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px; grid-template-areas: 'a b c g' 'a b c g' 'a b c h' 'a b c h' 'd e f i' 'd e f i' 'd e f j' 'd e f k';`,

    '9.featured': `grid-template: repeat(6,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c' 'ft ft ft d' 'ft ft ft e' 'ft ft ft f';`,

    '10': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px; grid-template-areas: 'a b c g' 'a b c g' 'a b c h' 'a b c h' 'd e f i' 'd e f i' 'd e f j' 'd e f j';`,

    '10.featured': `grid-template: repeat(6,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c' 'ft ft ft d' 'ft ft ft e' 'ft ft ft f';`,

    '11': `grid-template: repeat(10,1fr) / repeat(3,1fr) 128px; grid-template-areas: 'a b c g' 'a b c g' 'a b c h' 'a b c h' 'a b c i' 'd e f i' 'd e f j' 'd e f j' 'd e f k' 'd e f k';`,

    '11.featured': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c' 'ft ft ft d' 'ft ft ft e' 'ft ft ft f' 'ft ft ft g' 'ft ft ft h';`,

    '12': `grid-template: repeat(6,1fr) / repeat(3,1fr) 128px; grid-template-areas: 'a b c g' 'a b c h' 'a b c i' 'd e f j' 'd e f k' 'd e f l';`,

    '12.featured': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c' 'ft ft ft d' 'ft ft ft e' 'ft ft ft f' 'ft ft ft g' 'ft ft ft h';`,

    '13': `grid-template: repeat(14,1fr) / repeat(3,1fr) 128px; grid-template-areas: 'a b c g' 'a b c g' 'a b c h' 'a b c h' 'a b c i' 'a b c i' 'a b c j' 'd e f j' 'd e f k' 'd e f k' 'd e f l' 'd e f l' 'd e f m' 'd e f m';`,

    '13.featured': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c' 'ft ft ft d' 'ft ft ft e' 'ft ft ft f' 'ft ft ft g' 'ft ft ft h';`,

    '14': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px; grid-template-areas: 'a b c g' 'a b c h' 'a b c i' 'a b c j' 'd e f k' 'd e f l' 'd e f m' 'd e f n';`,

    '14.featured': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c' 'ft ft ft d' 'ft ft ft e' 'ft ft ft f' 'ft ft ft g' 'ft ft ft h';`,

    '15': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px; grid-template-areas: 'a b c g' 'a b c h' 'a b c i' 'a b c j' 'd e f k' 'd e f l' 'd e f m' 'd e f n';`,

    '15.featured': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c' 'ft ft ft d' 'ft ft ft e' 'ft ft ft f' 'ft ft ft g' 'ft ft ft h';`,

    '16': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px; grid-template-areas: 'a b c g' 'a b c h' 'a b c i' 'a b c j' 'd e f k' 'd e f l' 'd e f m' 'd e f n';`,

    '16.featured': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c' 'ft ft ft d' 'ft ft ft e' 'ft ft ft f' 'ft ft ft g' 'ft ft ft h';`,

    '17': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px; grid-template-areas: 'a b c g' 'a b c h' 'a b c i' 'a b c j' 'd e f k' 'd e f l' 'd e f m' 'd e f n';`,

    '17.featured': `grid-template: repeat(8,1fr) / repeat(3,1fr) 128px;
    grid-template-areas: 'ft ft ft a' 'ft ft ft b' 'ft ft ft c' 'ft ft ft d' 'ft ft ft e' 'ft ft ft f' 'ft ft ft g' 'ft ft ft h';`,

    '18': 'grid-template: repeat(5,1fr) / repeat(4,1fr);',
    '18.slim': 'grid-template: repeat(8,1fr) / repeat(3,1fr);',
    '18.r1by2': 'grid-template: repeat(6,1fr) / repeat(4,1fr);',
    '18.r1by1': 'grid-template: repeat(4,1fr) / repeat(5,1fr);',

    '18.featured': `grid-template: repeat(8,1fr) / repeat(9,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft'
      'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft';`,
    '18.r1by1.featured': `grid-template: repeat(9,1fr) / repeat(9,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft v'
      'ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft x';`,

    '19': 'grid-template: repeat(5,1fr) / repeat(4,1fr);',
    '19.slim': 'grid-template: repeat(8,1fr) / repeat(3,1fr);',
    '19.r1by2': 'grid-template: repeat(6,1fr) / repeat(4,1fr);',
    '19.r1by1': 'grid-template: repeat(4,1fr) / repeat(5,1fr);',

    '19.featured': `grid-template: repeat(8,1fr) / repeat(9,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft'
      'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft';`,
    '19.r1by1.featured': `grid-template: repeat(9,1fr) / repeat(10,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft ft v' 
      'ft ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft ft v' 
      'ft ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft ft x';`,

    '20': 'grid-template: repeat(5,1fr) / repeat(4,1fr);',
    '20.slim': 'grid-template: repeat(8,1fr) / repeat(3,1fr);',
    '20.r1by2': 'grid-template: repeat(6,1fr) / repeat(4,1fr);',
    '20.r1by1': 'grid-template: repeat(4,1fr) / repeat(5,1fr);',

    '20.featured': `grid-template: repeat(11,1fr) / repeat(7,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 
      'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft';`,
    '20.r1by1.featured': `grid-template: repeat(10,1fr) / repeat(10,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft ft v' 
      'ft ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft ft v' 
      'ft ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft ft x';`,

    '21': 'grid-template: repeat(5,1fr) / repeat(5,1fr);',
    '21.slim': 'grid-template: repeat(8,1fr) / repeat(3,1fr);',
    '21.r1by2': 'grid-template: repeat(6,1fr) / repeat(4,1fr);',
    '21.r1by1': 'grid-template: repeat(5,1fr) / repeat(5,1fr);',

    '21.featured': `grid-template: repeat(11,1fr) / repeat(7,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 
      'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft';`,
    '21.r1by1.featured': `grid-template: repeat(8,1fr) / repeat(7,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft v' 'ft ft ft ft ft ft v' 'ft ft ft ft ft ft v'  
      'ft ft ft ft ft ft v' 'ft ft ft ft ft ft v' 'ft ft ft ft ft ft x';`,

    '22': 'grid-template: repeat(5,1fr) / repeat(5,1fr);',
    '22.slim': 'grid-template: repeat(8,1fr) / repeat(3,1fr);',
    '22.r1by2': 'grid-template: repeat(6,1fr) / repeat(4,1fr);',
    '22.r1by1': 'grid-template: repeat(5,1fr) / repeat(5,1fr);',

    '22.featured': `grid-template: repeat(11,1fr) / repeat(7,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 
      'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft';`,
    '22.r1by1.featured': `grid-template: repeat(9,1fr) / repeat(7,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft v' 'ft ft ft ft ft ft v' 'ft ft ft ft ft ft v' 'ft ft ft ft ft ft v' 
      'ft ft ft ft ft ft v' 'ft ft ft ft ft ft v' 'ft ft ft ft ft ft x';`,

    '23': 'grid-template: repeat(5,1fr) / repeat(5,1fr);',
    '23.slim': 'grid-template: repeat(8,1fr) / repeat(3,1fr);',
    '23.r1by2': 'grid-template: repeat(6,1fr) / repeat(4,1fr);',
    '23.r1by1': 'grid-template: repeat(5,1fr) / repeat(5,1fr);',

    '23.featured': `grid-template: repeat(11,1fr) / repeat(8,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 
      'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft';`,
    '23.r1by1.featured': `grid-template: repeat(8,1fr) / repeat(8,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft v'  
      'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft x';`,

    '24': 'grid-template: repeat(5,1fr) / repeat(5,1fr);',
    '24.slim': 'grid-template: repeat(8,1fr) / repeat(3,1fr);',
    '24.r1by2': 'grid-template: repeat(6,1fr) / repeat(4,1fr);',
    '24.r1by1': 'grid-template: repeat(5,1fr) / repeat(5,1fr);',

    '24.featured': `grid-template: repeat(11,1fr) / repeat(8,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 
      'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft';`,
    '24.r1by1.featured': `grid-template: repeat(9,1fr) / repeat(8,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft v' 
      'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft x';`,

    '25': 'grid-template: repeat(5,1fr) / repeat(5,1fr);',
    '25.slim': 'grid-template: repeat(9,1fr) / repeat(3,1fr);',
    '25.r1by2': 'grid-template: repeat(7,1fr) / repeat(4,1fr);',
    '25.r1by1': 'grid-template: repeat(5,1fr) / repeat(5,1fr);',

    '25.featured': `grid-template: repeat(11,1fr) / repeat(8,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 
      'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft';`,
    '25.r1by1.featured': `grid-template: repeat(10,1fr) / repeat(8,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft v' 
      'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft x';`,

    '26': 'grid-template: repeat(6,1fr) / repeat(5,1fr);',
    '26.slim': 'grid-template: repeat(9,1fr) / repeat(3,1fr);',
    '26.r1by2': 'grid-template: repeat(7,1fr) / repeat(4,1fr);',
    '26.r1by1': 'grid-template: repeat(6,1fr) / repeat(5,1fr);',

    '26.featured': `grid-template: repeat(11,1fr) / repeat(9,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft'
      'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft ft';`,
    '26.r1by1.featured': `grid-template: repeat(9,1fr) / repeat(9,1fr);
    grid-template-areas:
      'ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft v' 
      'ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft v' 'ft ft ft ft ft ft ft ft x';`
}

const responsiveStyles = {
    '2.featured': `
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-template-rows: calc(100% / 3);
      grid-auto-rows: calc(100% / 3);
    }
  `,

    '3.r16by9': `
    @media (max-height: 600px) {
      grid-template-rows: repeat(2,1fr);
      grid-template-columns: 50%;
      grid-auto-columns: 50%;
      grid-auto-flow: column;
    }
  `,

    '3.featured': `
    @media (max-width: 600px) {
      grid-template-areas: 'ft' 'ft';
      grid-template-columns: 1fr;
      grid-template-rows: 25%;
      grid-auto-rows: 25%;
    }
  `,

    '4.r16by9': `
    @media (max-height: 600px) {
      grid-template-rows: repeat(2,1fr);
      grid-template-columns: 50%;
      grid-auto-columns: 50%;
      grid-auto-flow: column;
    }
  `,

    '5.r16by9': `grid-template: repeat(2,1fr) / repeat(3,1fr);
    @media (max-height: 600px) {
      grid-template-rows: repeat(2,1fr);
      grid-template-columns: calc(100% / 3);
      grid-auto-columns: calc(100% / 3);
      grid-auto-flow: column;
    }
  `,

    '6.r16by9': `grid-template: repeat(2,1fr) / repeat(3,1fr);
    @media (max-height: 600px) {
      grid-template-rows: repeat(2,1fr);
      grid-template-columns: calc(100% / 3);
      grid-auto-columns: calc(100% / 3);
      grid-auto-flow: column;
    }
  `,

    '7.r16by9': `
    @media (max-height: 600px) {
      grid-template-rows: repeat(2,1fr);
      grid-template-columns: 25%;
      grid-auto-columns: 25%;
      grid-auto-flow: column;
    }
  `
}

const portraitStyles = `
  @media (max-width: 600px) {
    grid-template-areas: 'ft ft' 'ft ft';
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 25%;
    grid-auto-rows: 25%;
  }
`

const landscapeStyles = `
  @media (max-height: 600px) {
    grid-template-areas: 'ft ft' 'ft ft';
    grid-template-rows: repeat(2,1fr);
    grid-template-columns: 25%;
    grid-auto-columns: 25%;
    grid-auto-flow: column;
  }
`

export const StyledGrid = styled.div<StyledGridProps>`
    position: relative;
    display: grid;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: ${props => props.theme.videoGrid.bgd};

    ${({ size, featured }) => ratioStyles[`${size}${featured ? '.featured' : ''}`] || ''}
    ${({ size, featured, ratio }) => {
        if (!ratio) {
            return
        }

        let styles = ''
        const index = sortedRatios.indexOf(ratio)

        for (let i = 0; i <= index; i++) {
            const currentRatio = sortedRatios[i]
            const baseStyles = ratioStyles[`${size}.${currentRatio}${featured ? '.featured' : ''}`]
            styles += baseStyles || ''
        }

        const mobileStyles =
            responsiveStyles[`${size}.${ratio}${featured ? '.featured' : ''}`] ||
            responsiveStyles[`${size}${featured ? '.featured' : ''}`]

        if (mobileStyles) {
            styles += mobileStyles
        } else if (ratio === 'r16by9' && (size > 7 || featured)) {
            styles += landscapeStyles
        } else if (size > 7 || featured) {
            styles += portraitStyles
        }

        return styles
    }};
`
