import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { useGetUserData as _useGetUserData } from '@missionlabs/react'
import {
    useGetDestinationForUserQuery,
    useGetUserCallRouteQuery,
    useGetUserQuery
} from 'shared/store'

export function useGetUserData(
    skip?: Parameters<typeof _useGetUserData>[4]
): ReturnType<typeof _useGetUserData> {
    const auth = useSelector(selectAuthenticatedUser)
    return _useGetUserData(
        auth,
        useGetUserQuery,
        useGetUserCallRouteQuery,
        useGetDestinationForUserQuery,
        skip
    )
}
