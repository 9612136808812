import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const MicOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MicOutlineIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5 5C8.5 2.79086 10.2909 1 12.5 1C14.7091 1 16.5 2.79086 16.5 5V13C16.5 15.2091 14.7091 17 12.5 17C10.2909 17 8.5 15.2091 8.5 13V5ZM15.5 5V13C15.5 14.6569 14.1569 16 12.5 16C10.8431 16 9.5 14.6569 9.5 13V5C9.5 3.34315 10.8431 2 12.5 2C14.1569 2 15.5 3.34315 15.5 5Z"
                fill="currentColor"
            />
            <path
                d="M16.5 23V22H13.5V19.9291C16.8923 19.4439 19.5 16.5265 19.5 13V11H18.5V13C18.5 16.3137 15.8137 19 12.5 19C9.18629 19 6.5 16.3137 6.5 13V11H5.5V13C5.5 16.5265 8.10771 19.4439 11.5 19.9291V22H8.5V23H16.5Z"
                fill="currentColor"
            />
        </g>
    )
})

export const MicOffOutlineIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MicOffOutlineIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M8.5 13V7.5721L9.5 9.30415V13C9.5 14.6569 10.8431 16 12.5 16C12.7782 16 13.0475 15.9621 13.3031 15.8913L13.8154 16.7787C13.4035 16.9221 12.9608 17 12.5 17C10.2909 17 8.5 15.2091 8.5 13Z"
                fill="currentColor"
            />
            <path
                d="M15.5 13C15.5 13.2035 15.4797 13.4023 15.4411 13.5945L16.1026 14.7402C16.3572 14.2141 16.5 13.6237 16.5 13V5C16.5 2.79086 14.7091 1 12.5 1C11.1209 1 9.90486 1.69789 9.1857 2.75977L9.76557 3.76414C10.2365 2.72379 11.2837 2 12.5 2C14.1569 2 15.5 3.34315 15.5 5V13Z"
                fill="currentColor"
            />
            <path
                d="M12.5 19C13.3254 19 14.1119 18.8333 14.8276 18.5318L15.3311 19.4039C14.7571 19.658 14.1426 19.8372 13.5 19.9291V22H16.5V23H8.5V22H11.5V19.9291C8.10771 19.4439 5.5 16.5265 5.5 13V11H6.5V13C6.5 16.3137 9.18629 19 12.5 19Z"
                fill="currentColor"
            />
            <path
                d="M17.2297 16.6923L17.7628 17.6157C18.8443 16.3835 19.5 14.7683 19.5 13V11H18.5V13C18.5 14.3925 18.0256 15.6742 17.2297 16.6923Z"
                fill="currentColor"
            />
            <path
                d="M17.8833 19.8238L7.44287 1.74048L6.57685 2.24048L17.0172 20.3238L17.8833 19.8238Z"
                fill="currentColor"
            />
        </g>
    )
})

export const MicOffIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MicOffIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M8 13V7.5721L9 9.30415L12.8031 15.8913L13.3154 16.7787C12.9035 16.9221 12.4608 17 12 17C9.79086 17 8 15.2091 8 13Z"
                fill="currentColor"
            />
            <path
                d="M14.9411 13.5945L15.6026 14.7402C15.8572 14.2141 16 13.6237 16 13V5C16 2.79086 14.2091 1 12 1C10.6209 1 9.40486 1.69789 8.6857 2.75977L9.26557 3.76414L14.9411 13.5945Z"
                fill="currentColor"
            />
            <path
                d="M12 19C12.8254 19 13.6119 18.8333 14.3276 18.5318L14.8311 19.4039C14.2571 19.658 13.6426 19.8372 13 19.9291V22H16V23H8V22H11V19.9291C7.60771 19.4439 5 16.5265 5 13V11H6V13C6 16.3137 8.68629 19 12 19Z"
                fill="currentColor"
            />
            <path
                d="M16.7297 16.6923L17.2628 17.6157C18.3443 16.3835 19 14.7683 19 13V11H18V13C18 14.3925 17.5256 15.6742 16.7297 16.6923Z"
                fill="currentColor"
            />
            <path d="M18.366 21L6 0.5L5.13397 1L17.5 21.5L18.366 21Z" fill="currentColor" />
        </g>
    )
})

export const MicOnIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MicOnIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 5C8 2.79086 9.79086 1 12 1C14.2091 1 16 2.79086 16 5V13C16 15.2091 14.2091 17 12 17C9.79086 17 8 15.2091 8 13V5Z"
                fill="#616E7C"
            />
            <path
                d="M16 23V22H13V19.9291C16.3923 19.4439 19 16.5265 19 13V11H18V13C18 16.3137 15.3137 19 12 19C8.68629 19 6 16.3137 6 13V11H5V13C5 16.5265 7.60771 19.4439 11 19.9291V22H8V23H16Z"
                fill="#616E7C"
            />
        </g>
    )
})

export const MicIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MicIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M16.2 5.24561L16.2 11.6784C16.2 14.0232 14.3196 15.924 12 15.924C9.68041 15.924 7.8 14.0232 7.8 11.6784V5.24561C7.8 2.90083 9.68041 1 12 1C14.3196 1 16.2 2.90083 16.2 5.24561Z"
                fill="currentColor"
            />
            <path
                d="M6.4 11.7872V8.93198L5 8.93502V11.6784C5 15.1017 7.40486 17.9572 10.6 18.6128V21.5848H7.8V23H16.2V21.5848H13.4V18.6128C16.5951 17.9572 19 15.1017 19 11.6784V8.90462L17.6 8.90766L17.6 11.7872C17.6 14.8535 15.0928 17.3392 12 17.3392C8.90721 17.3392 6.4 14.8535 6.4 11.7872Z"
                fill="currentColor"
            />
        </g>
    )
})
