import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const EmailIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EmailIconLight',
    viewBox: '0 0 20 16',
    path: (
        <path
            d="M0 3C0 1.63281 1.09375 0.5 2.5 0.5H17.5C18.8672 0.5 20 1.63281 20 3V13C20 14.4062 18.8672 15.5 17.5 15.5H2.5C1.09375 15.5 0 14.4062 0 13V3ZM1.25 3V4.5625L8.86719 10.1875C9.53125 10.6562 10.4297 10.6562 11.0938 10.1875L18.75 4.5625V3C18.75 2.33594 18.1641 1.75 17.5 1.75H2.46094C1.79688 1.75 1.21094 2.33594 1.21094 3H1.25ZM1.25 6.125V13C1.25 13.7031 1.79688 14.25 2.5 14.25H17.5C18.1641 14.25 18.75 13.7031 18.75 13V6.125L11.8359 11.2031C10.7422 11.9844 9.21875 11.9844 8.125 11.2031L1.25 6.125Z"
            fill="currentColor"
        />
    )
})

export const EmailIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EmailIconRegular',
    viewBox: '0 0 20 16',
    path: (
        <path
            d="M0 3C0 1.63281 1.09375 0.5 2.5 0.5H17.5C18.8672 0.5 20 1.63281 20 3V13C20 14.4062 18.8672 15.5 17.5 15.5H2.5C1.09375 15.5 0 14.4062 0 13V3ZM1.875 3V3.89844L8.59375 9.40625C9.41406 10.0703 10.5469 10.0703 11.3672 9.40625L18.125 3.89844V3C18.125 2.6875 17.8125 2.375 17.5 2.375H2.5C2.14844 2.375 1.875 2.6875 1.875 3ZM1.875 6.32031V13C1.875 13.3516 2.14844 13.625 2.5 13.625H17.5C17.8125 13.625 18.125 13.3516 18.125 13V6.32031L12.5781 10.8516C11.0547 12.1016 8.90625 12.1016 7.38281 10.8516L1.875 6.32031Z"
            fill="currentColor"
        />
    )
})

export const EmailIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EmailIconSolid',
    viewBox: '0 0 20 16',
    path: (
        <path
            d="M18.125 0.5C19.1406 0.5 20 1.35938 20 2.375C20 3 19.6875 3.54688 19.2188 3.89844L10.7422 10.2656C10.2734 10.6172 9.6875 10.6172 9.21875 10.2656L0.742188 3.89844C0.273438 3.54688 0 3 0 2.375C0 1.35938 0.820312 0.5 1.875 0.5H18.125ZM8.47656 11.2812C9.375 11.9453 10.5859 11.9453 11.4844 11.2812L20 4.875V13C20 14.4062 18.8672 15.5 17.5 15.5H2.5C1.09375 15.5 0 14.4062 0 13V4.875L8.47656 11.2812Z"
            fill="currentColor"
        />
    )
})

export const EmailNotificationIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EmailNotificationIconLight',
    viewBox: '0 0 23 18',
    path: (
        <path
            d="M17.5 3.125C17.5 2.62772 17.6975 2.15081 18.0492 1.79917C18.4008 1.44754 18.8777 1.25 19.375 1.25C19.8723 1.25 20.3492 1.44754 20.7008 1.79917C21.0525 2.15081 21.25 2.62772 21.25 3.125C21.25 3.62228 21.0525 4.09919 20.7008 4.45083C20.3492 4.80246 19.8723 5 19.375 5C18.8777 5 18.4008 4.80246 18.0492 4.45083C17.6975 4.09919 17.5 3.62228 17.5 3.125ZM22.5 3.125C22.5 2.2962 22.1708 1.50134 21.5847 0.915291C20.9987 0.32924 20.2038 0 19.375 0C18.5462 0 17.7513 0.32924 17.1653 0.915291C16.5792 1.50134 16.25 2.2962 16.25 3.125C16.25 3.9538 16.5792 4.74866 17.1653 5.33471C17.7513 5.92076 18.5462 6.25 19.375 6.25C20.2038 6.25 20.9987 5.92076 21.5847 5.33471C22.1708 4.74866 22.5 3.9538 22.5 3.125ZM20 15V7.45703C19.7969 7.48438 19.5859 7.5 19.375 7.5C18.8359 7.5 18.3203 7.40234 17.8438 7.22266L11.1094 12.1602C10.4492 12.6445 9.55078 12.6445 8.89062 12.1602L1.25 6.55859V5C1.25 4.30859 1.80859 3.75 2.5 3.75H15.043C15.0156 3.54688 15 3.33594 15 3.125C15 2.91406 15.0156 2.70312 15.043 2.5H2.5C1.12109 2.5 0 3.62109 0 5V15C0 16.3789 1.12109 17.5 2.5 17.5H17.5C18.8789 17.5 20 16.3789 20 15ZM1.25 8.10938L8.15234 13.168C9.25391 13.9727 10.75 13.9727 11.8477 13.168L18.75 8.10938V15C18.75 15.6914 18.1914 16.25 17.5 16.25H2.5C1.80859 16.25 1.25 15.6914 1.25 15V8.10938Z"
            fill="currentColor"
        />
    )
})

export const EmailNotificationIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EmailNotificationIconRegular',
    viewBox: '0 0 24 18',
    path: (
        <path
            d="M20.125 6.25C20.9538 6.25 21.7487 5.92076 22.3347 5.33471C22.9208 4.74866 23.25 3.9538 23.25 3.125C23.25 2.2962 22.9208 1.50134 22.3347 0.915291C21.7487 0.32924 20.9538 0 20.125 0C19.2962 0 18.5013 0.32924 17.9153 0.915291C17.3292 1.50134 17 2.2962 17 3.125C17 3.9538 17.3292 4.74866 17.9153 5.33471C18.5013 5.92076 19.2962 6.25 20.125 6.25ZM20.75 15V7.45703C20.5469 7.48437 20.3359 7.5 20.125 7.5C19.2461 7.5 18.4258 7.24219 17.7422 6.79297L12.1367 11.3945C11.3281 12.0586 10.168 12.0586 9.35938 11.3945L2.625 5.86328V5C2.625 4.65625 2.90625 4.375 3.25 4.375H15.9297C15.8125 3.98047 15.75 3.55859 15.75 3.125C15.75 2.91406 15.7656 2.70312 15.793 2.5H3.25C1.87109 2.5 0.75 3.62109 0.75 5V15C0.75 16.3789 1.87109 17.5 3.25 17.5H18.25C19.6289 17.5 20.75 16.3789 20.75 15ZM2.625 8.28906L8.17188 12.8438C9.67188 14.0742 11.832 14.0742 13.3281 12.8438L18.875 8.28906V15C18.875 15.3438 18.5938 15.625 18.25 15.625H3.25C2.90625 15.625 2.625 15.3438 2.625 15V8.28906Z"
            fill="currentColor"
        />
    )
})

export const EmailNotificationIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'EmailNotificationIconSolid',
    viewBox: '0 0 23 18',
    path: (
        <path
            d="M19.375 0C20.2038 0 20.9987 0.32924 21.5847 0.915291C22.1708 1.50134 22.5 2.2962 22.5 3.125C22.5 3.9538 22.1708 4.74866 21.5847 5.33471C20.9987 5.92076 20.2038 6.25 19.375 6.25C18.5462 6.25 17.7513 5.92076 17.1653 5.33471C16.5792 4.74866 16.25 3.9538 16.25 3.125C16.25 2.2962 16.5792 1.50134 17.1653 0.915291C17.7513 0.32924 18.5462 0 19.375 0V0ZM1.875 2.5H15.043C15.0156 2.70312 15 2.91406 15 3.125C15 4.90625 16.0625 6.4375 17.5898 7.12109L10.75 12.25C10.3047 12.582 9.69531 12.582 9.25 12.25L0.75 5.875C0.277344 5.51953 0 4.96484 0 4.375C0 3.33984 0.839844 2.5 1.875 2.5ZM11.5 13.25L19.1719 7.49609C19.2383 7.5 19.3047 7.5 19.375 7.5C19.5859 7.5 19.7969 7.48437 20 7.45703V15C20 16.3789 18.8789 17.5 17.5 17.5H2.5C1.12109 17.5 0 16.3789 0 15V6.875L8.5 13.25C9.39062 13.918 10.6094 13.918 11.5 13.25Z"
            fill="currentColor"
        />
    )
})
