import { Box, useColorMode, VStack } from '@chakra-ui/react'
import {
    ErrorMessage,
    get,
    Scrollable,
    useFormContext,
    useMeasure,
    VirtualPaginationContextProvider
} from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchInput } from 'shared/components/SearchInput'

import { useAddEditMeetingDirectorySearch } from '../hooks/useAddEditMeetingDirectorySearch'
import { AddAttendeesList } from './AddAttendeesList'
import { AddGuestAttendee } from './AddGuestAttendee'

type MeetingCreateShareFormProps = {}

export const MeetingCreateShareForm: FC<MeetingCreateShareFormProps> = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    const [linkRef, { blockSize: linkHeight }] = useMeasure<HTMLDivElement>()

    const {
        contacts,
        search,
        noSearchResults,
        showEmailWarning,
        handleSearch,
        clearSearch,
        filterParams
    } = useAddEditMeetingDirectorySearch()

    const {
        formState: { errors }
    } = useFormContext()

    const error = get(errors, 'invitedUsers')

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div style={{ background: 'white' }} ref={linkRef}>
                <SearchInput
                    value={search}
                    hideFilters
                    onChange={handleSearch}
                    placeholder={t('Enter name or email')}
                />
            </div>
            {showEmailWarning ? (
                <AddGuestAttendee email={search} />
            ) : (
                <VStack
                    spacing={0}
                    flex={1}
                    w="full"
                    px="16px"
                    align="stretch"
                    overflow="hidden"
                    bg={`${colorMode}.tones.ghostWhite`}
                    h={'100%'}
                >
                    <VirtualPaginationContextProvider>
                        <AddAttendeesList
                            searchedContacts={search.length ? contacts : []}
                            clearSearch={clearSearch}
                            scrollElement={
                                <Scrollable
                                    h={`calc(100% - ${linkHeight + (error ? 29 : 0)}px)`}
                                    bg={`${colorMode}.tones.ghostWhite`}
                                />
                            }
                            noSearchResults={noSearchResults}
                            filterParams={filterParams}
                            siblingHeight={error ? linkHeight + 29 : linkHeight}
                        />
                    </VirtualPaginationContextProvider>
                </VStack>
            )}

            <Box px="24px">
                <ErrorMessage error={error} />
            </Box>
        </div>
    )
}
