import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ShareScreenIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ShareScreenIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M17.1429 0H2.85714C1.25 0 0 1.29464 0 2.85714V17.1429C0 18.75 1.25 20 2.85714 20H17.1429C18.7054 20 20 18.75 20 17.1429V2.85714C20 1.29464 18.7054 0 17.1429 0ZM18.5714 17.1429C18.5714 17.9464 17.9018 18.5714 17.1429 18.5714H2.85714C2.05357 18.5714 1.42857 17.9464 1.42857 17.1429V2.85714C1.42857 2.09821 2.05357 1.42857 2.85714 1.42857H17.1429C17.9018 1.42857 18.5714 2.09821 18.5714 2.85714V17.1429ZM10.4911 4.50893C10.3571 4.375 10.1786 4.28571 9.95536 4.28571C9.73214 4.28571 9.59822 4.375 9.46429 4.50893L5.17857 8.79464C4.91071 9.0625 4.91071 9.55357 5.17857 9.82143C5.44643 10.0893 5.9375 10.0893 6.20536 9.82143L9.28572 6.74107V15C9.28572 15.4018 9.59822 15.7143 9.95536 15.7143C10.3125 15.7143 10.7143 15.4018 10.7143 15V6.74107L13.75 9.82143C14.0179 10.0893 14.5089 10.0893 14.7768 9.82143C15.0446 9.55357 15.0446 9.0625 14.7768 8.79464L10.4911 4.50893Z"
            fill="currentColor"
        />
    )
})

export const ShareScreenIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ShareScreenIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10.7589 4.64286C10.5804 4.41964 10.2679 4.28571 9.95536 4.28571C9.59822 4.28571 9.375 4.41964 9.19643 4.64286L4.55357 9.64286C4.15179 10.0893 4.15179 10.7589 4.59821 11.1607C5.04464 11.5625 5.71429 11.5625 6.11607 11.1161L8.92857 8.125V14.6429C8.92857 15.2679 9.375 15.7143 10 15.7143C10.5804 15.7143 11.0714 15.2679 11.0714 14.6429V8.125L13.8393 11.1161C14.2411 11.5625 14.9107 11.5625 15.3571 11.1607C15.8036 10.7589 15.8036 10.0893 15.4018 9.64286L10.7589 4.64286ZM17.1429 0H2.85714C1.25 0 0 1.29464 0 2.85714V17.1429C0 18.75 1.25 20 2.85714 20H17.1429C18.7054 20 20 18.75 20 17.1429V2.85714C20 1.29464 18.7054 0 17.1429 0ZM17.8571 17.1429C17.8571 17.5446 17.5 17.8571 17.1429 17.8571H2.85714C2.45536 17.8571 2.14286 17.5446 2.14286 17.1429V2.85714C2.14286 2.5 2.45536 2.14286 2.85714 2.14286H17.1429C17.5 2.14286 17.8571 2.5 17.8571 2.85714V17.1429Z"
            fill="currentColor"
        />
    )
})

export const ShareScreenIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ShareScreenIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M17.1429 0H2.85714C1.25 0 0 1.29464 0 2.85714V17.1429C0 18.75 1.25 20 2.85714 20H17.1429C18.7054 20 20 18.75 20 17.1429V2.85714C20 1.29464 18.7054 0 17.1429 0ZM15.625 9.95536C15.0893 10.5357 14.1518 10.5357 13.6161 9.95536L11.4286 7.76786V15.7143C11.4286 16.5179 10.7589 17.1429 10 17.1429C9.19643 17.1429 8.57143 16.5179 8.57143 15.7143V7.76786L6.33929 9.95536C5.80357 10.5357 4.86607 10.5357 4.33036 9.95536C3.75 9.41964 3.75 8.48214 4.33036 7.94643L8.92857 3.34821C9.33036 2.94643 9.77679 2.85714 10 2.85714C10.1786 2.85714 10.625 2.94643 10.9821 3.30357L15.5804 7.90179C16.2054 8.48214 16.2054 9.41964 15.625 9.95536Z"
            fill="currentColor"
        />
    )
})
