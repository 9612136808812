import { useColorMode, VStack } from '@chakra-ui/react'
import { Body, Button, useToast } from '@missionlabs/react'
import { downloadURL } from '@missionlabs/utils'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useExportContactsMutation, useLazyGetContactExportLogQuery } from 'shared/store'

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export function ContactsExport() {
    const { t } = useTranslation()
    const { toast } = useToast()
    const { colorMode } = useColorMode()

    const [loading, setLoading] = useState(false)
    const [initContactsExport] = useExportContactsMutation()
    const [getContactExportLog] = useLazyGetContactExportLogQuery()

    const downloadExportFile = (url: string) => {
        toast({ status: 'success', title: 'Export complete' })
        downloadURL(url)
    }

    const onExportContacts = async () => {
        setLoading(true)
        try {
            const contactExport = await initContactsExport().unwrap()

            /**
             * Poll the export status until it's ready or errored
             * 30 attempts with 10% exponential increment from initial 1s duration ~= 3 minutes total wait
             */
            for (let attempt = 0; attempt < 30; attempt++) {
                await wait(1000 * attempt * 1.1)
                const result = await getContactExportLog(contactExport.ID).unwrap()
                if (result.status === 'error') break
                if (result.status === 'ready') {
                    downloadExportFile(result.fileURL)
                    break
                }
            }
        } catch (e) {
            console.error('Failed to export contacts', e)
            toast({ status: 'error', title: 'Failed to export contacts' })
        } finally {
            setLoading(false)
        }
    }

    return (
        <SettingsRow label={t('globalSettings.exportContacts.title')} divider={false}>
            <VStack align="flex-start">
                <Body size="sm" variant="bold" color={`${colorMode}.tones.navy`}>
                    {t('globalSettings.exportContacts.description')}
                </Body>
                <Button
                    w="150px"
                    variant="creationary"
                    onClick={onExportContacts}
                    isLoading={loading}
                >
                    {t('globalSettings.exportContacts.title')}
                </Button>
            </VStack>
        </SettingsRow>
    )
}
