import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const PlainLogo: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'PlainLogo',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <g clipPath="url(#clip0_212_89)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.5044 18.183C2.16006 14.8308 2.13888 9.477 5.71944 5.8878C9.2802 2.31972 14.5556 2.31972 17.858 5.62986C18.8186 6.5928 19.4744 7.6722 19.8752 8.826L16.1144 12.5898C16.2608 11.286 15.7442 9.9582 14.7278 8.9412C12.9902 7.1988 10.2884 6.9414 8.48604 8.7468C6.62064 10.617 6.94284 13.1754 8.63604 14.8728C10.3742 16.6152 13.0766 16.8726 14.8784 15.0672C14.8838 15.0606 20.1476 9.7728 20.1476 9.7728C20.1488 9.7788 22.7372 7.203 22.7372 7.203C22.1624 5.91828 21.35 4.71324 20.2982 3.65814C15.6548 -0.99648 8.12664 -0.99648 3.48324 3.65814C-1.16058 8.3124 -1.16058 15.8585 3.48318 20.5127C4.53768 21.5699 5.74134 22.3841 7.02624 22.9607L8.80884 21.1733C9.62904 21.4127 10.4834 21.5417 11.3558 21.5417C12.4736 21.5417 13.5692 21.3287 14.6126 20.9393L11.5646 23.9951C14.7152 24.0809 17.8934 22.9229 20.2982 20.5127C22.6994 18.1055 23.855 14.9249 23.7728 11.7707L17.6924 17.8769C14.0846 21.4943 8.80824 21.4943 5.50464 18.1829L5.5044 18.183Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_212_89">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </g>
    )
})
