import { ButtonGroup, useColorMode } from '@chakra-ui/react'
import { Button } from '@missionlabs/react'
import { AnalyticsGroup } from '@missionlabs/types'
import { DatePeriod } from 'features/analytics/context/index'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import React from 'react'

interface Props {}

const groupMap: Record<DatePeriod, AnalyticsGroup[]> = {
    days: [],
    weeks: ['hour', 'day'],
    months: ['day', 'week'],
    custom: []
}

const AnalyticsGroupControls: React.FC<Props> = () => {
    const { period, group, setGroup } = useAnalytics()

    const { colorMode } = useColorMode()

    const groups = groupMap[period]

    if (!groups.length) return null

    return (
        <div>
            <ButtonGroup isAttached>
                {groups.map(_group => (
                    <Button
                        key={_group}
                        backgroundColor={`${colorMode}.tones.periwinkle`}
                        color={`${colorMode}.tones.stormGrey`}
                        _active={{
                            bg: `#fff`,
                            color: `${colorMode}.tones.midnightExpress`
                        }}
                        variant="outline"
                        isActive={group === _group}
                        onClick={() => setGroup(_group)}
                        textTransform="capitalize"
                    >
                        {_group}
                    </Button>
                ))}
            </ButtonGroup>
        </div>
    )
}

export default AnalyticsGroupControls
