import 'shared/components/Lexical/theme/index.css'

import { Box } from '@chakra-ui/react'
import {
    ChatRootState,
    resetNewMessages,
    selectNewMessagesForChannel,
    setSearching,
    setThreadIsOpen,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { Scrollable, useDebouncedFunction, useThrottledFunction } from '@missionlabs/react'
import { ChatMessage } from '@missionlabs/types'
import { useVirtualizer } from '@tanstack/react-virtual'
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDebounce, useUpdateEffect } from 'react-use'
import { isSection } from 'shared/hooks/useSectionTitle'
import { Section } from 'shared/types/feed'

import { Pagination } from '../ChatView'
import { useChatContext } from '../context/useChatContext'
import { ChatHistoryEmpty } from './ChatHistoryEmpty'
import { ChatHistoryLoading } from './ChatHistoryLoading'
import { ChatHistoryRenderer } from './ChatHistoryRenderer'
import ChatHistorySearch from './ChatHistorySearch'
import ForwardMessage from './ForwardMessage'
import { NewMessageFAB } from './NewMessageFAB'

export type VirtualisedChatHistoryItem = (ChatMessage & { section: Section }) | Section
export interface ChatHistoryProps {
    channelID: string
    siblingHeight: number
    isThread?: boolean
    messages: ChatMessage[]
    messagesLoading: boolean
    searchMessageResults?: ChatMessage[]
    setPagination: Dispatch<SetStateAction<Pagination>>
}

export const ChatHistory: FC<ChatHistoryProps> = ({
    channelID,
    siblingHeight,
    messages,
    messagesLoading,
    searchMessageResults,
    isThread = false,
    setPagination
}) => {
    const reset = useRef<boolean>()
    const boundaryID = useRef<string>()
    const direction = useRef<'prev' | 'next'>()

    const [searchSkipID, setSearchSkipID] = useState<string>()

    const { searchOpen } = useChatContext().search

    const [initialised, _setInitialised] = useState<boolean>(false)
    // This is debounced to ensure that it takes a minimum of 300ms to start the initialisation flow to prevent the loading UI disappearing too quickly.
    const setInitialised = useDebouncedFunction(_setInitialised, 300)

    const ref = useRef<OverlayScrollbarsComponentRef>(null)
    const { messageID, threadMessageID } = useParams()
    const dispatch = useDispatch()
    const location = useLocation()

    const [hasNewMessages, setHasNewMessages] = useState(false)
    const newMessages = useSelector((state: ChatRootState) => {
        return selectNewMessagesForChannel(state, channelID)
    })

    // This is debounced because if the message is in view we empty the newMessages array on the next render, so this prevents the notification flickering in the meantime.
    useDebounce(() => setHasNewMessages(!!newMessages.length), 200, [newMessages])

    const grouped = useMemo(() => {
        if (isThread) {
            const replies = [...messages]
            return [...replies] as Array<VirtualisedChatHistoryItem>
        }

        const sorted = [...messages].sort((_a, _b) => {
            const a = new Date(_a.created).valueOf()
            const b = new Date(_b.created).valueOf()

            // oldest to newest.
            return a - b
        })

        // This is not displayed, just gives us a way of starting the reduce off with a date that no data will fall within.
        const epoch = {
            object: 'section',
            startOfDay: new Date('01/01/1970').setUTCHours(0, 0, 0, 0).valueOf(),
            endOfDay: new Date('01/01/1970').setUTCHours(23, 59, 59, 999).valueOf()
        }

        let lastSection: Section = epoch

        return sorted.reduce<VirtualisedChatHistoryItem[]>((prev, curr) => {
            const date = new Date(curr.created)

            const created = date.valueOf()
            const startOfDay = date.setUTCHours(0, 0, 0, 0).valueOf()
            const endOfDay = date.setUTCHours(23, 59, 59, 999).valueOf()

            if (created >= lastSection.startOfDay && created <= lastSection.endOfDay) {
                // Within the current sections day, so just append data and inherit section info.
                prev.push({ ...curr, section: lastSection })
            } else {
                // Start of a new section, first appends section object and then data to maintain order.
                const section = { object: 'section', startOfDay, endOfDay }
                lastSection = section
                prev.push(section, { ...curr, section })
            }

            return prev
        }, [])
    }, [isThread, messages])

    function getScrollElement() {
        const instance = ref.current?.osInstance()
        return instance?.elements().viewport ?? null
    }

    const virtualiser = useVirtualizer({
        count: grouped.length || 1,
        overscan: 10, // This needs to be less than page size.
        getScrollElement,
        estimateSize: () => 53
    })

    // Clears cached messages and repositions the list from the most recent message. Also cleans up new message notifications.
    function resetPagination(pagination?: Partial<Pagination>) {
        setPagination({
            direction: 'next',
            channelID,
            size: 50,
            sort: 'desc',
            includeFirstMessage: true,
            clearCache: true,
            ...pagination
        })

        reset.current = true
        dispatch(resetNewMessages())
    }

    const _fetchMoreMessages = (
        direction: 'next' | 'prev',
        sort: 'asc' | 'desc',
        messageID: string
    ) => {
        setPagination({
            ...{
                direction,
                channelID,
                startFrom: messageID,
                size: 50,
                sort,
                reverse: direction !== 'next',
                includeFirstMessage: false
            }
        })
    }
    const fetchNextMessages = useThrottledFunction(_fetchMoreMessages, 500)
    const items = virtualiser.getVirtualItems()

    function nextMessagePredicate<T extends ChatMessage>(
        index: number,
        items: T[],
        loading: boolean,
        scrolling: boolean
    ) {
        if (index === items.length - 1 && !loading && scrolling) {
            const lastMessage = items[items.length - 1]

            boundaryID.current = lastMessage.ID
            direction.current = 'next'

            fetchNextMessages('next', 'asc', lastMessage.ID)
        }
    }

    function previousMessagePredicate<T extends ChatMessage>(
        index: number,
        items: T[],
        loading: boolean,
        scrolling: boolean
    ) {
        if (index === 0 && !loading && scrolling) {
            const _items = items.filter(item => !isSection(item))
            const firstMessage = _items[0]

            boundaryID.current = firstMessage.ID
            direction.current = 'prev'

            fetchNextMessages('prev', 'desc', firstMessage.ID)
        }
    }

    function loadMessages() {
        const [firstItem] = [...items]
        const [lastItem] = [...items].reverse()

        const end = virtualiser.scrollElement?.scrollTop || 0
        const notAtStartOrEnd = virtualiser.scrollOffset > 0 && virtualiser.scrollOffset < end

        if (!lastItem || !firstItem || notAtStartOrEnd) {
            return
        }

        nextMessagePredicate(
            lastItem.index,
            grouped as ChatMessage[],
            messagesLoading,
            virtualiser.isScrolling
        )
        previousMessagePredicate(
            firstItem.index,
            grouped as ChatMessage[],
            messagesLoading,
            virtualiser.isScrolling
        )
    }

    const onScrollInit = () => {
        if (!grouped.length) return
        if (searchSkipID) return

        if (messageID) {
            // Go to top of chat
            virtualiser.scrollToIndex(1, { align: 'start' })
        } else {
            // Go to bottom of chat
            virtualiser.scrollToIndex(grouped.length - 1)
        }

        setInitialised(true)
    }

    useEffect(() => {
        const searching = Boolean(messageID)
        dispatch(setSearching(searching))
    }, [dispatch, messageID])

    useEffect(() => {
        if (isThread && threadMessageID) dispatch(setThreadIsOpen(threadMessageID))
        else dispatch(setThreadIsOpen(false))
    }, [dispatch, isThread, threadMessageID])

    useEffect(() => {
        const [newMessage] = [...newMessages].reverse()
        if (newMessage) boundaryID.current = newMessage.ID
    }, [newMessages])

    // After a new set of messages are loaded, reposition list to the correct index.
    // This is required as we only show 'n' items at a time, so the list needs to reposition to the last index (what was at the top of the list when the last request was sent off) to keep consistency.
    // Without this, the list would jump up to however many items were provided by the request infront of where you would expect.
    useUpdateEffect(() => {
        // When going through the reset flow (i.e, resetPagination() is called), we force a scroll to the bottom of the list so pagination can resume as normal.
        if (reset.current) {
            virtualiser.scrollToIndex(grouped.length - 1, { align: 'end' })
            reset.current = false
            return
        }

        if (searchSkipID) {
            virtualiser.measure()
            const searchIndex = grouped.findIndex(message => {
                return !isSection(message) && message.ID === searchSkipID
            })
            virtualiser.scrollToIndex(searchIndex, { align: 'center' })
            return
        }

        const index = grouped.findIndex(message => {
            return !isSection(message) && message.ID === boundaryID.current
        })

        // Uses an index of 1 to prevent triggering this functionality on initial load. That has preset behaviour above.
        if (index < 1) return

        virtualiser.scrollToIndex(index, {
            align: direction.current === 'prev' ? 'start' : 'end'
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grouped, searchSkipID])

    // Load next or previous messages as the virtual list is rendered.
    useUpdateEffect(() => {
        // Checks if the lastest new message has been rendered as the last item in the list (i.e, has the message been seen). And then resets notifications accordingly.
        const lastItem = [...items].pop()
        const lastMessage = grouped[lastItem?.index || -1] as ChatMessage
        const newMessage = [...newMessages].pop()

        if (newMessage && lastMessage && newMessage.ID === lastMessage.ID) {
            dispatch(resetNewMessages())
            dispatch(setSearching(false))
        }

        loadMessages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])

    useUpdateEffect(() => {
        resetPagination({ startFrom: messageID })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const searchRef = useRef<HTMLDivElement>(null)

    if (!messages.length) return <ChatHistoryEmpty siblingHeight={siblingHeight} />

    return (
        <Box position="relative">
            {hasNewMessages && newMessages.length > 0 && (
                <NewMessageFAB count={newMessages.length} onClick={() => resetPagination()} />
            )}
            {!initialised && <ChatHistoryLoading />}

            {!!searchOpen && (
                <ChatHistorySearch
                    ref={searchRef}
                    goToMessage={(messageID: string) => {
                        dispatch(resetNewMessages())
                        setSearchSkipID(messageID)
                        setPagination({
                            direction: 'next',
                            channelID,
                            sort: 'desc',
                            startFrom: messageID,
                            size: 24,
                            offset: 25,
                            includeFirstMessage: true
                        })
                    }}
                    searchMessageResults={searchMessageResults}
                    onClose={() => {
                        setSearchSkipID(undefined)
                    }}
                />
            )}

            <Scrollable
                ref={ref}
                h={`calc(100dvh - ${siblingHeight}px - ${searchRef.current?.clientHeight ?? 0}px)`}
                w="full"
                mb="8px"
                events={{
                    initialized: () => {
                        virtualiser.measure()
                        onScrollInit()
                    }
                }}
                options={{ overflow: { x: 'hidden' } }}
                className="chat-history"
                position="relative"
            >
                <div
                    style={{
                        position: 'relative',
                        height: virtualiser.getTotalSize(),
                        width: '100%'
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            transform: `translateY(${items[0].start}px)`
                        }}
                    >
                        {items.map(virtualRow => (
                            <ChatHistoryRenderer
                                key={virtualRow.key}
                                ref={virtualiser.measureElement}
                                data={grouped}
                                index={virtualRow.index}
                                isThread={isThread}
                                searchSkipID={searchSkipID}
                            />
                        ))}
                    </div>
                </div>
            </Scrollable>
            <ForwardMessage />
        </Box>
    )
}
