import { MediaDevice } from '@missionlabs/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type SystemState = {
    devices: MediaDevice[]
}

const initialState: SystemState = {
    devices: []
}

export const systemSlice = createSlice({
    name: 'systemSlice',
    initialState,
    reducers: {
        updateDevices: (state, action: PayloadAction<any>) => {
            state.devices = action.payload
        }
    }
})

export type SystemSLiceRootState = {
    systemSlice: ReturnType<typeof systemSlice.getInitialState>
}

export const selectDevices = (state: SystemSLiceRootState) => state.systemSlice.devices

export const { updateDevices } = systemSlice.actions
