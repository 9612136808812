import { Box, HStack, Skeleton, useColorMode, VStack } from '@chakra-ui/react'
import { Avatar } from '@missionlabs/react'
import { FC } from 'react'

export interface UnreadThreadsSkeletonProps {}

export const UnreadThreadsSkeleton: FC<UnreadThreadsSkeletonProps> = () => {
    const { colorMode } = useColorMode()
    return (
        <Box
            data-testid="chat-unreadthreads-skeleton"
            minWidth={'1000px'}
            style={{ position: 'relative' }}
            borderBottom={'1px solid'}
            borderColor={`${colorMode}.tones.periwinkle`}
            w="full"
            pt="24px"
            pb="30px"
        >
            <HStack spacing="8px" ml={'24px'} mb={'16px'}>
                <RowSkeleton size="md" />
            </HStack>
            <HStack
                spacing="8px"
                ml="24px"
                w="full"
                mt="12px"
                px="4px"
                borderColor={`${colorMode}.tones.periwinkle`}
                data-testid="chat-item-skeleton"
            >
                <RowSkeleton size="sm" />
            </HStack>
            <HStack
                spacing="8px"
                ml="40px"
                w="full"
                py="12px"
                mt="12px"
                px="8px"
                borderColor={`${colorMode}.tones.periwinkle`}
                data-testid="chat-item-skeleton"
            >
                <RowSkeleton size="sm" />
            </HStack>
        </Box>
    )
}
export interface RowSkeletonProps {
    size: 'sm' | 'md'
}

const RowSkeleton: FC<RowSkeletonProps> = ({ size }) => {
    return (
        <>
            <Avatar size={size} />
            <VStack spacing="3.5px" align="start">
                <Skeleton width="130px" height="14px" />
                <Skeleton width="90px" height="14px" />
                <Skeleton width="75px" height="14px" />
            </VStack>
        </>
    )
}
