import { CodeNode } from '@lexical/code'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import {
    ElementTransformer,
    ORDERED_LIST,
    TEXT_FORMAT_TRANSFORMERS,
    TEXT_MATCH_TRANSFORMERS,
    TextFormatTransformer,
    TextMatchTransformer,
    UNORDERED_LIST
} from '@lexical/markdown'
import { MarkdownShortcutPlugin as LexicalMarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { $isTextNode, Klass, LexicalNode, TextNode } from 'lexical'
import { FC } from 'react'

import { AutoLinkPlugin } from './AutoLinkPlugin'
import { ListMaxIndentLevelPlugin } from './ListMaxIndentLevelPlugin'

const UNDERLINE: TextMatchTransformer = {
    dependencies: [TextNode],
    export: node => {
        if (!$isTextNode(node) || node.getFormat() !== 8) return null
        return `<u>${node.getTextContent()}</u>`
    },
    importRegExp: /<u*>.*?<\/u>/,
    regExp: /<u*>.*?<\/u>/,
    replace: textNode => {
        // strip html tags from text content
        const sanitized = textNode.getTextContent().replace(/(<([^>]+)>)/gi, '')
        textNode.setTextContent(sanitized)
        // apply formatting
        textNode.setFormat('underline')
    },
    trigger: '>',
    type: 'text-match'
}

type MarkdownTransformer = ElementTransformer | TextFormatTransformer | TextMatchTransformer

export const MarkdownTransformers: MarkdownTransformer[] = [
    UNDERLINE,
    UNORDERED_LIST,
    ORDERED_LIST,
    ...TEXT_FORMAT_TRANSFORMERS,
    ...TEXT_MATCH_TRANSFORMERS
]

export const MarkdownShortcutNodes: Klass<LexicalNode>[] = [
    CodeNode,
    LinkNode,
    AutoLinkNode,
    ListNode,
    ListItemNode
]

export interface MarkdownShortcutPluginProps {}

export const MarkdownShortcutPlugin: FC<MarkdownShortcutPluginProps> = () => {
    return (
        <>
            <AutoLinkPlugin />
            <ListMaxIndentLevelPlugin />
            <LexicalMarkdownShortcutPlugin transformers={MarkdownTransformers} />
        </>
    )
}
