import { selectCalls, useSelector } from '@missionlabs/api'
import { useCallback } from 'react'

export const useCallLookup = () => {
    const calls = useSelector(selectCalls)

    return useCallback(
        (callTraceID?: string) => {
            return calls.find(call => call.callTraceID === callTraceID)
        },
        [calls]
    )
}
