import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const BlockedIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'BlockedIcon',
    viewBox: '0 0 84 84',
    defaultProps: {
        boxSize: '84px'
    },
    path: (
        <g stroke="white">
            <path
                d="M22.201 59.799C33.1357 70.7337 50.8643 70.7337 61.799 59.799C72.7337 48.8643 72.7337 31.1357 61.799 20.201C50.8643 9.26632 33.1357 9.26632 22.201 20.201C11.2663 31.1357 11.2663 48.8643 22.201 59.799ZM28.0936 26.0936C34.7627 19.4245 45.032 18.5458 52.6478 23.4597L25.4598 50.6477C20.5459 43.0319 21.4245 32.7626 28.0936 26.0936ZM55.9064 53.9064C49.2374 60.5755 38.968 61.4541 31.3523 56.5402L58.5403 29.3522C63.4542 36.968 62.5755 47.2373 55.9064 53.9064Z"
                stroke="white"
                strokeWidth="4"
            />
            <path
                d="M60.3848 58.3848C50.2311 68.5384 33.7689 68.5384 23.6152 58.3848C13.4616 48.2311 13.4616 31.7689 23.6152 21.6152C33.7689 11.4616 50.2311 11.4616 60.3848 21.6152C70.5384 31.7689 70.5384 48.2311 60.3848 58.3848ZM25.223 53.7129L55.713 23.2229C47.2021 16.2493 34.6236 16.7352 26.6794 24.6794C18.7352 32.6236 18.2494 45.202 25.223 53.7129ZM57.3206 55.3206C49.3764 63.2648 36.798 63.7506 28.2871 56.777L58.7771 26.287C65.7507 34.7979 65.2648 47.3764 57.3206 55.3206Z"
                fill="#DF0080"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </g>
    )
})
