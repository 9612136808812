import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const InformationIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'InformationIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 18.75C5.15625 18.75 1.25 14.8438 1.25 10C1.25 5.19531 5.15625 1.25 10 1.25C14.8047 1.25 18.75 5.19531 18.75 10C18.75 14.8438 14.8047 18.75 10 18.75ZM10 7.1875C10.5078 7.1875 10.9375 6.79688 10.9375 6.25C10.9375 5.74219 10.5078 5.3125 10 5.3125C9.45312 5.3125 9.0625 5.74219 9.0625 6.25C9.0625 6.79688 9.45312 7.1875 10 7.1875ZM11.875 13.75H10.625V9.375C10.625 9.0625 10.3125 8.75 10 8.75H8.75C8.39844 8.75 8.125 9.0625 8.125 9.375C8.125 9.72656 8.39844 10 8.75 10H9.375V13.75H8.125C7.77344 13.75 7.5 14.0625 7.5 14.375C7.5 14.7266 7.77344 15 8.125 15H11.875C12.1875 15 12.5 14.7266 12.5 14.375C12.5 14.0625 12.1875 13.75 11.875 13.75Z"
            fill="currentColor"
        />
    )
})

export const InformationIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'InformationIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 18.125C5.50781 18.125 1.875 14.4922 1.875 10C1.875 5.54688 5.50781 1.875 10 1.875C14.4531 1.875 18.125 5.54688 18.125 10C18.125 14.4922 14.4531 18.125 10 18.125ZM11.5625 13.125H10.9375V9.6875C10.9375 9.17969 10.5078 8.75 10 8.75H8.75C8.20312 8.75 7.8125 9.17969 7.8125 9.6875C7.8125 10.2344 8.20312 10.625 8.75 10.625H9.0625V13.125H8.4375C7.89062 13.125 7.5 13.5547 7.5 14.0625C7.5 14.6094 7.89062 15 8.4375 15H11.5625C12.0703 15 12.5 14.6094 12.5 14.0625C12.5 13.5547 12.0703 13.125 11.5625 13.125ZM10 7.5C10.6641 7.5 11.25 6.95312 11.25 6.25C11.25 5.58594 10.6641 5 10 5C9.29688 5 8.75 5.58594 8.75 6.25C8.75 6.95312 9.29688 7.5 10 7.5Z"
            fill="currentColor"
        />
    )
})

export const InformationIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'InformationIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0ZM10 5C10.6641 5 11.25 5.58594 11.25 6.25C11.25 6.95312 10.6641 7.5 10 7.5C9.29688 7.5 8.75 6.95312 8.75 6.25C8.75 5.58594 9.29688 5 10 5ZM11.5625 15H8.4375C7.89062 15 7.5 14.6094 7.5 14.0625C7.5 13.5547 7.89062 13.125 8.4375 13.125H9.0625V10.625H8.75C8.20312 10.625 7.8125 10.2344 7.8125 9.6875C7.8125 9.17969 8.20312 8.75 8.75 8.75H10C10.5078 8.75 10.9375 9.17969 10.9375 9.6875V13.125H11.5625C12.0703 13.125 12.5 13.5547 12.5 14.0625C12.5 14.6094 12.0703 15 11.5625 15Z"
            fill="currentColor"
        />
    )
})
