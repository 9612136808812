import { HStack, SystemStyleObject, useMultiStyleConfig } from '@chakra-ui/react'
import { Body, Button, MenuHeader } from '@missionlabs/react'
import { ArrowDownIcon } from '@missionlabs/react/circleloop'
import { UserStatus } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface GlobalNavHeaderProps {
    fullName: string
    status: string
    onToggle: () => void
    statusType?: UserStatus
}

export const GlobalNavHeader: FC<GlobalNavHeaderProps> = ({
    fullName,
    status,
    statusType,
    onToggle
}) => {
    const { t } = useTranslation()

    const styles = useMultiStyleConfig('AvatarBadge', { variant: statusType })

    return (
        <MenuHeader>
            <Body>{fullName}</Body>
            <HStack spacing="8px">
                <Body size="sm">{t('nav.status')}</Body>
                <Button
                    variant="transparent"
                    sx={{
                        p: 0,
                        h: 'auto',
                        color: styles.bg as SystemStyleObject['color'],
                        _hover: {}
                    }}
                    onClick={onToggle}
                    rightIcon={<ArrowDownIcon color="inherit" />}
                    textTransform="capitalize"
                >
                    {status}
                </Button>
            </HStack>
        </MenuHeader>
    )
}
