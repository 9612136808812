import { Table } from '@missionlabs/react'
import { AnalyticsCallGroup, AnalyticsNumber, AnalyticsUser } from '@missionlabs/types'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { AnalyticsTableDataType, useAnalyticsTable } from './useAnalyticsTableColumns'

type AnalyticsTableProps = {
    usersData?: AnalyticsUser[]
    callGroupsData?: AnalyticsCallGroup[]
    numbersData?: AnalyticsNumber[]
}

export const AnalyticsTable: FC<AnalyticsTableProps> = ({
    usersData,
    callGroupsData,
    numbersData
}) => {
    const { data, columns } = useAnalyticsTable({ usersData, callGroupsData, numbersData })

    const navigate = useNavigate()
    const onRowClicked = (data: AnalyticsTableDataType) => {
        if ('teamID' in data) {
            return navigate(data.teamID, { state: { teamName: data.name } })
        }

        if ('userID' in data) {
            return navigate(data.userID, { state: { username: data.fullName } })
        }

        navigate(data.numberE164)
    }

    return <Table data={data} columns={columns} paginate onRowClicked={onRowClicked} />
}
