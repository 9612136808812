import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const XIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'XIconLight',
    viewBox: '0 0 14 14',
    path: (
        <path
            d="M13.0742 13.0742C12.8398 13.3086 12.4102 13.3086 12.1758 13.0742L7.01953 7.87891L1.82422 13.0742C1.58984 13.3086 1.16016 13.3086 0.925781 13.0742C0.691406 12.8398 0.691406 12.4102 0.925781 12.1758L6.12109 6.98047L0.925781 1.82422C0.691406 1.58984 0.691406 1.16016 0.925781 0.925781C1.16016 0.691406 1.58984 0.691406 1.82422 0.925781L7.01953 6.12109L12.1758 0.925781C12.4102 0.691406 12.8398 0.691406 13.0742 0.925781C13.3086 1.16016 13.3086 1.58984 13.0742 1.82422L7.87891 6.98047L13.0742 12.1758C13.3086 12.4102 13.3086 12.8398 13.0742 13.0742Z"
            fill="currentColor"
        />
    )
})

export const XIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'XIconRegular',
    viewBox: '0 0 14 14',
    path: (
        <path
            d="M12.957 11.6484C13.3086 12.0391 13.3086 12.625 12.957 12.9766C12.5664 13.3672 11.9805 13.3672 11.6289 12.9766L7.01953 8.32812L2.37109 12.9766C1.98047 13.3672 1.39453 13.3672 1.04297 12.9766C0.652344 12.625 0.652344 12.0391 1.04297 11.6484L5.69141 7L1.04297 2.35156C0.652344 1.96094 0.652344 1.375 1.04297 1.02344C1.39453 0.632812 1.98047 0.632812 2.33203 1.02344L7.01953 5.71094L11.668 1.0625C12.0195 0.671875 12.6055 0.671875 12.957 1.0625C13.3477 1.41406 13.3477 2 12.957 2.39062L8.30859 7L12.957 11.6484Z"
            fill="currentColor"
        />
    )
})

export const XIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'XIconSolid',
    viewBox: '0 0 14 14',
    path: (
        <path
            d="M12.8789 11.1357C13.3867 11.6045 13.3867 12.4248 12.8789 12.8936C12.6445 13.1279 12.332 13.2451 12.0195 13.2451C11.668 13.2451 11.3555 13.1279 11.1211 12.8936L7.01953 8.79199L2.87891 12.8936C2.64453 13.1279 2.33203 13.2451 2.01953 13.2451C1.66797 13.2451 1.35547 13.1279 1.12109 12.8936C0.613281 12.4248 0.613281 11.6045 1.12109 11.1357L5.22266 6.99512L1.12109 2.89355C0.613281 2.4248 0.613281 1.60449 1.12109 1.13574C1.58984 0.62793 2.41016 0.62793 2.87891 1.13574L7.01953 5.2373L11.1211 1.13574C11.5898 0.62793 12.4102 0.62793 12.8789 1.13574C13.3867 1.60449 13.3867 2.4248 12.8789 2.89355L8.77734 7.03418L12.8789 11.1357Z"
            fill="currentColor"
        />
    )
})
