import { ActionImpl } from 'kbar'

import { ResultGroupHeader } from './ResultGroupHeader'
import { ResultItem } from './ResultItem'
import { RenderParams } from './types'

export function filterUniqueResults(results: Array<string | ActionImpl>) {
    // Map of actions or group headers, keyed by their ID
    const unique = new Map<string, string | ActionImpl>()

    for (const result of results) {
        const id = typeof result === 'string' ? result : result.id
        if (!unique.has(id)) {
            unique.set(id, result)
        }
    }

    return [...unique.values()]
}

export function renderResultItem(
    { item, active }: RenderParams<string | ActionImpl>,
    rootActionId?: string | null
) {
    // If the item is a string, it's a section header
    if (typeof item === 'string') return <ResultGroupHeader label={item} />

    return <ResultItem action={item} active={active} currentRootActionId={rootActionId} />
}
