import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const LogoutIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'LogoutIconLight',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M19.8047 8.57031L14.1797 2.94531C13.9453 2.71094 13.5156 2.71094 13.2812 2.94531C13.0469 3.17969 13.0469 3.60938 13.2812 3.84375L17.8516 8.375H6.875C6.52344 8.375 6.25 8.6875 6.25 9C6.25 9.35156 6.52344 9.625 6.875 9.625H17.8516L13.2812 14.1953C13.0469 14.4297 13.0469 14.8594 13.2812 15.0938C13.5156 15.3281 13.9453 15.3281 14.1797 15.0938L19.8047 9.46875C19.9219 9.35156 20 9.19531 20 9C20 8.84375 19.9219 8.6875 19.8047 8.57031ZM6.875 16.5H3.125C2.07031 16.5 1.25 15.6797 1.25 14.625V3.375C1.25 2.35938 2.07031 1.5 3.125 1.5H6.875C7.1875 1.5 7.5 1.22656 7.5 0.875C7.5 0.5625 7.1875 0.25 6.875 0.25H3.125C1.36719 0.25 0 1.65625 0 3.375V14.625C0 16.3828 1.36719 17.75 3.125 17.75H6.875C7.1875 17.75 7.5 17.4766 7.5 17.125C7.5 16.8125 7.1875 16.5 6.875 16.5Z"
            fill="currentColor"
        />
    )
})

export const LogoutIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'LogoutIconRegular',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M7.5094 16.8125C7.5094 17.3594 7.07971 17.75 6.5719 17.75H3.7594C1.65002 17.75 0.00939941 16.1094 0.00939941 14V4C0.00939941 1.92969 1.65002 0.25 3.7594 0.25H6.5719C7.07971 0.25 7.5094 0.679688 7.5094 1.1875C7.5094 1.73438 7.07971 2.125 6.5719 2.125H3.7594C2.70471 2.125 1.8844 2.98438 1.8844 4V14C1.8844 15.0547 2.70471 15.875 3.7594 15.875H6.5719C7.07971 15.875 7.5094 16.3047 7.5094 16.8125ZM19.736 8.375L14.775 3.0625C14.4235 2.67188 13.8375 2.67188 13.4469 3.02344C13.0563 3.375 13.0563 3.96094 13.4078 4.35156L16.8844 8.0625H7.15784C6.65002 8.0625 6.2594 8.49219 6.2594 9C6.2594 9.54688 6.65002 9.9375 7.15784 9.9375H16.8453L13.3297 13.6875C12.9781 14.0781 12.9781 14.6641 13.3688 15.0156C13.6031 15.1719 13.8375 15.25 14.0328 15.25C14.2672 15.25 14.5016 15.1719 14.6969 14.9766L19.6578 9.66406C20.0875 9.3125 20.0875 8.72656 19.736 8.375Z"
            fill="currentColor"
        />
    )
})

export const LogoutIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'LogoutIconSolid',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M6.25488 15.25H3.75488C3.05176 15.25 2.50488 14.7031 2.50488 14V4C2.50488 3.33594 3.05176 2.75 3.75488 2.75H6.25488C6.91895 2.75 7.50488 2.20312 7.50488 1.5C7.50488 0.835938 6.91895 0.25 6.25488 0.25H3.75488C1.64551 0.25 0.00488281 1.92969 0.00488281 4V14C0.00488281 16.1094 1.64551 17.75 3.75488 17.75H6.25488C6.91895 17.75 7.50488 17.2031 7.50488 16.5C7.50488 15.8359 6.91895 15.25 6.25488 15.25ZM19.6143 8.14062L14.6143 3.14062C14.1455 2.63281 13.3252 2.63281 12.8564 3.14062C12.3486 3.60938 12.3486 4.42969 12.8564 4.89844L15.708 7.75H7.50488C6.80176 7.75 6.25488 8.33594 6.25488 9C6.25488 9.70312 6.80176 10.25 7.50488 10.25H15.708L12.8564 13.1406C12.3486 13.6094 12.3486 14.4297 12.8564 14.8984C13.3252 15.4062 14.1455 15.4062 14.6143 14.8984L19.6143 9.89844C20.1221 9.42969 20.1221 8.60938 19.6143 8.14062Z"
            fill="currentColor"
        />
    )
})
