import { useDisclosure } from '@chakra-ui/react'
import {
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay
} from '@missionlabs/react'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDeleteHuntGroupMutation, useGetHuntGroupQuery } from 'shared/store'

export const DeleteTeamDialog: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { id = '' } = useParams()

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    const ref = useRef<HTMLButtonElement>(null)

    const { currentData: team } = useGetHuntGroupQuery(id, { skip: !id || !isOpen })
    const [deleteTeam, { isLoading }] = useDeleteHuntGroupMutation()

    async function handleDelete() {
        try {
            if (!team) return
            await deleteTeam(team.ID).unwrap()
            return onClose()
        } catch (e) {
            console.error(e)
        }
    }

    function handleTransition() {
        navigate(`/admin/teams/${id}/edit`)
    }

    return (
        <Dialog
            leastDestructiveRef={ref}
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={handleTransition}
        >
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>{t('admin.teams.delete.header')}</DialogHeader>
                <DialogBody>{t('admin.teams.delete.body', { name: team?.name })}</DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('admin.teams.delete.confirm'),
                        isDestructive: true,
                        onClick: handleDelete
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
