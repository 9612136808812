import { useColorMode } from '@chakra-ui/react'
import { PieCustomLayerProps } from '@nivo/pie'
import { useMemo } from 'react'

import { AnalyticsPieChartDatum } from './AnalyticsPieChart'

export const CenteredMetric = ({
    dataWithArc,
    centerX,
    centerY
}: PieCustomLayerProps<AnalyticsPieChartDatum>) => {
    const { colorMode } = useColorMode()

    const percentage = useMemo(() => {
        const datum = dataWithArc.find(item => item.id !== 'remainder')?.value ?? 0
        const remainder = dataWithArc.find(item => item.id === 'remainder')?.value ?? 0

        const total = datum + remainder

        if (total) {
            return Math.round((datum / total) * 100)
        }
        return 0
    }, [dataWithArc])

    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fontSize: '14px',
                fontWeight: 400,
                color: `${colorMode}.tones.navy`
            }}
        >
            {percentage}%
        </text>
    )
}
