import { Center, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Button, Heading } from '@missionlabs/react'
import { Logo } from '@missionlabs/react/circleloop'
import { CLOSE_TIMEOUT } from 'features/meetings/hooks/useVideoCall'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const IS_GUEST_APP = process.env.REACT_APP_GUEST === 'true'

export enum VideoCallEndReason {
    ended = 'ended',
    rejected = 'rejected',
    cancelled = 'cancelled',
    disconnected = 'disconnected',
    error = 'error'
}

function formatTime(value: number): number {
    return Math.ceil(value / 1000)
}

export interface VideoCallEndedProps {
    reason: VideoCallEndReason
    message?: string
}

export const VideoCallEnded: FC<VideoCallEndedProps> = ({ reason, message }) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    const [timeLeft, setTimeLeft] = useState(formatTime(CLOSE_TIMEOUT))

    const { heading, description } = useMemo(() => {
        if (reason === VideoCallEndReason.disconnected)
            return {
                heading: t('meetings.callEnded.disconnected.reason'),
                description: t('meetings.callEnded.disconnected.description')
            }

        return {
            heading: t(`meetings.callEnded.${reason}.reason`),
            description: message
        }
    }, [reason, message, t])

    useEffect(() => {
        if (IS_GUEST_APP) return

        const newTime = timeLeft - 1
        if (newTime < 0) return window.close()
        const t = setTimeout(() => setTimeLeft(newTime), 1000)

        return () => {
            clearTimeout(t)
        }
    }, [timeLeft])

    return (
        <Center w="100dvw" h="100dvh" bg={`${colorMode}.tones.midnightExpress`}>
            <VStack spacing="16px" p="24px">
                <VStack spacing="24px">
                    <Logo boxSize="48px" color={`${colorMode}.alerts.red`} />
                    <VStack spacing={0}>
                        <Heading size="h3" variant="bold" color="white" fontSize="16px">
                            {heading}
                        </Heading>
                        {description && (
                            <Body size="sm" sx={{ color: `${colorMode}.tones.periwinkle` }}>
                                {description}
                            </Body>
                        )}
                        <Body size="sm" sx={{ color: `${colorMode}.tones.periwinkle` }}>
                            {IS_GUEST_APP
                                ? t('meetings.callEnded.safeToClose')
                                : t('meetings.callEnded.closeTimer', {
                                      timeLeft: timeLeft.toString()
                                  })}
                        </Body>
                    </VStack>
                </VStack>
                <Button variant="negatory" onClick={() => window.close()}>
                    {t('meetings.callEnded.closeNow')}
                </Button>
            </VStack>
        </Center>
    )
}
