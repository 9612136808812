import { Box, Flex } from '@chakra-ui/react'
import {
    Button,
    Dropdown,
    Input,
    Player,
    SelectOption,
    useFormContext,
    ValidatedInput
} from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { getTimeoutOptions } from 'shared/components/OutOfHours/timeoutOptions'

import { AddEditTeamFields } from './AddEditTeamForm'

export interface UnansweredOptionsProps {
    mode: 'audioclip' | 'internal' | 'numberE164'
    name?: string
    options?: SelectOption[]
}

export const UnansweredOptions: FC<UnansweredOptionsProps> = ({ mode, name, options }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { setValue, watch } = useFormContext<AddEditTeamFields>()
    const display = watch(`${name}.display` as keyof AddEditTeamFields)

    // A little bit jank as name could be other values, but its constrained to mode === "audioclip" components
    const greetingURL = watch(name as keyof AddEditTeamFields) as string

    function handleChange(name: any, value: any) {
        setValue(name, value, { shouldDirty: true })
    }

    function handleClick(drawer: 'assign' | 'upload') {
        navigate(`${location.pathname}/${drawer}`, { state: name })
    }

    return (
        <Flex
            flexWrap="wrap"
            gap="16px"
            align="start"
            pl="24px"
            sx={{ ':not(:last-child)': { pb: '8px' } }}
        >
            <ValidatedInput maxW="196px" name="callTimeout" shouldRegister={false}>
                <Dropdown
                    options={getTimeoutOptions(t)}
                    value={watch('callTimeout')}
                    placeholder={t('Select')}
                    onChange={value => handleChange('callTimeout', value)}
                />
            </ValidatedInput>
            {mode === 'audioclip' && (
                <>
                    <ValidatedInput flex={1} name={name} shouldRegister={false}>
                        <Dropdown
                            options={options}
                            value={greetingURL}
                            onChange={value => handleChange(name, value)}
                        />
                    </ValidatedInput>
                    <Button variant="creationary" onClick={() => handleClick('upload')}>
                        {t('Upload New')}
                    </Button>
                    {!!greetingURL && (
                        <Box w="100%" alignItems="flex-start" id="voicemail-player">
                            <Player url={greetingURL} width="100%" />
                        </Box>
                    )}
                </>
            )}
            {mode === 'internal' && (
                <>
                    {!display ? (
                        <Button w="196px" variant="primary" onClick={() => handleClick('assign')}>
                            {t('calls.selectUTM')}
                        </Button>
                    ) : (
                        <ValidatedInput
                            name={`${name}.display`}
                            shouldRegister={false}
                            onClick={() => handleClick('assign')}
                        >
                            <Input isReadOnly cursor="pointer" value={display as string} />
                        </ValidatedInput>
                    )}
                </>
            )}
            {mode === 'numberE164' && (
                <ValidatedInput name={`${name}.numberE164`} maxW="60%">
                    <Input placeholder={t('Enter number')} />
                </ValidatedInput>
            )}
        </Flex>
    )
}
