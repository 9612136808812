import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const CalendarDayIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CalendarDayIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M11.125 12C11.4375 12 11.75 12.3125 11.75 12.625V16.375C11.75 16.7266 11.4375 17 11.125 17H7.375C7.02344 17 6.75 16.7266 6.75 16.375V12.625C6.75 12.3125 7.02344 12 7.375 12H11.125ZM8 2C8.50781 2 8.9375 2.42969 8.9375 2.9375V4.5H14.5625V2.9375C14.5625 2.42969 14.9531 2 15.5 2C16.0078 2 16.4375 2.42969 16.4375 2.9375V4.5H18C19.3672 4.5 20.5 5.63281 20.5 7V19.5C20.5 20.9062 19.3672 22 18 22H5.5C4.09375 22 3 20.9062 3 19.5V7C3 5.63281 4.09375 4.5 5.5 4.5H7.0625V2.9375C7.0625 2.42969 7.45312 2 8 2ZM18.625 9.5H4.875V19.5C4.875 19.8516 5.14844 20.125 5.5 20.125H18C18.3125 20.125 18.625 19.8516 18.625 19.5V9.5Z"
            fill="currentColor"
        />
    )
})

export const CalendarIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CalendarDayIcon',
    viewBox: '0 0 18 20',
    path: (
        <path
            d="M6.1875 2.5H11.8125V0.9375C11.8125 0.429688 12.2031 0 12.75 0C13.2578 0 13.6875 0.429688 13.6875 0.9375V2.5H15.25C16.6172 2.5 17.75 3.63281 17.75 5V17.5C17.75 18.9062 16.6172 20 15.25 20H2.75C1.34375 20 0.25 18.9062 0.25 17.5V5C0.25 3.63281 1.34375 2.5 2.75 2.5H4.3125V0.9375C4.3125 0.429688 4.70312 0 5.25 0C5.75781 0 6.1875 0.429688 6.1875 0.9375V2.5ZM2.125 17.5C2.125 17.8516 2.39844 18.125 2.75 18.125H15.25C15.5625 18.125 15.875 17.8516 15.875 17.5V7.5H2.125V17.5Z"
            fill="currentColor"
        />
    )
})
