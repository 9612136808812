export enum Activity {
    CALL_MADE = 'call_made',
    INTERNAL_CALL_MADE = 'internal_call_made',

    CALL_MISSED = 'call_missed',
    INTERNAL_CALL_MISSED = 'internal_call_missed',

    CALL_RECEIVED = 'call_received',
    INTERNAL_CALL_RECEIVED = 'internal_call_received',

    /** Voicemail */
    MESSAGE_RECEIVED = 'message_received',
    /** Internal Voicemail */
    INTERNAL_MESSAGE_RECEIVED = 'internal_message_received',

    INTERNAL_MEETING_MADE = 'internal_meeting_made',
    INTERNAL_MEETING_MISSED = 'internal_meeting_missed',
    INTERNAL_MEETING_RECEIVED = 'internal_meeting_received',
    INTERNAL_MEETING_REJECTED = 'internal_meeting_rejected',

    USER_CREATED = 'user_created',
    USER_DELETED = 'user_deleted',
    TEAM_CREATED = 'team_created',
    TEAM_DELETED = 'team_deleted',
    MENU_CREATED = 'menu_created',
    MENU_DELETED = 'menu_deleted',
    CLIENTADDON_CREATED = 'clientaddon_created',
    CLIENTADDON_DELETED = 'clientaddon_deleted',
    NUMBER_ASSOCIATED = 'number_associated',
    NUMBER_UNASSOCIATED = 'number_unassociated',

    /** SMS Received */
    CHAT_MESSAGE_RECEIVED = 'chat_message_received',
    /** SMS Sent */
    CHAT_MESSAGE_SENT = 'chat_message_sent',

    WHATSAPP_MESSAGE_RECEIVED = 'whatsapp_message_received',
    WHATSAPP_MESSAGE_SENT = 'whatsapp_message_sent',
    /** Chat */
    CHAT_CHANNEL_MESSAGE_RECEIVED = 'chat_channel_message_received',

    /*
     * EMAIL:
     * Activity types not yet confirmed, so leave them for now
     * TODO: Replace with actual activity types
     */
    DO_NOT_USE__EMAIL_RECEIVED = 'email_received',
    DO_NOT_USE__EMAIL_SENT = 'email_sent'
}

export const ALL_ACTIVITIES = [
    Activity.CALL_MADE,
    Activity.CALL_MISSED,
    Activity.CALL_RECEIVED,
    Activity.MESSAGE_RECEIVED,
    Activity.INTERNAL_CALL_MADE,
    Activity.INTERNAL_CALL_MISSED,
    Activity.INTERNAL_CALL_RECEIVED,
    Activity.INTERNAL_MESSAGE_RECEIVED,
    Activity.INTERNAL_MEETING_MADE,
    Activity.INTERNAL_MEETING_MISSED,
    Activity.INTERNAL_MEETING_RECEIVED,
    Activity.INTERNAL_MEETING_REJECTED,
    Activity.CHAT_MESSAGE_RECEIVED,
    Activity.CHAT_MESSAGE_SENT,
    Activity.WHATSAPP_MESSAGE_RECEIVED,
    Activity.WHATSAPP_MESSAGE_SENT
] as const

export const ALL_CLIENT_ACTIVITIES = [
    Activity.USER_CREATED,
    Activity.USER_DELETED,
    Activity.TEAM_CREATED,
    Activity.TEAM_DELETED,
    Activity.MENU_CREATED,
    Activity.MENU_DELETED,
    Activity.CLIENTADDON_CREATED,
    Activity.CLIENTADDON_DELETED,
    Activity.NUMBER_ASSOCIATED,
    Activity.NUMBER_UNASSOCIATED
] as const

export type ActivityDirection = 'inbound' | 'outbound' | undefined

export enum UnreadActivity {
    MISSED_CALLS = 'missedCalls',
    VOICEMAILS = 'voicemails',
    SMS = 'smsMessages',
    WHATSAPPS = 'whatsApps',
    // EMAILS = 'emails',
    FLAGGED = 'flagged'
}

export const ALL_UNREAD_ACTIVITIES = [
    UnreadActivity.MISSED_CALLS,
    UnreadActivity.VOICEMAILS,
    UnreadActivity.SMS,
    UnreadActivity.WHATSAPPS,
    // UnreadActivity.EMAILS
    UnreadActivity.FLAGGED
] as const

/**
 * For some ungodly reason, the API calls the "from" date "sinceTime" and the "to" date "fromTime".
 * This is incredibly confusing when trying to work with, so we're going to alias them here.
 */
export enum DateFilterParam {
    FROM = 'sinceTime',
    TO = 'fromTime'
}
