import { Notification } from '@missionlabs/react'
import { TeamVmNotificationPayload } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationProps } from 'shared/components/Notifications/types'

export const VoicemailNotification: FC<NotificationProps<TeamVmNotificationPayload>> = ({
    call,
    onClose
}) => {
    const { t } = useTranslation()

    const onDismiss = () => {
        if (!call) return
        onClose(call.callTraceID)
    }

    // todo
    const onVoicemail = () => {}

    if (!call) return null

    return (
        <Notification
            variant="voicemail"
            header={t('notifications.voicemail')}
            name={call.contactFullName}
            role={call.companyName}
            via={call.teamName}
            onVoicemail={onVoicemail}
            onDismiss={onDismiss}
        />
    )
}
