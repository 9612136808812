import { HStack, useColorMode, useDisclosure, VStack } from '@chakra-ui/react'
import {
    Body,
    Button,
    Dropdown,
    Player,
    Toggle,
    useFormContext,
    ValidatedInput
} from '@missionlabs/react'
import { Client } from '@missionlabs/types'
import { RecordingDrawer } from 'features/settings/components/RecordingDrawer'
import { SettingsRow } from 'features/settings/components/SettingsRow'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAllClientAudioClips } from 'shared/hooks/useAllAudioClips'
import { useUploadRecording } from 'shared/hooks/useUploadRecording'
import { useGetClientQuery, useUpdateClientMutation } from 'shared/store'

type URLTypes = 'customHoldMusicURL' | 'customWaitMusicURL'

export const CompanyMusicForm: FC = () => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()
    const recordingDrawer = useDisclosure()
    const { data: client } = useGetClientQuery()
    const [updateClient] = useUpdateClientMutation()
    const uploadRecording = useUploadRecording()
    const [drawerType, setDrawerType] = useState<URLTypes>()

    const { watch, setValue } = useFormContext()

    const { customHoldMusicURL = '', customWaitMusicURL = '' } = watch()

    const allClientAudioClips = useAllClientAudioClips()

    const allClientAudioClipsWithDefault = useMemo(() => {
        return [{ label: t('Default'), value: '' }, ...allClientAudioClips]
    }, [allClientAudioClips, t])

    const onToggle = (setting: Partial<Client>) => {
        updateClient({
            status: client?.status,
            ...setting
        })
    }

    const onSaveAudio = async (file: File | null) => {
        if (!drawerType || !file) return
        try {
            const url = await uploadRecording(file)
            if (url) {
                await updateClient({
                    status: client?.status,
                    [drawerType]: url
                })
            }
        } catch (error) {
            console.error('components: Could not upload file', error)
        }
    }

    const openDrawer = (type: URLTypes) => {
        setDrawerType(type)
        recordingDrawer.onOpen()
    }

    const holdMusicEnabled = client?.holdMusic ?? false
    const waitMusicEnabled = client?.waitMusic ?? false

    return (
        <>
            <SettingsRow label={t('globalSettings.holdMusic')}>
                <HStack w="full" justify="space-between" align="start">
                    <VStack align="start" spacing={3}>
                        <Body size="sm" variant="bold" color={`${colorMode}.tones.navy`}>
                            {t('globalSettings.holdMusicDescription')}
                        </Body>
                        {holdMusicEnabled && (
                            <>
                                <HStack spacing={5} align="end">
                                    <ValidatedInput
                                        name="customHoldMusicURL"
                                        // label={t('globalSettings.holdMusicDescription')}
                                        shouldDisable
                                        shouldRegister={false}
                                        w="350px"
                                    >
                                        <Dropdown
                                            options={allClientAudioClipsWithDefault}
                                            value={customHoldMusicURL}
                                            onChange={value =>
                                                setValue('customHoldMusicURL', value)
                                            }
                                        />
                                    </ValidatedInput>
                                    <Player variant="micro" url={customHoldMusicURL} />
                                    <Button
                                        variant="creationary"
                                        px={6}
                                        onClick={() => openDrawer('customHoldMusicURL')}
                                    >
                                        {t('globalSettings.uploadNew')}
                                    </Button>
                                </HStack>
                                <Body size="sm" color={`${colorMode}.alerts.red`}>
                                    Maximum length is 60 seconds. Maximum file size 1.5MB
                                </Body>
                            </>
                        )}
                    </VStack>

                    <Toggle
                        isChecked={holdMusicEnabled}
                        onChange={e => onToggle({ holdMusic: e.target.checked })}
                    />
                </HStack>
            </SettingsRow>

            <SettingsRow label={t('globalSettings.waitingMusic')}>
                <HStack w="full" justify="space-between" align="start">
                    <VStack align="start" spacing={3}>
                        <Body size="sm" variant="bold" color={`${colorMode}.tones.navy`}>
                            {t('globalSettings.waitingMusicDescription')}
                        </Body>

                        {waitMusicEnabled && (
                            <>
                                <HStack spacing={5} align="end">
                                    <ValidatedInput
                                        name="customWaitMusicURL"
                                        shouldDisable
                                        shouldRegister={false}
                                        w="350px"
                                    >
                                        <Dropdown
                                            options={allClientAudioClipsWithDefault}
                                            value={customWaitMusicURL}
                                            onChange={value =>
                                                setValue('customWaitMusicURL', value)
                                            }
                                        />
                                    </ValidatedInput>
                                    <Player variant="micro" url={customWaitMusicURL} />
                                    <Button
                                        variant="creationary"
                                        px={6}
                                        onClick={() => openDrawer('customWaitMusicURL')}
                                    >
                                        {t('globalSettings.uploadNew')}
                                    </Button>
                                </HStack>
                                <Body size="sm" color={`${colorMode}.alerts.red`}>
                                    Maximum length is 60 seconds. Maximum file size 1.5MB
                                </Body>
                            </>
                        )}
                    </VStack>

                    <Toggle
                        isChecked={waitMusicEnabled}
                        onChange={e => onToggle({ waitMusic: e.target.checked })}
                    />
                </HStack>
            </SettingsRow>
            <RecordingDrawer
                isOpen={recordingDrawer.isOpen}
                onClose={recordingDrawer.onClose}
                onSubmit={file => onSaveAudio(file)}
            />
        </>
    )
}
