import { ChakraProvider, ChakraProviderProps, theme as defaultTheme } from '@chakra-ui/react'
import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react'
import circleloopTheme from 'theme/circleloop/theme'
import phonelineplusTheme from 'theme/phonelineplus/theme'
import zetaTheme from 'theme/zeta/theme'

export enum Themes {
    CIRCLELOOP = 'circleloop',
    PHONELINEPLUS = 'phonelineplus',
    ZETA = 'zeta'
}

interface ThemeContextProps {
    theme: Record<string, any>
    variant: Themes
}

const ThemeContext = createContext<ThemeContextProps>({ theme: {}, variant: Themes.CIRCLELOOP })

export const useTheme = () => useContext(ThemeContext)

interface ThemeProviderProps extends Omit<ChakraProviderProps, 'theme'> {
    variant: Themes
}

export const ThemeProvider: FC<PropsWithChildren<ThemeProviderProps>> = ({
    variant,
    children,
    ...props
}) => {
    const theme = useMemo(() => {
        if (variant === Themes.CIRCLELOOP) return circleloopTheme
        if (variant === Themes.PHONELINEPLUS) return phonelineplusTheme
        if (variant === Themes.ZETA) return zetaTheme

        if (process.env.NODE_ENV !== 'test') {
            console.error(
                '[ThemeProvider] Incorrect variant configuration, defaulting to @chakra-ui theme.'
            )
        }
        return defaultTheme
    }, [variant])

    return (
        <ChakraProvider {...props} theme={theme} portalZIndex={10}>
            <ThemeContext.Provider value={{ theme, variant }}>{children}</ThemeContext.Provider>
        </ChakraProvider>
    )
}
