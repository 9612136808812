import { Box, HStack, useColorMode } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface ForwardMessageProps {
    from: string
    message: string
}

const parseHTML = (html: string) => {
    const parser = new DOMParser()
    const dom = parser.parseFromString(html, 'text/html')
    return dom.body.innerHTML
}

export const ForwardMessage: FC<ForwardMessageProps> = ({ message, from }) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    return (
        <Box mb="12px">
            <HStack spacing={0}>
                <Body size="sm" sx={{ color: `${colorMode}.tones.stormGrey` }}>
                    <Box as="span" sx={{ color: `${colorMode}.primary.gamma` }}>
                        {`${t('Forwarded from')} ${from}:`}
                    </Box>
                    <Box>
                        <div dangerouslySetInnerHTML={{ __html: parseHTML(message) }} />
                    </Box>
                </Body>
            </HStack>
        </Box>
    )
}
