import { Box, Button, HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetClientPlansQuery } from 'shared/store'

import UpdateInvoiceDetailsDrawer from './UpdateInvoiceDetailsDrawer'

interface Props {}

const InvoiceDetails: React.FC<Props> = () => {
    const { data } = useGetClientPlansQuery()

    const { t } = useTranslation()

    const { colorMode } = useColorMode()

    const [activePlan, setActivePlan] = useState('')

    return (
        <Box
            p="24px"
            border="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
            borderRadius="4px"
            data-testid="invoice-details"
        >
            {data?.map(plan => (
                <HStack key={plan.ID} spacing="24px" align="end">
                    <VStack align="start">
                        <Body>{t('admin.billing.invoice.title')}</Body>
                        <Body color={`${colorMode}.tones.stormGrey`}>
                            {plan.invoiceAddress?.company}
                        </Body>
                        {!!plan.invoiceAddress && (
                            <Body color={`${colorMode}.tones.stormGrey`}>
                                {[
                                    'line1',
                                    'line2',
                                    'line3',
                                    'town',
                                    'county',
                                    'postcode',
                                    'country'
                                ]
                                    .map(field => plan.invoiceAddress?.[field])
                                    .filter(field => !!field)
                                    .join(', ')}
                            </Body>
                        )}
                    </VStack>

                    <Button onClick={() => setActivePlan(plan.ID)}>
                        {t('admin.billing.invoice.update')}
                    </Button>
                </HStack>
            ))}

            <UpdateInvoiceDetailsDrawer onClose={() => setActivePlan('')} planId={activePlan} />
        </Box>
    )
}

export default InvoiceDetails
