import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { callsAnatomy } from 'theme/anatomy'

const { definePartsStyle } = createMultiStyleConfigHelpers([...callsAnatomy.keys])

export const ActiveCallStyles: ComponentStyleConfig = {
    baseStyle: definePartsStyle(({ colorMode }) => {
        return {
            container: {
                width: '100%'
            },
            details: {
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'space-between'
            },
            timeContainer: {
                alignItems: 'flex-end'
            },
            duration: {
                fontSize: '24px',
                fontWeight: 500,
                color: 'light.tones.midnightExpress'
            },
            time: {
                fontSize: '14px',
                fontWeight: 500,
                color: 'light.tones.stormGrey'
            },
            dualCallContainer: {
                width: '95%',
                justifyContent: 'space-between',
                alignItems: 'start',
                alignSelf: 'end'
            },
            callStatus: {
                alignItems: 'center',
                justifyContent: 'start'
            },
            callWarning: {
                width: '100%',
                height: '104px',
                borderRadius: '4px',
                border: '1px solid',
                borderColor: `${colorMode}.tones.periwinkle`,
                justifyContent: 'center',
                alignItems: 'center',
                color: `${colorMode}.tones.stormGrey`
            }
        }
    })
}

export const RingingCallStyles: ComponentStyleConfig = {
    baseStyle: definePartsStyle({
        container: {
            width: '100%',
            gap: '16px'
        },
        dualCallContainer: {
            alignSelf: 'center',
            opacity: 0.8
        }
    })
}

export const ParkedCallStyles: ComponentStyleConfig = {
    baseStyle: definePartsStyle(({ colorMode }) => ({
        container: {
            bg: `${colorMode}.tones.ghostWhite`
        },
        heading: {
            color: `${colorMode}.tones.stormGrey`,
            fontWeight: 800
        }
    }))
}
