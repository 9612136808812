import { Box } from '@chakra-ui/react'
import { ResponsiveBar } from '@nivo/bar'
import { timeFormat as d3TimeFormat } from 'd3-time-format'
import { useAnalytics } from 'features/analytics/hooks/useAnalytics'
import { FC, useMemo } from 'react'

import { getTimeFormatForGroup } from '../../utils/graph'
import { AnalyticsGraphCommonProps, AnalyticsGraphProps } from './AnalyticsGraphProps'

type AnalyticsBarChartData = {
    [k: string]: any
    label: string
    inbound: number
    inboundColor: string
    outbound: number
    outboundColor: string
    missed: number
    missedColor: string
}

export const AnalyticsBarChart: FC<AnalyticsGraphProps> = ({ data, group }) => {
    const { colours } = useAnalytics()

    const chartData = useMemo(() => {
        const timeFormat = getTimeFormatForGroup(group)
        const formatter = d3TimeFormat(timeFormat)

        return data.map(
            (item): AnalyticsBarChartData => ({
                label: formatter(new Date(item.fromMs)),
                inbound: item.inbound,
                inboundColor: colours.inbound,
                outbound: item.outbound,
                outboundColor: colours.outbound,
                missed: item.missed,
                missedColor: colours.missed
            })
        )
    }, [data, group, colours])

    return (
        <Box h={500} w="100%" data-testid="analytics-bar-chart">
            <ResponsiveBar
                {...AnalyticsGraphCommonProps}
                data={chartData}
                keys={['inbound', 'outbound', 'missed']}
                colors={d => d.data[`${d.id}Color`]}
                indexBy="label"
                groupMode="grouped"
                padding={0.25}
                innerPadding={6}
                axisBottom={{
                    ...AnalyticsGraphCommonProps.axisBottom,
                    tickRotation: chartData.length > 18 ? 45 : undefined
                }}
                enableGridX={true}
                enableLabel={false}
                isInteractive
            />
        </Box>
    )
}
