export function numericSort(a: number, b: number) {
    return a - b
}

export function sort<T extends number | { toString(): string }>(a: T, b: T) {
    if (typeof a === 'number' && typeof b === 'number') {
        return numericSort(a, b)
    } else {
        return a.toString().localeCompare(b.toString(), undefined, { numeric: true })
    }
}

export enum SortDirection {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING'
}

/**
 * Creates a directional sort function
 * @param direction
 * @example
 * const sorter = createDirectionalSorter(SortDirection.ASCENDING)
 * const sortedItems = items.sort(sorter)
 * // or
 * const sortedItems = items.sort((a,b) => sorter(a,b))
 */
export function createDirectionalSorter<T extends string>(
    direction: SortDirection = SortDirection.ASCENDING
) {
    return function (a: T, b: T) {
        if (direction === SortDirection.ASCENDING) return a.localeCompare(b)
        return b.localeCompare(a)
    }
}
