import { Divider, DrawerFooter, useDisclosure, VStack } from '@chakra-ui/react'
import {
    Body,
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps,
    Input,
    Player
} from '@missionlabs/react'
import { MicIcon } from '@missionlabs/react/circleloop'
import { ChangeEvent, FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AudioRecorder } from './AudioRecorder'
import { FileUpload } from './FileUpload'

export interface RecordingDrawerProps extends Omit<DrawerProps, 'children'> {
    isLoading?: boolean
    onSubmit: (file: File | null) => Promise<void> | void
}

export const RecordingDrawer: FC<RecordingDrawerProps> = ({
    isLoading,
    onClose: _onClose,
    onSubmit: _onSubmit,
    ...props
}) => {
    const { t } = useTranslation()
    const audioRecorder = useDisclosure()

    const [file, setFile] = useState<File | null>(null)
    const [fileName, setFileName] = useState<string>()

    const fileURL = useMemo(() => {
        if (!file) return
        return URL.createObjectURL(file)
    }, [file])

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return
        setFile(e.target.files[0])
        audioRecorder.onClose()
    }

    const onFileNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFileName(e.target.value)
    }

    const onTrashFile = () => {
        setFile(null)
        audioRecorder.onClose()
    }

    const onRecordingEnd = (file: File) => {
        setFile(file)
    }

    const onClose = () => {
        setFileName(undefined)
        setFile(null)

        audioRecorder.onClose()
        _onClose()
    }

    const onSubmit = async () => {
        if (!file || !fileName) return

        const target = new File([file], fileName, { type: file.type })
        await _onSubmit(target)
        onClose()
    }

    return (
        <Drawer {...props} size="lg" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>{t('recordings.addNew')}</DrawerHeader>
                <DrawerBody>
                    <VStack spacing="24px" p="24px" align="stretch">
                        <VStack align="start">
                            <Body>{t('recordings.recordingName')}</Body>
                            <Input
                                placeholder="Enter name"
                                value={fileName}
                                onChange={onFileNameChange}
                                isInvalid={!fileName}
                            />
                        </VStack>
                        <Divider />
                        {!file &&
                            (!audioRecorder.isOpen ? (
                                <Button
                                    size="lg"
                                    variant="negatory"
                                    leftIcon={<MicIcon boxSize="20px" />}
                                    onClick={audioRecorder.onOpen}
                                >
                                    {t('recordings.clickToRecord')}
                                </Button>
                            ) : (
                                <AudioRecorder onEnd={onRecordingEnd} />
                            ))}

                        {fileURL && (
                            <Player sx={{ width: '100%' }} url={fileURL} onDelete={onTrashFile} />
                        )}
                        <Body align="center">{t('or')}</Body>
                        <FileUpload accept="audio/*" onChange={onFileChange} />
                        <Body align="center">{t('recordings.max')}</Body>
                    </VStack>
                </DrawerBody>
                <DrawerFooter pt="32px" p="24px" gap="16px">
                    <Button
                        w="full"
                        size="lg"
                        variant="secondary"
                        isDisabled={isLoading}
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        w="full"
                        size="lg"
                        variant="creationary"
                        isDisabled={!file || !fileName}
                        isLoading={isLoading}
                        onClick={onSubmit}
                    >
                        {t('Save')}
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}
