import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const SendIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SendIconLight',
    viewBox: '0 0 22 18',
    path: (
        <path
            d="M20.2271 7.84766L2.72714 0.347656C2.57089 0.269531 2.41464 0.230469 2.25839 0.230469C1.90683 0.230469 1.55527 0.347656 1.32089 0.621094C0.96933 1.01172 0.891205 1.59766 1.12558 2.06641L4.75839 8.98047L1.12558 15.9336C0.891205 16.4023 0.96933 16.9883 1.32089 17.3398C1.55527 17.6133 1.90683 17.7695 2.25839 17.7695C2.41464 17.7695 2.57089 17.7305 2.72714 17.6523L20.2271 10.1523C20.6959 9.95703 21.0084 9.48828 21.0084 8.98047C20.9693 8.51172 20.6959 8.04297 20.2271 7.84766ZM2.21933 1.51953L18.274 8.35547H5.81308L2.21933 1.51953ZM2.21933 16.5195L5.81308 9.60547H18.235L2.21933 16.5195Z"
            fill="currentColor"
        />
    )
})

export const SendIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SendIconRegular',
    viewBox: '0 0 22 18',
    path: (
        <path
            d="M20.2271 7.84766L2.72714 0.347656C2.57089 0.269531 2.41464 0.230469 2.25839 0.230469C1.90683 0.230469 1.55527 0.347656 1.32089 0.621094C0.96933 1.01172 0.891205 1.59766 1.12558 2.06641L4.75839 8.98047L1.12558 15.9336C0.891205 16.4023 0.96933 16.9883 1.32089 17.3398C1.55527 17.6133 1.90683 17.7695 2.25839 17.7695C2.41464 17.7695 2.57089 17.7305 2.72714 17.6523L20.2271 10.1523C20.6959 9.95703 21.0084 9.48828 21.0084 8.98047C20.9693 8.51172 20.6959 8.04297 20.2271 7.84766ZM3.58652 2.76953L15.9693 8.04297H6.35995L3.58652 2.76953ZM6.35995 9.91797H15.9693L3.58652 15.2305L6.35995 9.91797Z"
            fill="currentColor"
        />
    )
})

export const SendIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'SendIconSolid',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M19.9889 8.9878C19.9889 9.45655 19.6764 9.9253 19.2076 10.1206L1.74667 17.6597C1.59042 17.7378 1.43417 17.7378 1.27792 17.7378C0.926361 17.7378 0.574798 17.6206 0.340423 17.3472C-0.0111392 16.9956 -0.0892642 16.4097 0.145111 15.9409L3.03574 10.1987L12.4889 9.02686L3.03574 7.81593L0.145111 2.07374C-0.0892642 1.60499 -0.0111392 1.01905 0.340423 0.667488C0.691986 0.276863 1.27792 0.159676 1.74667 0.354988L19.2467 7.85499C19.7154 8.0503 19.9889 8.51905 19.9889 8.9878Z"
            fill="currentColor"
        />
    )
})
