import { HStack, StackProps, useMultiStyleConfig } from '@chakra-ui/react'
import { Heading } from 'atoms/Typography/Heading'
import { FC, PropsWithChildren, ReactNode } from 'react'

export interface ListTitleProps extends StackProps {
    rightElement?: ReactNode
}

export const ListTitle: FC<PropsWithChildren<ListTitleProps>> = ({
    color,
    rightElement,
    children,
    ...props
}) => {
    const styles = useMultiStyleConfig('List')
    return (
        <HStack {...props} sx={{ ...styles.title, ...(props.sx ?? {}) }}>
            {/* @ts-ignore */}
            <Heading {...styles.heading} color={color}>
                {children}
            </Heading>
            {rightElement}
        </HStack>
    )
}
