import {
    selectAuthenticatedUser,
    updateNotifications,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { useCallback, useState } from 'react'
import { useUpdateUserMutation } from 'shared/store'

interface MuteChatChannelNotifications {
    muted: boolean
    onMuteChatChannelNotifications: () => Promise<void>
    onElectronMuteChatChannelNotifications: (id: string) => Promise<void>
}

export const useMuteChatChannelNotifications = (
    channelID: string = ''
): MuteChatChannelNotifications => {
    const [updateUser] = useUpdateUserMutation()
    const dispatch = useDispatch()
    const user = useSelector(selectAuthenticatedUser)
    const [muted, setMuted] = useState(
        !!user?.notifications?.mutedChatChannels?.includes(channelID)
    )
    const onMuteChatChannelNotifications = useCallback(async () => {
        if (!user) return
        try {
            const userNotification = {
                ID: user?.userID,
                notifications: {
                    ...user?.notifications
                }
            }
            const mutedChatChannels = user?.notifications?.mutedChatChannels || []
            if (muted) {
                userNotification.notifications['mutedChatChannels'] = mutedChatChannels.filter(
                    ID => ID !== channelID
                )
                setMuted(false)
            }
            if (!muted) {
                userNotification.notifications['mutedChatChannels'] = [
                    ...mutedChatChannels,
                    channelID
                ]
                setMuted(true)
            }
            await updateUser(userNotification)
            dispatch(updateNotifications(userNotification.notifications))
        } catch (error) {
            console.error('Error muting/unmuting chat notification', error)
        }
    }, [channelID, dispatch, muted, updateUser, user])

    const onElectronMuteChatChannelNotifications = useCallback(
        async (id: string) => {
            if (!user) return
            try {
                const userNotification = {
                    ID: user?.userID,
                    notifications: {
                        ...user?.notifications
                    }
                }
                const mutedChatChannels = user?.notifications?.mutedChatChannels || []
                if (muted) {
                    userNotification.notifications['mutedChatChannels'] = mutedChatChannels.filter(
                        ID => ID !== id
                    )
                    setMuted(false)
                }
                if (!muted) {
                    userNotification.notifications['mutedChatChannels'] = [...mutedChatChannels, id]
                    setMuted(true)
                }
                await updateUser(userNotification)
                dispatch(updateNotifications(userNotification.notifications))
            } catch (error) {
                console.error('Error muting/unmuting chat notification', error)
            }
        },
        [dispatch, muted, updateUser, user]
    )
    return {
        muted,
        onMuteChatChannelNotifications,
        onElectronMuteChatChannelNotifications
    }
}
