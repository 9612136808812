import { Box, useColorMode, useMultiStyleConfig, VStack } from '@chakra-ui/react'
import { Body, Button } from 'atoms'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface EmptyPageProps {
    title?: string
    subtitle?: string
    link?: { label: string; onClick: () => void }
    showLogo?: boolean
}

export const EmptyPage: FC<EmptyPageProps> = ({ title, subtitle, link, showLogo = true }) => {
    const { t } = useTranslation()
    const styles = useMultiStyleConfig('EmptyPage')
    const { colorMode } = useColorMode()

    return (
        <VStack spacing="8px">
            {showLogo && (
                <Box
                    w="64px"
                    h="64px"
                    borderRadius="8px"
                    border="2px dashed"
                    borderColor={`${colorMode}.tones.periwinkle`}
                />
            )}
            <VStack spacing="2px">
                <Body variant="extra-bold" sx={styles.title}>
                    {title || t('emptyState.noItems')}
                </Body>
                <Body size="sm" sx={styles.subtitle}>
                    {subtitle || t('emptyState.nothingToSeeHere')}
                </Body>
            </VStack>
            {link && (
                <Button variant="link-underline" onClick={link.onClick} sx={styles.link}>
                    {link.label}
                </Button>
            )}
        </VStack>
    )
}
