export const REACTION_EMOJIS = [
    {
        name: 'Reactions',
        id: 'reactions',
        emojis: [
            {
                id: '+1',
                name: 'Thumbs Up',
                shortcodes: ':+1:',
                keywords: [
                    '+1',
                    'thumbsup',
                    'yes',
                    'awesome',
                    'good',
                    'agree',
                    'accept',
                    'cool',
                    'hand',
                    'like'
                ],
                skins: [
                    { unified: '1f44d', native: '👍' },
                    { unified: '1f44d-1f3fb', native: '👍🏻' },
                    { unified: '1f44d-1f3fc', native: '👍🏼' },
                    { unified: '1f44d-1f3fd', native: '👍🏽' },
                    { unified: '1f44d-1f3fe', native: '👍🏾' },
                    { unified: '1f44d-1f3ff', native: '👍🏿' }
                ]
            },
            {
                id: '-1',
                name: 'Thumbs Down',
                shortcodes: ':-1:',
                keywords: ['-1', 'thumbsdown', 'no', 'dislike', 'hand'],
                skins: [
                    { unified: '1f44e', native: '👎' },
                    { unified: '1f44e-1f3fb', native: '👎🏻' },
                    { unified: '1f44e-1f3fc', native: '👎🏼' },
                    { unified: '1f44e-1f3fd', native: '👎🏽' },
                    { unified: '1f44e-1f3fe', native: '👎🏾' },
                    { unified: '1f44e-1f3ff', native: '👎🏿' }
                ]
            },
            {
                id: 'heart',
                shortcodes: ':heart:',
                name: 'Red Heart',
                emoticons: ['<3'],
                keywords: ['love', 'like', 'valentines'],
                skins: [{ unified: '2764-fe0f', native: '❤️' }]
            },
            {
                id: 'grin',
                shortcodes: ':grin:',
                name: 'Beaming Face with Smiling Eyes',
                keywords: ['grin', 'happy', 'smile', 'joy', 'kawaii'],
                skins: [{ unified: '1f601', native: '😁' }]
            },
            {
                id: 'joy',
                shortcodes: ':joy:',
                name: 'Face with Tears of Joy',
                keywords: ['cry', 'weep', 'happy', 'happytears', 'haha'],
                skins: [{ unified: '1f602', native: '😂' }]
            },
            {
                id: 'sunglasses',
                shortcodes: ':sunglasses:',
                name: 'Smiling Face with Sunglasses',
                emoticons: ['8)'],
                keywords: ['cool', 'smile', 'summer', 'beach', 'sunglass'],
                skins: [{ unified: '1f60e', native: '😎' }]
            },
            {
                id: 'white_frowning_face',
                shortcodes: ':white_frowning_face:',
                name: 'Frowning Face',
                keywords: ['white', 'sad', 'upset', 'frown'],
                skins: [{ unified: '2639-fe0f', native: '☹️' }]
            },
            {
                id: 'wave',
                shortcodes: ':wave:',
                name: 'Waving Hand',
                keywords: [
                    'wave',
                    'hands',
                    'gesture',
                    'goodbye',
                    'solong',
                    'farewell',
                    'hello',
                    'hi',
                    'palm'
                ],
                skins: [
                    { unified: '1f44b', native: '👋' },
                    { unified: '1f44b-1f3fb', native: '👋🏻' },
                    { unified: '1f44b-1f3fc', native: '👋🏼' },
                    { unified: '1f44b-1f3fd', native: '👋🏽' },
                    { unified: '1f44b-1f3fe', native: '👋🏾' },
                    { unified: '1f44b-1f3ff', native: '👋🏿' }
                ]
            }
        ]
    }
]
