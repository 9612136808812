import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay
} from '@missionlabs/react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLeaveChatGroupMutation } from 'shared/store'

interface LeaveChatGroupDialogProps {
    groupID: string
    isOpen: boolean
    onClose?: () => void
}

export const LeaveChatGroupDialog = ({
    groupID,
    isOpen,
    onClose = () => {}
}: LeaveChatGroupDialogProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [leaveGroup, { isLoading }] = useLeaveChatGroupMutation()
    const user = useSelector(selectAuthenticatedUser)

    const ref = useRef<HTMLButtonElement>(null)
    const onLeaveGroup = async () => {
        if (!groupID || !user) return
        await leaveGroup({ groupID, userID: user.userID })
        navigate('/chat')
    }

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>{t('chatGroups.leave.header')}</DialogHeader>
                <DialogBody gap="12px">{t('chatGroups.leave.body')}</DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('chatGroups.leave.confirm'),
                        isDestructive: true,
                        onClick: () => {
                            onLeaveGroup()
                        }
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
