import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const DotsOutlineVerticalIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DotsOutlineVerticalIconLight',
    viewBox: '0 0 6 18',
    path: (
        <path
            d="M3 17.75C1.59375 17.75 0.5 16.6562 0.5 15.25C0.5 13.8828 1.59375 12.75 3 12.75C4.36719 12.75 5.5 13.8828 5.5 15.25C5.5 16.6562 4.36719 17.75 3 17.75ZM4.25 15.25C4.25 14.5859 3.66406 14 3 14C2.29688 14 1.75 14.5859 1.75 15.25C1.75 15.9531 2.29688 16.5 3 16.5C3.66406 16.5 4.25 15.9531 4.25 15.25ZM3 11.5C1.59375 11.5 0.5 10.4062 0.5 9C0.5 7.63281 1.59375 6.5 3 6.5C4.36719 6.5 5.5 7.63281 5.5 9C5.5 10.4062 4.36719 11.5 3 11.5ZM4.25 9C4.25 8.33594 3.66406 7.75 3 7.75C2.29688 7.75 1.75 8.33594 1.75 9C1.75 9.70312 2.29688 10.25 3 10.25C3.66406 10.25 4.25 9.70312 4.25 9ZM3 0.25C4.36719 0.25 5.5 1.38281 5.5 2.75C5.5 4.15625 4.36719 5.25 3 5.25C1.59375 5.25 0.5 4.15625 0.5 2.75C0.5 1.38281 1.59375 0.25 3 0.25ZM1.75 2.75C1.75 3.45312 2.29688 4 3 4C3.66406 4 4.25 3.45312 4.25 2.75C4.25 2.08594 3.66406 1.5 3 1.5C2.29688 1.5 1.75 2.08594 1.75 2.75Z"
            fill="currentColor"
        />
    )
})

export const DotsOutlineVerticalIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DotsOutlineVerticalIconRegular',
    viewBox: '0 0 6 18',
    path: (
        <path
            d="M3 17.75C1.59375 17.75 0.5 16.6562 0.5 15.25C0.5 13.8828 1.59375 12.75 3 12.75C4.36719 12.75 5.5 13.8828 5.5 15.25C5.5 16.6562 4.36719 17.75 3 17.75ZM3.9375 15.25C3.9375 14.7422 3.50781 14.3125 3 14.3125C2.45312 14.3125 2.0625 14.7422 2.0625 15.25C2.0625 15.7969 2.45312 16.1875 3 16.1875C3.50781 16.1875 3.9375 15.7969 3.9375 15.25ZM3 11.5C1.59375 11.5 0.5 10.4062 0.5 9C0.5 7.63281 1.59375 6.5 3 6.5C4.36719 6.5 5.5 7.63281 5.5 9C5.5 10.4062 4.36719 11.5 3 11.5ZM3.9375 9C3.9375 8.49219 3.50781 8.0625 3 8.0625C2.45312 8.0625 2.0625 8.49219 2.0625 9C2.0625 9.54688 2.45312 9.9375 3 9.9375C3.50781 9.9375 3.9375 9.54688 3.9375 9ZM3 0.25C4.36719 0.25 5.5 1.38281 5.5 2.75C5.5 4.15625 4.36719 5.25 3 5.25C1.59375 5.25 0.5 4.15625 0.5 2.75C0.5 1.38281 1.59375 0.25 3 0.25ZM2.0625 2.75C2.0625 3.29688 2.45312 3.6875 3 3.6875C3.50781 3.6875 3.9375 3.29688 3.9375 2.75C3.9375 2.24219 3.50781 1.8125 3 1.8125C2.45312 1.8125 2.0625 2.24219 2.0625 2.75Z"
            fill="currentColor"
        />
    )
})

export const DotsOutlineVerticalIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DotsOutlineVerticalIconSolid',
    viewBox: '0 0 6 20',
    path: (
        <path
            d="M3 19.0625C1.4375 19.0625 0.1875 17.8125 0.1875 16.25C0.1875 14.7266 1.4375 13.4375 3 13.4375C4.52344 13.4375 5.8125 14.7266 5.8125 16.25C5.8125 17.8125 4.52344 19.0625 3 19.0625ZM3 12.8125C1.4375 12.8125 0.1875 11.5625 0.1875 10C0.1875 8.47656 1.4375 7.1875 3 7.1875C4.52344 7.1875 5.8125 8.47656 5.8125 10C5.8125 11.5625 4.52344 12.8125 3 12.8125ZM3 0.9375C4.52344 0.9375 5.8125 2.22656 5.8125 3.75C5.8125 5.3125 4.52344 6.5625 3 6.5625C1.4375 6.5625 0.1875 5.3125 0.1875 3.75C0.1875 2.22656 1.4375 0.9375 3 0.9375Z"
            fill="currentColor"
        />
    )
})

export const DotsVerticalIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DotsVerticalIconLight',
    viewBox: '0 0 2 14',
    path: [
        <path
            d="M2 7C2 7.55228 1.55228 8 1 8C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6C1.55228 6 2 6.44772 2 7Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M2 1C2 1.55228 1.55228 2 1 2C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0C1.55228 0 2 0.447715 2 1Z"
            fill="currentColor"
            key="2"
        />,
        <path
            d="M2 13C2 13.5523 1.55228 14 1 14C0.447715 14 0 13.5523 0 13C0 12.4477 0.447715 12 1 12C1.55228 12 2 12.4477 2 13Z"
            fill="currentColor"
            key="3"
        />
    ]
})

export const DotsVerticalIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DotsVerticalIconRegular',
    viewBox: '0 0 4 16',
    path: [
        <path
            d="M3.5 2C3.5 2.82843 2.82843 3.5 2 3.5C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.5 2 0.5C2.82843 0.5 3.5 1.17157 3.5 2Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M3.5 8C3.5 8.82843 2.82843 9.5 2 9.5C1.17157 9.5 0.5 8.82843 0.5 8C0.5 7.17157 1.17157 6.5 2 6.5C2.82843 6.5 3.5 7.17157 3.5 8Z"
            fill="currentColor"
            key="2"
        />,
        <path
            d="M2 15.5C2.82843 15.5 3.5 14.8284 3.5 14C3.5 13.1716 2.82843 12.5 2 12.5C1.17157 12.5 0.5 13.1716 0.5 14C0.5 14.8284 1.17157 15.5 2 15.5Z"
            fill="currentColor"
            key="3"
        />
    ]
})

export const DotsVerticalIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DotsVerticalIconSolid',
    viewBox: '0 0 4 16',
    path: [
        <path
            d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2Z"
            fill="currentColor"
            key="1"
        />,
        <path
            d="M4 8C4 9.10457 3.10457 10 2 10C0.895431 10 0 9.10457 0 8C0 6.89543 0.895431 6 2 6C3.10457 6 4 6.89543 4 8Z"
            fill="currentColor"
            key="2"
        />,
        <path
            d="M2 16C3.10457 16 4 15.1046 4 14C4 12.8954 3.10457 12 2 12C0.895431 12 0 12.8954 0 14C0 15.1046 0.895431 16 2 16Z"
            fill="currentColor"
            key="3"
        />
    ]
})
