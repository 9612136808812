import { useColorMode, VStack } from '@chakra-ui/react'
import { EmptyPage } from '@missionlabs/react'
import * as React from 'react'

interface ActivitySidebarEmptyStateProps {
    siblingHeight: number
}

export const ActivitySidebarEmptyState: React.FC<ActivitySidebarEmptyStateProps> = ({
    siblingHeight
}) => {
    const { colorMode } = useColorMode()
    return (
        <VStack
            w="full"
            h={`calc(100% - ${siblingHeight}px)`}
            align="center"
            justify="center"
            bg={`${colorMode}.tones.whiteLilac`}
            id="activity-sidebar-empty"
        >
            <EmptyPage />
        </VStack>
    )
}
