import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerOverlay,
    useColorMode,
    useDisclosure
} from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { useParticipantsData } from '@missionlabs/meetings'
import { DrawerHeader, IconButton, useMeasure } from '@missionlabs/react'
import { AddIconRegular } from '@missionlabs/react/zeta'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList } from 'react-window'

import { MeetingsCallParticipantsRowRenderer } from './MeetingsCallParticipantsRowRenderer'

export const MeetingsCallParticipantsDrawer: FC = () => {
    const navigate = useNavigate()
    const { id = '' } = useParams()
    const { colorMode } = useColorMode()
    const { t } = useTranslation()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    const [ref, { blockSize: headerHeight }] = useMeasure<HTMLDivElement>()

    const { participantsData } = useParticipantsData()

    const user = useSelector(selectAuthenticatedUser)

    const filteredParticipants = useMemo(() => {
        return participantsData.filter(
            data => typeof data === 'string' || data.externalUserId !== user?.userID
        )
    }, [participantsData, user])

    const rowHeights = useMemo(
        () => filteredParticipants.map(item => (typeof item === 'string' ? 72 : 76)),
        [filteredParticipants]
    )

    const getRowSize = (index: number) => rowHeights[index]

    function handleTransition() {
        navigate('..')
    }

    return (
        <Drawer size="sm" isOpen={isOpen} onClose={onClose} onCloseComplete={handleTransition}>
            <DrawerOverlay />
            <DrawerContent mt="64px">
                <DrawerHeader ref={ref}>{t('meeting.participants.title')}</DrawerHeader>
                <DrawerBody
                    h={`calc(100% - ${headerHeight}px)`}
                    bg={`${colorMode}.tones.ghostWhite`}
                    overflow="hidden auto"
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <VariableSizeList
                                height={height}
                                width={width}
                                itemSize={getRowSize}
                                itemCount={filteredParticipants.length}
                                itemData={filteredParticipants}
                            >
                                {MeetingsCallParticipantsRowRenderer}
                            </VariableSizeList>
                        )}
                    </AutoSizer>
                    {!user?.isGuest && (
                        <IconButton
                            size="lg"
                            isRounded
                            sx={{ pos: 'absolute', bottom: '24px', right: '24px' }}
                            onClick={() => navigate(`/meetings/${id}/add`)}
                            aria-label="Add to call"
                        >
                            <AddIconRegular />
                        </IconButton>
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
