import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys)

const baseStyle = definePartsStyle({
    container: defineStyle({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'fit-content',
        borderRadius: '4px',
        px: '12px',
        py: '5.5px'
    }),
    label: defineStyle({
        fontFamily: 'body',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '21px'
    }),
    closeButton: defineStyle({
        boxSize: '24px',
        minW: 'unset',
        ml: '4px',
        mr: '-8px',
        opacity: 1
    })
})

export const TagStyles = defineMultiStyleConfig({
    baseStyle,
    defaultProps: {
        variant: 'white'
    },
    sizes: {
        sm: baseStyle,
        md: baseStyle,
        lg: baseStyle
    },
    variants: {
        white: definePartsStyle(({ colorMode }) => ({
            container: {
                bg: 'white'
            },
            label: {
                color: `${colorMode}.tones.midnightExpress`
            },
            closeButton: {
                color: `${colorMode}.tones.stormGrey`
            }
        })),
        whiteLilac: definePartsStyle(({ colorMode }) => ({
            container: {
                bg: `${colorMode}.tones.whiteLilac`
            },
            label: {
                color: `${colorMode}.tones.midnightExpress`
            },
            closeButton: {
                color: `${colorMode}.tones.stormGrey`
            }
        })),
        stormGrey: definePartsStyle(({ colorMode }) => ({
            container: {
                bg: `${colorMode}.tones.stormGrey`
            },
            label: {
                color: 'white'
            },
            closeButton: {
                color: `${colorMode}.tones.periwinkle`
            }
        })),
        navy: definePartsStyle(({ colorMode }) => ({
            container: {
                bg: `${colorMode}.tones.navy`
            },
            label: {
                color: 'white'
            },
            closeButton: {
                color: `${colorMode}.tones.periwinkle`
            }
        })),
        gamma: definePartsStyle(({ colorMode }) => ({
            container: {
                bg: `${colorMode}.primary.gammaHover`
            },
            label: {
                color: 'white'
            },
            closeButton: {
                color: `${colorMode}.primary.gamma16`
            }
        })),
        algae: definePartsStyle(({ colorMode }) => ({
            container: {
                bg: `${colorMode}.alerts.algae`
            },
            label: {
                color: 'white'
            },
            closeButton: {
                color: `${colorMode}.alerts.turquoise24`
            }
        })),
        jazberry: definePartsStyle(({ colorMode }) => ({
            container: {
                bg: `${colorMode}.alerts.jazberryJam`
            },
            label: {
                color: 'white'
            },
            closeButton: {
                color: `${colorMode}.alerts.rose24`
            }
        }))
    }
})
