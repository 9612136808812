import {
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    useToast
} from '@missionlabs/react'
import { IVRMenu } from '@missionlabs/types'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDeleteIVRMenuMutation } from 'shared/store'

type DeleteMenuDialogProps = {
    menu: IVRMenu
    isOpen: boolean
    onClose: () => void
}

export const DeleteMenuDialog: FC<DeleteMenuDialogProps> = ({ menu, isOpen, onClose }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { toast } = useToast()

    const [deleteMenu, { isLoading }] = useDeleteIVRMenuMutation()

    const ref = useRef<HTMLButtonElement>(null)

    const handleRemove = async () => {
        try {
            await deleteMenu(menu.ID).unwrap()
            navigate('/admin/menus')
        } catch {
            toast({
                title: t('admin.users.errorDeleting'),
                status: 'error'
            })
        }
    }

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>{t('admin.menus.removeMenu')}</DialogHeader>
                <DialogBody gap="12px">
                    {t('admin.menus.removeMenuDescription', { name: menu.name })}
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('admin.menus.removeMenu'),
                        isDestructive: true,
                        onClick: handleRemove
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
