import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ComposeIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ComposeIconLight',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M15.1611 0.81543C16.1377 -0.161133 17.7393 -0.161133 18.7158 0.81543L19.1846 1.28418C20.1611 2.26074 20.1611 3.8623 19.1846 4.83887L10.5908 13.4326C10.2393 13.7842 9.80957 13.9795 9.37988 14.0967L5.8252 14.917C5.62988 14.9561 5.39551 14.917 5.23926 14.7607C5.08301 14.6045 5.04395 14.3701 5.08301 14.1748L5.90332 10.6201C6.02051 10.1904 6.21582 9.76074 6.56738 9.40918L15.1611 0.81543ZM17.8174 1.71387C17.3486 1.20605 16.5283 1.20605 16.0596 1.71387L15.0049 2.72949L17.2705 4.99512L18.2861 3.94043C18.7939 3.47168 18.7939 2.65137 18.2861 2.18262L17.8174 1.71387ZM7.11426 10.8936L6.52832 13.4717L9.06738 12.8857C9.30176 12.8467 9.53613 12.7295 9.69238 12.5342L16.3721 5.85449L14.1455 3.62793L7.46582 10.3076C7.27051 10.4639 7.15332 10.6982 7.11426 10.8936ZM8.20801 2.41699C8.52051 2.41699 8.83301 2.72949 8.83301 3.04199C8.83301 3.39355 8.52051 3.66699 8.20801 3.66699H3.20801C2.15332 3.66699 1.33301 4.52637 1.33301 5.54199V16.792C1.33301 17.8467 2.15332 18.667 3.20801 18.667H14.458C15.4736 18.667 16.333 17.8467 16.333 16.792V11.792C16.333 11.4795 16.6064 11.167 16.958 11.167C17.2705 11.167 17.583 11.4795 17.583 11.792V16.792C17.583 18.5498 16.1768 19.917 14.458 19.917H3.20801C1.4502 19.917 0.0830078 18.5498 0.0830078 16.792V5.54199C0.0830078 3.82324 1.4502 2.41699 3.20801 2.41699H8.20801Z"
            fill="currentColor"
        />
    )
})

export const ComposeIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ComposeIconRegular',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M14.6484 0.898438C15.7422 -0.195312 17.5 -0.195312 18.5938 0.898438L19.1016 1.40625C20.1953 2.5 20.1953 4.25781 19.1016 5.35156L11.3672 13.0859C11.0547 13.3984 10.625 13.6719 10.1562 13.7891L6.25 14.9219C5.9375 15 5.58594 14.9219 5.35156 14.6484C5.07812 14.4141 5 14.0625 5.07812 13.75L6.21094 9.84375C6.32812 9.375 6.60156 8.94531 6.91406 8.63281L14.6484 0.898438ZM17.2656 2.22656C16.9141 1.875 16.3281 1.875 15.9766 2.22656L14.8047 3.35938L16.6406 5.19531L17.7734 4.02344C18.125 3.67188 18.125 3.08594 17.7734 2.73438L17.2656 2.22656ZM8.00781 10.3516L7.34375 12.6562L9.64844 11.9922C9.80469 11.9531 9.92188 11.875 10.0391 11.7578L15.3125 6.48438L13.5156 4.6875L8.24219 9.96094C8.125 10.0781 8.04688 10.1953 8.00781 10.3516ZM7.89062 2.42188C8.39844 2.42188 8.82812 2.85156 8.82812 3.35938C8.82812 3.90625 8.39844 4.29688 7.89062 4.29688H3.51562C2.61719 4.29688 1.95312 5 1.95312 5.85938V16.4844C1.95312 17.3828 2.61719 18.0469 3.51562 18.0469H14.1406C15 18.0469 15.7031 17.3828 15.7031 16.4844V12.1094C15.7031 11.6016 16.0938 11.1719 16.6406 11.1719C17.1484 11.1719 17.5781 11.6016 17.5781 12.1094V16.4844C17.5781 18.3984 16.0156 19.9219 14.1406 19.9219H3.51562C1.60156 19.9219 0.078125 18.3984 0.078125 16.4844V5.85938C0.078125 3.98438 1.60156 2.42188 3.51562 2.42188H7.89062Z"
            fill="currentColor"
        />
    )
})

export const ComposeIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ComposeIconSolid',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M19.248 1.49414C20.1074 2.35352 20.1074 3.7207 19.248 4.58008L18.0762 5.75195L14.248 1.92383L15.4199 0.751953C16.2793 -0.107422 17.6465 -0.107422 18.5059 0.751953L19.248 1.49414ZM6.82617 9.3457L13.3496 2.82227L17.1777 6.65039L10.6543 13.1738C10.4199 13.4082 10.1074 13.6035 9.79492 13.7207L6.31836 14.8535C5.9668 14.9707 5.61523 14.8926 5.38086 14.6191C5.10742 14.3848 5.0293 13.9941 5.14648 13.6816L6.2793 10.2051C6.39648 9.89258 6.5918 9.58008 6.82617 9.3457ZM7.60742 2.39258C8.27148 2.39258 8.85742 2.97852 8.85742 3.64258C8.85742 4.3457 8.27148 4.89258 7.60742 4.89258H3.85742C3.1543 4.89258 2.60742 5.47852 2.60742 6.14258V16.1426C2.60742 16.8457 3.1543 17.3926 3.85742 17.3926H13.8574C14.5215 17.3926 15.1074 16.8457 15.1074 16.1426V12.3926C15.1074 11.7285 15.6543 11.1426 16.3574 11.1426C17.0215 11.1426 17.6074 11.7285 17.6074 12.3926V16.1426C17.6074 18.2129 15.9277 19.8926 13.8574 19.8926H3.85742C1.74805 19.8926 0.107422 18.2129 0.107422 16.1426V6.14258C0.107422 4.07227 1.74805 2.39258 3.85742 2.39258H7.60742Z"
            fill="currentColor"
        />
    )
})
