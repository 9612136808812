import { useColorMode } from '@chakra-ui/react'
import { ControlBarButton as ChimeControlBarButton } from 'amazon-chime-sdk-component-library-react'
import styled from 'styled-components'

function getColor(variant: string, colors: any): any {
    switch (variant) {
        case 'terminate':
            return colors.tones.whiteLilac
        default:
            return colors.tones.navy
    }
}

function getBgColor(variant: string, colors: any): any {
    switch (variant) {
        case 'terminate':
            return colors.alerts.rose
        default:
            return ''
    }
}

// Create a new styled component based on one of the components from the component library
const ControlBarButton = styled(ChimeControlBarButton).attrs(props => {
    const { colorMode } = useColorMode()
    return {
        color: getColor(props.variant, props.theme.appTheme.colors[colorMode]),
        backgroundColor: getBgColor(props.variant, props.theme.appTheme.colors[colorMode])
    }
})`
    grid-template-columns: 2.5rem auto;

    & .ch-control-bar-item-iconButton {
        height: 40px;
        width: 40px;
        background-color: ${props => props.backgroundColor};
        ${props => (props.variant === 'terminate' ? `border-color: ${props.backgroundColor};` : '')}
        & svg {
            color: ${props => props.color};
            height: 16px;
            width: 16px;
        }
    }
`

export default ControlBarButton
