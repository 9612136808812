import { SystemStyleObject, useMultiStyleConfig } from '@chakra-ui/react'
import { Body } from 'atoms/Typography/Body'
import { FC } from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type ValidationError = {
    key?: string
    values?: { [key: string]: string }
}

export type ErrorMessageProps = {
    error?: FieldError | ((FieldError | undefined)[] & Partial<FieldError>) | string
    sx?: SystemStyleObject
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ error, sx = {} }) => {
    const { t } = useTranslation()
    const styles = useMultiStyleConfig('CustomInput')

    if (Array.isArray(error)) {
        return (
            <>
                {error.map(child => (
                    <ErrorMessage key={child?.message} error={child} />
                ))}
            </>
        )
    }

    if (typeof error === 'string') {
        return (
            <Body aria-live="polite" sx={{ ...styles.error, ...sx }}>
                {error}
            </Body>
        )
    }

    if (typeof error?.message === 'string') {
        return (
            <Body aria-live="polite" sx={{ ...styles.error, ...sx }}>
                {error.message}
            </Body>
        )
    }

    if (typeof error?.message === 'object') {
        const { key, values } = error.message as ValidationError
        return (
            <Body aria-live="polite" sx={{ ...styles.error, ...sx }}>
                {t(key as any, { ...values })}
            </Body>
        )
    }

    return null
}
