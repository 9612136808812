import { HStack, useColorMode } from '@chakra-ui/react'
import {
    IconButton,
    SearchInput as MLSearchInput,
    SearchInputProps as MLSearchInputProps,
    Tooltip
} from '@missionlabs/react'
import { FilterIconRegular, StarIconRegular, StarIconSolid } from '@missionlabs/react/zeta'
import { FC, isValidElement } from 'react'
import { useTranslation } from 'react-i18next'

type SearchInputProps = MLSearchInputProps & {
    hideFilters?: boolean
    hideFavourites?: boolean
    isFavourite?: boolean
    setIsFavourite?: (value: boolean) => void
    filtersActive?: boolean
    toggleFiltersActive?: () => void
}

export const SearchInput: FC<SearchInputProps> = ({
    placeholder,
    hideFilters = false,
    hideFavourites = false,
    isFavourite,
    setIsFavourite,
    filtersActive,
    toggleFiltersActive,
    ...props
}) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    function renderRightElement() {
        if (isValidElement(props.rightElement)) return props.rightElement

        if (!hideFilters) {
            return (
                <HStack spacing={1}>
                    {!hideFavourites && (
                        <IconButton
                            variant="transparent"
                            size="sm"
                            sx={{ color: 'inherit' }}
                            onClick={() => setIsFavourite?.(!isFavourite)}
                            aria-label="Star button"
                        >
                            {isFavourite ? (
                                <StarIconSolid boxSize="18px" p="2px" />
                            ) : (
                                <StarIconRegular boxSize="18px" p="2px" />
                            )}
                        </IconButton>
                    )}
                    <Tooltip label={t('activities.Filters')}>
                        <IconButton
                            variant={filtersActive ? 'secondary' : 'transparent'}
                            size="sm"
                            sx={{
                                color: filtersActive ? `${colorMode}.tones.white` : 'inherit',
                                _hover: filtersActive
                                    ? {}
                                    : {
                                          color: `${colorMode}.primary.gammaHover`,
                                          border: `1px solid`,
                                          borderColor: `${colorMode}.tones.periwinkle`
                                      }
                            }}
                            onClick={() => toggleFiltersActive?.()}
                            aria-label="Filter"
                        >
                            <FilterIconRegular boxSize="21px" p="3px" />
                        </IconButton>
                    </Tooltip>
                </HStack>
            )
        }

        return null
    }

    return (
        <MLSearchInput
            data-testid="search-input"
            {...props}
            placeholder={placeholder ?? t('Search name or number')}
            rightElement={renderRightElement()}
        />
    )
}
