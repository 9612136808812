import { Box, HStack, useColorMode, VStack } from '@chakra-ui/react'
import {
    MeetingEventType,
    openMeetingWindow,
    selectAuthenticatedUser,
    useDispatch,
    useSelector
} from '@missionlabs/api'
import { Button, Tooltip } from '@missionlabs/react'
import { AttendeeStatus, ScheduledMeeting as ScheduledMeetingType } from '@missionlabs/types'
import { isValidEmail } from '@missionlabs/utils'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateScheduledMeetingAttendanceMutation } from 'shared/store'

import { useGetMeetingData } from '../useGetMeetingData'
import { MeetingButtons } from './MeetingButtons'
import { MeetingDetails } from './MeetingDetails'
import { MeetingOverflowMenu } from './MeetingOverflowMenu'

type ScheduledMeetingProps = {
    meeting: ScheduledMeetingType
    allMeetings: ScheduledMeetingType[]
}

export const ScheduledMeeting: FC<ScheduledMeetingProps> = ({ meeting, allMeetings }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const user = useSelector(selectAuthenticatedUser)
    const userID = user?.userID ?? ''
    const dispatch = useDispatch()
    const [updateMeetingStatus] = useUpdateScheduledMeetingAttendanceMutation()
    const {
        hasExpired,
        isJoinable,
        meetingOverlaps,
        time,
        isInternalAndExternal,
        isInAnotherMeeting,
        isCancelled,
        hasStarted
    } = useGetMeetingData(meeting, allMeetings, userID)

    const userIsOrganiser = useMemo(() => {
        return meeting.organiser.userID === userID
    }, [meeting, userID])

    const invitedUser = useMemo(() => {
        return meeting.invitedUsers.find(user => user.userID === userID)
    }, [meeting.invitedUsers, userID])

    const handleMeetingStatus = useCallback(
        (status: Exclude<AttendeeStatus, AttendeeStatus.NO_RESPONSE>) => {
            updateMeetingStatus({
                attendanceStatus: status,
                meetingID: meeting.ID,
                userID: userID
            })
        },
        [updateMeetingStatus, meeting, userID]
    )

    const joinMeeting = useCallback(() => {
        dispatch(
            openMeetingWindow({
                meetingID: meeting.ID,
                event: MeetingEventType.JOIN_VIDEO_CALL,
                userID: userID,
                clientID: user?.clientID ?? '',
                token: user?.token ?? '',
                meetingTitle: meeting.meetingName,
                username: user?.username
            })
        )
    }, [dispatch, meeting.ID, meeting.meetingName, user, userID])

    const renderActionButton = useCallback(() => {
        if (hasExpired || isCancelled) return

        if (!userIsOrganiser && invitedUser?.attendanceStatus === AttendeeStatus.NO_RESPONSE)
            return (
                <HStack>
                    <Button
                        variant="creationary"
                        onClick={() => handleMeetingStatus(AttendeeStatus.ATTENDING)}
                    >
                        {t('Accept')}
                    </Button>
                    <Button
                        variant="negatory"
                        onClick={() => handleMeetingStatus(AttendeeStatus.DECLINED)}
                    >
                        {t('Reject')}
                    </Button>
                </HStack>
            )

        if (isJoinable)
            return (
                <Tooltip label={t('meetings.joinMeetingWarning')} isDisabled={!isInAnotherMeeting}>
                    <Button
                        variant={'secondary'}
                        onClick={joinMeeting}
                        isDisabled={isInAnotherMeeting}
                    >
                        {t('meetings.joinMeeting')}
                    </Button>
                </Tooltip>
            )
    }, [
        hasExpired,
        isJoinable,
        isCancelled,
        userIsOrganiser,
        invitedUser,
        t,
        handleMeetingStatus,
        joinMeeting,
        isInAnotherMeeting
    ])

    const showOverflowMenu = useMemo(() => {
        // We only want to show the menu for the organiser to edit or cancel a meeting.
        // The invited user should only see it if it has been cancelled so they can remove it from their schedule.
        if (hasExpired) return false
        if (userIsOrganiser && !isCancelled) return true
        else return !userIsOrganiser && isCancelled
    }, [hasExpired, userIsOrganiser, isCancelled])

    const hasExternalParticipants = useMemo(() => {
        return meeting.invitedUsers.some(({ userID }) => isValidEmail(userID))
    }, [meeting])

    return (
        <VStack
            w="full"
            px="24px"
            pt="16px"
            pb="8px"
            borderBottom="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
            align="start"
        >
            <HStack w="full" justify="space-between" align="start">
                <MeetingDetails
                    name={meeting.meetingName}
                    time={time}
                    description={meeting.description ?? ''}
                    isInternalAndExternal={isInternalAndExternal}
                    meetingOverlaps={!hasExpired && meetingOverlaps}
                    hasExpired={hasExpired}
                    isCancelled={isCancelled}
                />
                <HStack spacing="16px">
                    {renderActionButton()}
                    {showOverflowMenu ? (
                        <MeetingOverflowMenu
                            meeting={meeting}
                            hasStarted={hasStarted}
                            isOrganiser={userIsOrganiser}
                            isCancelled={isCancelled}
                            hasExternalParticipants={hasExternalParticipants}
                        />
                    ) : (
                        <Box boxSize="40px" />
                    )}
                </HStack>
            </HStack>
            <MeetingButtons
                meeting={meeting}
                hasExpired={hasExpired}
                isCancelled={isCancelled}
                hasStarted={hasStarted}
                isOrganiser={userIsOrganiser}
            />
        </VStack>
    )
}
