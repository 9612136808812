import {
    Center,
    Divider,
    Fade,
    Grid,
    HStack,
    useColorMode,
    useDisclosure,
    useMultiStyleConfig,
    VStack
} from '@chakra-ui/react'
import { useSelector } from '@missionlabs/api'
import { ActivityDetailTextOverflow, ActivityIcon, Body, Player } from '@missionlabs/react'
import { FlagIcon } from '@missionlabs/react/circleloop'
import { UserActivity } from '@missionlabs/types'
import { downloadURL } from '@missionlabs/utils'
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useActivityInfo } from 'shared/hooks/useActivityInfo'
import { CallNoteSliceRootState, selectActivityCallNotes } from 'shared/slices/callNoteSlice'
import { useDeleteUserCallRecordingMutation } from 'shared/store'
import { Section } from 'shared/types/feed'

import { ActivityCallNote } from './ActivityCallNote'
import { ActivityFeedRowMenu } from './ActivityFeedRowMenu'
import { useReadActivityTimer } from './hooks/useReadActivityTimer'

type ActivityWithSection = UserActivity & { section: Section }

export interface ActivityFeedRowProps {
    highlighted?: boolean
    activity: ActivityWithSection
}

function getDuration(callLength = 0) {
    const secs = callLength % 60
    const mins = Math.floor(callLength / 60)
    return `${mins}m ${secs}s`
}

export const ActivityFeedRow: FC<ActivityFeedRowProps> = memo(({ activity, highlighted }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { direction, name, originatingNumber, team, time, descriptiveType } =
        useActivityInfo(activity)

    const [deleteCallRecording] = useDeleteUserCallRecordingMutation()

    // Little bit of a hack - track if the recording has been deleted so we can remove the player
    // There is an optimistic update to do this in the mutation but it's not quick enough.
    const [hasDeletedRecording, setHasDeletedRecording] = useState(false)

    const [showHighlight, setShowHighlight] = useState(false)

    const playerStyles = useMultiStyleConfig('Player')

    const activityRowStyles = useMultiStyleConfig('ActivityRow', {
        variant: showHighlight ? 'highlight' : undefined
    })

    useEffect(
        function removeHighlight() {
            let timeout: NodeJS.Timeout
            // If the activity is highlighted, show the highlight for 1.5 seconds
            if (highlighted) {
                setShowHighlight(true)
                timeout = setTimeout(() => setShowHighlight(false), 1500)
            }
            return () => {
                if (timeout) clearTimeout(timeout)
            }
        },
        [highlighted]
    )

    const { isOpen: isHovering, onOpen, onClose } = useDisclosure()

    const userRecordingURLs: string[] = useMemo(() => {
        const myRecordings = [activity.userRecordingURLs?.[activity.userID] ?? []]
        return myRecordings.flat()
    }, [activity])

    const isOutbound = direction === 'outbound'

    const onDeleteRecording = useCallback(() => {
        deleteCallRecording({ activityID: activity.ID, userID: activity.userID })
        setHasDeletedRecording(true)
    }, [activity, deleteCallRecording])

    const ref = useRef<HTMLDivElement>(null)
    useReadActivityTimer(activity, ref)

    let byDescription = t('details.takenBy')
    if (descriptiveType === 'SMS') byDescription = t('details.by')
    else if (isOutbound) byDescription = t('details.madeBy')

    const callNotes =
        useSelector((state: CallNoteSliceRootState) =>
            selectActivityCallNotes(state, activity.callTraceID)
        ) ?? activity.callNotes

    return (
        <Grid ref={ref} onMouseOver={onOpen} onMouseOut={onClose} sx={activityRowStyles}>
            <Center
                boxSize="32px"
                p="8px"
                bg={isOutbound ? `${colorMode}.tones.periwinkle` : `${colorMode}.tones.ghostWhite`}
                border={isOutbound ? '0' : '1px solid'}
                borderColor={`${colorMode}.tones.periwinkle`}
                borderRadius={isOutbound ? '16px 0 16px 16px' : '0 16px 16px 16px'}
            >
                <ActivityIcon
                    color={isOutbound ? `${colorMode}.tones.ghostWhite` : ''}
                    activityType={activity.activityType}
                    callLength={activity.callLength}
                />
            </Center>
            <VStack spacing="16px" w="full" align="inherit">
                <VStack spacing="2px" w="full" align="inherit">
                    <HStack spacing="8px" w="full">
                        {activity.isFlaggedByUser && (
                            <FlagIcon
                                color={`${colorMode}.avatar.blue`}
                                aria-label={t('activities.filters.flagged')}
                            />
                        )}
                        <Body
                            size="sm"
                            variant="bold"
                            color={`${colorMode}.tones.navy`}
                            whiteSpace="nowrap"
                        >
                            {descriptiveType}
                        </Body>
                        <Body size="sm" color={`${colorMode}.tones.stormGrey`} whiteSpace="nowrap">
                            {isOutbound ? t('to').toLowerCase() : t('from').toLowerCase()}
                        </Body>
                        <Body
                            size="sm"
                            variant="bold"
                            color={`${colorMode}.tones.navy`}
                            whiteSpace="nowrap"
                        >
                            {isOutbound ? name : originatingNumber}
                        </Body>

                        <Body size="sm" color={`${colorMode}.tones.stormGrey`} whiteSpace="nowrap">
                            {time}
                        </Body>
                        {isHovering && <Divider variant="dashed" />}
                    </HStack>
                    {!!team && (
                        <HStack spacing="8px" w="full">
                            <Body
                                size="sm"
                                color={`${colorMode}.tones.stormGrey`}
                                whiteSpace="nowrap"
                            >
                                {descriptiveType === 'SMS' && <span>{t('Sent') + ' '}</span>}
                                <span>
                                    {isOutbound ? t('from').toLowerCase() : t('to').toLowerCase()}
                                </span>
                            </Body>
                            <Body
                                size="sm"
                                variant="light"
                                color={`${colorMode}.alerts.redDark`}
                                whiteSpace="nowrap"
                            >
                                {team.replace(/^Via: /, '')}
                            </Body>
                            {activity.callLength && (
                                <>
                                    <Body
                                        size="sm"
                                        color={`${colorMode}.tones.stormGrey`}
                                        whiteSpace="nowrap"
                                    >
                                        {byDescription}
                                    </Body>

                                    <Body
                                        size="sm"
                                        variant="light"
                                        color={`${colorMode}.alerts.redDark`}
                                        whiteSpace="nowrap"
                                    >
                                        {activity.userName ?? t('Unknown')}
                                    </Body>
                                </>
                            )}
                        </HStack>
                    )}
                    {activity.text && (
                        <Body size="sm" color={`${colorMode}.tones.navy`}>
                            {activity.text}
                        </Body>
                    )}
                    {activity.callLength && (
                        <HStack spacing="4px">
                            <Body size="sm" color={`${colorMode}.tones.stormGrey`}>
                                {t('calls.callDuration')}:
                            </Body>
                            <Body size="sm" variant="bold" color={`${colorMode}.tones.navy`}>
                                {getDuration(activity.callLength)}
                            </Body>
                        </HStack>
                    )}
                    {callNotes?.map((callNote, index: number) => {
                        return (
                            <ActivityCallNote
                                key={callNote.ID + index.toString()}
                                idx={index}
                                callTraceID={activity.callTraceID}
                                content={callNote.content}
                            />
                        )
                    })}
                </VStack>

                {/* Call recording player */}
                {!hasDeletedRecording &&
                    userRecordingURLs.map(url => (
                        <Player
                            key={url}
                            sx={{ w: '352px' }}
                            url={url}
                            onDownload={() => downloadURL(url)}
                            onDelete={onDeleteRecording}
                        />
                    ))}

                {/* Voicemail player */}
                {!!activity.messageAttachmentURL && (
                    <>
                        <Player
                            sx={{ w: '352px' }}
                            url={activity.messageAttachmentURL}
                            onDownload={() => downloadURL(activity.messageAttachmentURL!)}
                        />
                        <ActivityDetailTextOverflow
                            label={`${t('player.transcript')}`}
                            message={
                                activity.messageTranscription?.toLowerCase() ===
                                'unable to obtain transcription for message'
                                    ? 'No transcript available.'
                                    : activity.messageTranscription
                            }
                            size={'sm'}
                            color={`${colorMode}.tones.navy`}
                            sx={{ ...playerStyles.label }}
                        />
                    </>
                )}
            </VStack>
            <Fade in={isHovering}>
                <ActivityFeedRowMenu activity={activity} />
            </Fade>
        </Grid>
    )
})
