import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const WarningIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'WarningIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.9724 22.792H2.97194C1.90454 22.792 0.946038 22.2402 0.407938 21.3164C-0.129662 20.3926 -0.136462 19.2871 0.390838 18.3584L9.39084 2.5015C9.92454 1.5611 10.8893 1 11.9723 1C13.0553 1 14.0202 1.561 14.5534 2.5015L23.5544 18.3584C24.0808 19.2871 24.0739 20.3926 23.5358 21.3164C22.9977 22.2402 22.0398 22.792 20.9724 22.792ZM11.9724 18.7002C11.696 18.7002 11.4724 18.4766 11.4724 18.2002V17.9512C11.4724 17.6748 11.696 17.4512 11.9724 17.4512C12.2488 17.4512 12.4724 17.6748 12.4724 17.9512V18.2002C12.4724 18.4766 12.2488 18.7002 11.9724 18.7002ZM11.4724 14.8164C11.4724 15.0928 11.696 15.3164 11.9724 15.3164C12.2488 15.3164 12.4724 15.0928 12.4724 14.8164V7.6348C12.4724 7.3584 12.2488 7.1348 11.9724 7.1348C11.696 7.1348 11.4724 7.3584 11.4724 7.6348V14.8164Z"
                fill="#3E4C59"
            />
            <mask
                id="mask0_2_2796"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="1"
                width="24"
                height="22"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.9724 22.792H2.97194C1.90454 22.792 0.946038 22.2402 0.407938 21.3164C-0.129662 20.3926 -0.136462 19.2871 0.390838 18.3584L9.39084 2.5015C9.92454 1.5611 10.8893 1 11.9723 1C13.0553 1 14.0202 1.561 14.5534 2.5015L23.5544 18.3584C24.0808 19.2871 24.0739 20.3926 23.5358 21.3164C22.9977 22.2402 22.0398 22.792 20.9724 22.792ZM11.9724 18.7002C11.696 18.7002 11.4724 18.4766 11.4724 18.2002V17.9512C11.4724 17.6748 11.696 17.4512 11.9724 17.4512C12.2488 17.4512 12.4724 17.6748 12.4724 17.9512V18.2002C12.4724 18.4766 12.2488 18.7002 11.9724 18.7002ZM11.4724 14.8164C11.4724 15.0928 11.696 15.3164 11.9724 15.3164C12.2488 15.3164 12.4724 15.0928 12.4724 14.8164V7.6348C12.4724 7.3584 12.2488 7.1348 11.9724 7.1348C11.696 7.1348 11.4724 7.3584 11.4724 7.6348V14.8164Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_2_2796)"></g>
        </g>
    )
})
