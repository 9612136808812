import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const FilterIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'FilterIconLight',
    viewBox: '0 0 18 14',
    path: (
        <path
            d="M0.25 0.75C0.25 0.4375 0.523438 0.125 0.875 0.125H17.125C17.4375 0.125 17.75 0.4375 17.75 0.75C17.75 1.10156 17.4375 1.375 17.125 1.375H0.875C0.523438 1.375 0.25 1.10156 0.25 0.75ZM2.75 7C2.75 6.6875 3.02344 6.375 3.375 6.375H14.625C14.9375 6.375 15.25 6.6875 15.25 7C15.25 7.35156 14.9375 7.625 14.625 7.625H3.375C3.02344 7.625 2.75 7.35156 2.75 7ZM10.875 13.875H7.125C6.77344 13.875 6.5 13.6016 6.5 13.25C6.5 12.9375 6.77344 12.625 7.125 12.625H10.875C11.1875 12.625 11.5 12.9375 11.5 13.25C11.5 13.6016 11.1875 13.875 10.875 13.875Z"
            fill="currentColor"
        />
    )
})

export const FilterIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'FilterIconRegular',
    viewBox: '0 0 18 16',
    path: (
        <path
            d="M0.25 1.75C0.25 1.24219 0.640625 0.8125 1.1875 0.8125H16.8125C17.3203 0.8125 17.75 1.24219 17.75 1.75C17.75 2.29688 17.3203 2.6875 16.8125 2.6875H1.1875C0.640625 2.6875 0.25 2.29688 0.25 1.75ZM2.75 8C2.75 7.49219 3.14062 7.0625 3.6875 7.0625H14.3125C14.8203 7.0625 15.25 7.49219 15.25 8C15.25 8.54688 14.8203 8.9375 14.3125 8.9375H3.6875C3.14062 8.9375 2.75 8.54688 2.75 8ZM10.5625 15.1875H7.4375C6.89062 15.1875 6.5 14.7969 6.5 14.25C6.5 13.7422 6.89062 13.3125 7.4375 13.3125H10.5625C11.0703 13.3125 11.5 13.7422 11.5 14.25C11.5 14.7969 11.0703 15.1875 10.5625 15.1875Z"
            fill="currentColor"
        />
    )
})

export const FilterIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'FilterIconSolid',
    viewBox: '0 0 18 16',
    path: (
        <path
            d="M0.25 1.75C0.25 1.08594 0.796875 0.5 1.5 0.5H16.5C17.1641 0.5 17.75 1.08594 17.75 1.75C17.75 2.45312 17.1641 3 16.5 3H1.5C0.796875 3 0.25 2.45312 0.25 1.75ZM2.75 8C2.75 7.33594 3.29688 6.75 4 6.75H14C14.6641 6.75 15.25 7.33594 15.25 8C15.25 8.70312 14.6641 9.25 14 9.25H4C3.29688 9.25 2.75 8.70312 2.75 8ZM10.25 15.5H7.75C7.04688 15.5 6.5 14.9531 6.5 14.25C6.5 13.5859 7.04688 13 7.75 13H10.25C10.9141 13 11.5 13.5859 11.5 14.25C11.5 14.9531 10.9141 15.5 10.25 15.5Z"
            fill="currentColor"
        />
    )
})
