import { ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { navbarAnatomy } from '../anatomy'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(navbarAnatomy.keys)

export const NavbarStyles: ComponentStyleConfig = defineMultiStyleConfig({
    baseStyle: ({ colorMode }) => ({
        bar: {
            bg: `${colorMode}.alerts.red`,
            w: '64px'
        },
        itemContainer: {
            boxSize: '64px',
            color: `${colorMode}.tones.white`,
            _active: {
                bg: `${colorMode}.alerts.redDark !important`
            },
            _hover: {
                bg: `${colorMode}.alerts.redHover`
            }
        },
        item: {
            boxSize: '20px'
        }
    }),
    variants: {
        dialler: ({ colorMode }) => ({
            bar: {
                bg: `${colorMode}.tones.navy`
            },
            itemContainer: {
                color: `${colorMode}.tones.periwinkle`,
                _active: {
                    bg: `${colorMode}.tones.midnightExpress !important`
                },
                _hover: {
                    bg: `${colorMode}.tones.navy`
                }
            }
        })
    }
})
