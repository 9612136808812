import { FormProvider, useYupForm, yup } from '@missionlabs/react'
import { FC, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DocumentTitle } from 'shared/components/DocumentTitle'
import { useUpdateResetTokenMutation } from 'shared/store'

import { ResetPasswordForm } from './ResetPasswordForm'
import { ResetPasswordSent } from './ResetPasswordSent'

export interface ResetPasswordProps {}

export interface ResetPasswordFields {
    password: string
    passwordConfirmation: string
}

export const ResetPassword: FC<ResetPasswordProps> = () => {
    const [searchParams] = useSearchParams()

    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const [updatePassword, { isSuccess }] = useUpdateResetTokenMutation()

    const tokenID = useMemo(() => searchParams.get('resettokenID'), [searchParams])
    const endpoint = useMemo(() => searchParams.get('endpoint'), [searchParams])

    const methods = useYupForm<ResetPasswordFields>(
        yup.object({
            password: yup.string().required(),
            passwordConfirmation: yup
                .string()
                .required()
                .oneOf([yup.ref('password')], { key: "Passwords don't match" })
        }),
        { defaultValues: { password: '', passwordConfirmation: '' } }
    )
    const {
        formState: { isSubmitting }
    } = methods

    const onSubmit = async (data: ResetPasswordFields) => {
        try {
            if (!tokenID || !endpoint) throw Error('Missing required search params.')

            await updatePassword({
                ID: tokenID,
                endpoint: endpoint,
                password: data.passwordConfirmation
            }).unwrap()
        } catch (e) {
            console.error(e)
            setErrorMessage('data' in e ? e.data.message : e.message)
        }
    }

    return (
        <>
            <DocumentTitle breadcrumbs={['Reset Password']} />
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    {isSuccess ? (
                        <ResetPasswordSent />
                    ) : (
                        <ResetPasswordForm errorMessage={errorMessage} isLoading={isSubmitting} />
                    )}
                </form>
            </FormProvider>
        </>
    )
}
