import { HStack } from '@chakra-ui/react'
import { Avatar, Body, Scrollable } from '@missionlabs/react'
import { User } from '@missionlabs/types'
import { FC } from 'react'
import { useGetUsersQuery } from 'shared/store'

import CreateReportDataStack from '../components/CreateReportDataList'
import CreateReportStackItem from '../components/CreateReportListItem'
import CreateReportSearch from '../components/CreateReportSearch'
import { useCreateReportSearch } from '../hooks/useCreateReportSearch'

interface ReportFormUsersProps {
    onSelect(ID: string): void
    isSelected(ID: string): boolean
}

export const ReportFormUsers: FC<ReportFormUsersProps> = ({ onSelect, isSelected }) => {
    const { data: users = [] } = useGetUsersQuery()

    const { searchValue, setSearchValue, results } = useCreateReportSearch<User>(users, 'fullName')

    return (
        <CreateReportDataStack>
            <CreateReportSearch search={searchValue} setSearch={setSearchValue} />
            <Scrollable height="container.sm">
                {results.map(user => (
                    <CreateReportStackItem
                        isSelected={isSelected(user.ID)}
                        onClick={() => onSelect(user.ID)}
                        key={user.ID}
                    >
                        <HStack>
                            <Avatar name={user.fullName} src={user.photoURL ?? undefined} />
                            <Body>{user.fullName}</Body>
                        </HStack>
                    </CreateReportStackItem>
                ))}
            </Scrollable>
        </CreateReportDataStack>
    )
}
