import { VStack } from '@chakra-ui/react'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@missionlabs/react'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useMeasure } from 'react-use'
import { useGetPortingFormURLsQuery } from 'shared/store'

import MirrorNumberForm from './Mirror'
import PortNumberForm from './Port'

export const ExistingNumberForm: FC = () => {
    const { t } = useTranslation()
    const [tabHeadersRef, { height: tabHeaderHeight }] = useMeasure()

    useGetPortingFormURLsQuery()

    const navigate = useNavigate()

    const onClose = useCallback(() => {
        navigate('..')
    }, [navigate])

    const { type } = useParams()

    const onChangeTab = useCallback(
        tabIndex => {
            navigate(`/admin/numbers/existing/${tabIndex ? 'mirror' : 'port'}`)
        },
        [navigate]
    )

    return (
        <VStack h="full" spacing="32px" align="stretch" justify="space-between" overflow={'hidden'}>
            <Tabs
                size="md"
                h="full"
                isFitted
                isLazy
                index={type === 'port' ? 0 : 1}
                onChange={onChangeTab}
            >
                <TabList ref={tabHeadersRef}>
                    <Tab>{t('admin.numbers.porting.header')}</Tab>
                    <Tab>{t('admin.numbers.mirror.header')}</Tab>
                </TabList>
                <TabPanels h={`calc(100% - ${tabHeaderHeight}px)`}>
                    <TabPanel h={'full'}>
                        <PortNumberForm onClose={onClose} />
                    </TabPanel>
                    <TabPanel h={'full'}>
                        <MirrorNumberForm onClose={onClose} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </VStack>
    )
}
