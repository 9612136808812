import { StackProps, useColorMode, VStack } from '@chakra-ui/react'
import { FC, PropsWithChildren } from 'react'

export type MenuHeaderProps = StackProps

export const MenuHeader: FC<PropsWithChildren<MenuHeaderProps>> = ({ children, ...props }) => {
    const { colorMode } = useColorMode()
    return (
        <VStack
            align="start"
            bg={`${colorMode}.tones.ghostWhite`}
            borderBottom="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
            borderTopRadius="8px"
            mt="-12px"
            mx="-12px"
            mb="12px"
            py="16px"
            px="24px"
            spacing="2px"
            {...props}
        >
            {children}
        </VStack>
    )
}
