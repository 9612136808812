import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useToast } from '@missionlabs/react'
import {
    $createRangeSelection,
    $getRoot,
    $getSelection,
    $insertNodes,
    $setSelection
} from 'lexical'
import { FC, useEffect } from 'react'
import { useUploadChatFileMutation } from 'shared/store'

import { $createUploadNode } from '../nodes/UploadNode'

export interface UploadPluginProps {}

export const ADD_CHAT_UPLOAD_EVENT = 'ADD_CHAT_UPLOAD_EVENT'
export const UPDATE_CHAT_UPLOAD_EVENT = 'UPDATE_CHAT_UPLOAD_EVENT'

export const dispatchUploadEvent = (
    ID: string,
    mime: string,
    body: string,
    fileName: string,
    userID: string,
    channelID: string
) => {
    return document.dispatchEvent(
        new CustomEvent(ADD_CHAT_UPLOAD_EVENT, {
            detail: { ID, mime, body, fileName, userID, channelID }
        })
    )
}

export const dispatchUpdateUploadEvent = (
    eventType: 'REMOVE' | 'COMPLETE',
    ID: string,
    uploadID?: string
) => {
    return document.dispatchEvent(
        new CustomEvent(UPDATE_CHAT_UPLOAD_EVENT, { detail: { ID, eventType, uploadID } })
    )
}

export const UploadPlugin: FC<UploadPluginProps> = () => {
    const [editor] = useLexicalComposerContext()
    const [uploadFile] = useUploadChatFileMutation()
    const { toast } = useToast()

    useEffect(() => {
        const insertUpload = e => {
            const { ID, mime, body, fileName, userID, channelID } = e.detail

            editor.update(() => {
                const currSelection = $getSelection()
                const rangeSelection = $createRangeSelection()
                $setSelection(rangeSelection)

                $insertNodes([$createUploadNode(ID, mime, body, fileName, false)])
                $setSelection(currSelection)

                const root = $getRoot()
                const nodes = root.getChildren()

                uploadFile({ body, fileName, userID, channelID })
                    .unwrap()
                    .then(res => {
                        const existingUpload = nodes.find(
                            n => n.__type === 'upload' && n.__ID === ID
                        )
                        if (!existingUpload) return
                        editor.update(() => {
                            existingUpload.replace(
                                $createUploadNode(
                                    existingUpload.__ID,
                                    existingUpload.__mime,
                                    existingUpload.__body,
                                    existingUpload.__fileName,
                                    true,
                                    res.ID
                                )
                            )
                        })
                    })
                    .catch(err => {
                        console.error('error uploading file', err)

                        // Remove the upload image preview
                        dispatchUpdateUploadEvent('REMOVE', ID)

                        // If we get a "payload too large" response
                        if (err?.status === 413) {
                            const maxSizeKb = err?.data?.limit ? err.data.limit / 1024 : undefined
                            const title = maxSizeKb
                                ? `File too large. The maximum file size is ${maxSizeKb}kb.`
                                : 'Error uploading file. Please try again.'
                            toast({ title, status: 'error' })
                        } else {
                            toast({
                                title: 'Error uploading file. Please try again.',
                                status: 'error'
                            })
                        }
                    })
            })
        }

        const updateUpload = e => {
            const { ID, eventType, uploadID } = e.detail

            editor.update(() => {
                const root = $getRoot()
                const nodes = root.getChildren()
                const existingUpload = nodes.find(n => n.__type === 'upload' && n.__ID === ID)

                if (!existingUpload) return
                if (eventType === 'REMOVE') return existingUpload.remove()
                if (eventType === 'COMPLETE') {
                    existingUpload.replace(
                        $createUploadNode(
                            existingUpload.__ID,
                            existingUpload.__mime,
                            existingUpload.__body,
                            existingUpload.__name,
                            true,
                            uploadID
                        )
                    )
                }
            })
        }

        document.addEventListener(ADD_CHAT_UPLOAD_EVENT, insertUpload)
        document.addEventListener(UPDATE_CHAT_UPLOAD_EVENT, updateUpload)
        return () => {
            document.removeEventListener(ADD_CHAT_UPLOAD_EVENT, insertUpload)
            document.removeEventListener(UPDATE_CHAT_UPLOAD_EVENT, updateUpload)
        }
    }, [editor, uploadFile, toast])

    return null
}
