import { HStack, useColorMode } from '@chakra-ui/react'
import { ContactDetail } from '@missionlabs/react'
import { ReplyIconSolid } from '@missionlabs/react/zeta'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface ChatHeaderUnreadThreadsProps {
    unreadThreadsCount: number
}

export const ChatHeaderUnreadThreads: FC<ChatHeaderUnreadThreadsProps> = ({
    unreadThreadsCount
}) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()
    const threadsString = t('chat.threads')

    return (
        <HStack
            spacing="10px"
            w="full"
            h="80px"
            px="24px"
            py="16px"
            align="center"
            justify="space-between"
            bg={`${colorMode}.tones.ghostWhite`}
            borderBottom="1px solid"
            borderColor={`${colorMode}.tones.periwinkle`}
        >
            <ContactDetail
                size="md"
                icon={<ReplyIconSolid p="3.5px" />}
                name={threadsString}
                subText={`${unreadThreadsCount} ${threadsString}`}
                containerSx={{ marginLeft: '8px' }}
            />
        </HStack>
    )
}
