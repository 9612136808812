import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const DateIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'DateIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <g clipPath="url(#clip0_5_71)">
                <path
                    d="M17.25 0C17.6642 0 18 0.335786 18 0.75V3H21.75C22.9926 3 24 4.00737 24 5.25V21.75C24 22.9926 22.9926 24 21.75 24H2.25C1.00737 24 0 22.9926 0 21.75V5.25C0 4.00737 1.00737 3 2.25 3H6V0.75C6 0.335786 6.33579 0 6.75 0C7.16421 0 7.5 0.335786 7.5 0.75V3H16.5V0.75C16.5 0.335786 16.8358 0 17.25 0ZM22.5 10.5H1.5V21.75C1.5 22.1642 1.83579 22.5 2.25 22.5H21.75C22.1642 22.5 22.5 22.1642 22.5 21.75V10.5ZM5.62456 18C6.24632 18 6.75 18.5037 6.75 19.125C6.75 19.7463 6.24632 20.25 5.625 20.25C5.00368 20.25 4.5 19.7463 4.5 19.125C4.5 18.5038 5.00344 18.0002 5.62456 18ZM11.9995 18C12.6213 18 13.125 18.5037 13.125 19.125C13.125 19.7463 12.6213 20.25 12 20.25C11.3787 20.25 10.875 19.7463 10.875 19.125C10.875 18.5039 11.3784 18.0003 11.9995 18ZM18.3746 18C18.9963 18 19.5 18.5037 19.5 19.125C19.5 19.7463 18.9963 20.25 18.375 20.25C17.7537 20.25 17.25 19.7463 17.25 19.125C17.25 18.5038 17.7534 18.0002 18.3746 18ZM5.62456 12.75C6.24632 12.75 6.75 13.2537 6.75 13.875C6.75 14.4963 6.24632 15 5.625 15C5.00368 15 4.5 14.4963 4.5 13.875C4.5 13.2538 5.00344 12.7502 5.62456 12.75ZM11.9995 12.75C12.6213 12.75 13.125 13.2537 13.125 13.875C13.125 14.4963 12.6213 15 12 15C11.3787 15 10.875 14.4963 10.875 13.875C10.875 13.2539 11.3784 12.7503 11.9995 12.75ZM18.3746 12.75C18.9963 12.75 19.5 13.2537 19.5 13.875C19.5 14.4963 18.9963 15 18.375 15C17.7537 15 17.25 14.4963 17.25 13.875C17.25 13.2538 17.7534 12.7502 18.3746 12.75ZM6 4.5H2.25C1.83579 4.5 1.5 4.83579 1.5 5.25V9H22.5V5.25C22.5 4.83579 22.1642 4.5 21.75 4.5H18V6C18 6.41421 17.6642 6.75 17.25 6.75C16.8358 6.75 16.5 6.41421 16.5 6V4.5H7.5V6C7.5 6.41421 7.16421 6.75 6.75 6.75C6.33579 6.75 6 6.41421 6 6V4.5Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_5_71">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </g>
    )
})
