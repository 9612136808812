import { useDisclosure } from '@chakra-ui/react'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { DocumentTitle } from 'shared/components/DocumentTitle'
import { MainLayout } from 'shared/components/MainLayout'

import {
    AddEditDrawerType,
    AddEditMeetingDrawer
} from './AddEditMeetingDrawer/AddEditMeetingDrawer'
import { MeetingsSidebar } from './MeetingsSidebar'

export const POLLING_INTERVAL = 10000

export interface MeetingDashboardProps {}

export const MeetingsDashboard: FC<MeetingDashboardProps> = () => {
    const location = useLocation()
    const meetingDrawer = useDisclosure()
    const [drawerType, setDrawerType] = useState<AddEditDrawerType>()

    const isScheduledMeetings = useMemo(() => {
        return location.pathname.includes('/meetings/day')
    }, [location.pathname])

    useEffect(() => {
        const end = location.pathname
            .split('/')
            .filter(p => p !== '')
            .pop()

        if (!end) return

        if (['new'].includes(end)) {
            meetingDrawer.onOpen()
            setDrawerType('CREATE')
        }
        if (['edit'].includes(end)) {
            meetingDrawer.onOpen()
            setDrawerType('EDIT')
        }
        if (['participants'].includes(end)) {
            meetingDrawer.onOpen()
            setDrawerType('SHARE')
        }

        return () => {
            meetingDrawer.onClose()
            setDrawerType(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    const onMeetingDrawerClose = useCallback(() => {
        meetingDrawer.onClose()
        setDrawerType(undefined)
    }, [meetingDrawer])

    if (!isScheduledMeetings) return <Outlet />
    return (
        <>
            <DocumentTitle breadcrumbs={['Meetings']} />

            <AddEditMeetingDrawer
                isOpen={meetingDrawer.isOpen}
                onClose={onMeetingDrawerClose}
                type={drawerType}
            />
            <MainLayout left={<MeetingsSidebar />} right={<Outlet />} />
        </>
    )
}
