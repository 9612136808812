import { useColorMode } from '@chakra-ui/react'
import {
    Body,
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay
} from '@missionlabs/react'
import { ScheduledMeeting } from '@missionlabs/types'
import { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthenticatedUser } from 'shared/hooks/useAuthenticatedUser'
import { useUpdateScheduledMeetingMutation } from 'shared/store'

type CancelMeetingDialogProps = {
    meeting: ScheduledMeeting
    isOpen: boolean
    onClose: () => void
}

export const CancelMeetingDialog: FC<CancelMeetingDialogProps> = ({ meeting, isOpen, onClose }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { data: user } = useAuthenticatedUser()
    const [cancelMeeting, { isLoading }] = useUpdateScheduledMeetingMutation()

    const ref = useRef<HTMLButtonElement>(null)

    const handleCancel = () => {
        cancelMeeting({ userID: user?.ID ?? '', meetingID: meeting.ID, meetingStatus: 'CANCELLED' })
    }

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>{t('meetings.RemoveMeeting')}</DialogHeader>
                <DialogBody gap="12px">
                    <Body size="sm" sx={{ color: `${colorMode}.tones.navy` }}>
                        {t('meetings.cancelMeetingWarning')}
                    </Body>
                </DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('meetings.removeMeeting'),
                        'data-testid': 'remove-meeting-btn',
                        isDestructive: true,
                        onClick: () => {
                            handleCancel()
                        }
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
