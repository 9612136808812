import { ChatMessage } from '@missionlabs/types'
import { DecoratorNode, EditorConfig, LexicalEditor, LexicalNode, NodeKey } from 'lexical'
import { ReactNode } from 'react'

import { EditMessage } from '../components/EditMessage'

export class EditMessageNode extends DecoratorNode<any> {
    __message: ChatMessage

    static getType(): string {
        return 'edit_message'
    }

    static clone(node: EditMessageNode): EditMessageNode {
        return new EditMessageNode(node.__message, node.__key)
    }

    constructor(message: ChatMessage, key?: NodeKey) {
        super(key)
        this.__message = message
    }

    createDOM(_config: EditorConfig): HTMLElement {
        const div = document.createElement('div')
        div.style.display = 'contents'
        return div
    }

    updateDOM(): false {
        return false
    }

    setURL(url: string): void {
        const writable = this.getWritable()
        writable.__url = url
    }

    decorate(_editor: LexicalEditor): ReactNode {
        return <EditMessage message={this.__message} />
    }

    isKeyboardSelectable(): boolean {
        return false
    }
}

export function $createEditMessageNode(message: ChatMessage): EditMessageNode {
    return new EditMessageNode(message)
}

export function $isEditMessageNodeNode(node: LexicalNode | null): boolean {
    return node instanceof EditMessageNode
}
