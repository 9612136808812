import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'

import { ActivityLogTable } from './ActivityLogTable'

export const ActivityLog: FC = () => {
    const { t } = useTranslation()

    return (
        <SettingsPage
            maxW="full"
            title={t('admin.activity.title')}
            subtitle={t('admin.activity.subtitle')}
        >
            <ActivityLogTable />
        </SettingsPage>
    )
}
