import { Box, Skeleton, useColorMode } from '@chakra-ui/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ChatListSection } from './ChatListSection'

export interface ChatListSkeletonProps {}

// todo: get counts?
export const ChatListSkeleton: FC<ChatListSkeletonProps> = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    return (
        <Box h="calc(100dvh - 65px)" w="full" px="16px" bg={`${colorMode}.tones.ghostWhite`}>
            <ChatListSection item={{ object: 'section', title: t('Pinned') }} />
            <Skeleton w="full" h="70px" />
            <ChatListSection item={{ object: 'section', title: t('Groups') }} />
            <Skeleton w="full" h="70px" />
            <ChatListSection item={{ object: 'section', title: t('Users') }} />
            <Skeleton w="full" h="70px" />
        </Box>
    )
}
