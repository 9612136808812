import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const VoicemailBubbleIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VoicemailBubbleIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M9.66667 10C9.66667 10.8285 8.97016 11.5 8.11111 11.5C7.25206 11.5 6.55556 10.8285 6.55556 10C6.55556 9.17154 7.25206 8.5 8.11111 8.5C8.97016 8.5 9.66667 9.17154 9.66667 10Z"
                fill="currentColor"
            />
            <path
                d="M17.4444 10C17.4444 10.8285 16.7479 11.5 15.8889 11.5C15.0298 11.5 14.3333 10.8285 14.3333 10C14.3333 9.17154 15.0298 8.5 15.8889 8.5C16.7479 8.5 17.4444 9.17154 17.4444 10Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 2C1.89543 2 1 2.89543 1 4V16C1 17.1046 1.89543 18 3 18H5V22L9 18H21C22.1046 18 23 17.1046 23 16V4C23 2.89543 22.1046 2 21 2H3ZM11.2222 10C11.2222 10.5464 11.0707 11.0587 10.806 11.5H13.194C12.9293 11.0587 12.7778 10.5464 12.7778 10C12.7778 8.34317 14.1708 7 15.8889 7C17.607 7 19 8.34317 19 10C19 11.6568 17.607 13 15.8889 13H8.11111C6.39301 13 5 11.6568 5 10C5 8.34317 6.39301 7 8.11111 7C9.82921 7 11.2222 8.34317 11.2222 10Z"
                fill="currentColor"
            />
        </g>
    )
})

export const VoicemailIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'VoicemailIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 11.5C9 13.433 7.433 15 5.5 15C3.567 15 2 13.433 2 11.5C2 9.567 3.567 8 5.5 8C7.433 8 9 9.567 9 11.5ZM9.74284 15C10.5282 14.0491 11 12.8296 11 11.5C11 8.46243 8.53757 6 5.5 6C2.46243 6 0 8.46243 0 11.5C0 14.369 2.19675 16.725 5 16.9776V17H5.5H18V16.9776C18.1647 16.9924 18.3315 17 18.5 17C21.5376 17 24 14.5376 24 11.5C24 8.46243 21.5376 6 18.5 6C15.4624 6 13 8.46243 13 11.5C13 12.8296 13.4718 14.0491 14.2572 15H9.74284ZM18.5 15C20.433 15 22 13.433 22 11.5C22 9.567 20.433 8 18.5 8C16.567 8 15 9.567 15 11.5C15 13.433 16.567 15 18.5 15Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_163"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="6"
                width="24"
                height="11"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 11.5C9 13.433 7.433 15 5.5 15C3.567 15 2 13.433 2 11.5C2 9.567 3.567 8 5.5 8C7.433 8 9 9.567 9 11.5ZM9.74284 15C10.5282 14.0491 11 12.8296 11 11.5C11 8.46243 8.53757 6 5.5 6C2.46243 6 0 8.46243 0 11.5C0 14.369 2.19675 16.725 5 16.9776V17H5.5H18V16.9776C18.1647 16.9924 18.3315 17 18.5 17C21.5376 17 24 14.5376 24 11.5C24 8.46243 21.5376 6 18.5 6C15.4624 6 13 8.46243 13 11.5C13 12.8296 13.4718 14.0491 14.2572 15H9.74284ZM18.5 15C20.433 15 22 13.433 22 11.5C22 9.567 20.433 8 18.5 8C16.567 8 15 9.567 15 11.5C15 13.433 16.567 15 18.5 15Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_163)"></g>
        </g>
    )
})
