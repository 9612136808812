import { Leaves } from '@missionlabs/types'
import i18next, { Resource } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import de from './de.json'
import en from './en.json'
import nl from './nl.json'

export type AllTranslations = Leaves<typeof en> | string

export enum TranslationLanguages {
    English = 'en',
    German = 'de',
    Dutch = 'nl'
}

const translations = {
    [TranslationLanguages.English]: en,
    [TranslationLanguages.German]: de,
    [TranslationLanguages.Dutch]: nl
}

const translationResources = Object.entries(translations).reduce((result, [language, values]) => {
    result[language] = { translation: values }
    return result
}, {} as Resource)

const i18n = i18next.createInstance()

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: translationResources,
        defaultNS: 'translation',
        fallbackLng: TranslationLanguages.English,
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        returnNull: false,
        returnEmptyString: false,
        compatibilityJSON: 'v3'
    })

export default i18n
