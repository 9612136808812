import { ButtonGroup, Divider, HStack } from '@chakra-ui/react'
import {
    $isListNode,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode,
    REMOVE_LIST_COMMAND
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $isHeadingNode } from '@lexical/rich-text'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import { IconButton } from '@missionlabs/react'
import {
    AttachIconRegular,
    BoldFormatIconRegular,
    ErrorIconRegular,
    GIFIconRegular,
    ItalicsIconRegular,
    ListIconRegular,
    SendIconSolid,
    SmileEmojiPlusIconRegular,
    UnderlineIconRegular
} from '@missionlabs/react/zeta'
import {
    $getRoot,
    $getSelection,
    $isRangeSelection,
    COMMAND_PRIORITY_LOW,
    FORMAT_TEXT_COMMAND,
    SELECTION_CHANGE_COMMAND
} from 'lexical'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { v4 } from 'uuid'

import { TOGGLE_EMOJI_PICKER_COMMAND } from './EmojiPickerPlugin'
import { SEND_MESSAGE_COMMAND } from './SendMessagePlugin'
import { dispatchUploadEvent } from './UploadPlugin'

export interface ToolbarPluginProps {
    channelID: string
    userID: string
}

export const ToolbarPlugin: FC<ToolbarPluginProps> = ({ channelID, userID }) => {
    const [editor] = useLexicalComposerContext()

    const [isDirty, setIsDirty] = useState(false)
    const [isBold, setIsBold] = useState(false)
    const [isItalic, setIsItalic] = useState(false)
    const [isUnderline, setIsUnderline] = useState(false)
    const [isList, setIsList] = useState(false)

    function toggleUnorderedList() {
        if (!isList) editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
        else editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }

    const updateToolbar = useCallback(() => {
        // Update dirty state
        const root = $getRoot()
        const isAttachment = root.getChildren().find(n => n.__type === 'upload')

        if (root.getAllTextNodes().length === 0 && isAttachment) setIsDirty(true)
        else setIsDirty(root.getAllTextNodes().length > 0)

        // Update text format
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode()
            const element =
                anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow()
            const elementKey = element.getKey()
            const elementDOM = editor.getElementByKey(elementKey)
            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode)
                    const type = parentList ? parentList.getTag() : element.getTag()
                    setIsList(['ol', 'ul'].includes(type))
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType()
                    setIsList(['ol', 'ul'].includes(type))
                }
            }

            setIsBold(selection.hasFormat('bold'))
            setIsItalic(selection.hasFormat('italic'))
            setIsUnderline(selection.hasFormat('underline'))
        }
    }, [editor])

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(updateToolbar)
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateToolbar()
                    return true
                },
                COMMAND_PRIORITY_LOW
            )
        )
    }, [editor, updateToolbar])

    const inputRef = useRef<HTMLInputElement>(null)

    const handleUpload = e => {
        if (!e.target?.files?.length) return
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            dispatchUploadEvent(v4(), file.type, reader.result || '', file.name, userID, channelID)
        }
    }

    return (
        <HStack
            spacing={0}
            py="8px"
            w="full"
            h="full"
            justify="space-between"
            data-testid="chat-toolbar"
        >
            <HStack spacing="8px">
                <input
                    onChange={handleUpload}
                    type="file"
                    id="file"
                    ref={inputRef}
                    style={{ display: 'none' }}
                    accept="image/*,audio/*,video/webm"
                />
                <IconButton
                    size="sm"
                    variant="ghost"
                    aria-label="Attach file"
                    onClick={() => inputRef?.current?.click()}
                >
                    <AttachIconRegular boxSize="24px" p="4px" />
                </IconButton>
                <Divider orientation="vertical" h="32px" />
                <ButtonGroup spacing={0}>
                    <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Add emoji"
                        onClick={() =>
                            editor.dispatchCommand(TOGGLE_EMOJI_PICKER_COMMAND, undefined)
                        }
                    >
                        <SmileEmojiPlusIconRegular boxSize="24px" p="4px" />
                    </IconButton>
                    <IconButton size="sm" variant="ghost" aria-label="Add gif">
                        <GIFIconRegular boxSize="24px" p="4px" />
                    </IconButton>
                </ButtonGroup>
                <Divider orientation="vertical" h="32px" />
                <ButtonGroup spacing={0}>
                    <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Format bold"
                        isActive={isBold}
                        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
                    >
                        <BoldFormatIconRegular boxSize="24px" p="4.8px" />
                    </IconButton>
                    <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Format bold"
                        isActive={isItalic}
                        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
                    >
                        <ItalicsIconRegular boxSize="24px" p="4.8px" />
                    </IconButton>
                    <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Format bold"
                        isActive={isUnderline}
                        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')}
                    >
                        <UnderlineIconRegular boxSize="24px" p="4px" />
                    </IconButton>
                    <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Format bulleted list"
                        isActive={isList}
                        onClick={toggleUnorderedList}
                    >
                        <ListIconRegular boxSize="24px" p="4px" />
                    </IconButton>
                </ButtonGroup>
                <Divider orientation="vertical" h="32px" />
                {/* todo: aria-label/logic */}
                <IconButton size="sm" variant="ghost" aria-label="_todo_">
                    <ErrorIconRegular boxSize="24px" p="3.2px" />
                </IconButton>
            </HStack>
            <IconButton
                size="sm"
                variant="ghost"
                isActive={isDirty}
                isDisabled={!isDirty}
                aria-label="Send message"
                onClick={() => editor.dispatchCommand(SEND_MESSAGE_COMMAND, undefined)}
            >
                <SendIconSolid boxSize="24px" p="4px" />
            </IconButton>
        </HStack>
    )
}
