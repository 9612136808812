import { darkTheme, lightTheme } from 'amazon-chime-sdk-component-library-react'

import { light as lightButtons } from './buttons'
import { dark as darkChat, light as lightChat } from './chat'
import { light as lightControlBar } from './controlBar'

export const demoLightTheme = {
    ...lightTheme,
    videoGrid: {
        ...lightTheme.videoGrid,
        bgd: '#1B1F3B'
    },
    chat: lightChat,
    buttons: lightButtons,
    controlBar: lightControlBar
}

export const demoDarkTheme = {
    ...darkTheme,
    chat: darkChat
}
