import { Flex, HStack, SimpleGrid, Spacer, useColorMode, VStack } from '@chakra-ui/react'
import {
    Call,
    cancelCallTransfer,
    confirmCallTransfer,
    nWayHangup,
    useDispatch
} from '@missionlabs/api'
import {
    Avatar,
    Body,
    IconButton,
    Tooltip,
    useFormatToNumberE164,
    useGetCallDuration
} from '@missionlabs/react'
import {
    ArrowDownRightIcon,
    CloseCircleIcon,
    IncomingChannelIcon,
    PhoneHangupIcon
} from '@missionlabs/react/circleloop'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCallDetails } from 'shared/hooks/useCallDetails'

import { BlindTransferButton } from './CallButtons/BlindTransferButton'
import { ConfirmTransferButton } from './CallButtons/ConfirmTransferButton'

interface CallDetailsProps {
    call: Call
    primaryAction?: 'hangup' | 'transfer'
    onHangup?: (callTraceID: string) => void
    onTransfer?: () => void
    onTransferComplete?: () => void
    isSelectingTransfer?: boolean
}

export const CallDetails: FC<CallDetailsProps> = ({
    call,
    primaryAction = 'hangup',
    onHangup: _onHangup,
    onTransfer,
    onTransferComplete,
    isSelectingTransfer
}) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { formatToLocalNumber } = useFormatToNumberE164()

    const { contactName, number, startTime, company } = useCallDetails(call)
    const duration = useGetCallDuration(call.startTimestamp, true)

    const isTransferring = call?.status === 'TRANSFERRING'

    const { isTransfer, transferType, direction, nWayMembers } = call

    const isTransferer = useMemo(() => {
        if (!isTransferring) return false

        if (!isTransfer) return true

        return isTransfer && transferType === 'blind' && direction === 'inbound'
    }, [isTransferring, isTransfer, transferType, direction])

    const isTransferee = useMemo(() => {
        if (isTransferer) return false

        return isTransfer && transferType !== 'blind'
    }, [isTransferer, isTransfer, transferType])

    const onCancelTransfer = () => {
        dispatch(cancelCallTransfer({ callTraceID: call.callTraceID }))
        onTransferComplete?.()
    }

    const onHangup = useCallback(() => {
        dispatch(confirmCallTransfer({ callTraceID: call.callTraceID }))
        _onHangup?.(call.callTraceID)
        onTransferComplete?.()
    }, [_onHangup, call.callTraceID, dispatch, onTransferComplete])

    const onHangupPerson = useCallback(
        (legID: string) => {
            dispatch(nWayHangup({ callTraceID: call.callTraceID, legID }))
        },
        [call.callTraceID, dispatch]
    )

    const { transfereeName, transfereeNumber, transferrCompanyName } = useMemo(() => {
        return {
            transfereeName: call.transferToContact?.fullName,
            transferrCompanyName: call.transfererCompanyName,
            transfereeNumber: call.transfereeNumber
        }
    }, [call])

    const { transfererContactName, transfererNumber } = useMemo(() => {
        return {
            transfererContactName: call.transfererContactName,
            transfererNumber: call.transfererNumber
        }
    }, [call])

    const PrimaryAction = useMemo(() => {
        if (isTransferee) return null
        if (isTransferer) {
            // Show the confirm transfer button regardless of primary action
            return <ConfirmTransferButton onConfirm={onHangup} />
        }
        switch (primaryAction) {
            case 'hangup': {
                return <HangupButton onHangup={onHangup} />
            }
            case 'transfer': {
                return (
                    <BlindTransferButton
                        onToggle={onTransfer}
                        transferring={isSelectingTransfer}
                        isRounded
                    />
                )
            }
            default:
                return null
        }
    }, [primaryAction, onHangup, onTransfer, isSelectingTransfer, isTransferee, isTransferer])

    return (
        <VStack w="full" spacing="0">
            <SimpleGrid gridTemplateColumns="1fr 7fr 1fr" gap="8px" w="full">
                <Avatar size="md" name={contactName} variant="teal" />

                <VStack spacing={0} align="flex-start">
                    <Body size="md" color="white">
                        {contactName}
                    </Body>
                    {company && (
                        <Body size="sm" color={`${colorMode}.tones.periwinkle`}>
                            {company}
                        </Body>
                    )}
                    {contactName !== number && (
                        <Body size="sm" color={`${colorMode}.tones.periwinkle`}>
                            {number}
                        </Body>
                    )}
                </VStack>

                {PrimaryAction}
            </SimpleGrid>
            {isTransferer && (
                <HStack m="12px" spacing="8px" w="full">
                    <ArrowDownRightIcon
                        color={`${colorMode}.tones.periwinkle`}
                        mb="auto"
                        mt="8px"
                        aria-label="Transferring call"
                    />
                    <VStack spacing="0" align="flex-start">
                        <Body size="sm" color="white">
                            {t('Transferring to')}
                        </Body>
                        <Body size="md" color="white">
                            {transfereeName}
                        </Body>
                        <Body size="sm" color={`${colorMode}.tones.periwinkle`}>
                            {transferrCompanyName}
                        </Body>
                        <Body size="sm" color={`${colorMode}.tones.periwinkle`}>
                            {transfereeNumber}
                        </Body>
                    </VStack>
                    <Spacer />
                    <Tooltip label={t('Cancel transfer')}>
                        <IconButton
                            size="sm"
                            isRounded
                            icon={<CloseCircleIcon boxSize="16px" opacity="0.75" color="white" />}
                            variant="negatory"
                            aria-label={t('Cancel transfer')}
                            onClick={onCancelTransfer}
                        />
                    </Tooltip>
                </HStack>
            )}
            {isTransferee && (
                <HStack m="12px" spacing="8px" w="full">
                    <ArrowDownRightIcon
                        color={`${colorMode}.tones.periwinkle`}
                        mb="auto"
                        mt="8px"
                        aria-label="Transferring call"
                    />
                    <VStack spacing="0" align="flex-start">
                        <Body size="sm" color="white">
                            {t('Transferring from')}
                        </Body>
                        <Body size="md" color="white">
                            {transfererContactName}
                        </Body>
                        <Body size="sm" color={`${colorMode}.tones.periwinkle`}>
                            {transferrCompanyName}
                        </Body>
                        <Body size="sm" color={`${colorMode}.tones.periwinkle`}>
                            {transfererNumber}
                        </Body>
                    </VStack>
                    <Spacer />
                    <Tooltip label={t('Cancel transfer')}>
                        <IconButton
                            size="sm"
                            isRounded
                            icon={<CloseCircleIcon boxSize="16px" opacity="0.75" color="white" />}
                            variant="negatory"
                            aria-label={t('Cancel transfer')}
                            onClick={onCancelTransfer}
                        />
                    </Tooltip>
                </HStack>
            )}
            {nWayMembers?.length &&
                nWayMembers.map(nWayMember => (
                    <HStack m="12px" spacing="8px" w="full" key={nWayMember.legID}>
                        <IncomingChannelIcon
                            color={`${colorMode}.tones.periwinkle`}
                            mb="auto"
                            mt="12px"
                            ml="12px"
                            aria-label="Transferring call"
                        />

                        <Avatar size="md" name={nWayMember.displayName} variant="teal" />

                        <VStack spacing="0" align="flex-start">
                            <Body size="md" color="white">
                                {nWayMember.displayName ||
                                    formatToLocalNumber(nWayMember.numberE164)}
                            </Body>
                            <Body size="sm" color={`${colorMode}.tones.periwinkle`}>
                                {nWayMember.companyName}
                            </Body>
                        </VStack>
                        <Spacer />
                        <Tooltip
                            label={`Hang up ${
                                nWayMember.displayName || formatToLocalNumber(nWayMember.numberE164)
                            }`}
                        >
                            <IconButton
                                size="sm"
                                icon={<PhoneHangupIcon color="white" opacity="0.75" />}
                                isRounded
                                boxSize="48px"
                                variant="negatory-outline"
                                aria-label="hangup"
                                onClick={() => onHangupPerson(nWayMember.legID)}
                            />
                        </Tooltip>
                    </HStack>
                ))}
            <Flex mt="12px" w="100%" align="center" justify="space-between">
                {startTime && (
                    <Body size="sm" color={`${colorMode}.tones.periwinkle`}>
                        <span>{t('call.startedAt')} </span>
                        <span>{startTime}</span>
                    </Body>
                )}
                <Body size="lg" color="white">
                    {duration}
                </Body>
            </Flex>
        </VStack>
    )
}

function HangupButton({ onHangup }: { onHangup?: () => void }) {
    return (
        <IconButton
            icon={<PhoneHangupIcon color="white" boxSize="20px" opacity="0.75" />}
            isRounded
            boxSize="48px"
            variant="negatory"
            borderRadius="100%"
            aria-label="hangup"
            onClick={onHangup}
        />
    )
}
