export const colors = {
    light: {
        primary: {
            gamma: '#6B49F1',
            gamma16: '#E9E3FD',
            'gamma.alpha-10': '#6B49F119',
            gammaHover: '#3A26BF'
        },
        alerts: {
            pink: '#DA0075',
            pinkDark: '#BB005F',
            aqua: '#3EBC9E',
            algae: '#00926F',
            selectiveYellow: '#FFBC00',
            rose: '#DF0080',
            rose24: '#F7C2E1',
            jazberryJam: '#A80054',
            turquoise: '#33E8C8',
            turquoise24: '#CEF9F2',
            redLight: '#FEE8E5',
            red: '#6B49F1',
            redDark: '#6B49F1',
            redHover: '#3A26BF'
        },
        tones: {
            firmament: '#1F2446',
            lemonChiffon: '#FFFACD',
            white: '#FFFFFF',
            'white.alpha-10': '#FFFFFF19',
            whiteLilac: '#ECEDF8',
            ghostWhite: '#F7F8FD',
            periwinkle: '#CACEE9',
            stormGrey: '#6E7090',
            navy: '#2E3667',
            'navy.alpha-20': '#2E366733',
            midnightExpress: '#1B1F3B',
            'midnightExpress.alpha-75': '#1B1F3BC0',
            vantaBlue: '#0A0C1C',
            facebook: '#1877f2',
            facebookDark: '#0A5A9C'
        },
        avatar: {
            red: '#E84040',
            bloodOrange: '#E65110',
            orange: '#F0811B',
            amber: '#F09B1B',
            yellow: '#FFBC00',
            lime: '#CCD006',
            green: '#66BC3E',
            aqua: '#3EBC9E',
            teal: '#3E9EBC',
            blue: '#34AAFF',
            indigo: '#346DFF',
            purple: '#7653F3',
            violet: '#AC64E3',
            pink: '#EC75F6',
            hotPink: '#FB49AF',
            rose: '#FB4974'
        }
    }
}
