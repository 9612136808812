import { CallStatus } from './CallController'
import { LiveServices, LiveServicesConfig } from './LiveServices'
import { CallDetails } from './LiveServicesSignaller'
import webrtc from './webrtc'

export class CallSocket extends LiveServices {
    callTraceID: string
    callStatus: CallStatus
    candidates: any[]
    wrtc?: webrtc
    calldetails?: CallDetails
    sessionID?: string
    handleID?: string
    remoteOffer?: any
    callStats?: any
    onrecordingfinished: any
    transfersEnabled?: any
    recordingEnabled?: any

    constructor(config: LiveServicesConfig, callTraceID: string) {
        super(config)
        this.callTraceID = callTraceID
        this.candidates = []
        this.callStatus = 'NO_CALL'
    }
}
