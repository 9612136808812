import {
    Tag as ChakraTag,
    TagCloseButton,
    TagLabel,
    TagProps as ChakraTagProps
} from '@chakra-ui/react'
import { IconButton } from 'atoms/IconButton/IconButton'
import { XIconRegular } from 'atoms/Icons/zeta'
import { forwardRef } from 'react'

export interface TagProps extends Omit<ChakraTagProps, 'size' | 'variant'> {
    label: string
    variant?:
        | 'grey'
        | 'white'
        | 'blue'
        | 'teal'
        | 'gold'
        | 'red'
        | 'purple'
        | 'darkGrey'
        | 'whiteLilac'
        | 'stormGrey'
        | 'navy'
        | 'gamma'
        | 'algae'
        | 'jazberry'
    onClose?: () => void
}

export const Tag = forwardRef<HTMLSpanElement, TagProps>(({ label, onClose, ...props }, ref) => {
    return (
        <ChakraTag {...props} ref={ref}>
            <TagLabel color="inherit">{label}</TagLabel>
            {!!onClose && (
                <TagCloseButton
                    as={IconButton}
                    variant="transparent"
                    onClick={e => {
                        // Prevent the Tag's "onClick" handler from being called when the close button is clicked
                        e.stopPropagation()
                        onClose?.()
                    }}
                    aria-label={`Remove ${label}`}
                    data-testid="remove-tag"
                >
                    <XIconRegular p="3.33px" />
                </TagCloseButton>
            )}
        </ChakraTag>
    )
})
