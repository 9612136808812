import {
    closeAllCallSockets,
    closeSocket,
    logoutSuccess,
    updateConnection,
    useDispatch
} from '@missionlabs/api'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { clearAutoLogin } from './useAutoLogin'

export const useOnLogout = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleLogout = useCallback(() => {
        dispatch(closeAllCallSockets())
        dispatch(closeSocket())
        dispatch(updateConnection({ status: 'NOT_CONNECTED' }))

        dispatch(logoutSuccess())
        clearAutoLogin()
        navigate('/')
    }, [dispatch, navigate])

    return { handleLogout }
}
