import { UserRole } from '@missionlabs/types'
import { getRole } from '@missionlabs/utils'
import { useAuthenticatedUser } from 'shared/hooks/useAuthenticatedUser'

const DEFAULT_ROLE = UserRole.END_USER

export function useUserRole() {
    const { data: user } = useAuthenticatedUser()
    return getRole(user?.type ?? '') ?? DEFAULT_ROLE
}
