import { selectAuthEnvironment, useSelector } from '@missionlabs/api'
import { DocumentTitle as Title, DocumentTitleProps } from '@missionlabs/react'
import { DeploymentEnvironments } from '@missionlabs/types'
import { FC, useMemo } from 'react'
import { AppConfig } from 'shared/utils/env'

export const DocumentTitle: FC<Partial<DocumentTitleProps>> = props => {
    const { environment } = useSelector(selectAuthEnvironment)

    const base = useMemo(() => {
        switch (environment) {
            case DeploymentEnvironments.dev:
                return `${AppConfig.name} Dev`
            case DeploymentEnvironments.uat:
                return `${AppConfig.name} UAT`
            default:
                return AppConfig.name
        }
    }, [environment])

    return <Title base={base} {...props} />
}
