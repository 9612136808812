import { Skeleton } from '@chakra-ui/react'
import { ContactSkeleton, ListItem, ListTitle } from '@missionlabs/react'
import { FC } from 'react'

interface ContactsSkeletonProps {
    number?: number
}

export const ContactsSkeleton: FC<ContactsSkeletonProps> = ({ number = 2 }) => {
    const skeletons = Array.from({ length: number }, (_, i) => (
        <div style={{ padding: '0px 24px', marginBottom: '-1px' }} role="listitem" key={i}>
            <ListItem>
                <ContactSkeleton />
            </ListItem>
        </div>
    ))
    return (
        <div>
            <ListTitle>
                <Skeleton height="22px" width="20px" />
            </ListTitle>
            {skeletons}
        </div>
    )
}
