import { Heading, HStack, Skeleton, StackDivider, useColorMode, VStack } from '@chakra-ui/react'
import { Button } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useGetWhatsAppBusinessQuery } from 'shared/store'

export const BusinessInfo: FC = () => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()
    const { data: business, isLoading, isFetching } = useGetWhatsAppBusinessQuery()
    const loading = isLoading || isFetching
    const navigate = useNavigate()

    return (
        <HStack
            spacing={0}
            align="start"
            py="24px"
            border="1px solid"
            borderColor={`${colorMode}.coolGrey.30`}
            borderRadius="4px"
            data-testid="billing-overview"
            divider={<StackDivider borderColor={`${colorMode}.coolGrey.30`} />}
        >
            <VStack spacing="16px" px="32px" align="center">
                <Heading size="h3" color={`${colorMode}.coolGrey.80`}>
                    {t('admin.whatsapp.businessName')}
                </Heading>
                <Skeleton h="36px" w="full" isLoaded={!loading}>
                    <Heading
                        size="h3"
                        color={`${colorMode}.coolGrey.80`}
                        aria-label={t('admin.whatsapp.businessName')}
                    >
                        {business?.name}
                    </Heading>
                </Skeleton>
            </VStack>
            <VStack spacing="16px" px="32px" align="center">
                <Heading size="h3" color={`${colorMode}.coolGrey.80`}>
                    {t('admin.whatsapp.businessID')}
                </Heading>
                <Skeleton h="36px" w="full" isLoaded={!loading}>
                    <Heading
                        size="h3"
                        color={`${colorMode}.coolGrey.80`}
                        aria-label={t('admin.whatsapp.businessID')}
                    >
                        {business?.wabaid}
                    </Heading>
                </Skeleton>
            </VStack>
            <VStack spacing="16px" px="32px" align="center" justify="center">
                <Skeleton h="36px" w="full" isLoaded={!loading}>
                    <Button
                        variant="outline"
                        colorScheme="red"
                        onClick={() => navigate('/admin/whatsapp/disconnect')}
                    >
                        {t('admin.whatsapp.info.disconnect')}
                    </Button>
                </Skeleton>
            </VStack>
        </HStack>
    )
}
