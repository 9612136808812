import { selectAuthenticatedUser, selectInData, useSelector } from '@missionlabs/api'
import { AudioClip, Option } from '@missionlabs/types'
import { useGetUserAudioClipsQuery } from 'shared/store'

const selectAllRecordings = selectInData<AudioClip[], Option[]>(data =>
    data?.map(({ label, URL }) => ({ value: URL, label }))
)

export function useUserAndSharedAudioClips(): Option[] {
    const user = useSelector(selectAuthenticatedUser)
    const { allClips = [] } = useGetUserAudioClipsQuery(user?.userID ?? '', {
        skip: !user,
        selectFromResult: result => ({ ...result, allClips: selectAllRecordings(result) })
    })
    return allClips
}
