import { HStack, useColorMode, VStack } from '@chakra-ui/react'
import { Body, Toggle } from '@missionlabs/react'
import { useTranslation } from 'react-i18next'

interface AnonymousCallsProps {
    anonymousCallSetting: boolean
    onChange(name: string, value: boolean, options?: Record<string, any>): void
}

export const AdminAnonymousCallSetting: React.FC<AnonymousCallsProps> = ({
    anonymousCallSetting,
    onChange
}) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()

    return (
        <>
            <VStack align="flex-start" gap={2} w="100%">
                <HStack justify="space-between" w="100%">
                    <Body variant="bold">{t('admin.anonymousCalls.label')}</Body>
                    <Toggle
                        isChecked={anonymousCallSetting}
                        onChange={e =>
                            onChange('blockAnonymousCalls', e.target.checked, {
                                shouldDirty: true
                            })
                        }
                    />
                </HStack>
                <Body size="sm" variant="bold" color={`${colorMode}.tones.stormGrey`}>
                    {t('admin.anonymousCalls.description')}
                </Body>
            </VStack>
        </>
    )
}
