import { Box, Link as ChakraLink, Text, useColorMode } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import backgroundImage from 'assets/images/features-numbers-instantly.png'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AuthLayout } from 'shared/components/AuthLayout'
import { AppConfig } from 'shared/utils/env'

export interface WelcomeErrorProps {}

export const WelcomeError: FC<WelcomeErrorProps> = () => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    return (
        <AuthLayout label={t('welcome.error.title')} contentWidth="512px" image={backgroundImage}>
            <Body size="sm" alignSelf="start" sx={{ color: `${colorMode}.tones.navy` }}>
                {t('welcome.error.text', { appName: AppConfig.name })}
            </Body>
            <Box alignSelf="center" pt="8px">
                <ChakraLink as={Link} to="/login">
                    <Text variant="link-bold">{t('welcome.error.backToSignIn')}</Text>
                </ChakraLink>
            </Box>
        </AuthLayout>
    )
}
