import {
    Box,
    Divider,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerOverlay,
    HStack,
    useDisclosure,
    VStack
} from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    Body,
    Button,
    DrawerHeader,
    FormProvider,
    Input,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useToast,
    useYupForm,
    ValidatedInput
} from '@missionlabs/react'
import { IVRMenu } from '@missionlabs/types'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { FeatureComponent, Features } from 'shared/components/FeatureComponent'
import { AdminAnonymousCallSetting } from 'shared/components/settings/AdminAnonymousCallSetting'
import { useGetIVRMenuQuery, useGetUsersQuery, useUpdateIVRMenuMutation } from 'shared/store'
import { buildNotAvailable } from 'shared/utils/notAvailable'

import { AssignedNumbers } from '../../../components/AssignedNumbers'
import { AddEditTeamMembers } from '../../Teams/AddEditTeam/AddEditTeamMembers'
import { DeleteMenuDialog } from '../DeleteMenuDialog'
import { IVRMenuOptions } from '../IVRMenuOptions'
import { defaultMenuValues, MenuFormFields, menuSchema } from '../menu.schema'
import { MenuOutOfHours } from '../MenuOutOfHours'
import { UserExtensions } from '../UserExtensions'
import { WaitMusic } from '../WaitMusic'
import { WelcomeGreeting } from '../WelcomeGreeting'

export interface EditMenuDrawerProps {}

const noop = () => {}

function getValues(menu?: IVRMenu): MenuFormFields | undefined {
    if (!menu) return
    return {
        name: menu.name,
        waitMusic: menu.waitMusic,
        customWaitMusicURL: menu.customWaitMusicURL,
        IVR: {
            greeting: menu.IVR.greeting,
            greetingEnabled: true, // Should never be disabled
            extensions: menu.IVR.extensions as MenuFormFields['IVR']['extensions']
        },
        notAvailable: menu.notAvailable,
        callTimeout: menu.callTimeout ?? 10,
        callRoute: {
            outOfHours: menu.callRoute.outOfHours ?? 'voicemail',
            teamMembers: menu.callRoute.teamMembers
        },
        outOfHoursRedirectInternal: menu.outOfHoursRedirectInternal || undefined,
        outOfHoursRedirect: menu.outOfHoursRedirect,
        outOfHoursVoicemail: menu.outOfHoursVoicemail,
        blockAnonymousCalls: menu.blockAnonymousCalls
    }
}

export const EditMenuDrawer: FC<EditMenuDrawerProps> = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id: menuID = '' } = useParams()
    const { toast } = useToast()

    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
    const deleteModal = useDisclosure()

    const { data: menu, isLoading: isLoadingMenu } = useGetIVRMenuQuery(menuID, { skip: !menuID })
    const [updateMenu, { isLoading: isUpdatingMenu }] = useUpdateIVRMenuMutation()
    const isLoading = isLoadingMenu || isUpdatingMenu

    const { data: users = [] } = useGetUsersQuery()

    const values = useMemo(() => getValues(menu), [menu])
    const methods = useYupForm<MenuFormFields>(menuSchema, {
        shouldFocusError: true,
        values,
        defaultValues: defaultMenuValues
    })

    const {
        formState: { isDirty, isSubmitting },
        watch,
        setValue
    } = methods

    const user = useSelector(selectAuthenticatedUser)

    function handleTransition() {
        navigate('/admin/menus')
    }

    const handleSubmit = async (data: MenuFormFields) => {
        if (!user || !menu) return
        try {
            await updateMenu({
                ...menu,
                ...data,
                notAvailable: buildNotAvailable({ ...data?.notAvailable }),
                IVR: { ...menu.IVR, ...data.IVR },
                callRoute: {
                    ...menu.callRoute,
                    ...data.callRoute
                }
            }).unwrap()
            // TODO: translate
            toast({ status: 'success', title: `${data.name} updated` })
            return onClose()
        } catch (e) {
            toast({
                title: t('menu.menuError'),
                status: 'error'
            })
            console.log(e)
        }
    }

    if (!menu) return null

    return (
        <Drawer
            size="lg"
            isOpen={isOpen}
            onClose={!isLoading ? onClose : noop}
            onCloseComplete={handleTransition}
        >
            <DrawerOverlay />
            <DrawerContent>
                <FormProvider {...methods}>
                    <form
                        onSubmit={methods.handleSubmit(handleSubmit, errors => {
                            console.log({ errors })
                        })}
                    >
                        <DrawerHeader>{t('menus.editMenu')}</DrawerHeader>
                        <DrawerBody>
                            <Box maxH="calc(100vh - 159px)">
                                <Tabs size="md" h="full" isLazy isFitted>
                                    <TabList mx="24px">
                                        <Tab>{t('Details')}</Tab>
                                        <Tab>
                                            {t('Users')} ({watch('callRoute.teamMembers').length})
                                        </Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            <VStack
                                                p={6}
                                                align="stretch"
                                                spacing={6}
                                                id="edit-menu-drawer"
                                            >
                                                <VStack align="flex-start">
                                                    <Body id="menu-name">{t('Name')}</Body>
                                                    <ValidatedInput
                                                        name="name"
                                                        aria-labelledby="menu-name"
                                                    >
                                                        <Input autoComplete="off" />
                                                    </ValidatedInput>
                                                </VStack>

                                                <AssignedNumbers numbers={menu.phoneNumbers} />

                                                <Divider />
                                                <IVRMenuOptions
                                                    extensions={watch('IVR.extensions')}
                                                    onUpdateExtensions={extensions => {
                                                        setValue('IVR.extensions', extensions, {
                                                            shouldDirty: true
                                                        })
                                                    }}
                                                />

                                                <FeatureComponent
                                                    feature={Features.user_extensions}
                                                >
                                                    <Divider />
                                                    <UserExtensions
                                                        extensions={watch('IVR.extensions')}
                                                        onUpdateExtensions={extensions => {
                                                            setValue('IVR.extensions', extensions, {
                                                                shouldDirty: true
                                                            })
                                                        }}
                                                    />
                                                </FeatureComponent>

                                                <Divider />
                                                <WelcomeGreeting
                                                    greetingUrl={watch('IVR.greeting')}
                                                    onChangeGreetingUrl={url => {
                                                        setValue('IVR.greeting', url, {
                                                            shouldDirty: true
                                                        })
                                                    }}
                                                />

                                                <Divider />
                                                <WaitMusic
                                                    enabled={watch('waitMusic')}
                                                    waitMusicUrl={watch('customWaitMusicURL')}
                                                    onToggle={isChecked => {
                                                        setValue('waitMusic', isChecked, {
                                                            shouldDirty: true
                                                        })
                                                    }}
                                                    onChangeWaitMusicUrl={url => {
                                                        setValue('customWaitMusicURL', url, {
                                                            shouldDirty: true
                                                        })
                                                    }}
                                                />

                                                <Divider />
                                                <MenuOutOfHours watch={watch} setValue={setValue} />

                                                <Divider />
                                                <AdminAnonymousCallSetting
                                                    onChange={setValue}
                                                    anonymousCallSetting={watch(
                                                        'blockAnonymousCalls'
                                                    )}
                                                />
                                            </VStack>
                                        </TabPanel>
                                        <TabPanel>
                                            <Body p="24px" size="sm">
                                                {t('menus.notificationUsers')}
                                            </Body>
                                            <AddEditTeamMembers pt="0" users={users} />
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Box>
                        </DrawerBody>
                        <DrawerFooter p={6}>
                            <HStack w="full" spacing={4}>
                                <Button
                                    variant="negatory-outline"
                                    w="full"
                                    onClick={deleteModal.onOpen}
                                >
                                    {t('Remove')}
                                </Button>
                                <Button variant="secondary" w="full" onClick={onClose}>
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    variant="creationary"
                                    w="full"
                                    isDisabled={!isDirty}
                                    isLoading={isSubmitting}
                                >
                                    {t('Save')}
                                </Button>
                            </HStack>
                        </DrawerFooter>
                    </form>
                </FormProvider>
            </DrawerContent>
            <DeleteMenuDialog menu={menu} {...deleteModal} />
        </Drawer>
    )
}
