import { HStack, useMultiStyleConfig, VStack } from '@chakra-ui/react'
import { IconButton } from 'atoms'
import {
    HangupIconSolid,
    MessageIconRegular,
    PhoneIconSolid,
    TransferIconRegular
} from 'atoms/Icons/zeta'
import { ContactDetail, ContactDetailProps } from 'molecules/ContactDetail/ContactDetail'
import { forwardRef, ReactNode } from 'react'

type RingingCallProps = ContactDetailProps & {
    company?: ReactNode
    dualCall?: { name: string; company: string }
    onHangup?: () => void
    onTransfer?: () => void
    onMessage?: () => void
    onAnswer?: () => void
}

export const RingingCall = forwardRef<HTMLDivElement, RingingCallProps>(
    (
        {
            name,
            company,
            via,
            transfer,
            dualCall,
            onHangup,
            onTransfer,
            onMessage,
            onAnswer,
            ...props
        },
        ref
    ) => {
        const { container, dualCallContainer } = useMultiStyleConfig('RingingCall')

        return (
            <VStack sx={container} ref={ref} spacing={0} data-testid={props['data-testid']}>
                <VStack spacing="8px" w="full">
                    <ContactDetail
                        size="xl"
                        name={name}
                        role={company}
                        via={via}
                        transfer={transfer}
                        direction={dualCall ? 'horizontal' : 'vertical'}
                        sx={dualCall ? { alignSelf: 'start' } : undefined}
                    />

                    {dualCall && (
                        <ContactDetail
                            name={dualCall.name}
                            role={dualCall.company}
                            via={via}
                            direction="horizontal"
                            size="sm"
                            sx={dualCallContainer}
                        />
                    )}
                </VStack>

                {/* zeta buttons */}
                <HStack spacing="8px">
                    {!!onHangup && (
                        <IconButton
                            size="lg"
                            icon={<HangupIconSolid sx={{ boxSize: '32px', py: '2.67px' }} />}
                            variant="negatory"
                            isRounded
                            aria-label="Hang up"
                            onClick={onHangup}
                        />
                    )}
                    {!!onTransfer && (
                        <IconButton
                            size="lg"
                            icon={<TransferIconRegular sx={{ boxSize: '32px', py: '5.33px' }} />}
                            variant="tertiary"
                            isRounded
                            aria-label="Transfer"
                            onClick={onTransfer}
                        />
                    )}
                    {!!onMessage && (
                        <IconButton
                            size="lg"
                            icon={<MessageIconRegular sx={{ boxSize: '32px', py: '5.33px' }} />}
                            variant="tertiary"
                            isRounded
                            aria-label="Message"
                            onClick={onMessage}
                        />
                    )}
                    {!!onAnswer && (
                        <IconButton
                            size="lg"
                            icon={<PhoneIconSolid sx={{ boxSize: '32px', py: '5.33px' }} />}
                            variant="creationary"
                            isRounded
                            aria-label="Answer"
                            onClick={onAnswer}
                        />
                    )}
                </HStack>
            </VStack>
        )
    }
)
