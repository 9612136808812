import { ComponentStyleConfig } from '@chakra-ui/react'

export const LabelStyles: ComponentStyleConfig = {
    baseStyle: {
        text: {
            fontFamily: 'body',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '21px'
        },
        supportingText: {
            fontFamily: 'body',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '21px',
            color: 'light.tones.stormGrey'
        },
        icon: {
            width: '10px',
            height: '10px',
            color: 'light.tones.stormGrey'
        }
    }
}
