import { updateSearchParams } from '@missionlabs/utils'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useUpdateParams = () => {
    const [params, setSearchParams] = useSearchParams()
    const updateParams = useCallback(
        (params: Record<string, string | undefined>) => {
            setSearchParams(existingParams => updateSearchParams(existingParams, params))
        },
        [setSearchParams]
    )
    return [params, updateParams] as const
}
