import { HStack, useColorMode } from '@chakra-ui/react'
import { Body, Button, Table, useFormatToNumberE164 } from '@missionlabs/react'
import { AddCircleIcon } from '@missionlabs/react/circleloop'
import { IVRMenu } from '@missionlabs/types'
import { createColumnHelper } from '@tanstack/react-table'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'
import { useGetIVRMenusQuery } from 'shared/store'

const PhoneNumberElement = ({ phoneNumber, count }) => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()
    return (
        <HStack spacing={0}>
            <Body size="sm">{phoneNumber}</Body>
            {count > 0 && (
                <Body size="sm" sx={{ color: `${colorMode}.coolGrey.80` }}>
                    , {t('menu.countNumber', { count })}
                </Body>
            )}
        </HStack>
    )
}

export const Menus: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const columnHelper = createColumnHelper<IVRMenu>()
    const { data: menus = [] } = useGetIVRMenusQuery()
    const { formatToLocalNumber } = useFormatToNumberE164()

    const columns = [
        columnHelper.accessor('name', {
            cell: ({ getValue }) => getValue(),
            header: t('Name')
        }),
        columnHelper.accessor('phoneNumbers', {
            cell: ({ getValue, row }) => {
                const phoneNumbers = getValue()
                const defaultNumber = row.original.defaultPhoneNumber

                let phoneNumber

                if (defaultNumber) {
                    phoneNumber = formatToLocalNumber(defaultNumber)
                } else if (phoneNumbers.length) {
                    phoneNumber = formatToLocalNumber(phoneNumbers[0])
                } else {
                    phoneNumber = t('menu.noAssignedNumbers')
                }

                const count = phoneNumbers.length - 1
                return <PhoneNumberElement phoneNumber={phoneNumber} count={count} />
            },
            header: t('Numbers')
        })
    ]

    const onAddMenu = () => {
        navigate('/admin/menus/add')
    }

    const onRowClicked = (menu: IVRMenu) => {
        navigate(`/admin/menus/${menu.ID}`)
    }

    return (
        <div>
            <SettingsPage
                maxW="100%"
                title={t('menus.heading')}
                subtitle={t('menus.subHeading')}
                actions={
                    <Button
                        type="submit"
                        variant="creationary"
                        leftIcon={<AddCircleIcon />}
                        onClick={onAddMenu}
                    >
                        {t('menus.addMenu')}
                    </Button>
                }
                isEmpty={!menus?.length}
            >
                <Table data={menus} columns={columns} paginate onRowClicked={onRowClicked} />
            </SettingsPage>
            <Outlet />
        </div>
    )
}
