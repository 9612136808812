import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@missionlabs/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { SettingsPage } from 'shared/components/settings/SettingsPage'

import { BillingCreditNotesTable } from './BillingCreditNotesTable'
import { BillingInvoicesTable } from './BillingInvoicesTable'
import { BillingOverview } from './BillingOverview'
import { BillingUsersTable } from './BillingUsersTable'
import InvoiceDetails from './InvoiceDetails'

export const Billing: FC = () => {
    const { t } = useTranslation()

    return (
        <>
            <SettingsPage
                maxW="full"
                title={t('admin.billing.title')}
                subtitle={t('admin.billing.subtitle')}
            >
                <BillingOverview />
                <InvoiceDetails />
                <Tabs w="full">
                    <TabList>
                        <Tab>{t('Users')}</Tab>
                        <Tab>{t('Invoices')}</Tab>
                        <Tab>{t('Credit Notes')}</Tab>
                    </TabList>
                    <TabPanels mt="24px">
                        <TabPanel p={0}>
                            <BillingUsersTable />
                        </TabPanel>
                        <TabPanel p={0}>
                            <BillingInvoicesTable />
                        </TabPanel>
                        <TabPanel p={0}>
                            <BillingCreditNotesTable />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </SettingsPage>
            <Outlet />
        </>
    )
}
