import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const JustifyIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'JustifyIconLight',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M17.125 0.875C17.4375 0.875 17.75 1.1875 17.75 1.5C17.75 1.85156 17.4375 2.125 17.125 2.125H0.875C0.523438 2.125 0.25 1.85156 0.25 1.5C0.25 1.1875 0.523438 0.875 0.875 0.875H17.125ZM0.25 6.5C0.25 6.1875 0.523438 5.875 0.875 5.875H17.125C17.4375 5.875 17.75 6.1875 17.75 6.5C17.75 6.85156 17.4375 7.125 17.125 7.125H0.875C0.523438 7.125 0.25 6.85156 0.25 6.5ZM17.125 10.875C17.4375 10.875 17.75 11.1875 17.75 11.5C17.75 11.8516 17.4375 12.125 17.125 12.125H0.875C0.523438 12.125 0.25 11.8516 0.25 11.5C0.25 11.1875 0.523438 10.875 0.875 10.875H17.125ZM17.125 15.875C17.4375 15.875 17.75 16.1875 17.75 16.5C17.75 16.8516 17.4375 17.125 17.125 17.125H0.875C0.523438 17.125 0.25 16.8516 0.25 16.5C0.25 16.1875 0.523438 15.875 0.875 15.875H17.125Z"
            fill="currentColor"
        />
    )
})

export const JustifyIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'JustifyIconRegular',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M16.8125 0.5625C17.3203 0.5625 17.75 0.992188 17.75 1.5C17.75 2.04688 17.3203 2.4375 16.8125 2.4375H1.1875C0.640625 2.4375 0.25 2.04688 0.25 1.5C0.25 0.992188 0.640625 0.5625 1.1875 0.5625H16.8125ZM16.8125 5.5625C17.3203 5.5625 17.75 5.99219 17.75 6.5C17.75 7.04688 17.3203 7.4375 16.8125 7.4375H1.1875C0.640625 7.4375 0.25 7.04688 0.25 6.5C0.25 5.99219 0.640625 5.5625 1.1875 5.5625H16.8125ZM0.25 11.5C0.25 10.9922 0.640625 10.5625 1.1875 10.5625H16.8125C17.3203 10.5625 17.75 10.9922 17.75 11.5C17.75 12.0469 17.3203 12.4375 16.8125 12.4375H1.1875C0.640625 12.4375 0.25 12.0469 0.25 11.5ZM16.8125 15.5625C17.3203 15.5625 17.75 15.9922 17.75 16.5C17.75 17.0469 17.3203 17.4375 16.8125 17.4375H1.1875C0.640625 17.4375 0.25 17.0469 0.25 16.5C0.25 15.9922 0.640625 15.5625 1.1875 15.5625H16.8125Z"
            fill="currentColor"
        />
    )
})

export const JustifyIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'JustifyIconSolid',
    viewBox: '0 0 18 18',
    path: (
        <path
            d="M16.5 2.75H1.5C0.796875 2.75 0.25 2.20312 0.25 1.5C0.25 0.835938 0.796875 0.25 1.5 0.25H16.5C17.1641 0.25 17.75 0.835938 17.75 1.5C17.75 2.20312 17.1641 2.75 16.5 2.75ZM16.5 12.75H1.5C0.796875 12.75 0.25 12.2031 0.25 11.5C0.25 10.8359 0.796875 10.25 1.5 10.25H16.5C17.1641 10.25 17.75 10.8359 17.75 11.5C17.75 12.2031 17.1641 12.75 16.5 12.75ZM0.25 6.5C0.25 5.83594 0.796875 5.25 1.5 5.25H16.5C17.1641 5.25 17.75 5.83594 17.75 6.5C17.75 7.20312 17.1641 7.75 16.5 7.75H1.5C0.796875 7.75 0.25 7.20312 0.25 6.5ZM16.5 17.75H1.5C0.796875 17.75 0.25 17.2031 0.25 16.5C0.25 15.8359 0.796875 15.25 1.5 15.25H16.5C17.1641 15.25 17.75 15.8359 17.75 16.5C17.75 17.2031 17.1641 17.75 16.5 17.75Z"
            fill="currentColor"
        />
    )
})
