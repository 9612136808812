import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const CameraIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CameraIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M11.9665 16.092C14.1695 16.092 15.9553 14.2971 15.9553 12.083C15.9553 9.8689 14.1695 8.074 11.9665 8.074C9.76351 8.074 7.97765 9.8689 7.97765 12.083C7.97765 14.2971 9.76351 16.092 11.9665 16.092Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.87175 2H16.0612C16.7097 2.00012 17.3932 2.5216 17.5879 3.16476L18.2346 5.3008H21.8547C23.0395 5.3008 24 6.26613 24 7.45691V18.8439C24 20.0347 23.0395 21 21.8547 21H2.14525C0.960463 21 0 19.0347 0 17.8439V7.45691C0 6.26613 0.960463 5.3008 2.14525 5.3008H5.69832L6.34501 3.16476C6.53973 2.5216 7.22328 2.00012 7.87175 2ZM17.2961 12.083C17.2961 15.0414 14.9099 17.4396 11.9665 17.4396C9.02302 17.4396 6.63687 15.0414 6.63687 12.083C6.63687 9.12466 9.02302 6.72643 11.9665 6.72643C14.9099 6.72643 17.2961 9.12466 17.2961 12.083Z"
                fill="currentColor"
            />
        </g>
    )
})

export const FlipCameraIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'FlipCameraIcon',
    viewBox: '0 0 24 24',
    path: (
        <path
            d="M20 5H16.83L15.59 3.65C15.22 3.24 14.68 3 14.12 3H9.88C9.32 3 8.78 3.24 8.4 3.65L7.17 5H4C2.9 5 2 5.9 2 7V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V7C22 5.9 21.1 5 20 5ZM13.67 17.7C13.15 17.89 12.59 18 12 18C9.24 18 7 15.76 7 13H5L7.5 10.5L10 13H8C8 15.21 9.79 17 12 17C12.46 17 12.91 16.92 13.32 16.77C13.51 16.7 13.71 16.74 13.85 16.88C14.11 17.14 14.01 17.57 13.67 17.7ZM16.5 15.5L14 13H16C16 10.79 14.21 9 12 9C11.54 9 11.09 9.08 10.68 9.23C10.49 9.3 10.29 9.26 10.15 9.12C9.89 8.86 9.99 8.43 10.33 8.3C10.85 8.11 11.41 8 12 8C14.76 8 17 10.24 17 13H19L16.5 15.5Z"
            fill="currentColor"
        />
    )
})
