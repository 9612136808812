import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const TransferIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'TransferIcon',
    viewBox: '0 0 17 16',
    path: (
        <path
            d="M1.25 4.98438H15.0312L11.875 8.14062C11.6875 8.32812 11.6875 8.67188 11.875 8.85938C12.0625 9.04688 12.4062 9.04688 12.5938 8.85938L16.5938 4.85938C16.6875 4.76562 16.75 4.64062 16.75 4.48438C16.75 4.35938 16.6875 4.23438 16.5938 4.14062L12.5938 0.140625C12.4062 -0.046875 12.0625 -0.046875 11.875 0.140625C11.6875 0.328125 11.6875 0.671875 11.875 0.859375L15.0312 3.98438H1.25C0.96875 3.98438 0.75 4.23438 0.75 4.48438C0.75 4.76562 0.96875 4.98438 1.25 4.98438ZM16.25 10.9844H2.4375L5.59375 7.85938C5.78125 7.67188 5.78125 7.32812 5.59375 7.14062C5.40625 6.95312 5.0625 6.95312 4.875 7.14062L0.875 11.1406C0.78125 11.2344 0.75 11.3594 0.75 11.4844C0.75 11.6406 0.78125 11.7656 0.875 11.8594L4.875 15.8594C5.0625 16.0469 5.40625 16.0469 5.59375 15.8594C5.78125 15.6719 5.78125 15.3281 5.59375 15.1406L2.4375 11.9844H16.25C16.5 11.9844 16.75 11.7656 16.75 11.4844C16.75 11.2344 16.5 10.9844 16.25 10.9844Z"
            fill="currentColor"
        />
    )
})
