import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const ForwardIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ForwardIconLight',
    viewBox: '0 0 20 14',
    path: (
        <path
            d="M0 12.2479V13.416C0 13.7445 0.3125 14 0.625 14C0.976562 14 1.25 13.7445 1.25 13.416V12.2844C1.25 9.40064 3.78906 7.06445 6.875 7.06445H17.6562L11.4844 11.8828C11.2109 12.1019 11.2109 12.4669 11.4062 12.7224C11.5234 12.7589 11.7188 12.8319 11.875 12.8319C12.0312 12.8319 12.1875 12.7954 12.3047 12.7224L19.8047 6.91844C19.9219 6.77243 20 6.58992 20 6.4074C20 6.22489 19.9219 6.04237 19.8047 5.93286L12.3047 0.128906C12.0312 -0.0536083 11.6406 -0.0536083 11.4062 0.201912C11.2109 0.457432 11.2109 0.822461 11.4844 1.04148L17.6562 5.82336H6.875C3.08594 5.82336 0 8.70708 0 12.2479Z"
            fill="currentColor"
        />
    )
})

export const ForwardIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ForwardIconRegular',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M0 12.1927V17.0321C0 17.5967 0.429688 18 0.9375 18C1.48438 18 1.875 17.5967 1.875 17.0724V12.2733C1.875 9.97461 3.71094 8.11949 5.9375 8.11949H16.5625L11.6016 12.5556C11.2109 12.9186 11.1719 13.5235 11.4844 13.9268C11.6797 14.0478 11.9531 14.1285 12.1875 14.1285C12.4219 14.1285 12.6562 14.0881 12.8125 13.9268L19.6875 7.83719C19.8828 7.59522 20 7.31292 20 7.03062C20 6.74832 19.8828 6.50635 19.6875 6.3047L12.8125 0.215086C12.4609 -0.107543 11.8359 -0.0672143 11.4844 0.336072C11.1719 0.739358 11.2109 1.34429 11.6016 1.70724L16.5625 6.06273H5.9375C2.69531 6.06273 0 8.84541 0 12.1927Z"
            fill="currentColor"
        />
    )
})

export const ForwardIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ForwardIconSolid',
    viewBox: '0 0 20 18',
    path: (
        <path
            d="M19.6779 6.30525L12.8062 0.226449C12.2206 -0.296891 11.2445 0.145935 11.2445 0.951075V4.21189C4.99756 4.21189 0 5.54037 0 11.6594C0 14.1151 1.56174 16.5708 3.27965 17.859C3.78721 18.2616 4.56808 17.7382 4.37286 17.0941C2.61591 11.2569 5.19278 9.72709 11.2445 9.60632V13.1489C11.2445 13.9943 12.2206 14.4372 12.8062 13.9138L19.6779 7.79476C20.1074 7.39219 20.1074 6.70782 19.6779 6.30525Z"
            fill="currentColor"
        />
    )
})

export const ForwardAllIconLight: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ForwardAllIconLight',
    viewBox: '0 0 22 14',
    path: (
        <path
            d="M6.72222 5.82336H14.8194L8.78472 1.04148C8.51736 0.822461 8.51736 0.457432 8.70833 0.201912C8.9375 -0.0536083 9.31944 -0.0536083 9.58681 0.128906L16.9201 5.93286C17.0347 6.07888 17.1111 6.26139 17.1111 6.4074C17.1111 6.55341 17.0347 6.73593 16.9201 6.84544L9.58681 12.6494C9.47222 12.7224 9.31944 12.7954 9.16667 12.7954C9.01389 12.7954 8.82292 12.7224 8.70833 12.5764C8.51736 12.3209 8.51736 11.9558 8.78472 11.7368L14.8194 6.99145H6.72222C3.70486 6.99145 1.22222 9.36413 1.22222 12.2479V13.3795C1.22222 13.7445 0.954861 14 0.611111 14C0.305555 14 0 13.7445 0 13.4525V12.3209C0 8.70708 3.01736 5.82336 6.72222 5.82336ZM20.434 6.4074L13.6736 1.04148C13.4062 0.822461 13.4062 0.457432 13.5972 0.201912C13.8264 -0.0536083 14.2083 -0.0536083 14.4757 0.128906L21.809 5.93286C21.9236 6.07888 22 6.26139 22 6.4074C22 6.55341 21.9236 6.73593 21.809 6.84544L14.4757 12.6494C14.3611 12.7224 14.2083 12.7954 14.0556 12.7954C13.9028 12.7954 13.7118 12.7224 13.5972 12.5764C13.4062 12.3209 13.4062 11.9558 13.6736 11.7368L20.434 6.4074Z"
            fill="currentColor"
        />
    )
})

export const ForwardAllIconRegular: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ForwardAllIconRegular',
    viewBox: '0 0 22 18',
    path: (
        <path
            d="M19.7083 7.05514L13.8264 1.70343C13.4444 1.34128 13.4062 0.737705 13.7118 0.33532C14.0556 -0.0670641 14.6285 -0.107303 15.0104 0.214605L21.7326 6.29061C21.8854 6.53204 22 6.77347 22 7.05514C22 7.33681 21.8854 7.61848 21.6944 7.81967L14.9722 13.8957C14.8194 14.0969 14.5903 14.1371 14.3611 14.1371C14.1319 14.1371 13.8646 14.0566 13.6736 13.8152C13.3681 13.4128 13.4062 12.8092 13.7882 12.4471L19.7083 7.05514ZM5.80556 6.08942H13.75L8.89931 1.70343C8.51736 1.34128 8.47917 0.737705 8.78472 0.33532C9.12847 -0.0670641 9.70139 -0.107303 10.0833 0.214605L16.8056 6.33085C16.9965 6.53204 17.1111 6.77347 17.1111 7.05514C17.1111 7.33681 16.9965 7.61848 16.8056 7.81967L10.0833 13.8957C9.93056 14.0566 9.70139 14.1371 9.47222 14.1371C9.24306 14.1371 8.97569 14.0164 8.78472 13.775C8.47917 13.3726 8.51736 12.769 8.89931 12.4069L13.75 8.02086H5.80556C3.62847 8.02086 1.83333 9.91207 1.83333 12.2057V16.994C1.83333 17.5976 1.45139 18 0.916666 18C0.420139 18 0 17.5976 0 17.0343V12.2459C0 8.86587 2.63542 6.08942 5.80556 6.08942Z"
            fill="currentColor"
        />
    )
})

export const ForwardAllIconSolid: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'ForwardAllIconSolid',
    viewBox: '0 0 22 18',
    path: (
        <path
            d="M16.7892 7.83199L10.0582 13.9093C9.48457 14.4325 8.56671 13.9898 8.56671 13.1848V9.80409C4.39809 10.2871 2.75359 12.1384 4.24511 17.0888C4.43633 17.773 3.67145 18.2559 3.17427 17.8535C1.49153 16.5656 0 14.1105 0 11.6554C0 6.38311 3.59496 4.69274 8.56671 4.29027V0.990023C8.56671 0.144838 9.48457 -0.297878 10.0582 0.225332L16.7892 6.34286C17.2099 6.74533 17.2099 7.42953 16.7892 7.83199ZM21.6845 7.83199L14.9535 13.9093C14.3798 14.4325 13.4237 13.9898 13.4237 13.1848V12.5409L19.4663 7.0673L13.4237 1.59373V0.990023C13.4237 0.144838 14.3798 -0.297878 14.9535 0.225332L21.6845 6.30261C22.1052 6.74533 22.1052 7.42953 21.6845 7.83199Z"
            fill="currentColor"
        />
    )
})
