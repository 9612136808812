import { Box, useColorMode, useMultiStyleConfig, VStack } from '@chakra-ui/react'
import { Activity as ActivityType } from '@missionlabs/types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Body } from '../../atoms'
import { ActivityIcon } from './ActivityIcon'
import { DirectionIndicator } from './DirectionIndicator'

export interface ActivityProps {
    direction: 'inbound' | 'outbound'
    activityType: ActivityType
    time: string
    name: string
    originatingNumber?: string
    dialledNumber?: string
    callLength?: number
    isNew?: boolean
    isSelected?: boolean
    team?: string
    onClick: (e: any) => void
}

export const Activity = ({
    activityType,
    name,
    originatingNumber,
    dialledNumber,
    team,
    time,
    isNew,
    isSelected,
    direction,
    callLength,
    onClick
}: ActivityProps) => {
    const { t } = useTranslation()
    const variant = isSelected ? 'selected' : isNew ? 'new' : undefined
    const isOutbound = direction === 'outbound'
    const styles = useMultiStyleConfig('Activity', { variant })
    const { colorMode } = useColorMode()

    const via = useMemo(() => {
        if (team) return team
        if (direction === 'inbound') return t('details.via', { value: dialledNumber })
        if (direction === 'outbound') return t('details.via', { value: originatingNumber })
        return null
    }, [team, direction, dialledNumber, originatingNumber, t])

    return (
        <Box
            boxShadow={'sm'}
            className={`activity ${direction} ${activityType} ${isNew ? 'new' : ''}`}
            tabIndex={0}
            _hover={isSelected ? undefined : { cursor: 'pointer', transform: 'translateX(4px)' }}
            sx={{ ...styles.container }}
            marginInlineStart={isOutbound ? '48px' : '32px'}
            marginInlineEnd={!isOutbound ? '48px' : '32px'}
            onClick={e => onClick(e)}
        >
            <DirectionIndicator direction={direction} variant={variant} />
            <Box w="20px" h="20px" ml="20px" pt="16px" zIndex={1}>
                <ActivityIcon
                    color={isOutbound ? `${colorMode}.tones.periwinkle` : ''}
                    activityType={activityType}
                    callLength={callLength}
                />
            </Box>
            <VStack spacing="1px" align="flex-start" flexGrow={1} p="16px" pl="4px">
                <Body size="sm" variant="bold" sx={{ ...styles.name }} aria-label={`from ${name}`}>
                    {name}
                </Body>
                {via && (
                    <Body size="sm" sx={{ ...styles.text }}>
                        {via}
                    </Body>
                )}
            </VStack>
            <Box p={4} zIndex={1}>
                <Body size="sm" sx={{ ...styles.text }} aria-label={`at ${time}`}>
                    {time}
                </Body>
            </Box>
            {isNew && (
                <Box id="new-indicator" aria-label="New activity" sx={{ ...styles.newIndicator }} />
            )}
        </Box>
    )
}
