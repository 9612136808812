import { useColorMode, VStack } from '@chakra-ui/react'
import { Body } from '@missionlabs/react'
import { UserCallRoute } from '@missionlabs/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useFormatToNumberE164 } from 'shared/hooks/useFormatToNumberE164'

type UsersNumbersProps = {
    numbers: UserCallRoute['phoneNumbers']
}

export const AssignedNumbers: FC<UsersNumbersProps> = ({ numbers = [] }) => {
    const { t } = useTranslation()
    const { colorMode } = useColorMode()
    const { formatToLocalNumber, formatToNumberE164 } = useFormatToNumberE164()

    return (
        <VStack w="full" align="start">
            <Body size="md" sx={{ color: `${colorMode}.coolGrey.80`, pb: '2px' }}>
                {t('Numbers')}
            </Body>
            {numbers.length > 0 ? (
                numbers.map(number => (
                    <Link key={number} to={navigateToNumberByE164(formatToNumberE164(number))}>
                        <Body size="sm" variant="bold" color={`${colorMode}.alerts.red`}>
                            {formatToLocalNumber(number)}
                        </Body>
                    </Link>
                ))
            ) : (
                <Body size="sm" sx={{ color: `${colorMode}.coolGrey.60` }}>
                    {t('No numbers assigned')}
                </Body>
            )}
        </VStack>
    )
}

function navigateToNumberByE164(numberE164: string) {
    return `/admin/numbers?numberE164=${numberE164}`
}
