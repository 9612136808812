import { SearchFilter } from '@missionlabs/api'
import { useLocalStorage } from 'react-use'

export type ContactFilterSort = 'surname-az' | 'surname-za' | 'firstname-az' | 'firstname-za'

export interface ContactFilters {
    sort: ContactFilterSort
    type: SearchFilter[]
    source: string[]
}

const defaultFilters: ContactFilters = {
    sort: 'firstname-az',
    type: [],
    source: []
}

const LOCAL_STORAGE_KEY = '__contact_filters'

export const useContactsFilterParams = (storageKey = LOCAL_STORAGE_KEY) => {
    const [filters, setFiltersLocal, clearFiltersLocal] = useLocalStorage<ContactFilters>(
        storageKey,
        defaultFilters
    )

    const toggleType = (value: ContactFilterType, checked: boolean) => {
        if (!filters) return
        setFiltersLocal({
            ...filters,
            type: checked ? [...filters.type, value] : filters.type.filter(type => type !== value)
        })
    }

    const toggleSource = (value: string, checked: boolean) => {
        if (!filters) return
        setFiltersLocal({
            ...filters,
            source: checked
                ? [...filters.source, value]
                : filters.source.filter(source => source !== value)
        })
    }

    const reset = () => {
        setFiltersLocal(defaultFilters)
    }

    const remove = () => {
        clearFiltersLocal()
    }

    return {
        toggleSource,
        toggleType,
        reset,
        remove,
        filters: filters ?? defaultFilters
    }
}
