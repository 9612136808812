import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const MessageIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'MessageIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.32533 23.9527C6.39383 23.9843 6.4669 24 6.53998 24C6.65773 24 6.77447 23.9596 6.868 23.8808L13.7021 18.1664H15.9275C20.3037 18.1664 24 14.0072 24 9.08319C24 4.1597 20.3037 0 15.9275 0H8.27334C3.78885 0 0 4.1715 0 9.10882C0 13.6504 3.00109 17.1374 6.03109 18.0638V23.4954C6.03109 23.6915 6.1459 23.8699 6.32533 23.9527ZM18.8095 9.71429H5.19048C4.87489 9.71429 4.61905 9.45845 4.61905 9.14286C4.61905 8.82727 4.87489 8.57143 5.19048 8.57143H18.8095C19.1251 8.57143 19.381 8.82727 19.381 9.14286C19.381 9.45845 19.1251 9.71429 18.8095 9.71429ZM5.19048 6.28571H18.8095C19.1251 6.28571 19.381 6.02988 19.381 5.71429C19.381 5.39869 19.1251 5.14286 18.8095 5.14286H5.19048C4.87489 5.14286 4.61905 5.39869 4.61905 5.71429C4.61905 6.02988 4.87489 6.28571 5.19048 6.28571ZM13.1905 13.1429H5.19048C4.87489 13.1429 4.61905 12.887 4.61905 12.5714C4.61905 12.2558 4.87489 12 5.19048 12H13.1905C13.5061 12 13.7619 12.2558 13.7619 12.5714C13.7619 12.887 13.5061 13.1429 13.1905 13.1429Z"
                fill="currentColor"
            />
            <mask
                id="mask0_420_164"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.32533 23.9527C6.39383 23.9843 6.4669 24 6.53998 24C6.65773 24 6.77447 23.9596 6.868 23.8808L13.7021 18.1664H15.9275C20.3037 18.1664 24 14.0072 24 9.08319C24 4.1597 20.3037 0 15.9275 0H8.27334C3.78885 0 0 4.1715 0 9.10882C0 13.6504 3.00109 17.1374 6.03109 18.0638V23.4954C6.03109 23.6915 6.1459 23.8699 6.32533 23.9527ZM18.8095 9.71429H5.19048C4.87489 9.71429 4.61905 9.45845 4.61905 9.14286C4.61905 8.82727 4.87489 8.57143 5.19048 8.57143H18.8095C19.1251 8.57143 19.381 8.82727 19.381 9.14286C19.381 9.45845 19.1251 9.71429 18.8095 9.71429ZM5.19048 6.28571H18.8095C19.1251 6.28571 19.381 6.02988 19.381 5.71429C19.381 5.39869 19.1251 5.14286 18.8095 5.14286H5.19048C4.87489 5.14286 4.61905 5.39869 4.61905 5.71429C4.61905 6.02988 4.87489 6.28571 5.19048 6.28571ZM13.1905 13.1429H5.19048C4.87489 13.1429 4.61905 12.887 4.61905 12.5714C4.61905 12.2558 4.87489 12 5.19048 12H13.1905C13.5061 12 13.7619 12.2558 13.7619 12.5714C13.7619 12.887 13.5061 13.1429 13.1905 13.1429Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_420_164)"></g>
        </g>
    )
})
