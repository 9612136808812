import { Destination, DestinationTypes } from '@missionlabs/types'
import { Buffer } from 'buffer'
import { detect } from 'detect-browser'
import isElectron from 'is-electron'
import os from 'os-browserify'

declare global {
    interface Window {
        platform: string
        arch: string
        cwd: () => string
    }
}

function generateInstallationID(userID: string) {
    let installationID = localStorage.getItem('installationID')
    if (!installationID) {
        installationID = window.crypto.randomUUID()
        localStorage.setItem('installationID', installationID)
    }
    return Buffer.from(installationID + userID).toString('base64')
}

enum DestinationOS {
    Linux = 'linux',
    Mac = 'mac',
    Windows = 'windows'
}

export function getDestinationOSName(platform: string): string {
    if (['darwin', 'Mac OS', 'iOS'].includes(platform)) return DestinationOS.Mac
    if (platform === 'win32' || platform.includes('Windows')) return DestinationOS.Windows
    return DestinationOS.Linux
}

export function createInstallationIdPayload(
    userID: string,
    name: string,
    displayName: string,
    version: string
): Partial<Destination> {
    const installationID = generateInstallationID(userID)

    if (isElectron()) {
        return createElectronPayload({ installationID, displayName, name, version })
    }

    return createWebappPayload({ installationID, displayName, name, version })
}

interface DestinationParams {
    installationID: string
    displayName: string
    name: string
    version: string
}

function createElectronPayload({
    installationID,
    name,
    displayName,
    version
}: DestinationParams): Partial<Destination> {
    const destinationOS = getDestinationOSName(window.platform)
    return {
        type: DestinationTypes.desktopApp,
        installationID: installationID,
        name: `${displayName} (${destinationOS})`,
        metadata: {
            brand: name,
            os: destinationOS,
            osVersion: os.release(),
            appVersion: version,
            desktopBitVersion: String(
                window.arch.replace('x', '').replace('ia', '').replace('arm', '')
            ),
            installationPath: window.cwd()
        }
    }
}

function createWebappPayload({
    installationID,
    displayName,
    name,
    version
}: DestinationParams): Partial<Destination> {
    const browser = detect()
    const destinationOS = getDestinationOSName(browser?.os ?? '')

    console.log({ name, displayName })

    return {
        type: DestinationTypes.webApp,
        installationID: installationID,
        name: `${displayName} (${browser?.name ?? 'unknown'})`,
        metadata: {
            brand: name,
            os: destinationOS,
            browser: browser?.name ?? '',
            browserVersion: browser?.version ?? '',
            appVersion: version
        }
    }
}
