import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const XIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'XIcon',
    viewBox: '0 0 13 13',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.97006 6.49828L0.317217 11.1511C-0.105739 11.5741 -0.105739 12.2598 0.317217 12.6828C0.740173 13.1057 1.42592 13.1057 1.84888 12.6828L6.50172 8.02994L11.1511 12.6793C11.5741 13.1023 12.2598 13.1023 12.6828 12.6793C13.1057 12.2564 13.1057 11.5706 12.6828 11.1477L8.03338 6.49828L12.6793 1.85231C13.1023 1.42936 13.1023 0.743611 12.6793 0.320655C12.2564 -0.102302 11.5706 -0.102301 11.1477 0.320655L6.50172 4.96662L1.85231 0.317217C1.42936 -0.105739 0.74361 -0.105739 0.320654 0.317217C-0.102303 0.740173 -0.102303 1.42592 0.320654 1.84888L4.97006 6.49828Z"
            fill="currentColor"
        />
    )
})
