import { useDisclosure } from '@chakra-ui/react'
import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import { readFile } from '@missionlabs/utils'
import { RecordingDrawer } from 'features/settings/components/RecordingDrawer'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCreateUserAudioClipMutation } from 'shared/store'

export const AddRecordingDrawer: FC = () => {
    const navigate = useNavigate()
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

    const user = useSelector(selectAuthenticatedUser)

    const [createClip, { isLoading }] = useCreateUserAudioClipMutation()

    async function handleSubmit(data: File | null) {
        try {
            const dataURL = await readFile(data)
            if (!user || !dataURL) return
            await createClip({ userID: user.userID, data: dataURL, label: data?.name }).unwrap()
        } catch (e) {
            console.error(e)
        }
    }

    function handleTransition() {
        navigate('/settings/recordings')
    }

    return (
        <RecordingDrawer
            isLoading={isLoading}
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={handleTransition}
            onSubmit={handleSubmit}
        />
    )
}
