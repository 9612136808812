import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react'

export const CallEndedIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CallEndedIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5782 6.06528L21.7877 8.49694C22.555 9.07841 22.6792 10.1654 22.0628 10.9055L20.3938 12.9097C19.7729 13.6555 18.6875 13.7046 18.0014 13.0185L15.8406 10.8577C15.6279 10.6451 15.5448 10.0798 15.5448 9.40752C15.545 9.14445 15.1784 8.8693 14.6824 8.86963L9.31728 8.86964C8.82176 8.86933 8.45535 9.14441 8.45512 9.40792C8.45512 10.077 8.36993 10.6471 8.15659 10.8605L5.99899 13.0181C5.31231 13.7048 4.22803 13.6553 3.60569 12.9094L1.93663 10.9051C1.32068 10.1654 1.44511 9.07839 2.21228 8.49698L5.42172 6.06532C6.06719 5.57602 7.20997 5.19199 8.0195 5.19251L15.9812 5.19202C16.7907 5.19254 17.9336 5.57659 18.5782 6.06528ZM10.8432 14.1569C10.1095 13.4232 10.1095 12.2337 10.8432 11.5C11.5768 10.7663 12.7663 10.7663 13.5 11.5C14.2337 12.2337 14.2337 13.4232 13.5 14.1569C12.7663 14.8905 11.5768 14.8905 10.8432 14.1569ZM10.8432 17.1569C10.1095 17.8905 10.1095 19.08 10.8432 19.8137C11.5768 20.5474 12.7663 20.5474 13.5 19.8137C14.2337 19.08 14.2337 17.8905 13.5 17.1569C12.7663 16.4232 11.5768 16.4232 10.8432 17.1569Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_170"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="1"
                y="5"
                width="22"
                height="16"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.5782 6.06528L21.7877 8.49694C22.555 9.07841 22.6792 10.1654 22.0628 10.9055L20.3938 12.9097C19.7729 13.6555 18.6875 13.7046 18.0014 13.0185L15.8406 10.8577C15.6279 10.6451 15.5448 10.0798 15.5448 9.40752C15.545 9.14445 15.1784 8.8693 14.6824 8.86963L9.31728 8.86964C8.82176 8.86933 8.45535 9.14441 8.45512 9.40792C8.45512 10.077 8.36993 10.6471 8.15659 10.8605L5.99899 13.0181C5.31231 13.7048 4.22803 13.6553 3.60569 12.9094L1.93663 10.9051C1.32068 10.1654 1.44511 9.07839 2.21228 8.49698L5.42172 6.06532C6.06719 5.57602 7.20997 5.19199 8.0195 5.19251L15.9812 5.19202C16.7907 5.19254 17.9336 5.57659 18.5782 6.06528ZM10.8432 14.1569C10.1095 13.4232 10.1095 12.2337 10.8432 11.5C11.5768 10.7663 12.7663 10.7663 13.5 11.5C14.2337 12.2337 14.2337 13.4232 13.5 14.1569C12.7663 14.8905 11.5768 14.8905 10.8432 14.1569ZM10.8432 17.1569C10.1095 17.8905 10.1095 19.08 10.8432 19.8137C11.5768 20.5474 12.7663 20.5474 13.5 19.8137C14.2337 19.08 14.2337 17.8905 13.5 17.1569C12.7663 16.4232 11.5768 16.4232 10.8432 17.1569Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_170)"></g>
        </g>
    )
})

export const CallInboundIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CallInboundIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M2.70778 9.66345L2.02029 4.67734C1.85608 3.48524 2.70709 2.41466 3.90604 2.30527L7.15276 2.00901C8.36069 1.8987 9.36349 2.81466 9.36349 4.02748V7.84726C9.36349 8.22317 8.93734 8.79627 8.34312 9.39049C8.1104 9.62281 8.19131 10.1901 8.62998 10.6282L13.3721 15.3703C13.8098 15.8086 14.3768 15.8893 14.6099 15.6566C15.2014 15.0652 15.7806 14.6366 16.1577 14.6366H19.9718C21.1857 14.6366 22.1004 15.6387 21.9911 16.8481L21.6949 20.0948C21.5854 21.2931 20.5147 22.1439 19.3227 21.9797L14.3366 21.2922C13.3336 21.1542 11.9841 20.4836 11.269 19.7676L4.23142 12.7308C3.51633 12.0148 2.84564 10.6652 2.70778 9.66345Z"
                fill="currentColor"
            />
            <path
                d="M12.4209 7.72338C12.7219 7.72338 12.966 7.96742 12.966 8.26846V10.3071L18.1532 5.20979L19.6299 3.72078C19.8419 3.50703 20.187 3.50559 20.4008 3.71758C20.6145 3.92956 20.6159 4.27468 20.404 4.48843L18.9223 5.9824L13.6689 11.1448H15.8424C16.1434 11.1448 16.3874 11.3889 16.3874 11.6899C16.3874 11.9909 16.1434 12.235 15.8424 12.235L12.4209 12.235C12.1199 12.235 11.8758 11.9909 11.8758 11.6899V8.26846C11.8758 7.96742 12.1199 7.72338 12.4209 7.72338Z"
                fill="currentColor"
            />
        </g>
    )
})

export const CallMissedInboundIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CallMissedInboundIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M9.5 3H6C5.58579 3 5.25 3.33579 5.25 3.75V7.25C5.25 7.66421 5.58579 8 6 8C6.41421 8 6.75 7.66421 6.75 7.25V5.56066L11.4697 10.2803C11.7626 10.5732 12.2374 10.5732 12.5303 10.2803L18.5303 4.28033C18.8232 3.98744 18.8232 3.51256 18.5303 3.21967C18.2374 2.92678 17.7626 2.92678 17.4697 3.21967L12 8.68934L7.81066 4.5H9.5C9.91421 4.5 10.25 4.16421 10.25 3.75C10.25 3.33579 9.91421 3 9.5 3Z"
                fill="currentColor"
            />
            <path
                d="M19.5505 12.9457L23.2342 15.5791C24.1149 16.2088 24.2574 17.3859 23.55 18.1874L21.6343 20.3579C20.9216 21.1655 19.6758 21.2187 18.8884 20.4757L16.4082 18.1356C16.1642 17.9054 16.0688 17.2932 16.0688 16.5652C16.069 16.2803 15.6482 15.9823 15.0789 15.9827L8.92091 15.9827C8.35216 15.9823 7.9316 16.2802 7.93134 16.5656C7.93133 17.2902 7.83356 17.9076 7.58869 18.1387L5.11223 20.4752C4.32408 21.2189 3.07957 21.1653 2.36525 20.3575L0.449539 18.187C-0.257437 17.3859 -0.114622 16.2087 0.765922 15.5791L4.44966 12.9457C5.19052 12.4159 6.50217 12 7.43134 12.0005L16.5696 12C17.4988 12.0006 18.8105 12.4165 19.5505 12.9457Z"
                fill="currentColor"
            />
        </g>
    )
})

export const CallMissedOutboundIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CallMissedOutboundIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M9.64904 12.5613C9.86742 12.3434 9.86759 11.9896 9.64943 11.7715L8.69437 10.8164C8.38213 10.5042 8.36911 10.0199 8.64435 9.74415C9.26601 9.1225 9.71308 8.52236 9.71308 8.13354V4.1171C9.71308 2.84972 8.66515 1.89321 7.40339 2.00959L3.99011 2.3202C2.73789 2.43455 1.84888 3.55328 2.02143 4.79881L2.74428 10.0414C2.88894 11.0915 3.59246 12.5075 4.3424 13.2574L6.24987 15.1657C6.46772 15.3836 6.82095 15.3838 7.03906 15.1661L9.64904 12.5613Z"
                fill="currentColor"
            />
            <path
                d="M4.36427 21.3148L17.2452 8.64842C17.465 8.43225 17.468 8.0788 17.2518 7.85897C17.0356 7.63914 16.6822 7.63618 16.4623 7.85235L3.58142 20.5188C3.36159 20.7349 3.35863 21.0884 3.5748 21.3082C3.79098 21.528 4.14444 21.531 4.36427 21.3148Z"
                fill="currentColor"
            />
            <path
                d="M9.72751 18.6419C9.50945 18.4237 9.50963 18.0701 9.72792 17.8521L12.337 15.2473C12.5552 15.0296 12.9085 15.0298 13.1264 15.2478L14.1313 16.2536C14.4762 16.598 14.985 16.6249 15.2548 16.3546C15.8744 15.735 16.4803 15.2864 16.8718 15.2864H20.8823C22.1493 15.2864 23.1048 16.3328 22.9908 17.5955L22.6792 21.0095C22.5649 22.261 21.4459 23.1503 20.2008 22.9788L14.9589 22.2551C13.908 22.1105 12.4919 21.4071 11.7417 20.6569L9.72751 18.6419Z"
                fill="currentColor"
            />
        </g>
    )
})

export const CallOutboundIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CallOutboundIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                d="M2.02029 4.67734L2.70778 9.66345C2.84564 10.6652 3.51633 12.0148 4.23142 12.7308L11.269 19.7676C11.9841 20.4836 13.3336 21.1542 14.3366 21.2922L19.3227 21.9797C20.5147 22.1439 21.5854 21.2931 21.6949 20.0948L21.9911 16.8481C22.1004 15.6387 21.1857 14.6366 19.9718 14.6366H16.1577C15.7806 14.6366 15.2014 15.0652 14.6099 15.6566C14.3768 15.8893 13.8098 15.8086 13.3721 15.3703L8.62998 10.6282C8.19131 10.1901 8.1104 9.62281 8.34312 9.39049C8.93734 8.79627 9.36349 8.22317 9.36349 7.84726V4.02748C9.36349 2.81466 8.36069 1.8987 7.15276 2.00901L3.90604 2.30527C2.70709 2.41466 1.85608 3.48524 2.02029 4.67734Z"
                fill="currentColor"
            />
            <path
                d="M19.472 7.52605C19.472 7.82709 19.716 8.07113 20.0171 8.07113C20.3181 8.07113 20.5622 7.82709 20.5622 7.52605V4.10461C20.5622 3.80357 20.3181 3.55953 20.0171 3.55953H16.5956C16.2946 3.55953 16.0505 3.80357 16.0505 4.10461C16.0505 4.40565 16.2946 4.64969 16.5956 4.64969H18.7691L13.5157 9.81212L12.034 11.3061C11.822 11.5198 11.8235 11.865 12.0372 12.0769C12.251 12.2889 12.5961 12.2875 12.8081 12.0737L14.2848 10.5847L19.472 5.48739V7.52605Z"
                fill="currentColor"
            />
        </g>
    )
})

export const CallProgressIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CallProgressIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.0001 22.0204L12.0531 14.9999L17.99 22.0204H6.0001ZM15.5762 8.8682C17.4763 10.5337 17.476 13.4665 15.5762 15.1316C15.3686 15.3136 15.3478 15.6295 15.5298 15.8372C15.7118 16.0448 16.0277 16.0656 16.2354 15.8836C18.5895 13.8202 18.59 10.1801 16.2354 8.11619C16.0277 7.93417 15.7118 7.95496 15.5298 8.16262C15.3478 8.37028 15.3686 8.68618 15.5762 8.8682ZM19.0688 19.1729C23.231 15.2754 23.2289 8.72244 19.0688 4.82692C18.8672 4.63818 18.5508 4.64857 18.362 4.85014C18.1733 5.05171 18.1837 5.36812 18.3852 5.55687C22.1235 9.05734 22.1254 14.9407 18.3852 18.4429C18.1837 18.6317 18.1733 18.9481 18.362 19.1496C18.5508 19.3512 18.8672 19.3616 19.0688 19.1729ZM8.42398 15.1316C6.52387 13.4661 6.52424 10.5333 8.42397 8.8682C8.63163 8.68618 8.65242 8.37029 8.4704 8.16262C8.28839 7.95496 7.97249 7.93417 7.76482 8.11619C5.41067 10.1796 5.41021 13.8197 7.76482 15.8836C7.97248 16.0656 8.28838 16.0448 8.4704 15.8372C8.65242 15.6295 8.63164 15.3136 8.42398 15.1316ZM4.93144 4.82702C0.769223 8.72445 0.771272 15.2775 4.93144 19.173C5.13301 19.3617 5.44942 19.3513 5.63817 19.1497C5.82691 18.9482 5.81652 18.6318 5.61495 18.443C1.87667 14.9426 1.87483 9.05915 5.61495 5.55697C5.81652 5.36822 5.82691 5.05181 5.63817 4.85024C5.44942 4.64867 5.13301 4.63828 4.93144 4.82702ZM13.4696 13.4691C12.6582 14.2805 11.3427 14.2805 10.5313 13.4691C9.71988 12.6577 9.71988 11.3422 10.5313 10.5308C11.3427 9.71938 12.6582 9.71938 13.4696 10.5308C14.281 11.3422 14.281 12.6577 13.4696 13.4691ZM11.2384 11.2379C10.8175 11.6587 10.8175 12.3411 11.2384 12.762C11.6592 13.1828 12.3416 13.1828 12.7625 12.762C13.1833 12.3411 13.1833 11.6587 12.7625 11.2379C12.3416 10.817 11.6592 10.817 11.2384 11.2379Z"
                fill="currentColor"
            />
            <mask
                id="mask0_2_2980"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="1"
                y="4"
                width="22"
                height="19"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.0001 22.0204L12.0531 14.9999L17.99 22.0204H6.0001ZM15.5762 8.8682C17.4763 10.5337 17.476 13.4665 15.5762 15.1316C15.3686 15.3136 15.3478 15.6295 15.5298 15.8372C15.7118 16.0448 16.0277 16.0656 16.2354 15.8836C18.5895 13.8202 18.59 10.1801 16.2354 8.11619C16.0277 7.93417 15.7118 7.95496 15.5298 8.16262C15.3478 8.37028 15.3686 8.68618 15.5762 8.8682ZM19.0688 19.1729C23.231 15.2754 23.2289 8.72244 19.0688 4.82692C18.8672 4.63818 18.5508 4.64857 18.362 4.85014C18.1733 5.05171 18.1837 5.36812 18.3852 5.55687C22.1235 9.05734 22.1254 14.9407 18.3852 18.4429C18.1837 18.6317 18.1733 18.9481 18.362 19.1496C18.5508 19.3512 18.8672 19.3616 19.0688 19.1729ZM8.42398 15.1316C6.52387 13.4661 6.52424 10.5333 8.42397 8.8682C8.63163 8.68618 8.65242 8.37029 8.4704 8.16262C8.28839 7.95496 7.97249 7.93417 7.76482 8.11619C5.41067 10.1796 5.41021 13.8197 7.76482 15.8836C7.97248 16.0656 8.28838 16.0448 8.4704 15.8372C8.65242 15.6295 8.63164 15.3136 8.42398 15.1316ZM4.93144 4.82702C0.769224 8.72445 0.771272 15.2775 4.93144 19.173C5.13301 19.3617 5.44942 19.3513 5.63817 19.1497C5.82691 18.9482 5.81652 18.6318 5.61495 18.443C1.87667 14.9426 1.87483 9.05915 5.61495 5.55697C5.81652 5.36822 5.82691 5.05181 5.63817 4.85024C5.44942 4.64867 5.13301 4.63828 4.93144 4.82702ZM13.4696 13.4691C12.6582 14.2805 11.3427 14.2805 10.5313 13.4691C9.71988 12.6577 9.71988 11.3422 10.5313 10.5308C11.3427 9.71938 12.6582 9.71938 13.4696 10.5308C14.281 11.3422 14.281 12.6577 13.4696 13.4691ZM11.2384 11.2379C10.8175 11.6587 10.8175 12.3411 11.2384 12.762C11.6592 13.1828 12.3416 13.1828 12.7625 12.762C13.1833 12.3411 13.1833 11.6587 12.7625 11.2379C12.3416 10.817 11.6592 10.817 11.2384 11.2379Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_2_2980)"></g>
        </g>
    )
})

export const CallQualityIcon: ComponentWithAs<'svg', IconProps> = createIcon({
    displayName: 'CallQualityIcon',
    viewBox: '0 0 24 24',
    path: (
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.668 8.73559L14.5526 8.41359L12.5648 2.86846C12.3995 2.37441 11.6356 2.37662 11.4642 2.88859L9.36794 8.73559L9.02599 8.74467L3.12745 8.89825C2.50005 8.98555 2.29724 9.64096 2.71928 9.97762L7.77278 13.7537L7.66781 14.0912L5.9205 19.7085C5.76181 20.1825 6.33332 20.6261 6.74441 20.3817L11.976 16.7684L12.2602 16.9647L17.2075 20.3844C17.6131 20.7084 18.1922 20.2703 18.0313 19.7088L16.1792 13.7537L16.4623 13.5421L21.2432 9.97103C21.6902 9.67465 21.5151 8.99424 20.9996 8.90744L14.668 8.73559Z"
                fill="currentColor"
            />
            <mask
                id="mask0_417_196"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="2"
                y="2"
                width="20"
                height="19"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.668 8.73559L14.5526 8.41359L12.5648 2.86846C12.3995 2.37441 11.6356 2.37662 11.4642 2.88859L9.36794 8.73559L9.02599 8.74467L3.12745 8.89825C2.50005 8.98555 2.29724 9.64096 2.71928 9.97762L7.77278 13.7537L7.66781 14.0912L5.9205 19.7085C5.76181 20.1825 6.33332 20.6261 6.74441 20.3817L11.976 16.7684L12.2602 16.9647L17.2075 20.3844C17.6131 20.7084 18.1922 20.2703 18.0313 19.7088L16.1792 13.7537L16.4623 13.5421L21.2432 9.97103C21.6902 9.67465 21.5151 8.99424 20.9996 8.90744L14.668 8.73559Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_417_196)"></g>
        </g>
    )
})
