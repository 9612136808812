// Check support and request access to Browser's Notification API.
export function requestPermission(onPermission: (permission: NotificationPermission) => void) {
    if (!('Notification' in window)) {
        console.warn('This browser does not support notifications.')
    } else {
        Notification.requestPermission().then(onPermission)
    }
}

export function createNotification(
    title: string,
    body: string,
    eventHandlers: { click?: (e) => void } = {}
) {
    const notification = new Notification(title, { body })

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            notification.close()
        }
    }

    Object.keys(eventHandlers).forEach(handler => {
        notification.addEventListener(handler, eventHandlers[handler])
        notification.addEventListener('close', () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
            window.removeEventListener('focus', handleVisibilityChange)
        })
    })

    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('focus', handleVisibilityChange)

    return notification
}
