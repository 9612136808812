import { Center, Grid } from '@chakra-ui/react'
import logger from '@missionlabs/logger'
import { EmptyPage } from '@missionlabs/react'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Navbar } from './Navbar'

export const NotFoundPage: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        logger.warn(`User trying to access URL: ${location.pathname}`)
    }, [location])

    return (
        <Grid h="100dvh" w="100dvw" templateColumns="64px 1fr" as="main">
            <Navbar />
            <Center h="80vh">
                <EmptyPage
                    title={t('404.heading')}
                    subtitle={t('404.subheading')}
                    link={{ label: t('404.homeLink'), onClick: () => navigate('/') }}
                    showLogo={false}
                />
            </Center>
        </Grid>
    )
}
