import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@missionlabs/react'
import { WhatsappIcon } from '@missionlabs/react/circleloop'
import { ChatIconRegular } from '@missionlabs/react/zeta'
import { ActivityFooterMessageType } from 'features/activity/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useActivityFooterState } from './hooks/useActivityFooterState'

export interface ActivityFeedMessagingMenuProps {
    smsEnabled: boolean
    whatsappEnabled: boolean
}

export const ActivityFeedMessagingMenu: FC<ActivityFeedMessagingMenuProps> = ({
    smsEnabled,
    whatsappEnabled
}: ActivityFeedMessagingMenuProps) => {
    const { t } = useTranslation()
    const { onOpen, setMessageType } = useActivityFooterState()

    const handleOpen = (type: ActivityFooterMessageType) => {
        setMessageType(type)
        onOpen()
    }

    return (
        <Menu variant="compact">
            <MenuButton as={IconButton} variant="ghost" aria-label="Messaging Menu">
                <ChatIconRegular />
            </MenuButton>
            <MenuList>
                {smsEnabled && (
                    <MenuItem
                        aria-label={t('messaging.sendSMS')}
                        icon={<ChatIconRegular />}
                        onClick={() => handleOpen(ActivityFooterMessageType.SMS)}
                    >
                        {t('messaging.sendSMS')}
                    </MenuItem>
                )}
                {whatsappEnabled && (
                    <MenuItem
                        aria-label={t('messaging.sendWhatsApp')}
                        icon={<WhatsappIcon />}
                        onClick={() => handleOpen(ActivityFooterMessageType.WhatsApp)}
                    >
                        {t('messaging.sendWhatsApp')}
                    </MenuItem>
                )}
            </MenuList>
        </Menu>
    )
}
