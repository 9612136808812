import { IconButton, IconButtonProps } from '@missionlabs/react'
import { VideoIconSolid, VideoSlashIconSolid } from '@missionlabs/react/zeta'
import { useLocalVideo, useVideoInputs } from 'amazon-chime-sdk-component-library-react'
import { FC } from 'react'

export interface VideoInputControlProps extends Omit<IconButtonProps, 'aria-label' | 'onClick'> {
    /** The label shown when video is enabled. Defaults to `Turn video off` */
    labelVideo?: string
    /** The label shown when video is disabled (voice only). Defaults to `Turn video on` */
    labelNoVideo?: string
    onClick?: (isVideoEnabled: boolean, defaultDevice: string) => void
    cameraDeviceID: string
}

const VideoInputControl: FC<VideoInputControlProps> = ({
    labelVideo = 'Turn video off',
    labelNoVideo = 'Turn video on',
    onClick,
    cameraDeviceID,
    ...props
}) => {
    const { isVideoEnabled, toggleVideo } = useLocalVideo()
    const {
        devices: [defaultDevice]
    } = useVideoInputs()

    async function handleToggle() {
        if (isVideoEnabled) {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true })
            const tracks = stream.getVideoTracks()
            tracks.forEach(track => track.stop())
        }

        if (cameraDeviceID) {
            // Only toggle video if we have a device set
            return await toggleVideo()
        }

        onClick?.(!isVideoEnabled, defaultDevice.deviceId)
    }

    return (
        <IconButton
            {...props}
            variant="negatory"
            isRounded
            title={isVideoEnabled ? labelNoVideo : labelVideo}
            disabled={!isVideoEnabled}
            onClick={handleToggle}
            aria-label={isVideoEnabled ? labelNoVideo : labelVideo}
            data-testid="video-input-control"
        >
            {!isVideoEnabled ? <VideoSlashIconSolid /> : <VideoIconSolid />}
        </IconButton>
    )
}

export default VideoInputControl
