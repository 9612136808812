import { DecoratorNode, EditorConfig, LexicalEditor, LexicalNode, NodeKey } from 'lexical'
import { ReactNode } from 'react'

import { Upload } from '../components/Upload'

export class UploadNode extends DecoratorNode<any> {
    __ID: string
    __mime: string
    __body: string
    __fileName: string
    __uploaded: boolean
    __uploadID?: string

    static getType(): string {
        return 'upload'
    }

    static clone(node: UploadNode): UploadNode {
        return new UploadNode(
            node.__ID,
            node.__mime,
            node.__body,
            node.__name,
            node.__uploaded,
            node.__uploadID,
            node.__key
        )
    }

    constructor(
        ID: string,
        mime: string,
        body: string,
        fileName: string,
        uploaded?: boolean,
        uploadID?: string,
        key?: NodeKey
    ) {
        super(key)
        this.__ID = ID
        this.__mime = mime
        this.__body = body
        this.__fileName = fileName
        this.__uploaded = uploaded || false
        this.__uploadID = uploadID
    }

    createDOM(_config: EditorConfig): HTMLElement {
        const div = document.createElement('div')
        div.style.display = 'contents'
        return div
    }

    updateDOM(): false {
        return false
    }

    setURL(url: string): void {
        const writable = this.getWritable()
        writable.__url = url
    }

    decorate(_editor: LexicalEditor): ReactNode {
        return (
            <Upload
                ID={this.__ID}
                uploaded={this.__uploaded}
                name={this.__fileName}
                mime={this.__mime}
                body={this.__body}
            />
        )
    }
    isKeyboardSelectable(): boolean {
        return false
    }
}

export function $createUploadNode(
    ID: string,
    mime: string,
    body: string,
    fileName: string,
    uploaded = false,
    uploadID?: string
): UploadNode {
    return new UploadNode(ID, mime, body, fileName, uploaded, uploadID)
}

export function $isUploadNode(node: LexicalNode | null): boolean {
    return node instanceof UploadNode
}
