import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsPage } from 'shared/components/settings/SettingsPage'

import { Email } from './Email'
import { Integrations } from './Integrations'
import { LoggedInDevices } from './LoggedInDevices'

export interface NotificationsProps {}

export const Notifications: FC<NotificationsProps> = () => {
    const { t } = useTranslation()
    return (
        <SettingsPage title={t('notifications.title')}>
            <LoggedInDevices />
            <Email />
            <Integrations />
        </SettingsPage>
    )
}
