import { Box, useColorMode } from '@chakra-ui/react'
import { Spinner, Table } from '@missionlabs/react'
import { InvoiceLine } from '@missionlabs/types'
import { formatCurrency } from '@missionlabs/utils'
import { createColumnHelper } from '@tanstack/react-table'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetEstimatedInvoiceQuery } from 'shared/store'

type RenderedEstimatedInvoiceLine = Partial<Omit<InvoiceLine, 'termStart' | 'termEnd'>>

const columnHelper = createColumnHelper<RenderedEstimatedInvoiceLine>()

export interface LicensingTableProps {}

export const LicensingTable: FC<LicensingTableProps> = () => {
    const { colorMode } = useColorMode()
    const { t } = useTranslation()

    const { data, isLoading } = useGetEstimatedInvoiceQuery()

    const columns = [
        columnHelper.accessor('description', {
            cell: info => info.getValue(),
            header: t('Type'),
            footer: t('Total'),
            enableSorting: false
        }),
        columnHelper.accessor('quantity', {
            cell: info => info.getValue(),
            header: t('Count'),
            enableSorting: false
        }),
        columnHelper.accessor('unitAmount', {
            cell: info =>
                formatCurrency({
                    amount: (info.getValue() || 0) / 100,
                    currency: data?.currencyCode
                }),
            header: t('Price Per Month'),
            footer: formatCurrency({
                amount: (data?.total || 0) / 100,
                currency: data?.currencyCode
            }),
            meta: { align: 'right' },
            enableSorting: false
        })
    ]

    const lines = useMemo(() => {
        if (!data) return []

        const subtotal: RenderedEstimatedInvoiceLine = {
            description: t('Subtotal'),
            unitAmount: data.subtotal
        }
        const tax: RenderedEstimatedInvoiceLine | undefined = !data.taxTotal
            ? undefined
            : {
                  description: data.taxDescription!,
                  unitAmount: data.taxTotal
              }

        return [...data.lines, subtotal, tax].filter(Boolean) as RenderedEstimatedInvoiceLine[]
    }, [data, t])

    if (isLoading) return <Spinner size="sm" />

    return (
        <Box
            w="100%"
            sx={{
                // Add border above the subtotals section
                'tbody tr:nth-last-child(3) td': {
                    borderBottom: '1px solid',
                    borderBottomColor: `${colorMode}.tones.periwinkle`
                },
                // Squeeze the last two rows to make them look like a footer
                'tbody tr:nth-last-child(2) td': { paddingBottom: 2, paddingTop: 6 },
                'tbody tr:nth-last-child(1) td': { paddingTop: 2, paddingBottom: 6 },
                // For the last two rows (subtotal and tax), make them bold and white, and remove hover effect
                'tbody tr:nth-last-child(-n+2)': {
                    bg: 'white',
                    td: { fontWeight: 500 },
                    _hover: { bg: 'white' }
                },
                // For the footer (Total), remove the bottom border and hover effect
                'tfoot th': {
                    bg: 'white',
                    fontSize: '16px',
                    borderBottom: 'none',
                    py: 6
                },
                // Remove table bottom border
                table: { borderBottom: 'none' }
            }}
        >
            <Table data={lines} columns={columns} />
        </Box>
    )
}
