import { Activity } from '@missionlabs/react'
import { UserActivity } from '@missionlabs/types'
import { FC, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useActivityInfo } from 'shared/hooks/useActivityInfo'
import { Section } from 'shared/types/feed'
import { isUnread } from 'shared/utils/activities'

import { useActivitySidebarContext } from './ActivitySidebarContext'

export interface ActivitySidebarRowProps {
    activity: UserActivity & {
        section: Section
    }
}

export const ActivitySidebarRow: FC<ActivitySidebarRowProps> = ({ activity }) => {
    const navigate = useNavigate()
    const { search, state } = useLocation()
    const { direction, name, originatingNumber, dialledNumber, team, time } =
        useActivityInfo(activity)
    const { handleRead } = useActivitySidebarContext()
    const selectedActivityID = useMemo(() => state?.activity?.ID as string | undefined, [state])

    const navigateToActivity = useCallback(() => {
        navigate({ pathname: `/home/feed`, search }, { state: { activityID: activity.ID } })

        if (selectedActivityID && selectedActivityID !== activity.ID) {
            // view activity -> view other activity
            // mark the activity that they _were_ viewing as read
            // TODO: [ZETA-7115] Remove this when we have a better way to handle read state - i.e. viewed in activity feed
            handleRead(selectedActivityID)
        }
    }, [navigate, search, selectedActivityID, activity, handleRead])

    return (
        <Activity
            direction={direction as any}
            activityType={activity.activityType}
            time={time}
            name={name}
            team={team}
            originatingNumber={originatingNumber}
            dialledNumber={dialledNumber}
            callLength={activity.callLength}
            onClick={navigateToActivity}
            isSelected={activity.ID === selectedActivityID}
            isNew={isUnread(activity)}
        />
    )
}
