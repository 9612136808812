import { selectAuthenticatedUser, useSelector } from '@missionlabs/api'
import {
    Dialog,
    DialogBody,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogOverlay
} from '@missionlabs/react'
import { Destination, DestinationTypes } from '@missionlabs/types'
import { FC, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDeleteDestinationForUserMutation } from 'shared/store'

export interface DeleteDeviceDialogProps {
    device: Destination
    isOpen: boolean
    onClose: () => void
}

export const DeleteDeviceDialog: FC<DeleteDeviceDialogProps> = ({ device, isOpen, onClose }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const ref = useRef<HTMLButtonElement>(null)

    const name = useMemo(() => {
        if (device.label) return device.label
        if (device.type === DestinationTypes.number) return device.numberE164
        return device.name
    }, [device])

    const user = useSelector(selectAuthenticatedUser)

    const [deleteDestination, { isLoading }] = useDeleteDestinationForUserMutation()

    async function handleDelete() {
        if (!user) return
        await deleteDestination({ ID: device.ID, userID: user.userID }).unwrap()
        onClose()
        navigate('/settings/devices')
    }

    return (
        <Dialog leastDestructiveRef={ref} isOpen={isOpen} onClose={onClose}>
            <DialogOverlay />
            <DialogContent>
                <DialogCloseButton />
                <DialogHeader>{t('devices.delete.header')}</DialogHeader>
                <DialogBody>{t('devices.delete.body', { name })}</DialogBody>
                <DialogFooter
                    cancel={{ ref, label: t('Cancel') }}
                    confirm={{
                        label: t('Remove device'),
                        isDestructive: true,
                        onClick: handleDelete
                    }}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    )
}
