import { Button, Spinner, Table } from '@missionlabs/react'
import { Invoice } from '@missionlabs/types'
import { downloadURL, formatCurrency, titleCase } from '@missionlabs/utils'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useGenerateInvoicePDFMutation, useGetInvoicesQuery } from 'shared/store'

const columnHelper = createColumnHelper<Invoice>()

export const BillingInvoicesTable: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { data = [], isLoading } = useGetInvoicesQuery()
    const [generatePDF, { isLoading: downloadLoading, originalArgs }] =
        useGenerateInvoicePDFMutation()

    async function handleDownload(id: string) {
        const pdf = await generatePDF(id).unwrap()
        if (pdf) downloadURL(pdf)
    }

    const unpaidStatus = ['payment_due', 'unpaid']

    const onPayInvoice = (invoiceID: string) => {
        navigate(`/admin/billing/invoices/${invoiceID}/pay`)
    }

    const columns = [
        columnHelper.accessor('invoiceDate', {
            cell: info => format(info.getValue() * 1000, 'dd MMM yyyy'),
            header: t('Date')
        }),
        columnHelper.accessor('invoiceID', {
            cell: info => info.getValue(),
            header: t('Reference')
        }),
        columnHelper.accessor('total', {
            cell: info => {
                const amount = info.getValue() / 100
                const currency = info.row.original.currencyCode
                return formatCurrency({ currency, amount })
            },
            header: t('Cost')
        }),
        columnHelper.accessor('status', {
            cell: info => titleCase(info.getValue().replace('_', '')),
            header: t('Status')
        }),
        columnHelper.display({
            cell: info => {
                const invoiceID = info.row.getValue<string>('invoiceID')
                const status = info.row.getValue<string>('status')
                return (
                    <>
                        {unpaidStatus.includes(status) && (
                            <Button
                                variant="secondary"
                                size="sm"
                                fontWeight="500"
                                data-testid={`pay-invoice-${invoiceID}`}
                                marginRight={1}
                                onClick={() => onPayInvoice(invoiceID)}
                            >
                                {t('admin.billing.invoice.payNow')}
                            </Button>
                        )}
                        <Button
                            variant="secondary"
                            size="sm"
                            fontWeight="500"
                            isLoading={downloadLoading && originalArgs === invoiceID}
                            onClick={() => handleDownload(invoiceID)}
                            data-testid={`download-invoice-${invoiceID}`}
                        >
                            {t('Download')}
                        </Button>
                    </>
                )
            },
            id: 'download',
            header: '',
            meta: { align: 'end' }
            // size: 0
        })
    ]

    if (isLoading) return <Spinner size="sm" />

    return (
        <Table
            data={data}
            columns={columns}
            paginate
            sorting={[{ id: 'invoiceDate', desc: true }]}
        />
    )
}
