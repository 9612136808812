import {
    selectCurrentDestinationID,
    selectPresenceSubscription,
    useSelector
} from '@missionlabs/api'
import {
    ContactGroup,
    DetailedState,
    DirectoryEntry,
    LabelledPresenceStatus,
    UserStatus
} from '@missionlabs/types'
import { useFlags } from 'flagsmith/react'
import { useMemo } from 'react'
import { selectPresenceSlice } from 'shared/slices/presenceSlice'
import { useGetCallsOnOtherDevicesQuery } from 'shared/store'
import { isPresenceAllowed } from 'shared/utils/presenceToStatus'

import { useGetPresenceHelpers } from './useGetPresenceHelpers'

export function useGetPresenceID(contact?: DirectoryEntry | ContactGroup) {
    return useMemo(
        () =>
            isPresenceAllowed(contact as DirectoryEntry | ContactGroup)
                ? (contact as DirectoryEntry).externalID
                : undefined,
        [contact]
    )
}

export const useGetPresence = (userID?: string) => {
    const flags = useFlags(['presence'])

    const presence = useSelector(selectPresenceSlice)
    const subscriptions = useSelector(selectPresenceSubscription)

    const subscription = subscriptions?.find(item => item.userID === userID)
    const destinationID = useSelector(selectCurrentDestinationID)

    const { data: callsOnOtherDevices } = useGetCallsOnOtherDevicesQuery(
        { userID: userID ?? '', currentDestinationID: destinationID ?? '' },
        {
            pollingInterval: 10_000,
            skip: !userID || !destinationID
        }
    )

    const {
        getStatus,
        getCachedStatus,
        getCachedVanityStatus,
        getLastKnownVanityStatus,
        isAvailable,
        isInAMeeting
    } = useGetPresenceHelpers(subscription)

    const { status, detailedState, vanityStatus } = useMemo((): {
        status?: LabelledPresenceStatus
        detailedState?: DetailedState
        vanityStatus?: UserStatus
    } => {
        if (!userID || !flags.presence.enabled) {
            return { status: undefined, detailedState: undefined }
        }

        const lastKnownStatus = presence.lastKnownStatus[userID]

        // cached updates from service.
        if (!subscription) {
            if (lastKnownStatus) {
                return {
                    status: getCachedStatus(lastKnownStatus.status),
                    detailedState: lastKnownStatus.status,
                    vanityStatus: getCachedVanityStatus(lastKnownStatus.status)
                }
            }

            return {
                status: { status: UserStatus.Offline, label: '' },
                detailedState: DetailedState.UNKNOWN,
                vanityStatus: UserStatus.Offline
            }
        }

        const snoozeStatusName =
            (subscription.metadata?.snoozeStatusName?.toLowerCase() as UserStatus) ??
            UserStatus.Active

        // optimistic updates from UI.
        if (lastKnownStatus && lastKnownStatus.lastUpdated > subscription.lastUpdated) {
            return {
                status: getStatus(lastKnownStatus.status),
                detailedState: lastKnownStatus.status,
                vanityStatus: getLastKnownVanityStatus(lastKnownStatus.status)
            }
        }

        return {
            status: getStatus(subscription.detailedState),
            detailedState: subscription.detailedState,
            vanityStatus: snoozeStatusName
        }
    }, [
        userID,
        flags.presence.enabled,
        subscription,
        presence.lastKnownStatus,
        getStatus,
        getCachedStatus,
        getCachedVanityStatus,
        getLastKnownVanityStatus
    ])

    const isUserInCallOnOtherDevice = useMemo(() => {
        return (callsOnOtherDevices?.length ?? 0) > 0
    }, [callsOnOtherDevices])

    return {
        status,
        detailedState,
        subscriptions,
        userSubscription: subscription,
        vanityStatus,
        isUserAvailable: isAvailable(detailedState),
        isUserStatusEditable: !isInAMeeting(detailedState),
        isUserInCallOnOtherDevice,
        callsOnOtherDevices
    }
}
