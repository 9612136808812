import { CSSProperties } from 'react'

import { UTMListItem, UTMListItemData } from './UTMListItem'

interface UTMListItemRendererProps {
    data: UTMListItemData[]
    index: number
    style?: CSSProperties
}

export const UTMListItemRenderer = ({ data, index, style }: UTMListItemRendererProps) => {
    const { item, isSelected, onClick } = data[index]

    return <UTMListItem item={item} isSelected={isSelected} style={style} onClick={onClick} />
}
