import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
    $createRangeSelection,
    $getRoot,
    $getSelection,
    $insertNodes,
    $setSelection,
    createCommand,
    LexicalCommand
} from 'lexical'
import { FC, useEffect } from 'react'

import { $createReplyNode } from '../nodes/ReplyNode'

export interface ReplyPluginProps {}

export const INSERT_REPLY_COMMAND: LexicalCommand<{
    ID: string
    from: string
    message: string
}> = createCommand('INSERT_REPLY_COMMAND')

export const REPLY_TO_CHAT_EVENT = 'REPLY_TO_CHAT_EVENT'

export const dispatchReplyEvent = (ID: string, from: string, message: string) => {
    return document.dispatchEvent(
        new CustomEvent(REPLY_TO_CHAT_EVENT, { detail: { ID, from, message } })
    )
}

export const ReplyPlugin: FC<ReplyPluginProps> = () => {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        const insertReply = e => {
            const { ID, from, message } = e.detail

            editor.update(() => {
                const currSelection = $getSelection()
                const rangeSelection = $createRangeSelection()
                const root = $getRoot()
                const nodes = root.getChildren()
                const existisingReply = nodes.find(
                    n => n.__type === 'reply' || n.__type === 'edit_message'
                )

                if (existisingReply) {
                    existisingReply.remove()
                }

                $setSelection(rangeSelection)
                $insertNodes([$createReplyNode(ID, from, message)])
                $setSelection(currSelection)
            })
        }

        document.addEventListener(REPLY_TO_CHAT_EVENT, insertReply)
        return () => document.removeEventListener(REPLY_TO_CHAT_EVENT, insertReply)
    }, [editor])

    return null
}
